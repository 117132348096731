import { Action } from '@ngrx/store';
import { NavConfig } from 'app/components/app-common-views/sidebar-nav/nav-menus.constants';

export enum ESidebarActions {
  SET_SELECTED_PRIMARY_NAV_CONFIG = '[SIDEBAR] Set Selected Primary NavConfig',
  SET_ACTIVE_NAV_IDS = '[SIDEBAR] Set Active Nav ID',
  SET_MENU_GUARDS = '[SIDEBAR] Set Menu Guards',
  CLEAR_MENU_GUARDS = '[SIDEBAR] Clear Menu Guards',
}

export class SetSelectedPrimaryNavConfig implements Action {
  public readonly type = ESidebarActions.SET_SELECTED_PRIMARY_NAV_CONFIG;

  constructor(public selectedPrimaryNavConfig: NavConfig) {}
}

export class SetActiveNavMenuIds implements Action {
  public readonly type = ESidebarActions.SET_ACTIVE_NAV_IDS;

  constructor(public activeNavMenuIds: { primary: string; secondary: string; tertiary: string }) {}
}

export type SidebarActions = SetSelectedPrimaryNavConfig | SetActiveNavMenuIds;
