import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '@gms/reporting-api/configuration';
import { appConfig } from 'config/app-config';
import { Observable } from 'rxjs';

@Injectable()
export class TelerikReportServerService {
  readonly basePath = appConfig.endpoints.reportServer + '/reportserver';
  public defaultHeaders = new HttpHeaders();
  configuration: Configuration;

  constructor(protected httpClient: HttpClient) {}

  getReportClientId(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    return this.httpClient.post<any>(`${this.basePath}/clients`, {
      headers: this.defaultHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  getReportParameters(
    reportName: string,
    reportClientId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (reportName === null || reportName === undefined) {
      throw new Error(
        'Required parameter reportName was null or undefined when calling getReportClientId.'
      );
    }

    // Pass in the report name and no parameter values as none are ever needed to fetch default params
    const body = JSON.stringify({ report: reportName, parameterValues: {} });
    return this.httpClient.post<any>(
      `${this.basePath}/clients/${reportClientId}/parameters`,
      body,
      {
        headers: this.defaultHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  triggerPdfDownload(
    url: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<Blob> {
    return this.httpClient.get(url, {
      headers: this.defaultHeaders,
      responseType: 'blob',
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
