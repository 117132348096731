import { Injectable } from '@angular/core';
import {
  NonTariffRate,
  NonTariffRateCollection,
  NonTariffRateDetailCollection,
  NonTariffRateDetailGridCollection,
  RatesDropdownDictionary,
  RatesNonTariffDetailsGridService,
  RatesNonTariffDetailsService,
  RatesNonTariffDictionaryService,
  RatesNonTariffService,
  RatesRevenueRecognitionService,
  RevenueRecognitionCollection,
} from '@gms/rate-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { findOriginalNonTariffRateDetailIndex } from 'app/modules/rates/pages/create-non-tariff-rate/non-tariff-rate.utils';
import { NonTariffRateForEdit } from 'app/store/rate-contract/rate-contract.models';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import {
  AddNonTariffRate,
  AddNonTariffRateFailure,
  AddNonTariffRateSuccess,
  DeleteNonTariffRate,
  DeleteNonTariffRateFailure,
  DeleteNonTariffRateSuccess,
  ERateContractActions,
  FetchExportContractualRatesDetails,
  FetchExportContractualRatesDetailsFailure,
  FetchExportContractualRatesDetailsSuccess,
  FetchExportContractualRatesSummary,
  FetchExportContractualRatesSummaryFailure,
  FetchExportContractualRatesSummarySuccess,
  FetchNonTariffById,
  FetchNonTariffByIdFailure,
  FetchNonTariffByIdForEditFailure,
  FetchNonTariffByIdForEditSuccess,
  FetchNonTariffByIdSuccess,
  FetchNonTariffDetailsGrid,
  FetchNonTariffDetailsGridFailure,
  FetchNonTariffDetailsGridSuccess,
  FetchNonTariffRates,
  FetchNonTariffRatesDetails,
  FetchNonTariffRatesDetailsFailure,
  FetchNonTariffRatesDetailsSucess,
  FetchNonTariffRatesFailure,
  FetchNonTariffRatesSucess,
  FetchRateDictionary,
  FetchRateDictionaryFailure,
  FetchRateDictionarySuccess,
  FetchRevenueRecognitionDictionary,
  FetchRevenueRecognitionDictionaryFailure,
  FetchRevenueRecognitionDictionarySuccess,
  UpdateNonTariffRate,
  UpdateNonTariffRateFailure,
  UpdateNonTariffRateSuccess,
} from './rate-contract.actions';

const formatServiceRequesterWithPipeDelimiter = (serviceRequesters: string[]): string[] => {
  return serviceRequesters && serviceRequesters.length ? [serviceRequesters.join('|')] : null;
};

@Injectable({
  providedIn: 'root',
})
export class RateContractEffects {
  constructor(
    private _actions$: Actions,
    private _nonTariffRateService: RatesNonTariffService,
    private _nonTariffRatesDetailsService: RatesNonTariffDetailsService,
    private _nonTariffRatesDetailsGridService: RatesNonTariffDetailsGridService,
    private _rateDictionaryService: RatesNonTariffDictionaryService,
    private _revenueRecognitionDictionaryService: RatesRevenueRecognitionService
  ) {}

  addNonTariffRate: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddNonTariffRate>(ERateContractActions.ADD_NON_TARIFF_RATE),
      debounceTime(500),
      map((action: AddNonTariffRate) => action),
      switchMap(action =>
        this._nonTariffRateService.postNonTariffRate(action.payload).pipe(
          map(nonTarrifRate => new AddNonTariffRateSuccess(nonTarrifRate)),
          catchError(error => of(new AddNonTariffRateFailure(error)))
        )
      )
    )
  );

  updateNonTariffRate: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateNonTariffRate>(ERateContractActions.UPDATE_NON_TARIFF_RATE),
      map((action: UpdateNonTariffRate) => action),
      switchMap(action => {
        return this._nonTariffRateService.putNonTariffRate(action.payload, action.rateId).pipe(
          map(response => new UpdateNonTariffRateSuccess(response)),
          catchError(error => {
            const originalOrder = (action && action.payload && action.payload.detail) || [];
            const errorOrder = (error && error.error && error.error.detail) || [];

            if (
              originalOrder.length &&
              errorOrder.length &&
              originalOrder.length === errorOrder.length
            ) {
              errorOrder.sort(
                (a, b) =>
                  findOriginalNonTariffRateDetailIndex(originalOrder, a) -
                  findOriginalNonTariffRateDetailIndex(originalOrder, b)
              );
            }

            return of(new UpdateNonTariffRateFailure(error));
          })
        );
      })
    )
  );

  deleteNonTariffRate: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DeleteNonTariffRate>(ERateContractActions.DELETE_NON_TARIFF_RATE),
      map((action: DeleteNonTariffRate) => action),
      switchMap(action => {
        return this._nonTariffRateService.deleteNonTariffRate(action.payload.rateId).pipe(
          map(response => {
            return new DeleteNonTariffRateSuccess(action.payload);
          }),
          catchError(error => of(new DeleteNonTariffRateFailure(error)))
        );
      })
    )
  );

  fetchRateDictionary = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRateDictionary>(ERateContractActions.FETCH_RATE_DICTIONARY),
      switchMap(() =>
        this._rateDictionaryService.getDictionary().pipe(
          switchMap((dictionary: RatesDropdownDictionary) =>
            of(new FetchRateDictionarySuccess(dictionary))
          ),
          catchError(error => of(new FetchRateDictionaryFailure(error)))
        )
      )
    )
  );

  fetchNonTariffById = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNonTariffById>(ERateContractActions.FETCH_NON_TARIFF_BY_ID),
      switchMap(action => {
        return this._nonTariffRateService
          .getNonTariffRateById(
            action.payload.rateId,
            action.payload.effectiveDate,
            action.payload.expireDate
          )
          .pipe(
            switchMap((fercRate: NonTariffRate) => of(new FetchNonTariffByIdSuccess(fercRate))),
            catchError(error => of(new FetchNonTariffByIdFailure(error)))
          );
      })
    )
  );

  fetchNonTariffRate = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNonTariffRates>(ERateContractActions.FETCH_NON_TARIFF_RATES),
      map((action: FetchNonTariffRates) => action.payload),
      switchMap(payload => {
        let sortQuery = '';
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir},`;
            });
          }
        }
        return this._nonTariffRateService
          .getNonTariffRates(
            payload.pageSize,
            payload.pageNumber,
            sortQuery.length > 0 ? [sortQuery] : undefined,
            payload.tspId,
            payload.rateType
          )
          .pipe(
            switchMap((nonTariffRateCollection: NonTariffRateCollection) =>
              of(
                new FetchNonTariffRatesSucess({
                  nonTariffRates: nonTariffRateCollection,
                })
              )
            ),
            catchError(error => of(new FetchNonTariffRatesFailure(error)))
          );
      })
    )
  );

  fetchNonTariffRatesDetails = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNonTariffRatesDetails>(ERateContractActions.FETCH_NON_TARIFF_RATES_DETAILS),
      map((action: FetchNonTariffRatesDetails) => action.payload),
      switchMap(payload => {
        let sortQuery = '';
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir},`;
            });
          }
        }
        return this._nonTariffRatesDetailsService
          .getNonTariffRateDetails(
            payload.pageSize,
            payload.pageNumber,
            sortQuery.length > 0 ? [sortQuery] : undefined,
            payload.tspId,
            payload.dateEffective,
            payload.dateExpire,
            payload.rateLevel,
            payload.rateId,
            payload.rateTypeId,
            payload.contractId,
            formatServiceRequesterWithPipeDelimiter(payload.serviceRequesterName),
            payload.rateScheduleId,
            payload.chargeTypeDesc,
            payload.arrAcctTypeId,
            payload.accountId
          )
          .pipe(
            switchMap((nonTariffRateCollection: NonTariffRateDetailCollection) =>
              of(
                new FetchNonTariffRatesDetailsSucess({
                  nonTariffRatesDetails: nonTariffRateCollection,
                })
              )
            ),
            catchError(error => of(new FetchNonTariffRatesDetailsFailure(error)))
          );
      })
    )
  );

  fetchNonTariffDetailsGrid = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNonTariffDetailsGrid>(ERateContractActions.FETCH_NON_TARIFF_DETAILS_GRID),
      map((action: FetchNonTariffDetailsGrid) => action.payload),
      switchMap(payload => {
        let sortQuery = '';
        if (payload) {
          if (payload.sortDescriptors) {
            payload.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir},`;
            });
          }
        }
        return this._nonTariffRatesDetailsGridService
          .getNonTariffRateDetailsGrid(
            payload.pageSize,
            payload.pageNumber,
            sortQuery.length > 0 ? [sortQuery] : undefined,
            payload.tspId,
            payload.dateEffective,
            payload.dateExpire,
            payload.rateId,
            payload.rateLevel,
            payload.rateTypeId,
            payload.contractId,
            formatServiceRequesterWithPipeDelimiter(payload.serviceRequesterName),
            payload.rateScheduleId,
            payload.chargeTypeDesc,
            payload.recLoc,
            payload.recZn,
            payload.recLocGrp,
            payload.delLoc,
            payload.delZn,
            payload.delLocGrp,
            payload.priceType,
            payload.rate,
            payload.rateApplication,
            payload.manual,
            payload.exclInvTran,
            payload.chargePeriod,
            payload.revenueRecognition,
            payload.notes,
            payload.arrAcctTypeId,
            payload.arrIndexRateId,
            payload.userId,
            payload.accountId
          )
          .pipe(
            switchMap((nonTariffDetailsGridCollection: NonTariffRateDetailGridCollection) =>
              of(
                new FetchNonTariffDetailsGridSuccess({
                  nonTariffDetailsGrid: nonTariffDetailsGridCollection,
                })
              )
            ),
            catchError(error => of(new FetchNonTariffDetailsGridFailure(error)))
          );
      })
    )
  );

  fetchExportContractualRatesSummary = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchExportContractualRatesSummary>(
        ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY
      ),
      map((action: FetchExportContractualRatesSummary) => action.payload),
      switchMap(contractualRateFilterParams => {
        let sortQuery = ``;
        if (contractualRateFilterParams) {
          if (contractualRateFilterParams.sortDescriptors) {
            contractualRateFilterParams.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir},`;
            });
          }
        }
        return this._nonTariffRatesDetailsService
          .getNonTariffRateDetails(
            contractualRateFilterParams.pageSize,
            contractualRateFilterParams.pageNumber,
            sortQuery.length > 0 ? [sortQuery] : null,
            contractualRateFilterParams.tspId,
            contractualRateFilterParams.dateEffective,
            contractualRateFilterParams.dateExpire,
            contractualRateFilterParams.rateLevel,
            contractualRateFilterParams.rateId,
            contractualRateFilterParams.rateTypeId,
            contractualRateFilterParams.contractId,
            formatServiceRequesterWithPipeDelimiter(
              contractualRateFilterParams.serviceRequesterName
            ),
            contractualRateFilterParams.rateScheduleId,
            contractualRateFilterParams.chargeTypeDesc
          )
          .pipe(
            switchMap((nonTariffRateDetailCollection: NonTariffRateDetailCollection) =>
              of(
                new FetchExportContractualRatesSummarySuccess({
                  exportContractualRatesSummary: nonTariffRateDetailCollection,
                })
              )
            ),
            catchError(error => of(new FetchExportContractualRatesSummaryFailure(error)))
          );
      })
    )
  );

  fetchExportContractualRatesDetails = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchExportContractualRatesDetails>(
        ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS
      ),
      map((action: FetchExportContractualRatesDetails) => action.payload),
      switchMap(contractualRateFilterParams => {
        let sortQuery = ``;
        if (contractualRateFilterParams) {
          if (contractualRateFilterParams.sortDescriptors) {
            contractualRateFilterParams.sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir},`;
            });
          }
        }
        return this._nonTariffRatesDetailsGridService
          .getNonTariffRateDetailsGrid(
            contractualRateFilterParams.pageSize,
            contractualRateFilterParams.pageNumber,
            sortQuery.length > 0 ? [sortQuery] : undefined,
            contractualRateFilterParams.tspId,
            contractualRateFilterParams.dateEffective,
            contractualRateFilterParams.dateExpire,
            contractualRateFilterParams.rateId,
            contractualRateFilterParams.rateLevel,
            contractualRateFilterParams.rateTypeId,
            contractualRateFilterParams.contractId,
            formatServiceRequesterWithPipeDelimiter(
              contractualRateFilterParams.serviceRequesterName
            ),
            contractualRateFilterParams.rateScheduleId,
            contractualRateFilterParams.chargeTypeDesc,
            contractualRateFilterParams.recLoc,
            contractualRateFilterParams.recZn,
            contractualRateFilterParams.recLocGrp,
            contractualRateFilterParams.delLoc,
            contractualRateFilterParams.delZn,
            contractualRateFilterParams.delLocGrp,
            contractualRateFilterParams.priceType,
            contractualRateFilterParams.rate,
            contractualRateFilterParams.rateApplication,
            contractualRateFilterParams.manual,
            contractualRateFilterParams.exclInvTran,
            contractualRateFilterParams.chargePeriod,
            contractualRateFilterParams.revenueRecognition,
            contractualRateFilterParams.notes,
            contractualRateFilterParams.arrAcctTypeId,
            contractualRateFilterParams.accountId,
            contractualRateFilterParams.arrIndexRateId,
            contractualRateFilterParams.userId
          )
          .pipe(
            switchMap((nonTariffRateDetailCollection: NonTariffRateDetailGridCollection) =>
              of(
                new FetchExportContractualRatesDetailsSuccess({
                  exportContractualRatesDetails: nonTariffRateDetailCollection,
                })
              )
            ),
            catchError(error => of(new FetchExportContractualRatesDetailsFailure(error)))
          );
      })
    )
  );

  fetchRevenueRecognitionDictionary = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRevenueRecognitionDictionary>(
        ERateContractActions.FETCH_REVENUE_RECOGNITION_DICTIONARY
      ),
      switchMap(() =>
        this._revenueRecognitionDictionaryService.getRevenueRecognition().pipe(
          switchMap((dictionary: RevenueRecognitionCollection) =>
            of(new FetchRevenueRecognitionDictionarySuccess(dictionary))
          ),
          catchError(error => of(new FetchRevenueRecognitionDictionaryFailure(error)))
        )
      )
    )
  );

  fetchNonTariffByIdForEdit = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNonTariffById>(ERateContractActions.FETCH_NON_TARIFF_BY_ID_FOR_EDIT),
      switchMap(action => {
        return forkJoin([
          this._nonTariffRateService.getNonTariffRateById(action.payload.rateId),
          this._nonTariffRateService.getNonTariffRateById(
            action.payload.rateId,
            action.payload.effectiveDate,
            action.payload.expireDate
          ),
        ]).pipe(
          map(([nonFilteredRate, filteredRate]) => {
            const hasDisqualification = !!nonFilteredRate.disqualificationRuleDetails;
            const rateForEdit: NonTariffRateForEdit = {
              ...filteredRate,
              hasDisqualification,
            };
            return new FetchNonTariffByIdForEditSuccess(rateForEdit);
          }),
          catchError(error => of(new FetchNonTariffByIdForEditFailure(error)))
        );
      })
    )
  );
}
