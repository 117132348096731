import { formatCurrency, formatNumber, formatPercent } from '@angular/common';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { EMPTY_DATA } from 'shared/consts/empty-data.const';
import { dateUtils } from 'shared/utils/date.utils';
import { formatDate } from 'shared/utils/formatDate.util';
import { isNullOrUndefined } from 'shared/utils/type.utils';

/*
  Adding something?
  Here is a list of
  https://angular.io/api/common#pipes

  sig:
  Decimal representation options, specified by a string in the following format:
  {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.

  minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
  minFractionDigits: The minimum number of digits after the decimal point. Default is 0.
  maxFractionDigits: The maximum number of digits after the decimal point. Default is 3.
*/

const locale = 'en-US';
const currency = '$';
const currencyCode = '840';
const centralTimezone = '(America/Chicago)';

export enum EFormatNumberType {
  CURRENCY = 'CURRENCY',
  DECIMAL = 'DECIMAL',
  QUANTITY = 'QUANTITY',
  PERCENTAGE = 'PERCENTAGE',
}

export const safeDateFormat = (date: any, format: any, localeDate: any) => {
  try {
    return formatDate(date, format, localeDate);
  } catch (error) {
    return date;
  }
};

export const isValidDate = (date: Date) => {
  const dateNum = (date as unknown) as number;
  if (date instanceof Date && isNaN(dateNum)) {
    return false;
  }

  return true;
};

export const cleanInput = (input: any, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : input;

export const cleanString = (input: string, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : input;

export enum CleanBooleanStrategy {
  YES_NO = 'YES_NO',
  Y_N = 'Y_N',
}

export const cleanBoolean = (
  input: boolean,
  notDefined: any = '',
  strategy: CleanBooleanStrategy = CleanBooleanStrategy.YES_NO
) => {
  if (isNullOrUndefined(input)) {
    return notDefined;
  } else {
    switch (strategy) {
      case CleanBooleanStrategy.YES_NO:
        return input ? 'Yes' : 'No';
      case CleanBooleanStrategy.Y_N:
        return input ? 'Y' : 'N';
      default:
        return input ? 'Yes' : 'No';
    }
  }
};

export const stringToBoolean = (input: any, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : input === 'Yes' ? true : false;

/** this will add commas in the 1,000's position. */
export const cleanNumberWithCommas = (input: number, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : formatNumber(input, locale);

export const cleanNumberWithCommasAndNegative = (input: number, notDefined: any = '') =>
  isNullOrUndefined(input)
    ? notDefined
    : formatNegativeNumbertoParenthesis(formatNumber(input, locale));

export const formatNegativeNumbertoParenthesis = (value: any) => {
  if (value === null || value === undefined || value === 0) {
    return value;
  }
  return value.toString().charAt(0) === '-' && value.toString().length > 1
    ? '(' + value.toString().substring(1, value.length) + ')'
    : value;
};

export const formatNegativeFloatWithPrecisionToParenthesis = (value: number, precision: number) => {
  const lessThanZero = value < 0,
    numberAsFixed = Math.abs(value).toFixed(precision);
  return lessThanZero ? `(${numberAsFixed})` : numberAsFixed;
};

export const formatNegativeCurrencytoParenthesis = (value: any) => {
  if (value === null || value === undefined) {
    return value;
  }
  return value.toString().charAt(0) === '-' && value.toString().length > 1
    ? '$(' + value.toString().substring(2, value.length) + ')'
    : value;
};

/** for how to define sig, see above comments */
export const cleanPercentageWithSig = (
  input: number,
  sig: string = '1.2-2',
  notDefined: any = ''
) => (isNullOrUndefined(input) ? notDefined : formatPercent(input, locale, sig));
export const cleanDecimalWithSig = (input: number, sig: string = '1.2-2', notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : formatNumber(input, locale, sig);

export const cleanDateWithMM_DD_YYYY = (input: string | number | Date, notDefined: any = '') => {
  if (input instanceof Date && !isValidDate(input)) {
    return notDefined;
  }
  return isNullOrUndefined(input) ? notDefined : formatDate(cleanAOE(input), 'MM/dd/yyyy', locale);
};

export const cleanDateWithMM_DD_YYYY_No_Timezone = (
  input: string | number | Date,
  notDefined: any = ''
) => {
  if (input instanceof Date && !isValidDate(input)) {
    return notDefined;
  }
  return isNullOrUndefined(input) ? notDefined : formatDate(input, 'MM/dd/yyyy', locale, null);
};

export const cleanAOE = input => {
  if (typeof input === 'number') {
    return dateUtils.getAOEDateFor(new Date(input));
  }
  if (typeof input === 'string') {
    if (input.split('T').length > 1) {
      input = input.split('T')[0];
    }
    return dateUtils.getAOEDateForDashedString(input);
  }
  if (typeof input === 'object') {
    return dateUtils.getAOEDateFor(input);
  }

  return input;
};

export const cleanDateWithMM_YYYY = (input: string | number | Date, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : formatDate(cleanAOE(input), 'MM/yyyy', locale);

export const cleanDateWithMM_DD_YYYY_HH_MM = (
  input: string | number | Date,
  notDefined: any = ''
) => (isNullOrUndefined(input) ? notDefined : formatDate(input, 'MM/dd/yyyy h:mm', locale));
export const cleanDateWithMM_DD_YYYY_HH_MM_AM_PM = (
  input: string | number | Date,
  notDefined: any = ''
) => (isNullOrUndefined(input) ? notDefined : formatDate(input, 'MM/dd/yyyy h:mm a', locale));

export const cleanDateWithMM_DD_YYYY_DASH_HH_MM_AM_PM = (
  input: string | number | Date,
  notDefined: any = ''
) => (isNullOrUndefined(input) ? notDefined : formatDate(input, 'MM/dd/yyyy - hh:mm a', locale));

export const cleanDateWithMM_DD_YYYY_DASH_HH_MM_SS_AM_PM_CentralTimezone = (
  input: string | number | Date,
  notDefined: any = ''
) =>
  isNullOrUndefined(input)
    ? notDefined
    : `${formatDate(input, 'MM/dd/yyyy - hh:mm:ss a', locale, centralTimezone)} CT`;

export const cleanDateWithMM_DD_YYYY_HH_MM_AM_PM_TZ = (
  input: string | number | Date,
  notDefined: any = ''
) => {
  if (input instanceof Date && !isValidDate(input)) {
    return notDefined;
  }

  return isNullOrUndefined(input)
    ? notDefined
    : `${formatDate(input, 'MM/dd/yyyy', locale)} - ${formatDate(input, 'h:mm a', locale)} CT`;
};

export const cleanDateWithYYYY_MM_DD = (input: string | number | Date, notDefined: any = '') => {
  if (input instanceof Date && !isValidDate(input)) {
    return notDefined;
  }

  return isNullOrUndefined(input) ? notDefined : formatDate(cleanAOE(input), 'yyyy-MM-dd', locale);
};

export const cleanDateWithYYYY_MM_DD_No_Timezone = (
  input: string | number | Date,
  notDefined: any = ''
) => {
  if (input instanceof Date && !isValidDate(input)) {
    return notDefined;
  }

  return isNullOrUndefined(input) ? notDefined : formatDate(input, 'yyyy-MM-dd', locale, null);
};

export const cleanDateWithYYYY_MM_DD_HH_MM_SS = (
  input: string | number | Date,
  notDefined: any = ''
) => {
  if (input instanceof Date && !isValidDate(input)) {
    return notDefined;
  }

  return isNullOrUndefined(input) ? notDefined : formatDate(input, 'yyyy-MM-ddThh:mm:ss', locale);
};

export const cleanCurrency = (input: number, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : formatCurrency(input, locale, currency);

export const cleanCurrencyWithSig = (input: number, notDefined: any = '', sig = '1.2-2') =>
  isNullOrUndefined(input)
    ? notDefined
    : formatCurrency(input, locale, currency, currencyCode, sig);

export const parseCurrency = (input: string): number => {
  return parseFloat(input.replace(currency, ''));
};

export const capitalizeEveryFirstLetter = (text: string): string => {
  return text ? startCase(toLower(text)) : EMPTY_DATA;
};

export const cleanDateWithMM_DD_YYYY_TOKEN_HH_MM_AM_PM = (d: Date, token: string = ''): string => {
  return d
    ? `${formatDate(d, 'MM/dd/yyyy', locale)} ${token} ${formatDate(
        d,
        'h:mma',
        locale
      ).toLowerCase()}`
    : '-';
};

//** convert 2019-06-27T00:00:00+00:00 to 06/27/2019 **/
// Temp solution to deal with Time zone issue
export const getDateAsMMDDYYYY = (date: string | Date, notDefined: any = null): string => {
  if (!isNullOrUndefined(date)) {
    const dateString = date.toString();
    if (!isNullOrUndefined(dateString)) {
      const dateParts = dateString.split('T')[0].split('-');
      return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
    }
  }

  return notDefined;
};

export const cleanDateTimeWithHH_MM_AM_PM_TZ = (
  input: string | number | Date,
  notDefined: any = ''
) => (isNullOrUndefined(input) ? notDefined : `${formatDate(input, 'h:mm a', locale)} CT`);

//** convert 2019-06-27T00:00:00+00:00 to 06/2019 **/
// Temp solution to deal with Time zone issue
export const convertDateToMMYYYY = (date: string | Date): string => {
  const dateParts = getDateParts(date);

  if (!isNullOrUndefined(dateParts)) {
    return `${dateParts[1]}/${dateParts[0]}`;
  }

  return null;
};

//** convert 2019-06-27T00:00:00+00:00 to 06/27/2019 **/
// Temp solution to deal with Time zone issue
export const convertDateToMMDDYYYY = (date: string | Date): string => {
  const dateParts = getDateParts(date);

  if (!isNullOrUndefined(dateParts) && dateParts.length > 2) {
    return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
  }

  return null;
};

const getDateParts = (date: string | Date): string[] => {
  if (!isNullOrUndefined(date)) {
    const dateString = date instanceof Date ? date.toISOString() : date.toString();

    return dateString.split('T')[0].split('-');
  }

  return null;
};

/*
 * @description
 *
 * Formats a date according to locale rules and custom format string.
 *
 * @param date The date to format, as a Date, or a number (milliseconds since UTC epoch)
 * or an [ISO date-time string](https://www.w3.org/TR/NOTE-datetime).
 * @param format The date-time components to include.
 * See [DatePipe Guide](https://angular.io/api/common/DatePipe) for details.
 *
 * @returns The formatted date string.
 * */
export const convertDateToLocale = (
  date: number | string | Date,
  format = 'MM/dd/yyyy'
): string => {
  if (isNullOrUndefined(date)) return null;

  return formatDate(date, format, locale);
};

/*
 * Converts the passed Date to a 'H:mm:ss' formatted time string in UTC (24 hours format).
 */
export const cleanTimeWithUTCHH_MM_SS = (input: string | number | Date, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : `${formatDate(input, 'H:mm:ss', locale, 'UTC')}`;

/*
 * Converts the passed Date to a 'H:mm:ss' formatted time string (24 hours format).
 */
export const cleanTimeWithHH_MM_SS = (input: string | number | Date, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : `${formatDate(input, 'H:mm:ss', locale)}`;

export const cleanTimeWithHH_MM_SS_A_CT = (input: string | number | Date, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : `${formatDate(input, 'H:mm:ss a CT', locale)}`;

export const cleanTimeWithHH_MM_SS_SS = (input: string | number | Date, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : `${formatDate(input, 'HH:mm.ss.SSS', locale)}`;

export const toISOString = (input: Date, notDefined: any = '') =>
  isNullOrUndefined(input) ? notDefined : input.toISOString();

// Useful when app-form-datepicker is used with app-form-timepicker
export const changeTimeOnDate = (targetDate: Date, dateWithTargetTime: Date) => {
  const hours = dateWithTargetTime.getHours();
  const minutes = dateWithTargetTime.getMinutes();
  const seconds = dateWithTargetTime.getSeconds();
  targetDate.setHours(hours, minutes, seconds);

  return targetDate;
};

export const getHH_MM_AM_PM = (date: string) => {
  const timePart = date.split('T')[1];
  const timePartArr = timePart.split(':');
  const hours = parseInt(timePartArr[0], 10);
  const minutes = parseInt(timePartArr[1], 10);

  const newDate = new Date().setHours(hours, minutes, 0);

  return formatDate(newDate, 'hh:mm a', locale);
};

export const getDateRangeAsMMDDYYYY = (startDate: string, endDate: string): string => {
  if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) {
    return null;
  }

  return `${getDateAsMMDDYYYY(startDate)} - ${getDateAsMMDDYYYY(endDate)}`;
};

export const formatSimplePhoneNumber = (phoneNumber: string) => {
  if (isNullOrUndefined(phoneNumber)) return null;
  if (/^\d{10}$/.test(phoneNumber)) {
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
      6
    )}`;
  } else return phoneNumber;
};

export const isNumberOrStringNumber = (value: any, zeroAsNull = true) => {
  return !(
    Object.is(value, null) ||
    Object.is(value, undefined) ||
    Object.is(value, NaN) ||
    Object.is(Number(value), NaN) ||
    Object.is(Number(value), -Infinity) ||
    Object.is(Number(value), Infinity) ||
    typeof value === 'symbol' ||
    typeof value === 'object' ||
    typeof value === 'function' ||
    (typeof value === 'string' && Object.is(value.trim(), '')) ||
    (zeroAsNull && (Object.is(Number(value), -0) || Object.is(Number(value), 0)))
  );
};

export const formatNumberToType = (
  value: number | string,
  formatType: EFormatNumberType,
  notDefined: any = '',
  sig: string = '1.2-2',
  formatNegative = true,
  zeroAsNull = true,
  percentageDenominator = 1
): string => {
  if (!isNumberOrStringNumber(value, zeroAsNull)) return notDefined;

  const numberValue = Number(value);

  switch (formatType) {
    case EFormatNumberType.CURRENCY:
      const formattedCurrency = cleanCurrencyWithSig(numberValue, notDefined, sig);

      return formatNegative
        ? formatNegativeCurrencytoParenthesis(formattedCurrency)
        : formattedCurrency;

    case EFormatNumberType.DECIMAL:
      return cleanDecimalWithSig(numberValue, sig, notDefined);

    case EFormatNumberType.PERCENTAGE:
      return cleanPercentageWithSig(numberValue / percentageDenominator, sig, notDefined);

    case EFormatNumberType.QUANTITY:
      const formattedQuantity = cleanNumberWithCommas(numberValue, notDefined);

      return formatNegative
        ? formatNegativeNumbertoParenthesis(formattedQuantity)
        : formattedQuantity;
  }
};

export const roundNumber = (number: number | string, decimalPlaces: number = 1): number => {
  if (isNullOrUndefined(number)) {
    return null;
  }

  number = typeof number === 'string' ? parseFloat(number) : number;

  if (!isNaN(number)) {
    const mult = 10 ** decimalPlaces;
    return Math.round(number * mult) / mult;
  }

  return null;
};

export const convertStringNumber = (number: number | string, notDefined: any = ''): number => {
  if (isNullOrUndefined(number)) return notDefined;

  let convertedNumber = number;

  if (typeof convertedNumber === 'string') {
    convertedNumber = parseFloat(convertedNumber.replace(/,/g, ''));
  }

  return convertedNumber;
};

export const cleanDateTimeModified = (obj: { dateTimeModified?: Date }): void => {
  if (!obj || !obj.dateTimeModified) {
    return;
  }

  const { dateTimeModified } = obj;

  const ms = new Date(dateTimeModified).getTime();
  obj.dateTimeModified = new Date(roundNumber(ms, -3));
};

export const convertDateToYYYYMM = (date: string | Date): string => {
  const dateParts = getDateParts(date);

  if (!isNullOrUndefined(dateParts)) {
    return `${dateParts[0]}${dateParts[1]}`;
  }

  return null;
};
