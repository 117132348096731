import { TagColor } from 'shared/components/static-tag/tag-color.enum';

export enum IImbalanceStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  WITHDRAWN = 'Withdrawn',
  REJECTED = 'Rejected',
}

export enum IImbalanceStatusCode {
  DRAFT = 1,
  PENDING = 2,
  ACCEPTED = 3,
  WITHDRAWN = 4,
  REJECTED = 5,
}

export enum IImbalanceTradeDirection {
  TO_INIT_TRADER = 1,
  FROM_INIT_TRADER = 2,
}

export enum IImbalanceOIA {
  SYSTEM_WIDE = 1,
}

export const imbalanceTradeColourMap = {
  [IImbalanceStatusCode.DRAFT]: TagColor.BLUE,
  [IImbalanceStatusCode.PENDING]: TagColor.YELLOW,
  [IImbalanceStatusCode.ACCEPTED]: TagColor.GREEN,
  [IImbalanceStatusCode.WITHDRAWN]: TagColor.GRAY,
  [IImbalanceStatusCode.REJECTED]: TagColor.RED,
};

export const ImbalanceStatusCollection = [
  { code: IImbalanceStatusCode.DRAFT, text: IImbalanceStatus.DRAFT, color: TagColor.BLUE },
  { code: IImbalanceStatusCode.PENDING, text: IImbalanceStatus.PENDING, color: TagColor.YELLOW },
  { code: IImbalanceStatusCode.ACCEPTED, text: IImbalanceStatus.ACCEPTED, color: TagColor.GREEN },
  { code: IImbalanceStatusCode.WITHDRAWN, text: IImbalanceStatus.WITHDRAWN, color: TagColor.GRAY },
  { code: IImbalanceStatusCode.REJECTED, text: IImbalanceStatus.REJECTED, color: TagColor.RED },
];

export const ImbalanceTradeDirections = [
  { id: IImbalanceTradeDirection.TO_INIT_TRADER, name: 'TO Init Trdr' },
  { id: IImbalanceTradeDirection.FROM_INIT_TRADER, name: 'FROM Init Trdr' },
];

export enum ImbalanceType {
  DUE_FROM_SERVICE_REQUESTER = 1,
  DUE_TO_SERVICE_REQUESTER = 2,  
}

export const ImbalanceTypes = [
  { id: ImbalanceType.DUE_FROM_SERVICE_REQUESTER, name: 'Due FROM Service Requester' },
  { id: ImbalanceType.DUE_TO_SERVICE_REQUESTER, name: 'Due TO Service Requester' },
];

export const ImbalanceOperationalImpactArea = [
  { id: IImbalanceOIA.SYSTEM_WIDE, name: 'System Wide' },
];

export enum EImbalanceSignoffActions {
  ACCEPT = 'Accept',
  REJECT = 'Reject',
}

export enum EImbalanceTradeErrorCodes {
  PL_POSITION_DIR = 1,
  CUR_IMB_QTY = 2,
  INACTIVE_TRADE_WINDOW = 3,
}

export enum EImbalanceTraderType {
  INITIATING = 'I',
  CONFIRMING = 'C',
}

export enum EImbalanceTspAttribute {
  IN_KIND_PERCENT = 1,
}

export const PermissionOptions = {
  ReadOnly: 'ReadOnly',
  Edit: 'Edit',
  Admin: 'Admin',
};
