import { Injectable } from '@angular/core';
import { Roles } from '@gms/user-api';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/app/app.state';
import { selectAuthResources, selectIsInternal, selectRoles } from 'app/store/auth/auth.selectors';
import { hasPermission } from 'app/store/auth/auth.utils';
import {
  AccessLevels,
  AuthResources,
  EPermissionOption,
  EUserType,
  NavConfigAcl,
} from 'app/store/auth/model/models';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { hasAllNullOrUndefinedValues } from 'shared/utils/object.utils';

export type RoleWithPermission = string;

export enum ERoleUserType_DEPRECATED {
  Internal = 'internal',
  External = 'external',
}

export type Role_DEPRECATED = Roles | ERoleUserType_DEPRECATED;

export function read(role: Role_DEPRECATED): RoleWithPermission {
  return `${role}.read`;
}

export function readAll(...roleEndpoints: Role_DEPRECATED[]): RoleWithPermission[] {
  return roleEndpoints.map(endpoint => read(endpoint));
}

export function write(roleEndpoint: Role_DEPRECATED): RoleWithPermission {
  return `${roleEndpoint}.write`;
}

export function writeAll(...roleEndpoints: Role_DEPRECATED[]): RoleWithPermission[] {
  return roleEndpoints.map(endpoint => write(endpoint));
}

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  //ToDo: figure out types
  private _userRoles$: Observable<any /*RoleWithPermission[]*/> = this._store.pipe(
    select(selectRoles),
    filter(Boolean)
  );

  _authResources$ = this._store.pipe(select(selectAuthResources));
  _isInternal$ = this._store.pipe(select(selectIsInternal));

  accessibleTspIds$ = this._authResources$.pipe(
    map(authResources => {
      const tsps = new Set<number>();
      Object.keys(authResources).forEach(resource => {
        authResources[resource].tspAccess
          .map(tspAccess => tspAccess.tspId)
          .forEach(tspId => tsps.add(tspId));
      });

      return Array.from(tsps);
    }),
    shareReplay(1) //making it hot so we don't need to re-run the map for every subscription
  );

  private _userAuthState = combineLatest(this._authResources$, this._isInternal$);

  constructor(private _store: Store<IAppState>) {}

  /* Provided in case you need access to the raw roles to do more complicated logic */
  roles(): Observable<RoleWithPermission[]> {
    return this._userRoles$;
  }

  /*
    This function can take multiple roles if permission can be granted through multiple conditions.
    Each param passed in will be ORed with the others.
    If an array is passed, it's contents will be ANDed.

    Example:
      hasRole('G1', ['G2', 'G3'])
    Is equivalent to:
      G1 or (G2 and G3)
   */
  hasRoles(...roles: Array<RoleWithPermission | Array<RoleWithPermission>>): Observable<boolean> {
    return this._userRoles$.pipe(map(userRoles => this.checkRoles(userRoles, roles)));
  }

  hasRolesSnapshot(...roles: Array<RoleWithPermission | Array<RoleWithPermission>>): boolean {
    let userHasRoles = false;
    this.hasRoles(...roles)
      .pipe(take(1))
      .subscribe(g => (userHasRoles = g));
    return userHasRoles;
  }

  /**
   * Checks if the user has the required access level for at least one of the provided
   * tsps. If null is provided for tspIds, check for any tsp.
   * @param requestedResourceAcls an array of required resource access
   * @param tspIds an array of tsp ids
   */
  hasResourceAccess(
    requestedResourceAcls: Array<NavConfigAcl>,
    tspIds: Array<number> = null
  ): Observable<boolean> {
    return this._userAuthState.pipe(
      take(1),
      map(([authResources, isInternal]) =>
        requestedResourceAcls.some(requestedResourceAcl =>
          this.checkResourceAccess(authResources, isInternal, requestedResourceAcl, tspIds)
        )
      )
    );
  }

  checkResourceAccess(
    authResources: AuthResources,
    isInternal: boolean,
    requestedResourceAcl: NavConfigAcl,
    tspIds: Array<number> = null
  ): boolean {
    const userType = isInternal ? EUserType.Internal : EUserType.External;

    // Check if the resource is defined
    if (!requestedResourceAcl || hasAllNullOrUndefinedValues(requestedResourceAcl)) {
      console.warn('RESOURCE ACL NOT DEFINED');
      return false;
    }

    // If the ACL is configured incorrectly
    if (!requestedResourceAcl.resource && requestedResourceAcl.accessLevel) {
      throw Error('RESOURCE ACL MISSING RESOURCE');
    }

    // If the user type is requested, check if user is the correct user type
    if (requestedResourceAcl.userType && requestedResourceAcl.userType !== userType) {
      return false;
    }

    if (!requestedResourceAcl.resource) {
      // If the resource isn't defined, then there isn't anything else to check
      return true;
    } else {
      const requiredAccessLevel = requestedResourceAcl.accessLevel || EPermissionOption.ReadOnly;

      // If the user's resources aren't defined or the user doesn't have the required resource
      if (!authResources || !authResources[requestedResourceAcl.resource]) {
        // User does not have any access to the requested resource
        return false;
      }

      const { tspAccess } = authResources[requestedResourceAcl.resource];
      let tspAccessToCheck = tspAccess;

      // If tspIds are provided, filter available user's tsp access by the requested tspIds
      if (tspIds && tspIds.length > 0) {
        tspAccessToCheck = tspIds
          .map(tspId => tspAccess.find(t => t.tspId === tspId)) // Only expect one matching tspAccess
          .filter(Boolean);
      }

      // Check if user's access level has permission to the resource
      return tspAccessToCheck
        .map(tsp => AccessLevels[tsp.access])
        .filter(this.isValidAccessLevel)
        .some(accessLevel => hasPermission(accessLevel, requiredAccessLevel));
    }
  }

  private isValidAccessLevel(accessLevel) {
    // If access level found on user's associated resource does not match any defined
    if (!accessLevel || typeof accessLevel === 'undefined') {
      console.warn('INVALID ACCESS LEVEL ON RESOURCE');
      return false;
    }

    return true;
  }

  private checkRoles(
    userRoles: RoleWithPermission[],
    requestedRoles: Array<RoleWithPermission | Array<RoleWithPermission>>
  ): boolean {
    return requestedRoles
      .map(role => {
        if (role instanceof Array) {
          if (role.length) {
            return role.every((condition: string) => userRoles.includes(condition));
          } else {
            return false;
          }
        } else {
          return userRoles.includes(role);
        }
      })
      .some((condition: boolean) => condition);
  }

  getEPermissionOption(aclResource, tspId?: number): Observable<EPermissionOption> {
    return this._authResources$.pipe(
      map(authResources => {
        if (!authResources || !authResources[aclResource]) {
          console.warn('INVALID AUTHRESOURCE');
          return null;
        }
        // If tspId is not provided, return true if the user has the required access level for any tsp
        if (tspId) {
          const { tspAccess } = authResources[aclResource];
          const tsp = tspAccess.find(t => t.tspId === tspId);
          if (tsp) {
            return AccessLevels[tsp.access];
          } else {
            // User is not associated with this tsp and resource
            return null;
          }
        } else {
          return AccessLevels[authResources[aclResource].highestPrivilege];
        }
      })
    );
  }

  getAvailableTsps(): Observable<number[]> {
    return this._authResources$.pipe(
      map(authResources => {
        const tsps = new Set<number>();
        Object.keys(authResources).forEach(resource => {
          authResources[resource].tspAccess
            .map(tspAccess => tspAccess.tspId)
            .forEach(tspId => tsps.add(tspId));
        });

        return Array.from(tsps);
      })
    );
  }
}
