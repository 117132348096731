import {
  transformJobConfig,
  transformJobConfigs,
  transformJobInstance,
  transformJobInstancesDates,
} from 'app/store/jobs/jobs.reducers.utils';
import { createErrorState, createLoadingState, createSuccessState } from '../app/app.models';
import { EJobsActions, JobsActions } from './jobs.actions';
import { IJobsState, initialJobsState } from './jobs.state';

export function jobsReducers(state = initialJobsState, action: JobsActions): IJobsState {
  switch (action.type) {
    case EJobsActions.UPDATE_JOB_INSTANCE:
      return {
        ...state,
        updateJobInstance: {
          httpError: null,
          loading: true,
        },
      };
    case EJobsActions.UPDATE_JOB_INSTANCE_SUCCESS:
      return {
        ...state,
        updateJobInstance: {
          httpError: null,
          loading: false,
        },
      };
    case EJobsActions.UPDATE_JOB_INSTANCE_ERROR:
      return {
        ...state,
        updateJobInstance: {
          httpError: action.payload,
          loading: false,
        },
      };
    case EJobsActions.CLEAR_JOB_INSTANCE:
      return {
        ...state,
        jobInstance: {
          ...state.jobInstance,
          data: null,
        },
      };
    case EJobsActions.FETCH_JOB_INSTANCE:
      return {
        ...state,
        jobInstance: createLoadingState(state.jobInstance),
      };
    case EJobsActions.FETCH_JOB_INSTANCE_SUCCESS:
      return {
        ...state,
        jobInstance: createSuccessState(action.payload, transformJobInstance),
      };
    case EJobsActions.FETCH_JOB_INSTANCE_ERROR:
      return {
        ...state,
        jobInstance: createErrorState(action.payload),
      };
    case EJobsActions.FETCH_JOB_INSTANCES:
      return {
        ...state,
        jobInstances: createLoadingState(state.jobInstances),
      };
    case EJobsActions.FETCH_JOB_INSTANCES_SUCCESS:
      return {
        ...state,
        jobInstances: createSuccessState(
          action.payload.jobInstanceCollection,
          transformJobInstancesDates
        ),
      };
    case EJobsActions.FETCH_JOB_INSTANCES_ERROR:
      return {
        ...state,
        jobInstances: createErrorState(action.payload.error),
      };
    case EJobsActions.FETCH_JOB_NAMES:
      return {
        ...state,
        jobNames: createLoadingState(state.jobNames),
      };
    case EJobsActions.FETCH_JOB_NAMES_SUCCESS:
      return {
        ...state,
        jobNames: createSuccessState(action.payload.jobNameCollection),
      };
    case EJobsActions.FETCH_JOB_NAMES_ERROR:
      return {
        ...state,
        jobNames: createErrorState(action.payload.error),
      };
    case EJobsActions.FETCH_JOB_STATUSES:
      return {
        ...state,
        jobStatuses: createLoadingState(state.jobStatuses),
      };
    case EJobsActions.FETCH_JOB_STATUSES_SUCCESS:
      return {
        ...state,
        jobStatuses: createSuccessState(action.payload.jobStatusCollection),
      };
    case EJobsActions.FETCH_JOB_STATUSES_ERROR:
      return {
        ...state,
        jobStatuses: createErrorState(action.payload.error),
      };
    case EJobsActions.FETCH_JOB_APIS:
      return {
        ...state,
        jobApis: createLoadingState(state.jobApis),
      };
    case EJobsActions.FETCH_JOB_APIS_SUCCESS:
      return {
        ...state,
        jobApis: createSuccessState(action.payload),
      };
    case EJobsActions.FETCH_JOB_APIS_ERROR:
      return {
        ...state,
        jobApis: createErrorState(action.payload),
      };

    // Job Configs
    case EJobsActions.CREATE_JOB_CONFIG:
      return {
        ...state,
        createJobConfig: {
          httpError: null,
          loading: true,
        },
      };
    case EJobsActions.CREATE_JOB_CONFIG_SUCCESS:
      return {
        ...state,
        createJobConfig: {
          httpError: null,
          loading: false,
        },
      };
    case EJobsActions.CREATE_JOB_CONFIG_ERROR:
      return {
        ...state,
        createJobConfig: {
          httpError: action.payload,
          loading: false,
        },
      };
    case EJobsActions.UPDATE_JOB_CONFIG:
      return {
        ...state,
        updateJobConfig: {
          httpError: null,
          loading: true,
        },
      };
    case EJobsActions.UPDATE_JOB_CONFIG_SUCCESS:
      return {
        ...state,
        updateJobConfig: {
          httpError: null,
          loading: false,
        },
      };
    case EJobsActions.UPDATE_JOB_CONFIG_ERROR:
      return {
        ...state,
        updateJobConfig: {
          httpError: action.payload,
          loading: false,
        },
      };
    case EJobsActions.CLEAR_JOB_CONFIG:
      return {
        ...state,
        jobConfig: {
          ...state.jobConfig,
          data: {},
        },
      };
    case EJobsActions.FETCH_JOB_CONFIG:
      return {
        ...state,
        jobConfig: createLoadingState(state.jobConfig),
      };
    case EJobsActions.FETCH_JOB_CONFIG_SUCCESS:
      return {
        ...state,
        jobConfig: createSuccessState(action.payload, transformJobConfig),
      };
    case EJobsActions.FETCH_JOB_CONFIG_ERROR:
      return {
        ...state,
        jobConfig: createErrorState(action.payload),
      };
    case EJobsActions.FETCH_JOB_CONFIGS:
      return {
        ...state,
        jobConfigs: createLoadingState(state.jobConfigs),
      };
    case EJobsActions.FETCH_JOB_CONFIGS_SUCCESS:
      return {
        ...state,
        jobConfigs: createSuccessState(action.payload.jobConfigCollection, transformJobConfigs),
      };
    case EJobsActions.FETCH_JOB_CONFIGS_ERROR:
      return {
        ...state,
        jobConfigs: createErrorState(action.payload.error),
      };
    case EJobsActions.DELETE_JOB_CONFIG:
      return {
        ...state,
        deleteJobConfig: {
          httpError: null,
          loading: true,
        },
      };
    case EJobsActions.DELETE_JOB_CONFIG_SUCCESS:
      return {
        ...state,
        deleteJobConfig: {
          httpError: null,
          loading: false,
        },
      };
    case EJobsActions.DELETE_JOB_CONFIG_ERROR:
      return {
        ...state,
        deleteJobConfig: {
          httpError: action.payload,
          loading: false,
        },
      };
    case EJobsActions.RERUN_JOB_CONFIG:
      return {
        ...state,
        rerunJobConfig: {
          httpError: null,
          loading: true,
        },
      };
    case EJobsActions.RERUN_JOB_CONFIG_SUCCESS:
      return {
        ...state,
        rerunJobConfig: {
          httpError: null,
          loading: false,
        },
      };
    case EJobsActions.RERUN_JOB_CONFIG_ERROR:
      return {
        ...state,
        rerunJobConfig: {
          httpError: action.payload,
          loading: false,
        },
      };
    case EJobsActions.CLEAR_RERUN_JOB_CONFIG_STATUS:
      return {
        ...state,
        rerunJobConfig: {
          httpError: null,
          loading: false,
        },
      };
    case EJobsActions.UPDATE_JOB_CONFIG_FILTERS:
      return {
        ...state,
        jobConfigFilters: {
          ...action.payload,
        },
      };
    case EJobsActions.UPDATE_JOB_LOG_FILTERS:
      return {
        ...state,
        jobLogFilters: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
