import { Injectable } from '@angular/core';
import {
  PrimaryPoint,
  RequestStatusCollection,
  Route,
  ServiceRequest,
  ServiceRequestCollection,
  ServiceRequestService,
} from '@gms/servicerequest-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sanitizeServiceRequest } from 'app/store/service-requests/service-requests.utils';
import { asyncScheduler, of } from 'rxjs';
import {
  auditTime,
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap,
  throttle,
  throttleTime,
} from 'rxjs/operators';
import { HttpCodes } from 'shared/consts/http-codes.const';
import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { DEBOUNCE_500 } from '../../../shared/consts/debounce.const';
import {
  DeleteServiceRequest,
  DeleteServiceRequestError,
  DeleteServiceRequestSuccess,
  EServiceRequestActions,
  GetRequestStatuses,
  GetRequestStatusesFailure,
  GetRequestStatusesSuccess,
  GetServiceRequestById,
  GetServiceRequests,
  GetServiceRequestsError,
  GetServiceRequestsSuccess,
  SaveServiceRequest,
  SaveServiceRequestError,
  SaveServiceRequestSuccess,
  SetServiceRequestSort,
  UpdateEditingServiceRequest,
  UpdateServiceRequest,
  UpdateServiceRequestError,
  UpdateServiceRequestSuccess,
  UpdateServiceRequestValidationError,
} from './service-requests.actions';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestsEffect {
  constructor(private actions$: Actions, private serviceRequestService: ServiceRequestService) {}

  getServiceRequests$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this.actions$.pipe(
      ofType<GetServiceRequests>(EServiceRequestActions.GetServiceRequests),
      map((action: GetServiceRequests) => action.payload),
      auditTime(debounce, scheduler),
      switchMap(payload => {
        let sortQuery = ``;
        payload.sortDescriptors.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
        return this.serviceRequestService
          .getServiceRequest(
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.entityId,
            payload.tspId,
            payload.requestId,
            payload.requestStatus,
            payload.requestStatusIds,
            payload.rateScheduleId,
            payload.lastUpdateBegin,
            !isNullOrUndefined(payload.primaryTermEndDate)
              ? dateUtils.getDateAsYYYY_MM_DD(payload.primaryTermEndDate)
              : null,
            payload.lastUpdateEnd,
            null,
            null,
            payload.assignedToId
          )
          .pipe(
            switchMap((serviceRequest: ServiceRequestCollection) =>
              of(
                new GetServiceRequestsSuccess({
                  serviceRequests: serviceRequest.serviceRequests,
                  totalServiceRequestsCount: serviceRequest.total,
                })
              )
            ),
            catchError(error => of(new GetServiceRequestsError(error)))
          );
      })
    )
  );

  getServiceRequestById$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceRequestById>(EServiceRequestActions.GetServiceRequestById),
      switchMap(action => {
        const requestId = action.payload;
        return this.serviceRequestService.getServiceRequestById(requestId).pipe(
          switchMap((serviceRequest: ServiceRequest) =>
            of(new UpdateEditingServiceRequest(serviceRequest))
          ),
          catchError(error => of(new GetServiceRequestsError(error)))
        );
      })
    )
  );

  saveServiceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveServiceRequest>(EServiceRequestActions.SaveServiceRequest),
      debounceTime(500),
      switchMap(body => {
        sanitizeServiceRequest(body.payload);
        const bodyPayload = body.payload;
        const prefer = <'Lenient' | 'Strict'>body.prefer;
        return this.serviceRequestService.addServiceRequest(bodyPayload, prefer).pipe(
          mergeMap((serviceRequest: ServiceRequest) => {
            return of(new SaveServiceRequestSuccess(serviceRequest));
          }),
          catchError(error => {
            return of(new SaveServiceRequestError(error.error));
          })
        );
      })
    )
  );

  updateSaveServiceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceRequest>(EServiceRequestActions.UpdateServiceRequest),
      switchMap(body => {
        sanitizeServiceRequest(body.payload);
        const bodyPayload = body.payload;
        return this.serviceRequestService
          .updateServiceRequest(bodyPayload, body.id, <'Lenient' | 'Strict'>body.prefer)
          .pipe(
            mergeMap((serviceRequest: ServiceRequest) => {
              return of(new UpdateServiceRequestSuccess(serviceRequest));
            }),
            catchError(error => {
              return error.status === HttpCodes.VALIDATION
                ? of(new UpdateServiceRequestValidationError(error.error))
                : of(new UpdateServiceRequestError(error.error));
            })
          );
      })
    )
  );

  setSortOrder$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this.actions$.pipe(
      ofType<SetServiceRequestSort>(EServiceRequestActions.SetSortOrder),
      auditTime(debounce),
      switchMap(x => {
        const sortOrder = x.payload[0];
        const sortQuery = `${sortOrder.field}+${sortOrder.dir}|`;
        return this.serviceRequestService.getServiceRequest(5, 1, [sortQuery]).pipe(
          switchMap((serviceRequest: ServiceRequestCollection) =>
            of(
              new GetServiceRequestsSuccess({
                serviceRequests: serviceRequest.serviceRequests,
                totalServiceRequestsCount: serviceRequest.total,
              })
            )
          ),
          catchError(error => of(new GetServiceRequestsError(error)))
        );
      })
    )
  );

  deleteServiceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceRequest>(EServiceRequestActions.DeleteServiceRequest),
      switchMap(body => {
        return this.serviceRequestService.deleteServiceRequest(body.payload.serviceRequestId).pipe(
          mergeMap((serviceRequest: ServiceRequest) => {
            return of(new DeleteServiceRequestSuccess(serviceRequest));
          }),
          catchError(error => {
            return of(new DeleteServiceRequestError(error));
          })
        );
      })
    )
  );

  getRequestStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetRequestStatuses>(EServiceRequestActions.GetRequestStatuses),
      switchMap(body => {
        const { payload } = body;
        return this.serviceRequestService.getRequestStatuses(payload.includeForCredit).pipe(
          mergeMap((requestStatuses: RequestStatusCollection) => {
            return of(new GetRequestStatusesSuccess(requestStatuses));
          }),
          catchError(error => {
            return of(new GetRequestStatusesFailure(error));
          })
        );
      })
    )
  );
}
