import { TagColor } from 'shared/components/static-tag/tag-color.enum';

export enum IOerationalImpactArea {
  SYSTEM_WIDE = 1,
}

export enum ITransferDirection {
  WITHDRAW = 'W',
  INJECTION = 'I',
}

export const OperationalImpactArea = [
  { id: IOerationalImpactArea.SYSTEM_WIDE, name: 'System Wide' },
];

export const TransferDirection = [
  { id: ITransferDirection.WITHDRAW, name: 'Withdraw' },
  { id: ITransferDirection.INJECTION, name: 'Injection' },
];

export enum IStatusCode {
  PENDING = 2,
  ACCEPTED = 3,
  WITHDRAWN = 4,
  REJECTED = 5,
}

export enum IStatusName {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  WITHDRAWN = 'Withdrawn',
  REJECTED = 'Rejected',
}

export const StatusCollection = [
  { code: IStatusCode.PENDING, text: IStatusName.PENDING, color: TagColor.YELLOW },
  { code: IStatusCode.ACCEPTED, text: IStatusName.ACCEPTED, color: TagColor.GREEN },
  { code: IStatusCode.WITHDRAWN, text: IStatusName.WITHDRAWN, color: TagColor.GRAY },
  { code: IStatusCode.REJECTED, text: IStatusName.REJECTED, color: TagColor.RED },
];

export enum EStorageTransferActions {
  SUBMIT = 'submit',
  ACCEPT = 'accept',
  REJECT = 'reject',
  PENDING = 'pending',
}

export const STORAGE_TRANSFER_RESOURCE = '/storage/storage-transfer';
