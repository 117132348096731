import {
  MergerAssignment,
  MergerAssignmentCollection,
  MergerAssignmentInput,
} from '@gms/entity-api';
import { emptyRequestStatus, IDataState, IRequestStatus } from './../app/app.models';

export interface IMergerAssignmentState {
  searchedMergerAssignments: MergerAssignmentCollection;
  mergerAssignment: MergerAssignment;
  mergerAssignmentInput: IDataState<MergerAssignmentInput>;
  status: {
    mergerAssignment: IRequestStatus<any>;
    searchedMergerAssignments: IRequestStatus<any>;
  };
  mergerAssignmentCreated: boolean;
}

export const initialMergerAssignmentCollection = {
  mergerAssignments: [],
  total: 0,
};

export const initialMergerAssignmentInput: IDataState = {
  data: {
    mergerAssignment: null,
    contractIds: null,
    terminatePreTransactionEntityFlag: null,
  },
  requestStatus: { ...emptyRequestStatus },
};

export const initialMergerAssignmentState: IMergerAssignmentState = {
  searchedMergerAssignments: initialMergerAssignmentCollection,
  mergerAssignment: null,
  mergerAssignmentInput: initialMergerAssignmentInput,
  mergerAssignmentCreated: false,

  status: {
    mergerAssignment: { httpError: null, loading: false },
    searchedMergerAssignments: { httpError: null, loading: false },
  },
};
