import { Injectable } from '@angular/core';
import { WebsocketEvent } from '@gms/websocket-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EWebsocketActions, SendWebsocketMessage } from 'app/store/websockets/websockets.actions';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WebsocketService } from 'shared/services/websocket.service';

@Injectable()
export class WebsocketsEffects {
  constructor(private _actions$: Actions, private _websocketService: WebsocketService) {}

  sendWebsocketMessage$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SendWebsocketMessage>(EWebsocketActions.SEND_WEBSOCKET_MESSAGE),
      map((action: SendWebsocketMessage) => action.payload),
      switchMap((payload: WebsocketEvent) => {
        this._websocketService.sendMessage(payload);
        return new Observable<any>(null);
      })
    )
  );
}
