import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AccountingMarketSectorOverridesService } from '@gms/accounting-api';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  DeleteMarketSectorOverride,
  DeleteMarketSectorOverrideFailure,
  DeleteMarketSectorOverrideSuccess,
  EMarketSectorOverridesActions,
  GetMarketSectorOverridesCollection,
  GetMarketSectorOverridesCollectionFailure,
  GetMarketSectorOverridesCollectionSuccess,
  UpdateMarketSectorOverrides,
  UpdateMarketSectorOverridesFailure,
  UpdateMarketSectorOverridesSuccess,
} from './market-sector-overrides.actions';

@Injectable({ providedIn: 'root' })
export class MarketSectorOverridesEffects {
  constructor(
    private _actions$: Actions,
    private _marketSectorOverridesService: AccountingMarketSectorOverridesService
  ) {}

  getMarketSectorOverridesCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetMarketSectorOverridesCollection>(
        EMarketSectorOverridesActions.FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION
      ),
      map((action: GetMarketSectorOverridesCollection) => action),
      switchMap(action => {
        const { tspId, entityNameId, locationNameId, pageSize, pageNumber } = action.payload;
        return this._marketSectorOverridesService
          .getMarketSectorOverrides(tspId, entityNameId, locationNameId, pageNumber, pageSize)
          .pipe(
            map(response => new GetMarketSectorOverridesCollectionSuccess(response)),
            catchError(error => of(new GetMarketSectorOverridesCollectionFailure(error)))
          );
      })
    )
  );

  updateMarketSectorOverrides$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateMarketSectorOverrides>(
        EMarketSectorOverridesActions.UPDATE_MARKET_SECTOR_OVERRIDES
      ),
      map((action: UpdateMarketSectorOverrides) => action),
      switchMap(action => {
        return this._marketSectorOverridesService.addMarketSectorOverrides(action.payload).pipe(
          map(response => new UpdateMarketSectorOverridesSuccess(response)),
          catchError(error => of(new UpdateMarketSectorOverridesFailure(error)))
        );
      })
    )
  );

  deleteMarketSectorOverride$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DeleteMarketSectorOverride>(
        EMarketSectorOverridesActions.DELETE_MARKET_SECTOR_OVERRIDE
      ),
      map((action: DeleteMarketSectorOverride) => action),
      switchMap(action => {
        return this._marketSectorOverridesService.deleteMarketSectorOverride(action.id).pipe(
          map(() => new DeleteMarketSectorOverrideSuccess()),
          catchError(error => of(new DeleteMarketSectorOverrideFailure(error)))
        );
      })
    )
  );
}
