import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { ETagType } from 'shared/components/static-tag/tag-type-enum';

@Component({
  selector: 'app-static-tag',
  templateUrl: './static-tag.component.html',
  styleUrls: ['./static-tag.component.scss'],
})
export class StaticTagComponent implements OnChanges {
  @Input()
  color: TagColor;

  @Input()
  type: ETagType = ETagType.PRIMARY;
  tagClasses: string[] = [];

  setTagClasses(color: TagColor, type: ETagType): void {
    const tagClasses = [];

    if (color) {
      tagClasses.push(color);
    }
    if (type) {
      tagClasses.push(type);
    }

    this.tagClasses = tagClasses;
  }

  ngOnChanges(changes: SimpleChanges) {
    const color: TagColor = changes.color ? changes.color.currentValue : this.color;
    const type: ETagType = changes.type ? changes.type.currentValue : this.type;
    this.setTagClasses(color, type);
  }
}
