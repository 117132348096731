import { SortDescriptor } from '@progress/kendo-data-query';
import { getPagination, IPagination } from 'shared/models/pagination.model';

import {
  Allocation,
  AllocationAdjustmentType,
  AllocationDetail,
  AllocationMethod,
  PredeterminedAllocation,
  PredeterminedAllocationDetailCollection,
  PredeterminedAllocationStatus,
  StatementBasis,
} from '@gms/allocation-api';
import { IRequestStatus } from '../app/app.models';

export interface IAllocationsState {
  allocations: Allocation[];
  totalAllocationsCount: number;
  exportAllocationDetails: IRequestStatus<any>;
  sortDescriptors: SortDescriptor[];
  pagination: IPagination;

  error: Error | null;
  loading: boolean;

  operatorId: number;

  allocationDetails: AllocationDetail[];
  totalAllocationDetailsCount: number;
  allocationDetailsLoading: boolean;
  allocationDetailsSortDescriptors: SortDescriptor[];
  allocationDetailsPagination: IPagination;
  allocationDetailsError: Error | null;

  allocationMethods: Array<AllocationMethod>;
  allocationMethodLoading: boolean;
  allocationMethodError: Error | null;

  createPdaLoading: boolean;
  createPdaError: Error | null;

  pdasCollection: Array<PredeterminedAllocation>;
  pdasCollectionTotal: number;
  pdasLoading: boolean;
  pdasError: Error | null;
  pdasSortDescriptors: SortDescriptor[];
  pdasPagination: IPagination;

  pdaDetailsCollection: PredeterminedAllocationDetailCollection;
  pdaDetailsLoading: boolean;
  pdaDetailsError: Error | null;

  addPdaDetailsLoading: boolean;
  addPdaDetailsError: Error | null;

  allocationFilterValues: {
    allocationRecipientName: any;
    allocationBeginDate: Date;
  };

  pdaById: PredeterminedAllocation | null;
  pdaByIdLoading: boolean;
  pdaByIdError: Error | null;

  incompletePdas: Array<PredeterminedAllocationStatus>;
  incompletePdasTotal: number;
  incompletePdasLoading: boolean;
  incompletePdasError: Error | null;

  missingPdas: Array<PredeterminedAllocationStatus>;
  missingPdasTotal: number;
  missingPdasLoading: boolean;
  missingPdasError: Error | null;

  expiredPdas: Array<PredeterminedAllocationStatus>;
  expiredPdasTotal: number;
  expiredPdasLoading: boolean;
  expiredPdasError: Error | null;
  createPDALocationParam: {
    locationName: string;
    locationNumber: number;
    locationId: number;
    beginDate: Date;
    endDate: Date;
  };

  lookupCollection: {
    adjustmentTypes: Array<AllocationAdjustmentType>;
    statementBases: Array<StatementBasis>;
  };
  allocationLookupLoading: boolean;
  allocationLookupError: Error | null;
}

export const initialAllocationsState: IAllocationsState = {
  allocations: [],
  totalAllocationsCount: 0,
  exportAllocationDetails: { httpError: null, loading: false },
  sortDescriptors: [
    {
      field: 'dateTimeModified',
      dir: 'asc',
    },
  ],

  loading: false,
  error: null,

  pagination: getPagination(),

  operatorId: 0,

  allocationDetails: [],
  totalAllocationDetailsCount: 0,
  allocationDetailsLoading: false,
  allocationDetailsError: null,
  allocationDetailsSortDescriptors: [
    {
      field: 'dateTimeModified',
      dir: 'asc',
    },
  ],
  allocationDetailsPagination: getPagination(),

  allocationMethods: [],
  allocationMethodLoading: true,
  allocationMethodError: null,
  createPdaLoading: false,
  createPdaError: null,

  pdasCollection: [],
  pdasCollectionTotal: 0,
  pdasLoading: false,
  pdasError: null,
  pdasSortDescriptors: [
    {
      field: 'locationNumber',
      dir: 'asc',
    },
  ],
  pdasPagination: getPagination(),

  pdaById: null,
  pdaByIdLoading: false,
  pdaByIdError: null,

  pdaDetailsCollection: {},
  pdaDetailsLoading: false,
  pdaDetailsError: null,

  addPdaDetailsLoading: false,
  addPdaDetailsError: null,

  allocationFilterValues: {
    allocationRecipientName: null,
    allocationBeginDate: new Date(),
  },

  incompletePdas: null,
  incompletePdasTotal: 0,
  incompletePdasLoading: false,
  incompletePdasError: null,

  missingPdas: null,
  missingPdasTotal: 0,
  missingPdasLoading: false,
  missingPdasError: null,

  expiredPdas: null,
  expiredPdasTotal: 0,
  expiredPdasLoading: false,
  expiredPdasError: null,
  createPDALocationParam: null,

  lookupCollection: {
    adjustmentTypes: [],
    statementBases: [],
  },
  allocationLookupLoading: false,
  allocationLookupError: null,
};
