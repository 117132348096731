import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const IndexRate_Routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/index-rate/index-rate.module').then(m => m.IndexRateModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/index-rate',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () => import('./pages/index-rate/index-rate.module').then(m => m.IndexRateModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/index-rate',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/create-index-rate/create-index-rate.module').then(
        m => m.CreateIndexRateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/index-rate',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
];

export const ChargeTypes_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/charge-types-admin/charge-types-admin.module').then(
        m => m.ChargeTypesAdminModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/charge-types',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/master-charge-type/master-charge-type.module').then(
        m => m.MasterChargeTypeModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/charge-types',
      aclAccessLevel: EPermissionOption.Admin,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/charge-types-detail/charge-types-detail.module').then(
        m => m.ChargeTypesDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/charge-types',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/master-charge-type/master-charge-type.module').then(
        m => m.MasterChargeTypeModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/charge-types',
      aclAccessLevel: EPermissionOption.Admin,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/:tspId',
    loadChildren: () =>
      import('./pages/charge-types-detail/charge-types-detail.module').then(
        m => m.ChargeTypesDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/charge-types',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const TariffRates_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/tariff-rates/tariff-rates.module').then(m => m.TariffsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/tariff-rates',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/create-tariff-rate/create-tariff-rate.module').then(
        m => m.CreateTariffRateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/tariff-rates/new',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/tariff-rate-detail/tariff-rate-detail.module').then(
        m => m.TariffRateDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/tariff-rates',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/create-tariff-rate/create-tariff-rate.module').then(
        m => m.CreateTariffRateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/tariff-rates/{id}/edit',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/version',
    loadChildren: () =>
      import('./pages/create-tariff-rate/create-tariff-rate.module').then(
        m => m.CreateTariffRateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/tariff-rates/{id}/version',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
];

export const NonTariff_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/non-tariff-rates/non-tariff-rates.module').then(m => m.NonTariffRatesModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contractual-rates',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/create-non-tariff-rate/create-non-tariff-rate.module').then(
        m => m.CreateNonTariffRateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contractual-rates/new',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/contractual-detail/contractual-detail.module').then(
        m => m.NonTariffDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contractual-rates',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/create-non-tariff-rate/create-non-tariff-rate.module').then(
        m => m.CreateNonTariffRateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contractual-rates',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'rev-rec/:id',
    loadChildren: () =>
      import('./pages/contractual-detail/contractual-detail.module').then(
        m => m.NonTariffDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contractual-rates/rev-rec',
      aclAccessLevel: EPermissionOption.Admin,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'rev-rec/:id/edit',
    loadChildren: () =>
      import('./pages/create-non-tariff-rate/create-non-tariff-rate.module').then(
        m => m.CreateNonTariffRateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contractual-rates/rev-rec',
      aclAccessLevel: EPermissionOption.Admin,
      aclUserType: EUserType.Internal,
    },
  },
];
