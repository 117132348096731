import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { ContactInfoService, RolesService, User, UserService } from '@gms/user-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { filterIfCached } from '../app/app.models';
import { IAppState } from '../app/app.state';
import {
  CreateUser,
  CreateUserFailure,
  CreateUserSuccess,
  EUsersActions,
  FetchContactInfo,
  FetchContactInfoFailure,
  FetchContactInfoSearch,
  FetchContactInfoSearchFailure,
  FetchContactInfoSearchSuccess,
  FetchContactInfoSuccess,
  FetchContactLevels,
  FetchContactLevelsFailure,
  FetchContactLevelsSuccess,
  FetchRoles,
  FetchRolesFailure,
  FetchRolesPermissionOptions,
  FetchRolesPermissionOptionsFailure,
  FetchRolesPermissionOptionsSuccess,
  FetchRolesSuccess,
  FetchUserById,
  FetchUserByIdFailure,
  FetchUserByIdSuccess,
  FetchUserEmailTypes,
  FetchUserEmailTypesFailure,
  FetchUserEmailTypesSuccess,
  FetchUserPhoneNumberTypes,
  FetchUserPhoneNumberTypesFailure,
  FetchUserPhoneNumberTypesSuccess,
  FetchUsers,
  FetchUsersFailure,
  FetchUsersSuccess,
  SaveUser,
  SaveUserFailure,
  SaveUserSuccess,
  SearchUsers,
  SearchUsersFailure,
  SearchUsersSuccess,
  SelectUser,
  UpdateUser,
  UpdateUserFailure,
  UpdateUserProfile,
  UpdateUserProfileFailure,
  UpdateUserProfileSuccess,
  UpdateUserSuccess,
} from './users.actions';
import {
  selectContactLevelsState,
  selectEmailTypesState,
  selectPhoneNumberTypesState,
  selectRoles,
  selectRolesPermissionOptionsState,
} from './users.selectors';

@Injectable()
export class UsersEffects {
  constructor(
    private _actions$: Actions,
    private _userService: UserService,
    private _contactInfoService: ContactInfoService,
    private _rolesService: RolesService,
    private _store: Store<IAppState>
  ) {}

  // TODO: ask what default should be, sort seems to be wrong...
  FetchUsers: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchUsers>(EUsersActions.FetchUsers),
      map((action: FetchUsers) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors.length) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        return this._userService
          .getUsers(
            payload.pageSize,
            payload.pageNumber,
            payload.sortDescriptors.length ? [sortQuery] : undefined,
            payload.status ? payload.status : undefined,
            payload.email ? payload.email : undefined,
            payload.entityId ? payload.entityId : undefined,
            payload.entityIds ? payload.entityIds : undefined,
            payload.entityName ? payload.entityName : undefined,
            payload.firstName ? payload.firstName : undefined,
            payload.lastName ? payload.lastName : undefined,
            payload.tspId ? payload.tspId : undefined,
            payload.roleId ? payload.roleId : undefined,
            undefined,
            payload.userIds ? payload.userIds : undefined
          )
          .pipe(
            map(
              userCollection =>
                new FetchUsersSuccess({
                  users: userCollection.users,
                  totalUserCount: userCollection.total,
                })
            ),
            catchError(error => of(new FetchUsersFailure({ error: error })))
          );
      })
    )
  );

  FetchUserById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchUserById>(EUsersActions.FetchUserById),
      map((action: FetchUserById) => action.payload),
      switchMap(payload => {
        return this._userService.getUserById(payload.userId).pipe(
          map(
            user =>
              new FetchUserByIdSuccess({
                user: user,
              })
          ),
          catchError(error => of(new FetchUserByIdFailure({ error: error })))
        );
      })
    )
  );

  SaveUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveUser>(EUsersActions.SaveUser),
      switchMap(body => {
        const bodyPayload = body.payload.user;
        return this._userService.createUser(bodyPayload).pipe(
          map((user: User) => new SaveUserSuccess({ user: user })),
          catchError(error => of(new SaveUserFailure(new Error(error.status))))
        );
      })
    )
  );

  CreateUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateUser>(EUsersActions.CreateUser),
      switchMap(action => {
        const newUser: User = action.payload;
        return this._userService.createUser(newUser).pipe(
          switchMap((user: User) => [new SelectUser(user), new CreateUserSuccess()]),
          catchError(error => of(new CreateUserFailure(error)))
        );
      })
    )
  );

  UpdateUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateUser>(EUsersActions.UpdateUser),
      switchMap(action => {
        const updatedUser: User = action.payload;
        return this._userService.updateUser(updatedUser, updatedUser.userId).pipe(
          switchMap((user: User) => [new SelectUser(user), new UpdateUserSuccess()]),
          catchError(error => of(new UpdateUserFailure(error)))
        );
      })
    )
  );

  SearchUsers: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SearchUsers>(EUsersActions.SearchUsers),
      map((action: SearchUsers) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        payload.sortDescriptors.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
        return this._userService
          .getUsers(
            payload.pageSize,
            payload.pageNumber,
            payload.sortDescriptors.length ? [sortQuery] : undefined,
            payload.status ? payload.status : undefined,
            payload.email ? payload.email : undefined,
            payload.entityId ? payload.entityId : undefined,
            undefined,
            payload.entityName ? payload.entityName : undefined,
            payload.firstName ? payload.firstName : undefined,
            payload.lastName ? payload.lastName : undefined,
            payload.tspId ? payload.tspId : undefined,
            payload.roleId ? payload.roleId : undefined,
            payload.userSearch
          )
          .pipe(
            map(userCollection => new SearchUsersSuccess({ users: userCollection.users })),
            catchError(error => {
              return of(new SearchUsersFailure({ error: error }));
            })
          );
      })
    )
  );

  FetchContactInfo: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContactInfo>(EUsersActions.FetchContactInfo),
      map((action: FetchContactInfo) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        payload.sortBy.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
        return this._contactInfoService
          .getContactInfo(
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy.length ? [sortQuery] : undefined,
            payload.status ? payload.status : undefined,
            payload.entityId ? payload.entityId : undefined,
            payload.entityIds ? payload.entityIds : undefined,
            payload.firstName,
            payload.lastName,
            payload.tspId ? payload.tspId : undefined,
            payload.roleId ? payload.roleId : undefined,
            payload.roleIds ? payload.roleIds : undefined,
            payload.userSearch,
            payload.userIds ? payload.userIds : undefined,
            payload.isInternal,
            payload.agencyResourceName,
            payload.minPermission,
            payload.agencyTransactionStartDate,
            payload.agencyTransactionEndDate,
            payload.agencyAllowHistoricalView,
            payload.contactHasPermission
          )
          .pipe(
            map(userCollection => new FetchContactInfoSuccess({ users: userCollection.users })),
            catchError(error => {
              return of(new FetchContactInfoFailure({ error: error }));
            })
          );
      })
    )
  );

  FetchRoles: Observable<FetchRolesSuccess | FetchRolesFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRoles>(EUsersActions.FetchRoles),
      filterIfCached(this._store.pipe(select(selectRoles))),
      switchMap(_ =>
        this._rolesService.getRoles().pipe(
          map(response => new FetchRolesSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new FetchRolesFailure(error)))
        )
      )
    )
  );

  FetchRolesPermissionOptions: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRolesPermissionOptions>(EUsersActions.FetchRolesPermissionOptions),
      filterIfCached(this._store.pipe(select(selectRolesPermissionOptionsState))),
      switchMap(payload => {
        return this._rolesService.getRolesPermissionOptions().pipe(
          map(rolesPermissionOptions => {
            return new FetchRolesPermissionOptionsSuccess({ rolesPermissionOptions });
          }),
          catchError(error => {
            return of(new FetchRolesPermissionOptionsFailure({ error }));
          })
        );
      })
    )
  );

  FetchContactLevels: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContactLevels>(EUsersActions.FetchContactLevels),
      filterIfCached(this._store.pipe(select(selectContactLevelsState))),
      switchMap(payload => {
        return this._rolesService.getContactLevels().pipe(
          map(contactLevels => new FetchContactLevelsSuccess({ contactLevels })),
          catchError(error => {
            return of(new FetchContactLevelsFailure({ error }));
          })
        );
      })
    )
  );

  FetchUserPhoneNumberTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchUserPhoneNumberTypes>(EUsersActions.FetchUserPhoneNumberTypes),
      filterIfCached(this._store.pipe(select(selectPhoneNumberTypesState))),
      switchMap(payload => {
        return this._userService.getPhoneNumberTypes().pipe(
          map(phoneNumberTypes => new FetchUserPhoneNumberTypesSuccess({ phoneNumberTypes })),
          catchError(error => {
            return of(new FetchUserPhoneNumberTypesFailure({ error }));
          })
        );
      })
    )
  );

  FetchUserEmailTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchUserEmailTypes>(EUsersActions.FetchUserEmailTypes),
      filterIfCached(this._store.pipe(select(selectEmailTypesState))),
      switchMap(payload => {
        return this._userService.getEmailTypes().pipe(
          map(emailTypes => new FetchUserEmailTypesSuccess({ emailTypes })),
          catchError(error => {
            return of(new FetchUserEmailTypesFailure({ error }));
          })
        );
      })
    )
  );

  SaveUserProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateUserProfile>(EUsersActions.UpdateUserProfile),
      switchMap(body => {
        const bodyPayload = body.payload;
        return this._userService.updateUserProfile(bodyPayload).pipe(
          map((user: User) => new UpdateUserProfileSuccess(user)),
          catchError(error => of(new UpdateUserProfileFailure(new Error(error.status))))
        );
      })
    )
  );

  FetchContactInfoSearch: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContactInfoSearch>(EUsersActions.FetchContactInfoSearch),
      map((action: FetchContactInfoSearch) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        payload.sortBy.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
        return this._contactInfoService
          .getContactInfo(
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy.length ? [sortQuery] : undefined,
            payload.status ? payload.status : undefined,
            payload.entityId ? payload.entityId : undefined,
            payload.entityIds ? payload.entityIds : undefined,
            payload.firstName,
            payload.lastName,
            payload.tspId ? payload.tspId : undefined,
            payload.roleId ? payload.roleId : undefined,
            payload.roleIds ? payload.roleIds : undefined,
            payload.userSearch,
            payload.userIds ? payload.userIds : undefined,
            payload.isInternal,
            payload.agencyResourceName,
            payload.minPermission,
            payload.agencyTransactionStartDate,
            payload.agencyTransactionEndDate,
            payload.agencyAllowHistoricalView,
            payload.contactHasPermission
          )
          .pipe(
            map(
              userCollection => new FetchContactInfoSearchSuccess({ users: userCollection.users })
            ),
            catchError(error => {
              return of(new FetchContactInfoSearchFailure({ error: error }));
            })
          );
      })
    )
  );
}
