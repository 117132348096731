import {
  DictionaryCollections,
  Entity,
  EntityAddress,
  EntityClassificationOverride,
  EntityCreditExposure,
  EntityCreditExposureAddUpdatePayload,
  EntityCreditExposureSummary,
  EntityCreditLimit,
  EntityCreditLimitSummary,
  EntityHeader,
  EntityHeaderCollection,
  ParentEntity,
  ParentEntityCollection,
} from '@gms/entity-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  IEntityHeaderByIdPayload,
  IEntityHeaderPayload,
} from 'app/store/entities/entities.action.interface';
import {
  ECreditExposureGridType,
  IEntityCreditExposureGridSort,
} from 'app/store/entities/entities.models';
import { EPermissionOption } from '../auth/model/enums';
import { RetryableAction } from '../retry/retry.actions';

export enum EEntitiesActions {
  FetchEntitiyById = '[ENTITIES] Fetch Entity By ID',
  ClearEntity = '[ENTITIES] Clear Entity',
  FetchEntitiyByIdSuccess = '[ENTITIES] Fetch Entity By ID Success',
  FetchEntitiyByIdFailure = '[ENTITIES] Fetch Entity By ID Failure',
  FetchEntities = '[ENTITIES] Fetch Entities',
  FetchEntitiesSuccess = '[ENTITIES] Fetch Entities Success',
  FetchEntitiesFailure = '[ENTITIES] Fetch Entities Failure',
  FetchAllEntities = '[ENTITIES] Fetch All Entities',
  CLEAR_FILTERED_ENTITIES = '[ENTITIES] Clear Filtered Entities',
  GetEntityDictionary = '[ENTITIES] Get Entity Dictionary',
  GetEntityDictionarySuccess = '[ENTITIES] Get Entity Dictionary Success',
  GetEntityDictionaryError = '[ENTITIES] Get Entity Dictionary Error',
  ResetEntities = '[ENTITIES] Reset Entities',
  ResetEntitiesSuccess = '[ENTITIES] Reset Entities Success',
  ResetEntitiesError = '[ENTITIES] Reset Entities Error',
  SearchEntities = '[ENTITIES] Search Entities',
  SearchEntitiesSuccess = '[ENTITIES] Search Entities Success',
  SearchEntitiesFailure = '[ENTITIES] Search Entities Failure',
  ClearEntitiesSearch = '[ENTITIES] Clear Entities Search',
  ResetFilteredEntities = '[ENTITIES] Reset Filtered Entities',
  ResetEntity = '[ENTITIES] Reset Entity',
  UpdateEntity = '[ENTITIES] Update Entity',
  UpdateEntitySuccess = '[ENTITIES] Update Entity Success',
  UpdateEntityFailure = '[ENTITIES] Update Entity Failure',
  CreateEntity = '[ENTITIES] Create Entity',
  CreateEntitySuccess = '[ENTITIES] Create Entity Success',
  CreateEntityFailure = '[ENTITIES] Create Entity Failure',
  UpdateEntityClassOverrides = '[ENTITIES] Update Entity Class Override',
  UpdateEntityClassOverridesSuccess = '[ENTITIES] Update Entity Class Override Success',
  UpdateEntityClassOverridesFailure = '[ENTITIES] Update Entity Class Override Failure',
  FETCH_PARENT_ENTITIES_BY_ENTITY_ID = '[ENTITIES] Fetch Parent Entities By Entity ID',
  FETCH_PARENT_ENTITIES_BY_ENTITY_ID_SUCCESS = '[ENTITIES] Fetch Parent Entities By Entity ID Success',
  FETCH_PARENT_ENTITIES_BY_ENTITY_ID_FAILURE = '[ENTITIES] Fetch Parent Entities By Entity ID Failure',
  UPDATE_PARENT_ENTITIES_BY_ENTITY_ID = '[ENTITIES] Update Parent Entities By Entity ID',
  UPDATE_PARENT_ENTITIES_BY_ENTITY_ID_SUCCESS = '[ENTITIES] Update Parent Entities By Entity ID Success',
  UPDATE_PARENT_ENTITIES_BY_ENTITY_ID_FAILURE = '[ENTITIES] Update Parent Entities By Entity ID Failure',
  GET_CREDIT_LIMITS_BY_ENTITY_ID = '[ENTITIES] Get Credit Limits By Entity ID',
  GET_CREDIT_LIMITS_BY_ENTITY_ID_SUCCESS = '[ENTITIES] Get Credit Limits By Entity ID Success',
  GET_CREDIT_LIMITS_BY_ENTITY_ID_FAILURE = '[ENTITIES] Get Credit Limits By Entity ID Failure',
  ADD_CREDIT_LIMIT_BY_ENTITY_ID = '[ENTITIES] Add Credit Limit By Entity ID',
  CLEAR_ADD_CREDIT_LIMIT_BY_ENTITY_ID = '[ENTITIES] Clear Add Credit Limit By Entity ID',
  ADD_CREDIT_LIMIT_BY_ENTITY_ID_SUCCESS = '[ENTITIES] Add Credit Limit By Entity ID Success',
  ADD_CREDIT_LIMIT_BY_ENTITY_ID_FAILURE = '[ENTITIES] Add Credit Limit By Entity ID Failure',
  UPDATE_CREDIT_LIMITS_BY_ENTITY_ID = '[ENTITIES] Update Credit Limits By Entity ID',
  CLEAR_UPDATE_CREDIT_LIMITS_BY_ENTITY_ID = '[ENTITIES] Clear Update Credit Limits By Entity ID',
  UPDATE_CREDIT_LIMITS_BY_ENTITY_ID_SUCCESS = '[ENTITIES] Update Credit Limits By Entity ID Success',
  UPDATE_CREDIT_LIMITS_BY_ENTITY_ID_FAILURE = '[ENTITIES] Update Credit Limits By Entity ID Failure',
  FETCH_ALL_ENTITIES_BY_IDS = '[ENTITIES] Fetch All Entities By IDs',
  FETCH_ALL_ENTITIES_BY_IDS_SUCCESS = '[ENTITIES] Fetch All Entities By IDs Success',
  FETCH_ALL_ENTITIES_BY_IDS_FAILURE = '[ENTITIES] Fetch All Entities By IDs Failure',
  FETCH_ENTITY_HEADER_BY_ID = '[ENTITIES] Fetch Entity Header By ID',
  FETCH_ENTITY_HEADER_BY_ID_SUCCESS = '[ENTITIES] Fetch Entity Header By ID Success',
  FETCH_ENTITY_HEADER_BY_ID_FAILURE = '[ENTITIES] Fetch Entity Header By ID Failure',
  FETCH_ALL_ENTITY_HEADERS = '[ENTITIES] Fetch All Entity Headers',
  FETCH_ALL_ENTITY_HEADERS_SUCCESS = '[ENTITIES] Fetch All Entity Headers Success',
  FETCH_ALL_ENTITY_HEADERS_FAILURE = '[ENTITIES] Fetch All Entity Headers Failure',
  SEARCH_ENTITY_HEADERS = '[ENTITIES] Search Entity Headers',
  SEARCH_ENTITY_HEADERS_SUCCESS = '[ENTITIES] Search Entity Headers Success',
  SEARCH_ENTITY_HEADERS_FAILURE = '[ENTITIES] Search Entity Headers Failure',
  CLEAR_ENTITY_HEADERS = '[ENTITIES] Clear Entity Headers',
  CLEAR_ENTITY_HEADERS_LOOKUP_ERROR = '[ENTITIES] Clear Entity Headers Lookup Error',
  FETCH_ENTITY_CREDIT_EXPOSURES = '[ENTITIES] Fetch Entity Credit Exposures',
  FETCH_ENTITY_CREDIT_EXPOSURES_FAILURE = '[ENTITIES] Fetch Entity Credit Exposures Failure',
  FETCH_ENTITY_CREDIT_EXPOSURES_SUCCESS = '[ENTITIES] Fetch Entity Credit Exposures Success',
  SORT_ENITITY_CREDIT_EXPOSURE_TABLE = '[ENTITIES] Sort Entity Credit Exposure Table',
  CREATE_ENTITY_CREDIT_EXPOSURE = '[ENTITIES] Ceate Credit Exposure',
  CREATE_ENTITY_CREDIT_EXPOSURE_FAILURE = '[ENTITIES] Ceate Credit Exposure Failure',
  CREATE_ENTITY_CREDIT_EXPOSURE_SUCCESS = '[ENTITIES] Ceate Credit Exposure Success',
  UPDATE_ENTITY_CREDIT_EXPOSURE = '[ENTITIES] Update Credit Exposure',
  UPDATE_ENTITY_CREDIT_EXPOSURE_FAILURE = '[ENTITIES] Update Credit Exposure Failure',
  UPDATE_ENTITY_CREDIT_EXPOSURE_SUCCESS = '[ENTITIES] Update Credit Exposure Success',
  CLEAR_CREDIT_LIMIT_STATE = '[ENTITIES] Clear Credit Limit State',
  CLEAR_CREDIT_EXPOSURE_STATE = '[ENTITIES] Clear Credit Exposure State',
  FETCH_ENTITY_ADDRESSES = '[ENTITIES] Fetch Entity Addresses',
  FETCH_ENTITY_ADDRESSES_SUCCESS = '[ENTITIES] Fetch Entity Addresses Success',
  FETCH_ENTITY_ADDRESSES_FAILURE = '[ENTITIES] Fetch Entity Addresses Failure',
  CLEAR_ENTITY_ADDRESSES = '[ENTITIES] Clear Entity Addresses',
  CLEAR_ENTITY_HEADER_BY_ID = '[ENTITIES] Clear Entity Header By ID',
  FETCH_ENTITY_PERMISSION_ACCESS = '[ENTITIES] Fetch Entity Permission Access',
  FETCH_ENTITY_PERMISSION_ACCESS_SUCCESS = '[ENTITIES] Fetch Entity Permission Access Success',
  FETCH_ENTITY_PERMISSION_ACCESS_FAILURE = '[ENTITIES] Fetch Entity Permission Access Failure',
}

export class FetchEntityById extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.FetchEntitiyById;

  constructor(public payload: { entityId: number }) {
    super(payload);
  }
}

export class ClearEntity implements Action {
  public readonly type = EEntitiesActions.ClearEntity;
}

export class FetchEntityByIdSuccess implements Action {
  public readonly type = EEntitiesActions.FetchEntitiyByIdSuccess;

  constructor(public payload: { entity: Entity }) {}
}

export class FetchEntityByIdFailure implements Action {
  public readonly type = EEntitiesActions.FetchEntitiyByIdFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchEntities extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.FetchEntities;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors?: SortDescriptor[];
      status?: Array<string>;
      email?: string;
      dunsNumber?: string;
      financialId?: string;
      legalName?: string;
      name?: string;
      entityIds?: number[];
      searchPhrase?: string;
      isEntitiesFilter?: boolean;
    }
  ) {
    super(payload);
  }
}

export class FetchEntitiesSuccess implements Action {
  public readonly type = EEntitiesActions.FetchEntitiesSuccess;

  constructor(
    public payload: {
      entities: Array<Entity>;
      totalEntityCount: number;
      isEntitiesFilter: boolean;
    }
  ) {}
}

export class FetchEntitiesFailure implements Action {
  public readonly type = EEntitiesActions.FetchEntitiesFailure;

  constructor(public payload: { error: Error; isEntitiesFilter: boolean }) {}
}

export class ClearFilteredEntities implements Action {
  public readonly type = EEntitiesActions.CLEAR_FILTERED_ENTITIES;
}

export class FetchAllEntities implements Action {
  public readonly type = EEntitiesActions.FetchAllEntities;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors: SortDescriptor[];
    }
  ) {}
}

export class GetEntityDictionary extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.GetEntityDictionary;
}

export class GetEntityDictionarySuccess implements Action {
  public readonly type = EEntitiesActions.GetEntityDictionarySuccess;

  constructor(public payload: DictionaryCollections) {}
}

export class GetEntityDictionaryError implements Action {
  public readonly type = EEntitiesActions.GetEntityDictionaryError;

  constructor(public payload: { error: Error }) {}
}

export class ResetEntities implements Action {
  public readonly type = EEntitiesActions.ResetEntities;
}

export class ResetEntitiesSuccess implements Action {
  public readonly type = EEntitiesActions.ResetEntitiesSuccess;
}

export class ResetEntitiesError implements Action {
  public readonly type = EEntitiesActions.ResetEntitiesError;
}

export class SearchEntities implements Action {
  public readonly type = EEntitiesActions.SearchEntities;

  constructor(
    public payload: {
      searchPhrase: string;
      dunsNumber?: string;
      pageSize: number;
      pageNumber: number;
      sortBy: string;
      entityIds?: number[];
      searchScope?: string[];
    }
  ) {}
}

export class SearchEntitiesSuccess implements Action {
  public readonly type = EEntitiesActions.SearchEntitiesSuccess;

  constructor(public payload: { entities: Array<Entity> }) {}
}

export class SearchEntitiesFailure implements Action {
  public readonly type = EEntitiesActions.SearchEntitiesFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearEntitiesSearch implements Action {
  public readonly type = EEntitiesActions.ClearEntitiesSearch;
}

export class ResetFilteredEntities implements Action {
  public readonly type = EEntitiesActions.ResetFilteredEntities;
}

export class ResetEntity implements Action {
  public readonly type = EEntitiesActions.ResetEntity;
}

export class UpdateEntity extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.UpdateEntity;

  constructor(
    readonly payload: {
      entityId: number;
      body: Entity;
    }
  ) {
    super(payload);
  }
}

export class UpdateEntitySuccess implements Action {
  public readonly type = EEntitiesActions.UpdateEntitySuccess;
}

export class UpdateEntityFailure implements Action {
  public readonly type = EEntitiesActions.UpdateEntityFailure;

  constructor(public payload: { error: Error }) {}
}

export class CreateEntity extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.CreateEntity;

  constructor(
    readonly payload: {
      body: Entity;
    }
  ) {
    super(payload);
  }
}

export class UpdateEntityClassOverrides extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.UpdateEntityClassOverrides;

  constructor(
    readonly payload: {
      entityId: number;
      body: EntityClassificationOverride[];
    }
  ) {
    super(payload);
  }
}

export class CreateEntitySuccess implements Action {
  public readonly type = EEntitiesActions.CreateEntitySuccess;

  constructor(public payload: { entity: Entity }) {}
}

export class CreateEntityFailure implements Action {
  public readonly type = EEntitiesActions.CreateEntityFailure;

  constructor(public payload: { error: Error }) {}
}

export class UpdateEntityClassOverridesSuccess implements Action {
  public readonly type = EEntitiesActions.UpdateEntityClassOverridesSuccess;
}

export class UpdateEntityClassOverridesFailure implements Action {
  public readonly type = EEntitiesActions.UpdateEntityClassOverridesFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchParentEntitiesByEntityId extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.FETCH_PARENT_ENTITIES_BY_ENTITY_ID;

  constructor(
    readonly payload: {
      entityId: number;
    }
  ) {
    super(payload);
  }
}

export class FetchParentEntitiesByEntityIdSuccess implements Action {
  public readonly type = EEntitiesActions.FETCH_PARENT_ENTITIES_BY_ENTITY_ID_SUCCESS;

  constructor(
    public payload: {
      parentEntityCollection: ParentEntityCollection;
    }
  ) {}
}

export class FetchParentEntitiesByEntityIdFailure implements Action {
  public readonly type = EEntitiesActions.FETCH_PARENT_ENTITIES_BY_ENTITY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateParentEntitiesByEntityId extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.UPDATE_PARENT_ENTITIES_BY_ENTITY_ID;

  constructor(
    readonly payload: {
      entityId: number;
      body: ParentEntity[];
    }
  ) {
    super(payload);
  }
}

export class UpdateParentEntitiesByEntityIdSuccess implements Action {
  public readonly type = EEntitiesActions.UPDATE_PARENT_ENTITIES_BY_ENTITY_ID_SUCCESS;
}

export class UpdateParentEntitiesByEntityIdFailure implements Action {
  public readonly type = EEntitiesActions.UPDATE_PARENT_ENTITIES_BY_ENTITY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}
export class GetCreditLimitsByEntityId extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.GET_CREDIT_LIMITS_BY_ENTITY_ID;

  constructor(
    readonly payload: {
      entityId: number;
    }
  ) {
    super(payload);
  }
}

export class GetCreditLimitsByEntityIdSuccess implements Action {
  public readonly type = EEntitiesActions.GET_CREDIT_LIMITS_BY_ENTITY_ID_SUCCESS;

  constructor(
    public payload: {
      creditLimits: EntityCreditLimitSummary;
    }
  ) {}
}

export class GetCreditLimitsByEntityIdFailure implements Action {
  public readonly type = EEntitiesActions.GET_CREDIT_LIMITS_BY_ENTITY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class AddCreditLimitByEntityId extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.ADD_CREDIT_LIMIT_BY_ENTITY_ID;

  constructor(
    readonly payload: {
      entityId: number;
      body: EntityCreditLimit;
    }
  ) {
    super(payload);
  }
}
export class ClearAddCreditLimitByEntityId extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.CLEAR_ADD_CREDIT_LIMIT_BY_ENTITY_ID;
}

export class AddCreditLimitByEntityIdSuccess implements Action {
  public readonly type = EEntitiesActions.ADD_CREDIT_LIMIT_BY_ENTITY_ID_SUCCESS;

  constructor(
    public payload: {
      creditLimit: EntityCreditLimit;
    }
  ) {}
}

export class AddCreditLimitByEntityIdFailure implements Action {
  public readonly type = EEntitiesActions.ADD_CREDIT_LIMIT_BY_ENTITY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateCreditLimitsByEntityId extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.UPDATE_CREDIT_LIMITS_BY_ENTITY_ID;

  constructor(
    readonly payload: {
      creditLimits: Array<EntityCreditLimit>;
    }
  ) {
    super(payload);
  }
}

export class ClearUpdateCreditLimitsByEntityId extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.CLEAR_UPDATE_CREDIT_LIMITS_BY_ENTITY_ID;
}

export class UpdateCreditLimitsByEntityIdSuccess implements Action {
  public readonly type = EEntitiesActions.UPDATE_CREDIT_LIMITS_BY_ENTITY_ID_SUCCESS;

  constructor(
    public payload: {
      creditLimits: {
        [creditLimitId: string]: EntityCreditLimit | UpdateCreditLimitsByEntityIdFailure;
      };
    }
  ) {}
}

export class UpdateCreditLimitsByEntityIdFailure implements Action {
  public readonly type = EEntitiesActions.UPDATE_CREDIT_LIMITS_BY_ENTITY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchAllEntitiesByIds extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.FETCH_ALL_ENTITIES_BY_IDS;

  constructor(
    readonly payload: {
      entityIds: Array<number>;
      sortDescriptors?: SortDescriptor[];
    }
  ) {
    super(payload);
  }
}

export class FetchAllEntitiesByIdsSuccess implements Action {
  public readonly type = EEntitiesActions.FETCH_ALL_ENTITIES_BY_IDS_SUCCESS;

  constructor(
    public payload: {
      entities: Array<EntityHeader>;
    }
  ) {}
}

export class FetchAllEntitiesByIdsFailure implements Action {
  public readonly type = EEntitiesActions.FETCH_ALL_ENTITIES_BY_IDS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchEntityHeaderById extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_HEADER_BY_ID;

  constructor(public payload: IEntityHeaderByIdPayload) {
    super(payload);
  }
}
export class FetchEntityHeaderByIdSuccess implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_HEADER_BY_ID_SUCCESS;

  constructor(public payload: EntityHeader) {}
}

export class FetchEntityHeaderByIdFailure implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_HEADER_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearEntityHeaderById implements Action {
  public readonly type = EEntitiesActions.CLEAR_ENTITY_HEADER_BY_ID;
}

export class FetchAllEntityHeaders extends RetryableAction implements Action {
  public readonly type = EEntitiesActions.FETCH_ALL_ENTITY_HEADERS;

  constructor(readonly payload: IEntityHeaderPayload) {
    super(payload);
  }
}

export class FetchAllEntityHeadersSuccess implements Action {
  public readonly type = EEntitiesActions.FETCH_ALL_ENTITY_HEADERS_SUCCESS;

  constructor(public payload: EntityHeaderCollection) {}
}

export class FetchAllEntityHeadersFailure implements Action {
  public readonly type = EEntitiesActions.FETCH_ALL_ENTITY_HEADERS_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearEntityHeaders implements Action {
  public readonly type = EEntitiesActions.CLEAR_ENTITY_HEADERS;
}

export class ClearEntityHeadersLookupError implements Action {
  public readonly type = EEntitiesActions.CLEAR_ENTITY_HEADERS_LOOKUP_ERROR;
}

export class SearchEntityHeaders implements Action {
  public readonly type = EEntitiesActions.SEARCH_ENTITY_HEADERS;

  constructor(readonly payload: IEntityHeaderPayload) {}
}
export class SearchEntityHeadersSuccess implements Action {
  public readonly type = EEntitiesActions.SEARCH_ENTITY_HEADERS_SUCCESS;

  constructor(public payload: EntityHeaderCollection) {}
}
export class SearchEntityHeadersFailure implements Action {
  public readonly type = EEntitiesActions.SEARCH_ENTITY_HEADERS_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchEntityCreditExposures implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_CREDIT_EXPOSURES;

  constructor(
    public payload: {
      entityId: number;
      tspId: number;
    }
  ) {}
}

export class FetchEntityCreditExposuresFailure implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_CREDIT_EXPOSURES_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchEntityCreditExposuresSuccess implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_CREDIT_EXPOSURES_SUCCESS;

  constructor(public payload: EntityCreditExposureSummary) {}
}

export class SortEntityCreditExposureTable implements Action {
  public readonly type = EEntitiesActions.SORT_ENITITY_CREDIT_EXPOSURE_TABLE;

  constructor(public payload: IEntityCreditExposureGridSort) {}
}

export class CreateEntityCreditExposure implements Action {
  public readonly type = EEntitiesActions.CREATE_ENTITY_CREDIT_EXPOSURE;

  constructor(public payload: EntityCreditExposureAddUpdatePayload) {}
}

export class CreateEntityCreditExposureFailure implements Action {
  public readonly type = EEntitiesActions.CREATE_ENTITY_CREDIT_EXPOSURE_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateEntityCreditExposureSuccess implements Action {
  public readonly type = EEntitiesActions.CREATE_ENTITY_CREDIT_EXPOSURE_SUCCESS;

  constructor(public payload: EntityCreditExposure) {}
}

export class UpdateEntityCreditExposure implements Action {
  public readonly type = EEntitiesActions.UPDATE_ENTITY_CREDIT_EXPOSURE;

  constructor(public payload: EntityCreditExposureAddUpdatePayload) {}
}

export class UpdateEntityCreditExposureFailure implements Action {
  public readonly type = EEntitiesActions.UPDATE_ENTITY_CREDIT_EXPOSURE_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateEntityCreditExposureSuccess implements Action {
  public readonly type = EEntitiesActions.UPDATE_ENTITY_CREDIT_EXPOSURE_SUCCESS;

  constructor(public payload: EntityCreditExposure) {}
}

export class ClearCreditExposureState implements Action {
  public readonly type = EEntitiesActions.CLEAR_CREDIT_EXPOSURE_STATE;
}

export class ClearCreditLimitState implements Action {
  public readonly type = EEntitiesActions.CLEAR_CREDIT_LIMIT_STATE;
}

export class FetchEntityAddresses implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_ADDRESSES;

  constructor(
    public payload: {
      entityIds: number[];
    }
  ) {}
}

export class FetchEntityAddressesFailure implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_ADDRESSES_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchEntityAddressesSuccess implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_ADDRESSES_SUCCESS;

  constructor(public payload: Array<EntityAddress>) {}
}

export class ClearEntityAddresses implements Action {
  public readonly type = EEntitiesActions.CLEAR_ENTITY_ADDRESSES;

  constructor() {}
}

export class FetchEntityPermissionAccess implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_PERMISSION_ACCESS;

  constructor(
    public payload: {
      entityId: number;
      agencyResourceName: string;
      agencyTransactionStartDate: string;
      agencyTransactionEndDate?: string;
      minPermissionOption?: EPermissionOption;
    }
  ) {}
}

export class FetchEntityPermissionAccessFailure implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_PERMISSION_ACCESS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchEntityPermissionAccessSuccess implements Action {
  public readonly type = EEntitiesActions.FETCH_ENTITY_PERMISSION_ACCESS_SUCCESS;

  constructor(public payload: { hasAccess: boolean }) {}
}

export type EntitiesActions =
  | FetchEntityById
  | ClearEntity
  | FetchEntityByIdSuccess
  | FetchEntityByIdFailure
  | FetchEntityHeaderById
  | FetchEntityHeaderByIdSuccess
  | FetchEntityHeaderByIdFailure
  | FetchEntities
  | FetchEntitiesSuccess
  | FetchEntitiesFailure
  | ClearEntityHeadersLookupError
  | GetEntityDictionary
  | GetEntityDictionarySuccess
  | GetEntityDictionaryError
  | ResetEntities
  | ResetEntitiesSuccess
  | ResetEntitiesError
  | FetchAllEntities
  | SearchEntities
  | SearchEntitiesSuccess
  | SearchEntitiesFailure
  | ClearEntitiesSearch
  | ResetFilteredEntities
  | ResetEntity
  | UpdateEntity
  | UpdateEntitySuccess
  | UpdateEntityFailure
  | CreateEntity
  | CreateEntitySuccess
  | CreateEntityFailure
  | UpdateEntityClassOverrides
  | UpdateEntityClassOverridesSuccess
  | UpdateEntityClassOverridesFailure
  | FetchParentEntitiesByEntityId
  | FetchParentEntitiesByEntityIdSuccess
  | FetchParentEntitiesByEntityIdFailure
  | GetCreditLimitsByEntityId
  | GetCreditLimitsByEntityIdSuccess
  | GetCreditLimitsByEntityIdFailure
  | AddCreditLimitByEntityId
  | ClearAddCreditLimitByEntityId
  | AddCreditLimitByEntityIdSuccess
  | AddCreditLimitByEntityIdFailure
  | UpdateCreditLimitsByEntityId
  | ClearUpdateCreditLimitsByEntityId
  | UpdateCreditLimitsByEntityIdSuccess
  | UpdateCreditLimitsByEntityIdFailure
  | UpdateParentEntitiesByEntityId
  | UpdateParentEntitiesByEntityIdSuccess
  | UpdateParentEntitiesByEntityIdFailure
  | FetchAllEntitiesByIds
  | FetchAllEntitiesByIdsSuccess
  | FetchAllEntitiesByIdsFailure
  | FetchAllEntityHeaders
  | FetchAllEntityHeadersSuccess
  | FetchAllEntityHeadersFailure
  | ClearEntityHeaders
  | ClearFilteredEntities
  | FetchEntityCreditExposures
  | FetchEntityCreditExposuresFailure
  | FetchEntityCreditExposuresSuccess
  | SortEntityCreditExposureTable
  | CreateEntityCreditExposure
  | CreateEntityCreditExposureFailure
  | CreateEntityCreditExposureSuccess
  | UpdateEntityCreditExposure
  | UpdateEntityCreditExposureFailure
  | UpdateEntityCreditExposureSuccess
  | ClearCreditLimitState
  | ClearCreditExposureState
  | FetchEntityAddresses
  | FetchEntityAddressesFailure
  | FetchEntityAddressesSuccess
  | ClearEntityAddresses
  | SearchEntityHeaders
  | SearchEntityHeadersSuccess
  | SearchEntityHeadersFailure
  | ClearEntityHeaderById
  | FetchEntityPermissionAccess
  | FetchEntityPermissionAccessSuccess
  | FetchEntityPermissionAccessFailure;
