export enum TagColor {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  TEAL = 'teal',
  DARK_BLUE = 'dark-blue',
  WHITE = 'white',
  GRAY = 'gray',
  PURPLE = 'purple',
  PRIMARY_BLUE = 'primary-blue',
  DARKER_BLUE_GRAY = 'darker-blue-gray',
}
