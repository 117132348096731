import { IDataState, emptyRequestStatus } from 'app/store/app/app.models';
import { InfoPostMenuItems } from "@gms/reporting-api/model/infoPostMenuItems";
import { InfoPostDetails, Posting } from '@gms/reporting-api';
import { PostingFilterModel } from "app/store/posting/posting-response.model";

export interface PostingState {
  postings: IDataState<InfoPostDetails>;
  postingDetails: IDataState<Posting>;
  createdNewRecord: IDataState<any>;
  menuItemList: IDataState<InfoPostMenuItems>;
  isLoading?: boolean;
  error?: any;
  deletePosting: IDataState<boolean>;
  filterData: PostingFilterModel;
}

export const initialPostingFilter: PostingFilterModel = {
  filterData: {},
  istriggerSearchApi: true
}

export const initialPostingState: PostingState = {
  postings: {
    data: {},
    requestStatus: { ...emptyRequestStatus },
  },
  postingDetails: {
    data: { tspId: null },
    requestStatus: { ...emptyRequestStatus },
  },
  menuItemList: {
    data: {},
    requestStatus: { ...emptyRequestStatus },
  },
  createdNewRecord: {
    data: {},
    requestStatus: { ...emptyRequestStatus },
  },
  deletePosting: {
    data: false,
    requestStatus: { ...emptyRequestStatus },
  },
  filterData: initialPostingFilter
}