import { Injectable } from '@angular/core';
import { Note, NotesService } from '@gms/servicerequest-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import {
  DeleteNote,
  DeleteNoteError,
  DeleteNoteSuccess,
  ENotesActions,
  FetchNotes,
  FetchNotesError,
  FetchNotesSuccess,
  SaveNote,
  SaveNoteError,
  SaveNoteSuccess,
} from './notes.actions';

@Injectable({ providedIn: 'root' })
export class NotesEffects {
  constructor(private actions$: Actions, private notesService: NotesService) {}

  SaveNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveNote>(ENotesActions.ADD_NOTE),
      switchMap(body => {
        const bodyPayload = body.payload;
        return this.notesService.addNote(bodyPayload).pipe(
          mergeMap((note: Note) => {
            return of(new SaveNoteSuccess(note));
          }),
          catchError(error => {
            return of(new SaveNoteError(error));
          })
        );
      })
    )
  );

  FetchNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchNotes>(ENotesActions.FETCH_NOTES),
      switchMap(body => {
        return this.notesService
          .getNote(body.payload.referenceId, body.payload.referenceTypeId)
          .pipe(
            mergeMap((notes: Note[]) => {
              return of(new FetchNotesSuccess(notes));
            }),
            catchError(error => {
              return of(new FetchNotesError(error));
            })
          );
      })
    )
  );

  DeleteNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteNote>(ENotesActions.DELETE_NOTE),
      switchMap(body => {
        return this.notesService.deleteNote(body.payload.id).pipe(
          mergeMap(response => {
            return of(new DeleteNoteSuccess());
          }),
          catchError(error => {
            return of(new DeleteNoteError());
          })
        );
      })
    )
  );
}
