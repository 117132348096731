import { Configuration as AccountingConfiguration} from '@gms/accounting-api';
import { Configuration as AuctionConfiguration } from '@gms/auction-api';
import { Configuration as ServiceREquestConfiguration, ConfigurationParameters } from '@gms/servicerequest-api';
import { Configuration as accountingEngineConfiguration} from '@gms/accountingengine-api';
import { Configuration as allocationConfiguration} from '@gms/allocation-api';
import { Configuration as capacityReleaseConfiguration} from '@gms/capacityrelease-api';
import { Configuration as billingConfiguration} from '@gms/billing-api';
import { Configuration as locationConfiguration} from '@gms/location-api';
import { Configuration as pipelineConfiguration} from '@gms/pipeline-api';
import { Configuration as closingConfiguration} from '@gms/closing-api';
import { Configuration as contractConfiguration} from '@gms/contract-api';
import { Configuration as commonConfiguration} from '@gms/common-api';
import { Configuration as imbalanceConfiguration} from '@gms/imbalance-api';
import { Configuration as jobScheduleConfiguration} from '@gms/jobschedule-api';
import { Configuration as measurementConfiguration} from '@gms/measurement-api';
import { Configuration as nominationConfiguration} from '@gms/nomination-api';
import { Configuration as tspConfiguration} from '@gms/tsp-api';
import { Configuration as schedulingConfiguration} from '@gms/scheduling-api';
import { Configuration as ratesConfiguration} from '@gms/rate-api';
import { Configuration as repResponsibilityConfiguration} from '@gms/represponsibility-api';
import { Configuration as workflowConfiguration} from '@gms/workflow-api';
import { Configuration as systemPlanningConfiguration} from '@gms/systemplanning-api';
import { Configuration as reportingConfiguration} from '@gms/reporting-api';
import { Configuration as invoicingConfiguration} from '@gms/invoicing-api';
import { Configuration as securityConfiguration} from '@gms/security-api';
import { Configuration as userConfiguration } from '@gms/user-api';
import { Configuration as rateSchedule2Configuration } from '@gms/rateschedulev2-api';
import { Configuration as entityConfiguration } from '@gms/entity-api';
import { Configuration as communicationConfiguration } from '@gms/communication-api';
import { Configuration as activityConfiguration } from '@gms/activity-api';

import { appConfig } from 'config/app-config';

// This pattern is necessary due to this issue
// https://github.com/angular/angular/issues/14707

export class ApiConfigFactory {
  static getParams(endpoint) {
    const params: ConfigurationParameters = {
      basePath: appConfig.endpoints[endpoint],
    };
    return params;
  }

  static accounting() {
    return new AccountingConfiguration(ApiConfigFactory.getParams('accounting'));
  }

  static auction() {
    return new AuctionConfiguration(ApiConfigFactory.getParams('auction'));
  }

  static accountingEngine() {
    return new accountingEngineConfiguration(ApiConfigFactory.getParams('accountingEngine'));
  }

  static allocations() {
    return new allocationConfiguration(ApiConfigFactory.getParams('allocation'));
  }

  static auctions() {
    return new AuctionConfiguration(ApiConfigFactory.getParams('auction'));
  }

  static capacityRelease() {
    return new capacityReleaseConfiguration(ApiConfigFactory.getParams('capacityRelease'));
  }

  static billing() {
    return new billingConfiguration(ApiConfigFactory.getParams('billing'));
  }

  static location() {
    return new locationConfiguration(ApiConfigFactory.getParams('location'));
  }

  static pipeline() {
    return new pipelineConfiguration(ApiConfigFactory.getParams('pipeline'));
  }

  static serviceRequest() {
    return new ServiceREquestConfiguration(ApiConfigFactory.getParams('serviceRequest'));
  }

  static closing() {
    return new closingConfiguration(ApiConfigFactory.getParams('closing'));
  }

  static contract() {
    return new contractConfiguration(ApiConfigFactory.getParams('contract'));
  }

  static common() {
    return new commonConfiguration(ApiConfigFactory.getParams('common'));
  }

  static imbalance() {
    return new imbalanceConfiguration(ApiConfigFactory.getParams('imbalance'));
  }

  static jobSchedule() {
    return new jobScheduleConfiguration(ApiConfigFactory.getParams('jobSchedule'));
  }

  static measurement() {
    return new measurementConfiguration(ApiConfigFactory.getParams('measurement'));
  }

  static nomination() {
    return new nominationConfiguration(ApiConfigFactory.getParams('nomination'));
  }

  static tsp() {
    return new tspConfiguration(ApiConfigFactory.getParams('tsp'));
  }

  static scheduling() {
    return new schedulingConfiguration(ApiConfigFactory.getParams('scheduling'));
  }

  static rates() {
    return new ratesConfiguration(ApiConfigFactory.getParams('rates'));
  }

  static repResponsibility() {
    return new repResponsibilityConfiguration(ApiConfigFactory.getParams('repResponsibility'));
  }

  static workflow() {
    return new workflowConfiguration(ApiConfigFactory.getParams('workflow'));
  }

  static systemPlanning() {
    return new systemPlanningConfiguration(ApiConfigFactory.getParams('systemPlanning'));
  }

  static reporting() {
    return new reportingConfiguration(ApiConfigFactory.getParams('reporting'));
  }

  static invoicing() {
    return new invoicingConfiguration(ApiConfigFactory.getParams('invoicing'));
  }

  static security() {
    return new securityConfiguration(ApiConfigFactory.getParams('security'));
  }

  static activity() {
    return new activityConfiguration(ApiConfigFactory.getParams('baseActivity'));
  }

  static rateSchedule2() {
    return new rateSchedule2Configuration(ApiConfigFactory.getParams('rateScheduleBase'))
  }

  static entity() {
    return new entityConfiguration(ApiConfigFactory.getParams('baseEntities'))
  }

  static communication() {
    return new communicationConfiguration(ApiConfigFactory.getParams('communication'))
  }

  static user() {
    return new userConfiguration(ApiConfigFactory.getParams('baseUser'))
  }

}
