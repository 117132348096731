import { Location } from '@gms/location-api';
import { ELocationAttributeId } from 'shared/consts/locations.const';
import { EMasterRateScheduleIds } from 'shared/consts/rate-schedule.const';
import { Tsps } from 'shared/utils/tsp.utils';
import { ELocationStatuses } from './location-statuses.enum';

export const allowedLocationStatuses: string[] = [
  ELocationStatuses.ACTIVE,
  ELocationStatuses.PROPOSED,
];

export const allowedLocationAttributes: ELocationAttributeId[] = [
  ELocationAttributeId.RELEASEABLE,
  ELocationAttributeId.NOMINATABLE,
];

export const masterListNominatableAttributes: ELocationAttributeId[] = [
  ELocationAttributeId.MASTER_LIST,
  ELocationAttributeId.NOMINATABLE,
];

interface LocationAttributeFilter {
  hasAttributes: (attributeIds: Set<number>) => boolean;
}

const hasAllAttributes = (
  filterableAttributes: ELocationAttributeId[],
  attributeIds: Set<number>
): boolean => filterableAttributes.every(attribute => attributeIds.has(attribute));

const hasSomeAttributes = (
  filterableAttributes: ELocationAttributeId[],
  attributeIds: Set<number>
): boolean => filterableAttributes.some(attribute => attributeIds.has(attribute));

const hasDefaultLocationAttributes = (attributeIds: Set<number>) =>
  hasSomeAttributes(allowedLocationAttributes, attributeIds);

const hasGSPetalLocationAttributes = (attributeIds: Set<number>) =>
  hasAllAttributes(masterListNominatableAttributes, attributeIds);

const hasBSCLocationAttributes = (attributeIds: Set<number>) =>
  hasAllAttributes(masterListNominatableAttributes, attributeIds);

const hasNominatableAttribute = (attributeIds: Set<number>) =>
  attributeIds.has(ELocationAttributeId.NOMINATABLE);

export const tspRsLocationAttributeFilterMap = new Tsps<{
  [rateScheduleId: number]: LocationAttributeFilter;
}>()
  .setTexasGas({
    [EMasterRateScheduleIds.GS]: {
      hasAttributes: hasNominatableAttribute,
    },
    [EMasterRateScheduleIds.GP]: {
      hasAttributes: hasNominatableAttribute,
    },
  })
  .setGulfSouth({
    [EMasterRateScheduleIds.AVS]: {
      hasAttributes: hasGSPetalLocationAttributes,
    },
    [EMasterRateScheduleIds.PKS]: {
      hasAttributes: hasGSPetalLocationAttributes,
    },
    [EMasterRateScheduleIds.ISS_P]: {
      hasAttributes: hasGSPetalLocationAttributes,
    },
    [EMasterRateScheduleIds.FSS_P]: {
      hasAttributes: hasGSPetalLocationAttributes,
    },
    [EMasterRateScheduleIds.GS]: {
      hasAttributes: hasNominatableAttribute,
    },
    [EMasterRateScheduleIds.GP]: {
      hasAttributes: hasNominatableAttribute,
    },
  })
  .setBoardwalkStorage({
    [EMasterRateScheduleIds.FSS]: {
      hasAttributes: hasBSCLocationAttributes,
    },
    [EMasterRateScheduleIds.ISS]: {
      hasAttributes: hasBSCLocationAttributes,
    },
    [EMasterRateScheduleIds.GS]: {
      hasAttributes: hasBSCLocationAttributes,
    },
    [EMasterRateScheduleIds.GP]: {
      hasAttributes: hasBSCLocationAttributes,
    },
  })
  .setBLGT({
    [EMasterRateScheduleIds.GS]: {
      hasAttributes: hasNominatableAttribute,
    },
  });

export const tspLocationAttributeFilterMap = new Tsps<LocationAttributeFilter>()
  .setTexasGas({
    hasAttributes: hasDefaultLocationAttributes,
  })
  .setBLGT({
    hasAttributes: hasDefaultLocationAttributes,
  })
  .setGulfSouth({
    hasAttributes: hasDefaultLocationAttributes,
  })
  .setBoardwalkStorage({
    hasAttributes: hasDefaultLocationAttributes,
  });

/**
 * Retrieves an applicable location attribute filtering fn for the provided tsp and rateSchedule.
 * If neither the rateSchedule is provided nor a rateSchedule applicable filter is found
 * then this function will return the default filtering for the provided tsp.
 * @param tspId
 * @param rateScheduleId
 * @returns
 */
export const getAttributeFiltering = (
  tspId: number,
  rateScheduleId: number
): LocationAttributeFilter => {
  if (!tspId) {
    return null;
  }

  const tspRsConfig = tspRsLocationAttributeFilterMap.get(tspId);
  const tspConfig = tspLocationAttributeFilterMap.get(tspId);

  const tspRsFilter =
    rateScheduleId && tspRsConfig && tspRsConfig.data && tspRsConfig.data[rateScheduleId]
      ? tspRsConfig.data[rateScheduleId]
      : null;

  const tspFilter = tspConfig && tspConfig.data ? tspConfig.data : null;
  return tspRsFilter || tspFilter;
};

export const filterFlowDirection = (
  location: Location,
  flowDirection: string,
  selectedLocation: Location
): boolean => {
  let result = true;
  if (selectedLocation.locationId === location.locationId) {
    result = false;
  }

  if (
    flowDirection &&
    location.flowDirection !== flowDirection &&
    location.flowDirection !== 'B' &&
    flowDirection !== 'B'
  ) {
    result = false;
  }
  return result;
};

export const filterByStatus = (location: Location): boolean => {
  return location.status ? allowedLocationStatuses.includes(location.status) : false;
};

export const filterByAttribute = (
  location: Location,
  tspId: number,
  rateScheduleId: number = null
): boolean => {
  let attributeFound = false;
  if (location.attributes.length) {
    const locationAttributeIds = new Set(
      location.attributes
        .filter(attribute => attribute.attributeFlag)
        .map(attribute => attribute.attributeId)
    );
    const tspAttributeFiltering = getAttributeFiltering(tspId, rateScheduleId);
    attributeFound =
      !!tspAttributeFiltering && tspAttributeFiltering.hasAttributes(locationAttributeIds);
  }
  return attributeFound;
};
