import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import {
  ClosingDashBoardActions,
  EClosingDashBoardActions,
} from 'app/store/closing-dashboard/closing-dashboard.actions';
import {
  IClosingDashboardState,
  initialClosingDashboardState,
} from 'app/store/closing-dashboard/closing-dashboard.state';
import {
  getProcesses,
  updateGroupProcess,
} from 'app/store/closing-dashboard/closing-dashboard.utils';

export function closingDashboardReducer(
  state = initialClosingDashboardState,
  action: ClosingDashBoardActions
): IClosingDashboardState {
  switch (action.type) {
    case EClosingDashBoardActions.FETCH_CLOSING_DASHBOARD_DATA:
      return {
        ...state,
        closingDashboardProcesses: null,
        closingDashboardData: createLoadingState(state.closingDashboardData),
      };
    case EClosingDashBoardActions.FETCH_CLOSING_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        closingDashboardProcesses: getProcesses(action.payload),
        closingDashboardData: createSuccessState(action.payload),
      };
    case EClosingDashBoardActions.FETCH_CLOSING_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        closingDashboardProcesses: null,
        closingDashboardData: createErrorState(action.payload),
      };
    case EClosingDashBoardActions.UPDATE_CLOSING_GROUP_TASK:
      return {
        ...state,
        updateGroupTask: { loading: true, httpError: null },
      };
    case EClosingDashBoardActions.UPDATE_CLOSING_GROUP_TASK_SUCCESS:
      return {
        ...state,
        updateGroupTask: { loading: false, httpError: null },
      };
    case EClosingDashBoardActions.UPDATE_CLOSING_GROUP_TASK_FAILURE:
      return {
        ...state,
        updateGroupTask: { loading: false, httpError: action.payload },
      };
    case EClosingDashBoardActions.CLOSE_CLOSING_GROUP:
      return {
        ...state,
        closeClosingGroup: { loading: true, httpError: null },
      };
    case EClosingDashBoardActions.CLOSE_CLOSING_GROUP_SUCCESS:
      return {
        ...state,
        closeClosingGroup: { loading: false, httpError: null },
      };
    case EClosingDashBoardActions.CLOSE_CLOSING_GROUP_FAILURE:
      return {
        ...state,
        closeClosingGroup: { loading: false, httpError: action.payload },
      };
    case EClosingDashBoardActions.REFRESH_GROUP_PROCESSES:
      return {
        ...state,
        closingDashboardProcesses: updateGroupProcess(
          { ...state.closingDashboardProcesses },
          action.payload.closingGroupId,
          null,
          true
        ),
      };
    case EClosingDashBoardActions.REFRESH_GROUP_PROCESSES_SUCCESS:
      return {
        ...state,
        closingDashboardProcesses: updateGroupProcess(
          { ...state.closingDashboardProcesses },
          action.payload.closingGroupId,
          action.payload.processCollection.closingGroups,
          false
        ),
      };
    case EClosingDashBoardActions.REFRESH_GROUP_PROCESSES_FAILURE:
      return {
        ...state,
        closingDashboardProcesses: updateGroupProcess(
          { ...state.closingDashboardProcesses },
          action.payload.closingGroupId,
          null,
          false,
          action.payload.error
        ),
      };
    case EClosingDashBoardActions.RUN_TIE_OUT_SCRIPT:
      return {
        ...state,
        runTieOutQuery: { loading: true, httpError: null },
      };
    case EClosingDashBoardActions.RUN_TIE_OUT_SCRIPT_SUCCESS:
      return {
        ...state,
        runTieOutQuery: { loading: false, httpError: null },
      };
    case EClosingDashBoardActions.RUN_TIE_OUT_SCRIPT_FAILURE:
      return {
        ...state,
        runTieOutQuery: { loading: false, httpError: action.payload },
      };
    default:
      return state;
  }
}
