import {
  DropdownDictionary,
  DynamicLocationGroup,
  DynamicLocationGroupCollection,
  EffectiveDateRecord,
  Location,
  LocationGroup,
  LocationGroupCollection,
  LocationHeader,
  LocationHeaderCollection,
  LocationListCollection,
  LocationResult,
  Zone,
} from '@gms/location-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { StorageLocationResult } from 'app/modules/service-request/services/locationStorage.service';
import {
  clearState,
  createInitialDataState,
  IDataState,
  IRequestStatus,
} from 'app/store/app/app.models';
import { getPagination, IPagination } from 'shared/models/pagination.model';

export enum ELocationValidationError {
  DUPLICATE_LOCATION_NUMBER_ERROR,
  DUPLICATE_ASSOCIATION_ERROR,
}

export interface ILocationState {
  allZones: Array<Zone>;
  locations: Location[];
  location: Location;
  childLocations: LocationResult[];
  dropdownDictionary: DropdownDictionary;
  dynamicLocationGroup: DynamicLocationGroup;
  dynamicLocationGroupCollection: IDataState<DynamicLocationGroupCollection>;
  locationGroupCollection: LocationGroup[];
  tspIdLocationGroupCollection: LocationGroupCollection[];
  loading: ILocationLoading;
  error: Error;
  dictionaryError: Error;
  updateLocationError: Location;
  pagination: IPagination;
  totalLocationCount: number;
  sortDescriptors: SortDescriptor[];
  locationsSearch: Location[];
  previouslySearchedLocation: Location;
  locationsSearchLoading: Boolean;
  locationGroup: LocationGroup;
  locationGroups: Array<LocationGroup>;
  locationGroupByIds: Array<LocationGroup>;
  totalLocationGroupCount: number;
  locationsSearchError: Error | null;
  effectiveDateHistory: EffectiveDateRecord[];
  storageLocations: StorageLocationResult;
  locationHeadersSearch: IDataState<LocationHeaderCollection>;
  locationsList: IDataState<LocationListCollection>;
  parentLocation: IDataState<Location>;
  status: {
    addLocation: IRequestStatus<ELocationValidationError>;
    childLocations: IRequestStatus;
    dynamicLocationGroup: IRequestStatus;
    effectiveDateHistory: IRequestStatus;
    locationGroup: IRequestStatus;
    locationGroups: IRequestStatus;
    updateLocation: IRequestStatus<ELocationValidationError>;
    createLocationGroup: IRequestStatus;
    createLocationGroupCriteria: IRequestStatus;
    updateLocationGroup: IRequestStatus;
    updateLocationGroupCriteria: IRequestStatus;
    deleteLocationGroup: IRequestStatus;
  };
}
export const initialLocationState: ILocationState = {
  allZones: new Array<Zone>(),
  locations: [],
  location: null,
  childLocations: null,
  sortDescriptors: [
    {
      field: 'locationNumber',
      dir: 'asc',
    },
  ],
  loading: {},
  error: null,
  updateLocationError: null,
  dictionaryError: null,
  dropdownDictionary: null,
  dynamicLocationGroup: null,
  dynamicLocationGroupCollection: createInitialDataState(),
  locationGroupCollection: null,
  tspIdLocationGroupCollection: [],
  pagination: getPagination(),
  totalLocationCount: 0,
  locationsSearch: [],
  locationHeadersSearch: clearState(null),
  previouslySearchedLocation: null,
  locationsSearchLoading: false,
  locationsSearchError: null,
  effectiveDateHistory: [],
  locationGroup: null,
  locationGroups: null,
  locationGroupByIds: null,
  totalLocationGroupCount: 0,
  storageLocations: null,
  locationsList: createInitialDataState(),
  parentLocation: createInitialDataState(),
  status: {
    addLocation: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    childLocations: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    dynamicLocationGroup: {
      httpError: null,
      loading: false,
    },
    effectiveDateHistory: {
      httpError: null,
      loading: false,
    },
    locationGroup: {
      httpError: null,
      loading: false,
    },
    locationGroups: {
      httpError: null,
      loading: false,
    },
    updateLocation: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    updateLocationGroupCriteria: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    createLocationGroup: {
      httpError: null,
      loading: false,
    },
    createLocationGroupCriteria: {
      httpError: null,
      loading: false,
    },
    updateLocationGroup: {
      httpError: null,
      loading: false,
    },
    deleteLocationGroup: {
      httpError: null,
      loading: false,
    },
  },
};

export interface ILocationLoading {
  dictionary?: boolean;
  locations?: boolean;
  effectiveDateHistory?: boolean;
}
