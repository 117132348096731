import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  ClosingDashboardClosingGroupClosePeriodService,
  ClosingDashboardProcessesForReviewService,
  ClosingDashboardService,
} from '@gms/closing-api';
import { convertAccountPeriodToDate } from 'app/modules/accounting/utils/journal-entries-utils';

import {
  CloseClosingGroup,
  CloseClosingGroupFailure,
  CloseClosingGroupSuccess,
  EClosingDashBoardActions,
  GetClosingDashBoardData,
  GetClosingDashBoardDataFailure,
  GetClosingDashBoardDataSuccess,
  RefreshGroupProcesses,
  RefreshGroupProcessesFailure,
  RefreshGroupProcessesSuccess,
  RunTieOutScript,
  RunTieOutScriptFailure,
  RunTieOutScriptSuccess,
  UpdateClosingGroupTask,
  UpdateClosingGroupTaskFailure,
  UpdateClosingGroupTaskSuccess,
} from 'app/store/closing-dashboard/closing-dashboard.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClosingDashboardEffects {
  constructor(
    private _actions$: Actions,
    private _closingDashboardService: ClosingDashboardService,
    private _closingGroupService: ClosingDashboardClosingGroupClosePeriodService,
    private _groupProcessesService: ClosingDashboardProcessesForReviewService
  ) {}

  getClosingDashboardData$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetClosingDashBoardData>(EClosingDashBoardActions.FETCH_CLOSING_DASHBOARD_DATA),
      map((action: GetClosingDashBoardData) => action),
      switchMap(action => {
        const { tspId, accountPeriod } = action.payload;
        return this._closingDashboardService
          .getClosingDashboardGroups(convertAccountPeriodToDate(accountPeriod), tspId)
          .pipe(
            map(response => new GetClosingDashBoardDataSuccess(response)),
            catchError(error => of(new GetClosingDashBoardDataFailure(error)))
          );
      })
    )
  );

  updateClosingGroupTask$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateClosingGroupTask>(EClosingDashBoardActions.UPDATE_CLOSING_GROUP_TASK),
      map((action: UpdateClosingGroupTask) => action),
      switchMap(action => {
        return this._closingDashboardService
          .updateClosingGroupTask(action.payload, action.payload.closingGroupTaskID)
          .pipe(
            map(() => new UpdateClosingGroupTaskSuccess()),
            catchError(error => of(new UpdateClosingGroupTaskFailure(error)))
          );
      })
    )
  );

  closeClosingGroup$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CloseClosingGroup>(EClosingDashBoardActions.CLOSE_CLOSING_GROUP),
      map((action: CloseClosingGroup) => action),
      switchMap(action => {
        const { closingGroupId, tspId, accountPeriod, reviewerId } = action.payload;
        return this._closingGroupService
          .closePeriodForClosingGroup(
            closingGroupId,
            convertAccountPeriodToDate(accountPeriod),
            tspId,
            reviewerId
          )
          .pipe(
            map(() => new CloseClosingGroupSuccess()),
            catchError(error => of(new CloseClosingGroupFailure(error)))
          );
      })
    )
  );

  refreshGroupProcesses$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<RefreshGroupProcesses>(EClosingDashBoardActions.REFRESH_GROUP_PROCESSES),
      map((action: RefreshGroupProcesses) => action),
      switchMap(action => {
        const { closingGroupId, tspId, accountPeriod } = action.payload;
        return this._groupProcessesService
          .getProcessesForReview(convertAccountPeriodToDate(accountPeriod), closingGroupId, tspId)
          .pipe(
            map(
              response =>
                new RefreshGroupProcessesSuccess({
                  processCollection: response,
                  closingGroupId,
                })
            ),
            catchError(error => of(new RefreshGroupProcessesFailure({ error, closingGroupId })))
          );
      })
    )
  );

  runTieOutScript$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<RunTieOutScript>(EClosingDashBoardActions.RUN_TIE_OUT_SCRIPT),
    map((action: RunTieOutScript) => action),
    switchMap(action => {
      const { tspId, accountPeriod, taskId } = action.payload;
      return this._closingDashboardService
        .postClosingGroupTaskScript(taskId, convertAccountPeriodToDate(accountPeriod), tspId)
        .pipe(
          map(() => new RunTieOutScriptSuccess()),
          catchError(error => of(new RunTieOutScriptFailure(error)))
        );
    })
  ));
}
