import { Amendment, Contract, QuantityLocation, QuantityPath } from '@gms/contract-api';
import { ServiceRequest } from '@gms/servicerequest-api';
import { CreateAmendment } from 'app/store/contracts/contracts.actions';

import { IGridContract } from 'app/store/contracts/contracts.model';
import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { dateUtils } from 'shared/utils/date.utils';

export enum EContractLabels {
  TARIFF = 'Tariff',
  NON_TARIFF = 'Non-Tariff',
}

export const contractStatusCodeToColorMap = {
  A: TagColor.GREEN,
  T: TagColor.RED,
  V: TagColor.GRAY,
  E: TagColor.DARK_BLUE,
  I: TagColor.WHITE,
  P: TagColor.YELLOW,
  S: TagColor.PRIMARY_BLUE,
  R: TagColor.PRIMARY_BLUE,
};

export enum EContractAttributes {
  NON_CONFORMING_AGREEMENT,
  RELEASABLE,
  CONTRACTUAL_ROFR,
  REGULATORY_ROFR,
  MAX_RATE = 8,
  DISCOUNTED_RATE = 9,
  NEGOTIATED_RATE = 10,
  REDUNDANT_RIGHTS,
  EXPANSION,
  ROFR_NOT_EXERCISED,
  SEME_OTHER,
  NEGOTIATED_FUEL = 17,
  AMENDABLE,
}

export const mapContractToGridContract = (contract: Contract): IGridContract => {
  return {
    ...contract,
    statusColor: contractStatusCodeToColorMap[(contract.status || {}).code],
  };
};

export const mapContractsListToGridContractsList = (
  contractsList: Array<Contract>
): Array<IGridContract> => contractsList.map(mapContractToGridContract);

export const extractLocationIdsForQuantityLocations = (quantityLocations: QuantityLocation[]) => {
  const locationIds = [];
  if (quantityLocations) {
    quantityLocations.forEach(({ locationId }) => {
      if (locationId && !locationIds.includes(locationId)) {
        locationIds.push(locationId);
      }
    });
  }
  return locationIds;
};

export const extractLocationIdsForQuantityPaths = (quantityPaths: QuantityPath[]) => {
  const locationIds = [];
  if (quantityPaths) {
    quantityPaths.forEach(({ beginLocationId, endLocationId }) => {
      if (beginLocationId && !locationIds.includes(beginLocationId)) {
        locationIds.push(beginLocationId);
      }
      if (endLocationId && !locationIds.includes(endLocationId)) {
        locationIds.push(endLocationId);
      }
    });
  }
  return locationIds;
};

export const mapSeasonIdToOrder = {
  1: 0,
  2: 2,
  5: 1,
  6: 3,
};

export const sortSeasonalColumns = (columnHeaders): void => {
  if (columnHeaders.every(header => header.season && header.season.seasonId)) {
    columnHeaders.sort(
      (a, b) => mapSeasonIdToOrder[a.season.seasonId] - mapSeasonIdToOrder[b.season.seasonId]
    );
  }
};

export const sanitizeAmendment = (payload: Amendment): void => {
  dateUtils.setDateFieldAsYYYY_MM_DD(payload, 'dateEffective');
  dateUtils.setDateFieldAsYYYY_MM_DD(payload, 'dateExpire');
};
