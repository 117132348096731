import { HttpErrorResponse } from '@angular/common/http';
import { WorkflowTask, WorkflowTaskCollection } from '@gms/workflow-api';

export interface IWorkflowTaskActionState {
  loading: boolean;
  error: HttpErrorResponse;
  success: boolean;
  task: WorkflowTask;
  taskCollection: WorkflowTaskCollection;
}

export const intialWorkflowTaskActionState: IWorkflowTaskActionState = {
  loading: false,
  error: null,
  success: false,
  task: null,
  taskCollection: null,
};
