import { Action } from '@ngrx/store';
import { PostingParamsModel, PostingResponseModel } from './posting-response.model';
import { InfoPostMenuItems } from "@gms/reporting-api/model/infoPostMenuItems";

export enum EPostingsActions {
  FETCH_POSTING_LIST = '[POSTING] Fetch Posting list',
  FETCH_POSTING_LIST_SUCCESS = '[POSTING] Fetch Posting list Success',
  FETCH_POSTING_LIST_ERROR = '[POSTING] Fetch Posting list Failure',

  FETCH_POSTING_LIST_BY_ID = '[POSTING] Fetch Posting Data By ID',
  FETCH_POSTING_LIST_BY_ID_SUCCESS = '[POSTING] Fetch Posting Data By ID Success',
  FETCH_POSTING_LIST_BY_ID_FAILURE = '[POSTING] Fetch Posting Data By ID Failure',

  CREATE_NEW_POSTING = '[POSTING] Create New Posting',
  CREATE_NEW_POSTING_SUCCESS = '[POSTING] Create New Posting Success',
  CREATE_NEW_POSTING_FAILURE = '[POSTING] Create New Posting Failure',

  DELETE_POSTING = '[POSTING] Delete Posting',
  DELETE_POSTING_SUCCESS = '[POSTING] Delete Posting success',
  DELETE_POSTING_FAILURE = '[POSTING] Delete Posting failure',

  FETCH_MENU_ITEM_LIST = '[POSTING] Fetch Menu Item List',
  FETCH_MENU_ITEM_LIST_SUCCESS = '[POSTING] Fetch Menu Item List success',
  FETCH_MENU_ITEM_LIST_FAILURE = '[POSTING] Fetch Menu Item List failure',


  POSTING_FILTER_LIST = '[POSTING] Post Posting Filter Data',
  FETCH_POSTING_FILTER_LIST = '[POSTING] Fetch Posting Filter Data',
  UPDATE_POSTING_FILTER_FLAG = '[POSTING] Update Posting Filter Data Flag',
}

export interface IUploadPostingPayload {
  tspId: number;
  description: string;
  menuItem: number;
  active: boolean;
  datetimePostingEffective: any;
  datetimePostingExpire: any;
  document: File;
  alternateDocument: File;
  contractId: string;
  postImmediately: boolean;
  displayOrder?: number;
  updateDateTimePostingEffective: boolean;
  additionalProperties?: string;
  userId: number
}

export interface PostingDetailsPayload {
  tsp_id: number,
  infopost_id: number,
  date: Date
}

export class LoadPostingListRequestAction implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST;

  constructor(public payload: PostingParamsModel) { }
}

export class LoadPostingListSuccessAction implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_SUCCESS;

  constructor(public payload: PostingResponseModel[]) { }
}
export class LoadPostingListFailureAction implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_ERROR;

  constructor(public payload: { error: Error }) { }
}

export class GetPostingDetailsById implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_BY_ID;

  constructor(public payload: PostingDetailsPayload) { }
}

export class GetPostingDetailsByIdSuccess implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_BY_ID_SUCCESS;

  constructor(public payload: PostingResponseModel) { }
}

export class GetPostingDetailsByIdFailure implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_LIST_BY_ID_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class CreatePostingAction implements Action {
  public readonly type = EPostingsActions.CREATE_NEW_POSTING;

  constructor(public payload: IUploadPostingPayload) { }
}
export class CreatePostingSuccessAction implements Action {
  public readonly type = EPostingsActions.CREATE_NEW_POSTING_SUCCESS;

  constructor(public payload: PostingResponseModel[]) { }
}
export class CreatePostingFailureAction implements Action {
  public readonly type = EPostingsActions.CREATE_NEW_POSTING_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class DeletePostingAction implements Action {
  public readonly type = EPostingsActions.DELETE_POSTING;

  constructor(public payload: PostingDetailsPayload) { }
}
export class DeletePostingActionSuccess implements Action {
  public readonly type = EPostingsActions.DELETE_POSTING_SUCCESS;

  constructor(public payload: boolean) { }
}
export class DeletePostingActionFailure implements Action {
  public readonly type = EPostingsActions.DELETE_POSTING_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class FetchPostingMenuItemsAction implements Action {
  public readonly type = EPostingsActions.FETCH_MENU_ITEM_LIST;

  constructor(public tspID: number) { }
}
export class FetchPostingMenuItemsSuccess implements Action {
  public readonly type = EPostingsActions.FETCH_MENU_ITEM_LIST_SUCCESS;

  constructor(public payload: InfoPostMenuItems) { }
}
export class FetchPostingMenuItemsFailure implements Action {
  public readonly type = EPostingsActions.FETCH_MENU_ITEM_LIST_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class FetchPostingFilterData implements Action {
  public readonly type = EPostingsActions.FETCH_POSTING_FILTER_LIST;

  constructor() { }
}

export class PostFilterData implements Action {
  public readonly type = EPostingsActions.POSTING_FILTER_LIST;

  constructor(public payload: PostingParamsModel) { }
}

export class UpdatePostFilterDataFlag implements Action {
  public readonly type = EPostingsActions.UPDATE_POSTING_FILTER_FLAG;

  constructor(public istriggerSearchApi: boolean) { }
}


export type PostingsActions =
  | LoadPostingListRequestAction
  | LoadPostingListSuccessAction
  | LoadPostingListFailureAction
  | GetPostingDetailsById
  | GetPostingDetailsByIdSuccess
  | GetPostingDetailsByIdFailure
  | DeletePostingAction
  | DeletePostingActionSuccess
  | DeletePostingActionFailure
  | CreatePostingAction
  | CreatePostingSuccessAction
  | CreatePostingFailureAction
  | FetchPostingMenuItemsAction
  | FetchPostingMenuItemsSuccess
  | FetchPostingMenuItemsFailure
  | FetchPostingFilterData
  | PostFilterData
  | UpdatePostFilterDataFlag;
