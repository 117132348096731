import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  ImbalanceParkAndLoanService,
  StorageStorageTransfersService,
  StorageStorageTransfersTcetqService,
  StorageTransferContractsService,
  StorageTransferDetail,
  StorageTransferDetailPair,
  StorageTransferNnsOfferCollection,
} from '@gms/imbalance-api';
import { convertAccountPeriodToDateOffsetTimezone } from 'app/modules/accounting/utils/journal-entries-utils';
import { ToastService } from 'shared/services/toast.service';

import { saveAs } from '@progress/kendo-file-saver';
import { EStorageTransferActions as StorageXferActionTypes } from 'app/store/storage-transfer/storage-transfer.utils';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  CreateStorageTransfer,
  CreateStorageTransferFailure,
  CreateStorageTransferNNS,
  CreateStorageTransferNNSSuccess,
  CreateStorageTransferNNSFailure,
  CreateStorageTransferSuccess,
  EStorageTransferActions,
  ExportParkAndLoanCollection,
  ExportParkAndLoanCollectionFailure,
  ExportParkAndLoanCollectionSuccess,
  FetchParkAndLoanCollection,
  FetchParkAndLoanCollectionFailure,
  FetchParkAndLoanCollectionSuccess,
  FetchStorageTCETQCollection,
  FetchStorageTCETQCollectionFailure,
  FetchStorageTCETQCollectionSuccess,
  FetchStorageTransferNNSOffers,
  FetchStorageTransferNNSOffersFailure,
  FetchStorageTransferNNSOffersSuccess,
  GetStorageTransferById,
  GetStorageTransferByIdFailure,
  GetStorageTransferByIdSuccess,
  GetStorageTransferCollection,
  GetStorageTransferCollectionFailure,
  GetStorageTransferCollectionSuccess,
  UpdateStorageTransfer,
  UpdateStorageTransferFailure,
  UpdateStorageTransferSuccess,
  WithdrawStorageTransfer,
  WithdrawStorageTransferFailure,
  WithdrawStorageTransferSuccess,
} from './storage-transfer.actions';

@Injectable({ providedIn: 'root' })
export class StorageTransferEffects {
  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _toastService: ToastService,
    private _storageTransfersService: StorageStorageTransfersService,
    private _storageTcetqService: StorageStorageTransfersTcetqService,
    private _parkAndLoanService: ImbalanceParkAndLoanService,
    private _storageTransferContractsService: StorageTransferContractsService
  ) {}

  getStorageTransferCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStorageTransferCollection>(
        EStorageTransferActions.FETCH_STORAGE_TRANSFER_COLLECTION
      ),
      map((action: GetStorageTransferCollection) => action),
      switchMap(action => {
        const {
          tspId,
          accountPeriod,
          svcReqNameId,
          statusId,
          sortDescriptors,
          pageSize,
          pageNumber,
        } = action.payload;
        const stringedAccountPeriod = accountPeriod.mon + '-01-' + accountPeriod.year;
        // const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;
        // TO DO: Implement sortBy
        return this._storageTransfersService
          .getStorageTransfers(
            stringedAccountPeriod,
            tspId,
            svcReqNameId,
            pageNumber,
            pageSize,
            null, //sortBy
            statusId
          )
          .pipe(
            map(response => new GetStorageTransferCollectionSuccess(response)),
            catchError(error => of(new GetStorageTransferCollectionFailure(error)))
          );
      })
    )
  );

  getStorageTransferById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStorageTransferById>(EStorageTransferActions.FETCH_STORAGE_TRANSFER_BY_ID),
      map((action: GetStorageTransferById) => action),
      switchMap(action => {
        return this._storageTransfersService.getStorageTransferById(action.payload).pipe(
          map((response: StorageTransferDetail) => new GetStorageTransferByIdSuccess(response)),
          catchError(error => of(new GetStorageTransferByIdFailure(error)))
        );
      })
    )
  );

  createStorageTransfer$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateStorageTransfer>(EStorageTransferActions.CREATE_STORAGE_TRANSFER),
      map((action: CreateStorageTransfer) => action),
      switchMap(action => {
        return this._storageTransfersService.addStorageTransfer(action.payload).pipe(
          map((newStorageTransfer: StorageTransferDetail) => {
            const acctPerSplit = newStorageTransfer.accountingPeriod.toString().split('-');
            this._toastService.success('This transfer was successfully submitted.');
            this._router.navigate(['/storage/storage-transfer'], {
              state: {
                transferAccountPeriodId: parseInt(acctPerSplit[1], 10).toString() + acctPerSplit[0],
              },
            });
            return new CreateStorageTransferSuccess(newStorageTransfer);
          }),
          catchError(error => of(new CreateStorageTransferFailure(error)))
        );
      })
    )
  );

  CreateStorageTransferNNS$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateStorageTransferNNS>(EStorageTransferActions.CREATE_STORAGE_TRANSFER_NNS),
      map((action: CreateStorageTransferNNS) => action),
      switchMap(action => {
        return this._storageTransfersService.addStorageTransferNns(action.payload).pipe(
          map((newStorageTransfer: StorageTransferDetailPair) => {
            const acctPerSplit = newStorageTransfer.storageTransfer1.accountingPeriod //check this later...
              .toString()
              .split('-');
            this._toastService.success('This transfer was successfully submitted.');
            this._router.navigate(['/storage/storage-transfer'], {
              state: {
                transferAccountPeriodId: parseInt(acctPerSplit[1], 10).toString() + acctPerSplit[0],
              },
            });
            return new CreateStorageTransferNNSSuccess(newStorageTransfer);
          }),
          catchError(error => of(new CreateStorageTransferNNSFailure(error)))
        );
      })
    )
  );

  FetchStorageTransferNNSOffers$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchStorageTransferNNSOffers>(
        EStorageTransferActions.FETCH_STORAGE_TRANSFERS_NNS_OFFERS
      ),
      map((action: FetchStorageTransferNNSOffers) => action.payload),
      switchMap((payload: { accountingPeriod: string; tspId: number; entityId: number }) => {
        const { accountingPeriod, tspId, entityId } = payload;

        return this._storageTransferContractsService
          .getStorageTransferNnsOffers(accountingPeriod, tspId, entityId)
          .pipe(
            map(
              (storageTransferNnsOfferCollection: StorageTransferNnsOfferCollection) =>
                new FetchStorageTransferNNSOffersSuccess({
                  offers: storageTransferNnsOfferCollection.Offers,
                })
            ),
            catchError(error => of(new FetchStorageTransferNNSOffersFailure(error)))
          );
      })
    )
  );

  updateStorageTransfer$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateStorageTransfer>(EStorageTransferActions.UPDATE_STORAGE_TRANSFER),
      map((action: UpdateStorageTransfer) => action),
      switchMap(action => {
        return this._storageTransfersService
          .confirmOrRejectStorageTransfer(action.payload.data, action.payload.data.id)
          .pipe(
            map((updatedStorageTransfer: StorageTransferDetail) => {
              const acctPerSplit = updatedStorageTransfer.accountingPeriod.toString().split('-');
              const msg = 'The transfer has been successfully ';
              const actionType: StorageXferActionTypes = action.payload.actionFlag;

              if (actionType === StorageXferActionTypes.ACCEPT) {
                this._toastService.success(`${msg} accepted.`);
              }
              if (actionType === StorageXferActionTypes.REJECT) {
                this._toastService.success(`${msg} rejected.`);
              }

              if (actionType !== StorageXferActionTypes.PENDING) {
                this._router.navigate(['/storage/storage-transfer'], {
                  state: {
                    transferAccountPeriodId:
                      parseInt(acctPerSplit[1], 10).toString() + acctPerSplit[0],
                  },
                });
                this._toastService.setDisplayDuration(4000);
              }

              return new UpdateStorageTransferSuccess(updatedStorageTransfer);
            }),
            catchError(error => of(new UpdateStorageTransferFailure(error)))
          );
      })
    )
  );

  withdrawStorageTransfer$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<WithdrawStorageTransfer>(EStorageTransferActions.WITHDRAW_STORAGE_TRANSFER),
      map((action: WithdrawStorageTransfer) => action),
      switchMap(action => {
        return this._storageTransfersService
          .withdrawStorageTransfer(action.payload.storageTransferId)
          .pipe(
            map(response => {
              this._toastService.success('The transfer has been successfully withdrawn.');
              this._router.navigate(['/storage/storage-transfer'], {
                state: {
                  transferAccountPeriodId: action.payload.accountingPeriod,
                },
              });
              this._toastService.setDisplayDuration(4000);
              return new WithdrawStorageTransferSuccess();
            }),
            catchError(error => of(new WithdrawStorageTransferFailure(error)))
          );
      })
    )
  );

  fetchParkAndLoanCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchParkAndLoanCollection>(EStorageTransferActions.FETCH_PARK_AND_LOAN_COLLECTION),
      map((action: FetchParkAndLoanCollection) => action),
      switchMap(action => {
        const {
          tspId,
          accountPeriod,
          viewBy,
          svcReqNameId,
          svcReqK,
          pageSize,
          pageNumber,
          dateBegin,
          dateEnd,
        } = action.payload;
        const acctPeriodDate = convertAccountPeriodToDateOffsetTimezone(accountPeriod);

        return this._parkAndLoanService
          .getPalSummary(
            acctPeriodDate,
            pageNumber,
            pageSize,
            tspId,
            viewBy,
            svcReqK,
            dateBegin,
            dateEnd,
            svcReqNameId
          )
          .pipe(
            map(response => new FetchParkAndLoanCollectionSuccess(response)),
            catchError(error => of(new FetchParkAndLoanCollectionFailure(error)))
          );
      })
    )
  );

  exportParkAndLoanCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportParkAndLoanCollection>(EStorageTransferActions.EXPORT_PARK_AND_LOAN_COLLECTION),
      map((action: ExportParkAndLoanCollection) => action),
      switchMap(action => {
        const {
          tspId,
          accountPeriod,
          viewBy,
          svcReqNameId,
          svcReqK,
          dateBegin,
          dateEnd,
        } = action.payload;
        const acctPeriodDate = convertAccountPeriodToDateOffsetTimezone(accountPeriod);

        const fileName = acctPeriodDate
          ? `PALSummary_${accountPeriod.year}-${accountPeriod.mon}.csv`
          : `PALExport.csv`;

        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });
          saveAs(blob, fileName);
        };

        return this._parkAndLoanService
          .getPalSummaryExport(
            acctPeriodDate,
            tspId,
            viewBy,
            svcReqK,
            dateBegin,
            dateEnd,
            svcReqNameId
          )
          .pipe(
            map(response => {
              saveData(response);
              return new ExportParkAndLoanCollectionSuccess();
            }),
            catchError(error => of(new ExportParkAndLoanCollectionFailure(error)))
          );
      })
    )
  );

  getStorageTcetqCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchStorageTCETQCollection>(EStorageTransferActions.FETCH_STORAGE_TCETQ_COLLECTION),
      map((action: FetchStorageTCETQCollection) => action),
      switchMap(action => {
        const { annualPeriod, tspId, svcReqNameId } = action.payload;
        return this._storageTcetqService
          .getTcetqStorageTransfers(tspId, Number(annualPeriod), svcReqNameId)
          .pipe(
            map(response => new FetchStorageTCETQCollectionSuccess(response)),
            catchError(error => of(new FetchStorageTCETQCollectionFailure(error)))
          );
      })
    )
  );
}
