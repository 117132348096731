import { DealSheet, InternalComment } from '@gms/servicerequest-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export enum EDealSheetActions {
  FETCH_DEAL_SHEETS = '[DEAL_SHEETS] fetch DealSheets',
  FETCH_DEAL_SHEETS_ERROR = '[DEAL_SHEETS] fetch DealSheets Error',
  FETCH_DEAL_SHEETS_SUCCESS = '[DEAL_SHEETS] fetch DealSheets Success',

  FETCH_DEAL_SHEET_BY_ID = '[DEAL_SHEETS] fetch DealSheet by Id',

  SAVE_DEAL_SHEET = '[DEAL_SHEETS] save DealSheet',
  SAVE_DEAL_SHEET_SUCCESS = '[DEAL_SHEETS] save DealSheet Success',
  SAVE_DEAL_SHEET_ERROR = '[DEAL_SHEETS] save DealSheet Error',

  UPDATE_EDITING_DEAL_SHEET = '[DEAL_SHEETS] update editingDealSheet',

  UPDATE_DEAL_SHEET = '[DEAL_SHEETS] update DealSheet',
  UPDATE_DEAL_SHEET_SUCCESS = '[DEAL_SHEETS] update save DealSheet Success',
  UPDATE_DEAL_SHEET_ERROR = '[DEAL_SHEETS] update save DealSheet Error',

  DELETE_DEAL_SHEET = '[DEAL_SHEETS] delete DealSheet',
  DELETE_DEAL_SHEET_SUCCESS = '[DEAL_SHEETS] delete save DealSheet Success',
  DELETE_DEAL_SHEET_ERROR = '[DEAL_SHEETS] delete save DealSheet Error',

  ADD_INTERNAL_COMMENT = '[DEAL_SHEETS] add internal Comment',
  ADD_INTERNAL_COMMENT_SUCCESS = '[DEAL_SHEETS] add internal Comment Success',
  ADD_INTERNAL_COMMENT_ERROR = '[DEAL_SHEETS] add internal Comment Error',

  ACKNOWLEDGE_NETWORK_RESULT = '[DEAL_SHEETS] acknowledge network result',

  SET_PAGE_SIZE = '[DEAL_SHEETS] set Page Size',
}

export interface IFetchDealSheetsPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  entityId?: number;
  tspId?: number;
  requestId?: number;
  sheetStatus?: string;
  contractId?: number;
  lastUpdateBegin?: string;
  lastUpdateEnd?: string;
  rateScheduleId?: number;
  assignedTo?: string;
  addedByUserId?: number;
  modifiedByUserId?: number;
  sheetId?: number;
}

export class FetchDealSheets implements Action {
  public readonly type = EDealSheetActions.FETCH_DEAL_SHEETS;

  constructor(public payload: IFetchDealSheetsPayload) {}
}

export class FetchDealSheetsSuccess implements Action {
  public readonly type = EDealSheetActions.FETCH_DEAL_SHEETS_SUCCESS;

  constructor(
    public payload: {
      dealSheets: DealSheet[];
      totalDealSheetsCount: number;
    }
  ) {}
}

export class FetchDealSheetsError implements Action {
  public readonly type = EDealSheetActions.FETCH_DEAL_SHEETS_ERROR;

  constructor(public error: DealSheet) {}
}

export class FetchDealSheetById implements Action {
  public readonly type = EDealSheetActions.FETCH_DEAL_SHEET_BY_ID;

  constructor(public payload: number) {}
}

export class SaveDealSheet implements Action {
  public readonly type = EDealSheetActions.SAVE_DEAL_SHEET;

  constructor(public payload: DealSheet, public prefer?: string) {}
}

export class SaveDealSheetSuccess implements Action {
  public readonly type = EDealSheetActions.SAVE_DEAL_SHEET_SUCCESS;

  constructor(public payload: DealSheet) {}
}

export class SaveDealSheetError implements Action {
  public readonly type = EDealSheetActions.SAVE_DEAL_SHEET_ERROR;

  constructor(public error: DealSheet) {}
}

export class UpdateEditingDealSheet implements Action {
  public readonly type = EDealSheetActions.UPDATE_EDITING_DEAL_SHEET;

  constructor(public payload: DealSheet) {}
}

export class UpdateDealSheet implements Action {
  public readonly type = EDealSheetActions.UPDATE_DEAL_SHEET;

  constructor(public payload: DealSheet, public id: number, public prefer?: string) {}
}

export class UpdateDealSheetSuccess implements Action {
  public readonly type = EDealSheetActions.UPDATE_DEAL_SHEET_SUCCESS;

  constructor(public payload: DealSheet) {}
}

export class UpdateDealSheetError implements Action {
  public readonly type = EDealSheetActions.UPDATE_DEAL_SHEET_ERROR;

  constructor(public error: DealSheet) {}
}

export class DeleteDealSheet implements Action {
  public readonly type = EDealSheetActions.DELETE_DEAL_SHEET;

  constructor(public payload: DealSheet) {}
}

export class DeleteDealSheetSuccess implements Action {
  public readonly type = EDealSheetActions.DELETE_DEAL_SHEET_SUCCESS;
}

export class DeleteDealSheetError implements Action {
  public readonly type = EDealSheetActions.DELETE_DEAL_SHEET_ERROR;

  constructor(public payload: { error: DealSheet }) {}
}

export class AcknowledgeNetworkResult implements Action {
  public readonly type = EDealSheetActions.ACKNOWLEDGE_NETWORK_RESULT;
}

export class SetPageSize implements Action {
  public readonly type = EDealSheetActions.SET_PAGE_SIZE;

  constructor(public pageSize: number) {}
}

export class AddInternalComment implements Action {
  public readonly type = EDealSheetActions.ADD_INTERNAL_COMMENT;

  constructor(public payload: InternalComment) {}
}

export class AddInternalCommentSuccess implements Action {
  public readonly type = EDealSheetActions.ADD_INTERNAL_COMMENT_SUCCESS;

  constructor(public payload: InternalComment) {}
}

export class AddInternalCommentError implements Action {
  public readonly type = EDealSheetActions.ADD_INTERNAL_COMMENT_ERROR;

  constructor(public error: Error) {}
}

export type DealSheetActions =
  | FetchDealSheets
  | FetchDealSheetsError
  | FetchDealSheetsSuccess
  | FetchDealSheetById
  | UpdateEditingDealSheet
  | UpdateDealSheet
  | UpdateDealSheetSuccess
  | UpdateDealSheetError
  | SaveDealSheet
  | SaveDealSheetSuccess
  | SaveDealSheetError
  | AcknowledgeNetworkResult
  | SetPageSize
  | DeleteDealSheet
  | DeleteDealSheetSuccess
  | DeleteDealSheetError
  | AddInternalComment
  | AddInternalCommentSuccess
  | AddInternalCommentError;
