import { createSelector } from '@ngrx/store';

import { Entity, User } from '@gms/user-api';
import { IAppState } from 'app/store/app/app.state';
import { isTokenExpired, tokenExpiredTime } from 'shared/utils/jwt.utils';
import { IAuthState } from './auth.state';

export const selectAuth = (state: IAppState) => state.authState;
export const selectAccessToken = createSelector(
  selectAuth,
  (state: IAuthState) => state.accessToken
);
export const selectIsAccessTokenExpired = createSelector(
  selectAuth,
  (state: IAuthState) => isTokenExpired(state.accessToken)
);
export const selectAccessTokenExpiredTime = createSelector(
  selectAuth,
  (state: IAuthState) => tokenExpiredTime(state.accessToken)
);
export const selectUser = createSelector(
  selectAuth,
  (state: IAuthState): User => state.user.data
);
export const selectUserEntities = createSelector(
  selectAuth,
  (state: IAuthState): Array<Entity> => (state.user.data ? state.user.data.entities || [] : [])
);
export const selectUserLoading = createSelector(
  selectAuth,
  (state: IAuthState): Boolean =>
    Boolean(state.user && state.user.requestStatus && state.user.requestStatus.loading)
);
export const selectRoles = createSelector(
  selectAuth,
  (state: IAuthState) => state.roles
);
export const selectCookiesAccepted = createSelector(
  selectAuth,
  (state: IAuthState) => state.cookiesAccepted
);
export const selectIsAuthenticated = createSelector(
  selectAuth,
  (state: IAuthState) => state.isAuthenticated
);
export const selectIsADFSLogin = createSelector(
  selectAuth,
  (state: IAuthState) => state.isADFSLogin
);
export const selectLogoutTime = createSelector(
  selectAuth,
  (state: IAuthState) => state.logoutTime
);
export const selectRefreshToken = createSelector(
  selectAuth,
  (state: IAuthState) => state.refreshToken
);
export const selectWarningLogoutTime = createSelector(
  selectAuth,
  (state: IAuthState) => state.warningLogoutTime
);

export const selectError = createSelector(
  selectAuth,
  (state: IAuthState) => state.error
);

export const selectLoading = createSelector(
  selectAuth,
  (state: IAuthState) => state.loginLoading
);
export const selectUpdatePasswordLoading = createSelector(
  selectAuth,
  (state: IAuthState) => state.updatePasswordLoading
);
export const selectUpdatePasswordError = createSelector(
  selectAuth,
  (state: IAuthState) => state.updatePasswordError
);

export const selectRouteConfigs = createSelector(
  selectAuth,
  (state: IAuthState) => state.routeRoleConfigs
);
export const selectRouteConfigsLoading = createSelector(
  selectAuth,
  (state: IAuthState) => state.routeRoleConfigsLoading
);
export const selectRouteConfigsError = createSelector(
  selectAuth,
  (state: IAuthState) => state.routeRoleConfigsError
);
export const selectInvalidSsoUserId = createSelector(
  selectAuth,
  (state: IAuthState) => state.invalidSsoUserId
);
export const selectAuthResources = createSelector(
  selectAuth,
  (state: IAuthState) => state.authResources
);
export const selectIsInternal = createSelector(
  selectAuth,
  (state: IAuthState) => state.isInternal
);

export const selectMfaInfo = createSelector(
  selectAuth,
  (state: IAuthState) => state.mfa.data
);

export const selectMfaError = createSelector(
  selectAuth,
  (state: IAuthState) => state.mfa.requestStatus.httpError
);

export const selectMfaLoading = createSelector(
  selectAuth,
  (state: IAuthState) => state.mfa.requestStatus.loading
);

export const selectWebsocketTicket = createSelector(
  selectAuth,
  (state: IAuthState) => state.websocketTicket
);

export const selectInvalidSsoUserIdFlag = createSelector(
  selectAuth,
  (state: IAuthState) => state.invalidSsoUserId
);

export const selectSaveUserIdFlag = createSelector(
  selectAuth,
  (state: IAuthState) => state.saveUserId
);
