import { AmendmentActions, EAmendmentActions } from './amendments.actions';
import { IAmendmentState, initialAmendmentState } from './amendments.state';

export function amendmentsReducer(
  state = initialAmendmentState,
  action: AmendmentActions
): IAmendmentState {
  switch (action.type) {
    case EAmendmentActions.FETCH_AMENDMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EAmendmentActions.FETCH_AMENDMENTS_SUCCESS:
      return {
        ...state,
        amendments: action.payload.amendments,
        totalAmendmentsCount: action.payload.totalAmendmentsCount,
        networkResultStatus: EAmendmentActions.FETCH_AMENDMENTS_SUCCESS,
        loading: false,
        error: null,
      };
    case EAmendmentActions.FETCH_AMENDMENTS_FAILURE:
      return {
        ...state,
        networkResultStatus: EAmendmentActions.FETCH_AMENDMENTS_FAILURE,
        error: action.payload.error,
        loading: false,
      };

    case EAmendmentActions.FETCH_AMENDMENT_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EAmendmentActions.FETCH_AMENDMENT_BY_ID_SUCCESS:
      return {
        ...state,
        editingAmendment: action.payload.amendment,
        networkResultStatus: null,
        loading: false,
        error: null,
        serviceRequest: action.payload.amendment.serviceRequest,
      };
    case EAmendmentActions.FETCH_AMENDMENT_BY_ID_FAILURE:
      return {
        ...state,
        networkResultStatus: EAmendmentActions.FETCH_AMENDMENT_BY_ID_FAILURE,
        error: action.payload.error,
        loading: false,
      };
    case EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID_SUCCESS:
      return {
        ...state,
        editingDealSheetAmendment: action.payload.dealSheetAmendment,
        dealSheetNetworkStatus: EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID_SUCCESS,
        loading: false,
        error: null,
      };
    case EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID_FAILURE:
      return {
        ...state,
        dealSheetNetworkStatus: EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID_FAILURE,
        error: action.payload.error,
        loading: false,
      };
    case EAmendmentActions.CREATE_AMENDMENT:
      return {
        ...state,
        loading: true,
      };
    case EAmendmentActions.CREATE_AMENDMENT_SUCCESS:
      return {
        ...state,
        editingAmendment: action.payload.amendment,
        networkResultStatus: EAmendmentActions.CREATE_AMENDMENT_SUCCESS,
        loading: false,
        error: null,
      };
    case EAmendmentActions.CREATE_AMENDMENT_FAILURE:
      return {
        ...state,
        networkResultStatus: EAmendmentActions.CREATE_AMENDMENT_FAILURE,
        loading: false,
        editingAmendment: action.amendment,
      };
    case EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT:
      return {
        ...state,
        loading: true,
      };
    case EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT_SUCCESS:
      return {
        ...state,
        editingDealSheetAmendment: action.payload.amendment,
        dealSheetNetworkStatus: EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT_SUCCESS,
        loading: false,
        error: null,
      };
    case EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT_FAILURE:
      return {
        ...state,
        dealSheetNetworkStatus: EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT_FAILURE,
        loading: false,
        error: action.payload.error,
      };

    case EAmendmentActions.UPDATE_AMENDMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EAmendmentActions.UPDATE_AMENDMENT_SUCCESS:
      return {
        ...state,
        editingAmendment: action.payload.amendment,
        networkResultStatus: EAmendmentActions.UPDATE_AMENDMENT_SUCCESS,
        loading: false,
        error: null,
        serviceRequest: action.payload.amendment.serviceRequest,
      };
    case EAmendmentActions.UPDATE_AMENDMENT_FAILURE:
      return {
        ...state,
        networkResultStatus: EAmendmentActions.UPDATE_AMENDMENT_FAILURE,
        loading: false,
        editingAmendment: action.amendment,
      };
    case EAmendmentActions.UPDATE_EDITING_AMENDMENT:
      return {
        ...state,
        editingAmendment: action.payload,
        loading: false,
        error: null,
      };

    case EAmendmentActions.UPDATE_CLIENT_SIDE_SERVICE_REQUEST:
      return {
        ...state,
        serviceRequest: { ...state.serviceRequest, ...action.payload },
        isAmendmentUpdated: true,
      };

    case EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        dealSheetNetworkStatus: EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT_SUCCESS,
        editingDealSheetAmendment: action.payload.amendment,
      };
    }
    case EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        editingDealSheetAmendment: action.amendment,
        dealSheetNetworkStatus: EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT_FAILURE,
      };
    }
    case EAmendmentActions.ACKNOWLEDGE_NETWORK_REQUEST: {
      return {
        ...state,
        networkResultStatus: null,
        dealSheetNetworkStatus: null,
      };
    }
    default:
      return state;
  }
}
