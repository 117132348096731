import { Injectable } from '@angular/core';
import { ServiceProvider } from '@gms/tsp-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/app/app.state';
import { selectTsps } from 'app/store/tsp/tsps.selectors';
import { merge, Observable } from 'rxjs';
import { combineLatest, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { EHeaderActions, SetTsp, SetTspFromTspId } from './header.actions';
import { VIEW_ALL_TSP } from './header.reducers';

@Injectable()
export class HeaderEffects {
  constructor(private _actions$: Actions, private _store: Store<IAppState>) {}

  SetTspFromTspId$: Observable<any> = createEffect(() =>
    merge(
      this._actions$.pipe(
        ofType<SetTspFromTspId>(EHeaderActions.SET_TSP_FROM_TSP_ID),
        combineLatest(
          this._store.pipe(
            select(selectTsps),
            filter((tsps: ServiceProvider[]) => Boolean(tsps && tsps.length)),
            take(1)
          )
        )
      ),
      this._actions$.pipe(
        ofType<SetTspFromTspId>(EHeaderActions.SET_TSP_FROM_TSP_ID),
        withLatestFrom(
          this._store.pipe(
            select(selectTsps),
            filter((tsps: ServiceProvider[]) => Boolean(tsps && tsps.length)),
            take(1)
          )
        )
      )
    ).pipe(
      map(([action, tsps]: [SetTspFromTspId, ServiceProvider[]]) => {
        if (tsps) {
          const selectedTsp = tsps.find(
            (tsp: ServiceProvider) => tsp.providerId === action.payload.tspId
          );
          return new SetTsp({ tsp: selectedTsp ? selectedTsp : VIEW_ALL_TSP });
        }
      })
    )
  );
}
