import { Routes } from '@angular/router';

export const Sso_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/login/pages/login/login.module').then(m => m.LoginModule),
    data: { ssoLogin: true },
  },
];
