import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * this class prevents memory leaks because a subscription does not go away automatically
 * allows to easily unsubscribe when component is destoryed as long as you don't override ngOverrideOnDestory
 * if you do overrride function then manually call ngOnDestroy
 */

// https://stackoverflow.com/questions/54979862/ensure-super-ondestroy-in-derived-component/56303624
// tslint:disable-next-line
class REQUIRED_SUPER {} //important to not export it, only we should be able to create it.

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class HasSubscriptions implements OnDestroy {
  private subscriptions: Array<Subscription> = [];

  ngOnDestroy(): REQUIRED_SUPER {
    this.subscriptions.forEach(subscription => {
      if (!!subscription && subscription instanceof Subscription) subscription.unsubscribe();
    });
    return new REQUIRED_SUPER();
  }

  public addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  public addSubscriptions(subscriptions: Array<Subscription>): void {
    subscriptions.forEach(subscription => this.subscriptions.push(subscription));
  }
}
