import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import {
  EMarketSectorOverridesActions,
  MarketSectorOverridesActions,
} from './market-sector-overrides.actions';
import {
  IMarketSectorOverridesState,
  initMarketSectorOverridesState,
} from './market-sector-overrides.state';

export function marketSectorOverridesReducer(
  state = initMarketSectorOverridesState,
  action: MarketSectorOverridesActions
): IMarketSectorOverridesState {
  switch (action.type) {
    case EMarketSectorOverridesActions.FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION:
      return {
        ...state,
        marketSectorOverridesCollection: createLoadingState(state.marketSectorOverridesCollection),
      };
    case EMarketSectorOverridesActions.FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION_SUCCESS:
      return {
        ...state,
        marketSectorOverridesCollection: createSuccessState(action.payload),
      };
    case EMarketSectorOverridesActions.FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION_FAILURE:
      return {
        ...state,
        marketSectorOverridesCollection: createErrorState(action.payload),
      };
    case EMarketSectorOverridesActions.UPDATE_MARKET_SECTOR_OVERRIDES:
      return {
        ...state,
        updateMarketSectorOverrides: { loading: true, httpError: null },
      };
    case EMarketSectorOverridesActions.UPDATE_MARKET_SECTOR_OVERRIDES_SUCCESS:
      return {
        ...state,
        marketSectorOverridesCollection: createSuccessState(action.payload),
        updateMarketSectorOverrides: { loading: false, httpError: null },
      };
    case EMarketSectorOverridesActions.UPDATE_MARKET_SECTOR_OVERRIDES_FAILURE:
      return {
        ...state,
        updateMarketSectorOverrides: { loading: false, httpError: action.payload },
      };
    case EMarketSectorOverridesActions.DELETE_MARKET_SECTOR_OVERRIDE:
      return {
        ...state,
        deleteMarketSectorOverride: { loading: true, httpError: null },
      };
    case EMarketSectorOverridesActions.DELETE_MARKET_SECTOR_OVERRIDE_SUCCESS:
      return {
        ...state,
        deleteMarketSectorOverride: { loading: false, httpError: null },
      };
    case EMarketSectorOverridesActions.DELETE_MARKET_SECTOR_OVERRIDE_FAILURE:
      return {
        ...state,
        deleteMarketSectorOverride: { loading: false, httpError: action.payload },
      };
    default:
      return state;
  }
}
