import { MarketSectorOverride, MarketSectorOverrideCollection } from '@gms/accounting-api';
import { emptyRequestStatus, IDataState, IRequestStatus } from '../app/app.models';

export interface IMarketSectorOverridesState {
  marketSectorOverridesCollection: IDataState<MarketSectorOverrideCollection>;
  updateMarketSectorOverrides: IRequestStatus;
  deleteMarketSectorOverride: IRequestStatus;
}

export const initMarketSectorOverridesState: IMarketSectorOverridesState = {
  marketSectorOverridesCollection: {
    data: {
      marketSectorOverrides: null,
      total: null,
    },
    requestStatus: { ...emptyRequestStatus },
  },
  updateMarketSectorOverrides: { ...emptyRequestStatus },
  deleteMarketSectorOverride: { ...emptyRequestStatus },
};
