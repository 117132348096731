// Form control names
export const LAST_RUN_START_DATE_CONTROL_NAME = 'lastRunStartDate';
export const LAST_RUN_START_TIME_CONTROL_NAME = 'lastRunStartTime';
export const LAST_RUN_END_DATE_CONTROL_NAME = 'lastRunEndDate';
export const LAST_RUN_END_TIME_CONTROL_NAME = 'lastRunEndTime';
export const NEXT_RUN_START_DATE_CONTROL_NAME = 'nextRunStartDate';
export const NEXT_RUN_START_TIME_CONTROL_NAME = 'nextRunStartTime';
export const NEXT_RUN_END_DATE_CONTROL_NAME = 'nextRunEndDate';
export const NEXT_RUN_END_TIME_CONTROL_NAME = 'nextRunEndTime';
export const CONFIG_NAME_CONTROL_NAME = 'configName';
export const CONFIG_IDS_CONTROL_NAME = 'configIds';
export const RECURRENCE_CONTROL_NAME = 'recurrence';
export const STATUSES_CONTROL_NAME = 'statuses';
