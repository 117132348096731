import { UntypedFormGroup } from '@angular/forms';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import get from 'lodash/get';
import { Observable } from 'rxjs';
import { CellMenuItem } from 'shared/components/form/dropdown/dropdown.component';
import { DynamicDropdownComponent } from 'shared/components/table/dynamic-cell-components/dropdown-cell/dropdown-cell.component';

export class DynamicDropdownCell {
  dynamicComponentType: typeof DynamicDropdownComponent;
  data: DropdownOptions;
}

export class DynamicDropdownCellMenuItem<T = any> extends CellMenuItem<T> {}

export interface DropdownOptions {
  id?: string;
  data?: any;
  data$?: Observable<any>;
  defaultItem?: any;

  valueField?: string;
  textField?: string;

  controlName: string;
  group: UntypedFormGroup;

  popupSettings?: PopupSettings;
  valuePrimitive?: boolean;

  // typeahead options
  filterable?: boolean;
  debounceTime?: string;
  minLength?: number;

  allowFreeFormInput?: boolean;

  filterChanged?: (searchPhrase: string, control: string) => void;
  valueChanged?: Function;
  opened?: Function;
  dropDownFocused?: Function;

  retainDataObservable?: boolean;
  placeholder?: string;
  tableOverflow?: boolean;
  updateOnModelChange?: boolean;
  isLoading?: boolean;
  isLoading$?: Observable<boolean>;
}

export const createDropDownCell = (dropdownOptions: DropdownOptions): DynamicDropdownCell => {
  const controlName = dropdownOptions.controlName;

  const prependId = get(dropdownOptions, 'id') || controlName;
  const id = prependId + '_Dropdown';

  return {
    dynamicComponentType: DynamicDropdownComponent,
    data: {
      ...dropdownOptions,
      id: id,
    },
  };
};
