import { Injectable } from '@angular/core';
import {
  AwardCollection,
  AwardsService,
  BidCollection,
  BidsService,
  CapacityReleaseService,
  LocationOptionInput,
  LocationOptionsResponse,
  Offer,
  OfferCollection,
  OffersService,
  WithdrawalCollection,
} from '@gms/capacityrelease-api';
import { ContractService } from '@gms/contract-api';
import { EntityService } from '@gms/entity-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SortDescriptor } from '@progress/kendo-data-query';
import { EBidStatuses } from 'app/modules/capacity-release/pages/bids/bids.enums';
import {
  FetchAwardNetQuantitiesById,
  FetchAwardNetQuantitiesByIdFailure,
  FetchAwardNetQuantitiesByIdSuccess,
  FetchAwardSeededLocationsById,
  FetchAwardSeededLocationsByIdFailure,
  FetchAwardSeededLocationsByIdSuccess,
  FetchLinkedAwardById,
  FetchLinkedAwardByIdFailure,
  FetchLinkedAwardByIdSuccess,
  FetchMultipleOfferLocationsOptions,
  FetchMultipleOfferLocationsOptionsFailure,
  FetchMultipleOfferLocationsOptionsSuccess,
  FetchWithdrawals,
  FetchWithdrawalsFailure,
  FetchWithdrawalsSuccess,
} from 'app/store/capacity-release/capacity-release.actions';
import { retryStrategy } from 'app/store/capacity-release/capacity-release.utils';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';
import { DEBOUNCE_500 } from 'shared/consts/debounce.const';
import { dateUtils } from 'shared/utils/date.utils';
import {
  ConfirmBid,
  ConfirmBidFailure,
  ConfirmBidSuccess,
  CopyOffer,
  CopyOfferFailure,
  CopyOfferSuccess,
  CreateMatchingBid,
  CreateMatchingBidFailure,
  CreateMatchingBidSuccess,
  DeleteBid,
  DeleteBidFailure,
  DeleteBidSuccess,
  DeleteOffer,
  DeleteOfferFailure,
  DeleteOfferSuccess,
  ECapacityReleaseActions,
  FetchAwardById,
  FetchAwardByIdFailure,
  FetchAwardByIdSuccess,
  FetchAwardOffer,
  FetchAwardOfferFailure,
  FetchAwardOfferSuccess,
  FetchAwards,
  FetchAwardsFailure,
  FetchAwardsForFilters,
  FetchAwardsForFiltersFailure,
  FetchAwardsForFiltersSuccess,
  FetchAwardsSuccess,
  FetchBidById,
  FetchBidByIdFailure,
  FetchBidByIdSuccess,
  FetchBiddableOfferById,
  FetchBiddableOfferByIdFailure,
  FetchBiddableOfferByIdSuccess,
  FetchBiddableOffers,
  FetchBiddableOffersFailure,
  FetchBiddableOffersSuccess,
  FetchBidEvaluationAwards,
  FetchBidEvaluationAwardsFailure,
  FetchBidEvaluationAwardsSuccess,
  FetchBidEvaluationBids,
  FetchBidEvaluationBidsFailure,
  FetchBidEvaluationBidsSuccess,
  FetchBidEvaluationOffers,
  FetchBidEvaluationOffersFailure,
  FetchBidEvaluationOffersSuccess,
  FetchBids,
  FetchBidsFailure,
  FetchBidsForFilters,
  FetchBidsForFiltersFailure,
  FetchBidsForFiltersSuccess,
  FetchBidsSuccess,
  FetchContractOverview,
  FetchContractOverviewFailure,
  FetchContractOverviewSuccess,
  FetchDictionary,
  FetchDictionaryFailure,
  FetchDictionarySuccess,
  FetchOfferBids,
  FetchOfferBidsFailure,
  FetchOfferBidsSuccess,
  FetchOfferById,
  FetchOfferByIdFailure,
  FetchOfferByIdSuccess,
  FetchOfferLocationOptions,
  FetchOfferLocationOptionsFailure,
  FetchOfferLocationOptionsSuccess,
  FetchOffers,
  FetchOffersFailure,
  FetchOffersSuccess,
  FetchOriginalAwardById,
  FetchOriginalAwardByIdFailure,
  FetchOriginalAwardByIdSuccess,
  FetchPreapprovedBidders,
  FetchPreapprovedBiddersFailure,
  FetchPreapprovedBiddersSuccess,
  FetchWithdrawnBids,
  FetchWithdrawnBidsFailure,
  FetchWithdrawnBidsSuccess,
  GetOffersLookup,
  GetOffersLookupFailure,
  GetOffersLookupSuccess,
  PostAward,
  PostAwardFailure,
  PostAwardSuccess,
  PostBid,
  PostBidFailure,
  PostBidSuccess,
  PostOffer,
  PostOfferFailure,
  PostOfferSuccess,
  PutAward,
  PutAwardFailure,
  PutAwardSuccess,
  PutBid,
  PutBidFailure,
  PutBidSuccess,
  PutOffer,
  PutOfferFailure,
  PutOfferSuccess,
  RejectBid,
  RejectBidFailure,
  RejectBidSuccess,
  WithdrawBid,
  WithdrawBidFailure,
  WithdrawBidSuccess,
  WithdrawOffer,
  WithdrawOfferFailure,
  WithdrawOfferSuccess,
} from './capacity-release.actions';
import { inflate, deflate, gzip } from 'pako';
import { HttpErrorResponse } from '@angular/common/http';
import { sortDefinitions } from 'app/store/default-sort.definitions';

@Injectable()
export class CapacityReleaseEffects {
  constructor(
    private _actions$: Actions,
    private _capacityReleaseService: CapacityReleaseService,
    private _offersService: OffersService,
    private _bidsService: BidsService,
    private _awardsService: AwardsService,
    private _contractService: ContractService,
    private _entityService: EntityService
  ) {}

  FetchBiddableOffers: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBiddableOffers>(ECapacityReleaseActions.FetchBiddableOffers),
    map((action: FetchBiddableOffers) => action.payload),
    switchMap(payload => {
      const sortBy = payload.sortDescriptors.map((sd: SortDescriptor) => `${sd.field}+${sd.dir}`);
      return this._bidsService
        .getBiddableOffers(
          payload.entityIds,
          payload.pageSize,
          payload.pageNumber,
          sortBy,
          null, // releaserId - we will use entityIds instead
          payload.contractId,
          payload.contractNumber,
          payload.offerId,
          payload.postingDt,
          payload.releaseTermStartDt,
          payload.releaseTermEndDt,
          payload.biddableFlag,
          payload.busDayFlag,
          payload.marketRateFlag,
          payload.recallNotifPeriods,
          payload.offerStatus,
          payload.processStatus,
          payload.releaseStatus,
          payload.biddableOnly,
          payload.tspId,
          payload.viewMode,
          payload.includeLocations
        )
        .pipe(
          map(
            (offerCollection: OfferCollection) =>
              new FetchBiddableOffersSuccess({
                offers: offerCollection,
                searchOffers: payload.searchOffers,
              })
          ),
          catchError(error => of(new FetchBiddableOffersFailure({ error: error })))
        );
    })
  ));

  FetchOffers: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchOffers>(ECapacityReleaseActions.FetchOffers),
    map((action: FetchOffers) => action.payload),
    switchMap(payload => {
      const sortBy = payload.sortDescriptors.map((sd: SortDescriptor) => `${sd.field}+${sd.dir}`);
      return this._offersService
        .getOffers(
          payload.entityIds,
          payload.pageSize,
          payload.pageNumber,
          sortBy,
          null, // releaserId - we will use entityIds instead
          payload.contractId,
          payload.offerId,
          payload.postingDt,
          payload.releaseTermStartDt,
          payload.releaseTermEndDt,
          payload.biddableFlag,
          payload.busDayFlag,
          payload.marketRateFlag,
          payload.recallNotifPeriods,
          payload.offerStatus,
          payload.processStatus,
          payload.releaseStatus,
          payload.biddableOnly,
          payload.tspId,
          payload.viewMode,
          payload.includeLocations ?? false
        )
        .pipe(
          map(
            (offerCollection: OfferCollection) =>
              new FetchOffersSuccess({
                offers: offerCollection,
                searchOffers: payload.searchOffers,
              })
          ),
          catchError(error => of(new FetchOffersFailure({ error: error })))
        );
    })
  ));

  FetchOfferById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchOfferById>(ECapacityReleaseActions.FetchOfferById),
    map((action: FetchOfferById) => action.payload),
    switchMap(payload => {
      return this._offersService.getOffer(payload.offerId, payload.viewMode).pipe(
        map(offer => new FetchOfferByIdSuccess({ offer })),
        catchError(error => of(new FetchOfferByIdFailure({ error })))
      );
    })
  ));

  FetchBiddableOfferById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBiddableOfferById>(ECapacityReleaseActions.FetchBiddableOfferById),
    map((action: FetchBiddableOfferById) => action.payload),
    switchMap(payload => {
      return this._bidsService.getBiddableOffer(payload.offerId, payload.viewMode).pipe(
        map(offer => new FetchBiddableOfferByIdSuccess({ offer })),
        catchError(error => of(new FetchBiddableOfferByIdFailure({ error })))
      );
    })
  ));

  FetchWithdrawals: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchWithdrawals>(ECapacityReleaseActions.FetchWithdrawals),
    map((action: FetchWithdrawals) => action.payload),
    switchMap(payload => {
      return this._capacityReleaseService
        .getWithdrawals(
          payload.pageSize,
          payload.pageNumber,
          payload.sortBy,
          payload.capacityReleaseTypeId,
          payload.transactionTypeId,
          payload.tspId,
          payload.offerId,
          payload.bidId,
          payload.awardId,
          payload.postingDt
        )
        .pipe(
          map(
            (withdrawalCollection: WithdrawalCollection) =>
              new FetchWithdrawalsSuccess({ withdrawals: withdrawalCollection })
          ),
          catchError(error => of(new FetchWithdrawalsFailure({ error })))
        );
    })
  ));

  FetchBidById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBidById>(ECapacityReleaseActions.FetchBidById),
    map((action: FetchBidById) => action.payload),
    switchMap(payload => {
      return this._bidsService.getBid(payload.bidId).pipe(
        map(bid => new FetchBidByIdSuccess({ bid })),
        catchError(error => of(new FetchBidByIdFailure({ error })))
      );
    })
  ));

  FetchAwardById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchAwardById>(ECapacityReleaseActions.FetchAwardById),
    map((action: FetchAwardById) => action.payload),
    switchMap(payload => {
      return this._awardsService.getAwardById(payload.awardId).pipe(
        map(award => new FetchAwardByIdSuccess({ award })),
        catchError(error => of(new FetchAwardByIdFailure({ error })))
      );
    })
  ));

  FetchOriginalAwardById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchOriginalAwardById>(ECapacityReleaseActions.FetchOriginalAwardById),
    map((action: FetchOriginalAwardById) => action.payload),
    switchMap(payload => {
      return this._awardsService.getAwardById(payload.awardId).pipe(
        map(award => new FetchOriginalAwardByIdSuccess({ award })),
        catchError(error => of(new FetchOriginalAwardByIdFailure({ error })))
      );
    })
  ));

  FetchLinkedAwardById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchLinkedAwardById>(ECapacityReleaseActions.FetchLinkedAwardById),
    map((action: FetchLinkedAwardById) => action.payload),
    switchMap(payload => {
      return this._awardsService.getAwardById(payload.awardId).pipe(
        map(award => new FetchLinkedAwardByIdSuccess({ award })),
        catchError(error => of(new FetchLinkedAwardByIdFailure({ error })))
      );
    })
  ));

  FetchAwardNetQuantitiesById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchAwardNetQuantitiesById>(ECapacityReleaseActions.FetchAwardNetQuantitiesById),
    map((action: FetchAwardNetQuantitiesById) => action.payload),
    switchMap(payload => {
      return this._awardsService
        .getAwardNetQuantitiesById(payload.awardId, payload.startDate, payload.endDate)
        .pipe(
          map(award => new FetchAwardNetQuantitiesByIdSuccess({ award })),
          retryWhen(retryStrategy()),
          catchError(error => of(new FetchAwardNetQuantitiesByIdFailure({ error })))
        );
    })
  ));

  FetchAwardSeededLocationsById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchAwardSeededLocationsById>(ECapacityReleaseActions.FetchAwardSeededLocationsById),
    map((action: FetchAwardSeededLocationsById) => action.payload),
    switchMap(payload => {
      return this._awardsService
        .getAwardLocationsById(payload.awardId, payload.startDate, payload.endDate)
        .pipe(
          map(award => new FetchAwardSeededLocationsByIdSuccess({ award })),
          retryWhen(retryStrategy()),
          catchError(error => of(new FetchAwardSeededLocationsByIdFailure({ error })))
        );
    })
  ));

  FetchDictionary: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchDictionary>(ECapacityReleaseActions.FetchDictionary),
    switchMap(() => {
      return this._capacityReleaseService.getDictionary().pipe(
        map(dictionary => new FetchDictionarySuccess({ dictionary })),
        catchError(error => of(new FetchDictionaryFailure({ error })))
      );
    })
  ));

  PostOffer: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostOffer>(ECapacityReleaseActions.PostOffer),
    map((action: PostOffer) => action.payload),
    switchMap(payload => {
      payload.offer.dateReleaseTermStart = dateUtils.getDateAsYYYY_MM_DD(
        new Date(payload.offer.dateReleaseTermStart)
      );
      payload.offer.dateReleaseTermEnd = dateUtils.getDateAsYYYY_MM_DD(
        new Date(payload.offer.dateReleaseTermEnd)
      );
      return this._offersService.postOffer(payload.offer).pipe(
        map(offer => new PostOfferSuccess({ offer })),
        retryWhen(retryStrategy()),
        catchError(error => of(new PostOfferFailure({ error })))
      );
    })
  ));

  PutOffer: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PutOffer>(ECapacityReleaseActions.PutOffer),
    map((action: PutOffer) => action.payload),
    switchMap(payload => {
      if (payload.offer.dateReleaseTermStart.toString().length > 10) {
        payload.offer.dateReleaseTermStart = dateUtils.getDateAsYYYY_MM_DD(
          new Date(payload.offer.dateReleaseTermStart)
        );
      }

      if (payload.offer.dateReleaseTermEnd.toString().length > 10) {
        payload.offer.dateReleaseTermEnd = dateUtils.getDateAsYYYY_MM_DD(
          new Date(payload.offer.dateReleaseTermEnd)
        );
      }

      let stringBody = JSON.stringify(payload.offer);
      let compressMethod = null;
      const blobBody = new Blob([stringBody]);
      if((blobBody.size / (1024 ** 2)) > 1){
        const compressedOffer = gzip(stringBody);
        compressMethod = 'gzip';
        const base64Offer = btoa(compressedOffer.reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, ''));
        console.log("Compress size from "+ blobBody.size +" to " + compressedOffer.buffer.byteLength);
        stringBody = base64Offer;
      }

      return this._offersService
        .putOffer(stringBody, payload.offer.offerId, payload.step, payload.isDraft, payload.locationsModified, compressMethod)
        .pipe(
          map(offer => new PutOfferSuccess({ offer })),
          retryWhen(retryStrategy()),
          catchError(error => of(new PutOfferFailure({ error })))
        );
    })
  ));

  GetOffersLookup: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetOffersLookup>(ECapacityReleaseActions.GetOffersLookup),
    map((action: GetOffersLookup) => action.payload),
    switchMap(payload => {
      return this._offersService.getOffersLookup(payload.entityIds, payload.contractNumbers).pipe(
        map(offersSearchLookup => new GetOffersLookupSuccess({ offersSearchLookup })),
        catchError(error => of(new GetOffersLookupFailure({ error })))
      );
    })
  ));

  DeleteOffer: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<DeleteOffer>(ECapacityReleaseActions.DeleteOffer),
    map((action: DeleteOffer) => action.payload),
    switchMap(payload => {
      return this._offersService.setOfferDeleted(payload.offerId).pipe(
        map(offer => new DeleteOfferSuccess()),
        catchError(error => of(new DeleteOfferFailure({ error })))
      );
    })
  ));

  WithdrawOffer: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<WithdrawOffer>(ECapacityReleaseActions.WithdrawOffer),
    map((action: WithdrawOffer) => action.payload),
    switchMap(payload => {
      return this._offersService.setOfferWithdrawn(payload.offerId).pipe(
        map(offer => new WithdrawOfferSuccess()),
        catchError(error => of(new WithdrawOfferFailure({ error })))
      );
    })
  ));

  CopyOffer: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CopyOffer>(ECapacityReleaseActions.CopyOffer),
    map((action: CopyOffer) => action.payload),
    switchMap(payload => {
      return this._offersService.copyOffer(payload.offerId, payload.offerCreatorId).pipe(
        map(offer => new CopyOfferSuccess({ offer })),
        catchError(error => of(new CopyOfferFailure({ error })))
      );
    })
  ));

  FetchBids: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBids>(ECapacityReleaseActions.FetchBids),
    map((action: FetchBids) => action.payload),
    switchMap(payload => {
      const sortBy =
        payload.sortDescriptors && payload.sortDescriptors.length
          ? payload.sortDescriptors.map((sd: SortDescriptor) => `${sd.field}+${sd.dir}`)
          : [sortDefinitions.getBids];

      const bidderIds =
        payload.bidderIds && payload.bidderIds.length
          ? payload.bidderIds.map(id => id.toString())
          : null;

      // TODO: map prearranged flag

      return this._bidsService
        .getBids(
          payload.pageSize,
          payload.pageNumber,
          sortBy,
          payload.tspId,
          bidderIds,
          payload.myBidsOnly,
          payload.bidNumber,
          payload.offerNumber,
          null,
          payload.bidStatus,
          payload.releaserId,
          payload.contractId,
          payload.contractNumber,
          payload.bidderReleaseTermStartDate,
          payload.bidderReleaseTermEndDate,
          payload.prearrangedFlag,
          payload.postedBeginDateTime
        )
        .pipe(
          map(bidsCollection => new FetchBidsSuccess({ bidsCollection })),
          catchError(error => of(new FetchBidsFailure({ error })))
        );
    })
  ));

  FetchBidsForFilters: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBidsForFilters>(ECapacityReleaseActions.FetchBidsForFilters),
    map((action: FetchBidsForFilters) => action.payload),
    switchMap(payload => {
      const sortBy =
        payload.sortDescriptors && payload.sortDescriptors.length
          ? payload.sortDescriptors.map((sd: SortDescriptor) => `${sd.field}+${sd.dir}`)
          : [sortDefinitions.getBids];

      const bidderIds =
        payload.bidderIds && payload.bidderIds.length
          ? payload.bidderIds.map(id => id.toString())
          : null;

      return this._bidsService
        .getBids(
          payload.pageSize,
          payload.pageNumber,
          sortBy,
          payload.tspId,
          bidderIds,
          payload.myBidsOnly,
          payload.bidNumber,
          payload.offerNumber,
          null,
          payload.bidStatus,
          payload.releaserId,
          payload.contractId,
          payload.contractNumber,
          null,
          null,
          null,
          payload.postedBeginDateTime
        )
        .pipe(
          map(bidsCollection => new FetchBidsForFiltersSuccess({ bids: bidsCollection.bids })),
          catchError(error => of(new FetchBidsForFiltersFailure({ error })))
        );
    })
  ));

  DeleteBid: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<DeleteBid>(ECapacityReleaseActions.DeleteBid),
    map((action: DeleteBid) => action.payload),
    switchMap(payload => {
      return this._bidsService.setBidDeleted(payload.bidId).pipe(
        map(_ => new DeleteBidSuccess()),
        catchError(error => of(new DeleteBidFailure({ error })))
      );
    })
  ));

  ConfirmBid: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<ConfirmBid>(ECapacityReleaseActions.ConfirmBid),
    map((action: ConfirmBid) => action.payload),
    switchMap(payload => {
      return this._bidsService.confirmBid(payload.bidId).pipe(
        map(bid => new ConfirmBidSuccess({ bid })),
        retryWhen(retryStrategy()),
        catchError(error => of(new ConfirmBidFailure({ error })))
      );
    })
  ));

  RejectBid: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<RejectBid>(ECapacityReleaseActions.RejectBid),
    map((action: RejectBid) => action.payload),
    switchMap(payload => {
      return this._bidsService.rejectBid(payload.bidId).pipe(
        map(bid => new RejectBidSuccess({ bid })),
        catchError(error => of(new RejectBidFailure({ error })))
      );
    })
  ));

  WithdrawBid: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<WithdrawBid>(ECapacityReleaseActions.WithdrawBid),
    map((action: WithdrawBid) => action.payload),
    switchMap(payload => {
      return this._bidsService.setBidWithdrawn(payload.bidId).pipe(
        map(bid => new WithdrawBidSuccess({ bid })),
        catchError(error => of(new WithdrawBidFailure({ error })))
      );
    })
  ));

  PostBid: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostBid>(ECapacityReleaseActions.PostBid),
    map((action: PostBid) => action.payload),
    switchMap(payload => {
      return this._bidsService.postBid(payload.bid).pipe(
        map(bid => new PostBidSuccess({ bid })),
        catchError(error => of(new PostBidFailure({ error })))
      );
    })
  ));

  PutBid: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PutBid>(ECapacityReleaseActions.PutBid),
    map((action: PutBid) => action.payload),
    switchMap(payload => {
      return this._bidsService.putBid(payload.bid, payload.bidId).pipe(
        map(bid => new PutBidSuccess({ bid })),
        catchError(error => of(new PutBidFailure({ error })))
      );
    })
  ));

  FetchOfferBids: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchOfferBids>(ECapacityReleaseActions.FetchOfferBids),
    map((action: FetchOfferBids) => action.payload),
    switchMap(payload => {
      const bidderIds =
        payload.bidderIds && payload.bidderIds.length
          ? payload.bidderIds.map(id => id.toString())
          : null;

      return this._bidsService
        .getBids(null, null, [sortDefinitions.getBids], null, bidderIds, null, null, payload.offerId, payload.offerIds)
        .pipe(
          map(bidsCollection => new FetchOfferBidsSuccess({ bidsCollection })),
          catchError(error => of(new FetchOfferBidsFailure({ error })))
        );
    })
  ));

  FetchWithdrawnBids: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchWithdrawnBids>(ECapacityReleaseActions.FetchWithdrawnBids),
    map((action: FetchWithdrawnBids) => action.payload),
    switchMap(payload => {
      return this._bidsService
        .getBids(
          null,
          null,
          [sortDefinitions.getBids],
          null,
          [payload.bidderId.toString()],
          payload.myBidsOnly,
          null,
          payload.offerId,
          null,
          [EBidStatuses.WITHDRAWN]
        )
        .pipe(
          map(bidCollection => new FetchWithdrawnBidsSuccess({ bidCollection })),
          catchError(error => of(new FetchWithdrawnBidsFailure({ error })))
        );
    })
  ));

  FetchAwards: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchAwards>(ECapacityReleaseActions.FetchAwards),
    map((action: FetchAwards) => action.payload),
    switchMap(payload => {
      const sortBy =
        payload.sortDescriptors && payload.sortDescriptors.length
          ? payload.sortDescriptors.map((sd: SortDescriptor) => `${sd.field}+${sd.dir}`)
          : null;

      const bidderIds =
        payload.bidderIds && payload.bidderIds.length
          ? payload.bidderIds.map(id => id.toString())
          : null;

      // TODO: map prearranged flag

      return this._awardsService
        .getAwards(
          payload.pageSize,
          payload.pageNumber,
          sortBy,
          payload.tspId,
          payload.capacityReleaseType, // Capacity release type
          bidderIds,
          payload.bidNumber,
          payload.offerNumber,
          null,
          payload.awardNumber,
          payload.capacityReleaseId, // Capacity release ID
          payload.awardStatus,
          payload.naesbStatus,
          payload.recallReputStatus, // Recall/Reput Status
          payload.releaserId,
          payload.contractId,
          payload.contractNumber,
          payload.replacementShipperContractNumber,
          payload.replacementShipperContractId,
          payload.bidderReleaseTermStartDate,
          payload.bidderReleaseTermEndDate,
          payload.postedBeginDateTime,
          payload.recallableOnly || null,
          payload.startDate
        )
        .pipe(
          map(awardsCollection => new FetchAwardsSuccess({ awardsCollection })),
          catchError(error => of(new FetchAwardsFailure({ error })))
        );
    })
  ));

  FetchAwardsForFilters: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchAwardsForFilters>(ECapacityReleaseActions.FetchAwardsForFilters),
    map((action: FetchAwardsForFilters) => action.payload),
    switchMap(payload => {
      const sortBy =
        payload.sortDescriptors && payload.sortDescriptors.length
          ? payload.sortDescriptors.map((sd: SortDescriptor) => `${sd.field}+${sd.dir}`)
          : null;

      const bidderIds =
        payload.bidderIds && payload.bidderIds.length
          ? payload.bidderIds.map(id => id.toString())
          : null;

      return this._awardsService
        .getAwards(
          payload.pageSize,
          payload.pageNumber,
          sortBy,
          payload.tspId,
          payload.capacityReleaseType, // Capacity release type
          bidderIds,
          payload.bidNumber,
          payload.offerNumber,
          null,
          payload.awardNumber,
          payload.capacityReleaseId, // Capacity release ID
          payload.awardStatus,
          payload.naesbStatus,
          payload.recallReputStatus, // Recall/Reput Status
          payload.releaserId,
          payload.contractId,
          payload.contractNumber,
          payload.replacementShipperContractId,
          payload.replacementShipperContractNumber,
          payload.bidderReleaseTermStartDate,
          payload.bidderReleaseTermEndDate,
          payload.postedBeginDateTime,
          payload.recallableOnly || null,
          payload.startDate
        )
        .pipe(
          map(
            awardsCollection =>
              new FetchAwardsForFiltersSuccess({ awards: awardsCollection.awards })
          ),
          catchError(error => of(new FetchAwardsForFiltersFailure({ error })))
        );
    })
  ));

  FetchAwardOffer: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchAwardOffer>(ECapacityReleaseActions.FetchAwardOffer),
    map((action: FetchAwardOffer) => action.payload),
    mergeMap(payload => {
      return this._offersService.getOffer(payload.offerId).pipe(
        map((offer: Offer) => new FetchAwardOfferSuccess({ offers: [offer] })),
        catchError(error => of(new FetchAwardOfferFailure({ error })))
      );
    })
  ));

  FetchBidEvaluationOffers: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBidEvaluationOffers>(ECapacityReleaseActions.FetchBidEvaluationOffers),
    map((action: FetchBidEvaluationOffers) => action.payload),
    switchMap(_payload => {
      return this._offersService
        .getOffers(
          null, //entityIds
          _payload.pageSize || 25, //pageSize
          1, //pageNumber
          ['dateTimePosted+desc'], //sortBy
          null, //releaserId
          null, //contractId
          null, //offerId
          null, //postingDt
          null, //releaseTermStartDt
          null, //releaseTermEndDt
          null, //biddableFlag
          null, //busDayFlag
          null, //marketRateFlag
          null, //recallNotifPeriods
          null, //offerStatus
          null, //processStatus
          null, //releaseStatus
          null, //biddableOnly
          _payload.tspId, //tspId
          'BID_EVALUATION', //viewMode
          false //includeLocations
        )
        .pipe(
          map(offerCollection => new FetchBidEvaluationOffersSuccess({ offerCollection })),
          retryWhen(retryStrategy()),
          catchError(error => of(new FetchBidEvaluationOffersFailure({ error })))
        );
    })
  ));

  //TODO: ask which sort is appropriate.
  FetchBidEvaluationBids: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBidEvaluationBids>(ECapacityReleaseActions.FetchBidEvaluationBids),
    map((action: FetchBidEvaluationBids) => action.payload),
    mergeMap(payload => {
      return this._bidsService
        .getBids(
          payload.pageSize,
          payload.pageNumber,
          [sortDefinitions.getBids],
          payload.tspId,
          null,
          payload.myBidsOnly,
          null,
          null,
          payload.offerIds
        )
        .pipe(
          map(
            (bidsCollection: BidCollection) =>
              new FetchBidEvaluationBidsSuccess({
                bids: bidsCollection.bids,
                offerIds: payload.offerIds,
              })
          ),
          catchError(error =>
            of(new FetchBidEvaluationBidsFailure({ error, offerIds: payload.offerIds }))
          )
        );
    })
  ));

  FetchBidEvaluationAwards: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchBidEvaluationAwards>(ECapacityReleaseActions.FetchBidEvaluationAwards),
    map((action: FetchBidEvaluationAwards) => action.payload),
    mergeMap(payload => {
      return this._awardsService
        .getAwards(
          payload.pageSize,
          payload.pageNumber,
          null,
          payload.tspId,
          null, // Capacity release type
          null,
          null,
          null,
          payload.offerIds
        )
        .pipe(
          map(
            (awardsCollection: AwardCollection) =>
              new FetchBidEvaluationAwardsSuccess({
                awards: awardsCollection.awards,
                offerIds: payload.offerIds,
              })
          ),
          catchError(error =>
            of(new FetchBidEvaluationAwardsFailure({ error, offerIds: payload.offerIds }))
          )
        );
    })
  ));

  CreateMatchingBid: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CreateMatchingBid>(ECapacityReleaseActions.CreateMatchingBid),
    map((action: CreateMatchingBid) => action.payload),
    switchMap(payload => {
      return this._bidsService.createMatchingBid(payload.offerId, payload.bidId).pipe(
        map(matchingBid => new CreateMatchingBidSuccess({ matchingBid })),
        catchError(error => of(new CreateMatchingBidFailure({ error })))
      );
    })
  ));

  PostAward: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAward>(ECapacityReleaseActions.PostAward),
    map((action: PostAward) => action.payload),
    debounceTime(DEBOUNCE_500),
    switchMap(payload => {
      return this._awardsService.postAward(payload.award).pipe(
        map(award => new PostAwardSuccess({ award })),
        catchError(error => of(new PostAwardFailure({ error })))
      );
    })
  ));

  PutAward: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PutAward>(ECapacityReleaseActions.PutAward),
    map((action: PutAward) => action.payload),
    debounceTime(DEBOUNCE_500),
    switchMap(payload => {
      return this._awardsService.putAward(payload.award, payload.award.capacityReleaseId).pipe(
        map(award => new PutAwardSuccess({ award })),
        catchError(error => of(new PutAwardFailure({ error })))
      );
    })
  ));

  FetchContractOverview: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchContractOverview>(ECapacityReleaseActions.FetchContractOverview),
    map((action: FetchContractOverview) => action.payload),
    switchMap(payload => {
      return this._contractService.getContractOverviewById(payload.contractId).pipe(
        map(contractOverview => new FetchContractOverviewSuccess({ contractOverview })),
        catchError(error => of(new FetchContractOverviewFailure({ error })))
      );
    })
  ));

  FetchOfferLocationOptions: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchOfferLocationOptions>(ECapacityReleaseActions.FetchOfferLocationOptions),
    map((action: FetchOfferLocationOptions) => action.payload),
    debounceTime(DEBOUNCE_500),
    switchMap(payload => {
      return this._offersService
        .getOfferLocationOptions(
          payload.offerId,
          payload.locqtiId,
          payload.seasnlStartDt,
          payload.seasnlEndDt,
          null, //sequence_id,
          null, //max_offer_qty
          null, //override
          payload.capBlockId,
          payload.capBlockTwoId,
          payload.locationOneId,
          payload.locationTwoId,
          payload.getDelLocs
        )
        .pipe(
          map(
            offerLocationOptions => new FetchOfferLocationOptionsSuccess({ offerLocationOptions })
          ),
          catchError(error => of(new FetchOfferLocationOptionsFailure({ error })))
        );
    })
  ));

  //TODO: what is the right sort for this
  FetchPreapprovedBidders: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchPreapprovedBidders>(ECapacityReleaseActions.FetchPreapprovedBidders),
    map((action: FetchPreapprovedBidders) => action.payload),
    mergeMap(payload => {
      return payload.entityIds.length
        ? this._entityService
            .getEntityHeaders(
              50, //pageSize
              1, //pageNumber
              'dunsNumber', //sortBy
              null, //dunsNumber
              null, //financialId
              null, //legalName
              null, //name
              payload.entityIds, //entityIds
              null, //searchPhrase
              null, //searchScope
              payload.agencyResourceName, //agencyResourceName
              payload.minPermissionOption, //minPermissionOption
              payload.agencyTransactionStartDate //agencyTransactionStartDate
            )
            .pipe(
              map(
                entityCollection =>
                  new FetchPreapprovedBiddersSuccess({
                    preapprovedBidders: entityCollection.entityHeaders,
                  })
              ),
              catchError(error => of(new FetchPreapprovedBiddersFailure({ error })))
            )
        : of(new FetchPreapprovedBiddersSuccess({ preapprovedBidders: [] }));
    })
  ));

  FetchMultipleOfferLocationsOptions: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchMultipleOfferLocationsOptions>(
      ECapacityReleaseActions.FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS
    ),
    map((action: FetchMultipleOfferLocationsOptions) => action),
    switchMap(payload => {
      const requests = payload.locationRanges.map<LocationOptionInput>(locationRange => {
        return {
          locqti_id: Number(locationRange.locqtiId),
          seasnl_start_dt: locationRange.seasnlStartDt,
          seasnl_end_dt: locationRange.seasnlEndDt,
          location1_id: locationRange.locationOneId,
          location2_id: locationRange.locationTwoId,
          cap_block2_id: locationRange.capBlockTwoId,
          cap_block_id: locationRange.capBlockId,
          get_del_locs: locationRange.getDelLocs,
        };
      });
      const offerId = payload.locationRanges[0].offerId;
      const chunks: Observable<LocationOptionsResponse[]>[] = [];
      const chunkSize = 200;
      for (let i = 0; i < requests.length; i += chunkSize) {
        const chunk = requests.slice(i, i + chunkSize);
        chunks.push(
          this._offersService.getOfferBulkLocationOptions(chunk, offerId).pipe(
            retryWhen(retryStrategy()),
            catchError(error => of(error))
          )
        );
      }

      return forkJoin(chunks)
        .pipe(map(response => response.reduce((a, b) => a.concat(b), [])))
        .pipe(
          map(response => {
            if (response.length !== requests.length) {
              return new FetchMultipleOfferLocationsOptionsFailure(new HttpErrorResponse({}));
            }
            return new FetchMultipleOfferLocationsOptionsSuccess({
              locationsOptions: response,
            });
          })
        );
    })
  ));
}
