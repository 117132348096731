import { Amendment, DealSheet, ServiceRequest } from '@gms/servicerequest-api';
import { SortDescriptor } from '@progress/kendo-data-query';

export interface IAmendmentState {
  serviceRequest: ServiceRequest;
  editingAmendment: Amendment;
  amendments: ServiceRequest[];
  editingDealSheetAmendment: DealSheet;
  sort: SortDescriptor[];
  loading: boolean;
  error: Error;
  dealSheetNetworkStatus: string;
  networkResultStatus: string;
  totalAmendmentsCount: number;
  isAmendmentUpdated: boolean;
}

export const initialAmendmentState: IAmendmentState = {
  editingAmendment: {} as Amendment,
  serviceRequest: {},
  amendments: [],
  editingDealSheetAmendment: {} as DealSheet,
  sort: [
    {
      field: 'lastUpdatedDate',
      dir: 'asc',
    },
  ],
  loading: false,
  error: null,
  networkResultStatus: null,
  dealSheetNetworkStatus: null,
  totalAmendmentsCount: 0,
  isAmendmentUpdated: false,
};
