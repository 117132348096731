import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Auctions_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/auctions-dashboard/auctions-dashboard.module').then(
        m => m.AuctionsDashboardModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/auctions',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'create',
    loadChildren: () =>
      import('./pages/auctions-setup/auctions-setup.module').then(m => m.AuctionsSetupModule),

    canActivate: [ResourceGuard],
    data: {
      aclResource: '/auctions',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'draft/:draftId',
    loadChildren: () =>
      import('./pages/auctions-setup/auctions-setup.module').then(m => m.AuctionsSetupModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/auctions',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/auctions-setup/auctions-setup.module').then(m => m.AuctionsSetupModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/auctions',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'auction-room/:id',
    loadChildren: () =>
      import('./pages/auction-room/auction-room.module').then(m => m.AuctionRoomModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/auctions',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
];
