import { WebsocketEvent } from '@gms/websocket-api';
import { Action } from '@ngrx/store';

export type WebsocketsActions = SendWebsocketMessage;

export enum EWebsocketActions {
  SEND_WEBSOCKET_MESSAGE = '[WEBSOCKETS] Send Websocket Message',
}

export class SendWebsocketMessage implements Action {
  public readonly type = EWebsocketActions.SEND_WEBSOCKET_MESSAGE;

  constructor(public payload: WebsocketEvent) {}
}
