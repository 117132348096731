import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuctionUser, AuctionUserCollection } from '@gms/auction-api';
import { DetailRateSchedule } from '@gms/rateschedulev2-api';
import { ServiceProvider } from '@gms/tsp-api';
import { LABELS } from 'app/modules/auctions/labels';
import { isAuctionBidder } from 'app/modules/auctions/utils/auction.utils';
import { ITradeblotterGrid } from 'app/modules/trades/components/tradeblotter-grid/tradeblotter-grid.interface';
import { IDataState } from 'app/store/app/app.models';
import { EPermissionOption, EUserType } from 'app/store/auth/model/enums';
import uniqueId from 'lodash/uniqueId';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EControlErrorType } from 'shared/components/form/form-inline-error-message/control-error';
import {
  PopoverAnimationDirection,
  PopoverHorizontalAlignment,
  PopoverVerticalAlignment,
} from 'shared/components/popover/popover.component';
import { createClickableButtonCell } from 'shared/components/table/dynamic-cell-components/button-cell/button-cell.interface';
import { createCheckboxCell } from 'shared/components/table/dynamic-cell-components/checkbox-cell/checkbox-cell.interface';
import { createClickableIconCell } from 'shared/components/table/dynamic-cell-components/clickable-icon-cell/clickable-icon-cell.interface';
import { createDatePickerCell } from 'shared/components/table/dynamic-cell-components/datepicker-cell/datepicker-cell.interface';
import {
  createDropDownCell,
  DropdownOptions,
  DynamicDropdownCellMenuItem,
} from 'shared/components/table/dynamic-cell-components/dropdown-cell/dropdown-cell.interface';
import { createIconPopoverCell } from 'shared/components/table/dynamic-cell-components/icon-popover-cell/icon-popover-cell.interface';
import { createInputCell } from 'shared/components/table/dynamic-cell-components/input-cell/input-cell.interface';
import { createMultiselectCell } from 'shared/components/table/dynamic-cell-components/multiselect-cell/multiselect-cell.interface';
import { RowOptionsMenu } from 'shared/components/table/dynamic-cell-components/row-options-menu/row-options-menu.interface';
import { createStatusTagCell } from 'shared/components/table/dynamic-cell-components/status-tag-cell/status-tag-cell.interface';
import { cleanDateWithYYYY_MM_DD_HH_MM_SS } from 'shared/services/data-cleaner.service';
import { dateUtils } from 'shared/utils/date.utils';
import {
  ETradeStatuses,
  statusToButtonIconMap,
  statusToColorMap,
  statusToTextMap,
} from 'shared/utils/trade.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { CustomValidators } from 'shared/validators/custom-validators';

const TRADEBLOTTER_DISPLAY_MONTHS = 3;

export enum ETradeblotterTabs {
  IT = 'IT',
  PAL = 'PAL',
  ISS = 'ISS',
}

export enum EPalTypes {
  PARK_LOAN = 'PARK_LOAN',
  UNPARK_PAYBACK = 'UNPARK_PAYBACK',
}

export const ServiceTypeNameToIdMap = {
  [ETradeblotterTabs.PAL]: 3,
  [ETradeblotterTabs.IT]: 4,
  [ETradeblotterTabs.ISS]: 5,
};

export enum LocationType {
  DELIVERY = 'DELIVERY',
  RECEIPT = 'RECEIPT',
  INJECTION = 'INJECTION',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum TradeblotterRoleIds {
  AUCTION = 40,
  EXTERNAL_BWP_COMMERICIAL_REP = 49,
}

export const EXTERNAL_BWP_COMMERICIAL_REP_ROLE_NAME = 'External BWP Commercial Rep';

//detail rate schedule ids for PAL rate schedules that use the single location column
export const SINGLE_LOCATION_PAL_RS = [51, 52, 55, 56];

export interface IDisplayTrade {
  editCheckboxOrError?: any;
  tradeStatusId?: number;
  statusTag?: any;
  traderName?: any;
  serviceRequesterName?: any;
  serviceRequesterEntityId?: number;
  externalTraderName?: any;
  externalTraderUserId?: number;
  tspRateSchedule?: any;
  tspId?: number;
  detailRateScheduleId?: number;
  includesFlexRightsFlag?: any;
  dateLoanParkEffectiveDate?: string;
  dateLoanParkTermExpireDate?: string;
  loanParkTermMaximumQuantityPerDay?: number;
  datePaybackUnparkTermEffectiveDate?: string;
  datePaybackUnparkTermExpireDate?: string;
  paybackUnparkTermMaximumQuantityPerDay?: number;
  fuelRate?: any;
  locations?: any;
  interruptibleDailyInjectionQuantity?: any;
  interruptibleDailyWithdrawalQuantity?: any;
  contractId?: any;
  maxQuantityPerDay?: any;
  termMaximumQuantity?: any;
  receiptLocation?: any;
  receiptLocationId?: number;
  deliveryLocation?: any;
  deliveryLocationId?: number;
  chargePerQuantity?: any;
  effectiveDate?: any;
  expireDate?: any;
  tradeId?: any;
  offerDateTime?: any;
  rowActions?: any;
  rowOptions?: any;
  rowIndex?: any;
  trackByRowId: string;
  canCopyTrade?: any;
  formGroup?: UntypedFormGroup;
}

export const fieldNamesToTableHeadersMap = {
  IT: {
    traderName: 'Trader Name',
    serviceRequesterName: 'Service Requester Name',
    externalTraderName: 'External Trader Name',
    tspRateSchedule: 'TSP/Rate Schedule',
    maxQuantityPerDay: 'Max Qty/Day',
    receiptLocation: 'Receipt Location',
    deliveryLocation: 'Delivery Location',
    chargePerQuantity: 'Charge Per Dth/Day',
    effectiveDate: 'Effective Date',
    expireDate: 'Expire Date',
    canCopyTrade: 'COPY',
  },
  PAL: {
    traderName: 'Trader Name',
    serviceRequesterName: 'Service Requester Name',
    externalTraderName: 'External Trader Name',
    tspRateSchedule: 'TSP/Rate Schedule',
    termMaximumQuantity: 'Max Total Qty',
    receiptLocation: 'Receipt Location',
    deliveryLocation: 'Delivery Location',
    locations: 'Location',
    chargePerQuantity: 'Charge Per Dth/Day',
    includesFlexRightsFlag: 'Incl Flex Rights',
    dateLoanParkEffectiveDate: 'Park/Loan - Effective Date',
    dateLoanParkTermExpireDate: 'Park/Loan - Expire Date',
    loanParkTermMaximumQuantityPerDay: 'Park/Loan - Max Qty/Day',
    datePaybackUnparkTermEffectiveDate: 'Unpark/Payback - Effective Date',
    datePaybackUnparkTermExpireDate: 'Unpark/Payback - Expire Date',
    paybackUnparkTermMaximumQuantityPerDay: 'Unpark/Payback - Max Qty/Day',
  },
  ISS: {
    traderName: 'Trader Name',
    serviceRequesterName: 'Service Requester Name',
    externalTraderName: 'External Trader Name',
    tspRateSchedule: 'TSP/Rate Schedule',
    effectiveDate: 'Contract Begin Date',
    expireDate: 'Primary Term End Date',
    termMaximumQuantity: 'Int Storage Qty',
    interruptibleDailyInjectionQuantity: 'Int Daily Injection Qty',
    interruptibleDailyWithdrawalQuantity: 'Int Daily Withdrawal Qty',
    receiptLocation: 'Injection Location(s)',
    deliveryLocation: 'Withdrawal Location(s)',
    chargePerQuantity: 'Chg Per Dth',
    fuelRate: 'Fuel Ret - Chg/Dth',
  },
};

export interface ITradeblotterLegendItem {
  iconName: string;
  label: string;
}

export const tradeblotterLegendConfig: Array<ITradeblotterLegendItem> = [
  { iconName: 'icon-auth-post', label: LABELS.MOVE_TO_OFFERED },
  { iconName: 'icon-thumbs-up', label: LABELS.MOVE_TO_ACCEPTED },
  { iconName: 'icon-paper-plane', label: LABELS.SUBMIT },
  { iconName: 'icon-delete', label: LABELS.DELETE },
];

export const getBeginningDisplayDate = (): string => {
  const beginDate = dateUtils.addMonthsToDate(
    dateUtils.getToday(),
    -1 * TRADEBLOTTER_DISPLAY_MONTHS
  );
  beginDate.setHours(0, 0, 0, 0);
  return cleanDateWithYYYY_MM_DD_HH_MM_SS(beginDate).toString();
};

export const intitializeFilterFormGroup = (): UntypedFormGroup => {
  return new UntypedFormGroup({
    trader: new UntypedFormControl(null),
    tsp: new UntypedFormControl(null),
    showTodayOnlyCheckbox: new UntypedFormControl(null),
  });
};

export const tradeblotterRowMenuPopoverAlignments = {
  verticalAlignment: PopoverVerticalAlignment.Bottom,
  horizontalAlignment: PopoverHorizontalAlignment.Center,
  anchorVerticalAlignment: PopoverVerticalAlignment.Top,
  anchorHorizontalAlignment: PopoverHorizontalAlignment.Center,
  animationDirection: PopoverAnimationDirection.Up,
};

export const mapTraderIdsToNames = traders => {
  const traderIdToNameMap = {};
  traders.forEach(
    trader => (traderIdToNameMap[trader.userId] = `${trader.firstName} ${trader.lastName}`)
  );
  return traderIdToNameMap;
};

interface ITradeblotterOverflowMenuItems {
  cancelTrade?: RowOptionsMenu;
}

export enum ETradeblotterMenuOptions {
  CANCEL = 'icon-error',
}

export const tradeblotterOverflowMenuItems: ITradeblotterOverflowMenuItems = {
  cancelTrade: {
    icon: ETradeblotterMenuOptions.CANCEL,
    label: 'Cancel Trade',
    onClick: () => {},
    acl: {
      resource: '/auctions',
      accessLevel: EPermissionOption.Edit,
      userType: EUserType.Internal,
    },
    resourceGuardOptions: {
      useAnyTspSelection: true,
    },
  },
};

const getMinEffectiveDate = (gridType: string, startDate = null, tspHolidays = null): Date => {
  if (!startDate) {
    //default to tomorrow
    startDate = dateUtils.getToday();
  }
  return startDate;
};

const getDefaultEffectiveDate = (gridType: string, tspHolidays = null): Date => {
  const minDate = getMinEffectiveDate(gridType, null, tspHolidays);
  return new Date(minDate.setDate(minDate.getDate() + 1));
};

const getDefaultExpireDate = (gridType: string, tspHolidays = null): Date => {
  const defaultEffectiveDate = getDefaultEffectiveDate(gridType, tspHolidays);

  return new Date(defaultEffectiveDate.setDate(defaultEffectiveDate.getDate() + 1));
};

export const processDraftFormErrors = (formGroup: UntypedFormGroup, gridType: string) => {
  const errors = {};
  for (const key in formGroup.controls) {
    if (formGroup.controls.hasOwnProperty(key)) {
      const control: UntypedFormControl = formGroup.controls[key] as UntypedFormControl;
      control.markAsTouched();
      errors[key] = control.errors;
    }
  }
  return Object.keys(errors)
    .map(key => {
      if (errors[key] && errors[key].controlError) {
        return {
          title: fieldNamesToTableHeadersMap[gridType]
            ? fieldNamesToTableHeadersMap[gridType][key]
            : null,
          text: errors[key].controlError.message,
        };
      }
    })
    .filter(item => !!item);
};

export const updateChargeValidationOnHttpError = (
  formGroup: UntypedFormGroup,
  errorMessage: string
) => {
  const chargeControlName = 'chargePerQuantity';
  const chargePerQuantity: UntypedFormControl = formGroup.get(
    chargeControlName
  ) as UntypedFormControl;
  chargePerQuantity.setErrors({ controlError: { type: 0, message: errorMessage } });
  const validatorType = errorMessage.includes('Maximum') ? 'maxNonInclusive' : 'minNonInclusive';
  chargePerQuantity.setValidators([
    chargePerQuantity.validator,
    CustomValidators[validatorType](chargePerQuantity.value, {
      type: EControlErrorType.ERROR,
      message: errorMessage,
    }),
  ]);
  chargePerQuantity.updateValueAndValidity();
};

export const createErrorPopover = errorArray => {
  return createIconPopoverCell({
    popOverTextList: errorArray,
    iconClass: 'icon-block',
    popOverTitle: 'ERRORS',
    verticalAlignment: PopoverVerticalAlignment.Bottom,
    anchorVerticalAlignment: PopoverVerticalAlignment.Top,
    animationDirection: PopoverAnimationDirection.Up,
    updateOnModelChange: true,
  });
};

const applyDateValidators = (
  effectiveDate: UntypedFormControl,
  expireDate: UntypedFormControl,
  gridType: string
) => {
  const baseValidators = [
    CustomValidators.required(),
    CustomValidators.minimumDate(dateUtils.getToday(), {
      type: EControlErrorType.ERROR,
      message: 'Must not be a past date',
    }),
  ];

  const expireNotLessThanEffectiveValidator = CustomValidators.ByReference.minimumDate(
    effectiveDate,
    {
      type: EControlErrorType.ERROR,
      message:
        gridType === ETradeblotterTabs.ISS
          ? 'End date must not be before contract begin date.'
          : 'Expire date must not be before effective date.',
    }
  );
  expireDate.setValidators([...baseValidators, expireNotLessThanEffectiveValidator]);

  const effectiveNotAfterExpireValidator = CustomValidators.ByReference.maximumDate(
    expireDate,
    {
      type: EControlErrorType.ERROR,
      message:
        gridType === ETradeblotterTabs.ISS
          ? 'Contract begin date must not be after end date.'
          : 'Effective date must not be after expire date.',
    }
  );

  effectiveDate.setValidators([...baseValidators, effectiveNotAfterExpireValidator]);
};

const applyDailyInterruptibleQuantityValidators = (
  interruptibleDailyInjectionQuantity: UntypedFormControl,
  interruptibleDailyWithdrawalQuantity: UntypedFormControl,
  interruptibleStorageQuantity: UntypedFormControl
) => {
  const intNotNegativeValidator = CustomValidators.min(0, {
    type: EControlErrorType.ERROR,
    message: 'Quantity cannot be negative',
  });

  const interruptibleStorageQuantityValidator = CustomValidators.ByReference.max(
    interruptibleStorageQuantity,
    {
      type: EControlErrorType.ERROR,
      message: 'Cannot be greater than interruptable storage quantity',
    }
  );

  const baseValidators = [
    CustomValidators.required(),
    intNotNegativeValidator,
    interruptibleStorageQuantityValidator,
  ];

  const bothCannotBeZeroDailyInjectionValidator = CustomValidators.ByReference.bothCannotBeZero(
    interruptibleDailyWithdrawalQuantity,
    {
      type: EControlErrorType.ERROR,
      message: 'Cannot be zero while withdrawal is zero',
    }
  );

  interruptibleDailyInjectionQuantity.setValidators([
    ...baseValidators,
    bothCannotBeZeroDailyInjectionValidator,
  ]);

  const bothCannotBeZeroDailyWithdrawalValidator = CustomValidators.ByReference.bothCannotBeZero(
    interruptibleDailyInjectionQuantity,
    {
      type: EControlErrorType.ERROR,
      message: 'Cannot be zero while injection is zero',
    }
  );

  interruptibleDailyWithdrawalQuantity.setValidators([
    ...baseValidators,
    bothCannotBeZeroDailyWithdrawalValidator,
  ]);
};

const applyUnparkDateValidators = (
  parkEffectiveDate: UntypedFormControl,
  parkExpireDate: UntypedFormControl,
  effectiveDate: UntypedFormControl,
  expireDate: UntypedFormControl
) => {
  const currentExpireValidators = expireDate.validator;
  const currentEffectiveValidators = effectiveDate.validator;

  const expireGreaterThanParkExpireValidator = CustomValidators.ByReference.strictlyLessThanDateTime(
    parkExpireDate,
    {
      type: EControlErrorType.ERROR,
      message: 'Expire date must be after park expire date',
    }
  );
  expireDate.setValidators([currentExpireValidators, expireGreaterThanParkExpireValidator]);

  const effectiveGreaterThanParkEffectiveValidator = CustomValidators.ByReference.strictlyLessThanDateTime(
    parkEffectiveDate,
    {
      type: EControlErrorType.ERROR,
      message: 'Effective date must be after park effective date.',
    }
  );
  effectiveDate.setValidators([
    currentEffectiveValidators,
    effectiveGreaterThanParkEffectiveValidator,
  ]);
};

export const mapDetailRateSchedulesToDropdownVals = (
  detailRateSchedules: Array<DetailRateSchedule>,
  tspList: ServiceProvider[]
) => {
  return detailRateSchedules.map(rs => {
    const { masterRateScheduleConfig, code } = rs;
    const tsp: ServiceProvider = masterRateScheduleConfig
      ? tspList.find(currentTsp => currentTsp.providerId === masterRateScheduleConfig.tspId)
      : null;

    if (tsp) {
      return {
        text: `${tsp ? tsp.shortName : '-'} - ${code}`,
        value: rs.detailRateScheduleId,
        tspId: tsp.providerId,
      };
    } else {
      return {
        text: null,
        value: null,
        tspId: null,
      };
    }
  });
};

const updatePalQuantityValidators = (
  quantityControls: Array<UntypedFormControl>,
  termMaxQuantity: number
) => {
  if (quantityControls && quantityControls.length) {
    quantityControls.forEach(control =>
      control.setValidators([
        CustomValidators.required(),
        CustomValidators.min(1, {
          type: EControlErrorType.ERROR,
          message: 'Quantity must be greater than zero',
        }),
        CustomValidators.max(termMaxQuantity, {
          type: EControlErrorType.ERROR,
          message: 'Daily quantity cannot be greater than term max',
        }),
      ])
    );
  }
};

const createTradeblotterFormControl = (
  validators = null,
  value = null,
  disabled = true,
  updateOn = 'change' as 'change' | 'blur' | 'submit'
) => {
  return new UntypedFormControl({ value, disabled }, { validators, updateOn });
};

export const createDraftRowFormGroup = (gridType: string) => {
  const isPAL = gridType === ETradeblotterTabs.PAL;
  const isIT = gridType === ETradeblotterTabs.IT;
  const isISS = gridType === ETradeblotterTabs.ISS;

  const requiredValidator = CustomValidators.required();
  const intGreaterThanZeroValidator = CustomValidators.min(1, {
    type: EControlErrorType.ERROR,
    message: 'Quantity must be greater than zero',
  });
  const decimalGreaterThanZeroValidator = CustomValidators.min(0.0001, {
    type: EControlErrorType.ERROR,
    message: 'Charge must be greater than zero',
  });

  const formGroup = new UntypedFormGroup(
    {
      traderName: createTradeblotterFormControl([requiredValidator], null, false),
      serviceRequesterName: createTradeblotterFormControl([requiredValidator]),
      externalTraderName: createTradeblotterFormControl(),
      tspRateSchedule: createTradeblotterFormControl([requiredValidator]),
      flexRights: createTradeblotterFormControl(null, false),
      maxQuantityPerDay: createTradeblotterFormControl(
        isIT ? [requiredValidator, intGreaterThanZeroValidator] : []
      ),
      termMaximumQuantity: createTradeblotterFormControl(
        isPAL || isISS ? [requiredValidator, intGreaterThanZeroValidator] : []
      ),
      dateLoanParkEffectiveDate: createTradeblotterFormControl(),
      dateLoanParkTermExpireDate: createTradeblotterFormControl(),
      loanParkTermMaximumQuantityPerDay: createTradeblotterFormControl(
        isPAL ? [requiredValidator, intGreaterThanZeroValidator] : []
      ),
      datePaybackUnparkTermEffectiveDate: createTradeblotterFormControl(),
      datePaybackUnparkTermExpireDate: createTradeblotterFormControl(),
      paybackUnparkTermMaximumQuantityPerDay: createTradeblotterFormControl(
        isPAL ? [requiredValidator, intGreaterThanZeroValidator] : []
      ),
      interruptibleDailyInjectionQuantity: createTradeblotterFormControl(),
      interruptibleDailyWithdrawalQuantity: createTradeblotterFormControl(),
      locations: createTradeblotterFormControl(),
      receiptLocation: createTradeblotterFormControl(isPAL ? [] : [requiredValidator]),
      deliveryLocation: createTradeblotterFormControl(isPAL ? [] : [requiredValidator]),
      fuelRate: createTradeblotterFormControl(isISS ? [requiredValidator] : []),
      chargePerQuantity: createTradeblotterFormControl([
        requiredValidator,
        decimalGreaterThanZeroValidator,
      ]),
      effectiveDate: createTradeblotterFormControl(),
      expireDate: createTradeblotterFormControl(),
    },
    { updateOn: 'blur' }
  );

  if (isIT || isISS) {
    applyDateValidators(
      formGroup.controls.effectiveDate as UntypedFormControl,
      formGroup.controls.expireDate as UntypedFormControl,
      gridType
    );
  } else if (isPAL) {
    applyDateValidators(
      formGroup.controls.dateLoanParkEffectiveDate as UntypedFormControl,
      formGroup.controls.dateLoanParkTermExpireDate as UntypedFormControl,
      gridType
    );
    applyDateValidators(
      formGroup.controls.datePaybackUnparkTermEffectiveDate as UntypedFormControl,
      formGroup.controls.datePaybackUnparkTermExpireDate as UntypedFormControl,
      gridType
    );
    applyUnparkDateValidators(
      formGroup.controls.dateLoanParkEffectiveDate as UntypedFormControl,
      formGroup.controls.dateLoanParkTermExpireDate as UntypedFormControl,
      formGroup.controls.datePaybackUnparkTermEffectiveDate as UntypedFormControl,
      formGroup.controls.datePaybackUnparkTermExpireDate as UntypedFormControl
    );
  }

  if (isISS) {
    applyDailyInterruptibleQuantityValidators(
      formGroup.controls.interruptibleDailyInjectionQuantity as UntypedFormControl,
      formGroup.controls.interruptibleDailyWithdrawalQuantity as UntypedFormControl,
      formGroup.controls.termMaximumQuantity as UntypedFormControl
    );
  }

  return formGroup;
};

const setPalLocationValidators = (formGroup: UntypedFormGroup, isSingleLocationPal) => {
  if (isSingleLocationPal) {
    formGroup.controls.locations.setValidators([CustomValidators.required()]);
    formGroup.controls.receiptLocation.setValidators([]);
    formGroup.controls.deliveryLocation.setValidators([]);
  } else {
    formGroup.controls.locations.setValidators([]);
    formGroup.controls.receiptLocation.setValidators([CustomValidators.required()]);
    formGroup.controls.deliveryLocation.setValidators([CustomValidators.required()]);
  }
};

export const createDropdownBehaviorSubject = (
  observable: Observable<any>
): BehaviorSubject<any> => {
  const subject = new BehaviorSubject([]);
  observable.subscribe({
    next: obsValue => subject.next(obsValue),
    error: obsValue => subject.error(obsValue),
    complete: () => subject.complete(),
  });
  return subject;
};

const dailyQuantityControlsMap = {
  [EPalTypes.PARK_LOAN]: {
    dailyQuantityField: 'loanParkTermMaximumQuantityPerDay',
    effectiveDateField: 'dateLoanParkEffectiveDate',
    expireDateField: 'dateLoanParkTermExpireDate',
  },
  [EPalTypes.UNPARK_PAYBACK]: {
    dailyQuantityField: 'paybackUnparkTermMaximumQuantityPerDay',
    effectiveDateField: 'datePaybackUnparkTermEffectiveDate',
    expireDateField: 'datePaybackUnparkTermExpireDate',
  },
};

const getPalDailyQuantity = (formGroup: UntypedFormGroup, category: EPalTypes) => {
  const categoryKey = EPalTypes[category];
  const palTypeControls = dailyQuantityControlsMap[categoryKey];
  if (!palTypeControls) return;

  //If no flex rights, set daily quantity to max total qty / days between effective and expire dates
  const flexRightsControl = formGroup.controls.flexRights ? formGroup.controls.flexRights : null;
  const dailyQuantityControl = formGroup.controls[palTypeControls.dailyQuantityField];
  const effectiveDate = formGroup.controls[palTypeControls.effectiveDateField]
    ? formGroup.controls[palTypeControls.effectiveDateField].value
    : null;
  const expireDate = formGroup.controls[palTypeControls.expireDateField]
    ? formGroup.controls[palTypeControls.expireDateField].value
    : null;
  const maxQuantity = formGroup.controls.termMaximumQuantity
    ? formGroup.controls.termMaximumQuantity.value
    : null;

  if (effectiveDate && expireDate && maxQuantity && dailyQuantityControl && flexRightsControl) {
    if (!flexRightsControl.value) {
      const totalDays = dateUtils.getDaysBetweenDates(effectiveDate, expireDate);
      return totalDays > 0 && maxQuantity > 0 ? Math.round(maxQuantity / totalDays) : null;
    }
    return dailyQuantityControl.value;
  }
};

const handlePalDateValueChange = (formGroup: UntypedFormGroup, category: EPalTypes) => {
  const categoryKey = EPalTypes[category];
  const palTypeControls = dailyQuantityControlsMap[categoryKey];
  if (!palTypeControls) return;

  formGroup.controls[palTypeControls.effectiveDateField].markAsTouched();
  formGroup.controls[palTypeControls.expireDateField].markAsTouched();
  formGroup.controls[palTypeControls.effectiveDateField].updateValueAndValidity();
  formGroup.controls[palTypeControls.expireDateField].updateValueAndValidity();
  formGroup.controls[palTypeControls.dailyQuantityField].setValue(
    getPalDailyQuantity(formGroup, category)
  );
};

const baseControlNameToNextControlMap = {
  traderName: ['serviceRequesterName'],
  serviceRequesterName: ['externalTraderName', 'tspRateSchedule'],
  externalTraderName: ['tspRateSchedule'],
  receiptLocation: ['deliveryLocation'],
  deliveryLocation: ['chargePerQuantity'],
};

export const controlNameToNextControlMap = {
  IT: {
    ...baseControlNameToNextControlMap,
    tspRateSchedule: ['maxQuantityPerDay'],
    maxQuantityPerDay: ['receiptLocation'],
    chargePerQuantity: ['effectiveDate', 'expireDate'],
    effectiveDate: [],
    expireDate: [],
  },
  PAL: {
    ...baseControlNameToNextControlMap,
    tspRateSchedule: ['flexRights', 'termMaximumQuantity'],
    flexRights: [],
    termMaximumQuantity: [
      'dateLoanParkEffectiveDate',
      'dateLoanParkTermExpireDate',
      'loanParkTermMaximumQuantityPerDay',
      'datePaybackUnparkTermEffectiveDate',
    ],
    dateLoanParkEffectiveDate: [],
    dateLoanParkTermExpireDate: [],
    loanParkTermMaximumQuantityPerDay: [],
    datePaybackUnparkTermEffectiveDate: ['datePaybackUnparkTermExpireDate'],
    datePaybackUnparkTermExpireDate: [
      'paybackUnparkTermMaximumQuantityPerDay',
      'locations',
      'receiptLocation',
    ],
    paybackUnparkTermMaximumQuantityPerDay: [],
    locations: ['chargePerQuantity'],
    chargePerQuantity: [],
  },
  ISS: {
    ...baseControlNameToNextControlMap,
    tspRateSchedule: ['effectiveDate', 'expireDate', 'termMaximumQuantity'],
    expireDate: [],
    termMaximumQuantity: ['interruptibleDailyInjectionQuantity'],
    interruptibleDailyInjectionQuantity: ['interruptibleDailyWithdrawalQuantity'],
    interruptibleDailyWithdrawalQuantity: ['receiptLocation'],
    chargePerQuantity: ['fuelRate'],
  },
};

const enableNextControl = (
  formGroup: UntypedFormGroup,
  controlName: string,
  type: string
): void => {
  const controlHasValue =
    formGroup.controls[controlName] && !isNullOrUndefined(formGroup.controls[controlName].value);
  if (!controlHasValue) return;

  const controlMap = controlNameToNextControlMap[type];

  if (controlMap[controlName] && controlMap[controlName].length) {
    controlMap[controlName].forEach((control: string) => {
      if (formGroup.controls[control]) formGroup.controls[control].enable();
    });
  }
};

const disableAndResetSubsequentControls = (
  formGroup: UntypedFormGroup,
  controlName: string,
  type: string
): void => {
  const controlMap = controlNameToNextControlMap[type];
  if (controlMap[controlName] && controlMap[controlName].length) {
    controlMap[controlName].forEach(control => {
      if (formGroup.controls[control]) {
        formGroup.controls[control].setValue(null);
        formGroup.controls[control].disable();
        disableAndResetSubsequentControls(formGroup, control, type);
      }
    });
  }
};

export const createDraftEditingRow = (
  internalTraders$: Observable<Array<DynamicDropdownCellMenuItem>>,
  dropdownRateSchedulesByType: Array<DynamicDropdownCellMenuItem>,
  searchedLocations$,
  selectedGrid,
  context: ITradeblotterGrid
) => {
  const formGroup = createDraftRowFormGroup(selectedGrid);
  const trackById = uniqueId('draft-row-');

  let isSingleLocationPal = false;

  const isPAL = selectedGrid === ETradeblotterTabs.PAL;
  const isIT = selectedGrid === ETradeblotterTabs.IT;
  const isISS = selectedGrid === ETradeblotterTabs.ISS;

  const locationMultiselectOptions = {
    data$: context.searchedMultiselectLocations$,
    retainDataObservable: true,
    updateOnModelChange: true,
    group: formGroup,
    numSelectionsBeforeCombined: 3,
    popupSettings: { popupClass: 'multi-select-popup' },
    filterChanged: searchPhrase => {
      context.searchLocations(searchPhrase, null, formGroup.controls.tspRateSchedule.value.value);
    },
    debounceTime: '100',
    minLength: 3,
  };

  const locationDropdownOptions = {
    data$: searchedLocations$,
    group: formGroup,
    minLength: 3,
    popupSettings: {
      width: 'auto',
    },
    valuePrimitive: false,
    valueField: 'value',
    textField: 'text',
    debounceTime: '100',
    filterable: true,
    filterChanged: searchPhrase => {
      context.searchLocations(searchPhrase, null, formGroup.controls.tspRateSchedule.value.value);
    },
    opened: () => {
      context.clearLocations();
      if (formGroup.controls.tspRateSchedule.value) {
        context.searchLocations(null, null, formGroup.controls.tspRateSchedule.value.value);
      }
    },
    retainDataObservable: true,
    updateOnModelChange: true,
  };

  const standardDatePickerOptions = {
    valueChanged: value => {
      formGroup.controls.effectiveDate.markAsTouched();
      formGroup.controls.expireDate.markAsTouched();
      formGroup.controls.effectiveDate.updateValueAndValidity();
      formGroup.controls.expireDate.updateValueAndValidity();
    },
    updateOnModelChange: true,
  };

  const standardDropdownOptions: DropdownOptions = {
    group: formGroup,
    minLength: 3,
    popupSettings: {
      width: 'auto',
    },
    valuePrimitive: false,
    valueField: 'value',
    textField: 'text',
    debounceTime: '100',
    retainDataObservable: true,
    updateOnModelChange: true,
    controlName: null,
  };

  const integerInputOptions = {
    numberFormat: { maximumFractionDigits: 0 },
    min: 0,
    decimals: 0,
    updateOnModelChange: true,
  };

  const currencyInputOptions = {
    min: 0,
    decimals: 4,
    updateOnModelChange: true,
  };

  return {
    statusTag: createStatusTagCell({
      color: statusToColorMap[ETradeStatuses.DRAFT],
      text: statusToTextMap[ETradeStatuses.DRAFT],
      id: `${trackById}-draft-status-tag`,
      updateOnModelChange: true,
    }),
    traderName: createDropDownCell({
      ...standardDropdownOptions,
      data$: internalTraders$,
      id: `${trackById}-draft-trader-dropdown`,
      valueChanged: value => enableNextControl(formGroup, 'traderName', selectedGrid),
      controlName: 'traderName',
    }),
    serviceRequesterName: createDropDownCell({
      ...standardDropdownOptions,
      filterable: true,
      data$: context.searchedEntities$,
      id: `${trackById}-draft-requester-dropdown`,
      controlName: 'serviceRequesterName',
      debounceTime: '300',
      minLength: 1,
      filterChanged: (searchPhrase: string) => {
        if (!searchPhrase || searchPhrase.length < 2) {
          return;
        }
        context.searchEntities(searchPhrase);
      },
      opened: () => {
        context.clearEntities();
      },
      valueChanged: value => {
        enableNextControl(formGroup, 'serviceRequesterName', selectedGrid);
        formGroup.controls['externalTraderName'].setValue(null);
      },
    }),
    externalTraderName: createDropDownCell({
      ...standardDropdownOptions,
      isLoading$: context.searchedExternalTradersLoading$,
      data$: context.searchedExternalTraders$,
      id: `${trackById}-draft-external-trader-dropdown`,
      valueChanged: value => enableNextControl(formGroup, 'externalTraderName', selectedGrid),
      controlName: 'externalTraderName',
      opened: () => {
        const entityId = formGroup.controls.serviceRequesterName?.value?.value;
        if (!entityId) {
          return;
        }
        context.searchAuctionUsers(entityId);
      }
    }),
    tspRateSchedule: createDropDownCell({
      data: dropdownRateSchedulesByType,
      id: `${trackById}-draft-tsp-rs-dropdown`,
      controlName: 'tspRateSchedule',
      group: formGroup,
      popupSettings: {
        width: 'auto',
      },
      valuePrimitive: false,
      valueField: 'value',
      textField: 'text',
      debounceTime: '100',
      valueChanged: value => {
        if (value) {
          disableAndResetSubsequentControls(formGroup, 'tspRateSchedule', selectedGrid);
          enableNextControl(formGroup, 'tspRateSchedule', selectedGrid);
          isSingleLocationPal = !!SINGLE_LOCATION_PAL_RS.find(rsId => rsId === value.value);
          setPalLocationValidators(formGroup, isSingleLocationPal);
          if (isISS) {
            formGroup.controls.effectiveDate.setValue(getDefaultEffectiveDate(selectedGrid));
            formGroup.controls.expireDate.setValue(getDefaultExpireDate(selectedGrid));
          }
        }
      },
      updateOnModelChange: true,
    }),
    includesFlexRightsFlag: isPAL
      ? createCheckboxCell({
          id: `${trackById}-flex-rights-checkbox`,
          control: formGroup.controls.flexRights,
          updateOnModelChange: true,
          checkboxFocused: () => {
            if (
              formGroup.controls.dateLoanParkEffectiveDate &&
              formGroup.controls.dateLoanParkTermExpireDate &&
              formGroup.controls.dateLoanParkEffectiveDate.value &&
              formGroup.controls.dateLoanParkTermExpireDate.value
            ) {
              formGroup.controls.loanParkTermMaximumQuantityPerDay.setValue(
                getPalDailyQuantity(formGroup, EPalTypes.PARK_LOAN)
              );
            }
            if (
              formGroup.controls.datePaybackUnparkTermEffectiveDate &&
              formGroup.controls.datePaybackUnparkTermExpireDate &&
              formGroup.controls.datePaybackUnparkTermEffectiveDate.value &&
              formGroup.controls.datePaybackUnparkTermExpireDate.value
            ) {
              formGroup.controls.paybackUnparkTermMaximumQuantityPerDay.setValue(
                getPalDailyQuantity(formGroup, EPalTypes.UNPARK_PAYBACK)
              );
            }
          },
        })
      : null,
    termMaximumQuantity:
      isPAL || isISS
        ? createInputCell({
            ...integerInputOptions,
            control: formGroup.controls.termMaximumQuantity,
            id: `${trackById}-draft-term-quantity-input`,
            inputChanges: () => {
              enableNextControl(formGroup, 'termMaximumQuantity', selectedGrid);
              if (formGroup.controls.termMaximumQuantity.value && isPAL) {
                formGroup.controls.dateLoanParkEffectiveDate.setValue(
                  getDefaultEffectiveDate(selectedGrid)
                );
                formGroup.controls.dateLoanParkTermExpireDate.setValue(
                  getDefaultExpireDate(selectedGrid)
                );
                formGroup.controls.loanParkTermMaximumQuantityPerDay.setValue(
                  getPalDailyQuantity(formGroup, EPalTypes.PARK_LOAN)
                );
                updatePalQuantityValidators(
                  [
                    formGroup.controls.loanParkTermMaximumQuantityPerDay as UntypedFormControl,
                    formGroup.controls.paybackUnparkTermMaximumQuantityPerDay as UntypedFormControl,
                  ],
                  formGroup.controls.termMaximumQuantity.value
                );
              }
            },
          })
        : null,
    dateLoanParkEffectiveDate: isPAL
      ? createDatePickerCell({
          control: formGroup.controls.dateLoanParkEffectiveDate,
          id: `${trackById}-draft-park-effective-date`,
          valueChanged: () => {
            handlePalDateValueChange(formGroup, EPalTypes.PARK_LOAN);
          },
          updateOnModelChange: true,
        })
      : null,
    dateLoanParkTermExpireDate: isPAL
      ? createDatePickerCell({
          control: formGroup.controls.dateLoanParkTermExpireDate,
          id: `${trackById}-draft-park-expire-date`,
          valueChanged: value => {
            handlePalDateValueChange(formGroup, EPalTypes.PARK_LOAN);
          },
          updateOnModelChange: true,
        })
      : null,
    loanParkTermMaximumQuantityPerDay: isPAL
      ? createInputCell({
          ...integerInputOptions,
          control: formGroup.controls['loanParkTermMaximumQuantityPerDay'],
          id: `${trackById}-draft-park-daily-quantity-input`,
          inputChanges: () => {
            if (formGroup.controls.loanParkTermMaximumQuantityPerDay.value) {
              enableNextControl(formGroup, 'loanParkTermMaximumQuantityPerDay', selectedGrid);
              formGroup.controls.paybackUnparkTermMaximumQuantityPerDay.setValue(
                getPalDailyQuantity(formGroup, EPalTypes.UNPARK_PAYBACK)
              );
            }
          },
        })
      : null,
    datePaybackUnparkTermEffectiveDate: isPAL
      ? createDatePickerCell({
          control: formGroup.controls.datePaybackUnparkTermEffectiveDate,
          id: `${trackById}-draft-unpark-effective-date`,
          valueChanged: value => {
            enableNextControl(formGroup, 'datePaybackUnparkTermEffectiveDate', selectedGrid);
            handlePalDateValueChange(formGroup, EPalTypes.UNPARK_PAYBACK);
          },
          updateOnModelChange: true,
        })
      : null,
    datePaybackUnparkTermExpireDate: isPAL
      ? createDatePickerCell({
          control: formGroup.controls.datePaybackUnparkTermExpireDate,
          id: `${trackById}-draft-unpark-expire-date`,
          valueChanged: value => {
            enableNextControl(formGroup, 'datePaybackUnparkTermExpireDate', selectedGrid);
            isSingleLocationPal
              ? formGroup.controls.receiptLocation.disable()
              : formGroup.controls.locations.disable();
            handlePalDateValueChange(formGroup, EPalTypes.UNPARK_PAYBACK);
          },
          updateOnModelChange: true,
        })
      : null,
    paybackUnparkTermMaximumQuantityPerDay: isPAL
      ? createInputCell({
          ...integerInputOptions,
          control: formGroup.controls['paybackUnparkTermMaximumQuantityPerDay'],
          id: `${trackById}-draft-unpark-daily-quantity-input`,
          inputBlurred: () => {},
        })
      : null,
    maxQuantityPerDay: isIT
      ? createInputCell({
          ...integerInputOptions,
          control: formGroup.controls['maxQuantityPerDay'],
          id: `${trackById}-draft-daily-quantity-input`,
          inputChanges: () => {
            if (formGroup.controls.maxQuantityPerDay.value) {
              enableNextControl(formGroup, 'maxQuantityPerDay', selectedGrid);
            }
          },
        })
      : null,
    interruptibleDailyInjectionQuantity: createInputCell({
      ...integerInputOptions,
      control: formGroup.controls['interruptibleDailyInjectionQuantity'],
      id: `${trackById}-draft-interruptibe-injection-input`,
      inputChanges: () => {
        enableNextControl(formGroup, 'interruptibleDailyInjectionQuantity', selectedGrid);
      },
    }),
    interruptibleDailyWithdrawalQuantity: createInputCell({
      ...integerInputOptions,
      control: formGroup.controls['interruptibleDailyWithdrawalQuantity'],
      id: `${trackById}-draft-interruptibe-withdrawal-input`,
      inputChanges: () => {
        enableNextControl(formGroup, 'interruptibleDailyWithdrawalQuantity', selectedGrid);
      },
    }),
    locations: createDropDownCell({
      ...locationDropdownOptions,
      id: `${trackById}-draft-pal-location-dropdown`,
      controlName: 'locations',
      valueChanged: value => enableNextControl(formGroup, 'locations', selectedGrid),
    }),
    receiptLocation: isIT
      ? createDropDownCell({
          ...locationDropdownOptions,
          id: `${trackById}-draft-receipt-location-dropdown`,
          controlName: 'receiptLocation',
          valueChanged: value => enableNextControl(formGroup, 'receiptLocation', selectedGrid),
        })
      : isPAL || isISS
      ? createMultiselectCell({
          ...locationMultiselectOptions,
          id: `${trackById}-draft-receipt-location-dropdown`,
          controlName: 'receiptLocation',
          valueChanged: () => enableNextControl(formGroup, 'receiptLocation', selectedGrid),
        })
      : null,
    deliveryLocation: isIT
      ? createDropDownCell({
          ...locationDropdownOptions,
          id: `${trackById}-draft-delivery-location-dropdown`,
          controlName: 'deliveryLocation',
          valueChanged: () => enableNextControl(formGroup, 'deliveryLocation', selectedGrid),
        })
      : isPAL || isISS
      ? createMultiselectCell({
          ...locationMultiselectOptions,
          id: `${trackById}-draft-delivery-location-dropdown`,
          controlName: 'deliveryLocation',
          valueChanged: () => enableNextControl(formGroup, 'deliveryLocation', selectedGrid),
        })
      : null,
    chargePerQuantity: createInputCell({
      ...currencyInputOptions,
      numberFormat: { style: 'currency', maximumFractionDigits: 4 },
      control: formGroup.controls['chargePerQuantity'],
      id: `${trackById}-draft-charge-input`,
      inputChanges: () => {
        enableNextControl(formGroup, 'chargePerQuantity', selectedGrid);
        if (isIT && formGroup.controls.chargePerQuantity.value) {
          formGroup.controls.effectiveDate.setValue(getDefaultEffectiveDate(selectedGrid));
          formGroup.controls.expireDate.setValue(getDefaultExpireDate(selectedGrid));
        }
      },
    }),
    fuelRate: createInputCell({
      ...currencyInputOptions,
      numberFormat: { style: 'currency', maximumFractionDigits: 4 },
      control: formGroup.controls['fuelRate'],
      id: `${trackById}-draft-fuel-rate-input`,
      inputBlurred: () => {},
    }),
    effectiveDate: createDatePickerCell({
      ...standardDatePickerOptions,
      control: formGroup.controls.effectiveDate,
      id: `${trackById}-draft-effective-date`,
    }),
    expireDate: createDatePickerCell({
      ...standardDatePickerOptions,
      id: `${trackById}-draft-expire-date`,
      control: formGroup.controls.expireDate,
    }),
    rowActions: createClickableButtonCell({
      displayText: null,
      id: `${trackById}-draft-action-button`,
      square: true,
      icon: statusToButtonIconMap[ETradeStatuses.DRAFT],
      buttonType: 'primary',
      buttonSize: 'small',
      onClick: () => context.moveDraftToOffered(trackById),
      updateOnModelChange: true,
    }),
    rowOptions: createClickableIconCell({
      id: `${trackById}-draft-delete`,
      data: null,
      dynamicClass: null,
      iconClass: 'icon-delete',
      onClick: () => context.deleteDraftRow(trackById),
      updateOnModelChange: true,
    }),
    trackByRowId: trackById,
    formGroup: formGroup,
  };
};

export const getTraderDropdownItem = (
  user: AuctionUser
): DynamicDropdownCellMenuItem => {
  if (!user) {
    return undefined;
  }

  return {
    text: `${user.firstName} ${user.lastName}`,
    value: user.userId,
  };
};

export const getEntityToTraderDropdownMap = (
  traders: AuctionUser[]
): {
  [entityId: number]: DynamicDropdownCellMenuItem[]
} => {
  if (!traders?.length) {
    return {};
  }

  return traders
    .reduce<{
      [entityId: number]: DynamicDropdownCellMenuItem[]
    }>((acc, trader) => {
      trader.entities?.forEach((entity) => {
        if (!acc[entity.entityId]) {
          acc[entity.entityId] = [];
        }

        acc[entity.entityId].push(
          getTraderDropdownItem(trader)
        );
      });

      return acc;
  }, {});
};

export const createEditTradeFormGroup = (): UntypedFormGroup =>
  new UntypedFormGroup({
    'externalTraderName': new UntypedFormControl(
      null,
      CustomValidators.required()
    )
  });

export const isExternalBwpCommercialRep = (user: AuctionUser): boolean => (
  user?.isInternal &&
  !!user.userRoles?.find(role =>
    role.name === EXTERNAL_BWP_COMMERICIAL_REP_ROLE_NAME &&
    role.permissionOptionId === EPermissionOption.Edit
  )
);


const isExternalTrader = (user: AuctionUser): boolean => {
  return isAuctionBidder(user) || isExternalBwpCommercialRep(user);
}

export const getExternalTraderDropdownData$ = (): (
  data: Observable<IDataState<AuctionUserCollection>>
) => Observable<DynamicDropdownCellMenuItem[]> => (
  traderData$: Observable<IDataState<AuctionUserCollection>>
) =>
  traderData$.pipe(
    map((dataState: IDataState<AuctionUserCollection>) => {
      if (!dataState?.data?.users?.length) {
        return [];
      }

      return dataState.data.users
        .filter((user) => isExternalTrader(user))
        .map((user) => getTraderDropdownItem(user));
    })
  );