import { Observable } from 'rxjs';
import { DynamicInputButtonComponent } from './button-cell.component';

export class DynamicInputButtonCell {
  dynamicComponentType: typeof DynamicInputButtonComponent;
  data: ClickableButtonOptions;
}

export interface ClickableButtonOptions {
  displayText: string;
  icon?: string;
  square?: boolean;
  onClick: Function;
  id?: string;
  buttonType?: string;
  buttonSize?: string;
  disabled?: Observable<Boolean>;
  updateOnModelChange?: boolean;
}

export const createClickableButtonCell = (
  clickableButtonOptions: ClickableButtonOptions
): DynamicInputButtonCell => {
  let id = 'InputButton';
  if (clickableButtonOptions.id) {
    id = clickableButtonOptions.id + '_' + id;
  }

  return {
    dynamicComponentType: DynamicInputButtonComponent,
    data: {
      ...clickableButtonOptions,
      id: id,
    },
  };
};
