import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { ILocationState } from './locations.state';

const selectLocationsState = (state: IAppState) => state.locations;

export const selectAllZones = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.allZones
);

export const selectLocations = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locations
);

export const selectLocation = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.location
);

export const selectLocationLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.loading.locations
);

export const selectSearchedLocations = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationsSearch
);

export const selectPreviouslySearchedLocation = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.previouslySearchedLocation
);

export const selectSearchLocationHeaders = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationHeadersSearch
);

export const selectSearchLocationHeadersLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationHeadersSearch.requestStatus.loading
);

export const selectSearchLocationHeadersError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationHeadersSearch.requestStatus.httpError
);

export const selectLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.loading
);

export const selectSearchLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationsSearchLoading
);

export const selectError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.error
);

export const selectUpdateLocationError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.updateLocationError
);

export const selectSearchError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationsSearchError
);

export const selectPagination = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.pagination
);

export const selectTotalLocationCount = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.totalLocationCount
);

export const selectSortDescriptors = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.sortDescriptors
);

export const selectDropdownDictionary = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.dropdownDictionary
);

export const selectDictionaryError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.dictionaryError
);

export const selectDictionaryLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.loading.dictionary
);

export const selectLocationEffectiveDateHistory = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.effectiveDateHistory
);

export const selectLocationEffectiveDateHistoryError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.effectiveDateHistory.httpError
);

export const selectLocationEffectiveDateHistoryLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.effectiveDateHistory.loading
);

export const selectLocationGroup = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationGroup
);

export const selectLocationGroupLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.locationGroup.loading
);

export const selectLocationGroupError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.locationGroup.httpError
);

export const selectLocationGroups = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationGroups
);

export const selectLocationGroupsError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.locationGroups.httpError
);

export const selectLocationGroupsLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.locationGroups.loading
);

export const selectTotalLocationGroupCount = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.totalLocationGroupCount
);

export const selectLocationGroupsByTspIds = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.tspIdLocationGroupCollection
);

export const selectAddLocationStatus = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.addLocation
);

export const selectUpdateLocationStatus = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.updateLocation
);

export const selectCreateLocationGroupLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.createLocationGroup.loading
);

export const selectCreateLocationGroupError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.createLocationGroup.httpError
);

export const selectUpdateLocationGroupLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.updateLocationGroup.loading
);

export const selectUpdateLocationGroupError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.updateLocationGroup.httpError
);

export const selectDeleteLocationGroupStatus = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.deleteLocationGroup
);

export const selectDeleteLocationGroupLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.deleteLocationGroup.loading
);

export const selectDeleteLocationGroupError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.deleteLocationGroup.httpError
);

export const selectStorageLocations = createSelector(
  selectLocationsState,
  (state: ILocationState) => {
    return state.storageLocations;
  }
);

export const selectLocationGroupsByIds = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationGroupByIds
);

export const selectDynamicLocationGroup = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.dynamicLocationGroup
);

export const selectDynamicLocationGroupLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.dynamicLocationGroup.loading
);

export const selectCreateDynamicLocationGroupLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.createLocationGroupCriteria.loading
);

export const selectDynamicLocationGroupError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.dynamicLocationGroup.httpError
);

export const selectCreateDynamicLocationGroupError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.createLocationGroupCriteria.httpError
);

export const selectUpdateDynamicLocationGroupLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.updateLocationGroupCriteria.loading
);

export const selectUpdateDynamicLocationGroupError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.updateLocationGroupCriteria.httpError
);

export const selectChildLocations = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.childLocations
);

export const selectChildLocationsLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.childLocations.loading
);

export const selectChildLocationsError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.status.childLocations.httpError
);

export const selectLocationsList = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.locationsList
);

export const selectParentLocation = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.parentLocation
);

export const selectDynamicLocationGroups = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.dynamicLocationGroupCollection.data
);

export const selectDynamicLocationGroupsLoading = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.dynamicLocationGroupCollection.requestStatus.loading
);

export const selectDynamicLocationGroupsError = createSelector(
  selectLocationsState,
  (state: ILocationState) => state.dynamicLocationGroupCollection.requestStatus.httpError
);
