import { createSelector } from '@ngrx/store';

import { IAppState } from 'app/store/app/app.state';
import { IRetryState } from './retry.state';

export const selectRetryState = (state: IAppState) => state.retryState;

export const selectSavedPayloads = createSelector(
  selectRetryState,
  (state: IRetryState) => state.savedPayloads
);
