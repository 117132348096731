import {
  clearState,
  createErrorState,
  createLoadingState,
  createSuccessState,
} from '../app/app.models';
import { EReportParameter } from './reports-response.model';
import { EReportsActions, ReportsActions } from './reports.actions';
import { initialReportsState, IReportsState } from './reports.state';

export function reportsReducers(
  state = initialReportsState,
  action: ReportsActions
): IReportsState {
  switch (action.type) {
    case EReportsActions.CLEAR_REPORT_DEFINITION:
      return {
        ...state,
        reportDefinition: clearState(state.reportDefinition),
      };
    case EReportsActions.FETCH_REPORT_DEFINITION_BY_ID:
      return {
        ...state,
        reportDefinition: createLoadingState(state.reportDefinition),
      };
    case EReportsActions.FETCH_REPORT_DEFINITION_BY_ID_SUCCESS:
      return {
        ...state,
        reportDefinition: createSuccessState(action.payload.reportDefinition),
      };
    case EReportsActions.FETCH_REPORT_DEFINITION_BY_ID_ERROR:
      return {
        ...state,
        reportDefinition: createErrorState(action.payload.error),
      };
    case EReportsActions.CLEAR_REPORT_CLIENT_ID:
      return {
        ...state,
        reportClientId: clearState(state.reportClientId),
      };
    case EReportsActions.FETCH_REPORT_CLIENT_ID:
      return {
        ...state,
        reportClientId: createLoadingState(state.reportClientId),
      };
    case EReportsActions.FETCH_REPORT_CLIENT_ID_SUCCESS:
      return {
        ...state,
        reportClientId: createSuccessState(action.payload.clientId),
      };
    case EReportsActions.FETCH_REPORT_CLIENT_ID_ERROR:
      return {
        ...state,
        reportClientId: createErrorState(action.payload.error),
      };
    case EReportsActions.CLEAR_REPORT_PARAMETERS:
      return {
        ...state,
        reportParameters: clearState(state.reportParameters),
      };
    case EReportsActions.FETCH_REPORT_PARAMETERS:
      return {
        ...state,
        reportParameters: createLoadingState(state.reportParameters),
      };
    case EReportsActions.FETCH_REPORT_PARAMETERS_SUCCESS:
      return {
        ...state,
        reportParameters: createSuccessState(action.payload.parameterValues),
      };
    case EReportsActions.FETCH_REPORT_PARAMETERS_ERROR:
      return {
        ...state,
        reportParameters: createErrorState(action.payload.error),
      };
    case EReportsActions.SET_REPORT_PARAMETER_VALUES:
      return {
        ...state,
      };
    case EReportsActions.SET_REPORT_PARAMETER_VALUES_SUCCESS:
      const selectedReportParameters = {
        ...state.selectedReportParameters,
        data: [],
      };

      Object.keys(action.payload.parameters).forEach(key => {
        selectedReportParameters.data.push({
          name: key,
          value: action.payload.parameters[key],
        });
      });

      return {
        ...state,
        selectedReportParameters,
      };
    case EReportsActions.FETCH_REPORT_DETAIL_RATE_SCHEDULE:
      return {
        ...state,
        reportRateSchedule: createLoadingState(state.reportRateSchedule),
      };
    case EReportsActions.FETCH_REPORT_DETAIL_RATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        reportRateSchedule: createSuccessState(action.payload),
      };
    case EReportsActions.FETCH_REPORT_DETAIL_RATE_SCHEDULE_ERROR:
      return {
        ...state,
        reportRateSchedule: createErrorState(action.payload.error, {}),
      };

    case EReportsActions.FETCH_AMBIENT_UNSUBSCRIBED_CAPACITIES:
      return {
        ...state,
        ambientUnsubscribedCapacities: createLoadingState(state.ambientUnsubscribedCapacities),
      };
    case EReportsActions.FETCH_AMBIENT_UNSUBSCRIBED_CAPACITIES_SUCCESS:
      return {
        ...state,
        ambientUnsubscribedCapacities: createSuccessState(action.payload),
      };
    case EReportsActions.FETCH_AMBIENT_UNSUBSCRIBED_CAPACITIES_ERROR:
      return {
        ...state,
        ambientUnsubscribedCapacities: createErrorState(action.payload.error, {}),
      };

    case EReportsActions.ADD_AMBIENT_UNSUBSCRIBED_CAPACITY:
      return {
        ...state,
        addAmbientUnsubscribedCapacity: createLoadingState(state.addAmbientUnsubscribedCapacity),
      };

    case EReportsActions.ADD_AMBIENT_UNSUBSCRIBED_CAPACITY_SUCCESS:
      return {
        ...state,
        addAmbientUnsubscribedCapacity: createSuccessState(action.payload),
      };

    case EReportsActions.ADD_AMBIENT_UNSUBSCRIBED_CAPACITY_ERROR:
      return {
        ...state,
        addAmbientUnsubscribedCapacity: createErrorState(action.payload.error, {}),
      };
    default:
      return state;
  }
}
