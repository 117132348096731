export interface IPasswordResetState {
  loading: boolean;
  passwordReset: boolean;
  error: Error | null;
}

export const initialPasswordResetState: IPasswordResetState = {
  loading: false,
  error: null,
  passwordReset: false,
};
