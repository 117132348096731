import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DynamicComponentDefinition } from 'shared/components/dynamic-component-wrapper/dynamic-component.interface';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import {
  PopoverAnimationDirection,
  PopoverHorizontalAlignment,
  PopoverVerticalAlignment,
} from 'shared/components/popover/popover.component';
import { EColor } from 'shared/consts/color.enum';
import { ICON_CLASSES_DEPRECATED } from 'shared/consts/icon-classes.const';
import { IconPopoverOptions } from './icon-popover-cell.interface';

@Component({
  selector: 'gms-dynamic-icon-popover-cell',
  templateUrl: './icon-popover-cell.component.html',
  styleUrls: ['./icon-popover-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicIconPopoverCellComponent extends HasSubscriptions {
  COLORS = EColor;
  ICON_CLASSES = ICON_CLASSES_DEPRECATED;

  @Input() data: IconPopoverOptions;
  popoverHorizontalAlignment = PopoverHorizontalAlignment;
  popoverVerticalAlignment = PopoverVerticalAlignment;
  popoverAnimationDirection = PopoverAnimationDirection;
  anchorHorizontalAlignment = PopoverHorizontalAlignment;

  static componentDefinition(data: IconPopoverOptions): DynamicComponentDefinition {
    return {
      dynamicComponentType: DynamicIconPopoverCellComponent,
      data: data,
    };
  }

  constructor(public cd: ChangeDetectorRef) {
    super();
  }
}
