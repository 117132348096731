import {
  clearState,
  createErrorState,
  createLoadingState,
  createSuccessState,
} from '../app/app.models';
import { ERepResponsibilityActions, RepResponsibilityActions } from './rep-responsibility.actions';
import { initialRepResponsibilityState, IRepResponsibilityState } from './rep-responsibility.state';

export function repResponsibilityReducers(
  state = initialRepResponsibilityState,
  action: RepResponsibilityActions
): IRepResponsibilityState {
  switch (action.type) {
    case ERepResponsibilityActions.CLEAR_REP_RESPONSIBILITIES:
      return {
        ...state,
        repResponsibilities: clearState(state.repResponsibilities),
      };
    case ERepResponsibilityActions.CLEAR_USER_RESPONSIBILITIES:
      return {
        ...state,
        userResponsibilities: clearState(state.userResponsibilities),
      };
    case ERepResponsibilityActions.CLEAR_REP_RESPONSIBILITY_ROLES:
      return {
        ...state,
        repResponsibilityRoles: clearState(state.repResponsibilityRoles),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITY_ROLES:
      return {
        ...state,
        repResponsibilityRoles: createLoadingState(state.repResponsibilityRoles),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITY_ROLES_SUCCESS:
      return {
        ...state,
        repResponsibilityRoles: createSuccessState(action.payload.repResponsibilityRolesCollection),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITY_ROLES_ERROR:
      return {
        ...state,
        repResponsibilityRoles: createErrorState(action.payload.error),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID:
      return {
        ...state,
        repResponsibilities: createLoadingState(state.repResponsibilities),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        repResponsibilities: createSuccessState(action.payload.repResponsibilityCollection),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID_ERROR:
      return {
        ...state,
        repResponsibilities: createErrorState(action.payload.error),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID:
      return {
        ...state,
        repResponsibilities: createLoadingState(state.repResponsibilities),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID_SUCCESS:
      return {
        ...state,
        repResponsibilities: createSuccessState(action.payload.repResponsibilityCollection),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID_ERROR:
      return {
        ...state,
        repResponsibilities: createErrorState(action.payload.error),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_USER_ID:
      return {
        ...state,
        userResponsibilities: createLoadingState(state.userResponsibilities),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_USER_ID_SUCCESS:
      return {
        ...state,
        userResponsibilities: createSuccessState(action.payload.userResponsibilityCollection),
      };
    case ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_USER_ID_ERROR:
      return {
        ...state,
        userResponsibilities: createErrorState(action.payload.error),
      };
    case ERepResponsibilityActions.UPDATE_USER_RESPONSIBILITIES:
      return {
        ...state,
        updateUserResponsibilities: createLoadingState(state.updateUserResponsibilities),
      };
    case ERepResponsibilityActions.UPDATE_USER_RESPONSIBILITIES_SUCCESS:
      return {
        ...state,
        updateUserResponsibilities: createSuccessState(
          action.payload.userResponsibilitiesCollection
        ),
      };
    case ERepResponsibilityActions.UPDATE_USER_RESPONSIBILITIES_ERROR:
      return {
        ...state,
        updateUserResponsibilities: createErrorState(action.payload.error),
      };
    default:
      return state;
  }
}
