import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

import {
  DropdownDictionary,
  DynamicLocationGroup,
  EffectiveDateRecord,
  Location,
  LocationGroup,
  LocationGroupCollection,
  LocationHeaderCollection,
  LocationListCollection,
  LocationResult,
} from '@gms/location-api';
import { StorageLocationResult } from 'app/modules/service-request/services/locationStorage.service';
import { RetryableAction } from '../retry/retry.actions';

export enum ELocationsActions {
  GetLocations = '[LOCATIONS] get Locations',
  GetLocationsError = '[LOCATIONS] get Locations Error',
  GetLocationsSuccess = '[LOCATIONS] get Locations Success',
  ClearLocations = '[LOCATIONS] clear Locations',
  GetLocationById = '[LOCATIONS] get LocationById',
  GetLocationByIdError = '[LOCATIONS] get LocationById Error',
  GetLocationByIdSuccess = '[LOCATIONS] get LocationById Success',
  ClearGetLocationById = '[LOCATIONS] clear GetLocationById',
  GET_LOCATIONS_BY_IDS = '[LOCATIONS] get locations LocationById',
  GET_LOCATIONS_BY_IDS_ERROR = '[LOCATIONS] get locations LocationById Error',
  GET_LOCATIONS_BY_IDS_SUCCESS = '[LOCATIONS] get locations LocationById Success',
  GetLocationDictionary = '[LOCATIONS] get LocationDictionary',
  GetLocationDictionaryError = '[LOCATIONS] get LocationDictionary Error',
  GetLocationDictionarySuccess = '[LOCATIONS] get LocationDictionary Success',
  GetLocationEffectiveDateHistory = '[LOCATIONS] get LocationEffectiveDateHistory',
  GetLocationEffectiveDateHistoryFailure = '[LOCATIONS] get LocationEffectiveDateHistory Failure',
  GetLocationEffectiveDateHistorySuccess = '[LOCATIONS] get LocationEffectiveDateHistory Success',
  GetLocationGroupEffectiveDateHistory = '[LOCATIONS] get LocationGroupEffectiveDateHistory',
  GetLocationGroupEffectiveDateHistoryFailure = '[LOCATIONS] get LocationGroupEffectiveDateHistory Failure',
  GetLocationGroupEffectiveDateHistorySuccess = '[LOCATIONS] get LocationGroupEffectiveDateHistory Success',
  AddLocation = '[LOCATIONS] add Location',
  AddLocationSuccess = '[LOCATIONS] add Location Success',
  AddLocationError = '[LOCATIONS] add Location Error',
  ClearAddLocationValidationErrors = '[LOCATIONS] clear add Location Validation Errors',
  ResetLocation = '[LOCATIONS] reset Location',
  UpdateLocation = '[LOCATIONS] update Location',
  UpdateLocationSuccess = '[LOCATIONS] update Location Success',
  UpdateLocationError = '[LOCATIONS] update Location Error',
  ClearUpdateLocationValidationErrors = '[LOCATIONS] clear update Location Validation Errors',
  SearchLocations = '[LOCATIONS] search locations',
  SetPreviouslySelectedSearchLocation = '[LOCATIONS] sets previouslySearchedLocation',
  SearchLocationsSuccess = '[LOCATIONS] search locations Success',
  SearchLocationsFailure = '[LOCATIONS] search locations failure',
  SearchLocationsFromMultipleTsps = '[LOCATIONS] search locations multi tsp',
  SearchLocationsFromMultipleTspsSuccess = '[LOCATIONS] search locations multi tsp Success',
  SearchLocationsFromMultipleTspsFailure = '[LOCATIONS] search locations multi tsp failure',
  ClearSearchLocations = '[LOCATIONS] search locations clear',
  SearchLocationHeaders = '[LOCATIONS] search location headers',
  SearchLocationHeadersSuccess = '[LOCATIONS] search location headers success',
  SearchLocationHeadersFailure = '[LOCATIONS] search location headers failure',
  ClearSearchLocationHeaders = '[LOCATIONS] clear search location headers',
  FetchLocationGroupById = '[LOCATIONS] Fetch Location Group By Id',
  FetchLocationGroupByIdSuccess = '[LOCATIONS] Fetch Location Group By Id Success',
  FetchLocationGroupByIdFailure = '[LOCATIONS] Fetch Location Group By Id Failure',
  FetchLocationGroups = '[LOCATIONS] fetch location groups',
  FetchLocationGroupsSuccess = '[LOCATIONS] fetch location groups success',
  FetchLocationGroupsFailure = '[LOCATIONS] fetch location groups failure',
  ClearLocationGroups = '[LOCATIONS] clear location groups',
  FetchLocationGroupsByTspIds = '[LOCATIONS] fetch location groups by tsp Ids',
  FetchLocationGroupsByTspIdsFailure = '[LOCATIONS] fetch location groups by tsp Ids failure',
  FetchLocationGroupsByTspIdsSuccess = '[LOCATIONS] fetch location groups by tsp Ids success',
  CreateLocationGroup = '[LOCATIONS] create location group',
  CreateLocationGroupSuccess = '[LOCATIONS] create location group success',
  CreateLocationGroupFailure = '[LOCATIONS] create location group failure',
  UpdateLocationGroup = '[LOCATIONS] update location group',
  UpdateLocationGroupSuccess = '[LOCATIONS] update location group success',
  UpdateLocationGroupFailure = '[LOCATIONS] update location group failure',
  ClearLocationGroupUpdateErrors = '[LOCATIONS] clear location group update errors',
  DeleteLocationGroup = '[LOCATIONS] delete location group',
  DeleteLocationGroupSuccess = '[LOCATIONS] delete location group success',
  DeleteLocationGroupFailure = '[LOCATIONS] delete location group failure',
  ClearDeleteLocationGroupError = '[LOCATIONS] clear delete location group error',
  FetchStorageLocations = '[LOCATIONS] Fetch storage locations',
  FetchStorageLocationsSuccess = '[LOCATIONS] Fetch storage locations success',
  FetchStorageLocationsFailure = '[LOCATIONS] Fetch storage locations error',
  FETCH_LOCATION_GROUPS_BY_IDS = '[LOCATIONS] Fetch location groups by multiple ids',
  FETCH_LOCATION_GROUPS_BY_IDS_FAILURE = '[LOCATIONS] Fetch location groups by multiple ids failure',
  FETCH_LOCATION_GROUPS_BY_IDS_SUCCESS = '[LOCATIONS] Fetch location groups by multiple ids success',
  UpdateLocationDictionary = '[LOCATIONS] Update Locations Dictionary',
  FetchLocationGroupCriteriaById = '[LOCATIONS] Fetch location group criteria by id',
  FetchLocationGroupCriteriaByIdSuccess = '[LOCATIONS] Fetch location group criteria by id success',
  FetchLocationGroupCriteriaByIdFailure = '[LOCATIONS] Fetch location group criteria by id failiure',
  ClearLocationGroupCriteria = '[LOCATIONS] Clear location group criteria',
  CreateLocationGroupCriteria = '[LOCATIONS] Create location group criteria',
  CreateLocationGroupCriteriaSuccess = '[LOCATIONS] Create location group criteria success',
  CreateLocationGroupCriteriaFailure = '[LOCATIONS] Create location group criteria failure',
  UpdateLocationGroupCriteria = '[LOCATIONS] Update location group criteria',
  UpdateLocationGroupCriteriaSuccess = '[LOCATIONS] Update location group criteria success',
  UpdateLocationGroupCriteriaFailure = '[LOCATIONS] Update locatin group criteria failure',
  FetchLocationGroupChildLocations = '[LOCATIONS] Fetch location group child locations',
  FetchLocationGroupChildLocationsSuccess = '[LOCATIONS] Fetch location group child locations success',
  FetchLocationGroupChildLocationsFailure = '[LOCATIONS] Fetch location group child locations failure',
  FetchLocationsList = '[LOCATIONS] Fetch locations list',
  FetchLocationsListSuccess = '[LOCATIONS] Fetch locations list success',
  FetchLocationsListFailure = '[LOCATIONS] Fetch locations list failure',
  FetchParentLocation = '[LOCATIONS] Fetch Parent Location',
  FetchParentLocationSuccess = '[LOCATIONS] Fetch Parent Location Success',
  FetchParentLocationFailure = '[LOCATIONS] Fetch Parent Location Failure',
  ClearParentLocation = '[LOCATIONS] Clear Parent Location',
  FetchDynamicLocationGroups = '[LOCATIONS] Fetch Dynamic Location Groups',
  FetchDynamicLocationGroupsSuccess = '[LOCATIONS] Fetch Dynamic Location Groups success',
  FetchDynamicLocationGroupsFailure = '[LOCATIONS] Fetch Dynamic Location Groups failure',
}

export interface ILocationsSearchPayload {
  tspId?: number;
  searchPhrase?: string;
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string;
  nominateable?: boolean;
  excludeAssociated?: boolean;
  masterRateScheduleId?: number;
  detailRateScheduleId?: number;
  pipelineSystemId?: number;
  statusId?: string;
  locationIds?: number[];
  locationNumbers?: string[];
  flowDirection?: string;
  includePipelines?: boolean;
}

export interface ILocationHeadersSearchPayload {
  agencyResourceName?: string;
  agencyTransactionStartDate?: string;
  agencyTransactionEndDate?: string;
  allowOwnerOrOperator?: boolean;
  agencyAllowHistoricalView?: boolean;
  agencyInferChildAccess?: boolean;
  minPermissionOption?: 'ReadOnly' | 'Edit' | 'Admin';
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string;
  tspId?: number;
  zoneId?: number;
  areaId?: number;
  excludeAssociated?: boolean;
  nominateable?: boolean;
  locationIds?: Array<number>;
  locationNumber?: string;
  locationNumbers?: Array<string>;
  locationName?: string;
  flowDirectionId?: string;
  classificationId?: number;
  typeId?: string;
  statusId?: string;
  searchPhrase?: string;
  masterRateScheduleId?: number;
  detailRateScheduleId?: number;
  pipelineSystemId?: number;
  pipelineSystemIds?: Array<number>;
  effectiveDate?: string;
  interconnectIds?: Array<string>;
  confirmingParty?: number;
  confirmingOrOwnerOrOperatorId?: number;
}

export interface IListLocationSearch {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  tspId?: number;
  zoneId?: number;
  areaId?: number;
  pipelineSystemId?: number;
  excludeAssociated?: boolean;
  locationNumber?: string;
  locationName?: string;
  flowDirectionId?: string;
  classificationId?: string;
  typeId?: string;
  statusId?: string;
  useRfsLocations?: boolean;
  searchPhrase?: string;
}

export interface IFetchDynamicLocationGroupsPayload {
  tspId?: number;
  capacityReporting?: boolean;
  statusCode?: string;
  groupTypeId?: number;
  gasDay?: string;
  dynamicLocationGroupIds?: Array<number>;
}

export class AddLocation implements Action {
  public readonly type = ELocationsActions.AddLocation;

  constructor(public payload: { location: Location }) {}
}
export class AddLocationSuccess implements Action {
  public readonly type = ELocationsActions.AddLocationSuccess;

  constructor(public payload: { location: Location }) {}
}

export class AddLocationError implements Action {
  public readonly type = ELocationsActions.AddLocationError;

  constructor(public payload: { error: Error }) {}
}

export class ClearAddLocationValidationErrors implements Action {
  public readonly type = ELocationsActions.ClearAddLocationValidationErrors;
}

export class GetLocations extends RetryableAction implements Action {
  public readonly type = ELocationsActions.GetLocations;

  constructor(
    public payload?: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      zoneId?: number;
      areaId?: number;
      pipelineSystemId?: number;
      excludeAssociated?: boolean;
      locationNumber?: string;
      locationName?: string;
      flowDirectionId?: string;
      classificationIds?: Array<number>;
      typeId?: string;
      statusId?: string;
      useRfsLocations?: boolean;
      searchPhrase?: string;
    }
  ) {
    super(payload);
    if (payload) {
      this.payload.useRfsLocations = payload.useRfsLocations || false;
    } else {
      this.payload = { useRfsLocations: false };
    }
  }
}

export class GetLocationsSuccess implements Action {
  public readonly type = ELocationsActions.GetLocationsSuccess;

  constructor(public payload: { locations: Array<Location>; totalLocationCount: number }) {}
}

export class GetLocationsError implements Action {
  public readonly type = ELocationsActions.GetLocationsError;

  constructor(public payload: { error: Error }) {}
}

export class ClearLocations implements Action {
  public readonly type = ELocationsActions.ClearLocations;
}

export class GetLocationById implements Action {
  public readonly type = ELocationsActions.GetLocationById;

  constructor(public locationId: number) {}
}

export class GetLocationByIdSuccess implements Action {
  public readonly type = ELocationsActions.GetLocationByIdSuccess;

  constructor(public location: Location) {}
}

export class GetLocationByIdError implements Action {
  public readonly type = ELocationsActions.GetLocationByIdError;

  constructor(public payload: { error: Error }) {}
}

export class ClearGetLocationById implements Action {
  public readonly type = ELocationsActions.ClearGetLocationById;
}

export class GetLocationsByIds implements Action {
  public readonly type = ELocationsActions.GET_LOCATIONS_BY_IDS;

  constructor(
    public locationIds: number[],
    public useRfsLocations = false,
    // /location-groups/edit-criteria/{id} needs this for getting all the criterias
    // If the performance becomes an issue, we'll need to create a dedicated endpoint and refactor the code
    public pageSize = 1000
  ) {}
}

export class GetLocationsByIdsSuccess implements Action {
  public readonly type = ELocationsActions.GET_LOCATIONS_BY_IDS_SUCCESS;

  constructor(public locations: Location[]) {}
}

export class GetLocationsByIdsError implements Action {
  public readonly type = ELocationsActions.GET_LOCATIONS_BY_IDS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class GetLocationDictionary extends RetryableAction implements Action {
  public readonly type = ELocationsActions.GetLocationDictionary;
}

export class UpdateLocationDictionary extends RetryableAction implements Action {
  public readonly type = ELocationsActions.UpdateLocationDictionary;
}

export class GetLocationDictionaryError implements Action {
  public readonly type = ELocationsActions.GetLocationDictionaryError;

  constructor(public payload: Error) {}
}

export class GetLocationDictionarySuccess implements Action {
  public readonly type = ELocationsActions.GetLocationDictionarySuccess;

  constructor(
    readonly payload: {
      dropdownDictionary?: DropdownDictionary;
      useCache?: boolean;
    } = { useCache: false }
  ) {}
}

export class GetLocationEffectiveDateHistory implements Action {
  public readonly type = ELocationsActions.GetLocationEffectiveDateHistory;

  constructor(public payload: number) {}
}

export class GetLocationEffectiveDateHistoryFailure implements Action {
  public readonly type = ELocationsActions.GetLocationEffectiveDateHistoryFailure;

  constructor(public payload: Error) {}
}

export class GetLocationEffectiveDateHistorySuccess implements Action {
  public readonly type = ELocationsActions.GetLocationEffectiveDateHistorySuccess;

  constructor(public payload: EffectiveDateRecord[]) {}
}

export class GetLocationGroupEffectiveDateHistory implements Action {
  public readonly type = ELocationsActions.GetLocationGroupEffectiveDateHistory;

  constructor(public payload: number) {}
}

export class GetLocationGroupEffectiveDateHistoryFailure implements Action {
  public readonly type = ELocationsActions.GetLocationGroupEffectiveDateHistoryFailure;

  constructor(public payload: Error) {}
}

export class GetLocationGroupEffectiveDateHistorySuccess implements Action {
  public readonly type = ELocationsActions.GetLocationGroupEffectiveDateHistorySuccess;

  constructor(public payload: EffectiveDateRecord[]) {}
}

export class FetchLocationGroupById extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupById;

  constructor(
    public payload: {
      locationGroupId: number;
      effectiveDate?: Date;
    }
  ) {
    super(payload);
  }
}

export class FetchLocationGroupByIdSuccess implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupByIdSuccess;

  constructor(
    public payload: {
      locationGroup: LocationGroup;
    }
  ) {}
}

export class FetchLocationGroupByIdFailure implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupByIdFailure;

  constructor(
    public payload: {
      error: Error;
    }
  ) {}
}

export class FetchLocationGroups extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FetchLocationGroups;

  constructor(
    public payload?: {
      tspId?: number;
      pageSize?: number;
      pageNumber?: number;
      sortBy?: Array<string>;
      SearchPhrase?: string;
      effectiveDate?: Date;
      statusCode?: string;
      groupTypeId?: number;
      groupName?: string;
      groupLocationId?: Array<string>;
      locationGroupIds?: Array<string>;
      fields?: string;
    }
  ) {
    super(payload);
  }
}

export class FetchLocationGroupsSuccess implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupsSuccess;

  constructor(public payload: LocationGroupCollection) {}
}

export class FetchLocationGroupsFailure implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupsFailure;

  constructor(public payload: Error) {}
}

export class ClearLocationGroups implements Action {
  public readonly type = ELocationsActions.ClearLocationGroups;
}

export class FetchLocationGroupsByTspIds implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupsByTspIds;

  constructor(public tspIds: number[], public fields?: string, public pageSize: number = null) {}
}

export class FetchLocationGroupsByTspIdsSuccess implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupsByTspIdsSuccess;

  constructor(public payload: LocationGroupCollection[]) {}
}

export class FetchLocationGroupsByTspIdsFailure implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupsByTspIdsFailure;

  constructor(public payload: Error) {}
}

export class ResetLocation implements Action {
  public readonly type = ELocationsActions.ResetLocation;
}

export class UpdateLocation implements Action {
  public readonly type = ELocationsActions.UpdateLocation;

  constructor(public payload: { location: Location }) {}
}
export class UpdateLocationSuccess implements Action {
  public readonly type = ELocationsActions.UpdateLocationSuccess;

  constructor(public payload: { location: Location }) {}
}

export class UpdateLocationError implements Action {
  public readonly type = ELocationsActions.UpdateLocationError;

  constructor(public payload: { error: Error }) {}
}

export class ClearUpdateLocationValidationErrors implements Action {
  public readonly type = ELocationsActions.ClearUpdateLocationValidationErrors;
}

export class SearchLocations implements Action {
  public readonly type = ELocationsActions.SearchLocations;

  constructor(public payload: ILocationsSearchPayload) {}
}

export class SetPreviouslySelectedSearchLocation implements Action {
  public readonly type = ELocationsActions.SetPreviouslySelectedSearchLocation;

  constructor(public payload: { location: Location }) {}
}

export class SearchLocationsSuccess implements Action {
  public readonly type = ELocationsActions.SearchLocationsSuccess;

  constructor(public payload: { locations: Array<Location> }) {}
}

export class SearchLocationsFailure implements Action {
  public readonly type = ELocationsActions.SearchLocationsFailure;

  constructor(public payload: { error: Error }) {}
}

export class SearchLocationsFromMultipleTsps implements Action {
  public readonly type = ELocationsActions.SearchLocationsFromMultipleTsps;

  constructor(
    public payload: {
      tspIds?: Array<number>;
      searchPhrase?: string;
      pageSize?: number;
      pageNumber?: number;
      sortBy?: string;
      nominateable?: boolean;
      excludeAssociated?: boolean;
    }
  ) {}
}

export class SearchLocationsFromMultipleTspsSuccess implements Action {
  public readonly type = ELocationsActions.SearchLocationsFromMultipleTspsSuccess;

  constructor(public payload: { locations }) {}
}

export class SearchLocationsFromMultipleTspsFailure implements Action {
  public readonly type = ELocationsActions.SearchLocationsFromMultipleTspsFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearSearchLocations implements Action {
  public readonly type = ELocationsActions.ClearSearchLocations;

  constructor() {}
}

export class SearchLocationHeaders implements Action {
  public readonly type = ELocationsActions.SearchLocationHeaders;

  constructor(public payload: ILocationHeadersSearchPayload) {}
}

export class SearchLocationHeadersSuccess implements Action {
  public readonly type = ELocationsActions.SearchLocationHeadersSuccess;

  constructor(public payload: LocationHeaderCollection) {}
}

export class SearchLocationHeadersFailure implements Action {
  public readonly type = ELocationsActions.SearchLocationHeadersFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearSearchLocationHeaders implements Action {
  public readonly type = ELocationsActions.ClearSearchLocationHeaders;

  constructor() {}
}

export class CreateLocationGroup extends RetryableAction implements Action {
  public readonly type = ELocationsActions.CreateLocationGroup;

  constructor(
    public payload: {
      locationGroup: LocationGroup;
    }
  ) {
    super();
  }
}

export class CreateLocationGroupSuccess implements Action {
  public readonly type = ELocationsActions.CreateLocationGroupSuccess;
}

export class CreateLocationGroupFailure implements Action {
  public readonly type = ELocationsActions.CreateLocationGroupFailure;

  constructor(public payload: { error: Error }) {}
}

export class UpdateLocationGroup extends RetryableAction implements Action {
  public readonly type = ELocationsActions.UpdateLocationGroup;

  constructor(
    public payload: {
      locationGroup: LocationGroup;
    }
  ) {
    super();
  }
}

export class UpdateLocationGroupSuccess implements Action {
  public readonly type = ELocationsActions.UpdateLocationGroupSuccess;
}

export class UpdateLocationGroupFailure implements Action {
  public readonly type = ELocationsActions.UpdateLocationGroupFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearLocationGroupUpdateErrors implements Action {
  public readonly type = ELocationsActions.ClearLocationGroupUpdateErrors;
}

export class DeleteLocationGroup extends RetryableAction implements Action {
  public readonly type = ELocationsActions.DeleteLocationGroup;

  constructor(
    public payload: {
      locationGroupId: number;
    }
  ) {
    super();
  }
}

export class DeleteLocationGroupSuccess implements Action {
  public readonly type = ELocationsActions.DeleteLocationGroupSuccess;
}

export class DeleteLocationGroupFailure implements Action {
  public readonly type = ELocationsActions.DeleteLocationGroupFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearDeleteLocationGroupError implements Action {
  public readonly type = ELocationsActions.ClearDeleteLocationGroupError;
}

export class FetchStorageLocationsAction extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FetchStorageLocations;

  constructor(
    public payload: {
      masterRateScheduleCode: string;
      tspId: number;
    }
  ) {
    super();
  }
}

export class FetchStorageLocationsSuccessAction implements Action {
  public readonly type = ELocationsActions.FetchStorageLocationsSuccess;

  constructor(public payload: StorageLocationResult) {}
}

export class FetchStorageLocationsFailureAction implements Action {
  public readonly type = ELocationsActions.FetchStorageLocationsFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchMultipleLocationGroupsById extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FETCH_LOCATION_GROUPS_BY_IDS;

  constructor(public locationGroupdIds: Array<number>) {
    super();
  }
}

export class FetchMultipleLocationGroupsByIdSuccess implements Action {
  public readonly type = ELocationsActions.FETCH_LOCATION_GROUPS_BY_IDS_SUCCESS;

  constructor(
    public payload: {
      locationGroupByIds: Array<LocationGroup>;
    }
  ) {}
}

export class FetchMultipleLocationGroupsByIdFailure implements Action {
  public readonly type = ELocationsActions.FETCH_LOCATION_GROUPS_BY_IDS_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchLocationGroupCriteriaById extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupCriteriaById;

  constructor(
    public payload: {
      locationGroupId: number;
      effectiveDate?: Date;
      clearCurrentLoadedGroup?: boolean;
    }
  ) {
    super(payload);
  }
}

export class FetchLocationGroupCriteriaByIdSuccess implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupCriteriaByIdSuccess;

  constructor(
    public payload: {
      dynamicLocationGroup: DynamicLocationGroup;
    }
  ) {}
}

export class FetchLocationGroupCriteriaByIdFailure implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupCriteriaByIdFailure;

  constructor(
    public payload: {
      error: Error;
    }
  ) {}
}

export class ClearLocationGroupCriteria implements Action {
  public readonly type = ELocationsActions.ClearLocationGroupCriteria;
}

export class CreateLocationGroupCriteria extends RetryableAction implements Action {
  public readonly type = ELocationsActions.CreateLocationGroupCriteria;

  constructor(
    public payload: {
      locationGroupCriteria: DynamicLocationGroup;
    }
  ) {
    super();
  }
}

export class CreateLocationGroupCriteriaSuccess implements Action {
  public readonly type = ELocationsActions.CreateLocationGroupCriteriaSuccess;
}

export class CreateLocationGroupCriteriaFailure implements Action {
  public readonly type = ELocationsActions.CreateLocationGroupCriteriaFailure;

  constructor(public payload: { error: Error }) {}
}

export class UpdateLocationGroupCriteria extends RetryableAction implements Action {
  public readonly type = ELocationsActions.UpdateLocationGroupCriteria;

  constructor(
    public payload: {
      locationGroupCriteria: DynamicLocationGroup;
    }
  ) {
    super();
  }
}

export class UpdateLocationGroupCriteriaSuccess implements Action {
  public readonly type = ELocationsActions.UpdateLocationGroupCriteriaSuccess;
}

export class UpdateLocationGroupCriteriaFailure implements Action {
  public readonly type = ELocationsActions.UpdateLocationGroupCriteriaFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchLocationGroupChildLocations extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupChildLocations;

  constructor(
    public payload: {
      locationGroupId: number;
      effectiveDate?: Date;
    }
  ) {
    super(payload);
  }
}

export class FetchLocationGroupChildLocationsSuccess implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupChildLocationsSuccess;

  constructor(public payload: { childLocations: LocationResult[] }) {}
}

export class FetchLocationGroupChildLocationsFailure implements Action {
  public readonly type = ELocationsActions.FetchLocationGroupChildLocationsFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchLocationsList extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FetchLocationsList;

  constructor(public payload: IListLocationSearch) {
    super(payload);
  }
}
export class FetchLocationsListSuccess implements Action {
  public readonly type = ELocationsActions.FetchLocationsListSuccess;

  constructor(public payload: { locations: LocationListCollection }) {}
}
export class FetchLocationsListFailure implements Action {
  public readonly type = ELocationsActions.FetchLocationsListFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchParentLocation implements Action {
  public readonly type = ELocationsActions.FetchParentLocation;

  constructor(public payload: { childLocationId: number }) {}
}

export class FetchParentLocationSuccess implements Action {
  public readonly type = ELocationsActions.FetchParentLocationSuccess;

  constructor(public payload: { parentLocation?: Location }) {}
}

export class FetchParentLocationFailure implements Action {
  public readonly type = ELocationsActions.FetchParentLocationFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearParentLocation implements Action {
  public readonly type = ELocationsActions.ClearParentLocation;
}

export class FetchDynamicLocationGroups extends RetryableAction implements Action {
  public readonly type = ELocationsActions.FetchDynamicLocationGroups;

  constructor(public payload: IFetchDynamicLocationGroupsPayload) {
    super(payload);
  }
}

export class FetchDynamicLocationGroupsSuccess implements Action {
  public readonly type = ELocationsActions.FetchDynamicLocationGroupsSuccess;

  constructor(public payload: { locationGroups: DynamicLocationGroup[] }) {}
}

export class FetchDynamicLocationGroupsFailure implements Action {
  public readonly type = ELocationsActions.FetchDynamicLocationGroupsFailure;

  constructor(
    public payload: {
      error: Error;
    }
  ) {}
}

export type LocationActions =
  | AddLocation
  | AddLocationError
  | AddLocationSuccess
  | ClearAddLocationValidationErrors
  | GetLocations
  | GetLocationsSuccess
  | GetLocationsError
  | ClearLocations
  | GetLocationById
  | GetLocationByIdSuccess
  | GetLocationByIdError
  | ClearGetLocationById
  | GetLocationsByIds
  | GetLocationsByIdsSuccess
  | GetLocationsByIdsError
  | GetLocationDictionary
  | GetLocationDictionaryError
  | GetLocationDictionarySuccess
  | GetLocationEffectiveDateHistory
  | GetLocationEffectiveDateHistorySuccess
  | GetLocationEffectiveDateHistoryFailure
  | GetLocationGroupEffectiveDateHistory
  | GetLocationGroupEffectiveDateHistorySuccess
  | GetLocationGroupEffectiveDateHistoryFailure
  | FetchLocationGroupById
  | FetchLocationGroupByIdSuccess
  | FetchLocationGroupByIdFailure
  | FetchLocationGroups
  | FetchLocationGroupsSuccess
  | FetchLocationGroupsFailure
  | ClearLocationGroups
  | FetchLocationGroupsByTspIds
  | FetchLocationGroupsByTspIdsSuccess
  | FetchLocationGroupsByTspIdsFailure
  | ResetLocation
  | UpdateLocation
  | UpdateLocationSuccess
  | UpdateLocationError
  | ClearUpdateLocationValidationErrors
  | SearchLocations
  | SetPreviouslySelectedSearchLocation
  | SearchLocationsSuccess
  | SearchLocationsFailure
  | SearchLocationsFromMultipleTsps
  | SearchLocationsFromMultipleTspsSuccess
  | SearchLocationsFromMultipleTspsFailure
  | ClearSearchLocations
  | SearchLocationHeaders
  | SearchLocationHeadersSuccess
  | SearchLocationHeadersFailure
  | ClearSearchLocationHeaders
  | CreateLocationGroup
  | CreateLocationGroupSuccess
  | CreateLocationGroupFailure
  | UpdateLocationGroup
  | UpdateLocationGroupSuccess
  | UpdateLocationGroupFailure
  | ClearLocationGroupUpdateErrors
  | DeleteLocationGroup
  | DeleteLocationGroupSuccess
  | DeleteLocationGroupFailure
  | ClearDeleteLocationGroupError
  | FetchStorageLocationsAction
  | FetchStorageLocationsSuccessAction
  | FetchStorageLocationsFailureAction
  | FetchMultipleLocationGroupsById
  | FetchMultipleLocationGroupsByIdFailure
  | FetchMultipleLocationGroupsByIdSuccess
  | UpdateLocationDictionary
  | FetchLocationGroupCriteriaById
  | FetchLocationGroupCriteriaByIdSuccess
  | FetchLocationGroupCriteriaByIdFailure
  | ClearLocationGroupCriteria
  | CreateLocationGroupCriteria
  | CreateLocationGroupCriteriaSuccess
  | CreateLocationGroupCriteriaFailure
  | UpdateLocationGroupCriteria
  | UpdateLocationGroupCriteriaSuccess
  | UpdateLocationGroupCriteriaFailure
  | FetchLocationGroupChildLocations
  | FetchLocationGroupChildLocationsSuccess
  | FetchLocationGroupChildLocationsFailure
  | FetchLocationsList
  | FetchLocationsListSuccess
  | FetchLocationsListFailure
  | FetchParentLocation
  | FetchParentLocationSuccess
  | FetchParentLocationFailure
  | ClearParentLocation
  | FetchDynamicLocationGroups
  | FetchDynamicLocationGroupsSuccess
  | FetchDynamicLocationGroupsFailure;
