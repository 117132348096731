<!--These divs are wrapped in message-container-wrapper as a fix for IE. DO NOT REMOVE-->
<div *ngIf="messages && messages.length" class="message-container-wrapper">
  <div class="message" [ngClass]="classes">
    <div class="icon" [ngClass]="iconClasses"><i [ngClass]="iconType"> </i></div>
    <div>
      <div class="dismiss-button" *ngIf="showDismissButton">
        <i class="icon-dismiss-position icon-close-small" (click)="dismissMessage()"></i>
      </div>
      <div class="content" *ngFor="let message of messages; let i = index">
        <span class="label" *ngIf="!message.code">{{ type | titlecase }}:</span>
        <span class="label" *ngIf="message.code">{{ message.code }}:</span>
        <span class="message-content">{{ message.message }}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!(messages && messages.length)" class="message-container-wrapper">
  <div class="message" [ngClass]="classes">
    <div class="icon" [ngClass]="iconClasses"><i [ngClass]="iconType"> </i></div>
    <div class="label">{{ type | titlecase }}:</div>
    <div>
      <ng-content class="content"></ng-content>
    </div>
  </div>
</div>
