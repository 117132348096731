import { Contact } from '@gms/allocation-api'; //TODO: do we need this?
import { LocationHeader } from '@gms/location-api';
import {
  AccumulatedFlow,
  FlowMetrics,
  GasQuality,
  LookupCollections,
  Measurement,
  MeasurementPeriodCollection,
} from '@gms/measurement-api';
import { HourlyOverrun } from '@gms/measurement-api/model/hourlyOverrun';
import { Location } from '@gms/measurement-api/model/location';
import { Action } from '@ngrx/store';

import { RetryableAction } from 'app/store/retry/retry.actions';

export enum EMeasurementsActions {
  FETCH_LOCATION_MEASUREMENTS = '[MEASUREMENTS] Fetch Location Measurements',
  FETCH_LOCATION_MEASUREMENTS_SUCCESS = '[MEASUREMENTS] Fetch Location Measurements Success',
  FETCH_LOCATION_MEASUREMENTS_FAILURE = '[MEASUREMENTS] Fetch Location Measurements Failure',
  FETCH_LOOKUP_COLLECTIONS = '[MEASUREMENTS] Fetch Lookup Collections',
  FETCH_LOOKUP_COLLECTIONS_SUCCESS = '[MEASUREMENTS] Fetch Lookup Collections Success',
  FETCH_LOOKUP_COLLECTIONS_FAILURE = '[MEASUREMENTS] Fetch Lookup Collections Failure',
  RESET_LOCATION_MEASUREMENTS = '[MEASUREMENTS] Reset Location Measurements',
  SET_LOCATION_MEASUREMENTS = '[MEASUREMENTS] Set Location Measurements',
  UPDATE_LOCATION_MEASUREMENT = '[MEASUREMENTS] Update Location Measurement',
  UPDATE_LOCATION_MEASUREMENT_SUCCESS = '[MEASUREMENTS] Update Location Measurement Success',
  UPDATE_LOCATION_MEASUREMENT_FAILURE = '[MEASUREMENTS] Update Location Measurement Failure',
  FETCH_HOURLY_OVERRUN = '[MEASUREMENTS] Fetch Hourly Overruns',
  FETCH_HOURLY_OVERRUN_SUCCESS = '[MEASUREMENTS] Fetch Hourly Overruns Success',
  FETCH_HOURLY_OVERRUN_FAILURE = '[MEASUREMENTS] Fetch Hourly Overruns Failure',
  EXPORT_HOURLY_OVERRUN = '[MEASUREMENTS] Export Hourly Overrun',
  EXPORT_HOURLY_OVERRUN_SUCCESS = '[MEASUREMENTS] Export Hourly Overrun Success',
  EXPORT_HOURLY_OVERRUN_FAILURE = '[MEASUREMENTS] Export Hourly Overrun Failure',
  FETCH_GAS_QUALITY = '[MEASUREMENTS] Fetch Gas Quality',
  FETCH_GAS_QUALITY_SUCCESS = '[MEASUREMENTS] Fetch Gas Quality Success',
  FETCH_GAS_QUALITY_FAILURE = '[MEASUREMENTS] Fetch Gas Quality Failure',
  EXPORT_GAS_QUALITY = '[MEASUREMENTS] Export Gas Quality',
  EXPORT_GAS_QUALITY_SUCCESS = '[MEASUREMENTS] Export Gas Quality Success',
  EXPORT_GAS_QUALITY_FAILURE = '[MEASUREMENTS] Export Gas Quality Failure',
  FETCH_ACCUMULATED_FLOW_SUMMARY = '[MEASUREMENTS] Fetch Accumulated Flow Summary',
  FETCH_ACCUMULATED_FLOW_SUMMARY_SUCCESS = '[MEASUREMENTS] Fetch Accumulated Flow Summary Success',
  FETCH_ACCUMULATED_FLOW_SUMMARY_FAILURE = '[MEASUREMENTS] Fetch Accumulated Flow Summary Failure',
  FETCH_ACCUMULATED_FLOWS = '[MEASUREMENTS] Fetch Accumulated Flows',
  FETCH_ACCUMULATED_FLOWS_SUCCESS = '[MEASUREMENTS] Fetch Accumulated Flows Success',
  FETCH_ACCUMULATED_FLOWS_FAILURE = '[MEASUREMENTS] Fetch Accumulated Flows Failure',
  FETCH_ACCUMULATED_FLOWS_SUMMARY_SUCCESS = 'FETCH_ACCUMULATED_FLOWS_SUMMARY_SUCCESS',
  FETCH_ACCUMULATED_FLOWS_SUMMARY_FAILURE = 'FETCH_ACCUMULATED_FLOWS_SUMMARY_FAILURE',
  EXPORT_MEASUREMENT_INFO = '[MEASUREMENTS] Export Measurement Info',
  EXPORT_MEASUREMENT_INFO_SUCCESS = '[MEASUREMENTS] Export Measurement Info Success',
  EXPORT_MEASUREMENT_INFO_FAILURE = '[MEASUREMENTS] Export Measurement Info Failure',
  CREATE_LOCATION_MEASUREMENT = '[MEASUREMENTS] Create Location Measurement',
  CREATE_LOCATION_MEASUREMENT_SUCCESS = '[MEASUREMENTS] Create Location Measurement Success',
  CREATE_LOCATION_MEASUREMENT_FAILURE = '[MEASUREMENTS] Create Location Measurement Failure',
}

export class FetchLocationMeasurements extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.FETCH_LOCATION_MEASUREMENTS;

  constructor(
    public payload?: {
      tspId?: number;
      locationNumbers?: Array<string>;
      beginFlowDate?: Date;
      endFlowDate?: Date;
      locationId?: number;
    }
  ) {
    super(payload);
  }
}

export class FetchLocationMeasurementsFailure implements Action {
  public readonly type = EMeasurementsActions.FETCH_LOCATION_MEASUREMENTS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchLocationMeasurementsSuccess implements Action {
  public readonly type = EMeasurementsActions.FETCH_LOCATION_MEASUREMENTS_SUCCESS;

  constructor(public payload: { locationMeasurements: MeasurementPeriodCollection }) {}
}

export class FetchLookupCollections extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.FETCH_LOOKUP_COLLECTIONS;

  constructor() {
    super();
  }
}

export class FetchLookupCollectionsFailure implements Action {
  public readonly type = EMeasurementsActions.FETCH_LOOKUP_COLLECTIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchLookupCollectionsSuccess implements Action {
  public readonly type = EMeasurementsActions.FETCH_LOOKUP_COLLECTIONS_SUCCESS;

  constructor(
    readonly payload: { lookupCollections?: LookupCollections; useCache?: boolean } = {
      useCache: false,
    }
  ) {}
}

export class ResetLocationMeasurements implements Action {
  public readonly type = EMeasurementsActions.RESET_LOCATION_MEASUREMENTS;
}

export class SetLocationMeasurements implements Action {
  public readonly type = EMeasurementsActions.SET_LOCATION_MEASUREMENTS;

  constructor(
    public payload: {
      locationMeasurements: MeasurementPeriodCollection;
      previousSearched?: { summaryEnergyQuantity?: number; location: LocationHeader };
    }
  ) {}
}

export class UpdateLocationMeasurement extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.UPDATE_LOCATION_MEASUREMENT;

  constructor(
    public payload: {
      locationMeasurements: MeasurementPeriodCollection;
      beginFlowDate: Date;
      endFlowDate: Date;
      locationNumber: string;
      tspId: number;
    }
  ) {
    super();
  }
}

export class UpdateLocationMeasurementSuccess implements Action {
  public readonly type = EMeasurementsActions.UPDATE_LOCATION_MEASUREMENT_SUCCESS;
}

export class UpdateLocationMeasurementFailure implements Action {
  public readonly type = EMeasurementsActions.UPDATE_LOCATION_MEASUREMENT_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class CreateLocationMeasurement extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.CREATE_LOCATION_MEASUREMENT;

  constructor(
    public payload: {
      measurement: Measurement;
      locationNumber?: string;
      tspId: number;
    }
  ) {
    super();
  }
}

export class CreateLocationMeasurementSuccess implements Action {
  public readonly type = EMeasurementsActions.CREATE_LOCATION_MEASUREMENT_SUCCESS;
}

export class CreateLocationMeasurementFailure implements Action {
  public readonly type = EMeasurementsActions.CREATE_LOCATION_MEASUREMENT_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchHourlyOverrun extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.FETCH_HOURLY_OVERRUN;

  constructor(
    public payload: {
      locationId: number;
      gasDay: string;
    }
  ) {
    super();
  }
}

export class FetchHourlyOverrunSuccess implements Action {
  public readonly type = EMeasurementsActions.FETCH_HOURLY_OVERRUN_SUCCESS;

  constructor(public payload: { hourlyOverrun: HourlyOverrun }) {}
}

export class FetchHourlyOverrunFailure implements Action {
  public readonly type = EMeasurementsActions.FETCH_HOURLY_OVERRUN_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ExportHourlyOverrun extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.EXPORT_HOURLY_OVERRUN;

  constructor(
    public payload: {
      locationId: number;
      locationNumber: string;
      gasDay: string;
      tspId: number;
    }
  ) {
    super();
  }
}

export class ExportHourlyOverrunSuccess implements Action {
  public readonly type = EMeasurementsActions.EXPORT_HOURLY_OVERRUN_SUCCESS;
}

export class ExportHourlyOverrunFailure implements Action {
  public readonly type = EMeasurementsActions.EXPORT_HOURLY_OVERRUN_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchGasQuality implements Action {
  public readonly type = EMeasurementsActions.FETCH_GAS_QUALITY;

  constructor(
    public payload: {
      locationId: number;
      accountingPeriod: string;
    }
  ) {}
}

export class FetchGasQualitySuccess implements Action {
  public readonly type = EMeasurementsActions.FETCH_GAS_QUALITY_SUCCESS;

  constructor(public payload: { gasQuality: GasQuality }) {}
}

export class FetchGasQualityFailure implements Action {
  public readonly type = EMeasurementsActions.FETCH_GAS_QUALITY_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export interface ExportGasQualityPayload {
  locationId: number;
  locationNumber: string;
  accountingPeriod: string;
}
export class ExportGasQuality extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.EXPORT_GAS_QUALITY;

  constructor(public payload: ExportGasQualityPayload) {
    super();
  }
}

export class ExportGasQualitySuccess extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.EXPORT_GAS_QUALITY_SUCCESS;

  constructor() {
    super();
  }
}

export class ExportGasQualityFailure extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.EXPORT_GAS_QUALITY_FAILURE;

  constructor(public payload: { error: Error }) {
    super();
  }
}

export interface AccumulatedFlowSummaryPayload {
  locationId: number;
  gasDay: Date;
}
export class FetchAccumulatedFlowSummary implements Action {
  public readonly type = EMeasurementsActions.FETCH_ACCUMULATED_FLOW_SUMMARY;

  constructor(public payload: AccumulatedFlowSummaryPayload) {}
}

export interface AccumulatedFlowSummarySuccessPayload {
  hasChildren: boolean;
  location?: Location;
  flow?: FlowMetrics;
  contact?: Contact;
}

export class FetchAccumulatedFlowSummarySuccess implements Action {
  public readonly type = EMeasurementsActions.FETCH_ACCUMULATED_FLOW_SUMMARY_SUCCESS;

  constructor(public payload: AccumulatedFlowSummarySuccessPayload) {}
}

export class FetchAccumulatedFlowSummaryFailure implements Action {
  public readonly type = EMeasurementsActions.FETCH_ACCUMULATED_FLOW_SUMMARY_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export interface AccumulatedFlowsPayload {
  locationId: number;
  gasDay: Date;
  pageSize?: number;
  pageNumber?: number;
}

export class FetchAccumulatedFlows implements Action {
  public readonly type = EMeasurementsActions.FETCH_ACCUMULATED_FLOWS;

  constructor(public payload: AccumulatedFlowsPayload) {}
}

export interface AccumulatedFlowsSuccessPayload {
  accumulatedFlows?: Array<AccumulatedFlow>;
  total?: number;
}

export class FetchAccumulatedFlowsSuccess implements Action {
  public readonly type = EMeasurementsActions.FETCH_ACCUMULATED_FLOWS_SUCCESS;

  constructor(public payload: AccumulatedFlowsSuccessPayload) {}
}

export class FetchAccumulatedFlowsFailure implements Action {
  public readonly type = EMeasurementsActions.FETCH_ACCUMULATED_FLOWS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ExportMeasurementInfo extends RetryableAction implements Action {
  public readonly type = EMeasurementsActions.EXPORT_MEASUREMENT_INFO;

  constructor(
    public payload: {
      tspId?: number;
      beginFlowDate?: Date;
      endFlowDate?: Date;
      locationId?: number;
      locationNumber?: string;
      exportChildrenWithEmail?: boolean;
    }
  ) {
    super();
  }
}

export class ExportMeasurementInfoSuccess implements Action {
  public readonly type = EMeasurementsActions.EXPORT_MEASUREMENT_INFO_SUCCESS;
}

export class ExportMeasurementInfoFailure implements Action {
  public readonly type = EMeasurementsActions.EXPORT_MEASUREMENT_INFO_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export type MeasurementsActions =
  | FetchLocationMeasurements
  | FetchLocationMeasurementsFailure
  | FetchLocationMeasurementsSuccess
  | FetchLookupCollections
  | FetchLookupCollectionsFailure
  | FetchLookupCollectionsSuccess
  | ResetLocationMeasurements
  | SetLocationMeasurements
  | UpdateLocationMeasurement
  | UpdateLocationMeasurementSuccess
  | UpdateLocationMeasurementFailure
  | CreateLocationMeasurement
  | CreateLocationMeasurementSuccess
  | CreateLocationMeasurementFailure
  | FetchHourlyOverrun
  | FetchHourlyOverrunSuccess
  | FetchHourlyOverrunFailure
  | ExportHourlyOverrun
  | ExportHourlyOverrunFailure
  | ExportHourlyOverrunSuccess
  | FetchGasQuality
  | FetchGasQualitySuccess
  | FetchGasQualityFailure
  | ExportGasQuality
  | ExportGasQualitySuccess
  | ExportGasQualityFailure
  | FetchAccumulatedFlowSummary
  | FetchAccumulatedFlowSummarySuccess
  | FetchAccumulatedFlowSummaryFailure
  | FetchAccumulatedFlows
  | FetchAccumulatedFlowsSuccess
  | FetchAccumulatedFlowsFailure
  | ExportMeasurementInfo
  | ExportMeasurementInfoFailure
  | ExportMeasurementInfoSuccess;
