import {
  clearState,
  createEmptyLoadingState,
  createErrorState,
  createInitialDataState,
  createLoadingState,
  createSuccessState,
} from '../app/app.models';
import { AuctionsActions, EAuctionsActions } from './auctions.actions';
import { IAuctionsState, initialAuctionsState } from './auctions.state';

export function auctionsReducers(
  state = initialAuctionsState,
  action: AuctionsActions
): IAuctionsState {
  switch (action.type) {
    case EAuctionsActions.GET_AUCTION_COLLECTION:
      return {
        ...state,
        auctionCollection: createLoadingState(state.auctionCollection),
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        sortDescriptors: action.payload.sortDescriptors,
      };
    case EAuctionsActions.GET_AUCTION_COLLECTION_SUCCESS:
      return {
        ...state,
        auctionCollection: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_AUCTION_COLLECTION_FAILURE:
      return {
        ...state,
        auctionCollection: createErrorState(action.payload),
      };
    case EAuctionsActions.GET_TRADE_COLLECTION:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        sortDescriptors: action.payload.sortDescriptors,
        tradeCollection: createLoadingState(state.tradeCollection),
      };
    case EAuctionsActions.GET_TRADE_COLLECTION_SUCCESS:
      return {
        ...state,
        tradeCollection: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_TRADE_COLLECTION_FAILURE:
      return {
        ...state,
        tradeCollection: createErrorState(action.payload.error),
      };
    case EAuctionsActions.POST_TRADE:
      return {
        ...state,
        postTrade: createLoadingState(state.postTrade),
      };
    case EAuctionsActions.POST_TRADE_SUCCESS:
      return {
        ...state,
        postTrade: createSuccessState(action.payload),
      };
    case EAuctionsActions.POST_TRADE_FAILURE:
      return {
        ...state,
        postTrade: createErrorState(action.payload),
      };
    case EAuctionsActions.UPDATE_TRADE_BY_ID:
      return {
        ...state,
        updateTrade: createLoadingState(state.updateTrade),
      };
    case EAuctionsActions.UPDATE_TRADE_BY_ID_SUCCESS:
      return {
        ...state,
        updateTrade: createSuccessState(action.payload),
      };
    case EAuctionsActions.UPDATE_TRADE_BY_ID_FAILURE:
      return {
        ...state,
        updateTrade: createErrorState(action.payload),
      };
    case EAuctionsActions.SUBMIT_TRADES:
      return {
        ...state,
        submitTrades: createLoadingState(state.submitTrades),
      };
    case EAuctionsActions.SUBMIT_TRADES_SUCCESS:
      return {
        ...state,
        submitTrades: createSuccessState(action.payload),
      };
    case EAuctionsActions.SUBMIT_TRADES_FAILURE:
      return {
        ...state,
        submitTrades: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_BIDDING_PERIOD_MAPPINGS:
      return {
        ...state,
        biddingPeriodMappings: createLoadingState(state.biddingPeriodMappings),
      };
    case EAuctionsActions.GET_BIDDING_PERIOD_MAPPINGS_SUCCESS:
      return {
        ...state,
        biddingPeriodMappings: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_BIDDING_PERIOD_MAPPINGS_FAILURE:
      return {
        ...state,
        biddingPeriodMappings: createErrorState(action.payload),
      };

    case EAuctionsActions.GET_AUCTION_SERIES_COLLECTION:
      return {
        ...state,
        auctionSeriesCollection: {
          auctions: [],
        },
      };
    case EAuctionsActions.GET_AUCTION_SERIES_COLLECTION_SUCCESS:
      return {
        ...state,
        auctionSeriesCollection: action.payload,
        getAuctionSeriesCollection: { loading: false, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_SERIES_COLLECTION_FAILURE:
      return {
        ...state,
        auctionSeriesCollection: {
          auctions: [],
          total: null,
        },
        getAuctionSeriesCollection: { loading: false, httpError: action.payload },
      };

    case EAuctionsActions.SAVE_AUCTION_DRAFT:
      return {
        ...state,
        saveAuctionDraftStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.SAVE_AUCTION_DRAFT_SUCCESS:
      return {
        ...state,
        auctionDraft: action.payload,
        saveAuctionDraftStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.SAVE_AUCTION_DRAFT_FAILURE:
      return {
        ...state,
        saveAuctionDraftStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.GET_AUCTION_REFERENCE_TYPES:
      return {
        ...state,
        referenceTypes: {
          ...state.referenceTypes,
          requestStatus: { loading: true, httpError: null },
        },
      };
    case EAuctionsActions.GET_AUCTION_REFERENCE_TYPES_SUCCESS:
      return {
        ...state,
        referenceTypes: {
          data: action.payload,
          requestStatus: { loading: false, httpError: null },
        },
      };
    case EAuctionsActions.GET_AUCTION_REFERENCE_TYPES_FAILURE:
      return {
        ...state,
        referenceTypes: {
          data: {},
          requestStatus: { loading: false, httpError: action.payload },
        },
      };
    case EAuctionsActions.GET_AUCTION_RATE_SCHEDULES:
      return {
        ...state,
        getAuctionRateSchedules: { loading: true, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_RATE_SCHEDULES_SUCCESS:
      return {
        ...state,
        auctionRateSchedules: action.payload,
        getAuctionRateSchedules: { loading: false, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_RATE_SCHEDULES_FAILURE:
      return {
        ...state,
        auctionRateSchedules: [],
        getAuctionRateSchedules: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.GET_AUCTION_RATE_SCHEDULE_DETAILS:
      return {
        ...state,
        getAuctionRateScheduleDetails: { loading: true, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_RATE_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        auctionDetailRateSchedules: action.payload,
        getAuctionRateScheduleDetails: { loading: false, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_RATE_SCHEDULE_DETAILS_FAILURE:
      return {
        ...state,
        auctionDetailRateSchedules: [],
        getAuctionRateSchedules: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.DELETE_AUCTION_DRAFT:
      return {
        ...state,
        deleteAuctionDraftStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.DELETE_AUCTION_DRAFT_SUCCESS:
      return {
        ...state,
        deleteAuctionDraftStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.DELETE_AUCTION_DRAFT_FAILURE:
      return {
        ...state,
        deleteAuctionDraftStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.GET_AUCTION_BY_ID:
      return {
        ...state,
        auctionStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_BY_ID_SUCCESS:
      return {
        ...state,
        auction: action.payload,
        auctionStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_BY_ID_FAILURE:
      return {
        ...state,
        auctionStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.GET_AUCTION_DRAFT_BY_ID:
      return {
        ...state,
        auctionDraftStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_DRAFT_BY_ID_SUCCESS:
      return {
        ...state,
        auctionDraft: action.payload,
        auctionDraftStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_DRAFT_BY_ID_FAILURE:
      return {
        ...state,
        auctionDraftStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.CLEAR_AUCTION_DRAFT:
      return {
        ...state,
        auctionDraft: null,
        auctionDraftStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.CLEAR_AUCTION:
      return {
        ...state,
        auction: null,
        auctionStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.UPDATE_AUCTION_DRAFT:
      return {
        ...state,
        saveAuctionDraftStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.UPDATE_AUCTION_DRAFT_FAILURE:
      return {
        ...state,
        saveAuctionDraftStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.UPDATE_AUCTION_DRAFT_SUCCESS:
      return {
        ...state,
        auctionDraft: action.payload,
        saveAuctionDraftStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.POST_AUCTION:
      return {
        ...state,
        postAuctionStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.POST_AUCTION_SUCCESS:
      return {
        ...state,
        auction: action.payload,
        postAuctionStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.POST_AUCTION_FAILURE:
      return {
        ...state,
        postAuctionStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.CLEAR_POST_AUCTION_STATUS:
      return {
        ...state,
        postAuctionStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.GET_AUCTION_NOTICES:
      return {
        ...state,
        getAuctionNotices: createLoadingState(state.postAuctionEvent),
      };
    case EAuctionsActions.GET_AUCTION_NOTICES_SUCCESS:
      return {
        ...state,
        getAuctionNotices: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_AUCTION_NOTICES_FAILURE:
      return {
        ...state,
        postAuctionEvent: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_AUCTION_NOTICE_DOCUMENT_CONTENT:
      return {
        ...state,
        auctionNoticeDocumentContent: createLoadingState(state.auctionNoticeDocumentContent),
      };
    case EAuctionsActions.GET_AUCTION_NOTICE_DOCUMENT_CONTENT_SUCCESS:
      return {
        ...state,
        auctionNoticeDocumentContent: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_AUCTION_NOTICE_DOCUMENT_CONTENT_FAILURE:
      return {
        ...state,
        auctionNoticeDocumentContent: createErrorState(action.payload),
      };
    case EAuctionsActions.CLEAR_AUCTION_NOTICE:
      return {
        ...state,
        getAuctionNotices: {
          data: null,
          requestStatus: { loading: false, httpError: null },
        },
      };
    case EAuctionsActions.PUT_AUCTION:
      return {
        ...state,
        putAuctionStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.PUT_AUCTION_FAILURE:
      return {
        ...state,
        putAuctionStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.PUT_AUCTION_SUCCESS:
      return {
        ...state,
        auction: action.payload,
        putAuctionStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.PUT_AUCTION_NOTICE:
      return {
        ...state,
        auctionNotice: createLoadingState(state.auctionNotice),
      };
    case EAuctionsActions.PUT_AUCTION_NOTICE_FAILURE:
      return {
        ...state,
        auctionNotice: createErrorState(action.payload),
      };
    case EAuctionsActions.PUT_AUCTION_NOTICE_SUCCESS:
      return {
        ...state,
        auctionNotice: createSuccessState(action.payload),
      };
    case EAuctionsActions.CANCEL_AUCTION_BY_ID:
      return {
        ...state,
        cancelAuctionStatus: createLoadingState(state.cancelAuctionStatus),
      };
    case EAuctionsActions.CANCEL_AUCTION_BY_ID_FAILURE:
      return {
        ...state,
        cancelAuctionStatus: createErrorState(action.payload.error),
      };
    case EAuctionsActions.CANCEL_AUCTION_BY_ID_SUCCESS:
      return {
        ...state,
        cancelAuctionStatus: createSuccessState(action.payload),
      };
    case EAuctionsActions.SET_DETAIL_RATE_SCHEDULE:
      return {
        ...state,
        auctionRateSchedule: action.payload,
      };
    case EAuctionsActions.SET_MASTER_RATE_SCHEDULE:
      return {
        ...state,
        auctionMasterRateSchedule: action.payload,
      };
    case EAuctionsActions.SET_AUCTION_TSP:
      return {
        ...state,
        auctionTsp: action.payload,
      };
    case EAuctionsActions.UPDATE_SERVICE_REQUEST_FORM_VALIDITY:
      return {
        ...state,
        serviceRequestForm: {
          ...state.serviceRequestForm,
          valid: {
            ...state.serviceRequestForm.valid,
            ...action.payload,
          },
        },
      };
    case EAuctionsActions.RESET_SERVICE_REQUEST_FORM_VALIDITY:
      return {
        ...state,
        serviceRequestForm: {
          valid: {},
          touched: false,
        },
      };
    case EAuctionsActions.SET_SERVICE_REQUEST_FORM_AS_TOUCHED:
      return {
        ...state,
        serviceRequestForm: {
          ...state.serviceRequestForm,
          touched: action.payload,
        },
      };
    case EAuctionsActions.UPDATE_CLIENT_SIDE_AUCTION:
      return {
        ...state,
        clientSideAuction: {
          ...state.clientSideAuction,
          ...action.payload,
        },
      };
    case EAuctionsActions.COPY_AUCTION_DRAFT_BY_ID:
    case EAuctionsActions.COPY_AUCTION_BY_ID:
      return {
        ...state,
        copyAuctionStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.COPY_AUCTION_DRAFT_BY_ID_SUCCESS:
    case EAuctionsActions.COPY_AUCTION_BY_ID_SUCCESS:
      return {
        ...state,
        auctionDraft: action.payload,
        copyAuctionStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.COPY_AUCTION_DRAFT_BY_ID_FAILURE:
    case EAuctionsActions.COPY_AUCTION_BY_ID_FAILURE:
      return {
        ...state,
        copyAuctionStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.CLEAR_COPY_AUCTION_STATUS:
      return {
        ...state,
        copyAuctionStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.CLEAR_CANCEL_AUCTION_STATUS:
      return {
        ...state,
        cancelAuctionStatus: {
          data: null,
          requestStatus: { loading: false, httpError: null },
        },
      };
    case EAuctionsActions.SET_AUCTION_OVERVIEW_FORM_LOADING:
      return {
        ...state,
        overviewFormLoading: action.payload,
      };
    case EAuctionsActions.POST_AUCTION_EVENT:
      return {
        ...state,
        postAuctionEvent: createLoadingState(state.postAuctionEvent),
      };
    case EAuctionsActions.POST_AUCTION_EVENT_SUCCESS:
      return {
        ...state,
        postAuctionEvent: createSuccessState(action.payload),
      };
    case EAuctionsActions.POST_AUCTION_EVENT_FAILURE:
      return {
        ...state,
        postAuctionEvent: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_AUCTION_EVENT_POPOUT_PANEL:
      return {
        ...state,
        auctionPopoutPanel: createLoadingState(state.auctionPopoutPanel),
      };
    case EAuctionsActions.GET_AUCTION_EVENT_POPOUT_PANEL_SUCCESS:
      return {
        ...state,
        auctionPopoutPanel: createSuccessState(action.payload.auctionPopOutPanel),
      };
    case EAuctionsActions.GET_AUCTION_EVENT_POPOUT_PANEL_FAILURE:
      return {
        ...state,
        auctionPopoutPanel: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_AUCTION_BID_GRID:
      return {
        ...state,
        auctionBidGrid: createLoadingState(state.auctionBidGrid),
      };
    case EAuctionsActions.GET_AUCTION_BID_GRID_SUCCESS:
      return {
        ...state,
        auctionBidGrid: createSuccessState(action.payload.auctionBidGrid),
      };
    case EAuctionsActions.GET_AUCTION_BID_GRID_FAILURE:
      return {
        ...state,
        auctionBidGrid: createErrorState(action.payload.error),
      };
    case EAuctionsActions.POST_AUCTION_BID:
      return {
        ...state,
        auctionBid: {
          ...createLoadingState(state.auctionBid),
          data: null,
        },
      };
    case EAuctionsActions.POST_AUCTION_BID_SUCCESS:
      return {
        ...state,
        auctionBid: createSuccessState(action.payload),
      };
    case EAuctionsActions.POST_AUCTION_BID_FAILURE:
      return {
        ...state,
        auctionBid: createErrorState(action.payload.error),
      };
    case EAuctionsActions.WITHDRAW_AUCTION_BID:
      return {
        ...state,
        withdrawnBidEvent: {
          ...createLoadingState(state.withdrawnBidEvent),
          data: null,
        },
      };
    case EAuctionsActions.WITHDRAW_AUCTION_BID_SUCCESS:
      return {
        ...state,
        withdrawnBidEvent: createSuccessState(action.payload),
      };
    case EAuctionsActions.WITHDRAW_AUCTION_BID_FAILURE:
      return {
        ...state,
        withdrawnBidEvent: createErrorState(action.payload.error),
      };
    case EAuctionsActions.POST_AUCTION_SEGMENT:
      return {
        ...state,
        postAuctionSegmentStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.POST_AUCTION_SEGMENT_SUCCESS:
      return {
        ...state,
        auctionSegmentResponse: action.payload,
        postAuctionSegmentStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.POST_AUCTION_SEGMENT_FAILURE:
      return {
        ...state,
        postAuctionSegmentStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.GET_AUCTION_AWARDS:
      return {
        ...state,
        getAuctionAwards: createLoadingState(state.getAuctionAwards),
      };
    case EAuctionsActions.GET_AUCTION_AWARDS_SUCCESS:
      return {
        ...state,
        getAuctionAwards: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_AUCTION_AWARDS_FAILURE:
      return {
        ...state,
        getAuctionAwards: createErrorState(action.payload.error),
      };
    case EAuctionsActions.SET_AUCTION:
      return {
        ...state,
        auction: action.payload,
      };
    case EAuctionsActions.SET_AUCTION:
      return {
        ...state,
        auction: action.payload,
      };
    case EAuctionsActions.CREATE_AUCTION_PARTICIPANT:
      return {
        ...state,
        postAuctionParticipant: createLoadingState(state.postAuctionParticipant),
      };
    case EAuctionsActions.CREATE_AUCTION_PARTICIPANT_SUCCESS:
      return {
        ...state,
        currentParticipant: action.payload,
        postAuctionParticipant: createSuccessState(action.payload),
      };
    case EAuctionsActions.CREATE_AUCTION_PARTICIPANT_FAILURE:
      return {
        ...state,
        postAuctionParticipant: createErrorState(action.payload.error),
      };
    case EAuctionsActions.SET_AUCTION:
      return {
        ...state,
        auction: action.payload,
      };
    case EAuctionsActions.GET_AUCTION_PARTICIPANT:
      return {
        ...state,
        auctionParticipant: createLoadingState(state.auctionParticipant),
      };
    case EAuctionsActions.GET_AUCTION_PARTICIPANT_SUCCESS:
      return {
        ...state,
        currentParticipant: action.payload,
        auctionParticipant: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_AUCTION_PARTICIPANT_FAILURE:
      return {
        ...state,
        auctionParticipant: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_AUCTION_PARTICIPANTS:
      return {
        ...state,
        getAuctionParticipants: createLoadingState(state.getAuctionParticipants),
      };
    case EAuctionsActions.GET_AUCTION_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        getAuctionParticipants: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_AUCTION_PARTICIPANTS_FAILURE:
      return {
        ...state,
        getAuctionParticipants: createErrorState(action.payload.error),
      };
    case EAuctionsActions.CREATE_AUCTION_PARTICIPANT:
      return {
        ...state,
        postAuctionParticipant: createLoadingState(state.postAuctionParticipant),
      };
    case EAuctionsActions.CREATE_AUCTION_PARTICIPANT_SUCCESS:
      return {
        ...state,
        postAuctionParticipant: createSuccessState(action.payload),
      };
    case EAuctionsActions.CREATE_AUCTION_PARTICIPANT_FAILURE:
      return {
        ...state,
        postAuctionParticipant: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_AUCTION_SEGMENTS:
      return {
        ...state,
        auctionSegments: createLoadingState(state.auctionSegments),
      };
    case EAuctionsActions.GET_AUCTION_SEGMENTS_SUCCESS:
      return {
        ...state,
        auctionSegments: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_AUCTION_SEGMENTS_FAILURE:
      return {
        ...state,
        auctionSegments: createErrorState(action.payload.error),
      };
    case EAuctionsActions.POST_PARTICIPANT_ENTER:
      return {
        ...state,
        auctionParticipantEnterEvent: createLoadingState(state.auctionParticipantEnterEvent),
      };
    case EAuctionsActions.POST_PARTICIPANT_ENTER_SUCCESS:
      return {
        ...state,
        auctionParticipantEnterEvent: createSuccessState(action.payload),
      };
    case EAuctionsActions.POST_PARTICIPANT_ENTER_FAILURE:
      return {
        ...state,
        auctionParticipantEnterEvent: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_AUCTION_MESSAGE_PANEL:
      return {
        ...state,
        auctionMessagePanel: createLoadingState(state.auctionMessagePanel),
      };
    case EAuctionsActions.GET_AUCTION_MESSAGE_PANEL_SUCCESS:
      return {
        ...state,
        auctionMessagePanel: createSuccessState(action.payload.auctionMessagePanel),
      };
    case EAuctionsActions.GET_AUCTION_MESSAGE_PANEL_FAILURE:
      return {
        ...state,
        auctionMessagePanel: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_AUCTION_BANNER:
      return {
        ...state,
        auctionBanner: createLoadingState(state.auctionBanner),
      };
    case EAuctionsActions.GET_AUCTION_BANNER_SUCCESS:
      return {
        ...state,
        auctionBanner: createSuccessState(action.payload.auctionBanner),
      };
    case EAuctionsActions.GET_AUCTION_BANNER_FAILURE:
      return {
        ...state,
        auctionBanner: createErrorState(action.payload.error),
      };
    case EAuctionsActions.EVENT_UPDATE_AUCTION_BANNER:
      return {
        ...state,
        auctionBanner: createSuccessState(action.payload),
      };
    case EAuctionsActions.TOGGLE_AWARD_PERIOD_TIMER:
      return {
        ...state,
        awardTimerInProgress: action.payload,
      };
    case EAuctionsActions.END_AWARD_PERIOD:
      return {
        ...state,
        awardTimerInProgress: false,
        awardPeriodEnded: true,
      };
    case EAuctionsActions.PUT_AUCTION_AWARD:
      return {
        ...state,
        auctionAward: createLoadingState(state.auctionAward),
      };
    case EAuctionsActions.PUT_AUCTION_AWARD_SUCCESS:
      return {
        ...state,
        auctionAward: createSuccessState(action.payload),
      };
    case EAuctionsActions.PUT_AUCTION_AWARD_FAILURE:
      return {
        ...state,
        auctionAward: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_CALCULATED_AWARD:
      return {
        ...state,
        calculatedAwardCollection: createLoadingState(state.calculatedAwardCollection),
      };
    case EAuctionsActions.GET_CALCULATED_AWARD_SUCCESS:
      return {
        ...state,
        calculatedAwardCollection: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_CALCULATED_AWARD_FAILURE:
      return {
        ...state,
        calculatedAwardCollection: createErrorState(action.payload.error),
      };
    case EAuctionsActions.POST_AUCTION_AWARDS:
      return {
        ...state,
        awardCollection: createLoadingState(state.awardCollection),
      };
    case EAuctionsActions.POST_AUCTION_AWARDS_SUCCESS:
      return {
        ...state,
        awardCollection: createSuccessState(action.payload),
      };
    case EAuctionsActions.POST_AUCTION_AWARDS_FAILURE:
      return {
        ...state,
        awardCollection: createErrorState(action.payload.error),
      };
    case EAuctionsActions.POST_IT_ONLINE_LOCATIONS:
      return {
        ...state,
        itOnlineLocations: { loading: true, httpError: null },
      };
    case EAuctionsActions.POST_IT_ONLINE_LOCATIONS_SUCCESS:
      return {
        ...state,
        itOnlineLocations: { loading: false, httpError: null },
      };
    case EAuctionsActions.POST_IT_ONLINE_LOCATIONS_FAILURE:
      return {
        ...state,
        itOnlineLocations: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.VALIDATE_AUCTION_SETTINGS:
      return {
        ...state,
        auctionSettingsValidation: {
          valid: null,
          validate: true,
        },
      };
    case EAuctionsActions.SET_AUCTION_SETTINGS_VALID:
      return {
        ...state,
        auctionSettingsValidation: {
          validate: null,
          valid: action.payload,
        },
      };
    case EAuctionsActions.POST_AUCTION_NOTICE_TEMPLATE:
      return {
        ...state,
        workflowCaseCreationRequest: createLoadingState(state.workflowCaseCreationRequest),
      };
    case EAuctionsActions.POST_AUCTION_NOTICE_TEMPLATE_SUCCESS:
      return {
        ...state,
        workflowCaseCreationRequest: createSuccessState(action.payload),
      };
    case EAuctionsActions.POST_AUCTION_NOTICE_TEMPLATE_FAILURE:
      return {
        ...state,
        workflowCaseCreationRequest: createErrorState(action.payload.error),
      };
    case EAuctionsActions.CLEAR_AUCTION_NOTICE_TEMPLATE:
      return {
        ...state,
        workflowCaseCreationRequest: {
          data: null,
          requestStatus: {
            loading: false,
            httpError: null,
          },
        },
      };
    case EAuctionsActions.POST_AUCTION_NOTICE:
      return {
        ...state,
        postAuctionNotice: createLoadingState(state.postAuctionNotice),
      };
    case EAuctionsActions.POST_AUCTION_NOTICE_SUCCESS:
      return {
        ...state,
        postAuctionNotice: createSuccessState(action.payload),
      };
    case EAuctionsActions.POST_AUCTION_NOTICE_FAILURE:
      return {
        ...state,
        postAuctionNotice: createErrorState(action.payload.error),
      };
    case EAuctionsActions.EXPORT_TRADES:
      return {
        ...state,
        exportTrades: { loading: true, httpError: null },
      };
    case EAuctionsActions.EXPORT_TRADES_SUCCESS:
      return {
        ...state,
        exportTrades: { loading: false, httpError: null },
      };
    case EAuctionsActions.EXPORT_TRADES_FAILURE:
      return {
        ...state,
        exportTrades: { loading: false, httpError: action.payload.error },
      };
    case EAuctionsActions.SET_DISABLE_CLOSE_BUTTON:
      return {
        ...state,
        disableCloseButton: action.payload,
      };
    case EAuctionsActions.SET_BID_ALTERNATE_RATE_SCHEDULE:
      return {
        ...state,
        bidAlternateRateSchedule: action.payload,
      };
    case EAuctionsActions.GET_IT_ONLINE_RECEIPT_LOCATIONS:
      return {
        ...state,
        itOnlineReceiptLocations: createEmptyLoadingState(),
      };
    case EAuctionsActions.GET_IT_ONLINE_RECEIPT_LOCATIONS_SUCCESS:
      return {
        ...state,
        itOnlineReceiptLocations: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_IT_ONLINE_RECEIPT_LOCATIONS_FAILURE:
      return {
        ...state,
        itOnlineReceiptLocations: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_IT_ONLINE_DELIVERY_LOCATIONS:
      return {
        ...state,
        itOnlineDeliveryLocations: createEmptyLoadingState(),
      };
    case EAuctionsActions.GET_IT_ONLINE_DELIVERY_LOCATIONS_SUCCESS:
      return {
        ...state,
        itOnlineDeliveryLocations: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_IT_ONLINE_DELIVERY_LOCATIONS_FAILURE:
      return {
        ...state,
        itOnlineDeliveryLocations: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_IT_ONLINE_ICE_LOCATIONS:
      return {
        ...state,
        itOnlineIceLocations: createLoadingState(state.itOnlineIceLocations),
      };
    case EAuctionsActions.GET_IT_ONLINE_ICE_LOCATIONS_SUCCESS:
      return {
        ...state,
        itOnlineIceLocations: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_IT_ONLINE_ICE_LOCATIONS_FAILURE:
      return {
        ...state,
        itOnlineIceLocations: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_IT_ONLINE_ADMIN_CELLS:
      return {
        ...state,
        itOnlineAdminCells: createEmptyLoadingState(),
        itOnlineCells: clearState(state.itOnlineCells),
      };
    case EAuctionsActions.GET_IT_ONLINE_ADMIN_CELLS_SUCCESS:
      return {
        ...state,
        itOnlineAdminCells: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_IT_ONLINE_ADMIN_CELLS_FAILURE:
      return {
        ...state,
        itOnlineAdminCells: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_IT_ONLINE_CELLS:
      return {
        ...state,
        itOnlineCells: createEmptyLoadingState(),
        itOnlineAdminCells: clearState(state.itOnlineAdminCells),
      };
    case EAuctionsActions.GET_IT_ONLINE_CELLS_SUCCESS:
      return {
        ...state,
        itOnlineCells: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_IT_ONLINE_CELLS_FAILURE:
      return {
        ...state,
        itOnlineCells: createErrorState(action.payload.error),
      };
    case EAuctionsActions.GET_IT_ONLINE_PARAMETER_SET:
      return {
        ...state,
        itOnlineParameterSet: createLoadingState(state.itOnlineParameterSet),
      };
    case EAuctionsActions.GET_IT_ONLINE_PARAMETER_SET_SUCCESS:
      return {
        ...state,
        itOnlineParameterSet: createSuccessState(action.payload),
      };
    case EAuctionsActions.GET_IT_ONLINE_PARAMETER_SET_FAILURE:
      return {
        ...state,
        itOnlineParameterSet: createErrorState(action.payload.error),
      };
    case EAuctionsActions.PUT_IT_ONLINE_PARAMETER_SET:
      return {
        ...state,
        updatedITOnlineParameterSet: createLoadingState(state.updatedITOnlineParameterSet),
      };
    case EAuctionsActions.PUT_IT_ONLINE_PARAMETER_SET_SUCCESS:
      return {
        ...state,
        updatedITOnlineParameterSet: createSuccessState(action.payload),
      };
    case EAuctionsActions.PUT_IT_ONLINE_PARAMETER_SET_FAILURE:
      return {
        ...state,
        updatedITOnlineParameterSet: createErrorState(action.payload.error),
      };
    case EAuctionsActions.SET_AUCTION_PARTICIPANTS:
      return {
        ...state,
        auctionParticipants: action.payload,
      };
    case EAuctionsActions.DELETE_IT_ONLINE_LOCATION:
      return {
        ...state,
        deleteITOnlineLocationStatus: { loading: true, httpError: null },
      };
    case EAuctionsActions.DELETE_IT_ONLINE_LOCATION_SUCCESS:
      return {
        ...state,
        deleteITOnlineLocationStatus: { loading: false, httpError: null },
      };
    case EAuctionsActions.DELETE_IT_ONLINE_LOCATION_FAILURE:
      return {
        ...state,
        deleteITOnlineLocationStatus: { loading: false, httpError: action.payload },
      };
    case EAuctionsActions.CLEAR_SUBMITTED_IT_ONLINE_TRADE:
      return {
        ...state,
        postTrade: clearState(null),
      };
    case EAuctionsActions.GET_AUCTION_USERS:
      return {
        ...state,
        auctionUsers: createLoadingState(state.auctionUsers),
      };
    case EAuctionsActions.GET_AUCTION_USERS_FAILURE:
      return {
        ...state,
        auctionUsers: createErrorState(action.error),
      };
    case EAuctionsActions.GET_AUCTION_USERS_SUCCESS:
      return {
        ...state,
        auctionUsers: createSuccessState(action.response),
      };
    case EAuctionsActions.SEARCH_AUCTION_USERS:
      return {
        ...state,
        searchedAuctionUsers: createEmptyLoadingState(),
      };
    case EAuctionsActions.SEARCH_AUCTION_USERS_FAILURE:
      return {
        ...state,
        searchedAuctionUsers: createErrorState(action.error),
      };
    case EAuctionsActions.SEARCH_AUCTION_USERS_SUCCESS:
      return {
        ...state,
        searchedAuctionUsers: createSuccessState(action.response),
      };
    case EAuctionsActions.GENERATE_AUCTION_NOTICES:
      return {
        ...state,
        generatedNotices: createLoadingState(state.generatedNotices),
      };
    case EAuctionsActions.GENERATE_AUCTION_NOTICES_SUCCESS:
      return {
        ...state,
        generatedNotices: createSuccessState(action.response),
      };
    case EAuctionsActions.GENERATE_AUCTION_NOTICES_FAILURE:
      return {
        ...state,
        generatedNotices: createErrorState(action.error),
      };
    case EAuctionsActions.CLEAR_AUCTION_NOTICE_STATE:
      return {
        ...state,
        auctionNotice: createInitialDataState(),
        generatedNotices: createInitialDataState(),
        getAuctionNotices: createInitialDataState(),
        postAuctionNotice: createInitialDataState(),
        auctionNoticeDocumentContent: createInitialDataState(),
      };

    case EAuctionsActions.START_AUCTION:
      return {
        ...state,
        startingAuction: createLoadingState(state.startingAuction),
      };
    case EAuctionsActions.START_AUCTION_SUCCESS:
      return {
        ...state,
        startingAuction: createSuccessState(action.response),
      };
    case EAuctionsActions.START_AUCTION_FAILURE:
      return {
        ...state,
        startingAuction: createErrorState(action.error),
      };
    case EAuctionsActions.UPDATE_AUCTION_NOTICE_DOCUMENT_CONTENT_MAP:
      return {
        ...state,
        auctionNoticeDocumentContentsMap: {
          ...state.auctionNoticeDocumentContentsMap,
          ...action.documentContents,
        },
      };
    case EAuctionsActions.CLEAR_AUCTION_NOTICE_DOCUMENT_CONTENT_MAP:
      return {
        ...state,
        auctionNoticeDocumentContentsMap: {},
      };
    default:
      return state;
  }
}
