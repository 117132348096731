import {
  RepRolesCollection,
  RoleRepsCollection,
  UserResponsibilitiesCollection,
} from '@gms/represponsibility-api';
import { Action } from '@ngrx/store';

export enum ERepResponsibilityActions {
  FETCH_REP_RESPONSIBILITY_ROLES = '[REP RESPONSIBLILITES] Fetch Rep Responsibility Roles',
  FETCH_REP_RESPONSIBILITY_ROLES_SUCCESS = '[REP RESPONSIBLILITES] Fetch Rep Responsibility Roles Success',
  FETCH_REP_RESPONSIBILITY_ROLES_ERROR = '[REP RESPONSIBLILITES] Fetch Rep Responsibility Roles Error',
  FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By Entity ID',
  FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID_SUCCESS = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By Entity ID Success',
  FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID_ERROR = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By Entity ID Error',
  FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By Location ID',
  FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID_SUCCESS = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By Location ID Success',
  FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID_ERROR = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By Location ID Error',
  FETCH_REP_RESPONSIBILITES_BY_USER_ID = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By User ID',
  FETCH_REP_RESPONSIBILITES_BY_USER_ID_SUCCESS = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By User ID Success',
  FETCH_REP_RESPONSIBILITES_BY_USER_ID_ERROR = '[REP RESPONSIBLILITES] Fetch Rep Responsibilities By User ID Error',
  CLEAR_REP_RESPONSIBILITIES = '[REP RESPONSIBLILITES] Clear Rep Responsibilities',
  CLEAR_USER_RESPONSIBILITIES = '[REP RESPONSIBLILITES] Clear User Responsibilities',
  CLEAR_REP_RESPONSIBILITY_ROLES = '[REP RESPONSIBLILITES] Clear Rep Responsibility Roles',
  UPDATE_USER_RESPONSIBILITIES = '[REP RESPONSIBILITIES] Update Rep Responsibilities By User ID',
  UPDATE_USER_RESPONSIBILITIES_SUCCESS = '[REP RESPONSIBILITIES] Update Rep Responsibilities By User ID Success',
  UPDATE_USER_RESPONSIBILITIES_ERROR = '[REP RESPONSIBILITIES] Update Rep Responsibilities By User ID Error',
}

export class ClearRepResponsibilities implements Action {
  public readonly type = ERepResponsibilityActions.CLEAR_REP_RESPONSIBILITIES;

  constructor() {}
}

export class ClearUserResponsibilities implements Action {
  public readonly type = ERepResponsibilityActions.CLEAR_USER_RESPONSIBILITIES;

  constructor() {}
}

export class ClearRepResponsibilityRoles implements Action {
  public readonly type = ERepResponsibilityActions.CLEAR_REP_RESPONSIBILITY_ROLES;

  constructor() {}
}

export class FetchRepResponsibilityRoles implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITY_ROLES;

  constructor() {}
}

export class FetchRepResponsibilityRolesSuccess implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITY_ROLES_SUCCESS;

  constructor(public payload: { repResponsibilityRolesCollection: RepRolesCollection }) {}
}

export class FetchRepResponsibilityRolesError implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITY_ROLES_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchRepResponsibilitiesByEntityId implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID;

  constructor(public payload: { entityId: number; tspId?: number }) {}
}

export class FetchRepResponsibilitiesByEntityIdSuccess implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID_SUCCESS;

  constructor(public payload: { repResponsibilityCollection: RoleRepsCollection }) {}
}

export class FetchRepResponsibilitiesByEntityIdError implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchRepResponsibilitiesByLocationId implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID;

  constructor(public payload: number) {}
}

export class FetchRepResponsibilitiesByLocationIdSuccess implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID_SUCCESS;

  constructor(public payload: { repResponsibilityCollection: RoleRepsCollection }) {}
}

export class FetchRepResponsibilitiesByLocationIdError implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchRepResponsibilitiesByUserId implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_USER_ID;

  constructor(public payload: number) {}
}

export class FetchRepResponsibilitiesByUserIdSuccess implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_USER_ID_SUCCESS;

  constructor(public payload: { userResponsibilityCollection: UserResponsibilitiesCollection }) {}
}

export class FetchRepResponsibilitiesByUserIdError implements Action {
  public readonly type = ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_USER_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class UpdateUserResponsibilities implements Action {
  public readonly type = ERepResponsibilityActions.UPDATE_USER_RESPONSIBILITIES;

  constructor(
    public payload: {
      userId: number;
      userResponsibilitiesCollection: UserResponsibilitiesCollection;
    }
  ) {}
}

export class UpdateUserResponsibilitiesSuccess implements Action {
  public readonly type = ERepResponsibilityActions.UPDATE_USER_RESPONSIBILITIES_SUCCESS;

  constructor(public payload: { userResponsibilitiesCollection: UserResponsibilitiesCollection }) {}
}

export class UpdateUserResponsibilitiesError implements Action {
  public readonly type = ERepResponsibilityActions.UPDATE_USER_RESPONSIBILITIES_ERROR;

  constructor(public payload: { error: Error }) {}
}

export type RepResponsibilityActions =
  | ClearRepResponsibilities
  | ClearUserResponsibilities
  | ClearRepResponsibilityRoles
  | FetchRepResponsibilityRoles
  | FetchRepResponsibilityRolesSuccess
  | FetchRepResponsibilityRolesError
  | FetchRepResponsibilitiesByEntityId
  | FetchRepResponsibilitiesByEntityIdSuccess
  | FetchRepResponsibilitiesByEntityIdError
  | FetchRepResponsibilitiesByLocationId
  | FetchRepResponsibilitiesByLocationIdSuccess
  | FetchRepResponsibilitiesByLocationIdError
  | FetchRepResponsibilitiesByUserId
  | FetchRepResponsibilitiesByUserIdSuccess
  | FetchRepResponsibilitiesByUserIdError
  | UpdateUserResponsibilities
  | UpdateUserResponsibilitiesSuccess
  | UpdateUserResponsibilitiesError;
