<button
  id="{{ id }}-inner"
  class="btn"
  [ngClass]="buttonClasses"
  (click)="activate($event)"
  [disabled]="disabled"
  [type]="buttonType"
>
  <span><ng-content></ng-content></span>
</button>
