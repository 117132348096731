import { LookupCollections } from '@gms/common-api';

export interface ILookupState {
  lookupCollections: LookupCollections;
  loading: boolean;
  error: Error | null;
}

export const initialLookupState: ILookupState = {
  lookupCollections: null,
  loading: false,
  error: null,
};
