import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Administration_Routes: Routes = [
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/administration/pages/create-journal-entry-rule/create-journal-entry-rule.module').then(
        m => m.CreateJournalEntryRuleModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/chart-of-accounts',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/edit-journal-entry-rules/edit-journal-entry-rules.module').then(
        m => m.EditJournalEntryRulesModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/chart-of-accounts',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/chart-of-accounts-detail/chart-of-accounts-detail.module').then(
        m => m.ChartOfAccountsDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/chart-of-accounts',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('app/modules/administration/pages/chart-of-accounts-list/chart-of-accounts-list.module').then(
        m => m.ChartOfAccountsListModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/chart-of-accounts',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const CycleConfiguration_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/administration/pages/cycle-configuration/cycle-configuration.module').then(
        m => m.CycleConfigurationModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/cycle-configuration',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Accounts_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/administration/pages/accounts-view/accounts-view.module').then(
        m => m.AccountsViewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/accounts',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/administration/pages/accounts-create/create-account.module').then(
        m => m.CreateAccountModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/accounts',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('app/modules/administration/pages/account-details/account-details.module').then(
        m => m.AccountDetailsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/accounts',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Closing_Dashboard_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/administration/pages/closing-dashboard/closing-dashboard.module').then(
        m => m.ClosingDashboardModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/closing-dashboard',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Ambient_Capacity_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/administration/pages/ambient-capacity/ambient-capacity.module').then(
        m => m.AmbientCapacityModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/ambient-unsubscribed-capacity',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Postings_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../administration/pages/posting/view-posting/view-posting.module').then(
        m => m.ViewPostingModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/informational-postings/posting',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('../administration/pages/posting/create-posting/create-posting.module').then(
        m => m.CreatePostingModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/informational-postings/posting',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('../administration/pages/posting/posting-details/posting-details.module').then(
        m => m.PostingDetailsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/informational-postings/posting',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('../administration/pages/posting/create-posting/create-posting.module').then(
        m => m.CreatePostingModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/informational-postings/posting',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Scheduling_Constrained_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/administration/pages/scheduling-constrained-areas/scheduling-posting.module').then(
        m => m.SchedulingPostingModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/informational-postings/scheduling-and-constrained-areas',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];
