import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

export interface IRequestStatus<T = any> {
  httpError: Error;
  loading: boolean;
  validationError?: T;
}

export const emptyRequestStatus: IRequestStatus = {
  loading: false,
  httpError: null,
};

export const loadingRequestStatus: IRequestStatus = {
  loading: true,
  httpError: null,
};

export const errorRequestStatus = (error: Error): IRequestStatus => ({
  loading: false,
  httpError: error,
});

export interface IDataState<T = any, T2 = any> {
  data: T;
  requestStatus: IRequestStatus<T2>;
}

export interface ICachedDataState<T = any, T2 = any> extends IDataState<T, T2> {
  requestPayload?: any;
}

export interface ICacheableRequestAction extends Action {
  forceRefresh?: boolean;
  payload?: any;
}

export interface ICacheableSuccessAction extends Action {
  requestPayload?: any;
}

/**
 * Returns a new empty IDataState object.
 * The initial state should typically have null for the `data` as well as a false `loading` flag and a null `httpError` property in the `requestStatus`.
 * */
export function createInitialDataState(initialData = null): IDataState {
  return {
    data: initialData,
    requestStatus: { ...emptyRequestStatus },
  };
}

/**
 * Returns a new IDataState object representing the cleared state.
 * The cleared state should have null for the `data` as well as a false `loading` flag and a null `httpError` property in the `requestStatus`.
 * @param {IDataState} currentState - Provides the original state for any additional properties. Note that the requestStatus and data of this object will be override.
 * @param initialData - Sets the data back to its blank state, typically null.
 * */
export function clearState(currentState: IDataState, initialData = null): IDataState {
  return {
    ...currentState,
    data: initialData,
    requestStatus: { ...emptyRequestStatus },
  };
}

/**
 * Returns a new IDataState object representing the loading state.
 * The loading state is represented by a true `loading` flag and a `null` `httpError` property in the `requestStatus`.
 * @param {IDataState} currentState - Provides the original state to copy data from. Note that the requestStatus of this object will be override.
 * */
export function createLoadingState(currentState: IDataState): IDataState {
  return {
    ...currentState,
    requestStatus: {
      loading: true,
      httpError: null,
    },
  };
}

/**
 * Returns a new IDataState object representing the loading state with cleared data.
 * The empty loading state is represented by a true `loading` flag and a `null` `httpError` property in the `requestStatus` and `null` data.
 * */
export function createEmptyLoadingState(initialData = null): IDataState {
  return {
    data: initialData,
    requestStatus: {
      loading: true,
      httpError: null,
    },
  };
}

/**
 * Returns a new IDataState object a success state and with the `data` property set to the value passed in.
 * The success state is represented by a false `loading` flag and a `null` `httpError` property in the `requestStatus`.
 * @param {any} data - The data to be mapped to the `data` property of the state object.
 * @param {Function} dataMapFunction - [Optional] A transformation function to be applied to the supplied data to change it in any way necessary. This function must return a value.
 * */
export function createSuccessState(data: any, dataMapFunction: Function = a => a): IDataState {
  return {
    data: dataMapFunction(data),
    requestStatus: {
      loading: false,
      httpError: null,
    },
  };
}

/**
 * Returns a new IDataState object a success state and with the `data` property set to the value passed in.
 * The success state is represented by a false `loading` flag and a `null` `httpError` property in the `requestStatus`.
 * @param {any} data - The data to be mapped to the `data` property of the state object.
 * @param {any} requestPayload - The payload that was used for the data fetch
 * @param {Function} dataMapFunction - [Optional] A transformation function to be applied to the supplied data to change it in any way necessary. This function must return a value.
 * */
export function createCacheableSuccessState(
  data: any,
  requestPayload?: any,
  dataMapFunction: Function = a => a
): ICachedDataState {
  return {
    ...createSuccessState(data, dataMapFunction),
    requestPayload,
  };
}

/**
 * Returns a new IDataState object representing an Error state with the provided Error, and clean (null) data property.
 * The loading state is represented by a `false` `loading` flag and a populated `httpError` property in the `requestStatus`.
 * @param {Error} error - Provides the error to be saved in the state.
 * @param initialData - Initial data state, typically null
 * */
export function createErrorState(error: Error, initialData = null): IDataState {
  return {
    data: initialData,
    requestStatus: {
      loading: false,
      httpError: error,
    },
  };
}

/**
 * Determines if we should use the cache for the provided dataState and action
 *
 * @param dataState The dataState that results should be stored in
 * @param action The action being dispatched
 * @returns true if we should use the value that is already in the store
 */
export function useCache(dataState: ICachedDataState, action: ICacheableRequestAction): boolean {
  if (action.forceRefresh === true) return false;

  return (
    dataState.data && JSON.stringify(dataState.requestPayload) === JSON.stringify(action.payload)
  );
}

/**
 * Operator function to filter request actions that are already fulfilled by cached data
 *
 * @param dataState$ The dataState that would be updated by the request action
 * @returns An observable of the incoming action that only fires when the data isn't already available
 */
export function filterIfCached(dataState$: Observable<ICachedDataState>) {
  return function<T extends ICacheableRequestAction>(source: Observable<T>): Observable<T> {
    return source.pipe(
      withLatestFrom(dataState$),
      filter(([action, dataState]) => !useCache(dataState, action)),
      map(([action, _]) => action)
    );
  };
}
