export enum LABELS {
  REQUIRED_FIELD = 'Required Field',
  EFFECTIVE_DATE_ATLEAST_TODAY = 'Effective Date must be no earlier than today.',
  EFFECTIVE_DATE_BEFORE_EFFECTIVE_DATE = 'Effective Date must be equal or less than the Expire Date.',
  EXPIRE_DATE_ATLEAST_TODAY = 'Expire Date must be no earlier than today.',
  EXPIRE_DATE_AFTER_EFFECTIVE_DATE = 'Expire Date must be equal or greater than the Effective Date.',
  PENDING_CHANGES_WARNING = 'There are unsubmitted changes that will be lost. Are you sure you want to proceed with this action?',
  FORM_ERRORS_ERROR = 'Error: Please resolve all errors before continuing.',
  CONFIRMATIONS_FORM_ERRORS = 'Failed to submit all confirmations. Please address all errors.',
  FORM_ERROR = 'Failed to submit. Please address all errors on the page to continue.',
  DEFAULT_SERVER_ERROR = 'Uh-oh! Our servers are not working as they should. Please try again.',
  QUANTITY_MUST_BE_GREATER_THAN_0 = 'Must be greater than 0',
  QUANTITY_MUST_BE_0_OR_GREATER = 'Must be 0 or greater',
  QUANTITY_MUST_BE_5_DIGITS_OR_FEWER = 'Must be less than 100,000',
  QUANTITY_MUST_BE_4_DIGITS_OR_FEWER = 'Must be equal to or less than 9,999',
  MUST_SELECT_TSP = 'Please select a TSP',
  CONCURRENCY_ERROR = 'Data on this page is outdated. Please reload this page and try again.',
  NOT_APPLIED_TSP_ERROR = 'Informational Postings does not apply to Boardwalk Louisiana Gas Transmission. Please select a different TSP.',
  LINK_COPIED_CLIP_BOARD =  'copied to clipboard!',
  LINK_COPIED_FAILED_CLIP_BOARD =  'Failed to copy',

}
