import { createFeatureSelector, createSelector, MemoizedSelector, State } from '@ngrx/store';
import { ISchedulingState } from 'app/store/scheduling/scheduling.state';
import { IAppState } from '../app/app.state';

// --------------------------------------------------
// SCHEDULING STATE
// --------------------------------------------------
export const selectSchedulingState = (state: IAppState) => state.schedulingState;

// --------------------------------------------------
// SCHEDULING RUN RESULTS
// --------------------------------------------------
export const selectRunResults = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runs
);
export const selectRunResultsCount = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.totalRunsCount
);
export const selectRunResultsLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runsLoading
);
export const selectRunResultsError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runsError
);
export const selectUserInitiated = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.userInitiated
);

// --------------------------------------------------
// SCHEDULING RUN RESULTS FOR VALIDATION LOOKUP
// --------------------------------------------------
export const selectRunResultsValidationLookup = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runsForValidationLookup
);
export const selectRunResultsNAESBCycle = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runsForNAESBCycle
);

// --------------------------------------------------
// RUN SCHEDULING ENGINE
// --------------------------------------------------
export const selectRunSchedulingEngineError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runSchedulingEngineError
);
export const selectRunSchedulingEngineLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runSchedulingEngineLoading
);
export const selectRunSchedulingEngineSuccess = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runSchedulingEngineSuccess
);

// --------------------------------------------------
// RUN TEMPLATE
// --------------------------------------------------
export const selectRunTemplate = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.Template
);
export const selectRunTemplateLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.TemplateLoading
);
export const selectRunTemplateError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.TemplateError
);

// --------------------------------------------------
// RUN RESULTS NOM DETAIL
// --------------------------------------------------
export const selectRunResultsNomDetail = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsNomDetail
);

// --------------------------------------------------
// RUN RESULTS OVERVIEW
// --------------------------------------------------
export const selectRunResultsOverview = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsOverview
);
export const selectRunResultsOverviewError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsOverviewError
);
export const selectRunResultsOverviewLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsOverviewLoading
);

// -----------------------------------------------
// RUN RESULTS P/L COMPONENT
// -----------------------------------------------
export const selectRunResultsPLComponent = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsPLComponent
);
export const selectExportPLComponent = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.exportPLComponent
);

// --------------------------------------------------
// RUN RESULTS K-PATH LIMITS
// --------------------------------------------------
export const selectRunResultsKPathLimits = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsKPathLimits
);

// --------------------------------------------------
// SCHEDULING PAGE INFORMATION
// --------------------------------------------------
export const selectSchedulingPageInformation = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.SchedulingPageInformation
);

// -----------------------------------------------
// SCHEDULING ENGINE CONTRACT TAB, CHAIN PATH LIMIT SEGMENT
// -----------------------------------------------
export const selectRunResultsChainPathLimits = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsChainPathLimits
);

// -----------------------------------------------
// SCHEDULING ENGINE CONTRACT TAB, MAX PATH LIMIT SEGMENT
// -----------------------------------------------
export const selectRunResultsMaxAvailablePathLimits = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsMaxAvailablePathLimits
);

// -----------------------------------------------
// SCHEDULING ENGINE COMMIT RUN
// -----------------------------------------------
export const selectRunResultsCommit = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.runResultsCommit
);

export const selectCreateSeOverride = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.createSeOverride
);

export const selectBlockMatching = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.blockMatching
);

export const selectExportBlockMatching = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.exportBlockMatching
);

// -----------------------------------------------

// POS Results
// -----------------------------------------------

export const selectPriorityOfServiceResults = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.priorityOfServiceCollection
);

export const selectPriorityOfServiceResultsLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.priorityOfServiceCollection.requestStatus.loading
);

export const selectPriorityOfServiceResultsError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.priorityOfServiceCollection.requestStatus.httpError
);

// SCHEDULING ORDER SETS
// -----------------------------------------------
export const selectSchedulingOrderSets = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingOrderSets
);
export const selectSchedulingOrderSetsLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingOrderSets.requestStatus.loading
);
export const selectSchedulingOrderSetsError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingOrderSets.requestStatus.httpError
);

// -----------------------------------------------
// SCHEDULING ORDER SET
// -----------------------------------------------
export const selectSchedulingOrderSet = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingOrderSet
);
export const selectSchedulingOrderSetLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingOrderSet.requestStatus.loading
);
export const selectSchedulingOrderSetError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingOrderSet.requestStatus.httpError
);

export const selectCreateSchedulingOrderSet = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.createSchedulingOrderSet
);
export const selectCreateSchedulingOrderSetLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.createSchedulingOrderSet.requestStatus.loading
);
export const selectCreateSchedulingOrderSetError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.createSchedulingOrderSet.requestStatus.httpError
);

export const selectUpdateSchedulingOrderSet = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.updateSchedulingOrderSet
);
export const selectUpdateSchedulingOrderSetLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.updateSchedulingOrderSet.requestStatus.loading
);
export const selectUpdateSchedulingOrderSetError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.updateSchedulingOrderSet.requestStatus.httpError
);

export const selectDeleteSchedulingOrderSet = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.deleteSchedulingOrderSet
);
export const selectDeleteSchedulingOrderSetLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.deleteSchedulingOrderSet.requestStatus.loading
);
export const selectDeleteSchedulingOrderSetError = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.deleteSchedulingOrderSet.requestStatus.httpError
);
// Fetch Scheduling Group Item List
export const getSchedulingGroupItemIsLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingGroupItem.requestStatus.loading
);

export const getSchedulingGroupMenuItem = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.schedulingGroupItem.data
);

export const createSchedulingRecordLoading = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.createSchedulingRecord.requestStatus.loading
);

export const successCreatedRecordData = createSelector(
  selectSchedulingState,
  (state: ISchedulingState) => state.createSchedulingRecord.data
);
