import get from 'lodash/get';
import {
  PopoverAnimationDirection,
  PopoverHorizontalAlignment,
  PopoverVerticalAlignment,
} from 'shared/components/popover/popover.component';
import { EColor } from 'shared/consts/color.enum';
import { DynamicIconPopoverCellComponent } from './icon-popover-cell.component';

export class DynamicIconPopoverCell {
  dynamicComponentType: typeof DynamicIconPopoverCellComponent;
  data: IconPopoverOptions;
}

export interface Text {
  title?: string;
  text?: string | number;
}

export interface IconPopoverOptions {
  id?: string;
  popOverTextList?: Array<Text>;
  popOverTitle?: string;
  iconClass?: string;
  cellText?: string;
  hidePopoverIcon?: boolean;
  horizontalAlignment?: PopoverHorizontalAlignment;
  verticalAlignment?: PopoverVerticalAlignment;
  anchorVerticalAlignment?: PopoverVerticalAlignment;
  anchorHorizontalAlignment?: PopoverHorizontalAlignment;
  animationDirection?: PopoverAnimationDirection;
  horizontalLeftOffset?: number;
  verticalTopOffset?: number;
  noTitlePadding?: boolean;
  displayOnHover?: boolean;
  updateOnModelChange?: boolean;
  /**
   * Defines if the text should be moved to the right of the icon
   * */
  switchTextPosition?: boolean;
  /**
   * Defines the icon color. Default is Dark Blue
   */
  iconColor?: EColor;
}

export const createIconPopoverCell = (
  iconPopoverOptions: IconPopoverOptions
): DynamicIconPopoverCell => {
  let id = 'IconPopoverCell';

  if (get(iconPopoverOptions, 'id')) {
    id = get(iconPopoverOptions, 'id') + '_' + id;
  }

  return {
    dynamicComponentType: DynamicIconPopoverCellComponent,
    data: {
      ...iconPopoverOptions,
      id: id,
    },
  };
};
