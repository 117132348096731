import { GmsAppConfig } from 'config/app-config.interfaces';

export const LOCAL_ENV_NAME = 'local';

// @ts-ignore
export let appConfig: GmsAppConfig = {};

export function setAppConfig(config: GmsAppConfig) {
  appConfig = config;
}
