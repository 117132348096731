import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PopupSettings } from '@progress/kendo-angular-popup';
import { take } from 'rxjs/operators';
import { DynamicComponentDefinition } from 'shared/components/dynamic-component-wrapper/dynamic-component.interface';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { DynamicMultiselectCellMenuItem, MultiselectOptions } from './multiselect-cell.interface';

@Component({
  selector: 'app-dynamic-multiselect-cell',
  templateUrl: './multiselect-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicMultiselectComponent extends HasSubscriptions implements OnInit, OnDestroy {
  @Input() data: MultiselectOptions;
  menuItems: DynamicMultiselectCellMenuItem[] = [];
  numSelectionsBeforeCombined: number;
  popupSettings: PopupSettings;
  debounceTime: string;
  placeholder: string;

  currentSelection: any[];

  static componentDefinition(data: MultiselectOptions): DynamicComponentDefinition {
    return {
      dynamicComponentType: DynamicMultiselectComponent,
      data: data,
    };
  }

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    // maintains backward compatibility with static lists
    if (this.data.data) {
      this.menuItems = this.data.data;
    }
    if (this.data.numSelectionsBeforeCombined) {
      this.numSelectionsBeforeCombined = this.data.numSelectionsBeforeCombined;
    }
    if (this.data.popupSettings) {
      this.popupSettings = this.data.popupSettings;
    }
    if (this.data.debounceTime) {
      this.debounceTime = this.data.debounceTime;
    }
    if (this.data.debounceTime) {
      this.placeholder = this.data.placeholder;
    }
    this.addSubscription(
      (this.data.group.controls[
        this.data.controlName
      ] as UntypedFormControl).statusChanges.subscribe(() => this.cd.markForCheck())
    );
    if (this.data.filterable) {
      this.addControlSubscription();
    }
    if (this.data.retainDataObservable) {
      this.manageMenuSubscriptionPersistently();
    }
  }

  public valueChanged(event) {
    if (this.data.valueChanged) {
      this.data.valueChanged(event);
    }
    if (this.data.data$) {
      this.currentSelection = event;
    }
  }

  public multiselectFocused() {
    if (this.data.multiselectFocused) {
      this.data.multiselectFocused();
    }
  }

  public opened() {
    if (this.data.opened) {
      this.data.opened();
    }
    // default initial selection to already selected items
    if (this.data.data$) {
      this.menuItems = this.currentSelection;
    }
  }

  public filterChanged(event, controlName) {
    if (this.data.filterChanged) {
      this.data.filterChanged(event, controlName);
    }
    this.manageMenuSubscription();
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }

  private manageMenuSubscription() {
    if (this.data.data$) {
      const menuItems$ = this.data.data$;
      menuItems$.pipe(take(1)).subscribe(menuItems => {
        this.menuItems = menuItems;

        // Code Smell: we shouldn't need this but without it the dropdown component
        // is rendering the result set prior to this emission?
        this.cd.markForCheck();
      });
    }
  }

  private manageMenuSubscriptionPersistently() {
    if (this.data.data$) {
      const menuItems$ = this.data.data$;
      menuItems$.subscribe(menuItems => {
        this.menuItems = menuItems;

        // Code Smell: we shouldn't need this but without it the dropdown component
        // is rendering the result set prior to this emission?
        this.cd.markForCheck();
      });
    }
  }

  private addControlSubscription() {
    if (this.data.group && this.data.controlName) {
      this.addSubscription(
        (this.data.group.controls[
          this.data.controlName
        ] as UntypedFormControl).valueChanges.subscribe(() => {
          // clear menu after selection was made or control was updated
          this.menuItems = [];
        })
      );
    }
  }
}
