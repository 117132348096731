import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/routeGuards/auth.guard';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { ProfileResolverService } from 'app/modules/people/services/profile-resolver.service';
import { EPermissionOption } from 'app/store/auth/model/enums';

export const Profile_Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/people/pages/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'edit',
    loadChildren: () =>
      import('app/modules/people/pages/user-update/user-update.module').then(
        m => m.UserUpdateModule
      ),
    canActivate: [AuthGuard],
    resolve: {
      user: ProfileResolverService,
    },
  },
];

export const People_Routes = [
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/people/pages/user-update/user-update.module').then(
        m => m.UserUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/users',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'edit/:id',
    loadChildren: () =>
      import('app/modules/people/pages/user-update/user-update.module').then(
        m => m.UserUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/users/edit/{id}',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('app/modules/people/pages/users/users.module').then(m => m.UsersModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/users',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    // This endpoint doesn't work right now, but is here to
    // safeguard in case of future implementation
    path: ':id',
    loadChildren: () =>
      import('app/modules/people/pages/users/users.module').then(m => m.UsersModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/users',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];

export const Rep_Responsibility_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/people/pages/rep-responsibility/rep-responsibility.module').then(
        m => m.RepResponsibilityModule
      ),
  },
];
