import {
  LineSequenceDetail,
  Location,
  LookupData,
  ModelLine,
  ModelLineSequence,
  ModelSequenceLocation,
  ModelStatuses,
  PipelineModel,
  PipelineModelDelta,
} from '@gms/pipeline-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IPagination } from 'shared/models/pagination.model';
import { createInitialDataState, IDataState, IRequestStatus } from '../app/app.models';

export enum EPipelineError {
  LINE_CODE_ERROR = 'DUPLICATE LINE CODE',
  LINE_NAME_ERROR = 'DUPLICATE LINE NAME',
  API_ERROR = 'FORM SUBMIT FAILURE',
  PENDING = 'PENDING',
  NONE = 'NO ERRORS',
}

export enum ESequenceValidationError {
  DUPLICATE_SEQUENCE_NUMBER_ERROR = 'DUPLICATE SEQUENCE NUMBER',
  DUPLICATE_SEQUENCE_NAME_ERROR = 'DUPLICATE SEQUENCE NAME',
  API_ERROR = 'FORM SUBMIT FAILURE',
}

export interface IPipelineModelsState {
  error: Error;
  historicalModelLines: Array<ModelLine>;
  historicalPipelineModel: PipelineModel;
  modelLines: Array<ModelLine>;
  modelLinesLoading: boolean;
  modelLinesError: Error;
  unassociatedModelLines: Array<ModelLine>;
  modelSequences: Map<number, Array<ModelLineSequence>>;
  modelSequencesErrorForLine: Map<number, Error>;
  modelSequencesLoadingForLine: Map<number, boolean>;
  modelSequenceLocations: Map<number, Array<ModelSequenceLocation>>;
  modelSequenceLocationsErrorForSequence: Map<number, Error>;
  modelSequenceLocationsLoadingForSequence: Map<number, boolean>;
  modelLineSequencesByLineIds: Map<number, ModelLineSequence[]>;
  loading: boolean;
  lookupData: LookupData;
  pipelineModels: Array<PipelineModel>;
  pipelineModelDelta: PipelineModelDelta;
  pipelineModelPriority: Array<ModelStatuses>;
  selectedElements: ISelectedElements;
  lineSequenceDetails: Array<LineSequenceDetail>;
  status: {
    [key: string]: IRequestStatus;
    activateDraft: IRequestStatus;
    createDraft: IRequestStatus;
    createLine: IRequestStatus<EPipelineError>;
    createSequence: IRequestStatus<ESequenceValidationError>;
    updateSequence: IRequestStatus<ESequenceValidationError>;
    deletePipelineModel: IRequestStatus;
    unassociatedModelLinesStatus: IRequestStatus;
    saveDraft: IRequestStatus;
    updateLine: IRequestStatus<EPipelineError>;
    pipelineModelDelta: IRequestStatus;
    lookupData: IRequestStatus;
    lineSequenceDetails: IRequestStatus;
    modelLineSequencesByLineIds: IRequestStatus;
  };
  sortDescriptors: SortDescriptor[];
  pagination: IPagination;
  totalPipelineCount: number;
  availableModelSequenceLocations: IDataState<Location[]>;
}

export const initialPipelineModelsState: IPipelineModelsState = {
  error: null,
  historicalModelLines: [],
  historicalPipelineModel: null,
  modelLines: [],
  modelLinesLoading: false,
  modelLinesError: null,
  unassociatedModelLines: [],
  modelSequences: new Map<number, Array<ModelLineSequence>>(),
  modelSequencesErrorForLine: new Map<number, Error>(),
  modelSequencesLoadingForLine: new Map<number, boolean>(),
  modelSequenceLocations: new Map<number, Array<ModelSequenceLocation>>(),
  modelSequenceLocationsErrorForSequence: new Map<number, Error>(),
  modelSequenceLocationsLoadingForSequence: new Map<number, boolean>(),
  modelLineSequencesByLineIds: new Map<number, ModelLineSequence[]>(),
  loading: false,
  lookupData: null,
  pipelineModels: [],
  pipelineModelDelta: null,
  pipelineModelPriority: [ ModelStatuses.Active,  ModelStatuses.Pending,  ModelStatuses.Draft],
  selectedElements: null,
  lineSequenceDetails: [],
  status: {
    activateDraft: {
      httpError: null,
      loading: false,
    },
    createDraft: {
      httpError: null,
      loading: false,
    },
    createLine: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    createSequence: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    updateSequence: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    deletePipelineModel: {
      httpError: null,
      loading: false,
    },
    saveDraft: {
      httpError: null,
      loading: false,
    },
    updateLine: {
      httpError: null,
      loading: false,
      validationError: null,
    },
    unassociatedModelLinesStatus: {
      httpError: null,
      loading: false,
    },
    pipelineModelDelta: {
      httpError: null,
      loading: false,
    },
    lookupData: {
      httpError: null,
      loading: false,
    },
    lineSequenceDetails: {
      httpError: null,
      loading: false,
    },
    modelLineSequencesByLineIds: {
      httpError: null,
      loading: false,
    },
  },
  sortDescriptors: [
    {
      field: 'pipelineModelId',
      dir: 'desc',
    },
  ],
  pagination: {
    pageSize: 5,
    pageNumber: 1,
  },
  totalPipelineCount: 0,
  availableModelSequenceLocations: createInitialDataState(),
};

export interface ISelectedElements {
  modelLine: ModelLine;
  modelLineSequence: ModelLineSequence;
}
