import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { JobConfig, JobInstance, JobScheduleService } from '@gms/jobschedule-api';
import { dateUtils } from 'shared/utils/date.utils';
import {
  CreateJobConfig,
  CreateJobConfigError,
  CreateJobConfigSuccess,
  DeleteJobConfig,
  DeleteJobConfigError,
  DeleteJobConfigSuccess,
  EJobsActions,
  FetchJobApis,
  FetchJobApisError,
  FetchJobApisSuccess,
  FetchJobConfig,
  FetchJobConfigError,
  FetchJobConfigs,
  FetchJobConfigsError,
  FetchJobConfigsSuccess,
  FetchJobConfigSuccess,
  FetchJobInstance,
  FetchJobInstanceError,
  FetchJobInstances,
  FetchJobInstancesError,
  FetchJobInstancesSuccess,
  FetchJobInstanceSuccess,
  FetchJobNames,
  FetchJobNamesError,
  FetchJobNamesSuccess,
  FetchJobStatuses,
  FetchJobStatusesError,
  FetchJobStatusesSuccess,
  IFetchJobConfigsPayload,
  IFetchJobInstancesPayload,
  RerunJobConfig,
  RerunJobConfigError,
  RerunJobConfigSuccess,
  UpdateJobConfig,
  UpdateJobConfigError,
  UpdateJobConfigSuccess,
  UpdateJobInstance,
  UpdateJobInstanceError,
  UpdateJobInstanceSuccess,
} from './jobs.actions';

@Injectable()
export class JobsEffects {
  constructor(private _actions$: Actions, private _jobsService: JobScheduleService) {}

  UpdateJobInstance$ = createEffect(() => ({ 
    debounce = 500 } = {}) =>
    this._actions$.pipe(
      ofType<UpdateJobInstance>(EJobsActions.UPDATE_JOB_INSTANCE),
      debounceTime(debounce),
      switchMap(action => {
        const updatedJobInstance = action.payload;
        return this._jobsService
          .putJobInstance(updatedJobInstance, updatedJobInstance.jobInstanceId)
          .pipe(
            map(jobInstance => new UpdateJobInstanceSuccess(jobInstance)),
            catchError(error => of(new UpdateJobInstanceError(error)))
          );
      })
    )
  );

  FetchJobInstance$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchJobInstance>(EJobsActions.FETCH_JOB_INSTANCE),
      map(action => action.payload),
      switchMap(payload => {
        return this._jobsService.getJobInstance(payload).pipe(
          map(jobInstance => new FetchJobInstanceSuccess(jobInstance)),
          catchError(error => of(new FetchJobInstanceError(error)))
        );
      })
    )
  );

  FetchJobInstances$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchJobInstances>(EJobsActions.FETCH_JOB_INSTANCES),
      map(action => action.payload),
      switchMap((payload: IFetchJobInstancesPayload) => {
        return this._jobsService
          .getJobInstances(
            payload.runStart,
            payload.runEnd,
            payload.statusIds,
            payload.jobName, // Job Config name
            payload.jobInstanceIds,
            payload.jobConfigIds,
            100 //pagesize - as per requirements this is the max page size we should be fetching. If that changes in the future this field need to become a field in the action payload.
          )
          .pipe(
            map(jobInstanceCollection => new FetchJobInstancesSuccess({ jobInstanceCollection })),
            catchError(error => of(new FetchJobInstancesError({ error: error })))
          );
      })
    )
  );

  FetchJobNames$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchJobNames>(EJobsActions.FETCH_JOB_NAMES),
      map(action => action.payload),
      switchMap(payload => {
        return this._jobsService.getJobNames(payload.searchPhrase).pipe(
          map(jobNameCollection => new FetchJobNamesSuccess({ jobNameCollection })),
          catchError(error => of(new FetchJobNamesError({ error: error })))
        );
      })
    )
  );

  FetchJobStatuses$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchJobStatuses>(EJobsActions.FETCH_JOB_STATUSES),
      switchMap(() => {
        return this._jobsService.getJobStatuses().pipe(
          map(jobStatusCollection => new FetchJobStatusesSuccess({ jobStatusCollection })),
          catchError(error => of(new FetchJobStatusesError({ error: error })))
        );
      })
    )
  );

  FetchJobApis$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchJobStatuses>(EJobsActions.FETCH_JOB_APIS),
      switchMap(() => {
        return this._jobsService.getJobApis().pipe(
          map(jobApiCollection => new FetchJobApisSuccess(jobApiCollection)),
          catchError(error => of(new FetchJobApisError(error)))
        );
      })
    )
  );

  CreateJobConfig$ = createEffect(() => ({ 
    debounce = 500 } = {}) =>
    this._actions$.pipe(
      ofType<CreateJobConfig>(EJobsActions.CREATE_JOB_CONFIG),
      debounceTime(debounce),
      switchMap(action => {
        const newJobConfig: JobConfig = action.payload;
        return this._jobsService.postJobConfig(newJobConfig).pipe(
          map(jobConfig => new CreateJobConfigSuccess(jobConfig)),
          catchError(error => of(new CreateJobConfigError(error)))
        );
      })
    )
  );

  UpdateJobConfig$ = createEffect(() => ({ 
    debounce = 500 } = {}) =>
    this._actions$.pipe(
      ofType<UpdateJobConfig>(EJobsActions.UPDATE_JOB_CONFIG),
      debounceTime(debounce),
      switchMap(action => {
        const updatedJobConfig = action.payload;
        return this._jobsService.putJobConfig(updatedJobConfig, updatedJobConfig.jobConfigId).pipe(
          map(jobConfig => new UpdateJobConfigSuccess(jobConfig)),
          catchError(error => of(new UpdateJobConfigError(error)))
        );
      })
    )
  );

  FetchJobConfig$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchJobConfig>(EJobsActions.FETCH_JOB_CONFIG),
      map(action => action.payload),
      switchMap(payload => {
        return this._jobsService.getJobConfig(payload).pipe(
          map(jobConfig => new FetchJobConfigSuccess(jobConfig)),
          catchError(error => of(new FetchJobConfigError(error)))
        );
      })
    )
  );

  FetchJobConfigs$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchJobConfigs>(EJobsActions.FETCH_JOB_CONFIGS),
      map(action => action.payload),
      switchMap((payload: IFetchJobConfigsPayload) => {
        return this._jobsService
          .getJobConfigs(
            dateUtils.convertToApiDate(payload.nextRunsBefore),
            dateUtils.convertToApiDate(payload.nextRunsAfter),
            dateUtils.convertToApiDate(payload.lastRanBefore),
            dateUtils.convertToApiDate(payload.lastRanAfter),
            payload.statusIds,
            payload.configName,
            payload.jobConfigIds,
            null,
            payload.recurrence,
            payload.lastRunStatusIds,
            payload.pageSize
          )
          .pipe(
            map(jobConfigCollection => new FetchJobConfigsSuccess({ jobConfigCollection })),
            catchError(error => of(new FetchJobConfigsError({ error: error })))
          );
      })
    )
  );

  DeleteJobConfig$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DeleteJobConfig>(EJobsActions.DELETE_JOB_CONFIG),
      map(action => action.jobConfigId),
      switchMap(jobConfigId => {
        return this._jobsService.archiveJobConfig(jobConfigId).pipe(
          map(() => new DeleteJobConfigSuccess()),
          catchError(error => of(new DeleteJobConfigError(error)))
        );
      })
    )
  );

  RerunJobConfig$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<RerunJobConfig>(EJobsActions.RERUN_JOB_CONFIG),
      map(action => action.jobConfigId),
      switchMap(jobConfigId => {
        return this._jobsService.postJobConfigRun(jobConfigId).pipe(
          map(() => new RerunJobConfigSuccess()),
          catchError(error => of(new RerunJobConfigError(error)))
        );
      })
    )
  );
}
