import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { IAppState } from 'app/store/app/app.state';
import { RetryableAction } from './retry.actions';
import { selectSavedPayloads } from './retry.selectors';

@Injectable()
export class RetryEffects {
  constructor(private _actions$: Actions, private _store: Store<IAppState>) {}

  RecreateAction$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      filter(action => action instanceof RetryableAction && action.isRetry),
      map(action => action as RetryableAction),
      withLatestFrom(this._store.pipe(select(selectSavedPayloads))),
      map(
        ([action, savedPayloads]: [RetryableAction, Map<string, any>]) =>
          new RetryableAction(savedPayloads.get(action.savedType), action.savedType)
      )
    )
  );
}
