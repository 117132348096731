import { AbstractControl, UntypedFormControl } from '@angular/forms';
import get from 'lodash/get';
import { DynamicDatepickerComponent } from 'shared/components/table/dynamic-cell-components/datepicker-cell/datepicker-cell.component';
import { getControlName } from 'shared/components/table/dynamic-cell-components/dynamic-cell.interface';

export class DynamicDatepickerCell {
  dynamicComponentType: typeof DynamicDatepickerComponent;
  data: DatepickerOptions;
}
export interface DatepickerOptions {
  id?: string;
  control: UntypedFormControl | AbstractControl;
  datepickerBlurred?: Function;
  datepickerFocused?: Function;
  minDate?: Date;
  maxDate?: Date;
  disableBeforeGivenDate?: Date;
  valueChanged?: Function;
  updateOnModelChange?: boolean;
  forceAOE?: boolean;
  forceAOEAggresively?: boolean;

  // If you need to set validators make sure to use the IControlError validators,
  // This way your min / max date values will automatically get shown and updated via the control.
}

export const createDatePickerCell = (
  datepickerOptions: DatepickerOptions
): DynamicDatepickerCell => {
  const controlName = getControlName(datepickerOptions.control as UntypedFormControl);

  const prependId = get(datepickerOptions, 'id') || controlName;
  const id = prependId + '_Datepicker';

  return {
    dynamicComponentType: DynamicDatepickerComponent,
    data: {
      ...datepickerOptions,
      control: datepickerOptions.control,
      id: id,
    },
  };
};
