import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FuelRateResponseCollection } from '@gms/invoicing-api';
import {
  Cycle,
  DropdownDictionary,
  GasDay,
  Nomination,
  NominationCollection,
  NominationSummary,
  OperatorScheduledQuantity,
  ReductionReasonModel,
  ScheduledQuantity,
  ScheduledQuantitySummary,
  TransactionType,
  TransactionTypeCollection,
  TspDetailCollection,
  Validation,
} from '@gms/nomination-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  createInitialDataState,
  createSuccessState,
  emptyRequestStatus,
  ICachedDataState,
  IDataState,
} from 'app/store/app/app.models';
import { getPagination, IPagination } from 'shared/models/pagination.model';
import { ServiceRequester } from '../header/header.state';
import { INominationsUploadResponse, INominationWarning } from './nominations.actions';

export interface INominationsState {
  nominations: Nomination[];
  nomination: Nomination;
  nominationWarnings: INominationWarning[];
  nominationUploadValidation: IDataState<INominationsUploadResponse>;
  totalNominationsCount: number;
  agencyAccessBySvcReq: boolean;
  transactionTypes: IDataState<TransactionTypeCollection>;

  nominationSummary: IDataState<NominationSummary>;

  sortDescriptors: SortDescriptor[];
  pagination: IPagination;

  error: Error | null;
  loading: boolean;
  loadingCycles: boolean;
  loadingNominations: boolean;

  createNominationValidationError: NominationCollection | null;
  createNominationError: Error | null;
  createNominationLoading: boolean;
  createNominationsSuccess: NominationCollection;

  validateNominationsError: NominationCollection;
  validateNominationsLoading: boolean;
  validateNominationsSuccess: NominationCollection;

  scheduledQuantities: Array<ScheduledQuantitySummary>;
  scheduledQuantitiesLoading: boolean;
  scheduledQuantitiesError: Error | null;
  totalScheduledQuantities: number;

  exportScheduledQuantitiesLoading: boolean;
  exportScheduledQuantitiesError: Error | null;

  exportOperatorScheduledQuantitiesLoading: boolean;
  exportOperatorScheduledQuantitiesError: Error | null;

  scheduledQuantity: { [contractId: string]: Array<ScheduledQuantity> };
  scheduledQuantityLoading: { [contractId: string]: boolean };
  scheduledQuantityError: { [contractId: string]: Error | null };
  totalScheduledQuantity: { [contractId: string]: number };

  operatorScheduledQuantities: Array<OperatorScheduledQuantity>;
  operatorScheduledQuantitiesLoading: boolean;
  operatorScheduledQuantitiesError: Error | null;
  operatorTotalScheduledQuantities: number;

  operatorScheduledQuantity: OperatorScheduledQuantity | null;
  operatorScheduledQuantityLoading: boolean;
  operatorScheduledQuantityError: Error | null;

  operatorScheduledQuantityFilterValues: {
    recipient: ServiceRequester;
    beginDate: Date;
    endDate: Date;
    cycle: string;
    locationId: number;
  };

  dictionary: DropdownDictionary;
  fuelRateCollection: IDataState<FuelRateResponseCollection>;

  nominationCycles: Array<Cycle>;
  createCycleOverrideLoading: boolean;
  createCycleOverrideSuccess: boolean;

  gasDay: GasDay;
  gasDayError: Error | null;

  reductionReasons: IDataState<ReductionReasonModel[]>;

  tspDetails: ICachedDataState<TspDetailCollection>;
}

export const initialNominationsState: INominationsState = {
  nominations: [],
  nomination: null,
  nominationUploadValidation: createSuccessState(null),
  transactionTypes: createSuccessState(null),
  sortDescriptors: [
    {
      field: 'dateTimeModified',
      dir: 'asc',
    },
  ],
  loading: false,
  loadingCycles: false,
  loadingNominations: false,
  createNominationLoading: false,
  error: null,
  nominationWarnings: [],
  createNominationValidationError: null,
  createNominationError: null,
  createNominationsSuccess: null,
  pagination: getPagination(),
  totalNominationsCount: 0,

  validateNominationsError: null,
  validateNominationsLoading: false,
  validateNominationsSuccess: null,

  scheduledQuantities: [],
  scheduledQuantitiesLoading: false,
  scheduledQuantitiesError: null,
  totalScheduledQuantities: 0,

  scheduledQuantity: {},
  scheduledQuantityLoading: {},
  scheduledQuantityError: {},

  exportScheduledQuantitiesLoading: false,
  exportScheduledQuantitiesError: null,

  exportOperatorScheduledQuantitiesLoading: false,
  exportOperatorScheduledQuantitiesError: null,

  totalScheduledQuantity: {},

  operatorScheduledQuantities: [],
  operatorScheduledQuantitiesLoading: false,
  operatorScheduledQuantitiesError: null,
  operatorTotalScheduledQuantities: 0,

  operatorScheduledQuantity: null,
  operatorScheduledQuantityLoading: false,
  operatorScheduledQuantityError: null,

  operatorScheduledQuantityFilterValues: {
    recipient: null,
    beginDate: null,
    endDate: null,
    cycle: null,
    locationId: null,
  },

  dictionary: null,
  fuelRateCollection: createSuccessState(null),
  nominationSummary: createSuccessState(null),

  nominationCycles: [],
  createCycleOverrideLoading: false,
  createCycleOverrideSuccess: false,

  gasDay: null,
  gasDayError: null,

  reductionReasons: createSuccessState([]),

  tspDetails: createInitialDataState(),
  agencyAccessBySvcReq: false,
};
