export const LABELS = {
  AUCTION_DETAILS_HEADER: 'Auction Details',

  // SETTINGS EDIT
  SAVE_SETTINGS: 'SAVE SETTINGS',
  INITIAL_RATE: 'INITIAL RATE',
  AUCTION_DATE_TIME: 'AUCTION DATE / TIME',
  AUCTION_START_DATE: 'Auction Start Date',
  AUCTION_END_DATE: 'Auction End Date',
  AUCTION_START_TIME: 'Auction Start Time',
  AUCTION_SERIES_END_DATE: 'Auction Series End Date',
  CONTRACTUAL_ROFR: 'Contractual ROFR',
  DAILY_BIDDING_WINDOW: 'Daily Bidding Window',
  GAS_SALES_TYPE: 'Inventory Type',
  HIGH_THRESHOLD_QUANTITY: '3 Threshold Quantity',
  LOW_MULTIPLIER: 'Low Multiplier',
  LOW_THRESHOLD_QUANTITY: '1 Threshold Quantity',
  MEDIUM_THRESHOLD_QUANTITY: '2 Threshold Quantity',
  MAXIMUM_QUANTITY: 'Maximum Quantity',
  MAXIMUM_SUMMER_QUANTITY: 'Summer Maximum Quantity',
  MAXIMUM_WINTER_QUANTITY: 'Winter Maximum Quantity',
  MAX_TERM_END_DATE: 'Maximum Primary Term End Date',
  MINIMUM_QUANTITY: 'Minimum Quantity',
  MINIMUM_SUMMER_QUANTITY: 'Summer Minimum Quantity',
  MINIMUM_WINTER_QUANTITY: 'Winter Minimum Quantity',
  MIN_TERM_END_DATE: 'Minimum Primary Term End Date',
  NUM_BIDDING_PERIODS: 'Number of Bidding Periods',
  QUANTITY_SETTINGS: 'QUANTITY SETTINGS',
  RATE_FORM: 'Rate Form',
  RATE_INCREMENT_PARAMETERS: 'RATE INCREMENT PARAMETERS',
  RATE_SETTINGS: 'RATE SETTINGS',
  ROUND_DURATION: 'ROUND DURATION',
  SERIES: 'Series',
  CONTRACT_ID: 'Svc Req K',
  REQUEST_ID: 'Request ID',
  TERM_BEGIN_DATE: 'Primary Term Begin Date',
  TERM_END_DATE: 'Primary Term End Date',
  ADDITIONAL_NOTICE_TEXT: 'Additional Notice Text',
  ADDITIONAL_NOTICE_TEXT_DESCRIPTION:
    'Enter any additional text that you would like to be displayed on the Upcoming Auction Notice. Text must be approved by legal/regulatory.',
  AUCTION_READY_MESSAGE: 'Auction has been moved to Ready',
  SETTINGS_SAVE_SUCCESS: 'Auction settings successfully saved',
  SETTINGS_SAVE_FAILURE: 'An error occurred while saving the Auction settings',
  ORIGINAL_REQUESTER: 'Original Requester',

  // SETTINGS OVERVIEW
  BIDDING_WINDOW_COUNT: 'Number of Bidding Periods',
  BIDDING_WINDOW: 'Daily Bidding Window',

  // POST NOTICE
  SETUP_NOTICE_TOAST_SERVICE_ERROR: 'The Upcoming Auction Notice failed to post. Please try again.',
  SETUP_NOTICE_ERROR_MESSAGE:
    'Invalid Notice. Go to the Variables Tab to address all errors before posting.',
  SETUP_NOTICE_POST_SUCCESS_MESSAGE: 'Your auction has been moved to Posted.',
  POST_NOTICE_TEXT:
    'Posting Upcoming Auction Notice to Infopost. Check Infopost to verify that the notice posted.',
  POST_NOTICE_TEXT_ERROR:
    'Click “Post Notice” to post Upcoming Auction Notice to Infopost or use the dropdown to indicate the notice was posted manually.',
  POST_NOTICE_TEXT_MANUAL: '*Notice posted manually.',
  POST_NOTICE_SUCCESS: 'Success: Please verify in Infopost that the Notice was posted.',
  POST_NOTICE_FAILURE: 'Failed to post notice to Infopost. Please try again.',

  // SETUP FORM ERRORS
  START_DATE_FIELD_ERROR: 'Must be a future date',
  END_DATE_FIELD_ERROR: 'Must be after start date',
  WEEKEND_DATE_ERROR: 'Must not be a weekend date',
  HOLIDAY_DATE_ERROR: 'Must not be a holiday date',
  POST_AND_BID_ROFR_CONTRACT_NUMBER_ERROR: 'Invalid Contract Number',
  POST_AND_BID_ROFR_CONTRACT_PARAMS_ERROR: 'Contract TSP & Rate Schedule Must Match Request',

  // SETUP MESSAGES
  SETUP_VALIDATION_ERROR: 'Please address all errors on the page before saving.',
  SETUP_SAVE_SUCCESS: 'Your auction has been successfully saved.',
  SETUP_DELETE_SUCCESS: 'Your auction has been successfully deleted.',
  SETUP_GET_AUCTION_GENERIC_ERROR: 'An error occurred while retrieving the Auction',

  // SETUP COMBO BUTTON
  GENERATE_NOTICE: 'Generate Notice',
  MOVE_TO_POSTED: 'Move to Posted',
  OPEN_AUCTION_ROOM: 'Auction Room',
  POST_NOTICE: 'Post Notice',
  DELETE: 'Delete',
  DELETE_AUCTION: 'Delete Auction',
  DELETE_AUCTION_SERIES: 'Delete Auction Series',
  COPY: 'Copy',
  CANCEL_AUCTION: 'Cancel Auction',
  CANCEL_AUCTION_SERIES: 'Cancel Auction Series',
  SAVE_AUCTION: 'Save Auction',
  POSTED_NOTICE_MANUALLY: 'Posted Notice Manually',

  // AUCTION DETAILS DATE MESSAGES
  DATE_RANGE_MESSAGE: 'auctions planned to occur daily on these dates:',
  BIDDING_WINDOWS_MESSAGE: 'Bidding windows planned to occur on these dates:',
  START_DATE_ERROR_MESSAGE: 'Auction Start Date cannot be on a weekend or holiday.',
  END_DATE_ERROR_MESSAGE: 'Auction End Date cannot be on a weekend or holiday.',
  START_DATE_AFTER_ERROR_MESSAGE: 'Auction Start Date cannot be after the Auction End Date.',
  END_DATE_BEFORE_ERROR_MESSAGE: 'Auction End Date cannot be before the Auction Start Date.',
  START_EQUALS_END_ERROR_MESSAGE: 'An auction series must contain more than one auction.',
  MAX_AUCTION_ERROR_MESSAGE: 'Number of auctions in a series cannot be greater than 30.',
  START_END_FUTURE_DATE_ERROR_MESSAGE:
    'Auction Start Date and Auction End Date must be future dates.',
  START_FUTURE_DATE_ERROR_MESSAGE: 'Auction Start Date must be a future date.',
  FUTURE_TIME_ERROR_MESSAGE: 'Auction Start Time must be in the future.',

  // COPY MESSAGES
  COPY_SUCCESS: 'Success: You have successfully copied an auction.',
  COPY_ERROR: 'Error: Copy auction failed.',

  SUPERSEDED_NOTICE:
    "This notice has been superseded. View superseding notices on the pipeline's Informational Postings Website.",
  MARK_SUPERSEDED_NOTICE: 'Mark notice as superseded',

  // Auction Fields
  DATE_CONTRACT_BEGIN: 'Contract Begin Date',
  DATE_PRIMARY_TERM_END: 'Primary Term End Date',
  AUCTION_LOCATIONS: 'Auction Locations',

  // Bidding
  BID_RATE: 'Daily Reservation Rate',
  SUMMER_QUANTITY: 'Summer Quantity',
  WINTER_QUANTITY: 'Winter Quantity',
  QUANTITY: 'Quantity',

  // Locations
  RECEIPT_LOCATION: 'Receipt Location/Name',
  DELIVERY_LOCATION: 'Delivery Location/Name',
  EXPANSION_PATH_BEGIN: 'Expansion Path Begin Location/Name',
  EXPANSION_PATH_END: 'Expansion Path End Location/Name',

  // Bid Panel
  PLACE_BID: 'Place Bid',
  UPDATE_BID: 'Update Bid',
  WITHDRAW_BID: 'Withdraw Bid',

  // Auction Settings Validation Error Messages
  GREATER_THAN_RFS_QTY: 'Cannot be greater than quantity requested on RFS',
  GREATER_THAN_ZERO: 'Must be greater than zero',
  AFTER_MAX_PRIMARY_TERM_DATE: 'Cannot be after Maximum Primary Term End Date',
  BEFORE_PRIMARY_TERM_DATE: 'Cannot be before Primary Term End Date requested on RFS',
  SAME_LOCATION: 'Receipt and Delivery locations cannot be the same',
  MIN_RATE: 'Must be greater than rate requested on RFS',
  MAX_RATE: 'Cannot be greater than Maximum Tariff Rate',
  TERM_DATE: 'Term must be at least',
  ONE_MONTH: ' one month',
  ONE_DAY: ' one day',
  MINIMUM_WINTER_QTY: 'Cannot be less than the Minimum Winter Quantity',
  MINIMUM_SUMMER_QTY: 'Cannot be less than the Minimum Summer Quantity',
  MINIMUM_QTY: 'Cannot be less than the Minimum Quantity',
  MEDIUM_THRESHOLD_MAX_ERROR: 'MUST BE LESS THAN 3 MULTIPLIER',
  MEDIUM_THRESHOLD_MIN_ERROR: 'MUST BE GREATER THAN 1 MULTIPLIER',
  HIGH_THRESHOLD_MIN_ERROR: 'MUST BE HIGHEST MULTIPLIER',
  MINIMUM_QTY_ERROR: 'Cannot be greater than the 1 Threshold Quantity',
  MIN_QTY_STORAGE_ERROR: 'Cannot be greater than Maximum Storage Quantity',
  MIN_QTY_TRANSPORT_ERROR: 'Cannot be greater than Maximum Daily Quantity',
  MIN_QTY_PAL_PARK_ERROR: 'Cannot be greater than Package Quantity',
  SETTINGS_INVALID_MESSAGE: 'Please address all errors on the settings tab to continue.',
  RATE_MAX_ERROR: 'Cannot be greater than Maximum Rate',
  RATE_MIN_ERROR: 'Cannot be less than Minimum Rate',
  GREATER_THAN_MAX_QTY: 'Cannot be greater than Maximum Quantity',
  MISSING_SERIES_QTY: 'Missing quantity for series Auction. Please submit Daily Quantity Notice',

  // Tradeblotter
  TRADER: 'Trader',
  TSP: 'TSP',
  EXPORT: 'Export',
  EXPORT_FORMAT: 'Export Format',
  SUBMIT: 'Submit',
  SHOW_TODAY_ONLY: "Show only today's activity",
  IT: 'IT',
  PAL: 'PAL',
  ISS: 'ISS',
  MOVE_TO_OFFERED: 'Move to Offered',
  MOVE_TO_ACCEPTED: 'Move to Accepted',
  SAVE: 'Save',
  CANCEL: 'Cancel',

  // Awards
  COMPETING_BID: 'You have a competing bid available to match.',
  AWARDED_QUANTITY: 'You have been awarded a quantity - Go to the Results tab.',
  NOT_AWARDED_QUANTITY:
    'You have not been awarded a quantity in this auction. Thank you for attending.',
  SEASONAL_RATE: 'Seasonal Capacity Reservation Rate',
  DELIVERY_RATE: 'Deliverability Reservation Rate',

  // Global
  RATE_SCHEDULE: 'Rate Schedule',

  AUCTION_POSTED_MESSAGE: `An Auction has been posted.`,
  AUCTION_DRAFT_CREATED_MESSAGE: 'A new auction draft has been created.',
  AUCTION_DRAFT_UPDATED_MESSAGE: 'An auction draft has been updated.',
  AUCTION_DRAFT_DELETED_MESSAGE: 'An auction draft has been deleted.',
  AUCTION_ROOM_SETTINGS_ERROR:
    'Error: Unable to begin Auction. Go to the Settings tab to address any issues.',
  AUCTION_ROOM_SETTINGS_READY_ERROR:
    'Error: Unable to move Auction to Ready. Go to the Settings tab to address any issues.',
  AUCTION_ROOM_API_ERROR_MAIN_MESSAGE: 'Uh oh! Something went wrong...',
  AUCTION_ROOM_API_ERROR_SUB_MESSAGE:
    'We ran into some trouble trying to load this page. Please try loading it again.',
  CHANNEL_ADDRESS_ANY: 'ANY',

  // Validation Errors
  MAX_QUANITIY_ERROR: 'Cannot exceed maximum quantity offered.',
};
