import { Injectable } from '@angular/core';
import {
  AverageDayService,
  LineSequenceService,
  LookupDataService,
  MeterCapacityCheckService,
  ObligationService,
  ReservationService,
  StorageObligationService,
  StorageService,
} from '@gms/systemplanning-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import snakeCase from 'lodash/snakeCase';

import { getSortQuery } from 'app/store/system-planning/system-planning.utils';
import { asyncScheduler, Observable, of } from 'rxjs';
import { auditTime, catchError, map, switchMap } from 'rxjs/operators';
import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { DEBOUNCE_500 } from '../../../shared/consts/debounce.const';
import {
  ESystemPlanningActions,
  ExportAverageDayMeasurementSelection,
  ExportAverageDayMeasurementSelectionFailure,
  ExportAverageDayMeasurementSelectionSuccess,
  ExportFirmObligationDownloadResultSuccess,
  ExportFirmObligationSelection,
  ExportFirmObligationSelectionFailure,
  ExportFirmObligationSelectionSuccess,
  ExportStorageObligationCollection,
  ExportStorageObligationCollectionFailure,
  ExportStorageObligationCollectionSuccess,
  GetAverageDayById,
  GetAverageDayByIdFailure,
  GetAverageDayByIdSuccess,
  GetAverageDayCollection,
  GetAverageDayCollectionFailure,
  GetAverageDayCollectionSuccess,
  GetAverageDayLookup,
  GetAverageDayLookupFailure,
  GetAverageDayLookupSuccess,
  GetFirmObligationsCollection,
  GetFirmObligationsCollectionFailure,
  GetFirmObligationsCollectionSuccess,
  GetLineSequenceCollection,
  GetLineSequenceCollectionFailure,
  GetLineSequenceCollectionSuccess,
  GetMeterCapacityCheckById,
  GetMeterCapacityCheckByIdFailure,
  GetMeterCapacityCheckByIdSuccess,
  GetMeterCapacityCheckCollection,
  GetMeterCapacityCheckCollectionFailure,
  GetMeterCapacityCheckCollectionSuccess,
  GetMeterCheckGUID,
  GetMeterCheckGUIDFailure,
  GetMeterCheckGUIDSuccess,
  GetOperationalCapacityReservationCollection,
  GetOperationalCapacityReservationCollectionFailure,
  GetOperationalCapacityReservationCollectionSuccess,
  GetOperationalCapacityReservationEntry,
  GetOperationalCapacityReservationEntryFailure,
  GetOperationalCapacityReservationEntrySuccess,
  GetStorageObligationCollection,
  GetStorageObligationCollectionFailure,
  GetStorageObligationCollectionSuccess,
  SaveAverageDay,
  SaveAverageDayFailure,
  SaveAverageDaySucess,
  SaveOperationalCapacityReservationEntry,
  SaveOperationalCapacityReservationEntryFailure,
  SaveOperationalCapacityReservationEntrySuccess,
  UpdateAverageDayDefault,
  UpdateAverageDayDefaultFailure,
  UpdateAverageDayDefaultSuccess,
  UpdateOperationalCapacityReservationEntry,
  UpdateOperationalCapacityReservationEntryFailure,
  UpdateOperationalCapacityReservationEntrySuccess,
} from './system-planning.actions';

@Injectable({ providedIn: 'root' })
export class SystemPlanningEffects {
  constructor(
    private _actions$: Actions,
    private _systemPlanningObligationsService: ObligationService,
    private _systemPlanningAverageDayService: AverageDayService,
    private _systemPlanningLookupService: LookupDataService,
    private _systemPlanningMeterCapacityCheckService: MeterCapacityCheckService,
    private _systemPlanningReservationService: ReservationService,
    private _systemPlanningLineSequenceService: LineSequenceService,
    private _systemPlanningStorageService: StorageService,
    private _systemPlanningStorageObligationService: StorageObligationService
  ) {}

  getFirmObligationsCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetFirmObligationsCollection>(ESystemPlanningActions.FETCH_OBLIGATIONS_COLLECTION),
      map((action: GetFirmObligationsCollection) => action),
      switchMap(action => {
        const {
          pageSize,
          pageNumber,
          sortDescriptors,
          entityId,
          tspId,
          requestId,
          requestStatus,
          lastUpdateBegin,
          lastUpdateEnd,
          rateScheduleId,
          assignedToId,
          studyBeginDate,
          studyEndDate,
          segmentable,
          combination,
          contractualRofr,
          flowDirection,
          contractId,
          deliveryZone,
          receiptZone,
          locationId,
          lineId,
        } = action.payload;
        return this._systemPlanningObligationsService
          .getObligations(
            pageSize || null,
            pageNumber || null,
            getSortQuery(sortDescriptors),
            entityId || null,
            tspId || null,
            requestId || null,
            requestStatus || null,
            rateScheduleId || null,
            dateUtils.getDateAsYYYY_MM_DD(lastUpdateBegin) || null,
            dateUtils.getDateAsYYYY_MM_DD(lastUpdateEnd) || null,
            null, //contractType
            null, //searchPhrase
            assignedToId || null,
            dateUtils.getDateAsYYYY_MM_DD(studyBeginDate) || null,
            dateUtils.getDateAsYYYY_MM_DD(studyEndDate) || null,
            segmentable,
            combination,
            contractualRofr,
            flowDirection,
            contractId,
            deliveryZone,
            receiptZone,
            locationId,
            lineId
          )
          .pipe(
            map(response => new GetFirmObligationsCollectionSuccess(response)),
            catchError(error => of(new GetFirmObligationsCollectionFailure(error)))
          );
      })
    )
  );

  exportFirmObligations$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportFirmObligationSelection>(
        ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_SELECTION
      ),
      map((action: ExportFirmObligationSelection) => action),
      switchMap(action => {
        const {
          obligationSelection,
          averageDayBaseMonthId,
          sortDescriptors,
          entityId,
          tspId,
          requestId,
          requestStatus,
          lastUpdateBegin,
          lastUpdateEnd,
          rateScheduleId,
          assignedToId,
          studyBeginDate,
          studyEndDate,
          segmentable,
          combination,
          contractualRofr,
          flowDirection,
          contractId,
          deliveryZone,
          receiptZone,
          locationId,
          lineId,
          userId,
          studyName,
        } = action.payload;

        const obligationSelectionParameters = {
          averageDayBaseMonthId: averageDayBaseMonthId || null,
          sortBy: getSortQuery(sortDescriptors),
          entityId: entityId || null,
          tspId: tspId || null,
          requestId: requestId || null,
          requestStatus: requestStatus || null,
          rateScheduleId: rateScheduleId || null,
          lastUpdateBegin: dateUtils.getDateAsYYYY_MM_DD(lastUpdateBegin) || null,
          lastUpdateEnd: dateUtils.getDateAsYYYY_MM_DD(lastUpdateEnd) || null,
          contractType: null,
          searchPhrase: null,
          assignedToId: assignedToId || null,
          studyBeginDate: dateUtils.getDateAsYYYY_MM_DD(studyBeginDate) || null,
          studyEndDate: dateUtils.getDateAsYYYY_MM_DD(studyEndDate) || null,
          segmentable,
          combination,
          contractualRofr,
          flowDirection,
          contractId,
          deliveryZone,
          receiptZone,
          locationId,
          lineId,
          userId,
          studyName,
        };

        obligationSelection.parameters = Object.keys(obligationSelectionParameters).reduce(
          (params, key) => {
            if (!isNullOrUndefined(obligationSelectionParameters[key])) {
              params[snakeCase(key)] = obligationSelectionParameters[key].toString();
            }
            return params;
          },
          {} as Record<string, string>
        );

        return this._systemPlanningObligationsService
          .exportObligations(
            obligationSelection,
            obligationSelectionParameters.averageDayBaseMonthId,
            obligationSelectionParameters.sortBy,
            obligationSelectionParameters.entityId,
            obligationSelectionParameters.tspId,
            obligationSelectionParameters.requestId,
            obligationSelectionParameters.requestStatus,
            obligationSelectionParameters.rateScheduleId,
            obligationSelectionParameters.lastUpdateBegin,
            obligationSelectionParameters.lastUpdateEnd,
            obligationSelectionParameters.contractType,
            obligationSelectionParameters.searchPhrase,
            obligationSelectionParameters.assignedToId,
            obligationSelectionParameters.studyBeginDate,
            obligationSelectionParameters.studyEndDate,
            obligationSelectionParameters.segmentable,
            obligationSelectionParameters.combination,
            obligationSelectionParameters.contractualRofr,
            obligationSelectionParameters.flowDirection,
            obligationSelectionParameters.contractId,
            obligationSelectionParameters.deliveryZone,
            obligationSelectionParameters.receiptZone,
            obligationSelectionParameters.locationId,
            obligationSelectionParameters.lineId,
            obligationSelectionParameters.userId,
            obligationSelectionParameters.studyName
          )
          .pipe(
            map(response => new ExportFirmObligationSelectionSuccess(response)),
            catchError(error => of(new ExportFirmObligationSelectionFailure(error)))
          );
      })
    )
  );

  getAverageDayCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAverageDayCollection>(ESystemPlanningActions.FETCH_AVERAGE_DAY_COLLECTION),
      map((action: GetAverageDayCollection) => action),
      switchMap(action => {
        return this._systemPlanningAverageDayService
          .getAverageDay(
            action.payload.tspId || null,
            dateUtils.getDateAsYYYY_MM_DD(action.payload.baseMonth) || null
          )
          .pipe(
            map(response => new GetAverageDayCollectionSuccess(response)),
            catchError(error => of(new GetAverageDayCollectionFailure(error)))
          );
      })
    )
  );

  saveAverageDay$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveAverageDay>(ESystemPlanningActions.SAVE_AVERAGE_DAY),
      map((action: SaveAverageDay) => action),
      switchMap(action => {
        return this._systemPlanningAverageDayService.addAverageDay(action.payload).pipe(
          map(response => new SaveAverageDaySucess(response)),
          catchError(error => of(new SaveAverageDayFailure(error)))
        );
      })
    )
  );

  getAverageDayLookup$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAverageDayLookup>(ESystemPlanningActions.FETCH_AVERAGE_DAY_LOOKUP),
      map((action: GetAverageDayLookup) => action),
      switchMap(action => {
        return this._systemPlanningLookupService.getLookupData(action.tspId).pipe(
          map(response => new GetAverageDayLookupSuccess(response)),
          catchError(error => of(new GetAverageDayLookupFailure(error)))
        );
      })
    )
  );

  getAverageDayById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAverageDayById>(ESystemPlanningActions.FETCH_AVERAGE_DAY_BY_ID),
      map((action: GetAverageDayById) => action),
      switchMap(action => {
        return this._systemPlanningAverageDayService.getAverageDayById(action.averageDayId).pipe(
          map(response => new GetAverageDayByIdSuccess(response)),
          catchError(error => of(new GetAverageDayByIdFailure(error)))
        );
      })
    )
  );

  updateAverageDayDefault$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateAverageDayDefault>(ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT),
      map((action: UpdateAverageDayDefault) => action),
      switchMap(action => {
        return this._systemPlanningAverageDayService
          .putAverageDayDefaultById(action.payload.averageDayId)
          .pipe(
            map(response => new UpdateAverageDayDefaultSuccess(response)),
            catchError(error => of(new UpdateAverageDayDefaultFailure(error)))
          );
      })
    )
  );

  ExportAverageDayMeasurementSelection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportAverageDayMeasurementSelection>(
        ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION
      ),
      map((action: ExportAverageDayMeasurementSelection) => action),
      switchMap(action => {
        return this._systemPlanningAverageDayService.exportAverageDayResults(action.payload).pipe(
          map(response => new ExportAverageDayMeasurementSelectionSuccess(response)),
          catchError(error => of(new ExportAverageDayMeasurementSelectionFailure(error)))
        );
      })
    )
  );

  getMeterCapacityCheckGUID$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetMeterCheckGUID>(ESystemPlanningActions.FETCH_METER_CHECK_GUID),
      map((action: GetMeterCheckGUID) => action),
      switchMap(action => {
        const {
          obligationSelection,
          averageDayBaseMonthId,
          sortDescriptors,
          entityId,
          tspId,
          requestId,
          requestStatus,
          lastUpdateBegin,
          lastUpdateEnd,
          rateScheduleId,
          assignedToId,
          studyBeginDate,
          studyEndDate,
          segmentable,
          combination,
          contractualRofr,
          flowDirection,
          contractId,
          deliveryZone,
          receiptZone,
          locationId,
          lineId,
          userId,
        } = action.payload;

        const obligationSelectionParameters = {
          averageDayBaseMonthId: averageDayBaseMonthId || null,
          sortBy: getSortQuery(sortDescriptors),
          entityId: entityId || null,
          tspId: tspId || null,
          requestId: requestId || null,
          requestStatus: requestStatus || null,
          rateScheduleId: rateScheduleId || null,
          lastUpdateBegin: dateUtils.getDateAsYYYY_MM_DD(lastUpdateBegin) || null,
          lastUpdateEnd: dateUtils.getDateAsYYYY_MM_DD(lastUpdateEnd) || null,
          contractType: null,
          searchPhrase: null,
          assignedToId: assignedToId || null,
          studyBeginDate: dateUtils.getDateAsYYYY_MM_DD(studyBeginDate) || null,
          studyEndDate: dateUtils.getDateAsYYYY_MM_DD(studyEndDate) || null,
          segmentable,
          combination,
          contractualRofr,
          flowDirection,
          contractId,
          deliveryZone,
          receiptZone,
          locationId,
          lineId,
          userId,
        };

        obligationSelection.parameters = Object.keys(obligationSelectionParameters).reduce(
          (params, key) => {
            if (!isNullOrUndefined(obligationSelectionParameters[key])) {
              params[snakeCase(key)] = obligationSelectionParameters[key].toString();
            }
            return params;
          },
          {} as Record<string, string>
        );

        return this._systemPlanningMeterCapacityCheckService
          .mtrCapCheck(
            obligationSelection,
            obligationSelectionParameters.averageDayBaseMonthId,
            obligationSelectionParameters.sortBy,
            obligationSelectionParameters.entityId,
            obligationSelectionParameters.tspId,
            obligationSelectionParameters.requestId,
            obligationSelectionParameters.requestStatus,
            obligationSelectionParameters.rateScheduleId,
            obligationSelectionParameters.lastUpdateBegin,
            obligationSelectionParameters.lastUpdateEnd,
            obligationSelectionParameters.contractType,
            obligationSelectionParameters.searchPhrase,
            obligationSelectionParameters.assignedToId,
            obligationSelectionParameters.studyBeginDate,
            obligationSelectionParameters.studyEndDate,
            obligationSelectionParameters.segmentable,
            obligationSelectionParameters.combination,
            obligationSelectionParameters.contractualRofr,
            obligationSelectionParameters.flowDirection,
            obligationSelectionParameters.contractId,
            obligationSelectionParameters.deliveryZone,
            obligationSelectionParameters.receiptZone,
            obligationSelectionParameters.locationId,
            obligationSelectionParameters.lineId
          )
          .pipe(
            map(response => new GetMeterCheckGUIDSuccess(response)),
            catchError(error => of(new GetMeterCheckGUIDFailure(error)))
          );
      })
    )
  );

  getMeterCapacityCheckCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetMeterCapacityCheckCollection>(
        ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_COLLECTION
      ),
      map((action: GetMeterCapacityCheckCollection) => action),
      switchMap(action => {
        return this._systemPlanningMeterCapacityCheckService
          .getMeterCapacityCheckByGuid(
            action.payload.guid,
            action.payload.pageSize,
            action.payload.pageNumber,
            action.payload.sequenceIds || null,
            action.payload.locationIds || null,
            action.payload.lineIds || null,
            getSortQuery(action.payload.sortDescriptors)
          )
          .pipe(
            map(response => new GetMeterCapacityCheckCollectionSuccess(response)),
            catchError(error => of(new GetMeterCapacityCheckCollectionFailure(error)))
          );
      })
    )
  );

  getMeterCapacityCheckById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetMeterCapacityCheckById>(ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_BY_ID),
      map((action: GetMeterCapacityCheckById) => action),
      switchMap(action => {
        return this._systemPlanningMeterCapacityCheckService
          .getMeterCapacityCheckById(action.payload)
          .pipe(
            map(response => new GetMeterCapacityCheckByIdSuccess(response)),
            catchError(error => of(new GetMeterCapacityCheckByIdFailure(error)))
          );
      })
    )
  );

  GetOperationalCapacityReservationCollection$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<GetOperationalCapacityReservationCollection>(
        ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION
      ),
      map((action: GetOperationalCapacityReservationCollection) => action.payload),
      auditTime(debounce, scheduler),
      switchMap(payload => {
        return this._systemPlanningReservationService
          .getReservationList(
            getSortQuery(payload.sortDescriptors),
            payload.pageSize,
            payload.pageNumber
          )
          .pipe(
            map(response => new GetOperationalCapacityReservationCollectionSuccess(response)),
            catchError(error => of(new GetOperationalCapacityReservationCollectionFailure(error)))
          );
      })
    )
  );

  GetOperationalCapacityReservationEntry$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetOperationalCapacityReservationEntry>(
        ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY
      ),
      map((action: GetOperationalCapacityReservationEntry) => action.payload),
      switchMap(payload => {
        return this._systemPlanningReservationService
          .getReservationEntry(payload.reservationId)
          .pipe(
            map(response => new GetOperationalCapacityReservationEntrySuccess(response)),
            catchError(error => of(new GetOperationalCapacityReservationEntryFailure(error)))
          );
      })
    )
  );

  SaveOperationalCapacityReservationEntry$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveOperationalCapacityReservationEntry>(
        ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY
      ),
      map((action: SaveOperationalCapacityReservationEntry) => action.payload),
      switchMap(payload => {
        return this._systemPlanningReservationService.postReservationEntry(payload).pipe(
          map(response => new SaveOperationalCapacityReservationEntrySuccess(response)),
          catchError(error => of(new SaveOperationalCapacityReservationEntryFailure(error)))
        );
      })
    )
  );

  UpdateOperationalCapacityReservationEntry$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateOperationalCapacityReservationEntry>(
        ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY
      ),
      switchMap(action => {
        return this._systemPlanningReservationService
          .putReservationEntry(action.payload, action.id)
          .pipe(
            map(response => new UpdateOperationalCapacityReservationEntrySuccess(response)),
            catchError(error => of(new UpdateOperationalCapacityReservationEntryFailure(error)))
          );
      })
    )
  );

  GetLineSequenceCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetLineSequenceCollection>(ESystemPlanningActions.FETCH_LINE_SEQUENCE_COLLECTION),
      map((action: GetLineSequenceCollection) => action),
      switchMap(action => {
        return this._systemPlanningLineSequenceService.getLineSequenceCollection().pipe(
          map(response => new GetLineSequenceCollectionSuccess(response)),
          catchError(error => of(new GetLineSequenceCollectionFailure(error)))
        );
      })
    )
  );

  GetStorageObligationCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStorageObligationCollection>(
        ESystemPlanningActions.FETCH_STORAGE_OBLIGATION_COLLECTION
      ),
      map((action: GetStorageObligationCollection) => action),
      switchMap(action => {
        const {
          studyBeginDate,
          studyEndDate,
          tspId,
          sortDescriptors,
          pageSize,
          pageNumber,
        } = action.payload;
        return this._systemPlanningStorageService
          .getStorageObligationList(
            pageSize || null,
            pageNumber || null,
            getSortQuery(sortDescriptors) || null,
            dateUtils.getDateAsYYYY_MM_DD(studyBeginDate) || null,
            dateUtils.getDateAsYYYY_MM_DD(studyEndDate) || null,
            tspId || null
          )
          .pipe(
            map(response => new GetStorageObligationCollectionSuccess(response)),
            catchError(error => of(new GetStorageObligationCollectionFailure(error)))
          );
      })
    )
  );

  ExportStorageObligationCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportStorageObligationCollection>(
        ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_COLLECTION
      ),
      map((action: ExportStorageObligationCollection) => action),
      switchMap(action => {
        const { studyBeginDate, studyEndDate, tspId, sortDescriptors, userId } = action.payload;

        const obligationSelection = {
          selectAllFlag: false,
          selection: [],
          parameters: {},
        };

        const obligationSelectionParameters = {
          sortBy: getSortQuery(sortDescriptors),
          tspId: tspId || null,
          studyBeginDate: dateUtils.getDateAsYYYY_MM_DD(studyBeginDate) || null,
          studyEndDate: dateUtils.getDateAsYYYY_MM_DD(studyEndDate) || null,
          userId,
        };

        obligationSelection.parameters = Object.keys(obligationSelectionParameters).reduce(
          (params, key) => {
            if (!isNullOrUndefined(obligationSelectionParameters[key])) {
              params[snakeCase(key)] = obligationSelectionParameters[key].toString();
            }
            return params;
          },
          {} as Record<string, string>
        );

        return this._systemPlanningStorageObligationService
          .postExportStorageObligations(
            obligationSelection,
            obligationSelectionParameters.tspId,
            obligationSelectionParameters.studyBeginDate,
            obligationSelectionParameters.studyEndDate,
            obligationSelectionParameters.userId
          )
          .pipe(
            map(response => new ExportStorageObligationCollectionSuccess(response)),
            catchError(error => of(new ExportStorageObligationCollectionFailure(error)))
          );
      })
    )
  );
}
