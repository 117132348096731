import { SortDescriptor } from '@progress/kendo-data-query';

export const getSortQuery = (sortDescriptors: SortDescriptor[]) => {
  let sortQuery = ``;
  if (sortDescriptors && sortDescriptors.length > 0) {
    sortDescriptors.forEach((sortDescriptor, index) => {
      sortQuery += `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}`;
      if (sortDescriptors.length - 1 > index) {
        sortQuery += '|';
      }
    });
  }
  return sortQuery.length > 0 ? [sortQuery] : null;
};

export const getInitialState = () => ({
  data: null,
  requestStatus: {
    httpError: null,
    loading: false,
  },
});
