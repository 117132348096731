import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { UserService } from '@gms/user-api';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  EPasswordResetActions,
  ResetPassword,
  ResetPasswordFailure,
  ResetPasswordSuccess,
} from './password-reset.actions';

@Injectable()
export class PasswordResetEffects {
  constructor(private _actions$: Actions, private _userService: UserService) {}

  ResetPassword: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ResetPassword>(EPasswordResetActions.ResetPassword),
      map((action: ResetPassword) => action.payload),
      switchMap(payload => {
        return this._userService.forgotPassword({ username: payload.userId }).pipe(
          map(() => new ResetPasswordSuccess()),
          catchError(error => of(new ResetPasswordFailure({ error: error })))
        );
      })
    )
  );
}
