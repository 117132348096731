import { dateUtils } from 'shared/utils/date.utils';

import { HttpErrorResponse } from '@angular/common/http';
import { EffectiveDateRecord, Zone } from '@gms/location-api';
import {
  clearState,
  createErrorState,
  createLoadingState,
  createSuccessState,
} from 'app/store/app/app.models';
import { ELocationsActions, LocationActions } from 'app/store/locations/locations.actions';
import {
  ELocationValidationError,
  ILocationState,
  initialLocationState,
} from 'app/store/locations/locations.state';

const LOCATION_VALIDATION_ERROR_MAPPINGS: { [key: string]: ELocationValidationError } = {
  'Location already exists': ELocationValidationError.DUPLICATE_LOCATION_NUMBER_ERROR,
  'Cannot create multiple associations with the same location and association type.':
    ELocationValidationError.DUPLICATE_ASSOCIATION_ERROR,
};

function getErrorMessage(error: Error) {
  const httpError = error as HttpErrorResponse;
  return !!httpError && !!httpError.error ? httpError.error : null;
}

//need to use the function format for AOT
export function locationReducers(
  state = initialLocationState,
  action: LocationActions
): ILocationState {
  switch (action.type) {
    case ELocationsActions.GetLocations:
      let optionalParams = {};
      if (action.payload) {
        optionalParams = {
          sortDescriptors: action.payload.sortDescriptors,
          pagination: {
            pageSize: action.payload.pageSize,
            pageNumber: action.payload.pageNumber,
          },
        };
      }
      return {
        ...state,
        error: null,
        loading: { ...state.loading, locations: true },
        ...optionalParams,
      };
    case ELocationsActions.GetLocationsSuccess:
      return {
        ...state,
        locations: action.payload.locations,
        loading: { ...state.loading, locations: false },
        error: null,
        totalLocationCount: action.payload.totalLocationCount,
      };

    case ELocationsActions.GetLocationsError:
      return {
        ...state,
        error: action.payload.error,
        loading: { ...state.loading, locations: false },
      };

    case ELocationsActions.ClearLocations:
      return {
        ...state,
        locations: [],
        error: null,
      };

    case ELocationsActions.GetLocationById:
      return {
        ...state,
        error: null,
        loading: { ...state.loading, locations: true },
      };

    case ELocationsActions.GetLocationByIdSuccess:
      return {
        ...state,
        error: null,
        loading: { ...state.loading, locations: false },
        location: action.location,
      };

    case ELocationsActions.GetLocationByIdError:
      return {
        ...state,
        error: action.payload.error,
        loading: { ...state.loading, locations: false },
      };

    case ELocationsActions.ClearGetLocationById:
      return {
        ...state,
        location: null,
      };

    case ELocationsActions.GET_LOCATIONS_BY_IDS:
      return {
        ...state,
        error: null,
        loading: { ...state.loading, locations: true },
      };

    case ELocationsActions.GET_LOCATIONS_BY_IDS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: { ...state.loading, locations: false },
      };

    case ELocationsActions.GET_LOCATIONS_BY_IDS_SUCCESS:
      return {
        ...state,
        locations: action.locations,
        loading: { ...state.loading, locations: false },
        error: null,
      };

    case ELocationsActions.GetLocationDictionary:
      return {
        ...state,
        dictionaryError: null,
        loading: { ...state.loading, dictionary: true },
      };
    case ELocationsActions.GetLocationDictionarySuccess:
      return {
        ...state,
        allZones: action.payload.useCache
          ? state.allZones
          : action.payload.dropdownDictionary.tsps.reduce(
              (results, tsp) => {
                tsp.zones.forEach(zone => {
                  if (!results.keys.has(zone.id)) {
                    results.keys.add(zone.id);
                    results.allUnique.push(zone);
                  }
                });

                return results;
              },
              { allUnique: new Array<Zone>(), keys: new Set<number>() }
            ).allUnique,
        dropdownDictionary: action.payload.useCache
          ? state.dropdownDictionary
          : action.payload.dropdownDictionary,
        dictionaryError: null,
        loading: { ...state.loading, dictionary: false },
      };

    case ELocationsActions.GetLocationDictionaryError:
      return {
        ...state,
        dictionaryError: action.payload,
        loading: { ...state.loading, dictionary: false },
      };

    case ELocationsActions.GetLocationEffectiveDateHistory:
      return {
        ...state,
        effectiveDateHistory: [],
        status: {
          ...state.status,
          effectiveDateHistory: {
            httpError: null,
            loading: true,
          },
        },
      };

    case ELocationsActions.GetLocationEffectiveDateHistorySuccess:
      return {
        ...state,
        effectiveDateHistory: action.payload.map((effectiveDateRecord: EffectiveDateRecord) => {
          // Stripping out time information as we only care about the date portion.
          const effectiveDate = effectiveDateRecord.dateEffective
            ? dateUtils.stripTimeFromDate(effectiveDateRecord.dateEffective)
            : '';
          const dateExpire = effectiveDateRecord.dateExpire
            ? dateUtils.stripTimeFromDate(effectiveDateRecord.dateExpire)
            : '';

          return {
            ...effectiveDateRecord,
            dateEffective: effectiveDate,
            dateExpire: dateExpire,
          };
        }) as EffectiveDateRecord[],
        status: {
          ...state.status,
          effectiveDateHistory: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.GetLocationEffectiveDateHistoryFailure:
      return {
        ...state,
        effectiveDateHistory: [],
        status: {
          ...state.status,
          effectiveDateHistory: {
            httpError: action.payload,
            loading: false,
          },
        },
      };

    case ELocationsActions.GetLocationGroupEffectiveDateHistory:
      return {
        ...state,
        effectiveDateHistory: [],
        status: {
          ...state.status,
          effectiveDateHistory: {
            httpError: null,
            loading: true,
          },
        },
      };

    case ELocationsActions.GetLocationGroupEffectiveDateHistorySuccess:
      return {
        ...state,
        effectiveDateHistory: action.payload.map((effectiveDateRecord: EffectiveDateRecord) => {
          return {
            ...effectiveDateRecord,
            dateEffective: effectiveDateRecord.dateEffective,
            dateExpire: effectiveDateRecord.dateExpire,
          };
        }) as EffectiveDateRecord[],
        status: {
          ...state.status,
          effectiveDateHistory: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.GetLocationGroupEffectiveDateHistoryFailure:
      return {
        ...state,
        effectiveDateHistory: [],
        status: {
          ...state.status,
          effectiveDateHistory: {
            httpError: action.payload,
            loading: false,
          },
        },
      };

    case ELocationsActions.FetchLocationGroupById:
      return {
        ...state,
        locationGroup: null,
        status: { ...state.status, locationGroup: { loading: true, httpError: null } },
      };

    case ELocationsActions.FetchLocationGroupByIdSuccess:
      return {
        ...state,
        locationGroup: action.payload.locationGroup,
        status: { ...state.status, locationGroup: { loading: false, httpError: null } },
      };

    case ELocationsActions.FetchLocationGroupByIdFailure:
      return {
        ...state,
        locationGroup: null,
        status: {
          ...state.status,
          locationGroup: { loading: false, httpError: action.payload.error },
        },
      };

    case ELocationsActions.FetchLocationGroups:
      return {
        ...state,
        locationGroups: null,
        totalLocationGroupCount: 0,
        status: { ...state.status, locationGroups: { httpError: null, loading: true } },
      };

    case ELocationsActions.FetchLocationGroupsSuccess:
      return {
        ...state,
        locationGroups: action.payload.locationGroups,
        totalLocationGroupCount: action.payload.total,
        status: { ...state.status, locationGroups: { httpError: null, loading: false } },
      };

    case ELocationsActions.FetchLocationGroupsFailure:
      return {
        ...state,
        status: { ...state.status, locationGroups: { httpError: action.payload, loading: false } },
      };

    case ELocationsActions.ClearLocationGroups:
      return {
        ...state,
        locationGroups: [],
        totalLocationGroupCount: 0,
      };

    case ELocationsActions.FetchLocationGroupsByTspIds:
      return {
        ...state,
        error: null,
        status: { ...state.status, locationGroups: { httpError: null, loading: true } },
        loading: { ...state.loading, locations: true },
      };
    case ELocationsActions.FetchLocationGroupsByTspIdsSuccess:
      return {
        ...state,
        tspIdLocationGroupCollection: action.payload,
        status: { ...state.status, locationGroups: { httpError: null, loading: false } },
        loading: { ...state.loading, locations: false },
      };
    case ELocationsActions.FetchLocationGroupsByTspIdsFailure:
      return {
        ...state,
        tspIdLocationGroupCollection: [],
        status: { ...state.status, locationGroups: { httpError: action.payload, loading: false } },
        loading: { ...state.loading, locations: false },
      };

    case ELocationsActions.AddLocation:
      return {
        ...state,
        status: {
          ...state.status,
          addLocation: {
            httpError: null,
            loading: true,
            validationError: null,
          },
        },
      };

    case ELocationsActions.AddLocationSuccess:
      return {
        ...state,
        status: {
          ...state.status,
          addLocation: {
            httpError: null,
            loading: false,
            validationError: null,
          },
        },
      };

    case ELocationsActions.AddLocationError:
      return {
        ...state,
        status: {
          ...state.status,
          addLocation: {
            httpError: action.payload.error,
            loading: false,
            validationError:
              LOCATION_VALIDATION_ERROR_MAPPINGS[getErrorMessage(action.payload.error)],
          },
        },
      };

    case ELocationsActions.ClearAddLocationValidationErrors:
      return {
        ...state,
        status: {
          ...state.status,
          addLocation: {
            httpError: null,
            ...state.status.addLocation,
            validationError: null,
          },
        },
      };

    case ELocationsActions.UpdateLocation:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocation: {
            httpError: null,
            loading: true,
            validationError: null,
          },
        },
      };

    case ELocationsActions.UpdateLocationSuccess:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocation: {
            httpError: null,
            loading: false,
            validationError: null,
          },
        },
      };

    case ELocationsActions.UpdateLocationError:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocation: {
            httpError: action.payload.error,
            loading: false,
            validationError:
              LOCATION_VALIDATION_ERROR_MAPPINGS[getErrorMessage(action.payload.error)],
          },
        },
      };

    case ELocationsActions.ClearUpdateLocationValidationErrors:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocation: {
            httpError: null,
            ...state.status.updateLocation,
            validationError: null,
          },
        },
      };

    case ELocationsActions.SearchLocations:
      return {
        ...state,
        locationsSearchLoading: true,
        locationsSearchError: null,
      };

    case ELocationsActions.SetPreviouslySelectedSearchLocation:
      return {
        ...state,
        previouslySearchedLocation: action.payload.location,
      };

    case ELocationsActions.SearchLocationsSuccess:
      return {
        ...state,
        locationsSearch: action.payload.locations,
        locationsSearchLoading: false,
        locationsSearchError: null,
      };

    case ELocationsActions.SearchLocationsFailure:
      return {
        ...state,
        locationsSearchLoading: false,
        locationsSearchError: action.payload.error,
      };

    case ELocationsActions.SearchLocationsFromMultipleTsps:
      return {
        ...state,
        locationsSearchLoading: true,
        locationsSearchError: null,
      };

    case ELocationsActions.SearchLocationsFromMultipleTspsSuccess:
      return {
        ...state,
        locationsSearch: action.payload.locations,
        locationsSearchLoading: false,
        locationsSearchError: null,
      };

    case ELocationsActions.SearchLocationsFromMultipleTspsFailure:
      return {
        ...state,
        locationsSearchLoading: false,
        locationsSearchError: action.payload.error,
      };

    case ELocationsActions.ClearSearchLocations:
      return {
        ...state,
        locationsSearch: [],
      };
    case ELocationsActions.SearchLocationHeaders:
      return {
        ...state,
        locationHeadersSearch: createLoadingState(state.locationHeadersSearch),
      };
    case ELocationsActions.SearchLocationHeadersSuccess:
      return {
        ...state,
        locationHeadersSearch: createSuccessState(action.payload),
      };
    case ELocationsActions.SearchLocationHeadersFailure:
      return {
        ...state,
        locationHeadersSearch: createErrorState(action.payload.error),
      };
    case ELocationsActions.ClearSearchLocationHeaders:
      return {
        ...state,
        locationHeadersSearch: clearState(state.locationHeadersSearch),
      };
    case ELocationsActions.CreateLocationGroup:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationGroup: {
            httpError: null,
            loading: true,
          },
        },
      };

    case ELocationsActions.CreateLocationGroupSuccess:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationGroup: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.CreateLocationGroupFailure:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationGroup: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case ELocationsActions.UpdateLocationGroup:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationGroup: {
            httpError: null,
            loading: true,
          },
        },
      };

    case ELocationsActions.UpdateLocationGroupSuccess:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationGroup: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.UpdateLocationGroupFailure:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationGroup: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case ELocationsActions.ClearLocationGroupUpdateErrors:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationGroup: {
            ...state.status.updateLocationGroup,
            httpError: null,
          },
          createLocationGroup: {
            ...state.status.createLocationGroup,
            httpError: null,
          },
        },
      };

    case ELocationsActions.DeleteLocationGroup:
      return {
        ...state,
        status: {
          ...state.status,
          deleteLocationGroup: {
            httpError: null,
            loading: true,
          },
        },
      };

    case ELocationsActions.DeleteLocationGroupSuccess:
      return {
        ...state,
        status: {
          ...state.status,
          deleteLocationGroup: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.DeleteLocationGroupFailure:
      return {
        ...state,
        status: {
          ...state.status,
          deleteLocationGroup: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case ELocationsActions.ClearDeleteLocationGroupError:
      return {
        ...state,
        status: {
          ...state.status,
          deleteLocationGroup: {
            ...state.status.deleteLocationGroup,
            httpError: null,
          },
        },
      };

    case ELocationsActions.FetchStorageLocationsSuccess:
      return {
        ...state,
        storageLocations: action.payload,
      };

    case ELocationsActions.FETCH_LOCATION_GROUPS_BY_IDS:
      return {
        ...state,
        locationGroupByIds: null,
        status: {
          ...state.status,
          locationGroups: {
            httpError: null,
            loading: false,
          },
        },
      };
    case ELocationsActions.FETCH_LOCATION_GROUPS_BY_IDS_SUCCESS:
      return {
        ...state,
        locationGroupByIds: action.payload.locationGroupByIds,
        status: {
          ...state.status,
          locationGroups: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.FETCH_LOCATION_GROUPS_BY_IDS_FAILURE:
      return {
        ...state,
        locationGroupByIds: null,
        status: {
          ...state.status,
          locationGroups: { httpError: action.payload, loading: false },
        },
      };

    case ELocationsActions.FetchLocationGroupCriteriaById:
      return {
        ...state,
        dynamicLocationGroup: null,
        status: { ...state.status, dynamicLocationGroup: { loading: true, httpError: null } },
      };

    case ELocationsActions.FetchLocationGroupCriteriaByIdSuccess:
      return {
        ...state,
        dynamicLocationGroup: action.payload.dynamicLocationGroup,
        status: { ...state.status, dynamicLocationGroup: { loading: false, httpError: null } },
      };

    case ELocationsActions.FetchLocationGroupCriteriaByIdFailure:
      return {
        ...state,
        dynamicLocationGroup: null,
        status: {
          ...state.status,
          dynamicLocationGroup: { loading: false, httpError: action.payload.error },
        },
      };

    case ELocationsActions.ClearLocationGroupCriteria:
      return {
        ...state,
        dynamicLocationGroup: null,
        status: { ...state.status, dynamicLocationGroup: { loading: false, httpError: null } },
      };

    case ELocationsActions.CreateLocationGroupCriteria:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationGroupCriteria: {
            httpError: null,
            loading: true,
          },
        },
      };

    case ELocationsActions.CreateLocationGroupCriteriaSuccess:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationGroupCriteria: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.CreateLocationGroupCriteriaFailure:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationGroupCriteria: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case ELocationsActions.UpdateLocationGroupCriteria:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationGroupCriteria: {
            httpError: null,
            loading: true,
          },
        },
      };

    case ELocationsActions.UpdateLocationGroupCriteriaSuccess:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationGroupCriteria: {
            httpError: null,
            loading: false,
          },
        },
      };

    case ELocationsActions.UpdateLocationGroupCriteriaFailure:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationGroupCriteria: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case ELocationsActions.FetchLocationGroupChildLocations:
      return {
        ...state,
        childLocations: null,
        status: { ...state.status, childLocations: { loading: true, httpError: null } },
      };

    case ELocationsActions.FetchLocationGroupChildLocationsSuccess:
      return {
        ...state,
        childLocations: action.payload.childLocations,
        status: { ...state.status, childLocations: { loading: false, httpError: null } },
      };

    case ELocationsActions.FetchLocationGroupChildLocationsFailure:
      return {
        ...state,
        childLocations: null,
        status: {
          ...state.status,
          childLocations: { loading: false, httpError: action.payload.error },
        },
      };

    case ELocationsActions.FetchLocationsList:
      return {
        ...state,
        locationsList: createLoadingState(state.locationsList),
      };
    case ELocationsActions.FetchLocationsListSuccess:
      return {
        ...state,
        locationsList: createSuccessState(action.payload.locations),
      };
    case ELocationsActions.FetchLocationsListFailure:
      return {
        ...state,
        locationsList: createErrorState(action.payload.error),
      };

    case ELocationsActions.FetchParentLocation:
      return {
        ...state,
        parentLocation: createLoadingState(state.parentLocation),
      };
    case ELocationsActions.FetchParentLocationSuccess:
      return {
        ...state,
        parentLocation: createSuccessState(action.payload.parentLocation),
      };
    case ELocationsActions.FetchParentLocationFailure:
      return {
        ...state,
        parentLocation: createErrorState(action.payload.error),
      };

    case ELocationsActions.ClearParentLocation:
      return {
        ...state,
        parentLocation: createSuccessState(null),
      };

    case ELocationsActions.FetchDynamicLocationGroups:
      return {
        ...state,
        dynamicLocationGroupCollection: createLoadingState(state.dynamicLocationGroupCollection),
      };
    case ELocationsActions.FetchDynamicLocationGroupsSuccess:
      return {
        ...state,
        dynamicLocationGroupCollection: createSuccessState(action.payload),
      };
    case ELocationsActions.FetchDynamicLocationGroupsFailure:
      return {
        ...state,
        dynamicLocationGroupCollection: createErrorState(action.payload.error),
      };

    default:
      return state;
  }
}
