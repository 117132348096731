import { EHeaderActions, HeaderActions } from './header.actions';
import { IHeaderState, initialHeaderState } from './header.state';

export const VIEW_ALL_TSP = {};

export function headerReducers(state = initialHeaderState, action: HeaderActions): IHeaderState {
  switch (action.type) {
    case EHeaderActions.SET_TSP:
      return {
        ...state,
        tsp:
          (action.payload.overrideIfAlreadySet === undefined ||
            action.payload.overrideIfAlreadySet ||
            !state.tsp) &&
          action.payload.tsp
            ? action.payload.tsp
            : state.tsp,
      };
    case EHeaderActions.SET_SERVICE_REQUESTER:
      return {
        ...state,
        serviceRequester: action.payload.serviceRequester,
      };
    case EHeaderActions.CLEAR_SERVICE_REQUESTER:
      return {
        ...state,
        serviceRequester: initialHeaderState.serviceRequester,
        serviceRequesters: initialHeaderState.serviceRequesters,
      };
    default:
      return state;
  }
}
