import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Entity_Management_Routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/entities/entities.module').then(m => m.EntitiesModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/create-entity/create-entity.module').then(m => m.CreateEntityModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/new',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/create-entity/create-entity.module').then(m => m.CreateEntityModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/edit',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    redirectTo: ':id/overview',
  },
  {
    path: ':id/overview',
    loadChildren: () =>
      import('./pages/entity-overview/entity-overview.module').then(m => m.EntityOverviewModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/overview',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/class-override',
    loadChildren: () =>
      import('./pages/entity-class-override/entity-class-override.module').then(
        m => m.EntityClassOverrideModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/class-override',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/edit-class-override',
    loadChildren: () =>
      import('./pages/create-entity-class-override/create-entity-class-override.module').then(
        m => m.CreateEntityClassOverrideModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/edit-class-override',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/credit',
    loadChildren: () =>
      import('./pages/entity-credit/entity-credit.module').then(m => m.EntityCreditModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/credit',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit-parent-entity',
    loadChildren: () =>
      import('./pages/create-entity-parent/create-entity-parent.module').then(
        m => m.CreateEntityParentModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/edit-parent-entity',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/parent-entity',
    loadChildren: () =>
      import('./pages/entity-parent/entity-parent.module').then(m => m.EntityParentModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/parent-entity',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/invoice-groups',
    loadChildren: () =>
      import('./pages/entities-invoice-groups/entities-invoice-groups.module').then(
        m => m.EntitiesInvoiceGroupsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/entities/{id}/invoice-groups',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];
