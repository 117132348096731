import {
  ESystemPlanningActions,
  SystemPlanningActions,
} from 'app/store/system-planning/system-planning.actions';
import {
  initialSystemPlanningState,
  ISystemPlanningState,
} from 'app/store/system-planning/system-planning.state';
import {
  clearState,
  createErrorState,
  createLoadingState,
  createSuccessState,
} from '../app/app.models';

export function systemPlanningReducer(
  state = initialSystemPlanningState,
  action: SystemPlanningActions
): ISystemPlanningState {
  switch (action.type) {
    case ESystemPlanningActions.FETCH_OBLIGATIONS_COLLECTION:
      return {
        ...state,
        fetchObligations: createLoadingState(state.fetchObligations),
      };
    case ESystemPlanningActions.FETCH_OBLIGATIONS_COLLECTION_SUCCESS:
      return {
        ...state,
        fetchObligations: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.FETCH_OBLIGATIONS_COLLECTION_FAILURE:
      return {
        ...state,
        fetchObligations: createErrorState(action.payload),
      };
    case ESystemPlanningActions.CLEAR_OBLIGATIONS_COLLECTION:
      return {
        ...state,
        fetchObligations: clearState(state.fetchObligations),
      };
    case ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_SELECTION:
      return {
        ...state,
        exportObligations: createLoadingState(state.exportObligations),
      };
    case ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_SELECTION_SUCCESS:
      return {
        ...state,
      };
    case ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_SELECTION_FAILURE:
      return {
        ...state,
        exportObligations: createErrorState(action.payload),
      };
    case ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_DOWNLOAD_RESULT_SUCCESS:
      return {
        ...state,
        exportObligations: clearState(state.exportObligations),
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_COLLECTION:
      return {
        ...state,
        fetchAverageDayCollectionLoading: true,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_COLLECTION_SUCCESS:
      return {
        ...state,
        averageDay: action.payload,
        averageDayMeasurements: action.payload.averageMeasurements,
        totalAverageDaysCount: action.payload.averageMeasurements.length,
        fetchAverageDayCollectionLoading: false,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_COLLECTION_FAILURE:
      return {
        ...state,
        averageDay: null,
        averageDayMeasurements: null,
        fetchAverageDayCollectionLoading: false,
        totalAverageDaysCount: 0,
        fetchAverageDayCollectionError: action.payload,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_BY_ID:
      return {
        ...state,
        fetchAverageDayCollectionLoading: true,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_BY_ID_SUCCESS:
      return {
        ...state,
        averageDay: action.payload,
        averageDayMeasurements: action.payload.averageMeasurements,
        totalAverageDaysCount: action.payload.averageMeasurements.length,
        fetchAverageDayCollectionLoading: false,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_BY_ID_FAILURE:
      return {
        ...state,
        averageDay: null,
        averageDayMeasurements: null,
        fetchAverageDayCollectionLoading: false,
        totalAverageDaysCount: 0,
        fetchAverageDayCollectionError: action.payload,
      };
    case ESystemPlanningActions.CLEAR_AVERAGE_DAY_COLLECTION:
      return {
        ...state,
        averageDay: null,
        averageDayMeasurements: null,
        totalAverageDaysCount: 0,
        fetchAverageDayCollectionError: null,
      };
    case ESystemPlanningActions.SAVE_AVERAGE_DAY:
      return {
        ...state,
        saveAverageDayLoading: true,
      };
    case ESystemPlanningActions.SAVE_AVERAGE_DAY_SUCCESS:
      return {
        ...state,
        saveAverageDayLoading: false,
        editingAverageDay: action.payload,
        networkResultStatus: ESystemPlanningActions.SAVE_AVERAGE_DAY_SUCCESS,
      };
    case ESystemPlanningActions.SAVE_AVERAGE_DAY_FAILURE:
      return {
        ...state,
        saveAverageDayLoading: false,
        saveAverageDayError: true,
        editingAverageDay: action.payload,
        networkResultStatus: ESystemPlanningActions.SAVE_AVERAGE_DAY_FAILURE,
      };
    case ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT:
      return {
        ...state,
        averageDayIdDefaultLoading: true,
      };
    case ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT_SUCCESS:
      return {
        ...state,
        averageDayIdDefaultLoading: false,
        saveAverageDayError: false,
        averageDayIdDefault: action.payload.averageDayId,
        networkResultStatus: ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT_SUCCESS,
      };
    case ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT_FAILURE:
      return {
        ...state,
        averageDayIdDefaultLoading: false,
        averageDayIdDefaultError: true,
        networkResultStatus: ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT_FAILURE,
      };
    case ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION:
      return {
        ...state,
        exportAverageDay: createLoadingState(state.exportAverageDay),
      };
    case ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION_SUCCESS:
      return {
        ...state,
      };
    case ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION_FAILURE:
      return {
        ...state,
        exportAverageDay: createErrorState(action.payload),
      };
    case ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_DOWNLOAD_RESULT_SUCCESS:
      return {
        ...state,
        exportAverageDay: clearState(state.exportAverageDay),
      };
    case ESystemPlanningActions.ACKNOWLEDGE_NETWORK_RESULT:
      return {
        ...state,
        networkResultStatus: null,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_LOOKUP:
      return {
        ...state,
        averageDayLookupLoading: true,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_LOOKUP_SUCCESS:
      return {
        ...state,
        averageDayLookupLoading: false,
        averageDayLookup: action.payload,
      };
    case ESystemPlanningActions.FETCH_AVERAGE_DAY_LOOKUP_FAILURE:
      return {
        ...state,
        averageDayLookupLoading: false,
        averageDayLookupError: action.payload,
      };
    case ESystemPlanningActions.FETCH_METER_CHECK_GUID:
      return {
        ...state,
        meterCapacityGuid: createLoadingState(state.meterCapacityGuid),
      };
    case ESystemPlanningActions.FETCH_METER_CHECK_GUID_SUCCESS:
      return {
        ...state,
      };
    case ESystemPlanningActions.FETCH_METER_CHECK_GUID_FAILURE:
      return {
        ...state,
        meterCapacityGuid: createErrorState(action.payload),
      };
    case ESystemPlanningActions.CLEAR_METER_CHECK_GUID:
      return {
        ...state,
        meterCapacityGuid: clearState(state.meterCapacityGuid),
      };
    case ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_COLLECTION:
      return {
        ...state,
        meterCapacityCheckCollection: createLoadingState(state.meterCapacityCheckCollection),
      };
    case ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_COLLECTION_SUCCESS:
      return {
        ...state,
        meterCapacityCheckCollection: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_COLLECTION_FAILURE:
      return {
        ...state,
        meterCapacityCheckCollection: createErrorState(action.payload),
      };
    case ESystemPlanningActions.CLEAR_METER_CAPACITY_CHECK_COLLECTION:
      return {
        ...state,
        meterCapacityCheckCollection: clearState(state.meterCapacityCheckCollection),
      };
    case ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_BY_ID:
      return {
        ...state,
        meterCapacityCheck: createLoadingState(state.meterCapacityCheck),
      };
    case ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_BY_ID_SUCCESS:
      return {
        ...state,
        meterCapacityCheck: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_BY_ID_FAILURE:
      return {
        ...state,
        meterCapacityCheck: createErrorState(action.payload),
      };
    case ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION:
      return {
        ...state,
        operationalCapacityReservationCollection: createLoadingState(
          state.operationalCapacityReservationCollection
        ),
      };
    case ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION_SUCCESS:
      return {
        ...state,
        operationalCapacityReservationCollection: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION_FAILURE:
      return {
        ...state,
        operationalCapacityReservationCollection: createErrorState(action.payload),
      };
    case ESystemPlanningActions.CLEAR_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION:
      return {
        ...state,
        operationalCapacityReservationCollection: clearState(
          state.operationalCapacityReservationCollection
        ),
      };
    case ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY:
      return {
        ...state,
        operationalCapacityReservationEntry: createLoadingState(
          state.operationalCapacityReservationEntry
        ),
      };
    case ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS:
      return {
        ...state,
        operationalCapacityReservationEntry: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE:
      return {
        ...state,
        operationalCapacityReservationEntry: createErrorState(action.payload),
      };
    case ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY:
      return {
        ...state,
        operationalCapacityReservationEntry: createLoadingState(
          state.operationalCapacityReservationEntry
        ),
      };
    case ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS:
      return {
        ...state,
        operationalCapacityReservationEntry: createSuccessState(action.payload),
        networkResultStatus:
          ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS,
      };
    case ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE:
      return {
        ...state,
        operationalCapacityReservationEntry: createErrorState(action.payload),
        networkResultStatus:
          ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE,
      };
    case ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY:
      return {
        ...state,
        operationalCapacityReservationEntry: createLoadingState(
          state.operationalCapacityReservationEntry
        ),
      };
    case ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS:
      return {
        ...state,
        operationalCapacityReservationEntry: createSuccessState(action.payload),
        networkResultStatus:
          ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS,
      };
    case ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE:
      return {
        ...state,
        operationalCapacityReservationEntry: createErrorState(action.payload),
        networkResultStatus:
          ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE,
      };
    case ESystemPlanningActions.CLEAR_OPERATIONAL_CAPACITY_RESERVATION_ENTRY:
      return {
        ...state,
        operationalCapacityReservationEntry: clearState(state.operationalCapacityReservationEntry),
      };
    case ESystemPlanningActions.FETCH_LINE_SEQUENCE_COLLECTION:
      return {
        ...state,
        lineSequenceCollection: createLoadingState(state.lineSequenceCollection),
      };
    case ESystemPlanningActions.FETCH_LINE_SEQUENCE_COLLECTION_SUCCESS:
      return {
        ...state,
        lineSequenceCollection: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.FETCH_LINE_SEQUENCE_COLLECTION_FAILURE:
      return {
        ...state,
        lineSequenceCollection: createErrorState(action.payload),
      };
    case ESystemPlanningActions.FETCH_STORAGE_OBLIGATION_COLLECTION:
      return {
        ...state,
        storageObligationCollection: createLoadingState(state.storageObligationCollection),
      };
    case ESystemPlanningActions.FETCH_STORAGE_OBLIGATION_COLLECTION_SUCCESS:
      return {
        ...state,
        storageObligationCollection: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.FETCH_STORAGE_OBLIGATION_COLLECTION_FAILURE:
      return {
        ...state,
        storageObligationCollection: createErrorState(action.payload),
      };
    case ESystemPlanningActions.CLEAR_STORAGE_OBLIGATION_COLLECTION:
      return {
        ...state,
        storageObligationCollection: clearState(state.storageObligationCollection),
      };
    case ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_COLLECTION:
      return {
        ...state,
        exportStorageObligationCollection: createLoadingState(
          state.exportStorageObligationCollection
        ),
      };
    case ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_DOWNLOAD_SUCCESS:
      return {
        ...state,
        exportStorageObligationCollection: createSuccessState(action.payload),
      };
    case ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_COLLECTION_FAILURE:
      return {
        ...state,
        exportStorageObligationCollection: createErrorState(action.payload),
      };
    case ESystemPlanningActions.CLEAR_EXPORT_STORAGE_OBLIGATION_COLLECTION:
      return {
        ...state,
        exportStorageObligationCollection: clearState(state.exportStorageObligationCollection),
      };
    default:
      return state;
  }
}
