import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { dateUtils } from 'shared/utils/date.utils';

import {
  ConfirmationLevelCollection,
  ConfirmationsEditAccess,
  ConfirmationService,
  ConfirmationSummary,
  ConfirmationSummaryCollection,
} from '@gms/nomination-api';
import get from 'lodash/get';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  EConfirmationsActions,
  FetchConfirmationAccessForLocation,
  FetchConfirmationAccessForLocationFailure,
  FetchConfirmationAccessForLocationSuccess,
  FetchConfirmationLevelConfig,
  FetchConfirmationLevelConfigFailure,
  FetchConfirmationLevelConfigSuccess,
  FetchConfirmations,
  FetchConfirmationsFailure,
  FetchConfirmationsForLocation,
  FetchConfirmationsForLocationFailure,
  FetchConfirmationsForLocationSuccess,
  FetchConfirmationsSuccess,
  UpdateConfirmations,
  UpdateConfirmationsError,
  UpdateConfirmationsSuccess,
} from './confirmations.actions';

@Injectable()
export class ConfirmationsEffects {
  constructor(private _actions$: Actions, private _confirmationService: ConfirmationService) {}

  FetchConfirmations: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchConfirmations>(EConfirmationsActions.FETCH_CONFIRMATIONS),
      map((action: FetchConfirmations) => action.payload),
      switchMap(payload =>
        this._confirmationService
          .getConfirmations(
            new Date(new Date(get(payload, 'gasFlowDate')).toDateString()),
            payload.cycleCode,
            payload.confirmingPartyId,
            payload.tspId,
            payload.excludeZeroQty,
            payload.locationNumber,
            payload.serviceRequestContractId,
            payload.confirmationGroupId,
            payload.packageId
          )
          .pipe(
            map(
              (confirmationsCollection: ConfirmationSummaryCollection) =>
                new FetchConfirmationsSuccess({
                  confirmationSummaries: get(confirmationsCollection, 'confirmationSummaries', []),
                  totalConfirmationsCount: get(confirmationsCollection, 'total', 0),
                })
            ),
            catchError(error => {
              error.error = error.error || new Error(error.status);
              return of(new FetchConfirmationsFailure(error));
            })
          )
      )
    )
  );

  //TODO:THISONE
  FetchConfirmationsForLocation: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchConfirmationsForLocation>(EConfirmationsActions.FETCH_CONFIRMATIONS_FOR_LOCATION),
      map((action: FetchConfirmationsForLocation) => action.payload),
      mergeMap(payload =>
        this._confirmationService
          .getConfirmationsForLocation(
            payload.locationId,
            new Date(new Date(get(payload, 'gasFlowDate')).toDateString()),
            payload.cycleCode,
            payload.confirmationLevel,
            payload.excludeZeroQty,
            payload.serviceRequestContractId,
            payload.packageId
          )
          .pipe(
            map((confirmationSummary: ConfirmationSummary) => {
              return new FetchConfirmationsForLocationSuccess({
                confirmationSummary: confirmationSummary,
                locationId: payload.locationId,
              });
            }),
            catchError(error => {
              error.error = error.error || new Error(error.status);
              return of(
                new FetchConfirmationsForLocationFailure({
                  error: error,
                  locationId: payload.locationId,
                })
              );
            })
          )
      )
    )
  );

  FetchConfirmationsAccessForLocation: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchConfirmationAccessForLocation>(
        EConfirmationsActions.FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION
      ),
      map((action: FetchConfirmationAccessForLocation) => action.payload),
      mergeMap(payload =>
        this._confirmationService
          .getConfsEditAccessForLocation(payload.locationId, payload.gasFlowDate)
          .pipe(
            map((confirmationsEditAccess: ConfirmationsEditAccess) => {
              return new FetchConfirmationAccessForLocationSuccess({
                locationId: payload.locationId,
                gasFlowDate: payload.gasFlowDate,
                editAccess: confirmationsEditAccess.editAccess,
              });
            }),
            catchError(error => {
              error.error = error.error || new Error(error.status);
              return of(
                new FetchConfirmationAccessForLocationFailure({
                  error: error,
                  locationId: payload.locationId,
                  gasFlowDate: payload.gasFlowDate,
                })
              );
            })
          )
      )
    )
  );

  FetchConfirmationLevelConfig: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchConfirmationLevelConfig>(EConfirmationsActions.FETCH_CONFIRMATION_LEVEL_CONFIG),
      switchMap(() =>
        this._confirmationService.getConfirmationsLevels().pipe(
          map(
            (confirmationLevelCollection: ConfirmationLevelCollection) =>
              new FetchConfirmationLevelConfigSuccess({
                confirmationLevels: confirmationLevelCollection.confirmationLevels,
              })
          ),
          catchError(error => {
            error.error = error.error || new Error(error.status);
            return of(new FetchConfirmationLevelConfigFailure(error));
          })
        )
      )
    )
  );

  UpdateConfirmations: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateConfirmations>(EConfirmationsActions.UPDATE_CONFIRMATIONS),
      map((action: UpdateConfirmations) => action.payload),
      concatMap(payload =>
        this._confirmationService
          .confirmLocation(
            payload.confirmationSummary,
            payload.locationId,
            payload.gasFlowDate,
            payload.cycleCode,
            payload.confirmationLevel,
            payload.excludeZeroQty,
            payload.serviceRequestContractId
          )
          .pipe(
            map(
              (confirmationSummary: ConfirmationSummary) =>
                new UpdateConfirmationsSuccess({
                  updateConfirmationsSuccess: confirmationSummary,
                  locationId: payload.locationId,
                })
            ),
            catchError(error => {
              error.error = error.error || new Error(error.status);
              return of(
                new UpdateConfirmationsError({
                  confirmationSummary: { ...error.error } as ConfirmationSummary,
                  status: Number(error.status),
                  locationId: payload.locationId,
                })
              );
            })
          )
      )
    )
  );
}
