import { FacilityStorageGroupCardComponent } from 'app/modules/accounting/pages/inventory-pipeline-balance-report/facility-storage-group-card/facility-storage-group-card.component';
import get from 'lodash/get';
import { ConfirmationsActions, EConfirmationsActions } from './confirmations.actions';
import { IConfirmationsState, initialConfirmationsState } from './confirmations.state';

export function confirmationsReducers(
  state = initialConfirmationsState,
  action: ConfirmationsActions
): IConfirmationsState {
  switch (action.type) {
    case EConfirmationsActions.CLEAR_CONFIRMATION_SUMMARIES:
      return {
        ...state,
        confirmationLastUpdated: null,
        confirmationSummaries: null,
      };

    case EConfirmationsActions.CLEAR_CONFIRMATION_TOASTS:
      return {
        ...state,
        error: null,
        updateConfirmationsError: null,
        updateConfirmationsSuccess: null,
      };

    case EConfirmationsActions.FETCH_CONFIRMATIONS:
      return {
        ...state,
        cycleCode: action.payload.cycleCode,
        gasFlowDate: action.payload.gasFlowDate,
        excludeZeroQty: action.payload.excludeZeroQty,
        confirmationSummaries: null,
        totalConfirmationsCount: null,
        loading: true,
        error: null,
      };

    case EConfirmationsActions.FETCH_CONFIRMATIONS_SUCCESS:
      const confirmationSummariesLoading = {};
      const confirmationSummariesError = {};
      const totalConfirmationSummaries = {};

      if (action.payload.confirmationSummaries && action.payload.confirmationSummaries.length) {
        action.payload.confirmationSummaries.map(ScheduledQuantitySummary => {
          confirmationSummariesLoading[ScheduledQuantitySummary.locationId] = true;
          confirmationSummariesError[ScheduledQuantitySummary.locationId] = null;
          totalConfirmationSummaries[ScheduledQuantitySummary.locationId] = [];
        });
      }

      return {
        ...state,
        confirmationSummaries: action.payload.confirmationSummaries,
        totalConfirmationsCount: action.payload.totalConfirmationsCount,
        loading: false,
        error: null,

        confirmationSummariesLoading: confirmationSummariesLoading,
        confirmationSummariesError: confirmationSummariesError,
        totalConfirmationSummaries: totalConfirmationSummaries,
      };

    case EConfirmationsActions.FETCH_CONFIRMATIONS_ERROR:
      return {
        ...state,
        confirmationSummaries: null,
        totalConfirmationsCount: null,
        loading: false,
        error: action.payload.error,
      };
    case EConfirmationsActions.FETCH_CONFIRMATIONS_FOR_LOCATION: {
      return {
        ...state,
        confirmationLastUpdated: null,
        confirmationSummariesLoading: {
          ...state.confirmationSummariesLoading,
          [action.payload.locationId]: true,
        },
        confirmationSummariesError: {
          ...state.confirmationSummariesError,
          [action.payload.locationId]: null,
        },
        totalConfirmationSummaries: {
          ...state.totalConfirmationSummaries,
          [action.payload.locationId]: null,
        },
      };
    }
    case EConfirmationsActions.FETCH_CONFIRMATIONS_FOR_LOCATION_SUCCESS: {
      //TODO: factor this out somehow, temp fix for blinking on loading.
      let index;
      for (index = 0; index < get(state, 'confirmationSummaries.length', 0); index++) {
        if (
          state.confirmationSummaries[index] &&
          state.confirmationSummaries[index].locationId === action.payload.locationId
        ) {
          break;
        }
      }

      state.confirmationSummaries[index] = action.payload.confirmationSummary;

      return {
        ...state,
        confirmationSummaries: [...state.confirmationSummaries],
        confirmationLastUpdated: action.payload.locationId,
        confirmationSummariesLoading: {
          ...state.confirmationSummariesLoading,
          [action.payload.locationId]: false,
        },
        confirmationSummariesError: {
          ...state.confirmationSummariesError,
          [action.payload.locationId]: null,
        },
        totalConfirmationSummaries: {
          ...state.totalConfirmationSummaries,
          [action.payload.locationId]: action.payload.confirmationSummary
            ? action.payload.confirmationSummary.confirmations.length
            : 0,
        },
      };
    }
    case EConfirmationsActions.FETCH_CONFIRMATIONS_FOR_LOCATION_ERROR: {
      return {
        ...state,
        confirmationLastUpdated: action.payload.locationId,
        confirmationSummariesLoading: {
          ...state.confirmationSummariesLoading,
          [action.payload.locationId]: false,
        },
        confirmationSummariesError: {
          ...state.confirmationSummariesError,
          [action.payload.locationId]: true,
        },
        totalConfirmationSummaries: {
          ...state.totalConfirmationSummaries,
          [action.payload.locationId]: null,
        },
      };
    }
    case EConfirmationsActions.FETCH_CONFIRMATION_LEVEL_CONFIG:
      return {
        ...state,
        confirmationLevels: [],
        loading: true,
        error: null,
      };
    case EConfirmationsActions.FETCH_CONFIRMATION_LEVEL_CONFIG_SUCCESS:
      return {
        ...state,
        confirmationLevels: action.payload.confirmationLevels,
        loading: false,
        error: null,
      };
    case EConfirmationsActions.FETCH_CONFIRMATION_LEVEL_CONFIG_FAILURE:
      return {
        ...state,
        confirmationLevels: [],
        loading: false,
        error: action.payload.error,
      };
    case EConfirmationsActions.UPDATE_CONFIRMATIONS:
      return {
        ...state,
        confirmationLastUpdated: action.payload.locationId,
        confirmationsToUpdate: action.payload.confirmationSummary,
        confirmationSummariesLoading: {
          ...state.confirmationSummariesLoading,
          [action.payload.locationId]: true,
        },
        confirmationSummariesError: {
          ...state.confirmationSummariesError,
          [action.payload.locationId]: null,
        },
        totalConfirmationSummaries: {
          ...state.totalConfirmationSummaries,
          [action.payload.locationId]: null,
        },
        updateConfirmationsLoading: true,
        updateConfirmationsSuccess: null,
        updateConfirmationsError: null,
      };
    case EConfirmationsActions.UPDATE_CONFIRMATIONS_SUCCESS:
      let i;
      for (i = 0; i < state.confirmationSummaries.length; i++) {
        if (
          state.confirmationSummaries[i] &&
          state.confirmationSummaries[i].locationId ===
            action.payload.updateConfirmationsSuccess.locationId
        ) {
          break;
        }
      }

      state.confirmationSummaries[i] = action.payload.updateConfirmationsSuccess;
      return {
        ...state,
        confirmationLastUpdated: action.payload.locationId,
        confirmationSummaries: [...state.confirmationSummaries],
        confirmationSummariesLoading: {
          ...state.confirmationSummariesLoading,
          [action.payload.locationId]: false,
        },
        confirmationSummariesError: {
          ...state.confirmationSummariesError,
          [action.payload.locationId]: null,
        },
        confirmationsToUpdate: null,
        updateConfirmationsLoading: false,
        updateConfirmationsError: null,
      };
    case EConfirmationsActions.UPDATE_CONFIRMATIONS_ERROR:
      return {
        ...state,
        confirmationLastUpdated: action.payload.locationId,
        confirmationSummariesLoading: {
          ...state.confirmationSummariesLoading,
          [action.payload.locationId]: false,
        },
        confirmationSummariesError: {
          ...state.confirmationSummariesError,
          [action.payload.locationId]: null,
        },
        confirmationsToUpdate: null,
        updateConfirmationsSuccess: null,
        updateConfirmationsLoading: null,
        updateConfirmationsError: {
          ...action.payload,
        },
      };
    case EConfirmationsActions.FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION:
      return {
        ...state,
        confirmationsAccessForLocation: {
          ...state.confirmationsAccessForLocation,
          [action.payload.locationId]: {
            [action.payload.gasFlowDate]: {
              editAccess: false,
              loading: true,
              error: null,
            },
          },
        },
      };

    case EConfirmationsActions.FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION_SUCCESS:
      return {
        ...state,
        confirmationsAccessForLocation: {
          ...state.confirmationsAccessForLocation,
          [action.payload.locationId]: {
            [action.payload.gasFlowDate]: {
              editAccess: action.payload.editAccess,
              loading: false,
              error: null,
            },
          },
        },
      };
    case EConfirmationsActions.FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION_FAILURE:
      return {
        ...state,
        confirmationsAccessForLocation: {
          ...state.confirmationsAccessForLocation,
          [action.payload.locationId]: {
            [action.payload.gasFlowDate]: {
              editAccess: false,
              loading: false,
              error: action.payload.error,
            },
          },
        },
      };
    default:
      return state;
  }
}
