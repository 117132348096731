import {
  NonTariffRate,
  NonTariffRateCollection,
  NonTariffRateDetailCollection,
  NonTariffRateDetailGridCollection,
  RateLevelEnum,
  RatesDropdownDictionary,
  RevenueRecognitionCollection,
} from '@gms/rate-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { NonTariffRateForEdit } from 'app/store/rate-contract/rate-contract.models';
import { RetryableAction } from '../retry/retry.actions';

export enum ERateContractActions {
  ADD_NON_TARIFF_RATE = '[RATE_CONTRACT] add non tariff rate',
  ADD_NON_TARIFF_RATE_SUCCESS = '[RATE_CONTRACT] add non tariff rate success',
  ADD_NON_TARIFF_RATE_FAILURE = '[RATE_CONTRACT] add non tariff rate failure',
  UPDATE_NON_TARIFF_RATE = '[RATE_CONTRACT] update non tariff rate',
  UPDATE_NON_TARIFF_RATE_SUCCESS = '[RATE_CONTRACT] update non tariff rate success',
  UPDATE_NON_TARIFF_RATE_FAILURE = '[RATE_CONTRACT] update non tariff rate failure',
  DELETE_NON_TARIFF_RATE = '[RATE_CONTRACT] delete non tariff rate',
  DELETE_NON_TARIFF_RATE_SUCCESS = '[RATE_CONTRACT] delete non tariff rate success',
  DELETE_NON_TARIFF_RATE_FAILURE = '[RATE_CONTRACT] delete non tariff rate failure',
  FETCH_RATE_DICTIONARY = '[RATE_CONTRACT] fetch rate dictionary',
  FETCH_RATE_DICTIONARY_SUCCESS = '[RATE_CONTRACT] fetch rate dictionary success',
  FETCH_RATE_DICTIONARY_FAILURE = '[RATE_CONTRACT] fetch rate dictionary failure',
  FETCH_NON_TARIFF_RATES = '[RATE_CONTRACT] fetch non tariff rates',
  FETCH_NON_TARIFF_RATES_SUCESS = '[RATE_CONTRACT] fetch non tariff rates success',
  FETCH_NON_TARIFF_RATES_FAILURE = '[RATE_CONTRACT] fetch non tariff rates failure',
  FETCH_NON_TARIFF_RATES_DETAILS = '[RATE_CONTRACT] fetch non tariff rate details',
  FETCH_NON_TARIFF_RATES_DETAILS_SUCESS = '[RATE_CONTRACT] fetch non tariff rates details success',
  FETCH_NON_TARIFF_RATES_DETAILS_FAILURE = '[RATE_CONTRACT] fetch non tariff rates details failure',
  FETCH_NON_TARIFF_DETAILS_GRID = '[RATE_CONTRACT] fetch non tariff details grid',
  FETCH_NON_TARIFF_DETAILS_GRID_SUCCESS = '[RATE_CONTRACT] fetch non tariff details grid success',
  FETCH_NON_TARIFF_DETAILS_GRID_FAILURE = '[RATE_CONTRACT] fetch non tariff details grid failure',
  FETCH_NON_TARIFF_BY_ID = '[RATE_CONTRACT] fetch contractual rate by id',
  FETCH_NON_TARIFF_BY_ID_SUCCESS = '[RATE_CONTRACT] fetch contractual rate by id success',
  FETCH_NON_TARIFF_BY_ID_FAILURE = '[RATE_CONTRACT] fetch contractual rate by id failure',
  FETCH_NON_TARIFF_BY_ID_FOR_EDIT = '[RATE_CONTRACT] fetch contractual rate by id for edit',
  FETCH_NON_TARIFF_BY_ID_FOR_EDIT_SUCCESS = '[RATE_CONTRACT] fetch contractual rate by id for edit Sucess',
  FETCH_NON_TARIFF_BY_ID_FOR_EDIT_FAILURE = '[RATE_CONTRACT] fetch contractual rate by id for edit Failure',
  RESET_CONTRACTUAL_RATE = '[RATE_CONTRACT] reset contractual rate',
  CLEAR_EXPORT_CONTRACTUAL_RATES = '[RATE_CONTRACT] clear export contractual rates',
  FETCH_EXPORT_CONTRACTUAL_RATES = '[RATE_CONTRACT] fetch export contract',
  FETCH_EXPORT_CONTRACTUAL_RATES_SUCCESS = '[RATE_CONTRACT] fetch export contract success',
  FETCH_EXPORT_CONTRACTUAL_RATES_FAILURE = '[RATE_CONTRACT] fetch export contract failure',
  CLEAR_EXPORT_CONTRACTUAL_RATES_DETAILS_AND_SUMMARY = '[RATE_CONTRACT] clear export contractual rates details and summary',
  FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY = '[RATE_CONTRACT] fetch export contract rates summary',
  FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY_SUCCESS = '[RATE_CONTRACT] fetch export contract rate summary success',
  FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY_FAILURE = '[RATE_CONTRACT] fetch export contract rate summary failure',
  FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS = '[RATE_CONTRACT] fetch export contract rates details',
  FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS_SUCCESS = '[RATE_CONTRACT] fetch export contract rate details success',
  FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS_FAILURE = '[RATE_CONTRACT] fetch export contract rate details failure',
  FETCH_REVENUE_RECOGNITION_DICTIONARY = '[RATE_CONTRACT] fetch revenue recognition dictionary',
  FETCH_REVENUE_RECOGNITION_DICTIONARY_SUCCESS = '[RATE_CONTRACT] fetch revenue recognition success',
  FETCH_REVENUE_RECOGNITION_DICTIONARY_FAILURE = '[RATE_CONTRACT] fetch revenue recognition failure',
}

export interface IFetchContractualRatesPayload {
  pageSize: number;
  pageNumber: number;
  sortDescriptors?: Array<SortDescriptor>;
  tspId?: number;
  dateEffective?: string;
  dateExpire?: string;
  rateType?: Array<string>;
  rateId?: number;
  rateScheduleId?: Array<string>;
  rateTypeId?: Array<string>;
  serviceRequesterName?: Array<string>;
  contractId?: Array<number>;
  chargeTypeDesc?: Array<string>;
  rateLevel?: RateLevelEnum;
  recLoc?: Array<number>;
  recZn?: Array<number>;
  recLocGrp?: Array<number>;
  delLoc?: Array<number>;
  delZn?: Array<number>;
  delLocGrp?: Array<number>;
  priceType?: Array<string>;
  rate?: number;
  rateApplication?: Array<string>;
  manual?: Array<boolean>;
  exclInvTran?: Array<boolean>;
  chargePeriod?: Array<string>;
  revenueRecognition?: Array<string>;
  notes?: string;
  arrAcctTypeId?: Array<number>;
  arrIndexRateId?: Array<number>;
  userId?: Array<number>;
  accountId?: Array<number>;
}

export interface INonTariffByIdPayload {
  rateId: number;
  effectiveDate?: string;
  expireDate?: string;
}

export class AddNonTariffRate implements Action {
  public readonly type = ERateContractActions.ADD_NON_TARIFF_RATE;

  constructor(public payload: NonTariffRate) {}
}

export class AddNonTariffRateFailure implements Action {
  public readonly type = ERateContractActions.ADD_NON_TARIFF_RATE_FAILURE;

  constructor(public error: Error) {}
}

export class AddNonTariffRateSuccess implements Action {
  public readonly type = ERateContractActions.ADD_NON_TARIFF_RATE_SUCCESS;

  constructor(public payload: NonTariffRate) {}
}

export class UpdateNonTariffRate implements Action {
  public readonly type = ERateContractActions.UPDATE_NON_TARIFF_RATE;

  constructor(public payload: NonTariffRate, public rateId: number) {}
}

export class UpdateNonTariffRateSuccess implements Action {
  public readonly type = ERateContractActions.UPDATE_NON_TARIFF_RATE_SUCCESS;

  constructor(public payload: NonTariffRate) {}
}

export class UpdateNonTariffRateFailure implements Action {
  public readonly type = ERateContractActions.UPDATE_NON_TARIFF_RATE_FAILURE;

  constructor(public error: Error) {}
}

export class DeleteNonTariffRate implements Action {
  public readonly type = ERateContractActions.DELETE_NON_TARIFF_RATE;

  constructor(public payload: NonTariffRate) {}
}

export class DeleteNonTariffRateSuccess implements Action {
  public readonly type = ERateContractActions.DELETE_NON_TARIFF_RATE_SUCCESS;

  constructor(public payload: NonTariffRate) {}
}

export class DeleteNonTariffRateFailure implements Action {
  public readonly type = ERateContractActions.DELETE_NON_TARIFF_RATE_FAILURE;

  constructor(public error: Error) {}
}

export class FetchRateDictionary extends RetryableAction implements Action {
  public readonly type = ERateContractActions.FETCH_RATE_DICTIONARY;
}

export class FetchRateDictionarySuccess implements Action {
  public readonly type = ERateContractActions.FETCH_RATE_DICTIONARY_SUCCESS;

  constructor(public payload: RatesDropdownDictionary) {}
}

export class FetchRateDictionaryFailure implements Action {
  public readonly type = ERateContractActions.FETCH_RATE_DICTIONARY_FAILURE;

  constructor(public error: Error) {}
}

export class FetchNonTariffRates extends RetryableAction implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_RATES;

  constructor(public payload: IFetchContractualRatesPayload) {
    super();
  }
}

export class FetchNonTariffRatesSucess implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_RATES_SUCESS;

  constructor(
    public payload: {
      nonTariffRates: NonTariffRateCollection;
    }
  ) {}
}

export class FetchNonTariffRatesFailure implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_RATES_FAILURE;

  constructor(public error: Error) {}
}

export class FetchNonTariffRatesDetails extends RetryableAction implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_RATES_DETAILS;

  constructor(public payload: IFetchContractualRatesPayload) {
    super();
  }
}

export class FetchNonTariffRatesDetailsSucess implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_RATES_DETAILS_SUCESS;

  constructor(
    public payload: {
      nonTariffRatesDetails: NonTariffRateDetailCollection;
    }
  ) {}
}

export class FetchNonTariffRatesDetailsFailure implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_RATES_DETAILS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchNonTariffDetailsGrid extends RetryableAction implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_DETAILS_GRID;

  constructor(public payload: IFetchContractualRatesPayload) {
    super();
  }
}

export class FetchNonTariffDetailsGridSuccess implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_DETAILS_GRID_SUCCESS;

  constructor(
    public payload: {
      nonTariffDetailsGrid: NonTariffRateDetailGridCollection;
    }
  ) {}
}

export class FetchNonTariffDetailsGridFailure implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_DETAILS_GRID_FAILURE;

  constructor(public error: Error) {}
}

export class FetchNonTariffById extends RetryableAction implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_BY_ID;

  constructor(public payload?: INonTariffByIdPayload) {
    super();
  }
}

export class FetchNonTariffByIdSuccess implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_BY_ID_SUCCESS;

  constructor(public payload: NonTariffRate) {}
}

export class FetchNonTariffByIdFailure implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class FetchNonTariffByIdForEdit extends RetryableAction implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_BY_ID_FOR_EDIT;

  constructor(public payload?: INonTariffByIdPayload) {
    super();
  }
}

export class FetchNonTariffByIdForEditSuccess implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_BY_ID_FOR_EDIT_SUCCESS;

  constructor(public payload: NonTariffRateForEdit) {}
}

export class FetchNonTariffByIdForEditFailure implements Action {
  public readonly type = ERateContractActions.FETCH_NON_TARIFF_BY_ID_FOR_EDIT_FAILURE;

  constructor(public error: Error) {}
}

export class ResetContractualRate implements Action {
  public readonly type = ERateContractActions.RESET_CONTRACTUAL_RATE;
}

export class ClearExportContractualRates implements Action {
  public readonly type = ERateContractActions.CLEAR_EXPORT_CONTRACTUAL_RATES;
}

export class FetchExportContractualRates implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES;

  constructor(public payload: IFetchContractualRatesPayload) {}
}

export class FetchExportContractualRatesSuccess implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUCCESS;

  constructor(
    public payload: {
      exportContractualRates: NonTariffRateCollection;
    }
  ) {}
}

export class FetchExportContractualRatesFailure implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_FAILURE;

  constructor(public error: Error) {}
}

export class ClearExportContractualRatesDetailsAndSummary implements Action {
  public readonly type = ERateContractActions.CLEAR_EXPORT_CONTRACTUAL_RATES_DETAILS_AND_SUMMARY;
}

export class FetchExportContractualRatesSummary implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY;

  constructor(public payload: IFetchContractualRatesPayload) {}
}

export class FetchExportContractualRatesSummarySuccess implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY_SUCCESS;

  constructor(
    public payload: {
      exportContractualRatesSummary: NonTariffRateDetailCollection;
    }
  ) {}
}

export class FetchExportContractualRatesSummaryFailure implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY_FAILURE;

  constructor(public error: Error) {}
}

export class FetchExportContractualRatesDetails implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS;

  constructor(public payload: IFetchContractualRatesPayload) {}
}

export class FetchExportContractualRatesDetailsSuccess implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS_SUCCESS;

  constructor(
    public payload: {
      exportContractualRatesDetails: NonTariffRateDetailGridCollection;
    }
  ) {}
}

export class FetchExportContractualRatesDetailsFailure implements Action {
  public readonly type = ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchRevenueRecognitionDictionary implements Action {
  public readonly type = ERateContractActions.FETCH_REVENUE_RECOGNITION_DICTIONARY;
}

export class FetchRevenueRecognitionDictionarySuccess implements Action {
  public readonly type = ERateContractActions.FETCH_REVENUE_RECOGNITION_DICTIONARY_SUCCESS;

  constructor(public payload: RevenueRecognitionCollection) {}
}

export class FetchRevenueRecognitionDictionaryFailure implements Action {
  public readonly type = ERateContractActions.FETCH_REVENUE_RECOGNITION_DICTIONARY_FAILURE;

  constructor(public error: Error) {}
}

export type RateContractActions =
  | AddNonTariffRate
  | AddNonTariffRateFailure
  | AddNonTariffRateSuccess
  | DeleteNonTariffRate
  | DeleteNonTariffRateFailure
  | DeleteNonTariffRateSuccess
  | UpdateNonTariffRate
  | UpdateNonTariffRateSuccess
  | UpdateNonTariffRateFailure
  | FetchRateDictionary
  | FetchRateDictionarySuccess
  | FetchRateDictionaryFailure
  | FetchNonTariffRates
  | FetchNonTariffRatesSucess
  | FetchNonTariffRatesFailure
  | FetchNonTariffRatesDetails
  | FetchNonTariffRatesDetailsSucess
  | FetchNonTariffRatesDetailsFailure
  | FetchNonTariffDetailsGrid
  | FetchNonTariffDetailsGridSuccess
  | FetchNonTariffDetailsGridFailure
  | FetchNonTariffById
  | FetchNonTariffByIdSuccess
  | FetchNonTariffByIdFailure
  | FetchNonTariffByIdForEdit
  | FetchNonTariffByIdForEditSuccess
  | FetchNonTariffByIdForEditFailure
  | ResetContractualRate
  | ClearExportContractualRates
  | FetchExportContractualRates
  | FetchExportContractualRatesSuccess
  | FetchExportContractualRatesFailure
  | ClearExportContractualRatesDetailsAndSummary
  | FetchExportContractualRatesSummary
  | FetchExportContractualRatesSummarySuccess
  | FetchExportContractualRatesSummaryFailure
  | FetchExportContractualRatesDetails
  | FetchExportContractualRatesDetailsSuccess
  | FetchExportContractualRatesDetailsFailure
  | FetchRevenueRecognitionDictionary
  | FetchRevenueRecognitionDictionarySuccess
  | FetchRevenueRecognitionDictionaryFailure;
