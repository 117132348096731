import { Injectable } from '@angular/core';
import { PredefinedFootnoteService } from '@gms/servicerequest-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import {
  EPredefinedFootnotesActions,
  FetchPredefinedFootnoteError,
  FetchPredefinedFootnotes,
  FetchPredefinedFootnoteSuccess,
} from './predefined-footnotes.actions';

@Injectable({ providedIn: 'root' })
export class PredefinedFootnotesEffect {
  constructor(
    private actions$: Actions,
    private predefinedFootnoteService: PredefinedFootnoteService
  ) {}

  FetchPredefinedFootnotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchPredefinedFootnotes>(EPredefinedFootnotesActions.FETCH_PREDEFINED_FOOTNOTES),
      switchMap(body => {
        return this.predefinedFootnoteService.getPredefinedFootnote(body.tspId).pipe(
          mergeMap(predefinedFootnote => {
            return of(new FetchPredefinedFootnoteSuccess(predefinedFootnote));
          }),
          catchError(error => {
            return of(new FetchPredefinedFootnoteError(error));
          })
        );
      })
    )
  );
}
