export const isWebsocketV3 = (message: string): boolean => {
  let parsedMessage;

  try {
    parsedMessage = JSON.parse(message);
  } catch (e) {
    parsedMessage = message;
  }
  if (typeof parsedMessage === 'object') {
    const { data } = parsedMessage;
    return Boolean(data && `payloadV3` in data);
  }

  return false;
};

export const isValidJSON = (message: string): boolean => {
  try {
    JSON.parse(message);
    return true;
  } catch (e) {
    return false;
  }
};

export const checkChannelMatch = (subscribedAddress: string, receivedAddress: string) => {
  const CHANNEL_ADDRESS_SEGMENTS = 5;
  if (subscribedAddress === receivedAddress) {
    return true;
  } else {
    const subscriptionTokens = subscribedAddress.split('/');
    const receivedTokens = receivedAddress.split('/');
    return (
      subscriptionTokens.length === CHANNEL_ADDRESS_SEGMENTS &&
      receivedTokens.length === CHANNEL_ADDRESS_SEGMENTS &&
      subscriptionTokens[0] === receivedTokens[0] &&
      subscriptionTokens[1] === receivedTokens[1] &&
      subscriptionTokens[2] === receivedTokens[2] &&
      (subscriptionTokens[3] === receivedTokens[3] || subscriptionTokens[3] === 'ANY') &&
      (subscriptionTokens[4] === receivedTokens[4] || subscriptionTokens[4] === 'ANY')
    );
  }
};
