import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ContractHeaderCollection,
  ContractService,
  LookupCollections,
  LookupService,
  RateSchedule,
} from '@gms/contract-api';
import { StorageTransferContractsService } from '@gms/imbalance-api';
import { RateScheduleService } from '@gms/rateschedulev2-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { saveAs } from '@progress/kendo-file-saver';
import {
  FetchContractHeader,
  FetchContractHeaderError,
  FetchContractHeaderSuccess,
  FetchContractsHeaders,
  FetchContractsHeadersError,
  FetchContractsHeadersSuccess,
  FetchStorageTransferContractsHeaders,
  FetchStorageTransferContractsHeadersFailure,
  FetchStorageTransferContractsHeadersSuccess,
  GetContractLocationHeadersByIds,
  GetContractLocationHeadersByIdsError,
  GetContractLocationHeadersByIdsSuccess,
} from 'app/store/contracts/contracts.actions';
import { DetailRateScheduleContract, ISearchContracts } from 'app/store/contracts/contracts.model';
import { sanitizeAmendment } from 'app/store/contracts/contracts.utils';
import { get, uniqBy } from 'lodash/index';
import { Observable, of } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import {
  auditTime,
  catchError,
  debounceTime,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { DEBOUNCE_500 } from 'shared/consts/debounce.const';
import { dateUtils } from 'shared/utils/date.utils';
import { IAppState } from '../app/app.state';
import {
  AddContractOverview,
  AddContractOverviewFailure,
  AddContractOverviewSuccess,
  CreateAmendment,
  CreateAmendmentFailure,
  CreateAmendmentSuccess,
  EContractsActions,
  ExportContractsList,
  ExportContractsListFailure,
  ExportContractsListSuccess,
  ExportGrandfatheredSupplyLateral,
  ExportGrandfatheredSupplyLateralFailure,
  ExportGrandfatheredSupplyLateralSuccess,
  FetchAmendmentsByContractId,
  FetchAmendmentsByContractIdError,
  FetchAmendmentsByContractIdSuccess,
  FetchContractById,
  FetchContractByIdError,
  FetchContractByIdSuccess,
  FetchContractDocumentById,
  FetchContractDocumentByIdFailure,
  FetchContractDocumentByIdSuccess,
  FetchContractDocuments,
  FetchContractDocumentsFailure,
  FetchContractDocumentsSuccess,
  FetchContractEdiTransactionSetByContractId,
  FetchContractEdiTransactionSetByContractIdFailure,
  FetchContractEdiTransactionSetByContractIdSuccess,
  FetchContractEntitiesByContractId,
  FetchContractEntitiesByContractIdFailure,
  FetchContractEntitiesByContractIdSuccess,
  FetchContractEntitiesByRateScheduleIds,
  FetchContractEntitiesByRateScheduleIdsFailure,
  FetchContractEntitiesByRateScheduleIdsSuccess,
  FetchContractGeneralInfo,
  FetchContractGeneralInfoFailure,
  FetchContractGeneralInfoSuccess,
  FetchContractLocationPointPairQuantitiesByContractId,
  FetchContractLocationPointPairQuantitiesByContractIdFailure,
  FetchContractLocationPointPairQuantitiesByContractIdSuccess,
  FetchContractLocationPointPairQuantitiesChangesByContractId,
  FetchContractLocationPointPairQuantitiesChangesByContractIdFailure,
  FetchContractLocationPointPairQuantitiesChangesByContractIdSuccess,
  FetchContractLocationQuantitiesByContractId,
  FetchContractLocationQuantitiesByContractIdFailure,
  FetchContractLocationQuantitiesByContractIdSuccess,
  FetchContractLocationQuantitiesChangesByContractId,
  FetchContractLocationQuantitiesChangesByContractIdFailure,
  FetchContractLocationQuantitiesChangesByContractIdSuccess,
  FetchContractPathQuantitiesByContractId,
  FetchContractPathQuantitiesByContractIdFailure,
  FetchContractPathQuantitiesByContractIdSuccess,
  FetchContractQuantitiesByContractId,
  FetchContractQuantitiesByContractIdFailure,
  FetchContractQuantitiesByContractIdSuccess,
  FetchContractQuantitiesChangesByContractId,
  FetchContractQuantitiesChangesByContractIdFailure,
  FetchContractQuantitiesChangesByContractIdSuccess,
  FetchContractRateSchedules,
  FetchContractRateSchedulesFailure,
  FetchContractRateSchedulesSuccess,
  FetchContracts,
  FetchContractsByMergerAssignment,
  FetchContractsByMergerAssignmentFailure,
  FetchContractsByMergerAssignmentSuccess,
  FetchContractsError,
  FetchContractsList,
  FetchContractsListFailure,
  FetchContractsListSuccess,
  FetchContractsSuccess,
  FetchLookupCollections,
  FetchLookupCollectionsFailure,
  FetchLookupCollectionsSuccess,
  FetchSupplementalRestrictionsById,
  FetchSupplementalRestrictionsByIdFailure,
  FetchSupplementalRestrictionsByIdSuccess,
  SearchContracts,
  SearchContractsFailure,
  SearchContractsSuccess,
  UnlockContract,
  UnlockContractFailure,
  UnlockContractSuccess,
  UpdateContractEdiTransactionSet,
  UpdateContractEdiTransactionSetFailure,
  UpdateContractEdiTransactionSetSuccess,
  UpdateContractLocationPointPairQuantities,
  UpdateContractLocationPointPairQuantitiesFailure,
  UpdateContractLocationPointPairQuantitiesSuccess,
  UpdateContractLocationQuantities,
  UpdateContractLocationQuantitiesFailure,
  UpdateContractLocationQuantitiesSuccess,
  UpdateContractLocationQuantityChanges,
  UpdateContractLocationQuantityChangesFailure,
  UpdateContractLocationQuantityChangesSuccess,
  UpdateContractLocationQuantityPointPairChanges,
  UpdateContractLocationQuantityPointPairChangesFailure,
  UpdateContractLocationQuantityPointPairChangesSuccess,
  UpdateContractOverview,
  UpdateContractOverviewFailure,
  UpdateContractOverviewSuccess,
  UpdateContractPathQuantities,
  UpdateContractPathQuantitiesFailure,
  UpdateContractPathQuantitiesSuccess,
  UpdateContractQuantities,
  UpdateContractQuantitiesFailure,
  UpdateContractQuantitiesSuccess,
  UpdateContractQuantityChanges,
  UpdateContractQuantityChangesFailure,
  UpdateContractQuantityChangesSuccess,
  WorkflowInitiateKgen,
  WorkflowInitiateKgenFailure,
  WorkflowInitiateKgenSuccess,
} from './contracts.actions';
import { selectLookupCollections } from './contracts.selectors';
import { sortDefinitions } from '../default-sort.definitions';
import { defaultSortDescriptors } from 'app/modules/system-planning/pages/operational-capacity-reservation/operational-capacity-reservation.table';

@Injectable()
export class ContractsEffects {
  constructor(
    private _actions$: Actions,
    private _contractService: ContractService,
    private _rateScheduleService: RateScheduleService,
    private _storageTransferService: StorageTransferContractsService,
    private _store: Store<IAppState>,
    private _lookupService: LookupService
  ) {}

  UpdateContractPathQuantities: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractPathQuantities>(EContractsActions.UPDATE_CONTRACT_PATH_QUANTITIES),
      map((action: UpdateContractPathQuantities) => action.payload),
      switchMap(({ contractId, groupingType, contractPathQuantities }) =>
        this._contractService
          .putPathQuantities(contractPathQuantities, contractId, groupingType)
          .pipe(
            map(() => new UpdateContractPathQuantitiesSuccess()),
            catchError(error => of(new UpdateContractPathQuantitiesFailure({ error })))
          )
      )
    )
  );

  getContractLocationHeadersByIds: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetContractLocationHeadersByIds>(EContractsActions.FETCH_CONTRACT_LOCATION_HEADERS),
      map((action: GetContractLocationHeadersByIds) => action),
      debounceTime(500),
      switchMap(action => {
        // We want to be able to get all locations defined in the locationIds array independent of status.
        return this._contractService.postLocationsForContract(action.locationIds).pipe(
          map(
            response =>
              new GetContractLocationHeadersByIdsSuccess({
                contractLocationHeadersList: response.locationHeadersList,
              })
          ),
          catchError(error => of(new GetContractLocationHeadersByIdsError(error)))
        );
      })
    )
  );

  FetchContracts: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContracts>(EContractsActions.FETCH_CONTRACTS),
      map((action: FetchContracts) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        sortQuery = !!sortQuery? sortQuery : sortDefinitions.getContracts;
        return this._contractService
          .getContracts(
            payload.pageSize,
            payload.pageNumber,
            sortQuery,
            payload.contractStatus, // contractStatus
            payload.entityId, //entityId
            payload.searchPhrase, //searchPhrase
            payload.tspId,
            payload.contractNumber, //contractNumber
            payload.contractNumberExactMatch,
            null, //originId
            null, //contractBeginDate
            null, //createdAfter
            null, //createdBefore
            null, //rateScheduleId
            payload.rateScheduleIds, //list of rate schedule Ids, separate by comma
            null, //attributeId
            payload.contractIds,
            null, // serviceRequestId
            null, // previousContractNumber
            payload.searchPhraseReport
          )
          .pipe(
            map(
              contractCollection =>
                new FetchContractsSuccess({
                  contracts: contractCollection.contracts,
                  totalContractCount: contractCollection.total,
                })
            ),
            catchError(error => of(new FetchContractsError({ error: error })))
          );
      })
    )
  );

  FetchContractHeaders: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractsHeaders>(EContractsActions.FETCH_CONTRACTS_HEADERS),
      map((action: FetchContractsHeaders) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        sortQuery = !!sortQuery? sortQuery : sortDefinitions.getContractHeaders;
        return this._contractService
          .getContractHeaders(
            payload.pageSize,
            payload.pageNumber,
            sortQuery,
            payload.contractStatus,
            payload.entityId,
            payload.searchPhrase,
            payload.tspId,
            payload.contractNumber,
            payload.originId,
            payload.contractBeginDate,
            payload.beginDate,
            payload.endDate,
            payload.createdAfter,
            payload.createdBefore,
            payload.rateScheduleId,
            payload.rateScheduleIds, //list of rate schedule Ids, separate by comma
            payload.attributeId,
            payload.contractIds,
            payload.searchPhraseReport,
            payload.restrictedIncludes,
            payload.agencyResourceName,
            payload.agencyTransactionStartDate,
            payload.agencyTransactionEndDate,
            null,
            payload.statusMatchOrNonzeroQuantity,
            payload.onlyNominatable
          )
          .pipe(
            map(
              contractCollection =>
                new FetchContractsHeadersSuccess({
                  contractHeaders: contractCollection.contractHeaders,
                })
            ),
            catchError(error => of(new FetchContractsHeadersError(error)))
          );
      })
    )
  );

  FetchContractHeader: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractHeader>(EContractsActions.FETCH_CONTRACT_HEADER),
      map((action: FetchContractHeader) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        sortQuery = !!sortQuery? sortQuery : sortDefinitions.getContractHeaders;
        return this._contractService
          .getContractHeaders(
            payload.pageSize,
            payload.pageNumber,
            sortQuery,
            payload.contractStatus,
            payload.entityId,
            payload.searchPhrase,
            payload.tspId,
            payload.contractNumber,
            payload.originId,
            payload.contractBeginDate,
            payload.beginDate,
            payload.endDate,
            payload.createdAfter,
            payload.createdBefore,
            payload.rateScheduleId,
            payload.rateScheduleIds, //list of rate schedule Ids, separate by comma
            payload.attributeId,
            [payload.contractId],
            payload.searchPhraseReport,
            payload.restrictedIncludes,
            payload.agencyResourceName,
            payload.agencyTransactionStartDate,
            payload.agencyTransactionEndDate
          )
          .pipe(
            map(
              contractCollection =>
                new FetchContractHeaderSuccess({
                  contractHeader: get(contractCollection, 'contractHeaders[0]', null),
                })
            ),
            catchError(error => of(new FetchContractHeaderError(error)))
          );
      })
    )
  );

  FetchContractById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractById>(EContractsActions.FETCH_CONTRACT_BY_ID),
      map((action: FetchContractById) => action.payload),
      switchMap(payload =>
        forkJoin([
          of(payload),
          this._contractService
            .getContractById(payload.id, payload.gasDay)
            .pipe(catchError(error => of(error))),
        ])
      ),
      switchMap(data => {
        const [payload, contract] = data;
        return forkJoin([
          of(payload),
          of(contract),
          payload.getRateSchedule
            ? this._rateScheduleService
                .getDetailRateScheduleById(
                  contract.rateSchedule.detailRateScheduleId,
                  contract.tspId
                )
                .pipe(catchError(error => of(error)))
            : of({} as RateSchedule),
        ]);
      }),
      switchMap(data => {
        const [payload, contract, rateSchedule] = data;
        if (contract instanceof Error || contract instanceof HttpErrorResponse)
          return of(new FetchContractByIdError({ error: contract }));
        if (rateSchedule instanceof Error || rateSchedule instanceof HttpErrorResponse)
          return of(new FetchContractByIdError({ error: rateSchedule }));

        if (!payload.getRateSchedule) {
          return of(
            new FetchContractByIdSuccess({
              contract: contract,
            })
          );
        } else {
          const mappedContract: DetailRateScheduleContract = {
            ...contract,
            rateSchedule: {
              ...contract.rateSchedule,
              marketBasedRate: rateSchedule.masterRateScheduleConfig.marketBasedRateFlag,
            },
          };
          return of(
            new FetchContractByIdSuccess({
              contract: mappedContract,
            })
          );
        }
      })
    )
  );

  FetchAmendmentsByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAmendmentsByContractId>(EContractsActions.FETCH_AMENDMENTS_BY_CONTRACT_ID),
      map((action: FetchAmendmentsByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getContractAmendmentsById(
            payload.contractId,
            payload.pageSize,
            payload.pageNumber,
            payload.searchAmendmentSequence
          )
          .pipe(
            map(
              amendment =>
                new FetchAmendmentsByContractIdSuccess({
                  amendmentCollection: amendment,
                })
            ),
            catchError(error => of(new FetchAmendmentsByContractIdError({ error: error })))
          );
      })
    )
  );

  FetchContractLocationQuantitiesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractLocationQuantitiesByContractId>(
        EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID
      ),
      map((action: FetchContractLocationQuantitiesByContractId) => action.payload),
      switchMap(payload => {

        let sortBy =  !!payload.sortBy ? payload.sortBy: sortDefinitions.getContractLocationQnty
        return this._contractService
          .getContractLocationQnty(
            payload.contractId,
            payload.groupingType,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            null, // seasonalFiltering,
            payload.filterChildQuantities,
            payload.locationIds,
            payload.pageSize,
            payload.pageNumber,
            sortBy
          )
          .pipe(
            map(data => {
              return new FetchContractLocationQuantitiesByContractIdSuccess({
                contractId: data.contractId,
                locationQuantities: data.locationQuantities,
                size: data.size,
                page: data.page,
                total: data.total,
                sort: data.sort,
              });
            }),
            catchError(error =>
              of(new FetchContractLocationQuantitiesByContractIdFailure({ error: error }))
            )
          );
      })
    )
  );

  FetchContractLocationQuantitiesChangesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractLocationQuantitiesChangesByContractId>(
        EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID
      ),
      map((action: FetchContractLocationQuantitiesChangesByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getContractLocationQtyChanges(
            payload.contractId,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            null,
            payload.locationIds,
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy
          )
          .pipe(
            map(data => {
              return new FetchContractLocationQuantitiesChangesByContractIdSuccess({
                contractId: data.contractId,
                locationQuantities: data.locationQuantities,
                size: data.size,
                page: data.page,
                total: data.total,
                sort: data.sort,
              });
            }),
            catchError(error =>
              of(new FetchContractLocationQuantitiesChangesByContractIdFailure({ error: error }))
            )
          );
      })
    )
  );

  FetchContractLocationPointPairQuantitiesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractLocationPointPairQuantitiesByContractId>(
        EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID
      ),
      map((action: FetchContractLocationPointPairQuantitiesByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getLocationPointPairQty(
            payload.groupingType,
            payload.contractId,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            payload.locationIds,
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy,
            null, //ShowCycleDetails
            null, //isLatestCycle
            null, //seasonalFiltering
            'gzip'
          )
          .pipe(
            map(data => {
              return new FetchContractLocationPointPairQuantitiesByContractIdSuccess({
                contractId: data.contractId,
                locationQuantities: data.locationQuantities,
                size: data.size,
                page: data.page,
                total: data.total,
                sort: data.sort,
              });
            }),
            catchError(error =>
              of(new FetchContractLocationPointPairQuantitiesByContractIdFailure({ error: error }))
            )
          );
      })
    )
  );

  FetchContractLocationPointPairQuantitiesChangesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractLocationPointPairQuantitiesChangesByContractId>(
        EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID
      ),
      map((action: FetchContractLocationPointPairQuantitiesChangesByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getLocationPointPairQtyChanges(
            payload.contractId,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            payload.locationIds,
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy
          )
          .pipe(
            map(data => {
              return new FetchContractLocationPointPairQuantitiesChangesByContractIdSuccess({
                contractId: data.contractId,
                locationQuantities: data.locationQuantities,
                size: data.size,
                page: data.page,
                total: data.total,
                sort: data.sort,
              });
            }),
            catchError(error =>
              of(
                new FetchContractLocationPointPairQuantitiesChangesByContractIdFailure({
                  error: error,
                })
              )
            )
          );
      })
    )
  );

  FetchContractQuantitiesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractQuantitiesByContractId>(
        EContractsActions.FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID
      ),
      map((action: FetchContractQuantitiesByContractId) => action.payload),
      auditTime(DEBOUNCE_500),
      switchMap(payload => {
        return this._contractService
          .getContractQuantities(
            payload.contractId,
            payload.groupingType,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            null, // seasonalFiltering
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy
          )
          .pipe(
            map(data => {
              return new FetchContractQuantitiesByContractIdSuccess({
                contractId: data.contractId,
                contractQuantities: data.contractQuantities,
                size: data.size,
                page: data.page,
                total: data.total,
                sort: data.sort,
              });
            }),
            catchError(error =>
              of(new FetchContractQuantitiesByContractIdFailure({ error: error }))
            )
          );
      })
    )
  );

  FetchContractPathQuantitiesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractPathQuantitiesByContractId>(
        EContractsActions.FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID
      ),
      map((action: FetchContractPathQuantitiesByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getContractPathQty(
            payload.contractId,
            payload.groupingType,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            null, //seasonalFiltering
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy,
            null, // showCycleDetails
            payload.isRedundantPath,
            null, // isOriginalNets
            null, // stitchPaths
            payload.capBlockIds
          )
          .pipe(
            map(data => {
              return new FetchContractPathQuantitiesByContractIdSuccess({
                contractId: data.contractId,
                pathQuantities: data.pathQuantities,
                size: data.size,
                page: data.page,
                total: data.total,
                sort: data.sort,
                capBlocks: data.capBlocks,
              });
            }),
            catchError(error =>
              of(new FetchContractPathQuantitiesByContractIdFailure({ error: error }))
            )
          );
      })
    )
  );

  FetchContractEdiTransactionSetByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractEdiTransactionSetByContractId>(
        EContractsActions.FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID
      ),
      map((action: FetchContractEdiTransactionSetByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getEdiTransactionSetsById(
            payload.contractId,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy
          )
          .pipe(
            map(data => {
              return new FetchContractEdiTransactionSetByContractIdSuccess({
                contractId: data.contractId,
                contractEdiTransactionSets: data.contractEdiTransactionSets,
                size: data.size,
                page: data.page,
                total: data.total,
              });
            }),
            catchError(error =>
              of(new FetchContractEdiTransactionSetByContractIdFailure({ error: error }))
            )
          );
      })
    )
  );
//TODO: needs clarification
  SearchContracts: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SearchContracts>(EContractsActions.SEARCH_CONTRACTS),
      map((action: SearchContracts) => action.payload),
      switchMap((payload: ISearchContracts) => {
        // TODO: Re-enable with TD https://boardwalkpp.atlassian.net/browse/GMS-8614
        let sortby = sortDefinitions.getContracts;
        return this._contractService
          .getContracts(
            null, //pageSize
            null, //pageNumber
            sortby, //sortBy
            payload.contractStatus as Array<
              'Active' | 'Executed' | 'Inactive' | 'Pending' | 'Terminated' | 'Void'
            >,
            payload.entityId,
            payload.searchPhrase,
            payload.tspId,
            payload.contractNumber,
            payload.contractNumberExactMatch,
            payload.originId,
            payload.contractBeginDate,
            payload.createdAfter,
            payload.createdBefore,
            payload.rateScheduleId,
            payload.rateScheduleIds,
            payload.attributeId,
            payload.contractIds,
            payload.serviceRequestId,
            payload.previousContractNumber,
            payload.releasingContractId,
            payload.searchPhraseReport,
            payload.restrictedIncludes,
            payload.includes,
            payload.dateCurrentTermEnd,
            payload.includeMdq,
            payload.gasDay
          )
          .pipe(
            map(
              contractCollection =>
                new SearchContractsSuccess({ contracts: contractCollection.contracts })
            ),
            catchError(error => {
              return of(new SearchContractsFailure({ error: error }));
            })
          );
      })
    )
  );

  FetchContractsList$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractsList>(EContractsActions.FETCH_CONTRACTS_LIST),
      map((action: FetchContractsList) => action.payload),
      switchMap(
        ({
          pageSize,
          pageNumber,
          sortDescriptors,
          status,
          serviceRequester,
          searchPhrase,
          tspId,
          serviceRequestContract,
          origin,
          contractBeginDate,
          rateSchedule,
          attributeId,
          rateScheduleIds,
          includeMdq,
          contractIds,
          serviceRequestId,
          previousContractNumber,
          releasingContractId,
          searchPhraseReport,
          restrictedIncludes,
          includes,
          dateCurrentTermEnd,
        }) => {
          let sortQuery = ``;
          if (sortDescriptors) {
            sortDescriptors.forEach(sortDescriptor => {
              sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
            });
          }

          let contractStatus = [``];
          if (status) {
            contractStatus = [status.name];
          }

          sortQuery = !!sortQuery ? sortQuery : sortDefinitions.getContracts;

          return this._contractService
            .getContracts(
              pageSize,
              pageNumber,
              sortQuery,
              contractStatus,
              serviceRequester && serviceRequester.entityId ? serviceRequester.entityId : null,
              searchPhrase,
              tspId,
              serviceRequestContract || undefined,
              null, //contractNumberExactMatch
              origin && origin.originId ? origin.originId : null,
              contractBeginDate,
              null, //createdAfter
              null, //createdBefore
              rateSchedule && rateSchedule.detailRateScheduleId
                ? rateSchedule.detailRateScheduleId
                : null,
              rateScheduleIds || null, //rateScheduleIds
              attributeId || null,
              contractIds,
              serviceRequestId,
              previousContractNumber,
              releasingContractId,
              searchPhraseReport,
              restrictedIncludes,
              includes,
              dateCurrentTermEnd,
              includeMdq
            )
            .pipe(
              map(
                contractCollection =>
                  new FetchContractsListSuccess({
                    contractsList: contractCollection.contracts,
                    total: contractCollection.total,
                  })
              ),
              catchError(error => of(new FetchContractsListFailure({ error: error })))
            );
        }
      )
    )
  );

  getLookupCollections$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchLookupCollections>(EContractsActions.FETCH_LOOKUP_COLLECTIONS),
      withLatestFrom(this._store.pipe(select(selectLookupCollections))),
      switchMap(([_, lookupCollectionsState]: [FetchLookupCollections, LookupCollections]) => {
        if (lookupCollectionsState) {
          return of(
            new FetchLookupCollectionsSuccess({
              useCache: true,
            })
          );
        }

        return this._lookupService.getLookup().pipe(
          switchMap((lookupCollections: LookupCollections) =>
            of(
              new FetchLookupCollectionsSuccess({
                lookupCollections,
              })
            )
          ),
          catchError(error => of(new FetchLookupCollectionsFailure({ error })))
        );
      })
    )
  );

  FetchContractRateSchedules$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractRateSchedules>(EContractsActions.FETCH_CONTRACT_RATE_SCHEDULES),
      map((action: FetchContractRateSchedules) => action.payload),
      switchMap(payload => {
        return this._contractService.getContractRateSchedulesById(payload.contractId).pipe(
          map(
            contractRateSchedules =>
              new FetchContractRateSchedulesSuccess({
                contractRateSchedules: contractRateSchedules.rateSchedules,
              })
          ),
          catchError(error => of(new FetchContractRateSchedulesFailure({ error: error })))
        );
      })
    )
  );

  FetchContractEntities: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractEntitiesByRateScheduleIds>(
        EContractsActions.FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS
      ),
      map((action: FetchContractEntitiesByRateScheduleIds) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }

        sortQuery = !!sortQuery? sortQuery : sortDefinitions.getContracts;
        return this._contractService
          .getContracts(
            payload.pageSize,
            payload.pageNumber,
            sortQuery,
            payload.contractStatus, // contractStatus
            payload.entityId, //entityId
            null, //searchPhrase
            payload.tspId,
            null, //contractNumber
            null, //contractNumberExactMatch
            null, //originId
            null, //contractBeginDate
            null, //createdAfter
            null, //createdBefore
            null, //rateScheduleId
            payload.rateScheduleIds, //list of rate schedule Ids, separate by comma
            null, //attributeId
            payload.contractIds
          )
          .pipe(
            map(
              contractCollection =>
                new FetchContractEntitiesByRateScheduleIdsSuccess({
                  entities: uniqBy(
                    contractCollection.contracts.map(contract => contract.entity),
                    'entityId'
                  ),
                })
            ),
            catchError(error =>
              of(new FetchContractEntitiesByRateScheduleIdsFailure({ error: error }))
            )
          );
      })
    )
  );

  UpdateContractQuantities$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractQuantities>(EContractsActions.UPDATE_CONTRACT_QUANTITIES),
      map((action: UpdateContractQuantities) => action.payload),
      switchMap(({ contractId, groupingType, contractQuantities }) =>
        this._contractService
          .putContractQuantities(contractQuantities, contractId, groupingType)
          .pipe(
            map(() => new UpdateContractQuantitiesSuccess()),
            catchError(error => of(new UpdateContractQuantitiesFailure({ error })))
          )
      )
    )
  );

  UpdateContractLocationQuantities$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractLocationQuantities>(
        EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES
      ),
      map((action: UpdateContractLocationQuantities) => action.payload),
      switchMap(({ contractId, groupingType, contractLocationQuantities, isMaxMdq }) =>
        this._contractService
          .putContractLocationQty(contractLocationQuantities, contractId, groupingType, isMaxMdq)
          .pipe(
            map(() => new UpdateContractLocationQuantitiesSuccess()),
            catchError(error => of(new UpdateContractLocationQuantitiesFailure({ error })))
          )
      )
    )
  );

  UpdateContractOverview$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractOverview>(EContractsActions.UPDATE_CONTRACT_OVERVIEW),
      map((action: UpdateContractOverview) => action.payload),
      switchMap(({ body, contractId }) =>
        this._contractService.updateContract(body, contractId, 'response', true).pipe(
          map(() => new UpdateContractOverviewSuccess()),
          catchError(error => of(new UpdateContractOverviewFailure({ error })))
        )
      )
    )
  );

  UpdateContractEdiTransactionSet$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractEdiTransactionSet>(
        EContractsActions.UPDATE_CONTRACT_EDI_TRANSACTION_SET
      ),
      map((action: UpdateContractEdiTransactionSet) => action.payload),
      switchMap(({ contractId, contractEdiTransactionSets }) =>
        this._contractService.putEdiTransactionSets(contractEdiTransactionSets, contractId).pipe(
          map(() => new UpdateContractEdiTransactionSetSuccess()),
          catchError(error => of(new UpdateContractEdiTransactionSetFailure({ error })))
        )
      )
    )
  );

  UpdateContractLocationPointPairQuantities$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractLocationPointPairQuantities>(
        EContractsActions.UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES
      ),
      map((action: UpdateContractLocationPointPairQuantities) => action.payload),
      switchMap(({ contractId, groupingType, contractLocationPointPairQuantities }) =>
        this._contractService
          .putLocationQtyPointPair(contractLocationPointPairQuantities, contractId, groupingType)
          .pipe(
            map(() => new UpdateContractLocationPointPairQuantitiesSuccess()),
            catchError(error => of(new UpdateContractLocationPointPairQuantitiesFailure({ error })))
          )
      )
    )
  );

  FetchContactGeneralInfo$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractGeneralInfo>(EContractsActions.FETCH_CONTRACT_GENERAL_INFO),
      map((action: FetchContractGeneralInfo) => action.payload),
      switchMap(payload => {
        const {
          pageSize,
          pageNumber,
          sortBy,
          contractStatus,
          entityId,
          searchPhrase,
          tspId,
          contractNumber,
          originId,
          contractBeginDate,
          rateScheduleId,
          locationIds,
          attributeIds,
          contractIds,
          entityIds,
        } = payload;

        return this._contractService
          .getContractsGeneralInfo(
            pageSize,
            pageNumber,
            sortBy,
            contractStatus,
            entityId,
            searchPhrase,
            tspId,
            contractNumber,
            originId,
            contractBeginDate,
            rateScheduleId,
            locationIds,
            attributeIds,
            contractIds,
            entityIds
          )
          .pipe(
            map(collection => new FetchContractGeneralInfoSuccess(collection)),
            catchError(error => of(new FetchContractGeneralInfoFailure({ error })))
          );
      })
    )
  );

  AddContractOverview$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddContractOverview>(EContractsActions.ADD_CONTRACT_OVERVIEW),
      debounceTime(500),
      map((action: AddContractOverview) => action),
      switchMap(action =>
        this._contractService.postContract(action.payload).pipe(
          map(contract => new AddContractOverviewSuccess(contract)),
          catchError(error => of(new AddContractOverviewFailure({ error })))
        )
      )
    )
  );

  unlockContract$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UnlockContract>(EContractsActions.UNLOCK_CONTRACT),
      map((action: UnlockContract) => action.contractId),
      switchMap(payload => {
        return this._contractService.postUnlockContract(payload).pipe(
          map(() => new UnlockContractSuccess()),
          catchError(error => of(new UnlockContractFailure({ error: error })))
        );
      })
    )
  );

  FetchContractSupplementalRestrictionsByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchSupplementalRestrictionsById>(
        EContractsActions.FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID
      ),
      map((action: FetchSupplementalRestrictionsById) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getSupplementalRestrictions(
            payload.contractId,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy
          )
          .pipe(
            map(
              data =>
                new FetchSupplementalRestrictionsByIdSuccess({
                  contractId: data.contractId,
                  supplementalRestrictions: data.supplementalRestrictions,
                  size: data.size,
                  page: data.page,
                  total: data.total,
                  sort: data.sort,
                })
            ),
            catchError(error => of(new FetchSupplementalRestrictionsByIdFailure({ error: error })))
          );
      })
    )
  );

  FetchContractsByMergerAssignment: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractsByMergerAssignment>(
        EContractsActions.FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT
      ),
      map((action: FetchContractsByMergerAssignment) => action.payload),
      switchMap(payload => {
        return this._contractService.getMergerAssignmentById(payload).pipe(
          map(collection => new FetchContractsByMergerAssignmentSuccess(collection)),
          catchError(error => of(new FetchContractsByMergerAssignmentFailure(error)))
        );
      })
    )
  );

  ExportContractsList$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportContractsList>(EContractsActions.EXPORT_CONTRACT_LIST),
      map((action: ExportContractsList) => action.payload),
      switchMap(payload => {
        const {
          entityId,
          contractStatus,
          tspId,
          contractNumber,
          originId,
          contractBeginDate,
          rateScheduleIds,
          todaysDate,
        } = payload;

        const saveData = (data: string) => {
          const blob = new Blob([data], { type: 'text/csv' });
          saveAs(blob, `ContractListExport-${dateUtils.getDateAsYYYY_MM_DD(todaysDate)}.csv`);
        };

        return this._contractService
          .getContractsExport(
            entityId,
            contractStatus,
            tspId,
            contractNumber,
            originId,
            contractBeginDate,
            rateScheduleIds
          )
          .pipe(
            map(response => {
              saveData(response);
              return new ExportContractsListSuccess();
            }),
            catchError(error => of(new ExportContractsListFailure(error)))
          );
      })
    )
  );

  CreateAmendment$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateAmendment>(EContractsActions.CREATE_AMENDMENT),
      map((action: CreateAmendment) => {
        sanitizeAmendment(action.payload.amendment);
        return {
          amendment: action.payload.amendment,
          contractId: action.payload.contractId,
        };
      }),
      switchMap(payload => {
        return this._contractService
          .postContractAmendment(payload.amendment, payload.contractId)
          .pipe(
            map(amendment => new CreateAmendmentSuccess(amendment)),
            catchError(error => of(new CreateAmendmentFailure(error)))
          );
      })
    )
  );

  FetchContractQuantityChangesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractQuantitiesChangesByContractId>(
        EContractsActions.FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID
      ),
      map((action: FetchContractQuantitiesChangesByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getQuantityChanges(
            payload.contractId,
            payload.activePeriodStart,
            payload.activePeriodEnd,
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy
          )
          .pipe(
            map(results => new FetchContractQuantitiesChangesByContractIdSuccess(results)),
            catchError(error => of(new FetchContractQuantitiesChangesByContractIdFailure(error)))
          );
      })
    )
  );

  WorkflowInitiateKgen$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<WorkflowInitiateKgen>(EContractsActions.WORKFLOW_INITIATE_KGEN),
      map((action: WorkflowInitiateKgen) => action.payload),
      switchMap(payload => {
        return this._contractService.workflowInitiateKgen(payload).pipe(
          map(() => new WorkflowInitiateKgenSuccess()),
          catchError(error => of(new WorkflowInitiateKgenFailure({ error })))
        );
      })
    )
  );

  FetchContractDocuments: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractDocuments>(EContractsActions.FETCH_CONTRACT_DOCUMENTS),
      map((action: FetchContractDocuments) => action.payload),
      switchMap(payload => {
        return this._contractService.getContractDocuments(payload.contractId).pipe(
          map(results => new FetchContractDocumentsSuccess(results)),
          catchError(error => of(new FetchContractDocumentsFailure({ error })))
        );
      })
    )
  );

  FetchContractDocumentById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractDocumentById>(EContractsActions.FETCH_CONTRACT_DOCUMENT_BY_ID),
      map((action: FetchContractDocumentById) => action.payload),
      switchMap(payload => {
        return this._contractService
          .getContractDocumentById(payload.contractId, payload.documentId)
          .pipe(
            map(result => new FetchContractDocumentByIdSuccess(result)),
            catchError(error => of(new FetchContractDocumentByIdFailure({ error })))
          );
      })
    )
  );

  UpdateContractQuantityChanges: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractQuantityChanges>(EContractsActions.UPDATE_CONTRACT_QUANTITY_CHANGES),
      map((action: UpdateContractQuantityChanges) => action.payload),
      switchMap(payload => {
        return this._contractService
          .putContractQuantityChanges(payload.quantityChanges, payload.contractId)
          .pipe(
            map(result => new UpdateContractQuantityChangesSuccess(result)),
            catchError(error => of(new UpdateContractQuantityChangesFailure({ error })))
          );
      })
    )
  );

  UpdateContractLocationQuantityChanges: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractLocationQuantityChanges>(
        EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES
      ),
      map((action: UpdateContractLocationQuantityChanges) => action.payload),
      switchMap(payload => {
        return this._contractService
          .putContractLocationQtyChanges(
            payload.contractLocationQuantityChanges,
            payload.contractId
          )
          .pipe(
            map(result => new UpdateContractLocationQuantityChangesSuccess(result)),
            catchError(error => of(new UpdateContractLocationQuantityChangesFailure({ error })))
          );
      })
    )
  );

  UpdateContractLocationQuantityPointPairChanges: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateContractLocationQuantityPointPairChanges>(
        EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES
      ),
      map((action: UpdateContractLocationQuantityPointPairChanges) => action.payload),
      switchMap(payload => {
        return this._contractService
          .putLocationPointPairQtyChanges(
            payload.contractLocationQuantityPointPairChanges,
            payload.contractId
          )
          .pipe(
            map(result => new UpdateContractLocationQuantityPointPairChangesSuccess(result)),
            catchError(error =>
              of(new UpdateContractLocationQuantityPointPairChangesFailure({ error }))
            )
          );
      })
    )
  );

  ExportGrandfatheredSupplyLateral$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportGrandfatheredSupplyLateral>(
        EContractsActions.EXPORT_GRANDFATHERED_SUPPLY_LATERAL
      ),
      map((action: ExportGrandfatheredSupplyLateral) => action.payload),
      switchMap(payload => {
        const saveData = (data: string) => {
          const blob = new Blob([data], { type: 'text/csv' });
          saveAs(blob, `GrandfatheredSupplyLateral-${payload.supplyLateralLineName}.csv`);
        };

        return this._contractService
          .getGfContractsExport('csv', payload.supplyLateralLineName as
            | 'All'
            | 'East'
            | 'North Louisiana'
            | 'South'
            | 'Southeast'
            | 'Southwest'
            | 'West')
          .pipe(
            map(response => {
              saveData(response);
              return new ExportGrandfatheredSupplyLateralSuccess();
            }),
            catchError(error => of(new ExportGrandfatheredSupplyLateralFailure(error)))
          );
      })
    )
  );

  FetchContractEntitiesByContractId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchContractEntitiesByContractId>(
        EContractsActions.FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID
      ),
      map((action: FetchContractEntitiesByContractId) => action.payload),
      switchMap(payload => {
        return this._contractService.getContractEntities(payload.contractId).pipe(
          map(data => new FetchContractEntitiesByContractIdSuccess(data)),
          catchError(error => of(new FetchContractEntitiesByContractIdFailure({ error })))
        );
      })
    )
  );

  FetchStorageTransferContractsHeaders$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchStorageTransferContractsHeaders>(
        EContractsActions.FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS
      ),
      map((action: FetchStorageTransferContractsHeaders) => action.payload),
      switchMap((payload: { accountingPeriod: string; tspId: number; entityId: number, includeAgentEntities: boolean }) => {
        const { accountingPeriod, tspId, entityId, includeAgentEntities } = payload;

        return this._storageTransferService
        .getStorageTransferContracts(accountingPeriod, tspId, entityId, includeAgentEntities)
          .pipe(
            map(
              (contractCollection: ContractHeaderCollection) =>
                new FetchStorageTransferContractsHeadersSuccess({
                  contractHeaders: contractCollection.contractHeaders,
                })
            ),
            catchError(error => of(new FetchStorageTransferContractsHeadersFailure(error)))
          );
      })
    )
  );
}
