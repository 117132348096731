import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import { EServiceRequestActions, ServiceRequestActions } from './service-requests.actions';
import { initialServiceRequestsState, IServiceRequestState } from './service-requests.state';

//need to use the function format for AOT
export function serviceRequestsReducers(
  state = initialServiceRequestsState,
  action: ServiceRequestActions
): IServiceRequestState {
  switch (action.type) {
    case EServiceRequestActions.GetServiceRequestsSuccess:
      return {
        ...state,
        serviceRequests: action.payload.serviceRequests,
        totalServiceRequestsCount: action.payload.totalServiceRequestsCount,
        loading: false,
        error: false,
      };

    case EServiceRequestActions.ClearServiceRequests:
      return {
        ...state,
        serviceRequests: [],
        totalServiceRequestsCount: 0,
        loading: false,
        error: false,
      };

    case EServiceRequestActions.GetServiceRequestsError:
      return {
        ...state,
        editingServiceRequest: action.error,
        error: true,
        loading: false,
      };
    case EServiceRequestActions.GetServiceRequestById:
      return {
        ...state,
        loading: true,
      };
    case EServiceRequestActions.SetSortOrder:
      return {
        ...state,
        sort: action.payload,
        loading: true,
      };
    case EServiceRequestActions.GetServiceRequests:
      return {
        ...state,
        sort: action.payload.sortDescriptors,
        loading: true,
      };
    case EServiceRequestActions.RESET_CLIENT_SIDE_SERVICE_REQUEST:
      return {
        ...state,
        serviceRequest: action.resetTspId ? { tspId: state.serviceRequest.tspId } : {},
      };
    case EServiceRequestActions.RESET_CLIENT_SIDE_EDITING_SERVICE_REQUEST:
      return {
        ...state,
        editingServiceRequest: {
          requestContacts: [],
          routes: [],
        },
      };
    case EServiceRequestActions.UPDATE_CLIENT_SIDE_SERVICE_REQUEST:
      return {
        ...state,
        serviceRequest: { ...state.serviceRequest, ...action.payload },
      };
    case EServiceRequestActions.UpdateEditingServiceRequest:
      return {
        ...state,
        editingServiceRequest: action.payload,
        error: false,
        loading: false,
        serviceRequest: action.payload,
      };
    case EServiceRequestActions.SaveServiceRequest:
      return {
        ...state,
        loading: true,
      };
    case EServiceRequestActions.SaveServiceRequestSuccess: {
      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: EServiceRequestActions.SaveServiceRequestSuccess,
        editingServiceRequest: action.payload,
        serviceRequest: action.payload,
      };
    }
    case EServiceRequestActions.SaveServiceRequestError: {
      return {
        ...state,
        loading: false,
        error: true,
        networkResultStatus: EServiceRequestActions.SaveServiceRequestError,
        editingServiceRequest: action.error,
      };
    }
    case EServiceRequestActions.UpdateServiceRequest: {
      return {
        ...state,
        loading: true,
      };
    }
    case EServiceRequestActions.UpdateServiceRequestSuccess: {
      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: EServiceRequestActions.UpdateServiceRequestSuccess,
        editingServiceRequest: action.payload,
        serviceRequest: action.payload,
      };
    }
    case EServiceRequestActions.UpdateServiceRequestValidationError: {
      return {
        ...state,
        editingServiceRequest: action.error,
        loading: false,
        error: true,
        networkResultStatus: EServiceRequestActions.UpdateServiceRequestValidationError,
      };
    }
    case EServiceRequestActions.UpdateServiceRequestServerError: {
      return {
        ...state,
        editingServiceRequest: action.error,
        loading: false,
        error: true,
        networkResultStatus: EServiceRequestActions.UpdateServiceRequestServerError,
      };
    }
    case EServiceRequestActions.DeleteServiceRequest: {
      return {
        ...state,
        loading: true,
      };
    }
    case EServiceRequestActions.DeleteServiceRequestSuccess: {
      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: EServiceRequestActions.DeleteServiceRequestSuccess,
      };
    }
    case EServiceRequestActions.DeleteServiceRequestError: {
      return {
        ...state,
        loading: false,
        error: true,
        networkResultStatus: EServiceRequestActions.DeleteServiceRequestError,
      };
    }
    case EServiceRequestActions.AcknowledgeNetworkResult: {
      return {
        ...state,
        networkResultStatus: null,
      };
    }
    case EServiceRequestActions.GetRequestStatuses: {
      return {
        ...state,
        requestStatuses: createLoadingState(state.requestStatuses),
      };
    }
    case EServiceRequestActions.GetRequestStatusesSuccess: {
      return {
        ...state,
        requestStatuses: createSuccessState(action.payload),
      };
    }
    case EServiceRequestActions.GetRequestStatusesFailure: {
      return {
        ...state,
        requestStatuses: createErrorState(action.error),
      };
    }
    default:
      return state;
  }
}
