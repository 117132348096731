<div
  class="form-datepicker-container"
  [class.small]="size === 'Small'"
  [class.secondary-display-type]="displayType === 'Secondary'"
>
  <label *ngIf="label" class="global-label-text form-datepicker-label">{{ label }}</label>
  <gms-inline-error-message [control]="control" [isInATable]="isInATable">
    <kendo-datepicker
      [class]="'form-datepicker ' + customClass"
      [class.isInActionBar]="isInActionBar"
      [bottomView]="bottomView"
      [disabled]="disabled"
      [id]="id"
      [formControl]="control"
      [placeholder]="placeholder"
      [min]="min"
      [max]="max"
      [format]="format"
      [formatPlaceholder]="formatPlaceholder"
      [focusedDate]="focusedDate"
      [disabledDates]="disabledDates"
      [rangeValidation]="rangeValidation"
      [readOnlyInput]="readOnlyInput"
      (close)="close($event)"
      (open)="open($event)"
      (blur)="blur($event)"
      (focus)="onFocus($event)"
      (valueChange)="_valueChanged($event)"
    ></kendo-datepicker>
  </gms-inline-error-message>
  <div
    *ngIf="control && control.invalid && control.touched && !isFocused && errorMessage"
    class="global-error-text"
  >
    {{ errorMessage }}
  </div>
  <div
    *ngIf="
      control &&
      control.invalid &&
      control.touched &&
      control.errors.required &&
      !isFocused &&
      !errorMessage
    "
    class="global-error-text"
  >
    Required Field
  </div>
  <div
    *ngIf="
      control &&
      control.invalid &&
      control.touched &&
      control.errors.maxError &&
      !isFocused &&
      !errorMessage &&
      maxErrorMessage
    "
    class="global-error-text"
  >
    {{ maxErrorMessage }}
  </div>

  <div
    *ngIf="
      control &&
      control.invalid &&
      control.touched &&
      control.errors.minError &&
      !isFocused &&
      !errorMessage &&
      !control.errors.maxError &&
      minErrorMessage
    "
    class="global-error-text"
  >
    {{ minErrorMessage }}
  </div>
</div>
