import { ENotesActions, NotesActions } from './notes.actions';
import { initialNotesState, INotesState } from './notes.state';

export function notesReducers(state = initialNotesState, action: NotesActions): INotesState {
  switch (action.type) {
    case ENotesActions.ADD_NOTE:
      return {
        ...state,
        loading: true,
      };
    case ENotesActions.ADD_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: ENotesActions.ADD_NOTE_SUCCESS,
      };
    case ENotesActions.ADD_NOTE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        networkResultStatus: ENotesActions.ADD_NOTE_ERROR,
      };
    case ENotesActions.FETCH_NOTES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ENotesActions.FETCH_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.notes,
        loading: false,
        error: false,
        networkResultStatus: ENotesActions.FETCH_NOTES_SUCCESS,
      };
    case ENotesActions.FETCH_NOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        networkResultStatus: ENotesActions.FETCH_NOTES_ERROR,
      };
    case ENotesActions.DELETE_NOTE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ENotesActions.DELETE_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: ENotesActions.DELETE_NOTE_SUCCESS,
      };
    case ENotesActions.DELETE_NOTE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        networkResultStatus: ENotesActions.DELETE_NOTE_ERROR,
      };
    default:
      return state;
  }
}
