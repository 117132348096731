import { AccountAllDetail, AccountAllDetailCollection, AccountDetail } from '@gms/billing-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IRequestStatus } from '../app/app.models';

export interface IOperationalAccountsState {
  pageSize: number;
  pageNumber: number;
  sortDescriptors: SortDescriptor[];
  accountsCollection: AccountAllDetailCollection;
  getAccountsCollection: IRequestStatus<any>;
  addedAccount: AccountDetail;
  addedAccountStatus: IRequestStatus<any>;
  account: AccountAllDetail;
  getAccount: IRequestStatus<any>;
}

export const initialOperationalAccountsState = {
  pageSize: 25,
  pageNumber: 1,
  sortDescriptors: [],
  accountsCollection: {
    accountAllDetails: [],
    total: 0,
  },
  getAccountsCollection: { httpError: null, loading: false },
  addedAccount: null,
  addedAccountStatus: { httpError: null, loading: false },
  account: null,
  getAccount: { httpError: null, loading: false },
};
