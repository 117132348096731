import {
  EOperationAccountsActions,
  OperationalAccountsActions,
} from './operational-accounts.actions';
import {
  initialOperationalAccountsState,
  IOperationalAccountsState,
} from './operational-accounts.state';

export function operationalAccountsReducer(
  state = initialOperationalAccountsState,
  action: OperationalAccountsActions
): IOperationalAccountsState {
  switch (action.type) {
    case EOperationAccountsActions.FETCH_ACCOUNTS_COLLECTION:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        sortDescriptors: action.payload.sortDescriptors,
        accountsCollection: { accountAllDetails: [], total: null },
        getAccountsCollection: { loading: true, httpError: null },
      };
    case EOperationAccountsActions.FETCH_ACCOUNTS_COLLECTION_SUCCESS:
      return {
        ...state,
        accountsCollection: action.payload,
        getAccountsCollection: { loading: false, httpError: null },
      };
    case EOperationAccountsActions.FETCH_ACCOUNTS_COLLECTION_FAILED:
      return {
        ...state,
        accountsCollection: { accountAllDetails: [], total: null },
        getAccountsCollection: { loading: false, httpError: action.payload },
      };

    case EOperationAccountsActions.ADD_ACCOUNT:
      return {
        ...state,
        addedAccount: null,
        addedAccountStatus: { httpError: null, loading: true },
      };

    case EOperationAccountsActions.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        addedAccount: action.payload,
        addedAccountStatus: { httpError: null, loading: false },
      };

    case EOperationAccountsActions.ADD_ACCOUNT_FAILURE:
      return {
        ...state,
        addedAccount: null,
        addedAccountStatus: { httpError: action.error, loading: false },
      };

    case EOperationAccountsActions.ADD_ACCOUNT_INIT:
      return {
        ...state,
        addedAccount: null,
        addedAccountStatus: { httpError: null, loading: false },
      };

    case EOperationAccountsActions.FETCH_ACCOUNT_BY_ID:
      return {
        ...state,
        account: null,
        getAccount: { loading: true, httpError: null },
      };
    case EOperationAccountsActions.FETCH_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        account: action.payload,
        getAccount: { loading: false, httpError: null },
      };
    case EOperationAccountsActions.FETCH_ACCOUNT_BY_ID_FAILURE:
      return {
        ...state,
        account: null,
        getAccount: { loading: false, httpError: action.error },
      };
    default:
      return state;
  }
}
