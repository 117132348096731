import {
  createEmptyLoadingState,
  createErrorState,
  createLoadingState,
  createSuccessState,
} from '../app/app.models';
import { EImbalancesActions, ImbalancesActions } from './imbalances.actions';
import { IImbalancesState, initialImbalancesState } from './imbalances.state';

export function imbalancesReducers(
  state = initialImbalancesState,
  action: ImbalancesActions
): IImbalancesState {
  switch (action.type) {
    case EImbalancesActions.FETCH_IMBALANCES:
      return {
        ...state,
        imbalances: [],
        totalImbalancesCount: null,
        loading: true,
        error: null,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
      };

    case EImbalancesActions.FETCH_IMBALANCES_SUCCESS:
      return {
        ...state,
        imbalances: action.payload.imbalances,
        totalImbalancesCount: action.payload.totalImbalancesCount,
        loading: false,
        error: null,
      };

    case EImbalancesActions.FETCH_IMBALANCES_ERROR:
      return {
        ...state,
        imbalances: [],
        totalImbalancesCount: null,
        loading: false,
        error: createErrorState(action.payload).requestStatus.httpError,
      };

    case EImbalancesActions.FETCH_IMBALANCE_DETAILS:
      return {
        ...state,
        imbalanceDetails: null,
        totalImbalanceDetailsCount: null,
        imbalanceDetailsLoading: true,
        imbalanceDetailsError: null,
        imbalanceDetailsPagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
      };

    case EImbalancesActions.FETCH_IMBALANCES_DETAILS_SUCCESS:
      return {
        ...state,
        imbalanceDetails: action.payload.contractImbalances,
        totalImbalanceDetailsCount: action.payload.totalImbalancesCount,
        imbalanceDetailsLoading: false,
        imbalanceDetailsError: null,
      };

    case EImbalancesActions.FETCH_IMBALANCES_DETAILS_ERROR:
      return {
        ...state,
        imbalanceDetails: null,
        totalImbalanceDetailsCount: null,
        imbalanceDetailsLoading: false,
        imbalanceDetailsError: createErrorState(action.payload).requestStatus.httpError,
      };

    case EImbalancesActions.EXPORT_IMBALANCE_DETAILS:
      return {
        ...state,
        exportImbalanceDetails: { loading: true, httpError: null },
      };

    case EImbalancesActions.EXPORT_IMBALANCE_DETAILS_SUCCESS:
      return {
        ...state,
        exportImbalanceDetails: { loading: false, httpError: null },
      };

    case EImbalancesActions.EXPORT_IMBALANCE_DETAILS_FAILURE:
      return {
        ...state,
        exportImbalanceDetails: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.SET_SELECTED_IMBALANCE_ACCOUNTING_PERIOD:
      return {
        ...state,
        selectedImbalanceAccountingPeriod: action.payload,
      };

    case EImbalancesActions.FETCH_IMBALANCE_ACCOUNTING_PERIODS:
      return {
        ...state,
        imbalanceAccountingPeriods: null,
        imbalanceAccountingPeriodsError: null,
        imbalanceAccountingPeriodsLoading: true,
      };
    case EImbalancesActions.FETCH_IMBALANCE_ACCOUNTING_PERIODS_SUCCESS:
      return {
        ...state,
        imbalanceAccountingPeriods: action.payload.accountPeriods,
        imbalanceAccountingPeriodsError: null,
        imbalanceAccountingPeriodsLoading: false,
      };
    case EImbalancesActions.FETCH_IMBALANCE_ACCOUNTING_PERIODS_ERROR:
      return {
        ...state,
        imbalanceAccountingPeriods: null,
        imbalanceAccountingPeriodsError: action.payload.error,
        imbalanceAccountingPeriodsLoading: false,
      };

    case EImbalancesActions.SET_IMBALANCE_CONTACT:
      return {
        ...state,
        imbalanceContact: action.payload.user,
      };

    case EImbalancesActions.FETCH_IMBALANCE_SUMMARY:
      return {
        ...state,
        imbalanceSummaryLoading: true,
      };

    case EImbalancesActions.FETCH_IMBALANCE_SUMMARY_ERROR:
      return {
        ...state,
        imbalanceSummaryLoading: false,
        imbalanceSummaryError: action.payload.error,
      };

    case EImbalancesActions.FETCH_IMBALANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        imbalanceSummary: action.payload,
        imbalanceSummaryLoading: false,
        imbalanceSummaryError: null,
      };

    case EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE:
      return {
        ...state,
        obaImbalances: [],
        obaImbalancesTotal: 0,
        obaImbalancesLoading: true,
        obaImbalancesError: null,
      };

    case EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_SUCCESS:
      return {
        ...state,
        obaImbalances: action.payload.obaImbalances,
        obaImbalancesTotal: action.payload.total,
        obaImbalancesLoading: false,
      };

    case EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_FAILURE:
      return {
        ...state,
        obaImbalancesLoading: false,
        obaImbalancesError: action.payload.error,
      };

    case EImbalancesActions.FETCH_IMBALANCE_TRADES_COLLECTION:
      return {
        ...state,
        focusImbalanceTradeAccountPeriod: action.payload.accountPeriod,
        getImbalanceTradesCollection: { loading: true, httpError: null },
        imbalanceTradesCollection: {
          imbalanceTrades: [],
          total: null,
        },
      };
    case EImbalancesActions.FETCH_IMBALANCE_TRADES_COLLECTION_SUCCESS:
      return {
        ...state,
        imbalanceTradesCollection: action.payload,
        getImbalanceTradesCollection: { loading: false, httpError: null },
      };
    case EImbalancesActions.FETCH_IMBALANCE_TRADES_COLLECTION_FAILURE:
      return {
        ...state,
        imbalanceTradesCollection: {
          imbalanceTrades: [],
          total: null,
        },
        getImbalanceTradesCollection: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION:
      return {
        ...state,
        focusImbalanceTradeAccountPeriod: action.payload.accountPeriod,
        getImbalanceTradesSummaryCollection: { loading: true, httpError: null },
        imbalanceTradesSummaryCollection: {
          imbalanceTradesSummary: [],
          total: null,
        },
      };
    case EImbalancesActions.FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION_SUCCESS:
      return {
        ...state,
        imbalanceTradesSummaryCollection: action.payload,
        getImbalanceTradesSummaryCollection: { loading: false, httpError: null },
      };
    case EImbalancesActions.FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION_FAILURE:
      return {
        ...state,
        getImbalanceTradesSummaryCollection: { loading: false, httpError: action.payload },
        imbalanceTradesSummaryCollection: {
          imbalanceTradesSummary: [],
          total: null,
        },
      };

    case EImbalancesActions.FETCH_STORAGE_BALANCE_COLLECTION:
      return {
        ...state,
        getStorageBalanceCollection: { loading: true, httpError: null },
      };
    case EImbalancesActions.FETCH_STORAGE_BALANCE_COLLECTION_SUCCESS:
      return {
        ...state,
        storageBalanceCollection: action.payload,
        getStorageBalanceCollection: { loading: false, httpError: null },
      };
    case EImbalancesActions.FETCH_STORAGE_BALANCE_COLLECTION_FAILURE:
      return {
        ...state,
        storageBalanceCollection: {
          balances: [],
          total: null,
        },
        getStorageBalanceCollection: { loading: false, httpError: action.payload },
      };
    case EImbalancesActions.SET_IMBALANCE_TRADE:
      return {
        ...state,
        imbalanceTrade: action.payload,
        withdrawImbalanceTrade: { loading: false, httpError: null },
      };
    case EImbalancesActions.WITHDRAW_IMBALANCE_TRADE:
      return {
        ...state,
        withdrawImbalanceTrade: { loading: true, httpError: null },
      };
    case EImbalancesActions.WITHDRAW_IMBALANCE_TRADE_SUCCESS:
      return {
        ...state,
        imbalanceTrade: action.payload,
        withdrawImbalanceTrade: { loading: false, httpError: null },
      };
    case EImbalancesActions.WITHDRAW_IMBALANCE_TRADE_FAILURE:
      return {
        ...state,
        imbalanceTrade: null,
        withdrawImbalanceTrade: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.EXPORT_STORAGE_BALANCE:
      return {
        ...state,
        exportStorageBalance: { loading: true, httpError: null },
      };
    case EImbalancesActions.EXPORT_STORAGE_BALANCE_SUCCESS:
      return {
        ...state,
        exportStorageBalance: { loading: false, httpError: null },
      };
    case EImbalancesActions.EXPORT_STORAGE_BALANCE_FAILURE:
      return {
        ...state,
        exportStorageBalance: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.CREATE_IMBALANCE_TRADE:
      return {
        ...state,
        createImbalanceTrade: { loading: true, httpError: null },
      };
    case EImbalancesActions.CREATE_IMBALANCE_TRADE_SUCCESS:
      return {
        ...state,
        imbalanceTrade: action.payload,
        createImbalanceTrade: { loading: false, httpError: null },
      };
    case EImbalancesActions.CREATE_IMBALANCE_TRADE_FAILURE:
      return {
        ...state,
        imbalanceTrade: null,
        createImbalanceTrade: { loading: false, httpError: action.payload },
      };
    case EImbalancesActions.CLEAR_CREATE_IMBALANCE_TRADE:
      return {
        ...state,
        createImbalanceTrade: { loading: false, httpError: null },
        updateImbalanceTrade: { status: { loading: false, httpError: null }, actionType: null },
      };

    case EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS: {
      return {
        ...state,
        balancingImbalanceDetailsLoading: {
          ...state.balancingImbalanceDetailsLoading,
          [action.payload.contractId]: true,
        },
        balancingImbalanceDetailsError: {
          ...state.balancingImbalanceDetailsError,
          [action.payload.contractId]: null,
        },
      };
    }
    case EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_SUCCESS: {
      let index;
      for (index = 0; index < state.balancingImbalanceDetails.length; index++) {
        if (state.balancingImbalanceDetails[index].contractId === action.payload.contractId) {
          break;
        }
      }

      state.balancingImbalanceDetails[index] = action.payload.balancingAgreementImbalanceDetails;

      return {
        ...state,
        balancingImbalanceDetails: [...state.balancingImbalanceDetails],
        balancingImbalanceDetailsLoading: {
          ...state.balancingImbalanceDetailsLoading,
          [action.payload.contractId]: false,
        },
        balancingImbalanceDetailsError: {
          ...state.balancingImbalanceDetailsError,
          [action.payload.contractId]: null,
        },
      };
    }
    case EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_ERROR: {
      return {
        ...state,
        balancingImbalanceDetailsLoading: {
          ...state.balancingImbalanceDetailsLoading,
          [action.payload.contractId]: false,
        },
        balancingImbalanceDetailsError: {
          ...state.balancingImbalanceDetailsError,
          [action.payload.contractId]: true,
        },
      };
    }
    case EImbalancesActions.UPDATE_IMBALANCE_TRADE:
      return {
        ...state,
        updateImbalanceTrade: {
          ...state.updateImbalanceTrade,
          status: { loading: true, httpError: null },
          actionType: action.payload.actionType,
        },
      };
    case EImbalancesActions.UPDATE_IMBALANCE_TRADE_SUCCESS:
      return {
        ...state,
        imbalanceTrade: {
          ...state.imbalanceTrade,
          imbalanceTradeStatus: action.payload.imbalanceTradeStatus,
          statementDateTime: action.payload.statementDateTime,
        },
        updateImbalanceTrade: {
          ...state.updateImbalanceTrade,
          status: { loading: false, httpError: null },
        },
      };
    case EImbalancesActions.UPDATE_IMBALANCE_TRADE_FAILURE:
      return {
        ...state,
        updateImbalanceTrade: {
          ...state.updateImbalanceTrade,
          status: { loading: false, httpError: action.payload },
        },
      };

    case EImbalancesActions.FETCH_SHIPPER_ACTIVITY_COLLECTION:
      return {
        ...state,
        getShipperActivityCollection: { loading: true, httpError: null },
      };
    case EImbalancesActions.FETCH_SHIPPER_ACTIVITY_COLLECTION_SUCCESS:
      return {
        ...state,
        shipperActivityCollection: action.payload,
        getShipperActivityCollection: { loading: false, httpError: null },
      };
    case EImbalancesActions.FETCH_SHIPPER_ACTIVITY_COLLECTION_FAILURE:
      return {
        ...state,
        shipperActivityCollection: {
          shipperActivityCollection: null,
          tspConfig: null,
        },
        getShipperActivityCollection: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION:
      return {
        ...state,
        getShipperActivityContractCollection: { loading: true, httpError: null },
      };
    case EImbalancesActions.FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION_SUCCESS:
      return {
        ...state,
        shipperActivityContractCollection: action.payload,
        getShipperActivityContractCollection: { loading: false, httpError: null },
      };
    case EImbalancesActions.FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION_FAILURE:
      return {
        ...state,
        shipperActivityContractCollection: {
          contracts: null,
          total: 0,
        },
        getShipperActivityContractCollection: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.EXPORT_SHIPPER_ACTIVITY:
      return {
        ...state,
        exportShipperActivity: { loading: true, httpError: null },
      };
    case EImbalancesActions.EXPORT_SHIPPER_ACTIVITY_SUCCESS:
      return {
        ...state,
        exportShipperActivity: { loading: false, httpError: null },
      };
    case EImbalancesActions.EXPORT_SHIPPER_ACTIVITY_FAILURE:
      return {
        ...state,
        exportShipperActivity: { loading: false, httpError: action.payload },
      };
    case EImbalancesActions.FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION:
      return {
        ...state,
        getAuthorizationtoPostImbalancesCollection: { loading: true, httpError: null },
      };
    case EImbalancesActions.FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION_SUCCESS:
      return {
        ...state,
        authorizationtoPostImbalancesCollection: action.payload,
        getAuthorizationtoPostImbalancesCollection: { loading: false, httpError: null },
      };
    case EImbalancesActions.FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION_FAILURE:
      return {
        ...state,
        authorizationtoPostImbalancesCollection: {
          imbalanceAuthorizations: [],
          total: null,
        },
        getAuthorizationtoPostImbalancesCollection: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.PUT_AUTH_TO_POST_IMBALANCES:
      return {
        ...state,
        putAuthorizationToPost: createLoadingState(state.putAuthorizationToPost),
      };
    case EImbalancesActions.PUT_AUTH_TO_POST_IMBALANCES_SUCCESS:
      return {
        ...state,
        putAuthorizationToPost: createSuccessState(action.payload),
      };
    case EImbalancesActions.PUT_AUTH_TO_POST_IMBALANCES_FAILURE:
      return {
        ...state,
        putAuthorizationToPost: createErrorState(action.payload),
      };

    case EImbalancesActions.PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF:
      return {
        ...state,
        imbalanceAgreementSignoff: {
          requestDataState: {
            data: null,
            requestStatus: { httpError: null, loading: true },
          },
          contractId: action.payload.contractId,
          actionType: action.payload.balancingImbalanceSignoff.status,
        },
      };
    case EImbalancesActions.PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF_SUCCESS:
      return {
        ...state,
        imbalanceAgreementSignoff: {
          requestDataState: {
            data: action.payload.signoffResponse,
            requestStatus: {
              loading: false,
              httpError: null,
            },
          },
          contractId: state.imbalanceAgreementSignoff.contractId,
          actionType: state.imbalanceAgreementSignoff.actionType, //TODO: clear this when back end is complete, set based on value
        },
      };
    case EImbalancesActions.PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF_FAILURE:
      return {
        ...state,
        imbalanceAgreementSignoff: {
          requestDataState: {
            data: null,
            requestStatus: {
              loading: false,
              httpError: action.payload,
            },
          },
          contractId: null,
          actionType: state.imbalanceAgreementSignoff.actionType,
        },
      };

    case EImbalancesActions.FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS:
      return {
        ...state,
        authorizedImbalanceAccounts: createLoadingState(state.authorizedImbalanceAccounts),
      };
    case EImbalancesActions.FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        authorizedImbalanceAccounts: createSuccessState(action.payload),
      };
    case EImbalancesActions.FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS_FAILURE:
      return {
        ...state,
        authorizedImbalanceAccounts: createErrorState(action.payload),
      };

    case EImbalancesActions.EDIT_OBA_SUMMARY_REQUEST:
      return {
        ...state,
        editObaSummary: createLoadingState(state.editObaSummary),
      };
    case EImbalancesActions.EDIT_OBA_SUMMARY_SUCCESS: {
      let obaSummary = state.obaSummary;
      let index = -1;

      const { operationalBalancingAgmts: obas = [] } = state.obaSummary.requestDataState.data || {};
      const { accountingPeriod = null, productionPeriod = null, contractId = null } =
        action.payload || {};

      if (obas.length) {
        index = obas.findIndex(
          oba =>
            oba.accountingPeriod === accountingPeriod &&
            oba.productionPeriod === productionPeriod &&
            oba.contractId === contractId
        );

        if (index >= 0) {
          obaSummary = { ...state.obaSummary };
          obaSummary.requestDataState.data.operationalBalancingAgmts[index] = { ...action.payload };
        }
      }

      return {
        ...state,
        obaSummary,
        editObaSummary: createSuccessState(action.payload),
      };
    }
    case EImbalancesActions.EDIT_OBA_SUMMARY_FAILURE:
      return {
        ...state,
        editObaSummary: createErrorState(action.payload),
      };
    case EImbalancesActions.EDIT_SHIPPER_ACTIVITY_REQUEST:
      return {
        ...state,
        editShipperActivity: createLoadingState(state.editShipperActivity),
      };
    case EImbalancesActions.EDIT_SHIPPER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        editShipperActivity: createSuccessState(action.payload),
      };
    }
    case EImbalancesActions.EDIT_SHIPPER_ACTIVITY_FAILURE:
      return {
        ...state,
        editShipperActivity: createErrorState(action.payload),
      };
    case EImbalancesActions.EXPORT_OBA_SUMMARY_REQUEST:
      return {
        ...state,
        exportObaSummary: { loading: true, httpError: null },
      };
    case EImbalancesActions.EXPORT_OBA_SUMMARY_SUCCESS:
      return {
        ...state,
        exportObaSummary: { loading: false, httpError: null },
      };
    case EImbalancesActions.EXPORT_OBA_SUMMARY_FAILURE:
      return {
        ...state,
        exportObaSummary: { loading: false, httpError: action.payload },
      };

    case EImbalancesActions.FETCH_OBA_SUMMARY_REQUEST:
      return {
        ...state,
        obaSummary: {
          requestDataState: createLoadingState(state.obaSummary.requestDataState),
        },
      };
    case EImbalancesActions.FETCH_OBA_SUMMARY_SUCCESS:
      return {
        ...state,
        obaSummary: {
          requestDataState: createSuccessState(action.payload),
        },
      };
    case EImbalancesActions.FETCH_OBA_SUMMARY_FAILURE:
      return {
        ...state,
        obaSummary: {
          requestDataState: createErrorState(action.payload),
        },
      };

    case EImbalancesActions.FETCH_IMBALANCE_INVENTORIES:
      return {
        ...state,
        imbalanceInventoryCollection: createLoadingState(state.imbalanceInventoryCollection),
      };
    case EImbalancesActions.FETCH_IMBALANCE_INVENTORIES_SUCCESS:
      return {
        ...state,
        imbalanceInventoryCollection: createSuccessState(
          action.payload.imbalanceInventoryCollection
        ),
      };
    case EImbalancesActions.FETCH_IMBALANCE_INVENTORIES_ERROR:
      return {
        ...state,
        imbalanceInventoryCollection: createErrorState(action.payload.error),
      };

    case EImbalancesActions.FETCH_ACCT_IMBALANCE:
      return {
        ...state,
        acctImbalanceCollection: createLoadingState(state.acctImbalanceCollection),
      };

    case EImbalancesActions.FETCH_ACCT_IMBALANCE_SUCCESS:
      return {
        ...state,
        acctImbalanceCollection: createSuccessState(action.payload.acctImbalanceCollection),
      };

    case EImbalancesActions.FETCH_ACCT_IMBALANCE_ERROR:
      return {
        ...state,
        acctImbalanceCollection: createErrorState(action.payload.error),
      };

    case EImbalancesActions.FETCH_TRADE_WINDOW:
      return {
        ...state,
        imbalanceTradeWindow: createEmptyLoadingState(),
      };
    case EImbalancesActions.FETCH_TRADE_WINDOW_SUCCESS:
      return {
        ...state,
        imbalanceTradeWindow: createSuccessState(action.payload),
      };
    case EImbalancesActions.FETCH_TRADE_WINDOW_FAILURE:
      return {
        ...state,
        imbalanceTradeWindow: createErrorState(action.payload),
      };
    case EImbalancesActions.CLEAR_TRADE_WINDOW_DATA:
      return {
        ...state,
        imbalanceTradeWindow: createSuccessState(null),
      };

    case EImbalancesActions.FETCH_PIPELINE_POSITION:
      return {
        ...state,
        pipelinePosition: createLoadingState(state.pipelinePosition),
      };
    case EImbalancesActions.FETCH_PIPELINE_POSITION_SUCCESS:
      return {
        ...state,
        pipelinePosition: createSuccessState(action.payload),
      };
    case EImbalancesActions.FETCH_PIPELINE_POSITION_FAILURE:
      return {
        ...state,
        pipelinePosition: createErrorState(action.payload),
      };
    case EImbalancesActions.FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION:
      return {
        ...state,
        masterRateCICOSchedule: createLoadingState(state.masterRateCICOSchedule),
      };
    case EImbalancesActions.FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION_SUCCESS:
      return {
        ...state,
        masterRateCICOSchedule: createSuccessState(action.payload),
      };
    case EImbalancesActions.FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION_FAILURE:
      return {
        ...state,
        masterRateCICOSchedule: createErrorState(action.payload),
      };
    default:
      return state;
  }
}
