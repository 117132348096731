import { Injectable } from '@angular/core';
import { Location, LocationService } from '@gms/location-api';
import {
  filterByAttribute,
  filterByStatus,
} from 'app/components/locations-typeahead/location-filter.utils';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'shared/utils/type.utils';

interface LocationRateScheduleStorageMap {
  injectable: LocationRateScheduleStorageMapItem[];
  withdrawable: LocationRateScheduleStorageMapItem[];
}

interface LocationStorageMapItem {
  masterRateScheduleCode: string;
  locations: number[];
}

interface LocationRateScheduleStorageMapItem {
  tspId: number;
  storageLocations: LocationStorageMapItem[];
}

export interface StorageLocationResult {
  injectable: Location[];
  withdrawable: Location[];
}

export const locationsMap: LocationRateScheduleStorageMap = {
  injectable: [
    {
      tspId: 1,
      storageLocations: [
        {
          masterRateScheduleCode: 'ISS',
          locations: [
            // Location No.
            2886, //003597
            3583, //010401
            4094, //22806
          ],
        },
        {
          masterRateScheduleCode: 'FSS-P',
          locations: [
            // Location No.
            4883, // 23105
            4884, // 23106
            4939, // 50201
            4615, // 108009
            4617, // 108015
          ],
        },
        {
          masterRateScheduleCode: 'FSS-B',
          locations: [
            // Location No.
            2886, //3597
            3583, //10401
            4094, //22806
          ],
        },
        {
          masterRateScheduleCode: 'ISS-P',
          locations: [
            // Location No.
            4883, //023105
            4884, //023106
            4939, //050201
            4615, //108009
            4617, //108015
          ],
        },
      ],
    },
    {
      tspId: 100000,
      storageLocations: [
        {
          masterRateScheduleCode: 'ISS-M',
          locations: [
            // Location No.
            1171, //4801
          ],
        },
        {
          masterRateScheduleCode: 'FSS',
          locations: [
            // Location No.
            1170, //4800
          ],
        },
        {
          masterRateScheduleCode: 'FSS-M',
          locations: [
            // Location No.
            1171, //4801
          ],
        },
        {
          masterRateScheduleCode: 'ISS',
          locations: [
            1170, // 4800
          ],
        },
        {
          masterRateScheduleCode: 'NNS',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
        {
          masterRateScheduleCode: 'NNL',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
        {
          masterRateScheduleCode: 'SGL',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
        {
          masterRateScheduleCode: 'SGT',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
        {
          masterRateScheduleCode: 'WNS',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
        {
          masterRateScheduleCode: 'SNS',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
      ],
    },
    {
      tspId: 6,
      storageLocations: [
        {
          masterRateScheduleCode: 'FSS',
          locations: [
            // Location No.
            5026, // 023130
            5028, // 023132
            5030, // 023134
            5032, // 023136
            5034, // 023138
            5036, // 023140
            5038, // 023142
            5047, // 023300
            5049, // 023302
          ],
        },
        {
          masterRateScheduleCode: 'ISS',
          locations: [
            // Location No.
            5026, // 023130
            5028, // 023132
            5030, // 023134
            5032, // 023136
            5034, // 023138
            5036, // 023140
            5038, // 023142
            5047, // 023300
            5049, // 023302
          ],
        },
      ],
    },
  ],
  withdrawable: [
    {
      tspId: 100000,
      storageLocations: [
        {
          masterRateScheduleCode: 'ISS-M',
          locations: [
            // Location No.
            1211, //5801
          ],
        },
        {
          masterRateScheduleCode: 'FSS',
          locations: [
            // Location No.
            1210, //5800
          ],
        },
        {
          masterRateScheduleCode: 'FSS-M',
          locations: [
            // Location No.
            1211, //5801
          ],
        },
        {
          masterRateScheduleCode: 'ISS',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
        {
          masterRateScheduleCode: 'NNL',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
        {
          masterRateScheduleCode: 'GS',
          locations: [
            // Location No.
            1210, // 5800
          ],
        },
      ],
    },
    {
      tspId: 1,
      storageLocations: [
        {
          masterRateScheduleCode: 'FSS-P',
          locations: [
            // Location No.
            4104, // 23108
            4414, // 23109
            4106, // 23111
            4415, // 23112
            4611, // 50202
            4618, // 109009,
            4950, // 109015
          ],
        },
        {
          masterRateScheduleCode: 'ISS',
          locations: [
            // Location No.
            2890, //004705
            3475, //010402
            4195, //002807
          ],
        },
        {
          masterRateScheduleCode: 'FSS-B',
          locations: [
            // Location No.
            2890, //4705
            3475, //10402
            4195, //22807
          ],
        },
        {
          masterRateScheduleCode: 'ISS-P',
          locations: [
            // Location No.
            4104, //023108
            4414, //023109
            4106, //023111
            4415, //023112
            4611, //050202
            4618, //109009
            4950, //109015
          ],
        },
        {
          masterRateScheduleCode: 'NNS',
          locations: [
            // Location No.
            3629, // 10755
            4227, // 23600
          ],
        },
        {
          masterRateScheduleCode: 'GS',
          locations: [
            // Location No.
            3475, // 010402
            4264, // 050200
            4118, // 023602
          ],
        },
      ],
    },
    {
      tspId: 6,
      storageLocations: [
        {
          masterRateScheduleCode: 'FSS',
          locations: [
            // Location No.
            5025, // 023129
            5027, // 023131
            5029, // 023133
            5031, // 023135
            5033, // 023137
            5035, // 023139
            5037, // 023141
            5047, // 023300
            5048, // 023301
          ],
        },
        {
          masterRateScheduleCode: 'ISS',
          locations: [
            // Location No.
            5025, // 023129
            5027, // 023131
            5029, // 023133
            5031, // 023135
            5033, // 023137
            5035, // 023139
            5037, // 023141
            5047, // 023300
            5048, // 023301
          ],
        },
        {
          masterRateScheduleCode: 'GS',
          locations: [
            // Location No.
            5047, // 023300
          ],
        },
      ],
    },
  ],
};

export interface ILocationStorageService {
  getStorageLocations: (
    masterRateScheduleCode: string,
    tspId: number
  ) => Observable<StorageLocationResult>;

  getStorageLocationIds: (
    classificationIds: Array<number>,
    tspId: number,
    detailRateScheduleId: number,
    rateScheduleId: number,
    pipelineSystemId: number
  ) => void;
}

const LOCATIONS_FILTER_ALL_STATUS = 'All';

@Injectable({
  providedIn: 'root',
})
export class LocationStorageService implements ILocationStorageService {
  constructor(private _locationService: LocationService) {}

  getStorageLocations(
    masterRateScheduleCode: string,
    tspId: number
  ): Observable<StorageLocationResult> {
    const injectableMatchingItem = this.getInjectableMatchingItem(tspId, masterRateScheduleCode);
    const withdrawalMatchingItem = this.getWithdrawalMatchingItem(tspId, masterRateScheduleCode);

    const mergedLocations = [
      ...injectableMatchingItem.locations,
      ...withdrawalMatchingItem.locations,
    ];

    const result = this._locationService.getLocations(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      mergedLocations,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      LOCATIONS_FILTER_ALL_STATUS
    );

    return result.pipe(
      map(collection => ({
        injectable: collection.locations.filter(l =>
          injectableMatchingItem.locations.includes(l.locationId)
        ),
        withdrawable: collection.locations.filter(l =>
          withdrawalMatchingItem.locations.includes(l.locationId)
        ),
      }))
    );
  }

  getStorageLocationIds(
    classificationIds: Array<number>,
    tspId: number,
    detailRateScheduleId: number,
    rateScheduleId: number,
    pipelineSystemId: number
  ): Observable<Location[]> {
    return this._locationService
      .getLocations(
        50,
        null,
        null,
        tspId,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        classificationIds,
        null,
        LOCATIONS_FILTER_ALL_STATUS,
        null,
        null,
        detailRateScheduleId,
        pipelineSystemId,
        null,
        null,
        null,
        null,
        null
      )
      .pipe(
        first(),
        map(result =>
          result.locations.filter(
            location =>
              filterByStatus(location) && filterByAttribute(location, tspId, rateScheduleId)
          )
        )
      );
  }

  private getInjectableMatchingItem(tspId: number, masterRateScheduleCode: string) {
    const injectableTspMatchingItem: LocationRateScheduleStorageMapItem = locationsMap.injectable.find(
      item => item.tspId === tspId
    );

    let injectableMatchingItem;
    if (!isNullOrUndefined(injectableTspMatchingItem)) {
      injectableMatchingItem = injectableTspMatchingItem.storageLocations.find(
        sl => sl.masterRateScheduleCode === masterRateScheduleCode
      );
    }

    if (isNullOrUndefined(injectableMatchingItem)) {
      injectableMatchingItem = {
        masterRateScheduleCode,
        locations: [],
      };
    }
    return injectableMatchingItem;
  }

  private getWithdrawalMatchingItem(tspId: number, masterRateScheduleCode: string) {
    const withdrawalTspMatchingItem: LocationRateScheduleStorageMapItem = locationsMap.withdrawable.find(
      item => item.tspId === tspId
    );

    let withdrawalMatchingItem;
    if (!isNullOrUndefined(withdrawalTspMatchingItem)) {
      withdrawalMatchingItem = withdrawalTspMatchingItem.storageLocations.find(
        sl => sl.masterRateScheduleCode === masterRateScheduleCode
      );
    }

    if (isNullOrUndefined(withdrawalMatchingItem)) {
      withdrawalMatchingItem = {
        masterRateScheduleCode,
        locations: [],
      };
    }
    return withdrawalMatchingItem;
  }
}
