import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const JournalEntries_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/journal-entries-list/journal-entries-list.module').then(
        m => m.JournalEntriesListModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/journal-entries',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Adjustments_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/accounting/pages/adjustments-view/adjustments-view.module').then(
        m => m.AdjustmentsViewModule
      ),
  },
];

export const Projects_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/projects-view/projects-view.module').then(m => m.ProjectsViewModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/projects',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/accounting/pages/create-project/create-project.module').then(
        m => m.CreateProjectModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/projects',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('app/modules/accounting/pages/create-project/create-project.module').then(
        m => m.CreateProjectModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/projects',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Inventory_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/inventory-pipeline-balance-report/inventory-pipeline-balance-report.module').then(
        m => m.InventoryPipelineBalanceReportModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/inventory',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Revenue_Recognition_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/revenue-recognition/revenue-recognition.module').then(
        m => m.RevenueRecognitionModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/revenue-recognition',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];
