import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import get from 'lodash/get';

export enum PopoverHorizontalAlignment {
  Center = 'center',
  Right = 'right',
  Left = 'left',
}

export enum PopoverVerticalAlignment {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
}

export enum PopoverAnimationDirection {
  Down = 'down',
  Up = 'up',
  Left = 'left',
  Right = 'right',
}

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnDestroy, OnInit, OnChanges {
  @ViewChild('anchor', { static: false }) /*TODO: do we want static to be false?*/
  anchor: ElementRef;

  @ViewChild('template', { static: false }) /*TODO: do we want static to be false?*/
  template: TemplateRef<any>;

  @Input()
  popupClass = '';

  @Input()
  horizontalLeftOffset = 0;

  @Input()
  verticalTopOffset = 0;

  @Input()
  shadowVerticalTopOffset = null;

  @Input()
  shadowVerticalBottomOffset = null;

  @Input()
  horizontalAlignment = PopoverHorizontalAlignment.Right;

  @Input()
  verticalAlignment = PopoverVerticalAlignment.Bottom;

  @Input()
  anchorHorizontalAlignment = PopoverHorizontalAlignment.Right;

  @Input()
  anchorVerticalAlignment = PopoverVerticalAlignment.Top;

  @Input()
  animationDirection = PopoverAnimationDirection.Down;

  @Input()
  displayOnHover = false;

  @Input()
  useArrow = false;

  @Input()
  overrideFitContent = false;

  @Input()
  tableOverflow = false;

  @Input()
  isActivatedByFocus = false;

  @Input()
  removeShadow = false;

  @Input()
  allowScroll = false;

  popupRef: PopupRef;

  isFlipped: boolean;

  mouseClickToggle = false;

  shadowStyles: any;

  leftArrow: boolean;
  rightArrow: boolean;

  private scrollEventOptions = {
    capture: true,
    passive: true,
  };

  constructor(private _popupService: PopupService, private ngZone: NgZone) {}

  scroll(e: Event): void {
    if (!this.allowScroll) this.hide();
  }

  ngOnInit() {
    this.setupScrollingListener();
    this.setPopupShadowStyles(this.shadowVerticalTopOffset, this.shadowVerticalBottomOffset);
  }

  setupScrollingListener() {
    // Really slow if this is run inside angular
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scroll.bind(this), this.scrollEventOptions);
    });
  }

  removeScrollingListener() {
    window.removeEventListener('scroll', this.scroll.bind(this), this.scrollEventOptions);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shadowVerticalTopOffset || changes.shadowVerticalBottomOffset) {
      const topOffset = changes.shadowVerticalTopOffset
        ? changes.shadowVerticalTopOffset.currentValue
        : this.shadowVerticalTopOffset;
      const bottomOffset = changes.shadowVerticalBottomOffset
        ? changes.shadowVerticalBottomOffset.currentValue
        : this.shadowVerticalBottomOffset;

      this.setPopupShadowStyles(topOffset, bottomOffset);
    }
  }

  ngOnDestroy() {
    this.removeScrollingListener();
    this.hide();
  }

  setPopupShadowStyles(topOffset: number, bottomOffset: number) {
    this.shadowStyles = {
      top: `${topOffset}px`,
      bottom: `${bottomOffset}px`,
    };
  }

  toggle(): void {
    if (this.popupRef && this.mouseClickToggle) {
      this.popupRef.close();
      this.popupRef = null;
      this.mouseClickToggle = false;
    } else {
      this.createPopover();
      this.mouseClickToggle = true;
    }
  }

  hide(): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = null;
      this.mouseClickToggle = false;
    }
  }

  mouseHovering(isHovering) {
    if (this.displayOnHover) {
      if (isHovering) {
        if (!this.popupRef) {
          this.createPopover();
        }
      } else {
        if (!this.mouseClickToggle) {
          this.hide();
        }
      }
    }
  }

  hasFocus(hasFocus) {
    if (this.isActivatedByFocus) {
      if (this.displayOnHover) {
        if (hasFocus) {
          if (!this.popupRef) {
            this.createPopover();
          }
        } else {
          if (!this.mouseClickToggle) {
            this.hide();
          }
        }
      }
    }
  }

  private createPopover() {
    if (this.popupRef) {
      this.popupRef.close();
    }
    const anchor = this.calculateAnchorPosition();
    this.popupRef = this._popupService.open({
      anchor: anchor,
      content: this.template,
      popupAlign: {
        horizontal: this.horizontalAlignment,
        vertical: this.verticalAlignment,
      },
      anchorAlign: {
        horizontal: this.anchorHorizontalAlignment,
        vertical: this.anchorVerticalAlignment,
      },
      animate: {
        duration: 100,
        direction: this.animationDirection,
      },
      popupClass: 'gms-popover ' + `${this.popupClass ? this.popupClass : ''}`,
    });

    // Keeps track of vertical position of popover, useful in cases of rendering the corresponding popover arrow
    this.isFlipped = get(this.popupRef, 'popup.instance.animationService.flip.vertical');

    this.leftArrow =
      this.horizontalAlignment === PopoverHorizontalAlignment.Left &&
      this.anchorHorizontalAlignment === PopoverHorizontalAlignment.Left;

    this.rightArrow =
      this.horizontalAlignment === PopoverHorizontalAlignment.Right &&
      this.anchorHorizontalAlignment === PopoverHorizontalAlignment.Right;
  }

  private calculateAnchorPosition() {
    const rect = this.anchor.nativeElement.getBoundingClientRect();
    rect.x += this.horizontalLeftOffset;
    rect.y += this.verticalTopOffset;
    return {
      nativeElement: {
        getBoundingClientRect: () => rect,
      },
    };
  }
}
