import { AccountAllDetail, AccountAllDetailCollection, AccountDetail } from '@gms/billing-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export enum EOperationAccountsActions {
  FETCH_ACCOUNTS_COLLECTION = '[OPERATIONAL ACCOUNTS] Fetch Operational Accounts Collection',
  FETCH_ACCOUNTS_COLLECTION_SUCCESS = '[OPERATIONAL ACCOUNTS] Fetch Operational Accounts Collection Success',
  FETCH_ACCOUNTS_COLLECTION_FAILED = '[OPERATIONAL ACCOUNTS] Fetch Operational Accounts Collection Failed',
  FETCH_ACCOUNT_BY_ID = '[OPERATIONAL ACCOUNTS] Fetch Operational Account By ID',
  FETCH_ACCOUNT_BY_ID_SUCCESS = '[OPERATIONAL ACCOUNTS] Fetch Operational Account By ID Success',
  FETCH_ACCOUNT_BY_ID_FAILURE = '[OPERATIONAL ACCOUNTS] Fetch Operational Account By ID Failure',
  ADD_ACCOUNT = '[OPERATIONAL ACCOUNTS] Add Operational Account',
  ADD_ACCOUNT_SUCCESS = '[OPERATIONAL ACCOUNTS] Add Operational Account Success',
  ADD_ACCOUNT_FAILURE = '[OPERATIONAL ACCOUNTS] Add Operational Account Failure',
  CLEAR_ADDED_ACCOUNT = '[OPERATIONAL ACCOUNTS] Clear Current Operational Account',
  ADD_ACCOUNT_INIT = '[OPERATIONAL ACCOUNTS] Init Create Operational Account Page',
}

export class GetAccountsCollection implements Action {
  public readonly type = EOperationAccountsActions.FETCH_ACCOUNTS_COLLECTION;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      entityIds?: number[];
      viewBy?: string;
      accountTypeId?: string;
      svcReqNameId?: string;
      searchPhrase?: string;
      accountTypeIds?: Array<number>;
    }
  ) {}
}

export class AddAccount implements Action {
  public readonly type = EOperationAccountsActions.ADD_ACCOUNT;

  constructor(
    public payload: {
      tspId?: number;
      description?: string;
      dateEffective?: string;
      accountTypeId?: number;
      facilityId?: number;
      entityId?: number;
    }
  ) {}
}

export class GetAccountsCollectionSuccess implements Action {
  public readonly type = EOperationAccountsActions.FETCH_ACCOUNTS_COLLECTION_SUCCESS;

  constructor(public payload: AccountAllDetailCollection) {}
}

export class GetAccountsCollectionFailure implements Action {
  public readonly type = EOperationAccountsActions.FETCH_ACCOUNTS_COLLECTION_FAILED;

  constructor(public payload: Error) {}
}

export class GetAccountById implements Action {
  public readonly type = EOperationAccountsActions.FETCH_ACCOUNT_BY_ID;

  constructor(public id: number) {}
}

export class GetAccountByIdSuccess implements Action {
  public readonly type = EOperationAccountsActions.FETCH_ACCOUNT_BY_ID_SUCCESS;

  constructor(public payload: AccountAllDetail) {}
}

export class GetAccountByIdFailure implements Action {
  public readonly type = EOperationAccountsActions.FETCH_ACCOUNT_BY_ID_FAILURE;

  constructor(public error: Error) {}
}

export class AddAccountSuccess implements Action {
  public readonly type = EOperationAccountsActions.ADD_ACCOUNT_SUCCESS;

  constructor(public payload: AccountDetail) {}
}

export class AddAccountFailure implements Action {
  public readonly type = EOperationAccountsActions.ADD_ACCOUNT_FAILURE;

  constructor(public error: Error) {}
}

export class AddAccountInit implements Action {
  public readonly type = EOperationAccountsActions.ADD_ACCOUNT_INIT;

  constructor() {}
}

export type OperationalAccountsActions =
  | GetAccountsCollection
  | GetAccountsCollectionSuccess
  | GetAccountsCollectionFailure
  | GetAccountById
  | GetAccountByIdSuccess
  | GetAccountByIdFailure
  | AddAccount
  | AddAccountSuccess
  | AddAccountFailure
  | AddAccountInit;
