import { PredefinedFootnote, PredefinedFootnoteCollection } from '@gms/servicerequest-api';
import { Action } from '@ngrx/store';

export enum EPredefinedFootnotesActions {
  FETCH_PREDEFINED_FOOTNOTES = '[PREDEFINED_FOOTNOTES] fetch predefined_footnotes',
  FETCH_PREDEFINED_FOOTNOTES_SUCCESS = '[PREDEFINED_FOOTNOTES] fetch predefined_footnotes success',
  FETCH_PREDEFINED_FOOTNOTES_ERROR = '[PREDEFINED_FOOTNOTES] fetch predefined_footnotes error',
}

export class FetchPredefinedFootnotes implements Action {
  public readonly type = EPredefinedFootnotesActions.FETCH_PREDEFINED_FOOTNOTES;

  constructor(public tspId: number) {}
}

export class FetchPredefinedFootnoteSuccess implements Action {
  public readonly type = EPredefinedFootnotesActions.FETCH_PREDEFINED_FOOTNOTES_SUCCESS;

  constructor(public predefinedFootnotes: PredefinedFootnoteCollection) {}
}

export class FetchPredefinedFootnoteError implements Action {
  public readonly type = EPredefinedFootnotesActions.FETCH_PREDEFINED_FOOTNOTES_ERROR;

  constructor(public payload: PredefinedFootnote[]) {}
}

export type PredefinedFootnotesActions =
  | FetchPredefinedFootnotes
  | FetchPredefinedFootnoteSuccess
  | FetchPredefinedFootnoteError;
