export const HttpCodes = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  ACCESS_FORBIDDEN: 403,
  NOT_FOUND: 404,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  VALIDATION: 422,
  ACCOUNT_LOCKED: 423,
  INTERNAL_SERVER_ERROR: 500,
};
