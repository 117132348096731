import {
  BalancingImbalance,
  BalancingImbalanceDetails,
  Contact,
  Imbalance,
  ImbalanceSummary,
} from '@gms/allocation-api';
import {
  AuthorizedImbalanceAccountDetailCollection,
  ImbalanceAuthorization,
  ImbalanceAuthorizationCollection,
  ImbalancePipelinePosition,
  ImbalanceTrade,
  ImbalanceTradeCollection,
  ImbalanceTradesSummary,
  ImbalanceTradesSummaryCollection,
  InventoryCollection,
  MasterRateCICOScheduleCollection,
  OperationalBalancingAgmt,
  OperationalBalancingAgmtCollection,
  SAContractCollection,
  ShipperActivity,
  ShipperActivityCollection,
  StorageBalanceCollection,
  TradeWindow,
  TspImbalanceConfigDetail,
} from '@gms/imbalance-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';
import { EImbalanceTradeActions } from 'app/modules/flowing-gas/pages/flowing-gas-utils/imbalance.utils';
import { MappedImbalanceTrade } from 'app/modules/flowing-gas/pages/imbalance-trade-view/imbalance-trade-view.model';
import { getPagination, IPagination } from 'shared/models/pagination.model';
import { emptyRequestStatus, IDataState, IRequestStatus } from '../app/app.models';
import { EImbalanceSignoffActions } from './imbalance.utils';
import { IShipperActivityContractPayload } from './imbalances.actions';

export interface IShipperActivity {
  shipperActivityCollection: ShipperActivityCollection;
  tspConfig: TspImbalanceConfigDetail;
}

export interface IImbalancesState {
  loading: boolean;
  error: Error | null;
  imbalances: Array<Imbalance>;
  totalImbalancesCount: number;
  sortDescriptors: SortDescriptor[];
  pagination: IPagination;

  imbalanceContact: Contact;
  imbalanceStatementDate: Date;
  imbalanceDetailsLoading: boolean;
  imbalanceDetailsError: Error;
  imbalanceDetails: Array<Imbalance>;
  totalImbalanceDetailsCount: number;
  imbalanceDetailsPagination: IPagination;

  selectedImbalanceAccountingPeriod: IAccountPeriod;

  imbalanceAccountingPeriodsLoading: boolean;
  imbalanceAccountingPeriodsError: Error;
  imbalanceAccountingPeriods: Array<IAccountPeriod>;

  imbalanceSummaryLoading: boolean;
  imbalanceSummaryError: Error;
  imbalanceSummary: ImbalanceSummary;

  obaImbalances: Array<BalancingImbalance>;
  obaImbalancesTotal: number;
  obaImbalancesLoading: boolean;
  obaImbalancesError: Error;

  exportImbalanceDetails: IRequestStatus<any>;

  balancingImbalanceDetails: Array<BalancingImbalanceDetails>;
  balancingImbalanceDetailsLoading: { [locationNumber: string]: boolean };
  balancingImbalanceDetailsError: { [locationNumber: string]: Error | null };

  // Imbalance Trade Summary
  imbalanceTradesSummaryCollection: ImbalanceTradesSummaryCollection;
  getImbalanceTradesSummaryCollection: IRequestStatus<any>;

  // IMBALANCE TRADE
  focusImbalanceTradeAccountPeriod: IAccountPeriod;
  imbalanceTradesCollection: ImbalanceTradeCollection;
  getImbalanceTradesCollection: IRequestStatus<any>;

  imbalanceTrade: ImbalanceTrade | MappedImbalanceTrade;
  withdrawImbalanceTrade: IRequestStatus<any>;
  createImbalanceTrade: IRequestStatus<any>;
  updateImbalanceTrade: {
    status: IRequestStatus<any>;
    actionType: EImbalanceTradeActions;
  };
  authorizedImbalanceAccounts: IDataState<AuthorizedImbalanceAccountDetailCollection>;
  imbalanceTradeWindow: IDataState<TradeWindow.TradeWindowStatusEnum>;
  pipelinePosition: IDataState<ImbalancePipelinePosition>;

  // STORAGE BALANCE
  storageBalanceCollection: StorageBalanceCollection;
  getStorageBalanceCollection: IRequestStatus<any>;
  exportStorageBalance: IRequestStatus<any>;

  // Master Rate CICO Schedules
  masterRateCICOSchedule: IDataState<MasterRateCICOScheduleCollection>;

  //SHIPPER ACTIVITY
  shipperActivityCollection: IShipperActivity;
  getShipperActivityCollection: IRequestStatus<any>;
  exportShipperActivity: IRequestStatus<any>;

  //SHIPPER ACTIVITY CONTRACT
  shipperActivityContractCollection: SAContractCollection;
  getShipperActivityContractCollection: IRequestStatus<any>;
  exportShipperActivityContract: IRequestStatus<any>;

  //SHIPPER ACTIVITY MANUAL ADJUSTMENT
  editShipperActivity: IDataState<ShipperActivity>;

  //AUTHORIZATION TO POST
  authorizationtoPostImbalancesCollection: ImbalanceAuthorizationCollection;
  getAuthorizationtoPostImbalancesCollection: IRequestStatus<any>;

  putAuthorizationToPost: IDataState<ImbalanceAuthorization>;

  //SIGNOFF
  imbalanceAgreementSignoff: {
    requestDataState: IDataState;
    contractId: number;
    actionType: EImbalanceSignoffActions;
  };

  // IMBALANCE INVENTORY
  imbalanceInventoryCollection: IDataState<InventoryCollection>;

  // IMBALANCE INVENTORY
  acctImbalanceCollection: IDataState<InventoryCollection>;

  //OBA SUMMARY
  editObaSummary: IDataState<OperationalBalancingAgmt>;
  exportObaSummary: IRequestStatus<any>;
  obaSummary: {
    requestDataState: IDataState<OperationalBalancingAgmtCollection>;
  };
}

export const initialImbalancesState: IImbalancesState = {
  loading: true,
  error: null,
  imbalances: [],
  totalImbalancesCount: 0,

  imbalanceContact: null,

  // TODO: Stubbed for right now util we receive this from the imbalance summary card
  imbalanceStatementDate: new Date(),
  sortDescriptors: [
    {
      field: 'serviceRequestContractId',
      dir: 'asc',
    },
  ],
  pagination: getPagination(),

  imbalanceDetailsLoading: true,
  imbalanceDetailsError: null,
  imbalanceDetails: null,
  totalImbalanceDetailsCount: 0,
  imbalanceDetailsPagination: getPagination(),

  selectedImbalanceAccountingPeriod: null,

  imbalanceAccountingPeriodsLoading: false,
  imbalanceAccountingPeriodsError: null,
  imbalanceAccountingPeriods: null,

  imbalanceSummaryLoading: false,
  imbalanceSummaryError: null,
  imbalanceSummary: null,

  obaImbalances: [],
  obaImbalancesTotal: 0,
  obaImbalancesLoading: false,
  obaImbalancesError: null,

  exportImbalanceDetails: { httpError: null, loading: false },

  balancingImbalanceDetails: [],
  balancingImbalanceDetailsLoading: {},
  balancingImbalanceDetailsError: {},

  imbalanceTradesSummaryCollection: {
    imbalanceTradesSummary: [],
    total: null,
  },
  getImbalanceTradesSummaryCollection: { httpError: null, loading: false },

  focusImbalanceTradeAccountPeriod: null,
  imbalanceTradesCollection: {
    imbalanceTrades: [],
  },
  getImbalanceTradesCollection: { httpError: null, loading: false },

  imbalanceTrade: null,
  withdrawImbalanceTrade: { httpError: null, loading: false },
  createImbalanceTrade: { httpError: null, loading: false },
  updateImbalanceTrade: {
    status: { httpError: null, loading: false },
    actionType: null,
  },

  masterRateCICOSchedule: {
    data: null,
    requestStatus: { httpError: null, loading: false },
  },

  storageBalanceCollection: {
    balances: [],
  },
  getStorageBalanceCollection: { httpError: null, loading: false },
  exportStorageBalance: { httpError: null, loading: false },

  shipperActivityCollection: {
    shipperActivityCollection: {},
    tspConfig: {},
  },
  getShipperActivityCollection: { httpError: null, loading: false },
  exportShipperActivity: { httpError: null, loading: false },
  shipperActivityContractCollection: {
    contracts: [],
    total: null,
  },
  getShipperActivityContractCollection: { httpError: null, loading: false },
  exportShipperActivityContract: { httpError: null, loading: false },
  authorizationtoPostImbalancesCollection: {
    imbalanceAuthorizations: [],
    total: null,
  },
  getAuthorizationtoPostImbalancesCollection: { httpError: null, loading: false },
  putAuthorizationToPost: { data: null, requestStatus: { httpError: null, loading: false } },
  authorizedImbalanceAccounts: { data: null, requestStatus: { ...emptyRequestStatus } },

  imbalanceAgreementSignoff: {
    requestDataState: {
      data: null,
      requestStatus: { httpError: null, loading: false },
    },
    contractId: null,
    actionType: null,
  },

  imbalanceInventoryCollection: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },

  acctImbalanceCollection: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },

  editObaSummary: {
    data: null,
    requestStatus: { httpError: null, loading: false },
  },
  exportObaSummary: { httpError: null, loading: false },
  obaSummary: {
    requestDataState: {
      data: {
        operationalBalancingAgmts: [],
        total: 0,
      },
      requestStatus: { httpError: null, loading: false },
    },
  },
  imbalanceTradeWindow: { data: null, requestStatus: { ...emptyRequestStatus } },
  pipelinePosition: { data: null, requestStatus: { ...emptyRequestStatus } },
  editShipperActivity: {
    data: null,
    requestStatus: { httpError: null, loading: false },
  },
};
