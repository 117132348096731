import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuctionAwardService,
  AuctionEventService,
  AuctionNoticeService,
  AuctionParticipantService,
  AuctionReferenceTypesService,
  AuctionRoomService,
  AuctionSegmentService,
  AuctionService,
  AuctionStatusEnum,
  AuctionUsersService,
  ItOnlineService,
  TradeByIdService,
  TradesService,
} from '@gms/auction-api';
import { RateScheduleService } from '@gms/rateschedulev2-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from '@progress/kendo-file-saver';
import {
  formatCopyAuctionDraftToNewAuctionDraft,
  formatCopyAuctionToNewAuctionDraft,
} from 'app/modules/auctions/utils/auction.utils';
import { ELocationsGridTypes } from 'app/modules/it-online/components/locations-grid/locations-grid.utils';
import { ServiceTypeNameToIdMap } from 'app/modules/trades/utils/tradeblotter.utils';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { dateUtils } from 'shared/utils/date.utils';
import {
  CancelAuctionById,
  CancelAuctionByIdFailure,
  CancelAuctionByIdSuccess,
  CopyAuctionById,
  CopyAuctionByIdFailure,
  CopyAuctionByIdSuccess,
  CopyAuctionDraftById,
  CopyAuctionDraftByIdFailure,
  CopyAuctionDraftByIdSuccess,
  CreateAuctionParticipant,
  CreateAuctionParticipantFailure,
  CreateAuctionParticipantSuccess,
  DeleteAuctionDraft,
  DeleteAuctionDraftFailure,
  DeleteAuctionDraftSuccess,
  DeleteITOnlineLocation,
  DeleteITOnlineLocationFailure,
  DeleteITOnlineLocationSuccess,
  EAuctionsActions,
  ExportTrades,
  ExportTradesFailure,
  ExportTradesSuccess,
  GenerateAuctionNotices,
  GenerateAuctionNoticesFailure,
  GenerateAuctionNoticesSuccess,
  GetAuctionAwards,
  GetAuctionAwardsFailure,
  GetAuctionAwardsSuccess,
  GetAuctionBanner,
  GetAuctionBannerFailure,
  GetAuctionBannerSuccess,
  GetAuctionBidGrid,
  GetAuctionBidGridFailure,
  GetAuctionBidGridSuccess,
  GetAuctionById,
  GetAuctionByIdFailure,
  GetAuctionByIdSuccess,
  GetAuctionCollection,
  GetAuctionCollectionFailure,
  GetAuctionCollectionSuccess,
  GetAuctionDraftById,
  GetAuctionDraftByIdFailure,
  GetAuctionDraftByIdSuccess,
  GetAuctionEventPopoutPanel,
  GetAuctionEventPopoutPanelFailure,
  GetAuctionEventPopoutPanelSuccess,
  GetAuctionMessagePanel,
  GetAuctionMessagePanelFailure,
  GetAuctionMessagePanelSuccess,
  GetAuctionNoticeDocumentContent,
  GetAuctionNoticeDocumentContentFailure,
  GetAuctionNoticeDocumentContentSuccess,
  GetAuctionNotices,
  GetAuctionNoticesFailure,
  GetAuctionNoticesSuccess,
  GetAuctionParticipant,
  GetAuctionParticipantFailure,
  GetAuctionParticipants,
  GetAuctionParticipantsFailure,
  GetAuctionParticipantsSuccess,
  GetAuctionParticipantSuccess,
  GetAuctionRateScheduleDetails,
  GetAuctionRateScheduleDetailsFailure,
  GetAuctionRateScheduleDetailsSuccess,
  GetAuctionRateSchedules,
  GetAuctionRateSchedulesFailure,
  GetAuctionRateSchedulesSuccess,
  GetAuctionReferenceTypes,
  GetAuctionReferenceTypesFailure,
  GetAuctionReferenceTypesSuccess,
  GetAuctionSegments,
  GetAuctionSegmentsFailure,
  GetAuctionSegmentsSuccess,
  GetAuctionSeriesCollection,
  GetAuctionSeriesCollectionFailure,
  GetAuctionSeriesCollectionSuccess,
  GetAuctionUsers,
  GetAuctionUsersFailure,
  GetAuctionUsersSuccess,
  GetBiddingPeriodMappings,
  GetBiddingPeriodMappingsFailure,
  GetBiddingPeriodMappingsSuccess,
  GetCalculatedAwardByAuctionId,
  GetCalculatedAwardFailure,
  GetCalculatedAwardSuccess,
  GetItOnlineAdminCells,
  GetItOnlineAdminCellsFailure,
  GetItOnlineAdminCellsSuccess,
  GetItOnlineCells,
  GetItOnlineCellsFailure,
  GetItOnlineCellsSuccess,
  GetItOnlineDeliveryLocations,
  GetItOnlineDeliveryLocationsFailure,
  GetItOnlineDeliveryLocationsSuccess,
  GetItOnlineIceLocations,
  GetItOnlineIceLocationsFailure,
  GetItOnlineIceLocationsSuccess,
  GetItOnlineParameterSet,
  GetItOnlineParameterSetFailure,
  GetItOnlineParameterSetSuccess,
  GetItOnlineReceiptLocations,
  GetItOnlineReceiptLocationsFailure,
  GetItOnlineReceiptLocationsSuccess,
  GetTradeCollection,
  GetTradeCollectionFailure,
  GetTradeCollectionSuccess,
  PostAuction,
  PostAuctionAwardByAuctionId,
  PostAuctionAwardByAuctionIdFailure,
  PostAuctionAwardByAuctionIdSuccess,
  PostAuctionBid,
  PostAuctionBidFailure,
  PostAuctionBidSuccess,
  PostAuctionEvent,
  PostAuctionEventFailure,
  PostAuctionEventSuccess,
  PostAuctionFailure,
  PostAuctionNotice,
  PostAuctionNoticeFailure,
  PostAuctionNoticeSuccess,
  PostAuctionNoticeTemplate,
  PostAuctionNoticeTemplateFailure,
  PostAuctionNoticeTemplateSuccess,
  PostAuctionParticipantEnter,
  PostAuctionParticipantEnterFailure,
  PostAuctionParticipantEnterSuccess,
  PostAuctionSegment,
  PostAuctionSegmentFailure,
  PostAuctionSegmentSuccess,
  PostAuctionSuccess,
  PostItOnlineLocations,
  PostItOnlineLocationsFailure,
  PostItOnlineLocationsSuccess,
  PostTrade,
  PostTradeFailure,
  PostTradeSuccess,
  PutAuctionAwardById,
  PutAuctionAwardFailure,
  PutAuctionAwardSuccess,
  PutAuctionById,
  PutAuctionByIdFailure,
  PutAuctionByIdSuccess,
  PutAuctionNoticeById,
  PutAuctionNoticeByIdFailure,
  PutAuctionNoticeByIdSuccess,
  PutItOnlineParameterSet,
  PutItOnlineParameterSetFailure,
  PutItOnlineParameterSetSuccess,
  SaveAuctionDraft,
  SaveAuctionDraftFailure,
  SaveAuctionDraftSuccess,
  SearchAuctionUsers,
  SearchAuctionUsersFailure,
  SearchAuctionUsersSuccess,
  StartAuction,
  StartAuctionFailure,
  StartAuctionSuccess,
  SubmitTrades,
  SubmitTradesFailure,
  SubmitTradesSuccess,
  UpdateAuctionDraftById,
  UpdateAuctionDraftByIdFailure,
  UpdateAuctionDraftByIdSuccess,
  UpdateTradeById,
  UpdateTradeByIdFailure,
  UpdateTradeByIdSuccess,
  WithdrawAuctionBid,
  WithdrawAuctionBidFailure,
  WithdrawAuctionBidSuccess,
} from './auctions.actions';
import { sortDefinitions } from '../default-sort.definitions';

@Injectable({ providedIn: 'root' })
export class AuctionsEffects {
  constructor(
    private _actions$: Actions,
    private _auctionService: AuctionService,
    private _auctionAwardService: AuctionAwardService,
    private _auctionEventService: AuctionEventService,
    private _auctionSegmentService: AuctionSegmentService,
    private _rateScheduleService: RateScheduleService,
    private _referenceTypesService: AuctionReferenceTypesService,
    private _auctionNoticeService: AuctionNoticeService,
    private _auctionParticipantService: AuctionParticipantService,
    private _tradeService: TradesService,
    private _itOnlineService: ItOnlineService,
    private _tradeByIdService: TradeByIdService,
    private _auctionUserService: AuctionUsersService,
    private _auctionRoomService: AuctionRoomService
  ) {}

  getAuctionsCollection$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionCollection>(EAuctionsActions.GET_AUCTION_COLLECTION),
    map((action: GetAuctionCollection) => action),
    switchMap(action => {
      const { sortDescriptors } = action.payload;
      const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;
      return this._auctionService
        .getAuctions(
          action.payload.pageSize,
          action.payload.pageNumber,
          [sortBy],
          action.payload.auctionId,
          action.payload.series,
          action.payload.seriesAuctionId,
          action.payload.status,
          action.payload.dateContractBegin,
          action.payload.datePrimaryTermEnd,
          action.payload.dateTimeAuctionStart,
          action.payload.dateTimeAuctionEnd,
          action.payload.quantityOffered,
          action.payload.quantityAwarded,
          action.payload.tspId,
          action.payload.detailRateScheduleId,
          action.payload.auctionTypeId,
          action.payload.contractualRofrFlag,
          action.payload.auctioneerId,
          action.payload.dateTimeModified,
          action.payload.hideZeroQuantityClosed,
          action.payload.serviceRequestId
        )
        .pipe(
          map(response => new GetAuctionCollectionSuccess(response)),
          catchError(error => of(new GetAuctionCollectionFailure(error)))
        );
    })
  ));

  getAuctionsSeriesCollection$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionSeriesCollection>(EAuctionsActions.GET_AUCTION_SERIES_COLLECTION),
    map((action: GetAuctionSeriesCollection) => action),
    switchMap(action => {
      return this._auctionService
        .getAuctions(null, null, null, null, null, action.payload.seriesAuctionId, [
          AuctionStatusEnum.Pending,
        ])
        .pipe(
          map(response => new GetAuctionSeriesCollectionSuccess(response)),
          catchError(error => of(new GetAuctionSeriesCollectionFailure(error)))
        );
    })
  ));

  getTradesCollection$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetTradeCollection>(EAuctionsActions.GET_TRADE_COLLECTION),
    map((action: GetTradeCollection) => action),
    switchMap(action => {
      const {
        traderId,
        externalTraderId,
        tspId,
        offerDate,
        beginningOfferDate,
        serviceTypeId,
        itOnlineFlag,
        sortDescriptors,
        pageSize,
        pageNumber,
        entityId,
      } = action.payload;
      let sortBy = '';

      if (sortDescriptors) {
        sortBy = sortDescriptors
          .map(sortDescriptor => `${sortDescriptor.field}+${sortDescriptor.dir}`)
          .join('|');
      }
      sortBy = !!sortBy ? sortBy : sortDefinitions.getTrades;
      return this._tradeService
        .getTrades(
          traderId,
          externalTraderId,
          tspId,
          offerDate,
          beginningOfferDate,
          serviceTypeId,
          entityId,
          itOnlineFlag,
          pageSize,
          pageNumber,
          sortBy
        )
        .pipe(
          map(response => new GetTradeCollectionSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new GetTradeCollectionFailure(error)))
        );
    })
  ));

  postTrade$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostTrade>(EAuctionsActions.POST_TRADE),
    map((action: PostTrade) => action),
    switchMap(action => {
      return this._tradeService.postTrade(action.payload).pipe(
        map(response => new PostTradeSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostTradeFailure(error)))
      );
    })
  ));

  updateTradeById$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<UpdateTradeById>(EAuctionsActions.UPDATE_TRADE_BY_ID),
    map((action: UpdateTradeById) => action),
    switchMap(action =>
      this._tradeByIdService.putTrade(action.payload, action.payload.tradeId).pipe(
        map(response => new UpdateTradeByIdSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new UpdateTradeByIdFailure(error)))
      )
    )
  ));

  submitTrades$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<SubmitTrades>(EAuctionsActions.SUBMIT_TRADES),

    map((action: SubmitTrades) => action),
    mergeMap(action => {
      return forkJoin(
        action.payload.map(trade =>
          this._tradeByIdService
            .putTrade(trade, trade.tradeId)
            .pipe(catchError(error => of(new UpdateTradeByIdFailure(error))))
        )
      ).pipe(
        map(response => new SubmitTradesSuccess(response)),
        catchError(error => of(new SubmitTradesFailure(error)))
      );
    })
  ));

  cancelAuction$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CancelAuctionById>(EAuctionsActions.CANCEL_AUCTION_BY_ID),
    map((action: CancelAuctionById) => action),
    switchMap(action =>
      this._auctionService.postCancelAuction(action.payload).pipe(
        map(response => new CancelAuctionByIdSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new CancelAuctionByIdFailure(error)))
      )
    )
  ));

  getAuctionsRateSchedules$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionRateSchedules>(EAuctionsActions.GET_AUCTION_RATE_SCHEDULES),
    map((action: GetAuctionRateSchedules) => action),
    switchMap(action => {
      return this._auctionService.getAuctionRateSchedules().pipe(
        map(response => new GetAuctionRateSchedulesSuccess(response.auctionRateSchedules)),
        catchError(error => of(new GetAuctionRateSchedulesFailure(error)))
      );
    })
  ));

  getAuctionsRateScheduleDetails$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionRateScheduleDetails>(EAuctionsActions.GET_AUCTION_RATE_SCHEDULE_DETAILS),

    map((action: GetAuctionRateScheduleDetails) => action),
    mergeMap(action => {
      return forkJoin(
        action.payload.map(item =>
          this._rateScheduleService.getDetailRateScheduleById(
            Number(item.detailRateScheduleId),
            Number(item.tspId)
          )
        )
      ).pipe(
        map(response => new GetAuctionRateScheduleDetailsSuccess(response)),
        catchError(error => of(new GetAuctionRateScheduleDetailsFailure(error)))
      );
    })
  ));

  getBiddingPeriodMappings$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetBiddingPeriodMappings>(EAuctionsActions.GET_BIDDING_PERIOD_MAPPINGS),

    map((action: GetBiddingPeriodMappings) => action),
    switchMap(action => {
      return this._auctionService
        .getBiddingWindowMappings(
          action.payload.tspId,
          action.payload.masterRateScheduleId,
          action.payload.auctionTypeId,
          action.payload.multiYearTermFlag
        )
        .pipe(
          map(response => new GetBiddingPeriodMappingsSuccess(response)),
          catchError(error => of(new GetBiddingPeriodMappingsFailure(error)))
        );
    })
  ));

  saveAuction$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<SaveAuctionDraft>(EAuctionsActions.SAVE_AUCTION_DRAFT),
    map((action: SaveAuctionDraft) => action),
    switchMap(action => {
      return this._auctionService.postAuctionDraft(action.payload).pipe(
        map(response => new SaveAuctionDraftSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new SaveAuctionDraftFailure(error)))
      );
    })
  ));

  getReferenceTypes$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionReferenceTypes>(EAuctionsActions.GET_AUCTION_REFERENCE_TYPES),
    switchMap(() => {
      return this._referenceTypesService.getAuctionReferenceTypes().pipe(
        map(response => new GetAuctionReferenceTypesSuccess(response)),
        catchError(error => of(new GetAuctionReferenceTypesFailure(error)))
      );
    })
  ));

  deleteAuctionDraft$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<DeleteAuctionDraft>(EAuctionsActions.DELETE_AUCTION_DRAFT),
    map((action: DeleteAuctionDraft) => action.auctionDraftId),
    switchMap(auctionDraftId => {
      return this._auctionService.deleteAuctionDraft(auctionDraftId).pipe(
        map(() => new DeleteAuctionDraftSuccess()),
        catchError(error => of(new DeleteAuctionDraftFailure(error)))
      );
    })
  ));

  getAuctionDraftById$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionDraftById>(EAuctionsActions.GET_AUCTION_DRAFT_BY_ID),
    map((action: GetAuctionDraftById) => action),
    switchMap(action =>
      this._auctionService.getAuctionDraftById(action.payload).pipe(
        map(response => new GetAuctionDraftByIdSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetAuctionDraftByIdFailure(error)))
      )
    )
  ));

  getAuctionById$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionById>(EAuctionsActions.GET_AUCTION_BY_ID),
    map((action: GetAuctionById) => action),
    switchMap(action =>
      this._auctionService.getAuctionById(action.payload).pipe(
        map(response => new GetAuctionByIdSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetAuctionByIdFailure(error)))
      )
    )
  ));

  getAuctionNoticeDocumentContent$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionNoticeDocumentContent>(EAuctionsActions.GET_AUCTION_NOTICE_DOCUMENT_CONTENT),
    map((action: GetAuctionNoticeDocumentContent) => action),
    concatMap(action =>
      this._auctionNoticeService.getAuctionNoticeDocument(action.payload).pipe(
        map(response => new GetAuctionNoticeDocumentContentSuccess(response)),
        catchError((error: HttpErrorResponse) =>
          of(new GetAuctionNoticeDocumentContentFailure(error))
        )
      )
    )
  ));

  getAuctionNotices$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionNotices>(EAuctionsActions.GET_AUCTION_NOTICES),
    map((action: GetAuctionNotices) => action),
    mergeMap(action =>
      this._auctionNoticeService.getAuctionNotices(null, action.payload.auctionId).pipe(
        map(response => new GetAuctionNoticesSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetAuctionNoticesFailure(error)))
      )
    )
  ));

  CopyAuctionDraftById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CopyAuctionDraftById>(EAuctionsActions.COPY_AUCTION_DRAFT_BY_ID),
    map((action: CopyAuctionDraftById) => action.payload),
    switchMap(draftId => {
      return this._auctionService.getAuctionDraftById(draftId).pipe(
        switchMap(auctionDraft => {
          const newAuction = formatCopyAuctionDraftToNewAuctionDraft(auctionDraft);
          return this._auctionService.postAuctionDraft(newAuction).pipe(
            // If the post is successful, dispatch Copy Success with the auction object
            map(copiedAuction => {
              return new CopyAuctionDraftByIdSuccess(copiedAuction);
            }),
            catchError((error: HttpErrorResponse) => of(new CopyAuctionDraftByIdFailure(error)))
          );
        }),
        catchError((error: HttpErrorResponse) => of(new CopyAuctionDraftByIdFailure(error)))
      );
    })
  ));

  CopyAuctionById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CopyAuctionById>(EAuctionsActions.COPY_AUCTION_BY_ID),
    map((action: CopyAuctionById) => action.payload),
    switchMap(auctionId => {
      return this._auctionService.getAuctionById(auctionId).pipe(
        switchMap(auction => {
          const auctionDraftId = auction.auctionDraft.auctionDraftId;
          // We need to get the auctionDraft because the auction itself doesn't return the formatted serviceRequest object
          // This can be refactored once the Auction BE Endpoint returns it's auction draft with a ServiceRequest
          return this._auctionService.getAuctionDraftById(auctionDraftId).pipe(
            switchMap(auctionDraft => {
              const newAuction = formatCopyAuctionToNewAuctionDraft(auctionDraft, auction);
              return this._auctionService.postAuctionDraft(newAuction).pipe(
                map(copiedAuction => {
                  return new CopyAuctionByIdSuccess(copiedAuction);
                }),
                catchError((error: HttpErrorResponse) => of(new CopyAuctionByIdFailure(error)))
              );
            })
          );
        }),
        catchError((error: HttpErrorResponse) => of(new CopyAuctionByIdFailure(error)))
      );
    })
  ));

  updateAuctionDraftById: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<UpdateAuctionDraftById>(EAuctionsActions.UPDATE_AUCTION_DRAFT),
    map((action: UpdateAuctionDraftById) => action),
    switchMap(action =>
      this._auctionService.putAuctionDraft(action.payload, action.payload.auctionDraftId).pipe(
        map(auctionDraft => new UpdateAuctionDraftByIdSuccess(auctionDraft)),
        catchError((error: HttpErrorResponse) => of(new UpdateAuctionDraftByIdFailure(error)))
      )
    )
  ));

  postAuction$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuction>(EAuctionsActions.POST_AUCTION),
    map((action: PostAuction) => action),
    switchMap(action =>
      this._auctionService.postAuction(action.payload).pipe(
        map(response => new PostAuctionSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionFailure(error)))
      )
    )
  ));

  putAuctionById$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PutAuctionById>(EAuctionsActions.PUT_AUCTION),
    map((action: PutAuctionById) => action),
    switchMap(action =>
      this._auctionService
        .putAuction(action.payload, action.payload.auctionId, action.validateSettings)
        .pipe(
          map(response => new PutAuctionByIdSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new PutAuctionByIdFailure(error)))
        )
    )
  ));

  putAuctionNoticeById$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PutAuctionNoticeById>(EAuctionsActions.PUT_AUCTION_NOTICE),
    map((action: PutAuctionNoticeById) => action),
    switchMap(action =>
      this._auctionNoticeService
        .putAuctionNotice(action.payload, action.payload.auctionNoticeId, action.postNotice)
        .pipe(
          map(response => new PutAuctionNoticeByIdSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new PutAuctionNoticeByIdFailure(error)))
        )
    )
  ));

  postAuctionNotice$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuctionNotice>(EAuctionsActions.POST_AUCTION_NOTICE),
    map((action: PostAuctionNotice) => action),
    switchMap(action =>
      this._auctionNoticeService.postAuctionNotice(action.payload).pipe(
        map(response => new PostAuctionNoticeSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionNoticeFailure(error)))
      )
    )
  ));

  postAuctionEvent$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuctionEvent>(EAuctionsActions.POST_AUCTION_EVENT),
    map((action: PostAuctionEvent) => action),
    mergeMap(action =>
      this._auctionEventService.postAuctionEvent(action.payload).pipe(
        map(response => new PostAuctionEventSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionEventFailure(error)))
      )
    )
  ));

  postAuctionBid$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuctionBid>(EAuctionsActions.POST_AUCTION_BID),
    map((action: PostAuctionBid) => action),
    switchMap(action =>
      this._auctionEventService.postAuctionEvent(action.payload).pipe(
        map(response => new PostAuctionBidSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionBidFailure(error)))
      )
    )
  ));

  postWithdrawAuctionBid$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<WithdrawAuctionBid>(EAuctionsActions.WITHDRAW_AUCTION_BID),
    map((action: WithdrawAuctionBid) => action),
    switchMap(action =>
      this._auctionEventService.postAuctionEvent(action.payload).pipe(
        map(response => new WithdrawAuctionBidSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new WithdrawAuctionBidFailure(error)))
      )
    )
  ));

  postAuctionParticipantEnter$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuctionParticipantEnter>(EAuctionsActions.POST_PARTICIPANT_ENTER),
    map((action: PostAuctionParticipantEnter) => action),
    switchMap(action =>
      this._auctionEventService.postAuctionEvent(action.payload).pipe(
        map(response => new PostAuctionParticipantEnterSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionParticipantEnterFailure(error)))
      )
    )
  ));

  getAuctionPopupPanel$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionEventPopoutPanel>(EAuctionsActions.GET_AUCTION_EVENT_POPOUT_PANEL),
    map((action: GetAuctionEventPopoutPanel) => action),
    switchMap(action => {
      const { auctionId, auctionParticipantId } = action.payload;
      return this._auctionRoomService.getAuctionPopoutPanel(auctionId, auctionParticipantId).pipe(
        map(response => new GetAuctionEventPopoutPanelSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetAuctionEventPopoutPanelFailure(error)))
      );
    })
  ));

  getAuctionBidGrid$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionBidGrid>(EAuctionsActions.GET_AUCTION_BID_GRID),
    map((action: GetAuctionBidGrid) => action),
    switchMap(action => {
      const { auctionId, auctionParticipantId, userId } = action.payload;
      return this._auctionRoomService
        .getAuctionBidGrid(auctionId, auctionParticipantId, userId)
        .pipe(
          map(response => new GetAuctionBidGridSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new GetAuctionBidGridFailure(error)))
        );
    })
  ));

  postAuctionSegment$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuctionSegment>(EAuctionsActions.POST_AUCTION_SEGMENT),
    map((action: PostAuctionSegment) => action),
    switchMap(action => {
      return this._auctionSegmentService.postAuctionSegment(action.payload, 'response').pipe(
        map(response => new PostAuctionSegmentSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionSegmentFailure(error)))
      );
    })
  ));

  GetAuctionParticipants$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionParticipants>(EAuctionsActions.GET_AUCTION_PARTICIPANTS),
    map((action: GetAuctionParticipants) => action),
    switchMap(action => {
      return this._auctionRoomService.getAuctionParticipants(action.auctionId).pipe(
        map(response => new GetAuctionParticipantsSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetAuctionParticipantsFailure(error)))
      );
    })
  ));

  GetAuctionParticipant$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionParticipant>(EAuctionsActions.GET_AUCTION_PARTICIPANT),
    map((action: GetAuctionParticipant) => action),
    switchMap(action => {
      return this._auctionRoomService
        .getAuctionParticipant(action.payload.auctionId, action.payload.userId)
        .pipe(
          map(response => new GetAuctionParticipantSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new GetAuctionParticipantFailure(error)))
        );
    })
  ));

  PostAuctionParticipant$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CreateAuctionParticipant>(EAuctionsActions.CREATE_AUCTION_PARTICIPANT),
    map((action: CreateAuctionParticipant) => action),
    switchMap(action => {
      const { auctionId } = action.payload;
      return this._auctionParticipantService.postAuctionParticipant(action.payload, auctionId).pipe(
        map(response => new CreateAuctionParticipantSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new CreateAuctionParticipantFailure(error)))
      );
    })
  ));

  getAuctionSegments$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionSegments>(EAuctionsActions.GET_AUCTION_SEGMENTS),
    map((action: GetAuctionSegments) => action),
    switchMap(action => {
      const {
        pageSize,
        pageNumber,
        sortDescriptors,
        auctionId,
        auctionSegmentId,
        auctionSegmentTypeId,
        sequenceByType,
        targetDuration,
        rate,
        dateTimeEnd,
        dateTimeStart,
      } = action.payload;
      const sortBy = sortDescriptors.map(item => `${item.field}+${item.dir}`);
      return this._auctionSegmentService
        .getAuctionSegment(
          auctionId,
          pageSize,
          pageNumber,
          sortBy,
          auctionSegmentId,
          auctionSegmentTypeId,
          sequenceByType,
          targetDuration,
          rate,
          dateTimeStart,
          dateTimeEnd
        )
        .pipe(
          map(response => new GetAuctionSegmentsSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new GetAuctionSegmentsFailure(error)))
        );
    })
  ));

  getAuctionAwards$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionAwards>(EAuctionsActions.GET_AUCTION_AWARDS),
    map((action: GetAuctionAwards) => action),
    switchMap(action => {
      return this._auctionAwardService
        .getAuctionAwardByAuctionId(
          action.payload.auctionId,
          action.payload.auctionParticipantId,
          'response'
        )
        .pipe(
          map(response => new GetAuctionAwardsSuccess(response.body)),
          catchError((error: HttpErrorResponse) => of(new GetAuctionAwardsFailure(error)))
        );
    })
  ));

  getAuctionMessagePanel$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionMessagePanel>(EAuctionsActions.GET_AUCTION_MESSAGE_PANEL),
    map((action: GetAuctionMessagePanel) => action),
    switchMap(action => {
      const { auctionId } = action.payload;
      return this._auctionRoomService.getAuctionMessagePanel(auctionId).pipe(
        map(response => new GetAuctionMessagePanelSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetAuctionMessagePanelFailure(error)))
      );
    })
  ));

  getAuctionBanner$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionBanner>(EAuctionsActions.GET_AUCTION_BANNER),
    map((action: GetAuctionBanner) => action),
    switchMap(action => {
      const { auctionId, auctionParticipantId } = action.payload;
      return this._auctionRoomService.getAuctionBanner(auctionId, auctionParticipantId).pipe(
        map(response => new GetAuctionBannerSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetAuctionBannerFailure(error)))
      );
    })
  ));

  putAuctionAwardById$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PutAuctionAwardById>(EAuctionsActions.PUT_AUCTION_AWARD),
    mergeMap(action => {
      const { auctionId, auctionAwardId } = action.payload;
      return this._auctionAwardService
        .updateAuctionAward(action.payload, auctionId, auctionAwardId)
        .pipe(
          map(response => new PutAuctionAwardSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new PutAuctionAwardFailure(error)))
        );
    })
  ));

  getCalculatedAwardByAuctionId$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetCalculatedAwardByAuctionId>(EAuctionsActions.GET_CALCULATED_AWARD),
    map((action: GetCalculatedAwardByAuctionId) => action),
    switchMap(action => {
      return this._auctionAwardService.getCalculatedAwardByAuctionId(action.payload).pipe(
        map(response => new GetCalculatedAwardSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetCalculatedAwardFailure(error)))
      );
    })
  ));

  postAuctionAwardByAuctionId$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuctionAwardByAuctionId>(EAuctionsActions.POST_AUCTION_AWARDS),
    map((action: PostAuctionAwardByAuctionId) => action),
    switchMap(action => {
      const { awardCollection, auctionId } = action.payload;
      return this._auctionAwardService.postAuctionAwardByAuctionId(awardCollection, auctionId).pipe(
        map(response => new PostAuctionAwardByAuctionIdSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionAwardByAuctionIdFailure(error)))
      );
    })
  ));

  postItOnlineLocations$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostItOnlineLocations>(EAuctionsActions.POST_IT_ONLINE_LOCATIONS),
    map((action: PostItOnlineLocations) => action),
    switchMap(action => {
      const { iTOnlineLocationCollection } = action.payload;
      return this._itOnlineService.postItOnlineLocations(iTOnlineLocationCollection).pipe(
        map(response => new PostItOnlineLocationsSuccess()),
        catchError((error: HttpErrorResponse) => of(new PostItOnlineLocationsFailure(error)))
      );
    })
  ));

  postAuctionNoticeTemplate$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PostAuctionNoticeTemplate>(EAuctionsActions.POST_AUCTION_NOTICE_TEMPLATE),
    map((action: PostAuctionNoticeTemplate) => action),
    switchMap(action => {
      return this._auctionNoticeService.postAuctionNoticeTemplate(action.payload).pipe(
        map(response => new PostAuctionNoticeTemplateSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PostAuctionNoticeTemplateFailure(error)))
      );
    })
  ));

  exportTrades$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<ExportTrades>(EAuctionsActions.EXPORT_TRADES),
    map((action: ExportTrades) => action),
    switchMap(action => {
      const { serviceTypeId } = action.payload;
      const serviceTypeName = Object.keys(ServiceTypeNameToIdMap).find(
        key => ServiceTypeNameToIdMap[key] === serviceTypeId
      );
      return this._tradeService.getTradesExport(serviceTypeId).pipe(
        map(response => {
          const blob = new Blob([atob(response)], { type: 'text/csv' });
          saveAs(
            blob,
            `Tradeblotter - ${serviceTypeName} Export - ${dateUtils.getDateAsYYYY_MM_DD(
              new Date()
            )}.csv`
          );
          return new ExportTradesSuccess();
        }),
        catchError(error => of(new ExportTradesFailure(error)))
      );
    })
  ));

  getITOnlineReceiptLocations$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetItOnlineReceiptLocations>(EAuctionsActions.GET_IT_ONLINE_RECEIPT_LOCATIONS),
    map((action: GetItOnlineReceiptLocations) => action),
    switchMap(action => {
      const { tspId } = action.payload;
      return this._itOnlineService.getReceiptLocations(tspId).pipe(
        map(response => new GetItOnlineReceiptLocationsSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetItOnlineReceiptLocationsFailure(error)))
      );
    })
  ));

  getITOnlineDeliveryLocations$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetItOnlineDeliveryLocations>(EAuctionsActions.GET_IT_ONLINE_DELIVERY_LOCATIONS),
    map((action: GetItOnlineDeliveryLocations) => action),
    switchMap(action => {
      const { tspId } = action.payload;
      return this._itOnlineService.getDeliveryLocations(tspId).pipe(
        map(response => new GetItOnlineDeliveryLocationsSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetItOnlineDeliveryLocationsFailure(error)))
      );
    })
  ));

  getITOnlineIceLocations$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetItOnlineIceLocations>(EAuctionsActions.GET_IT_ONLINE_ICE_LOCATIONS),
    map((action: GetItOnlineIceLocations) => action),
    switchMap(() => {
      return this._itOnlineService.getICELocations().pipe(
        map(response => new GetItOnlineIceLocationsSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetItOnlineIceLocationsFailure(error)))
      );
    })
  ));

  getITOnlineAdminCells$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetItOnlineAdminCells>(EAuctionsActions.GET_IT_ONLINE_ADMIN_CELLS),
    map((action: GetItOnlineAdminCells) => action),
    switchMap(action => {
      const { tspId } = action.payload;
      return this._itOnlineService.getAdminCells(tspId, true, true, 999).pipe(
        map(response => new GetItOnlineAdminCellsSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetItOnlineAdminCellsFailure(error)))
      );
    })
  ));

  getITOnlineCells$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetItOnlineCells>(EAuctionsActions.GET_IT_ONLINE_CELLS),
    map((action: GetItOnlineCells) => action),
    switchMap(action => {
      const { tspId } = action.payload;
      return this._itOnlineService.getCells(tspId, true, true, 999).pipe(
        map(response => new GetItOnlineCellsSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetItOnlineCellsFailure(error)))
      );
    })
  ));

  getITOnlineParameterSet$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetItOnlineParameterSet>(EAuctionsActions.GET_IT_ONLINE_PARAMETER_SET),
    map((action: GetItOnlineParameterSet) => action),
    switchMap(() => {
      return this._itOnlineService.getParameterSets().pipe(
        map(response => new GetItOnlineParameterSetSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GetItOnlineParameterSetFailure(error)))
      );
    })
  ));

  putITOnlineParameterSet$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<PutItOnlineParameterSet>(EAuctionsActions.PUT_IT_ONLINE_PARAMETER_SET),
    map((action: PutItOnlineParameterSet) => action),
    switchMap(action => {
      return this._itOnlineService
        .putParameterSet(action.payload, action.payload.itOnlineParameterSetId)
        .pipe(
          map(response => new PutItOnlineParameterSetSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new PutItOnlineParameterSetFailure(error)))
        );
    })
  ));

  deleteITOnlineLocation$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<DeleteITOnlineLocation>(EAuctionsActions.DELETE_IT_ONLINE_LOCATION),
    map((action: DeleteITOnlineLocation) => action),
    switchMap(action => {
      const { location, gridType } = action.payload;
      if (gridType === ELocationsGridTypes.RECEIPT) {
        return this._itOnlineService.deleteReceiptLocationById(location.itOnlineLocationId).pipe(
          map(response => new DeleteITOnlineLocationSuccess()),
          catchError((error: HttpErrorResponse) => of(new DeleteITOnlineLocationFailure(error)))
        );
      } else if (gridType === ELocationsGridTypes.DELIVERY) {
        return this._itOnlineService.deleteDeliveryLocationById(location.itOnlineLocationId).pipe(
          map(response => new DeleteITOnlineLocationSuccess()),
          catchError((error: HttpErrorResponse) => of(new DeleteITOnlineLocationFailure(error)))
        );
      }
    })
  ));

  getAuctionUsers$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetAuctionUsers>(EAuctionsActions.GET_AUCTION_USERS),
    switchMap(action => {
      const { pageNumber, pageSize, sortDescriptors, userSearch, entityId, roleIds } = action.payload;

      const sortBy = (sortDescriptors || []).map(item => `${item.field}+${item.dir}`);

      return this._auctionUserService
        .getAuctionUsers(pageSize, pageNumber, sortBy, userSearch, entityId, roleIds)
        .pipe(
          map(response => new GetAuctionUsersSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new GetAuctionUsersFailure(error)))
        );
    })
  ));

  searchAuctionUsers$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<SearchAuctionUsers>(EAuctionsActions.SEARCH_AUCTION_USERS),
    switchMap(action => {
      const { pageNumber, pageSize, sortDescriptors, userSearch, entityId, roleIds } = action.payload;
      const sortBy = (sortDescriptors || []).map(item => `${item.field}+${item.dir}`);

      return this._auctionUserService
        .getAuctionUsers(pageSize, pageNumber, sortBy, userSearch, entityId, roleIds)
        .pipe(
          map(response => new SearchAuctionUsersSuccess(response)),
          catchError((error: HttpErrorResponse) => of(new SearchAuctionUsersFailure(error)))
        );
    })
  ));

  generateAuctionNotices$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GenerateAuctionNotices>(EAuctionsActions.GENERATE_AUCTION_NOTICES),
    switchMap(action => {
      return this._auctionNoticeService.generateAuctionNotice(action.payload).pipe(
        map(response => new GenerateAuctionNoticesSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new GenerateAuctionNoticesFailure(error)))
      );
    })
  ));

  startAuction$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<StartAuction>(EAuctionsActions.START_AUCTION),
    switchMap(action => {
      return this._auctionRoomService.startAuction(action.payload, action.payload.auctionId).pipe(
        map(response => new StartAuctionSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new StartAuctionFailure(error)))
      );
    })
  ));
}
