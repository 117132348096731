import { HttpErrorResponse } from '@angular/common/http';
import {
  Award,
  AwardCollection,
  Bid,
  BidCollection,
  DictionaryModel,
  LocationOptionsResponse,
  Offer,
  OfferCollection,
  OffersSearchLookup,
  WithdrawalCollection,
} from '@gms/capacityrelease-api';
import { ContractOverview } from '@gms/contract-api';
import { Entity } from '@gms/entity-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { BidderViewModesEnum } from 'app/modules/capacity-release/pages/bids/bids.enums';
import {
  OfferLocationOptionsPayload,
  OfferViewModesEnum,
} from 'app/modules/capacity-release/pages/offers/offers.enums';
import { RetryableAction } from '../retry/retry.actions';

export enum ECapacityReleaseActions {
  FetchOffers = '[CAPACITY_RELEASE] Fetch Offers',
  FetchOffersSuccess = '[CAPACITY_RELEASE] Fetch Offers Success',
  FetchOffersFailure = '[CAPACITY_RELEASE] Fetch Offers Failure',
  FetchBiddableOffers = '[CAPACITY_RELEASE] Fetch Biddable Offers',
  FetchBiddableOffersSuccess = '[CAPACITY_RELEASE] Fetch Biddable Offers Success',
  FetchBiddableOffersFailure = '[CAPACITY_RELEASE] Fetch Biddable Offers Failure',
  FetchOfferById = '[CAPACITY_RELEASE] Fetch Offer by Id',
  FetchOfferByIdSuccess = '[CAPACITY_RELEASE] Fetch Offer by Id Success',
  FetchOfferByIdFailure = '[CAPACITY_RELEASE] Fetch Offer by Id Failure',
  FetchBiddableOfferById = '[CAPACITY_RELEASE] Fetch Biddable Offer by Id',
  FetchBiddableOfferByIdSuccess = '[CAPACITY_RELEASE] Fetch Biddable Offer by Id Success',
  FetchBiddableOfferByIdFailure = '[CAPACITY_RELEASE] Fetch Biddable Offer by Id Failure',
  FetchWithdrawals = '[CAPACITY_RELEASE] Fetch Withdrawals',
  FetchWithdrawalsSuccess = '[CAPACITY_RELEASE] Fetch Withdrawals Success',
  FetchWithdrawalsFailure = '[CAPACITY_RELEASE] Fetch Withdrawals Failure',
  FetchDictionary = '[CAPACITY_RELEASE] Fetch Dictionary',
  FetchDictionarySuccess = '[CAPACITY_RELEASE] Fetch Dictionary Success',
  FetchDictionaryFailure = '[CAPACITY_RELEASE] Fetch Dictionary Failure',
  PostOffer = '[CAPACITY_RELEASE] Post Offer',
  PostOfferSuccess = '[CAPACITY_RELEASE] Post Offer Success',
  PostOfferFailure = '[CAPACITY_RELEASE] Post Offer Failure',
  GetOffersLookup = '[CAPACITY_RELEASE] Get Offer Lookup',
  GetOffersLookupSuccess = '[CAPACITY_RELEASE] Get Offer Lookup Success',
  GetOffersLookupFailure = '[CAPACITY_RELEASE] Get Offer Lookup Failure',
  PutOffer = '[CAPACITY_RELEASE] Put Offer',
  PutOfferSuccess = '[CAPACITY_RELEASE] Put Offer Success',
  PutOfferFailure = '[CAPACITY_RELEASE] Put Offer Failure',
  ClearOffer = '[CAPACITY_RELEASE] Clear Offer',
  ClearOffersCollection = '[CAPACITY_RELEASE] Clear Offers Collection',
  DeleteOffer = '[CAPACITY_RELEASE] Delete Offer',
  DeleteOfferSuccess = '[CAPACITY_RELEASE] Delete Offer Success',
  DeleteOfferFailure = '[CAPACITY_RELEASE] Delete Offer Failure',
  WithdrawOffer = '[CAPACITY_RELEASE] Withdraw Offer',
  WithdrawOfferSuccess = '[CAPACITY_RELEASE] Withdraw Offer Success',
  WithdrawOfferFailure = '[CAPACITY_RELEASE] Withdraw Offer Failure',
  ConfirmBid = '[CAPACITY_RELEASE] Confirm Bid',
  ConfirmBidSuccess = '[CAPACITY_RELEASE] Confirm Bid Success',
  ConfirmBidFailure = '[CAPACITY_RELEASE] Confirm Bid Failure',
  ResetConfirmBidStatus = '[CAPACITY_RELEASE] Reset Confirm Bid Status',
  RejectBid = '[CAPACITY_RELEASE] Reject Bid',
  RejectBidSuccess = '[CAPACITY_RELEASE] Reject Bid Success',
  RejectBidFailure = '[CAPACITY_RELEASE] Reject Bid Failure',
  ResetRejectBidStatus = '[CAPACITY_RELEASE] Reset Reject Bid Status',
  ResetDeleteOfferStatus = '[CAPACITY_RELEASE] Reset Delete Offer Status',
  ResetWithdrawOfferStatus = '[CAPACITY_RELEASE] Reset Withdraw Offer Status',
  CopyOffer = '[CAPACITY_RELEASE] Copy Offer',
  CopyOfferSuccess = '[CAPACITY_RELEASE] Copy Offer Success',
  CopyOfferFailure = '[CAPACITY_RELEASE] Copy Offer Failure',
  FetchBids = '[CAPACITY_RELEASE] Fetch Bids',
  FetchBidsSuccess = '[CAPACITY_RELEASE] Fetch Bids Success',
  FetchBidsFailure = '[CAPACITY_RELEASE] Fetch Bids Failure',
  ClearBids = '[CAPACITY_RELEASE] Clear Bids',
  SetBidderViewMode = '[CAPACITY_RELEASE] Set Bidder View Mode',
  SET_OFFER_VIEW_MODE = '[CAPACITY_RELEASE] Set Offer View Mode',
  CLEAR_OFFER_VIEW_MODE = '[CAPACITY_RELEASE] Clear Offer View Mode',
  FetchBidsForFilters = '[CAPACITY RELEASE] Fetch Bids For Filters',
  FetchBidsForFiltersSuccess = '[CAPACITY RELEASE] Fetch Bids For Filters Success',
  FetchBidsForFiltersFailure = '[CAPACITY RELEASE] Fetch Bids For Filters Failure',
  RESET_BIDS_FOR_FILTERS = '[CAPACITY RELEASE] Reset Bids For Filters',
  ClearBid = '[CAPACITY_RELEASE] Clear Bid',
  FetchBidById = '[CAPACITY_RELEASE] Fetch Bid by Id',
  FetchBidByIdSuccess = '[CAPACITY_RELEASE] Fetch Bid by Id Success',
  FetchBidByIdFailure = '[CAPACITY_RELEASE] Fetch Bid by Id Failure',
  DeleteBid = '[CAPACITY_RELEASE] Delete Bid',
  DeleteBidSuccess = '[CAPACITY_RELEASE] Delete Bid Success',
  DeleteBidFailure = '[CAPACITY_RELEASE] Delete Bid Failure',
  ResetDeleteBidStatus = '[CAPACITY_RELEASE] Reset Delete Bid Status',
  WithdrawBid = '[CAPACITY_RELEASE] Withdraw Bid',
  WithdrawBidSuccess = '[CAPACITY_RELEASE] Withdraw Bid Success',
  WithdrawBidFailure = '[CAPACITY_RELEASE] Withdraw Bid Failure',
  ResetWithdrawBidStatus = '[CAPACITY_RELEASE] Reset Withdraw Bid Status',
  FetchOfferBids = '[CAPACITY_RELEASE] Fetch Offer Bids',
  FetchOfferBidsSuccess = '[CAPACITY_RELEASE] Fetch Offer Bids Success',
  FetchOfferBidsFailure = '[CAPACITY_RELEASE] Fetch Offer Bids Failure',
  ClearOfferBids = '[CAPACITY_RELEASE] Clear Offer Bids',
  PostBid = '[CAPACITY_RELEASE] Post Bid',
  PostBidSuccess = '[CAPACITY_RELEASE] Post Bid Success',
  PostBidFailure = '[CAPACITY_RELEASE] Post Bid Failure',
  PutBid = '[CAPACITY_RELEASE] Put Bid',
  PutBidSuccess = '[CAPACITY_RELEASE] Put Bid Success',
  PutBidFailure = '[CAPACITY_RELEASE] Put Bid Failure',
  FetchOfferReleasers = '[CAPACITY_RELEASE] Fetch Offer Releasers',
  FetchOfferReleasersSuccess = '[CAPACITY_RELEASE] Fetch Offer Releasers Success',
  FetchOfferReleasersFailure = '[CAPACITY_RELEASE] Fetch Offer Releasers Failure',
  ClearOfferReleasers = '[CAPACITY_RELEASE] Clear Offer Releasers',
  FetchWithdrawnBids = '[CAPACITY_RELEASE] Fetch Withdrawn Bids',
  FetchWithdrawnBidsSuccess = '[CAPACITY_RELEASE] Fetch Withdrawn Bids Success',
  FetchWithdrawnBidsFailure = '[CAPACITY_RELEASE] Fetch Withdrawn Bids Failure',
  ClearWithdrawnBids = '[CAPACITY_RELEASE] Clear Withdrawn Bids',
  FetchAwards = '[CAPACITY_RELEASE] Fetch Awards',
  FetchAwardsSuccess = '[CAPACITY_RELEASE] Fetch Awards Success',
  FetchAwardsFailure = '[CAPACITY_RELEASE] Fetch Awards Failure',
  FetchAwardsForFilters = '[CAPACITY RELEASE] Fetch Awards For Filters',
  FetchAwardsForFiltersSuccess = '[CAPACITY RELEASE] Fetch Awards For Filters Success',
  FetchAwardsForFiltersFailure = '[CAPACITY RELEASE] Fetch Awards For Filters Failure',
  FetchAwardOffer = '[CAPACITY_RELEASE] Fetch Award Offer',
  FetchAwardOfferFailure = '[CAPACITY_RELEASE] Fetch Award Offer Failure',
  FetchAwardOfferSuccess = '[CAPACITY_RELEASE] Fetch Award Offer Success',
  SetReleaser = '[CAPACITY_RELEASE] Set Releaser',
  SetReleaserOptions = '[CAPACITY_RELEASE] Set Releaser Options',
  FetchBidEvaluationOffers = '[CAPACITY_RELEASE] Fetch Bid Evaluation Offers',
  FetchBidEvaluationOffersSuccess = '[CAPACITY_RELEASE] Fetch Bid Evaluation Offers Success',
  FetchBidEvaluationOffersFailure = '[CAPACITY_RELEASE] Fetch Bid Evaluation Offers Failure',
  FetchAwardById = '[CAPACITY_RELEASE] Fetch Original Award by Id',
  FetchAwardByIdSuccess = '[CAPACITY_RELEASE] Fetch Original Award by Id Success',
  FetchAwardByIdFailure = '[CAPACITY_RELEASE] Fetch Original Award by Id Failure',
  FetchOriginalAwardById = '[CAPACITY_RELEASE] Fetch Award by Id',
  FetchOriginalAwardByIdSuccess = '[CAPACITY_RELEASE] Fetch Award by Id Success',
  FetchOriginalAwardByIdFailure = '[CAPACITY_RELEASE] Fetch Award by Id Failure',
  FetchLinkedAwardById = '[CAPACITY_RELEASE] Fetch Linked Award by Id',
  FetchLinkedAwardByIdSuccess = '[CAPACITY_RELEASE] Fetch Linked Award by Id Success',
  FetchLinkedAwardByIdFailure = '[CAPACITY_RELEASE] Fetch Linked Award by Id Failure',
  FetchAwardNetQuantitiesById = '[CAPACITY_RELEASE] Fetch Award Net Quantities by Id',
  FetchAwardNetQuantitiesByIdSuccess = '[CAPACITY_RELEASE] Fetch Award Net Quantities by Id Success',
  FetchAwardNetQuantitiesByIdFailure = '[CAPACITY_RELEASE] Fetch Award Net Quantities by Id Failure',
  FetchAwardSeededLocationsById = '[CAPACITY_RELEASE] Fetch Award Seeded Locations by Id',
  FetchAwardSeededLocationsByIdSuccess = '[CAPACITY_RELEASE] Fetch Award Seeded Locations by Id Success',
  FetchAwardSeededLocationsByIdFailure = '[CAPACITY_RELEASE] Fetch Award Seeded Locations by Id Failure',
  PostAward = '[CAPACITY_RELEASE] Create Award',
  PostAwardSuccess = '[CAPACITY_RELEASE] Create Award Success',
  PostAwardFailure = '[CAPACITY_RELEASE] Create Award Failure',
  PutAward = '[CAPACITY_RELEASE] Put Award',
  PutAwardSuccess = '[CAPACITY_RELEASE] Put Award Success',
  PutAwardFailure = '[CAPACITY_RELEASE] Put Award Failure',
  ClearAward = '[CAPACITY_RELEASE] Clear Award',
  ClearBidEvaluationOffers = '[CAPACITY_RELEASE] Clear Bid Evaluation Offers',
  FetchBidEvaluationBids = '[CAPACITY_RELEASE] Fetch Bid Evaluation Bids',
  FetchBidEvaluationBidsSuccess = '[CAPACITY_RELEASE] Fetch Bid Evaluation Bids Success',
  FetchBidEvaluationBidsFailure = '[CAPACITY_RELEASE] Fetch Bid Evaluation Bids Failure',
  FetchBidEvaluationAwards = '[CAPACITY_RELEASE] Fetch Bid Evaluation Awards',
  FetchBidEvaluationAwardsSuccess = '[CAPACITY_RELEASE] Fetch Bid Evaluation Awards Success',
  FetchBidEvaluationAwardsFailure = '[CAPACITY_RELEASE] Fetch Bid Evaluation Awards Failure',
  ClearBidEvaluationBids = '[CAPACITY_RELEASE] Clear Bid Evaluation Bids',
  ClearBidEvaluationAwards = '[CAPACITY_RELEASE] Clear Bid Evaluation Awards',
  CreateMatchingBid = '[CAPACITY_RELEASE] Create Matching Bid',
  CreateMatchingBidFailure = '[CAPACITY_RELEASE] Create Matching Bid Failure',
  CreateMatchingBidSuccess = '[CAPACITY_RELEASE] Create Matching Bid Success',
  FetchContractOverview = '[CAPACITY_RELEASE] Fetch Offer Contract Overview',
  FetchContractOverviewSuccess = '[CAPACITY_RELEASE] Fetch Offer Contract Overview Success',
  FetchContractOverviewFailure = '[CAPACITY_RELEASE] Fetch Offer Contract Overview Failure',
  FetchOfferLocationOptions = '[CAPACITY_RELEASE] Fetch Offer Location Options',
  FetchOfferLocationOptionsSuccess = '[CAPACITY_RELEASE] Fetch Offer Location Options Success',
  FetchOfferLocationOptionsFailure = '[CAPACITY_RELEASE] Fetch Offer Location Options Failure',
  ClearOfferLocationOptions = '[CAPACITY_RELEASE] Clearing Offer Location Options',
  FetchPreapprovedBidders = '[CAPACITY_RELEASE] Fetch Preapproved Bidders',
  FetchPreapprovedBiddersSuccess = '[CAPACITY_RELEASE] Fetch Preapproved Bidders Success',
  FetchPreapprovedBiddersFailure = '[CAPACITY_RELEASE] Fetch Preapproved Bidders Failure',
  ClearPreapprovedBidders = '[CAPACITY_RELEASE] Clear Preapproved Bidders',
  FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS = '[CAPACITY RELEASE] Fetch multiple offer locations options',
  FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS_SUCCESS = '[CAPACITY RELEASE] Fetch multiple offer locations options success',
  FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS_FAILURE = '[CAPACITY RELEASE] Fetch multiple offer locations options failure',
  SET_PARENT_OFFER = '[CAPACITY RELEASE] Set Parent Offer',
  CLEAR_RELEASER = '[CAPACITY RELEASE] Clear Releaser',
}

export class FetchOffers extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchOffers;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors?: SortDescriptor[];
      entityIds: string[];
      // releaserId?: number;
      contractId?: number;
      offerId?: number;
      postingDt?: string;
      releaseTermStartDt?: string;
      releaseTermEndDt?: string;
      biddableFlag?: boolean;
      busDayFlag?: boolean;
      marketRateFlag?: boolean;
      recallNotifPeriods?: Array<number>;
      offerStatus?: number;
      processStatus?: number;
      releaseStatus?: number;
      biddableOnly?: boolean;
      tspId?: number;
      searchOffers?: boolean;
      viewMode?: string;
      includeLocations?: boolean;
    }
  ) {
    super(payload);
  }
}

export class FetchOffersSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchOffersSuccess;

  constructor(
    public payload: {
      offers: OfferCollection;
      searchOffers?: boolean;
    }
  ) {}
}

export class FetchOffersFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchOffersFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchBiddableOffers extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchBiddableOffers;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors?: SortDescriptor[];
      entityIds: string[];
      // releaserId?: number;
      contractId?: number;
      contractNumber?: number,
      offerId?: number;
      postingDt?: string;
      releaseTermStartDt?: string;
      releaseTermEndDt?: string;
      biddableFlag?: boolean;
      busDayFlag?: boolean;
      marketRateFlag?: boolean;
      recallNotifPeriods?: Array<number>;
      offerStatus?: number;
      processStatus?: number;
      releaseStatus?: number;
      biddableOnly?: boolean;
      tspId?: number;
      searchOffers?: boolean;
      viewMode?: string;
      includeLocations?: boolean;
    }
  ) {
    super(payload);
  }
}

export class FetchBiddableOffersFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBiddableOffersFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchBiddableOffersSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBiddableOffersSuccess;

  constructor(
    public payload: {
      offers: OfferCollection;
      searchOffers?: boolean;
    }
  ) {}
}

export class FetchOfferById implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferById;

  constructor(public payload: { offerId: number; viewMode?: string }) {}
}

export class FetchOfferByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferByIdSuccess;

  constructor(public payload: { offer: Offer }) {}
}

export class FetchOfferByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchBiddableOfferById implements Action {
  public readonly type = ECapacityReleaseActions.FetchBiddableOfferById;

  constructor(public payload: { offerId: number; viewMode?: string }) {}
}

export class FetchBiddableOfferByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBiddableOfferByIdSuccess;

  constructor(public payload: { offer: Offer }) {}
}

export class FetchBiddableOfferByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBiddableOfferByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchWithdrawals extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchWithdrawals;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortBy?: Array<string>;
      capacityReleaseTypeId?: Array<number>;
      transactionTypeId?: Array<number>;
      tspId?: number;
      offerId?: number;
      bidId?: number;
      awardId?: number;
      postingDt?: string;
    }
  ) {
    super(payload);
  }
}

export class FetchWithdrawalsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchWithdrawalsSuccess;

  constructor(public payload: { withdrawals: WithdrawalCollection }) {}
}

export class FetchWithdrawalsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchWithdrawalsFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchDictionary implements Action {
  public readonly type = ECapacityReleaseActions.FetchDictionary;
}

export class FetchDictionarySuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchDictionarySuccess;

  constructor(public payload: { dictionary: DictionaryModel }) {}
}

export class FetchDictionaryFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchDictionaryFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class PostOffer implements Action {
  public readonly type = ECapacityReleaseActions.PostOffer;

  constructor(public payload: { offer: Offer }) {}
}

export class PostOfferSuccess implements Action {
  public readonly type = ECapacityReleaseActions.PostOfferSuccess;

  constructor(public payload: { offer: Offer }) {}
}

export class PostOfferFailure implements Action {
  public readonly type = ECapacityReleaseActions.PostOfferFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class GetOffersLookup implements Action {
  public readonly type = ECapacityReleaseActions.GetOffersLookup;

  constructor(
    public payload: {
      entityIds: string[];
      contractNumbers: string[];
    }
  ) {}
}

export class GetOffersLookupSuccess implements Action {
  public readonly type = ECapacityReleaseActions.GetOffersLookupSuccess;

  constructor(public payload: { offersSearchLookup: OffersSearchLookup }) {}
}

export class GetOffersLookupFailure implements Action {
  public readonly type = ECapacityReleaseActions.GetOffersLookupFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class PutOffer implements Action {
  public readonly type = ECapacityReleaseActions.PutOffer;

  constructor(public payload: { offer: Offer; step?: number; isDraft?: boolean, locationsModified?: boolean }) {}
}

export class PutOfferSuccess implements Action {
  public readonly type = ECapacityReleaseActions.PutOfferSuccess;

  constructor(public payload: { offer: Offer }) {}
}

export class PutOfferFailure implements Action {
  public readonly type = ECapacityReleaseActions.PutOfferFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ClearOffer implements Action {
  public readonly type = ECapacityReleaseActions.ClearOffer;
}

export class ClearOffersCollection implements Action {
  public readonly type = ECapacityReleaseActions.ClearOffersCollection;
}

export class DeleteOffer implements Action {
  public readonly type = ECapacityReleaseActions.DeleteOffer;

  constructor(public payload: { offerId: number }) {}
}

export class DeleteOfferSuccess implements Action {
  public readonly type = ECapacityReleaseActions.DeleteOfferSuccess;
}

export class DeleteOfferFailure implements Action {
  public readonly type = ECapacityReleaseActions.DeleteOfferFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ResetDeleteOfferStatus implements Action {
  public readonly type = ECapacityReleaseActions.ResetDeleteOfferStatus;
}

export class WithdrawOffer implements Action {
  public readonly type = ECapacityReleaseActions.WithdrawOffer;

  constructor(public payload: { offerId: number }) {}
}

export class WithdrawOfferSuccess implements Action {
  public readonly type = ECapacityReleaseActions.WithdrawOfferSuccess;
}

export class WithdrawOfferFailure implements Action {
  public readonly type = ECapacityReleaseActions.WithdrawOfferFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ResetWithdrawOfferStatus implements Action {
  public readonly type = ECapacityReleaseActions.ResetWithdrawOfferStatus;
}

export class CopyOffer extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.CopyOffer;

  constructor(public payload: { offerId: number; offerCreatorId: number }) {
    super(payload);
  }
}

export class CopyOfferSuccess implements Action {
  public readonly type = ECapacityReleaseActions.CopyOfferSuccess;

  constructor(public payload: { offer: Offer }) {}
}

export class CopyOfferFailure implements Action {
  public readonly type = ECapacityReleaseActions.CopyOfferFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchBids extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchBids;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      bidderIds?: number[];
      myBidsOnly?: boolean;
      bidNumber?: number;
      offerNumber?: number;
      bidStatus?: number[];
      releaserId?: number;
      contractId?: number;
      contractNumber?: number;
      bidderReleaseTermStartDate?: string;
      bidderReleaseTermEndDate?: string;
      prearrangedFlag?: boolean;
      postedBeginDateTime?: Date;
    }
  ) {
    super(payload);
  }
}

export class FetchBidsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidsSuccess;

  constructor(public payload: { bidsCollection: BidCollection }) {}
}

export class FetchBidsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidsFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ConfirmBid implements Action {
  public readonly type = ECapacityReleaseActions.ConfirmBid;

  constructor(public payload: { offerId: number; bidId: number }) {}
}

export class ConfirmBidSuccess implements Action {
  public readonly type = ECapacityReleaseActions.ConfirmBidSuccess;

  constructor(public payload: { bid: Bid }) {}
}

export class ConfirmBidFailure implements Action {
  public readonly type = ECapacityReleaseActions.ConfirmBidFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ResetConfirmBidStatus implements Action {
  public readonly type = ECapacityReleaseActions.ResetConfirmBidStatus;
}

export class RejectBid implements Action {
  public readonly type = ECapacityReleaseActions.RejectBid;

  constructor(public payload: { offerId: number; bidId: number }) {}
}

export class RejectBidSuccess implements Action {
  public readonly type = ECapacityReleaseActions.RejectBidSuccess;

  constructor(public payload: { bid: Bid }) {}
}

export class RejectBidFailure implements Action {
  public readonly type = ECapacityReleaseActions.RejectBidFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ResetRejectBidStatus implements Action {
  public readonly type = ECapacityReleaseActions.ResetRejectBidStatus;
}

export class ClearBids implements Action {
  public readonly type = ECapacityReleaseActions.ClearBids;
}

export class SetBidderViewMode implements Action {
  public readonly type = ECapacityReleaseActions.SetBidderViewMode;

  constructor(public payload: { bidderViewMode: BidderViewModesEnum }) {}
}

export class SetOfferViewMode implements Action {
  public readonly type = ECapacityReleaseActions.SET_OFFER_VIEW_MODE;

  constructor(public payload: { offerViewMode: OfferViewModesEnum }) {}
}

export class ClearOfferViewMode implements Action {
  public readonly type = ECapacityReleaseActions.CLEAR_OFFER_VIEW_MODE;
}

export class ResetBidsForFilters implements Action {
  public readonly type = ECapacityReleaseActions.RESET_BIDS_FOR_FILTERS;

  constructor(public payload: { bids: Bid[] }) {}
}

export class FetchBidsForFilters extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidsForFilters;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      bidderIds?: number[];
      myBidsOnly?: boolean;
      bidNumber?: number;
      offerNumber?: number;
      bidStatus?: number[];
      releaserId?: number;
      contractId?: number;
      contractNumber?: number;
      postedBeginDateTime?: Date;
    }
  ) {
    super(payload);
  }
}

export class FetchBidsForFiltersSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidsForFiltersSuccess;

  constructor(public payload: { bids: Bid[] }) {}
}

export class FetchBidsForFiltersFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidsForFiltersFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchBidById extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidById;

  constructor(public payload: { offerId: number; bidId: number }) {
    super(payload);
  }
}

export class FetchBidByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidByIdSuccess;

  constructor(public payload: { bid: Bid }) {}
}

export class FetchBidByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DeleteBid implements Action {
  public readonly type = ECapacityReleaseActions.DeleteBid;

  constructor(public payload: { offerId: number; bidId: number }) {}
}

export class DeleteBidSuccess implements Action {
  public readonly type = ECapacityReleaseActions.DeleteBidSuccess;
}

export class DeleteBidFailure implements Action {
  public readonly type = ECapacityReleaseActions.DeleteBidFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ResetDeleteBidStatus implements Action {
  public readonly type = ECapacityReleaseActions.ResetDeleteBidStatus;
}

export class WithdrawBid implements Action {
  public readonly type = ECapacityReleaseActions.WithdrawBid;

  constructor(public payload: { bidId: number }) {}
}

export class WithdrawBidSuccess implements Action {
  public readonly type = ECapacityReleaseActions.WithdrawBidSuccess;

  constructor(public payload: { bid: Bid }) {}
}

export class WithdrawBidFailure implements Action {
  public readonly type = ECapacityReleaseActions.WithdrawBidFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ResetWithdrawBidStatus implements Action {
  public readonly type = ECapacityReleaseActions.ResetWithdrawBidStatus;
}

export class PostBid extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.PostBid;

  constructor(public payload: { bid: Bid }) {
    super(payload);
  }
}

export class PostBidSuccess implements Action {
  public readonly type = ECapacityReleaseActions.PostBidSuccess;

  constructor(public payload: { bid: Bid }) {}
}

export class PostBidFailure implements Action {
  public readonly type = ECapacityReleaseActions.PostBidFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class PutBid extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.PutBid;

  constructor(public payload: { bid: Bid; offerId: number; bidId: number }) {
    super(payload);
  }
}

export class PutBidSuccess implements Action {
  public readonly type = ECapacityReleaseActions.PutBidSuccess;

  constructor(public payload: { bid: Bid }) {}
}

export class PutBidFailure implements Action {
  public readonly type = ECapacityReleaseActions.PutBidFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ClearBid implements Action {
  public readonly type = ECapacityReleaseActions.ClearBid;
}

export class FetchOfferBids extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferBids;

  constructor(
    public payload: {
      myBidsOnly?: boolean;
      bidderIds?: number[];
      offerId?: number;
      offerIds?: string[];
    }
  ) {
    super(payload);
  }
}

export class FetchOfferBidsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferBidsSuccess;

  constructor(public payload: { bidsCollection: BidCollection }) {}
}

export class FetchOfferBidsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferBidsFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ClearOfferBids implements Action {
  public readonly type = ECapacityReleaseActions.ClearOfferBids;
}

export class FetchWithdrawnBids extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchWithdrawnBids;

  constructor(public payload: { offerId: number; bidderId: number; myBidsOnly?: boolean }) {
    super(payload);
  }
}

export class FetchWithdrawnBidsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchWithdrawnBidsSuccess;

  constructor(public payload: { bidCollection: BidCollection }) {}
}

export class FetchWithdrawnBidsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchWithdrawnBidsFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ClearWithdrawnBids implements Action {
  public readonly type = ECapacityReleaseActions.ClearWithdrawnBids;
}

export class FetchAwards extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwards;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      bidderIds?: number[];
      bidNumber?: number;
      offerNumber?: number;
      awardNumber?: number;
      awardStatus?: number[];
      naesbStatus?: number;
      releaserId?: number;
      contractId?: number;
      contractNumber?: number;
      replacementShipperContractId?: number;
      replacementShipperContractNumber?: number;
      bidderReleaseTermStartDate?: string;
      bidderReleaseTermEndDate?: string;
      postedBeginDateTime?: Date;
      startDate?: string;
      recallableOnly?: boolean;
      capacityReleaseType?: string[];
      capacityReleaseId?: number;
      recallReputStatus?: number[];
    }
  ) {
    super(payload);
  }
}

export class FetchAwardsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardsSuccess;

  constructor(public payload: { awardsCollection: AwardCollection }) {}
}

export class FetchAwardsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardsFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchAwardsForFilters extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardsForFilters;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      bidderIds?: number[];
      bidNumber?: number;
      offerNumber?: number;
      awardNumber?: number;
      awardStatus?: number[];
      naesbStatus?: number;
      releaserId?: number;
      contractId?: number;
      contractNumber?: number;
      replacementShipperContractId?: number;
      replacementShipperContractNumber?: number;
      bidderReleaseTermStartDate?: string;
      bidderReleaseTermEndDate?: string;
      postedBeginDateTime?: Date;
      startDate?: string;
      recallableOnly?: boolean;
      capacityReleaseType?: string[];
      capacityReleaseId?: number;
      recallReputStatus?: number[];
    }
  ) {
    super(payload);
  }
}

export class FetchAwardsForFiltersSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardsForFiltersSuccess;

  constructor(public payload: { awards: Award[] }) {}
}

export class FetchAwardsForFiltersFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardsForFiltersFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchAwardOffer extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardOffer;

  constructor(public payload: { offerId: number }) {
    super();
  }
}

export class FetchAwardOfferSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardOfferSuccess;

  constructor(public payload: { offers: Offer[] }) {}
}

export class FetchAwardOfferFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardOfferFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class SetReleaser implements Action {
  public readonly type = ECapacityReleaseActions.SetReleaser;

  constructor(
    public payload: { releaser: { entityId: number; name: string; dunsNumber?: string } }
  ) {}
}

export class SetReleaserOptions implements Action {
  public readonly type = ECapacityReleaseActions.SetReleaserOptions;

  constructor(
    public payload: { releasers: { entityId: number; name: string; dunsNumber?: string }[] }
  ) {}
}

export class FetchBidEvaluationOffers extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationOffers;

  constructor(public payload: { tspId: number; pageSize?: number }) {
    super();
  }
}

export class FetchBidEvaluationOffersSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationOffersSuccess;

  constructor(public payload: { offerCollection: OfferCollection }) {}
}

export class FetchBidEvaluationOffersFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationOffersFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchAwardById extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardById;

  constructor(public payload: { awardId: number }) {
    super(payload);
  }
}

export class FetchAwardByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardByIdSuccess;

  constructor(public payload: { award: Award }) {}
}

export class FetchAwardByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchOriginalAwardById extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchOriginalAwardById;

  constructor(public payload: { awardId: number }) {
    super(payload);
  }
}

export class FetchOriginalAwardByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchOriginalAwardByIdSuccess;

  constructor(public payload: { award: Award }) {}
}

export class FetchOriginalAwardByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchOriginalAwardByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchLinkedAwardById extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchLinkedAwardById;

  constructor(public payload: { awardId: number }) {
    super(payload);
  }
}

export class FetchLinkedAwardByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchLinkedAwardByIdSuccess;

  constructor(public payload: { award: Award }) {}
}

export class FetchLinkedAwardByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchLinkedAwardByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchAwardNetQuantitiesById extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardNetQuantitiesById;

  constructor(public payload: { awardId: number; startDate: string; endDate: string }) {
    super(payload);
  }
}

export class FetchAwardNetQuantitiesByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardNetQuantitiesByIdSuccess;

  constructor(public payload: { award: Award }) {}
}

export class FetchAwardNetQuantitiesByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardNetQuantitiesByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchAwardSeededLocationsById extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardSeededLocationsById;

  constructor(public payload: { awardId: number; startDate: string; endDate: string }) {
    super(payload);
  }
}

export class FetchAwardSeededLocationsByIdSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardSeededLocationsByIdSuccess;

  constructor(public payload: { award: Award }) {}
}

export class FetchAwardSeededLocationsByIdFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchAwardSeededLocationsByIdFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ClearBidEvaluationOffers implements Action {
  public readonly type = ECapacityReleaseActions.ClearBidEvaluationOffers;
}

export class FetchBidEvaluationBids extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationBids;

  constructor(
    public payload: {
      pageSize: number;
      offerIds: string[];
      pageNumber: number;
      myBidsOnly?: boolean;
      tspId: number;
    }
  ) {
    super();
  }
}

export class FetchBidEvaluationBidsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationBidsSuccess;

  constructor(public payload: { bids: Bid[]; offerIds: string[] }) {}
}

export class FetchBidEvaluationBidsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationBidsFailure;

  constructor(public payload: { error: HttpErrorResponse; offerIds: string[] }) {}
}

export class FetchBidEvaluationAwards extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationAwards;

  constructor(
    public payload: {
      pageSize: number;
      offerIds: string[];
      pageNumber: number;
      myBidsOnly?: boolean;
      tspId: number;
    }
  ) {
    super();
  }
}

export class FetchBidEvaluationAwardsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationAwardsSuccess;

  constructor(public payload: { awards: Award[]; offerIds: string[] }) {}
}

export class FetchBidEvaluationAwardsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchBidEvaluationAwardsFailure;

  constructor(public payload: { error: HttpErrorResponse; offerIds: string[] }) {}
}

export class ClearAward implements Action {
  public readonly type = ECapacityReleaseActions.ClearAward;
}

export class ClearBidEvaluationBids implements Action {
  public readonly type = ECapacityReleaseActions.ClearBidEvaluationBids;
}

export class ClearBidEvaluationAwards implements Action {
  public readonly type = ECapacityReleaseActions.ClearBidEvaluationAwards;
}

export class CreateMatchingBid implements Action {
  public readonly type = ECapacityReleaseActions.CreateMatchingBid;

  constructor(
    readonly payload: {
      offerId: number;
      bidId: number;
    }
  ) {}
}

export class CreateMatchingBidFailure implements Action {
  public readonly type = ECapacityReleaseActions.CreateMatchingBidFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class CreateMatchingBidSuccess implements Action {
  public readonly type = ECapacityReleaseActions.CreateMatchingBidSuccess;

  constructor(public payload: { matchingBid: Bid }) {}
}

export class PostAward implements Action {
  public readonly type = ECapacityReleaseActions.PostAward;

  constructor(public payload: { award: Award }) {}
}

export class PostAwardSuccess implements Action {
  public readonly type = ECapacityReleaseActions.PostAwardSuccess;

  constructor(public payload: { award: Award }) {}
}

export class PostAwardFailure implements Action {
  public readonly type = ECapacityReleaseActions.PostAwardFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class PutAward implements Action {
  public readonly type = ECapacityReleaseActions.PutAward;

  constructor(public payload: { award: Award }) {}
}

export class PutAwardSuccess implements Action {
  public readonly type = ECapacityReleaseActions.PutAwardSuccess;

  constructor(public payload: { award: Award }) {}
}

export class PutAwardFailure implements Action {
  public readonly type = ECapacityReleaseActions.PutAwardFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchContractOverview extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchContractOverview;

  constructor(readonly payload: { contractId: number }) {
    super();
  }
}

export class FetchContractOverviewSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchContractOverviewSuccess;

  constructor(public payload: { contractOverview: ContractOverview }) {}
}

export class FetchContractOverviewFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchContractOverviewFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class FetchOfferLocationOptions extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferLocationOptions;

  constructor(readonly payload: OfferLocationOptionsPayload) {
    super();
  }
}

export class FetchOfferLocationOptionsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferLocationOptionsSuccess;

  constructor(public payload: { offerLocationOptions: LocationOptionsResponse }) {}
}

export class FetchOfferLocationOptionsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchOfferLocationOptionsFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ClearOfferLocationOptions implements Action {
  public readonly type = ECapacityReleaseActions.ClearOfferLocationOptions;
}

export class FetchPreapprovedBidders extends RetryableAction implements Action {
  public readonly type = ECapacityReleaseActions.FetchPreapprovedBidders;

  constructor(
    readonly payload: {
      entityIds: number[];
      minPermissionOption?: 'ReadOnly' | 'Edit' | 'Admin';
      agencyTransactionStartDate?: string;
      agencyResourceName?: string;
    }
  ) {
    super();
  }
}

export class FetchPreapprovedBiddersSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FetchPreapprovedBiddersSuccess;

  constructor(public payload: { preapprovedBidders: Entity[] }) {}
}

export class FetchPreapprovedBiddersFailure implements Action {
  public readonly type = ECapacityReleaseActions.FetchPreapprovedBiddersFailure;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class ClearPreapprovedBidders implements Action {
  public readonly type = ECapacityReleaseActions.ClearPreapprovedBidders;
}

export class FetchMultipleOfferLocationsOptions implements Action {
  public readonly type = ECapacityReleaseActions.FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS;

  constructor(public locationRanges: Array<OfferLocationOptionsPayload>) {}
}

export class FetchMultipleOfferLocationsOptionsSuccess implements Action {
  public readonly type = ECapacityReleaseActions.FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS_SUCCESS;

  constructor(
    public payload: {
      locationsOptions: Array<LocationOptionsResponse>;
    }
  ) {}
}

export class FetchMultipleOfferLocationsOptionsFailure implements Action {
  public readonly type = ECapacityReleaseActions.FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS_FAILURE;

  constructor(public error: HttpErrorResponse) {}
}

export class SetParentOffer implements Action {
  public readonly type = ECapacityReleaseActions.SET_PARENT_OFFER;

  constructor(public payload: { parentOffer: Offer }) {}
}

export class ClearReleaser implements Action {
  public readonly type = ECapacityReleaseActions.CLEAR_RELEASER;
}

export type CapacityReleaseActions =
  | FetchOffers
  | FetchOffersSuccess
  | FetchOffersFailure
  | FetchBiddableOffers
  | FetchBiddableOffersSuccess
  | FetchBiddableOffersFailure
  | FetchWithdrawals
  | FetchWithdrawalsSuccess
  | FetchWithdrawalsFailure
  | FetchOfferById
  | FetchOfferByIdSuccess
  | FetchOfferByIdFailure
  | FetchBiddableOfferById
  | FetchBiddableOfferByIdSuccess
  | FetchBiddableOfferByIdFailure
  | FetchDictionary
  | FetchDictionarySuccess
  | FetchDictionaryFailure
  | PostOffer
  | PostOfferSuccess
  | PostOfferFailure
  | GetOffersLookup
  | GetOffersLookupSuccess
  | GetOffersLookupFailure
  | PutOffer
  | PutOfferSuccess
  | PutOfferFailure
  | ClearOffer
  | ClearOffersCollection
  | DeleteOffer
  | DeleteOfferSuccess
  | DeleteOfferFailure
  | ResetDeleteOfferStatus
  | WithdrawOffer
  | WithdrawOfferSuccess
  | WithdrawOfferFailure
  | ResetWithdrawOfferStatus
  | CopyOffer
  | CopyOfferSuccess
  | CopyOfferFailure
  | FetchBids
  | FetchBidsSuccess
  | FetchBidsFailure
  | ClearBid
  | FetchBidById
  | FetchBidByIdSuccess
  | FetchBidByIdFailure
  | SetBidderViewMode
  | SetOfferViewMode
  | ClearOfferViewMode
  | ClearBids
  | FetchBidsForFilters
  | FetchBidsForFiltersSuccess
  | FetchBidsForFiltersFailure
  | DeleteBid
  | DeleteBidSuccess
  | DeleteBidFailure
  | ResetDeleteBidStatus
  | WithdrawBid
  | WithdrawBidSuccess
  | WithdrawBidFailure
  | ResetWithdrawBidStatus
  | ConfirmBid
  | ConfirmBidSuccess
  | ConfirmBidFailure
  | ResetConfirmBidStatus
  | RejectBid
  | RejectBidSuccess
  | RejectBidFailure
  | ResetRejectBidStatus
  | FetchOfferBids
  | FetchOfferBidsSuccess
  | FetchOfferBidsFailure
  | ClearOfferBids
  | PostBid
  | PostBidSuccess
  | PostBidFailure
  | PutBid
  | PutBidSuccess
  | PutBidFailure
  | FetchWithdrawnBids
  | FetchWithdrawnBidsSuccess
  | FetchWithdrawnBidsFailure
  | ClearWithdrawnBids
  | FetchAwards
  | FetchAwardsSuccess
  | FetchAwardsFailure
  | FetchAwardsForFilters
  | FetchAwardsForFiltersSuccess
  | FetchAwardsForFiltersFailure
  | FetchAwardOffer
  | FetchAwardOfferSuccess
  | FetchAwardOfferFailure
  | SetReleaser
  | SetReleaserOptions
  | FetchBidEvaluationOffers
  | FetchBidEvaluationOffersSuccess
  | FetchBidEvaluationOffersFailure
  | FetchAwardById
  | FetchAwardByIdSuccess
  | FetchAwardByIdFailure
  | FetchOriginalAwardById
  | FetchOriginalAwardByIdSuccess
  | FetchOriginalAwardByIdFailure
  | FetchLinkedAwardById
  | FetchLinkedAwardByIdSuccess
  | FetchLinkedAwardByIdFailure
  | FetchAwardNetQuantitiesById
  | FetchAwardNetQuantitiesByIdSuccess
  | FetchAwardNetQuantitiesByIdFailure
  | FetchAwardSeededLocationsById
  | FetchAwardSeededLocationsByIdSuccess
  | FetchAwardSeededLocationsByIdFailure
  | ClearAward
  | ClearBidEvaluationOffers
  | FetchBidEvaluationBids
  | FetchBidEvaluationBidsSuccess
  | FetchBidEvaluationBidsFailure
  | FetchBidEvaluationAwards
  | FetchBidEvaluationAwardsSuccess
  | FetchBidEvaluationAwardsFailure
  | ClearBidEvaluationBids
  | ClearBidEvaluationAwards
  | CreateMatchingBid
  | CreateMatchingBidFailure
  | CreateMatchingBidSuccess
  | PostAward
  | PostAwardSuccess
  | PostAwardFailure
  | PutAward
  | PutAwardSuccess
  | PutAwardFailure
  | FetchContractOverview
  | FetchContractOverviewSuccess
  | FetchContractOverviewFailure
  | FetchOfferLocationOptions
  | FetchOfferLocationOptionsSuccess
  | FetchOfferLocationOptionsFailure
  | ClearOfferLocationOptions
  | FetchPreapprovedBidders
  | FetchPreapprovedBiddersSuccess
  | FetchPreapprovedBiddersFailure
  | ClearPreapprovedBidders
  | ClearPreapprovedBidders
  | FetchMultipleOfferLocationsOptions
  | FetchMultipleOfferLocationsOptionsSuccess
  | FetchMultipleOfferLocationsOptionsFailure
  | SetParentOffer
  | ResetBidsForFilters
  | ClearReleaser;
