import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RepResponsibilityService } from '@gms/represponsibility-api';
import {
  ERepResponsibilityActions,
  FetchRepResponsibilitiesByEntityId,
  FetchRepResponsibilitiesByEntityIdError,
  FetchRepResponsibilitiesByEntityIdSuccess,
  FetchRepResponsibilitiesByLocationId,
  FetchRepResponsibilitiesByLocationIdError,
  FetchRepResponsibilitiesByLocationIdSuccess,
  FetchRepResponsibilitiesByUserId,
  FetchRepResponsibilitiesByUserIdError,
  FetchRepResponsibilitiesByUserIdSuccess,
  FetchRepResponsibilityRoles,
  FetchRepResponsibilityRolesError,
  FetchRepResponsibilityRolesSuccess,
  UpdateUserResponsibilities,
  UpdateUserResponsibilitiesError,
  UpdateUserResponsibilitiesSuccess,
} from './rep-responsibility.actions';

@Injectable()
export class RepResponsibilityEffects {
  constructor(
    private _actions$: Actions,
    private _repResponsibilityService: RepResponsibilityService
  ) {}

  FetchRepResponsibilityRoles$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRepResponsibilityRoles>(ERepResponsibilityActions.FETCH_REP_RESPONSIBILITY_ROLES),
      switchMap(() => {
        return this._repResponsibilityService.getRepRoles().pipe(
          map(
            repResponsibilityRolesCollection =>
              new FetchRepResponsibilityRolesSuccess({ repResponsibilityRolesCollection })
          ),
          catchError(error => of(new FetchRepResponsibilityRolesError({ error: error })))
        );
      })
    )
  );

  FetchRepResponsibilitiesByEntityId$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRepResponsibilitiesByEntityId>(
        ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_ENTITY_ID
      ),
      map(action => action.payload),
      switchMap(payload => {
        return this._repResponsibilityService.getRepsByEntity(payload.entityId, payload.tspId).pipe(
          map(repResponsibilityCollection => {
            return new FetchRepResponsibilitiesByEntityIdSuccess({ repResponsibilityCollection });
          }),
          catchError(error => of(new FetchRepResponsibilitiesByEntityIdError({ error: error })))
        );
      })
    )
  );

  FetchRepResponsibilitiesByLocationId$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRepResponsibilitiesByLocationId>(
        ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_LOCATION_ID
      ),
      map(action => action.payload),
      switchMap(payload => {
        return this._repResponsibilityService.getRepsByLocation(payload).pipe(
          map(
            repResponsibilityCollection =>
              new FetchRepResponsibilitiesByLocationIdSuccess({ repResponsibilityCollection })
          ),
          catchError(error => of(new FetchRepResponsibilitiesByLocationIdError({ error: error })))
        );
      })
    )
  );

  FetchRepResponsibilitiesByUserId$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRepResponsibilitiesByUserId>(
        ERepResponsibilityActions.FETCH_REP_RESPONSIBILITES_BY_USER_ID
      ),
      map(action => action.payload),
      switchMap(payload => {
        return this._repResponsibilityService.getUserResponsibilities(payload).pipe(
          map(
            userResponsibilityCollection =>
              new FetchRepResponsibilitiesByUserIdSuccess({ userResponsibilityCollection })
          ),
          catchError(error => of(new FetchRepResponsibilitiesByUserIdError({ error: error })))
        );
      })
    )
  );

  UpdateUserResponsibilities$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateUserResponsibilities>(ERepResponsibilityActions.UPDATE_USER_RESPONSIBILITIES),
      map(action => action.payload),
      switchMap(payload => {
        return this._repResponsibilityService
          .putUserResponsibilities(payload.userResponsibilitiesCollection, payload.userId)
          .pipe(
            map(
              userResponsibilitiesCollection =>
                new UpdateUserResponsibilitiesSuccess({
                  userResponsibilitiesCollection: userResponsibilitiesCollection,
                })
            ),
            catchError(error => of(new UpdateUserResponsibilitiesError({ error: error })))
          );
      })
    )
  );
}
