import { HttpErrorResponse } from '@angular/common/http';
import {
  BalancingImbalance,
  BalancingImbalanceDetails,
  Contact,
  ContractImbalance,
  Imbalance,
  ImbalanceSummary,
} from '@gms/allocation-api';
import { AccountAllDetailCollection } from '@gms/billing-api';
import {
  ImbalanceAuthorization,
  ImbalanceAuthorizationCollection,
  ImbalancePipelinePosition,
  ImbalanceTrade,
  ImbalanceTradeCollection,
  ImbalanceTradesSummaryCollection,
  InventoryCollection,
  MasterRateCICOScheduleCollection,
  OperationalBalancingAgmt,
  OperationalBalancingAgmtCollection,
  SAContractCollection,
  ShipperActivity,
  ShipperActivityCollection,
  StorageBalanceCollection,
  TradeWindow,
  TspImbalanceConfigDetail,
} from '@gms/imbalance-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';
import { EImbalanceTradeActions } from 'app/modules/flowing-gas/pages/flowing-gas-utils/imbalance.utils';
import {
  IObaSummaryExportRequest,
  IObaSummaryRequest,
} from 'app/modules/flowing-gas/pages/oba-summary/oba-summary.model';

export enum EImbalancesActions {
  FETCH_IMBALANCES = '[IMBALANCES] Fetch Imbalances',
  FETCH_IMBALANCES_SUCCESS = '[IMBALANCES] Fetch Imbalances Success',
  FETCH_IMBALANCES_ERROR = '[IMBALANCES] Fetch Imbalances Error',
  FETCH_IMBALANCE_DETAILS = '[IMBALANCES] fetch Imbalance Details',
  FETCH_IMBALANCES_DETAILS_SUCCESS = '[IMBALANCES] fetch Imbalance Details Success',
  FETCH_IMBALANCES_DETAILS_ERROR = '[IMBALANCES] fetch Imbalance Details Error',
  EXPORT_IMBALANCE_DETAILS = '[IMBALANCES] Export Imbalance Details',
  EXPORT_IMBALANCE_DETAILS_SUCCESS = '[IMBALANCES] Export Imbalance Details Success',
  EXPORT_IMBALANCE_DETAILS_FAILURE = '[IMBALANCES] Export Imbalance Details Failure',
  FETCH_IMBALANCE_SUMMARY = '[IMBALANCES] Fetch Imbalances Summary',
  FETCH_IMBALANCE_SUMMARY_SUCCESS = '[IMBALANCES] Fetch Imbalances Summary Success',
  FETCH_IMBALANCE_SUMMARY_ERROR = '[IMBALANCES] Fetch Imbalances Summary Error',
  SET_SELECTED_IMBALANCE_ACCOUNTING_PERIOD = '[IMBALANCES] Set Imbalance Period',
  FETCH_IMBALANCE_ACCOUNTING_PERIODS = '[IMBALANCES] Fetch Imbalance Periods',
  FETCH_IMBALANCE_ACCOUNTING_PERIODS_SUCCESS = '[IMBALANCES] Fetch Imbalance Periods Success',
  FETCH_IMBALANCE_ACCOUNTING_PERIODS_ERROR = '[IMBALANCES]  Fetch Imbalance Periods Error',
  SET_IMBALANCE_CONTACT = '[IMBALANCES] Set the Imbalance Contact',
  FETCH_BALANCING_AGREEMENT_IMBALANCE = '[IMBALANCES] Fetch balancing agreement imbalance',
  FETCH_BALANCING_AGREEMENT_IMBALANCE_SUCCESS = '[IMBALANCES] Fetch balancing agreement imbalance success',
  FETCH_BALANCING_AGREEMENT_IMBALANCE_FAILURE = '[IMBALANCES] Fetch balancing agreement imbalance failure',
  FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS = '[IMBALANCES] Fetch balancing agreement imbalance details',
  FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_SUCCESS = '[IMBALANCES] Fetch balancing agreement imbalance details success',
  FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_FAILURE = '[IMBALANCES] Fetch balancing agreement imbalance details failure',
  FETCH_IMBALANCE_TRADES_COLLECTION = '[IMBALANCE] Fetch Imbalance Trades Collection',
  FETCH_IMBALANCE_TRADES_COLLECTION_SUCCESS = '[IMBALANCE] Fetch Imbalance Trades Collection Success',
  FETCH_IMBALANCE_TRADES_COLLECTION_FAILURE = '[IMBALANCE] Fetch Imbalance Trades Collection Failure',
  SET_IMBALANCE_TRADE = '[IMBALANCE] Set Imbalance Trade',
  FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION = '[IMBALANCE] Fetch Imbalance Trades Summary Collection',
  FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION_SUCCESS = '[IMBALANCE] Fetch Imbalance Trades Summary Collection Success',
  FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION_FAILURE = '[IMBALANCE] Fetch Imbalance Trades Summary Collection Failure',
  WITHDRAW_IMBALANCE_TRADE = '[IMBALANCE] Withdraw Imbalance Trade',
  WITHDRAW_IMBALANCE_TRADE_SUCCESS = '[IMBALANCE] Withdraw Imbalance Trade Success',
  WITHDRAW_IMBALANCE_TRADE_FAILURE = '[IMBALANCE] Withdraw Imbalance Trade Failure',
  FETCH_STORAGE_BALANCE_COLLECTION = '[IMBALANCE] Fetch Storage Balance Collection',
  FETCH_STORAGE_BALANCE_COLLECTION_SUCCESS = '[IMBALANCE] Fetch Storage Balance Collection Success',
  FETCH_STORAGE_BALANCE_COLLECTION_FAILURE = '[IMBALANCE] Fetch Storage Balance Collection Failure',
  EXPORT_STORAGE_BALANCE = '[ACCOUNTING] Export Imbalance Storage Info',
  EXPORT_STORAGE_BALANCE_SUCCESS = '[ACCOUNTING] Export Imbalance Storage Info Success',
  EXPORT_STORAGE_BALANCE_FAILURE = '[ACCOUNTING] Export Imbalance Storage Info Failure',
  CREATE_IMBALANCE_TRADE = '[IMBALANCE] Create an Imbalane Trade',
  CREATE_IMBALANCE_TRADE_SUCCESS = '[IMBALANCE] Create an Imbalane Trade Success',
  CREATE_IMBALANCE_TRADE_FAILURE = '[IMBALANCE] Create an Imbalane Trade Failure',
  FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS = '[IMBALANCE] Fetch Authorized Imbalance Accounts',
  FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS_SUCCESS = '[IMBALANCE] Fetch Authorized Imbalance Accounts Success',
  FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS_FAILURE = '[IMBALANCE] Fetch Authorized Imbalance Accounts Failure',
  CLEAR_CREATE_IMBALANCE_TRADE = '[IMBALANCE] Clear state piece of create Imbalance Trade',
  UPDATE_IMBALANCE_TRADE = '[IMBALANCE] Update an Imbalane Trade',
  UPDATE_IMBALANCE_TRADE_SUCCESS = '[IMBALANCE] Update an Imbalane Trade Success',
  UPDATE_IMBALANCE_TRADE_FAILURE = '[IMBALANCE] Update an Imbalane Trade Failure',
  FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_ERROR = 'FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_ERROR',
  FETCH_SHIPPER_ACTIVITY_COLLECTION = '[IMBALANCE] Fetch Shipper Activity Collection',
  FETCH_SHIPPER_ACTIVITY_COLLECTION_SUCCESS = '[IMBALANCE] Fetch Shipper Activity Collection Success',
  FETCH_SHIPPER_ACTIVITY_COLLECTION_FAILURE = '[IMBALANCE] Fetch Shipper Activity Collection Failure',
  FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION = '[IMBALANCE] Fetch Shipper Activity Contract Collection',
  FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION_SUCCESS = '[IMBALANCE] Fetch Shipper Activity Contract Collection Success',
  FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION_FAILURE = '[IMBALANCE] Fetch Shipper Activity Contract Collection Failure',
  EXPORT_SHIPPER_ACTIVITY = '[ACCOUNTING] Export Shipper Activity',
  EXPORT_SHIPPER_ACTIVITY_SUCCESS = '[ACCOUNTING] Export Shipper Activity Success',
  EXPORT_SHIPPER_ACTIVITY_FAILURE = '[ACCOUNTING] Export Shipper Activity Failure',
  FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION = '[IMBALANCE] Fetch Auth To Post Imb Collection',
  FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION_SUCCESS = '[IMBALANCE] Fetch Auth To Post Imb Collection Success',
  PUT_AUTH_TO_POST_IMBALANCES_FAILURE = '[IMBALANCE] Put Auth To Post Imb Failure',
  PUT_AUTH_TO_POST_IMBALANCES = '[IMBALANCE] Put Auth To Post Imb',
  PUT_AUTH_TO_POST_IMBALANCES_SUCCESS = '[IMBALANCE] Put Auth To Post Imb Success',
  FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION_FAILURE = '[IMBALANCE] Fetch Auth To Post Imb Collection Failure',
  PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF = '[IMBALANCE] Put Balancing Agreement Imbalance Signoff',
  PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF_SUCCESS = '[IMBALANCE] Put Balancing Agreement Imbalance Signoff Success',
  PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF_FAILURE = '[IMBALANCE] Put Balancing Agreement Imbalance Signoff Failure',
  FETCH_IMBALANCE_INVENTORIES = '[IMBALANCES] Fetch Imbalance Inventories',
  FETCH_IMBALANCE_INVENTORIES_SUCCESS = '[IMBALANCES] Fetch Imbalance Success',
  FETCH_IMBALANCE_INVENTORIES_ERROR = '[IMBALANCES] Fetch Imbalance Error',
  FETCH_ACCT_IMBALANCE = '[IMBALANCES] Fetch Account Imbalance',
  FETCH_ACCT_IMBALANCE_SUCCESS = '[IMBALANCES] Fetch Account Imbalance Success',
  FETCH_ACCT_IMBALANCE_ERROR = '[IMBALANCES] Fetch Account Imbalance Error',
  EDIT_OBA_SUMMARY_REQUEST = '[IMBALANCE] Edit OBA Summary Request',
  EDIT_OBA_SUMMARY_SUCCESS = '[IMBALANCE] Edit OBA Summary Success',
  EDIT_OBA_SUMMARY_FAILURE = '[IMBALANCE] Edit OBA Summary Failure',
  EDIT_SHIPPER_ACTIVITY_REQUEST = '[IMBALANCE] Edit Shipper Activity Request',
  EDIT_SHIPPER_ACTIVITY_SUCCESS = '[IMBALANCE] Edit Shipper Activity Success',
  EDIT_SHIPPER_ACTIVITY_FAILURE = '[IMBALANCE] Edit Shipper Activity Failure',
  EXPORT_OBA_SUMMARY_REQUEST = '[IMBALANCE] Export OBA Summary Request',
  EXPORT_OBA_SUMMARY_SUCCESS = '[IMBALANCE] Export OBA Summary Success',
  EXPORT_OBA_SUMMARY_FAILURE = '[IMBALANCE] Export OBA Summary Failure',
  FETCH_OBA_SUMMARY_REQUEST = '[IMBALANCE] Fetch OBA Summary Request',
  FETCH_OBA_SUMMARY_SUCCESS = '[IMBALANCE] Fetch OBA Summary Success',
  FETCH_OBA_SUMMARY_FAILURE = '[IMBALANCE] Fetch OBA Summary Failure',
  FETCH_TRADE_WINDOW = '[IMBALANCE] Fetch Trading Window',
  FETCH_TRADE_WINDOW_SUCCESS = '[IMBALANCE] Fetch Trading Window Success',
  FETCH_TRADE_WINDOW_FAILURE = '[IMBALANCE] Fetch Trading Window Failure',
  CLEAR_TRADE_WINDOW_DATA = '[IMBALANCE] Clear Trading Window Data',
  FETCH_PIPELINE_POSITION = '[IMBALANCE] Fetch Pipeline Position',
  FETCH_PIPELINE_POSITION_SUCCESS = '[IMBALANCE] Fetch Pipeline Position Success',
  FETCH_PIPELINE_POSITION_FAILURE = '[IMBALANCE] Fetch Pipeline Position Failure',
  FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION = '[IMBALANCE] Fetch Master Rate CICO SChedule Colletion',
  FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION_SUCCESS = '[IMBALANCE] Fetch Master Rate CICO SChedule Colletion Success',
  FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION_FAILURE = '[IMBALANCE] Fetch Master Rate CICO SChedule Colletion Failure',
}

export interface IImbalancesFilterPayload {
  tspId?: number;
  serviceRequestor?: number;
  accountingPeriod?: string;
  contractId?: number;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
}

export enum ViewBy {
  Summary = 'Summary',
  Detail = 'Detail',
}

export interface IImbalanceDetailsFilterPayload {
  contractId: number;
  accountingPeriod: string;
  viewBy: ViewBy;
  pageSize?: number;
  pageNumber?: number;
}

export interface IImbalanceSummary {
  tspId: number;
  serviceRequstorId: number;
  accountingPeriod: string;
}

export interface IImbalanceTradesPayload {
  tspId: number;
  accountPeriod?: IAccountPeriod;
  svcReqNameId?: number;
  statusId?: number;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
}

export interface IStorageBalanceExportPayload {
  tspId: number;
  tspName?: string;
  viewBy: ViewBy;
  svcReqNameId?: number;
  // FUTURE ENHANCEMENT: svcReqNameId to only accept string of form 1+2+3...
  acctPeriod?: IAccountPeriod;
  svcReqK?: number;
  beginDate?: Date;
  endDate?: Date;
  format?: string;
}

export interface IStorageBalancePayload extends IStorageBalanceExportPayload {
  pageSize?: number;
  pageNumber?: number;
}

export interface IExportImbalancesPayload {
  accountingPeriod?: string;
  contractId?: number;
}

export interface IShipperActivityPayload {
  tspId?: number;
  tspName?: string;
  svcReqNameId?: number[];
  svcReqKId?: number; // Contract Id or Account Id
  viewBy?: string;
  beginningAccountingPeriod?: IAccountPeriod;
  endingAccountingPeriod?: IAccountPeriod;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
}

export interface IShipperActivityContractPayload {
  entityIds?: string;
  tspId?: number;
  accountPeriodStart?: Date;
  accountPeriodEnd?: Date;
  pageSize?: number;
  pageNumber?: number;
}

export enum EImbalanceSignoffActions {
  ACCEPT = 'Accept',
  REJECT = 'Reject',
}
export interface BalancingImbalanceSignoff {
  status: EImbalanceSignoffActions;
  accountingPeriod: string;
  note: string;
}

export interface ITradeWindowPayload {
  tspId: number;
  accountPeriod?: Date;
  tradeDate?: Date;
}

export interface IPipelinePositionPayload {
  tspId: number;
  accountPeriod?: Date;
}

export class FetchImbalances implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCES;

  constructor(public payload: IImbalancesFilterPayload) {}
}

export class FetchImbalancesSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCES_SUCCESS;

  constructor(
    public payload: {
      imbalances: Array<Imbalance>;
      totalImbalancesCount: number;
    }
  ) {}
}
export class FetchImbalancesFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCES_ERROR;

  constructor(public payload: Error) {}
}

export class FetchImbalanceDetails implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_DETAILS;

  constructor(public payload: IImbalanceDetailsFilterPayload) {}
}

export class FetchImbalanceDetailsSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCES_DETAILS_SUCCESS;

  constructor(
    public payload: {
      contractImbalances: Array<ContractImbalance>;
      totalImbalancesCount: number;
    }
  ) {}
}

export class FetchImbalanceDetailsFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCES_DETAILS_ERROR;

  constructor(public payload: Error) {}
}

export class ExportImbalanceDetails implements Action {
  public readonly type = EImbalancesActions.EXPORT_IMBALANCE_DETAILS;

  constructor(
    public payload: {
      contractId?: number;
      accountingPeriod?: string;
    }
  ) {}
}

export class ExportImbalanceDetailsSuccess implements Action {
  public readonly type = EImbalancesActions.EXPORT_IMBALANCE_DETAILS_SUCCESS;
}

export class ExportImbalanceDetailsFailure implements Action {
  public readonly type = EImbalancesActions.EXPORT_IMBALANCE_DETAILS_FAILURE;

  constructor(public payload: Error) {}
}

export class SetSelectedImbalanceAccountingPeriod implements Action {
  public readonly type = EImbalancesActions.SET_SELECTED_IMBALANCE_ACCOUNTING_PERIOD;

  constructor(public payload: IAccountPeriod) {}
}

export class FetchImbalanceSummary implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_SUMMARY;

  constructor(public payload: IImbalanceSummary) {}
}

export class FetchImbalanceSummarySuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_SUMMARY_SUCCESS;

  constructor(public payload: ImbalanceSummary) {}
}

export class FetchImbalanceSummaryFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_SUMMARY_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchImbalanceAccountingPeriods implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_ACCOUNTING_PERIODS;

  constructor(public payload: { monthsBack: number; showAllOption?: boolean }) {}
}
export class FetchImbalanceAccountingPeriodsSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_ACCOUNTING_PERIODS_SUCCESS;

  constructor(
    public payload: {
      accountPeriods: Array<IAccountPeriod>;
    }
  ) {}
}

export class FetchImbalanceAccountingPeriodsFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_ACCOUNTING_PERIODS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class SetImbalanceContact implements Action {
  public readonly type = EImbalancesActions.SET_IMBALANCE_CONTACT;

  constructor(public payload: { user: Contact }) {}
}

export class FetchBalancingAgreementImbalance implements Action {
  public readonly type = EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE;

  constructor(
    public payload: { tspId: number; serviceRequstorId: number; accountingPeriod: string }
  ) {}
}

export class FetchBalancingAgreementImbalanceSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_SUCCESS;

  constructor(
    public payload: {
      obaImbalances: Array<BalancingImbalance>;
      total?: number;
    }
  ) {}
}

export class FetchBalancingAgreementImbalanceFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchBalancingAgreementImbalanceDetails implements Action {
  public readonly type = EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS;

  constructor(public payload: { contractId: number; accountingPeriod: string }) {}
}

export class FetchBalancingAgreementImbalanceDetailsSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_SUCCESS;

  constructor(
    public payload: {
      contractId: number;
      balancingAgreementImbalanceDetails: BalancingImbalanceDetails;
    }
  ) {}
}

export class FetchBalancingAgreementImbalanceDetailsFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_BALANCING_AGREEMENT_IMBALANCE_DETAILS_ERROR;

  constructor(public payload: { error: Error; contractId: number }) {}
}

export class FetchImbalanceTradesCollection implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_TRADES_COLLECTION;

  constructor(public payload: IImbalanceTradesPayload) {}
}

export class FetchImbalanceTradesCollectionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_TRADES_COLLECTION_SUCCESS;

  constructor(public payload: ImbalanceTradeCollection) {}
}

export class FetchImbalanceTradesCollectionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_TRADES_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class SetImbalanceTrade implements Action {
  public readonly type = EImbalancesActions.SET_IMBALANCE_TRADE;

  constructor(public payload) {}
}
export class FetchImbalanceTradesSummaryCollection implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION;

  constructor(public payload: IImbalanceTradesPayload) {}
}

export class FetchImbalanceTradesSummaryCollectionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION_SUCCESS;

  constructor(public payload: ImbalanceTradesSummaryCollection) {}
}

export class FetchImbalanceTradesSummaryCollectionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_TRADES_SUMMARY_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class WithdrawImbalanceTrade implements Action {
  public readonly type = EImbalancesActions.WITHDRAW_IMBALANCE_TRADE;

  constructor(public payload: ImbalanceTrade) {}
}

export class WithdrawImbalanceTradeSuccess implements Action {
  public readonly type = EImbalancesActions.WITHDRAW_IMBALANCE_TRADE_SUCCESS;

  constructor(public payload: ImbalanceTrade) {}
}

export class WithdrawImbalanceTradeFailure implements Action {
  public readonly type = EImbalancesActions.WITHDRAW_IMBALANCE_TRADE_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchStorageBalanceCollection implements Action {
  public readonly type = EImbalancesActions.FETCH_STORAGE_BALANCE_COLLECTION;

  constructor(public payload: IStorageBalancePayload) {}
}

export class FetchStorageBalanceCollectionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_STORAGE_BALANCE_COLLECTION_SUCCESS;

  constructor(public payload: StorageBalanceCollection) {}
}

export class FetchStorageBalanceCollectionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_STORAGE_BALANCE_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportStorageBalance implements Action {
  public readonly type = EImbalancesActions.EXPORT_STORAGE_BALANCE;

  constructor(public payload: IStorageBalanceExportPayload) {}
}

export class ExportStorageBalanceSuccess implements Action {
  public readonly type = EImbalancesActions.EXPORT_STORAGE_BALANCE_SUCCESS;
}

export class ExportStorageBalanceFailure implements Action {
  public readonly type = EImbalancesActions.EXPORT_STORAGE_BALANCE_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateImbalanceTrade implements Action {
  public readonly type = EImbalancesActions.CREATE_IMBALANCE_TRADE;

  constructor(public payload: ImbalanceTrade) {}
}

export class CreateImbalanceTradeSuccess implements Action {
  public readonly type = EImbalancesActions.CREATE_IMBALANCE_TRADE_SUCCESS;

  constructor(public payload: ImbalanceTrade) {}
}

export class CreateImbalanceTradeFailure implements Action {
  public readonly type = EImbalancesActions.CREATE_IMBALANCE_TRADE_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearCachedCreateImbalanceTrade implements Action {
  public readonly type = EImbalancesActions.CLEAR_CREATE_IMBALANCE_TRADE;
}

export class FetchShipperActivityCollection implements Action {
  public readonly type = EImbalancesActions.FETCH_SHIPPER_ACTIVITY_COLLECTION;

  constructor(public payload: IShipperActivityPayload) {}
}

export class FetchShipperActivityCollectionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_SHIPPER_ACTIVITY_COLLECTION_SUCCESS;

  constructor(
    public payload: {
      shipperActivityCollection: ShipperActivityCollection;
      tspConfig: TspImbalanceConfigDetail;
    }
  ) {}
}

export class FetchShipperActivityCollectionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_SHIPPER_ACTIVITY_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchShipperActivityContractCollection implements Action {
  public readonly type = EImbalancesActions.FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION;

  constructor(public payload: IShipperActivityContractPayload) {}
}

export class FetchShipperActivityContractCollectionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION_SUCCESS;

  constructor(public payload: SAContractCollection) {}
}

export class FetchShipperActivityContractCollectionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_SHIPPER_ACTIVITY_CONTRACT_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateImbalanceTrade implements Action {
  public readonly type = EImbalancesActions.UPDATE_IMBALANCE_TRADE;

  constructor(public payload: { trade: ImbalanceTrade; actionType: EImbalanceTradeActions }) {}
}

export class UpdateImbalanceTradeSuccess implements Action {
  public readonly type = EImbalancesActions.UPDATE_IMBALANCE_TRADE_SUCCESS;

  constructor(public payload: ImbalanceTrade) {}
}

export class UpdateImbalanceTradeFailure implements Action {
  public readonly type = EImbalancesActions.UPDATE_IMBALANCE_TRADE_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportShipperActivity implements Action {
  public readonly type = EImbalancesActions.EXPORT_SHIPPER_ACTIVITY;

  constructor(public payload: IShipperActivityPayload) {}
}

export class ExportShipperActivitySuccess implements Action {
  public readonly type = EImbalancesActions.EXPORT_SHIPPER_ACTIVITY_SUCCESS;
}

export class ExportShipperActivityFailure implements Action {
  public readonly type = EImbalancesActions.EXPORT_SHIPPER_ACTIVITY_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchAuthToPostImbalancesCollection implements Action {
  public readonly type = EImbalancesActions.FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION;

  constructor(
    public payload: {
      tspId: number;
      svcReqNameId: number;
    }
  ) {}
}

export class FetchAuthToPostImbalancesCollectionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION_SUCCESS;

  constructor(public payload: ImbalanceAuthorizationCollection) {}
}

export class FetchAuthToPostImbalancesCollectionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_AUTH_TO_POST_IMBALANCES_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class PutAuthToPostImbalances implements Action {
  public readonly type = EImbalancesActions.PUT_AUTH_TO_POST_IMBALANCES;

  constructor(public payload: { imbalanceAuth: ImbalanceAuthorization }) {}
}

export class PutAuthToPostImbalancesSuccess implements Action {
  public readonly type = EImbalancesActions.PUT_AUTH_TO_POST_IMBALANCES_SUCCESS;

  constructor(public payload: ImbalanceAuthorization) {}
}

export class PutAuthToPostImbalancesFailure implements Action {
  public readonly type = EImbalancesActions.PUT_AUTH_TO_POST_IMBALANCES_FAILURE;

  constructor(public payload: Error) {}
}

export class PutBalancingAgreementImbalanceSignoff implements Action {
  public readonly type = EImbalancesActions.PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF;

  constructor(
    public payload: { contractId: number; balancingImbalanceSignoff: BalancingImbalanceSignoff }
  ) {}
}

export class PutBalancingAgreementImbalanceSignoffSuccess implements Action {
  public readonly type = EImbalancesActions.PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF_SUCCESS;

  constructor(public payload: any) {} //TODO: fix this when backend is complete
}

export class PutBalancingAgreementImbalanceSignoffFailure implements Action {
  public readonly type = EImbalancesActions.PUT_BALANCING_AGREEMENT_IMBALANCE_SIGNOFF_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAuthorizedImbalanceAccounts implements Action {
  public readonly type = EImbalancesActions.FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS;
}

export class GetAuthorizedImbalanceAccountsSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS_SUCCESS;

  constructor(public payload: AccountAllDetailCollection) {}
}

export class GetAuthorizedImbalanceAccountsFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_AUTHORIZED_IMBALANCE_ACCOUNTS_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchImbalanceInventories implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_INVENTORIES;

  constructor(
    public payload: {
      accountingPeriod: IAccountPeriod;
      accountIds: number[];
    }
  ) {}
}

export class FetchImbalanceInventoriesSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_INVENTORIES_SUCCESS;

  constructor(public payload: { imbalanceInventoryCollection: InventoryCollection }) {}
}

export class FetchImbalanceInventoriesError implements Action {
  public readonly type = EImbalancesActions.FETCH_IMBALANCE_INVENTORIES_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchAcctImbalance implements Action {
  public readonly type = EImbalancesActions.FETCH_ACCT_IMBALANCE;

  constructor(
    public payload: {
      accountingPeriod: IAccountPeriod;
      accountId: number;
    }
  ) {}
}

export class FetchAcctImbalanceSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_ACCT_IMBALANCE_SUCCESS;

  constructor(public payload: { acctImbalanceCollection: InventoryCollection }) {}
}

export class FetchAcctImbalanceError implements Action {
  public readonly type = EImbalancesActions.FETCH_ACCT_IMBALANCE_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class EditObaSummaryRequest implements Action {
  public readonly type = EImbalancesActions.EDIT_OBA_SUMMARY_REQUEST;

  constructor(public payload: OperationalBalancingAgmt) {}
}

export class EditObaSummarySuccess implements Action {
  public readonly type = EImbalancesActions.EDIT_OBA_SUMMARY_SUCCESS;

  constructor(public payload: OperationalBalancingAgmt) {}
}

export class EditObaSummaryFailure implements Action {
  public readonly type = EImbalancesActions.EDIT_OBA_SUMMARY_FAILURE;

  constructor(public payload: Error) {}
}

export class EditShipperActivityRequest implements Action {
  public readonly type = EImbalancesActions.EDIT_SHIPPER_ACTIVITY_REQUEST;

  constructor(public payload: ShipperActivity) {}
}

export class EditShipperActivitySuccess implements Action {
  public readonly type = EImbalancesActions.EDIT_SHIPPER_ACTIVITY_SUCCESS;

  constructor(public payload: ShipperActivity) {}
}

export class EditShipperActivityFailure implements Action {
  public readonly type = EImbalancesActions.EDIT_SHIPPER_ACTIVITY_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportObaSummaryRequest implements Action {
  public readonly type = EImbalancesActions.EXPORT_OBA_SUMMARY_REQUEST;

  constructor(public payload: IObaSummaryExportRequest) {}
}

export class ExportObaSummarySuccess implements Action {
  public readonly type = EImbalancesActions.EXPORT_OBA_SUMMARY_SUCCESS;
}

export class ExportObaSummaryFailure implements Action {
  public readonly type = EImbalancesActions.EXPORT_OBA_SUMMARY_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchObaSummaryRequest implements Action {
  public readonly type = EImbalancesActions.FETCH_OBA_SUMMARY_REQUEST;

  constructor(public payload: IObaSummaryRequest) {}
}

export class FetchObaSummarySuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_OBA_SUMMARY_SUCCESS;

  constructor(public payload: OperationalBalancingAgmtCollection) {}
}

export class FetchObaSummaryFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_OBA_SUMMARY_FAILURE;

  constructor(public payload: Error) {}
}
export class FetchTradeWindow implements Action {
  public readonly type = EImbalancesActions.FETCH_TRADE_WINDOW;

  constructor(public payload: ITradeWindowPayload) {}
}

export class FetchTradeWindowSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_TRADE_WINDOW_SUCCESS;

  constructor(public payload: TradeWindow.TradeWindowStatusEnum) {}
}

export class FetchTradeWindowFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_TRADE_WINDOW_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearTradeWindowData implements Action {
  public readonly type = EImbalancesActions.CLEAR_TRADE_WINDOW_DATA;
}

export class FetchPipelinePosition implements Action {
  public readonly type = EImbalancesActions.FETCH_PIPELINE_POSITION;

  constructor(public payload: IPipelinePositionPayload) {}
}

export class FetchPipelinePositionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_PIPELINE_POSITION_SUCCESS;

  constructor(public payload: ImbalancePipelinePosition) {}
}

export class FetchPipelinePositionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_PIPELINE_POSITION_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchMasterRateCICOScheduleCollection implements Action {
  public readonly type = EImbalancesActions.FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION;
}

export class FetchMasterRateCICOScheduleCollectionSuccess implements Action {
  public readonly type = EImbalancesActions.FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION_SUCCESS;

  constructor(public payload: MasterRateCICOScheduleCollection) {}
}

export class FetchMasterRateCICOScheduleCollectionFailure implements Action {
  public readonly type = EImbalancesActions.FETCH_MASTER_RATE_CICO_SCHEDULE_COLLECTION_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export type ImbalancesActions =
  | FetchImbalances
  | FetchImbalancesSuccess
  | FetchImbalancesFailure
  | FetchImbalanceDetails
  | FetchImbalanceDetailsSuccess
  | FetchImbalanceDetailsFailure
  | ExportImbalanceDetails
  | ExportImbalanceDetailsSuccess
  | ExportImbalanceDetailsFailure
  | SetSelectedImbalanceAccountingPeriod
  | FetchImbalanceAccountingPeriods
  | FetchImbalanceAccountingPeriodsSuccess
  | FetchImbalanceAccountingPeriodsFailure
  | SetImbalanceContact
  | FetchImbalanceSummary
  | FetchImbalanceSummaryFailure
  | FetchImbalanceSummarySuccess
  | FetchBalancingAgreementImbalance
  | FetchBalancingAgreementImbalanceSuccess
  | FetchBalancingAgreementImbalanceFailure
  | FetchBalancingAgreementImbalanceDetails
  | FetchBalancingAgreementImbalanceDetailsFailure
  | FetchBalancingAgreementImbalanceDetailsSuccess
  | FetchImbalanceTradesCollection
  | FetchImbalanceTradesCollectionSuccess
  | FetchImbalanceTradesCollectionFailure
  | SetImbalanceTrade
  | FetchImbalanceTradesSummaryCollection
  | FetchImbalanceTradesSummaryCollectionSuccess
  | FetchImbalanceTradesSummaryCollectionFailure
  | WithdrawImbalanceTrade
  | WithdrawImbalanceTradeSuccess
  | WithdrawImbalanceTradeFailure
  | FetchStorageBalanceCollection
  | FetchStorageBalanceCollectionSuccess
  | FetchStorageBalanceCollectionFailure
  | ExportStorageBalance
  | ExportStorageBalanceSuccess
  | ExportStorageBalanceFailure
  | CreateImbalanceTrade
  | CreateImbalanceTradeSuccess
  | CreateImbalanceTradeFailure
  | ClearCachedCreateImbalanceTrade
  | FetchShipperActivityCollection
  | FetchShipperActivityCollectionSuccess
  | FetchShipperActivityCollectionFailure
  | FetchShipperActivityContractCollection
  | FetchShipperActivityContractCollectionSuccess
  | FetchShipperActivityContractCollectionFailure
  | UpdateImbalanceTrade
  | UpdateImbalanceTradeSuccess
  | UpdateImbalanceTradeFailure
  | ExportShipperActivity
  | ExportShipperActivitySuccess
  | ExportShipperActivityFailure
  | FetchAuthToPostImbalancesCollection
  | FetchAuthToPostImbalancesCollectionSuccess
  | FetchAuthToPostImbalancesCollectionFailure
  | PutAuthToPostImbalances
  | PutAuthToPostImbalancesSuccess
  | PutAuthToPostImbalancesFailure
  | PutBalancingAgreementImbalanceSignoff
  | PutBalancingAgreementImbalanceSignoffSuccess
  | PutBalancingAgreementImbalanceSignoffFailure
  | GetAuthorizedImbalanceAccounts
  | GetAuthorizedImbalanceAccountsSuccess
  | GetAuthorizedImbalanceAccountsFailure
  | FetchImbalanceInventories
  | FetchImbalanceInventoriesSuccess
  | FetchImbalanceInventoriesError
  | FetchAcctImbalance
  | FetchAcctImbalanceSuccess
  | FetchAcctImbalanceError
  | EditObaSummaryRequest
  | EditObaSummarySuccess
  | EditObaSummaryFailure
  | EditShipperActivityRequest
  | EditShipperActivitySuccess
  | EditShipperActivityFailure
  | ExportObaSummaryRequest
  | ExportObaSummarySuccess
  | ExportObaSummaryFailure
  | FetchObaSummaryRequest
  | FetchObaSummarySuccess
  | FetchObaSummaryFailure
  | FetchTradeWindow
  | FetchTradeWindowSuccess
  | FetchTradeWindowFailure
  | ClearTradeWindowData
  | FetchPipelinePosition
  | FetchPipelinePositionSuccess
  | FetchPipelinePositionFailure
  | FetchMasterRateCICOScheduleCollection
  | FetchMasterRateCICOScheduleCollectionSuccess
  | FetchMasterRateCICOScheduleCollectionFailure;
