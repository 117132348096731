import {
  JobConfig,
  JobConfigCollection,
  JobInstance,
  JobInstanceCollection,
} from '@gms/jobschedule-api';
import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';

function convertToDate(stringDate: string | Date) {
  if (isNullOrUndefined(stringDate)) return null;

  return dateUtils.convertJSONDateToDateObject(stringDate) as Date;
}

function transformJobInstanceDates(instance: JobInstance): JobInstance {
  return {
    ...instance,
    scheduledDateTime: convertToDate(instance.scheduledDateTime),
    startDateTime: convertToDate(instance.startDateTime),
    endDateTime: convertToDate(instance.endDateTime),
  };
}

function transformJobConfigDates(config: JobConfig): JobConfig {
  return {
    ...config,
    nextRunTime: convertToDate(config.nextRunTime),
    lastRunTime: convertToDate(config.lastRunTime),
  };
}

export function transformJobInstance(instance: JobInstance): JobInstance {
  return transformJobInstanceDates(instance);
}

export function transformJobInstancesDates(data: JobInstanceCollection) {
  return {
    ...data,
    jobInstances: data.jobInstances.map(transformJobInstance),
  };
}

export function transformJobConfig(config: JobConfig): JobConfig {
  return transformJobConfigDates(config);
}

export function transformJobConfigs(data: JobConfigCollection): JobConfigCollection {
  return {
    ...data,
    jobConfigs: data.jobConfigs.map(transformJobConfig),
  };
}
