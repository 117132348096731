import { MarketSectorOverride, MarketSectorOverrideCollection } from '@gms/accounting-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export enum EMarketSectorOverridesActions {
  FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION = '[MARKET-SECTOR-OVERRIDES] Fetch Market Sector Overrides Collection',
  FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION_SUCCESS = '[MARKET-SECTOR-OVERRIDES] Fetch Market Sector Overrides Collection Success',
  FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION_FAILURE = '[MARKET-SECTOR-OVERRIDES] Fetch Market Sector Overrides Collection Failure',
  UPDATE_MARKET_SECTOR_OVERRIDES = '[MARKET_SECTOR_SECTOR_OVERRIDES] Batch Update/Create Market Sector Overrides',
  UPDATE_MARKET_SECTOR_OVERRIDES_SUCCESS = '[MARKET_SECTOR_SECTOR_OVERRIDES] Batch Update/Create Market Sector Overrides Sucess',
  UPDATE_MARKET_SECTOR_OVERRIDES_FAILURE = '[MARKET_SECTOR_SECTOR_OVERRIDES] Batch Update/Create Market Sector Overrides Failure',
  DELETE_MARKET_SECTOR_OVERRIDE = '[MARKET_SECTOR_SECTOR_OVERRIDES] Delete A Market Sector Override',
  DELETE_MARKET_SECTOR_OVERRIDE_SUCCESS = '[MARKET_SECTOR_SECTOR_OVERRIDES] Delete A Market Sector Override Sucess',
  DELETE_MARKET_SECTOR_OVERRIDE_FAILURE = '[MARKET_SECTOR_SECTOR_OVERRIDES] Delete A Market Sector Override Failure',
}

export interface IMarketSectorOverridesCollectionPayload {
  tspId: number;
  entityNameId?: number;
  locationNameId?: number;
  pageSize?: number;
  pageNumber?: number;
}

export class GetMarketSectorOverridesCollection implements Action {
  public readonly type = EMarketSectorOverridesActions.FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION;

  constructor(public payload: IMarketSectorOverridesCollectionPayload) {}
}

export class GetMarketSectorOverridesCollectionSuccess implements Action {
  public readonly type =
    EMarketSectorOverridesActions.FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION_SUCCESS;

  constructor(public payload: MarketSectorOverrideCollection) {}
}

export class GetMarketSectorOverridesCollectionFailure implements Action {
  public readonly type =
    EMarketSectorOverridesActions.FETCH_MARKET_SECTOR_OVERRIDES_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateMarketSectorOverrides implements Action {
  public readonly type = EMarketSectorOverridesActions.UPDATE_MARKET_SECTOR_OVERRIDES;

  constructor(public payload: MarketSectorOverrideCollection) {}
}

export class UpdateMarketSectorOverridesSuccess implements Action {
  public readonly type = EMarketSectorOverridesActions.UPDATE_MARKET_SECTOR_OVERRIDES_SUCCESS;

  constructor(public payload: MarketSectorOverrideCollection) {}
}

export class UpdateMarketSectorOverridesFailure implements Action {
  public readonly type = EMarketSectorOverridesActions.UPDATE_MARKET_SECTOR_OVERRIDES_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteMarketSectorOverride implements Action {
  public readonly type = EMarketSectorOverridesActions.DELETE_MARKET_SECTOR_OVERRIDE;

  constructor(public id: number) {}
}

export class DeleteMarketSectorOverrideSuccess implements Action {
  public readonly type = EMarketSectorOverridesActions.DELETE_MARKET_SECTOR_OVERRIDE_SUCCESS;
}

export class DeleteMarketSectorOverrideFailure implements Action {
  public readonly type = EMarketSectorOverridesActions.DELETE_MARKET_SECTOR_OVERRIDE_FAILURE;

  constructor(public payload: Error) {}
}

export type MarketSectorOverridesActions =
  | GetMarketSectorOverridesCollection
  | GetMarketSectorOverridesCollectionSuccess
  | GetMarketSectorOverridesCollectionFailure
  | UpdateMarketSectorOverrides
  | UpdateMarketSectorOverridesSuccess
  | UpdateMarketSectorOverridesFailure
  | DeleteMarketSectorOverride
  | DeleteMarketSectorOverrideSuccess
  | DeleteMarketSectorOverrideFailure;
