import {
  RepRolesCollection,
  RoleRepsCollection,
  UserResponsibilitiesCollection,
} from '@gms/represponsibility-api';
import { emptyRequestStatus, IDataState, IRequestStatus } from '../app/app.models';

export interface IRepResponsibilityState {
  repResponsibilityRoles: IDataState<RepRolesCollection>;
  userResponsibilities: IDataState<UserResponsibilitiesCollection>;
  repResponsibilities: IDataState<RoleRepsCollection>;
  updateUserResponsibilities: IDataState<UserResponsibilitiesCollection>;
}

export const initialRepResponsibilityState: IRepResponsibilityState = {
  repResponsibilityRoles: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  userResponsibilities: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  repResponsibilities: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
  updateUserResponsibilities: {
    data: null,
    requestStatus: { ...emptyRequestStatus },
  },
};
