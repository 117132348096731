import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  LocationStorageService,
  StorageLocationResult,
} from 'app/modules/service-request/services/locationStorage.service';
import { asyncScheduler, forkJoin, Observable, of } from 'rxjs';
import {
  auditTime,
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  DropdownDictionary,
  DynamicLocationGroup,
  DynamicLocationGroupCollection,
  DynamicLocationGroupsService,
  EffectiveDateRecord,
  LocationCollection,
  LocationDictionaryService,
  LocationGroup,
  LocationGroupCollection,
  LocationGroupCriteriaService,
  LocationGroupsService,
  LocationListCollection,
  LocationResult,
  LocationService,
} from '@gms/location-api';

import { IAppState } from 'app/store/app/app.state';
import {
  AddLocation,
  AddLocationError,
  AddLocationSuccess,
  CreateLocationGroup,
  CreateLocationGroupCriteria,
  CreateLocationGroupCriteriaFailure,
  CreateLocationGroupCriteriaSuccess,
  CreateLocationGroupFailure,
  CreateLocationGroupSuccess,
  DeleteLocationGroup,
  DeleteLocationGroupFailure,
  DeleteLocationGroupSuccess,
  ELocationsActions,
  FetchDynamicLocationGroups,
  FetchDynamicLocationGroupsFailure,
  FetchDynamicLocationGroupsSuccess,
  FetchLocationGroupById,
  FetchLocationGroupByIdFailure,
  FetchLocationGroupByIdSuccess,
  FetchLocationGroupChildLocations,
  FetchLocationGroupChildLocationsFailure,
  FetchLocationGroupChildLocationsSuccess,
  FetchLocationGroupCriteriaById,
  FetchLocationGroupCriteriaByIdFailure,
  FetchLocationGroupCriteriaByIdSuccess,
  FetchLocationGroups,
  FetchLocationGroupsByTspIds,
  FetchLocationGroupsByTspIdsFailure,
  FetchLocationGroupsByTspIdsSuccess,
  FetchLocationGroupsFailure,
  FetchLocationGroupsSuccess,
  FetchLocationsList,
  FetchLocationsListFailure,
  FetchLocationsListSuccess,
  FetchMultipleLocationGroupsById,
  FetchMultipleLocationGroupsByIdFailure,
  FetchMultipleLocationGroupsByIdSuccess,
  FetchParentLocation,
  FetchParentLocationFailure,
  FetchParentLocationSuccess,
  FetchStorageLocationsAction,
  FetchStorageLocationsFailureAction,
  FetchStorageLocationsSuccessAction,
  GetLocationById,
  GetLocationByIdError,
  GetLocationByIdSuccess,
  GetLocationDictionary,
  GetLocationDictionaryError,
  GetLocationDictionarySuccess,
  GetLocationEffectiveDateHistory,
  GetLocationEffectiveDateHistoryFailure,
  GetLocationEffectiveDateHistorySuccess,
  GetLocationGroupEffectiveDateHistory,
  GetLocationGroupEffectiveDateHistoryFailure,
  GetLocationGroupEffectiveDateHistorySuccess,
  GetLocations,
  GetLocationsByIds,
  GetLocationsByIdsError,
  GetLocationsByIdsSuccess,
  GetLocationsError,
  GetLocationsSuccess,
  IFetchDynamicLocationGroupsPayload,
  SearchLocationHeaders,
  SearchLocationHeadersFailure,
  SearchLocationHeadersSuccess,
  SearchLocations,
  SearchLocationsFailure,
  SearchLocationsFromMultipleTsps,
  SearchLocationsFromMultipleTspsFailure,
  SearchLocationsFromMultipleTspsSuccess,
  SearchLocationsSuccess,
  UpdateLocation,
  UpdateLocationDictionary,
  UpdateLocationError,
  UpdateLocationGroup,
  UpdateLocationGroupCriteria,
  UpdateLocationGroupCriteriaFailure,
  UpdateLocationGroupCriteriaSuccess,
  UpdateLocationGroupFailure,
  UpdateLocationGroupSuccess,
  UpdateLocationSuccess,
} from 'app/store/locations/locations.actions';
import { selectDropdownDictionary } from 'app/store/locations/locations.selectors';
import { mapLocationTypes } from 'app/store/locations/locations.utils';
import { dateUtils } from 'shared/utils/date.utils';
import { sortDefinitions } from 'app/store/default-sort.definitions';

@Injectable({
  providedIn: 'root',
})
export class LocationsEffect {
  DATE_KEYS = [
    'dateFlowStart',
    'typeEffective',
    'statusEffective',
    'pipelineSystemEffective',
    'flowDirectionEffective',
    'zoneEffective',
    'confirmingCompanyEffective',
    'gasQualityEffective',
  ];
  ENTITY_KEYS = [
    'confirmingCompany',
    'interconnectingCompany',
    'mtrOperator',
    'mtoCustodyTransfer',
  ];
  ALL_STATUS_FILTER = 'All';

  constructor(
    private _actions$: Actions,
    private _locationService: LocationService,
    private _locationGroupsService: LocationGroupsService,
    private _locationGroupCriteriaService: LocationGroupCriteriaService,
    private _locationDictionaryService: LocationDictionaryService,
    private _locationStorageService: LocationStorageService,
    private _dynamicLocationGroupService: DynamicLocationGroupsService,
    private _store: Store<IAppState>,
    private _router: Router
  ) {}

  addLocation$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<AddLocation>(ELocationsActions.AddLocation),
    debounceTime(500),
    map((action: AddLocation) => action),
    switchMap(action =>
      this._locationService.addLocation(action.payload.location).pipe(
        map(location => new AddLocationSuccess({ location })),
        catchError(error => of(new AddLocationError({ error })))
      )
    )
  ));

  getLocations$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetLocations>(ELocationsActions.GetLocations),
    switchMap((action: GetLocations) => {
      const payload = action.payload || {};

      let sortQuery = ``;
      if (payload.sortDescriptors) {
        payload.sortDescriptors.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
      }
      
      sortQuery = !!sortQuery? sortQuery: sortDefinitions.getLocations; 

      return this._locationService
        .getLocations(
          payload.pageSize,
          payload.pageNumber,
          [sortQuery],
          payload.tspId,
          payload.zoneId,
          payload.excludeAssociated,
          undefined, // Nominatable, not used for this endopoint currently
          null,
          payload.locationNumber,
          null,
          payload.locationName,
          payload.flowDirectionId,
          null,
          payload.classificationIds,
          payload.typeId,
          this.resolveLocationStatusFilter(payload.statusId),
          payload.searchPhrase,
          null,
          null,
          payload.pipelineSystemId
        )
        .pipe(
          map((locationCollection: LocationCollection) => {
            const mappedLocations = mapLocationTypes(locationCollection);
            return new GetLocationsSuccess({
              locations: mappedLocations,
              totalLocationCount: locationCollection.total,
            });
          }),
          catchError(error => of(new GetLocationsError({ error })))
        );
    })
  ));

  getLocationById$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetLocationById>(ELocationsActions.GetLocationById),
    map((action: GetLocationById) => action),
    filter(action => {
      return !!action.locationId;
    }),
    switchMap(action =>
      this._locationService.getLocationById(action.locationId).pipe(
        map(location => {
          const tempLocation = {
            ...location,
          };
          this.DATE_KEYS.forEach(key => {
            let date = dateUtils.stripTimeFromDate(location[key]);
            date = date ? date : '';
            if (date) {
              tempLocation[key] = date;
            }
          });
          return new GetLocationByIdSuccess(tempLocation);
        }),
        catchError(error => of(new GetLocationByIdError(error)))
      )
    )
  ));

  getLocationsByIds$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<GetLocationsByIds>(ELocationsActions.GET_LOCATIONS_BY_IDS),
    map((action: GetLocationsByIds) => action),
    debounceTime(500),
    switchMap(action => {
      // We want to be able to get all locations defined in the locationIds array independent of status.
      return this._locationService
        .getLocations(
          action.pageSize,
          null,
          [sortDefinitions.getLocations],
          null,
          null,
          null,
          null,
          action.locationIds,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          this.ALL_STATUS_FILTER
        )
        .pipe(
          map(locationResponse => new GetLocationsByIdsSuccess(locationResponse.locations)),
          catchError(error => of(new GetLocationsByIdsError(error)))
        );
    })
  ));

  getLocationDictionary$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<GetLocationDictionary>(ELocationsActions.GetLocationDictionary),
      withLatestFrom(this._store.pipe(select(selectDropdownDictionary))),
      auditTime(debounce, scheduler),
      switchMap(([_, dropdownDictionaryState]: [GetLocationDictionary, DropdownDictionary]) => {
        if (dropdownDictionaryState) {
          return of(
            new GetLocationDictionarySuccess({
              useCache: true,
            })
          );
        }

        return this._locationDictionaryService.getDictionary().pipe(
          switchMap((dropdownDictionary: DropdownDictionary) =>
            of(
              new GetLocationDictionarySuccess({
                dropdownDictionary,
              })
            )
          ),
          catchError(error => of(new GetLocationDictionaryError(error)))
        );
      })
    )
  );

  updateLocationDictionary$ = createEffect(() => this._actions$.pipe(
    ofType<UpdateLocationDictionary>(ELocationsActions.UpdateLocationDictionary),
    map((action: UpdateLocationDictionary) => action),
    switchMap(_ =>
      this._locationDictionaryService.getDictionary().pipe(
        switchMap((dropdownDictionary: DropdownDictionary) =>
          of(new GetLocationDictionarySuccess({ dropdownDictionary }))
        ),
        catchError(error => of(new GetLocationDictionaryError(error)))
      )
    )
  ));

  getLocationEffectiveDateHistory = createEffect(() => this._actions$.pipe(
    ofType<GetLocationEffectiveDateHistory>(ELocationsActions.GetLocationEffectiveDateHistory),
    map((action: GetLocationEffectiveDateHistory) => action.payload),
    switchMap((locationId: number) =>
      this._locationService.getEffectiveDateHistory(locationId).pipe(
        switchMap((locationEffectiveDateHistory: EffectiveDateRecord[]) =>
          of(new GetLocationEffectiveDateHistorySuccess(locationEffectiveDateHistory))
        ),
        catchError(error => of(new GetLocationEffectiveDateHistoryFailure(error)))
      )
    )
  ));

  getLocationGroupEffectiveDateHistory = createEffect(() => this._actions$.pipe(
    ofType<GetLocationGroupEffectiveDateHistory>(
      ELocationsActions.GetLocationGroupEffectiveDateHistory
    ),
    map((action: GetLocationGroupEffectiveDateHistory) => action.payload),
    switchMap((locationId: number) =>
      this._locationGroupsService.getLocGroupEffDateHistory(locationId).pipe(
        switchMap((locationGroupEffectiveDateHistory: EffectiveDateRecord[]) =>
          of(new GetLocationGroupEffectiveDateHistorySuccess(locationGroupEffectiveDateHistory))
        ),
        catchError(error => of(new GetLocationGroupEffectiveDateHistoryFailure(error)))
      )
    )
  ));

  fetchLocationGroupById$ = createEffect(() => this._actions$.pipe(
    ofType<FetchLocationGroupById>(ELocationsActions.FetchLocationGroupById),
    map((action: FetchLocationGroupById) => action.payload),
    switchMap(({ locationGroupId, effectiveDate }) =>
      this._locationGroupsService
        .getLocationGroup(locationGroupId, dateUtils.getDateAsYYYY_MM_DD(effectiveDate))
        .pipe(
          switchMap((locationGroup: LocationGroup) =>
            of(new FetchLocationGroupByIdSuccess({ locationGroup }))
          ),
          catchError((error: Error) => of(new FetchLocationGroupByIdFailure({ error })))
        )
    )
  ));

  fetchLocationGroups$ = createEffect(() => this._actions$.pipe(
    ofType<FetchLocationGroups>(ELocationsActions.FetchLocationGroups),
    map((action: FetchLocationGroups) => action.payload),
    switchMap(payload => {
      payload = payload || {};
      return this._locationGroupsService
        .getLocationGroups(
          payload.tspId,
          payload.pageSize,
          payload.pageNumber,
          payload.effectiveDate ? new Date(payload.effectiveDate).toISOString() : null,
          payload.statusCode,
          payload.groupTypeId,
          payload.groupName,
          payload.groupLocationId,
          payload.locationGroupIds,
          null,
          false,
          payload.sortBy,
          payload.SearchPhrase,
          payload.fields
        )
        .pipe(
          switchMap((locationGroups: LocationGroupCollection) =>
            of(new FetchLocationGroupsSuccess(locationGroups))
          ),
          catchError(error => of(new FetchLocationGroupsFailure(error)))
        );
    })
  ));

  FetchLocationGroupsByTspIds = createEffect(() => this._actions$.pipe(
    ofType<FetchLocationGroupsByTspIds>(ELocationsActions.FetchLocationGroupsByTspIds),
    switchMap(action => {
      const locationResult = action.tspIds.map(tspId =>
        this._locationGroupsService.getLocationGroups(
          tspId,
          action.pageSize,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          action.fields
        )
      );
      return forkJoin(locationResult).pipe(
        map(locations => new FetchLocationGroupsByTspIdsSuccess(locations)),
        catchError(error => of(new FetchLocationGroupsByTspIdsFailure(error)))
      );
    })
  ));

  resetLocation$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<UpdateLocation>(ELocationsActions.ResetLocation),
    map(_ => new UpdateLocationSuccess({ location: null }))
  ));

  updateLocation$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<UpdateLocation>(ELocationsActions.UpdateLocation),
    map((action: UpdateLocation) => action),
    switchMap(action =>
      this._locationService
        .updateLocation(action.payload.location, action.payload.location.locationId)
        .pipe(
          map(location => new UpdateLocationSuccess({ location })),
          catchError(error => of(new UpdateLocationError({ error })))
        )
    )
  ));

  SearchLocations: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<SearchLocations>(ELocationsActions.SearchLocations),
    map((action: SearchLocations) => action.payload),
    switchMap(payload =>
      this._locationService
        .getLocations(
          payload.pageSize,
          payload.pageNumber,
          payload.sortBy ? [payload.sortBy] : [sortDefinitions.getLocations],
          payload.tspId,
          null,
          payload.excludeAssociated,
          payload.nominateable,
          payload.locationIds && payload.locationIds.length ? payload.locationIds : undefined,
          undefined,
          payload.locationNumbers ? payload.locationNumbers : [],
          undefined,
          payload.flowDirection,
          undefined,
          undefined,
          undefined,
          this.resolveLocationStatusFilter(payload.statusId),
          payload.searchPhrase,
          payload.masterRateScheduleId,
          payload.detailRateScheduleId,
          payload.pipelineSystemId,
          undefined, // effectivedate
          undefined, // unsubscribedCapacity
          undefined, // masterList
          undefined, // interconnectIds
          payload.includePipelines
        )
        .pipe(
          map(
            locationCollection =>
              new SearchLocationsSuccess({ locations: locationCollection.locations })
          ),
          catchError(error => {
            return of(new SearchLocationsFailure({ error: error }));
          })
        )
    )
  ));

  SearchLocationsFromMultipleTsps: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<SearchLocationsFromMultipleTsps>(ELocationsActions.SearchLocationsFromMultipleTsps),
    map((action: SearchLocationsFromMultipleTsps) => action.payload),
    switchMap(payload =>
      forkJoin(
        payload.tspIds.map(tspId =>
          this._locationService
            .getLocations(
              payload.pageSize,
              payload.pageNumber,
              payload.sortBy ? [payload.sortBy] : [sortDefinitions.getLocations],
              tspId,
              null,
              payload.excludeAssociated,
              payload.nominateable,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              this.ALL_STATUS_FILTER,
              payload.searchPhrase
            )
            .pipe(catchError(error => of(error)))
        )
      ).pipe(
        map(locationCollection => {
          const locationsByTsp = {};
          locationCollection.forEach(tsp => {
            if (tsp.locations.length > 0) {
              locationsByTsp[tsp.locations[0].tspId] = tsp.locations;
            }
          });
          return new SearchLocationsFromMultipleTspsSuccess({ locations: locationsByTsp });
        }),
        catchError(error => {
          return of(new SearchLocationsFromMultipleTspsFailure({ error: error }));
        })
      )
    )
  ));

  SearchLocationHeaders: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<SearchLocationHeaders>(ELocationsActions.SearchLocationHeaders),
    map((action: SearchLocationHeaders) => action.payload),
    switchMap(payload =>
      this._locationService
        .getLocationHeaders(
          payload.agencyResourceName,
          payload.minPermissionOption,
          payload.agencyTransactionStartDate,
          payload.agencyTransactionEndDate,
          payload.allowOwnerOrOperator,
          payload.agencyAllowHistoricalView,
          payload.pageSize,
          payload.pageNumber,
          payload.sortBy ? [payload.sortBy] : [sortDefinitions.getLocationHeaders],
          payload.tspId,
          payload.zoneId,
          payload.excludeAssociated,
          payload.nominateable,
          payload.locationIds,
          payload.locationNumber,
          payload.locationNumbers,
          payload.locationName,
          payload.flowDirectionId,
          payload.classificationId,
          payload.typeId,
          this.resolveLocationStatusFilter(payload.statusId),
          payload.searchPhrase,
          payload.masterRateScheduleId,
          payload.detailRateScheduleId,
          payload.pipelineSystemId,
          payload.pipelineSystemIds,
          payload.effectiveDate,
          payload.interconnectIds,
          payload.confirmingParty,
          payload.agencyInferChildAccess,
          payload.confirmingOrOwnerOrOperatorId
        )
        .pipe(
          map(
            locationHeaderCollections => new SearchLocationHeadersSuccess(locationHeaderCollections)
          ),
          catchError(error => {
            return of(new SearchLocationHeadersFailure({ error: error }));
          })
        )
    )
  ));

  createLocationGroup$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CreateLocationGroup>(ELocationsActions.CreateLocationGroup),
    debounceTime(500),
    switchMap((action: CreateLocationGroup) =>
      this._locationGroupsService.postLocationGroup(action.payload.locationGroup).pipe(
        tap((locationGroup: LocationGroup) => {
          this._router.navigate([`/location-groups/${locationGroup.locationGroupId}`]);
        }),
        map(() => new CreateLocationGroupSuccess()),
        catchError(error => of(new CreateLocationGroupFailure({ error })))
      )
    )
  ));

  updateLocationGroup$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<UpdateLocationGroup>(ELocationsActions.UpdateLocationGroup),
    switchMap((action: UpdateLocationGroup) =>
      this._locationGroupsService
        .putLocationGroup(
          action.payload.locationGroup,
          action.payload.locationGroup.locationGroupId
        )
        .pipe(
          map(() => new UpdateLocationGroupSuccess()),
          tap(() => {
            this._router.navigate([
              `/location-groups/${action.payload.locationGroup.locationGroupId}`,
            ]);
          }),
          catchError(error => of(new UpdateLocationGroupFailure({ error })))
        )
    )
  ));

  deleteLocationGroup$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<DeleteLocationGroup>(ELocationsActions.DeleteLocationGroup),
    switchMap((action: DeleteLocationGroup) =>
      this._locationGroupsService.deleteLocationGroup(action.payload.locationGroupId).pipe(
        map(() => new DeleteLocationGroupSuccess()),
        catchError(error => of(new DeleteLocationGroupFailure({ error })))
      )
    )
  ));

  getStorageLocations$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchStorageLocationsAction>(ELocationsActions.FetchStorageLocations),
    switchMap((action: FetchStorageLocationsAction) =>
      this._locationStorageService
        .getStorageLocations(action.payload.masterRateScheduleCode, action.payload.tspId)
        .pipe(
          map((result: StorageLocationResult) => new FetchStorageLocationsSuccessAction(result)),
          catchError(error => of(new FetchStorageLocationsFailureAction({ error })))
        )
    )
  ));

  fetchMultipleLocationGroupsByIds: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchMultipleLocationGroupsById>(ELocationsActions.FETCH_LOCATION_GROUPS_BY_IDS),
    map((action: FetchMultipleLocationGroupsById) => action),
    debounceTime(500),
    switchMap(payload =>
      forkJoin(
        payload.locationGroupdIds.map(locationGroupId =>
          this._locationGroupsService
            .getLocationGroup(Number(locationGroupId))
            .pipe(catchError(error => of(error)))
        )
      ).pipe(
        map(locationGroups => {
          return new FetchMultipleLocationGroupsByIdSuccess({ locationGroupByIds: locationGroups });
        }),
        catchError(error => {
          return of(new FetchMultipleLocationGroupsByIdFailure(error));
        })
      )
    )
  ));

  createLocationGroupCriteria$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<CreateLocationGroupCriteria>(ELocationsActions.CreateLocationGroupCriteria),
    switchMap((action: CreateLocationGroupCriteria) =>
      this._locationGroupCriteriaService
        .postLocationGroupCriteria(action.payload.locationGroupCriteria)
        .pipe(
          tap((dynamicLocationGroup: DynamicLocationGroup) => {
            this._router.navigate([
              `/location-groups/criteria-based/${dynamicLocationGroup.dynamicLocationGroupId}`,
            ]);
          }),
          map(() => new CreateLocationGroupCriteriaSuccess()),
          catchError(error => of(new CreateLocationGroupCriteriaFailure({ error })))
        )
    )
  ));

  updateLocationGroupCriteria$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<UpdateLocationGroupCriteria>(ELocationsActions.UpdateLocationGroupCriteria),
    switchMap((action: UpdateLocationGroupCriteria) =>
      this._locationGroupCriteriaService
        .updateDynamicLocationGroup(
          action.payload.locationGroupCriteria,
          action.payload.locationGroupCriteria.dynamicLocationGroupId
        )
        .pipe(
          tap((dynamicLocationGroup: DynamicLocationGroup) => {
            this._router.navigate([
              `/location-groups/criteria-based/${dynamicLocationGroup.dynamicLocationGroupId}`,
            ]);
          }),
          map(() => new UpdateLocationGroupCriteriaSuccess()),
          catchError(error => of(new UpdateLocationGroupCriteriaFailure({ error })))
        )
    )
  ));

  fetchLocationGroupCriteriaById$ = createEffect(() => this._actions$.pipe(
    ofType<FetchLocationGroupCriteriaById>(ELocationsActions.FetchLocationGroupCriteriaById),
    map((action: FetchLocationGroupCriteriaById) => action.payload),
    switchMap(({ locationGroupId, effectiveDate }) =>
      this._locationGroupCriteriaService
        .getLocationGroupCriteria(locationGroupId, dateUtils.getDateAsYYYY_MM_DD(effectiveDate))
        .pipe(
          switchMap((dynamicLocationGroup: DynamicLocationGroup) =>
            of(new FetchLocationGroupCriteriaByIdSuccess({ dynamicLocationGroup }))
          ),
          catchError((error: Error) => of(new FetchLocationGroupCriteriaByIdFailure({ error })))
        )
    )
  ));

  fetchLocationGroupChildLocations$ = createEffect(() => this._actions$.pipe(
    ofType<FetchLocationGroupChildLocations>(ELocationsActions.FetchLocationGroupChildLocations),
    map((action: FetchLocationGroupChildLocations) => action.payload),
    switchMap(({ locationGroupId, effectiveDate }) =>
      this._locationGroupCriteriaService
        .getGroupCriteriaResults(locationGroupId, dateUtils.getDateAsYYYY_MM_DD(effectiveDate))
        .pipe(
          switchMap((childLocations: LocationResult[]) =>
            of(new FetchLocationGroupChildLocationsSuccess({ childLocations }))
          ),
          catchError((error: Error) => of(new FetchLocationGroupChildLocationsFailure({ error })))
        )
    )
  ));

  getLocationsList$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchLocationsList>(ELocationsActions.FetchLocationsList),
    switchMap((action: FetchLocationsList) => {
      const payload = action.payload || {};

      let sortQuery = ``;
      if (payload.sortDescriptors) {
        payload.sortDescriptors.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
      }
      const classificationId = payload.classificationId
        ? parseInt(payload.classificationId, 10)
        : null;

      return this._locationService
        .listLocations(
          payload.pageSize,
          payload.pageNumber,
          [sortQuery],
          payload.tspId,
          payload.zoneId,
          payload.areaId,
          payload.excludeAssociated,
          undefined, // Nominatable, not used for this endopoint currently
          null,
          payload.locationNumber,
          null,
          payload.locationName,
          payload.flowDirectionId,
          classificationId,
          payload.typeId,
          this.resolveLocationStatusFilter(payload.statusId),
          payload.searchPhrase,
          null,
          null,
          payload.pipelineSystemId
        )
        .pipe(
          map((locations: LocationListCollection) => {
            locations.locations = mapLocationTypes(locations);
            return new FetchLocationsListSuccess({ locations });
          }),
          catchError(error => of(new FetchLocationsListFailure({ error })))
        );
    })
  ));

  getParentLocation$: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchParentLocation>(ELocationsActions.FetchParentLocation),
    switchMap((action: FetchParentLocation) => {
      const { childLocationId } = action.payload;

      return this._locationGroupsService
        .getLocationGroups(
          null, // tspId
          null, // pageSize
          null, // pageNumber
          null, // effectiveDate
          null, // statusCode
          1, // groupTypeId - Aggregate
          null,
          null, // groupName
          null, // groupLocationId
          childLocationId
        )
        .pipe(
          map((locationGroups: LocationGroupCollection) => {
            return locationGroups.locationGroups[0];
          }),
          catchError(error => of(new FetchParentLocationFailure({ error })))
        );
    }),
    switchMap((locationGroup: LocationGroup) => {
      if (!locationGroup) return of(new FetchParentLocationSuccess({}));

      const parentLocationId = locationGroup.parentLocation.locationId;
      return this._locationService.getLocationById(parentLocationId).pipe(
        map(location => {
          return new FetchParentLocationSuccess({ parentLocation: location });
        }),
        catchError(error => of(new FetchParentLocationFailure({ error })))
      );
    })
  ));

  fetchDynamicLocationGroups: Observable<any> = createEffect(() => this._actions$.pipe(
    ofType<FetchDynamicLocationGroups>(ELocationsActions.FetchDynamicLocationGroups),
    switchMap((action: FetchDynamicLocationGroups) => {
      const payload = action.payload;
      return this._dynamicLocationGroupService
        .getDynamicLocationGroups(
          payload.tspId,
          payload.capacityReporting,
          payload.statusCode,
          payload.groupTypeId,
          payload.gasDay
        )
        .pipe(
          map((response: DynamicLocationGroupCollection) => {
            return new FetchDynamicLocationGroupsSuccess({
              locationGroups: response.locationGroups,
            });
          }),
          catchError((error: Error) => of(new FetchDynamicLocationGroupsFailure({ error })))
        );
    })
  ));

  private resolveLocationStatusFilter(selectedStatus: string) {
    // The API will default the status filter to "Active" if left empty.
    // On the FE we want to get all locations unless a status filter is selected.
    return selectedStatus && selectedStatus !== '' ? selectedStatus : this.ALL_STATUS_FILTER;
  }
}
