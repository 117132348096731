import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { saveAs } from '@progress/kendo-file-saver';
import { Observable, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  debounce,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { BillingFuelRateService, FuelRateResponseCollection } from '@gms/invoicing-api';
import {
  CycleCollection,
  CycleService,
  GasDay,
  GasdayService,
  NominationService,
  NominationSummary,
  NominationUploadService,
  OrderManagementDictionaryService,
  ReductionReasonsService,
  ScheduledQuantityCollection,
  ScheduledQuantityForOperatorsService,
  ScheduledQuantityService,
  TransactionTypesService,
  TspDetailCollection,
  TspDetailService,
} from '@gms/nomination-api';
import { filterIfCached } from 'app/store/app/app.models';
import { IAppState } from 'app/store/app/app.state';
import get from 'lodash/get';
import { HttpCodes } from 'shared/consts/http-codes.const';
import { dateUtils } from 'shared/utils/date.utils';
import {
  CreateNomination,
  CreateNominationCyclesOverride,
  CreateNominationCyclesOverrideFailure,
  CreateNominationCyclesOverrideSuccess,
  CreateNominationFailure,
  CreateNominationSuccess,
  CreateNominationValidationFailure,
  ENominationsActions,
  ExportOperatorScheduledQuantities,
  ExportOperatorScheduledQuantitiesFailure,
  ExportOperatorScheduledQuantitiesSuccess,
  ExportScheduledQuantities,
  ExportScheduledQuantitiesFailure,
  ExportScheduledQuantitiesSuccess,
  FetchCurrentGasDay,
  FetchCurrentGasDayFailure,
  FetchCurrentGasDaySuccess,
  FetchFuelRate,
  FetchFuelRateFailure,
  FetchFuelRateSuccess,
  FetchNominationById,
  FetchNominationByIdError,
  FetchNominationByIdSuccess,
  FetchNominationCycles,
  FetchNominationCyclesFailure,
  FetchNominationCyclesSuccess,
  FetchNominations,
  FetchNominationsError,
  FetchNominationsSuccess,
  FetchNominationSummary,
  FetchNominationSummaryFailure,
  FetchNominationSummarySuccess,
  FetchOperatorScheduledQuantities,
  FetchOperatorScheduledQuantitiesFailure,
  FetchOperatorScheduledQuantitiesSuccess,
  FetchOperatorScheduledQuantity,
  FetchOperatorScheduledQuantityFailure,
  FetchOperatorScheduledQuantitySuccess,
  FetchOrderManagementDictionary,
  FetchOrderManagementDictionaryFailure,
  FetchOrderManagementDictionarySuccess,
  FetchReductionReasons,
  FetchReductionReasonsFailure,
  FetchReductionReasonsSuccess,
  FetchScheduledQuantities,
  FetchScheduledQuantitiesFailure,
  FetchScheduledQuantitiesSuccess,
  FetchScheduledQuantity,
  FetchScheduledQuantityFailure,
  FetchScheduledQuantitySuccess,
  FetchTransactionTypes,
  FetchTransactionTypesSuccess,
  FetchTspDetails,
  FetchTspDetailsFailure,
  FetchTspDetailsSuccess,
  IExportOperatorScheduledQuantityPayload,
  IExportScheduledQuantitiesPayload,
  IFetchNominationCyclePayload,
  IFetchNominationSummary,
  IFuelRatePayload,
  INominationsUploadPayload,
  IScheduledQuantitiesPayload,
  ITransactionTypesPayload,
  UploadNominations,
  UploadNominationsError,
  UploadNominationsSuccess,
  ValidateNominations,
  ValidateNominationsFailure,
  ValidateNominationsSuccess,
} from './nominations.actions';
import { selectTspDetails } from './nominations.selectors';
import { sortNominationCycles } from './nominations.utils';
import { ToastService } from 'shared/services/toast.service';
import { DEBOUNCE_100 } from 'shared/consts/debounce.const';
@Injectable()
export class NominationsEffects {
  constructor(
    private _actions$: Actions,
    private _uploadNominationsService: NominationUploadService,
    private _nominationsService: NominationService,
    private _transactionTypesService: TransactionTypesService,
    private _reductionReasonsService: ReductionReasonsService,
    private _scheduledQuantitiesService: ScheduledQuantityService,
    private _scheduledQuantityForOperatorsService: ScheduledQuantityForOperatorsService,
    private _orderManagementDictionaryService: OrderManagementDictionaryService,
    private _cycleService: CycleService,
    private _gasDayService: GasdayService,
    private _invoicingService: BillingFuelRateService,
    private _tspDetailService: TspDetailService,
    private _store: Store<IAppState>,
    private _toastService: ToastService
  ) {}

  UploadNominations: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UploadNominations>(ENominationsActions.UPLOAD_NOMINATIONS),
      map((action: UploadNominations) => action.payload),
      switchMap((payload: INominationsUploadPayload) => {
        return this._uploadNominationsService
          .s3NominationUpload(payload.file, payload.contentType, payload.fileName, null)
          .pipe(
            map(response => {
              return new UploadNominationsSuccess({
                ...{ success: true },
              });
            }),
            catchError(error => {
              return of(new UploadNominationsError(error));
            })
          );
      })
    )
  );

  FetchNominations: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNominations>(ENominationsActions.FETCH_NOMINATIONS),
      map((action: FetchNominations) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }

        return this._nominationsService
          .getNominations(
            payload.pageSize,
            payload.pageNumber,
            sortQuery,
            payload.excludeZeroNomQty,
            payload.tspId,
            payload.serviceRequestEntityId,
            payload.contractId,
            payload.status,
            new Date(new Date(get(payload, 'gasFlowDate')).toDateString()),
            null, // gasFlowEndDate?: Date,
            payload.lastChangeCycleCode,
            payload.transactionTypeCode,
            payload.packageId,
            payload.receiptLocationNumber,
            payload.upstreamEntityId,
            payload.upstreamContractId,
            payload.deliveryLocationNumber,
            payload.downstreamEntityId,
            payload.downstreamContractId,
            null, // locationNumberList: Array<number>,
            payload.cycleCode,
            payload.upstreamEntityCustomInput,
            payload.downstreamEntityCustomInput,
            null, // locationIdList: Array<number>
            payload.contractNumber
          )
          .pipe(
            map(nominationsCollection => {
              return new FetchNominationsSuccess({
                nominations: nominationsCollection.nominations,
                totalNominationsCount: nominationsCollection.total,
                agencyAccessBySvcReq: nominationsCollection.editAccess,
              });
            }),
            catchError(error => of(new FetchNominationsError({ error: error })))
          );
      })
    )
  );

  FetchNominationCycles: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNominationCycles>(ENominationsActions.FETCH_NOMINATION_CYCLES),
      map((action: FetchNominationCycles) => action.payload),
      switchMap((payload: IFetchNominationCyclePayload) => {
        return this._cycleService
          .getCycle(
            payload.tspId, // Tsp Id
            payload.cycleId, // cycle id
            payload.dateEffective, // date effective
            payload.naesbOnly, // naesbObly
            payload.addGasDayOffset // addGasDayOffset
          )
          .pipe(
            map(cycleCollection => {
              return new FetchNominationCyclesSuccess({
                cycles: sortNominationCycles(cycleCollection.cycles),
              });
            }),
            catchError(error => of(new FetchNominationCyclesFailure(error)))
          );
      })
    )
  );

  FetchNominationSummary: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNominationSummary>(ENominationsActions.FETCH_NOMINATION_SUMMARY),
      map((action: FetchNominationSummary) => action.payload),
      switchMap((payload: IFetchNominationSummary) => {
        return this._nominationsService
          .getNominationSummaryById(payload.nominationId, payload.gasDay, payload.cycleId)
          .pipe(
            map((nominationSummary: NominationSummary) => {
              return new FetchNominationSummarySuccess(nominationSummary);
            }),
            catchError(error => of(new FetchNominationSummaryFailure(error)))
          );
      })
    )
  );

  CreateNominationCyclesOverride: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateNominationCyclesOverride>(ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE),
      debounceTime(500),
      map((action: CreateNominationCyclesOverride) => action.payload),
      switchMap(payload => {
        return this._cycleService.postCycle(payload).pipe(
          map((cycleOverrides: CycleCollection) => {
            return new CreateNominationCyclesOverrideSuccess(cycleOverrides);
          }),
          catchError(error => of(new CreateNominationCyclesOverrideFailure(error)))
        );
      })
    )
  );

  FetchNominationById: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNominationById>(ENominationsActions.FETCH_NOMINATION_BY_ID),
      map((action: FetchNominationById) => action.payload),
      switchMap(payload => {
        return this._nominationsService.getNominationDetailById(payload).pipe(
          map(
            nomination =>
              new FetchNominationByIdSuccess({
                nomination: nomination,
              })
          ),
          catchError(error => of(new FetchNominationByIdError({ error: error })))
        );
      })
    )
  );

  ValidateNominations: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ValidateNominations>(ENominationsActions.VALIDATE_NOMINATIONS),
      map((action: ValidateNominations) => action.payload),
      mergeMap(payload => {
        return this._nominationsService.validateNomination(payload).pipe(
          map(
            nomination =>
              new ValidateNominationsSuccess({
                nominations: nomination.nominations,
              })
          ),
          catchError(error => {
            return of(new ValidateNominationsFailure({ error }));
          })
        );
      })
    )
  );

  CreateNomination: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateNomination>(ENominationsActions.CREATE_NOMINATION),
      map((action: CreateNomination) => action.payload),
      mergeMap(payload => {
        payload.nominations.forEach((e: any) => {
          delete e.dynamicFuelQty;
          delete e.dynamicFuelRate;
          delete e.errorMessage;
          delete e.validations;
        });

        return !!payload.nominations[0].nominationOverrides
          ? this._nominationsService.addNominationOverride(payload).pipe(
              map(
                nomination =>
                  new CreateNominationSuccess({
                    nominations: nomination.nominations,
                  })
              ),
              catchError(error => {
                /**
                 * if status !== 422 (the code we use for validations) we know server failed, update error reducer
                 * else - we know validations ran and our form has errors
                 */
                return error.status !== HttpCodes.VALIDATION
                  ? of(new CreateNominationFailure({ error }))
                  : of(new CreateNominationValidationFailure({ error: error.error }));
              })
            )
          : this._nominationsService.addNomination(payload).pipe(
              map(
                nomination =>
                  new CreateNominationSuccess({
                    nominations: nomination.nominations,
                  })
              ),
              catchError(error => {
                /**
                 * if status !== 422 (the code we use for validations) we know server failed, update error reducer
                 * else - we know validations ran and our form has errors
                 */
                return error.status !== HttpCodes.VALIDATION
                  ? of(new CreateNominationFailure({ error }))
                  : of(new CreateNominationValidationFailure({ error: error.error }));
              })
            );
      })
    )
  );

  FetchScheduledQuantities: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchScheduledQuantities>(ENominationsActions.FETCH_SCHEDULED_QUANTITIES),
      map((action: FetchScheduledQuantities) => action.payload),
      switchMap((payload: IScheduledQuantitiesPayload) => {
        return this._scheduledQuantitiesService
          .getScheduledQuantitySummary(
            new Date(new Date(get(payload, 'gasFlowDate')).toDateString()),
            payload.cycleCode,
            payload.serviceRequestorId,
            payload.excludeZeroReductionQty,
            payload.tspId
          )
          .pipe(
            map(scheduledQuantitiesSummaryCollection => {
              return new FetchScheduledQuantitiesSuccess({
                scheduledQuantities:
                  scheduledQuantitiesSummaryCollection.scheduledQuantitySummaries,
                totalScheduledQuantitiesCount: scheduledQuantitiesSummaryCollection.total,
              });
            }),
            catchError(error => of(new FetchScheduledQuantitiesFailure({ error: error })))
          );
      })
    )
  );

  ExportScheduledQuantities$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportScheduledQuantities>(ENominationsActions.EXPORT_SCHEDULED_QUANTITIES),
      map((action: ExportScheduledQuantities) => action.payload),
      switchMap((payload: IExportScheduledQuantitiesPayload) => {
        const { gasFlowDate } = payload;
        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });
          saveAs(blob, `ScheduledQuantity-${dateUtils.getDateAsDD_MM_YYYY(gasFlowDate)}.csv`);
        };

        return this._scheduledQuantitiesService
          .exportScheduledQuantity(
            new Date(new Date(get(payload, 'gasFlowDate')).toDateString()),
            payload.cycleCode,
            payload.tspId,
            payload.serviceRequestorId,
            payload.sortBy
          )
          .pipe(
            map(response => {
              saveData(response);
              return new ExportScheduledQuantitiesSuccess();
            }),
            catchError(error => of(new ExportScheduledQuantitiesFailure(error)))
          );
      })
    )
  );

  ExportOperatorScheduledQuantities$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportOperatorScheduledQuantities>(
        ENominationsActions.EXPORT_OPERATOR_SCHEDULED_QUANTITIES
      ),
      map((action: ExportOperatorScheduledQuantities) => action.payload),
      switchMap((payload: IExportOperatorScheduledQuantityPayload) => {
        const { beginDate } = payload;
        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });
          if (!payload.asEmail) {
            saveAs(blob, `OperatorScheduledQuantity-${beginDate}.csv`);
          }
        };

        if (payload.asEmail) {
          return this._scheduledQuantityForOperatorsService
            .exportSchedQtyForOperator(
              payload.tspId,
              new Date(get(payload, 'beginDate')),
              new Date(get(payload, 'endDate')),
              payload.cycleCode,
              payload.confirmingEntityId,
              payload.locationId,
              payload.reductionsOnly,
              payload.asEmail
            )
            .pipe(
              map(response => {
                this._toastService.success('You will get an email when export is finished.');
                return new ExportOperatorScheduledQuantitiesSuccess();
              }),
              catchError(error => of(new ExportOperatorScheduledQuantitiesFailure(error)))
            );
        } else {
          return this._scheduledQuantityForOperatorsService
            .exportSchedQtyForOperator(
              payload.tspId,
              new Date(get(payload, 'beginDate')),
              new Date(get(payload, 'endDate')),
              payload.cycleCode,
              payload.confirmingEntityId,
              payload.locationId,
              payload.reductionsOnly,
              payload.asEmail
            )
            .pipe(
              map(response => {
                saveData(response);
                return new ExportOperatorScheduledQuantitiesSuccess();
              }),
              catchError(error => of(new ExportOperatorScheduledQuantitiesFailure(error)))
            );
        }
      })
    )
  );

  FetchScheduledQuantity: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchScheduledQuantity>(ENominationsActions.FETCH_SCHEDULED_QUANTITY),
      map((action: FetchScheduledQuantity) => action.payload),
      concatMap(payload => {
        return this._scheduledQuantitiesService
          .getScheduledQuantity(
            new Date(new Date(get(payload, 'gasFlowDate')).toDateString()),
            payload.serviceRequestorId,
            payload.serviceRequestContractId,
            payload.tspId,
            payload.cycleCode,
            payload.excludeZeroReductionQty,
            payload.pageSize,
            payload.pageNumber,
            payload.sortBy
          )
          .pipe(
            map((scheduledQuantityCollection: ScheduledQuantityCollection) => {
              return new FetchScheduledQuantitySuccess({
                contractId: payload.serviceRequestContractId,
                scheduledQuantity: scheduledQuantityCollection.scheduledQuantity,
                totalScheduledQuantityCount: scheduledQuantityCollection.total,
              });
            }),
            catchError(error =>
              of(
                new FetchScheduledQuantityFailure({
                  error: error,
                  contractId: payload.serviceRequestContractId,
                })
              )
            )
          );
      })
    )
  );

  FetchOrderManagementDictionary: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchOrderManagementDictionary>(ENominationsActions.FETCH_ORDER_MANAGEMENT_DICTIONARY),
      switchMap(() => {
        return this._orderManagementDictionaryService.getDictionary().pipe(
          map(dictionary => new FetchOrderManagementDictionarySuccess({ dictionary: dictionary })),
          catchError(error => of(new FetchOrderManagementDictionaryFailure({ error: error })))
        );
      })
    )
  );

  FetchCurrentGasDay: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchCurrentGasDay>(ENominationsActions.FETCH_CURRENT_GAS_DAY),
      switchMap(() => {
        return this._gasDayService.getGasDay().pipe(
          map((day: GasDay) => new FetchCurrentGasDaySuccess({ gasDay: day })),
          catchError(error => of(new FetchCurrentGasDayFailure({ error: error })))
        );
      })
    )
  );

  FetchFuelRate: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchFuelRate>(ENominationsActions.FETCH_FUEL_RATE),
      map((action: FetchFuelRate) => action.payload),
      switchMap((payload: IFuelRatePayload) => {
        return this._invoicingService
          .getFuelRate(
            new Date(get(payload, 'gasFlowDate')).toDateString(),
            payload.contractId,
            payload.delLocId,
            payload.recLocId,
            payload.accountId,
            payload.detailRateScheduleId,
            payload.tspId,
            payload.transactionTypeCode
          )
          .pipe(
            map(
              (fuelRateCollection: FuelRateResponseCollection) =>
                new FetchFuelRateSuccess({ fuelRateCollection: fuelRateCollection })
            ),
            catchError(error => of(new FetchFuelRateFailure({ error: error })))
          );
      })
    )
  );

  FetchOperatorScheduledQuantities: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchOperatorScheduledQuantities>(
        ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITIES
      ),
      map((action: FetchOperatorScheduledQuantities) => action.payload),
      switchMap(payload => {
        return this._scheduledQuantityForOperatorsService
          .getOperatorScheduledQuantity(
            payload.tspId,
            payload.confirmingEntityId,
            payload.beginDate,
            payload.endDate,
            payload.cycleCode,
            payload.onlyShowReductions,
            payload.locationId
          )
          .pipe(
            map(
              operatorScheduledQuantities =>
                new FetchOperatorScheduledQuantitiesSuccess(operatorScheduledQuantities)
            ),
            catchError(error => of(new FetchOperatorScheduledQuantitiesFailure({ error: error })))
          );
      })
    )
  );

  /* Fetch individual scheduled quantity by passing in the location name and location number
   * Current required fields to pass in to retreive OperatorScheduledQuantity using getOperatorScheduledQuantity:
   * @param tspId
   * @param confirmingEntityId
   * @param gasFlowDate
   * @param cycleCode
   * @param locationId
   * @param onlyShowReductions
   * */
  FetchOperatorScheduledQuantity: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchOperatorScheduledQuantity>(ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITY),
      map((action: FetchOperatorScheduledQuantity) => action.payload),
      debounce(() => timer(DEBOUNCE_100)),
      switchMap(payload => {
        return this._scheduledQuantityForOperatorsService
          .getOperatorScheduledQuantity(
            payload.tspId,
            payload.confirmingEntityId,
            payload.beginDate,
            payload.endDate,
            payload.cycleCode,
            payload.onlyShowReductions,
            payload.locationId
          )
          .pipe(
            map(
              operatorScheduledQuantities =>
                new FetchOperatorScheduledQuantitySuccess(operatorScheduledQuantities)
            ),
            catchError(error => of(new FetchOperatorScheduledQuantityFailure({ error: error })))
          );
      })
    )
  );

  FetchTransactionTypes: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTransactionTypes>(ENominationsActions.FETCH_TRANSACTION_TYPES),
      map((action: FetchTransactionTypes) => action.payload),
      switchMap((payload: ITransactionTypesPayload) => {
        return this._transactionTypesService
          .getTransactionTypes(payload ? payload.tspId : null)
          .pipe(
            map(transactionTypes => new FetchTransactionTypesSuccess(transactionTypes)),
            catchError(error => of(new FetchOrderManagementDictionaryFailure({ error: error })))
          );
      })
    )
  );

  FetchReductionReasons: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchReductionReasons>(ENominationsActions.FETCH_REDUCTION_REASONS),
      switchMap(() => {
        return this._reductionReasonsService.getReductionReasons().pipe(
          map(reductionReasons => new FetchReductionReasonsSuccess(reductionReasons)),
          catchError(error => of(new FetchReductionReasonsFailure({ error: error })))
        );
      })
    )
  );

  FetchTspDetails: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchTspDetails>(ENominationsActions.FETCH_TSP_DETAILS),
      filterIfCached(this._store.pipe(select(selectTspDetails))),
      switchMap(() => {
        return this._tspDetailService.getTspDetails().pipe(
          map(
            (tspDetailCollection: TspDetailCollection) =>
              new FetchTspDetailsSuccess(tspDetailCollection)
          ),
          catchError(error => of(new FetchTspDetailsFailure({ error: error })))
        );
      })
    )
  );
}
