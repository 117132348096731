import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { setAppConfig } from 'config/app-config';
import { GmsAppConfig } from 'config/app-config.interfaces';
import { environment } from 'environments/environment';

fetch('/assets/app-config.json')
  .then(response => response.json())
  .then((config: GmsAppConfig) => {
    setAppConfig(config);

    // Set env to local storage for easy logging retrieval
    localStorage.setItem('environment', JSON.stringify(config.environment));
    if (config.environment !== 'prod') {
      // For now don't enable in prod
      window.dispatchEvent(new Event('TracingStart'));
    }

    if (!environment.production) {
      console.warn(
        `App is running under '${config.environment}' configuration. Endpoint configuration:`
      );
      console.table ? console.table(config.endpoints) : console.log(config.endpoints);
    }

    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
  .catch(err => console.error(err));
