import { RequestStatusCollection, ServiceRequest } from '@gms/servicerequest-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export enum EServiceRequestActions {
  GetServiceRequests = '[SERVICE_REQUESTS] get ServiceRequests',
  GetServiceRequestsError = '[SERVICE_REQUESTS] get ServiceRequests Error',
  GetServiceRequestsSuccess = '[SERVICE_REQUESTS] get ServiceRequests Success',
  ClearServiceRequests = '[SERVICE_REQUESTS] clear ServiceRequests',
  GetServiceRequestById = '[SERVICE_REQUESTS] get ServiceRequest by Id',
  SetSortOrder = '[SERVICE_REQUESTS] set Sort',
  RESET_CLIENT_SIDE_SERVICE_REQUEST = '[SERVICE_REQUESTS] reset client side service request',
  RESET_CLIENT_SIDE_EDITING_SERVICE_REQUEST = '[SERVICE_REQUESTS] reset client side editing service request',
  UPDATE_CLIENT_SIDE_SERVICE_REQUEST = '[SERVICE_REQUESTS] update client side service request',
  UpdateEditingServiceRequest = '[SERVICE_REQUESTS] update editingServiceRequest',
  SaveServiceRequest = '[SERVICE_REQUESTS] save ServiceRequest',
  SaveServiceRequestSuccess = '[SERVICE_REQUESTS] save ServiceRequests Success',
  SaveServiceRequestError = '[SERVICE_REQUESTS] save ServiceRequests Error',
  UpdateServiceRequest = '[SERVICE_REQUESTS] update ServiceRequest',
  UpdateServiceRequestSuccess = '[SERVICE_REQUESTS] update save ServiceRequests Success',
  UpdateServiceRequestValidationError = '[SERVICE_REQUESTS] update save ServiceRequests Validation Error',
  UpdateServiceRequestServerError = '[SERVICE_REQUESTS] update save ServiceRequests Server Error',
  DeleteServiceRequest = '[SERVICE_REQUESTS] delete ServiceRequests',
  DeleteServiceRequestSuccess = '[SERVICE_REQUESTS] delete save ServiceRequest Success',
  DeleteServiceRequestError = '[SERVICE_REQUESTS] delete save ServiceRequest Error',
  AcknowledgeNetworkResult = '[SERVICE_REQUESTS] acknowledge network result',
  GetRequestStatuses = '[SERVICE_REQUESTS] Get request statuses',
  GetRequestStatusesSuccess = '[SERVICE_REQUESTS] Get request statuses success',
  GetRequestStatusesFailure = '[SERVICE_REQUESTS] Get request statuses failure',
}

export interface IFetchServiceRequestsPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  entityId?: number;
  tspId?: number;
  requestId?: number;
  requestStatus?: string;
  requestStatusIds?: number[];
  lastUpdateBegin?: Date;
  lastUpdateEnd?: Date;
  rateScheduleId?: number;
  assignedToId?: number;
  primaryTermEndDate?: Date;
}

export class GetServiceRequests implements Action {
  public readonly type = EServiceRequestActions.GetServiceRequests;

  constructor(public payload: IFetchServiceRequestsPayload) {}
}

export class GetServiceRequestsSuccess implements Action {
  public readonly type = EServiceRequestActions.GetServiceRequestsSuccess;

  constructor(
    public payload: {
      serviceRequests: ServiceRequest[];
      totalServiceRequestsCount: number;
    }
  ) {}
}

export class ClearServiceRequests implements Action {
  public readonly type = EServiceRequestActions.ClearServiceRequests;
}

export class GetServiceRequestById implements Action {
  public readonly type = EServiceRequestActions.GetServiceRequestById;

  constructor(public payload: number) {}
}

export class GetServiceRequestsError implements Action {
  public readonly type = EServiceRequestActions.GetServiceRequestsError;

  constructor(public error: ServiceRequest) {}
}

export class SetServiceRequestSort implements Action {
  public readonly type = EServiceRequestActions.SetSortOrder;

  constructor(public payload: SortDescriptor[]) {}
}

export class ResetClientSideEditingServiceRequest implements Action {
  public readonly type = EServiceRequestActions.RESET_CLIENT_SIDE_EDITING_SERVICE_REQUEST;
}

export class ResetClientSideServiceRequest implements Action {
  public readonly type = EServiceRequestActions.RESET_CLIENT_SIDE_SERVICE_REQUEST;

  constructor(public resetTspId: boolean = false) {}
}

export class UpdateClientSideServiceRequest implements Action {
  public readonly type = EServiceRequestActions.UPDATE_CLIENT_SIDE_SERVICE_REQUEST;

  constructor(public payload: ServiceRequest) {}
}

export class UpdateEditingServiceRequest implements Action {
  public readonly type = EServiceRequestActions.UpdateEditingServiceRequest;

  constructor(public payload: ServiceRequest) {}
}

export class SaveServiceRequest implements Action {
  public readonly type = EServiceRequestActions.SaveServiceRequest;

  constructor(public payload: ServiceRequest, public prefer?: string) {}
}
export class SaveServiceRequestSuccess implements Action {
  public readonly type = EServiceRequestActions.SaveServiceRequestSuccess;

  constructor(public payload: ServiceRequest) {}
}

export class SaveServiceRequestError implements Action {
  public readonly type = EServiceRequestActions.SaveServiceRequestError;

  constructor(public error: ServiceRequest) {}
}

export class UpdateServiceRequest implements Action {
  public readonly type = EServiceRequestActions.UpdateServiceRequest;

  constructor(public payload: ServiceRequest, public id: number, public prefer?: string) {}
}

export class UpdateServiceRequestSuccess implements Action {
  public readonly type = EServiceRequestActions.UpdateServiceRequestSuccess;

  constructor(public payload: ServiceRequest) {}
}

export class UpdateServiceRequestValidationError implements Action {
  public readonly type = EServiceRequestActions.UpdateServiceRequestValidationError;

  constructor(public error: ServiceRequest) {}
}

export class UpdateServiceRequestError implements Action {
  public readonly type = EServiceRequestActions.UpdateServiceRequestServerError;

  constructor(public error: ServiceRequest) {}
}

export class DeleteServiceRequest implements Action {
  public readonly type = EServiceRequestActions.DeleteServiceRequest;

  constructor(public payload: ServiceRequest) {}
}

export class DeleteServiceRequestSuccess implements Action {
  public readonly type = EServiceRequestActions.DeleteServiceRequestSuccess;

  constructor(public payload: ServiceRequest) {}
}

export class DeleteServiceRequestError implements Action {
  public readonly type = EServiceRequestActions.DeleteServiceRequestError;

  constructor(public error: ServiceRequest) {}
}

export class AcknowledgeNetworkResult implements Action {
  public readonly type = EServiceRequestActions.AcknowledgeNetworkResult;
}

export class GetRequestStatuses implements Action {
  public readonly type = EServiceRequestActions.GetRequestStatuses;

  constructor(public payload: { includeForCredit?: boolean }) {}
}

export class GetRequestStatusesSuccess implements Action {
  public readonly type = EServiceRequestActions.GetRequestStatusesSuccess;

  constructor(public payload: RequestStatusCollection) {}
}

export class GetRequestStatusesFailure implements Action {
  public readonly type = EServiceRequestActions.GetRequestStatusesFailure;

  constructor(public error: Error) {}
}

export type ServiceRequestActions =
  | GetServiceRequests
  | GetServiceRequestById
  | GetServiceRequestsSuccess
  | ClearServiceRequests
  | GetServiceRequestsError
  | SetServiceRequestSort
  | ResetClientSideEditingServiceRequest
  | ResetClientSideServiceRequest
  | UpdateClientSideServiceRequest
  | UpdateEditingServiceRequest
  | SaveServiceRequest
  | SaveServiceRequestSuccess
  | SaveServiceRequestError
  | UpdateServiceRequest
  | UpdateServiceRequestSuccess
  | UpdateServiceRequestValidationError
  | UpdateServiceRequestError
  | DeleteServiceRequest
  | DeleteServiceRequestSuccess
  | DeleteServiceRequestError
  | AcknowledgeNetworkResult
  | GetRequestStatuses
  | GetRequestStatusesSuccess
  | GetRequestStatusesFailure;
