import { Injectable } from '@angular/core';
import { MergerAssignmentsService } from '@gms/entity-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { mapSortDescriptorsToQueryParam } from 'shared/utils/sort.utils';
import {
  AddMergerAssignment,
  AddMergerAssignmentFailure,
  AddMergerAssignmentSuccess,
  EMergerAssignmentActions,
  FetchMergerAssignmentById,
  FetchMergerAssignmentByIdFailure,
  FetchMergerAssignmentByIdSuccess,
  SearchMergerAssignments,
  SearchMergerAssignmentsFailure,
  SearchMergerAssignmentsSuccess,
} from './merger-assignment.actions';

@Injectable()
export class MergerAssignmentEffects {
  constructor(
    private _actions$: Actions,
    private _mergerAssignmentService: MergerAssignmentsService
  ) {}

  SearchMergerAssignments$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SearchMergerAssignments>(EMergerAssignmentActions.SEARCH_MERGER_ASSIGNMENTS),
      switchMap(action => {
        return this._mergerAssignmentService
          .getMergerAssignments(
            action.payload.pageSize,
            action.payload.pageNumber,
            mapSortDescriptorsToQueryParam(action.payload.sortBy),
            action.payload.ids
          )
          .pipe(
            map(
              mergerAssignmentCollection =>
                new SearchMergerAssignmentsSuccess(mergerAssignmentCollection)
            ),
            catchError(error => of(new SearchMergerAssignmentsFailure(error)))
          );
      })
    )
  );

  FetchMergerAssignmentById$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMergerAssignmentById>(EMergerAssignmentActions.FETCH_MERGER_ASSIGNMENT_BY_ID),
      switchMap(action => {
        return this._mergerAssignmentService
          .getMergerAssignmentById(action.mergerAssignmentId)
          .pipe(
            map(mergerAssignment => new FetchMergerAssignmentByIdSuccess(mergerAssignment)),
            catchError(error => of(new FetchMergerAssignmentByIdFailure(error)))
          );
      })
    )
  );

  AddMergerAssignment$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddMergerAssignment>(EMergerAssignmentActions.ADD_MERGER_ASSIGNMENT),
      switchMap(action => {
        return this._mergerAssignmentService.postMergerAssignment(action.payload).pipe(
          map(mergerAssignment => new AddMergerAssignmentSuccess(mergerAssignment)),
          catchError(error => of(new AddMergerAssignmentFailure(error)))
        );
      })
    )
  );
}
