import { TspHolidayCollection } from '@gms/rateschedulev2-api';
import { ServiceProvider } from '@gms/tsp-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { createSuccessState, ICachedDataState, IDataState } from 'app/store/app/app.models';

export interface ITspState {
  serviceProvider: ServiceProvider;
  serviceProviders: ICachedDataState<ServiceProvider[]>;
  sort: SortDescriptor[];
  loading: boolean;
  error: boolean;
  networkResultStatus: string;
  tspHolidays: IDataState<TspHolidayCollection>;
  tspHolidayCollections: IDataState<Array<TspHolidayCollection>>;
}
export const initialTspsState: ITspState = {
  serviceProvider: {
    rateScheduleConfigs: [],
  },
  serviceProviders: createSuccessState([]),
  sort: [
    {
      field: 'lastUpdatedDate',
      dir: 'asc',
    },
  ],
  loading: false,
  error: null,
  networkResultStatus: null,
  tspHolidays: {
    data: { tspHolidays: [] },
    requestStatus: { loading: false, httpError: null },
  },
  tspHolidayCollections: {
    data: [],
    requestStatus: { loading: false, httpError: null },
  },
};
