<div class="table-spacing">
  <app-form-datepicker
    [id]="data?.id"
    [control]="data?.control"
    [min]="data?.minDate"
    [max]="data?.maxDate"
    [disableBeforeGivenDate]="data?.disableBeforeGivenDate"
    [forceAOE]="data?.forceAOE"
    [forceAOEAggressively]="data?.forceAOEAggresively"
    (datepickerFocused)="datePickerFocused()"
    (valueChanged)="valueChanged($event)"
    (datepickerBlurred)="datePickerBlurred()"
    size="Small"
    isInATable="true"
  >
  </app-form-datepicker>
</div>
