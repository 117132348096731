import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DynamicComponentDefinition } from 'shared/components/dynamic-component-wrapper/dynamic-component.interface';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { DatepickerOptions } from './datepicker-cell.interface';

@Component({
  selector: 'gms-dynamic-datepicker-cell',
  templateUrl: './datepicker-cell.component.html',
  styleUrls: ['./datepicker-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDatepickerComponent extends HasSubscriptions implements OnInit, OnDestroy {
  constructor(private cd: ChangeDetectorRef) {
    super();
  }
  @Input() data: DatepickerOptions;

  static componentDefinition(data: DatepickerOptions): DynamicComponentDefinition {
    return {
      dynamicComponentType: DynamicDatepickerComponent,
      data: data,
    };
  }

  ngOnInit(): void {
    this.addSubscription(
      this.data.control.statusChanges.subscribe(() => {
        this.cd.detectChanges();
      })
    );
  }

  public datePickerFocused() {
    if (this.data.datepickerFocused) {
      this.data.datepickerFocused();
    }
  }

  public datePickerBlurred() {
    if (this.data.datepickerBlurred) {
      this.data.datepickerBlurred();
    }
  }

  public valueChanged(event) {
    if (this.data.valueChanged) {
      this.data.valueChanged(event);
    }
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}
