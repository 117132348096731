import { createFeatureSelector, createSelector, MemoizedSelector, State } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { INominationsState } from './nominations.state';

export const selectNominationsState = (state: IAppState) => state.nominationsState;
export const selectNominationsUploadValidation = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.nominationUploadValidation
);
export const selectNominations = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.nominations
);
export const selectScheduledQuantities = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.scheduledQuantities
);
export const selectScheduledQuantity = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.scheduledQuantity
);

export const selectNomination = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.nomination
);
export const selectLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.loading
);

export const selectLoadingCycles = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.loadingCycles
);

export const selectLoadingNominations = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.loadingNominations
);

export const selectScheduledQuantitiesLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.scheduledQuantitiesLoading
);

export const selectNominationWarnings = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.nominationWarnings
);

export const selectExportScheduledQuantitiesLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.exportScheduledQuantitiesLoading
);

export const selectExportOperatorScheduledQuantitiesLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.exportOperatorScheduledQuantitiesLoading
);

export const selectExportOperatorScheduledQuantitiesError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.exportOperatorScheduledQuantitiesError
);

export const selectScheduledQuantityLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.scheduledQuantityLoading
);
export const selectCreateNominationLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.createNominationLoading
);
export const selectError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.error
);
export const selectScheduledQuantitiesError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.scheduledQuantitiesError
);
export const selectExportScheduledQuantitiesError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.exportScheduledQuantitiesError
);
export const selectScheduledQuantityError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.scheduledQuantityError
);
export const selectCreateNominationValidationError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.createNominationValidationError
);
export const selectCreateNominationError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.createNominationError
);
export const selectCreateNominationsSuccess = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.createNominationsSuccess
);

// VALIDATE NOMINATIONS BEFORE SUBMIT
export const selectValidateNominationsSuccess = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.validateNominationsSuccess
);
export const selectValidateNominationsError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.validateNominationsError
);
export const selectValidateNominationsLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.validateNominationsLoading
);

export const selectSortDescriptors = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.sortDescriptors
);
export const selectPagination = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.pagination
);
export const selectTotalNominationsCount = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.totalNominationsCount
);
export const selectAgencyAccessBySvcReq = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.agencyAccessBySvcReq
);
export const selectScheduledQuantitiesCount = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.totalScheduledQuantities
);
export const selectScheduledQuantityCount = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.totalScheduledQuantity
);

export const selectOrderManagementDictionary = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.dictionary
);

export const selectFuelRate = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.fuelRateCollection
);

export const selectNominationCycles = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.nominationCycles
);

export const selectCreateNominationCyclesOverrideLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.createCycleOverrideLoading
);

export const selectCreateCycleOverrideSuccess = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.createCycleOverrideSuccess
);

export const selectCurrentGasDay = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.gasDay
);

export const selectCurrentGasDayError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.gasDayError
);

export const selectCurrentGasDayOrError = createSelector(
  selectNominationsState,
  (state: INominationsState) => ({ gasDay: state.gasDay, error: state.gasDayError })
);

export const selectOperatorScheduledQuantities = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorScheduledQuantities
);

export const selectoperatorScheduledQuantitiesLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorScheduledQuantitiesLoading
);

export const selectOperatorScheduledQuantitiesError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorScheduledQuantitiesError
);

export const selectOperatorTotalScheduledQuantities = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorTotalScheduledQuantities
);

export const selectOperatorScheduledQuantity = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorScheduledQuantity
);

export const selectOperatorScheduledQuantityLoading = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorScheduledQuantityLoading
);

export const selectOperatorScheduledQuantityError = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorScheduledQuantityError
);

export const selectOperatorScheduledQuantityFilter = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.operatorScheduledQuantityFilterValues
);

export const selectTransactionTypeCollection = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.transactionTypes.data
);

export const selectTransactionTypeCollectionStatus = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.transactionTypes.requestStatus
);

export const selectNominationSummary = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.nominationSummary
);

export const selectReductionReasons = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.reductionReasons
);

export const selectTspDetails = createSelector(
  selectNominationsState,
  (state: INominationsState) => state.tspDetails
);
