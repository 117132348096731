import { UntypedFormControl } from '@angular/forms';
import { DynamicCheckboxCell } from 'shared/components/table/dynamic-cell-components/checkbox-cell/checkbox-cell.interface';
import { DynamicClickableIconCell } from 'shared/components/table/dynamic-cell-components/clickable-icon-cell/clickable-icon-cell.interface';
import { DynamicDatepickerCell } from 'shared/components/table/dynamic-cell-components/datepicker-cell/datepicker-cell.interface';
import { DynamicDropdownCell } from 'shared/components/table/dynamic-cell-components/dropdown-cell/dropdown-cell.interface';
import { DynamicUpdatableCell } from 'shared/components/table/dynamic-cell-components/dynamically-updatable-cell/updatable-cell.interface';
import { DynamicErrorsAndWarningsCell } from 'shared/components/table/dynamic-cell-components/errors-and-warnings-cell/errors-and-warnings-cell.interface';
import { DynamicErrorsControlCell } from 'shared/components/table/dynamic-cell-components/errors-control-cell/errors-control-cell.interface';
import { DynamicFlagCell } from 'shared/components/table/dynamic-cell-components/flag-cell/flag-cell.interface';
import { DynamicInputCell } from 'shared/components/table/dynamic-cell-components/input-cell/input-cell.interface';
import { DynamicPipelineApplicableIconCell } from 'shared/components/table/dynamic-cell-components/path-applicable-icon-cell/path-applicable-icon-cell.interface';
import { DynamicRadioButtonCell } from 'shared/components/table/dynamic-cell-components/radio-button-cell/radio-button-cell.interface';
import { DynamicRowOptionsMenuCell } from 'shared/components/table/dynamic-cell-components/row-options-menu/row-options-menu.interface';
import { DynamicTooltipCell } from 'shared/components/table/dynamic-cell-components/tooltip/tooltip.interface';
import { DynamicInputButtonCell } from './button-cell/button-cell.interface';
import { DynamicDateTimepickerCell_DEPRECATED } from './datetimepicker-cell-deprecated/datetimepicker-cell.interface';
import { DynamicIconPopoverCell } from './icon-popover-cell/icon-popover-cell.interface';
import { DynamicMultiselectCell } from './multiselect-cell/multiselect-cell.interface';
import { DynamicNonClickableIconCell } from './non-clickable-icon-cell/non-clickable-icon-cell.interface';
import { DynamicStackedClickableIconCell } from './stacked-clickable-icon-cell/stacked-clickable-icon-cell.interface';
import { DynamicStackedDataCell } from './stacked-data-cell/stacked-data-cell.interface';
import { DynamicStackedInputCell } from './stacked-input-cell/stacked-input-cell.interface';
import { DynamicStatusTagCell } from './status-tag-cell/status-tag-cell.interface';
import { DynamicSwitchCell } from './switch-cell/switch-cell.interface';
import { DynamicTimepickerCell } from './timepicker-cell/timepicker-cell.interface';

/**
 * Please add the types for the dynamic cells to these type definitions.
 */

export type DynamicCellType =
  | DynamicCheckboxCell
  | DynamicClickableIconCell
  | DynamicNonClickableIconCell
  | DynamicDatepickerCell
  | DynamicDropdownCell
  | DynamicFlagCell
  | DynamicInputCell
  | DynamicPipelineApplicableIconCell
  | DynamicRadioButtonCell
  | DynamicRowOptionsMenuCell
  | DynamicTooltipCell
  | DynamicErrorsAndWarningsCell
  | DynamicErrorsControlCell
  | DynamicIconPopoverCell
  | DynamicStatusTagCell
  | DynamicSwitchCell
  | DynamicInputButtonCell
  | DynamicTimepickerCell
  | DynamicDateTimepickerCell_DEPRECATED
  | DynamicUpdatableCell
  | DynamicMultiselectCell
  | DynamicStackedDataCell
  | DynamicStackedInputCell
  | DynamicStackedClickableIconCell;

//get the name of the control by using the parent :)
export const getControlName = (control: UntypedFormControl): string => {
  try {
    return Object.keys(control.parent.controls).filter(
      parentControl => control === control.parent.controls[parentControl]
    )[0];
  } catch (e) {
    return 'no parent form control name';
  }
};
