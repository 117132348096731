import { Injectable } from '@angular/core';
import {
  Amendment,
  AmendmentsService,
  DealSheet,
  DealSheetService,
  ServiceRequest,
  ServiceRequestCollection,
  ServiceRequestService,
} from '@gms/servicerequest-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import {
  CreateAmendment,
  CreateAmendmentFailure,
  CreateAmendmentSuccess,
  CreateDealSheetAmendment,
  CreateDealSheetAmendmentFailure,
  CreateDealSheetAmendmentSuccess,
  EAmendmentActions,
  FetchAmendmentById,
  FetchAmendmentByIdFailure,
  FetchAmendmentByIdSuccess,
  FetchAmendments,
  FetchAmendmentsFailure,
  FetchAmendmentsSuccess,
  FetchDealSheetAmendmentById,
  FetchDealSheetAmendmentByIdFailure,
  FetchDealSheetAmendmentByIdSuccess,
  UpdateAmendment,
  UpdateAmendmentFailure,
  UpdateAmendmentSuccess,
  UpdateDealSheetAmendment,
  UpdateDealSheetAmendmentFailure,
  UpdateDealSheetAmendmentSuccess,
} from './amendments.actions';

@Injectable({
  providedIn: 'root',
})
export class AmendmentsEffects {
  constructor(
    private actions$: Actions,
    private serviceRequestService: ServiceRequestService,
    private amendmentService: AmendmentsService,
    private dealSheetService: DealSheetService
  ) {}

  getServiceRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAmendments>(EAmendmentActions.FETCH_AMENDMENTS),
      map((action: FetchAmendments) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        payload.sortDescriptors.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
        return this.serviceRequestService
          .getServiceRequest(
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.entityId,
            payload.tspId,
            payload.requestId,
            payload.requestStatus,
            payload.requestStatusIds,
            payload.rateScheduleId,
            payload.lastUpdateBegin,
            !isNullOrUndefined(payload.primaryTermEndDate)
              ? dateUtils.getDateAsYYYY_MM_DD(payload.primaryTermEndDate)
              : null,
            payload.lastUpdateEnd,
            null,
            null,
            payload.assignedToId
          )
          .pipe(
            switchMap((serviceRequest: ServiceRequestCollection) =>
              of(
                new FetchAmendmentsSuccess({
                  amendments: serviceRequest.serviceRequests,
                  totalAmendmentsCount: serviceRequest.total,
                })
              )
            ),
            catchError(error => of(new FetchAmendmentsFailure(error)))
          );
      })
    )
  );

  getAmendmentByServiceRequestId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAmendmentById>(EAmendmentActions.FETCH_AMENDMENT_BY_ID),
      switchMap(action => {
        const requestId = action.payload;
        return this.amendmentService.getAmendment(requestId).pipe(
          switchMap((amendment: Amendment) =>
            of(
              new FetchAmendmentByIdSuccess({
                amendment: amendment,
              })
            )
          ),
          catchError(error => of(new FetchAmendmentByIdFailure(error)))
        );
      })
    )
  );

  saveAmendment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAmendment>(EAmendmentActions.CREATE_AMENDMENT),
      switchMap(body => {
        const contractId = body.payload;
        return this.amendmentService.addAmendment(contractId).pipe(
          mergeMap((amendment: Amendment) => {
            return of(new CreateAmendmentSuccess({ amendment: amendment }));
          }),
          catchError(error => {
            return of(new CreateAmendmentFailure(error.error));
          })
        );
      })
    )
  );

  updateAmendment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAmendment>(EAmendmentActions.UPDATE_AMENDMENT),
      switchMap(body => {
        const bodyPayload = body.payload;
        const prefer = <'Lenient' | 'Strict'>body.prefer;
        return this.amendmentService.updateAmendment(bodyPayload, body.id, prefer).pipe(
          mergeMap((amendment: Amendment) => {
            return of(new UpdateAmendmentSuccess({ amendment: amendment }));
          }),
          catchError(error => {
            return of(new UpdateAmendmentFailure(error.error));
          })
        );
      })
    )
  );

  saveDealSheetAmendment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateDealSheetAmendment>(EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT),
      switchMap(body => {
        const bodyPayload = body.payload;
        const prefer = <'Lenient' | 'Strict'>body.prefer;
        return this.dealSheetService.amendDealSheet(bodyPayload, prefer).pipe(
          mergeMap((dealSheet: DealSheet) => {
            return of(new CreateDealSheetAmendmentSuccess({ amendment: dealSheet }));
          }),
          catchError(error => {
            return of(new CreateDealSheetAmendmentFailure(error.error));
          })
        );
      })
    )
  );

  updateDealSheetAmendment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateDealSheetAmendment>(EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT),
      switchMap(body => {
        const bodyPayload = body.payload;
        const prefer = <'Lenient' | 'Strict'>body.prefer;
        return this.dealSheetService.updateDealSheet(bodyPayload, body.id, prefer).pipe(
          mergeMap((dealSheet: DealSheet) => {
            return of(new UpdateDealSheetAmendmentSuccess({ amendment: dealSheet }));
          }),
          catchError(error => {
            return of(new UpdateDealSheetAmendmentFailure(error.error));
          })
        );
      })
    )
  );

  getDealSheetAmendmentById$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDealSheetAmendmentById>(EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID),
      switchMap(action => {
        const requestId = action.payload;
        return this.dealSheetService.getDealSheetById(requestId).pipe(
          switchMap((dealSheet: DealSheet) =>
            of(
              new FetchDealSheetAmendmentByIdSuccess({
                dealSheetAmendment: dealSheet,
              })
            )
          ),
          catchError(error => of(new FetchDealSheetAmendmentByIdFailure(error)))
        );
      })
    )
  );
}
