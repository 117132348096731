import { Injectable } from '@angular/core';
import {
  AccountingGlAccountsDictionaryService,
  AccountingJournalEntryRulesService,
  GlAccountCollection,
  JournalEntryRuleType,
} from '@gms/accounting-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  AddJournalEntryRule,
  AddJournalEntryRuleError,
  AddJournalEntryRuleSuccess,
  EJournalEntryActions,
  EJournalEntryRuleCollectionActions,
  GetGeneralLedgerAccounts,
  GetGeneralLedgerAccountsFailure,
  GetGeneralLedgerAccountsSuccess,
  GetJournalEntryRule,
  GetJournalEntryRuleCollection,
  GetJournalEntryRuleCollectionFailure,
  GetJournalEntryRuleCollectionSuccess,
  GetJournalEntryRuleFailure,
  GetJournalEntryRuleSuccess,
  PutJournalEntryRule,
  PutJournalEntryRuleFailure,
  PutJournalEntryRuleSuccess,
} from './journal-entry-rule.actions';

@Injectable({ providedIn: 'root' })
export class JournalEntryRuleEffects {
  constructor(
    private _actions$: Actions,
    private _journalEntryRuleService: AccountingJournalEntryRulesService,
    private _glAccountService: AccountingGlAccountsDictionaryService
  ) {}

  getJournalEntryRuleCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJournalEntryRuleCollection>(
        EJournalEntryRuleCollectionActions.GetJournalEntryRuleCollection
      ),
      map((action: GetJournalEntryRuleCollection) => action),
      switchMap(action => {
        const {
          rateSchedule,
          chargeType,
          rateType,
          intercompany,
          effectiveDate,
          expireDate,
        } = action.payload.filterForm;
        const journalEntryType = action.payload.filterForm.journalEntryType as JournalEntryRuleType;
        const { tspId, pageSize, pageNumber, sortDescriptors, viewBy } = action.payload;
        const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;
        return this._journalEntryRuleService
          .getJournalEntryRules(
            tspId,
            rateSchedule,
            chargeType,
            effectiveDate,
            expireDate,
            intercompany,
            journalEntryType,
            pageSize,
            rateType,
            pageNumber,
            sortBy,
            viewBy
          )
          .pipe(
            map(response => new GetJournalEntryRuleCollectionSuccess(response)),
            catchError(error => of(new GetJournalEntryRuleCollectionFailure(error)))
          );
      })
    )
  );

  getJournalEntryRule$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJournalEntryRule>(EJournalEntryRuleCollectionActions.GetJournalEntryRule),
      map((action: GetJournalEntryRule) => action),
      switchMap(action =>
        this._journalEntryRuleService.getJournalEntryRuleById(action.payload).pipe(
          map(response => new GetJournalEntryRuleSuccess(response)),
          catchError(error => of(new GetJournalEntryRuleFailure(error)))
        )
      )
    )
  );

  addJournalEntryRule$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddJournalEntryRule>(EJournalEntryActions.ADD_JOURNAL_ENTRY),
      map((action: AddJournalEntryRule) => action),
      switchMap(action =>
        this._journalEntryRuleService.createJournalEntryRule(action.payload).pipe(
          map(journalEntryRule => new AddJournalEntryRuleSuccess(journalEntryRule)),
          catchError(error => of(new AddJournalEntryRuleError(error)))
        )
      )
    )
  );

  putJournalEntryRule$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<PutJournalEntryRule>(EJournalEntryRuleCollectionActions.PutJournalEntryRule),
      map((action: PutJournalEntryRule) => action),
      switchMap(action =>
        this._journalEntryRuleService
          .updateJournalEntryRule(action.payload, action.payload.id)
          .pipe(
            map(response => new PutJournalEntryRuleSuccess(response)),
            catchError(error => of(new PutJournalEntryRuleFailure(error)))
          )
      )
    )
  );

  getGeneralLedgerAccounts: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetGeneralLedgerAccounts>(EJournalEntryRuleCollectionActions.GetGeneralLedgerAccounts),
      map((action: GetGeneralLedgerAccounts) => action),
      switchMap(action =>
        this._glAccountService.getDictionary(null).pipe(
          map(
            (response: GlAccountCollection) =>
              new GetGeneralLedgerAccountsSuccess(response.glAccounts)
          ),
          catchError(error => of(new GetGeneralLedgerAccountsFailure(error)))
        )
      )
    )
  );
}
