import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  EControlErrorType,
  IControlError,
} from 'shared/components/form/form-inline-error-message/control-error';
import { LABELS } from 'shared/consts/labels.const';
import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from './../utils/type.utils';

/* TODO: Reimplement
  static requiredTrue(control: AbstractControl): ValidationErrors | null
  static email(control: AbstractControl): ValidationErrors | null
  static minLength(minLength: number): ValidatorFn
  static maxLength(maxLength: number): ValidatorFn
  static pattern(pattern: string | RegExp): ValidatorFn
  static nullValidator(control: AbstractControl): ValidationErrors | null
*/
/**
 * Usage, add a validator to the CustomValidators object.
 * Validators should follow the format.
 *
 *  static FUNCTION_NAME(ARGS, controlError: IControlError): ValidatorFn {
 *  return (control: AbstractControl): ValidationErrors | null => {
 *    return OPERATION ? null : { controlError };
 *   }
 *  }
 */
export const DEFAULT_ERROR = { type: EControlErrorType.ERROR, message: LABELS.REQUIRED_FIELD };

// objectValue is needed when the value is non primitive and is in form of {..., value: {}}
const isMissingValue = (value, objectValue?) => {
  if (value === null || value === undefined || value === '') return true;
  if (
    objectValue &&
    typeof value === 'object' &&
    (value.value === null || value.value === undefined || value.value === '')
  )
    return true;
  return false;
};
const isEmptyValue = value => isMissingValue(value) || value.trim().length === 0;
const isInteger = value => parseFloat(value) === parseInt(value, 10) && !isNaN(value);

/**
 * ByReference methods to be used when need to add dynamic validators
 * based on formControls (i.e. one formControl date cannot be prior to another formControl date)
 *
 *  Example Usage: CustomValidators.ByReference.minimumDate
 */

export class ByReference {
  public static maxLessThan(
    maxToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isMissingValue(control.value) || isNaN(control.value)) {
        return null;
      }

      if (isMissingValue(maxToCompareOnControl) || isNaN(maxToCompareOnControl.value)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message =
        controlError.message || `Must be less than ${maxToCompareOnControl.value}`;

      return control.value >= maxToCompareOnControl.value
        ? {
            controlError,
          }
        : null;
    };
  }

  public static minGreaterThan(
    minToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isMissingValue(control.value) || isNaN(control.value)) {
        return null;
      }

      if (isMissingValue(minToCompareOnControl) || isNaN(minToCompareOnControl.value)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message =
        controlError.message || `Must be greater than ${minToCompareOnControl.value}`;

      return control.value <= minToCompareOnControl.value
        ? {
            controlError,
          }
        : null;
    };
  }

  public minimumDate(
    dateToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dateToCompareOnControl || !dateToCompareOnControl.value) {
        return null;
      }
      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));
      if (isNaN(controlDate.getTime())) {
        return null;
      }
      const validationDate = dateUtils.getDateWithMM_DD_YYYY(
        new Date(dateToCompareOnControl.value)
      );
      return controlDate >= validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  public minimumDateTime(
    dateToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dateToCompareOnControl || !dateToCompareOnControl.value) {
        return null;
      }
      const controlDate = new Date(control.value);
      if (isNaN(controlDate.getTime())) {
        return null;
      }
      const validationDate = new Date(dateToCompareOnControl.value);
      return controlDate >= validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  public strictlyLessThanDateTime(
    dateToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dateToCompareOnControl || !dateToCompareOnControl.value) {
        return null;
      }
      const controlDate = new Date(control.value);
      if (isNaN(controlDate.getTime())) {
        return null;
      }
      const validationDate = new Date(dateToCompareOnControl.value);
      return controlDate > validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  public maximumDate(
    dateToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dateToCompareOnControl || !dateToCompareOnControl.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = dateUtils.getDateWithMM_DD_YYYY(
        new Date(dateToCompareOnControl.value)
      );

      return controlDate <= validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  public maximumDateTime(
    dateToCompareOnControl: AbstractControl,
    controlError: IControlError,
    datesCannotBeEqual?: boolean
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dateToCompareOnControl || !dateToCompareOnControl.value) {
        return null;
      }

      const controlDate = new Date(control.value);

      if (isNaN(controlDate.getTime())) {
        return null;
      }
      const validationDate = new Date(dateToCompareOnControl.value);
      if (datesCannotBeEqual) {
        return controlDate < validationDate
          ? null
          : {
              controlError,
            };
      } else {
        return controlDate <= validationDate
          ? null
          : {
              controlError,
            };
      }
    };
  }

  public strictlyGreaterThanDateTime(
    dateToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dateToCompareOnControl || !dateToCompareOnControl.value) {
        return null;
      }

      const controlDate = new Date(control.value);

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = new Date(dateToCompareOnControl.value);

      return controlDate < validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  public uniqueValueInSet(
    controlsToCompareAgainst: Array<AbstractControl>,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let error = null;

      controlsToCompareAgainst.forEach(controlToCompareAgainst => {
        if (
          controlToCompareAgainst !== control &&
          control !== null &&
          controlToCompareAgainst !== null
        ) {
          const value1 = controlToCompareAgainst.value;
          const value2 = control.value;

          if (this.checkIfValuesAreEqual(value1, value2)) {
            controlError.message = controlError.message || `Must be unique`;
            error = { controlError };
          }
          return;
        }
      });
      return error;
    };
  }

  public uniqueValueAgainstField(
    controlToCompareAgainst: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Do nothing if the same field was passed in
      if (controlToCompareAgainst === control) {
        return null;
      }

      const value1 = controlToCompareAgainst.value;
      const value2 = control.value;

      if (this.checkIfValuesAreEqual(value1, value2)) {
        controlError.message = controlError.message || `Must be unique`;
        return { controlError };
      }

      return null;
    };
  }

  public checkIfValuesAreEqual(value1, value2) {
    if (value1 === null || value1 === '' || value2 === null || value2 === '') {
      return false;
    }

    // Basic comparison
    if (value1 === value2) {
      return true;
    }
    // Object comparison
    if (JSON.stringify(value1) === JSON.stringify(value2)) {
      return true;
    }
  }

  public max(maxToCompareOnControl: AbstractControl, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isMissingValue(control.value) || isNaN(control.value)) {
        return null;
      }

      if (isMissingValue(maxToCompareOnControl) || isNaN(maxToCompareOnControl.value)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message =
        controlError.message || `Must be less than ${maxToCompareOnControl.value + 1}`;

      return control.value <= maxToCompareOnControl.value
        ? null
        : {
            controlError,
          };
    };
  }

  public dateDifference(
    maxDaysDifference: number,
    dateToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !dateToCompareOnControl || !dateToCompareOnControl.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));
      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = dateUtils.getDateWithMM_DD_YYYY(
        new Date(dateToCompareOnControl.value)
      );

      return dateUtils.addDaysToDate(validationDate, maxDaysDifference) <= controlDate
        ? null
        : { controlError };
    };
  }

  public requiredToggle(
    toggleControl: AbstractControl,
    toggleControlValueToTriggerError: any,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!toggleControl) {
        return null;
      }

      return toggleControl.value === toggleControlValueToTriggerError && !control.value
        ? { controlError }
        : null;
    };
  }

  public bothCannotBeZero(
    numberToCompareOnControl: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        isNaN(control.value) ||
        isMissingValue(control.value) ||
        isNaN(numberToCompareOnControl.value) ||
        isMissingValue(numberToCompareOnControl.value)
      ) {
        return null;
      }

      const controlNumber = parseInt(control.value, 10);
      const validationNumber = parseInt(numberToCompareOnControl.value, 10);

      return controlNumber === 0 && validationNumber === 0 ? { controlError } : null;
    };
  }

  public exclusiveOr(
    controlToCompareWith: AbstractControl,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // If the main control has a value, ensure the compare control doesn't.
      // Otherwise the main doesn't have a value so the compare should.
      const isExclusive = !isNullOrUndefined(control.value)
        ? isNullOrUndefined(controlToCompareWith.value)
        : !isNullOrUndefined(controlToCompareWith.value);

      return isExclusive ? null : { controlError };
    };
  }
}

export class CustomValidators {
  static ByReference = new ByReference();

  /**
   *
   * usage `new formControl(minUsingString(300, {type: EControlErrorType.ERROR, message: 'whatever message you want'} ))`
   * ^ saving the `IControlError` off as its own variable might look nicer
   *
   * new formControl(minUsingString(300, controlError));
   *
   *
   */

  static minUsingString(minNumber: number, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isMissingValue(control.value) || isNaN(parseInt(control.value.toString(), 10))) {
        return null;
      }

      if (isNaN(parseInt(minNumber.toString(), 10))) {
        return null;
      }
      return minNumber <= parseInt(control.value, 10) ? null : { controlError };
    };
  }

  static maxUsingString(maxNumber: number, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isMissingValue(control.value) || isNaN(parseInt(control.value.toString(), 10))) {
        return null;
      }

      if (isNaN(parseInt(maxNumber.toString(), 10))) {
        return null;
      }

      return maxNumber >= parseInt(control.value, 10) ? null : { controlError };
    };
  }

  static max(
    maxNumber: number,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNaN(control.value) || isMissingValue(control.value)) {
        return null;
      }

      if (isNaN(maxNumber)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message = controlError.message || `Must be less than ${maxNumber + 1}`;

      return maxNumber >= control.value ? null : { controlError };
    };
  }

  static required(
    controlError: IControlError = { type: EControlErrorType.ERROR, message: LABELS.REQUIRED_FIELD },
    objectValue?
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isMissingValue(control.value, objectValue) ? { controlError } : null;
    };
  }

  static nonEmpty(
    controlError: IControlError = { type: EControlErrorType.ERROR, message: LABELS.REQUIRED_FIELD }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isEmptyValue(control.value) ? { controlError } : null;
    };
  }

  // Fails validation if control.value is empty string
  static isInteger(
    controlError: IControlError = {
      type: EControlErrorType.ERROR,
      message: 'Please enter a valid integer.',
    }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !isNullOrUndefined(control.value) && !isInteger(control.value)
        ? { controlError }
        : null;
    };
  }

  static isIntegerOrEmpty(
    controlError: IControlError = {
      type: EControlErrorType.ERROR,
      message: 'Please enter a valid integer.',
    }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !isMissingValue(control.value) && !isInteger(control.value) ? { controlError } : null;
    };
  }

  static isWeekday(
    controlError: IControlError = {
      type: EControlErrorType.ERROR,
      message: 'Cannot be a weekend date.',
    }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !isNullOrUndefined(control.value) && dateUtils.isWeekendDate(control.value)
        ? { controlError }
        : null;
    };
  }

  static min(
    minNumber: number,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNaN(control.value) || isMissingValue(control.value)) {
        return null;
      }

      if (isNaN(minNumber)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message = controlError.message || `Must be greater than ${minNumber - 1}`;

      return minNumber <= control.value ? null : { controlError };
    };
  }

  static maxNonInclusive(
    maxNumber: number,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNaN(control.value) || isMissingValue(control.value)) {
        return null;
      }

      if (isNaN(maxNumber)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message = controlError.message || `Must be less than ${maxNumber}`;

      return maxNumber > control.value ? null : { controlError };
    };
  }

  static minNonInclusive(
    minNumber: number,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNaN(control.value) || isMissingValue(control.value)) {
        return null;
      }

      if (isNaN(minNumber)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message = controlError.message || `Must be greater than ${minNumber}`;

      return minNumber < control.value ? null : { controlError };
    };
  }

  static notEqualNumber(
    notEqualNumber: number,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNaN(control.value) || isMissingValue(control.value)) {
        return null;
      }

      if (isNaN(notEqualNumber)) {
        return null;
      }

      // Default message if one is not provided
      controlError.message = controlError.message || `Must not equal ${notEqualNumber}`;

      return notEqualNumber !== control.value ? null : { controlError };
    };
  }

  static minimumDateTimeNow(
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = new Date(control.value);

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = new Date();

      //If we have a passed in control message, return it to be used in UI
      if (controlError.message !== '') {
        return controlDate >= validationDate ? null : { controlError };
      } else {
        return controlDate >= validationDate
          ? null
          : {
              controlError,
            };
      }
    };
  }

  static minimumDateTime(
    date: Date,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = new Date(control.value);

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = new Date(date);

      //If we have a passed in control message, return it to be used in UI
      if (controlError.message !== '') {
        return controlDate >= validationDate ? null : { controlError };
      } else {
        return controlDate >= validationDate
          ? null
          : {
              controlError,
            };
      }
    };
  }

  static minimumDate_MM_DD_YYYY(
    date: Date,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: '' }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = dateUtils.getDateWithMM_DD_YYYY(new Date(date));

      //If we have a passed in control message, return it to be used in UI
      if (controlError.message !== '') {
        return controlDate >= validationDate ? null : { controlError };
      } else {
        return controlDate >= validationDate
          ? null
          : {
              controlError,
            };
      }
    };
  }

  static maximumDate_MM_DD_YYYY(date: Date, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = dateUtils.getDateWithMM_DD_YYYY(new Date(date));

      return controlDate <= validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  static minimumDate(date: Date, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = dateUtils.getDateWithMM_DD_YYYY(new Date(date));

      return controlDate >= validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  static maximumDate(date: Date, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = dateUtils.getDateWithMM_DD_YYYY(new Date(date));

      return controlDate <= validationDate
        ? null
        : {
            controlError,
          };
    };
  }

  static notEqualDate(date: Date, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));

      if (isNaN(controlDate.getTime())) {
        return null;
      }

      const validationDate = dateUtils.getDateWithMM_DD_YYYY(new Date(date));

      return controlDate.getTime() !== validationDate.getTime()
        ? null
        : {
            controlError,
          };
    };
  }

  static matchingRegExp(exp: RegExp, controlError: IControlError): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (exp.test(control.value)) {
        return null;
      } else {
        controlError.message = controlError.message || 'Must match correct pattern';

        return { controlError };
      }
    };
  }

  static stringLength(
    length,
    controlError: IControlError = { type: EControlErrorType.ERROR, message: LABELS.REQUIRED_FIELD }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !control.value || control.value.length !== length ? { controlError } : null;
    };
  }

  static isParticularDayAndMonth(
    day: number,
    month: number,
    controlError: IControlError
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const controlDate = dateUtils.getDateWithMM_DD_YYYY(new Date(control.value));

      // months are 0 based
      return controlDate.getDate() === day && controlDate.getMonth() === month - 1
        ? null
        : { controlError };
    };
  }

  static phone(
    controlError: IControlError = {
      type: EControlErrorType.ERROR,
      message: 'Invalid phone number format',
    }
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return CustomValidators.matchingRegExp(/^\d{10}$/, controlError)(control);
    };
  }

  /**
   *
   * Use below ByControlReference methods when needed to dynamically validate against other form control values
   * ^ saving the `IControlError` off as its own variable might look nicer
   *
   */
}
