import { JournalEntryRule, JournalEntryRuleCollection } from '@gms/accounting-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export enum EJournalEntryRuleCollectionActions {
  GetJournalEntryRuleCollection = '[JOURNAL ENTRY RULES] get Journal Entry Rules',
  GetJournalEntryRuleCollectionSuccess = '[JOURNAL ENTRY RULES] get Journal Entry Rules Success',
  GetJournalEntryRuleCollectionFailure = '[JOURNAL ENTRY RULES] get Journal Entry Rules Failure',
  ClearJournalEntryRuleCollection = '[JOURNAL ENTRY RULES] clear Journal Entry Rules',
  GetJournalEntryRule = '[JOURNAL ENTRY RULES] get Journal Entry Rule By Id',
  SET_JOURNAL_ENTRY_RULE = '[JOURNAL ENTRY RULES] set Journal Entry Rule with Data',
  CLEAR_JOURNAL_ENTRY_RULE = '[JOURNAL ENTRY RULES] clear Data from Journal Entry Rule',
  GetJournalEntryRuleSuccess = '[JOURNAL ENTRY RULES] get Journal Entry Rule By Id Success',
  GetJournalEntryRuleFailure = '[JOURNAL ENTRY RULES] get Journal Entry Rule By Id Failure',
  PutJournalEntryRule = '[JOURNAL ENTRY RULES] edit Journal Entry Rule',
  PutJournalEntryRuleSuccess = '[JOURNAL ENTRY RULES] edit Journal Entry Rule Success',
  PutJournalEntryRuleFailure = '[JOURNAL ENTRY RULES] edit Journal Entry Rule Failure',
  GetGeneralLedgerAccounts = '[JOURNAL ENTRY RULES] get General Ledger Accounts',
  GetGeneralLedgerAccountsSuccess = '[JOURNAL ENTRY RULES] get General Ledger Accounts Success',
  GetGeneralLedgerAccountsFailure = '[JOURNAL ENTRY RULES] get General Ledger Accounts Failure',
  ClearJournalEntryRuleError = '[JOURNAL ENTRY RULES] clear current Journal Entry Rule Error',
}

export enum EJournalEntryActions {
  ADD_JOURNAL_ENTRY = '[JOURNAL ENTRY RULES] add Journal Entry',
  ADD_JOURNAL_ENTRY_FAILURE = '[JOURNAL ENTRY RULES] add Journal Entry Failure',
  ADD_JOURNAL_ENTRY_SUCCESS = '[JOURNAL ENTRY RULES] add Journal Entry Success',
  RESET_JOURNAL_ENTRY_RULE = '[JOURNAL ENTRY RULES] reset Journal Entry Rule from Create',
  ClearCreateJournalEntryRuleError = '[JOURNAL ENTRY RULES] clear create Journal Entry Rule Error',
}

export class GetJournalEntryRuleCollection implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetJournalEntryRuleCollection;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: SortDescriptor[];
      tspId?: number;
      filterForm?: {
        journalEntryType?: string;
        rateSchedule?: number; //rateScheduleId
        chargeType?: string;
        rateType?: string;
        intercompany?: boolean;
        effectiveDate?: Date;
        expireDate?: Date;
      };
      viewBy?: string;
    }
  ) {}
}

export class GetJournalEntryRuleCollectionSuccess implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetJournalEntryRuleCollectionSuccess;

  constructor(public payload: JournalEntryRuleCollection) {}
}

export class GetJournalEntryRuleCollectionFailure implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetJournalEntryRuleCollectionFailure;

  constructor(public payload: Error) {}
}

export class GetJournalEntryRule implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetJournalEntryRule;

  constructor(public payload: number) {}
}

export class SetJournalEntryRule implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.SET_JOURNAL_ENTRY_RULE;

  constructor(public payload: JournalEntryRule) {}
}

export class ClearJournalEntryRule implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.CLEAR_JOURNAL_ENTRY_RULE;
}

export class GetJournalEntryRuleSuccess implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetJournalEntryRuleSuccess;

  constructor(public payload: JournalEntryRule) {}
}

export class GetJournalEntryRuleFailure implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetJournalEntryRuleFailure;

  constructor(public payload: Error) {}
}

// Create Journal Entry Rule

export class AddJournalEntryRule implements Action {
  public readonly type = EJournalEntryActions.ADD_JOURNAL_ENTRY;

  constructor(public payload: JournalEntryRule) {}
}

export class AddJournalEntryRuleError implements Action {
  public readonly type = EJournalEntryActions.ADD_JOURNAL_ENTRY_FAILURE;

  constructor(public error: Error) {}
}

export class AddJournalEntryRuleSuccess implements Action {
  public readonly type = EJournalEntryActions.ADD_JOURNAL_ENTRY_SUCCESS;

  constructor(public payload: JournalEntryRule) {}
}

export class ResetJournalEntryRule implements Action {
  public readonly type = EJournalEntryActions.RESET_JOURNAL_ENTRY_RULE;
}

export class ClearCreateJournalEntryRuleError implements Action {
  public readonly type = EJournalEntryActions.ClearCreateJournalEntryRuleError;
}

// Edit Journal Entry
export class PutJournalEntryRule implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.PutJournalEntryRule;

  constructor(public payload: JournalEntryRule) {}
}

export class PutJournalEntryRuleSuccess implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.PutJournalEntryRuleSuccess;

  constructor(public payload: JournalEntryRule) {}
}

export class PutJournalEntryRuleFailure implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.PutJournalEntryRuleFailure;

  constructor(public payload: Error) {}
}

export class GetGeneralLedgerAccounts implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetGeneralLedgerAccounts;

  constructor(public payload: number) {}
}

export class GetGeneralLedgerAccountsSuccess implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetGeneralLedgerAccountsSuccess;

  constructor(public payload: Array<any>) {}
}

export class GetGeneralLedgerAccountsFailure implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.GetGeneralLedgerAccountsFailure;

  constructor(public payload: Error) {}
}

export class ClearJournalEntryRuleError implements Action {
  public readonly type = EJournalEntryRuleCollectionActions.ClearJournalEntryRuleError;
}

export type JournalEntryRuleActions =
  | GetJournalEntryRuleCollection
  | GetJournalEntryRuleCollectionSuccess
  | GetJournalEntryRuleCollectionFailure
  | GetJournalEntryRule
  | SetJournalEntryRule
  | ClearJournalEntryRule
  | GetJournalEntryRuleSuccess
  | GetJournalEntryRuleFailure
  | AddJournalEntryRule
  | AddJournalEntryRuleError
  | AddJournalEntryRuleSuccess
  | ResetJournalEntryRule
  | PutJournalEntryRule
  | PutJournalEntryRuleFailure
  | PutJournalEntryRuleSuccess
  | GetGeneralLedgerAccounts
  | GetGeneralLedgerAccountsSuccess
  | GetGeneralLedgerAccountsFailure
  | ClearJournalEntryRuleError
  | ClearCreateJournalEntryRuleError;
