import { TspHolidayCollection } from '@gms/rateschedulev2-api';
import { ServiceProvider } from '@gms/tsp-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ICacheableRequestAction, ICacheableSuccessAction } from '../app/app.models';

export enum ETspActions {
  FetchTsps = '[TSP] Fetch TSPs',
  FetchTspsError = '[TSP] Fetch TSPs Error',
  FetchTspsSuccess = '[TSP] Fetch TSPs Success',
  GetTspById = '[TSP] get TSP by Id',
  GetTspByIdSuccess = '[TSP] get TSP by Id Success',
  GetTspByIdError = '[TSP] get TSP by Id Error',
  GetTspHolidays = '[TSP] get TSP Holidays',
  GetTspHolidaysSuccess = '[TSP] get TSP Holidays Success',
  GetTspHolidaysError = '[TSP] get TSP Holidays Error',
  GetAllTspHolidays = '[TSP] get all TSP Holidays',
  GetAllTspHolidaysSuccess = '[TSP] get all TSP Holidays Success',
  GetAllTspHolidaysError = '[TSP] get all TSP Holidays Error',
}

export interface IFetchTspsPayload {
  status?: 'A' | 'I';
}

export interface IFetchTspPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  tspId?: number;
}

export interface IFetchTspHolidaysPayload {
  tspId: number;
  dateBegin: string;
  dateEnd: string;
}

// Get All
export class FetchTsps implements ICacheableRequestAction {
  public readonly type = ETspActions.FetchTsps;

  constructor(
    public payload: IFetchTspsPayload = { status: 'A' },
    public forceRefresh: boolean = false
  ) {}
}

export class FetchTspsSuccess implements ICacheableSuccessAction {
  public readonly type = ETspActions.FetchTspsSuccess;

  constructor(public payload: ServiceProvider[], public requestPayload: IFetchTspsPayload) {}
}

export class FetchTspsError implements Action {
  public readonly type = ETspActions.FetchTspsError;

  constructor(public error: Error) {}
}

// Get By ID
export class GetTspById implements Action {
  public readonly type = ETspActions.GetTspById;

  constructor(public payload: number) {}
}

export class GetTspByIdSuccess implements Action {
  public readonly type = ETspActions.GetTspByIdSuccess;

  constructor(public payload: ServiceProvider) {}
}

export class GetTspByIdError implements Action {
  public readonly type = ETspActions.GetTspByIdError;

  constructor(public error: ServiceProvider) {}
}

// Holidays
export class GetTspHolidays implements Action {
  public readonly type = ETspActions.GetTspHolidays;

  constructor(public payload: IFetchTspHolidaysPayload) {}
}

export class GetTspHolidaysSuccess implements Action {
  public readonly type = ETspActions.GetTspHolidaysSuccess;

  constructor(public payload: TspHolidayCollection) {}
}

export class GetTspHolidaysError implements Action {
  public readonly type = ETspActions.GetTspHolidaysError;

  constructor(public payload: Error) {}
}
export class GetAllTspHolidays implements Action {
  public readonly type = ETspActions.GetAllTspHolidays;

  constructor(public payload: Array<IFetchTspHolidaysPayload>) {}
}

export class GetAllTspHolidaysSuccess implements Action {
  public readonly type = ETspActions.GetAllTspHolidaysSuccess;

  constructor(public payload: Array<TspHolidayCollection>) {}
}

export class GetAllTspHolidaysError implements Action {
  public readonly type = ETspActions.GetAllTspHolidaysError;

  constructor(public payload: Error) {}
}

export type TspsActions =
  | FetchTsps
  | FetchTspsSuccess
  | FetchTspsError
  | GetTspById
  | GetTspByIdSuccess
  | GetTspByIdError
  | GetTspHolidays
  | GetTspHolidaysSuccess
  | GetTspHolidaysError
  | GetAllTspHolidays
  | GetAllTspHolidaysSuccess
  | GetAllTspHolidaysError;
