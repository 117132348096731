import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EndingBalance, GroupBalance, ReturnedInventoryGroup } from 'app/models/inventory.model';
import { cleanNumberWithCommas } from 'shared/services/data-cleaner.service';

export const inventoryFilterForm = () =>
  new UntypedFormGroup({
    viewOpt: new UntypedFormControl(null),
    acctPeriod: new UntypedFormControl(null),
  });

export enum EInventoryViewByOptions {
  SandD = 'Source & Disposition',
  FacilityStorage = 'Facility Balance',
}

export enum EInventoryGroups {
  PHYSICAL_STORAGE = 'Physical Storage',
  LINE_PACK_PERMANENT = 'Line Pack Permanent',
  IMBALANCE_INVENTORY = 'Imbalance Inventory',
  NET_FUEL = 'Net Fuel',
  THIRD_PARTY_STORAGE = 'Third Party Storage',
}

export enum EInventoryCategories {
  RECOVERABLE_CUSHION = 'Recoverable Cushion',
  NON_RECOVERABLE_CUSHION = 'Non Recoverable Cushion',
  WORKINH_GAS = 'Working Gas',
}

export enum ETsp {
  GULF_SOUTH = 'GULF SOUTH',
  BOARDWALK = 'BOARDWALK STORAGE',
  TEXAS_GAS = 'TEXAS GAS',
}

export const inventoryViewByOptions = [
  { id: EInventoryViewByOptions.SandD, text: 'Source & Disposition' },
  { id: EInventoryViewByOptions.FacilityStorage, text: 'Facility Balance' },
];

export const defaultInventoryGroupName = () => {
  return new Array(
    { groupName: EInventoryGroups.PHYSICAL_STORAGE },
    { groupName: EInventoryGroups.LINE_PACK_PERMANENT },
    { groupName: EInventoryGroups.IMBALANCE_INVENTORY },
    { groupName: EInventoryGroups.NET_FUEL },
    { groupName: EInventoryGroups.THIRD_PARTY_STORAGE }
  );
};

export const defaultInventoryCategoryName = () => {
  return new Array(
    { categoryName: EInventoryCategories.RECOVERABLE_CUSHION },
    { categoryName: EInventoryCategories.NON_RECOVERABLE_CUSHION },
    { categoryName: EInventoryCategories.WORKINH_GAS }
  );
};

const getChargeTypeNetQty = (chargeType, groupName): number => {
  return groupName.toLowerCase() === EInventoryGroups.PHYSICAL_STORAGE.toLowerCase()
    ? chargeType.totalDeliveryQty - chargeType.totalReceiptQty
    : chargeType.totalReceiptQty - chargeType.totalDeliveryQty;
};

const mapInventoryChargeTypes = (chargeTypes, groupName) => {
  return chargeTypes.map(chargeType => ({
    ...chargeType,
    netQty: cleanNumberWithCommas(getChargeTypeNetQty(chargeType, groupName)),
    ppaReceiptQty: cleanNumberWithCommas(chargeType.ppaReceiptQty),
    ppaDeliveryQty: cleanNumberWithCommas(chargeType.ppaDeliveryQty),
    allocatedReceiptQty: cleanNumberWithCommas(chargeType.allocatedReceiptQty),
    allocatedDeliveryQty: cleanNumberWithCommas(chargeType.allocatedDeliveryQty),
    totalDeliveryQty: cleanNumberWithCommas(chargeType.totalDeliveryQty),
    totalReceiptQty: cleanNumberWithCommas(chargeType.totalReceiptQty),
  }));
};

export const mapInventoryGroups = (groups, inventorySummary): ReturnedInventoryGroup => {
  return groups.map(group => {
    if (
      group.groupTotal &&
      group.chargeTypes.length &&
      !group.chargeTypes.filter(
        chargeType => chargeType.netChargeTypeDesc === `TOTAL ${group.groupName.toUpperCase()}`
      ).length
    ) {
      group.chargeTypes.push({
        netChargeTypeDesc: `TOTAL ${group.groupName.toUpperCase()}`,
        ...group.groupTotal,
      });
    }
    if (group.groupName.toLowerCase() === EInventoryGroups.PHYSICAL_STORAGE.toLowerCase()) {
      inventorySummary.totalPhysicalStorage =
        group.groupTotal.totalDeliveryQty - group.groupTotal.totalReceiptQty;
    } else {
      inventorySummary.totalAllocatedToStorage +=
        group.groupTotal.totalReceiptQty - group.groupTotal.totalDeliveryQty;
    }
    return {
      ...group,
      chargeTypes: mapInventoryChargeTypes(group.chargeTypes, group.groupName),
    };
  });
};

export const mapEndingBalancd = (balance): Array<EndingBalance> => {
  const endBalances: Array<EndingBalance> = [];
  if (!!balance) {
    Object.keys(balance).forEach(item => {
      endBalances.push({
        facilityGroupName: item,
        balance: balance[item],
      });
    });
  }
  return endBalances;
};

export const mapInventoryGroupBalance = (groupBalance): Array<GroupBalance> => {
  const balances: Array<GroupBalance> = [];
  if (!!groupBalance) {
    Object.keys(groupBalance).forEach(item => {
      balances.push({
        inventoryGroupName: item,
        endingBalances: mapEndingBalancd(groupBalance[item]),
      });
    });
  }
  return balances;
};

export const replaceZeroWithDash = (value: any) => {
  if (Number(value) === 0) {
    return '-';
  }
  return value;
};

export const replaceTotal = (str: string) => {
  if (str.toUpperCase().startsWith('TOTAL_')) {
    return str.replace('TOTAL_', '');
  } else if (str.toLowerCase().indexOf('category_total') > -1) {
    return 'TOTAL';
  }
  return str;
};
