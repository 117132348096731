import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import { BillingActions, EBillingActions } from './billing.actions';
import { IBillingState, initialBillingState } from './billing.state';
import { formatAccountingPeriods, generateDefaultAccountingPeriod } from './billing.utils';

export function billingReducers(
  state = initialBillingState,
  action: BillingActions
): IBillingState {
  switch (action.type) {
    case EBillingActions.ADD_MASTER_CHARGE_TYPE:
      return {
        ...state,
        loading: {
          ...state.loading,
          chargeType: true,
          chargeTypeSubmit: true,
        },
        error: null,
      };
    case EBillingActions.ADD_MASTER_CHARGE_TYPE_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          chargeType: false,
          chargeTypeSubmit: false,
        },
        error: action.error,
      };
    case EBillingActions.ADD_MASTER_CHARGE_TYPE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          chargeType: false,
          chargeTypeSubmit: false,
        },
        error: null,
        masterChargeType: action.payload,
      };
    case EBillingActions.GET_CHARGE_TYPES:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          chargeType: true,
        },
      };
    case EBillingActions.GET_CHARGE_TYPES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          chargeType: false,
        },
      };
    case EBillingActions.GET_CHARGE_TYPES_SUCCESS:
      return {
        ...state,
        chargeTypes: action.payload.chargeTypes,
        error: null,
        loading: {
          ...state.loading,
          chargeType: false,
        },
      };
    case EBillingActions.GET_CHARGE_TYPES_DICTIONARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          chargeTypeDictionary: true,
        },
        error: null,
        chargeTypesDictionaryError: null,
      };
    case EBillingActions.GET_CHARGE_TYPES_DICTIONARY_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          chargeTypeDictionary: false,
        },
        error: null,
        chargeTypesDictionaryError: action.error,
      };
    case EBillingActions.GET_CHARGE_TYPES_DICTIONARY_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          chargeTypeDictionary: false,
        },
        error: null,
        chargeTypesDictionaryError: null,
        chargeTypesDictionary: action.payload,
      };
    case EBillingActions.FETCH_CHARGE_TYPES_BY_ID:
      return {
        ...state,
        masterChargeTypeView: null,
        chargeTypeByIdError: null,
        loading: {
          ...state.loading,
          chargeType: true,
        },
      };
    case EBillingActions.FETCH_MULTIPLE_CHARGE_TYPES_BY_ID:
      return {
        ...state,
        chargeTypesByIdsError: null,
      };
    case EBillingActions.FETCH_TARIFF_RATE_BY_ID:
      return {
        ...state,
        error: null,
        tariffRateByIdError: null,
        loading: {
          ...state.loading,
          tariffRate: true,
        },
      };
    case EBillingActions.FETCH_TARIFF_RATE_BY_ID_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRate: false,
        },
        error: action.error,
        tariffRateByIdError: action.error,
      };
    case EBillingActions.FETCH_TARIFF_RATE_BY_ID_SUCCESS:
      return {
        ...state,
        tariffRate: action.tariffRate,
        loading: {
          ...state.loading,
          tariffRate: false,
        },
        error: null,
        tariffRateByIdError: null,
      };
    case EBillingActions.FETCH_TARIFF_RATES:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          tariffRate: true,
        },
      };
    case EBillingActions.FETCH_TARIFF_RATES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          tariffRate: false,
        },
      };
    case EBillingActions.FETCH_CHARGE_TYPES_BY_ID_SUCCESS:
      return {
        ...state,
        masterChargeTypeView: action.payload.chargeType,
        chargeTypeByIdError: null,
        loading: {
          ...state.loading,
          chargeType: false,
        },
      };
    case EBillingActions.FETCH_MULTIPLE_CHARGE_TYPES_BY_ID_SUCCESS:
      return {
        ...state,
        selectedChargeTypes: action.payload.chargeTypes,
        chargeTypesByIdsError: null,
        loading: {
          ...state.loading,
          chargeType: false,
        },
      };
    case EBillingActions.FETCH_TARIFF_RATES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          tariffRate: false,
        },
        totalTariffRateCount: action.payload.totalTariffRateCount,
        tariffRates: action.payload.tariffRates,
      };
    case EBillingActions.FETCH_INVOICES:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoices: true,
        },
      };
    case EBillingActions.FETCH_INVOICES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          invoices: false,
        },
      };

    case EBillingActions.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoices: false,
        },
        totalInvoiceCount: action.payload.totalInvoiceCount,
        invoices: action.payload.invoices,
      };

    case EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoiceDetailsTotal: true,
        },
      };
    case EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL_FAILURE:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoiceDetailsTotal: false,
        },
      };
    case EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_TOTAL_SUCCESS:
      return {
        ...state,
        invoiceDetailsTotal: action.payload,
        error: null,
        loading: {
          ...state.loading,
          invoiceDetailsTotal: false,
        },
      };
    case EBillingActions.CLEAR_INVOICE_TRANSACTION_DETAILS_TOTAL:
      return {
        ...state,
        invoiceDetailsTotal: null,
      };

    case EBillingActions.FETCH_INVOICES_EXCEPTIONS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoiceExceptions: true,
        },
      };
    case EBillingActions.FETCH_INVOICES_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoiceExceptions: false,
        },
        invoiceExceptions: action.payload.invoiceErrors,
        totalInvoiceExceptions: action.payload.total,
      };
    case EBillingActions.FETCH_INVOICES_EXCEPTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          invoiceExceptions: false,
        },
      };
    case EBillingActions.FETCH_INVOICE_GROUPS_BY_ID:
      return {
        ...state,
        invoiceGroupCollectionError: null,
        loading: {
          ...state.loading,
          invoiceGroupCollection: true,
        },
        invoiceGroupCollection: null,
      };
    case EBillingActions.FETCH_INVOICE_GROUPS_BY_ID_FAILURE:
      return {
        ...state,
        invoiceGroupCollectionError: action.error,
        loading: {
          ...state.loading,
          invoiceGroupCollection: false,
        },
        invoiceGroupCollection: null,
      };
    case EBillingActions.FETCH_INVOICE_GROUPS_BY_ID_SUCCESS:
      return {
        ...state,
        invoiceGroupCollectionError: null,
        loading: {
          ...state.loading,
          invoiceGroupCollection: false,
        },
        invoiceGroupCollection: action.invoiceGroupCollection,
      };
    case EBillingActions.FETCH_CHARGE_TYPES_BY_ID_FAILURE:
      return {
        ...state,
        masterChargeTypeView: null,
        chargeTypeByIdError: action.error,
        loading: {
          ...state.loading,
          chargeType: false,
        },
      };
    case EBillingActions.FETCH_MULTIPLE_CHARGE_TYPES_BY_ID_FAILURE:
      return {
        ...state,
        chargeTypesByIdsError: action.error,
        loading: {
          ...state.loading,
          chargeType: false,
        },
      };
    case EBillingActions.GET_TARIFF_RATE_DICTIONARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRateDictionary: true,
        },
        error: null,
        tariffRateDictionaryError: null,
      };
    case EBillingActions.GET_TARIFF_RATE_DICTIONARY_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRateDictionary: false,
        },
        error: null,
        tariffRateDictionaryError: action.error,
      };
    case EBillingActions.GET_TARIFF_RATE_DICTIONARY_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRateDictionary: false,
        },
        error: null,
        tariffRateDictionaryError: null,
        tariffRateDictionary: action.payload,
      };
    case EBillingActions.RESET_MASTER_CHARGE_TYPE:
      return {
        ...state,
        loading: {
          ...state.loading,
          chargeType: false,
        },
        error: null,
        chargeTypes: null,
        masterChargeType: null,
      };
    case EBillingActions.RESET_MASTER_CHARGE_TYPE_VIEW:
      return {
        ...state,
        masterChargeTypeView: null,
        chargeTypeByIdError: null,
        loading: {
          ...state.loading,
          chargeType: false,
        },
      };

    case EBillingActions.FETCH_INVOICE_FILTERS:
      return {
        ...state,
        error: null,
        invoiceFilterCollection: null,
        loading: {
          ...state.loading,
          invoiceFilters: true,
        },
        invoiceFilterError: null,
      };

    case EBillingActions.FETCH_INVOICE_FILTERS_SUCCESS:
      return {
        ...state,
        invoiceFilterCollection: action.payload.invoiceFilterCollection,
        loading: {
          ...state.loading,
          invoiceFilters: false,
        },
        invoiceFilterError: null,
      };
    case EBillingActions.FETCH_INVOICE_FILTERS_FAILURE:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoiceFilters: false,
        },
        invoiceFilterError: action.payload.error,
      };

    case EBillingActions.SEARCH_CHARGE_TYPES:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          searchChargeTypes: true,
        },
      };
    case EBillingActions.SEARCH_CHARGE_TYPES_SUCCESS:
      return {
        ...state,
        error: null,
        chargeTypesSearch: action.payload.chargeTypes,
        loading: {
          ...state.loading,
          searchChargeTypes: false,
        },
      };
    case EBillingActions.SEARCH_CHARGE_TYPES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          searchChargeTypes: false,
        },
      };

    case EBillingActions.SEARCH_DISQUALIFICATION_FLOW_CHARGE_TYPES_SUCCESS:
      return {
        ...state,
        error: null,
        flowLocationsChargeTypesSearch: action.payload.chargeTypes,
      };
    case EBillingActions.SEARCH_SEGMENTATION_TOTALS_CHARGE_TYPES_SUCCESS:
      return {
        ...state,
        error: null,
        segmentationTotalsChargeTypesSearch: action.payload.chargeTypes,
      };

    case EBillingActions.ADD_TARIFF_RATE:
    case EBillingActions.UPDATE_TARIFF_RATE:
      return {
        ...state,
        loading: {
          ...state.loading,
          addOrUpdateTariffRate: true,
        },
        addOrUpdateTariffRateError: null,
      };

    case EBillingActions.ADD_TARIFF_RATE_FAILURE:
    case EBillingActions.UPDATE_TARIFF_RATE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          addOrUpdateTariffRate: false,
        },
        addOrUpdateTariffRateError: action.error,
      };

    case EBillingActions.ADD_TARIFF_RATE_SUCCESS:
    case EBillingActions.UPDATE_TARIFF_RATE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          addOrUpdateTariffRate: false,
        },
        addOrUpdateTariffRateError: null,
        tariffRate: action.payload,
      };

    case EBillingActions.RESET_TARIFF_RATE:
      return {
        ...state,
        tariffRate: null,
        addOrUpdateTariffRateError: null,
      };

    case EBillingActions.CLEAR_INVOICE_TRANSACTION_DETAILS:
      return {
        ...state,
        invoiceDetailsError: null,
        invoiceDetails: null,
        loading: {
          ...state.loading,
          invoiceDetail: false,
        },
      };

    case EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS:
      return {
        ...state,
        invoiceDetailsError: null,
        loading: {
          ...state.loading,
          invoiceDetail: true,
        },
      };

    case EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        invoiceDetailsError: action.error,
        loading: {
          ...state.loading,
          invoiceDetail: false,
        },
      };
    case EBillingActions.FETCH_INVOICE_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceDetails: action.payload.invoiceDetails,
        invoiceDetailsError: null,
        loading: {
          ...state.loading,
          invoiceDetail: false,
        },
      };
    case EBillingActions.UPDATE_MASTER_CHARGE_TYPE:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          chargeType: true,
          chargeTypeSubmit: true,
        },
      };
    case EBillingActions.UPDATE_MASTER_CHARGE_TYPE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          chargeType: false,
          chargeTypeSubmit: false,
        },
      };
    case EBillingActions.UPDATE_MASTER_CHARGE_TYPE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          chargeType: false,
          chargeTypeSubmit: false,
        },
        masterChargeType: action.payload,
      };
    case EBillingActions.FETCH_INVOICE_BY_ID:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoice: true,
        },
      };
    case EBillingActions.FETCH_INVOICE_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          invoice: false,
        },
      };
    case EBillingActions.FETCH_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          invoice: false,
        },
        invoice: action.payload,
      };

    case EBillingActions.FETCH_CHARGE_TYPES_DETAILS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          chargeTypesDetails: true,
        },
      };

    case EBillingActions.FETCH_CHARGE_TYPES_DETAILS_SUCCESS:
      return {
        ...state,
        chargeTypesDetails: action.payload,
        error: null,
        loading: {
          ...state.loading,
          chargeTypesDetails: false,
        },
      };
    case EBillingActions.FETCH_CHARGE_TYPES_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          chargeTypesDetails: false,
        },
      };

    case EBillingActions.FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          earliestOpenAccountingPeriod: true,
        },
        accountingPeriodError: null,
      };
    case EBillingActions.FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          earliestOpenAccountingPeriod: false,
        },
        accountingPeriodError: action.error,
      };
    case EBillingActions.FETCH_EARLIEST_OPEN_ACCOUNTING_PERIOD_SUCCESS:
      return {
        ...state,
        earliestOpenAccountingPeriod: action.payload,
        error: null,
        loading: {
          ...state.loading,
          earliestOpenAccountingPeriod: false,
        },
        accountingPeriodError: null,
      };
    case EBillingActions.FETCH_TARIFF_RATE_DETAILS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          tariffRateDetails: true,
        },
      };

    case EBillingActions.FETCH_TARIFF_RATE_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          tariffRateDetails: false,
        },
      };
    case EBillingActions.FETCH_TARIFF_RATE_DETAILS_SUCCESS:
      return {
        ...state,
        tariffRateDetails: action.payload.tarrifRateDetails,
        error: null,
        loading: {
          ...state.loading,
          tariffRateDetails: false,
        },
      };
    case EBillingActions.FETCH_EXPORT_CHARGE_TYPES_DETAILS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
        },
        exportChargeTypesDetailsLoading: true,
      };
    case EBillingActions.FETCH_EXPORT_CHARGE_TYPES_DETAILS_SUCCESS:
      return {
        ...state,
        exportChargeTypesDetails: action.payload,
        error: null,
        loading: {
          ...state.loading,
        },
        exportChargeTypesDetailsLoading: false,
      };
    case EBillingActions.FETCH_EXPORT_CHARGE_TYPES_DETAILS_FAILURE:
      return {
        ...state,
        exportChargeTypesDetails: null,
        error: action.error,
        loading: {
          ...state.loading,
        },
        exportChargeTypesDetailsLoading: false,
      };
    case EBillingActions.CLEAR_EXPORT_CHARGE_TYPES_DETAILS:
      return {
        ...state,
        exportChargeTypesDetails: null,
      };
    case EBillingActions.FETCH_EXPORT_INVOICE_DETAILS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          exportInvoiceDetails: true,
        },
      };
    case EBillingActions.FETCH_EXPORT_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        exportInvoiceDetails: action.payload.invoiceDetails,
        error: null,
        loading: {
          ...state.loading,
          exportInvoiceDetails: false,
        },
      };
    case EBillingActions.FETCH_EXPORT_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        exportInvoiceDetails: null,
        error: action.error,
        loading: {
          ...state.loading,
          exportInvoiceDetails: false,
        },
      };
    case EBillingActions.CLEAR_EXPORT_INVOICE_DETAILS:
      return {
        ...state,
        exportInvoiceDetails: null,
      };
    case EBillingActions.FETCH_EXPORT_INVOICE_EXCEPTIONS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          exportInvoiceExceptions: true,
        },
      };
    case EBillingActions.FETCH_EXPORT_INVOICE_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        exportInvoiceExceptions: action.payload.invoiceExceptions,
        error: null,
        loading: {
          ...state.loading,
          exportInvoiceExceptions: false,
        },
      };
    case EBillingActions.FETCH_EXPORT_INVOICE_EXCEPTIONS_FAILURE:
      return {
        ...state,
        exportInvoiceExceptions: null,
        error: action.error,
        loading: {
          ...state.loading,
          exportInvoiceExceptions: false,
        },
      };
    case EBillingActions.CLEAR_EXPORT_INVOICE_EXCEPTIONS:
      return {
        ...state,
        exportInvoiceExceptions: null,
      };
    case EBillingActions.FETCH_EXPORT_TARIFF_RATE_DETAILS:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          exportTariffRateDetails: true,
        },
      };
    case EBillingActions.FETCH_EXPORT_TARIFF_RATE_DETAILS_SUCCESS:
      return {
        ...state,
        exportTariffRateDetails: action.payload.exportTarrifRateDetails,
        error: null,
        loading: {
          ...state.loading,
          exportTariffRateDetails: false,
        },
      };
    case EBillingActions.FETCH_EXPORT_TARIFF_RATE_DETAILS_FAILURE:
      return {
        ...state,
        exportTariffRateDetails: null,
        error: action.error,
        loading: {
          ...state.loading,
          exportTariffRateDetails: false,
        },
      };
    case EBillingActions.CLEAR_EXPORT_TARIFF_RATE_DETAILS:
      return {
        ...state,
        exportTariffRateDetails: null,
      };

    case EBillingActions.FETCH_TSP_CHARGE_TYPE:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          tspChargeType: true,
        },
      };

    case EBillingActions.FETCH_TSP_CHARGE_TYPE_SUCCESS:
      return {
        ...state,
        tspChargeType: action.payload.tspChargeType,
        error: null,
        loading: {
          ...state.loading,
          tspChargeType: false,
        },
      };
    case EBillingActions.FETCH_TSP_CHARGE_TYPE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          tspChargeType: false,
        },
      };
    case EBillingActions.FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID:
      return {
        ...state,
        error: null,
        loading: {
          ...state.loading,
          multipleTspChargeTypes: true,
        },
      };
    case EBillingActions.FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID_SUCCESS:
      return {
        ...state,
        selectedTspChargeTypes: action.payload.tspChargeTypes,
        error: null,
        loading: {
          ...state.loading,
          multipleTspChargeTypes: false,
        },
      };
    case EBillingActions.FETCH_MULTIPLE_TSP_CHARGE_TYPES_BY_ID_FAILURE:
      return {
        ...state,
        selectedTspChargeTypes: null,
        error: action.error,
        loading: {
          ...state.loading,
          multipleTspChargeTypes: false,
        },
      };
    case EBillingActions.FETCH_TSP_RESERVATION_CHARGE_TYPES:
      return {
        ...state,
        tspReservationChargeTypesError: null,
        loading: {
          ...state.loading,
          tspReservationChargeType: true,
        },
      };
    case EBillingActions.FETCH_TSP_RESERVATION_CHARGE_TYPES_SUCCESS:
      return {
        ...state,
        tspReservationChargeTypes: action.payload.tspReservationChargeTypes,
        tspReservationChargeTypesError: null,
        loading: {
          ...state.loading,
          tspReservationChargeType: false,
        },
      };
    case EBillingActions.FETCH_TSP_RESERVATION_CHARGE_TYPES_FAILURE:
      return {
        ...state,
        tspReservationChargeTypes: null,
        tspReservationChargeTypesError: action.error,
        loading: {
          ...state.loading,
          tspReservationChargeType: false,
        },
      };

    case EBillingActions.ADD_INVOICE_GROUP:
    case EBillingActions.UPDATE_INVOICE_GROUP:
      return {
        ...state,
        loading: {
          ...state.loading,
          invoiceGroup: true,
        },
        invoiceGroupError: null,
      };

    case EBillingActions.ADD_INVOICE_GROUP_FAILURE:
    case EBillingActions.UPDATE_INVOICE_GROUP_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          invoiceGroup: false,
        },
        invoiceGroupError: action.error,
      };

    case EBillingActions.ADD_INVOICE_GROUP_SUCCESS:
    case EBillingActions.UPDATE_INVOICE_GROUP_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          invoiceGroup: false,
        },
        invoiceGroupError: null,
        invoiceGroup: action.payload,
      };

    case EBillingActions.RESET_INVOICE_GROUP:
      return {
        ...state,
        invoiceGroup: null,
        invoiceGroupError: null,
      };

    case EBillingActions.FETCH_INVOICE_GROUP_BY_ID:
      return {
        ...state,
        loading: {
          ...state.loading,
          invoiceGroup: true,
        },
        invoiceGroupError: null,
        invoiceGroup: null,
      };

    case EBillingActions.FETCH_INVOICE_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          invoiceGroup: false,
        },
        invoiceGroupError: null,
        invoiceGroup: action.invoiceGroup,
      };

    case EBillingActions.FETCH_INVOICE_GROUP_BY_ID_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          invoiceGroup: false,
        },
        invoiceGroupError: action.error,
        invoiceGroup: null,
      };

    case EBillingActions.FETCH_ALL_OPEN_ACCOUNTING_PERIODS:
      return {
        ...state,
        loading: {
          ...state.loading,
          allOpenAccountingPeriods: true,
        },
        error: null,
        allOpenAccountingPeriods: null,
      };

    case EBillingActions.FETCH_ALL_OPEN_ACCOUNTING_PERIODS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          allOpenAccountingPeriods: false,
        },
        error: null,
        allOpenAccountingPeriods: action.payload,
      };

    case EBillingActions.FETCH_ALL_OPEN_ACCOUNTING_PERIODS_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          allOpenAccountingPeriods: false,
        },
        error: action.error,
        allOpenAccountingPeriods: null,
      };
    case EBillingActions.SET_CHARGE_TYPES_NAVIGATION_STATE:
      return {
        ...state,
        chargeTypesNavigationState: action.payload,
      };

    case EBillingActions.UPDATE_TARIFF_RATE_WORKFLOW:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRateVersionWorkflowAction: true,
        },
        tariffRateVersionWorkflowActionSuccess: false,
        tariffRateVersionWorkflowAction: action.payload.tariffRateVersionWorkflowAction,
      };

    case EBillingActions.UPDATE_TARIFF_RATE_WORKFLOW_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRateVersionWorkflowAction: false,
        },
        tariffRateVersionWorkflowActionError: action.error,
        tariffRateVersionWorkflowActionSuccess: false,
        tariffRateVersionWorkflowAction: null,
      };

    case EBillingActions.UPDATE_TARIFF_RATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRateVersionWorkflowAction: false,
        },
        tariffRateVersionWorkflowActionError: null,
        tariffRateVersionWorkflowAction: null,
        tariffRateVersionWorkflowActionSuccess: true,
      };

    case EBillingActions.CLEAR_TARIFF_RATE_WORKFLOW:
      return {
        ...state,
        loading: {
          ...state.loading,
          tariffRateVersionWorkflowAction: false,
        },
        tariffRateVersionWorkflowActionError: null,
        tariffRateVersionWorkflowAction: null,
        tariffRateVersionWorkflowActionSuccess: null,
      };

    case EBillingActions.FETCH_INDEX_RATE:
      return {
        ...state,
        indexRateDetail: null,
        loading: {
          ...state.loading,
          indexRate: true,
        },
      };
    case EBillingActions.FETCH_INDEX_RATE_FAILURE:
      return {
        ...state,
        indexRateError: action.error,
        loading: {
          ...state.loading,
          indexRate: false,
        },
      };
    case EBillingActions.FETCH_INDEX_RATE_SUCCESS:
      return {
        ...state,
        indexRateError: null,
        loading: {
          ...state.loading,
          indexRate: false,
        },
        indexRateDetail: action.payload,
      };

    case EBillingActions.CLEAR_INDEX_RATE:
      return {
        ...state,
        indexRateDetail: null,
        loading: {
          ...state.loading,
          indexRate: false,
        },
      };

    case EBillingActions.FETCH_CALCULATED_PRICE_TYPES:
      return {
        ...state,
        calculatedPriceTypes: null,
        loading: {
          ...state.loading,
          calculatedPriceTypes: true,
        },
      };
    case EBillingActions.FETCH_CALCULATED_PRICE_TYPES_FAILURE:
      return {
        ...state,
        calculatedPriceTypesError: action.error,
        loading: {
          ...state.loading,
          calculatedPriceTypes: false,
        },
      };
    case EBillingActions.FETCH_CALCULATED_PRICE_TYPES_SUCCESS:
      return {
        ...state,
        calculatedPriceTypesError: null,
        loading: {
          ...state.loading,
          calculatedPriceTypes: false,
        },
        calculatedPriceTypes: action.payload,
      };

    case EBillingActions.FETCH_INDEX_RATES:
      return {
        ...state,
        indexRates: createLoadingState(state.indexRates),
      };
    case EBillingActions.FETCH_INDEX_RATES_FAILURE:
      return {
        ...state,
        indexRates: createErrorState(action.error),
      };
    case EBillingActions.FETCH_INDEX_RATES_SUCCESS:
      return {
        ...state,
        indexRates: createSuccessState(action.payload.indexRates),
      };

    case EBillingActions.ADD_INDEX_RATE:
      return {
        ...state,
        loading: {
          ...state.loading,
          indexRate: true,
          indexRateDetail: true,
        },
        indexRate: null,
        indexRateError: null,
      };
    case EBillingActions.ADD_INDEX_RATE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          indexRate: false,
        },
        indexRateError: action.error,
      };
    case EBillingActions.ADD_INDEX_RATE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          indexRate: false,
          indexRateDetail: false,
        },
        indexRate: action.payload,
        indexRateError: null,
      };

    case EBillingActions.FETCH_ACCOUNTS:
      return {
        ...state,
        accounts: createLoadingState(state.accounts),
      };
    case EBillingActions.FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: createSuccessState(action.payload.accountAllDetails),
      };
    case EBillingActions.FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        accounts: createErrorState(action.error),
      };

    case EBillingActions.FETCH_INVOICE_ATTACHMENTS:
      return {
        ...state,
        invoiceAttachments: createLoadingState(state.invoiceAttachments),
      };
    case EBillingActions.FETCH_INVOICE_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        invoiceAttachments: createSuccessState(action.payload),
      };
    case EBillingActions.FETCH_INVOICE_ATTACHMENTS_FAILURE:
      return {
        ...state,
        invoiceAttachments: createErrorState(action.error),
      };

    case EBillingActions.DELETE_INVOICE_ATTACHMENT:
      return {
        ...state,
        deleteInvoiceAttachment: { httpError: null, loading: true },
      };
    case EBillingActions.DELETE_INVOICE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        deleteInvoiceAttachment: { httpError: null, loading: false },
      };
    case EBillingActions.DELETE_INVOICE_ATTACHMENT_FAILURE:
      return {
        ...state,
        deleteInvoiceAttachment: { httpError: action.error, loading: false },
      };

    case EBillingActions.UPLOAD_INVOICE_ATTACHMENT:
      return {
        ...state,
        uploadInvoiceAttachment: { httpError: null, loading: true },
      };
    case EBillingActions.UPLOAD_INVOICE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        uploadInvoiceAttachment: { httpError: null, loading: false },
      };
    case EBillingActions.UPLOAD_INVOICE_ATTACHMENT_FAILURE:
      return {
        ...state,
        uploadInvoiceAttachment: { httpError: action.error, loading: false },
      };

    case EBillingActions.DOWNLOAD_INVOICE_PACKAGE:
      return {
        ...state,
        downloadInvoicePackage: { httpError: null, loading: true, invoiceId: action.invoiceId },
      };
    case EBillingActions.DOWNLOAD_INVOICE_PACKAGE_SUCCESS:
      return {
        ...state,
        downloadInvoicePackage: { httpError: null, loading: false, invoiceId: null },
      };
    case EBillingActions.DOWNLOAD_INVOICE_PACKAGE_FAILURE:
      return {
        ...state,
        downloadInvoicePackage: { httpError: action.error, loading: false, invoiceId: null },
      };

    case EBillingActions.GET_PAL_DAILY_BILLING_RATE:
      return {
        ...state,
        palDailyRates: {
          ...state.palDailyRates,
          loading: true,
          httpError: null,
        },
      };
    case EBillingActions.GET_PAL_DAILY_BILLING_RATE_SUCCESS:
      return {
        ...state,
        palDailyRates: {
          ...state.palDailyRates,
          loading: false,
          dailyRates: {
            ...state.palDailyRates.dailyRates,
            [action.payload.contractId]: action.payload.rate,
          },
        },
      };
    case EBillingActions.GET_PAL_DAILY_BILLING_RATE_FAILURE:
      return {
        ...state,
        palDailyRates: {
          ...state.palDailyRates,
          loading: false,
          httpError: action.error,
          dailyRates: { ...state.palDailyRates.dailyRates, [action.contractId]: action.rate },
        },
      };

    case EBillingActions.GET_ACCOUNTING_PERIODS:
      return {
        ...state,
        accountingPeriodCollection: {
          accountingPeriods: [],
          defaultAccountingPeriod: null,
          formattedAccountingPeriods: [],
          httpError: null,
          loading: true,
        },
      };
    case EBillingActions.GET_ACCOUNTING_PERIODS_SUCCESS:
      return {
        ...state,
        accountingPeriodCollection: {
          accountingPeriods: action.payload,
          defaultAccountingPeriod: generateDefaultAccountingPeriod(action.payload),
          formattedAccountingPeriods: formatAccountingPeriods(action.payload),
          httpError: null,
          loading: false,
        },
      };
    case EBillingActions.GET_ACCOUNTING_PERIODS_FAILURE:
      return {
        ...state,
        accountingPeriodCollection: {
          accountingPeriods: [],
          defaultAccountingPeriod: null,
          formattedAccountingPeriods: [],
          httpError: action.error,
          loading: false,
        },
      };

    case EBillingActions.FETCH_CAP_REL_INDEX_RATES:
      return {
        ...state,
        capRelIndexRates: createLoadingState(state.capRelIndexRates),
      };
    case EBillingActions.FETCH_CAP_REL_INDEX_RATES_FAILURE:
      return {
        ...state,
        capRelIndexRates: createErrorState(action.error),
      };
    case EBillingActions.FETCH_CAP_REL_INDEX_RATES_SUCCESS:
      return {
        ...state,
        capRelIndexRates: createSuccessState(action.payload.indexRates),
      };

    case EBillingActions.FETCH_ZONE_TO_ZONE:
      return {
        ...state,
        effectiveRates: createLoadingState(state.effectiveRates),
      };

    case EBillingActions.FETCH_ZONE_TO_ZONE_SUCCESS:
      return {
        ...state,
        effectiveRates: createSuccessState(action.payload),
      };

    case EBillingActions.FETCH_ZONE_TO_ZONE_FAILURE:
      return {
        ...state,
        effectiveRates: createErrorState(null),
      };

    case EBillingActions.FETCH_FUEL_ZONE_TO_FUEL_ZONE:
      return {
        ...state,
        effectiveRates: createLoadingState(state.effectiveRates),
      };

    case EBillingActions.FETCH_FUEL_ZONE_TO_FUEL_ZONE_SUCCESS:
      return {
        ...state,
        effectiveRates: createSuccessState(action.payload),
      };

    case EBillingActions.FETCH_FUEL_ZONE_TO_FUEL_ZONE_FAILURE:
      return {
        ...state,
        effectiveRates: createErrorState(null),
      };

    case EBillingActions.FETCH_DELIVERY_ZONES:
      return {
        ...state,
        effectiveRates: createLoadingState(state.effectiveRates),
      };

    case EBillingActions.FETCH_DELIVERY_ZONES_SUCCESS:
      return {
        ...state,
        effectiveRates: createSuccessState(action.payload),
      };

    case EBillingActions.FETCH_DELIVERY_ZONES_FAILURE:
      return {
        ...state,
        effectiveRates: createErrorState(null),
      };

    case EBillingActions.FETCH_DELIVERY_FUEL_ZONES:
      return {
        ...state,
        effectiveRates: createLoadingState(state.effectiveRates),
      };

    case EBillingActions.FETCH_DELIVERY_FUEL_ZONES_SUCCESS:
      return {
        ...state,
        effectiveRates: createSuccessState(action.payload),
      };

    case EBillingActions.FETCH_DELIVERY_FUEL_ZONES_FAILURE:
      return {
        ...state,
        effectiveRates: createErrorState(action.payload.error),
      };

    default:
      return state;
  }
}
