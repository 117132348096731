import { DealSheet } from '@gms/servicerequest-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IPagination } from '../../../shared/models/pagination.model';

export interface IDealSheetState {
  editingDealSheet: DealSheet;
  dealSheets: DealSheet[];
  sort: SortDescriptor[];
  pagination: IPagination;
  loading: boolean;
  error: boolean;
  savedDealSheetId: number;
  networkResultStatus: string;
  totalDealSheetsCount: number;
  updatedDealSheet: DealSheet;
  dealSheetError: DealSheet;
}
export const initialDealSheetsState: IDealSheetState = {
  editingDealSheet: {},
  dealSheets: [],
  savedDealSheetId: undefined,
  sort: [
    {
      field: 'lastUpdatedDate',
      dir: 'asc',
    },
  ],
  pagination: {
    pageSize: 5,
    pageNumber: 1,
  },
  loading: false,
  error: null,
  networkResultStatus: null,
  totalDealSheetsCount: 0,
  updatedDealSheet: {},
  dealSheetError: {},
};
