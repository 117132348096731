import { SortDescriptor } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'shared/utils/type.utils';

export const mapSortDescriptorsToQueryParam = (sortBy: SortDescriptor[]) => {
  if (isNullOrUndefined(sortBy)) return null;
  return sortBy.map(sortDesc => `${sortDesc.field}+${sortDesc.dir}`).join('|');
};

export function sortArray(array: any[], field: string, ascending: boolean): any[] {
  return array.sort((a, b) => {
    const valueA = a[field];
    const valueB = b[field];

    if (valueA < valueB) {
      return ascending ? -1 : 1;
    } else if (valueA > valueB) {
      return ascending ? 1 : -1;
    } else {
      return 0;
    }
  });
}