import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

import {
  LineResultAssociationType,
  LineSequenceDetail,
  Location,
  LookupData,
  ModelLine,
  ModelLineSequence,
  ModelSequenceLocation,
  ModelStatuses,
  PipelineModel,
  PipelineModelCollection,
  PipelineModelDelta,
} from '@gms/pipeline-api';
import { RetryableAction } from 'app/store/retry/retry.actions';
import { EPipelineError } from './pipeline-models.state';

export enum EPipelineModelsActions {
  FETCH_PIPELINE_MODELS = '[PIPELINE-MODELS] Fetch Pipeline Models',
  FETCH_PIPELINE_MODELS_SUCCESS = '[PIPELINE-MODELS] Fetch Pipeline Models Success',
  FETCH_PIPELINE_MODELS_FAILURE = '[PIPELINE-MODELS] Fetch Pipeline Models Failure',
  SET_HISTORICAL_PIPELINE_MODEL = '[PIPELINE-MODELS] Set Historical Pipeline Model',
  CLEAR_HISTORICAL_PIPELINE_MODEL = '[PIPELINE-MODELS] Fetch Clear Historical Pipeline Model',
  FETCH_MODEL_LINES = '[PIPELINE-MODELS] Fetch Model Lines',
  FETCH_MODEL_LINES_SUCCESS = '[PIPELINE-MODELS] Fetch Model Lines Success',
  FETCH_MODEL_LINES_FAILURE = '[PIPELINE-MODELS] Fetch Model Lines Failure',
  FETCH_MODEL_LINE_SEQUENCES = '[PIPELINE-MODELS] Fetch Model Line Sequences',
  FETCH_MODEL_LINE_SEQUENCES_SUCCESS = '[PIPELINE-MODELS] Fetch Model Line Sequences Success',
  FETCH_MODEL_LINE_SEQUENCES_FAILURE = '[PIPELINE-MODELS] Fetch Model Line Sequences Failure',
  FETCH_MODEL_SEQUENCE_LOCATIONS = '[PIPELINE-MODELS] Fetch Sequence Locations',
  FETCH_MODEL_SEQUENCE_LOCATIONS_SUCCESS = '[PIPELINE-MODELS] Fetch Sequence Locations Success',
  FETCH_MODEL_SEQUENCE_LOCATIONS_FAILURE = '[PIPELINE-MODELS] Fetch Sequence Locations Failure',
  FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS = '[PIPELINE-MODELS] Fetch Available Sequence Locations',
  FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS_SUCCESS = '[PIPELINE-MODELS] Fetch Available Sequence Locations Success',
  FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS_FAILURE = '[PIPELINE-MODELS] Fetch Available Sequence Locations Failure',
  FETCH_UNASSOCIATED_MODEL_LINES = '[PIPELINE-MODELS] Fetch Unassociated Model Lines',
  FETCH_UNASSOCIATED_MODEL_LINES_SUCCESS = '[PIPELINE-MODELS] Fetch Unassociated Model Lines Success',
  FETCH_UNASSOCIATED_MODEL_LINES_FAILURE = '[PIPELINE-MODELS] Fetch Unassociated Model Lines Failure',
  CREATE_LINE = '[PIPELINE-MODELS] Create Line',
  CREATE_LINE_SUCCESS = '[PIPELINE-MODELS] Create Line Success',
  CREATE_LINE_FAILURE = '[PIPELINE-MODELS] Create Line Failure',
  UPDATE_LINE = '[PIPELINE-MODELS] Update Line',
  UPDATE_LINE_SUCCESS = '[PIPELINE-MODELS] Update Line Success',
  UPDATE_LINE_FAILURE = '[PIPELINE-MODELS] Update Line Failure',
  CREATE_SEQUENCE = '[PIPELINE-MODELS] Create Sequence',
  CREATE_SEQUENCE_SUCCESS = '[PIPELINE-MODELS] Create Sequence Success',
  CREATE_SEQUENCE_FAILURE = '[PIPELINE-MODELS] Create Sequence Failure',
  UPDATE_SEQUENCE = '[PIPELINE-MODELS] Update Sequence',
  UPDATE_SEQUENCE_SUCCESS = '[PIPELINE-MODELS] Update Sequence Success',
  UPDATE_SEQUENCE_FAILURE = '[PIPELINE-MODELS] Update Sequence Failure',
  CLEAR_SEQUENCE_VALIDATION_ERROR = '[PIPELINE-MODELS] Clear Sequence Validation Error',
  RESET_SELECTED_LINE_AND_SEQUENCE = '[PIPELINE-MODELS] Reset Selected Line And Sequence',
  SET_SELECTED_LINE_AND_SEQUENCE = '[PIPELINE-MODELS] Set Selected Line And Sequence',
  SET_PIPELINE_MODEL_PRIORITY = '[PIPELINE-MODELS] Set Pipeline Model Priority',
  ACTIVATE_DRAFT = '[PIPELINE-MODELS] Activate Draft',
  ACTIVATE_DRAFT_SUCCESS = '[PIPELINE-MODELS] Activate Draft Success',
  ACTIVATE_DRAFT_FAILURE = '[PIPELINE-MODELS] Activate Draft Failure',
  CREATE_DRAFT = '[PIPELINE-MODELS] Create Draft',
  CREATE_DRAFT_SUCCESS = '[PIPELINE-MODELS] Create Draft Success',
  CREATE_DRAFT_FAILURE = '[PIPELINE-MODELS] Create Draft Failure',
  SAVE_DRAFT = '[PIPELINE-MODELS] Save Draft',
  SAVE_DRAFT_SUCCESS = '[PIPELINE-MODELS] Save Draft Success',
  SAVE_DRAFT_FAILURE = '[PIPELINE-MODELS] Save Draft Failure',
  DELETE_PIPELINE_MODEL = '[PIPELINE-MODELS] Delete Pipeline Model',
  DELETE_PIPELINE_MODEL_SUCCESS = '[PIPELINE-MODELS] Delete Pipeline Model Success',
  DELETE_PIPELINE_MODEL_FAILURE = '[PIPELINE-MODELS] Delete Pipeline Model Failure',
  CLEAR_DELETE_PIPELINE_MODEL_ERROR = '[PIPELINE-MODELS] Clear Delete Pipeline Model Error',
  FETCH_PIPELINE_MODEL_DELTA = '[PIPELINE-MODELS] Fetch Pipeline Model Delta',
  FETCH_PIPELINE_MODEL_DELTA_SUCCESS = '[PIPELINE-MODELS] Fetch Pipeline Model Delta Success',
  FETCH_PIPELINE_MODEL_DELTA_FAILURE = '[PIPELINE-MODELS] Fetch Pipeline Model Delta Failure',
  RESET_CREATE_AND_UPDATE_STATUSES = '[PIPELINE-MODELS] Reset Create And Update Statuses',
  FETCH_LOOKUP_DATA = '[PIPELINE_MODELS] Fetch Lookup Data',
  FETCH_LOOKUP_DATA_SUCCESS = '[PIPELINE_MODELS] Fetch Lookup Data Success',
  FETCH_LOOKUP_DATA_FAILURE = '[PIPELINE_MODELS] Fetch Lookup Data Failure',
  FETCH_LINE_SEQUENCES_BY_IDS = '[PIPELINE_MODELS] Fetch Line Sequences by IDs',
  FETCH_LINE_SEQUENCES_BY_IDS_SUCCESS = '[PIPELINE_MODELS] Fetch Line Sequences by IDs Success',
  FETCH_LINE_SEQUENCES_BY_IDS_FAILURE = '[PIPELINE_MODELS] Fetch Line Sequences by IDs Failure',
  FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS = '[PIPELINE-MODELS] Fetch Model Line Sequences by multiple ids',
  FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS_SUCCESS = '[PIPELINE-MODELS] Fetch Model Line Sequences by multiple ids Success',
  FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS_FAILURE = '[PIPELINE-MODELS] Fetch Model Line Sequences by multiple ids Failure',
  UPDATE_PIPELINE_MODEL = '[PIPELINE-MODELS] Update Pipeline Model',
  UPDATE_PIPELINE_MODEL_SUCCESS = '[PIPELINE-MODELS] Update Pipeline Model Success',
  UPDATE_PIPELINE_MODEL_FAILURE = '[PIPELINE-MODELS] Update Pipeline Model Failure',
}

export interface IFetchPipelineModelsPayload {
  pageSize: number;
  pageNumber: number;
  sortDescriptors: Array<SortDescriptor>;
  tspId: number;
  pipelineModelPriority?: Array<ModelStatuses>;
  associationType?: LineResultAssociationType;
  pipelineModelStatus?: string;
}

export class FetchPipelineModels extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.FETCH_PIPELINE_MODELS;

  constructor(public payload: IFetchPipelineModelsPayload) {
    super(payload);
  }
}

export class FetchPipelineModelsSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_PIPELINE_MODELS_SUCCESS;

  constructor(public payload: { pipelineModelCollection: PipelineModelCollection }) {}
}

export class FetchPipelineModelsFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_PIPELINE_MODELS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchModelLines extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINES;

  constructor(
    public payload: {
      modelId: number;
      tspId: number;
      areHistoricalModelLines: boolean;
      associationType?: LineResultAssociationType;
    }
  ) {
    super(payload);
  }
}

export class FetchModelLinesSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINES_SUCCESS;

  constructor(public payload: { modelLines: Array<ModelLine>; areHistoricalModelLines: boolean }) {}
}

export class FetchModelLinesFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchUnassociatedModelLines extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.FETCH_UNASSOCIATED_MODEL_LINES;

  constructor(public payload: { modelId: number; tspId: number }) {
    super(payload);
  }
}

export class FetchUnassociatedModelLinesSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_UNASSOCIATED_MODEL_LINES_SUCCESS;

  constructor(public payload: { unassociatedModelLines: Array<ModelLine> }) {}
}

export class FetchUnassociatedModelLinesFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_UNASSOCIATED_MODEL_LINES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchModelLineSequences implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES;

  constructor(public payload: { modelId: number; lineId: number }) {}
}

export class FetchModelLineSequencesSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_SUCCESS;

  constructor(public payload: { lineId: number; modelSequences: Array<ModelLineSequence> }) {}
}

export class FetchModelLineSequencesFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_FAILURE;

  constructor(public payload: { lineId: number; error: Error }) {}
}

export class FetchModelLineSequencesByIds implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS;

  constructor(
    public lineModels: Array<{
      modelId: number;
      lineId: number;
    }>
  ) {}
}

export class FetchModelLineSequencesByIdsSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS_SUCCESS;

  constructor(
    public payload: {
      lineId: number[];
      modelLineSequencesByIds: ModelLineSequence[];
    }
  ) {}
}

export class FetchModelLineSequencesByIdsFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS_FAILURE;

  constructor(public error: Error) {}
}

export class FetchModelSequenceLocations implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_SEQUENCE_LOCATIONS;

  constructor(public payload: { modelId: number; lineId: number; sequenceId: number }) {}
}

export class FetchModelSequenceLocationsSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_SEQUENCE_LOCATIONS_SUCCESS;

  constructor(
    public payload: {
      sequenceId: number;
      modelSequenceLocations: Array<ModelSequenceLocation>;
    }
  ) {}
}

export class FetchModelSequenceLocationsFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_MODEL_SEQUENCE_LOCATIONS_FAILURE;

  constructor(public payload: { sequenceId: number; error: Error }) {}
}

export class FetchAvailableModelSequenceLocations implements Action {
  public readonly type = EPipelineModelsActions.FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS;

  constructor(
    public payload: {
      tspId?: number;
      zoneId?: number;
      pipelineSystemId?: number;
      pmVersionId?: number;
      modelSequenceId?: number;
    }
  ) {}
}

export class FetchAvailableModelSequenceLocationsSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS_SUCCESS;

  constructor(public payload: { locations: Location[] }) {}
}

export class FetchAvailableModelSequenceLocationsFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class CreateLine implements Action {
  public readonly type = EPipelineModelsActions.CREATE_LINE;

  constructor(public payload: { modelLine: ModelLine }) {}
}

export class CreateLineSuccess implements Action {
  public readonly type = EPipelineModelsActions.CREATE_LINE_SUCCESS;
}

export class CreateLineFailure implements Action {
  public readonly type = EPipelineModelsActions.CREATE_LINE_FAILURE;

  constructor(public payload: { error: Error; validationError: EPipelineError }) {}
}

export class UpdateLine implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_LINE;

  constructor(public payload: { modelLine: ModelLine }) {}
}

export class UpdateLineSuccess implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_LINE_SUCCESS;
}

export class UpdateLineFailure implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_LINE_FAILURE;

  constructor(public payload: { error: Error; validationError: EPipelineError }) {}
}

export class CreateSequence extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.CREATE_SEQUENCE;

  constructor(
    public payload: {
      newSequence: ModelLineSequence;
      pipelineId: number;
      line: ModelLine;
    }
  ) {
    super(payload);
  }
}

export class CreateSequenceSuccess implements Action {
  public readonly type = EPipelineModelsActions.CREATE_SEQUENCE_SUCCESS;
}

export class CreateSequenceFailure implements Action {
  public readonly type = EPipelineModelsActions.CREATE_SEQUENCE_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateSequence extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_SEQUENCE;

  constructor(
    public payload: {
      modelLineSequence: ModelLineSequence;
      pipelineId: number;
      line: ModelLine;
    }
  ) {
    super(payload);
  }
}

export class UpdateSequenceSuccess implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_SEQUENCE_SUCCESS;
}

export class UpdateSequenceFailure implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_SEQUENCE_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearSequenceValidationError implements Action {
  public readonly type = EPipelineModelsActions.CLEAR_SEQUENCE_VALIDATION_ERROR;
}

export class ResetSelectedLineAndSequence implements Action {
  public readonly type = EPipelineModelsActions.RESET_SELECTED_LINE_AND_SEQUENCE;
}

export class SetSelectedLineAndSequence implements Action {
  public readonly type = EPipelineModelsActions.SET_SELECTED_LINE_AND_SEQUENCE;

  constructor(
    public payload: {
      selectedModelLine: ModelLine;
      selectedModelLineSequence: ModelLineSequence;
    }
  ) {}
}

export class FetchPipelineModelsAndSavePriority implements Action {
  public readonly type = EPipelineModelsActions.SET_PIPELINE_MODEL_PRIORITY;

  constructor(public payload: IFetchPipelineModelsPayload) {}
}

export class ActivateDraft implements Action {
  public readonly type = EPipelineModelsActions.ACTIVATE_DRAFT;

  constructor(public payload: { pipelineModel: PipelineModel }) {}
}

export class ActivateDraftSuccess implements Action {
  public readonly type = EPipelineModelsActions.ACTIVATE_DRAFT_SUCCESS;
}

export class ActivateDraftFailure implements Action {
  public readonly type = EPipelineModelsActions.ACTIVATE_DRAFT_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class SaveDraft extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.SAVE_DRAFT;

  constructor(public payload: { pipelineModel: PipelineModel }) {
    super(payload);
  }
}

export class SaveDraftSuccess implements Action {
  public readonly type = EPipelineModelsActions.SAVE_DRAFT_SUCCESS;
}

export class SaveDraftFailure implements Action {
  public readonly type = EPipelineModelsActions.SAVE_DRAFT_FAILURE;

  constructor(public payload: { error: Error }) {}
}
export class CreateDraft extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.CREATE_DRAFT;

  constructor(
    public payload: {
      tspId: number;
      modelId: number;
    }
  ) {
    super(payload);
  }
}

export class CreateDraftSuccess implements Action {
  public readonly type = EPipelineModelsActions.CREATE_DRAFT_SUCCESS;

  constructor(
    public payload: {
      pipelineModel: PipelineModel;
    }
  ) {}
}

export class CreateDraftFailure implements Action {
  public readonly type = EPipelineModelsActions.CREATE_DRAFT_FAILURE;

  constructor(
    public payload: {
      error: Error;
    }
  ) {}
}

export class DeletePipelineModel implements Action {
  public readonly type = EPipelineModelsActions.DELETE_PIPELINE_MODEL;

  constructor(public payload: { tspId: number; pipelineModel: PipelineModel }) {}
}

export class DeletePipelineModelSuccess implements Action {
  public readonly type = EPipelineModelsActions.DELETE_PIPELINE_MODEL_SUCCESS;
}

export class DeletePipelineModelFailure implements Action {
  public readonly type = EPipelineModelsActions.DELETE_PIPELINE_MODEL_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearDeletePipelineModelError implements Action {
  public readonly type = EPipelineModelsActions.CLEAR_DELETE_PIPELINE_MODEL_ERROR;
}

export class ClearHistoricalPipelineModel implements Action {
  public readonly type = EPipelineModelsActions.CLEAR_HISTORICAL_PIPELINE_MODEL;
}

export class SetHistoricalPipelineModel implements Action {
  public readonly type = EPipelineModelsActions.SET_HISTORICAL_PIPELINE_MODEL;

  constructor(
    public payload: {
      pipelineModel: PipelineModel;
      tspId: number;
      areHistoricalModelLines: boolean;
      associationType?: LineResultAssociationType;
    }
  ) {}
}

export class FetchPipelineModelDelta implements Action {
  public readonly type = EPipelineModelsActions.FETCH_PIPELINE_MODEL_DELTA;

  constructor(public payload: { pipelineModelId: number; compareToPipelineModelId: number }) {}
}

export class FetchPipelineModelDeltaSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_PIPELINE_MODEL_DELTA_SUCCESS;

  constructor(public payload: { pipelineModelDelta: PipelineModelDelta }) {}
}

export class FetchPipelineModelDeltaFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_PIPELINE_MODEL_DELTA_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ResetCreateAndUpdateStatuses implements Action {
  public readonly type = EPipelineModelsActions.RESET_CREATE_AND_UPDATE_STATUSES;
}

export class FetchLookupData extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.FETCH_LOOKUP_DATA;
}

export class FetchLookupDataSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_LOOKUP_DATA_SUCCESS;

  constructor(public payload: { lookupData: LookupData }) {}
}

export class FetchLookupDataFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_LOOKUP_DATA_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchLineSequencesByIds extends RetryableAction implements Action {
  public readonly type = EPipelineModelsActions.FETCH_LINE_SEQUENCES_BY_IDS;

  constructor(
    public payload: {
      lineSequenceIds: Array<number>;
      locationIds?: Array<number>;
      pmVersionId?: number;
    }
  ) {
    super(payload);
  }
}

export class FetchLineSequencesByIdsSuccess implements Action {
  public readonly type = EPipelineModelsActions.FETCH_LINE_SEQUENCES_BY_IDS_SUCCESS;

  constructor(public payload: { lineSequenceDetails: Array<LineSequenceDetail> }) {}
}

export class FetchLineSequencesByIdsFailure implements Action {
  public readonly type = EPipelineModelsActions.FETCH_LINE_SEQUENCES_BY_IDS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdatePipelineModel implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_PIPELINE_MODEL;

  constructor(public payload: { pipelineModel: PipelineModel }) {}
}

export class UpdatePipelineModelSuccess implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_PIPELINE_MODEL_SUCCESS;
}

export class UpdatePipelineModelFailure implements Action {
  public readonly type = EPipelineModelsActions.UPDATE_PIPELINE_MODEL_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export type PipelineModelsActions =
  | FetchPipelineModels
  | FetchPipelineModelsSuccess
  | FetchPipelineModelsFailure
  | ClearHistoricalPipelineModel
  | SetHistoricalPipelineModel
  | FetchModelLines
  | FetchModelLinesSuccess
  | FetchModelLinesFailure
  | FetchUnassociatedModelLines
  | FetchUnassociatedModelLinesSuccess
  | FetchUnassociatedModelLinesFailure
  | FetchModelLineSequences
  | FetchModelLineSequencesSuccess
  | FetchModelLineSequencesFailure
  | FetchModelSequenceLocations
  | FetchModelSequenceLocationsSuccess
  | FetchModelSequenceLocationsFailure
  | FetchAvailableModelSequenceLocations
  | FetchAvailableModelSequenceLocationsSuccess
  | FetchAvailableModelSequenceLocationsFailure
  | CreateLine
  | CreateLineFailure
  | CreateLineSuccess
  | UpdateLine
  | UpdateLineSuccess
  | UpdateLineFailure
  | CreateSequence
  | CreateSequenceFailure
  | CreateSequenceSuccess
  | UpdateSequence
  | UpdateSequenceSuccess
  | UpdateSequenceFailure
  | ClearSequenceValidationError
  | ResetSelectedLineAndSequence
  | SetSelectedLineAndSequence
  | FetchPipelineModelsAndSavePriority
  | ActivateDraft
  | ActivateDraftSuccess
  | ActivateDraftFailure
  | SaveDraft
  | SaveDraftSuccess
  | SaveDraftFailure
  | CreateDraft
  | CreateDraftSuccess
  | CreateDraftFailure
  | DeletePipelineModel
  | DeletePipelineModelSuccess
  | DeletePipelineModelFailure
  | ClearDeletePipelineModelError
  | FetchPipelineModelDelta
  | FetchPipelineModelDeltaSuccess
  | FetchPipelineModelDeltaFailure
  | ResetCreateAndUpdateStatuses
  | FetchLookupData
  | FetchLookupDataSuccess
  | FetchLookupDataFailure
  | FetchLineSequencesByIds
  | FetchLineSequencesByIdsSuccess
  | FetchLineSequencesByIdsFailure
  | FetchModelLineSequencesByIds
  | FetchModelLineSequencesByIdsFailure
  | FetchModelLineSequencesByIdsSuccess
  | UpdatePipelineModel
  | UpdatePipelineModelSuccess
  | UpdatePipelineModelFailure;
