import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { GasDay } from '@gms/nomination-api';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/app/app.state';
import { FetchCurrentGasDay } from 'app/store/nominations/nominations.actions';
import { selectCurrentGasDayOrError } from 'app/store/nominations/nominations.selectors';
import 'rxjs/add/operator/first';
import { Observable } from 'rxjs/Observable';
import { first, map, skip } from 'rxjs/operators';

export const CurrentGasDayResolver: ResolveFn<GasDay> = (): Observable<GasDay> | null => {
  const _store = inject(Store<IAppState>);
  const currentGasDaySuccessOrError$ = _store.pipe(
    select(selectCurrentGasDayOrError),
    skip(1),
    map(result => (result.error ? null : result.gasDay))
  );

  _store.dispatch(new FetchCurrentGasDay());
  return currentGasDaySuccessOrError$.pipe(first());
}
