<ng-template #listItemTemplate let-dataItem="dataItem">
  <div class="list-item-container">
    <span class="text" [attr.data-key]="dataItem?.text">{{ dataItem?.text }}</span>
    <span class="sub-text" [attr.data-key-subtext]="dataItem?.subText" *ngIf="dataItem?.value || dataItem?.value === 0">
      {{dataItem?.subText}}
    </span>
  </div>
</ng-template>

<ng-template #valueTemplate let-dataItem="dataItem">
  <span class="text" [attr.data-key]="dataItem?.text">{{ dataItem?.text }}</span>
</ng-template>

<ng-template #placeholderTemplate let-dataItem="dataItem">
  <span *ngIf="!dataItem || !dataItem.text" class="select-placeholder">{{ data.placeholder }}</span>
  <span *ngIf="dataItem && dataItem.text" class="text" [attr.data-key]="dataItem?.text">{{ dataItem?.text }}</span>
</ng-template>


<ng-template #normalItemTemplate let-dataItem="dataItem">
  <div class="single-item-container">
    <span class="text" [attr.data-key]="dataItem?.text">{{ dataItem?.text }}</span>
  </div>
</ng-template>

<app-dropdown

[id] = "data?.id"
[data] = "menuItems"
[defaultItem] = "data?.defaultItem"

[controlName]="data?.controlName"
[group]="data?.group"

[popupSettings] = "data?.popupSettings"
[valuePrimitive] = "data?.valuePrimitive"

[valueField]="data?.valueField"
[textField]="data?.textField"

[dropdownItemTemplate] = "menuItems && menuItems[0] && menuItems[0]?.subText ? listItemTemplate : normalItemTemplate "
[dropdownValueTemplate] = "data?.placeholder ? placeholderTemplate : menuItems && menuItems[0] && menuItems[0]?.subText ? valueTemplate : null"

[class.is-in-a-table] = "true"
isInATable = "true"
[tableOverflow] = "data?.tableOverflow"

[allowFreeFormInput] = "data?.allowFreeFormInput"

[filterable] = data?.filterable
[debounceTime] = data?.debounceTime
[minLength]=data?.minLength

[isLoading]="data?.isLoading || (data?.isLoading$ | async)"

(filterChanged)="filterChanged($event, data?.controlName)"
(valueChanged)="valueChanged($event)"
(dropDownFocused) = "dropDownFocused()"
(opened) = "opened()"

></app-dropdown>
