import { UntypedFormGroup } from '@angular/forms';
import { PopupSettings } from '@progress/kendo-angular-popup';
import get from 'lodash/get';
import { Observable } from 'rxjs';
import { MultiSelectItem } from 'shared/components/form/form-multi-select/form-multi-select.component';
import { DynamicMultiselectComponent } from 'shared/components/table/dynamic-cell-components/multiselect-cell/multiselect-cell.component';

export class DynamicMultiselectCell {
  dynamicComponentType: typeof DynamicMultiselectComponent;
  data: MultiselectOptions;
}

export class DynamicMultiselectCellMenuItem {
  text: string;
  templateSubtext?: string;
  tagSubtext?: string;
  value?: any;
  model?: object;
}

export interface MultiselectOptions {
  id?: string;
  data?: any;
  data$?: Observable<any>;

  // valueField?: string;  // valueField is value
  // textField?: string;  // textField is text

  controlName: string;
  group: UntypedFormGroup;

  valuePrimitive?: boolean;

  // typeahead options
  filterable?: boolean;
  debounceTime?: string;
  minLength?: number;
  numSelectionsBeforeCombined?: number;

  filterChanged?: (searchPhrase: string, control: string) => void;
  valueChanged?: Function;
  opened?: Function;
  multiselectFocused?: Function;

  retainDataObservable?: boolean;
  placeholder?: string;
  tableOverflow?: boolean;
  popupSettings?: PopupSettings;
  updateOnModelChange?: boolean;
}

export const createMultiselectCell = (
  multiselectOptions: MultiselectOptions
): DynamicMultiselectCell => {
  const controlName = multiselectOptions.controlName;

  const prependId = get(multiselectOptions, 'id') || controlName;
  const id = prependId + '_Multiselect';

  return {
    dynamicComponentType: DynamicMultiselectComponent,
    data: {
      ...multiselectOptions,
      id: id,
    },
  };
};
