import {
  ParkAndLoanCollection,
  StorageTransferDetail,
  StorageTransferDetailCollection,
  StorageTransferDetailPair,
  StorageTransferNnsOffer,
  StorageTransferTCETQCollection,
} from '@gms/imbalance-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';
import { EStorageTransferActions as StorageXferActionTypes } from 'app/store/storage-transfer/storage-transfer.utils';

export enum EStorageTransferActions {
  FETCH_STORAGE_TRANSFER_COLLECTION = '[STORAGE TRANSFER] Fetch Storage Transfer Collection',
  FETCH_STORAGE_TRANSFER_COLLECTION_SUCCESS = '[STORAGE TRANSFER] Fetch Storage Transfer Collection Success',
  FETCH_STORAGE_TRANSFER_COLLECTION_FAILURE = '[STORAGE TRANSFER] Fetch Storage Transfer Collection Failure',
  FETCH_STORAGE_TRANSFER_BY_ID = '[STORAGE TRANSFER] Fetch Storage Transfer By Id',
  FETCH_STORAGE_TRANSFER_BY_ID_SUCCESS = '[STORAGE TRANSFER] Fetch Storage Transfer By Id Success',
  FETCH_STORAGE_TRANSFER_BY_ID_FAILURE = '[STORAGE TRANSFER] Fetch Storage Transfer By Id Failure',
  CREATE_STORAGE_TRANSFER = '[STORAGE TRANSFER] Create a Storage Transfer',
  CREATE_STORAGE_TRANSFER_SUCCESS = '[STORAGE TRANSFER] Create a Storage Transfer Success',
  CREATE_STORAGE_TRANSFER_FAILURE = '[STORAGE TRANSFER] Create a Storage Transfer Failure',
  CLEAR_STORAGE_TRANSFER_ERROR = '[STORAGE TRANSFER] Clear create Storage Transfer',
  CREATE_STORAGE_TRANSFER_NNS = '[STORAGE TRANSFER] Create a Storage Transfer NNS',
  CREATE_STORAGE_TRANSFER_NNS_SUCCESS = '[STORAGE TRANSFER] Create a Storage Transfer NNS Success',
  CREATE_STORAGE_TRANSFER_NNS_FAILURE = '[STORAGE TRANSFER] Create a Storage Transfer NNS Failure',
  CLEAR_STORAGE_TRANSFER_NNS_ERROR = '[STORAGE TRANSFER] Clear create Storage NNS Transfer',
  UPDATE_STORAGE_TRANSFER = '[STORAGE TRANSFER] Update a Storage Transfer',
  UPDATE_STORAGE_TRANSFER_SUCCESS = '[STORAGE TRANSFER] Update a Storage Transfer Success',
  UPDATE_STORAGE_TRANSFER_FAILURE = '[STORAGE TRANSFER] Update a Storage Transfer Failure',
  SET_STORAGE_TRANSFER = '[STORAGE TRANSFER] Set a Storage Transfer',
  WITHDRAW_STORAGE_TRANSFER = '[STORAGE TRANSFER] Withdraw Storage Transfer',
  WITHDRAW_STORAGE_TRANSFER_SUCCESS = '[STORAGE TRANSFER] Withdraw Storage Transfer Success',
  WITHDRAW_STORAGE_TRANSFER_FAILURE = '[STORAGE TRANSFER] Withdraw Storage Transfer Failure',
  CLEAR_SELECTED_STORAGE_TRANSFER = '[STORAGE TRANSFER] Clear selected Storage Transfer',
  FETCH_PARK_AND_LOAN_COLLECTION = '[STORAGE TRANSFER] Fetch Park And Loan Collection',
  FETCH_PARK_AND_LOAN_COLLECTION_SUCCESS = '[STORAGE TRANSFER] Fetch Park And Loan Collection Success',
  FETCH_PARK_AND_LOAN_COLLECTION_FAILURE = '[STORAGE TRANSFER] Fetch Park And Loan Collection Failure',
  EXPORT_PARK_AND_LOAN_COLLECTION = '[STORAGE TRANSFER] Export Park And Loan Collection',
  EXPORT_PARK_AND_LOAN_COLLECTION_SUCCESS = '[STORAGE TRANSFER] Export Park And Loan Collection Success',
  EXPORT_PARK_AND_LOAN_COLLECTION_FAILURE = '[STORAGE TRANSFER] Export Park And Loan Collection Failure',
  FETCH_STORAGE_TCETQ_COLLECTION = '[STORAGE TRANSFER] Fetch Storage TCETQ Collection',
  FETCH_STORAGE_TCETQ_COLLECTION_SUCCESS = '[STORAGE TRANSFER] Fetch Storage TCETQ Collection Success',
  FETCH_STORAGE_TCETQ_COLLECTION_FAILURE = '[STORAGE TRANSFER] Fetch Storage TCETQ Collection Failure',
  FETCH_STORAGE_TRANSFERS_NNS_OFFERS = '[IMBALANCES] Fetch Storage Transfers NNS Offers',
  FETCH_STORAGE_TRANSFERS_NNS_OFFERS_SUCCESS = '[IMBALANCES] Fetch Storage Transfers NNS Offers Success',
  FETCH_STORAGE_TRANSFERS_NNS_OFFERS_ERROR = '[IMBALANCES] Fetch Storage Transfers NNS Offers Error',
}

export interface IStorageTransferCollectionPayload {
  tspId: number;
  accountPeriod: IAccountPeriod;
  svcReqNameId?: number;
  statusId?: number;
  sortDescriptors?: SortDescriptor[];
  pageSize?: number;
  pageNumber?: number;
}

export interface IParkAndLoanCollectionPayload {
  tspId: number;
  accountPeriod: IAccountPeriod;
  svcReqNameId?: number;
  svcReqK?: number;
  viewBy: 'Summary' | 'Detail';
  pageSize?: number;
  pageNumber?: number;
  dateBegin?: Date;
  dateEnd?: Date;
}

export interface IParkAndLoanCollectionRequest
  extends Omit<IParkAndLoanCollectionPayload, 'pageNumber' | 'pageSize'> {}

export interface IStorageTCETQPayload {
  tspId: number;
  annualPeriod: string;
  svcReqNameId?: number;
}

export class GetStorageTransferCollection implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFER_COLLECTION;

  constructor(public payload: IStorageTransferCollectionPayload) {}
}

export class GetStorageTransferCollectionSuccess implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFER_COLLECTION_SUCCESS;

  constructor(public payload: StorageTransferDetailCollection) {}
}

export class GetStorageTransferCollectionFailure implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFER_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateStorageTransfer implements Action {
  public readonly type = EStorageTransferActions.CREATE_STORAGE_TRANSFER;

  constructor(public payload: StorageTransferDetail) {}
}

export class CreateStorageTransferSuccess implements Action {
  public readonly type = EStorageTransferActions.CREATE_STORAGE_TRANSFER_SUCCESS;

  constructor(public payload: StorageTransferDetail) {}
}

export class CreateStorageTransferFailure implements Action {
  public readonly type = EStorageTransferActions.CREATE_STORAGE_TRANSFER_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearStorageTransferError implements Action {
  public readonly type = EStorageTransferActions.CLEAR_STORAGE_TRANSFER_ERROR;
}

export class CreateStorageTransferNNS implements Action {
  public readonly type = EStorageTransferActions.CREATE_STORAGE_TRANSFER_NNS;

  constructor(public payload: StorageTransferDetailPair) {}
}

export class CreateStorageTransferNNSSuccess implements Action {
  public readonly type = EStorageTransferActions.CREATE_STORAGE_TRANSFER_NNS_SUCCESS;

  constructor(public payload: StorageTransferDetailPair) {}
}

export class CreateStorageTransferNNSFailure implements Action {
  public readonly type = EStorageTransferActions.CREATE_STORAGE_TRANSFER_NNS_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearStorageTransferNNSError implements Action {
  public readonly type = EStorageTransferActions.CLEAR_STORAGE_TRANSFER_NNS_ERROR;
}

export class GetStorageTransferById implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFER_BY_ID;

  constructor(public payload: number) {}
}

export class GetStorageTransferByIdSuccess implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFER_BY_ID_SUCCESS;

  constructor(public payload: StorageTransferDetail) {}
}

export class GetStorageTransferByIdFailure implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFER_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateStorageTransfer implements Action {
  public readonly type = EStorageTransferActions.UPDATE_STORAGE_TRANSFER;

  constructor(
    public payload: { data: StorageTransferDetail; actionFlag: StorageXferActionTypes }
  ) {}
}

export class UpdateStorageTransferSuccess implements Action {
  public readonly type = EStorageTransferActions.UPDATE_STORAGE_TRANSFER_SUCCESS;

  constructor(public payload: StorageTransferDetail) {}
}

export class UpdateStorageTransferFailure implements Action {
  public readonly type = EStorageTransferActions.UPDATE_STORAGE_TRANSFER_FAILURE;

  constructor(public payload: Error) {}
}

export class SetStorageTransfer implements Action {
  public readonly type = EStorageTransferActions.SET_STORAGE_TRANSFER;

  constructor(public payload: any) {}
}

export class WithdrawStorageTransfer implements Action {
  public readonly type = EStorageTransferActions.WITHDRAW_STORAGE_TRANSFER;

  constructor(public payload: { storageTransferId: number; accountingPeriod: string }) {}
}

export class WithdrawStorageTransferSuccess implements Action {
  public readonly type = EStorageTransferActions.WITHDRAW_STORAGE_TRANSFER_SUCCESS;

  constructor() {}
}

export class WithdrawStorageTransferFailure implements Action {
  public readonly type = EStorageTransferActions.WITHDRAW_STORAGE_TRANSFER_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearSelectedStorageTransfer implements Action {
  public readonly type = EStorageTransferActions.CLEAR_SELECTED_STORAGE_TRANSFER;
}

export class FetchParkAndLoanCollection implements Action {
  public readonly type = EStorageTransferActions.FETCH_PARK_AND_LOAN_COLLECTION;

  constructor(public payload: IParkAndLoanCollectionPayload) {}
}

export class FetchParkAndLoanCollectionSuccess implements Action {
  public readonly type = EStorageTransferActions.FETCH_PARK_AND_LOAN_COLLECTION_SUCCESS;

  constructor(public payload: ParkAndLoanCollection) {}
}

export class FetchParkAndLoanCollectionFailure implements Action {
  public readonly type = EStorageTransferActions.FETCH_PARK_AND_LOAN_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportParkAndLoanCollection implements Action {
  public readonly type = EStorageTransferActions.EXPORT_PARK_AND_LOAN_COLLECTION;

  constructor(public payload: IParkAndLoanCollectionRequest) {}
}

export class ExportParkAndLoanCollectionSuccess implements Action {
  public readonly type = EStorageTransferActions.EXPORT_PARK_AND_LOAN_COLLECTION_SUCCESS;

  constructor() {}
}

export class ExportParkAndLoanCollectionFailure implements Action {
  public readonly type = EStorageTransferActions.EXPORT_PARK_AND_LOAN_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchStorageTCETQCollection implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TCETQ_COLLECTION;

  constructor(public payload: IStorageTCETQPayload) {}
}

export class FetchStorageTCETQCollectionSuccess implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TCETQ_COLLECTION_SUCCESS;

  constructor(public payload: StorageTransferTCETQCollection) {}
}

export class FetchStorageTCETQCollectionFailure implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TCETQ_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchStorageTransferNNSOffers implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFERS_NNS_OFFERS;

  constructor(
    public payload: {
      accountingPeriod: string;
      tspId: number;
      entityId: number;
    }
  ) {}
}

export class FetchStorageTransferNNSOffersSuccess implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFERS_NNS_OFFERS_SUCCESS;

  constructor(public payload: { offers: Array<StorageTransferNnsOffer> }) {}
}

export class FetchStorageTransferNNSOffersFailure implements Action {
  public readonly type = EStorageTransferActions.FETCH_STORAGE_TRANSFERS_NNS_OFFERS_ERROR;

  constructor(public payload: Error) {}
}

export type StorageTransferActions =
  | GetStorageTransferCollection
  | GetStorageTransferCollectionSuccess
  | GetStorageTransferCollectionFailure
  | CreateStorageTransfer
  | CreateStorageTransferSuccess
  | CreateStorageTransferFailure
  | ClearStorageTransferError
  | UpdateStorageTransfer
  | UpdateStorageTransferSuccess
  | UpdateStorageTransferFailure
  | SetStorageTransfer
  | GetStorageTransferById
  | GetStorageTransferByIdSuccess
  | GetStorageTransferByIdFailure
  | WithdrawStorageTransfer
  | WithdrawStorageTransferSuccess
  | WithdrawStorageTransferFailure
  | ClearSelectedStorageTransfer
  | FetchParkAndLoanCollection
  | FetchParkAndLoanCollectionSuccess
  | FetchParkAndLoanCollectionFailure
  | ExportParkAndLoanCollection
  | ExportParkAndLoanCollectionSuccess
  | ExportParkAndLoanCollectionFailure
  | FetchStorageTCETQCollection
  | FetchStorageTCETQCollectionSuccess
  | FetchStorageTCETQCollectionFailure
  | FetchStorageTCETQCollectionFailure
  | CreateStorageTransferNNS
  | CreateStorageTransferNNSSuccess
  | CreateStorageTransferNNSFailure
  | ClearStorageTransferNNSError
  | FetchStorageTransferNNSOffers
  | FetchStorageTransferNNSOffersSuccess
  | FetchStorageTransferNNSOffersFailure;
