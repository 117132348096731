import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Pipeline_Model_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/market-sector-assignments-view/market-sector-overrides-view.module').then(
        m => m.MarketSectorOverridesViewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/market-sector-assignments',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Scheduling_Order_Set_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/scheduling-order-set/scheduling-order-set.module').then(
        m => m.SchedulingOrderSetModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/scheduling-engine',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/scheduling-order-set-update/scheduling-order-set-update.module').then(
        m => m.SchedulingOrderSetUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/scheduling-engine',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/scheduling-order-set-update/scheduling-order-set-update.module').then(
        m => m.SchedulingOrderSetUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/scheduling-engine',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Location_Groups_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/location-groups/location-groups.module').then(m => m.LocationGroupsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  // keeping this here for compatibility reasons. We should use the one underneath this one, but I'm not sure if any other place (emails?) points to this route.
  {
    path: 'edit',
    loadChildren: () =>
      import('./pages/location-group-update/location-group-update.module').then(
        m => m.LocationGroupUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/location-group-update/location-group-update.module').then(
        m => m.LocationGroupUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/location-group-update/location-group-update.module').then(
        m => m.LocationGroupUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new-criteria',
    loadChildren: () =>
      import('./pages/location-group-criteria/location-group-criteria.module').then(
        m => m.LocationGroupCriteriaModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'edit-criteria/:id',
    loadChildren: () =>
      import('./pages/location-group-criteria/location-group-criteria.module').then(
        m => m.LocationGroupCriteriaModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/location-group/location-group.module').then(m => m.LocationGroupModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'criteria-based/:id',
    loadChildren: () =>
      import('./pages/location-group-criteria-based/location-group-criteria-based.module').then(
        m => m.LocationGroupCriteriaBasedModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/location-groups',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Lines_Routes: Routes = [
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/pipeline-model/pages/line-update/line-update.module').then(
        m => m.LineUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/lines',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':pipelineModelId/:modelLineId/edit',
    loadChildren: () =>
      import('app/modules/pipeline-model/pages/line-update/line-update.module').then(
        m => m.LineUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/lines',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':modelLineId/edit',
    loadChildren: () =>
      import('app/modules/pipeline-model/pages/line-update/line-update.module').then(
        m => m.LineUpdateModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/lines',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Sequences_Routes: Routes = [
  {
    path: 'new',
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/sequences',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
    loadChildren: () =>
      import('app/modules/pipeline-model/pages/sequence-update/sequence-update.module').then(
        m => m.SequenceUpdateModule
      ),
  },
  {
    path: 'edit',
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/sequences',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
    loadChildren: () =>
      import('app/modules/pipeline-model/pages/sequence-update/sequence-update.module').then(
        m => m.SequenceUpdateModule
      ),
  },
];

export const Contractual_Rights_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/contractual-rights/contractual-rights.module').then(
        m => m.ContractualRightsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contractual-rights',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];

export const Locations_Routes: Routes = [
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/view-locations/location/location.module').then(m => m.LocationModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/locations',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('./pages/view-locations/location/location.module').then(m => m.LocationModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/locations',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/view-locations/locations/locations.module').then(m => m.LocationsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/locations',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];
