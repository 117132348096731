import {
  AgencyCollection,
  AgencyHeaderCollection,
  AgencyRelationship,
  AgencyRelationshipWithEntities,
  AgencyRoleMap,
} from '@gms/entity-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ICacheableRequestAction } from '../app/app.models';
import { EAgencyStatus } from '../entities/entities.models';

export enum EAgencyActions {
  CREATE_AGENCY = '[AGENCY] Create Agency',
  CREATE_AGENCY_SUCCESS = '[AGENCY] Create Agency Success',
  CREATE_AGENCY_FAILURE = '[AGENCY] Create Agency Failure',
  SEARCH_AGENCIES = '[AGENCY] Search Agencies',
  SEARCH_AGENCIES_SUCCESS = '[AGENCY] Search Agencies Success',
  SEARCH_AGENCIES_FAILURE = '[AGENCY] Search Agencies Failure',
  UPDATE_AGENCY = '[AGENCY] Update Agency',
  UPDATE_AGENCY_SUCCESS = '[AGENCY] Update Agency Success',
  UPDATE_AGENCY_FAILURE = '[AGENCY] Update Agency Failure',
  CLEAR_AGENCY_FAILURE = '[AGENCY] Clear Agency Failure',
  FETCH_AGENCY_BY_ID = '[AGENCY] Fetch Agency by ID',
  FETCH_AGENCY_BY_ID_SUCCESS = '[AGENCY] Fetch Agency by ID Success',
  FETCH_AGENCY_BY_ID_FAILURE = '[AGENCY] Fetch Agency by ID Failure',
  FETCH_AGENCY_ROLES = '[AGENCY] Fetch Agency Roles',
  FETCH_AGENCY_ROLES_SUCCESS = '[AGENCY] Fetch Agency Roles Success',
  FETCH_AGENCY_ROLES_FAILURE = '[AGENCY] Fetch Agency Roles Failure',
  FETCH_AGENCY_HEADERS = '[AGENCY] Fetch Agency Headers',
  FETCH_AGENCY_HEADERS_SUCCESS = '[AGENCY] Fetch Agency Headers Success',
  FETCH_AGENCY_HEADERS_FAILURE = '[AGENCY] Fetch Agency Headers Failure',
  CLEAR_AGENCY_HEADERS = '[AGENCY] Clear Agency Headers',
}

export class CreateAgency implements Action {
  public readonly type = EAgencyActions.CREATE_AGENCY;

  constructor(public payload: AgencyRelationship) {}
}

export class CreateAgencySuccess implements Action {
  public readonly type = EAgencyActions.CREATE_AGENCY_SUCCESS;

  constructor() {}
}

export class CreateAgencyFailure implements Action {
  public readonly type = EAgencyActions.CREATE_AGENCY_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearAgencyFailure implements Action {
  public readonly type = EAgencyActions.CLEAR_AGENCY_FAILURE;

  constructor() {}
}

export class SearchAgencies implements Action {
  public readonly type = EAgencyActions.SEARCH_AGENCIES;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortBy?: SortDescriptor[];
      agencyIds?: number[];
      searchPhrase?: string;
      agentIds?: number[];
      principalIds?: number[];
      status?: EAgencyStatus;
    }
  ) {}
}

export class SearchAgenciesSuccess implements Action {
  public readonly type = EAgencyActions.SEARCH_AGENCIES_SUCCESS;

  constructor(public payload: AgencyCollection) {}
}

export class SearchAgenciesFailure implements Action {
  public readonly type = EAgencyActions.SEARCH_AGENCIES_FAILURE;

  constructor(public payload: Error) {}
}
export class UpdateAgency implements Action {
  public readonly type = EAgencyActions.UPDATE_AGENCY;

  constructor(public payload: AgencyRelationship) {}
}

export class UpdateAgencySuccess implements Action {
  public readonly type = EAgencyActions.UPDATE_AGENCY_SUCCESS;

  constructor() {}
}

export class UpdateAgencyFailure implements Action {
  public readonly type = EAgencyActions.UPDATE_AGENCY_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchAgencyById implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_BY_ID;

  constructor(public payload: number) {}
}

export class FetchAgencyByIdSuccess implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_BY_ID_SUCCESS;

  constructor(public payload: AgencyRelationshipWithEntities) {}
}

export class FetchAgencyByIdFailure implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchAgencyRoles implements ICacheableRequestAction {
  public readonly type = EAgencyActions.FETCH_AGENCY_ROLES;

  constructor(public forceRefresh: boolean = false) {}
}

export class FetchAgencyRolesSuccess implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_ROLES_SUCCESS;

  constructor(public payload: AgencyRoleMap[]) {}
}

export class FetchAgencyRolesFailure implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_ROLES_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchAgencyHeaders implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_HEADERS;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortBy?: SortDescriptor[];
      searchPhrase?: string;
      agentIds?: number[];
      principalIds?: number[];
      status?: EAgencyStatus;
    }
  ) {}
}

export class FetchAgencyHeadersSuccess implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_HEADERS_SUCCESS;

  constructor(public payload: { agencyHeaderCollection: AgencyHeaderCollection }) {}
}

export class FetchAgencyHeadersFailure implements Action {
  public readonly type = EAgencyActions.FETCH_AGENCY_HEADERS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearAgencyHeaders implements Action {
  public readonly type = EAgencyActions.CLEAR_AGENCY_HEADERS;

  constructor() {}
}

export type AgencyActions =
  | CreateAgency
  | CreateAgencySuccess
  | CreateAgencyFailure
  | ClearAgencyFailure
  | SearchAgencies
  | SearchAgenciesSuccess
  | SearchAgenciesFailure
  | UpdateAgency
  | UpdateAgencySuccess
  | UpdateAgencyFailure
  | FetchAgencyById
  | FetchAgencyByIdSuccess
  | FetchAgencyByIdFailure
  | FetchAgencyRoles
  | FetchAgencyRolesSuccess
  | FetchAgencyRolesFailure
  | FetchAgencyHeaders
  | FetchAgencyHeadersSuccess
  | FetchAgencyHeadersFailure
  | ClearAgencyHeaders;
