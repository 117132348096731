import { Injectable } from '@angular/core';

import {
  NotificationRef,
  NotificationService,
  NotificationSettings,
} from '@progress/kendo-angular-notification';
import { isNullOrUndefined } from 'shared/utils/type.utils';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private notificationsArray = [];

  // UX confirmed that the behaviour is for Success Toasts, which are set to auto close, should do so after 5 seconds
  // Information about error toasts can be found here:
  // https://boardwalkpp.atlassian.net/wiki/spaces/GMS/pages/80576513/Error+Handling+Validation+Patterns
  private displayDuration = 5000;

  constructor(private _notificationService: NotificationService) {}

  success(message, closable?: boolean) {
    this.notificationsArray.push(
      this._notificationService.show({
        content: message,
        animation: { type: 'slide', duration: 400 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'success', icon: true },
        hideAfter: this.displayDuration,
        closable: closable,
      })
    );
  }

  info(content, showIcon = true, closable = false) {
    const notificationSettings: NotificationSettings = this.createNotification(
      content,
      showIcon,
      closable,
      'info'
    );

    return this._notificationService.show(notificationSettings);
  }

  showError(content, showIcon = true, closable = false): NotificationRef {
    if (isNullOrUndefined(content)) return;
    const notificationSettings: NotificationSettings = this.createNotification(
      content,
      showIcon,
      closable,
      'error'
    );

    return this._notificationService.show(notificationSettings);
  }

  showWarning(content, showIcon = true, closable = false): NotificationRef {
    if (isNullOrUndefined(content)) return;
    const notificationSettings: NotificationSettings = this.createNotification(
      content,
      showIcon,
      closable,
      'warning'
    );

    return this._notificationService.show(notificationSettings);
  }

  createNotification(content, showIcon, closable, style) {
    const notificationSettings: NotificationSettings = {
      content: content,
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: style, icon: showIcon },
      closable: closable,
    };
    if (!closable) {
      notificationSettings['hideAfter'] = this.displayDuration;
    }

    return notificationSettings;
  }

  setDisplayDuration(duration) {
    this.displayDuration = duration;
  }
}
