<app-popover
  useArrow="true"
  [displayOnHover]="data?.displayOnHover"
  [horizontalLeftOffset]="data?.horizontalLeftOffset ? data.horizontalLeftOffset : 0"
  [verticalTopOffset]="data?.verticalTopOffset ? data.verticalTopOffset : 0"
  [horizontalAlignment]="
    data?.horizontalAlignment ? data.horizontalAlignment : popoverHorizontalAlignment.Center
  "
  [verticalAlignment]="
    data?.verticalAlignment ? data.verticalAlignment : popoverVerticalAlignment.Top
  "
  [anchorVerticalAlignment]="
    data?.anchorVerticalAlignment ? data.anchorVerticalAlignment : popoverVerticalAlignment.Bottom
  "
  [anchorHorizontalAlignment]="
    data?.anchorHorizontalAlignment
      ? data.anchorHorizontalAlignment
      : popoverHorizontalAlignment.Right
  "
  [animationDirection]="
    data?.animationDirection ? data.animationDirection : popoverAnimationDirection.Down
  "

>
  <div class="cell-text-wrapper">
    <div class="cell-text" *ngIf="!data.switchTextPosition">
      <span *ngIf="data?.cellText">{{ data.cellText }}</span>
    </div>
    <div>
      <i [ngClass]="[
        (data?.iconClass ? data.iconClass : ICON_CLASSES.INFO),
        (data?.iconColor ? data.iconColor : COLORS.DARK_BLUE)]"></i>
    </div>
    <div class="cell-text" *ngIf="data.switchTextPosition">
      <span *ngIf="data?.cellText">{{ data.cellText }}</span>
    </div>
  </div>

  <popover-content class="popup-content">
    <span class="inline" *ngIf="data?.popOverTextList">
      <i
        *ngIf="!data?.hidePopoverIcon"
        [ngClass]="[
        (data?.iconClass ? data.iconClass : ICON_CLASSES.INFO),
        (data?.iconColor ? data.iconColor : COLORS.DARK_BLUE)]"
      ></i>
      <h6 [class.no-title-padding]="data?.noTitlePadding">{{ data?.popOverTitle }}</h6>
    </span>

    <ng-container *ngIf="data?.popOverTextList">
      <div class="text-container" *ngFor="let text of data.popOverTextList">
        <span *ngIf="text?.title" class="text-title">{{ text.title }}:&nbsp;</span>
        <span *ngIf="text?.text" class="text">{{ text.text }}</span>
      </div>
    </ng-container>
  </popover-content>
</app-popover>
