import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { IPipelineModelsState } from './pipeline-models.state';

export const selectPipelineModelsState = (state: IAppState) => state.pipelineModelsState;

export const selectError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.error
);

export const selectModelLines = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelLines
);

export const selectModelSequences = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelSequences
);

export const selectModelSequencesErrorForLine = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelSequencesErrorForLine
);

export const selectModelSequencesLoadingForLine = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelSequencesLoadingForLine
);

export const selectModelSequenceLocations = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelSequenceLocations
);

export const selectModelSequenceLocationsErrorForSequence = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelSequenceLocationsErrorForSequence
);

export const selectModelSequenceLocationsLoadingForSequence = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelSequenceLocationsLoadingForSequence
);

export const selectLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.loading
);

export const selectPipelineModels = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.pipelineModels
);

export const selectPipelineModelPriority = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.pipelineModelPriority
);

export const selectCreateLineLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.createLine.loading
);

export const selectCreateLineValidationError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.createLine.validationError
);

export const selectUpdateLineLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.updateLine.loading
);

export const selectUpdateLineValidationError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.updateLine.validationError
);

export const selectCreateSequenceStatus = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.createSequence
);

export const selectUpdateSequenceStatus = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.updateSequence
);

export const selectModelLinesLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelLinesLoading
);

export const selectModelLinesError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelLinesError
);

export const selectActivateDraftLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.activateDraft.loading
);

export const selectActivateDraftError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.activateDraft.httpError
);

export const selectActivateDraftStatus = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.activateDraft
);

export const selectSaveDraftLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.saveDraft.loading
);

export const selectSaveDraftError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.saveDraft.httpError
);

export const selectCreateDraftLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.createDraft.loading
);

export const selectCreateDraftError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.createDraft.httpError
);

export const selectDeletePipelineModelStatus = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.deletePipelineModel
);

export const selectDeletePipelineModelLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.deletePipelineModel.loading
);

export const selectDeletePipelineModelError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.deletePipelineModel.httpError
);

export const selectPagination = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.pagination
);

export const selectSortDescriptors = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.sortDescriptors
);

export const selectTotalPipelineCount = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.totalPipelineCount
);

export const selectSelectedElements = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.selectedElements
);

export const selectUnassociatedModelLines = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.unassociatedModelLines
);

export const selectUnassociatedModelLinesLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.unassociatedModelLinesStatus.loading
);

export const selectUnassociatedModelLinesError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.unassociatedModelLinesStatus.httpError
);

export const selectHistoricalPipelineModel = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.historicalPipelineModel
);

export const selectHistoricalModelLines = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.historicalModelLines
);

export const selectPipelineModelDelta = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.pipelineModelDelta
);

export const selectPipelineModelDeltaLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.pipelineModelDelta.loading
);

export const selectPipelineModelDeltaError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.pipelineModelDelta.httpError
);

export const selectLookupData = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.lookupData
);

export const selectLookupDataLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.lookupData.loading
);

export const selectLookupDataError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.lookupData.httpError
);

export const selectLineSequenceDetails = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.lineSequenceDetails
);

export const selectLineSequenceDetailsLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.lineSequenceDetails.loading
);

export const selectLineSequenceDetailsError = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.lineSequenceDetails.httpError
);

export const selectLineSequencesByLineIds = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.modelLineSequencesByLineIds
);

export const selectLineSequencesByLineIdsIsLoading = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.status.modelLineSequencesByLineIds.loading
);

export const selectavailableModelSequenceLocations = createSelector(
  selectPipelineModelsState,
  (state: IPipelineModelsState) => state.availableModelSequenceLocations
);
