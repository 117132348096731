import { ERateContractActions, RateContractActions } from './rate-contract.actions';
import { initialRateContractState, IRateContractState } from './rate-contract.state';

export function rateContractReducers(
  state = initialRateContractState,
  action: RateContractActions
): IRateContractState {
  switch (action.type) {
    case ERateContractActions.ADD_NON_TARIFF_RATE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: true,
        },
        nonTariffRateError: null,
      };

    case ERateContractActions.ADD_NON_TARIFF_RATE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: false,
        },
        nonTariffRateError: action.error,
      };

    case ERateContractActions.ADD_NON_TARIFF_RATE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: false,
        },
        nonTariffRate: action.payload,
        nonTariffRateError: null,
      };

    case ERateContractActions.DELETE_NON_TARIFF_RATE:
      return {
        ...state,
        error: {
          ...state.error,
          nonTariffRateDelete: null,
        },
        loading: {
          ...state.loading,
          nonTariffRateDelete: true,
        },
        nonTariffRateError: null,
      };

    case ERateContractActions.DELETE_NON_TARIFF_RATE_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          nonTariffRateDelete: action.error,
        },
        loading: {
          ...state.loading,
          nonTariffRateDelete: false,
        },
      };

    case ERateContractActions.DELETE_NON_TARIFF_RATE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRateDelete: false,
        },
        nonTariffRateDelete: action.payload,
      };

    case ERateContractActions.UPDATE_NON_TARIFF_RATE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: true,
        },
        nonTariffRateError: null,
      };

    case ERateContractActions.UPDATE_NON_TARIFF_RATE_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: false,
        },
        nonTariffRate: action.payload,
        nonTariffRateError: null,
      };

    case ERateContractActions.UPDATE_NON_TARIFF_RATE_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: false,
        },
        nonTariffRateError: action.error,
      };

    case ERateContractActions.FETCH_RATE_DICTIONARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          rateDictionary: true,
        },
        rateDictionaryError: null,
      };

    case ERateContractActions.FETCH_RATE_DICTIONARY_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          rateDictionary: false,
        },
        rateDictionaryError: action.error,
      };

    case ERateContractActions.FETCH_RATE_DICTIONARY_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          rateDictionary: false,
        },
        rateDictionaryError: null,
        rateDictionary: action.payload,
      };

    case ERateContractActions.FETCH_NON_TARIFF_RATES:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRates: true,
        },
        nonTariffRatesError: null,
      };

    case ERateContractActions.FETCH_NON_TARIFF_RATES_SUCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRates: false,
        },
        nonTariffRatesError: null,
        nonTariffRates: action.payload.nonTariffRates,
      };

    case ERateContractActions.FETCH_NON_TARIFF_RATES_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRates: false,
        },
        nonTariffRatesError: action.error,
      };

    case ERateContractActions.FETCH_NON_TARIFF_RATES_DETAILS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRatesDetails: true,
        },
        nonTariffRatesDetailsError: null,
      };

    case ERateContractActions.FETCH_NON_TARIFF_RATES_DETAILS_SUCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRatesDetails: false,
        },
        nonTariffRatesDetailsError: null,
        nonTariffRatesDetails: action.payload.nonTariffRatesDetails,
      };

    case ERateContractActions.FETCH_NON_TARIFF_RATES_DETAILS_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRatesDetails: false,
        },
        nonTariffRatesDetailsError: action.error,
      };

    case ERateContractActions.FETCH_NON_TARIFF_DETAILS_GRID:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffDetailsGrid: true,
        },
        nonTariffDetailsGridError: null,
      };

    case ERateContractActions.FETCH_NON_TARIFF_DETAILS_GRID_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffDetailsGrid: false,
        },
        nonTariffDetailsGridError: null,
        nonTariffDetailsGrid: action.payload.nonTariffDetailsGrid,
      };

    case ERateContractActions.FETCH_NON_TARIFF_DETAILS_GRID_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffDetailsGrid: false,
        },
        nonTariffDetailsGridError: action.error,
      };

    case ERateContractActions.FETCH_NON_TARIFF_BY_ID:
    case ERateContractActions.FETCH_NON_TARIFF_BY_ID_FOR_EDIT:
      return {
        ...state,
        nonTariffRate: null,
        error: {
          ...state.error,
          nonTariffRateDelete: null,
        },
        loading: {
          ...state.loading,
          nonTariffRate: true,
        },
        nonTariffRateError: null,
      };

    case ERateContractActions.FETCH_NON_TARIFF_BY_ID_SUCCESS:
    case ERateContractActions.FETCH_NON_TARIFF_BY_ID_FOR_EDIT_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: false,
        },
        nonTariffRateError: null,
        nonTariffRate: action.payload,
      };

    case ERateContractActions.FETCH_NON_TARIFF_BY_ID_FAILURE:
    case ERateContractActions.FETCH_NON_TARIFF_BY_ID_FOR_EDIT_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nonTariffRate: false,
        },
        nonTariffRateError: action.error,
      };

    case ERateContractActions.RESET_CONTRACTUAL_RATE:
      return {
        ...state,
        nonTariffRate: null,
        nonTariffRateError: null,
      };

    case ERateContractActions.CLEAR_EXPORT_CONTRACTUAL_RATES:
      return {
        ...state,
        exportContractualRates: null,
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRates: null,
        },
        loading: {
          ...state.loading,
          exportContractualRates: true,
        },
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRates: null,
        },
        loading: {
          ...state.loading,
          exportContractualRates: false,
        },
        exportContractualRates: action.payload.exportContractualRates,
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRates: action.error,
        },
        loading: {
          ...state.loading,
          exportContractualRates: false,
        },
        exportContractualRates: null,
      };

    case ERateContractActions.CLEAR_EXPORT_CONTRACTUAL_RATES_DETAILS_AND_SUMMARY:
      return {
        ...state,
        exportContractualRatesDetails: null,
        exportContractualRatesSummary: null,
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRatesSummary: null,
        },
        loading: {
          ...state.loading,
          exportContractualRatesSummary: true,
        },
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRatesSummary: null,
        },
        loading: {
          ...state.loading,
          exportContractualRatesSummary: false,
        },
        exportContractualRatesSummary: action.payload.exportContractualRatesSummary,
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_SUMMARY_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRatesSummary: action.error,
        },
        loading: {
          ...state.loading,
          exportContractualRatesSummary: false,
        },
        exportContractualRatesSummary: null,
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRatesDetails: null,
        },
        loading: {
          ...state.loading,
          exportContractualRatesDetails: true,
        },
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRatesDetails: null,
        },
        loading: {
          ...state.loading,
          exportContractualRatesDetails: false,
        },
        exportContractualRatesDetails: action.payload.exportContractualRatesDetails,
      };

    case ERateContractActions.FETCH_EXPORT_CONTRACTUAL_RATES_DETAILS_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          exportContractualRatesDetails: action.error,
        },
        loading: {
          ...state.loading,
          exportContractualRatesDetails: false,
        },
        exportContractualRatesDetails: null,
      };

    case ERateContractActions.FETCH_REVENUE_RECOGNITION_DICTIONARY:
      return {
        ...state,
        loading: {
          ...state.loading,
          revenuerecognitionDictionary: true,
        },
        revenueRecognitionError: null,
      };

    case ERateContractActions.FETCH_REVENUE_RECOGNITION_DICTIONARY_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          revenuerecognitionDictionary: false,
        },
        revenueRecognitionError: action.error,
      };

    case ERateContractActions.FETCH_REVENUE_RECOGNITION_DICTIONARY_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          revenuerecognitionDictionary: false,
        },
        revenueRecognitionError: null,
        revenueRecognitionDictionary: action.payload,
      };

    default:
      return state;
  }
}
