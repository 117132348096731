import { Injectable } from '@angular/core';
import { WebsocketEventSubscriptionMappingsService } from '@gms/auction-api';
import { WebsocketEvent, WebsocketRoute } from '@gms/websocket-api';
import { select, Store } from '@ngrx/store';
import { IDataState } from 'app/store/app/app.models';
import { IAppState } from 'app/store/app/app.state';
import { FetchWebsocketTicket } from 'app/store/auth/auth.actions';
import {
  selectAccessToken,
  selectLogoutTime,
  selectWebsocketTicket,
} from 'app/store/auth/auth.selectors';
import { IAuthState } from 'app/store/auth/auth.state';
import { appConfig } from 'config/app-config';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { getMinutesInMS } from 'shared/utils/number.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { checkChannelMatch, isValidJSON, isWebsocketV3 } from 'shared/utils/websocket.utils';

@Injectable({
  providedIn: 'root',
})
/**
 *
 * */
export class WebsocketService extends HasSubscriptions {
  static maxConnectionRetries = 3;

  idleConnectionRefreshTime: number;
  reconnectTime: number;
  logoutTime = 0;
  accessToken: string;
  securityTicket: string;

  private queryParams_DEPRECATED;
  private idleConnectionTimeout;
  private reconnectTimeout;

  private wsSubject$: BehaviorSubject<WebSocketSubject<any>> = new BehaviorSubject(null);
  private subject$: Observable<WebSocketSubject<any>> = null;

  private accessToken$: Observable<string> = this._store.pipe(
    select(selectAccessToken),
    filter<string>(Boolean)
  );

  private ticket$: Observable<string> = this._store.pipe(
    select(selectWebsocketTicket),
    filter((dataState: IDataState<string>) => Boolean(dataState && dataState.data)),
    map((dataState: IDataState<string>) => dataState.data)
  );

  private logoutTime$: Observable<number> = this._store.pipe(select(selectLogoutTime));

  /**
   * @param {WebsocketEventSubscriptionMappingsService} _websocketEventSubscriptionMappingsService
   * @param {Store<IAppState>} _store
   */
  constructor(
    private _websocketEventSubscriptionMappingsService: WebsocketEventSubscriptionMappingsService,
    private _store: Store<IAppState>
  ) {
    super();

    this.addSubscriptions([
      this.logoutTime$.subscribe(time => {
        this.logoutTime = time;
      }),
      this.accessToken$.pipe(distinctUntilChanged()).subscribe(accessToken => {
        this.accessToken = accessToken;
        // only fetch ticket during token refresh and not on page load
        if (!isNullOrUndefined(this.securityTicket)) {
          this.fetchTicket(accessToken);
        }
      }),
      this.ticket$.pipe(distinctUntilChanged()).subscribe(ticket => {
        this.securityTicket = ticket;
        this.disconnect();
        this.clearConnectionTimeouts();
        this.connect(ticket);
      }),
    ]);

    this.idleConnectionRefreshTime = getMinutesInMS(5);
    this.reconnectTime = getMinutesInMS(115);
  }

  /**
   * Clears the websocket connection and resets timeouts and observables
   */
  reset() {
    this.disconnect();
    this.clearConnectionTimeouts();
    this.subject$ = null;
  }

  /**
   * Returns the injected event mapping service to be used by the IsEventRecipient class.
   * @returns {WebsocketEventSubscriptionMappingsService}
   */
  getWebsocketEventSubscriptionMappingsService(): WebsocketEventSubscriptionMappingsService {
    return this._websocketEventSubscriptionMappingsService;
  }

  setQueryParams_DEPRECATED(queryParams) {
    this.queryParams_DEPRECATED = queryParams;
  }

  getIdleConnectionTimeout() {
    return this.idleConnectionTimeout;
  }

  getReconnectTimeout() {
    return this.reconnectTimeout;
  }

  /**
   * Builds URL for establishing websocket connection.
   * @param {{}} queryParams (deprecated)
   * @returns {string}
   */
  private buildWebSocketUrl = (queryParams = {}) => {
    const baseUrl = appConfig.websocket.url;
    const formattedParams = new URLSearchParams(queryParams).toString();
    return `${baseUrl}/?${formattedParams}`;
  };

  /**
   * Creates an observable with the current websocket connection
   * @returns {WebSocketSubject<any>}
   */
  private createSubject = (): Observable<WebSocketSubject<any>> =>
    this.wsSubject$.pipe(
      filter<WebSocketSubject<any>>(Boolean),
      tap(() => {
        this.setIdleConnectionTimeout();
        this.setReconnectTimeout();
      }),
      shareReplay(1)
    );

  /**
   * Opens a new websocket connection by creating a new websocket subject
   */
  connect(ticket: string): void {
    const queryParams = { ticket, ...this.queryParams_DEPRECATED };
    const url = this.buildWebSocketUrl(queryParams);
    this.wsSubject$.next(
      webSocket({
        url,
        deserializer: message => (message.data ? message.data : message),
      })
    );
  }

  /**
   * Disconnects websocket connection if connected.
   */
  disconnect() {
    const wsSubject = this.wsSubject$.getValue();
    if (isNullOrUndefined(wsSubject)) {
      return;
    }

    wsSubject.complete();
    this.wsSubject$.next(null);
  }

  /**
   * Establishes a websocket connection or returns an existing connection.
   * @returns {WebSocketSubject<string>}
   */
  getSubject(): Observable<WebSocketSubject<any>> {
    if (isNullOrUndefined(this.subject$)) {
      this.fetchTicket(this.accessToken);
      this.subject$ = this.createSubject();
    }
    return this.subject$;
  }

  /**
   * @deprecated Extend the IsEventRecipient class or use this.subscribeToEvents()
   * @param {string | string[]} route
   * @returns {Observable<any>}
   */
  subscribeToRoute_DEPRECATED(route: string | string[]): Observable<any> {
    const routes = !Array.isArray(route) ? [route] : route;

    return this.getSubject().pipe(
      switchMap(sub =>
        sub.pipe(
          filter((websocketEventMessage: string) => {
            if (!isValidJSON(websocketEventMessage) || isWebsocketV3(websocketEventMessage)) {
              return false;
            }

            const websocketEvent: WebsocketEvent = JSON.parse(websocketEventMessage);
            return routes.includes(websocketEvent.route);
          }),
          tap(() => this.setIdleConnectionTimeout())
        )
      )
    );
  }

  /**
   * Gets an observable that will emit when messages match one of the given channel addresses
   * @param {Array<string>} channelAddresses - Used to filter incoming messages
   * @returns {Observable<WebsocketEvent>}
   */
  subscribeToEvents(channelAddresses: Array<string>): Observable<WebsocketEvent> {
    return this.getSubject().pipe(
      switchMap(sub =>
        sub
          .multiplex(
            (): WebsocketEvent => ({ route: 'onSubscribe', data: { channelAddresses } }),
            () => {},
            (websocketEventMsg: string) => {
              if (isValidJSON(websocketEventMsg) && isWebsocketV3(websocketEventMsg)) {
                const websocketEvent: WebsocketEvent = JSON.parse(websocketEventMsg);
                const { data } = websocketEvent;
                return data && data.channelAddresses && data.channelAddresses.length
                  ? data.channelAddresses.some(
                      receivedAddress =>
                        !!channelAddresses.find(channelAddress =>
                          checkChannelMatch(channelAddress, receivedAddress)
                        )
                    )
                  : false;
              }

              return false;
            }
          )
          .pipe(tap(() => this.setIdleConnectionTimeout()))
      )
    );
  }

  subscribeToSubject(onMessage = msg => {}, onErr = err => {}, onClosed = () => {}): Subscription {
    let subjectSubscription;
    this.addSubscription(
      this.getSubject()
        .pipe(first())
        .subscribe(sub => (subjectSubscription = sub.subscribe(onMessage, onErr, onClosed)))
    );

    return subjectSubscription;
  }

  /**
   * Send an outgoing message over the websocket
   * @param {WebsocketEvent} payload
   */
  public sendMessage(payload: WebsocketEvent): void {
    this.addSubscription(
      this.getSubject()
        .pipe(first())
        .subscribe(sub => {
          sub.next(payload);
          this.setIdleConnectionTimeout();
        })
    );
  }

  /**
   * Sets a timeout to refresh the ws connection during idle time
   */
  public setIdleConnectionTimeout(): void {
    this.clearIdleConnectionTimeout();

    this.idleConnectionTimeout = setTimeout(() => {
      if (this.hasExpiredSession()) {
        return;
      }

      this.sendMessage({
        data: { payload: 'ping' },
        route: WebsocketRoute.onMessage,
      });
    }, this.idleConnectionRefreshTime);
  }

  /**
   * Sets a timeout to create a new websocket connection
   */
  public setReconnectTimeout(): void {
    this.clearReconnectTimeout();

    this.reconnectTimeout = setTimeout(() => {
      if (this.hasExpiredSession()) {
        return;
      }

      this.fetchTicket(this.accessToken);
    }, this.reconnectTime);
  }

  /**
   * Clears the current idleConnectionTimeout
   */
  public clearIdleConnectionTimeout(): void {
    clearTimeout(this.idleConnectionTimeout);
    this.idleConnectionTimeout = undefined;
  }

  /**
   * Clears the current reconnectTimeout
   */
  public clearReconnectTimeout(): void {
    clearTimeout(this.reconnectTimeout);
    this.reconnectTimeout = undefined;
  }

  public clearConnectionTimeouts(): void {
    this.clearIdleConnectionTimeout();
    this.clearReconnectTimeout();
  }

  /**
   * Checks is the current users session is still active
   * @returns {boolean}
   */
  private hasExpiredSession(): boolean {
    const sessionAuthState: IAuthState = JSON.parse(localStorage.getItem('authState'));
    const logoutTime = Boolean(sessionAuthState && sessionAuthState.logoutTime)
      ? sessionAuthState.logoutTime
      : this.logoutTime;
    const now = new Date().getTime();
    return now >= logoutTime;
  }

  /**
   * Fetches the websocket security ticket
   * @param accessToken - gms auth token
   */
  private fetchTicket(accessToken: string): void {
    this._store.dispatch(new FetchWebsocketTicket({ accessToken }));
  }
}
