import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import {
  EMeasurementsActions,
  MeasurementsActions,
} from 'app/store/measurements/measurements.actions';
import {
  IMeasurementsState,
  initialMeasurementsState,
} from 'app/store/measurements/measurements.state';

export function measurementsReducers(
  state = initialMeasurementsState,
  action: MeasurementsActions
): IMeasurementsState {
  switch (action.type) {
    case EMeasurementsActions.FETCH_LOCATION_MEASUREMENTS:
      return {
        ...state,
        locationMeasurements: null,
        status: {
          ...state.status,
          locationMeasurements: {
            httpError: null,
            loading: true,
          },
        },
        locationMeasurementsSearchState: {
          dateFlowBegin: action.payload.beginFlowDate,
          dateFlowEnd: action.payload.endFlowDate,
          statementDateTime: null,
          summaryEnergyQuantity: null,
          searchParams: {
            dateFlowBegin: action.payload.beginFlowDate,
            dateFlowEnd: action.payload.endFlowDate,
          },
        },
      };

    case EMeasurementsActions.FETCH_LOCATION_MEASUREMENTS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          locationMeasurements: {
            httpError: action.payload.error,
            loading: false,
          },
        },
        locationMeasurementsSearchState: {
          dateFlowBegin: null,
          dateFlowEnd: null,
          statementDateTime: null,
          summaryEnergyQuantity: null,
          searchParams: null,
        },
      };

    case EMeasurementsActions.FETCH_LOCATION_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        locationMeasurements: action.payload.locationMeasurements,
        status: {
          ...state.status,
          locationMeasurements: {
            httpError: null,
            loading: false,
          },
        },
        locationMeasurementsSearchState: {
          ...state.locationMeasurementsSearchState,
          statementDateTime: new Date().getTime(),
        },
      };

    case EMeasurementsActions.FETCH_LOOKUP_COLLECTIONS:
      return {
        ...state,
        status: {
          ...state.status,
          lookupCollections: {
            httpError: null,
            loading: true,
          },
        },
      };

    case EMeasurementsActions.FETCH_LOOKUP_COLLECTIONS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          lookupCollections: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case EMeasurementsActions.FETCH_LOOKUP_COLLECTIONS_SUCCESS:
      return {
        ...state,
        lookupCollections: action.payload.useCache
          ? state.lookupCollections
          : action.payload.lookupCollections,
        status: {
          ...state.status,
          lookupCollections: {
            httpError: null,
            loading: false,
          },
        },
      };

    case EMeasurementsActions.RESET_LOCATION_MEASUREMENTS:
      return {
        ...state,
        locationMeasurements: null,
        locationMeasurementsSearchState: {
          dateFlowBegin: null,
          dateFlowEnd: null,
          statementDateTime: null,
          summaryEnergyQuantity: null,
          searchParams: null,
        },
        status: {
          ...state.status,
          locationMeasurements: {
            httpError: null,
            loading: false,
          },
        },
      };

    case EMeasurementsActions.SET_LOCATION_MEASUREMENTS:
      return {
        ...state,
        locationMeasurements: action.payload.locationMeasurements,
        locationMeasurementsSearchState: {
          ...state.locationMeasurementsSearchState,
          summaryEnergyQuantity: action.payload.previousSearched
            ? action.payload.previousSearched.summaryEnergyQuantity
            : state.locationMeasurementsSearchState.summaryEnergyQuantity,
          searchParams: {
            ...state.locationMeasurementsSearchState.searchParams,
            location: action.payload.previousSearched
              ? action.payload.previousSearched.location
              : state.locationMeasurementsSearchState.searchParams.location,
          },
        },
      };

    case EMeasurementsActions.UPDATE_LOCATION_MEASUREMENT:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationMeasurement: {
            httpError: null,
            loading: true,
          },
        },
      };

    case EMeasurementsActions.UPDATE_LOCATION_MEASUREMENT_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationMeasurement: {
            httpError: null,
            loading: false,
          },
        },
      };

    case EMeasurementsActions.UPDATE_LOCATION_MEASUREMENT_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          updateLocationMeasurement: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case EMeasurementsActions.CREATE_LOCATION_MEASUREMENT:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationMeasurement: {
            httpError: null,
            loading: true,
          },
        },
      };

    case EMeasurementsActions.CREATE_LOCATION_MEASUREMENT_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationMeasurement: {
            httpError: null,
            loading: false,
          },
        },
      };

    case EMeasurementsActions.CREATE_LOCATION_MEASUREMENT_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          createLocationMeasurement: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case EMeasurementsActions.FETCH_HOURLY_OVERRUN:
      return {
        ...state,
        hourlyOverrun: createLoadingState(state.hourlyOverrun),
      };

    case EMeasurementsActions.FETCH_HOURLY_OVERRUN_SUCCESS:
      return {
        ...state,
        hourlyOverrun: createSuccessState(action.payload.hourlyOverrun),
      };

    case EMeasurementsActions.FETCH_HOURLY_OVERRUN_FAILURE:
      return {
        ...state,
        hourlyOverrun: createErrorState(action.payload.error),
      };

    case EMeasurementsActions.EXPORT_HOURLY_OVERRUN:
      return {
        ...state,
        exportHourlyOverrun: createLoadingState(state.exportHourlyOverrun),
      };

    case EMeasurementsActions.EXPORT_HOURLY_OVERRUN_SUCCESS:
      return {
        ...state,
        exportHourlyOverrun: createSuccessState(state.exportHourlyOverrun),
      };

    case EMeasurementsActions.EXPORT_HOURLY_OVERRUN_FAILURE:
      return {
        ...state,
        exportHourlyOverrun: createErrorState(action.payload.error),
      };

    case EMeasurementsActions.FETCH_GAS_QUALITY:
      return {
        ...state,
        gasQuality: createLoadingState(state.gasQuality),
      };

    case EMeasurementsActions.FETCH_GAS_QUALITY_SUCCESS:
      return {
        ...state,
        gasQuality: createSuccessState(action.payload.gasQuality),
      };

    case EMeasurementsActions.FETCH_GAS_QUALITY_FAILURE:
      return {
        ...state,
        gasQuality: createErrorState(action.payload.error),
      };

    case EMeasurementsActions.EXPORT_GAS_QUALITY:
      return {
        ...state,
        exportGasQuality: createLoadingState(state.exportGasQuality),
      };

    case EMeasurementsActions.EXPORT_GAS_QUALITY_SUCCESS:
      return {
        ...state,
        exportGasQuality: createSuccessState(state.exportGasQuality),
      };

    case EMeasurementsActions.EXPORT_GAS_QUALITY_FAILURE:
      return {
        ...state,
        exportGasQuality: createErrorState(action.payload.error),
      };

    case EMeasurementsActions.FETCH_ACCUMULATED_FLOW_SUMMARY:
      return {
        ...state,
        accumulatedFlowSummary: createLoadingState(state.accumulatedFlowSummary),
      };

    case EMeasurementsActions.FETCH_ACCUMULATED_FLOW_SUMMARY_FAILURE:
      return {
        ...state,
        accumulatedFlowSummary: createErrorState(action.payload.error),
      };

    case EMeasurementsActions.FETCH_ACCUMULATED_FLOW_SUMMARY_SUCCESS:
      return {
        ...state,
        accumulatedFlowSummary: createSuccessState({ ...action.payload }),
      };

    case EMeasurementsActions.FETCH_ACCUMULATED_FLOWS:
      return {
        ...state,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        accumulatedFlowsTotal: null,
        accumulatedFlows: createLoadingState(state.accumulatedFlows),
      };

    case EMeasurementsActions.FETCH_ACCUMULATED_FLOWS_SUCCESS:
      return {
        ...state,
        accumulatedFlowsTotal: action.payload.total,
        accumulatedFlows: createSuccessState(action.payload.accumulatedFlows),
      };

    case EMeasurementsActions.FETCH_ACCUMULATED_FLOWS_FAILURE:
      return {
        ...state,
        accumulatedFlowsTotal: null,
        accumulatedFlows: createErrorState(action.payload.error),
      };

    case EMeasurementsActions.EXPORT_MEASUREMENT_INFO:
      return {
        ...state,
        exportMeasurementInfo: createLoadingState(state.exportMeasurementInfo),
      };

    case EMeasurementsActions.EXPORT_MEASUREMENT_INFO_SUCCESS:
      return {
        ...state,
        exportMeasurementInfo: createSuccessState(state.exportMeasurementInfo),
      };

    case EMeasurementsActions.EXPORT_MEASUREMENT_INFO_FAILURE:
      return {
        ...state,
        exportMeasurementInfo: createErrorState(action.payload.error),
      };

    default:
      return state;
  }
}
