/* The name of each property doesn't follow our camel casing convention, however the property names match the properties in the DB.
Writing the property names as the DB format > writing glue code to convert from camel case to DB readable properties */
import { TagColor } from 'shared/components/static-tag/tag-color.enum';

export interface IAccountPeriod {
  date?: string;
  id?: string;
  isPreliminary?: boolean;
  mon: string;
  name?: string;
  year: string;
  status?: string;
}

export const idToMonthMap = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12',
};

export const idToLongMonthMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const RateTypeId = {
  DISCOUNT: 'Discount',
  NEGOTIATED: 'Negotiated',
  TARIFFMAX: 'Tariff Max',
  MARKETBASED: 'Market Based',
  OTHER: 'Other',
  CAPACITYRELEASE: 'Capacity Release',
}

export const rateTypeOptions = [
  { value: RateTypeId.CAPACITYRELEASE, name: RateTypeId.CAPACITYRELEASE },
  { value: RateTypeId.DISCOUNT, name: RateTypeId.DISCOUNT},
  { value: RateTypeId.MARKETBASED, name: RateTypeId.MARKETBASED },
  { value: RateTypeId.NEGOTIATED, name: RateTypeId.NEGOTIATED },
  { value: RateTypeId.OTHER, name: RateTypeId.OTHER },
  { value: RateTypeId.TARIFFMAX, name: RateTypeId.TARIFFMAX },
];

export function generateAccountingPeriods(
  monthsBack: number,
  showAllOption: boolean = false,
  startMonth?: IAccountPeriod
): Array<IAccountPeriod> {
  const currentDate = startMonth ? new Date(+startMonth.year, +startMonth.mon - 1) : new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  let accountingPeriod: Array<IAccountPeriod>;

  accountingPeriod = [
    {
      id: currentMonth.toString() + currentYear.toString(),
      mon: idToMonthMap[currentMonth],
      year: currentYear.toString(),
      name: idToLongMonthMap[currentMonth],
      isPreliminary: true,
    },
  ];

  if (showAllOption) {
    accountingPeriod = [
      {
        id: '0',
        name: 'Browse All',
        mon: null,
        year: null,
        isPreliminary: null,
      },
      ...accountingPeriod,
    ];
  }

  for (let i = 1; i < monthsBack; i++) {
    let month: number;
    let year: number;

    const monthConversion = Math.ceil(Math.abs(currentMonth - i) / 12);

    if (currentMonth - i > 0) {
      month = currentMonth - i;
      year = currentYear;
    } else if (currentMonth - i === 0) {
      month = currentMonth - i + 12;
      year = currentYear - 1;
    } else {
      if (currentMonth - i + 12 * monthConversion === 0) {
        month = 12;
        year = currentYear - monthConversion - 1;
      } else {
        month = currentMonth - i + 12 * monthConversion;
        year = currentYear - monthConversion;
      }
    }

    accountingPeriod.push({
      id: month.toString() + year.toString(),
      mon: idToMonthMap[month],
      year: year.toString(),
      name: idToLongMonthMap[month],
      isPreliminary: false,
    });
  }

  return accountingPeriod;
}

export const ETransactionStatusCodeEnum = {
  F: 'F',
  P: 'P',
};

export type TransactionStatusEnum = 'PRELIMINARY' | 'FINAL';

export const ETransactionStatusEnum = {
  PRELIMINARY: 'Preliminary' as TransactionStatusEnum,
  FINAL: 'Final' as TransactionStatusEnum,
};

export const transactionsColorMap = {
  [ETransactionStatusEnum.PRELIMINARY]: TagColor.YELLOW,
  [ETransactionStatusEnum.FINAL]: TagColor.PRIMARY_BLUE,
};

export function convertAccountPeriodToDate(accPer: IAccountPeriod): Date {
  if (!!accPer) return new Date(+accPer.year, +accPer.mon - 1);
}

export function convertAccountPeriodToDateOffsetTimezone(accountPeriod: IAccountPeriod): Date {
  if (!!accountPeriod && !!accountPeriod.id) {
    const firstOfTheMonthTimeZoneOffset = new Date().getTimezoneOffset() < 0 ? 2 : 1;
    return new Date(+accountPeriod.year, +accountPeriod.mon - 1, firstOfTheMonthTimeZoneOffset);
  } else {
    return null;
  }
}
