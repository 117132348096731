import { Injectable } from '@angular/core';
import { RateScheduleService, TspHolidayCollection } from '@gms/rateschedulev2-api';
import { ServiceProvider, TspService } from '@gms/tsp-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  GetAllTspHolidays,
  GetAllTspHolidaysError,
  GetAllTspHolidaysSuccess,
} from 'app/store/tsp/tsps.actions';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { filterIfCached } from '../app/app.models';
import { IAppState } from '../app/app.state';
import {
  ETspActions,
  FetchTsps,
  FetchTspsError,
  FetchTspsSuccess,
  GetTspById,
  GetTspByIdError,
  GetTspByIdSuccess,
  GetTspHolidays,
  GetTspHolidaysError,
  GetTspHolidaysSuccess,
} from './tsps.actions';
import { selectServiceProviders } from './tsps.selectors';

@Injectable({
  providedIn: 'root',
})
export class TspsEffect {
  constructor(
    private actions$: Actions,
    private tspService: TspService,
    private rateScheduleService: RateScheduleService,
    private store: Store<IAppState>
  ) {}

  getTsps$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchTsps>(ETspActions.FetchTsps),
      filterIfCached(this.store.pipe(select(selectServiceProviders))),
      switchMap(action => {
        const { status } = action.payload;
        return this.tspService.getAllTsp(status).pipe(
          switchMap((serviceProviders: ServiceProvider[]) =>
            of(new FetchTspsSuccess(serviceProviders, action.payload))
          ),
          catchError(error => of(new FetchTspsError(error)))
        );
      })
    )
  );

  getTspById$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTspById>(ETspActions.GetTspById),
      switchMap(action => {
        const requestId = action.payload;
        return this.tspService.getTspById(requestId).pipe(
          switchMap((serviceProvider: ServiceProvider) =>
            of(new GetTspByIdSuccess(serviceProvider))
          ),
          catchError(error => of(new GetTspByIdError(error)))
        );
      })
    )
  );

  getTspHolidays$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTspHolidays>(ETspActions.GetTspHolidays),
      switchMap(action => {
        const { tspId, dateBegin, dateEnd } = action.payload;
        return this.rateScheduleService.getTspHolidays(tspId, dateBegin, dateEnd).pipe(
          switchMap((holidays: TspHolidayCollection) => of(new GetTspHolidaysSuccess(holidays))),
          catchError(error => of(new GetTspHolidaysError(error)))
        );
      })
    )
  );

  getAllTspHolidays$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllTspHolidays>(ETspActions.GetAllTspHolidays),
      map((action: GetAllTspHolidays) => action),
      mergeMap(action => {
        return forkJoin(
          action.payload.map(requestItem =>
            this.rateScheduleService.getTspHolidays(
              requestItem.tspId,
              requestItem.dateBegin,
              requestItem.dateEnd
            )
          )
        ).pipe(
          map(response => new GetAllTspHolidaysSuccess(response)),
          catchError(error => of(new GetAllTspHolidaysError(error)))
        );
      })
    )
  );
}
