import { Roles, User } from '@gms/user-api';
import {
  createEmptyLoadingState,
  createErrorState,
  createInitialDataState,
  createLoadingState,
  createSuccessState,
} from 'app/store/app/app.models';
import { read, RoleWithPermission } from 'shared/services/roles.service';
import { AuthActions, EAuthActions } from './auth.actions';
import { IAuthState, initialAuthState, RouteRoleConfigs } from './auth.state';

const ONE_MINUTE = 60000;

const getTokenExpirationDate = (accessToken: string): Date => {
  return new Date(JSON.parse(atob(accessToken.split('.')[1])).exp * 1000);
};

export function buildRoles(roles: RoleWithPermission[]): RoleWithPermission[] {
  const impliedRoles: RoleWithPermission[] = [];
  roles.forEach(role => {
    const [baseRole, accessLevel] = role.split('.');
    if (accessLevel === 'write') {
      impliedRoles.push(read(baseRole as Roles));
    }
  });
  return [...roles, ...impliedRoles].sort();
}

export function authReducers(state = initialAuthState, action: AuthActions): IAuthState {
  let stateUpdates: Partial<IAuthState>;

  switch (action.type) {
    case EAuthActions.LOG_IN:
      stateUpdates = {
        accessToken: null,
        roles: [],
        loginLoading: true,
        error: null,
        isAuthenticated: false,
        logoutTime: null,
        refreshToken: null,
        saveUserId: action.payload.saveUserId,
        user: createInitialDataState(),
        userId: action.payload.userId,
        warningLogoutTime: null,
        authResources: {},
        isInternal: null,
      };
      break;

    case EAuthActions.LOG_IN_SUCCESS:
      stateUpdates = {
        accessToken: action.payload.accessToken,
        isADFSLogin: action.payload.isADFSLogin,
        roles: buildRoles(action.payload.roles),
        loginLoading: false,
        error: null,
        isAuthenticated: true,
        refreshToken: action.payload.refreshToken,
        ttl: action.payload.ttl * 1000,
        authResources: action.payload.authResources,
        isInternal: action.payload.isInternal,
        mfa: createSuccessState(null),
        user: createEmptyLoadingState(),
      };
      break;

    case EAuthActions.LOG_IN_FAILURE:
      stateUpdates = {
        isAuthenticated: false,
        loginLoading: false,
        error: action.payload.error,
        logoutTime: null,
        warningLogoutTime: null,
        authResources: {},
        isInternal: null,
      };
      break;

    case EAuthActions.REDIRECT_TO_MFA:
      stateUpdates = {
        mfa: createSuccessState(action.payload),
        loginLoading: false,
        error: null,
        isAuthenticated: false,
      };
      break;

    case EAuthActions.LOG_IN_MFA:
      stateUpdates = {
        mfa: createLoadingState(state.mfa),
      };
      break;

    case EAuthActions.LOG_IN_MFA_ERROR:
      stateUpdates = {
        mfa: createErrorState(action.payload.error),
      };
      break;

    case EAuthActions.FETCH_USER_SUCCESS:
      stateUpdates = {
        user: createSuccessState(action.payload.user),
        error: null,
      };
      break;

    case EAuthActions.FETCH_USER_FAILURE:
      stateUpdates = {
        user: createErrorState(action.payload.error),
        error: action.payload.error,
      };
      break;

    case EAuthActions.REFRESH:
      stateUpdates = {
        accessToken: null,
        error: null,
      };
      break;

    case EAuthActions.REFRESH_SUCCESS:
      console.error(
        `Token refresh success, expires ${getTokenExpirationDate(action.payload.accessToken)}`
      );
      stateUpdates = {
        accessToken: action.payload.accessToken,
        error: null,
        isAuthenticated: true,
        ttl: action.payload.ttl * 1000,
      };
      break;

    case EAuthActions.REFRESH_FAILURE:
      console.error('Token refresh failure');
      stateUpdates = {
        isAuthenticated: false,
        user: createInitialDataState(),
        error: action.payload.error,
        logoutTime: null,
        warningLogoutTime: null,
      };
      break;

    case EAuthActions.REFRESH_CANCEL:
      stateUpdates = {
        isAuthenticated: false,
        user: createInitialDataState(),
        error: null,
        logoutTime: null,
        warningLogoutTime: null,
      };
      break;

    case EAuthActions.LOG_OUT:
      stateUpdates = {
        accessToken: null,
        isADFSLogin: false,
        roles: [],
        error: null,
        isAuthenticated: false,
        logoutTime: null,
        refreshToken: null,
        user: createInitialDataState(),
        warningLogoutTime: null,
        authResources: {},
      };
      break;

    // used for refresh failures
    case EAuthActions.LOG_OUT_WITH_ERROR:
      console.error('Token refresh failure');
      stateUpdates = {
        accessToken: null,
        isADFSLogin: false,
        roles: [],
        error: action.payload.error,
        isAuthenticated: false,
        logoutTime: null,
        refreshToken: null,
        user: createInitialDataState(),
        warningLogoutTime: null,
        authResources: {},
      };
      break;

    case EAuthActions.FORGET_USER_ID:
      stateUpdates = {
        saveUserId: false,
      };
      break;

    case EAuthActions.ACCEPT_COOKIES:
      stateUpdates = {
        cookiesAccepted: true,
      };
      break;

    case EAuthActions.UPDATE_PASSWORD:
      stateUpdates = {
        updatePasswordLoading: true,
        updatePasswordError: null,
      };
      break;

    case EAuthActions.UPDATE_PASSWORD_SUCCESS:
      stateUpdates = {
        updatePasswordLoading: false,
        updatePasswordError: null,
      };
      break;

    case EAuthActions.UPDATE_PASSWORD_FAILURE:
      stateUpdates = {
        updatePasswordLoading: false,
        updatePasswordError: action.payload.error,
      };
      break;

    case EAuthActions.FETCH_ROUTE_CONFIGS:
      stateUpdates = {
        routeRoleConfigsLoading: true,
      };
      break;

    case EAuthActions.FETCH_ROUTE_CONFIGS_SUCCESS:
      stateUpdates = {
        routeRoleConfigs: action.payload.rolesPages.reduce(
          (acc, config): RouteRoleConfigs => {
            acc[config.uiRoute] = config.roles;
            return acc;
          },
          {} as RouteRoleConfigs
        ),
        routeRoleConfigsLoading: false,
      };
      break;

    case EAuthActions.FETCH_ROUTE_CONFIGS_FAILURE:
      stateUpdates = {
        routeRoleConfigsLoading: false,
        routeRoleConfigsError: action.payload.error,
      };
      break;

    case EAuthActions.SSO_REDIRECT:
      stateUpdates = {
        saveUserId: action.payload.saveUserId,
        userId: action.payload.userId,
      };
      break;

    case EAuthActions.SSO_LOG_IN:
      stateUpdates = {
        accessToken: null,
        roles: [],
        loginLoading: true,
        error: null,
        isAuthenticated: false,
        logoutTime: null,
        refreshToken: null,
        user: createInitialDataState(),
        warningLogoutTime: null,
        authResources: {},
      };
      break;

    case EAuthActions.SSO_LOG_IN_FAILURE:
      stateUpdates = {
        isAuthenticated: false,
        loginLoading: false,
        error: action.payload.error,
        logoutTime: null,
        warningLogoutTime: null,
        authResources: {},
      };
      break;

    case EAuthActions.UNAUTHORIZED_USER:
      stateUpdates = {
        accessToken: null,
        isAuthenticated: false,
        roles: [],
        error: null,
        logoutTime: null,
        refreshToken: null,
        warningLogoutTime: null,
        authResources: {},
      };
      break;

    case EAuthActions.INVALID_SSO_USER_ID:
      stateUpdates = {
        invalidSsoUserId: true,
        userId: action.payload.userId,
      };
      break;
    case EAuthActions.FETCH_WEBSOCKET_TICKET:
      stateUpdates = {
        websocketTicket: createEmptyLoadingState(),
      };
      break;
    case EAuthActions.FETCH_WEBSOCKET_TICKET_SUCCESS:
      stateUpdates = {
        websocketTicket: createSuccessState(action.payload.ticket.ticket),
      };
      break;
    case EAuthActions.FETCH_WEBSOCKET_TICKET_FAILURE:
      stateUpdates = {
        websocketTicket: createErrorState(action.payload.error),
      };
      break;
  }

  return {
    ...state,
    ...stateUpdates,
  };
}
