import { NavConfig } from 'app/components/app-common-views/sidebar-nav/nav-menus.constants';

export interface ISidebarState {
  error: boolean;
  loading: boolean;
  selectedPrimaryNavConfig: NavConfig;
  activePrimaryNavMenuId: string;
  activeSecondaryNavMenuId: string;
  activeTertiaryNavMenuId: string;
}

export const initialSidebarState: ISidebarState = {
  error: false,
  loading: true,
  selectedPrimaryNavConfig: null,
  activePrimaryNavMenuId: null,
  activeSecondaryNavMenuId: null,
  activeTertiaryNavMenuId: null,
};
