import { Injectable } from '@angular/core';
import {
  DealSheet,
  DealSheetCollection,
  DealSheetService,
  InternalComment,
} from '@gms/servicerequest-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { retryStrategy } from 'app/store/capacity-release/capacity-release.utils';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, retryWhen, switchMap } from 'rxjs/operators';
import { cleanDateWithYYYY_MM_DD } from 'shared/services/data-cleaner.service';
import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import {
  AddInternalComment,
  AddInternalCommentError,
  AddInternalCommentSuccess,
  DeleteDealSheet,
  DeleteDealSheetError,
  DeleteDealSheetSuccess,
  EDealSheetActions,
  FetchDealSheetById,
  FetchDealSheets,
  FetchDealSheetsError,
  FetchDealSheetsSuccess,
  SaveDealSheet,
  SaveDealSheetError,
  SaveDealSheetSuccess,
  UpdateDealSheet,
  UpdateDealSheetError,
  UpdateDealSheetSuccess,
  UpdateEditingDealSheet,
} from './deal-sheets.actions';

@Injectable({
  providedIn: 'root',
})
export class DealSheetsEffect {
  constructor(private actions$: Actions, private dealSheetService: DealSheetService) {}

  FetchDealSheets$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDealSheets>(EDealSheetActions.FETCH_DEAL_SHEETS),
      map((action: FetchDealSheets) => action.payload),
      switchMap(payload => {
        let sortQuery = ``;
        payload.sortDescriptors.forEach(sortDescriptor => {
          sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
        });
        return this.dealSheetService
          .getDealSheet(
            payload.pageSize,
            payload.pageNumber,
            [sortQuery],
            payload.tspId,
            payload.rateScheduleId,
            payload.entityId,
            payload.contractId,
            payload.sheetStatus,
            payload.sheetId,
            payload.lastUpdateBegin,
            payload.lastUpdateEnd,
            payload.addedByUserId,
            payload.modifiedByUserId
          )
          .pipe(
            switchMap((dealSheet: DealSheetCollection) =>
              of(
                new FetchDealSheetsSuccess({
                  dealSheets: dealSheet.dealSheets,
                  totalDealSheetsCount: dealSheet.total,
                })
              )
            ),
            catchError(error => of(new FetchDealSheetsError(error)))
          );
      })
    )
  );

  FetchDealSheetById$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDealSheetById>(EDealSheetActions.FETCH_DEAL_SHEET_BY_ID),
      switchMap(action => {
        const requestId = action.payload;
        return this.dealSheetService.getDealSheetById(requestId).pipe(
          switchMap((dealSheet: DealSheet) => of(new UpdateEditingDealSheet(dealSheet))),
          catchError(error => of(new FetchDealSheetsError(error)))
        );
      })
    )
  );

  saveDealSheet$ = createEffect(() => ({ 
    debounce = 500 } = {}) =>
    this.actions$.pipe(
      ofType<SaveDealSheet>(EDealSheetActions.SAVE_DEAL_SHEET),
      debounceTime(debounce),
      switchMap(body => {
        const bodyPayload = this.sanitizeDealSheet({ ...body.payload });
        const prefer = <'Lenient' | 'Strict'>body.prefer;
        return this.dealSheetService.addDealSheet(bodyPayload, prefer).pipe(
          mergeMap((dealSheet: DealSheet) => {
            return of(new SaveDealSheetSuccess(dealSheet));
          }),
          retryWhen(retryStrategy()),
          catchError(error => {
            return of(new SaveDealSheetError(error.error));
          })
        );
      })
    )
  );

  updateSaveDealSheet$ = createEffect(() => ({ 
    debounce = 500 } = {}) =>
    this.actions$.pipe(
      ofType<UpdateDealSheet>(EDealSheetActions.UPDATE_DEAL_SHEET),
      debounceTime(debounce),
      switchMap(body => {
        const bodyPayload = this.sanitizeDealSheet({ ...body.payload });
        return this.dealSheetService
          .updateDealSheet(bodyPayload, body.id, <'Lenient' | 'Strict'>body.prefer)
          .pipe(
            mergeMap((dealSheet: DealSheet) => {
              return of(new UpdateDealSheetSuccess(dealSheet));
            }),
            retryWhen(retryStrategy()),
            catchError(error => {
              return of(new UpdateDealSheetError(error.error));
            })
          );
      })
    )
  );

  DeleteDealSheet$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteDealSheet>(EDealSheetActions.DELETE_DEAL_SHEET),
      switchMap(body => {
        return this.dealSheetService.deleteDealSheet(body.payload.dealSheetId).pipe(
          mergeMap(() => {
            return of(new DeleteDealSheetSuccess());
          }),
          catchError(error => {
            return of(new DeleteDealSheetError(error));
          })
        );
      })
    )
  );

  AddInternalCommentToDealSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddInternalComment>(EDealSheetActions.ADD_INTERNAL_COMMENT),
      switchMap(body => {
        const bodyPayload = body.payload;
        return this.dealSheetService.dealSheetInternalComment(bodyPayload).pipe(
          switchMap((internalComment: InternalComment) =>
            of(new AddInternalCommentSuccess(internalComment))
          ),
          catchError(error => of(new AddInternalCommentError(error)))
        );
      })
    )
  );

  private sanitizeDealSheet(payload: DealSheet): DealSheet {
    payload.contractBeginDate = !isNullOrUndefined(payload.contractBeginDate)
      ? typeof payload.contractBeginDate === 'string'
        ? payload.contractBeginDate
        : cleanDateWithYYYY_MM_DD(new Date(payload.contractBeginDate))
      : null;
    payload.primaryTermDate = !isNullOrUndefined(payload.primaryTermDate)
      ? typeof payload.primaryTermDate === 'string'
        ? payload.primaryTermDate
        : cleanDateWithYYYY_MM_DD(new Date(payload.primaryTermDate))
      : null;

    if (payload.discountRates && payload.discountRates.rates) {
      payload.discountRates.rates.forEach(rate => {
        return {
          ...rate,
          effectiveDate: !isNullOrUndefined(rate.effectiveDate)
            ? typeof rate.effectiveDate === 'string'
              ? rate.effectiveDate
              : cleanDateWithYYYY_MM_DD(new Date(rate.effectiveDate))
            : null,
          expireDate: !isNullOrUndefined(rate.expireDate)
            ? typeof rate.expireDate === 'string'
              ? rate.expireDate
              : cleanDateWithYYYY_MM_DD(new Date(rate.expireDate))
            : null,
        };
      });
    }

    if (payload.negotiatedRates && payload.negotiatedRates.rates) {
      payload.negotiatedRates.rates.forEach(rate => {
        return {
          ...rate,
          effectiveDate: !isNullOrUndefined(rate.effectiveDate)
            ? typeof rate.effectiveDate === 'string'
              ? rate.effectiveDate
              : cleanDateWithYYYY_MM_DD(new Date(rate.effectiveDate))
            : null,
          expireDate: !isNullOrUndefined(rate.expireDate)
            ? typeof rate.expireDate === 'string'
              ? rate.expireDate
              : cleanDateWithYYYY_MM_DD(new Date(rate.expireDate))
            : null,
        };
      });
    }

    return payload;
  }
}
