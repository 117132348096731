import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'popover-content',
  template: `
    <ng-content></ng-content>
  `,
})
export class PopoverContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
