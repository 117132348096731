import { UserResource } from '@gms/user-api';

export interface AuthResources {
  [resource: string]: UserResource;
}

export const AUTH_RESOURCE_NAME = {
  Measurements: 'measurements',
  Pdas: 'pdas',
  Imbalance: 'imbalance', // Imbalance role
  Imbalances: 'imbalances', // Imbalance and Accounting roles
  Nominations: 'nominations',
  Contracts: 'contracts',
  Agencies: 'agencies',
  Allocations: 'allocations',
  Bids: 'bids',
  Offers: 'offers',
  ScheduledQuantityOperator: 'sched-qty-operator',
};
