import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface IValidityCheck {
  name: string;
  valid: boolean | null;
}

@Component({
  selector: 'gms-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})

/**
 * Please Use value changed on the form control.
 */
export class CheckboxComponent implements OnInit {
  /**
   * Please Use value changed on the form control.
   */
  @Input()
  control: UntypedFormControl;

  @Input()
  id: string;

  @Input()
  label: string;

  @Output()
  readonly checkboxFocused: EventEmitter<Event> = new EventEmitter();

  /* ******************************************************** *
   *                                                          *
   *                                                          *
   * Please Use value changed on the form control.            *
   * DO not add a valueChanged emitter.                       *
   *                                                          *
   *                                                          *
   * ******************************************************** */

  constructor() {}

  ngOnInit() {}

  onFocus() {
    this.checkboxFocused.emit();
  }
}
