import { NonTariffRate, RateApplicationTypeEnum } from '@gms/rate-api';
import { NonTariffRateDetail } from '@gms/rate-api/model/nonTariffRateDetail';
import { ContractualRateLevel, ContractualRateLevelEnum } from 'app/store/billing/billing.utils';
import cloneDeep from 'lodash/cloneDeep';
import { EMessageType } from 'shared/components/message/message.interface';

export interface NonTariffRateErrorMessage {
  type: EMessageType.ERROR;
  message: string;
}

export const createNonTariffRateErrorMessage = (message: string): NonTariffRateErrorMessage => ({
  type: EMessageType.ERROR,
  message,
});

export enum LocationFlowDirection {
  BOTH = 'B',
  DELIVERY = 'D',
  RECEIPT = 'R',
  NEITHER = 'N',
}

export interface IContractualRateLevel {
  displayName: ContractualRateLevel;
  id: ContractualRateLevelEnum;
}

export interface CopyRateRouterState {
  nonTariffRateCopyId: number;
  effectiveDate: string;
  expireDate: string;
}

export const CONTRACTUAL_RATE_LEVELS: IContractualRateLevel[] = [
  { displayName: ContractualRateLevel.AccountId, id: ContractualRateLevelEnum.AccountId },
  { displayName: ContractualRateLevel.AccountType, id: ContractualRateLevelEnum.AccountType },
  { displayName: ContractualRateLevel.Blanket, id: ContractualRateLevelEnum.Blanket },
  { displayName: ContractualRateLevel.Contract, id: ContractualRateLevelEnum.Contract },
  {
    displayName: ContractualRateLevel.ServiceRequester,
    id: ContractualRateLevelEnum.ServiceRequester,
  },
];

export enum RateErrorCodes {
  DUPLICATE_RATE = 14,
  ACCOUNT_TYPE_TSP = 17,
}

export enum HttpStatusCode {
  UNPROCESSABLE_ENTITY = 422,
}

export enum TariffRatesChargePeriodName {
  DAILY = 'Daily',
  K_BILL_MO = 'K Bill Mo',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export enum TariffRatesChargePeriodCode {
  DAY = 'DAY',
  KMO = 'KMO',
  MTH = 'MTH',
  YR = 'YR',
}

export enum RevenueRecognitionName {
  CONTRACT_ASSET = 'Contract Asset',
  CONTRACT_LIABILITY = 'Contract Liability',
  PAL = 'PAL',
}

export enum RevenueRecognitionId {
  CONTRACT_ASSET = 1,
  CONTRACT_LIABILITY = 2,
  PAL = 3,
}

export const findOriginalNonTariffRateDetailIndex = (
  details: NonTariffRateDetail[],
  detail: NonTariffRateDetail
) =>
  details.findIndex(
    d =>
      (detail.id && d.id && d.id === detail.id) ||
      (!detail.id &&
        !d.id &&
        ((detail.chargeTypeId && d.chargeTypeId && d.chargeTypeId === detail.chargeTypeId) ||
          (!d.chargeTypeId && !detail.chargeTypeId)) &&
        ((detail.dateEffective && d.dateEffective && d.dateEffective === detail.dateEffective) ||
          (!detail.dateEffective && !d.dateEffective)) &&
        ((detail.dateExpire && d.dateExpire && d.dateExpire === detail.dateExpire) ||
          (!detail.dateExpire && !d.dateExpire)))
  );


export const prepareNonTariffRateCopy = (nonTariffRate: NonTariffRate): NonTariffRate => {
  if (!nonTariffRate) {
    return null;
  }

  const copy: NonTariffRate = cloneDeep(nonTariffRate);

  copy.rateId = undefined;

  copy.detail = copy.detail?.map(detail => {
    const updatedDetail = {
      ...detail,
      id: undefined,
      sourceId: undefined,
      source: undefined,
      revenueRecognition: undefined,
      revenueRecognitionId: undefined,
      ratePoints: detail.ratePoints?.map(
        ratePoint => ({ ...ratePoint, id: undefined})
      )
    }

    if (updatedDetail.indexOrMiscRate) {
      updatedDetail.indexOrMiscRate = {
        ...updatedDetail.indexOrMiscRate,
        indexRateId: undefined
      };
    }
    
    return updatedDetail;
  });

  return copy;
}

export const isTieredMultipleRateType = (rateApplication: string) => 
  rateApplication === RateApplicationTypeEnum.Tiered ||
  rateApplication === RateApplicationTypeEnum.TopdownTier ||
  rateApplication === RateApplicationTypeEnum.Block;
