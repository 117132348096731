import {
  ITOnlineDeliveryLocationCollection,
  ITOnlineReceiptLocationCollection,
} from '@gms/auction-api';

export enum ELocationsGridTypes {
  RECEIPT = 'Receipt',
  DELIVERY = 'Delivery',
}

export interface ILocationsGridData
  extends ITOnlineDeliveryLocationCollection,
    ITOnlineReceiptLocationCollection {}
