import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RolesService } from 'shared/services/roles.service';

export const ResourceGuard = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const _rolesService = inject(RolesService);
  const _router = inject(Router);
  const resourceAcl = {
    resource: route.data.aclResource,
    accessLevel: route.data.aclAccessLevel,
    userType: route.data.aclUserType,
  };

  const hasAccess = _rolesService.hasResourceAccess([resourceAcl]);
  hasAccess.subscribe(result => {
    if (!result) {
      _router.navigateByUrl('/unauthorized');
    }
  });
  return hasAccess;
};
