import { Injectable } from '@angular/core';
import {
  DictionaryCollections,
  Entity,
  EntityAddress,
  EntityCollection,
  EntityCreditExposure,
  EntityCreditExposureSummary,
  EntityCreditLimit,
  EntityCreditLimitSummary,
  EntityHeaderCollection,
  EntityService,
} from '@gms/entity-api';
import { ParentEntityCollection } from '@gms/entity-api/model/parentEntityCollection';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IEntityHeaderByIdPayload } from 'app/store/entities/entities.action.interface';
import { asyncScheduler, forkJoin, Observable, of } from 'rxjs';
import {
  auditTime,
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap,
  throttle,
  throttleTime,
} from 'rxjs/operators';
import { DEBOUNCE_500 } from 'shared/consts/debounce.const';
import { cleanDateTimeModified } from 'shared/services/data-cleaner.service';
import { EPermissionOption } from '../auth/model/enums';
import {
  AddCreditLimitByEntityId,
  AddCreditLimitByEntityIdFailure,
  AddCreditLimitByEntityIdSuccess,
  CreateEntity,
  CreateEntityCreditExposure,
  CreateEntityCreditExposureFailure,
  CreateEntityCreditExposureSuccess,
  CreateEntityFailure,
  CreateEntitySuccess,
  EEntitiesActions,
  FetchAllEntitiesByIds,
  FetchAllEntitiesByIdsFailure,
  FetchAllEntitiesByIdsSuccess,
  FetchAllEntityHeaders,
  FetchAllEntityHeadersFailure,
  FetchAllEntityHeadersSuccess,
  FetchEntities,
  FetchEntitiesFailure,
  FetchEntitiesSuccess,
  FetchEntityAddresses,
  FetchEntityAddressesFailure,
  FetchEntityAddressesSuccess,
  FetchEntityById,
  FetchEntityByIdFailure,
  FetchEntityByIdSuccess,
  FetchEntityCreditExposures,
  FetchEntityCreditExposuresFailure,
  FetchEntityCreditExposuresSuccess,
  FetchEntityHeaderById,
  FetchEntityHeaderByIdFailure,
  FetchEntityHeaderByIdSuccess,
  FetchEntityPermissionAccess,
  FetchEntityPermissionAccessFailure,
  FetchEntityPermissionAccessSuccess,
  FetchParentEntitiesByEntityId,
  FetchParentEntitiesByEntityIdFailure,
  FetchParentEntitiesByEntityIdSuccess,
  GetCreditLimitsByEntityId,
  GetCreditLimitsByEntityIdFailure,
  GetCreditLimitsByEntityIdSuccess,
  GetEntityDictionary,
  GetEntityDictionaryError,
  GetEntityDictionarySuccess,
  ResetEntities,
  ResetEntitiesSuccess,
  SearchEntities,
  SearchEntitiesFailure,
  SearchEntitiesSuccess,
  SearchEntityHeaders,
  SearchEntityHeadersFailure,
  SearchEntityHeadersSuccess,
  UpdateCreditLimitsByEntityId,
  UpdateCreditLimitsByEntityIdFailure,
  UpdateCreditLimitsByEntityIdSuccess,
  UpdateEntity,
  UpdateEntityClassOverrides,
  UpdateEntityClassOverridesFailure,
  UpdateEntityClassOverridesSuccess,
  UpdateEntityCreditExposure,
  UpdateEntityCreditExposureFailure,
  UpdateEntityCreditExposureSuccess,
  UpdateEntityFailure,
  UpdateEntitySuccess,
  UpdateParentEntitiesByEntityId,
  UpdateParentEntitiesByEntityIdFailure,
  UpdateParentEntitiesByEntityIdSuccess,
} from './entities.actions';
import { sortDefinitions } from '../default-sort.definitions';

@Injectable()
export class EntitiesEffects {
  constructor(private _actions$: Actions, private _entityService: EntityService) {}

  FetchEntityById: Observable<FetchEntityByIdSuccess | FetchEntityByIdFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchEntityById>(EEntitiesActions.FetchEntitiyById),
      map((action: FetchEntityById) => action.payload),
      switchMap(payload => {
        return this._entityService.getEntityById(payload.entityId).pipe(
          map((entity: Entity) => new FetchEntityByIdSuccess({ entity })),
          catchError(error => of(new FetchEntityByIdFailure({ error })))
        );
      })
    )
  );

  FetchEntityHeaderById: Observable<
    FetchEntityHeaderByIdSuccess | FetchEntityHeaderByIdFailure
  > = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchEntityHeaderById>(EEntitiesActions.FETCH_ENTITY_HEADER_BY_ID),
      map((action: FetchEntityHeaderById) => action.payload),
      switchMap((payload: IEntityHeaderByIdPayload) => {
        return this._entityService
          .getEntityHeaders(
            null, //pageSize
            null, //pageNumber
            sortDefinitions.getEntityHeaders, //sortBy
            null, //dunsNumber
            null, //financialId
            null, //legalName
            null, //name
            [payload.entityId], //entityIds
            null, //searchPhrase
            null, //searchScope
            payload.agencyResourceName, //agencyResourceName
            null, //minPermissionOption
            payload.agencyTransactionStartDate, //agencyTransactionStartDate
            payload.agencyTransactionEndDate //agencyTransactionEndDate
          )
          .pipe(
            map(
              response =>
                new FetchEntityHeaderByIdSuccess(
                  response && response.entityHeaders && response.entityHeaders.length > 0
                    ? response.entityHeaders[0]
                    : null
                )
            ),
            catchError(error => of(new FetchEntityHeaderByIdFailure(error)))
          );
      })
    )
  );

  GetEntityDictionary = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<GetEntityDictionary>(EEntitiesActions.GetEntityDictionary),
      auditTime(debounce, scheduler),
      switchMap(() =>
        this._entityService.getDictionary().pipe(
          map((dictionary: DictionaryCollections) => new GetEntityDictionarySuccess(dictionary)),
          catchError(error => of(new GetEntityDictionaryError(error)))
        )
      )
    )
  );

  FetchEntities = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<FetchEntities>(EEntitiesActions.FetchEntities, EEntitiesActions.FetchAllEntities),
      map((action: FetchEntities) => action.payload),
      debounceTime(debounce, scheduler),
      switchMap(payload => {
        let sortQuery = ``;
        let status = ``;
        const isEntitiesFilter = !!payload.isEntitiesFilter;

        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        if(sortQuery.length === 0) {
          sortQuery = sortDefinitions.getEntityHeaders;
        }
        if (payload.status) {
          // Need to Update to forEach when multiple statuses filtering is supported from BE
          status = payload.status[0];
        }
        return this._entityService
          .getEntities(
            payload.pageSize,
            payload.pageNumber,
            sortQuery,
            payload.dunsNumber,
            payload.financialId,
            payload.legalName,
            payload.name,
            payload.entityIds,
            payload.searchPhrase
            // The status parameter was added to the swagger contract on the FE only however,
            // it was not implemented as a filter in the entity-api and would need to built out before it can be used
            //status
          )
          .pipe(
            map(
              (entityCollection: EntityCollection) =>
                new FetchEntitiesSuccess({
                  entities: entityCollection.entities,
                  totalEntityCount: entityCollection.total,
                  isEntitiesFilter,
                })
            ),
            catchError(error => of(new FetchEntitiesFailure({ error: error, isEntitiesFilter })))
          );
      })
    )
  );

  FetchAllEntitiesByIds: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAllEntitiesByIds>(EEntitiesActions.FETCH_ALL_ENTITIES_BY_IDS),
      map((action: FetchAllEntitiesByIds) => action.payload),
      switchMap(payload => {
        if (payload.entityIds.length === 0) {
          return of(new FetchAllEntitiesByIdsSuccess({ entities: [] }));
        }

        let sortQuery = ``;
        const entityIds = payload.entityIds;

        if (payload.sortDescriptors) {
          payload.sortDescriptors.forEach(sortDescriptor => {
            sortQuery = `${sortQuery}${sortDescriptor.field}+${sortDescriptor.dir}|`;
          });
        }
        if(sortQuery.length === 0) {
          sortQuery = sortDefinitions.getEntityHeaders;
        }
        const pages = payload.entityIds.length / 50;
        const entities$ = [];
        for (let i = 0; i < pages; i++) {
          entities$.push(
            this._entityService.getEntityHeaders(
              50, //pageSize
              i + 1, //pageNumber
              sortQuery, //sortBy
              undefined, //dunsNumber
              undefined, //financialId
              undefined, //legalName
              undefined, //name
              entityIds //entityIds
            )
          );
        }
        return forkJoin(entities$).pipe(
          map((entityCollections: EntityHeaderCollection[]) => {
            const entities = entityCollections.reduce(
              (accumulator, curr) => accumulator.concat(curr.entityHeaders),
              []
            );
            return new FetchAllEntitiesByIdsSuccess({ entities: entities });
          }),
          catchError(error => of(new FetchAllEntitiesByIdsFailure({ error: error })))
        );
      })
    )
  );

  ResetEntities: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ResetEntities>(EEntitiesActions.ResetEntities),
      map((action: ResetEntities) => new ResetEntitiesSuccess())
    )
  );

  SearchEntities = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<SearchEntities>(EEntitiesActions.SearchEntities),
      map((action: SearchEntities) => action.payload),
      debounceTime(debounce, scheduler),
      switchMap(payload => {
        let sortby = !!payload.sortBy ? payload.sortBy : sortDefinitions.getEntityHeaders;
        return this._entityService
          .getEntities(
            payload.pageSize,
            payload.pageNumber,
            sortby,
            payload.dunsNumber,
            null, //financialId
            null, //legalName
            null, //name
            payload.entityIds,
            payload.searchPhrase,
            payload.searchScope
          )
          .pipe(
            map(
              entityCollection => new SearchEntitiesSuccess({ entities: entityCollection.entities })
            ),
            catchError(error => {
              return of(new SearchEntitiesFailure({ error: error }));
            })
          );
      })
    )
  );

  UpdateEntity: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateEntity>(EEntitiesActions.UpdateEntity),
      map((action: UpdateEntity) => action.payload),
      switchMap(({ body, entityId }) =>
        this._entityService.updateEntity(body, entityId, 'response', true).pipe(
          map(() => new UpdateEntitySuccess()),
          catchError(error => of(new UpdateEntityFailure({ error })))
        )
      )
    )
  );

  CreateEntity: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateEntity>(EEntitiesActions.CreateEntity),
      debounceTime(500),
      map((action: CreateEntity) => action.payload),
      switchMap(({ body }) =>
        this._entityService.addEntity(body, 'body', true).pipe(
          map(entityResponse => new CreateEntitySuccess({ entity: entityResponse })),
          catchError(error => of(new CreateEntityFailure({ error })))
        )
      )
    )
  );

  UpdateEntityClassOverrides: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateEntityClassOverrides>(EEntitiesActions.UpdateEntityClassOverrides),
      map((action: UpdateEntityClassOverrides) => action.payload),
      switchMap(({ body, entityId }) =>
        this._entityService.putEntityClassOverrides(body, entityId, 'response', true).pipe(
          map(() => new UpdateEntityClassOverridesSuccess()),
          catchError(error => of(new UpdateEntityClassOverridesFailure({ error })))
        )
      )
    )
  );

  FetchParentEntitiesByEntityId: Observable<
    FetchParentEntitiesByEntityIdSuccess | FetchParentEntitiesByEntityIdFailure
  > = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchParentEntitiesByEntityId>(EEntitiesActions.FETCH_PARENT_ENTITIES_BY_ENTITY_ID),
      map((action: FetchParentEntitiesByEntityId) => action.payload),
      switchMap(payload => {
        return this._entityService.getParentEntitiesById(payload.entityId).pipe(
          map(
            (parentEntityCollection: ParentEntityCollection) =>
              new FetchParentEntitiesByEntityIdSuccess({ parentEntityCollection })
          ),
          catchError(error => of(new FetchParentEntitiesByEntityIdFailure({ error })))
        );
      })
    )
  );

  AddCreditLimitByEntityId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddCreditLimitByEntityId>(EEntitiesActions.ADD_CREDIT_LIMIT_BY_ENTITY_ID),
      map((action: AddCreditLimitByEntityId) => action.payload),
      switchMap(payload => {
        return this._entityService.addEntityCreditLimit(payload.body, payload.entityId).pipe(
          map((creditLimit: EntityCreditLimit) => {
            cleanDateTimeModified(creditLimit);
            return new AddCreditLimitByEntityIdSuccess({ creditLimit });
          }),
          catchError(error => of(new AddCreditLimitByEntityIdFailure({ error })))
        );
      })
    )
  );

  UpdateCreditLimitsByEntityId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateCreditLimitsByEntityId>(EEntitiesActions.UPDATE_CREDIT_LIMITS_BY_ENTITY_ID),
      map((action: UpdateCreditLimitsByEntityId) => action.payload),
      mergeMap(payload => {
        return forkJoin(
          payload.creditLimits.map(updatedCreditLimit =>
            this._entityService
              .updateEntityCreditLimit(
                updatedCreditLimit,
                updatedCreditLimit.entityId,
                updatedCreditLimit.entityCreditLimitId
              )
              .pipe(catchError(error => of(new UpdateCreditLimitsByEntityIdFailure(error))))
          )
        ).pipe(
          map(response => {
            const resultsById = {};
            response.forEach(
              (
                updateResult: EntityCreditLimit | UpdateCreditLimitsByEntityIdFailure,
                index: number
              ) => {
                const creditLimitId = payload.creditLimits[index].entityCreditLimitId;
                const creditLimit = updateResult as EntityCreditLimit;
                cleanDateTimeModified(creditLimit);
                resultsById[creditLimitId] = updateResult;
              }
            );
            return new UpdateCreditLimitsByEntityIdSuccess({ creditLimits: resultsById });
          })
        );
      })
    )
  );

  GetCreditLimitsByEntityId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetCreditLimitsByEntityId>(EEntitiesActions.GET_CREDIT_LIMITS_BY_ENTITY_ID),
      map((action: GetCreditLimitsByEntityId) => action.payload),
      switchMap(payload => {
        return this._entityService.getEntityCreditLimits(payload.entityId).pipe(
          map(
            (creditLimits: EntityCreditLimitSummary) =>
              new GetCreditLimitsByEntityIdSuccess({ creditLimits })
          ),
          catchError(error => of(new GetCreditLimitsByEntityIdFailure({ error })))
        );
      })
    )
  );

  UpdateParentEntitiesByEntityId: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateParentEntitiesByEntityId>(EEntitiesActions.UPDATE_PARENT_ENTITIES_BY_ENTITY_ID),
      map((action: UpdateParentEntitiesByEntityId) => action.payload),
      switchMap(payload => {
        return this._entityService.putParentEntities(payload.body, payload.entityId).pipe(
          map(() => new UpdateParentEntitiesByEntityIdSuccess()),
          catchError(error => of(new UpdateParentEntitiesByEntityIdFailure({ error })))
        );
      })
    )
  );

  fetchAllEntityHeaders$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<FetchAllEntityHeaders>(EEntitiesActions.FETCH_ALL_ENTITY_HEADERS),
      map((action: FetchAllEntityHeaders) => action),
      debounceTime(debounce, scheduler),
      switchMap(action => {
        const { sortBy } = action.payload;
        const sortByString = sortBy && sortBy.length ? `${sortBy[0].field}+${sortBy[0].dir}` : sortDefinitions.getEntityHeaders;
        return this._entityService
          .getEntityHeaders(
            action.payload.pageSize, //pageSize
            action.payload.pageNumber, //pageNumber
            sortByString, //sortBy
            action.payload.dunsNumber, //dunsNumber
            action.payload.financialId, //financialId
            action.payload.legalName, //legalName
            action.payload.name, //name
            action.payload.entityIds, //entityIds
            action.payload.searchPhrase, //searchPhrase
            action.payload.searchScope, //searchScope
            action.payload.agencyResourceName, //agencyResourceName
            action.payload.minPermissionOption, //minPermissionOption
            action.payload.agencyTransactionStartDate, //agencyTransactionStartDate
            action.payload.agencyTransactionEndDate //agencyTransactionEndDate
          )
          .pipe(
            map(response => new FetchAllEntityHeadersSuccess(response)),
            catchError(error => of(new FetchAllEntityHeadersFailure(error)))
          );
      })
    )
  );

  SearchEntityHeaders$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<SearchEntityHeaders>(EEntitiesActions.SEARCH_ENTITY_HEADERS),
      map((action: SearchEntityHeaders) => action),
      debounceTime(debounce, scheduler),
      switchMap(action => {
        const { sortBy } = action.payload;
        const sortByString = sortBy && sortBy.length ? `${sortBy[0].field}+${sortBy[0].dir}` : sortDefinitions.getEntityHeaders;
        return this._entityService
          .getEntityHeaders(
            action.payload.pageSize, //pageSize
            action.payload.pageNumber, //pageNumber
            sortByString, //sortBy
            action.payload.dunsNumber, //dunsNumber
            action.payload.financialId, //financialId
            action.payload.legalName, //legalName
            action.payload.name, //name
            action.payload.entityIds, //entityIds
            action.payload.searchPhrase, //searchPhrase
            action.payload.searchScope, //searchScope
            action.payload.agencyResourceName, //agencyResourceName
            null, //minPermissionOption
            action.payload.agencyTransactionStartDate, //agencyTransactionStartDate
            action.payload.agencyTransactionEndDate //agencyTransactionEndDate
          )
          .pipe(
            map(response => new SearchEntityHeadersSuccess(response)),
            catchError(error => of(new SearchEntityHeadersFailure(error)))
          );
      })
    )
  );

  FetchEntityCreditExposures: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchEntityCreditExposures>(EEntitiesActions.FETCH_ENTITY_CREDIT_EXPOSURES),
      map((action: FetchEntityCreditExposures) => action.payload),
      switchMap(payload => {
        const { entityId, tspId } = payload;
        return this._entityService.getEntityCreditExposure(entityId, tspId).pipe(
          map(
            (creditExposure: EntityCreditExposureSummary) =>
              new FetchEntityCreditExposuresSuccess(creditExposure)
          ),
          catchError(error => of(new FetchEntityCreditExposuresFailure(error)))
        );
      })
    )
  );

  CreateEntityCreditExposure: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateEntityCreditExposure>(EEntitiesActions.CREATE_ENTITY_CREDIT_EXPOSURE),
      map((action: CreateEntityCreditExposure) => action.payload),
      switchMap(payload => {
        return this._entityService
          .addEntityCreditExposure(payload, payload.entityCreditExposure.entityId)
          .pipe(
            map((creditExposure: EntityCreditExposure) => {
              cleanDateTimeModified(creditExposure);
              return new CreateEntityCreditExposureSuccess(creditExposure);
            }),
            catchError(error => of(new CreateEntityCreditExposureFailure(error)))
          );
      })
    )
  );

  UpdateEntityCreditExposure: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateEntityCreditExposure>(EEntitiesActions.UPDATE_ENTITY_CREDIT_EXPOSURE),
      map((action: UpdateEntityCreditExposure) => action.payload),
      switchMap(payload => {
        return this._entityService
          .updateEntityCreditExposure(
            payload,
            payload.entityCreditExposure.entityId,
            payload.entityCreditExposure.entityCreditExposureId
          )
          .pipe(
            map((creditExposure: EntityCreditExposure) => {
              cleanDateTimeModified(creditExposure);
              return new UpdateEntityCreditExposureSuccess(creditExposure);
            }),
            catchError(error => of(new UpdateEntityCreditExposureFailure(error)))
          );
      })
    )
  );

  FetchEntityAddresses: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchEntityAddresses>(EEntitiesActions.FETCH_ENTITY_ADDRESSES),
      map((action: FetchEntityAddresses) => action.payload),
      switchMap(payload => {
        return this._entityService.getEntityAddresses(payload.entityIds).pipe(
          map(
            (entityAddresses: EntityAddress[]) => new FetchEntityAddressesSuccess(entityAddresses)
          ),
          catchError(error => of(new FetchEntityAddressesFailure(error)))
        );
      })
    )
  );

  FetchEntityPermissionAccess: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchEntityPermissionAccess>(EEntitiesActions.FETCH_ENTITY_PERMISSION_ACCESS),
      switchMap((action: FetchEntityPermissionAccess) => {
        const {
          entityId,
          agencyResourceName,
          minPermissionOption,
          agencyTransactionStartDate,
          agencyTransactionEndDate,
        } = action.payload;
        let minPermissionOptionStr: 'Edit' | 'Admin' | 'ReadOnly' = null;
        switch (minPermissionOption) {
          case EPermissionOption.Edit:
            minPermissionOptionStr = 'Edit';
            break;
          case EPermissionOption.Admin:
            minPermissionOptionStr = 'Admin';
            break;
          default:
            minPermissionOptionStr = 'ReadOnly';
        }

        return this._entityService
          .getEntityHeaders(
            1, //pageSize
            1, //pageNumber
            sortDefinitions.getEntityHeaders, //sortBy
            null, //dunsNumber
            null, //financialId
            null, //legalName
            null, //name
            [entityId], //entityIds
            null, //searchPhrase
            null, //searchScope
            agencyResourceName, //agencyResourceName
            minPermissionOptionStr, //minPermissionOption
            agencyTransactionStartDate, //agencyTransactionStartDate
            agencyTransactionEndDate //agencyTransactionEndDate
          )
          .pipe(
            map(
              (entities: EntityHeaderCollection) =>
                new FetchEntityPermissionAccessSuccess({
                  hasAccess: !!entities.entityHeaders.find(entity => entity.entityId === entityId),
                })
            ),
            catchError(error => of(new FetchEntityPermissionAccessFailure({ error })))
          );
      })
    )
  );
}
