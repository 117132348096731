import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  Auction,
  AuctionActorEnum,
  AuctionDraft,
  AuctionParticipant,
  AuctionTypeEnum,
  AuctionUser,
} from '@gms/auction-api';
import { DetailRateSchedule } from '@gms/rateschedulev2-api';
import { ServiceProvider } from '@gms/tsp-api';
import { User } from '@gms/user-api';
import { LABELS } from 'app/modules/auctions/labels';
import { IAuction } from 'app/store/auctions/auctions.model';
import { EPermissionOption } from 'app/store/auth/model/enums';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EDetailRateScheduleCodes } from 'shared/consts/detail-rate-schedule.const';
import { tspShortNameMap } from 'shared/consts/tsp.const';
import { dateUtils } from 'shared/utils/date.utils';
import { formatDate } from 'shared/utils/formatDate.util';
import { isNullOrUndefined } from 'shared/utils/type.utils';

export interface IDropdownItem {
  text: string;
  value: number;
}

export enum EAuctionStatuses {
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  DRAFT = 'Draft',
  LIVE = 'Live',
  PAUSED = 'Paused',
  POSTED = 'Posted',
  READY = 'Ready',
  RESULTS_PENDING = 'Results Pending',
}

export const EAuctionStatusIds = {
  [EAuctionStatuses.DRAFT]: 1,
  [EAuctionStatuses.POSTED]: 2,
  [EAuctionStatuses.READY]: 3,
  [EAuctionStatuses.LIVE]: 4,
  [EAuctionStatuses.CLOSED]: 5,
  [EAuctionStatuses.CANCELLED]: 6,
  [EAuctionStatuses.RESULTS_PENDING]: 9,
  [EAuctionStatuses.PAUSED]: 10,
};

export enum EAuctionType {
  INTERACTIVE = 'Interactive',
  POST_AND_BID = 'Post & Bid',
  OPEN_SEASON = 'Open Season',
  POST_AND_BID_MAX_RATE = 'Post & Bid Max Rate',
  POST_AND_BID_DISCOUNTED_RATE = 'Post & Bid Discounted Rate',
  POST_AND_BID_ROFR = 'Post & Bid ROFR',
  TRADEBLOTTER = 'Tradeblotter',
  ITONLINE = 'ITOnline',
}

export enum EAuctionTypeIds {
  INTERACTIVE = 1,
  POST_AND_BID_MAX_RATE = 2,
  POST_AND_BID_DISCOUNTED_RATE = 3,
  POST_AND_BID_ROFR = 4,
  TRADEBLOTTER = 5,
  ITONLINE = 6,
}

export enum EAuctionBidDemandLevels {
  Low = 1,
  Medium = 2,
  High = 3,
}

// TODO: add to Auction reference data endpoint
export enum EAuctionSegmentTypeIds {
  Round = 1,
  Interval = 2,
  Setup = 3,
  Closing = 4,
}

export const EAuctionStatusIdMap = {
  Draft: 1,
  Posted: 2,
  Ready: 3,
  Live: 4,
  Closed: 5,
  Cancelled: 6,
  Pending: 7,
  Deferred: 8,
  'Results Pending': 9,
};

export const EAuctionIdStatusMap = {
  1: 'Draft',
  2: 'Posted',
  3: 'Ready',
  4: 'Live',
  5: 'Closed',
  6: 'Cancelled',
  7: 'Pending',
  8: 'Deferred',
  9: 'Results Pending',
  10: 'Paused',
};

export enum EAuctionEventType {
  ENTER = 'enter',
  LEAVE = 'leave',
  BID = 'bid',
  WITHDRAW = 'withdraw',
  CHAT = 'chat',
  POST_NOTICE = 'post notice',
  AUCTION_SERIES_START = 'auction series start',
  AUCTION_SERIES_END = 'auction series end',
  AUCTION_START = 'auction start',
  AUCTION_END = 'auction end',
  AUCTION_CANCEL = 'auction cancel',
  AUCTION_CLOSE = 'auction close',
  AUCTION_SEGMENT_START = 'auction segment start',
  AUCTION_SEGMENT_END = 'auction segment end',
  AUCTION_SEGMENT_PAUSE = 'auction segment pause',
  AUCTION_SEGMENT_RESUME = 'auction segment resume',
  AUCTION_SEGMENT_CREATE = 'auction segment create',
  OFFER_AWARD = 'offer award',
  ACCEPT_AWARD = 'accept award',
  REJECT_AWARD = 'reject award',
  SET_INITIAL_RATE = 'set initial rate',
  AUCTION_PREVIEW_NOTICE = 'preview notice',
  AWARD_AWARD = 'auction award',
  AUCTION_READY = 'auction ready',
  NOTICES_GENERATED = 'NOTICES_GENERATED',
}

export const EAuctionEventTypeIdMap = {
  ENTER: 1,
  LEAVE: 2,
  BID: 3,
  WITHDRAW: 4,
  CHAT: 5,
  POST_NOTICE: 6,
  AUCTION_SERIES_START: 7,
  AUCTION_SERIES_END: 8,
  AUCTION_START: 9,
  AUCTION_END: 10,
  AUCTION_CANCEL: 11,
  AUCTION_CLOSE: 12,
  AUCTION_SEGMENT_START: 13,
  AUCTION_SEGMENT_END: 14,
  AUCTION_SEGMENT_PAUSE: 15,
  AUCTION_SEGMENT_RESUME: 16,
  AUCTION_SEGMENT_CREATE: 17,
  OFFER_AWARD: 18,
  ACCEPT_AWARD: 19,
  REJECT_AWARD: 20,
  SET_INITIAL_RATE: 21,
  AUCTION_PREVIEW_NOTICE: 22,
  AWARD_AWARD: 23,
  AUCTION_READY: 25,
};

export const EAuctionNoticeTypeIdMap = {
  UPCOMING_AUCTION: 1,
  DAILY_QUANTITY: 2,
  AUCTION_RESULTS: 3,
  AUCTION_CANCELLATION: 4,
  SERIES_NOTICE: 5,
  GAS_SALE: 6,
  GAS_PURCHASE: 7,
};

export const AuctionTypeNameToAuctionTypeEnum = {
  Interactive: AuctionTypeEnum.Interactive,
  'Post & Bid Max Rate': AuctionTypeEnum.Post_and_Bid_Max_Rate,
  'Post & Bid Discounted Rate': AuctionTypeEnum.Post_and_Bid_Discounted_Rate,
  'Post & Bid ROFR': AuctionTypeEnum.Post_and_Bid_ROFR,
};

export enum EAuctionSetupFormErrors {
  FUTURE_DATE = 'futureDate',
  FUTURE_TIME = 'futureTime',
  END_DATE_BEFORE = 'beforeStart',
  WEEKEND_DATE = 'weekendDate',
  HOLIDAY_DATE = 'holidayDate',
  MAX_AUCTIONS = 'maxAuctions',
  CONTRACT_NUMBER = 'contractNumber',
  CONTRACT_PARAMS = 'contractParams',
}

export const AuctionSetupFormErrorMessages = {
  [EAuctionSetupFormErrors.FUTURE_DATE]: LABELS.START_DATE_FIELD_ERROR,
  [EAuctionSetupFormErrors.FUTURE_TIME]: LABELS.FUTURE_TIME_ERROR_MESSAGE,
  [EAuctionSetupFormErrors.END_DATE_BEFORE]: LABELS.END_DATE_FIELD_ERROR,
  [EAuctionSetupFormErrors.WEEKEND_DATE]: LABELS.WEEKEND_DATE_ERROR,
  [EAuctionSetupFormErrors.HOLIDAY_DATE]: LABELS.HOLIDAY_DATE_ERROR,
  [EAuctionSetupFormErrors.CONTRACT_NUMBER]: LABELS.POST_AND_BID_ROFR_CONTRACT_NUMBER_ERROR,
  [EAuctionSetupFormErrors.CONTRACT_PARAMS]: LABELS.POST_AND_BID_ROFR_CONTRACT_PARAMS_ERROR,
};

export const getAuctionTypeEnum = (auction: Auction): AuctionTypeEnum => {
  if (!auction || !auction.auctionTypeName) {
    return null;
  }

  return AuctionTypeNameToAuctionTypeEnum[auction.auctionTypeName];
};

export const removeEmptyValues = (obj: {}) =>
  Object.entries(obj).reduce((acc, [k, v]) => {
    if (!isNullOrUndefined(v) && v !== '') {
      acc[k] = v;
    }
    return acc;
  }, {});

export const checkControlFilled = (control: AbstractControl) =>
  control.value && control.touched && control.valid;

export const checkControlsFilled = (formGroup: UntypedFormGroup): boolean => {
  return Object.values(formGroup.controls).every(control => checkControlFilled(control));
};

export const formatCopyAuctionToNewAuctionDraft = (
  auctionDraft: AuctionDraft,
  auction: Auction
): AuctionDraft => {
  const newAuction: AuctionDraft = {
    ...auction,
    auctionSeriesFlag: false,
    serviceRequestId: auctionDraft.serviceRequestId,
    serviceRequest: auctionDraft.serviceRequest,
    auctionTypeId: auctionDraft.auctionTypeId,
    rateTypeId: auctionDraft.rateTypeId,
    contractualRofrFlag: auctionDraft.contractualRofrFlag,
    auctionDraftSettings: auction.settings,
  };

  const propsToDelete = [
    'dateTimeAuctionStart',
    'dateTimeAuctionEnd',
    'dateContractBegin',
    'datePrimaryTermEnd',
    'dateTimeModified',
    'auctionDraftId',
    'auctionId',
    'auctionDraft',
    'auctionStatusId',
    'auctionTypeName',
    'initialRate',
    'sequence',
    'status',
    'series',
    'seriesAuctionId',
    'validations',
    'settings',
  ];

  if (newAuction.serviceRequest) {
    delete newAuction['serviceRequest']['contractBeginDate'];
    delete newAuction['serviceRequest']['primaryTermDate'];
  }
  if (newAuction.auctionDraftSettings) {
    delete newAuction['auctionDraftSettings']['auctionId']; // This will be the old Id.
  }
  propsToDelete.forEach(prop => delete newAuction[prop]);

  return newAuction;
};

export const formatCopyAuctionDraftToNewAuctionDraft = (
  auctionDraft: AuctionDraft
): AuctionDraft => {
  const newAuction: AuctionDraft = {
    ...auctionDraft,
    auctionSeriesFlag: false,
    auctionDraftSettings: auctionDraft.auctionDraftSettings,
  };

  // Setting props to delete
  const propsToDelete = [
    'dateTimeAuctionStart',
    'dateTimeAuctionEnd',
    'dateContractBegin',
    'datePrimaryTermEnd',
    'dateTimeModified',
    'auctionDraftId',
  ];

  if (newAuction.serviceRequest) {
    delete newAuction['serviceRequest']['contractBeginDate'];
    delete newAuction['serviceRequest']['primaryTermDate'];
  }
  if (newAuction.auctionDraftSettings) {
    delete newAuction['auctionDraftSettings']['auctionDraftId']; // This will be the old draft Id.
  }

  propsToDelete.forEach(prop => delete newAuction[prop]);
  return newAuction;
};

export const isInteractiveAuction = (auction: Auction): boolean => {
  const { auctionTypeName } = auction;
  return auctionTypeName === EAuctionType.INTERACTIVE;
};

export const isPostAndBidAuction = (auction: IAuction): boolean => {
  if (!auction) {
    return false;
  }
  const { auctionTypeId, auctionTypeName } = auction;
  return (
    auctionTypeName === EAuctionType.POST_AND_BID_DISCOUNTED_RATE ||
    auctionTypeName === EAuctionType.POST_AND_BID_MAX_RATE ||
    auctionTypeName === EAuctionType.POST_AND_BID_ROFR ||
    auctionTypeId === EAuctionTypeIds.POST_AND_BID_DISCOUNTED_RATE ||
    auctionTypeId === EAuctionTypeIds.POST_AND_BID_MAX_RATE ||
    auctionTypeId === EAuctionTypeIds.POST_AND_BID_ROFR
  );
};

export const isPostandBidMaxOrDiscountRate = (auction: IAuction): boolean => {
  if (!auction) {
    return false;
  }
  const { auctionTypeId, auctionTypeName } = auction;
  return (
    auctionTypeName === EAuctionType.POST_AND_BID_DISCOUNTED_RATE ||
    auctionTypeName === EAuctionType.POST_AND_BID_MAX_RATE ||
    auctionTypeId === EAuctionTypeIds.POST_AND_BID_DISCOUNTED_RATE ||
    auctionTypeId === EAuctionTypeIds.POST_AND_BID_MAX_RATE
  );
};

export const postAndBidTypeIds = new Set([2, 3, 4]);

export const isSeasonalRateAuction = (auction: IAuction) =>
  !!auction &&
  !!auction.detailRateSchedule &&
  !!auction.tsp &&
  auction.tsp.shortName === tspShortNameMap.TEXAS_GAS &&
  auction.detailRateSchedule.code === EDetailRateScheduleCodes.FSS;

export const createParticipantIdToParticipantMap = (participants: AuctionParticipant[]) => {
  if (!participants || !participants.length) {
    return {};
  }

  return participants.reduce<{}>((acc, participant) => {
    acc[participant.auctionParticipantId] = participant;
    return acc;
  }, {});
};

export const createUserIdToUserMap = (users: AuctionUser[]) => {
  if (!users || !users.length) {
    return {};
  }

  return users.reduce<{}>((acc, user) => {
    acc[user.userId] = user;
    return acc;
  }, {});
};

export const getAuctionTitle = (auction: IAuction): string => {
  if (!auction || !auction.detailRateSchedule || !auction.tsp) {
    return null;
  }

  const { dateContractBegin, datePrimaryTermEnd, detailRateSchedule: drs, tsp } = auction;
  let quantityOffered = auction.quantityOffered;
  const isStfRateSchedule = drs.code === EDetailRateScheduleCodes.STF;
  const { code } = drs;
  const { shortName } = tsp;

  const dateBegin = dateContractBegin
    ? dateUtils.getDateStringAsMMDDYYYY_WithLeadingZeros(dateContractBegin)
    : '';
  const dateEnd = datePrimaryTermEnd
    ? dateUtils.getDateStringAsMMDDYYYY_WithLeadingZeros(datePrimaryTermEnd)
    : '';
  if (isStfRateSchedule) {
    const summerQuantity = auction.settings.maximumQuantity;
    const winterQuantity = auction.settings.winterMaximumQuantity;
    quantityOffered = winterQuantity > summerQuantity ? winterQuantity : summerQuantity;
  }

  const quantity = quantityOffered ? quantityOffered.toLocaleString('en-US') : '';

  return `${shortName} ${code} | Term: ${dateBegin} - ${dateEnd} | ${quantity} Dth`;
};

export function combineAuctionData(
  detailRateSchedules$: Observable<DetailRateSchedule[]>,
  tsps$: Observable<ServiceProvider[]>
) {
  return (auction$: Observable<IAuction>): Observable<IAuction> =>
    combineLatest([auction$, detailRateSchedules$, tsps$]).pipe(
      filter(
        ([auction, detailRateSchedules, tsps]: [
          IAuction,
          DetailRateSchedule[],
          ServiceProvider[]
        ]) =>
          Boolean(
            auction && detailRateSchedules && detailRateSchedules.length && tsps && tsps.length
          )
      ),
      map(([auction, detailRateSchedules, tsps]) => {
        if (
          !auction.detailRateSchedule ||
          auction.detailRateScheduleId !== auction.detailRateSchedule.detailRateScheduleId
        ) {
          auction.detailRateSchedule = detailRateSchedules.find(
            drs => drs.detailRateScheduleId === auction.detailRateScheduleId
          );
        }

        if (!auction.tsp || auction.tspId !== auction.tsp.providerId) {
          auction.tsp = tsps.find(tsp => tsp.providerId === auction.tspId);
        }

        return auction;
      })
    );
}

// TODO: get from rfs api
export const rfsQuantityTypeToId = {
  maxTotalParkLoanQuantity: 20,
  unparkPaybackTermMaxQuantityPerDay: 22,
  maxStorageQuantity: 34,
  mdq: 1,
  summerMdq: 9,
  interruptibleStorageQuantity: 84,
};

export const isAuctioneer = (user: User | AuctionUser): boolean => {
  const { isInternal, userRoles } = user;
  if (!isInternal || !userRoles || !userRoles.length) return false;
  return !!userRoles.find(
    role => role.name === 'Auction' && role.permissionOptionId === EPermissionOption.Edit
  );
};

export const isAuctionBidder = (user: User | AuctionUser): boolean => {
  const { userRoles, isInternal } = user;
  if (isInternal || !userRoles || !userRoles.length) return false;
  return !!userRoles.find(
    role => role.name === 'Auction' && role.permissionOptionId === EPermissionOption.Edit
  );
};

export const getAuctionActorType = (user: User | AuctionUser): string => {
  if (isAuctioneer(user)) {
    return AuctionActorEnum.Auctioneer;
  }

  return AuctionActorEnum.Bidder;
};

export const createChannelAddress = (user: User, auction: Auction, eventType: string) => {
  const actorType = user ? getAuctionActorType(user).replace(/\s/g, '') : null;
  const userParam =
    actorType === AuctionActorEnum.Bidder ? user.userId : LABELS.CHANNEL_ADDRESS_ANY;

  return `auctionRoom/${actorType}/${eventType}/${auction.auctionId}/${userParam}`;
};

export const consolidateAuctionStartDateAndTime = (date: Date, time: Date): any => {
  if (!date) return null;

  const startDate = new Date(date);
  if (time) {
    startDate.setHours(time.getHours(), time.getMinutes(), 0, 0);
  }

  dateUtils.setLocalHourFromCstHour(startDate, time ? time.getHours() : 0);

  return formatDate(startDate, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', dateUtils.getCstOffsetString());
};
