import { appConfig, LOCAL_ENV_NAME } from 'config/app-config'; // Don't change this.
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';

/**
 * this class prevents is used to notify developers of deprecated component use in
 * the development Environment.
 */

// this type makes it so when someone leaves the project we know what holes need to be filled.
export enum ECommonComponentOwner {
  KEVIN_ORLANDO = 'Kevin Orlando',
  MAC_CARTER = 'Mac Carter',
  TRANG_DO = 'Trang Do',
  NONE = 'none',
} // Add your name here.

// Extends HasSubSubscriptions in order to maintain extension compatibility.
export class IsDeprecatedComponent extends HasSubscriptions {
  constructor(
    public currentSelector = 'Old Selector',
    public updateToSelector = 'New Selector',
    public owners: Array<ECommonComponentOwner> = [ECommonComponentOwner.NONE]
  ) {
    super();

    if (appConfig.environment === LOCAL_ENV_NAME) {
      const printableOwners = owners.reduce((previous, current, index) => {
        if (index < this.owners.length - 1) {
          return previous + current + ' or ';
        } else {
          return previous + current;
        }
      }, '');

      console.warn(
        ' ' +
          '\n╔═╗╔═╗╔╦╗╔═╗╔═╗╔╗╔╔═╗╔╗╔╔╦╗  ╔╦╗╔═╗╔═╗╦═╗╔═╗╔═╗╔═╗╔╦╗╔═╗╔╦╗\n║  ║ ║║║║╠═╝║ ║║║║║╣ ║║║ ║    ║║║╣ ╠═╝╠╦╝║╣ ║  ╠═╣ ║ ║╣  ║║\n╚═╝╚═╝╩ ╩╩  ╚═╝╝╚╝╚═╝╝╚╝ ╩   ═╩╝╚═╝╩  ╩╚═╚═╝╚═╝╩ ╩ ╩ ╚═╝═╩╝\n' +
          '\n<' +
          this.currentSelector +
          '> selector is DEPRECATED please transition to <' +
          this.updateToSelector +
          '>' +
          '\nsee the README.md associated with the component class for upgrade help.' +
          '\nfor additional help reach out to ' +
          printableOwners +
          '.' +
          '\n\n'
      );
    }
  }
}
