import { LevelTemplate, Quantity, ServiceRequest } from '@gms/servicerequest-api';
import { setNoDataMessaging } from 'app/modules/contracts/utils/format-table-data.utils';
import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { cleanDateWithYYYY_MM_DD } from 'shared/services/data-cleaner.service';
import { dateUtils } from 'shared/utils/date.utils';
import { gridStatusColorMapperFactory } from 'shared/utils/grid.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { RequestStatusEnum } from './service-requests-status.enum';

export const serviceRequestColorMap = {
  [RequestStatusEnum.Draft]: TagColor.BLUE,
  [RequestStatusEnum.Submitted]: TagColor.DARK_BLUE,
  [RequestStatusEnum.Withdrawn]: TagColor.GRAY,
  [RequestStatusEnum.Invalid]: TagColor.RED,
  [RequestStatusEnum.Approved]: TagColor.GREEN,
};

export const mapServiceRequestToGridServiceRequest = gridStatusColorMapperFactory(
  'statusId',
  serviceRequestColorMap
);

export const checkSubjectToBidding = (serviceRequest: ServiceRequest) => {
  let isSubjectToBidding = true;
  if (
    serviceRequest.contractQuantities &&
    serviceRequest.contractQuantities.calculatedQuantities &&
    serviceRequest.contractQuantities.calculatedQuantities.length > 0
  ) {
    isSubjectToBidding = false;
    serviceRequest.contractQuantities.calculatedQuantities.forEach(contractQuantity => {
      if (
        !isNullOrUndefined(contractQuantity.eligible) &&
        !contractQuantity.eligible &&
        serviceRequest.serviceRequestId
      ) {
        isSubjectToBidding = true;
      }
    });
  }
  return isSubjectToBidding;
};

export const extractLocationIdsForServiceRequest = (
  serviceRequest: ServiceRequest,
  pressureLevels: LevelTemplate[] = null
): number[] => {
  if (!serviceRequest) {
    return [];
  }

  const locationIds: Set<number> = new Set();
  const { routes, storageLocations, pressureLevel } = serviceRequest;

  if (routes) {
    routes.forEach(({ primaryPoints, paths }) => {
      if (primaryPoints) {
        primaryPoints.forEach(point => {
          locationIds.add(point.locationId);
        });
      }
      if (paths) {
        paths.forEach(path => {
          locationIds.add(path.beginPathLocationId);
          locationIds.add(path.endPathLocationId);
        });
      }
    });
  }

  if (storageLocations && storageLocations.storageLocationItems) {
    serviceRequest.storageLocations.storageLocationItems.forEach(({ locationId }) => {
      locationIds.add(locationId);
    });
  }

  if (
    pressureLevel &&
    pressureLevel.ratchetLevelValues &&
    pressureLevel.ratchetLevelValues.length &&
    pressureLevels &&
    pressureLevels.length
  ) {
    const levelTemplateIdSet = new Set(
      pressureLevel.ratchetLevelValues.map(ratchetLevelValue => ratchetLevelValue.levelTemplateId)
    );

    pressureLevels
      .filter(({ levelTemplateId }) => levelTemplateIdSet.has(levelTemplateId))
      .forEach(({ locationId }) => {
        locationIds.add(locationId);
      });
  }

  return Array.from(locationIds).filter(Boolean);
};

export const sanitizeServiceRequest = (payload: ServiceRequest): void => {
  const { routes, requestedRate, quantities } = payload;
  payload.contractBeginDate = !isNullOrUndefined(payload.contractBeginDate)
    ? typeof payload.contractBeginDate === 'string'
      ? payload.contractBeginDate
      : cleanDateWithYYYY_MM_DD(new Date(payload.contractBeginDate))
    : null;
  payload.primaryTermDate = !isNullOrUndefined(payload.primaryTermDate)
    ? typeof payload.primaryTermDate === 'string'
      ? payload.primaryTermDate
      : cleanDateWithYYYY_MM_DD(new Date(payload.primaryTermDate))
    : null;

  routes.forEach(route => {
    const { primaryPoints, paths } = route;
    if (primaryPoints) {
      primaryPoints.forEach(pp => {
        pp.expirationDate = !isNullOrUndefined(pp.expirationDate)
          ? typeof pp.expirationDate === 'string'
            ? pp.expirationDate
            : cleanDateWithYYYY_MM_DD(new Date(pp.expirationDate))
          : null;
        pp.effectiveDate = !isNullOrUndefined(pp.effectiveDate)
          ? typeof pp.effectiveDate === 'string'
            ? pp.effectiveDate
            : cleanDateWithYYYY_MM_DD(new Date(pp.effectiveDate))
          : null;
      });
    }
    if (paths) {
      paths.forEach(path => {
        path.dateEffective = !isNullOrUndefined(path.dateEffective)
          ? typeof path.dateEffective === 'string'
            ? path.dateEffective
            : cleanDateWithYYYY_MM_DD(new Date(path.dateEffective))
          : null;
        path.dateExpiration = !isNullOrUndefined(path.dateExpiration)
          ? typeof path.dateExpiration === 'string'
            ? path.dateExpiration
            : cleanDateWithYYYY_MM_DD(new Date(path.dateExpiration))
          : null;
      });
    }
  });

  if (quantities && quantities.length) {
    quantities.forEach((quantity: Quantity) => {
      quantity.dateEffective = !isNullOrUndefined(quantity.dateEffective)
        ? typeof quantity.dateEffective === 'string'
          ? quantity.dateEffective
          : cleanDateWithYYYY_MM_DD(new Date(quantity.dateEffective))
        : null;
      quantity.dateExpire = !isNullOrUndefined(quantity.dateExpire)
        ? typeof quantity.dateExpire === 'string'
          ? quantity.dateExpire
          : cleanDateWithYYYY_MM_DD(new Date(quantity.dateExpire))
        : null;
    });
  }

  if (requestedRate && requestedRate.slicedRates) {
    requestedRate.slicedRates.forEach(slicedRate => {
      const { rateSlices } = slicedRate;
      if (!rateSlices || !rateSlices.length) {
        return;
      }
      rateSlices.forEach(rateSlice => {
        rateSlice.effectiveDate = !isNullOrUndefined(rateSlice.effectiveDate)
          ? typeof rateSlice.effectiveDate === 'string'
            ? rateSlice.effectiveDate
            : cleanDateWithYYYY_MM_DD(new Date(rateSlice.effectiveDate))
          : null;
        rateSlice.expireDate = !isNullOrUndefined(rateSlice.expireDate)
          ? typeof rateSlice.expireDate === 'string'
            ? rateSlice.expireDate
            : cleanDateWithYYYY_MM_DD(new Date(rateSlice.expireDate))
          : null;
      });
    });
  }
};
