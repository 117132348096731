import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  LineResultAssociationType,
  ModelLine,
  ModelLineSequence,
  ModelStatuses,
  PipelineModelDelta,
  PipelineModelLookupService,
  PipelineModelsService,
} from '@gms/pipeline-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { IAppState } from 'app/store/app/app.state';
import { selectTsp } from 'app/store/header/header.selectors';
import {
  ActivateDraft,
  ActivateDraftFailure,
  ActivateDraftSuccess,
  CreateDraft,
  CreateDraftFailure,
  CreateDraftSuccess,
  CreateLine,
  CreateLineFailure,
  CreateLineSuccess,
  CreateSequence,
  CreateSequenceFailure,
  CreateSequenceSuccess,
  DeletePipelineModel,
  DeletePipelineModelFailure,
  DeletePipelineModelSuccess,
  EPipelineModelsActions,
  FetchAvailableModelSequenceLocations,
  FetchAvailableModelSequenceLocationsFailure,
  FetchAvailableModelSequenceLocationsSuccess,
  FetchLineSequencesByIds,
  FetchLineSequencesByIdsFailure,
  FetchLineSequencesByIdsSuccess,
  FetchLookupData,
  FetchLookupDataFailure,
  FetchLookupDataSuccess,
  FetchModelLines,
  FetchModelLineSequences,
  FetchModelLineSequencesByIds,
  FetchModelLineSequencesByIdsFailure,
  FetchModelLineSequencesByIdsSuccess,
  FetchModelLineSequencesFailure,
  FetchModelLineSequencesSuccess,
  FetchModelLinesFailure,
  FetchModelLinesSuccess,
  FetchModelSequenceLocations,
  FetchModelSequenceLocationsFailure,
  FetchModelSequenceLocationsSuccess,
  FetchPipelineModelDelta,
  FetchPipelineModelDeltaFailure,
  FetchPipelineModelDeltaSuccess,
  FetchPipelineModels,
  FetchPipelineModelsAndSavePriority,
  FetchPipelineModelsFailure,
  FetchPipelineModelsSuccess,
  FetchUnassociatedModelLines,
  FetchUnassociatedModelLinesFailure,
  FetchUnassociatedModelLinesSuccess,
  SaveDraft,
  SaveDraftFailure,
  SaveDraftSuccess,
  SetHistoricalPipelineModel,
  UpdateLine,
  UpdateLineFailure,
  UpdateLineSuccess,
  UpdatePipelineModel,
  UpdatePipelineModelFailure,
  UpdatePipelineModelSuccess,
  UpdateSequence,
  UpdateSequenceFailure,
  UpdateSequenceSuccess,
} from './pipeline-models.actions';
import { selectPipelineModelsState } from './pipeline-models.selectors';
import { EPipelineError } from './pipeline-models.state';
import { mapModelLine, mapModelLineSequence } from './pipeline-models.utils';

@Injectable()
export class PipelineModelsEffects {
  constructor(
    private _router: Router,
    private _actions$: Actions,
    private _pipelineModelsService: PipelineModelsService,
    private _pipelineLookupDataService: PipelineModelLookupService,
    private _store: Store<IAppState>
  ) {}
  // pipelineModelId?
  FetchPipelineModels$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchPipelineModels>(
        EPipelineModelsActions.FETCH_PIPELINE_MODELS,
        EPipelineModelsActions.SET_PIPELINE_MODEL_PRIORITY
      ),
      map((action: FetchPipelineModels | FetchPipelineModelsAndSavePriority) => action.payload),
      withLatestFrom(this._store.pipe(select(selectPipelineModelsState))),
      switchMap(
        ([
          {
            tspId,
            pipelineModelPriority,
            associationType,
            pageSize,
            pageNumber,
            sortDescriptors,
            pipelineModelStatus,
          },
          pipeLineModelsState,
        ]) =>
          this._pipelineModelsService
            .getPipelineModels(
              tspId,
              pageSize,
              pageNumber,
              sortDescriptors.map(
                sortDescriptor => `${sortDescriptor.field}+${sortDescriptor.dir}`
              ),
              pipelineModelStatus
            )
            .pipe(
              switchMap(pipelineModelCollection => {
                const chainedActions: Array<Action> = [
                  new FetchPipelineModelsSuccess({ pipelineModelCollection }),
                ];
                pipelineModelPriority =
                  pipelineModelPriority || pipeLineModelsState.pipelineModelPriority;
                if (pipelineModelPriority) {
                  for (const status of pipelineModelPriority as Array<ModelStatuses>) {
                    if (status) {
                      const pipeLineVersionWithCurrentStatus = pipelineModelCollection.pipelineModels.find(
                        model => model.status === status
                      );
                      if (pipeLineVersionWithCurrentStatus) {
                        chainedActions.push(
                          new FetchModelLines({
                            modelId: pipeLineVersionWithCurrentStatus.pipelineModelId,
                            tspId,
                            associationType,
                            areHistoricalModelLines: false,
                          }),
                          new FetchUnassociatedModelLines({
                            modelId: pipeLineVersionWithCurrentStatus.pipelineModelId,
                            tspId,
                          })
                        );
                        break;
                      }
                    }
                  }
                }
                return chainedActions;
              }),
              catchError(error => of(new FetchPipelineModelsFailure({ error })))
            )
      )
    )
  );

  FetchModelLines$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchModelLines>(
        EPipelineModelsActions.FETCH_MODEL_LINES,
        EPipelineModelsActions.SET_HISTORICAL_PIPELINE_MODEL
      ),
      map((action: FetchModelLines | SetHistoricalPipelineModel) => action.payload),
      mergeMap(payload =>
        this._pipelineModelsService
          .getModelLines(
            payload['modelId'] || payload['pipelineModel'].pipelineModelId,
            payload.tspId,
            null,
            payload.associationType || LineResultAssociationType.Associated
          )
          .pipe(
            map(
              modelLines =>
                new FetchModelLinesSuccess({
                  modelLines: modelLines.map(mapModelLine),
                  areHistoricalModelLines: payload.areHistoricalModelLines,
                })
            ),
            catchError(error => of(new FetchModelLinesFailure({ error: error })))
          )
      )
    )
  );

  FetchUnassociatedModelLines$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchUnassociatedModelLines>(EPipelineModelsActions.FETCH_UNASSOCIATED_MODEL_LINES),
      map((action: FetchUnassociatedModelLines) => action.payload),
      switchMap(({ modelId, tspId }) =>
        this._pipelineModelsService
          .getModelLines(modelId, tspId, null, LineResultAssociationType.Unassociated)
          .pipe(
            map(
              (unassociatedModelLines: Array<ModelLine>) =>
                new FetchUnassociatedModelLinesSuccess({
                  unassociatedModelLines: unassociatedModelLines.map(mapModelLine),
                })
            ),
            catchError(error => of(new FetchUnassociatedModelLinesFailure({ error })))
          )
      )
    )
  );

  FetchModelLineSequences$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchModelLineSequences>(EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES),
      map((action: FetchModelLineSequences) => action.payload),
      switchMap(({ modelId, lineId }) =>
        this._pipelineModelsService.getModelSequencesByModelId(modelId, lineId).pipe(
          map(
            modelSequences =>
              new FetchModelLineSequencesSuccess({
                lineId,
                modelSequences: modelSequences.map(mapModelLineSequence),
              })
          ),
          catchError(error => of(new FetchModelLineSequencesFailure({ lineId, error })))
        )
      )
    )
  );

  FetchModelLineSequencesByIds$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchModelLineSequencesByIds>(
        EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS
      ),
      map((action: FetchModelLineSequencesByIds) => action),
      switchMap(payload =>
        forkJoin(
          payload.lineModels.map(lineModel =>
            this._pipelineModelsService
              .getModelSequencesByModelId(lineModel.modelId, lineModel.lineId)
              .pipe(catchError(error => of(error)))
          )
        ).pipe(
          map((lineSequences: ModelLineSequence[], i: number) => {
            return new FetchModelLineSequencesByIdsSuccess({
              lineId: payload.lineModels.map(modelLine => modelLine.lineId),
              modelLineSequencesByIds: lineSequences,
            });
          }),
          catchError(error => {
            return of(new FetchModelLineSequencesByIdsFailure(error));
          })
        )
      )
    )
  );

  FetchModelSequenceLocations$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchModelSequenceLocations>(EPipelineModelsActions.FETCH_MODEL_SEQUENCE_LOCATIONS),
      map((action: FetchModelSequenceLocations) => action.payload),
      switchMap(({ modelId, lineId, sequenceId }) =>
        this._pipelineModelsService.getModelLocsByModelSequenceId(modelId, lineId, sequenceId).pipe(
          map(
            modelSequenceLocations =>
              new FetchModelSequenceLocationsSuccess({ sequenceId, modelSequenceLocations })
          ),
          catchError(error => of(new FetchModelSequenceLocationsFailure({ sequenceId, error })))
        )
      )
    )
  );

  FetchAvailableModelSequenceLocations$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAvailableModelSequenceLocations>(
        EPipelineModelsActions.FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS
      ),
      switchMap(action =>
        this._pipelineModelsService
          .getAvailableSequenceLocations(
            action.payload.pipelineSystemId,
            action.payload.tspId,
            action.payload.zoneId,
            action.payload.pmVersionId,
            action.payload.modelSequenceId
          )
          .pipe(
            map(locations => new FetchAvailableModelSequenceLocationsSuccess({ locations })),
            catchError(error => of(new FetchAvailableModelSequenceLocationsFailure({ error })))
          )
      )
    )
  );

  CreateModelLine$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateLine>(EPipelineModelsActions.CREATE_LINE),
      map((action: CreateLine) => action.payload),
      switchMap(({ modelLine }) =>
        this._pipelineModelsService.postModelLine(modelLine).pipe(
          map(() => {
            return new CreateLineSuccess();
          }),
          tap(() => {
            this._router.navigate(['/pipeline-model']);
          }),
          catchError(error =>
            of(
              new CreateLineFailure({
                error,
                validationError: this.mapHttpErrorToPipelineError(error),
              })
            )
          )
        )
      )
    )
  );

  UpdateModelLine$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateLine>(EPipelineModelsActions.UPDATE_LINE),
      map((action: UpdateLine) => action.payload),
      switchMap(({ modelLine }) =>
        this._pipelineModelsService.putModelLine(modelLine, modelLine.modelLineId).pipe(
          map(() => {
            return new UpdateLineSuccess();
          }),
          tap(() => {
            this._router.navigate(['/pipeline-model']);
          }),
          catchError(error =>
            of(
              new UpdateLineFailure({
                error,
                validationError: this.mapHttpErrorToPipelineError(error),
              })
            )
          )
        )
      )
    )
  );

  CreateModelLineSequence$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateSequence>(EPipelineModelsActions.CREATE_SEQUENCE),
      map((action: CreateSequence) => action.payload),
      switchMap(({ newSequence, pipelineId, line }) =>
        this._pipelineModelsService
          .postModelLineSequence(newSequence, pipelineId, line.modelLineId)
          .pipe(
            map(() => new CreateSequenceSuccess()),
            tap(() => this._router.navigate(['/pipeline-model'])),
            catchError(error => of(new CreateSequenceFailure({ error })))
          )
      )
    )
  );

  UpdateModelLineSequence$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateSequence>(EPipelineModelsActions.UPDATE_SEQUENCE),
      map((action: UpdateSequence) => action.payload),
      switchMap(({ modelLineSequence, pipelineId, line }) =>
        this._pipelineModelsService
          .putModelLineSequence(
            modelLineSequence,
            pipelineId,
            line.modelLineId,
            modelLineSequence.modelLineSequenceId
          )
          .pipe(
            map(() => new UpdateSequenceSuccess()),
            tap(() => this._router.navigate(['/pipeline-model'])),
            catchError(error => of(new UpdateSequenceFailure({ error })))
          )
      )
    )
  );

  ActivateDraft$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ActivateDraft>(EPipelineModelsActions.ACTIVATE_DRAFT),
      map((action: ActivateDraft) => action.payload),
      withLatestFrom(
        this._store.pipe(select(selectTsp)),
        this._store.pipe(select(selectPipelineModelsState))
      ),
      switchMap(([{ pipelineModel }, tsp, pipelineModelsState]) =>
        this._pipelineModelsService
          .putPipelineModel(pipelineModel, pipelineModel.pipelineModelId)
          .pipe(
            switchMap(() => [
              new ActivateDraftSuccess(),
              new FetchPipelineModelsAndSavePriority({
                pageNumber: pipelineModelsState.pagination.pageNumber,
                pageSize: pipelineModelsState.pagination.pageSize,
                sortDescriptors: pipelineModelsState.sortDescriptors,
                pipelineModelPriority:
                  pipelineModel.status === ModelStatuses.Active
                    ? [ModelStatuses.Active, ModelStatuses.Pending]
                    : [ModelStatuses.Pending, ModelStatuses.Active],
                tspId: tsp.providerId,
              }),
            ]),
            catchError(error => of(new ActivateDraftFailure({ error })))
          )
      )
    )
  );

  UpdatePipelineModel$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdatePipelineModel>(EPipelineModelsActions.UPDATE_PIPELINE_MODEL),
      map((action: UpdatePipelineModel) => action.payload),
      withLatestFrom(
        this._store.pipe(select(selectTsp)),
        this._store.pipe(select(selectPipelineModelsState))
      ),
      switchMap(([{ pipelineModel }, tsp, pipelineModelsState]) =>
        this._pipelineModelsService
          .putPipelineModel(pipelineModel, pipelineModel.pipelineModelId)
          .pipe(
            switchMap(() => [
              new UpdatePipelineModelSuccess(),
              new FetchPipelineModelsAndSavePriority({
                pageNumber: pipelineModelsState.pagination.pageNumber,
                pageSize: pipelineModelsState.pagination.pageSize,
                sortDescriptors: pipelineModelsState.sortDescriptors,
                pipelineModelPriority:
                  pipelineModel.status === ModelStatuses.Active
                    ? [ModelStatuses.Active, ModelStatuses.Draft]
                    : [ModelStatuses.Draft, ModelStatuses.Active],
                tspId: tsp.providerId,
              }),
            ]),
            catchError(error => of(new UpdatePipelineModelFailure({ error })))
          )
      )
    )
  );

  SaveDraft$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveDraft>(EPipelineModelsActions.SAVE_DRAFT),
      map((action: SaveDraft) => action.payload),
      switchMap(({ pipelineModel }) =>
        this._pipelineModelsService
          .putPipelineModel(pipelineModel, pipelineModel.pipelineModelId)
          .pipe(
            map(() => new SaveDraftSuccess()),
            catchError(error => of(new SaveDraftFailure({ error })))
          )
      )
    )
  );

  CreateDraft$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateDraft>(EPipelineModelsActions.CREATE_DRAFT),
      map((action: CreateDraft) => action.payload),
      switchMap(({ tspId, modelId }) =>
        this._pipelineModelsService.postPipelineModels(modelId).pipe(
          switchMap(pipelineModel => {
            const chainedActions: Array<Action> = [
              new CreateDraftSuccess({ pipelineModel }),
              new FetchModelLines({
                modelId: pipelineModel.pipelineModelId,
                tspId,
                associationType: LineResultAssociationType.Associated,
                areHistoricalModelLines: false,
              }),
            ];
            return chainedActions;
          }),
          catchError(error => of(new CreateDraftFailure({ error })))
        )
      )
    )
  );

  DeletePipelineModel$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<DeletePipelineModel>(EPipelineModelsActions.DELETE_PIPELINE_MODEL),
      map((action: DeletePipelineModel) => action.payload),
      withLatestFrom(this._store.pipe(select(selectPipelineModelsState))),
      switchMap(([{ tspId, pipelineModel }, pipelineModelsState]) =>
        this._pipelineModelsService.deletePipelineModel(pipelineModel.pipelineModelId).pipe(
          switchMap(() => [
            new DeletePipelineModelSuccess(),
            new FetchPipelineModelsAndSavePriority({
              pageNumber: pipelineModelsState.pagination.pageNumber,
              pageSize: pipelineModelsState.pagination.pageSize,
              sortDescriptors: pipelineModelsState.sortDescriptors,
              pipelineModelPriority: [ModelStatuses.Active],
              tspId,
            }),
          ]),
          catchError(error => of(new DeletePipelineModelFailure({ error })))
        )
      )
    )
  );

  FetchPipelineModelDelta$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchPipelineModelDelta>(EPipelineModelsActions.FETCH_PIPELINE_MODEL_DELTA),
      map((action: FetchPipelineModelDelta) => action.payload),
      switchMap(({ pipelineModelId, compareToPipelineModelId }) =>
        this._pipelineModelsService
          .getPipelineModelDelta(pipelineModelId, compareToPipelineModelId)
          .pipe(
            map(
              (pipelineModelDelta: PipelineModelDelta) =>
                new FetchPipelineModelDeltaSuccess({ pipelineModelDelta })
            ),
            catchError(error => of(new FetchPipelineModelDeltaFailure({ error })))
          )
      )
    )
  );

  fetchLookupData$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchLookupData>(EPipelineModelsActions.FETCH_LOOKUP_DATA),
      switchMap((action: FetchLookupData) =>
        this._pipelineLookupDataService.getLookupData().pipe(
          map(lookupData => new FetchLookupDataSuccess({ lookupData })),
          catchError(error => of(new FetchLookupDataFailure({ error })))
        )
      )
    )
  );

  fetchLineSequencesByIds$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchLineSequencesByIds>(EPipelineModelsActions.FETCH_LINE_SEQUENCES_BY_IDS),
      switchMap((action: FetchLineSequencesByIds) =>
        this._pipelineModelsService
          .getLineSequences(
            action.payload.lineSequenceIds,
            action.payload.locationIds,
            action.payload.pmVersionId
          )
          .pipe(
            map(lineSequenceDetails => new FetchLineSequencesByIdsSuccess({ lineSequenceDetails })),
            catchError(error => of(new FetchLineSequencesByIdsFailure({ error })))
          )
      )
    )
  );

  private mapHttpErrorToPipelineError(error: HttpErrorResponse): EPipelineError {
    let pipelineError: EPipelineError;

    switch (error.error) {
      case 'LineCode': {
        pipelineError = EPipelineError.LINE_CODE_ERROR;
        break;
      }
      case 'LineName': {
        pipelineError = EPipelineError.LINE_NAME_ERROR;
        break;
      }
      default: {
        pipelineError = EPipelineError.API_ERROR;
        break;
      }
    }

    return pipelineError;
  }
}
