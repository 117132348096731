import { AllocationsActions, EAllocationsActions } from './allocations.actions';
import { IAllocationsState, initialAllocationsState } from './allocations.state';

export function allocationsReducers(
  state = initialAllocationsState,
  action: AllocationsActions
): IAllocationsState {
  switch (action.type) {
    case EAllocationsActions.EXPORT_ALLOCATION_DETAILS:
      return {
        ...state,
        exportAllocationDetails: { loading: true, httpError: null },
      };

    case EAllocationsActions.EXPORT_ALLOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        exportAllocationDetails: { loading: false, httpError: null },
      };

    case EAllocationsActions.EXPORT_ALLOCATION_DETAILS_FAILURE:
      return {
        ...state,
        exportAllocationDetails: { loading: false, httpError: action.payload },
      };

    case EAllocationsActions.FETCH_ALLOCATIONS:
      return {
        ...state,
        loading: true,
        error: null,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
        allocations: [],
        totalAllocationsCount: null,
        operatorId: action.payload.operatorId,
      };

    case EAllocationsActions.FETCH_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        allocations: action.payload.allocations,
        loading: false,
        error: null,
        totalAllocationsCount: action.payload.totalAllocationsCount,
      };

    case EAllocationsActions.FETCH_ALLOCATIONS_FAILURE:
      return {
        ...state,
        allocations: [],
        loading: false,
        error: action.payload.error,
        totalAllocationsCount: null,
      };

    case EAllocationsActions.CLEAR_ALLOCATIONS:
      return {
        ...state,
        allocations: [],
        loading: false,
        error: null,
        totalAllocationsCount: null,
      };

    /**
     * Allocation Detail
     */
    case EAllocationsActions.FETCH_ALLOCATION_DETAILS:
      return {
        ...state,
        allocationDetailsLoading: true,
        allocationDetailsError: null,
        allocationDetailsPagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        allocationDetailsSortDescriptors: action.payload.sortDescriptors,
        allocationDetails: [],
        totalAllocationDetailsCount: null,
      };

    case EAllocationsActions.FETCH_ALLOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        allocationDetails: action.payload.allocationDetails,
        allocationDetailsLoading: false,
        allocationDetailsError: null,
        totalAllocationDetailsCount: action.payload.totalAllocationsCount,
      };

    case EAllocationsActions.FETCH_ALLOCATION_DETAILS_FAILURE:
      return {
        ...state,
        allocationDetails: [],
        allocationDetailsLoading: false,
        allocationDetailsError: action.payload.error,
        totalAllocationDetailsCount: null,
      };

    /**
     * Allocation Method
     */
    case EAllocationsActions.FETCH_ALLOCATIONS_METHOD:
      return {
        ...state,
        allocationMethods: [],
        allocationMethodLoading: true,
        allocationMethodError: null,
      };

    case EAllocationsActions.FETCH_ALLOCATIONS_METHOD_SUCCESS:
      return {
        ...state,
        allocationMethods: action.payload,
        allocationMethodLoading: false,
        allocationMethodError: null,
      };

    case EAllocationsActions.FETCH_ALLOCATIONS_METHOD_FAILURE:
      return {
        ...state,
        allocationMethods: [],
        allocationMethodLoading: false,
        allocationMethodError: action.payload.error,
      };

    /**
     * fetch Pdas
     */
    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATIONS:
      return {
        ...state,
        pdasCollection: [],
        pdasCollectionTotal: 0,
        pdasLoading: true,
        pdasError: null,
      };

    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        pdasCollection: action.payload.collection,
        pdasCollectionTotal: action.payload.totalPredeterminedAllocations,
        pdasLoading: false,
        pdasError: null,
      };

    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATIONS_FAILURE:
      return {
        ...state,
        pdasCollection: [],
        pdasCollectionTotal: 0,
        pdasLoading: false,
        pdasError: action.payload.error,
      };

    case EAllocationsActions.CLEAR_PDAS:
      return {
        ...state,
        pdasCollection: [],
        pdasCollectionTotal: 0,
        pdasLoading: false,
        pdasError: null,
      };

    /**
     * fetch pda by id
     */
    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_BY_ID:
      return {
        ...state,
        pdaById: null,
        pdaByIdLoading: true,
        pdaByIdError: null,
      };

    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        pdaById: action.payload.predeterminedAllocation,
        pdaByIdLoading: false,
        pdaByIdError: null,
      };

    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_BY_ID_FAILURE:
      return {
        ...state,
        pdaById: null,
        pdaByIdLoading: false,
        pdaByIdError: action.payload.error,
      };

    /**
     * Create Pda
     */
    case EAllocationsActions.CREATE_PDA:
      return {
        ...state,
        createPdaLoading: true,
        createPdaError: null,
      };

    case EAllocationsActions.CREATE_PDA_SUCCESS:
      return {
        ...state,
        createPdaLoading: false,
        createPdaError: null,
      };

    case EAllocationsActions.CREATE_PDA_FAILURE:
      return {
        ...state,
        createPdaLoading: false,
        createPdaError: action.payload.error,
      };

    /**
     * Fetch Pda details
     */
    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_DETAILS:
      return {
        ...state,
        pdaDetailsCollection: {},
        pdaDetailsLoading: true,
        pdaDetailsError: null,
      };

    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        pdaDetailsCollection: action.payload,
        pdaDetailsLoading: false,
        pdaDetailsError: null,
      };

    case EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_DETAILS_FAILURE:
      return {
        ...state,
        pdaDetailsCollection: {},
        pdaDetailsLoading: false,
        pdaDetailsError: action.payload.error,
      };

    case EAllocationsActions.CLEAR_PDA_DETAILS:
      return {
        ...state,
        pdaDetailsCollection: {},
        createPdaError: null,
        pdaDetailsLoading: false,
        pdaDetailsError: null,
      };

    /**
     * fetch incomplete pdas
     */
    case EAllocationsActions.FETCH_INCOMPLETE_PDAS:
      return {
        ...state,
        incompletePdas: [],
        incompletePdasTotal: 0,
        incompletePdasLoading: true,
        incompletePdasError: null,
      };

    case EAllocationsActions.FETCH_INCOMPLETE_PDAS_SUCCESS:
      return {
        ...state,
        incompletePdas:
          action.payload.predeterminedAllocationStatusCollection.predeterminedAllocationStatuses,
        incompletePdasTotal: action.payload.predeterminedAllocationStatusCollection.total,
        incompletePdasLoading: false,
        incompletePdasError: null,
      };

    case EAllocationsActions.FETCH_INCOMPLETE_PDAS_FAILURE:
      return {
        ...state,
        incompletePdas: [],
        incompletePdasTotal: 0,
        incompletePdasLoading: false,
        incompletePdasError: action.payload.error,
      };

    /**
     * fetch missing pdas
     */
    case EAllocationsActions.FETCH_MISSING_PDAS:
      return {
        ...state,
        missingPdas: [],
        missingPdasTotal: 0,
        missingPdasLoading: true,
        missingPdasError: null,
      };

    case EAllocationsActions.FETCH_MISSING_PDAS_SUCCESS:
      return {
        ...state,
        missingPdas:
          action.payload.predeterminedAllocationStatusCollection.predeterminedAllocationStatuses,
        missingPdasTotal: action.payload.predeterminedAllocationStatusCollection.total,
        missingPdasLoading: false,
        missingPdasError: null,
      };

    case EAllocationsActions.FETCH_MISSING_PDAS_FAILURE:
      return {
        ...state,
        missingPdas: [],
        missingPdasTotal: 0,
        missingPdasLoading: false,
        missingPdasError: action.payload.error,
      };

    /**
     * fetch expired pdas
     */
    case EAllocationsActions.FETCH_EXPIRED_PDAS:
      return {
        ...state,
        expiredPdas: [],
        expiredPdasTotal: 0,
        expiredPdasLoading: true,
        expiredPdasError: null,
      };

    case EAllocationsActions.FETCH_EXPIRED_PDAS_SUCCESS:
      return {
        ...state,
        expiredPdas:
          action.payload.predeterminedAllocationStatusCollection.predeterminedAllocationStatuses,
        expiredPdasTotal: action.payload.predeterminedAllocationStatusCollection.total,
        expiredPdasLoading: false,
        expiredPdasError: null,
      };

    case EAllocationsActions.FETCH_EXPIRED_PDAS_FAILURE:
      return {
        ...state,
        expiredPdas: [],
        expiredPdasTotal: 0,
        expiredPdasLoading: false,
        expiredPdasError: action.payload.error,
      };

    case EAllocationsActions.SET_CREATE_PDA_LOCATION_PARAM:
      return {
        ...state,
        createPDALocationParam: {
          locationName: action.payload.locationName,
          locationNumber: action.payload.locationNumber,
          locationId: action.payload.locationId,
          beginDate: action.payload.beginDate,
          endDate: action.payload.endDate,
        },
      };

    case EAllocationsActions.CLEAR_CREATE_PDA_LOCATION_PARAM:
      return {
        ...state,
        createPDALocationParam: null,
      };

    /*
     * Fetch Allocation Lookup
     */

    case EAllocationsActions.FETCH_ALLOCATION_LOOKUP:
      return {
        ...state,
        lookupCollection: {
          adjustmentTypes: [],
          statementBases: [],
        },
        allocationLookupLoading: true,
        allocationLookupError: null,
      };

    case EAllocationsActions.FETCH_ALLOCATION_LOOKUP_SUCCESS:
      return {
        ...state,
        lookupCollection: {
          adjustmentTypes: action.payload.adjustmentTypes,
          statementBases: action.payload.statementBases,
        },
        allocationLookupLoading: false,
        allocationLookupError: null,
      };

    case EAllocationsActions.FETCH_ALLOCATION_LOOKUP_FAILURE:
      return {
        ...state,
        lookupCollection: {
          adjustmentTypes: [],
          statementBases: [],
        },
        allocationLookupLoading: false,
        allocationLookupError: action.payload.error,
      };

    default:
      return state;
  }
}
