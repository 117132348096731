import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { DynamicComponentDefinition } from 'shared/components/dynamic-component-wrapper/dynamic-component.interface';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { ClickableIconOptions } from './clickable-icon-cell.interface';

@Component({
  selector: 'gms-dynamic-clickable-icon-cell',
  styleUrls: ['./clickable-icon-cell.component.scss'],
  templateUrl: './clickable-icon-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicClickableIconComponent extends HasSubscriptions implements OnInit {
  @Input() data: ClickableIconOptions;

  static componentDefinition(data: ClickableIconOptions): DynamicComponentDefinition {
    return {
      dynamicComponentType: DynamicClickableIconComponent,
      data: data,
    };
  }

  ngOnInit() {
    if (this.data.dynamicClass) {
      this.addSubscription(
        this.data.dynamicClass.pipe(take(1)).subscribe(dynamicClass => {
          this.data.iconClass = dynamicClass;
        })
      );
    }
  }

  public onClick($event: any) {
    this.data.onClick(this.data, $event);
  }
}
