import { NgModule } from '@angular/core';
import { EffectsModule as Effects } from '@ngrx/effects';
import { AuthEffects } from 'app/store/auth/auth.effects';
import { ClosingDashboardEffects } from 'app/store/closing-dashboard/closing-dashboard.effects';
import { EntitiesEffects } from 'app/store/entities/entities.effects';
import { ImbalancesEffects } from 'app/store/imbalances/imbalances.effects';
import { ServiceRequestsEffect } from 'app/store/service-requests/service-requests.effects';
import { SystemPlanningEffects } from 'app/store/system-planning/system-planning.effects';
import { UsersEffects } from 'app/store/users/users.effects';
import { WebsocketsEffects } from 'app/store/websockets/websockets.effects';
import { AccountingEffects } from '../accounting/accounting.effects';
import { AgencyEffects } from '../agency/agency.effects';
import { AllocationsEffects } from '../allocations/allocations.effects';
import { AmendmentsEffects } from '../amendments/amendments.effects';
import { AuctionsEffects } from '../auctions/auctions.effects';
import { BillingEffects } from '../billing/billing.effects';
import { CapacityReleaseEffects } from '../capacity-release/capacity-release.effects';
import { CommunicationEffects } from '../communication/communication.effects';
import { ConfirmationsEffects } from '../confirmations/confirmations.effects';
import { ContractsEffects } from '../contracts/contracts.effects';
import { DealSheetsEffect } from '../deal-sheets/deal-sheets.effects';
import { HeaderEffects } from '../header/header.effects';
import { JobsEffects } from '../jobs/jobs.effects';
import { JournalEntryRuleEffects } from '../journal-entry-rule/journal-entry-rule.effects';
import { LocationsEffect } from '../locations/locations.effects';
import { LookupEffects } from '../lookup/lookup.effects';
import { MarketSectorOverridesEffects } from '../market-sector-overrides/market-sector-overrides.effects';
import { MeasurementsEffects } from '../measurements/measurements.effects';
import { MergerAssignmentEffects } from '../merger-assignments/merger-assignment.effects';
import { NominationsEffects } from '../nominations/nominations.effects';
import { NotesEffects } from '../notes/notes.effects';
import { OperationalAccountsEffects } from '../operational-accounts/operational-accounts.effects';
import { PasswordResetEffects } from '../password-reset/password-reset.effects';
import { PipelineModelsEffects } from '../pipeline-models/pipeline-models.effects';
import { PredefinedFootnotesEffect } from '../predefined-footnotes/predefined-footnotes.effects';
import { RatchetLevelsEffect } from '../ratchet-levels/ratchet-levels.effects';
import { RateContractEffects } from '../rate-contract/rate-contract-effects';
import { RateSchedulesEffect } from '../rate-schedules/rate-schedules.effects';
import { RepResponsibilityEffects } from '../rep-responsibility/rep-responsibility.effects';
import { ReportsEffects } from '../reports/reports.effects';
import { RetryEffects } from '../retry/retry.effects';
import { SchedulingEffects } from '../scheduling/scheduling.effects';
import { StorageTransferEffects } from '../storage-transfer/storage-transfer.effects';
import { TspsEffect } from '../tsp/tsps.effects';
import { WorkflowTaskActionEffect } from '../workflow-task-action/workflow-task-action.effects';
import { PostingEffects } from '../posting/posting.effects';

@NgModule({
  imports: [
    Effects.forRoot([
      AccountingEffects,
      AgencyEffects,
      AllocationsEffects,
      AmendmentsEffects,
      AuctionsEffects,
      AuthEffects,
      BillingEffects,
      CapacityReleaseEffects,
      ClosingDashboardEffects,
      ConfirmationsEffects,
      ContractsEffects,
      CommunicationEffects,
      DealSheetsEffect,
      JobsEffects,
      JournalEntryRuleEffects,
      EntitiesEffects,
      HeaderEffects,
      ImbalancesEffects,
      LocationsEffect,
      LookupEffects,
      MarketSectorOverridesEffects,
      MeasurementsEffects,
      MergerAssignmentEffects,
      NominationsEffects,
      OperationalAccountsEffects,
      PasswordResetEffects,
      PipelineModelsEffects,
      RateContractEffects,
      RateSchedulesEffect,
      RepResponsibilityEffects,
      RetryEffects,
      SchedulingEffects,
      ServiceRequestsEffect,
      StorageTransferEffects,
      UsersEffects,
      TspsEffect,
      NotesEffects,
      PredefinedFootnotesEffect,
      RatchetLevelsEffect,
      RepResponsibilityEffects,
      WebsocketsEffects,
      WorkflowTaskActionEffect,
      SystemPlanningEffects,
      ReportsEffects,
      PostingEffects
    ]),
  ],
})
export class EffectsModule {}
