import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption } from 'app/store/auth/model/models';

export const Trades_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/tradeblotter/tradeblotter.module').then(m => m.TradeblotterModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/auctions',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
];
