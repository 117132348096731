import { Injectable } from '@angular/core';
import { DetailRateSchedule, RateScheduleService } from '@gms/rateschedulev2-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sortDefinitions } from 'app/store/default-sort.definitions';
import {
  ERateSchedulesActions,
  FetchAllDetailRateSchedules,
  FetchAllDetailRateSchedulesFailure,
  FetchAllDetailRateSchedulesSuccess,
  FetchDetailRateSchedules,
  FetchDetailRateSchedulesFailure,
  FetchDetailRateSchedulesSuccess,
  FetchDetailRateScheduleUiConfiguration,
  FetchDetailRateScheduleUiConfigurationFailure,
  FetchDetailRateScheduleUiConfigurationSuccess,
  FetchMasterRateSchedules,
  FetchMasterRateSchedulesFailure,
  FetchMasterRateSchedulesSuccess,
  FetchMultipleNAESBLocationIndicatorsByTspID,
  FetchMultipleNAESBLocationIndicatorsByTspIDFailure,
  FetchMultipleNAESBLocationIndicatorsByTspIDSuccess,
  FetchQuantityType,
  FetchQuantityTypeFailure,
  FetchQuantityTypeSuccess,
  FetchRateSchedule,
  FetchRateScheduleFailure,
  FetchRateScheduleSuccess,
  FetchTspSeasons,
  FetchTspSeasonsFailure,
  FetchTspSeasonsSuccess,
} from 'app/store/rate-schedules/rate-schedules.actions';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RateSchedulesEffect {
  constructor(private actions$: Actions, private rateSchedulesService: RateScheduleService) {}

  getMasterRateSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchMasterRateSchedules>(ERateSchedulesActions.FetchMasterRateSchedules),
      switchMap(action =>
        this.rateSchedulesService.getMasterRateSchedules(action.payload.tspId, 999).pipe(
          switchMap(response =>
            of(
              new FetchMasterRateSchedulesSuccess({
                masterRateSchedules: response.masterRateSchedules,
              })
            )
          ),
          catchError(error => of(new FetchMasterRateSchedulesFailure({ error })))
        )
      )
    )
  );

  getDetailRateSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDetailRateSchedules>(ERateSchedulesActions.FetchDetailRateSchedules),
      switchMap(action =>
        this.rateSchedulesService
          .getDetailRateSchedules(action.payload.tspId, 999, null, [sortDefinitions.getDetailRateSchedules], action.payload.isTariff)
          .pipe(
            switchMap(response =>
              of(
                new FetchDetailRateSchedulesSuccess({
                  detailRateSchedules: response.detailRateSchedules,
                })
              )
            ),
            catchError(error => of(new FetchDetailRateSchedulesFailure({ error })))
          )
      )
    )
  );

  getAllDetailRateSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAllDetailRateSchedules>(ERateSchedulesActions.FetchAllDetailRateSchedules),
      switchMap(action =>
        this.rateSchedulesService
          .getAllDetailRateSchedules(
            999,
            null,
            null,
            action.payload.isTariff,
            action.payload.serviceTypeId
          )
          .pipe(
            switchMap(response =>
              of(
                new FetchAllDetailRateSchedulesSuccess({
                  allDetailRateSchedules: response.detailRateSchedules,
                })
              )
            ),
            catchError(error => of(new FetchAllDetailRateSchedulesFailure({ error })))
          )
      )
    )
  );

  getDetailRateScheduleUiConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchDetailRateScheduleUiConfiguration>(
        ERateSchedulesActions.FetchDetailRateScheduleUiConfiguration
      ),
      switchMap(action =>
        this.rateSchedulesService
          .getRateScheduleConfiguration(action.payload.tspId, action.payload.detailRateScheduleId)
          .pipe(
            switchMap(response =>
              of(
                new FetchDetailRateScheduleUiConfigurationSuccess({
                  detailRateScheduleId: action.payload.detailRateScheduleId,
                  uiConfigurations: response.configurations,
                })
              )
            ),
            catchError(error => of(new FetchDetailRateScheduleUiConfigurationFailure({ error })))
          )
      )
    )
  );

  getDetailRateSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchRateSchedule>(ERateSchedulesActions.FetchRateSchedule),
      switchMap(action =>
        this.rateSchedulesService
          .getDetailRateScheduleById(action.payload.detailRateScheduleId, action.payload.tspId)
          .pipe(
            switchMap((response: DetailRateSchedule) =>
              of(new FetchRateScheduleSuccess({ detailRateSchedule: response }))
            ),
            catchError(error => of(new FetchRateScheduleFailure({ error })))
          )
      )
    )
  );

  getMultipleNAESBLocationIndicatorsByTspId = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchMultipleNAESBLocationIndicatorsByTspID>(
        ERateSchedulesActions.Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID
      ),
      switchMap(payload => {
        const requestObservables = {};
        payload.tspIds.forEach(tspId => {
          requestObservables[tspId] = this.rateSchedulesService.getNaesbByTspId(tspId);
        });

        return forkJoin(requestObservables).pipe(
          map(locationIndicators => {
            return new FetchMultipleNAESBLocationIndicatorsByTspIDSuccess({
              naesbLocationIndicators: locationIndicators,
            });
          }),
          catchError(error => of(new FetchMultipleNAESBLocationIndicatorsByTspIDFailure(error)))
        );
      })
    )
  );

  getQuantityType$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchQuantityType>(ERateSchedulesActions.FETCH_QUANTITY_TYPE),
      switchMap(action =>
        this.rateSchedulesService
          .getRateScheduleQuantityTypes(action.payload.detailRateScheduleId, action.payload.tspId)
          .pipe(
            switchMap(response =>
              of(
                new FetchQuantityTypeSuccess({
                  quantityType: response,
                })
              )
            ),
            catchError(error => of(new FetchQuantityTypeFailure({ error })))
          )
      )
    )
  );

  getTspSeasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchTspSeasons>(ERateSchedulesActions.FETCH_TSP_SEASONS),
      switchMap(action =>
        this.rateSchedulesService.getTspSeasons(action.payload.tspId).pipe(
          switchMap(response =>
            of(
              new FetchTspSeasonsSuccess({
                tspSeasons: response,
              })
            )
          ),
          catchError(error => of(new FetchTspSeasonsFailure({ error })))
        )
      )
    )
  );
}
