import {
  clearState,
  createEmptyLoadingState,
  createErrorState,
  createLoadingState,
  createSuccessState,
  emptyRequestStatus,
} from 'app/store/app/app.models';
import { ContractsActions, EContractsActions } from 'app/store/contracts/contracts.actions';
import { IContractState, initialContractsState } from 'app/store/contracts/contracts.state';
import { transformDates } from '../reducers.utils';

//TODO: Update any reducers not currently using project redux standards
export function contractReducers(
  state = initialContractsState,
  action: ContractsActions
): IContractState {
  switch (action.type) {
    case EContractsActions.FETCH_CONTRACT_LOCATION_HEADERS:
      return {
        ...state,
        contractLocationHeadersList: [],
        status: {
          ...state.status,
          contractLocationHeaders: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_HEADERS_SUCCESS:
      return {
        ...state,
        contractLocationHeadersList: action.payload.contractLocationHeadersList,
        status: {
          ...state.status,
          contractLocationHeaders: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_HEADERS_FAILURE:
      return {
        ...state,
        contractLocationHeadersList: [],
        status: {
          ...state.status,
          contractLocationHeaders: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.FETCH_AMENDMENTS_BY_CONTRACT_ID:
      return {
        ...state,
        amendments: [],
        status: {
          ...state.status,
          amendments: {
            loading: true,
            httpError: null,
          },
        },
      };
    case EContractsActions.FETCH_AMENDMENTS_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        amendments: action.payload.amendmentCollection.amendments,
        status: {
          ...state.status,
          amendments: {
            loading: false,
            httpError: null,
          },
        },
      };
    case EContractsActions.FETCH_AMENDMENTS_BY_CONTRACT_ID_ERROR:
      return {
        ...state,
        amendments: [],
        status: {
          ...state.status,
          amendments: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };
    case EContractsActions.FETCH_CONTRACTS:
      return {
        ...state,
        contractsLoading: true,
        loading: true,
        error: null,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
      };

    case EContractsActions.FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.payload.contracts.map(transformDates),
        contractsLoading: false,
        loading: false,
        error: null,
        totalContractCount: action.payload.totalContractCount,
      };

    case EContractsActions.FETCH_CONTRACTS_ERROR:
      return {
        ...state,
        contracts: null,
        contractsLoading: false,
        error: action.payload.error,
        loading: false,
        totalContractCount: null,
      };

    case EContractsActions.CLEAR_CONTRACTS:
      return {
        ...state,
        contractsLoading: false,
        loading: false,
        error: null,
        contracts: [],
        totalContractCount: null,
      };

    case EContractsActions.FETCH_CONTRACTS_HEADERS:
    case EContractsActions.FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS:
      return {
        ...state,
        contractHeaders: createLoadingState(state.contractHeaders),
      };

    case EContractsActions.FETCH_CONTRACTS_HEADERS_SUCCESS:
    case EContractsActions.FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_SUCCESS:
      return {
        ...state,
        contractHeaders: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACTS_HEADERS_ERROR:
    case EContractsActions.FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_FAILURE:
      return {
        ...state,
        contractHeaders: createErrorState(action.payload),
      };

    case EContractsActions.CLEAR_CONTRACTS_HEADERS:
      return {
        ...state,
        contractHeaders: clearState(state.contractHeaders),
      };

    case EContractsActions.FETCH_CONTRACT_HEADER:
      return {
        ...state,
        contractHeader: createLoadingState(state.contractHeader),
      };

    case EContractsActions.FETCH_CONTRACT_HEADER_SUCCESS:
      return {
        ...state,
        contractHeader: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_HEADER_ERROR:
      return {
        ...state,
        contractHeader: createErrorState(action.payload),
      };

    case EContractsActions.CLEAR_CONTRACT_HEADER:
      return {
        ...state,
        contractHeader: clearState(state.contractHeader),
      };

    case EContractsActions.FETCH_CONTRACT_BY_ID:
      return {
        ...state,
        contract: null,
        loading: true,
        error: null,
      };

    case EContractsActions.FETCH_CONTRACT_BY_ID_SUCCESS:
      return {
        ...state,
        contract: transformDates(action.payload.contract),
        loading: false,
        error: null,
      };

    case EContractsActions.FETCH_CONTRACT_BY_ID_ERROR:
      return {
        ...state,
        contract: null,
        error: action.payload.error,
        loading: false,
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID:
      return {
        ...state,
        quantityLocationCollection: null,
        status: {
          ...state.status,
          locationQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        quantityLocationCollection: action.payload,
        status: {
          ...state.status,
          locationQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        quantityLocationCollection: null,
        status: {
          ...state.status,
          locationQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID:
      return {
        ...state,
        quantityLocationChangesCollection: createLoadingState(
          state.quantityLocationChangesCollection
        ),
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        quantityLocationChangesCollection: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        quantityLocationChangesCollection: createErrorState(action.payload.error),
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID:
      return {
        ...state,
        quantityLocationPointPairCollection: null,
        status: {
          ...state.status,
          locationPointPairQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        quantityLocationPointPairCollection: action.payload,
        status: {
          ...state.status,
          locationPointPairQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        quantityLocationPointPairCollection: null,
        status: {
          ...state.status,
          locationPointPairQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID:
      return {
        ...state,
        quantityLocationChangesPointPairCollection: createLoadingState(
          state.quantityLocationChangesPointPairCollection
        ),
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        quantityLocationChangesPointPairCollection: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        quantityLocationChangesPointPairCollection: createErrorState(action.payload.error),
      };

    case EContractsActions.FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID:
      return {
        ...state,
        contractQuantityCollection: null,
        status: {
          ...state.status,
          contractQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        contractQuantityCollection: action.payload,
        isContractQuantitiesReset: null,
        status: {
          ...state.status,
          contractQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        contractQuantityCollection: null,
        status: {
          ...state.status,
          contractQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.SEARCH_CONTRACTS:
      return {
        ...state,
        contractsSearchLoading: true,
      };

    case EContractsActions.SEARCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        contractsSearch: action.payload.contracts.map(transformDates),
        contractsSearchLoading: false,
        contractsSearchError: null,
      };

    case EContractsActions.SEARCH_CONTRACTS_FAILURE:
      return {
        ...state,
        contractsSearchLoading: false,
        contractsSearchError: action.payload.error,
      };

    case EContractsActions.CLEAR_SEARCH_CONTRACTS:
      return {
        ...state,
        contractsSearch: [],
        contractsSearchLoading: false,
        contractsSearchError: null,
      };

    case EContractsActions.FETCH_CONTRACTS_LIST:
      return {
        ...state,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
        status: {
          ...state.status,
          contractsList: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACTS_LIST_SUCCESS:
      return {
        ...state,
        contractsList: action.payload.contractsList.map(transformDates),
        totalContractCount: action.payload.total,
        status: {
          ...state.status,
          contractsList: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACTS_LIST_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          contractsList: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.SET_CONTRACT:
      return {
        ...state,
        contract: action.payload.contract,
      };

    case EContractsActions.FETCH_LOOKUP_COLLECTIONS:
      return {
        ...state,
        status: {
          ...state.status,
          lookupCollections: {
            httpError: null,
            loading: true,
          },
        },
      };

    case EContractsActions.FETCH_LOOKUP_COLLECTIONS_SUCCESS:
      return {
        ...state,
        lookupCollections: action.payload.useCache
          ? state.lookupCollections
          : action.payload.lookupCollections,
        status: {
          ...state.status,
          lookupCollections: {
            httpError: null,
            loading: false,
          },
        },
      };

    case EContractsActions.FETCH_LOOKUP_COLLECTIONS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          lookupCollections: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case EContractsActions.CLEAR_LOOKUP_COLLECTIONS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          lookupCollections: emptyRequestStatus,
        },
      };

    case EContractsActions.FETCH_CONTRACT_RATE_SCHEDULES:
      return {
        ...state,
        status: {
          ...state.status,
          contractRateSchedules: {
            httpError: null,
            loading: true,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_RATE_SCHEDULES_SUCCESS:
      return {
        ...state,
        contractRateSchedules: action.payload.contractRateSchedules,
        status: {
          ...state.status,
          contractRateSchedules: {
            httpError: null,
            loading: false,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_RATE_SCHEDULES_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          contractRateSchedules: {
            httpError: action.payload.error,
            loading: false,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID:
      return {
        ...state,
        quantityPathCollection: null,
        status: {
          ...state.status,
          pathQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        quantityPathCollection: action.payload,
        status: {
          ...state.status,
          pathQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        quantityPathCollection: null,
        status: {
          ...state.status,
          pathQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.RESET_CONTRACT_PATHS:
      return {
        ...state,
        quantityPathCollection: null,
        contractPathQuantitiesUpdated: false,
        status: {
          ...state.status,
          pathQuantities: emptyRequestStatus,
        },
      };

    case EContractsActions.FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID:
      return {
        ...state,
        contractEdiTransactionSetCollection: null,
        status: {
          ...state.status,
          contractEdiTransactionSet: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        contractEdiTransactionSetCollection: action.payload,
        status: {
          ...state.status,
          contractEdiTransactionSet: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        contractEdiTransactionSetCollection: null,
        status: {
          ...state.status,
          contractEdiTransactionSet: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS:
      return {
        ...state,
        contractRateScheduleEntities: {
          loading: true,
          error: null,
          entities: [],
        },
      };

    case EContractsActions.FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS_SUCCESS:
      return {
        ...state,
        contractRateScheduleEntities: {
          loading: false,
          error: null,
          entities: action.payload.entities,
        },
      };

    case EContractsActions.FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS_FAILURE:
      return {
        ...state,
        contractRateScheduleEntities: {
          loading: false,
          error: action.payload.error,
          entities: [],
        },
      };

    case EContractsActions.UPDATE_CONTRACT_QUANTITIES:
      return {
        ...state,
        status: {
          ...state.status,
          updateContractQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_QUANTITIES_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          updateContractQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_QUANTITIES_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          updateContractQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_PATH_QUANTITIES:
      return {
        ...state,
        contractPathQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractPathQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_PATH_QUANTITIES_SUCCESS:
      return {
        ...state,
        contractPathQuantitiesUpdated: true,
        status: {
          ...state.status,
          updateContractPathQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_PATH_QUANTITIES_FAILURE:
      return {
        ...state,
        contractPathQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractPathQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.CLEAR_CONTRACT_PATH_QUANTITIES_STATUS:
      return {
        ...state,
        contractPathQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractPathQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_EDI_TRANSACTION_SET:
      return {
        ...state,
        status: {
          ...state.status,
          updateContractEdiTransactionSet: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_EDI_TRANSACTION_SET_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          updateContractEdiTransactionSet: {
            loading: false,
            httpError: null,
          },
        },
        contractEdiUpdated: createSuccessState(true),
      };

    case EContractsActions.UPDATE_CONTRACT_EDI_TRANSACTION_SET_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          updateContractEdiTransactionSet: {
            loading: false,
            httpError: action.payload.error,
          },
        },
        contractEdiUpdated: createErrorState(action.payload.error),
      };
    ////

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES:
      return {
        ...state,
        contractLocationQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractLocationQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES_SUCCESS:
      return {
        ...state,
        contractLocationQuantitiesUpdated: true,
        status: {
          ...state.status,
          updateContractLocationQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES_FAILURE:
      return {
        ...state,
        contractLocationQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractLocationQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES_RESET:
      return {
        ...state,
        contractLocationQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractLocationQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES:
      return {
        ...state,
        contractLocationQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractLocationPointPairQuantities: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_SUCCESS:
      return {
        ...state,
        contractLocationQuantitiesUpdated: true,
        status: {
          ...state.status,
          updateContractLocationPointPairQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_FAILURE:
      return {
        ...state,
        contractLocationQuantitiesUpdated: false,
        status: {
          ...state.status,
          updateContractLocationPointPairQuantities: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.UPDATE_SELECTED_AMENDMENT:
      return {
        ...state,
        selectedAmendment: action.payload,
      };

    case EContractsActions.CLEAR_SELECTED_AMENDMENT:
      return {
        ...state,
        selectedAmendment: null,
      };

    case EContractsActions.CLEAR_ALL_CONTRACT_QUANTITIES:
      return {
        ...state,
        contractQuantityCollection: null,
        quantityLocationCollection: null,
        quantityPathCollection: null,
        status: {
          ...state.status,
          contractQuantities: {
            loading: false,
            httpError: null,
          },
          locationQuantities: {
            loading: false,
            httpError: null,
          },
          pathQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };
    case EContractsActions.CLEAR_CONTRACT:
      return {
        ...state,
        contract: null,
        loading: false,
        error: null,
      };
    case EContractsActions.UPDATE_CONTRACT_OVERVIEW:
      return {
        ...state,
        contractOverviewUpdated: createLoadingState(state.contractOverviewUpdated),
      };
    case EContractsActions.UPDATE_CONTRACT_OVERVIEW_SUCCESS:
      return {
        ...state,
        contractOverviewUpdated: createSuccessState(true),
      };
    case EContractsActions.UPDATE_CONTRACT_OVERVIEW_FAILURE:
      return {
        ...state,
        contractOverviewUpdated: createErrorState(action.payload.error),
      };

    case EContractsActions.ADD_CONTRACT_OVERVIEW:
      return {
        ...state,
        status: {
          ...state.status,
          addContractOverview: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.ADD_CONTRACT_OVERVIEW_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          addContractOverview: {
            loading: false,
            httpError: null,
          },
        },
        contract: action.payload,
      };

    case EContractsActions.ADD_CONTRACT_OVERVIEW_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          addContractOverview: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_GENERAL_INFO:
      return {
        ...state,
        status: {
          ...state.status,
          contractGeneral: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACT_GENERAL_INFO_SUCCESS:
      return {
        ...state,
        contractGeneralCollection: action.payload,
        status: {
          ...state.status,
          contractGeneral: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.CLEAR_CONTRACTS_LIST:
      return {
        ...state,
        contractsList: [],
        totalContractCount: 0,
        status: {
          ...state.status,
          contractsList: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.CLEAR_CONTRACTS_LIST_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          contractsList: emptyRequestStatus,
        },
      };

    case EContractsActions.FETCH_CONTRACT_GENERAL_INFO_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          contractGeneral: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };
    case EContractsActions.RESET_CONTRACT_OVERVIEW:
      return {
        ...state,
        contract: null,
        contractLockStatusUpdated: false,
        contractOverviewUpdated: {
          data: null,
          requestStatus: emptyRequestStatus,
        },
        status: {
          ...state.status,
          addContractOverview: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.RESET_CONTRACT_QUANTITIES:
      return {
        ...state,
        contractQuantityCollection: null,
        isContractQuantitiesReset: true,
        status: {
          ...state.status,
          contractQuantities: {
            loading: false,
            httpError: null,
          },
          updateContractQuantities: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UNLOCK_CONTRACT:
      return {
        ...state,
        contractLockStatusUpdated: false,
        status: {
          ...state.status,
          contractLockStatusUpdated: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.UNLOCK_CONTRACT_SUCCESS:
      return {
        ...state,
        contractLockStatusUpdated: true,
        status: {
          ...state.status,
          contractLockStatusUpdated: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.UNLOCK_CONTRACT_FAILURE:
      return {
        ...state,
        contractLockStatusUpdated: false,
        status: {
          ...state.status,
          contractLockStatusUpdated: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID:
      return {
        ...state,
        supplementalRestrictionCollection: null,
        status: {
          ...state.status,
          supplementalRestrictions: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        supplementalRestrictionCollection: action.payload,
        status: {
          ...state.status,
          supplementalRestrictions: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID_FAILURE:
      return {
        ...state,
        supplementalRestrictionCollection: null,
        status: {
          ...state.status,
          supplementalRestrictions: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EContractsActions.RESET_CONTRACT_EDI:
      return {
        ...state,
        contract: null,
        contractLockStatusUpdated: false,
        contractEdiUpdated: createSuccessState(false),
        status: {
          ...state.status,
          contractEdiTransactionSet: {
            loading: false,
            httpError: null,
          },
          updateContractEdiTransactionSet: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT:
      return {
        ...state,
        contractsByMergerAssignment: null,
        status: {
          ...state.status,
          contractsByMergerAssignment: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        contractsByMergerAssignment: action.payload,
        status: {
          ...state.status,
          contractsByMergerAssignment: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT_FAILURE:
      return {
        ...state,
        contractsByMergerAssignment: null,
        status: {
          ...state.status,
          contractsByMergerAssignment: {
            loading: false,
            httpError: action.payload,
          },
        },
      };

    case EContractsActions.EXPORT_CONTRACT_LIST:
      return {
        ...state,
        status: {
          ...state.status,
          exportContractsList: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.EXPORT_CONTRACT_LIST_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          exportContractsList: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.EXPORT_CONTRACT_LIST_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          exportContractsList: {
            loading: false,
            httpError: action.payload,
          },
        },
      };

    case EContractsActions.CREATE_AMENDMENT:
      return {
        ...state,
        amendmentCreated: createLoadingState(state.amendmentCreated),
        status: {
          ...state.status,
          amendments: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EContractsActions.CREATE_AMENDMENT_SUCCESS:
      return {
        ...state,
        amendments: [...action.payload],
        amendmentCreated: createSuccessState(true),
        status: {
          ...state.status,
          amendments: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EContractsActions.CREATE_AMENDMENT_FAILURE:
      return {
        ...state,
        amendmentCreated: createErrorState(action.payload),
        status: {
          ...state.status,
          amendments: {
            loading: false,
            httpError: action.payload,
          },
        },
      };

    case EContractsActions.RESET_AMENDMENT_CREATED_VALUE:
      return {
        ...state,
        amendmentCreated: createSuccessState(null),
      };

    case EContractsActions.FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID:
      return {
        ...state,
        quantityChangesCollection: createLoadingState(state.quantityChangesCollection),
      };

    case EContractsActions.FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        quantityChangesCollection: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        quantityChangesCollection: createErrorState(action.payload.error),
      };

    case EContractsActions.RESET_CONTRACT_QUANTITIES_CHANGES:
      return {
        ...state,
        quantityChangesCollection: clearState(state.quantityChangesCollection),
      };

    case EContractsActions.RESET_CONTRACT_LOCATIONS:
      return {
        ...state,
        quantityLocationCollection: null,
        quantityLocationPointPairCollection: null,
        quantityLocationChangesPointPairCollection: clearState(
          state.quantityLocationChangesPointPairCollection
        ),
        contractLocationQuantitiesUpdated: false,
        status: {
          ...state.status,
          locationQuantities: emptyRequestStatus,
        },
      };

    case EContractsActions.WORKFLOW_INITIATE_KGEN:
      return {
        ...state,
        workflowInitiateKgen: createEmptyLoadingState(),
      };

    case EContractsActions.WORKFLOW_INITIATE_KGEN_SUCCESS:
      return {
        ...state,
        workflowInitiateKgen: createSuccessState(true),
      };

    case EContractsActions.WORKFLOW_INITIATE_KGEN_FAILURE:
      return {
        ...state,
        workflowInitiateKgen: createErrorState(action.payload.error),
      };

    case EContractsActions.RESET_WORKFLOW_INITIATE_KGEN:
      return {
        ...state,
        workflowInitiateKgen: clearState(state.workflowInitiateKgen),
      };

    case EContractsActions.FETCH_CONTRACT_DOCUMENTS:
      return {
        ...state,
        contractDocuments: createLoadingState(state.contractDocuments),
      };

    case EContractsActions.FETCH_CONTRACT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        contractDocuments: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_DOCUMENTS_FAILURE:
      return {
        ...state,
        contractDocuments: createErrorState(action.payload.error),
      };

    case EContractsActions.FETCH_CONTRACT_DOCUMENT_BY_ID:
      return {
        ...state,
        contractDocument: createLoadingState(state.contractDocument),
      };

    case EContractsActions.FETCH_CONTRACT_DOCUMENT_BY_ID_SUCCESS:
      return {
        ...state,
        contractDocument: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_DOCUMENT_BY_ID_FAILURE:
      return {
        ...state,
        contractDocument: createErrorState(action.payload.error),
      };

    case EContractsActions.UPDATE_CONTRACT_QUANTITY_CHANGES:
      return {
        ...state,
        contractQuantityChangesUpdated: createLoadingState(state.contractQuantityChangesUpdated),
      };

    case EContractsActions.UPDATE_CONTRACT_QUANTITY_CHANGES_SUCCESS:
      return {
        ...state,
        contractQuantityChangesUpdated: createSuccessState(true),
      };

    case EContractsActions.RESET_CONTRACT_QUANTITY_CHANGES:
      return {
        ...state,
        contractQuantityChangesUpdated: createSuccessState(false),
      };

    case EContractsActions.UPDATE_CONTRACT_QUANTITY_CHANGES_FAILURE:
      return {
        ...state,
        contractQuantityChangesUpdated: createErrorState(action.payload.error),
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES:
      return {
        ...state,
        contractLocationQuantityChangesUpdated: createLoadingState(
          state.contractLocationQuantityChangesUpdated
        ),
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES_SUCCESS:
      return {
        ...state,
        contractLocationQuantityChangesUpdated: createSuccessState(true),
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES_FAILURE:
      return {
        ...state,
        contractLocationQuantityChangesUpdated: createErrorState(action.payload.error),
      };

    case EContractsActions.RESET_CONTRACT_LOCATION_QUANTITY_CHANGES:
      return {
        ...state,
        contractLocationQuantityChangesUpdated: createSuccessState(false),
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES:
      return {
        ...state,
        contractLocationQuantityPointPairChangesUpdated: createLoadingState(
          state.contractLocationQuantityPointPairChangesUpdated
        ),
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES_SUCCESS:
      return {
        ...state,
        contractLocationQuantityPointPairChangesUpdated: createSuccessState(true),
      };

    case EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES_FAILURE:
      return {
        ...state,
        contractLocationQuantityPointPairChangesUpdated: createErrorState(action.payload.error),
      };

    case EContractsActions.RESET_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES:
      return {
        ...state,
        contractLocationQuantityPointPairChangesUpdated: createSuccessState(false),
      };

    case EContractsActions.EXPORT_GRANDFATHERED_SUPPLY_LATERAL:
      return {
        ...state,
        exportGrandfatheredSupplyLateral: createLoadingState(
          state.exportGrandfatheredSupplyLateral
        ),
      };

    case EContractsActions.ACKNOWLEDGE_NETWORK_RESULT:
      return {
        ...state,
        exportGrandfatheredSupplyLateral: createSuccessState(null),
      };

    case EContractsActions.EXPORT_GRANDFATHERED_SUPPLY_LATERAL_SUCCESS:
      return {
        ...state,
        exportGrandfatheredSupplyLateral: createSuccessState(true),
      };

    case EContractsActions.EXPORT_GRANDFATHERED_SUPPLY_LATERAL_FAILURE:
      return {
        ...state,
        exportGrandfatheredSupplyLateral: createErrorState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID:
      return {
        ...state,
        contractEntities: createLoadingState(state.contractEntities),
      };

    case EContractsActions.FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID_SUCCESS:
      return {
        ...state,
        contractEntities: createSuccessState(action.payload),
      };

    case EContractsActions.FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID_FAILURE:
      return {
        ...state,
        contractEntities: createErrorState(action.payload.error),
      };

    default:
      return state;
  }
}
