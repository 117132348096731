import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { CurrentGasDayResolver } from 'app/resolvers/current-gas-day.resolver';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

const Measurement_Routes: Routes = [
  {
    path: 'measurements',
    loadChildren: () =>
      import('./pages/location-measurements/location-measurements.module').then(
        m => m.LocationMeasurementsModule
      ),
    canActivate: [ResourceGuard],
    resolve: { currentGasDay: CurrentGasDayResolver },
    data: {
      aclResource: '/flowing-gas/measurements',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'measurement/current-day-measurement',
    loadChildren: () =>
      import('./pages/current-day-measurement/current-day-measurement.module').then(
        m => m.CurrentDayMeasurementModule
      ),
    canActivate: [ResourceGuard],
    resolve: { currentGasDay: CurrentGasDayResolver },
    data: {
      aclResource: '/flowing-gas/measurement/current-day-measurement',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'measurement/hourly-overruns',
    loadChildren: () =>
      import('./pages/hourly-overruns/hourly-overruns.module').then(m => m.HourlyOverrunsModule),
    canActivate: [ResourceGuard],
    resolve: { currentGasDay: CurrentGasDayResolver },
    data: {
      aclResource: '/flowing-gas/measurement/hourly-overruns',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'measurement/gas-quality',
    loadChildren: () =>
      import('./pages/gas-quality/gas-quality.module').then(m => m.GasQualityModule),
    canActivate: [ResourceGuard],
    resolve: { currentGasDay: CurrentGasDayResolver },
    data: {
      aclResource: '/flowing-gas/measurement/gas-quality',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'measurement/measured-volume-audit-statement',
    loadChildren: () =>
      import('./pages/measured-volume-audit-statement/measured-volume-audit-statement.module').then(
        m => m.MeasuredVolumeAuditStatementModule
      ),
    canActivate: [ResourceGuard],
    resolve: { currentGasDay: CurrentGasDayResolver },
    data: {
      aclResource: '/flowing-gas/measurement/measured-volume-audit-statement',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'measurements/:id',
    loadChildren: () =>
      import('./pages/location-measurement/location-measurement.module').then(
        m => m.LocationMeasurementModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/measurements',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];

export const Flowing_Gas_Routes: Routes = [
  {
    path: 'allocations',
    loadChildren: () =>
      import('./pages/allocations/allocations.module').then(m => m.AllocationsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/allocations',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'allocations/:id',
    loadChildren: () =>
      import('./pages/allocation-details/allocation-details.module').then(
        m => m.AllocationDetailsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/allocations',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'pda',
    loadChildren: () => import('./pages/pda/pda.module').then(m => m.PdaModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/pda',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'pda/new',
    loadChildren: () => import('./pages/create-pda/create-pda.module').then(m => m.CreatePdaModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/pda',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'pda/new-account',
    loadChildren: () =>
      import('./pages/create-pda-account/create-pda-account.module').then(
        m => m.CreatePdaAccountModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/pda',
      aclUserType: EUserType.Internal,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'pda/:id',
    loadChildren: () =>
      import('./pages/pda-details/pda-details.module').then(m => m.PdaDetailsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/pda',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'pda/:id/edit',
    loadChildren: () => import('./pages/create-pda/create-pda.module').then(m => m.CreatePdaModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/pda',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'pda/:id/edit-account',
    loadChildren: () =>
      import('./pages/create-pda-account/create-pda-account.module').then(
        m => m.CreatePdaAccountModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/pda',
      aclUserType: EUserType.Internal,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'shipper-imbalance',
    loadChildren: () =>
      import('./pages/shipper-imbalance/shipper-imbalance.module').then(
        m => m.ShipperImbalanceModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/shipper-imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'shipper-imbalance/:id',
    loadChildren: () =>
      import('./pages/shipper-imbalance-detail/shipper-imbalance-detail.module').then(
        m => m.ShipperImbalanceDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/shipper-imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'shipper-pool-detail/:id',
    loadChildren: () =>
      import('./pages/shipper-reports/shipper-reports.module').then(m => m.ShipperReportsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/shipper-imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'shipper-pool-summary/:id',
    loadChildren: () =>
      import('./pages/shipper-reports/shipper-reports.module').then(m => m.ShipperReportsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/shipper-imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'shipper-quantity-detail/:id',
    loadChildren: () =>
      import('./pages/shipper-reports/shipper-reports.module').then(m => m.ShipperReportsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/shipper-imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'shipper-quantity-summary/:id',
    loadChildren: () =>
      import('./pages/shipper-reports/shipper-reports.module').then(m => m.ShipperReportsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/shipper-imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'operational-balancing',
    loadChildren: () =>
      import('./pages/operational-balancing/operational-balancing.module').then(
        m => m.OperationalBalancingModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/balancing-agreement-imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'park-and-loan',
    loadChildren: () =>
      import('./pages/park-and-loan/park-and-loan.module').then(m => m.ParkAndLoanModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/flowing-gas/park-and-loan',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  ...Measurement_Routes,
];

export const Imbalance_Routes: Routes = [
  {
    path: 'imbalance-trade',
    loadChildren: () =>
      import('./pages/imbalance-trade-view/imbalance-trade-view.module').then(
        m => m.ImbalanceTradeViewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/imbalance',
    },
  },
  {
    path: 'imbalance-trade/new',
    loadChildren: () =>
      import('./pages/create-imbalance-trade/create-imbalance-trade.module').then(
        m => m.CreateImbalanceTradeModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'imbalance-trade/:id',
    loadChildren: () =>
      import('./pages/imbalance-trade-detail/imbalance-trade-detail.module').then(
        m => m.ImbalanceTradeDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/imbalance',
    },
  },
  {
    path: 'imbalance-trade/:id/edit',
    loadChildren: () =>
      import('./pages/create-imbalance-trade/create-imbalance-trade.module').then(
        m => m.CreateImbalanceTradeModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/imbalance/imbalance-trade',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'shipper-activity',
    loadChildren: () =>
      import('./pages/shipper-activity-view/shipper-activity-view.module').then(
        m => m.ShipperActivityViewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'authorization-to-post',
    loadChildren: () =>
      import('./pages/authorization-to-post/authorization-to-post.module').then(
        m => m.AuthorizationToPostModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/imbalance',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'oba-summary',
    loadChildren: () =>
      import('./pages/oba-summary/oba-summary.module').then(m => m.ObaSummaryModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/imbalance/oba-summary',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];

export const Storage_Routes: Routes = [
  {
    path: 'storage-balance',
    loadChildren: () =>
      import('./pages/storage-balance/storage-balance.module').then(m => m.StorageBalanceModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/storage',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'storage-transfer',
    loadChildren: () =>
      import('./pages/storage-transfer-view/storage-transfer-view.module').then(
        m => m.StorageTransferViewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/storage/storage-transfer',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'storage-transfer/new',
    loadChildren: () =>
      import('./pages/create-storage-transfer/create-storage-transfer.module').then(
        m => m.CreateStorageTransferModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/storage/storage-transfer',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'storage-transfer-nns/new',
    loadChildren: () =>
      import('./pages/create-storage-transfer-nns/create-storage-transfer-nns.module').then(
        m => m.CreateStorageTransferNNSModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/storage/storage-transfer',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'storage-transfer/:id',
    loadChildren: () =>
      import('./pages/storage-transfer-detail/storage-transfer-detail.module').then(
        m => m.StorageTransferDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/storage/storage-transfer',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'storage-transfer/:id/edit',
    loadChildren: () =>
      import('./pages/create-storage-transfer/create-storage-transfer.module').then(
        m => m.CreateStorageTransferModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/storage/storage-transfer',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'storage-tcetq',
    loadChildren: () =>
      import('./pages/storage-tcetq/storage-tcetq.module').then(m => m.StorageTCETQModule),
    canActivate: [ResourceGuard],
    data: {
      aclUserType: EUserType.Internal,
    },
  },
];
