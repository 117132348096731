import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from 'config/app-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  constructor(private httpClient: HttpClient) {}

  public loginSsoUser(body: { code: string }): Observable<any>;

  public loginSsoUser(body: { code: string }): Observable<HttpResponse<any>>;

  public loginSsoUser(body: { code: string }): Observable<HttpEvent<any>>;

  public loginSsoUser(body: { code: string }): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', body.code)
      .set('client_id', appConfig.cognito.adfsClientId)
      .set('client_secret', null)
      .set('redirect_uri', `${window.location.origin}${appConfig.cognito.redirectPathname}`);

    return this.httpClient.post<{
      id_token: string;
      access_token: string;
      refresh_token: string;
      expires_in;
    }>(`${appConfig.cognito.uri}/oauth2/token`, params, { headers });
  }

  public getSsoUserEmail(body: { idToken: string }): Observable<any>;

  public getSsoUserEmail(body: { idToken: string }): Observable<HttpResponse<any>>;

  public getSsoUserEmail(body: { idToken: string }): Observable<HttpEvent<any>>;

  public getSsoUserEmail(body: { idToken: string }): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling getSsoUserEmail.'
      );
    }

    return this.jwtDecode(body.idToken).payload.email;
  }

  public jwtDecode(token: string): { raw: any; header: any; payload: any } {
    const decodedToken = { raw: null, header: null, payload: null };
    decodedToken.raw = token;
    decodedToken.header = JSON.parse(window.atob(token.split('.')[0]));
    decodedToken.payload = JSON.parse(window.atob(token.split('.')[1]));
    return decodedToken;
  }
}
