import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DynamicComponentDefinition } from 'shared/components/dynamic-component-wrapper/dynamic-component.interface';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { CheckboxOptions } from './checkbox-cell.interface';

@Component({
  selector: 'gms-dynamic-checkbox-cell',
  templateUrl: './checkbox-cell.component.html',
  styleUrls: ['./checkbox-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicCheckboxComponent extends HasSubscriptions implements OnInit {
  @Input() data: CheckboxOptions;

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  static componentDefinition(data: CheckboxOptions): DynamicComponentDefinition {
    return {
      dynamicComponentType: DynamicCheckboxComponent,
      data: data,
    };
  }

  ngOnInit() {
    this.addSubscription(
      this.data.control.valueChanges.subscribe(changes => {
        this.cd.markForCheck();
        if (this.data.valueChanged) {
          this.data.valueChanged(changes, this.data.rowIndex);
        }
      })
    );
  }

  public checkboxFocused() {
    if (this.data.checkboxFocused) {
      this.data.checkboxFocused();
    }
  }
}
