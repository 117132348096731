import { EWorkflowTaskAction, WorkflowTaskActions } from './workflow-task-action.actions';
import {
  intialWorkflowTaskActionState,
  IWorkflowTaskActionState,
} from './workflow-task-action.state';

export function workflowTaskActionReducers(
  state = intialWorkflowTaskActionState,
  action: WorkflowTaskActions
): IWorkflowTaskActionState {
  switch (action.type) {
    case EWorkflowTaskAction.GetTaskByDomainId:
      return {
        ...state,
        task: null,
        loading: true,
        success: false,
        error: null,
      };
    case EWorkflowTaskAction.UpdateTaskActionByDomainId:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case EWorkflowTaskAction.GetTaskByDomainIdSuccess:
    case EWorkflowTaskAction.UpdateTaskActionByDomainIdSuccess:
      return {
        ...state,
        task: action.task,
        loading: false,
        success: true,
        error: null,
      };
    case EWorkflowTaskAction.GetTaskByDomainIdError:
    case EWorkflowTaskAction.UpdateTaskActionByDomainIdError:
      return {
        ...state,
        loading: false,
        error: action.error,
        success: false,
      };
    case EWorkflowTaskAction.ClearTaskActionState:
      return {
        ...state,
        task: null,
        loading: false,
        success: true,
        error: null,
      };
    default:
      return state;
  }
}
