import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

import { HttpErrorResponse } from '@angular/common/http';
import {
  ContactLevel,
  EmailType,
  Role,
  RolePermissionOption,
  User,
  UserContactInfoCollection,
  UserPhoneNumberType,
} from '@gms/user-api';
import { ICacheableRequestAction, ICacheableSuccessAction } from '../app/app.models';

export enum EUsersActions {
  FetchUsers = '[USERS] Fetch Users',
  FetchUsersSuccess = '[USERS] Fetch Users Success',
  FetchUsersFailure = '[USERS] Fetch Users Failure',
  FetchUserById = '[USERS] Fetch User By Id',
  FetchUserByIdSuccess = '[USERS] Fetch User By Id Success',
  FetchUserByIdFailure = '[USERS] Fetch User By Id Failure',
  SelectUser = '[USERS] Select User',
  DeselectUser = '[USERS] Deselect User',
  SaveUser = '[USERS] Save User',
  SaveUserSuccess = '[USERS] Save User Success',
  SaveUserFailure = '[USERS] Save User Failure',
  CreateUser = '[USERS] Create User',
  CreateUserSuccess = '[USERS] Create User Success',
  CreateUserFailure = '[USERS] Create User Failure',
  ClearUserUpdateError = '[USERS] Clear Create User Failure',
  UpdateUser = '[USERS] Update User',
  UpdateUserSuccess = '[USERS] Update User Success',
  UpdateUserFailure = '[USERS] Update User Failure',
  SearchUsers = '[USERS] Search Users',
  SearchUsersSuccess = '[USERS] Search Users Success',
  SearchUsersFailure = '[USERS] Search Users Failure',
  ClearSearchedUsers = '[USERS] Clear Searched Users',
  FetchRolesPermissionOptions = '[USERS] Fetch Roles Permission Options',
  FetchRolesPermissionOptionsSuccess = '[USERS] Fetch Roles Permission Options Success',
  FetchRolesPermissionOptionsFailure = '[USERS] Fetch Roles Permission Options Failure',
  FetchRoles = '[USERS] Fetch Roles Options',
  FetchRolesSuccess = '[USERS] Fetch Roles Success',
  FetchRolesFailure = '[USERS] Fetch Roles Failure',
  FetchContactLevels = '[USERS] Fetch Contact Levels',
  FetchContactLevelsSuccess = '[USERS] Fetch Contact Levels Success',
  FetchContactLevelsFailure = '[USERS] Fetch Contact Levels Failure',
  FetchUserPhoneNumberTypes = '[USERS] Fetch PhoneNumber Types',
  FetchUserPhoneNumberTypesSuccess = '[USERS] Fetch PhoneNumber Types Success',
  FetchUserPhoneNumberTypesFailure = '[USERS] Fetch PhoneNumber Types Failure',
  FetchUserEmailTypes = '[USERS] Fetch Email Types',
  FetchUserEmailTypesSuccess = '[USERS] Fetch Email Types Success',
  FetchUserEmailTypesFailure = '[USERS] Fetch Email Types Failure',
  UpdateUserProfile = '[USERS] Update User Profile',
  UpdateUserProfileSuccess = '[USERS] Update User Profile Success',
  UpdateUserProfileFailure = '[USERS] Update User Profile Failure',
  FetchContactInfo = '[USERS] Fetch User Contact Info',
  FetchContactInfoSuccess = '[USERS] Fetch User Contact Info Success',
  FetchContactInfoFailure = '[USERS] Fetch User Contact Info Failure',
  FetchContactInfoSearch = '[USERS] Fetch User Contact Info Search',
  FetchContactInfoSearchSuccess = '[USERS] Fetch User Contact Info Search Success',
  FetchContactInfoSearchFailure = '[USERS] Fetch User Contact Info Search Failure',
  ClearContactInfo = '[USERS] Clear User Contact Info',
}

export interface IFetchUsersPayload {
  pageSize: number;
  pageNumber: number;
  sortDescriptors: SortDescriptor[];
  status?: Array<string>;
  email?: string;
  entityId?: number;
  entityIds?: Array<number>;
  entityName?: string;
  firstName?: string;
  lastName?: string;
  tspId?: number;
  roleId?: number;
  userIds?: Array<number>;
}

export interface IFetchUsersContactInfoPayload {
  pageSize: number;
  pageNumber: number;
  sortBy: SortDescriptor[];
  status?: Array<string>;
  entityId?: number;
  entityIds?: Array<number>;
  firstName?: string;
  lastName?: string;
  tspId?: number;
  roleId?: number;
  roleIds?: Array<number>;
  userSearch?: string;
  userIds?: Array<number>;
  isInternal?: boolean;
  agencyResourceName?: string;
  minPermission?: 'ReadOnly' | 'Edit' | 'Admin';
  agencyTransactionStartDate?: string;
  agencyTransactionEndDate?: string;
  agencyAllowHistoricalView?: boolean;
  contactHasPermission?: boolean;
}
export interface IFetchUserById {
  userId: number;
}

export class FetchUsers implements Action {
  public readonly type = EUsersActions.FetchUsers;

  constructor(public payload: IFetchUsersPayload) {}
}

export class FetchUsersSuccess implements Action {
  public readonly type = EUsersActions.FetchUsersSuccess;

  constructor(public payload: { users: Array<User>; totalUserCount: number }) {}
}

export class FetchUsersFailure implements Action {
  public readonly type = EUsersActions.FetchUsersFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchUserById implements Action {
  public readonly type = EUsersActions.FetchUserById;

  constructor(public payload: IFetchUserById) {}
}

export class FetchUserByIdSuccess implements Action {
  public readonly type = EUsersActions.FetchUserByIdSuccess;

  constructor(public payload: { user: User }) {}
}

export class FetchUserByIdFailure implements Action {
  public readonly type = EUsersActions.FetchUserByIdFailure;

  constructor(public payload: { error: Error }) {}
}

export class SelectUser implements Action {
  public readonly type = EUsersActions.SelectUser;

  constructor(public payload: User) {}
}

export class DeselectUser implements Action {
  public readonly type = EUsersActions.DeselectUser;
}
export class SaveUser implements Action {
  public readonly type = EUsersActions.SaveUser;

  constructor(public payload: { user: User }) {}
}

export class SaveUserSuccess implements Action {
  public readonly type = EUsersActions.SaveUserSuccess;

  constructor(public payload: { user: User }) {}
}

export class SaveUserFailure implements Action {
  public readonly type = EUsersActions.SaveUserFailure;

  constructor(public payload: Error) {}
}

export class CreateUser implements Action {
  public readonly type = EUsersActions.CreateUser;

  constructor(public payload: User) {}
}

export class CreateUserSuccess implements Action {
  public readonly type = EUsersActions.CreateUserSuccess;

  constructor() {}
}

export class CreateUserFailure implements Action {
  public readonly type = EUsersActions.CreateUserFailure;

  constructor(public payload: Error) {}
}

export class UpdateUser implements Action {
  public readonly type = EUsersActions.UpdateUser;

  constructor(public payload: User) {}
}

export class UpdateUserSuccess implements Action {
  public readonly type = EUsersActions.UpdateUserSuccess;

  constructor() {}
}

export class UpdateUserFailure implements Action {
  public readonly type = EUsersActions.UpdateUserFailure;

  constructor(public payload: Error) {}
}

export class ClearUserUpdateError implements Action {
  public readonly type = EUsersActions.ClearUserUpdateError;

  constructor() {}
}

export class ClearSearchedUsers implements Action {
  public readonly type = EUsersActions.ClearSearchedUsers;

  constructor() {}
}

export class SearchUsers implements Action {
  public readonly type = EUsersActions.SearchUsers;

  constructor(
    public payload: {
      userSearch: string;
      pageSize: number;
      pageNumber: number;
      status?: Array<string>;
      email?: string;
      entityId?: number;
      entityName?: string;
      firstName?: string;
      lastName?: string;
      tspId?: number;
      roleId?: number;
      sortDescriptors?: SortDescriptor[];
    }
  ) {}
}

export class SearchUsersSuccess implements Action {
  public readonly type = EUsersActions.SearchUsersSuccess;

  constructor(public payload: { users: Array<User> }) {}
}

export class SearchUsersFailure implements Action {
  public readonly type = EUsersActions.SearchUsersFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchRoles implements ICacheableRequestAction {
  public readonly type = EUsersActions.FetchRoles;

  constructor(public forceRefresh: boolean = false) {}
}

export class FetchRolesSuccess implements ICacheableSuccessAction {
  public readonly type = EUsersActions.FetchRolesSuccess;

  constructor(public payload: Role[]) {}
}

export class FetchRolesFailure implements Action {
  public readonly type = EUsersActions.FetchRolesFailure;

  constructor(public payload: HttpErrorResponse) {}
}

export class FetchRolesPermissionOptions implements ICacheableRequestAction {
  public readonly type = EUsersActions.FetchRolesPermissionOptions;

  constructor(public forceRefresh: boolean = false) {}
}

export class FetchRolesPermissionOptionsSuccess implements ICacheableSuccessAction {
  public readonly type = EUsersActions.FetchRolesPermissionOptionsSuccess;

  constructor(public payload: { rolesPermissionOptions: Array<RolePermissionOption> }) {}
}

export class FetchRolesPermissionOptionsFailure implements Action {
  public readonly type = EUsersActions.FetchRolesPermissionOptionsFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchContactLevels implements ICacheableRequestAction {
  public readonly type = EUsersActions.FetchContactLevels;

  constructor(public forceRefresh: boolean = false) {}
}

export class FetchContactLevelsSuccess implements ICacheableSuccessAction {
  public readonly type = EUsersActions.FetchContactLevelsSuccess;

  constructor(public payload: { contactLevels: Array<ContactLevel> }) {}
}

export class FetchContactLevelsFailure implements Action {
  public readonly type = EUsersActions.FetchContactLevelsFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchUserPhoneNumberTypes implements ICacheableRequestAction {
  public readonly type = EUsersActions.FetchUserPhoneNumberTypes;

  constructor(public forceRefresh: boolean = false) {}
}

export class FetchUserPhoneNumberTypesSuccess implements ICacheableSuccessAction {
  public readonly type = EUsersActions.FetchUserPhoneNumberTypesSuccess;

  constructor(public payload: { phoneNumberTypes: Array<UserPhoneNumberType> }) {}
}

export class FetchUserPhoneNumberTypesFailure implements Action {
  public readonly type = EUsersActions.FetchUserPhoneNumberTypesFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchUserEmailTypes implements ICacheableRequestAction {
  public readonly type = EUsersActions.FetchUserEmailTypes;

  constructor(public forceRefresh: boolean = false) {}
}

export class FetchUserEmailTypesSuccess implements ICacheableSuccessAction {
  public readonly type = EUsersActions.FetchUserEmailTypesSuccess;

  constructor(public payload: { emailTypes: Array<EmailType> }) {}
}

export class FetchUserEmailTypesFailure implements Action {
  public readonly type = EUsersActions.FetchUserEmailTypesFailure;

  constructor(public payload: { error: Error }) {}
}

export class UpdateUserProfile implements Action {
  public readonly type = EUsersActions.UpdateUserProfile;

  constructor(public payload: User) {}
}

export class UpdateUserProfileSuccess implements Action {
  public readonly type = EUsersActions.UpdateUserProfileSuccess;

  constructor(public payload: User) {}
}

export class UpdateUserProfileFailure implements Action {
  public readonly type = EUsersActions.UpdateUserProfileFailure;

  constructor(public payload: Error) {}
}

export class FetchContactInfo implements Action {
  public readonly type = EUsersActions.FetchContactInfo;

  constructor(public payload: IFetchUsersContactInfoPayload) {}
}

export class FetchContactInfoSuccess implements Action {
  public readonly type = EUsersActions.FetchContactInfoSuccess;

  constructor(public payload: UserContactInfoCollection) {}
}

export class FetchContactInfoFailure implements Action {
  public readonly type = EUsersActions.FetchContactInfoFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearContactInfo implements Action {
  public readonly type = EUsersActions.ClearContactInfo;
}

export class FetchContactInfoSearch implements Action {
  public readonly type = EUsersActions.FetchContactInfoSearch;

  constructor(public payload: IFetchUsersContactInfoPayload) {}
}

export class FetchContactInfoSearchSuccess implements Action {
  public readonly type = EUsersActions.FetchContactInfoSearchSuccess;

  constructor(public payload: UserContactInfoCollection) {}
}

export class FetchContactInfoSearchFailure implements Action {
  public readonly type = EUsersActions.FetchContactInfoSearchFailure;

  constructor(public payload: { error: Error }) {}
}

export type UsersActions =
  | FetchUsers
  | FetchUsersSuccess
  | FetchUsersFailure
  | FetchUserById
  | FetchUserByIdSuccess
  | FetchUserByIdFailure
  | SaveUser
  | SaveUserSuccess
  | SaveUserFailure
  | SelectUser
  | DeselectUser
  | CreateUser
  | CreateUserSuccess
  | CreateUserFailure
  | ClearUserUpdateError
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailure
  | SearchUsers
  | SearchUsersSuccess
  | SearchUsersFailure
  | ClearSearchedUsers
  | FetchRoles
  | FetchRolesFailure
  | FetchRolesSuccess
  | FetchRolesPermissionOptions
  | FetchRolesPermissionOptionsFailure
  | FetchRolesPermissionOptionsSuccess
  | FetchContactLevels
  | FetchContactLevelsFailure
  | FetchContactLevelsSuccess
  | FetchUserPhoneNumberTypes
  | FetchUserPhoneNumberTypesFailure
  | FetchUserPhoneNumberTypesSuccess
  | FetchUserEmailTypes
  | FetchUserEmailTypesFailure
  | FetchUserEmailTypesSuccess
  | UpdateUserProfile
  | UpdateUserProfileSuccess
  | UpdateUserProfileFailure
  | FetchContactInfo
  | FetchContactInfoSuccess
  | FetchContactInfoFailure
  | FetchContactInfoSearch
  | FetchContactInfoSearchSuccess
  | FetchContactInfoSearchFailure
  | ClearContactInfo;
