import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Invoicing_Routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'invoice-transactions',
    loadChildren: () =>
      import('./pages/billing-transactions/billing-transactions.module').then(
        m => m.BillingTransactionsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices/invoice-transactions',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'invoice-exceptions',
    loadChildren: () =>
      import('./pages/billing-exceptions/billing-exceptions.module').then(
        m => m.BillingExceptionsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices/invoice-exceptions',
      aclAccessLevel: EPermissionOption.ReadOnly,
      UserTypes: EUserType.Internal,
    },
  },
  {
    path: 'statement-of-account',
    loadChildren: () =>
      import('./pages/statement-of-account/statement-of-account.module').then(
        m => m.StatementOfAccountModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices/statement-of-account',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'invoice-attachments',
    loadChildren: () =>
      import('./pages/invoice-attachments/invoice-attachments.module').then(
        m => m.InvoiceAttachmentsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices/invoice-attachments',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'supplemental-report',
    loadChildren: () =>
      import('./pages/supplemental-report/supplemental-report.module').then(
        m => m.SupplementalReportModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices/supplemental-report',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'quantity-entitlement-summary-report',
    loadChildren: () =>
      import('./pages/quantity-entitlement-report/quantity-entitlement-report.module').then(
        m => m.QuantityEntitlementSummaryReportModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices/quantity-entitlement-summary-report',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id',
    loadChildren: () => import('./pages/invoice/invoice.module').then(m => m.InvoiceModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/invoices',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];
