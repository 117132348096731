import { createSelector } from '@ngrx/store';

import { IAppState } from 'app/store/app/app.state';
import { IMeasurementsState } from 'app/store/measurements/measurements.state';

export const selectMeasurementsState = (state: IAppState) => state.measurements;

export const selectLocationMeasurements = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.locationMeasurements
);

export const selectLocationMeasurementsError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.locationMeasurements.httpError
);

export const selectLocationMeasurementsLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.locationMeasurements.loading
);

export const selectLookupCollections = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.lookupCollections
);

export const selectLookupCollectionsError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.lookupCollections.httpError
);

export const selectLookupCollectionsLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.lookupCollections.loading
);

export const selectUpdateLocationMeasurementLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.updateLocationMeasurement.loading
);

export const selectUpdateLocationMeasurementError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.updateLocationMeasurement.httpError
);

export const selectUpdateLocationMeasurementStatus = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.updateLocationMeasurement
);

export const selectCreateLocationMeasurementLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.createLocationMeasurement.loading
);

export const selectCreateLocationMeasurementError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.createLocationMeasurement.httpError
);

export const selectCreateLocationMeasurementStatus = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.status.createLocationMeasurement
);

export const selectHourlyOverrun = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.hourlyOverrun
);

export const selectExportHourlyOverrun = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.exportHourlyOverrun
);

export const selectExportHourlyOverrunLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.exportHourlyOverrun.requestStatus.loading
);

export const selectGasQuality = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.gasQuality
);

export const selectGasQualityExportLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.exportGasQuality.requestStatus.loading
);

export const selectGasQualityExportLoadingError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.exportGasQuality.requestStatus.httpError
);

export const selectAccumulatedFlowSummary = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.accumulatedFlowSummary.data
);

export const selectAccumulatedFlowSummaryLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.accumulatedFlowSummary.requestStatus.loading
);

export const selectAccumulatedFlowSummaryError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.accumulatedFlowSummary.requestStatus.httpError
);

export const selectAccumulatedFlows = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.accumulatedFlows.data
);

export const selectAccumulatedFlowsLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.accumulatedFlows.requestStatus.loading
);

export const selectAccumulatedFlowsError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.accumulatedFlows.requestStatus.httpError
);

export const selectPagination = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.pagination
);

export const selectTotalMeasurementsCount = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.accumulatedFlowsTotal
);

export const selectExportMeasurementInfoLoading = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.exportMeasurementInfo.requestStatus.loading
);

export const selectExportMeasurementInfoLoadingError = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.exportMeasurementInfo.requestStatus.httpError
);

export const selectLocationMeasurementsSearchState = createSelector(
  selectMeasurementsState,
  (state: IMeasurementsState) => state.locationMeasurementsSearchState
);
