import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export const retryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  matchingStatusCode?: number;
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (
        retryAttempt > maxRetryAttempts ||
        // Workaround due to Error.status returning 0 instead of actual status code
        (error.statusText !== 'Unknown Error' && error.status !== 504)
      ) {
        return throwError(error);
      }
      return timer(retryAttempt * scalingDuration);
    })
  );
};
