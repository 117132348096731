import {
  createCacheableSuccessState,
  createErrorState,
  createInitialDataState,
  createLoadingState,
  createSuccessState,
  useCache,
} from '../app/app.models';
import { AgencyActions, EAgencyActions } from './agency.actions';
import { IAgencyState, initialAgencyState } from './agency.state';

export function agencyReducers(state = initialAgencyState, action: AgencyActions): IAgencyState {
  switch (action.type) {
    case EAgencyActions.CREATE_AGENCY:
    case EAgencyActions.UPDATE_AGENCY:
      return {
        ...state,
        agencyUpdateLoading: true,
        agencyUpdateError: null,
      };
    case EAgencyActions.CREATE_AGENCY_SUCCESS:
    case EAgencyActions.UPDATE_AGENCY_SUCCESS:
      return {
        ...state,
        agencyUpdateLoading: false,
        agencyUpdateError: null,
      };
    case EAgencyActions.CREATE_AGENCY_FAILURE:
    case EAgencyActions.UPDATE_AGENCY_FAILURE:
      return {
        ...state,
        agencyUpdateLoading: false,
        agencyUpdateError: action.payload,
      };
    case EAgencyActions.CLEAR_AGENCY_FAILURE:
      return {
        ...state,
        agencyUpdateError: null,
      };
    case EAgencyActions.SEARCH_AGENCIES:
      return {
        ...state,
        searchAgenciesLoading: true,
        searchAgenciesError: null,
      };
    case EAgencyActions.SEARCH_AGENCIES_SUCCESS:
      return {
        ...state,
        searchedAgencies: action.payload,
        searchAgenciesLoading: false,
        searchAgenciesError: null,
      };
    case EAgencyActions.SEARCH_AGENCIES_FAILURE:
      return {
        ...state,
        searchAgenciesLoading: false,
        searchAgenciesError: action.payload,
      };
    case EAgencyActions.FETCH_AGENCY_BY_ID:
      return {
        ...state,
        agency: null,
        agencyFetchError: null,
        agencyFetchLoading: true,
      };
    case EAgencyActions.FETCH_AGENCY_BY_ID_SUCCESS:
      return {
        ...state,
        agency: action.payload,
        agencyFetchError: null,
        agencyFetchLoading: false,
      };
    case EAgencyActions.FETCH_AGENCY_BY_ID_FAILURE:
      return {
        ...state,
        agency: null,
        agencyFetchError: action.payload,
        agencyFetchLoading: false,
      };
    case EAgencyActions.FETCH_AGENCY_ROLES:
      if (useCache(state.agencyRoles, action)) return state;

      return {
        ...state,
        agencyRoles: createLoadingState(state.agencyRoles),
      };
    case EAgencyActions.FETCH_AGENCY_ROLES_SUCCESS:
      return {
        ...state,
        agencyRoles: createCacheableSuccessState(action.payload),
      };
    case EAgencyActions.FETCH_AGENCY_ROLES_FAILURE:
      return {
        ...state,
        agencyRoles: createErrorState(action.payload),
      };
    case EAgencyActions.FETCH_AGENCY_HEADERS:
      return {
        ...state,
        agencyHeaders: createLoadingState(state.agencyHeaders),
      };
    case EAgencyActions.FETCH_AGENCY_HEADERS_SUCCESS:
      return {
        ...state,
        agencyHeaders: createSuccessState(action.payload.agencyHeaderCollection),
      };
    case EAgencyActions.FETCH_AGENCY_HEADERS_FAILURE:
      return {
        ...state,
        agencyHeaders: createErrorState(action.payload.error),
      };
    case EAgencyActions.CLEAR_AGENCY_HEADERS:
      return {
        ...state,
        agencyHeaders: createInitialDataState(),
      };

    default:
      return state;
  }
}
