import { Action } from '@ngrx/store';

import { RetryableAction } from './retry.actions';
import { initialRetryState, IRetryState } from './retry.state';

export function retryReducers(
  state = initialRetryState,
  action: Action | RetryableAction
): IRetryState {
  return action instanceof RetryableAction && action.isRetry !== undefined && !action.isRetry
    ? {
        ...state,
        savedPayloads: copyAndUpdateMap(state.savedPayloads, action.type, action.payload),
      }
    : state;
}

function copyAndUpdateMap<K, V>(
  map: Map<string, V>,
  keyToUpdate: string,
  newValue: V
): Map<string, V> {
  const newMap = new Map(Object.entries(map));
  newMap.set(keyToUpdate, newValue);

  return newMap;
}
