import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CommunicationService } from '@gms/communication-api';
import {
  ECommunicationActions,
  FetchNotificationTypes,
  FetchNotificationTypesError,
  FetchNotificationTypesSuccess,
} from './communication.actions';

@Injectable()
export class CommunicationEffects {
  constructor(private _actions$: Actions, private _communicationService: CommunicationService) {}

  FetchNotificationTypes$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchNotificationTypes>(ECommunicationActions.FetchNotificationTypes),
      switchMap(() => {
        return this._communicationService.getAllNotificationTypes().pipe(
          map(
            notificationTypes =>
              new FetchNotificationTypesSuccess({
                notificationTypes: notificationTypes,
              })
          ),
          catchError(error => of(new FetchNotificationTypesError({ error: error })))
        );
      })
    )
  );
}
