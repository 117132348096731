import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { PopupSettings } from '@progress/kendo-angular-popup';
import get from 'lodash/get';
import { debounceTime, filter, tap } from 'rxjs/operators';

export interface MultiSelectItem {
  text: string;
  value: any;
  model?: object;
}

/**
 * NOTE: In instances where the result set is API driven or collections are functionaly derived
 * it is important to ensure a primitive value. Kendo will otherwise compare menu items to
 * selections by reference. And since redux will give you a new collection menus will not highlight
 * previously selected items. It is recommended to use the interface above and utilize the model for
 * item templates.
 */
@Component({
  selector: 'app-form-multi-select',
  templateUrl: './form-multi-select.component.html',
  styleUrls: ['./form-multi-select.component.scss'],
})
export class FormMultiSelectComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('multiselect', { static: false }) /*TODO: do we want static to be false?*/
  multiselect: MultiSelectComponent;

  // if controlling from the parent set the isLoadingParentControlled to true.
  @Input()
  loading = false;

  @Input()
  isLoadingParentControlled = false;

  @Input()
  placeholder?: string;

  @Input()
  popupSettings?: PopupSettings;

  @Input()
  value: any;

  @Input()
  data: Array<MultiSelectItem> = [];

  @Input()
  label: string;

  @Input()
  id: string;

  @Input()
  controlName: string;

  @Input()
  group: UntypedFormGroup;

  @Input()
  debounceTime = 0;

  @Input()
  minLength = 0;

  @Input()
  valuePrimitive: boolean;

  @Input()
  textField: string;

  @Input()
  valueField: string;

  @Input()
  noDataText: string;

  @Input()
  filterable = false;

  @Input()
  itemTemplate;

  @Input()
  tagTemplate: boolean;

  @Input()
  noDataTemplate;

  @Input()
  groupTagTemplate;

  _disabled = false;
  @Input()
  set disabled(_disabled: boolean) {
    this._disabled = _disabled;
    if (this.group && this.controlName) {
      if (this._disabled) {
        this.group.get(this.controlName).disable();
      } else {
        this.group.get(this.controlName).enable();
      }
    }
  }

  @Input()
  useVirtualScrolling = false;

  @Input()
  numSelectionsBeforeCombined = 3;

  @Input()
  textLengthBeforeCombined = 15;

  @Input()
  isInActionBar = false;

  /**@Input useOverflowEllipsis [boolean] (Optional): Hides text overflow
   * for each value tag when text overflows over 87% of width and is
   * usually used in combination with numSelectionsBeforeCombined**/
  @Input()
  useOverflowEllipsis = false;

  @Output()
  readonly filterChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly valueChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly opened: EventEmitter<any> = new EventEmitter();

  @Input()
  isInATable: boolean;

  @Output()
  readonly blurred: EventEmitter<any> = new EventEmitter();

  virtualScrollOptions = { itemHeight: 28 };
  useNumSelectionsBeforeCombined = false;

  constructor() {
    this.tagMapper = this.tagMapper.bind(this);
  }

  ngOnInit(): void {
    if (this.isInActionBar) {
      this.numSelectionsBeforeCombined = 2; //Action bar only look good with 1 selection chip
    }
  }

  ngAfterViewInit() {
    if (this.multiselect && this.filterable) {
      this.multiselect.filterChange
        .asObservable()
        .pipe(
          debounceTime(this.debounceTime),
          filter(value => value.length >= this.minLength),
          tap(value => this.filterChanged.emit(value))
        )
        .subscribe(() => {
          if (!this.isLoadingParentControlled) {
            this.loading = true;
          }
        });
    }
  }

  public tagMapper(tags: any[]): any[] {
    if (tags.length) {
      if (this.useNumSelectionsBeforeCombined || !tags[0].text) {
        return tags.length < this.numSelectionsBeforeCombined ? tags : [tags];
      } else {
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        const totalTextLength = tags.map(a => a.text.toString().length);
        const totalLetters = totalTextLength.reduce(reducer);
        return totalLetters < this.textLengthBeforeCombined ? tags : [tags];
      }
    }
  }

  onSelectBlurred() {
    this.blurred.emit(this.group.controls[this.controlName].value);
  }

  ngOnChanges(changes: SimpleChanges) {
    const data = get(changes, 'data.currentValue');
    if (this.multiselect && this.filterable && data && !this.isLoadingParentControlled) {
      this.loading = false;
    }

    if (
      changes.numSelectionsBeforeCombined &&
      changes.numSelectionsBeforeCombined.currentValue !==
        changes.numSelectionsBeforeCombined.previousValue
    ) {
      this.useNumSelectionsBeforeCombined = true;
    }
  }
}
