import { createSelector } from '@ngrx/store';

import { IAppState } from 'app/store/app/app.state';
import { IUsersState } from './users.state';

export const selectUsers = (state: IAppState) => state.usersState;

export const selectAllUsers = createSelector(
  selectUsers,
  (state: IUsersState) => state.users
);

export const selectUserById = createSelector(
  selectUsers,
  (state: IUsersState) => state.selectedUserById
);
export const selectUserByIdLoading = createSelector(
  selectUsers,
  (state: IUsersState) => state.selectedUserByIdLoading
);
export const selectUserByIdError = createSelector(
  selectUsers,
  (state: IUsersState) => state.selectedUserByIdError
);

export const selectSelectedUser = createSelector(
  selectUsers,
  (state: IUsersState) => state.selectedUser
);
export const selectError = createSelector(
  selectUsers,
  (state: IUsersState) => state.error
);
export const selectLoading = createSelector(
  selectUsers,
  (state: IUsersState) => state.loading
);
export const selectPagination = createSelector(
  selectUsers,
  (state: IUsersState) => state.pagination
);
export const selectSortDescriptors = createSelector(
  selectUsers,
  (state: IUsersState) => state.sortDescriptors
);
export const selectTotalUserCount = createSelector(
  selectUsers,
  (state: IUsersState) => state.totalUserCount
);
export const selectUserUpdateLoading = createSelector(
  selectUsers,
  (state: IUsersState) => state.userUpdateLoading
);
export const selectUserUpdateError = createSelector(
  selectUsers,
  (state: IUsersState) => state.userUpdateError
);
export const selectSearchedUsers = createSelector(
  selectUsers,
  (state: IUsersState) => state.usersSearch
);
export const selectSearchUsersError = createSelector(
  selectUsers,
  (state: IUsersState) => state.usersSearchError
);
export const selectSearchUsersLoading = createSelector(
  selectUsers,
  (state: IUsersState) => state.usersSearchLoading
);

export const selectRoles = createSelector(
  selectUsers,
  (state: IUsersState) => state.roles
);

export const selectRolesPermissionOptionsState = createSelector(
  selectUsers,
  (state: IUsersState) => state.rolesPermissionOptions
);
export const selectRolesPermissionOptions = createSelector(
  selectRolesPermissionOptionsState,
  dataState => (dataState.data ? dataState.data : [])
);
export const selectRolesPermissionOptionsError = createSelector(
  selectRolesPermissionOptionsState,
  dataState => dataState.requestStatus.httpError
);
export const selectRolesPermissionOptionsLoading = createSelector(
  selectRolesPermissionOptionsState,
  dataState => dataState.requestStatus.loading
);

export const selectContactLevelsState = createSelector(
  selectUsers,
  (state: IUsersState) => state.contactLevels
);
export const selectContactLevels = createSelector(
  selectContactLevelsState,
  dataState => (dataState.data ? dataState.data : [])
);
export const selectContactLevelsError = createSelector(
  selectContactLevelsState,
  dataState => dataState.requestStatus.httpError
);
export const selectContactLevelsLoading = createSelector(
  selectContactLevelsState,
  dataState => dataState.requestStatus.loading
);

export const selectPhoneNumberTypesState = createSelector(
  selectUsers,
  (state: IUsersState) => state.phoneNumberTypes
);
export const selectPhoneNumberTypes = createSelector(
  selectPhoneNumberTypesState,
  dataState => (dataState.data ? dataState.data : [])
);
export const selectPhoneNumberTypesError = createSelector(
  selectPhoneNumberTypesState,
  dataState => dataState.requestStatus.httpError
);
export const selectPhoneNumberTypesLoading = createSelector(
  selectPhoneNumberTypesState,
  dataState => dataState.requestStatus.loading
);

export const selectEmailTypesState = createSelector(
  selectUsers,
  (state: IUsersState) => state.emailTypes
);
export const selectEmailTypes = createSelector(
  selectEmailTypesState,
  dataState => (dataState.data ? dataState.data : [])
);
export const selectEmailTypesError = createSelector(
  selectEmailTypesState,
  dataState => dataState.requestStatus.httpError
);
export const selectEmailTypesLoading = createSelector(
  selectEmailTypesState,
  dataState => dataState.requestStatus.loading
);

export const selectContactInfoCollectionLoading = createSelector(
  selectUsers,
  (dataState: IUsersState) => dataState.contactInfoCollection.requestStatus.loading
);
export const selectContactInfoCollectionError = createSelector(
  selectUsers,
  (dataState: IUsersState) => dataState.contactInfoCollection.requestStatus.httpError
);
export const selectContactInfoCollection = createSelector(
  selectUsers,
  (dataState: IUsersState) => dataState.contactInfoCollection.data
);

export const selectContactInfoSearchCollectionLoading = createSelector(
  selectUsers,
  (dataState: IUsersState) => dataState.contactInfoCollectionSearch.requestStatus.loading
);
export const selectContactInfoCollectionSearchError = createSelector(
  selectUsers,
  (dataState: IUsersState) => dataState.contactInfoCollectionSearch.requestStatus.httpError
);
export const selectContactInfoCollectionSearch = createSelector(
  selectUsers,
  (dataState: IUsersState) => dataState.contactInfoCollectionSearch.data
);
