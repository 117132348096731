<ng-template #itemTemplate let-dataItem="dataItem">
  <ng-container *ngIf="!dataItem.styled">
    <span class="text" *ngIf="!dataItem?.templateSubtext">
      {{ dataItem?.text }}
    </span>
    <span class="text drop-item" *ngIf="dataItem?.templateSubtext">
      {{ dataItem?.text }} - {{ dataItem?.templateSubtext }}
    </span>
  </ng-container>
  <ng-container *ngIf="dataItem.styled">
    <span class="multi-item-id">{{ dataItem?.text }}</span>
    <span class="multi-item-name">{{ dataItem?.templateSubtext }}</span>
  </ng-container>
</ng-template>

<ng-template #tagTemplate let-dataItem="dataItem">
  <span class="text" *ngIf="!dataItem?.tagSubtext">
    {{ dataItem?.text }}
  </span>
  <span class="text" *ngIf="dataItem?.tagSubtext">
    {{ dataItem?.text }} - {{ dataItem?.tagSubtext }}
  </span>
</ng-template>

<ng-template #groupTagTemplate let-dataItems="dataItems">
  <span>{{ dataItems.length }} selected</span>
</ng-template>

<div class="table-spacing">
  <app-form-multi-select
    [placeholder]="placeholder || 'Select'"
    [id]="data?.id"
    [data]="menuItems"
    [numSelectionsBeforeCombined]="numSelectionsBeforeCombined"
    [controlName]="data?.controlName"
    [group]="data?.group"
    valueField="value"
    textField="text"
    [itemTemplate]="itemTemplate"
    [tagTemplate]="tagTemplate"
    [groupTagTemplate]="groupTagTemplate"
    [class.is-in-a-table]="true"
    isInATable="true"
    filterable="true"
    [popupSettings]="popupSettings || null"
    (filterChanged)="filterChanged($event, data?.controlName)"
    [debounceTime]="debounceTime || 0"
    (valueChanged)="valueChanged($event)"
    (dropDownFocused)="multiselectFocused()"
    (opened)="opened()"
  >
  </app-form-multi-select>
</div>
