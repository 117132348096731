// For now this is hard coded, this file may be generated automatically in the future.

export enum EColor {
  PRIMARY_BLUE = 'primary-blue',
  DARK_BLUE = 'dark-blue',
  WHITE = 'white',
  RED = 'red',
  TEAL = 'teal',
  GREEN = 'green',
  YELLOW = 'yellow',
  LIGHT_BLUE = 'light-blue',
  BLUE = 'blue',
  FOCUS = 'focus',
  HOVER = 'hover',
  PURPLE = 'purple',
  DARK_GRAY = 'dark-gray',
  GRAY = 'gray',
  LIGHT_GRAY = 'light-gray',
  DARKER_BLUE_GRAY = 'darker-blue-gray',
  DARK_BLUE_GRAY = 'dark-blue-gray',
  BLUE_GRAY = 'blue-gray',
  LIGHT_BLUE_GRAY = 'light-blue-gray',
}
