import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Auction,
  AuctionAward,
  AuctionAwardCollection,
  AuctionBanner,
  AuctionBidGrid,
  AuctionCollection,
  AuctionEvent,
  AuctionEventCollection,
  AuctionEventComposite,
  AuctionMessagePanel,
  AuctionNotice,
  AuctionNoticeDocumentContent,
  AuctionParticipant,
  AuctionParticipantCollection,
  AuctionPopOutPanel,
  AuctionRateSchedule,
  AuctionSegment,
  AuctionSegmentCollection,
  AuctionUserCollection,
  ITOnlineAdminCellsCollection,
  ITOnlineCellsCollection,
  ITOnlineDeliveryLocationCollection,
  ITOnlineICELocationCollection,
  ITOnlineParameterSet,
  ITOnlineParameterSetCollection,
  ITOnlineReceiptLocationCollection,
  PostAndBidBiddingWindowMappingCollection,
  ReferenceTypeCollection,
  Trade,
  TradeCollection,
  WorkflowCaseCreationRequest,
} from '@gms/auction-api';
import { AuctionNoticeCollection } from '@gms/auction-api/model/auctionNoticeCollection';
import { DetailRateSchedule, MasterRateSchedule } from '@gms/rateschedulev2-api';
import { ServiceProvider } from '@gms/tsp-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { createInitialDataState, IDataState, IRequestStatus } from '../app/app.models';
import { IAuction, IAuctionNoticeDocumentContentsMap } from './auctions.model';

export interface IAuctionsState {
  pageSize: number;
  pageNumber: number;
  sortDescriptors: SortDescriptor[];
  auctionCollection: IDataState<AuctionCollection>;
  auctionSeriesCollection: AuctionCollection;
  auctionRateSchedules: AuctionRateSchedule[];
  auctionDetailRateSchedules: DetailRateSchedule[];
  auction: IAuction;
  auctionStatus: { loading: boolean; httpError: HttpErrorResponse };
  auctionDraft: IAuction;
  auctionDraftStatus: { loading: boolean; httpError: HttpErrorResponse };
  auctionNotice: IDataState<AuctionNotice>;
  referenceTypes: IDataState<ReferenceTypeCollection>;
  getAuctionAwards: IDataState<AuctionAwardCollection>;
  tradeCollection: IDataState<TradeCollection>;
  getAuctionSeriesCollection: IRequestStatus<any>;
  cancelAuctionStatus: IDataState<Auction>;
  biddingPeriodMappings: IDataState<PostAndBidBiddingWindowMappingCollection>;
  saveAuctionDraftStatus: { loading: boolean; httpError: HttpErrorResponse };
  postAuctionStatus: { loading: boolean; httpError: HttpErrorResponse };
  getAuctionNotices: IDataState<AuctionNoticeCollection>;
  postAuctionEvent: IDataState<AuctionEvent>;
  postAuctionSegmentStatus: { loading: boolean; httpError: HttpErrorResponse };
  auctionSegmentResponse: HttpResponse<AuctionSegment>;
  copyAuctionStatus: { loading: boolean; httpError: HttpErrorResponse };
  getAuctionRateSchedules: IRequestStatus<any>;
  getAuctionRateScheduleDetails: IRequestStatus<any>;
  deleteAuctionDraftStatus: IRequestStatus<any>;
  putAuctionStatus: { loading: boolean; httpError: HttpErrorResponse };
  auctionRateSchedule: DetailRateSchedule;
  auctionMasterRateSchedule: MasterRateSchedule;
  auctionTsp: ServiceProvider;
  serviceRequestForm: {
    valid: {};
    touched: boolean;
  };
  clientSideAuction: IAuction;
  overviewFormLoading: boolean;
  auctionEvents: IDataState<AuctionEventCollection>;
  auctionEventComposite: IDataState<AuctionEventComposite>;
  auctionBid: IDataState<AuctionEvent>;
  auctionBidGrid: IDataState<AuctionBidGrid>;
  withdrawnBidEvent: IDataState<AuctionEvent>;
  auctionParticipant: IDataState<AuctionParticipant>;
  auctionParticipants: AuctionParticipant[];
  getAuctionParticipants: IDataState<AuctionParticipantCollection>;
  postAuctionParticipant: IDataState<AuctionParticipant>;
  auctionSegments: IDataState<AuctionSegmentCollection>;
  auctionParticipantEnterEvent: IDataState<AuctionEvent>;
  auctionMessagePanel: IDataState<AuctionMessagePanel>;
  currentParticipant: AuctionParticipant;
  auctionBanner: IDataState<AuctionBanner>;
  auctionPopoutPanel: IDataState<AuctionPopOutPanel>;
  awardTimerInProgress: boolean;
  awardPeriodEnded: boolean;
  auctionAward: IDataState<AuctionAward>;
  calculatedAwardCollection: IDataState<AuctionAwardCollection>;
  awardCollection: IDataState<AuctionAwardCollection>;
  auctionSettingsValidation: { validate: boolean; valid: boolean };
  workflowCaseCreationRequest: IDataState<WorkflowCaseCreationRequest>;
  auctionNoticeDocumentContent: IDataState<AuctionNoticeDocumentContent>;
  postAuctionNotice: IDataState<AuctionNotice>;
  postTrade: IDataState<Trade>;
  updateTrade: IDataState<Trade>;
  submitTrades: IDataState<Array<Trade>>;
  exportTrades: IRequestStatus;
  disableCloseButton: boolean;
  bidAlternateRateSchedule: DetailRateSchedule;
  itOnlineReceiptLocations: IDataState<ITOnlineReceiptLocationCollection>;
  itOnlineDeliveryLocations: IDataState<ITOnlineDeliveryLocationCollection>;
  itOnlineIceLocations: IDataState<ITOnlineICELocationCollection>;
  itOnlineAdminCells: IDataState<ITOnlineAdminCellsCollection>;
  itOnlineCells: IDataState<ITOnlineCellsCollection>;
  itOnlineParameterSet: IDataState<ITOnlineParameterSetCollection>;
  updatedITOnlineParameterSet: IDataState<ITOnlineParameterSet>;
  deleteITOnlineLocationStatus: IRequestStatus<any>;
  itOnlineLocations: IRequestStatus;
  auctionUsers: IDataState<AuctionUserCollection>;
  searchedAuctionUsers: IDataState<AuctionUserCollection>;
  generatedNotices: IDataState<AuctionNoticeCollection>;
  startingAuction: IDataState<AuctionSegment>;
  auctionNoticeDocumentContentsMap: IAuctionNoticeDocumentContentsMap;
}

export const initialAuctionsState: IAuctionsState = {
  pageSize: 15,
  pageNumber: 1,
  sortDescriptors: [],
  auctionCollection: createInitialDataState(),
  auctionSeriesCollection: {
    auctions: [],
  },
  auction: null,
  auctionNotice: createInitialDataState(),
  auctionStatus: { httpError: null, loading: false },
  auctionDraft: null,
  auctionDraftStatus: { httpError: null, loading: false },
  auctionRateSchedules: [],
  auctionDetailRateSchedules: [],
  referenceTypes: createInitialDataState(),
  getAuctionSeriesCollection: { httpError: null, loading: false },
  saveAuctionDraftStatus: { httpError: null, loading: false },
  postAuctionStatus: { httpError: null, loading: false },
  postAuctionEvent: createInitialDataState(),
  getAuctionAwards: createInitialDataState(),
  getAuctionNotices: createInitialDataState(),
  postAuctionSegmentStatus: { httpError: null, loading: false },
  auctionSegmentResponse: null,
  copyAuctionStatus: { httpError: null, loading: false },
  getAuctionRateSchedules: { httpError: null, loading: false },
  getAuctionRateScheduleDetails: { httpError: null, loading: false },
  cancelAuctionStatus: createInitialDataState(),
  tradeCollection: createInitialDataState(),
  biddingPeriodMappings: createInitialDataState(),
  deleteAuctionDraftStatus: { httpError: null, loading: false },
  putAuctionStatus: { httpError: null, loading: false },
  auctionRateSchedule: null,
  auctionMasterRateSchedule: null,
  auctionTsp: null,
  serviceRequestForm: {
    valid: {},
    touched: false,
  },
  clientSideAuction: null,
  overviewFormLoading: false,
  auctionEvents: createInitialDataState(),
  auctionEventComposite: {
    data: {},
    requestStatus: { loading: false, httpError: null },
  },
  auctionBid: createInitialDataState(),
  auctionBidGrid: createInitialDataState(),
  withdrawnBidEvent: createInitialDataState(),
  auctionParticipant: createInitialDataState(),
  auctionParticipants: null,
  getAuctionParticipants: createInitialDataState(),
  postAuctionParticipant: createInitialDataState(),
  auctionSegments: {
    data: { segments: [] },
    requestStatus: { loading: false, httpError: null },
  },
  auctionParticipantEnterEvent: createInitialDataState(),
  auctionMessagePanel: createInitialDataState(),
  auctionBanner: createInitialDataState(),
  currentParticipant: null,
  auctionPopoutPanel: createInitialDataState(),
  awardTimerInProgress: null,
  awardPeriodEnded: false,
  auctionAward: createInitialDataState(),
  calculatedAwardCollection: createInitialDataState(),
  awardCollection: createInitialDataState(),
  auctionSettingsValidation: { validate: null, valid: null },
  workflowCaseCreationRequest: createInitialDataState(),
  auctionNoticeDocumentContent: createInitialDataState(),
  postAuctionNotice: createInitialDataState(),
  postTrade: createInitialDataState(),
  updateTrade: createInitialDataState(),
  submitTrades: createInitialDataState(),
  exportTrades: { loading: false, httpError: null },
  disableCloseButton: false,
  bidAlternateRateSchedule: null,
  itOnlineReceiptLocations: createInitialDataState(),
  itOnlineDeliveryLocations: createInitialDataState(),
  itOnlineIceLocations: createInitialDataState(),
  itOnlineAdminCells: createInitialDataState(),
  itOnlineCells: createInitialDataState(),
  itOnlineParameterSet: createInitialDataState(),
  updatedITOnlineParameterSet: createInitialDataState(),
  deleteITOnlineLocationStatus: { httpError: null, loading: false },
  itOnlineLocations: { loading: false, httpError: null },
  auctionUsers: createInitialDataState(),
  searchedAuctionUsers: createInitialDataState(),
  generatedNotices: createInitialDataState(),
  startingAuction: createInitialDataState(),
  auctionNoticeDocumentContentsMap: {},
};
