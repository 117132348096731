export interface IGridStatusColor {
  statusColor?: string;
}

//If our APIs align around in naming status fields, this could be refactored into a generic.
export const gridStatusColorMapperFactory = (fieldRef: string, colorMap: any) => {
  return (gridObject: any) => {
    gridObject = gridObject as IGridStatusColor;
    const field: string[] = fieldRef != null ? fieldRef.split('.') : [];

    if (field.length > 1) {
      const parent = gridObject[field[0]];
      if (parent != null) {
        const status: string = parent[field[1]]; // i.e., gridObj.outer.fieldname / single level nesting
        gridObject = {
          ...gridObject,
          statusColor: colorMap[status],
        };
      } else {
        console.log(
          'undefined status entity. check obj data for entity id -> ' + gridObject['entityId']
        );
      }
    } else {
      gridObject = {
        ...gridObject,
        statusColor: colorMap[gridObject[fieldRef]],
      };
    }

    return gridObject;
  };
};
