import {
  ConnectingModelLine,
  DeltaStatuses,
  LineDelta,
  LocationDelta,
  ModelLine,
  ModelLineSequence,
  ModelSequenceLocation,
  ModelStatuses,
  PipelineModel,
  PipelineModelDelta,
} from '@gms/pipeline-api';

import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { dateUtils } from 'shared/utils/date.utils';
import { gridStatusColorMapperFactory } from 'shared/utils/grid.utils';

export const pipelineStatusColorMap = {
  [ModelStatuses.Active]: TagColor.GREEN,
  [ModelStatuses.Draft]: TagColor.BLUE,
  [ModelStatuses.Inactive]: TagColor.GRAY,
  [ModelStatuses.Pending]: TagColor.TEAL,
};

export const mapPipelineModelToGridPipelineModel = gridStatusColorMapperFactory(
  'status',
  pipelineStatusColorMap
);

export const mapPipelineModelsToGridPipelineModels = pipelineModels =>
  pipelineModels.map(mapPipelineModelToGridPipelineModel);

export interface IGridPipelineModel extends PipelineModel {
  statusColor?: string;
}

export interface IDisplayModelLine extends ModelLine {
  deltaStatus?: DeltaStatuses;
}

export interface IDisplayModelLineSequence extends ModelLineSequence {
  connectingModelLines: IDisplayConnectingModelLine;
  deltaStatus?: DeltaStatuses;
  locationDeltas?: Array<LocationDelta>;
  connectingLineDeltas?: Array<LineDelta>;
}

export interface IDisplayModelSequenceLocation extends ModelSequenceLocation {
  deltaStatus?: DeltaStatuses;
}

export interface IDisplayConnectingModelLine extends ConnectingModelLine {
  deltaStatus?: DeltaStatuses;
}

const DATE_KEYS = ['dateEffective', 'dateExpire'];

function addDeltaToConnectingLines(
  connectingModelLines: Array<IDisplayConnectingModelLine>,
  connectingLineDeltas: Array<LineDelta>
): Array<IDisplayConnectingModelLine> {
  const gridConnectingModelLines = [...connectingModelLines] as Array<IDisplayConnectingModelLine>;

  if (connectingLineDeltas) {
    connectingLineDeltas.forEach(connectingLineDelta => {
      const gridConnectingModelLine = gridConnectingModelLines.find(
        line => line.modelLineId === connectingLineDelta.modelLineId.toString()
      );

      if (gridConnectingModelLine) {
        gridConnectingModelLine.deltaStatus = connectingLineDelta.deltaStatus;
      }
    });
  }

  return gridConnectingModelLines;
}

export const mapModelLine = (modelLine: ModelLine): ModelLine => ({
  ...modelLine,
  dateEffective: dateUtils.stripTimeFromDate(modelLine.dateEffective),
  line: modelLine.line
    ? {
        ...modelLine.line,
        capacities: modelLine.line.capacities
          ? modelLine.line.capacities.map(capacity => ({
              ...capacity,
              ...DATE_KEYS.reduce(
                (parsedDates, key) => ({
                  ...parsedDates,
                  [key]: capacity[key]
                    ? dateUtils.stripTimeFromDate(capacity[key] + ' 00:00:00')
                    : null,
                }),
                {}
              ),
            }))
          : undefined,
        overrideCapacities: modelLine.line.overrideCapacities
          ? modelLine.line.overrideCapacities.map(overrideCapacity => ({
              ...overrideCapacity,
              ...DATE_KEYS.reduce(
                (parsedDates, key) => ({
                  ...parsedDates,
                  [key]: overrideCapacity[key]
                    ? dateUtils.stripTimeFromDate(overrideCapacity[key] + ' 00:00:00')
                    : null,
                }),
                {}
              ),
            }))
          : undefined,
      }
    : undefined,
});

export const mapModelLineSequence = (modelLineSequence: ModelLineSequence): ModelLineSequence => {
  // IRL scenario this comes in as a JSON datestring(even though the type ModelLineSequence.statusEffective is a Date)
  // Unit tests send in an actual date type. We only need to convert if it comes in as a string.
  const statusEffective =
    typeof modelLineSequence.statusEffective === 'string'
      ? dateUtils.convertUTCJsonDateToDateObject(modelLineSequence.statusEffective)
      : modelLineSequence.statusEffective;

  return {
    ...modelLineSequence,
    statusEffective: statusEffective,
  };
};

export const pipelineModelsUtils = {
  addDeltaToModelLines(
    modelLines: Array<ModelLine>,
    pipelineModelDelta: PipelineModelDelta
  ): Array<IDisplayModelLine> {
    const gridModelLines = [...modelLines] as Array<IDisplayModelLine>;

    if (pipelineModelDelta && pipelineModelDelta.lineDeltas) {
      pipelineModelDelta.lineDeltas.forEach(lineDelta => {
        const gridModelLine = gridModelLines.find(
          line => line.modelLineId === lineDelta.modelLineId
        );

        if (gridModelLine) {
          gridModelLine.deltaStatus = lineDelta.deltaStatus;
        }
      });
    }

    return gridModelLines;
  },

  addDeltaToModelSequences(
    modelLineSequencesByModelLine: Map<number, Array<ModelLineSequence>>,
    pipelineModelDelta: PipelineModelDelta
  ): Map<number, Array<IDisplayModelLineSequence>> {
    const gridModelLineSequencesByModelLine = new Map(modelLineSequencesByModelLine) as Map<
      number,
      Array<IDisplayModelLineSequence>
    >;

    if (pipelineModelDelta && pipelineModelDelta.lineDeltas) {
      pipelineModelDelta.lineDeltas.forEach(lineDelta => {
        const modelLineSequences = gridModelLineSequencesByModelLine.get(lineDelta.modelLineId);

        if (modelLineSequences && lineDelta.sequenceDeltas) {
          const gridModelLineSequences = [...modelLineSequences];

          lineDelta.sequenceDeltas.forEach(sequenceDelta => {
            let indexOfModifiedSequence = -1;

            gridModelLineSequences.some((gridModelLineSequence, i) => {
              if (gridModelLineSequence.modelLineSequenceId === sequenceDelta.modelLineSequenceId) {
                indexOfModifiedSequence = i;
                return true;
              } else {
                return false;
              }
            });

            if (indexOfModifiedSequence !== -1) {
              const gridModelLineSequence = gridModelLineSequences[indexOfModifiedSequence];

              gridModelLineSequences[indexOfModifiedSequence] = {
                ...gridModelLineSequence,
                deltaStatus: sequenceDelta.deltaStatus,
                locationDeltas: sequenceDelta.locationDeltas,
                connectionModelLines: gridModelLineSequence.connectionModelLines
                  ? addDeltaToConnectingLines(
                      gridModelLineSequence.connectionModelLines,
                      sequenceDelta.connectingLineDeltas
                    )
                  : undefined,
              };
            }
          });

          gridModelLineSequencesByModelLine.set(lineDelta.modelLineId, gridModelLineSequences);
        }
      });
    }

    return gridModelLineSequencesByModelLine;
  },

  addDeltaToModelSequenceLocations(
    modelLineLocationsBySequenceLocation: Map<number, Array<ModelSequenceLocation>>,
    pipelineModelDelta: PipelineModelDelta
  ): Map<number, Array<IDisplayModelSequenceLocation>> {
    const gridModelLineLocationsByModelLineSequence = new Map(
      modelLineLocationsBySequenceLocation
    ) as Map<number, Array<IDisplayModelSequenceLocation>>;

    if (pipelineModelDelta && pipelineModelDelta.lineDeltas) {
      pipelineModelDelta.lineDeltas.forEach(lineDelta => {
        if (lineDelta && lineDelta.sequenceDeltas) {
          lineDelta.sequenceDeltas.forEach(sequenceDelta => {
            const modelLineLocations = gridModelLineLocationsByModelLineSequence.get(
              sequenceDelta.modelLineSequenceId
            );

            if (modelLineLocations && sequenceDelta.locationDeltas) {
              const gridModelLineLocations = [...modelLineLocations];

              sequenceDelta.locationDeltas.forEach(locationDelta => {
                let indexOfModifiedLocation = -1;

                gridModelLineLocations.some((gridModelLineLocation, i) => {
                  if (
                    gridModelLineLocation.sequenceLocationId ===
                    locationDelta.modelSequenceLocationId
                  ) {
                    indexOfModifiedLocation = i;
                    return true;
                  } else {
                    return false;
                  }
                });

                if (indexOfModifiedLocation !== -1) {
                  gridModelLineLocations[indexOfModifiedLocation] = {
                    ...gridModelLineLocations[indexOfModifiedLocation],
                    deltaStatus: locationDelta.deltaStatus,
                  };
                }
              });

              gridModelLineLocationsByModelLineSequence.set(
                sequenceDelta.modelLineSequenceId,
                gridModelLineLocations
              );
            }
          });
        }
      });
    }

    return gridModelLineLocationsByModelLineSequence;
  },
};
