export class ACLResources {
  static readonly CAPACITY_RELEASE = '/capacity-release';
  static readonly BIDS = '/capacity-release/bids';
  static readonly OFFERS = '/capacity-release/offers';
  static readonly CYCLES = '/capacity-release/cycles';
  static readonly WITHDRAWALS = '/capacity-release/withdrawals';
  static readonly AWARDS = '/capacity-release/awards';
  static readonly BID_EVALUATION = '/capacity-release/bidevaluation';
  static readonly RECALLS_REPUT = '/capacity-release/recalls-reputs';
}
