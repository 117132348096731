import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountAllDetail,
  AccountDetail,
  BillingAccountAllService,
  BillingAccountsService,
} from '@gms/billing-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import {
  AddAccount,
  AddAccountFailure,
  AddAccountSuccess,
  EOperationAccountsActions,
  GetAccountById,
  GetAccountByIdFailure,
  GetAccountByIdSuccess,
  GetAccountsCollection,
  GetAccountsCollectionFailure,
  GetAccountsCollectionSuccess,
} from './operational-accounts.actions';

@Injectable({ providedIn: 'root' })
export class OperationalAccountsEffects {
  constructor(
    private _actions$: Actions,
    private _billingAccountAllService: BillingAccountAllService,
    private _billingAccountsService: BillingAccountsService
  ) {}

  getAccountsCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAccountsCollection>(EOperationAccountsActions.FETCH_ACCOUNTS_COLLECTION),
      map((action: GetAccountsCollection) => action),
      switchMap(action => {
        const {
          pageSize,
          pageNumber,
          sortDescriptors,
          tspId,
          entityIds,
          viewBy,
          accountTypeId,
          svcReqNameId,
          searchPhrase,
          accountTypeIds
        } = action.payload;

        let sortBy: string[] = null;
        if (!isNullOrUndefined(sortDescriptors) && sortDescriptors.length > 0) {
          sortBy = [`${sortDescriptors[0].field}+${sortDescriptors[0].dir}`];
        }

        return this._billingAccountAllService
          .getAccountsAll(
            pageSize,
            pageNumber,
            sortBy,
            tspId,
            !!accountTypeId ? +accountTypeId : null,
            !!svcReqNameId ? +svcReqNameId : null,
            !!entityIds ? entityIds.join('|') : null,
            null, // {accountIds}
            viewBy,
            searchPhrase,
            accountTypeIds
          )
          .pipe(
            map(response => new GetAccountsCollectionSuccess(response)),
            catchError(error => of(new GetAccountsCollectionFailure(error)))
          );
      })
    )
  );

  getAccountById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAccountById>(EOperationAccountsActions.FETCH_ACCOUNT_BY_ID),
      map((action: AccountAllDetail) => action),
      switchMap(action => {
        return this._billingAccountsService.getAccountById(action.id).pipe(
          map((account: AccountAllDetail) => {
            return new GetAccountByIdSuccess(account);
          }),
          catchError(error => of(new GetAccountByIdFailure(error)))
        );
      })
    )
  );

  addAccount$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddAccount>(EOperationAccountsActions.ADD_ACCOUNT),
      debounceTime(500),
      map((action: AddAccount) => action),
      switchMap(action =>
        this._billingAccountsService.addAccount(action.payload).pipe(
          map((account: AccountDetail) => new AddAccountSuccess(account)),
          catchError((error: HttpErrorResponse) => of(new AddAccountFailure(error.error)))
        )
      )
    )
  );
}
