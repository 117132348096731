import { Routes } from '@angular/router';

export const Agent_Routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/agents.module').then(m => m.AgentsModule),
  },
  {
    path: ':id',
    loadChildren: () => import('./pages/agents.module').then(m => m.AgentsModule),
  },
  {
    path: 'new',
    loadChildren: () => import('./pages/agents.module').then(m => m.AgentsModule),
  },
  {
    path: ':id/edit',
    loadChildren: () => import('./pages/agents.module').then(m => m.AgentsModule),
  },
];
