import { StorageTransferTCETQ, StorageTransferTCETQCollection } from '@gms/imbalance-api';
import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import { EStorageTransferActions, StorageTransferActions } from './storage-transfer.actions';
import { initialStorageTransferState, IStorageTransferState } from './storage-transfer.state';

const ANNUAL_MONTHS = ['04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03'];
function insertTcetqEmptyMonthData(
  storageTransfers: StorageTransferTCETQ[]
): StorageTransferTCETQ[] {
  const processedStorageTransfers: StorageTransferTCETQ[] = [];
  for (let i = 0; i < ANNUAL_MONTHS.length; i++) {
    const groupedByMonthRecords = storageTransfers.filter(
      transfer => transfer.accountingPeriod.toString().split('-')[1] === ANNUAL_MONTHS[i]
    );
    groupedByMonthRecords.length
      ? processedStorageTransfers.push(...groupedByMonthRecords)
      : processedStorageTransfers.push({ accountingPeriod: (ANNUAL_MONTHS[i] as unknown) as Date });
  }
  return processedStorageTransfers;
}

function transformTcetqCollection(
  tcetqCollection: StorageTransferTCETQCollection
): StorageTransferTCETQCollection {
  const processedStorageTransfers = insertTcetqEmptyMonthData(tcetqCollection.storageTransfers);
  return {
    ...tcetqCollection,
    storageTransfers: processedStorageTransfers,
    total: processedStorageTransfers.length,
  };
}

export function storageTransferReducer(
  state = initialStorageTransferState,
  action: StorageTransferActions
): IStorageTransferState {
  switch (action.type) {
    case EStorageTransferActions.FETCH_STORAGE_TRANSFER_COLLECTION:
      return {
        ...state,
        accountPeriod: action.payload.accountPeriod,
        getStorageTransferCollection: { loading: true, httpError: null },
      };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFER_COLLECTION_SUCCESS:
      return {
        ...state,
        storageTransferCollection: action.payload,
        getStorageTransferCollection: { loading: false, httpError: null },
      };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFER_COLLECTION_FAILURE:
      return {
        ...state,
        storageTransferCollection: null,
        getStorageTransferCollection: { loading: false, httpError: action.payload },
      };
    case EStorageTransferActions.CREATE_STORAGE_TRANSFER:
      return {
        ...state,
        createStorageTransfer: { loading: true, httpError: null },
      };
    case EStorageTransferActions.CREATE_STORAGE_TRANSFER_SUCCESS:
      return {
        ...state,
        storageTransfer: action.payload,
        createStorageTransfer: { loading: false, httpError: null },
      };
    case EStorageTransferActions.CREATE_STORAGE_TRANSFER_FAILURE:
      return {
        ...state,
        storageTransfer: null,
        createStorageTransfer: { loading: false, httpError: action.payload },
      };
    case EStorageTransferActions.CLEAR_STORAGE_TRANSFER_ERROR:
      return {
        ...state,
        createStorageTransfer: { loading: false, httpError: null },
        updateStorageTransfer: { reqStatus: { loading: false, httpError: null }, actionFlag: null },
      };
    case EStorageTransferActions.CREATE_STORAGE_TRANSFER_NNS:
      return {
        ...state,
        createStorageTransferNNS: { loading: true, httpError: null },
      };
    case EStorageTransferActions.CREATE_STORAGE_TRANSFER_NNS_SUCCESS:
      return {
        ...state,
        storageTransferNNS: action.payload,
        createStorageTransferNNS: { loading: false, httpError: null },
      };
    case EStorageTransferActions.CREATE_STORAGE_TRANSFER_NNS_FAILURE:
      return {
        ...state,
        storageTransferNNS: null,
        createStorageTransferNNS: { loading: false, httpError: action.payload },
      };
    case EStorageTransferActions.CLEAR_STORAGE_TRANSFER_NNS_ERROR:
      return {
        ...state,
        createStorageTransferNNS: { loading: false, httpError: null },
      };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFERS_NNS_OFFERS:
        return {
          ...state,
          offersNNSLoading: true,
          offers: createLoadingState(state.offers),
        };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFERS_NNS_OFFERS_SUCCESS:
        return {
          ...state,
          offersNNSLoading: false,
          offers: createSuccessState(action.payload?.offers),
        };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFERS_NNS_OFFERS_ERROR:
        return {
          ...state,
          offersNNSLoading: false,
          offers: createErrorState(action.payload, []),
        };
    case EStorageTransferActions.SET_STORAGE_TRANSFER:
      return {
        ...state,
        storageTransfer: action.payload,
      };
    case EStorageTransferActions.UPDATE_STORAGE_TRANSFER:
      return {
        ...state,
        updateStorageTransfer: {
          ...state.updateStorageTransfer,
          reqStatus: { loading: true, httpError: null },
          actionFlag: action.payload.actionFlag,
        },
      };
    case EStorageTransferActions.UPDATE_STORAGE_TRANSFER_SUCCESS:
      return {
        ...state,
        storageTransfer: {
          ...state.storageTransfer,
          status: action.payload.status,
        },
        updateStorageTransfer: {
          ...state.updateStorageTransfer,
          reqStatus: { loading: false, httpError: null },
        },
      };
    case EStorageTransferActions.UPDATE_STORAGE_TRANSFER_FAILURE:
      return {
        ...state,
        updateStorageTransfer: {
          ...state.updateStorageTransfer,
          reqStatus: { loading: false, httpError: action.payload },
        },
      };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFER_BY_ID:
      return {
        ...state,
        getStorageTransferById: { loading: true, httpError: null },
      };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFER_BY_ID_SUCCESS:
      return {
        ...state,
        storageTransfer: action.payload,
        getStorageTransferById: { loading: false, httpError: null },
      };
    case EStorageTransferActions.FETCH_STORAGE_TRANSFER_BY_ID_FAILURE:
      return {
        ...state,
        storageTransfer: null,
        getStorageTransferById: { loading: false, httpError: action.payload },
      };
    case EStorageTransferActions.WITHDRAW_STORAGE_TRANSFER:
      return {
        ...state,
        withdrawStorageTransfer: { loading: true, httpError: null },
      };
    case EStorageTransferActions.WITHDRAW_STORAGE_TRANSFER_SUCCESS:
      return {
        ...state,
        withdrawStorageTransfer: { loading: false, httpError: null },
      };
    case EStorageTransferActions.WITHDRAW_STORAGE_TRANSFER_FAILURE:
      return {
        ...state,
        withdrawStorageTransfer: { loading: false, httpError: action.payload },
      };
    case EStorageTransferActions.CLEAR_SELECTED_STORAGE_TRANSFER:
      return {
        ...state,
        storageTransfer: null,
      };
    case EStorageTransferActions.FETCH_PARK_AND_LOAN_COLLECTION:
      return {
        ...state,
        getParkAndLoanCollection: { loading: true, httpError: null },
      };
    case EStorageTransferActions.FETCH_PARK_AND_LOAN_COLLECTION_SUCCESS:
      return {
        ...state,
        parkAndLoanCollection: action.payload,
        getParkAndLoanCollection: { loading: false, httpError: null },
      };
    case EStorageTransferActions.FETCH_PARK_AND_LOAN_COLLECTION_FAILURE:
      return {
        ...state,
        parkAndLoanCollection: {
          parkAndLoans: [],
          total: null,
        },
        getParkAndLoanCollection: { loading: false, httpError: action.payload },
      };
    case EStorageTransferActions.EXPORT_PARK_AND_LOAN_COLLECTION:
      return {
        ...state,
        exportParkAndLoan: { loading: true, httpError: null },
      };
    case EStorageTransferActions.EXPORT_PARK_AND_LOAN_COLLECTION_SUCCESS:
      return {
        ...state,
        exportParkAndLoan: { loading: false, httpError: null },
      };
    case EStorageTransferActions.EXPORT_PARK_AND_LOAN_COLLECTION_FAILURE:
      return {
        ...state,
        exportParkAndLoan: { loading: false, httpError: action.payload },
      };
    case EStorageTransferActions.FETCH_STORAGE_TCETQ_COLLECTION:
      return {
        ...state,
        storageTcetqCollection: createLoadingState(state.storageTcetqCollection),
      };
    case EStorageTransferActions.FETCH_STORAGE_TCETQ_COLLECTION_SUCCESS:
      return {
        ...state,
        storageTcetqCollection: createSuccessState(action.payload, transformTcetqCollection),
      };
    case EStorageTransferActions.FETCH_STORAGE_TCETQ_COLLECTION_FAILURE:
      return {
        ...state,
        storageTcetqCollection: createErrorState(action.payload),
      };
    default:
      return state;
  }
}
