import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  ECommonComponentOwner,
  IsDeprecatedComponent,
} from 'shared/components/higher-order/is-deprecated-component';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
/* tslint:disable-next-line */
export class ButtonComponent_DEPRECATED extends IsDeprecatedComponent implements OnInit, OnChanges {
  @Input()
  id: string;

  @Input()
  disabled = false;

  @Input()
  size: 'large' | 'regular' | 'small' | 'mini' = 'regular';

  @Input()
  type: 'primary' | 'secondary' = 'primary';

  @Input()
  buttonType: 'button' | 'submit' | 'reset' = 'submit';

  @Input()
  square = false;

  @Input()
  focused = false;

  @Output()
  readonly activated = new EventEmitter<Event>();

  buttonClasses: string[];

  constructor() {
    super('app-button', 'gms-button', [
      ECommonComponentOwner.KEVIN_ORLANDO,
      ECommonComponentOwner.MAC_CARTER,
    ]);
  }

  ngOnInit() {
    this.setButtonClasses();
  }

  ngOnChanges() {
    this.setButtonClasses();
  }

  setButtonClasses() {
    this.buttonClasses = [
      this.size,
      this.type,
      this.square ? 'square' : null,
      this.disabled ? 'disabled' : 'enabled',
      this.focused ? 'focused' : null,
    ].filter(Boolean);
  }

  activate(event) {
    if (!this.disabled) {
      this.activated.emit(event);
    }
  }
}
