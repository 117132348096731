import { Injectable } from '@angular/core';
import { User } from '@gms/user-api';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/app/app.state';
import { selectUser as selectAuthUser } from 'app/store/auth/auth.selectors';
import { SelectUser } from 'app/store/users/users.actions';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileResolverService {
  constructor(private _store: Store<IAppState>) {}

  resolve(): Observable<User> {
    const authUser$ = this._store.pipe(select(selectAuthUser));

    const currentUser$ = authUser$.pipe(
      filter(user => !!user),
      take(1)
    );

    currentUser$.subscribe(user => {
      this._store.dispatch(new SelectUser(user));
    });

    return currentUser$;
  }
}
