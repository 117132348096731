import {
  ContactLevel,
  Role,
  RolePermissionOption,
  User,
  UserAdditionalEmail,
  UserContactInfoCollection,
  UserPhoneNumberType,
} from '@gms/user-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { createInitialDataState, ICachedDataState, IDataState } from 'app/store/app/app.models';
import { getPagination, IPagination } from 'shared/models/pagination.model';

export interface IUsersState {
  users: Array<User> | null;
  selectedUserById: User | null;
  selectedUserByIdLoading: boolean;
  selectedUserByIdError: Error;
  selectedUser: User | null;
  sortDescriptors: SortDescriptor[];
  pagination: IPagination;
  totalUserCount: number;
  loading: boolean;
  error: Error | null;
  userUpdateLoading: boolean;
  userUpdateError: Error;
  usersSearch: User[];
  usersSearchLoading: boolean;
  usersSearchError: Error | null;
  roles: ICachedDataState<Role[]>;
  rolesPermissionOptions: ICachedDataState<RolePermissionOption[]>;
  contactLevels: ICachedDataState<ContactLevel[]>;
  phoneNumberTypes: ICachedDataState<UserPhoneNumberType[]>;
  emailTypes: ICachedDataState<UserAdditionalEmail[]>;
  contactInfoCollection: IDataState<UserContactInfoCollection>;
  contactInfoCollectionSearch: IDataState<UserContactInfoCollection>;
}

export const initialUsersState: IUsersState = {
  users: [],
  selectedUserById: null,
  selectedUserByIdLoading: false,
  selectedUserByIdError: null,
  selectedUser: null,
  sortDescriptors: [
    {
      field: 'lastName',
      dir: 'asc',
    },
  ],
  pagination: getPagination(0),
  totalUserCount: 0,
  loading: false,
  error: null,
  userUpdateLoading: false,
  userUpdateError: null,
  usersSearch: [],
  usersSearchLoading: false,
  usersSearchError: null,
  roles: createInitialDataState(),
  rolesPermissionOptions: createInitialDataState(),
  contactLevels: createInitialDataState(),
  phoneNumberTypes: createInitialDataState(),
  emailTypes: createInitialDataState(),
  contactInfoCollection: createInitialDataState(),
  contactInfoCollectionSearch: createInitialDataState(),
};
