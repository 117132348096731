import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isStaticTagEmpty', pure: false })
export class IsStaticTagEmptyPipe implements PipeTransform {
  transform(value: any): boolean {
    const childNodesArray = Array.prototype.slice.call(value.childNodes);
    if (!childNodesArray.length) {
      return true;
    } else {
      return childNodesArray.some(childNode => {
        switch (childNode.nodeType) {
          case 8: //Comment Node
            return false;
          case 3: // Text Node
            return (
              (childNode.textContent !== undefined
                ? childNode.textContent
                : childNode.innerText
              ).trim().length === 0
            );
          default:
            // Anything else counts
            return false;
        }
      });
    }
  }
}
