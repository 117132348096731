import { HttpErrorResponse } from '@angular/common/http';
import {
  Award,
  AwardCollection,
  Bid,
  DictionaryModel,
  LocationOptionsResponse,
  Offer,
  OfferCollection,
  OffersSearchLookup,
  WithdrawalCollection,
} from '@gms/capacityrelease-api';
import { BidCollection } from '@gms/capacityrelease-api/model/bidCollection';
import { Contract, ContractOverview } from '@gms/contract-api';
import { Entity } from '@gms/entity-api';
import { User } from '@gms/user-api';
import { BidderViewModesEnum } from 'app/modules/capacity-release/pages/bids/bids.enums';
import { OfferViewModesEnum } from 'app/modules/capacity-release/pages/offers/offers.enums';
import { emptyRequestStatus, IDataState } from 'app/store/app/app.models';

export interface IRequestStatus {
  loadingCount?: number;
  success?: boolean;
  loading: boolean;
  error: HttpErrorResponse;
}

export interface IGroupedRequestStatus extends IRequestStatus {
  loadingByItem: { [offerId: number]: boolean };
}

export interface ICapacityReleaseState {
  bid: Bid;
  bidsForFilters: Bid[];
  bidsForFiltersStatus: IRequestStatus;
  bidderContacts: User[];
  bidderContactsStatus: IRequestStatus;
  bidsCollection: BidCollection;
  bidsCollectionStatus: IRequestStatus;
  bidderViewMode: BidderViewModesEnum;
  offerViewMode: OfferViewModesEnum;
  deleteBidStatus: IRequestStatus;
  withdrawBidStatus: IRequestStatus;
  offers: OfferCollection;
  offersCollectionStatus: IRequestStatus;
  offer: Offer;
  offerStatus: IRequestStatus;
  withdrawals: WithdrawalCollection;
  withdrawalsCollectionStatus: IRequestStatus;
  bidStatus: IRequestStatus;
  offerBids: BidCollection;
  offerBidsStatus: IRequestStatus;
  dictionary: DictionaryModel;
  dictionaryStatus: IRequestStatus;
  offersSearchLookup: OffersSearchLookup;
  offersSearchLookupStatus: IRequestStatus;
  deleteOfferStatus: IRequestStatus;
  confirmBidStatus: IRequestStatus;
  rejectBidStatus: IRequestStatus;
  withdrawOfferStatus: IRequestStatus;
  copyOfferStatus: IRequestStatus;
  withdrawnBids: Bid[];
  withdrawnBidsStatus: IRequestStatus;
  award: Award;
  originalAward: Award;
  linkedAward: Award;
  awardsCollection: AwardCollection;
  awardsCollectionStatus: IRequestStatus;
  awardsForFilters: Award[];
  awardsForFiltersStatus: IRequestStatus;
  awardOffer: { [key: number]: Offer[] };
  awardOfferStatus: IRequestStatus;
  releaser: { entityId: number; name: string };
  releaserOptions: { entityId: number; name: string }[];
  bidEvaluationOffers: Offer[];
  bidEvaluationOffersStatus: IRequestStatus;
  awardStatus: IRequestStatus;
  originalAwardStatus: IRequestStatus;
  linkedAwardStatus: IRequestStatus;
  bidEvaluationBids: { [key: number]: Bid[] };
  bidEvaluationBidsStatus: IGroupedRequestStatus;
  bidEvaluationAwards: { [key: number]: Award[] };
  bidEvaluationAwardsStatus: IGroupedRequestStatus;
  matchingBid: Bid;
  matchingBidStatus: IRequestStatus;
  contractOverview: ContractOverview;
  contractOverviewStatus: IRequestStatus;
  offerLocationOptions: LocationOptionsResponse;
  offerLocationOptionsStatus: IRequestStatus;
  preApprovedBidders: Entity[];
  preApprovedBiddersStatus: IRequestStatus;
  searchedOffers: IDataState<OfferCollection>;
  multipleOfferLocationsOptions: IDataState<LocationOptionsResponse[]>;
  parentOffer: Offer;
}

export const initialCapacityReleaseState: ICapacityReleaseState = {
  bidsForFilters: [],
  bidsForFiltersStatus: {
    loading: false,
    error: null,
  },
  bid: null,
  bidStatus: {
    loading: false,
    error: null,
  },
  bidderContacts: null,
  bidderContactsStatus: {
    loading: false,
    error: null,
  },
  bidsCollection: {
    bids: [],
    total: 0,
  },
  bidsCollectionStatus: {
    loading: false,
    error: null,
  },
  bidderViewMode: BidderViewModesEnum.DOWNLOAD,

  offerViewMode: OfferViewModesEnum.DOWNLOAD,

  deleteBidStatus: {
    success: false,
    loading: false,
    error: null,
  },

  withdrawBidStatus: {
    success: false,
    loading: false,
    error: null,
  },

  offers: {
    offers: [],
    total: 0,
  },

  offersCollectionStatus: {
    loading: false,
    error: null,
  },

  offer: null,
  offerStatus: {
    loading: false,
    error: null,
  },

  withdrawals: {
    withdrawals: [],
    total: 0,
  },

  withdrawalsCollectionStatus: {
    loading: false,
    error: null,
  },

  offerBids: null,
  offerBidsStatus: {
    loading: false,
    error: null,
  },

  dictionary: null,
  dictionaryStatus: {
    loading: false,
    error: null,
  },

  offersSearchLookup: null,
  offersSearchLookupStatus: {
    loading: false,
    error: null,
  },

  deleteOfferStatus: {
    success: false,
    loading: false,
    error: null,
  },
  withdrawOfferStatus: {
    success: false,
    loading: false,
    error: null,
  },
  copyOfferStatus: {
    success: false,
    loading: false,
    error: null,
  },
  confirmBidStatus: {
    success: false,
    loading: false,
    error: null,
  },
  rejectBidStatus: {
    success: false,
    loading: false,
    error: null,
  },
  withdrawnBids: null,
  withdrawnBidsStatus: {
    loading: false,
    error: null,
  },
  award: null,
  originalAward: null,
  linkedAward: null,
  awardsCollection: {
    awards: [],
    total: 0,
  },
  awardsCollectionStatus: {
    loading: false,
    error: null,
  },
  awardsForFilters: [],
  awardsForFiltersStatus: {
    loading: false,
    error: null,
  },
  awardOffer: null,
  awardOfferStatus: {
    loading: false,
    error: null,
    loadingCount: 0,
  },
  releaser: null,
  releaserOptions: [],
  bidEvaluationOffers: [],
  bidEvaluationOffersStatus: {
    loading: false,
    error: null,
  },
  awardStatus: {
    loading: false,
    error: null,
  },
  originalAwardStatus: {
    loading: false,
    error: null,
  },
  linkedAwardStatus: {
    loading: false,
    error: null,
  },
  bidEvaluationBids: {},
  bidEvaluationBidsStatus: {
    loading: false,
    loadingByItem: {},
    error: null,
  },
  bidEvaluationAwards: {},
  bidEvaluationAwardsStatus: {
    loading: false,
    loadingByItem: {},
    error: null,
  },
  matchingBid: null,
  matchingBidStatus: {
    loading: false,
    success: false,
    error: null,
  },
  contractOverview: null,
  contractOverviewStatus: {
    loading: false,
    error: null,
  },
  offerLocationOptions: null,
  offerLocationOptionsStatus: {
    loading: false,
    error: null,
  },
  preApprovedBidders: [],
  preApprovedBiddersStatus: {
    loading: false,
    error: null,
  },
  searchedOffers: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  multipleOfferLocationsOptions: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  parentOffer: null,
};
