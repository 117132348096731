import { createSelector } from '@ngrx/store';
import { IAppState } from 'app/store/app/app.state';
import { IContractState } from 'app/store/contracts/contracts.state';

export const selectContractState = (state: IAppState) => state.contracts;

export const selectContracts = createSelector(
  selectContractState,
  (state: IContractState) => state.contracts
);

export const selectContractsLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.contractsLoading
);

export const selectContractHeader = createSelector(
  selectContractState,
  (state: IContractState) => state.contractHeader
);

export const selectContractHeaders = createSelector(
  selectContractState,
  (state: IContractState) => state.contractHeaders
);

export const selectContractLocationHeaders = createSelector(
  selectContractState,
  (state: IContractState) => state.contractLocationHeadersList
);

export const selectContractLocationHeadersLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractLocationHeaders.loading
);

export const selectContractLocationHeadersError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractLocationHeaders.httpError
);

export const selectSearchedContracts = createSelector(
  selectContractState,
  (state: IContractState) => state.contractsSearch
);

export const selectContract = createSelector(
  selectContractState,
  (state: IContractState) => state.contract
);

export const selectContractLocationIds = createSelector(
  selectContractState,
  (state: IContractState) =>
    state.contract && state.contract.locations
      ? state.contract.locations.map(l => l.locationId)
      : []
);

export const selectLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.loading
);

export const selectSearchLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.contractsSearchLoading
);

export const selectError = createSelector(
  selectContractState,
  (state: IContractState) => state.error
);

export const selectSearchError = createSelector(
  selectContractState,
  (state: IContractState) => state.contractsSearchError
);

export const selectSelectedAmendment = createSelector(
  selectContractState,
  (state: IContractState) => state.selectedAmendment
);

export const selectPagination = createSelector(
  selectContractState,
  (state: IContractState) => state.pagination
);

export const selectSortDescriptors = createSelector(
  selectContractState,
  (state: IContractState) => state.sortDescriptors
);

export const selectAmendments = createSelector(
  selectContractState,
  (state: IContractState) => state.amendments
);

export const selectContractsList = createSelector(
  selectContractState,
  (state: IContractState) => state.contractsList
);

export const selectContractsListLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractsList.loading
);

export const selectContractsListError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractsList.httpError
);

export const selectLookupCollections = createSelector(
  selectContractState,
  (state: IContractState) => state.lookupCollections
);

export const selectLookupCollectionsLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.lookupCollections.loading
);

export const selectLookupCollectionsError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.lookupCollections.httpError
);

export const selectTotalContractCount = createSelector(
  selectContractState,
  (state: IContractState) => state.totalContractCount
);

export const selectContractRateSchedules = createSelector(
  selectContractState,
  (state: IContractState) => state.contractRateSchedules
);

export const selectContractRateSchedulesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractRateSchedules.loading
);

export const selectContractRateSchedulesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractRateSchedules.httpError
);

export const selectContractEntityHistory = createSelector(
  selectContractState,
  (state: IContractState) => state.contract.entityHistory
);

export const selectContractEntityHistoryLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractEntityHistory.loading
);

export const selectContractEntityHistoryError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractEntityHistory.httpError
);
//TODO: fix this

export const selectContractQuantityCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.contractQuantityCollection
);

export const selectContractQuantities = createSelector(
  selectContractState,
  (state: IContractState) =>
    state.contractQuantityCollection ? state.contractQuantityCollection.contractQuantities : null
);

export const selectResetContractQuantities = createSelector(
  selectContractState,
  (state: IContractState) => state.isContractQuantitiesReset
);

export const selectContractQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractQuantities.loading
);

export const selectContractQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractQuantities.httpError
);

export const selectUpdateContractQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractQuantities.loading
);

export const selectUpdateContractQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractQuantities.httpError
);

// Location quantities non-point-pair
export const selectContractLocationQuantityCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityLocationCollection
);

export const selectContractLocationQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.locationQuantities.loading
);

export const selectContractLocationQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.locationQuantities.httpError
);

export const selectContractLocationQuantitiesChangesCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityLocationChangesCollection.data
);

export const selectContractLocationQuantitiesChangesCollectionLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityLocationChangesCollection.requestStatus.loading
);

export const selectContractLocationQuantitiesChangesCollectionError = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityLocationChangesCollection.requestStatus.httpError
);

export const selectUpdateContractLocationQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractLocationQuantities.loading
);

export const selectUpdateContractLocationQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractLocationQuantities.httpError
);

// Location quantities point-pair
export const selectContractLocationPointPairQuantityCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityLocationPointPairCollection
);

export const selectContractLocationPointPairQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.locationPointPairQuantities.loading
);

export const selectContractLocationPointPairQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.locationPointPairQuantities.httpError
);

export const selectContractLocationPointPairQuantitiesChangesCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityLocationChangesPointPairCollection.data
);

export const selectContractLocationPointPairQuantitiesChangesCollectionLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityLocationChangesPointPairCollection.requestStatus.loading
);

export const selectContractLocationPointPairQuantitiesChangesCollectionError = createSelector(
  selectContractState,
  (state: IContractState) =>
    state.quantityLocationChangesPointPairCollection.requestStatus.httpError
);

// Path quantities non-point-pair
export const selectQuantityPathCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityPathCollection
);

export const selectPathQuantities = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityPathCollection.pathQuantities
);

export const selectPathQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.pathQuantities.loading
);

export const selectPathQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.pathQuantities.httpError
);

export const selectContractRateScheduleEntities = createSelector(
  selectContractState,
  (state: IContractState) => state.contractRateScheduleEntities
);

// Contract EDI Transaction Set

export const selectContractEdiTransactionSetCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.contractEdiTransactionSetCollection
);

export const selectContractEdiTransactionSetLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractEdiTransactionSet.loading
);

export const selectContractEdiTransactionSetError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractEdiTransactionSet.httpError
);

export const selectUpdateContractPathQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractPathQuantities.loading
);

export const selectUpdateContractPathQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractPathQuantities.httpError
);
export const selectUpdateContractEdiTransactionSetLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractEdiTransactionSet.loading
);

export const selectUpdateContractEdiTransactionSetError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractEdiTransactionSet.httpError
);

export const selectUpdateContractLocationPointPairQuantitiesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractLocationPointPairQuantities.loading
);

export const selectUpdateContractLocationPointPairQuantitiesError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.updateContractLocationPointPairQuantities.httpError
);

export const selectContractGeneral = createSelector(
  selectContractState,
  (state: IContractState) => state.contractGeneralCollection
);

export const selectContractGeneralLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractGeneral.loading
);

export const selectContractGeneralError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractGeneral.httpError
);

export const selectAddContractOverviewLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.addContractOverview.loading
);

export const selectAddContractOverviewError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.addContractOverview.httpError
);

export const selectContractLockStatusUpdated = createSelector(
  selectContractState,
  (state: IContractState) => state.contractLockStatusUpdated
);

export const selectContractLockLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractLockStatusUpdated.loading
);

export const selectContractLockError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractLockStatusUpdated.httpError
);

export const selectSupplementalRestrictionCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.supplementalRestrictionCollection
);

export const selectSupplementalRestrictionsLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.supplementalRestrictions.loading
);

export const selectSupplementalRestrictionsError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.supplementalRestrictions.httpError
);

export const selectContractsByMergerAssignment = createSelector(
  selectContractState,
  (state: IContractState) => state.contractsByMergerAssignment
);

export const selectContractsByMergerAssignmentLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractsByMergerAssignment.loading
);

export const selectContractsByMergerAssignmentError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.contractsByMergerAssignment.httpError
);

export const selectContractsListExportLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.exportContractsList.loading
);

export const selectContractsListExportError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.exportContractsList.httpError
);

export const selectCreateAmendmentLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.status.amendments.loading
);

export const selectCreateAmendmentError = createSelector(
  selectContractState,
  (state: IContractState) => state.status.amendments.httpError
);

export const selectQuantityChangesCollection = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityChangesCollection
);

export const selectQuantityChanges = createSelector(
  selectContractState,
  (state: IContractState) =>
    state.quantityChangesCollection.data
      ? state.quantityChangesCollection.data.quantityChanges
      : null
);

export const selectQuantityChangesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityChangesCollection.requestStatus.loading
);

export const selectQuantityChangesError = createSelector(
  selectContractState,
  (state: IContractState) => state.quantityChangesCollection.requestStatus.httpError
);

export const selectContractDocuments = createSelector(
  selectContractState,
  (state: IContractState) => state.contractDocuments
);

export const selectContractDocument = createSelector(
  selectContractState,
  (state: IContractState) => state.contractDocument
);

export const selectContractLocationQuantitiesUpdated = createSelector(
  selectContractState,
  (state: IContractState) => state.contractLocationQuantitiesUpdated
);

export const selectContractPathQuantitiesUpdated = createSelector(
  selectContractState,
  (state: IContractState) => state.contractPathQuantitiesUpdated
);

export const selectContractQuantityChangesUpdated = createSelector(
  selectContractState,
  (state: IContractState) => state.contractQuantityChangesUpdated.data
);

export const selectContractQuantityChangesError = createSelector(
  selectContractState,
  (state: IContractState) => state.contractQuantityChangesUpdated.requestStatus.httpError
);

export const selectContractQuantityChangesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.contractQuantityChangesUpdated.requestStatus.loading
);

export const selectContractEdiUpdated = createSelector(
  selectContractState,
  (state: IContractState) => state.contractEdiUpdated.data
);

export const selectContractLocationQuantityChangesUpdated = createSelector(
  selectContractState,
  (state: IContractState) => state.contractLocationQuantityChangesUpdated.data
);

export const selectContractLocationQuantityChangesError = createSelector(
  selectContractState,
  (state: IContractState) => state.contractLocationQuantityChangesUpdated.requestStatus.httpError
);

export const selectContractLocationQuantityChangesLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.contractLocationQuantityChangesUpdated.requestStatus.loading
);

export const selectContractLocationQuantityPointPairChangesUpdated = createSelector(
  selectContractState,
  (state: IContractState) => state.contractLocationQuantityPointPairChangesUpdated.data
);

export const selectContractLocationQuantityPointPairChangesError = createSelector(
  selectContractState,
  (state: IContractState) =>
    state.contractLocationQuantityPointPairChangesUpdated.requestStatus.httpError
);

export const selectContractLocationQuantityPointPairChangesLoading = createSelector(
  selectContractState,
  (state: IContractState) =>
    state.contractLocationQuantityPointPairChangesUpdated.requestStatus.loading
);

export const selectContractGrandfatheredSupplyLateralExported = createSelector(
  selectContractState,
  (state: IContractState) => state.exportGrandfatheredSupplyLateral.data
);

export const selectContractGrandfatheredSupplyLateralExportLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.exportGrandfatheredSupplyLateral.requestStatus.loading
);

export const selectContractGrandfatheredSupplyLateralExportError = createSelector(
  selectContractState,
  (state: IContractState) => state.exportGrandfatheredSupplyLateral.requestStatus.httpError
);

export const selectAmendmentCreatedSuccess = createSelector(
  selectContractState,
  (state: IContractState) => state.amendmentCreated.data
);

export const selectAmendmentCreationLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.amendmentCreated.requestStatus.loading
);

export const selectAmendmentCreationError = createSelector(
  selectContractState,
  (state: IContractState) => state.amendmentCreated.requestStatus.httpError
);

export const selectContractGenerationInitiatedSuccess = createSelector(
  selectContractState,
  (state: IContractState) => state.workflowInitiateKgen.data
);

export const selectWorkflowInitiateKgenLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.workflowInitiateKgen.requestStatus.loading
);

export const selectWorkflowInitiateKgenError = createSelector(
  selectContractState,
  (state: IContractState) => state.workflowInitiateKgen.requestStatus.httpError
);

export const selectContractOverviewUpdatedSuccess = createSelector(
  selectContractState,
  (state: IContractState) => state.contractOverviewUpdated.data
);

export const selectContractOverviewUpdatedLoading = createSelector(
  selectContractState,
  (state: IContractState) => state.contractOverviewUpdated.requestStatus.loading
);

export const selectContractOverviewUpdatedError = createSelector(
  selectContractState,
  (state: IContractState) => state.contractOverviewUpdated.requestStatus.httpError
);

export const selectContractEntities = createSelector(
  selectContractState,
  (state: IContractState) => state.contractEntities
);
