<div [ngClass]="data && data.displayText ? 'flex-display' : '' ">
  <app-popover
    [displayOnHover]="!!data.tooltipIconOptions?.displayOnHover"
    removeShadow="true"
    horizontalAlignment='center'
    *ngIf="!!data.tooltipIconOptions"
  >
    <i
      *ngIf="!!data.tooltipIconOptions?.iconClass && !!data.tooltipIconOptions?.displayIcon"
      [class]="data.tooltipIconOptions?.iconClass + data.tooltipIconOptions?.cssClass || ''"
      [class.icon]="true"
    ></i>

    <popover-content
      class="tooltip"
      *ngIf="!!data.tooltipIconOptions?.displayOnHover && !!data.tooltipIconOptions?.tooltipMessage"
    >
      {{ data.tooltipIconOptions?.tooltipMessage }}
      <div class="arrow-down"></div>
    </popover-content>
  </app-popover>

  <ng-container *ngIf="!!data?.displayText">{{data.displayText}}</ng-container>

  <i
    [class]="(data?.dynamicClass | async) || (data?.iconClass ? data?.iconClass : 'icon-info')"
    [class.icon]="true"
    (click)="onClick($event)"
  ></i>
</div>
