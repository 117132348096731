import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Job_Management_Routes: Routes = [
  { path: '', redirectTo: 'job-configurations', pathMatch: 'full' },
  {
    path: 'job-configurations',
    loadChildren: () =>
      import('./pages/job-configs/job-configs.module').then(m => m.JobConfigsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/job-management/job-configurations',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'job-logs',
    loadChildren: () =>
      import('./pages/job-schedule/job-schedule.module').then(m => m.JobScheduleModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/job-management/job-logs',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];
