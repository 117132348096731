export class RetryableAction {
  isRetry: boolean;
  savedType: string;
  type: string;

  /**
   * This constructor is called in 3 different cases:
   * 1: When creating your initial action.
   *   You don't need to pass in the type, defining it public in your own constructor will be enough.
   *   isRetry needs to be false, so that the retry reducer can save the payload.
   * 2: When requesting a retry action, from the factory retryLast.
   *   The type won't be passed either so that it can keep the definition of the child and be saved in a different variable in retryLast
   * 3: When the retry reducer re-creates the previous action.
   *   We pass in the type because we want this action to be caught by the regular reducers/effects as if it was created by a component.
   */
  constructor(public payload?: any, type?: string) {
    if (type) {
      this.type = type; // Creating the action action that is being retried
      // Note: isRetry needs to be UNDEFINED here, to avoid the retry reducer to store again the payload that it already has
    } else {
      this.isRetry = false; // DEFINING isRetry. False by default, because when this constructor is called from a child, we don't want it to be a retry action
    }
  }

  static retryLast() {
    const retryAction = new this();
    retryAction.isRetry = true;
    retryAction.savedType = retryAction.type; // We save the type so that the retry reducer can build the retried action.
    retryAction.type = `[RETRY] ${retryAction.type}`; // We modify the actual type, so that the regular reducer do NOT catch that action (it's missing the payload at this point)!
    return retryAction;
  }
}
