import { Routes } from '@angular/router';

export const PasswordUpdate_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/password-update/pages/password-update/password-update.module').then(
        m => m.PasswordUpdateModule
      ),
  },
];
