import {
  AccountingAdjustment,
  AccountingAdjustmentCollection,
  AccountingAdjustmentTypeCollection,
  DefaultAccountingPeriod,
  JeAccountingPeriodStatusCollection,
  JournalEntryCollection,
  JournalEntryDetailCollection,
  JournalEntryEbsUploadCollection,
  MissingJournalEntryRuleCollection,
  Project,
  ProjectCollection,
  RevenueRecognitionReviewChargeType,
  StatementOfAccountCollection,
} from '@gms/accounting-api';
import { AccountingEngineRunStatus } from '@gms/accountingengine-api';
import { ContractCollection } from '@gms/contract-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { EJournalEntriesViewBy } from 'app/modules/accounting/pages/journal-entries-list/journal-entries-list.table';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';

export enum EJournalEntryActions {
  GetJournalEntryCollection = '[ACCOUNTING] Fetch Journal Entry Collection',
  GetJournalEntryCollectionSuccess = '[ACCOUNTING] Fetch Journal Entry Collection Success',
  GetJournalEntryCollectionFailure = '[ACCOUNTING] Fetch Journal Entry Collection Failure',
  GetJournalEntryDetailCollection = '[ACCOUNTING] Fetch Journal Entry Detail Collection',
  GetJournalEntryDetailCollectionSuccess = '[ACCOUNTING] Fetch Journal Entry Detail Collection Success',
  GetJournalEntryDetailCollectionFailure = '[ACCOUNTING] Fetch Journal Entry Detail Collection Failure',
  GetJournalEntryEbsUploadCollection = '[ACCOUNTING] Fetch Journal Entry Ebs Upload Collection',
  GetJournalEntryEbsUploadCollectionSuccess = '[ACCOUNTING] Fetch Journal Entry Ebs Upload Collection Success',
  GetJournalEntryEbsUploadCollectionFailure = '[ACCOUNTING] Fetch Journal Entry Ebs Upload Collection Failure',
  GetJournalEntryRevenueRecognitionCollection = '[ACCOUNTING] Fetch Journal Entry Revenue Recognition Collection',
  GetJournalEntryRevenueRecognitionCollectionSuccess = '[ACCOUNTING] Fetch Journal Entry Revenue Recognition Collection Success',
  GetJournalEntryRevenueRecognitionCollectionFailure = '[ACCOUNTING] Fetch Journal Entry Revenue Recognition Collection Failure',
  ExportJournalEntries = '[ACCOUNTING] Export Journal Entries Collection',
  ExportJournalEntriesSuccess = '[ACCOUNTING] Export Journal Entries Collection Success',
  ExportJournalEntriesFailure = '[ACCOUNTING] Export Journal Entries Collection Failure',
  FETCH_RUN_JOURNAL_ENTRY_STATUS = '[ACCOUNTING] Fetch Run Journal Entry Status',
  FETCH_RUN_JOURNAL_ENTRY_STATUS_SUCCESS = '[ACCOUNTING] Fetch Run Journal Entry Status Success',
  FETCH_RUN_JOURNAL_ENTRY_STATUS_FAILURE = '[ACCOUNTING] Fetch Run Journal Entry Status Failure',
  FETCH_RUN_JOURNAL_ENTRY = '[ACCOUNTING] Fetch Run Journal Entry',
  FETCH_RUN_JOURNAL_ENTRY_SUCCESS = '[ACCOUNTING] Fetch Run Journal Entry Success',
  FETCH_RUN_JOURNAL_ENTRY_FAILURE = '[ACCOUNTING] Fetch Run Journal Entry Failure',
  FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION = '[ACCOUNTING] Fetch Missing Journal Entry Rules Collection',
  FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION_SUCCESS = '[ACCOUNTING] Fetch Missing Journal Entry Rules Collection Success',
  FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION_FAILURE = '[ACCOUNTING] Fetch Missing Journal Entry Rules Collection Failure',
  RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS = '[ACCOUNTING] Run Journal Entry in progress',
  RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS_SUCCESS = '[ACCOUNTING] Run Journal Entry Success.',
  RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS_FAILURE = '[ACCOUNTING] Run Journal Entry Failure.',
}

export enum EAdjustmentsActions {
  GET_ADJUSTMENTS_COLLECTION = '[ACCOUNTING] Fetch Adjustments Collection',
  GET_ADJUSTMENTS_COLLECTION_SUCCESS = '[ACCOUNTING] Fetch Adjustments Collection Success',
  GET_ADJUSTMENTS_COLLECTION_FAILURE = '[ACCOUNTING] Fetch Adjustments Collection Failed',
  GET_ADJUSTMENTS_TYPES_COLLECTION = '[ACCOUNTING] Fetch Adjustment Types Collection',
  GET_ADJUSTMENTS_TYPES_COLLECTION_SUCCESS = '[ACCOUNTING] Fetch Adjustment Types Collection Success',
  GET_ADJUSTMENTS_TYPES_COLLECTION_FAILURE = '[ACCOUNTING] Fetch Adjustment Types Collection Failed',
  SAVE_ADJUSTMENT = '[ACCOUNTING] Save Adjustment',
  SAVE_ADJUSTMENT_SUCCESS = '[ACCOUNTING] Save Adjustment Success',
  SAVE_ADJUSTMENT_FAILURE = '[ACCOUNTING] Save Adjustment Failure',
  GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION = '[ACCOUNTING] Fetch Adjustment Period Status Collection',
  GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION_SUCCESS = '[ACCOUNTING] Fetch Adjustment Period Status Collection Success',
  GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION_FAILURE = '[ACCOUNTING] Fetch Adjustment Period Status Collection Failed',
  FETCH_ADJUSTMENT_BY_ID = '[ACCOUNTING] Fetch Adjustment By Id',
  FETCH_ADJUSTMENT_BY_ID_SUCCESS = '[ACCOUNTING] Fetch Adjustment By Id Success',
  FETCH_ADJUSTMENT_BY_ID_FAILURE = '[ACCOUNTING] Fetch Adjustment By Id Failure',
}

export enum EProjectsActions {
  SAVE_PROJECT = '[ACCOUNTING] Create Project',
  SAVE_PROJECT_SUCCESS = '[ACCOUNTING] Create Project Success',
  SAVE_PROJECT_FAILURE = '[ACCOUNTING] Create Project Failure',
  FETCH_PROJECTS_COLLECTION = '[ACCOUNTING] Fetch Projects Collection',
  FETCH_PROJECTS_COLLECTION_SUCCESS = '[ACCOUNTING] Fetch Projects Collection Success',
  FETCH_PROJECTS_COLLECTION_FAILURE = '[ACCOUNTING] Fetch Projects Collection Failure',
  FETCH_UNASSIGNED_CONTRACTS = '[ACCOUNTING] Fetch Unassigned Contracts',
  FETCH_UNASSIGNED_CONTRACTS_SUCCESS = '[ACCOUNTING] Fetch Unassigned Contracts Success',
  FETCH_UNASSIGNED_CONTRACTS_FAILURE = '[ACCOUNTING] Fetch Unassigned Contracts Failure',
  ASSIGN_CONTRACT_TO_PROJECT = '[ACCOUNTING] Assign Contract To Project',
  ASSIGN_CONTRACT_TO_PROJECT_SUCCESS = '[ACCOUNTING] Assign Contract To Project Success',
  ASSIGN_CONTRACT_TO_PROJECT_FAILURE = '[ACCOUNTING] Assign Contract To Project Failure',
  UPDATE_PROJECT = '[ACCOUNTING] Update Project',
  UPDATE_PROJECT_SUCCESS = '[ACCOUNTING] Update Project Success',
  UPDATE_PROJECT_FAILURE = '[ACCOUNTING] Update Project Failure',
  FETCH_PROJECT_BY_ID = '[ACCOUNTING] Fetch Project By Id',
  FETCH_PROJECT_BY_ID_SUCCESS = '[ACCOUNTING] Fetch Project By Id Success',
  FETCH_PROJECT_BY_ID_FAILURE = '[ACCOUNTING] Fetch Project By Id Failure',
  CLEAR_PROJECT = '[ACCOUNTING] clear current Project',
}

export enum EInventoryActions {
  FETCH_INVENTORY = '[ACCOUNTING] Fetch Inventory',
  FETCH_INVENTORY_SUCCESS = '[ACCOUNTING] Fetch Inventory Success',
  FETCH_INVENTORY_FAILURE = '[ACCOUNTING] Fetch Inventory Failure',
  RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET = '[ACCOUNTING] Run Inventoty Accumulations in progress',
  RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET_SUCCESS = '[ACCOUNTING] Run Inventory Accumulations Success.',
  RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET_FAILURE = '[ACCOUNTING] Run Inventory Accumulations Failure.',
  FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD = '[ACCOUNTING] Fetch Inventory Default Account Period',
  FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD_SUCCESS = '[ACCOUNTING] Fetch Inventory Default Account Period Success',
  FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD_FAILURE = '[ACCOUNTING] Fetch Inventory Default Account Period Failure',
  RESET_INVENTORY_DEFAULT_ACCOUNT_PERIOD = '[ACCOUNTING] Reset Inventory Default Account Period',
  FETCH_FACILITY_STORAGE = '[ACCOUNTING] Fetch Facility Storage',
  FETCH_FACILITY_STORAGE_SUCCESS = '[ACCOUNTING] Fetch Facility Storage Success',
  FETCH_FACILITY_STORAGE_FAILURE = '[ACCOUNTING] Fetch Facility Storage Failure',
  EXPORT_INVENTORY = '[ACCOUNTING] Export Inventory',
  EXPORT_INVENTORY_SUCCESS = '[ACCOUNTING] Export Inventory Success',
  EXPORT_INVENTORY_FAILURE = '[ACCOUNTING] Export Inventory Failure',
}

export enum ERevenueRecognitionActions {
  FETCH_REVENUE_RECOGNITION = '[ACCOUNTING] Fetch Revenue Recognition Collection',
  FETCH_REVENUE_RECOGNITION_SUCCESS = '[ACCOUNTING] Fetch Revenue Recognition Collection Success',
  FETCH_REVENUE_RECOGNITION_FAILURE = '[ACCOUNTING] Fetch Revenue Recognition Collection Failure',
  UPDATE_REVENUE_RECOGNITION = '[ACCOUNTING] Update Revenue Recognition',
  UPDATE_REVENUE_RECOGNITION_SUCCESS = '[ACCOUNTING] Update Revenue Recognition Success',
  UPDATE_REVENUE_RECOGNITION_FAILURE = '[ACCOUNTING] Update Revenue Recognition Failure',
}

export enum EStatementOfAccountActions {
  FETCH_STATEMENT_OF_ACCOUNT_COLLECTION = '[ACCOUNTING] Fetch Statement Of Account Collection',
  FETCH_STATEMENT_OF_ACCOUNT_COLLECTION_SUCCESS = '[ACCOUNTING] Fetch Statement Of Account Collection Success',
  FETCH_STATEMENT_OF_ACCOUNT_COLLECTION_FAILURE = '[ACCOUNTING] Fetch Statement Of Account Collection Failure',
  EXPORT_STATEMENT_OF_ACCOUNT = '[ACCOUNTING] Export Statement Of Accounts',
  EXPORT_STATEMENT_OF_ACCOUNT_SUCCESS = '[ACCOUNTING] Export Statement Of Accounts Success',
  EXPORT_STATEMENT_OF_ACCOUNT_FAILURE = '[ACCOUNTING] Export Statement Of Accounts Failure',
}

// JOURNAL ENTRIES

export interface IJournalEntryPayload {
  tspIds?: Number[];
  accountPeriod?: IAccountPeriod;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
}

export class GetJournalEntryCollection implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryCollection;

  constructor(public payload: IJournalEntryPayload) {}
}

export class GetJournalEntryCollectionSuccess implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryCollectionSuccess;

  constructor(public payload: JournalEntryCollection) {}
}

export class GetJournalEntryCollectionFailure implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryCollectionFailure;

  constructor(public payload: Error) {}
}

export class GetJournalEntryDetailCollection implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryDetailCollection;

  constructor(public payload: IJournalEntryPayload) {}
}

export class GetJournalEntryDetailCollectionSuccess implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryDetailCollectionSuccess;

  constructor(public payload: JournalEntryDetailCollection) {}
}

export class GetJournalEntryDetailCollectionFailure implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryDetailCollectionFailure;

  constructor(public payload: Error) {}
}

export class GetJournalEntryEbsUploadCollection implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryEbsUploadCollection;

  constructor(public payload: IJournalEntryPayload) {}
}

export class GetJournalEntryEbsUploadCollectionSuccess implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryEbsUploadCollectionSuccess;

  constructor(public payload: JournalEntryEbsUploadCollection) {}
}

export class GetJournalEntryEbsUploadCollectionFailure implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryEbsUploadCollectionFailure;

  constructor(public payload: Error) {}
}

export class GetJournalEntryRevenueRecognitionCollection implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryRevenueRecognitionCollection;

  constructor(public payload: IJournalEntryPayload) {}
}

export class GetJournalEntryRevenueRecognitionCollectionSuccess implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryRevenueRecognitionCollectionSuccess;

  constructor(public payload: JournalEntryEbsUploadCollection) {}
}

export class GetJournalEntryRevenueRecognitionCollectionFailure implements Action {
  public readonly type = EJournalEntryActions.GetJournalEntryRevenueRecognitionCollectionFailure;

  constructor(public payload: Error) {}
}

export class FetchRunJournalEntryStatus implements Action {
  public readonly type = EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_STATUS;

  constructor(
    public payload: {
      tspId?: number;
      accountingPeriod?: IAccountPeriod;
      jobTypeIds?: string;
    }
  ) {}
}

export class FetchRunJournalEntryStatusSuccess implements Action {
  public readonly type = EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_STATUS_SUCCESS;

  constructor(public payload: AccountingEngineRunStatus) {}
}

export class FetchRunJournalEntryStatusFailure implements Action {
  public readonly type = EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_STATUS_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchRunJournalEntryWebsocketStatus implements Action {
  public readonly type = EJournalEntryActions.RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS;

  constructor() {}
}

export class FetchRunJournalEntryWebsocketStatusSuccess implements Action {
  public readonly type = EJournalEntryActions.RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS_SUCCESS;

  constructor() {}
}

export class FetchRunJournalEntryWebsocketStatusFailure implements Action {
  public readonly type = EJournalEntryActions.RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS_FAILURE;

  constructor() {}
}

export class FetchRunJournalEntrySuccess implements Action {
  public readonly type = EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_SUCCESS;

  constructor(public payload: any) {}
}

export class FetchRunJournalEntryFailure implements Action {
  public readonly type = EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchRunJournalEntry implements Action {
  public readonly type = EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY;

  constructor(
    public payload: {
      tspId?: number;
      accountingPeriod?: IAccountPeriod;
    }
  ) {}
}

export class ExportJournalEntries implements Action {
  public readonly type = EJournalEntryActions.ExportJournalEntries;

  constructor(
    public payload: {
      tspIds?: Number[];
      tspName?: string;
      accountPeriod?: IAccountPeriod;
      sortDescriptors?: SortDescriptor[];
      exportView?: EJournalEntriesViewBy;
    }
  ) {}
}

export class ExportJournalEntriesSuccess implements Action {
  public readonly type = EJournalEntryActions.ExportJournalEntriesSuccess;
}

export class ExportJournalEntriesFailure implements Action {
  public readonly type = EJournalEntryActions.ExportJournalEntriesFailure;

  constructor(public payload: Error) {}
}

export class FetchMissingJournalEntryRulesCollection implements Action {
  public readonly type = EJournalEntryActions.FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION;

  constructor(
    public payload: {
      tspIds?: Number[];
      accountPeriod?: IAccountPeriod;
    }
  ) {}
}

export class FetchMissingJournalEntryRulesCollectionSuccess implements Action {
  public readonly type = EJournalEntryActions.FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION_SUCCESS;

  constructor(public payload: MissingJournalEntryRuleCollection) {}
}

export class FetchMissingJournalEntryRulesCollectionFailure implements Action {
  public readonly type = EJournalEntryActions.FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

// ADJUSTMENTS

export class GetAdjustmentsCollection implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENTS_COLLECTION;

  constructor(
    public payload: {
      tspId?: number;
      accountPeriod?: IAccountPeriod;
      pageSize?: number;
      pageNumber?: number;
      sortDescriptors?: SortDescriptor[];
    }
  ) {}
}

export class GetAdjustmentsCollectionSuccess implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENTS_COLLECTION_SUCCESS;

  constructor(public payload: AccountingAdjustmentCollection) {}
}

export class GetAdjustmentsCollectionFailure implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENTS_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAdjustmentTypesCollection implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENTS_TYPES_COLLECTION;
}

export class GetAdjustmentTypesCollectionSuccess implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENTS_TYPES_COLLECTION_SUCCESS;

  constructor(public payload: AccountingAdjustmentTypeCollection) {}
}

export class GetAdjustmentTypesCollectionFailure implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENTS_TYPES_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAdjustmentPeriodStatusCollection implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION;

  constructor(public tspId: number) {}
}

export class GetAdjustmentPeriodStatusCollectionSuccess implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION_SUCCESS;

  constructor(public payload: JeAccountingPeriodStatusCollection) {}
}

export class GetAdjustmentPeriodStatusCollectionFailure implements Action {
  public readonly type = EAdjustmentsActions.GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class SaveAdjustment implements Action {
  public readonly type = EAdjustmentsActions.SAVE_ADJUSTMENT;

  constructor(public payload: AccountingAdjustment) {}
}

export class SaveAdjustmentSuccess implements Action {
  public readonly type = EAdjustmentsActions.SAVE_ADJUSTMENT_SUCCESS;

  constructor(public payload: AccountingAdjustment) {}
}

export class SaveAdjustmentFailure implements Action {
  public readonly type = EAdjustmentsActions.SAVE_ADJUSTMENT_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAdjustmentById implements Action {
  public readonly type = EAdjustmentsActions.FETCH_ADJUSTMENT_BY_ID;

  constructor(public payload: number) {}
}

export class GetAdjustmentByIdSuccess implements Action {
  public readonly type = EAdjustmentsActions.FETCH_ADJUSTMENT_BY_ID_SUCCESS;

  constructor(public payload: AccountingAdjustment) {}
}

export class GetAdjustmentByIdFailure implements Action {
  public readonly type = EAdjustmentsActions.FETCH_ADJUSTMENT_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

// PROJECTS
export class SaveProject implements Action {
  public readonly type = EProjectsActions.SAVE_PROJECT;

  constructor(public payload: Project) {}
}

export class SaveProjectSuccess implements Action {
  public readonly type = EProjectsActions.SAVE_PROJECT_SUCCESS;

  constructor(public payload: Project) {}
}

export class SaveProjectFailure implements Action {
  public readonly type = EProjectsActions.SAVE_PROJECT_FAILURE;

  constructor(public payload: Error) {}
}

export class GetProjectsCollection implements Action {
  public readonly type = EProjectsActions.FETCH_PROJECTS_COLLECTION;

  constructor(
    public payload: {
      tspIds?: Number[];
      viewById?: number;
    }
  ) {}
}

export class GetProjectsCollectionSuccess implements Action {
  public readonly type = EProjectsActions.FETCH_PROJECTS_COLLECTION_SUCCESS;

  constructor(public payload: ProjectCollection) {}
}

export class GetProjectsCollectionFailure implements Action {
  public readonly type = EProjectsActions.FETCH_PROJECTS_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateProject implements Action {
  public readonly type = EProjectsActions.UPDATE_PROJECT;

  constructor(public payload: Project, public projectId: number) {}
}

export class UpdateProjectSuccess implements Action {
  public readonly type = EProjectsActions.UPDATE_PROJECT_SUCCESS;

  constructor(public payload: Project) {}
}

export class UpdateProjectFailure implements Action {
  public readonly type = EProjectsActions.UPDATE_PROJECT_FAILURE;

  constructor(public payload: Error) {}
}

export class GetProjectById implements Action {
  public readonly type = EProjectsActions.FETCH_PROJECT_BY_ID;

  constructor(public payload: number) {}
}

export class GetProjectByIdSuccess implements Action {
  public readonly type = EProjectsActions.FETCH_PROJECT_BY_ID_SUCCESS;

  constructor(public payload: Project) {}
}

export class GetProjectByIdFailure implements Action {
  public readonly type = EProjectsActions.FETCH_PROJECT_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearProject implements Action {
  public readonly type = EProjectsActions.CLEAR_PROJECT;
}

export class GetUnassignedContracts implements Action {
  public readonly type = EProjectsActions.FETCH_UNASSIGNED_CONTRACTS;

  constructor(
    public payload: {
      tspId?: number;
    }
  ) {}
}

export class GetUnassignedContractsSuccess implements Action {
  public readonly type = EProjectsActions.FETCH_UNASSIGNED_CONTRACTS_SUCCESS;

  constructor(public payload: ContractCollection) {}
}

export class GetUnassignedContractsFailure implements Action {
  public readonly type = EProjectsActions.FETCH_UNASSIGNED_CONTRACTS_FAILURE;

  constructor(public payload: Error) {}
}

export class AssignContractToProject implements Action {
  public readonly type = EProjectsActions.ASSIGN_CONTRACT_TO_PROJECT;

  constructor(
    public payload: {
      contractId: number;
      projectId: number;
      viewById: number;
      tspId?: number;
    }
  ) {}
}

export class AssignContractToProjectSuccess implements Action {
  public readonly type = EProjectsActions.ASSIGN_CONTRACT_TO_PROJECT_SUCCESS;
}

export class AssignContractToProjectFailure implements Action {
  public readonly type = EProjectsActions.ASSIGN_CONTRACT_TO_PROJECT_FAILURE;

  constructor(public payload: Error) {}
}

// INVENTORY
export class GetInventory implements Action {
  public readonly type = EInventoryActions.FETCH_INVENTORY;

  constructor(
    public payload: {
      tspId: number;
      viewBy?: string;
      accountPeriod?: IAccountPeriod;
    }
  ) {}
}

export class GetInventorySuccess implements Action {
  public readonly type = EInventoryActions.FETCH_INVENTORY_SUCCESS;

  constructor(public payload: any) {}
}

export class GetInventoryFailure implements Action {
  public readonly type = EInventoryActions.FETCH_INVENTORY_FAILURE;

  constructor(public payload: Error) {}
}

export class GetInventoryDefaultAccountPeriod implements Action {
  public readonly type = EInventoryActions.FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD;

  constructor(
    public payload: {
      tspId: number;
    }
  ) {}
}

export class GetInventoryDefaultAccountPeriodSuccess implements Action {
  public readonly type = EInventoryActions.FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD_SUCCESS;

  constructor(public payload: DefaultAccountingPeriod) {}
}

export class GetInventoryDefaultAccountPeriodFailure implements Action {
  public readonly type = EInventoryActions.FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD_FAILURE;

  constructor(public payload: Error) {}
}

export class ResetInventoryDefaultAccountPeriod implements Action {
  public readonly type = EInventoryActions.RESET_INVENTORY_DEFAULT_ACCOUNT_PERIOD;
}

export class RunInventoryAccumulationsWebsocket implements Action {
  public readonly type = EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET;

  constructor(
    public payload: {
      tspId?: number;
      accountingPeriod?: IAccountPeriod;
    }
  ) {}
}

export class RunInventoryAccumulationsWebsocketSuccess implements Action {
  public readonly type = EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET_SUCCESS;
}

export class RunInventoryAccumulationsWebsocketFailed implements Action {
  public readonly type = EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET_SUCCESS;
}

export class RunInventoryAccumulationsWebsocketConnectionFailure implements Action {
  public readonly type = EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET_FAILURE;

  constructor(public payload: Error | string) {}
}

export class EmptyAction implements Action {
  public readonly type = 'EMPTY';
}

export class GetFacilityStorage implements Action {
  public readonly type = EInventoryActions.FETCH_FACILITY_STORAGE;

  constructor(
    public payload: {
      tspId: number;
      accountPeriod?: IAccountPeriod;
    }
  ) {}
}

export class GetFacilityStorageSuccess implements Action {
  public readonly type = EInventoryActions.FETCH_FACILITY_STORAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetFacilityStorageFailure implements Action {
  public readonly type = EInventoryActions.FETCH_FACILITY_STORAGE_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportInventory implements Action {
  public readonly type = EInventoryActions.EXPORT_INVENTORY;

  constructor(
    public payload: {
      tspId?: number;
      tspName?: string;
      accountPeriod?: IAccountPeriod;
      exportView?: string;
    }
  ) {}
}

export class ExportInventorySuccess implements Action {
  public readonly type = EInventoryActions.EXPORT_INVENTORY_SUCCESS;
}

export class ExportInventoryFailure implements Action {
  public readonly type = EInventoryActions.EXPORT_INVENTORY_FAILURE;

  constructor(public payload: Error) {}
}

// REVENUE RECOGNITION
export class FetchRevenueRecognitionCollection implements Action {
  public readonly type = ERevenueRecognitionActions.FETCH_REVENUE_RECOGNITION;

  constructor(
    public payload: {
      tspId?: number;
      pageSize?: number;
      pageNumber?: number;
    }
  ) {}
}

export class FetchRevenueRecognitionCollectionSuccess implements Action {
  public readonly type = ERevenueRecognitionActions.FETCH_REVENUE_RECOGNITION_SUCCESS;

  constructor(public payload: any) {} //TO DO: Type
}

export class FetchRevenueRecognitionCollectionFailure implements Action {
  public readonly type = ERevenueRecognitionActions.FETCH_REVENUE_RECOGNITION_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateRevenueRecognition implements Action {
  public readonly type = ERevenueRecognitionActions.UPDATE_REVENUE_RECOGNITION;

  constructor(
    public payload: {
      revRec: RevenueRecognitionReviewChargeType;
    }
  ) {}
}

export class UpdateRevenueRecognitionSuccess implements Action {
  public readonly type = ERevenueRecognitionActions.UPDATE_REVENUE_RECOGNITION_SUCCESS;
}

export class UpdateRevenueRecognitionFailure implements Action {
  public readonly type = ERevenueRecognitionActions.UPDATE_REVENUE_RECOGNITION_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchStatementOfAccountCollection implements Action {
  public readonly type = EStatementOfAccountActions.FETCH_STATEMENT_OF_ACCOUNT_COLLECTION;

  constructor(
    public payload: {
      tspId?: number;
      accountingPeriod?: IAccountPeriod;
      svcReqNameId?: number;
      agentNameId?: number;
    }
  ) {}
}

export class FetchStatementOfAccountCollectionSuccess implements Action {
  public readonly type = EStatementOfAccountActions.FETCH_STATEMENT_OF_ACCOUNT_COLLECTION_SUCCESS;

  constructor(public payload: StatementOfAccountCollection) {}
}

export class FetchStatementOfAccountCollectionFailure implements Action {
  public readonly type = EStatementOfAccountActions.FETCH_STATEMENT_OF_ACCOUNT_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportStatementOfAccount implements Action {
  public readonly type = EStatementOfAccountActions.EXPORT_STATEMENT_OF_ACCOUNT;

  constructor(
    public payload: {
      tspId?: number;
      tspName?: string;
      accountingPeriod?: IAccountPeriod;
      svcReqNameId?: number;
    }
  ) {}
}

export class ExportStatementOfAccountSuccess implements Action {
  public readonly type = EStatementOfAccountActions.EXPORT_STATEMENT_OF_ACCOUNT_SUCCESS;
}

export class ExportStatementOfAccountFailure implements Action {
  public readonly type = EStatementOfAccountActions.EXPORT_STATEMENT_OF_ACCOUNT_FAILURE;

  constructor(public payload: Error) {}
}

export type JournalEntryActions =
  | GetJournalEntryCollection
  | GetJournalEntryCollectionSuccess
  | GetJournalEntryCollectionFailure
  | GetJournalEntryDetailCollection
  | GetJournalEntryDetailCollectionSuccess
  | GetJournalEntryDetailCollectionFailure
  | GetJournalEntryEbsUploadCollection
  | GetJournalEntryEbsUploadCollectionSuccess
  | GetJournalEntryEbsUploadCollectionFailure
  | GetJournalEntryRevenueRecognitionCollection
  | GetJournalEntryRevenueRecognitionCollectionSuccess
  | GetJournalEntryRevenueRecognitionCollectionFailure
  | ExportJournalEntries
  | ExportJournalEntriesSuccess
  | ExportJournalEntriesFailure
  | FetchRunJournalEntryStatus
  | FetchRunJournalEntryStatusSuccess
  | FetchRunJournalEntryStatusFailure
  | FetchRunJournalEntry
  | FetchRunJournalEntrySuccess
  | FetchRunJournalEntryFailure
  | FetchRunJournalEntryWebsocketStatus
  | FetchRunJournalEntryWebsocketStatusFailure
  | FetchRunJournalEntryWebsocketStatusSuccess
  | FetchMissingJournalEntryRulesCollection
  | FetchMissingJournalEntryRulesCollectionFailure
  | FetchMissingJournalEntryRulesCollectionSuccess;

export type AdjusmentsActions =
  | GetAdjustmentsCollection
  | GetAdjustmentsCollectionSuccess
  | GetAdjustmentsCollectionFailure
  | GetAdjustmentTypesCollection
  | GetAdjustmentTypesCollectionSuccess
  | GetAdjustmentTypesCollectionFailure
  | GetAdjustmentPeriodStatusCollection
  | GetAdjustmentPeriodStatusCollectionSuccess
  | GetAdjustmentPeriodStatusCollectionFailure
  | SaveAdjustment
  | SaveAdjustmentSuccess
  | SaveAdjustmentFailure
  | GetAdjustmentById
  | GetAdjustmentByIdSuccess
  | GetAdjustmentByIdFailure;

export type ProjectsActions =
  | GetProjectsCollection
  | GetProjectsCollectionSuccess
  | GetProjectsCollectionFailure
  | SaveProject
  | SaveProjectSuccess
  | SaveProjectFailure
  | UpdateProject
  | UpdateProjectSuccess
  | UpdateProjectFailure
  | GetProjectById
  | GetProjectByIdSuccess
  | GetProjectByIdFailure
  | ClearProject
  | GetUnassignedContracts
  | GetUnassignedContractsSuccess
  | GetUnassignedContractsFailure
  | AssignContractToProject
  | AssignContractToProjectSuccess
  | AssignContractToProjectFailure;

export type InventoryActions =
  | GetInventory
  | GetInventorySuccess
  | GetInventoryFailure
  | GetInventoryDefaultAccountPeriod
  | GetInventoryDefaultAccountPeriodSuccess
  | GetInventoryDefaultAccountPeriodFailure
  | ResetInventoryDefaultAccountPeriod
  | RunInventoryAccumulationsWebsocket
  | RunInventoryAccumulationsWebsocketSuccess
  | RunInventoryAccumulationsWebsocketFailed
  | RunInventoryAccumulationsWebsocketConnectionFailure
  | EmptyAction
  | GetFacilityStorage
  | GetFacilityStorageSuccess
  | GetFacilityStorageFailure
  | ExportInventory
  | ExportInventorySuccess
  | ExportInventoryFailure;

export type RevenueRecognitionActions =
  | FetchRevenueRecognitionCollection
  | FetchRevenueRecognitionCollectionSuccess
  | FetchRevenueRecognitionCollectionFailure
  | UpdateRevenueRecognition
  | UpdateRevenueRecognitionSuccess
  | UpdateRevenueRecognitionFailure;

export type StatementOfAccountActions =
  | FetchStatementOfAccountCollection
  | FetchStatementOfAccountCollectionSuccess
  | FetchStatementOfAccountCollectionFailure
  | ExportStatementOfAccount
  | ExportStatementOfAccountSuccess
  | ExportStatementOfAccountFailure;
