import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { IAgencyState } from './agency.state';

export const selectAgencyState = (state: IAppState) => state.agencyState;

export const selectAgency = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.agency
);

export const selectAgencyUpdateLoading = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.agencyUpdateLoading
);

export const selectAgencyUpdateError = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.agencyUpdateError
);

export const selectSearchedAgencies = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.searchedAgencies
);

export const selectSearchAgenciesLoading = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.searchAgenciesLoading
);

export const selectSearchAgenciesError = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.searchAgenciesError
);

export const selectAgencyFetchLoading = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.agencyFetchLoading
);

export const selectAgencyFetchError = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.agencyFetchError
);

export const selectAgencyRolesState = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.agencyRoles
);

export const selectAgencyRoles = createSelector(
  selectAgencyRolesState,
  dataState => (dataState.data ? dataState.data : [])
);

export const selectAgencyRolesLoading = createSelector(
  selectAgencyRolesState,
  dataState => dataState.requestStatus.loading
);

export const selectAgencyRolesError = createSelector(
  selectAgencyRolesState,
  dataState => dataState.requestStatus.httpError
);

export const selectAgencyHeaders = createSelector(
  selectAgencyState,
  (state: IAgencyState) => state.agencyHeaders
);
