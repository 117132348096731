import { PagerSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

export const PAGE_OFFSET = 1;

export interface IPagination {
  pageSize: PaginationPageSize | number;
  pageNumber: number;
}

export interface IPaginationPageChange extends IPagination {
  sortDescriptors: SortDescriptor[];
}

export interface IPageChangeEvent {
  skip: number;
  take: number;
}

/**
 * Pagination size options for every table (number of rows to show).
 */
export enum PaginationPageSize {
  SMALL = 5,
  MEDIUM = 15,
  LARGE = 25,
  XLARGE = 50,
  XXXLarge = 1000,
  ZERO = 0,
}

/**
 * Array of page size options. This is passed to the GMS table to configure the page size dropdown.
 * @type {PaginationPageSize[]}
 */
export const DEFAULT_PAGINATION_PAGE_SIZES = [
  PaginationPageSize.SMALL,
  PaginationPageSize.MEDIUM,
  PaginationPageSize.LARGE,
  PaginationPageSize.XLARGE,
];

/**
 * Default page size for tables.
 * @type {PaginationPageSize}
 */
export const DEFAULT_PAGINATION_PAGE_SIZE: PaginationPageSize = PaginationPageSize.XLARGE;

/**
 * Default number of numbered page buttons to show.
 * @type {number}
 */
export const DEFAULT_PAGINATION_BUTTON_COUNT = 5;

/**
 * Returns a GMS Table pagination configuration object.
 *
 * @param {number} pageNumber The initial page number. Default: 1
 * @param {PaginationPageSize} pageSize The initial page size: Default: XLARGE
 * @returns {IPagination}
 */
export function getPagination(
  pageNumber: number = 1,
  pageSize: PaginationPageSize = DEFAULT_PAGINATION_PAGE_SIZE
): IPagination {
  return { pageSize, pageNumber } as IPagination;
}

/**
 *
 *
 * @param {number} buttonCount The number of numbered page buttons. Default: DEFAULT_PAGINATION_BUTTON_COUNT
 * @param {boolean | PaginationPageSize[]} pageSizes The page sizes that will be available for this table. Default: DEFAULT_PAGINATION_PAGE_SIZES
 * @param {boolean} previousNext If true, show the previous/next buttons. Default: true
 * @returns {PagerSettings}
 */
export function getPageable(
  buttonCount: number = DEFAULT_PAGINATION_BUTTON_COUNT,
  pageSizes: boolean | PaginationPageSize[] = DEFAULT_PAGINATION_PAGE_SIZES,
  previousNext: boolean = true
): PagerSettings {
  return {
    buttonCount,
    info: false,
    type: 'numeric',
    pageSizes,
    previousNext,
  };
}
