<app-button
  [id]="data?.id"
  [type]="data.buttonType ? data.buttonType : 'primary'"
  [size]="data.buttonSize ? data.buttonSize : 'regular'"
  [square]="data.square ? data.square : false"
  [disabled]="data.disabled | async"
  (click)="onClick()"
>
  <i *ngIf="data.icon" [class]="data.icon"></i>{{ data.displayText }}
</app-button>
