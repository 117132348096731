import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import { EMergerAssignmentActions, MergerAssignmentActions } from './merger-assignment.actions';
import {
  IMergerAssignmentState,
  initialMergerAssignmentCollection,
  initialMergerAssignmentInput,
  initialMergerAssignmentState,
} from './merger-assignment.state';

export function mergerAssignmentReducers(
  state = initialMergerAssignmentState,
  action: MergerAssignmentActions
): IMergerAssignmentState {
  switch (action.type) {
    case EMergerAssignmentActions.SEARCH_MERGER_ASSIGNMENTS:
      return {
        ...state,
        searchedMergerAssignments: initialMergerAssignmentCollection,
        status: {
          ...state.status,
          searchedMergerAssignments: { loading: true, httpError: null },
        },
      };
    case EMergerAssignmentActions.SEARCH_MERGER_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        searchedMergerAssignments: action.payload,
        status: {
          ...state.status,
          searchedMergerAssignments: { loading: false, httpError: null },
        },
      };
    case EMergerAssignmentActions.SEARCH_MERGER_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        searchedMergerAssignments: initialMergerAssignmentCollection,
        status: {
          ...state.status,
          searchedMergerAssignments: { loading: false, httpError: action.payload },
        },
      };
    case EMergerAssignmentActions.FETCH_MERGER_ASSIGNMENT_BY_ID:
      return {
        ...state,
        status: {
          ...state.status,
          mergerAssignment: { loading: true, httpError: null },
        },
      };
    case EMergerAssignmentActions.FETCH_MERGER_ASSIGNMENT_BY_ID_SUCCESS:
      return {
        ...state,
        mergerAssignment: action.payload,
        status: {
          ...state.status,
          mergerAssignment: { loading: false, httpError: null },
        },
      };
    case EMergerAssignmentActions.FETCH_MERGER_ASSIGNMENT_BY_ID_FAILURE:
      return {
        ...state,
        mergerAssignment: null,
        status: {
          ...state.status,
          mergerAssignment: { loading: false, httpError: action.payload },
        },
      };
    case EMergerAssignmentActions.ADD_MERGER_ASSIGNMENT:
      return {
        ...state,
        mergerAssignmentInput: createLoadingState(state.mergerAssignmentInput),
        mergerAssignmentCreated: false,
      };
    case EMergerAssignmentActions.ADD_MERGER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        mergerAssignmentInput: createSuccessState(action.payload),
        mergerAssignmentCreated: true,
      };
    case EMergerAssignmentActions.ADD_MERGER_ASSIGNMENT_FAILURE:
      return {
        ...state,
        mergerAssignmentInput: createErrorState(action.payload),
        mergerAssignmentCreated: false,
      };
    case EMergerAssignmentActions.RESET_MERGER_ASSIGNMENT:
      return {
        ...state,
        mergerAssignment: null,
        mergerAssignmentCreated: false,
        mergerAssignmentInput: initialMergerAssignmentInput,
      };
    default:
      return state;
  }
}
