<ng-template #template>
  <div
    class="background-shadow"
    *ngIf="!removeShadow"
    [ngStyle]="shadowStyles"
  ></div>
  <div
    (click)="hide()"
    [class.useArrow]="useArrow && !isFlipped"
    [class.isFlipped]="useArrow && isFlipped"
    [class.leftArrow]="useArrow && leftArrow"
    [class.rightArrow]="useArrow && rightArrow">
    <ng-content
      select="popover-content">
    </ng-content>
  </div>
</ng-template>
<div
  #anchor
  [ngClass]="overrideFitContent? 'override-fit-content' : 'popover-anchor'"
  [class.table-overflow]="tableOverflow"
  (click)="toggle()"
  [attachOutsideOnClick]="true"
  [clickOutsideEnabled] ="!!popupRef"
  (clickOutside)="hide()"
  (mouseenter)="mouseHovering(true)"
  (mouseleave)="mouseHovering(false)"
  (focusin)="hasFocus(true)"
  (focusout)="hasFocus(false)"
>
  <ng-content></ng-content>
</div>
<!--NOTE: popover-content need to be wrapped in a "div" if there is mouse interaction with the content -->
