import { MfaChallengeInfo, User } from '@gms/user-api';
import { createInitialDataState, IDataState } from 'app/store/app/app.models';
import { AuthResources } from './model/models';

export interface RouteRoleConfigs {
  [route: string]: string[];
}

export interface MfaInfo {
  mfaChallengeInfo: MfaChallengeInfo;
  userId: string;
}

export interface IAuthState {
  accessToken: string;
  isADFSLogin: boolean;
  roles: string[];
  cookiesAccepted: boolean;
  loginLoading: boolean;
  error: Error;
  isAuthenticated: boolean;
  logoutTime: number;
  refreshToken: string;
  saveUserId: boolean;
  ttl: number;
  user?: IDataState<User>;
  userId: string;
  warningLogoutTime: number;
  updatePasswordLoading: boolean;
  updatePasswordError: Error;
  routeRoleConfigs: RouteRoleConfigs;
  routeRoleConfigsLoading: boolean;
  routeRoleConfigsError: Error;
  invalidSsoUserId: boolean;
  authResources: AuthResources;
  isInternal: boolean;
  mfa: {
    data: MfaInfo;
    requestStatus: {
      loading: boolean;
      httpError: Error;
    };
  };
  websocketTicket: IDataState<string>;
}

export const initialAuthState: IAuthState = {
  accessToken: null,
  isADFSLogin: false,
  roles: [],
  cookiesAccepted: false,
  loginLoading: false,
  error: null,
  isAuthenticated: null,
  logoutTime: null,
  refreshToken: null,
  saveUserId: false,
  ttl: 1800000,
  user: createInitialDataState(),
  userId: null,
  warningLogoutTime: null,
  updatePasswordLoading: false,
  updatePasswordError: null,
  routeRoleConfigs: null,
  routeRoleConfigsLoading: false,
  routeRoleConfigsError: null,
  invalidSsoUserId: false,
  authResources: {},
  isInternal: null,
  mfa: {
    data: null,
    requestStatus: {
      httpError: null,
      loading: false,
    },
  },
  websocketTicket: createInitialDataState(),
};
