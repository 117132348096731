import { Injectable } from '@angular/core';
import { WorkflowService, WorkflowTask } from '@gms/workflow-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

import {
  EWorkflowTaskAction,
  GetTaskByDomainId,
  GetTaskByDomainIdError,
  GetTaskByDomainIdSuccess,
  UpdateTaskActionByDomainId,
  UpdateTaskActionByDomainIdError,
  UpdateTaskActionByDomainIdSuccess,
} from './workflow-task-action.actions';

@Injectable({
  providedIn: 'root',
})
export class WorkflowTaskActionEffect {
  constructor(private actions$: Actions, private workflowService: WorkflowService) {}

  getTaskByDomainId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTaskByDomainId>(EWorkflowTaskAction.GetTaskByDomainId),
      switchMap(action => {
        const { domainCaseName, domainId } = action.payload;
        return this.workflowService.getWorkflowTaskById(domainCaseName, domainId).pipe(
          switchMap((task: WorkflowTask) => of(new GetTaskByDomainIdSuccess(task))),
          catchError(error => of(new GetTaskByDomainIdError(error)))
        );
      })
    )
  );

  putActionOnTaskById$ = createEffect(() => ({ 
    debounce = 500 } = {}) =>
    this.actions$.pipe(
      ofType<UpdateTaskActionByDomainId>(EWorkflowTaskAction.UpdateTaskActionByDomainId),
      debounceTime(debounce),
      switchMap(action => {
        const { domainCaseName, domainId, body } = action.payload;
        return this.workflowService.putActionOnTaskById(body, domainCaseName, domainId).pipe(
          switchMap((task: WorkflowTask) => of(new UpdateTaskActionByDomainIdSuccess(task))),
          catchError(error => of(new UpdateTaskActionByDomainIdError(error)))
        );
      })
    )
  );
}
