import { ESidebarActions, SidebarActions } from './sidebar.actions';
import { initialSidebarState, ISidebarState } from './sidebar.state';

export function sidebarReducers(
  state = initialSidebarState,
  action: SidebarActions
): ISidebarState {
  switch (action.type) {
    case ESidebarActions.SET_SELECTED_PRIMARY_NAV_CONFIG:
      return {
        ...state,
        selectedPrimaryNavConfig: action.selectedPrimaryNavConfig,
      };

    case ESidebarActions.SET_ACTIVE_NAV_IDS:
      return {
        ...state,
        activePrimaryNavMenuId: action.activeNavMenuIds.primary,
        activeSecondaryNavMenuId: action.activeNavMenuIds.secondary,
        activeTertiaryNavMenuId: action.activeNavMenuIds.tertiary,
      };

    default:
      return state;
  }
}
