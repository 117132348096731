import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { auctionsReducers } from 'app/store/auctions/auctions.reducers';
import { EAuthActions } from 'app/store/auth/auth.actions';
import { authReducers } from 'app/store/auth/auth.reducers';
import { closingDashboardReducer } from 'app/store/closing-dashboard/closing-dashboard.reducers';
import { imbalancesReducers } from 'app/store/imbalances/imbalances.reducers';
import { rateSchedulesReducers } from 'app/store/rate-schedules/rate-schedules.reducers';
import { schedulingReducers } from 'app/store/scheduling/scheduling.reducers';
import { systemPlanningReducer } from 'app/store/system-planning/system-planning.reducers';
import { localStorageSync } from 'ngrx-store-localstorage';
import { accountingReducer } from '../accounting/accounting.reducers';
import { agencyReducers } from '../agency/agency.reducers';
import { allocationsReducers } from '../allocations/allocations.reducers';
import { amendmentsReducer } from '../amendments/amendments.reducers';
import { billingReducers } from '../billing/billing.reducers';
import { capacityReleaseReducers } from '../capacity-release/capacity-release.reducers';
import { communicationReducers } from '../communication/communication.reducers';
import { confirmationsReducers } from '../confirmations/confirmations.reducers';
import { contractReducers } from '../contracts/contracts.reducers';
import { dealSheetsReducers } from '../deal-sheets/deal-sheets.reducers';
import { entitiesReducers } from '../entities/entities.reducers';
import { headerReducers } from '../header/header.reducers';
import { jobsReducers } from '../jobs/jobs.reducers';
import { journalEntryRuleReducer } from '../journal-entry-rule/journal-entry-rule.reducers';
import { locationReducers } from '../locations/locations.reducers';
import { lookupReducers } from '../lookup/lookup.reducers';
import { marketSectorOverridesReducer } from '../market-sector-overrides/market-sector-overrides.reducers';
import { measurementsReducers } from '../measurements/measurements.reducers';
import { mergerAssignmentReducers } from '../merger-assignments/merger-assignment.reducers';
import { nominationsReducers } from '../nominations/nominations.reducers';
import { notesReducers } from '../notes/notes.reducers';
import { operationalAccountsReducer } from '../operational-accounts/operational-accounts.reducers';
import { passwordResetReducers } from '../password-reset/password-reset.reducers';
import { pipelineModelsReducers } from '../pipeline-models/pipeline-models.reducers';
import { predefinedFootnotesReducers } from '../predefined-footnotes/predefined-footnotes.reducers';
import { ratchetLevelsReducers } from '../ratchet-levels/ratchet-levels.reducers';
import { rateContractReducers } from '../rate-contract/rate-contract.reducers';
import { repResponsibilityReducers } from '../rep-responsibility/rep-responsibility.reducers';
import { reportsReducers } from '../reports/reports.reducers';
import { retryReducers } from '../retry/retry.reducer';
import { ESchedulingActions } from '../scheduling/scheduling.actions';
import { serviceRequestsReducers } from '../service-requests/service-requests.reducers';
import { sidebarReducers } from '../sidebar/sidebar.reducers';
import { storageTransferReducer } from '../storage-transfer/storage-transfer.reducers';
import { tspsReducers } from '../tsp/tsps.reducers';
import { usersReducers } from '../users/users.reducers';
import { workflowTaskActionReducers } from '../workflow-task-action/workflow-task-action.reducers';
import { IAppState } from './app.state';
import { postingReducer } from '../posting/posting.reducers';

export const appReducers: ActionReducerMap<IAppState, any> = {
  accounting: accountingReducer,
  auctions: auctionsReducers,
  agencyState: agencyReducers,
  authState: authReducers,
  allocationsState: allocationsReducers,
  amendmentState: amendmentsReducer,
  billing: billingReducers,
  capacityRelease: capacityReleaseReducers,
  closingDashboard: closingDashboardReducer,
  confirmationsState: confirmationsReducers,
  contracts: contractReducers,
  communicationState: communicationReducers,
  dealSheetsState: dealSheetsReducers,
  entitiesState: entitiesReducers,
  headerState: headerReducers,
  imbalancesState: imbalancesReducers,
  jobsState: jobsReducers,
  journalEntryRule: journalEntryRuleReducer,
  locations: locationReducers,
  lookupState: lookupReducers,
  marketSectorOverrides: marketSectorOverridesReducer,
  measurements: measurementsReducers,
  mergerAssignmentState: mergerAssignmentReducers,
  nominationsState: nominationsReducers,
  operationalAccounts: operationalAccountsReducer,
  passwordResetState: passwordResetReducers,
  pipelineModelsState: pipelineModelsReducers,
  rateContractState: rateContractReducers,
  rateSchedules: rateSchedulesReducers,
  retryState: retryReducers,
  repResponsibilityState: repResponsibilityReducers,
  serviceRequests: serviceRequestsReducers,
  schedulingState: schedulingReducers,
  sidebarState: sidebarReducers,
  storageTransfer: storageTransferReducer,
  usersState: usersReducers,
  tspState: tspsReducers,
  notesState: notesReducers,
  predefinedFootnotesState: predefinedFootnotesReducers,
  ratchetLevelsState: ratchetLevelsReducers,
  workflowTaskActionState: workflowTaskActionReducers,
  systemPlanningState: systemPlanningReducer,
  reportsState: reportsReducers,
  postingState: postingReducer,
};

/**
  This function Is for saving the state to the local storage.
  It allows us to rehydrate the store between hard page loads or reloads of the applicaiton,
  as long as the user is logged in. (which this function also assists with persisting)
*/
export function localStorageSyncReducer(
  reducer: ActionReducer<IAppState>
): ActionReducer<IAppState> {
  return localStorageSync({
    keys: [
      {
        authState: [
          'accessToken',
          'isADFSLogin',
          'roles',
          'cookiesAccepted',
          'isAuthenticated',
          'logoutTime',
          'refreshToken',
          'saveUserId',
          'ttl',
          'user',
          'userId',
          'warningLogoutTime',
          'routeRoleConfigs',
          'authResources',
          'isInternal',
        ],
      },
      {
        headerState: {},
      },
      {
        imbalancesState: [
          'selectedImbalanceAccountingPeriod',
          'imbalanceContact',
          'imbalanceTrade',
          'imbalanceTradesSummaryCollection',
        ],
      },
      {
        schedulingState: ['SchedulingPageInformation'],
      },
    ],
    rehydrate: true,
  })(reducer);
}

export function logOutResetReducer(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
  return (state, action) =>
    reducer(action.type === EAuthActions.LOG_OUT ? undefined : state, action);
}

const WARNING_LOGOUT_OFFSET = 300000;

export function logoutTimeReducer(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
  return (state, action) => {
    if (!state || !state.authState) {
      return reducer(state, action);
    }

    const now = new Date().getTime();
    let newLogoutTime = now + state.authState.ttl;
    let newWarningLogoutTime = newLogoutTime - WARNING_LOGOUT_OFFSET;

    if (
      (action.type === ESchedulingActions.FETCH_RUN_RESULTS ||
        action.type === ESchedulingActions.FETCH_RUN_RESULTS_SUCCESS ||
        action.type === ESchedulingActions.FETCH_RUN_RESULTS_ERROR) &&
      state.schedulingState &&
      !state.schedulingState.userInitiated
    ) {
      newLogoutTime = state.authState.logoutTime;
      newWarningLogoutTime = newLogoutTime - WARNING_LOGOUT_OFFSET;
    } else if (
      action.type === EAuthActions.REFRESH_SUCCESS ||
      action.type === EAuthActions.REFRESH
    ) {
      newLogoutTime = state.authState.logoutTime;
      newWarningLogoutTime = newLogoutTime - WARNING_LOGOUT_OFFSET;
    }

    const newState = {
      ...state,
      authState: {
        ...state.authState,
        logoutTime: newLogoutTime,
        warningLogoutTime: newWarningLogoutTime,
      },
    };
    return reducer(newState, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  logOutResetReducer,
  localStorageSyncReducer,
  logoutTimeReducer,
];
