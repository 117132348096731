import { ReportingService } from '@gms/reporting-api';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PostingParamsModel } from './posting-response.model';
import {
  EPostingsActions,
  CreatePostingAction,
  CreatePostingFailureAction,
  CreatePostingSuccessAction,
  DeletePostingAction,
  DeletePostingActionFailure,
  DeletePostingActionSuccess,
  LoadPostingListFailureAction,
  LoadPostingListRequestAction,
  LoadPostingListSuccessAction,
  GetPostingDetailsById,
  GetPostingDetailsByIdSuccess,
  GetPostingDetailsByIdFailure,
  PostingDetailsPayload,
  IUploadPostingPayload,
  FetchPostingMenuItemsAction, FetchPostingMenuItemsSuccess, FetchPostingMenuItemsFailure
} from './posting.actions';
import { InfoPostMenuItems } from "@gms/reporting-api/model/infoPostMenuItems";

@Injectable({
  providedIn: 'root',
})
export class PostingEffects {
  constructor(
    private actions$: Actions,
    private postingService: ReportingService,
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPostingListRequestAction>(EPostingsActions.FETCH_POSTING_LIST),
    map(action => action.payload),
    switchMap(action => {
      const params: PostingParamsModel = action;
      const infoPostDetails = {
        tspId: params.tspId,
        contractNumber: params.contractNumber,
        datetimePostingEffective: params.postingEffectiveOn,
        description: params.description,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        infoPostName: params.infoPostName,
        sortBy: params.sortBy[0].field,
        sortDescending: (params.sortBy[0].dir === 'desc') ? true : false,
      }
      return this.postingService.getInfoPostDetails(
        infoPostDetails.tspId,
        infoPostDetails.infoPostName,
        infoPostDetails.datetimePostingEffective,
        null,null,null,
        infoPostDetails.description,
        infoPostDetails.pageNumber,
        infoPostDetails.pageSize,
        infoPostDetails.sortBy,
        infoPostDetails.sortDescending,
        infoPostDetails.contractNumber,
        null,null
      ).pipe(
        map((items: any) => {
          return new LoadPostingListSuccessAction(items)
        }),
        catchError(error => of(new LoadPostingListFailureAction({ error: error })))
      )
    })
  ))

  getPostingDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType<GetPostingDetailsById>(EPostingsActions.FETCH_POSTING_LIST_BY_ID),
    map(action => action),
    switchMap(action => {
      const params: PostingDetailsPayload = action.payload;
      return this.postingService.getInfoPostViewDetails(params.tsp_id, params.infopost_id, params.date).pipe(
        map((items: any) => {
          return new GetPostingDetailsByIdSuccess(items)
        }),
        catchError(error => of(new GetPostingDetailsByIdFailure({ error: error })))
      )
    })
  ))

  createRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<CreatePostingAction>(EPostingsActions.CREATE_NEW_POSTING),
    switchMap(action => {
      const params: IUploadPostingPayload = action.payload;
      return this.postingService.postInfoPostDetails(
        params.tspId, params.menuItem, params.description, params.datetimePostingEffective,
        params.document, params.datetimePostingExpire, params.contractId,
        params.active, params.postImmediately, params.alternateDocument,
        params.displayOrder, params.updateDateTimePostingEffective, params.additionalProperties,
        params.userId
      ).pipe(
        map((item: any) => {
          return new CreatePostingSuccessAction(item)
        }),
        catchError(error => of(new CreatePostingFailureAction({ error: error })))
      )
    })
  ))

  deleteRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<DeletePostingAction>(EPostingsActions.DELETE_POSTING),
    switchMap(action => {
      const params: PostingDetailsPayload = action.payload;
      return this.postingService.deletePosting(params.tsp_id, params.infopost_id, params.date).pipe(
        map((item: any) => {
          return new DeletePostingActionSuccess(true)
        }),
        catchError(error => of(new DeletePostingActionFailure({ error: error })))
      )
    })
  ))

  fetchPostingMenuItemsEffect$ = createEffect(() => this.actions$.pipe(
    ofType<FetchPostingMenuItemsAction>(EPostingsActions.FETCH_MENU_ITEM_LIST),
    map(action => action.tspID),
    switchMap(action => {
      return this.postingService.getInfoPostMenuItems(action).pipe(
        map((items: InfoPostMenuItems) => {
          return new FetchPostingMenuItemsSuccess(items)
        }),
        catchError(error => of(new FetchPostingMenuItemsFailure({ error: error })))
      )
    })
  ))

}
