import { createSelector } from '@ngrx/store';
import { IAppState } from '../app/app.state';
import { ITspState } from './tsps.state';
import { TspConfig } from '@gms/tsp-api';
import { TSPConstant } from "../../../shared/utils/tsp.utils";

const selectTspsState = (state: IAppState) => state.tspState;

export const selectTsp = createSelector(
  selectTspsState,
  (state: ITspState) => state.serviceProvider
);

export const selectSortOrder = createSelector(
  selectTspsState,
  (state: ITspState) => state.sort
);

export const selectLoading = createSelector(
  selectTspsState,
  (state: ITspState) => state.loading
);

export const selectError = createSelector(
  selectTspsState,
  (state: ITspState) => state.error
);

export const selectNetworkResult = createSelector(
  selectTspsState,
  (state: ITspState) => state.networkResultStatus
);

export const selectTspHolidays = createSelector(
  selectTspsState,
  (state: ITspState) => state.tspHolidays.data
);

export const selectTspHolidaysLoading = createSelector(
  selectTspsState,
  (state: ITspState) => state.tspHolidays.requestStatus.loading
);

export const selectTspHolidaysError = createSelector(
  selectTspsState,
  (state: ITspState) => state.tspHolidays.requestStatus.httpError
);

export const selectAllTspHolidays = createSelector(
  selectTspsState,
  (state: ITspState) => state.tspHolidayCollections.data
);

export const selectAllTspHolidaysLoading = createSelector(
  selectTspsState,
  (state: ITspState) => state.tspHolidayCollections.requestStatus.loading
);

export const selectAllTspHolidaysError = createSelector(
  selectTspsState,
  (state: ITspState) => state.tspHolidayCollections.requestStatus.httpError
);

export const selectTsps = createSelector(
  selectTspsState,
  (state: ITspState) => state.serviceProviders.data
);

export const selectServiceProviders = createSelector(
  selectTspsState,
  (state: ITspState) => state.serviceProviders
);

export const selectGetTspsStatus = createSelector(
  selectTspsState,
  (state: ITspState) => state.serviceProviders.requestStatus
);

export const selectExcludeInfoPostTsp = createSelector(
  selectTspsState,
  (state: ITspState) => {

    return state.serviceProviders?.data?.filter(x => getExcludeInfoPostTsp(x.tspConfigs));

  });


function getExcludeInfoPostTsp(data: Array<TspConfig>) {
  return data.filter(x => x.configName === TSPConstant.infoPostReportGeneration && x.configValue === "false").length > 0;
}
