import {
  ClosingGroupCollection,
  ClosingGroupProcessCollection,
  ClosingGroupTask,
} from '@gms/closing-api';
import { Action } from '@ngrx/store';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';

export enum EClosingDashBoardActions {
  FETCH_CLOSING_DASHBOARD_DATA = '[CLOSING_DASHBOARD] Fetch Closing Dashboard Data',
  FETCH_CLOSING_DASHBOARD_DATA_SUCCESS = '[CLOSING_DASHBOARD] Fetch Closing Dashboard Data Success',
  FETCH_CLOSING_DASHBOARD_DATA_FAILURE = '[CLOSING_DASHBOARD] Fetch Closing Dashboard Data Failure',
  UPDATE_CLOSING_GROUP_TASK = '[CLOSING_DASHBOARD] Update a Closing Group Task',
  UPDATE_CLOSING_GROUP_TASK_SUCCESS = '[CLOSING_DASHBOARD] Update a Closing Group Task Success',
  UPDATE_CLOSING_GROUP_TASK_FAILURE = '[CLOSING_DASHBOARD] Update a Closing Group Task Failure',
  CLOSE_CLOSING_GROUP = '[CLOSING_DASHBOARD] Close a Closing Group',
  CLOSE_CLOSING_GROUP_SUCCESS = '[CLOSING_DASHBOARD] Close a Closing Group Success',
  CLOSE_CLOSING_GROUP_FAILURE = '[CLOSING_DASHBOARD] Close a Closing Group Failure',
  REFRESH_GROUP_PROCESSES = '[CLOSING_DASHBOARD] Refresh Group Processes',
  REFRESH_GROUP_PROCESSES_SUCCESS = '[CLOSING_DASHBOARD] Refresh Group Processes Success',
  REFRESH_GROUP_PROCESSES_FAILURE = '[CLOSING_DASHBOARD] Refresh Group Processes Failure',
  RUN_TIE_OUT_SCRIPT = '[CLOSING_DASHBOARD] Run tie out script',
  RUN_TIE_OUT_SCRIPT_SUCCESS = '[CLOSING_DASHBOARD] Run tie out script Success',
  RUN_TIE_OUT_SCRIPT_FAILURE = '[CLOSING_DASHBOARD] Run tie out script Failure',
}

export interface IClosingDashBoardDataPayload {
  tspId: number;
  accountPeriod: IAccountPeriod;
}

export interface IGroupProcessesPayload extends IClosingDashBoardDataPayload {
  closingGroupId: number;
}

export interface ICloseClosingGroupPayload extends IClosingDashBoardDataPayload {
  closingGroupId: number;
  reviewerId: number;
}

export interface IRunTieOutScriptPayload extends IClosingDashBoardDataPayload {
  taskId: number;
}

export class GetClosingDashBoardData implements Action {
  public readonly type = EClosingDashBoardActions.FETCH_CLOSING_DASHBOARD_DATA;

  constructor(public payload: IClosingDashBoardDataPayload) {}
}

export class GetClosingDashBoardDataSuccess implements Action {
  public readonly type = EClosingDashBoardActions.FETCH_CLOSING_DASHBOARD_DATA_SUCCESS;

  constructor(public payload: ClosingGroupCollection) {}
}

export class GetClosingDashBoardDataFailure implements Action {
  public readonly type = EClosingDashBoardActions.FETCH_CLOSING_DASHBOARD_DATA_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateClosingGroupTask implements Action {
  public readonly type = EClosingDashBoardActions.UPDATE_CLOSING_GROUP_TASK;

  constructor(public payload: ClosingGroupTask) {}
}

export class UpdateClosingGroupTaskSuccess implements Action {
  public readonly type = EClosingDashBoardActions.UPDATE_CLOSING_GROUP_TASK_SUCCESS;
}

export class UpdateClosingGroupTaskFailure implements Action {
  public readonly type = EClosingDashBoardActions.UPDATE_CLOSING_GROUP_TASK_FAILURE;

  constructor(public payload: Error) {}
}

export class CloseClosingGroup implements Action {
  public readonly type = EClosingDashBoardActions.CLOSE_CLOSING_GROUP;

  constructor(public payload: ICloseClosingGroupPayload) {}
}

export class CloseClosingGroupSuccess implements Action {
  public readonly type = EClosingDashBoardActions.CLOSE_CLOSING_GROUP_SUCCESS;
}

export class CloseClosingGroupFailure implements Action {
  public readonly type = EClosingDashBoardActions.CLOSE_CLOSING_GROUP_FAILURE;

  constructor(public payload: Error) {}
}

export class RefreshGroupProcesses implements Action {
  public readonly type = EClosingDashBoardActions.REFRESH_GROUP_PROCESSES;

  constructor(public payload: IGroupProcessesPayload) {}
}

export class RefreshGroupProcessesSuccess implements Action {
  public readonly type = EClosingDashBoardActions.REFRESH_GROUP_PROCESSES_SUCCESS;

  constructor(
    public payload: {
      processCollection: ClosingGroupProcessCollection;
      closingGroupId: number;
    }
  ) {}
}

export class RefreshGroupProcessesFailure implements Action {
  public readonly type = EClosingDashBoardActions.REFRESH_GROUP_PROCESSES_FAILURE;

  constructor(
    public payload: {
      error: Error;
      closingGroupId: number;
    }
  ) {}
}

export class RunTieOutScript implements Action {
  public readonly type = EClosingDashBoardActions.RUN_TIE_OUT_SCRIPT;

  constructor(public payload: IRunTieOutScriptPayload) {}
}
export class RunTieOutScriptSuccess implements Action {
  public readonly type = EClosingDashBoardActions.RUN_TIE_OUT_SCRIPT_SUCCESS;
}

export class RunTieOutScriptFailure implements Action {
  public readonly type = EClosingDashBoardActions.RUN_TIE_OUT_SCRIPT_FAILURE;

  constructor(public payload: Error) {}
}

export type ClosingDashBoardActions =
  | GetClosingDashBoardData
  | GetClosingDashBoardDataSuccess
  | GetClosingDashBoardDataFailure
  | UpdateClosingGroupTask
  | UpdateClosingGroupTaskSuccess
  | UpdateClosingGroupTaskFailure
  | CloseClosingGroup
  | CloseClosingGroupSuccess
  | CloseClosingGroupFailure
  | RefreshGroupProcesses
  | RefreshGroupProcessesSuccess
  | RefreshGroupProcessesFailure
  | RunTieOutScript
  | RunTieOutScriptSuccess
  | RunTieOutScriptFailure;
