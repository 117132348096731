import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  ECommonComponentOwner,
  IsDeprecatedComponent,
} from 'shared/components/higher-order/is-deprecated-component';
import { Message_DEPRECATED } from './message_deprecated.interface';

@Component({
  selector: 'app-message',
  templateUrl: './message-deprecated.component.html',
  styleUrls: ['./message-deprecated.component.scss'],
})
/* tslint:disable-next-line */
export class MessageComponent_DEPRECATED extends IsDeprecatedComponent
  implements OnChanges, OnInit {
  @Input()
  type: string;

  @Input()
  messages: Message_DEPRECATED[];

  @Input()
  showDismissButton = false;

  @Output()
  readonly dismissed: EventEmitter<any> = new EventEmitter();

  iconType: string;

  typeIconMap = {
    error: 'icon-block',
    info: 'icon-information',
    success: 'icon-success',
    warning: 'icon-warning',
  };

  classes = [];
  iconClasses = [];

  constructor() {
    super('app-message', 'gms-message', [ECommonComponentOwner.TRANG_DO]);
  }

  ngOnInit() {
    this.iconType = this.typeIconMap[this.type];
  }

  ngOnChanges() {
    this.setClasses();
  }

  setClasses() {
    const classes = [];
    const iconClasses = [];

    classes.push(this.type);

    if (!this.messages || (this.messages && this.messages.length === 1)) {
      classes.push('center-message');
      iconClasses.push('icon-single-message');
    } else {
      iconClasses.push('icon-multiple-messages');
    }
    this.classes = classes;
    this.iconClasses = iconClasses;
  }

  dismissMessage() {
    this.dismissed.emit();
  }
}
