import { Routes } from '@angular/router';

export const PasswordReset_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/password-reset/pages/password-reset/password-reset.module').then(
        m => m.PasswordResetModule
      ),
  },
];
