import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { DealSheetOverviewComponent } from 'app/modules/service-request/pages/deal-sheet-overview/deal-sheet-overview.component';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Nominations_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/nominations/nominations.module').then(m => m.NominationsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/nomination',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'block-matching',
    loadChildren: () =>
      import('./pages/block-matching/block-matching.module').then(m => m.BlockMatchingModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/block-matching',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'confirmations',
    loadChildren: () =>
      import('./pages/confirmations/confirmations.module').then(m => m.ConfirmationsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/confirmations',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'scheduled-quantity',
    loadChildren: () =>
      import('./pages/scheduled-quantity/scheduled-quantity.module').then(
        m => m.ScheduledQuantityModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/scheduled-quantity',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'sched-qty-operator',
    loadChildren: () =>
      import('./pages/scheduled-quantity-for-operator/scheduled-quantity-for-operator.module').then(
        m => m.ScheduledQuantityForOperatorModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/sched-qty-operator',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'sched-qty-operator/:id',
    loadChildren: () =>
      import('./pages/scheduled-quantity-for-operator-details/scheduled-quantity-for-operator-details.module').then(
        m => m.ScheduledQuantityForOperatorDetailsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/sched-qty-operator',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'scheduling-engine',
    loadChildren: () =>
      import('./pages/scheduling-engine/scheduling-engine.module').then(
        m => m.SchedulingEngineModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/scheduling-engine',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'scheduling-engine/:id',
    loadChildren: () =>
      import('./pages/scheduling-engine-details/scheduling-engine-details.module').then(
        m => m.SchedulingEngineDetailsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/scheduling-engine',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'nominations-details/:id',
    loadChildren: () =>
      import('./pages/nominations-details/nominations-details.module').then(
        m => m.NominationsDetailsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/nominations-details',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'nomination-upload',
    loadChildren: () =>
      import('./pages/nomination-upload/upload-nominations.module').then(
        m => m.UploadNominationsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/nominations/nomination-upload',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
];
