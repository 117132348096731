import {
  EJournalEntryActions,
  EJournalEntryRuleCollectionActions,
  JournalEntryRuleActions,
} from './journal-entry-rule.actions';
import { IJournalEntryRuleState, initialJournalEntryRuleState } from './journal-entry-rule.state';

export function journalEntryRuleReducer(
  state = initialJournalEntryRuleState,
  action: JournalEntryRuleActions
): IJournalEntryRuleState {
  switch (action.type) {
    case EJournalEntryRuleCollectionActions.GetJournalEntryRuleCollection:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        sortDescriptors: action.payload.sortDescriptors,
        getJournalEntryRuleCollection: { loading: true, httpError: null },
      };
    case EJournalEntryRuleCollectionActions.GetJournalEntryRuleCollectionSuccess:
      return {
        ...state,
        journalEntryRuleCollection: action.payload,
        getJournalEntryRuleCollection: { loading: false, httpError: null },
      };
    case EJournalEntryRuleCollectionActions.GetJournalEntryRuleCollectionFailure:
      return {
        ...state,
        journalEntryRuleCollection: { journalEntryRules: [], total: null },
        getJournalEntryRuleCollection: { loading: false, httpError: action.payload },
      };
    case EJournalEntryRuleCollectionActions.GetJournalEntryRule:
      return { ...state, getJournalEntryRule: { loading: true, httpError: null } };
    case EJournalEntryRuleCollectionActions.SET_JOURNAL_ENTRY_RULE:
    case EJournalEntryRuleCollectionActions.GetJournalEntryRuleSuccess:
      return {
        ...state,
        journalEntryRule: action.payload,
        getJournalEntryRule: { loading: false, httpError: null },
      };
    case EJournalEntryRuleCollectionActions.CLEAR_JOURNAL_ENTRY_RULE:
      return {
        ...state,
        journalEntryRule: null,
      };
    case EJournalEntryRuleCollectionActions.GetJournalEntryRuleFailure:
      return {
        ...state,
        journalEntryRule: null,
        getJournalEntryRule: { loading: false, httpError: action.payload },
      };

    case EJournalEntryActions.ADD_JOURNAL_ENTRY:
      return {
        ...state,
        createJournalEntryRule: {
          ...state.createJournalEntryRule,
          loading: {
            ...state.createJournalEntryRule.loading,
            journalEntryRule: true,
          },
          error: null,
        },
      };
    case EJournalEntryActions.ADD_JOURNAL_ENTRY_FAILURE:
      return {
        ...state,
        createJournalEntryRule: {
          ...state.createJournalEntryRule,
          loading: {
            ...state.createJournalEntryRule.loading,
            journalEntryRule: false,
          },
          error: action.error,
        },
      };
    case EJournalEntryActions.ADD_JOURNAL_ENTRY_SUCCESS:
      return {
        ...state,
        createJournalEntryRule: {
          ...state.createJournalEntryRule,
          loading: {
            ...state.createJournalEntryRule.loading,
            journalEntryRule: false,
          },
          error: null,
          journalEntryRule: action.payload,
        },
      };
    case EJournalEntryActions.RESET_JOURNAL_ENTRY_RULE:
      return {
        ...state,
        createJournalEntryRule: {
          ...state.createJournalEntryRule,
          loading: {
            ...state.createJournalEntryRule.loading,
          },
          error: null,
          journalEntryRule: null,
        },
      };
    case EJournalEntryRuleCollectionActions.PutJournalEntryRule:
      return {
        ...state,
        updateJournalEntryRule: { loading: true, httpError: null },
      };
    case EJournalEntryRuleCollectionActions.PutJournalEntryRuleSuccess:
      return {
        ...state,
        updateJournalEntryRule: { loading: false, httpError: null },
      };
    case EJournalEntryRuleCollectionActions.PutJournalEntryRuleFailure:
      return {
        ...state,
        updateJournalEntryRule: { loading: false, httpError: action.payload },
      };
    case EJournalEntryRuleCollectionActions.GetGeneralLedgerAccounts:
      return {
        ...state,
        generalLedgerAccountDictionary: [],
        getGeneralLedgerAccounts: { loading: true, httpError: null },
      };
    case EJournalEntryRuleCollectionActions.GetGeneralLedgerAccountsSuccess:
      return {
        ...state,
        generalLedgerAccountDictionary: action.payload,
        getGeneralLedgerAccounts: { loading: false, httpError: null },
      };
    case EJournalEntryRuleCollectionActions.GetGeneralLedgerAccountsFailure:
      return {
        ...state,
        generalLedgerAccountDictionary: [],
        getGeneralLedgerAccounts: { loading: false, httpError: action.payload },
      };
    case EJournalEntryRuleCollectionActions.ClearJournalEntryRuleError:
      return {
        ...state,
        updateJournalEntryRule: {
          loading: false,
          httpError: null,
        },
      };
    case EJournalEntryActions.ClearCreateJournalEntryRuleError:
      return {
        ...state,
        createJournalEntryRule: {
          ...state.createJournalEntryRule,
          error: null,
        },
      };
    default:
      return state;
  }
}
