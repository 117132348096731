import {
  createEmptyLoadingState,
  createErrorState,
  createSuccessState,
  emptyRequestStatus,
} from 'app/store/app/app.models';
import {
  ERateSchedulesActions,
  RateScheduleActions,
} from 'app/store/rate-schedules/rate-schedules.actions';
import {
  initialRateSchedulesState,
  IRateScheduleState,
} from 'app/store/rate-schedules/rate-schedules.state';

export function rateSchedulesReducers(
  state: IRateScheduleState = initialRateSchedulesState,
  action: RateScheduleActions
): IRateScheduleState {
  switch (action.type) {
    case ERateSchedulesActions.FetchMasterRateSchedules:
      return {
        ...state,
        status: {
          ...state.status,
          masterRateSchedules: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchMasterRateSchedulesSuccess:
      return {
        ...state,
        masterRateSchedules: action.payload.masterRateSchedules,
        status: {
          ...state.status,
          masterRateSchedules: {
            loading: false,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchMasterRateSchedulesFailure:
      return {
        ...state,
        status: {
          ...state.status,
          masterRateSchedules: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case ERateSchedulesActions.FetchDetailRateSchedules:
      return {
        ...state,
        status: {
          ...state.status,
          detailRateSchedules: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchDetailRateSchedulesSuccess:
      return {
        ...state,
        detailRateSchedules: action.payload.detailRateSchedules,
        status: {
          ...state.status,
          detailRateSchedules: {
            loading: false,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchDetailRateSchedulesFailure:
      return {
        ...state,
        status: {
          ...state.status,
          detailRateSchedules: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case ERateSchedulesActions.FetchAllDetailRateSchedules:
      return {
        ...state,
        status: {
          ...state.status,
          allDetailRateSchedules: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchAllDetailRateSchedulesSuccess:
      return {
        ...state,
        allDetailRateSchedules: action.payload.allDetailRateSchedules,
        status: {
          ...state.status,
          allDetailRateSchedules: {
            loading: false,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchAllDetailRateSchedulesFailure:
      return {
        ...state,
        status: {
          ...state.status,
          allDetailRateSchedules: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case ERateSchedulesActions.ClearDetailRateSchedulesError:
      return {
        ...state,
        status: {
          ...state.status,
          allDetailRateSchedules: emptyRequestStatus,
        },
      };

    case ERateSchedulesActions.FetchDetailRateScheduleUiConfiguration:
      return {
        ...state,
        status: {
          ...state.status,
          detailRateScheduleUiConfiguration: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchDetailRateScheduleUiConfigurationSuccess:
      return {
        ...state,
        uiConfiguration: [
          ...state.uiConfiguration.filter(
            config => config.detailRateScheduleId !== action.payload.detailRateScheduleId
          ),
          {
            detailRateScheduleId: action.payload.detailRateScheduleId,
            configuration: action.payload.uiConfigurations,
          },
        ],
        status: {
          ...state.status,
          detailRateScheduleUiConfiguration: {
            loading: false,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchDetailRateScheduleUiConfigurationFailure:
      return {
        ...state,
        status: {
          ...state.status,
          detailRateScheduleUiConfiguration: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case ERateSchedulesActions.FetchRateSchedule:
      return {
        ...state,
        status: {
          ...state.status,
          detailRateSchedule: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchRateScheduleSuccess:
      return {
        ...state,
        detailRateSchedule: action.payload.detailRateSchedule,
        status: {
          ...state.status,
          detailRateSchedule: {
            loading: false,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FetchRateScheduleFailure:
      return {
        ...state,
        status: {
          ...state.status,
          detailRateSchedule: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case ERateSchedulesActions.CLEAR_DETAIL_RATE_SCHEDULE:
      return {
        ...state,
        detailRateSchedule: null,
      };

    case ERateSchedulesActions.Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID:
      return {
        ...state,
        status: {
          ...state.status,
          naesbLocationIndicators: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID_Success:
      return {
        ...state,
        naesbLocationIndicators: action.payload.naesbLocationIndicators,
        status: {
          ...state.status,
          naesbLocationIndicators: {
            loading: false,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID_Failure:
      return {
        ...state,
        status: {
          ...state.status,
          naesbLocationIndicators: {
            loading: false,
            httpError: action.error,
          },
        },
      };

    case ERateSchedulesActions.FETCH_QUANTITY_TYPE:
      return {
        ...state,
        quantityTypeState: {
          data: null,
          requestStatus: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FETCH_QUANTITY_TYPE_SUCCESS:
      return {
        ...state,
        quantityTypeState: createSuccessState(action.payload.quantityType),
      };

    case ERateSchedulesActions.FETCH_QUANTITY_TYPE_FAILURE:
      return {
        ...state,
        quantityTypeState: createErrorState(action.payload.error),
      };

    case ERateSchedulesActions.CLEAR_QUANTITY_TYPE:
      return {
        ...state,
        quantityTypeState: createSuccessState(null),
      };

    case ERateSchedulesActions.FETCH_TSP_SEASONS:
      return {
        ...state,
        tspSeasons: null,
        status: {
          ...state.status,
          tspSeasons: {
            loading: true,
            httpError: null,
          },
        },
      };

    case ERateSchedulesActions.FETCH_TSP_SEASONS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          tspSeasons: {
            loading: false,
            httpError: null,
          },
        },
        tspSeasons: action.payload.tspSeasons,
      };

    case ERateSchedulesActions.FETCH_QUANTITY_TYPE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          tspSeasons: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case ERateSchedulesActions.SET_ALLOW_SAME_DAY_DEAL:
      return {
        ...state,
        allowSameDay: action.payload.allow,
      };

    default:
      return state;
  }
}
