import { NotificationType } from '@gms/communication-api';
import { Action } from '@ngrx/store';

export enum ECommunicationActions {
  FetchNotificationTypes = '[COMMUNICATION] Fetch Notification Types',
  FetchNotificationTypesSuccess = '[COMMUNICATION] Fetch Notification Types Success',
  FetchNotificationTypesError = '[COMMUNICATION] Fetch Notification Types Error',
}

export class FetchNotificationTypes implements Action {
  public readonly type = ECommunicationActions.FetchNotificationTypes;

  constructor() {}
}

export class FetchNotificationTypesSuccess implements Action {
  public readonly type = ECommunicationActions.FetchNotificationTypesSuccess;

  constructor(public payload: { notificationTypes: NotificationType[] }) {}
}

export class FetchNotificationTypesError implements Action {
  public readonly type = ECommunicationActions.FetchNotificationTypesError;

  constructor(public payload: { error: Error }) {}
}

export type CommunicationActions =
  | FetchNotificationTypes
  | FetchNotificationTypesSuccess
  | FetchNotificationTypesError;
