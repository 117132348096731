<label class="checkmark-container" [class.invalid]="control?.touched && control?.invalid"
  >{{ label }}
  <input
    id="{{ id }}-checkmark-input"
    class="checkmark-input"
    type="checkbox"
    [formControl]="control"
    (focus)="onFocus()"
  />
  <span
    id="{{ id }}-checkmark"
    class="checkmark"
    [class.disabled]="control?.disabled"
    [class.invalid]="control?.touched && control?.invalid"
  >
  </span>
</label>
