import { Component, Input } from '@angular/core';
import { ClickableButtonOptions } from './button-cell.interface';

@Component({
  selector: 'gms-dynamic-button-cell',
  templateUrl: './button-cell.component.html',
})
export class DynamicInputButtonComponent {
  @Input() data: ClickableButtonOptions;

  public onClick() {
    this.data.onClick();
  }
}
