import { FuelRateResponseCollection } from '@gms/invoicing-api';
import {
  Cycle,
  CycleCollection,
  DropdownDictionary,
  GasDay,
  Nomination,
  NominationCollection,
  NominationSummary,
  OperatorScheduledQuantityCollection,
  ReductionReasonModel,
  ScheduledQuantity,
  ScheduledQuantitySummary,
  TransactionTypeCollection,
  TspDetailCollection,
} from '@gms/nomination-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ServiceRequester } from '../header/header.state';

export enum ENominationsActions {
  FETCH_NOMINATIONS = '[NOMINATIONS] Fetch nominations',
  FETCH_NOMINATIONS_SUCCESS = '[NOMINATIONS] Fetch nominations Success',
  FETCH_NOMINATIONS_ERROR = '[NOMINATIONS] Fetch nominations Error',
  ADD_NOMINATION_WARNINGS = '[NOMINATIONS] Add nomination warnings',
  CLEAR_NOMINATION_WARNINGS = '[NOMINATIONS] Clear nomination warnings',

  UPLOAD_NOMINATIONS = '[NOMINATIONS] Upload nominations',
  UPLOAD_NOMINATIONS_SUCCESS = '[NOMINATIONS] Upload nominations success',
  UPLOAD_NOMINATIONS_FAILURE = '[NOMINATIONS] Upload nominations failure',
  SET_UPLOAD_NOMINATIONS_LOADING = '[NOMINATIONS] Set upload nominations loading',

  VALIDATE_NOMINATIONS = '[NOMINATIONS] Validate nominations',
  VALIDATE_NOMINATIONS_SUCCESS = '[NOMINATIONS] Validate nominations success',
  VALIDATE_NOMINATIONS_FAILURE = '[NOMINATIONS] Validate nominations failure',
  CLEAR_VALIDATE_NOMINATIONS = '[NOMINATIONS] Clear validate nominations',

  FETCH_NOMINATION_BY_ID = '[NOMINATIONS] Fetch nomination by id',
  FETCH_NOMINATION_BY_ID_SUCCESS = '[NOMINATIONS] Fetch nomination Success',
  FETCH_NOMINATION_BY_ID_ERROR = '[NOMINATIONS] Fetch nomination Error',

  CREATE_NOMINATION = '[NOMINATIONS] Create nomination',
  CREATE_NOMINATION_SUCCESS = '[NOMINATIONS] Create nomination Success',
  CREATE_NOMINATION_VALIDATION_FAILURE = '[NOMINATIONS] Create nomination validation Failure',
  CREATE_NOMINATION_FAILURE = '[NOMINATIONS] Create nomination Failure',
  CLEAR_NOMINATIONS = '[NOMINATIONS] Clear Nominations',

  FETCH_SCHEDULED_QUANTITIES = '[NOMINATIONS] Fetch Scheduled Quantities',
  FETCH_SCHEDULED_QUANTITIES_SUCCESS = '[NOMINATIONS] Fetch Scheduled Quantities Success',
  FETCH_SCHEDULED_QUANTITIES_FAILURE = '[NOMINATIONS] Fetch Scheduled Quantities Failure',

  EXPORT_SCHEDULED_QUANTITIES = '[NOMINATIONS] Export Scheduled Quantities',
  EXPORT_SCHEDULED_QUANTITIES_SUCCESS = '[NOMINATIONS] Export Scheduled Quantities Success',
  EXPORT_SCHEDULED_QUANTITIES_FAILURE = '[NOMINATIONS] Export Scheduled Quantities Failure',

  EXPORT_OPERATOR_SCHEDULED_QUANTITIES = '[NOMINATIONS] Export Operator Scheduled Quantities',
  EXPORT_OPERATOR_SCHEDULED_QUANTITIES_SUCCESS = '[NOMINATIONS] Export Operator Scheduled Quantities Success',
  EXPORT_OPERATOR_SCHEDULED_QUANTITIES_FAILURE = '[NOMINATIONS] Export Operator Scheduled Quantities Failure',

  FETCH_SCHEDULED_QUANTITY = '[NOMINATIONS] Fetch Scheduled Quantity',
  FETCH_SCHEDULED_QUANTITY_SUCCESS = '[NOMINATIONS] Fetch Scheduled Quantity Success',
  FETCH_SCHEDULED_QUANTITY_FAILURE = '[NOMINATIONS] Fetch Scheduled Quantity Failure',
  CLEAR_SCHEDULED_QUANTITIES = '[NOMINATIONS] Clear Scheduled Quantity',

  FETCH_ORDER_MANAGEMENT_DICTIONARY = '[NOMINATIONS] Fetch Order Management Dictionary',
  FETCH_ORDER_MANAGEMENT_DICTIONARY_SUCCESS = '[NOMINATIONS] Fetch Order Management Dictionary Success',
  FETCH_ORDER_MANAGEMENT_DICTIONARY_FAILURE = '[NOMINATIONS] Fetch Order Management Dictionary Failure',

  FETCH_FUEL_RATE = '[NOMINATION] Fetch Fuel Rate',
  FETCH_FUEL_RATE_SUCCESS = '[NOMINATIONS] Fetch Fuel Rate Success',
  FETCH_FUEL_RATE_FAILURE = '[NOMINATIONS] Fetch Fuel Rate Failure',
  CLEAR_FUEL_RATE = '[NOMINATIONS] Clear Fuel Rate',

  FETCH_NOMINATION_CYCLES = '[NOMINATIONS] Fetch Nomination Cycles',
  FETCH_NOMINATION_CYCLES_SUCCESS = '[NOMINATIONS] Fetch Nomination Cycles Success',
  FETCH_NOMINATION_CYCLES_FAILURE = '[NOMINATIONS] Fetch Nomination Cycles Failure',

  CREATE_NOMINATION_CYCLES_OVERRIDE = '[NOMINATIONS] Create Nomination Cycle Override',
  CREATE_NOMINATION_CYCLES_OVERRIDE_SUCCESS = '[NOMINATIONS] Create Nomination Cycle Override Success',
  CREATE_NOMINATION_CYCLES_OVERRIDE_FAILURE = '[NOMINATIONS] Create Nomination Cycle Override Failure',
  CREATE_NOMINATION_CYCLES_OVERRIDE_RESET = '[NOMINATIONS] Create Nomination Cycle Override Reset',

  FETCH_CURRENT_GAS_DAY = '[NOMINATIONS] Fetch Current Gas Day',
  FETCH_CURRENT_GAS_DAY_SUCCESS = '[NOMINATIONS] Fetch Current Gas Day Success',
  FETCH_CURRENT_GAS_DAY_FAILURE = '[NOMINATIONS] Fetch Current Gas Day Failure',

  FETCH_OPERATOR_SCHEDULED_QUANTITIES = '[NOMINATIONS] FETCH operator scheduled',
  FETCH_OPERATOR_SCHEDULED_QUANTITIES_SUCCESS = '[NOMINATIONS] FETCH operator scheduled Success',
  FETCH_OPERATOR_SCHEDULED_QUANTITIES_FAILURE = '[NOMINATIONS] FETCH operator scheduled Failure',

  FETCH_OPERATOR_SCHEDULED_QUANTITY = '[NOMINATIONS] Fetch Operator Scheduled Quantity',
  FETCH_OPERATOR_SCHEDULED_QUANTITY_SUCCESS = '[NOMINATIONS] Fetch Operator Scheduled Quantity Success',
  FETCH_OPERATOR_SCHEDULED_QUANTITY_FAILURE = '[NOMINATIONS] Fetch Operator Scheduled Quantity Failure',

  CLEAR_OPERATOR_SCHEDULED_QUANTITY = '[NOMINATIONS] Clear Operator Scheduled Quantity',
  SET_OPERATOR_SCHEDULED_QUANTITY_FILTER = '[NOMINATIONS] Set Operator Scheduled Quantity Filter',
  FETCH_TRANSACTION_TYPES = '[NOMINATIONS] Fetch Transaction Types',
  FETCH_TRANSACTION_TYPES_SUCCESS = '[NOMINATIONS] Fetch Transaction Types Success',
  FETCH_TRANSACTION_TYPES_FAILURE = '[NOMINATIONS] Fetch Transaction Types Failure',

  FETCH_NOMINATION_SUMMARY = '[NOMINATIONS] Fetch Nomination Summary',
  FETCH_NOMINATION_SUMMARY_SUCCESS = '[NOMINATIONS] Fetch Nomination Summary Success',
  FETCH_NOMINATION_SUMMARY_FAILURE = '[NOMINATIONS] Fetch Nomination Summary Failure',
  CLEAR_NOMINATION_SUMMARY = '[NOMINATIONS] Clear Nomination Summary',

  FETCH_REDUCTION_REASONS = '[NOMINATIONS] Fetch Reduction Reasons',
  FETCH_REDUCTION_REASONS_SUCCESS = '[NOMINATIONS] Fetch Reduction Reasons Success',
  FETCH_REDUCTION_REASONS_FAILURE = '[NOMINATIONS] Fetch Reduction Reasons Failure',

  FETCH_TSP_DETAILS = '[NOMINATIONS] Fetch Tsp Details',
  FETCH_TSP_DETAILS_SUCCESS = '[NOMINATIONS] Fetch Tsp Details Success',
  FETCH_TSP_DETAILS_FAILURE = '[NOMINATIONS] Fetch Tsp Details Failure',
}

// --------------------------------------------------
// INTERFACES
// --------------------------------------------------
export interface INominationsUploadPayload {
  fileName: string;
  file: File;
  contentType: string;
  contentLength?: any;
}

export interface INominationsUploadResponse {
  success: boolean;
}

export interface INominationsPayload {
  pageSize: number;
  pageNumber: number;
  sortDescriptors: SortDescriptor[];
  excludeZeroNomQty?: boolean;
  tspId?: number;
  serviceRequestEntityId?: number[];
  contractId?: number[];
  status?: Nomination.StatusEnum;
  gasFlowDate?: Date;
  cycleCode?: string;
  lastChangeCycleCode?: string;
  transactionTypeCode?: string;
  packageId?: string;
  receiptLocationNumber?: number[];
  upstreamEntityId?: number[];
  upstreamContractId?: string;
  deliveryLocationNumber?: number[];
  downstreamEntityId?: number[];
  downstreamContractId?: string;
  upstreamEntityCustomInput?: string;
  downstreamEntityCustomInput?: string;
  contractNumber?: string;
}

export interface INominationWarning {
  naesbCode: string;
  nominations: Nomination[];
}

export interface IScheduledQuantitiesPayload {
  serviceRequestorId?: number;
  gasFlowDate?: Date;
  cycleCode?: string;
  excludeZeroReductionQty?: boolean;
  tspId?: number;
}

export interface IExportScheduledQuantitiesPayload {
  tspId?: number;
  serviceRequestorId?: number;
  gasFlowDate?: Date;
  cycleCode?: string;
  sortBy?: string;
}

export interface IScheduledQuantityPayload {
  gasFlowDate: Date;
  serviceRequestorId?: number;
  serviceRequestContractId?: number;
  tspId?: number;
  cycleCode?: string;
  excludeZeroReductionQty?: boolean;
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string;
  locationId?: number;
  beginDate?: Date;
  endDate?: Date;
}

export interface IOperatorScheduledQuantityPayload {
  tspId: number;
  cycleCode: string;
  confirmingEntityId: number;
  beginDate: string;
  endDate: string;
  locationId: number;
  onlyShowReductions: boolean;
}

export interface IExportOperatorScheduledQuantityPayload {
  tspId: number;
  cycleCode: string;
  confirmingEntityId: number;
  beginDate: string;
  endDate: string;
  locationId: number;
  reductionsOnly: string;
  asEmail: boolean;
}

export interface IOperatorScheduledQuantityFilterPayload {
  recipient: ServiceRequester;
  beginDate: Date;
  endDate: Date;
  cycle: any;
  locationId: number;
}

export interface IFuelRatePayload {
  gasFlowDate: string;
  contractId?: number;
  delLocId?: number;
  recLocId?: number;
  accountId?: number;
  tspId?: number;
  transactionTypeCode?: string;
  detailRateScheduleId?: number;
}

export interface IFetchNominationCyclePayload {
  tspId: number;
  cycleId?: number;
  dateEffective?: string;
  naesbOnly?: boolean;
  addGasDayOffset?: boolean;
}

export interface ITransactionTypesPayload {
  tspId?: number;
}

// --------------------------------------------------
// UPLOAD NOMINATIONS
// --------------------------------------------------
export class UploadNominations implements Action {
  public readonly type = ENominationsActions.UPLOAD_NOMINATIONS;

  constructor(public payload: INominationsUploadPayload) {}
}

export class UploadNominationsSuccess implements Action {
  public readonly type = ENominationsActions.UPLOAD_NOMINATIONS_SUCCESS;

  constructor(public payload: INominationsUploadResponse) {}
}

export class UploadNominationsError implements Action {
  public readonly type = ENominationsActions.UPLOAD_NOMINATIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// Used to override the isLoading flag on upload nominations so we can stop the loading spinner in certain situations.
export class SetUploadNomminationsLoading implements Action {
  public readonly type = ENominationsActions.SET_UPLOAD_NOMINATIONS_LOADING;

  constructor(public payload: { loading: boolean }) {}
}

// --------------------------------------------------
// FETCH NOMINATIONS
// --------------------------------------------------
export class FetchNominations implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATIONS;

  constructor(public payload: INominationsPayload) {}
}

export class FetchNominationsSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATIONS_SUCCESS;

  constructor(
    public payload: {
      nominations: Array<Nomination>;
      totalNominationsCount: number;
      agencyAccessBySvcReq: boolean;
    }
  ) {}
}

export class FetchNominationsError implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATIONS_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// NOMINATION WARNINGS
// --------------------------------------------------
export class AddNominationWarnings implements Action {
  public readonly type = ENominationsActions.ADD_NOMINATION_WARNINGS;

  constructor(public payload: INominationWarning[]) {}
}

export class ClearNominationWarnings implements Action {
  public readonly type = ENominationsActions.CLEAR_NOMINATION_WARNINGS;

  constructor() {}
}

// --------------------------------------------------
// FETCH NOMINATION BY ID
// --------------------------------------------------
export class FetchNominationById implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_BY_ID;

  constructor(public payload: number) {}
}

export class FetchNominationByIdSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_BY_ID_SUCCESS;

  constructor(public payload: { nomination: Nomination }) {}
}

export class FetchNominationByIdError implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_BY_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// CREATE NOMINATION
// --------------------------------------------------
export class CreateNomination implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION;

  constructor(public payload: NominationCollection) {}
}

export class CreateNominationSuccess implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION_SUCCESS;

  constructor(public payload: { nominations: Array<Nomination> }) {}
}

export class CreateNominationFailure implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// NOMINATION VALIDATIONS BEFORE SUBMIT
// --------------------------------------------------
export class ValidateNominations implements Action {
  public readonly type = ENominationsActions.VALIDATE_NOMINATIONS;

  constructor(public payload: NominationCollection) {}
}

export class ValidateNominationsSuccess implements Action {
  public readonly type = ENominationsActions.VALIDATE_NOMINATIONS_SUCCESS;

  constructor(public payload: { nominations: Array<Nomination> }) {}
}

export class ValidateNominationsFailure implements Action {
  public readonly type = ENominationsActions.VALIDATE_NOMINATIONS_FAILURE;

  constructor(public payload: { error: NominationCollection }) {}
}

export class ClearValidateNominations implements Action {
  public readonly type = ENominationsActions.CLEAR_VALIDATE_NOMINATIONS;
}

export class CreateNominationValidationFailure implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION_VALIDATION_FAILURE;

  constructor(public payload: { error: NominationCollection }) {}
}

export class ClearNominations implements Action {
  public readonly type = ENominationsActions.CLEAR_NOMINATIONS;

  constructor() {}
}

// --------------------------------------------------
// FETCH SCHEDULED QUANTITIES
// --------------------------------------------------
export class FetchScheduledQuantities implements Action {
  public readonly type = ENominationsActions.FETCH_SCHEDULED_QUANTITIES;

  constructor(public payload: IScheduledQuantitiesPayload) {}
}

export class FetchScheduledQuantitiesSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_SCHEDULED_QUANTITIES_SUCCESS;

  constructor(
    public payload: {
      scheduledQuantities: Array<ScheduledQuantitySummary>;
      totalScheduledQuantitiesCount: number;
    }
  ) {}
}

export class FetchScheduledQuantitiesFailure implements Action {
  public readonly type = ENominationsActions.FETCH_SCHEDULED_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearScheduledQuantities implements Action {
  public readonly type = ENominationsActions.CLEAR_SCHEDULED_QUANTITIES;

  constructor() {}
}

// --------------------------------------------------
// EXPORT SCHEDULED QUANTITIES
// --------------------------------------------------
export class ExportScheduledQuantities implements Action {
  public readonly type = ENominationsActions.EXPORT_SCHEDULED_QUANTITIES;

  constructor(public payload: IExportScheduledQuantitiesPayload) {}
}

export class ExportScheduledQuantitiesSuccess implements Action {
  public readonly type = ENominationsActions.EXPORT_SCHEDULED_QUANTITIES_SUCCESS;
}

export class ExportScheduledQuantitiesFailure implements Action {
  public readonly type = ENominationsActions.EXPORT_SCHEDULED_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// EXPORT OPERATOR SCHEDULED QUANTITIES
// --------------------------------------------------
export class ExportOperatorScheduledQuantities implements Action {
  public readonly type = ENominationsActions.EXPORT_OPERATOR_SCHEDULED_QUANTITIES;

  constructor(public payload: IExportOperatorScheduledQuantityPayload) {}
}

export class ExportOperatorScheduledQuantitiesSuccess implements Action {
  public readonly type = ENominationsActions.EXPORT_OPERATOR_SCHEDULED_QUANTITIES_SUCCESS;
}

export class ExportOperatorScheduledQuantitiesFailure implements Action {
  public readonly type = ENominationsActions.EXPORT_OPERATOR_SCHEDULED_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// FETCH SCHEDULED QUANTITY
// --------------------------------------------------
export class FetchScheduledQuantity implements Action {
  public readonly type = ENominationsActions.FETCH_SCHEDULED_QUANTITY;

  constructor(public payload: IScheduledQuantityPayload) {}
}

export class FetchScheduledQuantitySuccess implements Action {
  public readonly type = ENominationsActions.FETCH_SCHEDULED_QUANTITY_SUCCESS;

  constructor(
    public payload: {
      contractId: number;
      scheduledQuantity: Array<ScheduledQuantity>;
      totalScheduledQuantityCount: number;
    }
  ) {}
}

export class FetchScheduledQuantityFailure implements Action {
  public readonly type = ENominationsActions.FETCH_SCHEDULED_QUANTITY_FAILURE;

  constructor(public payload: { error: Error; contractId: number }) {}
}

// --------------------------------------------------
// FETCH ORDER MANAGEMENT DICTIONARY
// --------------------------------------------------
export class FetchOrderManagementDictionary implements Action {
  public readonly type = ENominationsActions.FETCH_ORDER_MANAGEMENT_DICTIONARY;

  constructor() {}
}

export class FetchOrderManagementDictionarySuccess implements Action {
  public readonly type = ENominationsActions.FETCH_ORDER_MANAGEMENT_DICTIONARY_SUCCESS;

  constructor(public payload: { dictionary: DropdownDictionary }) {}
}

export class FetchOrderManagementDictionaryFailure implements Action {
  public readonly type = ENominationsActions.FETCH_ORDER_MANAGEMENT_DICTIONARY_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// FETCH FUEL RATE
// --------------------------------------------------
export class FetchFuelRate implements Action {
  public readonly type = ENominationsActions.FETCH_FUEL_RATE;

  constructor(public payload: IFuelRatePayload) {}
}

export class FetchFuelRateSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_FUEL_RATE_SUCCESS;

  constructor(public payload: { fuelRateCollection: FuelRateResponseCollection }) {}
}

export class FetchFuelRateFailure implements Action {
  public readonly type = ENominationsActions.FETCH_FUEL_RATE_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearFuelRate implements Action {
  public readonly type = ENominationsActions.CLEAR_FUEL_RATE;

  constructor() {}
}

// --------------------------------------------------
// FETCH NOMINATION CYCLES
// --------------------------------------------------
export class FetchNominationCycles implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_CYCLES;

  constructor(public payload: IFetchNominationCyclePayload) {}
}

export class FetchNominationCyclesSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_CYCLES_SUCCESS;

  constructor(public payload: { cycles: Array<Cycle> }) {}
}

export class FetchNominationCyclesFailure implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_CYCLES_FAILURE;

  constructor(public payload: Error) {}
}

// --------------------------------------------------
// CREATE NOMINATION CYCLE OVERRIDE
// --------------------------------------------------
export class CreateNominationCyclesOverride implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE;

  constructor(public payload: CycleCollection) {}
}

export class CreateNominationCyclesOverrideSuccess implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE_SUCCESS;

  constructor(public payload: CycleCollection) {}
}

export class CreateNominationCyclesOverrideFailure implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateNominationCyclesOverrideReset implements Action {
  public readonly type = ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE_RESET;

  constructor() {}
}

// --------------------------------------------------
// FETCH CURRENT GAS DAY
// --------------------------------------------------
export class FetchCurrentGasDay implements Action {
  public readonly type = ENominationsActions.FETCH_CURRENT_GAS_DAY;

  constructor() {}
}
export class FetchCurrentGasDaySuccess implements Action {
  public readonly type = ENominationsActions.FETCH_CURRENT_GAS_DAY_SUCCESS;

  constructor(public payload: { gasDay: GasDay }) {}
}
export class FetchCurrentGasDayFailure implements Action {
  public readonly type = ENominationsActions.FETCH_CURRENT_GAS_DAY_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// FETCH SCHEDULED QUANTITIES FOR OPERATOR
// --------------------------------------------------
export class FetchOperatorScheduledQuantities implements Action {
  public readonly type = ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITIES;

  constructor(
    public payload: {
      tspId: number;
      confirmingEntityId: number;
      beginDate: string;
      endDate: string;
      cycleCode: string;
      onlyShowReductions: boolean;
      locationId: number;
    }
  ) {}
}

export class FetchOperatorScheduledQuantitiesSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITIES_SUCCESS;

  constructor(public payload: OperatorScheduledQuantityCollection) {}
}

export class FetchOperatorScheduledQuantitiesFailure implements Action {
  public readonly type = ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// FETCH SCHEDULED QUANTITY FOR OPERATOR
// --------------------------------------------------
export class FetchOperatorScheduledQuantity implements Action {
  public readonly type = ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITY;

  constructor(public payload: IOperatorScheduledQuantityPayload) {}
}

export class FetchOperatorScheduledQuantitySuccess implements Action {
  public readonly type = ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITY_SUCCESS;

  constructor(public payload: OperatorScheduledQuantityCollection) {}
}

export class FetchOperatorScheduledQuantityFailure implements Action {
  public readonly type = ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITY_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearOperatorScheduledQuantity implements Action {
  public readonly type = ENominationsActions.CLEAR_OPERATOR_SCHEDULED_QUANTITY;

  constructor() {}
}

// --------------------------------------------------
// SET SCHEDULED QUANTITY FOR OPERATOR FILTER
// --------------------------------------------------
export class SetOperatorScheduledQuantityFilter implements Action {
  public readonly type = ENominationsActions.SET_OPERATOR_SCHEDULED_QUANTITY_FILTER;

  constructor(public payload: IOperatorScheduledQuantityFilterPayload) {}
}

export class FetchTransactionTypes implements Action {
  public readonly type = ENominationsActions.FETCH_TRANSACTION_TYPES;

  constructor(public payload?: ITransactionTypesPayload) {}
}

export class FetchTransactionTypesSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_TRANSACTION_TYPES_SUCCESS;

  constructor(public payload: TransactionTypeCollection) {}
}

export class FetchTransactionTypesFailure implements Action {
  public readonly type = ENominationsActions.FETCH_TRANSACTION_TYPES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// Nomination Summary
// --------------------------------------------------
export interface IFetchNominationSummary {
  nominationId: number;
  gasDay: Date;
  cycleId: number;
}

export class FetchNominationSummary implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_SUMMARY;

  constructor(public payload: IFetchNominationSummary) {}
}

export class FetchNominationSummarySuccess implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_SUMMARY_SUCCESS;

  constructor(public payload: NominationSummary) {}
}

export class FetchNominationSummaryFailure implements Action {
  public readonly type = ENominationsActions.FETCH_NOMINATION_SUMMARY_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearNominationSummary implements Action {
  public readonly type = ENominationsActions.CLEAR_NOMINATION_SUMMARY;
}

// --------------------------------------------------
// Reduction Reasons
// --------------------------------------------------

export class FetchReductionReasons implements Action {
  public readonly type = ENominationsActions.FETCH_REDUCTION_REASONS;

  constructor() {}
}

export class FetchReductionReasonsSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_REDUCTION_REASONS_SUCCESS;

  constructor(public payload: ReductionReasonModel[]) {}
}

export class FetchReductionReasonsFailure implements Action {
  public readonly type = ENominationsActions.FETCH_REDUCTION_REASONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// Tsp Details
// --------------------------------------------------

export class FetchTspDetails implements Action {
  public readonly type = ENominationsActions.FETCH_TSP_DETAILS;

  constructor() {}
}

export class FetchTspDetailsSuccess implements Action {
  public readonly type = ENominationsActions.FETCH_TSP_DETAILS_SUCCESS;

  constructor(public payload: TspDetailCollection) {}
}

export class FetchTspDetailsFailure implements Action {
  public readonly type = ENominationsActions.FETCH_TSP_DETAILS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export type NominationsActions =
  | UploadNominations
  | UploadNominationsSuccess
  | UploadNominationsError
  | SetUploadNomminationsLoading
  | FetchNominations
  | FetchNominationsSuccess
  | FetchNominationsError
  | FetchNominationById
  | FetchNominationByIdSuccess
  | FetchNominationByIdError
  | CreateNomination
  | CreateNominationSuccess
  | CreateNominationValidationFailure
  | CreateNominationFailure
  | ClearNominations
  | ValidateNominations
  | ValidateNominationsSuccess
  | ValidateNominationsFailure
  | ClearValidateNominations
  | AddNominationWarnings
  | ClearNominationWarnings
  | FetchScheduledQuantities
  | FetchScheduledQuantitiesSuccess
  | FetchScheduledQuantitiesFailure
  | ClearScheduledQuantities
  | ExportScheduledQuantities
  | ExportScheduledQuantitiesSuccess
  | ExportScheduledQuantitiesFailure
  | ExportOperatorScheduledQuantities
  | ExportOperatorScheduledQuantitiesSuccess
  | ExportOperatorScheduledQuantitiesFailure
  | FetchScheduledQuantity
  | FetchScheduledQuantitySuccess
  | FetchScheduledQuantityFailure
  | FetchOrderManagementDictionary
  | FetchOrderManagementDictionarySuccess
  | FetchOrderManagementDictionaryFailure
  | FetchFuelRate
  | FetchFuelRateSuccess
  | FetchFuelRateFailure
  | ClearFuelRate
  | FetchNominationCycles
  | FetchNominationCyclesSuccess
  | FetchNominationCyclesFailure
  | CreateNominationCyclesOverride
  | CreateNominationCyclesOverrideSuccess
  | CreateNominationCyclesOverrideFailure
  | CreateNominationCyclesOverrideReset
  | FetchCurrentGasDay
  | FetchCurrentGasDaySuccess
  | FetchCurrentGasDayFailure
  | FetchOperatorScheduledQuantities
  | FetchOperatorScheduledQuantitiesSuccess
  | FetchOperatorScheduledQuantitiesFailure
  | FetchOperatorScheduledQuantity
  | FetchOperatorScheduledQuantitySuccess
  | FetchOperatorScheduledQuantityFailure
  | ClearOperatorScheduledQuantity
  | SetOperatorScheduledQuantityFilter
  | FetchTransactionTypes
  | FetchTransactionTypesSuccess
  | FetchTransactionTypesFailure
  | FetchNominationSummary
  | FetchNominationSummarySuccess
  | FetchNominationSummaryFailure
  | ClearNominationSummary
  | FetchReductionReasons
  | FetchReductionReasonsSuccess
  | FetchReductionReasonsFailure
  | FetchTspDetails
  | FetchTspDetailsSuccess
  | FetchTspDetailsFailure;
