import {
  ApiCollection,
  JobConfig,
  JobConfigCollection,
  JobInstance,
  JobInstanceCollection,
  JobNameCollection,
  JobStatusCollection,
} from '@gms/jobschedule-api';
import { RecurrenceType } from '@gms/jobschedule-api/model/recurrenceType';
import { Action } from '@ngrx/store';
import { IJobConfigFiltersState, IJobLogFiltersState } from 'app/store/jobs/jobs.state';

export enum EJobsActions {
  CREATE_JOB_INSTANCE = '[JOBS] Create Job Instance',
  CREATE_JOB_INSTANCE_SUCCESS = '[JOBS] Create Job Instance Success',
  CREATE_JOB_INSTANCE_ERROR = '[JOBS] Create Job Instance Error',
  UPDATE_JOB_INSTANCE = '[JOBS] Update Job Instance',
  UPDATE_JOB_INSTANCE_SUCCESS = '[JOBS] Update Job Instance Success',
  UPDATE_JOB_INSTANCE_ERROR = '[JOBS] Update Job Instance Error',
  CLEAR_JOB_INSTANCE = '[JOBS] Clear Job Instance',
  FETCH_JOB_INSTANCE = '[JOBS] Fetch Job Instance',
  FETCH_JOB_INSTANCE_SUCCESS = '[JOBS] Fetch Job Instance Success',
  FETCH_JOB_INSTANCE_ERROR = '[JOBS] Fetch Job Instance Error',

  FETCH_JOB_INSTANCES = '[JOBS] Fetch Job Instances',
  FETCH_JOB_INSTANCES_SUCCESS = '[JOBS] Fetch Job Instances Success',
  FETCH_JOB_INSTANCES_ERROR = '[JOBS] Fetch Job Instances Error',
  FETCH_JOB_NAMES = '[JOBS] Fetch Jobs Names',
  FETCH_JOB_NAMES_SUCCESS = '[JOBS] Fetch Job Names Success',
  FETCH_JOB_NAMES_ERROR = '[JOBS] Fetch Job Names Error',
  FETCH_JOB_STATUSES = '[JOBS] Fetch Job Statuses',
  FETCH_JOB_STATUSES_SUCCESS = '[JOBS] Fetch Job Statuses Success',
  FETCH_JOB_STATUSES_ERROR = '[JOBS] Fetch Job Statuses Error',

  FETCH_JOB_APIS = '[JOBS] Fetch Job Apis',
  FETCH_JOB_APIS_SUCCESS = '[JOBS] Fetch Job Apis Success',
  FETCH_JOB_APIS_ERROR = '[JOBS] Fetch Job Apis Error',

  DELETE_JOB_INSTANCE = '[JOBS] Delete Job Instance',
  DELETE_JOB_INSTANCE_SUCCESS = '[JOBS] Delete Job Instance Success',
  DELETE_JOB_INSTANCE_ERROR = '[JOBS] Delete Job Instance Error',

  CREATE_JOB_CONFIG = '[JOBS] Create Job Config',
  CREATE_JOB_CONFIG_SUCCESS = '[JOBS] Create Job Config Success',
  CREATE_JOB_CONFIG_ERROR = '[JOBS] Create Job Config Error',
  UPDATE_JOB_CONFIG = '[JOBS] Update Job Config',
  UPDATE_JOB_CONFIG_SUCCESS = '[JOBS] Update Job Config Success',
  UPDATE_JOB_CONFIG_ERROR = '[JOBS] Update Job Config Error',
  CLEAR_JOB_CONFIG = '[JOBS] Clear Job Config',

  FETCH_JOB_CONFIG = '[JOBS] Fetch Job Config',
  FETCH_JOB_CONFIG_SUCCESS = '[JOBS] Fetch Job Config Success',
  FETCH_JOB_CONFIG_ERROR = '[JOBS] Fetch Job Config Error',
  FETCH_JOB_CONFIGS = '[JOBS] Fetch Job Configs',
  FETCH_JOB_CONFIGS_SUCCESS = '[JOBS] Fetch Job Configs Success',
  FETCH_JOB_CONFIGS_ERROR = '[JOBS] Fetch Job Configs Error',

  DELETE_JOB_CONFIG = '[JOBS] Delete Job Config',
  DELETE_JOB_CONFIG_SUCCESS = '[JOBS] Delete Job Config Success',
  DELETE_JOB_CONFIG_ERROR = '[JOBS] Delete Job Config Error',

  RERUN_JOB_CONFIG = '[JOBS] Rerun Job Config',
  RERUN_JOB_CONFIG_SUCCESS = '[JOBS] Rerun Job Config Success',
  RERUN_JOB_CONFIG_ERROR = '[JOBS] Rerun Job Config Error',
  CLEAR_RERUN_JOB_CONFIG_STATUS = '[JOBS] Clear Rerun Job Config Status',

  UPDATE_JOB_CONFIG_FILTERS = '[JOBS] Update Job Config Filters',
  UPDATE_JOB_LOG_FILTERS = '[JOBS] Update Job Log Filters',
}

export interface IFetchJobInstancesPayload {
  runStart?: Date;
  runEnd?: Date;
  statusIds?: Array<number>;
  jobName?: string;
  jobInstanceIds?: Array<number>;
  jobConfigIds?: Array<number>;
}
export interface IFetchJobConfigsPayload {
  lastRanAfter?: Date;
  lastRanBefore?: Date;
  nextRunsAfter?: Date;
  nextRunsBefore?: Date;
  statusIds?: Array<number>;
  lastRunStatusIds?: Array<number>;
  configName?: string;
  jobConfigIds?: Array<number>;
  pageSize?: number;
  recurrence?: Array<RecurrenceType>;
}

export class UpdateJobInstance implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_INSTANCE;

  constructor(public payload: JobInstance) {}
}

export class UpdateJobInstanceSuccess implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_INSTANCE_SUCCESS;

  constructor(public payload: JobInstance) {}
}

export class UpdateJobInstanceError implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_INSTANCE_ERROR;

  constructor(public payload: Error) {}
}

export class ClearJobInstance implements Action {
  public readonly type = EJobsActions.CLEAR_JOB_INSTANCE;

  constructor() {}
}

export class FetchJobInstance implements Action {
  public readonly type = EJobsActions.FETCH_JOB_INSTANCE;

  constructor(public payload: number) {}
}

export class FetchJobInstanceSuccess implements Action {
  public readonly type = EJobsActions.FETCH_JOB_INSTANCE_SUCCESS;

  constructor(public payload: JobInstance) {}
}

export class FetchJobInstanceError implements Action {
  public readonly type = EJobsActions.FETCH_JOB_INSTANCE_ERROR;

  constructor(public payload: Error) {}
}
export class FetchJobInstances implements Action {
  public readonly type = EJobsActions.FETCH_JOB_INSTANCES;

  constructor(public payload: IFetchJobInstancesPayload) {}
}

export class FetchJobInstancesSuccess implements Action {
  public readonly type = EJobsActions.FETCH_JOB_INSTANCES_SUCCESS;

  constructor(public payload: { jobInstanceCollection: JobInstanceCollection }) {}
}

export class FetchJobInstancesError implements Action {
  public readonly type = EJobsActions.FETCH_JOB_INSTANCES_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchJobNames implements Action {
  public readonly type = EJobsActions.FETCH_JOB_NAMES;

  constructor(public payload: { searchPhrase: string }) {}
}

export class FetchJobNamesSuccess implements Action {
  public readonly type = EJobsActions.FETCH_JOB_NAMES_SUCCESS;

  constructor(public payload: { jobNameCollection: JobNameCollection }) {}
}

export class FetchJobNamesError implements Action {
  public readonly type = EJobsActions.FETCH_JOB_NAMES_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchJobStatuses implements Action {
  public readonly type = EJobsActions.FETCH_JOB_STATUSES;

  constructor() {}
}

export class FetchJobStatusesSuccess implements Action {
  public readonly type = EJobsActions.FETCH_JOB_STATUSES_SUCCESS;

  constructor(public payload: { jobStatusCollection: JobStatusCollection }) {}
}

export class FetchJobStatusesError implements Action {
  public readonly type = EJobsActions.FETCH_JOB_STATUSES_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchJobApis implements Action {
  public readonly type = EJobsActions.FETCH_JOB_APIS;

  constructor() {}
}

export class FetchJobApisSuccess implements Action {
  public readonly type = EJobsActions.FETCH_JOB_APIS_SUCCESS;

  constructor(public payload: ApiCollection) {}
}

export class FetchJobApisError implements Action {
  public readonly type = EJobsActions.FETCH_JOB_APIS_ERROR;

  constructor(public payload: Error) {}
}

export class CreateJobConfig implements Action {
  public readonly type = EJobsActions.CREATE_JOB_CONFIG;

  constructor(public payload: JobConfig) {}
}

export class CreateJobConfigSuccess implements Action {
  public readonly type = EJobsActions.CREATE_JOB_CONFIG_SUCCESS;

  constructor(public payload: JobConfig) {}
}

export class CreateJobConfigError implements Action {
  public readonly type = EJobsActions.CREATE_JOB_CONFIG_ERROR;

  constructor(public payload: Error) {}
}

export class UpdateJobConfig implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_CONFIG;

  constructor(public payload: JobConfig) {}
}

export class UpdateJobConfigSuccess implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_CONFIG_SUCCESS;

  constructor(public payload: JobConfig) {}
}

export class UpdateJobConfigError implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_CONFIG_ERROR;

  constructor(public payload: Error) {}
}

export class ClearJobConfig implements Action {
  public readonly type = EJobsActions.CLEAR_JOB_CONFIG;

  constructor() {}
}

export class FetchJobConfig implements Action {
  public readonly type = EJobsActions.FETCH_JOB_CONFIG;

  constructor(public payload: number) {}
}

export class FetchJobConfigSuccess implements Action {
  public readonly type = EJobsActions.FETCH_JOB_CONFIG_SUCCESS;

  constructor(public payload: JobConfig) {}
}

export class FetchJobConfigError implements Action {
  public readonly type = EJobsActions.FETCH_JOB_CONFIG_ERROR;

  constructor(public payload: Error) {}
}
export class FetchJobConfigs implements Action {
  public readonly type = EJobsActions.FETCH_JOB_CONFIGS;

  constructor(public payload: IFetchJobConfigsPayload) {}
}

export class FetchJobConfigsSuccess implements Action {
  public readonly type = EJobsActions.FETCH_JOB_CONFIGS_SUCCESS;

  constructor(public payload: { jobConfigCollection: JobConfigCollection }) {}
}

export class FetchJobConfigsError implements Action {
  public readonly type = EJobsActions.FETCH_JOB_CONFIGS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class DeleteJobConfig implements Action {
  public readonly type = EJobsActions.DELETE_JOB_CONFIG;

  constructor(public jobConfigId: number) {}
}

export class DeleteJobConfigSuccess implements Action {
  public readonly type = EJobsActions.DELETE_JOB_CONFIG_SUCCESS;

  constructor() {}
}

export class DeleteJobConfigError implements Action {
  public readonly type = EJobsActions.DELETE_JOB_CONFIG_ERROR;

  constructor(public payload: Error) {}
}

export class ClearRerunJobConfigStatus implements Action {
  public readonly type = EJobsActions.CLEAR_RERUN_JOB_CONFIG_STATUS;

  constructor() {}
}

export class RerunJobConfig implements Action {
  public readonly type = EJobsActions.RERUN_JOB_CONFIG;

  constructor(public jobConfigId: number) {}
}

export class RerunJobConfigSuccess implements Action {
  public readonly type = EJobsActions.RERUN_JOB_CONFIG_SUCCESS;

  constructor() {}
}

export class RerunJobConfigError implements Action {
  public readonly type = EJobsActions.RERUN_JOB_CONFIG_ERROR;

  constructor(public payload: Error) {}
}

export class UpdateJobConfigFilters implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_CONFIG_FILTERS;

  constructor(public payload: IJobConfigFiltersState) {}
}

export class UpdateJobLogFilters implements Action {
  public readonly type = EJobsActions.UPDATE_JOB_LOG_FILTERS;

  constructor(public payload: IJobLogFiltersState) {}
}

export type JobsActions =
  | UpdateJobInstance
  | UpdateJobInstanceSuccess
  | UpdateJobInstanceError
  | ClearJobInstance
  | FetchJobInstance
  | FetchJobInstanceSuccess
  | FetchJobInstanceError
  | FetchJobInstances
  | FetchJobInstancesSuccess
  | FetchJobInstancesError
  | FetchJobNames
  | FetchJobNamesSuccess
  | FetchJobNamesError
  | FetchJobStatuses
  | FetchJobStatusesSuccess
  | FetchJobStatusesError
  | FetchJobApis
  | FetchJobApisSuccess
  | FetchJobApisError
  | CreateJobConfig
  | CreateJobConfigSuccess
  | CreateJobConfigError
  | UpdateJobConfig
  | UpdateJobConfigSuccess
  | UpdateJobConfigError
  | ClearJobConfig
  | FetchJobConfig
  | FetchJobConfigSuccess
  | FetchJobConfigError
  | FetchJobConfigs
  | FetchJobConfigsSuccess
  | FetchJobConfigsError
  | DeleteJobConfig
  | DeleteJobConfigSuccess
  | DeleteJobConfigError
  | RerunJobConfig
  | RerunJobConfigSuccess
  | RerunJobConfigError
  | ClearRerunJobConfigStatus
  | UpdateJobConfigFilters
  | UpdateJobLogFilters;
