import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/enums';

export const Contracts_List_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/contracts/pages/contracts-list/contracts-list.module').then(
        m => m.ContractsListModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];

export const Contract_Routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/create-contract/create-contract.module').then(m => m.CreateContractModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  { path: ':id', redirectTo: ':id/overview', pathMatch: 'full' },
  {
    path: ':id/quantities',
    loadChildren: () =>
      import('./pages/contract-quantities/contract-quantities.module').then(
        m => m.ContractQuantitiesModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/quantities/edit',
    loadChildren: () =>
      import('./pages/edit-contract-quantities/edit-contract-quantities.module').then(
        m => m.EditContractQuantitiesModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/locations',
    loadChildren: () =>
      import('./pages/contract-locations/contract-locations.module').then(
        m => m.ContractLocationsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/locations/edit',
    loadChildren: () =>
      import('./pages/edit-contract-locations/edit-contract-locations.module').then(
        m => m.EditContractLocationsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/paths',
    loadChildren: () =>
      import('./pages/contract-paths/contract-paths.module').then(m => m.ContractPathsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/paths/edit',
    loadChildren: () =>
      import('./pages/edit-contract-paths/edit-contract-paths.module').then(
        m => m.EditContractPathsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/documents',
    loadChildren: () =>
      import('./pages/contract-documents/contract-documents.module').then(
        m => m.ContractDocumentsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/supplemental-restrictions',
    loadChildren: () =>
      import('./pages/contract-supplemental-restrictions/contract-supplemental-restrictions.module').then(
        m => m.ContractSupplementalRestrictionsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/edi',
    loadChildren: () =>
      import('./pages/contract-edi/contract-edi.module').then(m => m.ContractEdiModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/edi/edit',
    loadChildren: () =>
      import('./pages/edit-contract-edi/edit-contract-edi.module').then(
        m => m.EditContractEdiModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: ':id/collaboration',
    loadChildren: () =>
      import('./pages/contract-collaboration-view/contract-collaboration-view.module').then(
        m => m.ContractCollaborationViewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id/redundant-path',
    loadChildren: () =>
      import('./pages/contract-redundant-path/contract-redundant-path.module').then(
        m => m.ContractRedundantPathModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/redundant-path/edit',
    loadChildren: () =>
      import('./pages/edit-contract-redundant-path/edit-contract-redundant-path.module').then(
        m => m.EditContractRedundantPathModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: ':id/overview',
    loadChildren: () =>
      import('./pages/contract-overview/contract-overview.module').then(
        m => m.ContractOverviewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/overview/edit',
    loadChildren: () =>
      import('./pages/edit-contract-overview/edit-contract-overview.module').then(
        m => m.EditContractOverviewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contracts-list',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
];

export const GrandfatheredSupply_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/contracts/pages/grandfathered-supply-lateral-capacity/grandfathered-supply-lateral-capacity.module').then(
        m => m.GrandfatheredSupplyLateralCapacityModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contract-consolidation/{id}/overview',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];
