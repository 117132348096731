import {
  AgencyCollection,
  AgencyHeaderCollection,
  AgencyRelationshipWithEntities,
  AgencyRoleMap,
} from '@gms/entity-api';
import { createInitialDataState, ICachedDataState, IDataState } from '../app/app.models';

export interface IAgencyState {
  agencyUpdateLoading: boolean;
  agencyUpdateError: Error;
  searchedAgencies: AgencyCollection;
  searchAgenciesLoading: boolean;
  searchAgenciesError: Error;

  agency: AgencyRelationshipWithEntities;
  agencyFetchLoading: boolean;
  agencyFetchError: Error;

  agencyRoles: ICachedDataState<AgencyRoleMap[]>;

  agencyHeaders: IDataState<AgencyHeaderCollection>;
}

const initialAgencyCollection = {
  agencyRelationships: [],
  total: 0,
};

export const initialAgencyState: IAgencyState = {
  agencyUpdateLoading: false,
  agencyUpdateError: null,
  searchedAgencies: initialAgencyCollection,
  searchAgenciesError: null,
  searchAgenciesLoading: false,
  agency: null,
  agencyFetchLoading: false,
  agencyFetchError: null,
  agencyRoles: createInitialDataState(),
  agencyHeaders: createInitialDataState(),
};
