import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Rum } from 'app/rum.service';
import { IAppState } from 'app/store/app/app.state';

export let staticStore: Store<IAppState>;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'BWP GMS';

  constructor(private _store: Store<IAppState>, private rum: Rum) {
    staticStore = _store;
  }

  ngOnDestroy(): void {
    this.rum.ngOnDestroy();
  }
}
