import { ERatchetLevelsActions, RatchetLevelsActions } from './ratchet-levels.actions';
import { initialRatchetLevelsState, IRatchetLevelsState } from './ratchet-levels.state';

export function ratchetLevelsReducers(
  state = initialRatchetLevelsState,
  action: RatchetLevelsActions
): IRatchetLevelsState {
  switch (action.type) {
    case ERatchetLevelsActions.FETCH_RATCHET_LEVELS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERatchetLevelsActions.FETCH_RATCHET_LEVELS_SUCCESS:
      return {
        ...state,
        ratchetLevelTemplates: action.ratchetLevels,
        loading: false,
        error: false,
      };
    case ERatchetLevelsActions.FETCH_RATCHET_LEVELS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ERatchetLevelsActions.FETCH_RATCHET_LEVEL_COLLECTION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERatchetLevelsActions.FETCH_RATCHET_LEVEL_COLLECTION_SUCCESS:
      return {
        ...state,
        ratchetLevelCollection: action.ratchetLevelCollection,
        loading: false,
        error: false,
      };
    case ERatchetLevelsActions.FETCH_RATCHET_LEVEL_COLLECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ERatchetLevelsActions.FETCH_TARIFF_RATCHET_COLLECTION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ERatchetLevelsActions.FETCH_TARIFF_RATCHET_COLLECTION_SUCCESS:
      return {
        ...state,
        tariffRatchetCollection: action.tariffRatchetCollection,
        loading: false,
        error: false,
      };
    case ERatchetLevelsActions.FETCH_TARIFF_RATCHET_COLLECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
