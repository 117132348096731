import { CommunicationActions, ECommunicationActions } from './communication.actions';
import { ICommunicationState, initialCommunicationState } from './communication.state';

export function communicationReducers(
  state = initialCommunicationState,
  action: CommunicationActions
): ICommunicationState {
  switch (action.type) {
    case ECommunicationActions.FetchNotificationTypes:
      return {
        ...state,
        fetchNotificationTypesLoading: true,
        fetchNotificationTypesError: null,
      };
    case ECommunicationActions.FetchNotificationTypesSuccess:
      return {
        ...state,
        fetchNotificationTypesLoading: false,
        fetchNotificationTypesError: null,
        notificationTypes: action.payload.notificationTypes,
      };
    case ECommunicationActions.FetchNotificationTypesError:
      return {
        ...state,
        fetchNotificationTypesLoading: false,
        fetchNotificationTypesError: action.payload.error,
        notificationTypes: [],
      };
    default:
      return state;
  }
}
