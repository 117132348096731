import {
  AccountingAdjustment,
  AccountingAdjustmentCollection,
  AccountingAdjustmentTypeCollection,
  DefaultAccountingPeriod,
  FacilityBalance,
  Inventory,
  JeAccountingPeriodStatusCollection,
  JournalEntryCollection,
  JournalEntryDetailCollection,
  JournalEntryEbsUploadCollection,
  MissingJournalEntryRuleCollection,
  Project,
  RevenueRecognitionReviewChargeTypeCollection,
  StatementOfAccountCollection,
} from '@gms/accounting-api';
import { AccountingEngineRunStatus } from '@gms/accountingengine-api';
import { ContractCollection } from '@gms/contract-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { emptyRequestStatus, IDataState, IRequestStatus } from '../app/app.models';

export interface IAccountingState {
  pageSize: number;
  pageNumber: number;
  sortDescriptors: SortDescriptor[];
  creditTotal: number;
  debitTotal: number;
  accountingPeriodStatusCode: string;
  journalEntryCollection: JournalEntryCollection;
  journalEntryDetailCollection: JournalEntryDetailCollection;
  journalEntryEbsUploadCollection: JournalEntryEbsUploadCollection;
  journalEntryRevenueRecognitionCollection: JournalEntryEbsUploadCollection;
  missingJournalEntryRulesCollection: MissingJournalEntryRuleCollection;
  runJournalEntryStatus: AccountingEngineRunStatus;
  adjustmentsCollection: AccountingAdjustmentCollection;
  adjustmentTypesCollection: AccountingAdjustmentTypeCollection;
  accountingPeriodStatusCollection: JeAccountingPeriodStatusCollection;
  selectedAdjustment: AccountingAdjustment;
  defaultInventoryAccountPeriod: DefaultAccountingPeriod;
  // projects for view with filter condition
  projectsCollection: Array<Project>;
  inventory: Inventory;
  facilityStorage: FacilityBalance;
  unassignedContracts: ContractCollection;
  currentContractIdToAssign: number;
  revenueRecognitionCollection: RevenueRecognitionReviewChargeTypeCollection;
  getRunJournalEntryStatus: IRequestStatus<any>;
  getRunJournalEntry: IRequestStatus<any>;
  getRunJournalEntryWebsocketStatus: IRequestStatus<any>;
  getJournalEntryCollection: IRequestStatus<any>;
  exportJournalEntries: IRequestStatus<any>;
  getAdjustmentsCollection: IRequestStatus<any>;
  getAdjustmentTypesCollection: IRequestStatus<any>;
  getSelectedAdjustment: IRequestStatus<any>;
  saveSelectedAdjustment: IRequestStatus<any>;
  getPeriodStatsCollection: IRequestStatus<any>;
  selectedProject: Project;
  getSelectedProject: IRequestStatus<any>;
  saveSelectedProject: IRequestStatus<any>;
  getProjectsCollection: IRequestStatus<any>;
  getUnassignedContracts: IRequestStatus<any>;
  assignContractToProject: IRequestStatus<any>;
  updateSelectedProject: IRequestStatus<any>;
  getMissingJournalEntryRulesCollection: IRequestStatus<any>;
  getInventory: IRequestStatus<any>;
  getDefaultInventoryAccountPeriod: IRequestStatus<any>;
  getInventoryAccumulationsWebsocketStatus: { httpError: Error | string; loading: boolean };
  getFacilityStorage: IRequestStatus<any>;
  exportInventory: IRequestStatus<any>;
  getRevenueRecognitionCollection: IRequestStatus<any>;
  updateRevenueRecognition: IRequestStatus<any>;
  statementOfAccountCollection: IDataState<StatementOfAccountCollection>;
  exportStatementOfAccount: IRequestStatus<any>;
}

export const initialAccountingState = {
  pageSize: 15,
  pageNumber: 1,
  sortDescriptors: [],
  creditTotal: null,
  debitTotal: null,
  accountingPeriodStatusCode: null,
  journalEntryCollection: {
    journalEntries: [],
  },
  journalEntryDetailCollection: {
    journalEntries: [],
  },
  journalEntryEbsUploadCollection: {
    journalEntries: [],
  },
  journalEntryRevenueRecognitionCollection: {
    journalEntries: [],
  },
  missingJournalEntryRulesCollection: {
    journalEntryRules: [],
  },
  adjustmentsCollection: {
    accountingAdjustments: [],
    defaultAccountingPeriodToDisplay: null,
  },
  adjustmentTypesCollection: {
    accountingAdjustmentTypes: [],
  },
  projectsCollection: [],
  unassignedContracts: {
    contracts: [],
  },
  currentContractIdToAssign: null,
  runJournalEntryStatus: null,
  selectedAdjustment: null,
  accountingPeriodStatusCollection: null,
  inventory: null,
  defaultInventoryAccountPeriod: null,
  facilityStorage: null,
  revenueRecognitionCollection: {
    revenueRecognitionReviewChargeTypes: [],
  },
  getJournalEntryCollection: { httpError: null, loading: false },
  getRunJournalEntryStatus: { httpError: null, loading: false },
  getRunJournalEntry: { httpError: null, loading: false },
  getRunJournalEntryWebsocketStatus: { httpError: null, loading: false },
  exportJournalEntries: { httpError: null, loading: false },
  getAdjustmentsCollection: { httpError: null, loading: false },
  getAdjustmentTypesCollection: { httpError: null, loading: false },
  saveSelectedAdjustment: { httpError: null, loading: false },
  getSelectedAdjustment: { httpError: null, loading: false },
  getPeriodStatsCollection: { httpError: null, loading: false },
  selectedProject: null,
  saveSelectedProject: { httpError: null, loading: false },
  getSelectedProject: { httpError: null, loading: false },
  getProjectsCollection: { httpError: null, loading: false },
  getUnassignedContracts: { httpError: null, loading: false },
  assignContractToProject: { httpError: null, loading: false },
  updateSelectedProject: { httpError: null, loading: false },
  getMissingJournalEntryRulesCollection: { httpError: null, loading: false },
  getInventory: { httpError: null, loading: false },
  getDefaultInventoryAccountPeriod: { httpError: null, loading: false },
  getInventoryAccumulationsWebsocketStatus: { httpError: null, loading: false },
  getFacilityStorage: { httpError: null, loading: false },
  exportInventory: { httpError: null, loading: false },
  getRevenueRecognitionCollection: { httpError: null, loading: false },
  updateRevenueRecognition: { httpError: null, loading: false },
  statementOfAccountCollection: {
    data: {
      statement: null,
      paymentDetail: null,
      statementDetails: null,
    },
    requestStatus: emptyRequestStatus,
  },
  exportStatementOfAccount: { httpError: null, loading: false },
};
