import { TagColor } from 'shared/components/static-tag/tag-color.enum';

export enum ETradeStatuses {
  DRAFT = 1,
  OFFERED = 2,
  ACCEPTED = 3,
  CREDIT_PENDING = 4,
  CREDIT_HOLD = 5,
  SUBMITTED = 6,
  CANCELLED = 7,
}

export const statusToButtonIconMap = {
  [ETradeStatuses.DRAFT]: 'icon-auth-post',
  [ETradeStatuses.OFFERED]: 'icon-thumbs-up',
  [ETradeStatuses.ACCEPTED]: 'icon-paper-plane',
};

export const statusToTextMap = {
  [ETradeStatuses.DRAFT]: 'Draft',
  [ETradeStatuses.OFFERED]: 'Offered',
  [ETradeStatuses.ACCEPTED]: 'Accepted',
  [ETradeStatuses.CREDIT_PENDING]: 'Credit Pending',
  [ETradeStatuses.CREDIT_HOLD]: 'Credit Hold',
  [ETradeStatuses.SUBMITTED]: 'Submitted',
  [ETradeStatuses.CANCELLED]: 'Cancelled',
};

export const textToStatusMap = {
  Draft: [ETradeStatuses.DRAFT],
  Offered: [ETradeStatuses.OFFERED],
  Accepted: [ETradeStatuses.ACCEPTED],
  'Credit Pending': [ETradeStatuses.CREDIT_PENDING],
  'Credit Hold': [ETradeStatuses.CREDIT_HOLD],
  Submitted: [ETradeStatuses.SUBMITTED],
  Cancelled: [ETradeStatuses.CANCELLED],
};

export const statusToColorMap = {
  [ETradeStatuses.DRAFT]: TagColor.BLUE,
  [ETradeStatuses.OFFERED]: TagColor.YELLOW,
  [ETradeStatuses.ACCEPTED]: TagColor.TEAL,
  [ETradeStatuses.CREDIT_PENDING]: TagColor.PURPLE,
  [ETradeStatuses.CREDIT_HOLD]: TagColor.RED,
  [ETradeStatuses.SUBMITTED]: TagColor.GREEN,
  [ETradeStatuses.CANCELLED]: TagColor.DARK_BLUE,
};
