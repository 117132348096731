import { Action } from '@ngrx/store';

export enum EPasswordResetActions {
  ResetPassword = '[PASSWORD RESET] Reset Password',
  ResetPasswordSuccess = '[PASSWORD RESET] Reset Password Success',
  ResetPasswordFailure = '[PASSWORD RESET] Reset Password Failure',
  ClearPasswordResetState = '[PASSWORD RESET] Clear Password Reset State',
}

export class ResetPassword implements Action {
  public readonly type = EPasswordResetActions.ResetPassword;

  constructor(public payload: { userId: string }) {}
}

export class ResetPasswordSuccess implements Action {
  public readonly type = EPasswordResetActions.ResetPasswordSuccess;

  constructor() {}
}

export class ResetPasswordFailure implements Action {
  public readonly type = EPasswordResetActions.ResetPasswordFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearResetPasswordState implements Action {
  public readonly type = EPasswordResetActions.ClearPasswordResetState;

  constructor() {}
}

export type ResetPasswordActions =
  | ResetPassword
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | ClearResetPasswordState;
