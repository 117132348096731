import { HttpErrorResponse } from '@angular/common/http';
import { HttpCodes } from 'shared/consts/http-codes.const';

export function isAValidNumber(object: any){
  return !(isNullOrUndefined(object) || isNaN(object) || isArray(object) || object.toString().replace(/\s/g, '').length < 1)
}

export function isNullOrUndefined(object: any): object is null | undefined {
  return object == null;
}

export function isNull(object: any): object is null {
  return object === null;
}

export function isBoolean(object: any): object is boolean {
  return typeof object === 'boolean';
}

export function isArray<T = any>(object: any): object is T[] {
  return Array.isArray(object);
}

export function isNullOrEmptyArray(object: any[]): boolean {
  return isNullOrUndefined(object) || !object.length;
}

export function isConcurrencyError<ErrorType>(error: ErrorType): boolean {
  return error instanceof HttpErrorResponse ? error.status === HttpCodes.CONFLICT : false;
}

export function isNullOrEmptyString(object: string): boolean {
  return isNull(object) || object === '';
}

export function objectUnlessNull(obj1: any, obj2: any): object {
  if (isNullOrEmptyString(obj1) || isNullOrUndefined(obj1)) {
    return obj2;
  }
  return obj1;
}

/**
 * This should be used as an input to the RxJS filter() function in order to filter out 409 responses.
 * Add this to your pipe chain that handles error responses in components.
 *
 * ex: filter(filterConcurrencyError);
 *
 * @param {Error} error
 * @returns {boolean} Will be false if the Error is an HttpErrorResponse 409 concurrency conflict
 */
export function filterConcurrencyError<ErrorType>(error: ErrorType): boolean {
  return !isConcurrencyError(error);
}
