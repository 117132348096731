import { LookupCollections } from '@gms/common-api';
import { Action } from '@ngrx/store';

export enum ELookupActions {
  FETCH_LOOKUP_COLLECTIONS = '[LOOKUP] Fetch Lookup Collections',
  FETCH_LOOKUP_COLLECTIONS_SUCCESS = '[LOOKUP] Fetch Lookup Collections Success',
  FETCH_LOOKUP_COLLECTIONS_FAILURE = '[LOOKUP] Fetch Lookup Collections Failure',
}

export class FetchLookupCollections implements Action {
  public readonly type = ELookupActions.FETCH_LOOKUP_COLLECTIONS;

  constructor() {}
}

export class FetchLookupCollectionsSuccess implements Action {
  public readonly type = ELookupActions.FETCH_LOOKUP_COLLECTIONS_SUCCESS;

  constructor(public payload: LookupCollections) {}
}

export class FetchLookupCollectionsFailure implements Action {
  public readonly type = ELookupActions.FETCH_LOOKUP_COLLECTIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export type LookupActions =
  | FetchLookupCollections
  | FetchLookupCollectionsSuccess
  | FetchLookupCollectionsFailure;
