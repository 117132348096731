import { Injectable } from '@angular/core';
import { LookupService } from '@gms/common-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ELookupActions,
  FetchLookupCollections,
  FetchLookupCollectionsFailure,
  FetchLookupCollectionsSuccess,
} from './lookup.actions';

@Injectable()
export class LookupEffects {
  constructor(private _actions$: Actions, private _lookupService: LookupService) {}

  fetchLookupCollections: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchLookupCollections>(ELookupActions.FETCH_LOOKUP_COLLECTIONS),
      switchMap(action => {
        return this._lookupService.getLookup().pipe(
          map(lookupCollections => new FetchLookupCollectionsSuccess(lookupCollections)),
          catchError(error => of(new FetchLookupCollectionsFailure({ error: error })))
        );
      })
    )
  );
}
