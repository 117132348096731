import {
  MergerAssignment,
  MergerAssignmentCollection,
  MergerAssignmentInput,
} from '@gms/entity-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export enum EMergerAssignmentActions {
  SEARCH_MERGER_ASSIGNMENTS = '[MERGER-ASSIGNMENT] Search Merger/Assignments',
  SEARCH_MERGER_ASSIGNMENTS_SUCCESS = '[MERGER-ASSIGNMENT] Search Merger/Assignments Success',
  SEARCH_MERGER_ASSIGNMENTS_FAILURE = '[MERGER-ASSIGNMENT] Search Merger/Assignments Failure',
  FETCH_MERGER_ASSIGNMENT_BY_ID = '[MERGER-ASSIGNMENT] Fetch Merger Assignment By Id',
  FETCH_MERGER_ASSIGNMENT_BY_ID_SUCCESS = '[MERGER-ASSIGNMENT] Fetch Merger Assignment By Id Success',
  FETCH_MERGER_ASSIGNMENT_BY_ID_FAILURE = '[MERGER-ASSIGNMENT] Fetch Merger Assignment By Id Failure',
  ADD_MERGER_ASSIGNMENT = '[MERGER-ASSIGNMENT] Add Merger Assignment',
  ADD_MERGER_ASSIGNMENT_SUCCESS = '[MERGER-ASSIGNMENT] Add Merger Assignment Success',
  ADD_MERGER_ASSIGNMENT_FAILURE = '[MERGER-ASSIGNMENT] Add Merger Assignment Failure',
  RESET_MERGER_ASSIGNMENT = '[MERGER-ASSIGNMENT] Reset Merger Assignment',
}

export class SearchMergerAssignments implements Action {
  public readonly type = EMergerAssignmentActions.SEARCH_MERGER_ASSIGNMENTS;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortBy?: SortDescriptor[];
      ids?: number[];
    }
  ) {}
}

export class SearchMergerAssignmentsSuccess implements Action {
  public readonly type = EMergerAssignmentActions.SEARCH_MERGER_ASSIGNMENTS_SUCCESS;

  constructor(public payload: MergerAssignmentCollection) {}
}

export class SearchMergerAssignmentsFailure implements Action {
  public readonly type = EMergerAssignmentActions.SEARCH_MERGER_ASSIGNMENTS_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchMergerAssignmentById implements Action {
  public readonly type = EMergerAssignmentActions.FETCH_MERGER_ASSIGNMENT_BY_ID;

  constructor(public mergerAssignmentId: number) {}
}

export class FetchMergerAssignmentByIdSuccess implements Action {
  public readonly type = EMergerAssignmentActions.FETCH_MERGER_ASSIGNMENT_BY_ID_SUCCESS;

  constructor(public payload: MergerAssignment) {}
}

export class FetchMergerAssignmentByIdFailure implements Action {
  public readonly type = EMergerAssignmentActions.FETCH_MERGER_ASSIGNMENT_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

export class AddMergerAssignment implements Action {
  public readonly type = EMergerAssignmentActions.ADD_MERGER_ASSIGNMENT;

  constructor(public payload: MergerAssignmentInput) {}
}

export class AddMergerAssignmentSuccess implements Action {
  public readonly type = EMergerAssignmentActions.ADD_MERGER_ASSIGNMENT_SUCCESS;

  constructor(public payload: MergerAssignment) {}
}

export class AddMergerAssignmentFailure implements Action {
  public readonly type = EMergerAssignmentActions.ADD_MERGER_ASSIGNMENT_FAILURE;

  constructor(public payload: Error) {}
}

export class ResetMergerAssignment implements Action {
  public readonly type = EMergerAssignmentActions.RESET_MERGER_ASSIGNMENT;
}

export type MergerAssignmentActions =
  | SearchMergerAssignments
  | SearchMergerAssignmentsSuccess
  | SearchMergerAssignmentsFailure
  | FetchMergerAssignmentById
  | FetchMergerAssignmentByIdSuccess
  | FetchMergerAssignmentByIdFailure
  | AddMergerAssignment
  | AddMergerAssignmentSuccess
  | AddMergerAssignmentFailure
  | ResetMergerAssignment;
