export const IMBALANCE_LABELS = {
  EST_FINAL_END_STOR_BAL: 'EST FINAL END STOR BAL',
  EST_IMB_ACCT_ROLLOVER: 'EST IMB ACCT ROLLOVER',
  EXPORT: 'Export',
  QUANTITY: 'QUANTITY',
  OR: 'OR',
  STORAGE: 'Storage',
  IMBALANCE: 'Imbalance',
  SVC_REQ_NAME: 'SVC REQ NAME',
  FROM_INIT_TRADER: 'FROM Init Trdr',
  TO_INIT_TRADER: 'TO Init Trdr',
  SVC_REQ_NUMBER_AND_NAME: 'SVC REQ/NAME',

  IMBALANCE_TRADE: 'Imbalance Trade',
  INITIATE_IMBALANCE_TRADE: 'Initiate Imbalance Trade',
  CONFIRM_IMBALANCE_TRADE: 'Confirm Imbalance Trade',
  TRADE_DETAILS: 'Trade Details',
  INITIATING_TRADER: 'Initiating Trader',
  CONFIRMING_TRADER: 'Confirming Trader',
  ACCOUNT_PERIOD: 'ACCT PER',
  STATUS: 'STATUS',
  IMB_TRD_TRK_ID: 'IMB TRD TRK ID',
  TRD_QTY_REQ: 'TRD QTY REQ',
  IMB_TRD_DIR_DESC: 'IMB TRD DIR DESC',
  SUBMIT_TRADE_ERROR: 'Error: Failed to create a new trade. Please address all errors to continue.',
  ACCEPT_TRADE_ERROR: 'Error: Failed to accept the trade. Please address all errors to continue.',
  REJECT_TRADE_ERROR: 'Error: Failed to reject the trade. Please address all errors to continue.',

  DTL_IMB_TRD_DIR_DESC: 'Imb Trd Dir Desc',
  DTL_TRD_QTY_REQ: 'Trd Qty Req',
  DTL_NOTE: 'Notes (Optional)',
  TRD_FUEL_QTY_INIT: 'Trd Fuel Qty-Init Trdr',
  TRD_FUEL_QTY_CONF: 'Trd Fuel Qty-Conf Trdr',
  TRD_FUEL_PERCENTAGE: 'Trd Fuel Pct',
  TRD_FUEL_RATE: 'Trd Fuel Rate',
  NET_TRD_QTY: 'Net Trd Qty',

  K_HOLDER_INIT_TRDR_NAME: 'K HOLDER-INIT TRDR NAME',
  K_HOLDER_CONF_TRDR_NAME: 'K HOLDER-CONF TRDR NAME',
  INIT_TRDR_NAME: 'INIT TRDR NAME',
  CONF_TRDR_NAME: 'CONF TRDR NAME',
  INIT_TRDR_ACCT_TYPE: 'INIT TRDR ACCT TYPE',
  CONF_TRDR_ACCT_TYPE: 'CONF TRDR ACCT TYPE',
  ACCT_INIT_TRDR: 'ACCT-INIT TRDR',
  ACCT_CONF_TRDR: 'ACCT-CONF TRDR',
  IMB_TYPE_INIT_TRDR: 'IMB TYPE-INIT TRDR',
  IMB_TYPE_CONF_TRDR: 'IMB TYPE-CONF TRDR',
  OVERALL_PIPE_LINE_POSITION: 'OVERALL P/L POS',
  CURRENT_IMBALANCE_QTY: 'CUR IMB QTY',

  STORAGE_INFORMATION: 'Storage Information',
  VIEW_BY: 'VIEW BY',
  BEGIN_DATE: 'BEG DATE',
  END_DATE: 'END DATE',
  SUMMARY: 'Summary',
  SUMMARYGS: 'SummaryGS',
  DETAIL: 'Detail',
  DETAILGS: 'DetailGS',
  CONTACT_NAME: 'Contact Name',
  CONTACT_PHONE: 'Contact phone',
  STATEMENT_DATE_TIME: 'STMT D/T',
  REPORT_TYPE: 'Report Type Description',
  ACTIVITY: 'Activity',
  SVC_REQ_K: 'SVC REQ K',
  STORAGE_RATE_SCHEDULE: 'STOR RATE SCH',
  END_STORAGE_BALANCE: 'END STOR BAL',
  BEGIN_STORAGE_BALANCE: 'BEG STOR BAL',
  MAX_STORAGE_CAP: 'MAX STOR CAP',
  PERCENT_FULL: '% FULL',
  ALLOC_REC_QTY: 'ALLOC REC QTY',
  ALLOC_FUEL_QTY: 'FUEL QTY',
  ALLOC_DEL_QTY: 'ALLOC DEL QTY',
  TRANSACTION_TYPE_DESCRIPTION: 'TRANSACTION TYPE DESCRIPTION',
  STORAGE_INJECTION: 'STOR INJ',
  STORAGE_WITHDRAWAL: 'STOR W/D',
  TRANSFER_INJECTION: 'XFER INJ',
  TRANSFER_WITHDRAWAL: 'XFER W/D',
  MAX_ADJUSTMENT_INJECTION_QTY: 'MAX AD INJ QTY',
  INTERR_ADJUSTMENT_INJECTION_QTY: 'INTERR AD INJ QTY',
  MAX_ADJUSTMENT_WITHDRAWAL_QTY: 'MAX AD W/D QTY',
  INTERR_ADJUSTMENT_WITHDRAWAL_QTY: 'INTERR AD W/D QTY',

  AUTHORIZATION_TO_POST_IMBALANCES: 'Authorization to Post Imbalances',
  K_HOLDER_NUMBER_AND_NAME: 'K HOLDER/NAME',
  AUTH_EFFECTIVE_DATE: 'AUTH EFF DATE',
  AUTH_SVC_REQ_ACCT: 'AUTH SVC REQ ACCT',
  SVC_REQ_CONTACT: 'SVC REQ CONTACT',
  SVC_REQ_PHONE: 'SVC REQ PHONE',
  AUTH_INDICATOR: 'AUTH IND',

  WITHDRAW_OVERRIDE_MESSAGE:
    'You are about to override this accepted transfer and withdraw it. Please provide a reason for this action.',
  WITHDRAW_CONFIRM_MESSAGE:
    'You are about to withdraw this trade. Are you sure you want to proceed with this action?',
  OVERRIDE_COMMENTS_TITLE: 'Override Comments',

  OBA_SUMMARY_PAGE_TITLE: 'Operational Balancing Summary',
  OBA_SUMMARY_NO_DATA_MAIN_MESSAGE:
    'There are currently no operational balancing agreements for the selected criteria.',
  OBA_SUMMARY_ERROR_AND_NOTICE_PANEL_MAIN_MESSAGE:
    'Please select the desired date range (begin/end) or accounting period (begin/end).',
  OBA_SUMMARY_CONTRACT_TYPE_OBA_BO: 'OBA-BO',

  OBA_SUMMARY_ACCOUNT_PERIOD_END: 'END ACCT PER',
  OBA_SUMMARY_ACCOUNT_PERIOD_START: 'BEG ACCT PER',
  OBA_SUMMARY_ACCOUNTING_PERIOD: 'ACCT PER',
  OBA_SUMMARY_ADJUSTMENT_QTY: 'ACCT ADJ QTY',
  OBA_SUMMARY_AFTER_CASH_OUT: 'AFTER CASH OUT',
  OBA_SUMMARY_AFTER_CO_ENDING_BALANCE_QTY: 'END BAL QTY',
  OBA_SUMMARY_ALLOCATED_DEL_QTY: 'ALLOC DEL QTY',
  OBA_SUMMARY_ALLOCATED_REC_QTY: 'ALLOC REC QTY',
  OBA_SUMMARY_BEFORE_CASH_OUT: 'BEFORE CASH OUT',
  OBA_SUMMARY_BEFORE_CO_ENDING_BALANCE_QTY: 'END BAL QTY',
  OBA_SUMMARY_BEGIN_BALANCE_QTY: 'BEG BAL QTY',
  OBA_SUMMARY_BEGIN_DATE: 'BEG DATE',
  OBA_SUMMARY_CASH_OUT: 'CASH OUT',
  OBA_SUMMARY_CO_AMOUNT: 'AMOUNT',
  OBA_SUMMARY_CO_QTY: 'QTY',
  OBA_SUMMARY_OVERRIDE_QTY: 'OVERRIDE QTY',
  OBA_SUMMARY_CO_RATE: 'RATE',
  OBA_SUMMARY_CONTRACT_IDS: 'SVC REQ K',
  OBA_SUMMARY_DATE_BEGIN: 'BEG DATE',
  OBA_SUMMARY_DATE_END: 'END DATE',
  OBA_SUMMARY_END_DATE: 'END DATE',
  OBA_SUMMARY_ENTITY_IDS: 'RECIPIENT NAME',
  OBA_SUMMARY_IN_KIND_QTY: 'IN-KIND QTY',
  OBA_SUMMARY_IN_KIND_THRESHOLD_AMOUNT: 'IN-KIND THOLD AMT',
  OBA_SUMMARY_IN_KIND_THRESHOLD_PERCENTAGE: 'IN-KIND THOLD %',
  OBA_SUMMARY_K_HOLDER: 'K HOLDER NAME',
  OBA_SUMMARY_MANUAL_ADJUSTMENT_QTY: 'MAN ADJ QTY',
  OBA_SUMMARY_OBA_XFER_QTY: 'OBA XFER QTY',
  OBA_SUMMARY_OBA_CUR_MTH_IMB_QTY: 'CUR MTH IMB QTY',
  OBA_SUMMARY_NET_SCHEDULED_QTY: 'NET SCHED QTY',
  OBA_SUMMARY_RATE_SCHEDULE: 'K TYPE',
  OBA_SUMMARY_SETTLEMENT_METHOD: 'STLMNT MTHD',
  OBA_SUMMARY_SETTLEMENT_PORTION: 'STLMNT PORTION',
  OBA_SUMMARY_SETTLEMENT_TIMING: 'STLMNT TIMING',
  OBA_SUMMARY_SVC_REQ_K: 'SVC REQ K',

  OBA_SUMMARY_ADJUSTMENT_QTY_TOOLTIP:
    'Negative balance is Due TO TSP and positive balance is Due FROM TSP.',
  OBA_SUMMARY_AFTER_CO_ENDING_BALANCE_QTY_TOOLTIP:
    'Negative balance is Due TO TSP and positive balance is Due FROM TSP.',
  OBA_SUMMARY_BEFORE_CO_ENDING_BALANCE_QTY_TOOLTIP:
    'Negative balance is Due TO TSP and positive balance is Due FROM TSP.',
  OBA_SUMMARY_CO_QTY_TOOLTIP:
    'Negative amount is a credit on the TSP invoice and positive amount is a charge on the TSP invoice.',
  OBA_SUMMARY_IN_KIND_QTY_TOOLTIP:
    'Equals Total Net Scheduled Quantity times the In-Kind Threshold %',
  OBA_SUMMARY_RATE_SCHEDULE_TOOLTIP:
    'An IOB is for internal accounting purposes only and is not a Service Requester Contract.',
};
