import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';

export interface IDecodedAccessToken {
  iat: number;
  exp: number;
}

export const decodeToken = (token: string): IDecodedAccessToken => {
  if (!token) {
    return null;
  }

  return JSON.parse(window.atob(token.split('.')[1]));
};

export function isTokenExpired(token: string): boolean {
  if (isNullOrUndefined(token)) {
    return true;
  }

  // This needs to be machine time, do NOT convert to central
  const today = new Date();
  const expDate = new Date(tokenExpiredTime(token));
  return dateUtils.compare(expDate, today) < 0;
}

export function tokenExpiredTime(token: string): number {
  if (isNullOrUndefined(token)) {
    return null;
  }

  const { exp } = decodeToken(token);
  return exp * 1000;
}
