import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/app/app.state';
import { LogOut, Refresh, RefreshLogoutTime } from 'app/store/auth/auth.actions';
import { selectAccessTokenExpiredTime, selectIsAccessTokenExpired, selectIsAuthenticated, selectLogoutTime } from 'app/store/auth/auth.selectors';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';

const MAX_ALLOWED_TOKEN_EXPIRED = 30 * 60 * 1000;
export const AuthGuard: CanActivateFn = () => {
  const _store = inject( Store<IAppState>);
   let accessGranted$: Observable<boolean>;

    const isAuthenticated$ = _store.pipe(
      select(selectIsAuthenticated),
      first()
    );

    const isTokenExpired$ = _store.pipe(
      select(selectIsAccessTokenExpired),
      first()
    );

    const logoutTime$ = _store.pipe(
      select(selectLogoutTime),
      first()
    );

    const tokenExpiredTime$ = _store.pipe(
      select(selectAccessTokenExpiredTime),
      first()
    );

    combineLatest([isAuthenticated$, isTokenExpired$, logoutTime$, tokenExpiredTime$]).subscribe(
      ([isAuthenticated, isTokenExpired, logoutTime, tokenExpiredTime]) => {
        accessGranted$ = isAccessGranted(isAuthenticated, isTokenExpired, logoutTime, tokenExpiredTime);
      }
    );
    return accessGranted$;
};

export const isAccessGranted = (isAuthenticated: boolean, isTokenExpired: boolean, logoutTime: number, tokenExpiredTime: number) =>  {
  const _store = inject( Store<IAppState>);
  let accessGranted$: Observable<boolean>;
  const now = new Date();
  const isTokenExpiredWithinAllowedTime =
    now.getTime() < tokenExpiredTime + MAX_ALLOWED_TOKEN_EXPIRED;

  if (isAuthenticated && !isTokenExpired) {
    accessGranted$ = of(true);
  } else if (
    isAuthenticated &&
    isTokenExpired &&
    logoutTime > now.getTime() &&
    isTokenExpiredWithinAllowedTime
  ) {
    accessGranted$ = doRefreshToken(_store);
  } else {
    console.error(
      'AuthGuard isAuthenticated false or isAuthenticated and logout time less than now'
    );
    _store.dispatch(new LogOut());
    accessGranted$ = of(false);
  }

  return accessGranted$;

}


export const doRefreshToken = (_store: Store<any>)=> {
  _store.dispatch(new Refresh());
  _store.dispatch(new RefreshLogoutTime());

  return _store.pipe(
    select(selectIsAuthenticated),
    first(),
    tap(isStillAuthenticated => {
      if (!isStillAuthenticated) {
        console.error('AuthGuard Authentication Lapse Logout');
        _store.dispatch(new LogOut());
      }
    }),
    catchError(err => {
      console.error('AuthGuard error Logout');
      _store.dispatch(new LogOut());
      return throwError(err);
    })
  );
}
