import {
  clearState,
  createCacheableSuccessState,
  createErrorState,
  createLoadingState,
  createSuccessState,
  useCache,
} from 'app/store/app/app.models';
import { EUsersActions, UsersActions } from './users.actions';
import { initialUsersState, IUsersState } from './users.state';

export function usersReducers(state = initialUsersState, action: UsersActions): IUsersState {
  switch (action.type) {
    case EUsersActions.FetchUsers:
      return {
        ...state,
        error: null,
        loading: true,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
      };

    case EUsersActions.FetchUsersSuccess:
      return {
        ...state,
        error: null,
        loading: false,
        totalUserCount: action.payload.totalUserCount,
        users: action.payload.users,
      };

    case EUsersActions.FetchUsersFailure:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        totalUserCount: null,
        users: null,
      };

    case EUsersActions.FetchUserById:
      return {
        ...state,
        selectedUserById: null,
        selectedUserByIdLoading: true,
        selectedUserByIdError: null,
      };

    case EUsersActions.FetchUserByIdSuccess:
      return {
        ...state,
        selectedUserById: action.payload.user,
        selectedUserByIdLoading: false,
        selectedUserByIdError: null,
      };

    case EUsersActions.FetchUserByIdFailure:
      return {
        ...state,
        selectedUserById: null,
        selectedUserByIdLoading: false,
        selectedUserByIdError: action.payload.error,
      };

    case EUsersActions.SelectUser:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case EUsersActions.DeselectUser:
      return {
        ...state,
        selectedUser: null,
      };

    case EUsersActions.UpdateUserProfile:
    case EUsersActions.UpdateUser:
    case EUsersActions.CreateUser:
      return {
        ...state,
        userUpdateLoading: true,
        userUpdateError: null,
      };

    case EUsersActions.UpdateUserProfileSuccess:
    case EUsersActions.UpdateUserSuccess:
    case EUsersActions.CreateUserSuccess:
      return {
        ...state,
        userUpdateLoading: false,
      };

    case EUsersActions.UpdateUserProfileFailure:
    case EUsersActions.UpdateUserFailure:
    case EUsersActions.CreateUserFailure:
      return {
        ...state,
        userUpdateLoading: false,
        userUpdateError: action.payload,
      };
    case EUsersActions.ClearUserUpdateError:
      return {
        ...state,
        userUpdateError: null,
      };
    case EUsersActions.SearchUsers:
      return {
        ...state,
        usersSearchLoading: true,
      };
    case EUsersActions.SearchUsersSuccess:
      return {
        ...state,
        usersSearch: action.payload.users,
        usersSearchLoading: false,
        usersSearchError: null,
      };
    case EUsersActions.SearchUsersFailure:
      return {
        ...state,
        usersSearchLoading: false,
        usersSearchError: action.payload.error,
      };
    case EUsersActions.ClearSearchedUsers:
      return {
        ...state,
        usersSearch: [],
      };
    case EUsersActions.FetchRoles:
      if (useCache(state.roles, action)) return state;

      return {
        ...state,
        roles: createLoadingState(state.roles),
      };
    case EUsersActions.FetchRolesFailure:
      return {
        ...state,
        roles: createErrorState(action.payload.error),
      };
    case EUsersActions.FetchRolesSuccess:
      return {
        ...state,
        roles: createCacheableSuccessState(action.payload),
      };
    case EUsersActions.FetchRolesPermissionOptions:
      if (useCache(state.rolesPermissionOptions, action)) return state;

      return {
        ...state,
        rolesPermissionOptions: createLoadingState(state.rolesPermissionOptions),
      };
    case EUsersActions.FetchRolesPermissionOptionsFailure:
      return {
        ...state,
        rolesPermissionOptions: createErrorState(action.payload.error),
      };
    case EUsersActions.FetchRolesPermissionOptionsSuccess:
      return {
        ...state,
        rolesPermissionOptions: createCacheableSuccessState(action.payload.rolesPermissionOptions),
      };
    case EUsersActions.FetchContactLevels:
      if (useCache(state.contactLevels, action)) return state;

      return {
        ...state,
        contactLevels: createLoadingState(state.contactLevels),
      };
    case EUsersActions.FetchContactLevelsFailure:
      return {
        ...state,
        contactLevels: createErrorState(action.payload.error),
      };
    case EUsersActions.FetchContactLevelsSuccess:
      return {
        ...state,
        contactLevels: createCacheableSuccessState(action.payload.contactLevels),
      };
    case EUsersActions.FetchUserPhoneNumberTypes:
      if (useCache(state.phoneNumberTypes, action)) return state;

      return {
        ...state,
        phoneNumberTypes: createLoadingState(state.phoneNumberTypes),
      };
    case EUsersActions.FetchUserPhoneNumberTypesFailure:
      return {
        ...state,
        phoneNumberTypes: createErrorState(action.payload.error),
      };
    case EUsersActions.FetchUserPhoneNumberTypesSuccess:
      return {
        ...state,
        phoneNumberTypes: createCacheableSuccessState(action.payload.phoneNumberTypes),
      };
    case EUsersActions.FetchUserEmailTypes:
      if (useCache(state.emailTypes, action)) return state;

      return {
        ...state,
        emailTypes: createLoadingState(state.emailTypes),
      };
    case EUsersActions.FetchUserEmailTypesFailure:
      return {
        ...state,
        emailTypes: createErrorState(action.payload.error),
      };
    case EUsersActions.FetchUserEmailTypesSuccess:
      return {
        ...state,
        emailTypes: createCacheableSuccessState(action.payload.emailTypes),
      };
    case EUsersActions.FetchContactInfo:
      return {
        ...state,
        contactInfoCollection: createLoadingState(state.contactInfoCollection),
      };
    case EUsersActions.FetchContactInfoSuccess:
      return {
        ...state,
        contactInfoCollection: createSuccessState(action.payload),
      };
    case EUsersActions.FetchContactInfoFailure:
      return {
        ...state,
        contactInfoCollection: createErrorState(action.payload.error),
      };
    case EUsersActions.ClearContactInfo:
      return {
        ...state,
        contactInfoCollection: clearState(state.contactInfoCollection),
      };
    case EUsersActions.FetchContactInfoSearch:
      return {
        ...state,
        contactInfoCollectionSearch: createLoadingState(state.contactInfoCollectionSearch),
      };
    case EUsersActions.FetchContactInfoSearchSuccess:
      return {
        ...state,
        contactInfoCollectionSearch: createSuccessState(action.payload),
      };
    case EUsersActions.FetchContactInfoSearchFailure:
      return {
        ...state,
        contactInfoCollectionSearch: createErrorState(action.payload.error),
      };
    default:
      return state;
  }
}
