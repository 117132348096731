import { HttpErrorResponse } from '@angular/common/http';
import { Action as WorkflowAction, WorkflowTask, WorkflowTaskCollection } from '@gms/workflow-api';
import { Action } from '@ngrx/store';
import { EServiceRequestActions } from '../service-requests/service-requests.actions';

export enum EWorkflowTaskAction {
  GetTasks = '[WORKFLOW_TASK_ACTION] Get Tasks',
  GetTasksSuccess = '[WORKFLOW_TASK_ACTION] Get Tasks Success',
  GetTasksError = '[WORKFLOW_TASK_ACTION] Get Tasks Error',
  GetTaskByDomainId = '[WORKFLOW_TASK_ACTION] Get Task By DomainId',
  GetTaskByDomainIdSuccess = '[WORKFLOW_TASK_ACTION] Get Task By DomainId Success',
  GetTaskByDomainIdError = '[WORKFLOW_TASK_ACTION] Get Task By DomainId Error',
  UpdateTaskActionByDomainId = '[WORKFLOW_TASK_ACTION] Update Task Action By DomainId',
  UpdateTaskActionByDomainIdSuccess = '[WORKFLOW_TASK_ACTION] Update Task Action By DomainId Success',
  UpdateTaskActionByDomainIdError = '[WORKFLOW_TASK_ACTION] Update Task Action By DomainId Error',
  ClearTaskActionState = '[WORKFLOW_TASK_ACTION] Clear Task Action State from local store',
}

export interface IWorkflowTaskActionGetTasksPayload {
  pageSize?: number;
  pageNumber?: number;
  domainName?: string;
  userId?: number;
}

export interface IWorkflowTaskActionGetTaskByDomainIdPayload {
  domainCaseName: string;
  domainId: string;
}
export interface IWorkflowTaskActionUpdateTaskActionByDomainIdPayload {
  domainCaseName: string;
  domainId: string;
  body: WorkflowAction;
}

export class GetTasks implements Action {
  public readonly type = EWorkflowTaskAction.GetTasks;

  constructor(public payload: IWorkflowTaskActionGetTasksPayload) {}
}

export class GetTasksSuccess implements Action {
  public readonly type = EWorkflowTaskAction.GetTasksSuccess;

  constructor(public tasks: WorkflowTaskCollection) {}
}

export class GetTasksError implements Action {
  public readonly type = EWorkflowTaskAction.GetTasksError;

  constructor(public error: HttpErrorResponse) {}
}

export class GetTaskByDomainId implements Action {
  public readonly type = EWorkflowTaskAction.GetTaskByDomainId;

  constructor(public payload: IWorkflowTaskActionGetTaskByDomainIdPayload) {}
}

export class GetTaskByDomainIdSuccess implements Action {
  public readonly type = EWorkflowTaskAction.GetTaskByDomainIdSuccess;

  constructor(public task: WorkflowTask) {}
}

export class GetTaskByDomainIdError implements Action {
  public readonly type = EWorkflowTaskAction.GetTaskByDomainIdError;

  constructor(public error: HttpErrorResponse) {}
}

export class ClearTaskActionState implements Action {
  public readonly type = EWorkflowTaskAction.ClearTaskActionState;
}

export class UpdateTaskActionByDomainId implements Action {
  public readonly type = EWorkflowTaskAction.UpdateTaskActionByDomainId;

  constructor(public payload: IWorkflowTaskActionUpdateTaskActionByDomainIdPayload) {}
}
export class UpdateTaskActionByDomainIdSuccess implements Action {
  public readonly type = EWorkflowTaskAction.UpdateTaskActionByDomainIdSuccess;

  constructor(public task: WorkflowTask) {}
}
export class UpdateTaskActionByDomainIdError implements Action {
  public readonly type = EWorkflowTaskAction.UpdateTaskActionByDomainIdError;

  constructor(public error: HttpErrorResponse) {}
}

export type WorkflowTaskActions =
  | GetTasks
  | GetTasksSuccess
  | GetTasksError
  | GetTaskByDomainId
  | GetTaskByDomainIdSuccess
  | GetTaskByDomainIdError
  | ClearTaskActionState
  | UpdateTaskActionByDomainId
  | UpdateTaskActionByDomainIdSuccess
  | UpdateTaskActionByDomainIdError;
