import {
  StorageTransferDetail,
  StorageTransferDetailCollection,
  StorageTransferDetailPair,
  StorageTransferNnsOffer,
  StorageTransferTCETQCollection,
} from '@gms/imbalance-api';
import { IAccountPeriod } from 'app/modules/accounting/utils/journal-entries-utils';
import {
  createSuccessState,
  emptyRequestStatus,
  IDataState,
  IRequestStatus,
} from 'app/store/app/app.models';
import { EStorageTransferActions } from 'app/store/storage-transfer/storage-transfer.utils';

export interface IStorageTransferState {
  accountPeriod: IAccountPeriod;

  // STORAGE TRANSFER //
  storageTransferCollection: StorageTransferDetailCollection;
  getStorageTransferCollection: IRequestStatus<any>;

  storageTransfer: StorageTransferDetail;
  storageTransferNNS: StorageTransferDetailPair;
  createStorageTransferNNS: IRequestStatus<any>;
  createStorageTransfer: IRequestStatus<any>;
  getStorageTransferById: IRequestStatus<any>;
  updateStorageTransfer: {
    reqStatus: IRequestStatus<any>;
    actionFlag: EStorageTransferActions;
  };
  withdrawStorageTransfer: IRequestStatus<any>;

  // PARK AND LOAN
  parkAndLoanCollection: any;
  getParkAndLoanCollection: IRequestStatus<any>;
  exportParkAndLoan: IRequestStatus<any>;

  // STORAGE TCETQ
  storageTcetqCollection: IDataState<StorageTransferTCETQCollection>;
  offers: IDataState<Array<StorageTransferNnsOffer>>;
  offersNNSLoading: boolean;
}

export const initialStorageTransferState: IStorageTransferState = {
  accountPeriod: null,
  storageTransferCollection: {
    storageTransfers: null,
    total: null,
  },
  storageTransfer: null,
  storageTransferNNS: null,

  getStorageTransferCollection: emptyRequestStatus,
  createStorageTransfer: emptyRequestStatus,
  createStorageTransferNNS: emptyRequestStatus,
  getStorageTransferById: emptyRequestStatus,
  updateStorageTransfer: {
    reqStatus: emptyRequestStatus,
    actionFlag: null,
  },
  withdrawStorageTransfer: emptyRequestStatus,

  parkAndLoanCollection: {
    parkAndLoans: [],
    total: null,
  },
  getParkAndLoanCollection: emptyRequestStatus,
  exportParkAndLoan: emptyRequestStatus,

  storageTcetqCollection: {
    data: {
      storageTransfers: null,
      total: null,
    },
    requestStatus: emptyRequestStatus,
  },
  offers: createSuccessState([]),
  offersNNSLoading: false,
};
