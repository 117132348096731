import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import {
  AdjusmentsActions,
  EAdjustmentsActions,
  EInventoryActions,
  EJournalEntryActions,
  EProjectsActions,
  ERevenueRecognitionActions,
  EStatementOfAccountActions,
  InventoryActions,
  JournalEntryActions,
  ProjectsActions,
  RevenueRecognitionActions,
  StatementOfAccountActions,
} from './accounting.actions';
import { IAccountingState, initialAccountingState } from './accounting.state';

export function accountingReducer(
  state = initialAccountingState,
  action:
    | JournalEntryActions
    | AdjusmentsActions
    | ProjectsActions
    | InventoryActions
    | RevenueRecognitionActions
    | StatementOfAccountActions
): IAccountingState {
  switch (action.type) {
    case EJournalEntryActions.GetJournalEntryCollection:
    case EJournalEntryActions.GetJournalEntryDetailCollection:
    case EJournalEntryActions.GetJournalEntryEbsUploadCollection:
    case EJournalEntryActions.GetJournalEntryRevenueRecognitionCollection:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        sortDescriptors: action.payload.sortDescriptors,
        getJournalEntryCollection: { loading: true, httpError: null },
      };
    case EAdjustmentsActions.GET_ADJUSTMENTS_COLLECTION:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        sortDescriptors: action.payload.sortDescriptors,
        getAdjustmentsCollection: { loading: true, httpError: null },
      };
    case EJournalEntryActions.GetJournalEntryCollectionSuccess:
      return {
        ...state,
        journalEntryCollection: action.payload,
        creditTotal: action.payload.creditTotal,
        debitTotal: action.payload.debitTotal,
        accountingPeriodStatusCode: action.payload.jeAccountingPeriodStatusCode,
        getJournalEntryCollection: { loading: false, httpError: null },
      };
    case EJournalEntryActions.GetJournalEntryDetailCollectionSuccess:
      return {
        ...state,
        journalEntryDetailCollection: action.payload,
        creditTotal: action.payload.creditTotal,
        debitTotal: action.payload.debitTotal,
        accountingPeriodStatusCode: action.payload.jeAccountingPeriodStatusCode,
        getJournalEntryCollection: { loading: false, httpError: null },
      };
    case EJournalEntryActions.GetJournalEntryEbsUploadCollectionSuccess:
      return {
        ...state,
        journalEntryEbsUploadCollection: action.payload,
        creditTotal: action.payload.creditTotal,
        debitTotal: action.payload.debitTotal,
        accountingPeriodStatusCode: action.payload.jeAccountingPeriodStatusCode,
        getJournalEntryCollection: { loading: false, httpError: null },
      };
    case EJournalEntryActions.GetJournalEntryRevenueRecognitionCollectionSuccess:
      return {
        ...state,
        journalEntryRevenueRecognitionCollection: action.payload,
        creditTotal: action.payload.creditTotal,
        debitTotal: action.payload.debitTotal,
        accountingPeriodStatusCode: action.payload.jeAccountingPeriodStatusCode,
        getJournalEntryCollection: { loading: false, httpError: null },
      };
    case EAdjustmentsActions.GET_ADJUSTMENTS_COLLECTION_SUCCESS:
      return {
        ...state,
        adjustmentsCollection: action.payload,
        getAdjustmentsCollection: { loading: false, httpError: null },
      };
    case EJournalEntryActions.GetJournalEntryCollectionFailure:
      return {
        ...state,
        creditTotal: null,
        debitTotal: null,
        accountingPeriodStatusCode: null,
        journalEntryCollection: {
          journalEntries: [],
          total: null,
        },
        getJournalEntryCollection: { loading: false, httpError: action.payload },
      };
    case EJournalEntryActions.GetJournalEntryDetailCollectionFailure:
      return {
        ...state,
        creditTotal: null,
        debitTotal: null,
        accountingPeriodStatusCode: null,
        journalEntryDetailCollection: {
          journalEntries: [],
          total: null,
        },
        getJournalEntryCollection: { loading: false, httpError: action.payload },
      };
    case EJournalEntryActions.GetJournalEntryEbsUploadCollectionFailure:
      return {
        ...state,
        creditTotal: null,
        debitTotal: null,
        accountingPeriodStatusCode: null,
        journalEntryEbsUploadCollection: {
          journalEntries: [],
          total: null,
        },
        getJournalEntryCollection: { loading: false, httpError: action.payload },
      };
    case EJournalEntryActions.GetJournalEntryRevenueRecognitionCollectionFailure:
      return {
        ...state,
        creditTotal: null,
        debitTotal: null,
        accountingPeriodStatusCode: null,
        journalEntryRevenueRecognitionCollection: {
          journalEntries: [],
          total: null,
        },
        getJournalEntryCollection: { loading: false, httpError: action.payload },
      };
    case EJournalEntryActions.ExportJournalEntries:
      return {
        ...state,
        exportJournalEntries: { loading: true, httpError: null },
      };
    case EJournalEntryActions.ExportJournalEntriesSuccess:
      return {
        ...state,
        exportJournalEntries: { loading: false, httpError: null },
      };
    case EJournalEntryActions.ExportJournalEntriesFailure:
      return {
        ...state,
        exportJournalEntries: { loading: false, httpError: action.payload },
      };
    case EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_STATUS:
      return {
        ...state,
        getRunJournalEntryStatus: { loading: true, httpError: null },
      };
    case EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_STATUS_SUCCESS:
      return {
        ...state,
        runJournalEntryStatus: action.payload,
        getRunJournalEntryStatus: { loading: false, httpError: null },
      };
    case EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_STATUS_FAILURE:
      return {
        ...state,
        runJournalEntryStatus: null,
        getRunJournalEntryStatus: { loading: false, httpError: action.payload },
      };
    case EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY:
      return {
        ...state,
        getRunJournalEntry: { loading: true, httpError: null },
      };
    case EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_SUCCESS:
      return {
        ...state,
        getRunJournalEntry: { loading: false, httpError: null },
      };
    case EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_FAILURE:
      return {
        ...state,
        getRunJournalEntry: { loading: false, httpError: action.payload },
        getRunJournalEntryStatus: { loading: false, httpError: null },
        getRunJournalEntryWebsocketStatus: { loading: false, httpError: null },
      };
    case EJournalEntryActions.RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS:
      return {
        ...state,
        getRunJournalEntryWebsocketStatus: { loading: true, httpError: null },
      };
    case EJournalEntryActions.RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS_SUCCESS:
      return {
        ...state,
        getRunJournalEntryWebsocketStatus: { loading: false, httpError: null },
      };
    case EJournalEntryActions.RUN_JOURNAL_ENTRY_WEBSOCKET_STATUS_FAILURE:
      return {
        ...state,
        getRunJournalEntryWebsocketStatus: { loading: false, httpError: null },
      };
    case EJournalEntryActions.FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION:
      return {
        ...state,
        getMissingJournalEntryRulesCollection: { loading: true, httpError: null },
      };
    case EJournalEntryActions.FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION_SUCCESS:
      return {
        ...state,
        missingJournalEntryRulesCollection: action.payload,
        getMissingJournalEntryRulesCollection: { loading: false, httpError: null },
      };
    case EJournalEntryActions.FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION_FAILURE:
      return {
        ...state,
        missingJournalEntryRulesCollection: null,
        getMissingJournalEntryRulesCollection: { loading: false, httpError: action.payload },
      };
    case EAdjustmentsActions.GET_ADJUSTMENTS_COLLECTION_FAILURE:
      return {
        ...state,
        adjustmentsCollection: {
          accountingAdjustments: [],
          defaultAccountingPeriodToDisplay: null,
          total: null,
        },
        getAdjustmentsCollection: { loading: false, httpError: action.payload },
      };

    case EAdjustmentsActions.GET_ADJUSTMENTS_TYPES_COLLECTION:
      return {
        ...state,
        getAdjustmentTypesCollection: { loading: true, httpError: null },
      };

    case EAdjustmentsActions.GET_ADJUSTMENTS_TYPES_COLLECTION_SUCCESS:
      return {
        ...state,
        adjustmentTypesCollection: action.payload,
        getAdjustmentTypesCollection: { loading: false, httpError: null },
      };
    case EAdjustmentsActions.GET_ADJUSTMENTS_TYPES_COLLECTION_FAILURE:
      return {
        ...state,
        adjustmentTypesCollection: {
          accountingAdjustmentTypes: [],
        },
        getAdjustmentTypesCollection: { loading: false, httpError: action.payload },
      };

    case EAdjustmentsActions.SAVE_ADJUSTMENT:
      return {
        ...state,
        saveSelectedAdjustment: { loading: true, httpError: null },
      };
    case EAdjustmentsActions.SAVE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        selectedAdjustment: action.payload,
        saveSelectedAdjustment: { loading: false, httpError: null },
      };
    case EAdjustmentsActions.SAVE_ADJUSTMENT_FAILURE:
      return {
        ...state,
        selectedAdjustment: null,
        saveSelectedAdjustment: { loading: false, httpError: action.payload },
      };

    case EAdjustmentsActions.GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION:
      return {
        ...state,
        getPeriodStatsCollection: { loading: true, httpError: null },
      };
    case EAdjustmentsActions.GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION_SUCCESS:
      return {
        ...state,
        accountingPeriodStatusCollection: action.payload,
        getPeriodStatsCollection: { loading: false, httpError: null },
      };
    case EAdjustmentsActions.GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION_FAILURE:
      return {
        ...state,
        accountingPeriodStatusCollection: {
          jeAccountingPeriodStatuses: [],
        },
        getPeriodStatsCollection: { loading: false, httpError: action.payload },
      };

    case EAdjustmentsActions.FETCH_ADJUSTMENT_BY_ID:
      return {
        ...state,
        selectedAdjustment: null,
        getSelectedAdjustment: { loading: true, httpError: null },
      };
    case EAdjustmentsActions.FETCH_ADJUSTMENT_BY_ID_SUCCESS:
      return {
        ...state,
        selectedAdjustment: action.payload,
        getSelectedAdjustment: { loading: false, httpError: null },
      };
    case EAdjustmentsActions.FETCH_ADJUSTMENT_BY_ID_FAILURE:
      return {
        ...state,
        selectedAdjustment: null,
        getSelectedAdjustment: { loading: false, httpError: action.payload },
      };
    case EProjectsActions.SAVE_PROJECT:
      return {
        ...state,
        saveSelectedProject: { loading: true, httpError: null },
      };
    case EProjectsActions.SAVE_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: action.payload,
        saveSelectedProject: { loading: false, httpError: null },
      };
    case EProjectsActions.SAVE_PROJECT_FAILURE:
      return {
        ...state,
        selectedProject: null,
        saveSelectedProject: { loading: false, httpError: action.payload },
      };

    case EProjectsActions.FETCH_PROJECTS_COLLECTION:
      return {
        ...state,
        projectsCollection: null,
        getProjectsCollection: { loading: true, httpError: null },
      };
    case EProjectsActions.FETCH_PROJECTS_COLLECTION_SUCCESS:
      return {
        ...state,
        projectsCollection: action.payload.projects,
        getProjectsCollection: { loading: false, httpError: null },
      };
    case EProjectsActions.FETCH_PROJECTS_COLLECTION_FAILURE:
      return {
        ...state,
        projectsCollection: null,
        getProjectsCollection: { loading: false, httpError: action.payload },
      };
    case EProjectsActions.UPDATE_PROJECT:
      return {
        ...state,
        updateSelectedProject: { loading: true, httpError: null },
      };
    case EProjectsActions.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: action.payload,
        updateSelectedProject: { loading: false, httpError: null },
      };
    case EProjectsActions.UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        selectedProject: null,
        updateSelectedProject: { loading: false, httpError: action.payload },
      };
    case EProjectsActions.FETCH_PROJECT_BY_ID:
      return {
        ...state,
        selectedProject: null,
        getSelectedProject: { loading: true, httpError: null },
      };
    case EProjectsActions.FETCH_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        selectedProject: action.payload,
        getSelectedProject: { loading: false, httpError: null },
      };
    case EProjectsActions.FETCH_PROJECT_BY_ID_FAILURE:
      return {
        ...state,
        selectedProject: null,
        getSelectedProject: { loading: false, httpError: action.payload },
      };
    case EProjectsActions.CLEAR_PROJECT:
      return {
        ...state,
        selectedProject: null,
        updateSelectedProject: {
          loading: false,
          httpError: null,
        },
        saveSelectedProject: {
          loading: false,
          httpError: null,
        },
      };
    case EProjectsActions.FETCH_UNASSIGNED_CONTRACTS:
      return {
        ...state,
        unassignedContracts: null,
        getUnassignedContracts: { loading: true, httpError: null },
      };
    case EProjectsActions.FETCH_UNASSIGNED_CONTRACTS_SUCCESS:
      return {
        ...state,
        unassignedContracts: action.payload,
        getUnassignedContracts: { loading: false, httpError: null },
      };
    case EProjectsActions.FETCH_UNASSIGNED_CONTRACTS_FAILURE:
      return {
        ...state,
        unassignedContracts: null,
        getUnassignedContracts: { loading: false, httpError: action.payload },
      };
    case EProjectsActions.ASSIGN_CONTRACT_TO_PROJECT:
      return {
        ...state,
        currentContractIdToAssign: action.payload.contractId,
        assignContractToProject: { loading: true, httpError: null },
      };
    case EProjectsActions.ASSIGN_CONTRACT_TO_PROJECT_SUCCESS:
      return {
        ...state,
        currentContractIdToAssign: null,
        assignContractToProject: { loading: false, httpError: null },
      };
    case EProjectsActions.ASSIGN_CONTRACT_TO_PROJECT_FAILURE:
      return {
        ...state,
        currentContractIdToAssign: null,
        assignContractToProject: { loading: false, httpError: action.payload },
      };

    case EInventoryActions.FETCH_INVENTORY:
      return {
        ...state,
        inventory: null,
        getInventory: { loading: true, httpError: null },
      };
    case EInventoryActions.FETCH_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: action.payload,
        getInventory: { loading: false, httpError: null },
      };
    case EInventoryActions.FETCH_INVENTORY_FAILURE:
      return {
        ...state,
        inventory: null,
        getInventory: { loading: false, httpError: action.payload },
      };
    case EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET:
      return {
        ...state,
        getInventoryAccumulationsWebsocketStatus: { loading: true, httpError: null },
      };
    case EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET_SUCCESS:
      return {
        ...state,
        getInventoryAccumulationsWebsocketStatus: { loading: false, httpError: null },
      };
    case EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET_FAILURE:
      return {
        ...state,
        getInventoryAccumulationsWebsocketStatus: { loading: false, httpError: action.payload },
      };
    case EInventoryActions.FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD:
      return {
        ...state,
        defaultInventoryAccountPeriod: null,
        getDefaultInventoryAccountPeriod: { loading: true, httpError: null },
      };
    case EInventoryActions.FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD_SUCCESS:
      return {
        ...state,
        defaultInventoryAccountPeriod: action.payload,
        getDefaultInventoryAccountPeriod: { loading: false, httpError: null },
      };
    case EInventoryActions.FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD_FAILURE:
      return {
        ...state,
        defaultInventoryAccountPeriod: null,
        getDefaultInventoryAccountPeriod: { loading: false, httpError: action.payload },
      };
    case EInventoryActions.RESET_INVENTORY_DEFAULT_ACCOUNT_PERIOD:
      return {
        ...state,
        defaultInventoryAccountPeriod: null,
        getDefaultInventoryAccountPeriod: { loading: false, httpError: null },
      };
    case EInventoryActions.FETCH_FACILITY_STORAGE:
      return {
        ...state,
        facilityStorage: null,
        getFacilityStorage: { loading: true, httpError: null },
      };
    case EInventoryActions.FETCH_FACILITY_STORAGE_SUCCESS:
      return {
        ...state,
        facilityStorage: action.payload,
        getFacilityStorage: { loading: false, httpError: null },
      };
    case EInventoryActions.FETCH_FACILITY_STORAGE_FAILURE:
      return {
        ...state,
        facilityStorage: null,
        getFacilityStorage: { loading: false, httpError: action.payload },
      };
    case EInventoryActions.EXPORT_INVENTORY:
      return {
        ...state,
        exportInventory: { loading: true, httpError: null },
      };
    case EInventoryActions.EXPORT_INVENTORY_SUCCESS:
      return {
        ...state,
        exportInventory: { loading: false, httpError: null },
      };
    case EInventoryActions.EXPORT_INVENTORY_FAILURE:
      return {
        ...state,
        exportInventory: { loading: false, httpError: action.payload },
      };
    case ERevenueRecognitionActions.FETCH_REVENUE_RECOGNITION:
      return {
        ...state,
        revenueRecognitionCollection: {},
        getRevenueRecognitionCollection: { loading: true, httpError: null },
        updateRevenueRecognition: { loading: false, httpError: null },
      };
    case ERevenueRecognitionActions.FETCH_REVENUE_RECOGNITION_SUCCESS:
      return {
        ...state,
        revenueRecognitionCollection: action.payload,
        getRevenueRecognitionCollection: { loading: false, httpError: null },
      };
    case ERevenueRecognitionActions.FETCH_REVENUE_RECOGNITION_FAILURE:
      return {
        ...state,
        revenueRecognitionCollection: {},
        getRevenueRecognitionCollection: { loading: false, httpError: action.payload },
      };
    case ERevenueRecognitionActions.UPDATE_REVENUE_RECOGNITION:
      return {
        ...state,
        updateRevenueRecognition: { loading: true, httpError: null },
      };
    case ERevenueRecognitionActions.UPDATE_REVENUE_RECOGNITION_SUCCESS:
      return {
        ...state,
        updateRevenueRecognition: { loading: false, httpError: null },
      };
    case ERevenueRecognitionActions.UPDATE_REVENUE_RECOGNITION_FAILURE:
      return {
        ...state,
        updateRevenueRecognition: { loading: false, httpError: action.payload },
      };
    case EStatementOfAccountActions.FETCH_STATEMENT_OF_ACCOUNT_COLLECTION:
      return {
        ...state,
        statementOfAccountCollection: createLoadingState(state.statementOfAccountCollection),
      };
    case EStatementOfAccountActions.FETCH_STATEMENT_OF_ACCOUNT_COLLECTION_SUCCESS:
      return {
        ...state,
        statementOfAccountCollection: createSuccessState(action.payload),
      };
    case EStatementOfAccountActions.FETCH_STATEMENT_OF_ACCOUNT_COLLECTION_FAILURE:
      return {
        ...state,
        statementOfAccountCollection: createErrorState(action.payload),
      };
    case EStatementOfAccountActions.EXPORT_STATEMENT_OF_ACCOUNT:
      return {
        ...state,
        exportStatementOfAccount: { loading: true, httpError: null },
      };
    case EStatementOfAccountActions.EXPORT_STATEMENT_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        exportStatementOfAccount: { loading: false, httpError: null },
      };
    case EStatementOfAccountActions.EXPORT_STATEMENT_OF_ACCOUNT_FAILURE:
      return {
        ...state,
        exportStatementOfAccount: { loading: false, httpError: action.payload },
      };
    default:
      return state;
  }
}
