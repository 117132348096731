import { LocationHeader } from '@gms/location-api';
import { LookupCollections, MeasurementPeriodCollection } from '@gms/measurement-api';
import { HourlyOverrun } from '@gms/measurement-api/model/hourlyOverrun';
import { createInitialDataState, IDataState, IRequestStatus } from 'app/store/app/app.models';
import { getPagination, IPagination } from 'shared/models/pagination.model';

export interface IMeasurementsState {
  locationMeasurements: MeasurementPeriodCollection;
  lookupCollections: LookupCollections;
  status: {
    locationMeasurements: IRequestStatus;
    lookupCollections: IRequestStatus;
    updateLocationMeasurement: IRequestStatus;
    createLocationMeasurement: IRequestStatus;
  };
  exportMeasurementInfo: IDataState<any>;
  hourlyOverrun: IDataState<HourlyOverrun>;
  exportHourlyOverrun: IDataState<any>;
  gasQuality: IDataState<any>;
  exportGasQuality: IDataState<any>;
  accumulatedFlowSummary: IDataState<any>;
  accumulatedFlows: IDataState<any>;
  accumulatedFlowsTotal: number;
  pagination: IPagination;
  locationMeasurementsSearchState: {
    dateFlowBegin?: Date;
    dateFlowEnd?: Date;
    statementDateTime?: number;
    summaryEnergyQuantity?: number;
    searchParams?: {
      dateFlowBegin?: Date;
      dateFlowEnd?: Date;
      location?: LocationHeader;
    };
  };
}

export const initialMeasurementsState: IMeasurementsState = {
  locationMeasurements: null,
  lookupCollections: null,
  status: {
    locationMeasurements: {
      httpError: null,
      loading: false,
    },
    lookupCollections: {
      httpError: null,
      loading: false,
    },
    updateLocationMeasurement: {
      httpError: null,
      loading: false,
    },
    createLocationMeasurement: {
      httpError: null,
      loading: false,
    },
  },
  exportMeasurementInfo: {
    data: null,
    requestStatus: {
      loading: false,
      httpError: null,
    },
  },
  hourlyOverrun: createInitialDataState(),
  exportHourlyOverrun: {
    data: null,
    requestStatus: {
      loading: false,
      httpError: null,
    },
  },
  gasQuality: {
    data: null,
    requestStatus: {
      loading: false,
      httpError: null,
    },
  },
  exportGasQuality: {
    data: null,
    requestStatus: {
      loading: false,
      httpError: null,
    },
  },
  accumulatedFlowSummary: {
    data: null,
    requestStatus: {
      loading: false,
      httpError: null,
    },
  },
  accumulatedFlows: {
    data: null,
    requestStatus: {
      loading: false,
      httpError: null,
    },
  },
  accumulatedFlowsTotal: null,
  pagination: getPagination(), // pagination for accumulatedFlows
  locationMeasurementsSearchState: {
    dateFlowBegin: null,
    dateFlowEnd: null,
    statementDateTime: null,
    summaryEnergyQuantity: null,
    searchParams: null,
  },
};
