import { AbstractControl, UntypedFormControl } from '@angular/forms';
import get from 'lodash/get';
import { DynamicCheckboxComponent } from 'shared/components/table/dynamic-cell-components/checkbox-cell/checkbox-cell.component';
import { getControlName } from 'shared/components/table/dynamic-cell-components/dynamic-cell.interface';

export class DynamicCheckboxCell {
  dynamicComponentType: typeof DynamicCheckboxComponent;
  data: CheckboxOptions;
}

export interface CheckboxOptions {
  id?: string;
  control: UntypedFormControl | AbstractControl;
  checkboxFocused?: Function;
  valueChanged?: (value: any, row?: number) => void;
  updateOnModelChange?: boolean;
  rowIndex?: number;
}

export const createCheckboxCell = (checkboxOptions: CheckboxOptions): DynamicCheckboxCell => {
  const controlName = getControlName(checkboxOptions.control as UntypedFormControl);

  const prependId = get(checkboxOptions, 'id') || controlName;
  const id = prependId + '_Checkbox';
  return {
    dynamicComponentType: DynamicCheckboxComponent,
    data: {
      ...checkboxOptions,
      control: checkboxOptions.control as UntypedFormControl,
      id: id,
    },
  };
};
