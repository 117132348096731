import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { ACLResources } from 'app/modules/capacity-release/constants';
import { ERecallReputModes } from 'app/modules/capacity-release/utils/capacity-release.recallReputModes.enum';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const CapacityReleaseRoutes: Routes = [
  {
    path: 'offers',
    loadChildren: () =>
      import('./pages/offers/offers-list/offers-list.module').then(m => m.OffersListModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.OFFERS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'offers/new',
    loadChildren: () =>
      import('./pages/offers/create-offer/create-offer.module').then(m => m.CreateOfferModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.OFFERS,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'offers/:id',
    loadChildren: () =>
      import('./pages/offers/view-offer/view-offer-page.module').then(m => m.ViewOfferPageModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.CAPACITY_RELEASE, // This page needs to be accessible by Bids as well.
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'offers/:id/edit',
    loadChildren: () =>
      import('./pages/offers/create-offer/create-offer.module').then(m => m.CreateOfferModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.OFFERS,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'bids',
    loadChildren: () =>
      import('./pages/bids/bids-list/bids-list.module').then(m => m.BidsListModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.BIDS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'awards',
    loadChildren: () =>
      import('./pages/awards/awards-list/awards-list.module').then(m => m.AwardsListModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.AWARDS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'recalls-reputs',
    loadChildren: () =>
      import('./pages/recalls-reputs/recalls-reputs.module').then(m => m.RecallsReputsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.RECALLS_REPUT,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId/awards/new',
    loadChildren: () =>
      import('./pages/awards/create-award/create-award.module').then(m => m.CreateAwardModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.AWARDS,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId/awards/:awardId',
    loadChildren: () =>
      import('./pages/awards/view-award-page/view-award-page.module').then(
        m => m.ViewAwardPageModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.AWARDS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId/awards/:awardId/recalls/new',
    loadChildren: () =>
      import('./pages/recalls-reputs/create-recall-reput/create-recall-reput-page.module').then(
        m => m.CreateRecallReputPageModule
      ),
    canActivate: [ResourceGuard],
    data: {
      createMode: ERecallReputModes.RECALL,
      aclResource: ACLResources.RECALLS_REPUT,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId/awards/:awardId/recalls/:capacityReleaseId',
    loadChildren: () =>
      import('./pages/recalls-reputs/view-recall-reput/view-recall-reput-page.module').then(
        m => m.ViewRecallReputPageModule
      ),
    canActivate: [ResourceGuard],
    data: {
      createMode: ERecallReputModes.RECALL,
      aclResource: ACLResources.RECALLS_REPUT,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId/awards/:awardId/recalls/:capacityReleaseId/reputs/new',
    loadChildren: () =>
      import('./pages/recalls-reputs/create-recall-reput/create-recall-reput-page.module').then(
        m => m.CreateRecallReputPageModule
      ),
    canActivate: [ResourceGuard],
    data: {
      createMode: ERecallReputModes.REPUT,
      aclResource: ACLResources.RECALLS_REPUT,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId/awards/:awardId/reputs/:capacityReleaseId',
    loadChildren: () =>
      import('./pages/recalls-reputs/view-recall-reput/view-recall-reput-page.module').then(
        m => m.ViewRecallReputPageModule
      ),
    canActivate: [ResourceGuard],
    data: {
      createMode: ERecallReputModes.REPUT,
      aclResource: ACLResources.RECALLS_REPUT,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'bidevaluation',
    loadChildren: () =>
      import('./pages/bids/bid-evaluation/bid-evaluation.module').then(m => m.BidEvaluationModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.BID_EVALUATION,
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'offers/:offerId/bids/new',
    loadChildren: () =>
      import('./pages/bids/create-bid/create-bid.module').then(m => m.CreateBidModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.BIDS,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId',
    loadChildren: () =>
      import('./pages/bids/view-bid/view-bid-page.module').then(m => m.ViewBidPageModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.BIDS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'offers/:offerId/bids/:bidId/edit',
    loadChildren: () =>
      import('./pages/bids/create-bid/create-bid.module').then(m => m.CreateBidModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.BIDS,
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'withdrawals',
    loadChildren: () =>
      import('./pages/withdrawals/withdrawals.module').then(m => m.WithdrawalsModule),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.WITHDRAWALS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'withdrawals/:id',
    loadChildren: () =>
      import('./pages/withdrawals/withdrawal-detail/withdrawal-detail.module').then(
        m => m.WithdrawalDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.WITHDRAWALS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'withdrawals/:id/bid/:bidId',
    loadChildren: () =>
      import('./pages/withdrawals/withdrawal-detail/withdrawal-detail.module').then(
        m => m.WithdrawalDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: ACLResources.WITHDRAWALS,
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];
