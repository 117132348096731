import { FormControl } from '@angular/forms';
import {
  ApiCollection,
  JobConfig,
  JobConfigCollection,
  JobInstance,
  JobInstanceCollection,
  JobNameCollection,
  JobStatusCollection,
} from '@gms/jobschedule-api';
import * as LOG_CONSTANTS from 'app/modules/job-management/constants';
import * as CONFIG_CONSTANTS from 'app/modules/job-management/pages/job-configs/constants';
import { emptyRequestStatus, IDataState, IRequestStatus } from '../app/app.models';

export interface IJobConfigFiltersState {
  [CONFIG_CONSTANTS.LAST_RUN_START_DATE_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.LAST_RUN_START_TIME_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.LAST_RUN_END_DATE_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.LAST_RUN_END_TIME_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.NEXT_RUN_START_DATE_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.NEXT_RUN_START_TIME_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.NEXT_RUN_END_DATE_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.NEXT_RUN_END_TIME_CONTROL_NAME]: Date;
  [CONFIG_CONSTANTS.CONFIG_NAME_CONTROL_NAME]: string;
  [CONFIG_CONSTANTS.CONFIG_IDS_CONTROL_NAME]: string;
  [CONFIG_CONSTANTS.STATUSES_CONTROL_NAME]: any[];
  [CONFIG_CONSTANTS.RECURRENCE_CONTROL_NAME]: {
    None: boolean;
    Daily: boolean;
    Monthly: boolean;
  };
}

export interface IJobLogFiltersState {
  [LOG_CONSTANTS.START_DATE]: Date;
  [LOG_CONSTANTS.START_TIME]: Date;
  [LOG_CONSTANTS.END_DATE]: Date;
  [LOG_CONSTANTS.END_TIME]: Date;
  [LOG_CONSTANTS.JOB_ID]: number;
  [LOG_CONSTANTS.CONFIG_ID]: string;
  [LOG_CONSTANTS.CONFIG_IDS]: string;
  [LOG_CONSTANTS.CONFIG_NAME]: string;
  [LOG_CONSTANTS.STATUSES]: any[];
}

export interface IJobsState {
  jobInstances: IDataState<JobInstanceCollection>;
  updateJobInstance: IRequestStatus;
  jobInstance: IDataState<JobInstance>;
  jobNames: IDataState<JobNameCollection>;
  jobStatuses: IDataState<JobStatusCollection>;
  jobApis: IDataState<ApiCollection>;

  createJobConfig: IRequestStatus;
  updateJobConfig: IRequestStatus;
  deleteJobConfig: IRequestStatus;
  rerunJobConfig: IRequestStatus;
  jobConfig: IDataState<JobConfig>;
  jobConfigs: IDataState<JobConfigCollection>;
  jobConfigFilters: IJobConfigFiltersState;
  jobLogFilters: IJobLogFiltersState;
}

export const initialJobsState: IJobsState = {
  jobInstances: {
    data: { jobInstances: [], total: 0 },
    requestStatus: { ...emptyRequestStatus },
  },
  jobInstance: {
    data: {
      jobConfigId: null,
      cronExpression: null,
      name: null,
    },
    requestStatus: { ...emptyRequestStatus },
  },
  updateJobInstance: { ...emptyRequestStatus },
  jobNames: {
    data: { jobNames: [], total: 0 },
    requestStatus: { ...emptyRequestStatus },
  },
  jobStatuses: {
    data: { statuses: [], total: 0 },
    requestStatus: { ...emptyRequestStatus },
  },
  jobApis: {
    data: { apis: [], total: 0 },
    requestStatus: { ...emptyRequestStatus },
  },
  jobConfigs: {
    data: { jobConfigs: [], total: 0 },
    requestStatus: { ...emptyRequestStatus },
  },
  jobConfig: {
    data: {},
    requestStatus: { ...emptyRequestStatus },
  },
  createJobConfig: { ...emptyRequestStatus },
  updateJobConfig: { ...emptyRequestStatus },
  deleteJobConfig: { ...emptyRequestStatus },
  rerunJobConfig: { ...emptyRequestStatus },
  jobConfigFilters: {
    [CONFIG_CONSTANTS.LAST_RUN_START_DATE_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.LAST_RUN_START_TIME_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.LAST_RUN_END_DATE_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.LAST_RUN_END_TIME_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.NEXT_RUN_START_DATE_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.NEXT_RUN_START_TIME_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.NEXT_RUN_END_DATE_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.NEXT_RUN_END_TIME_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.CONFIG_NAME_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.CONFIG_IDS_CONTROL_NAME]: null,
    [CONFIG_CONSTANTS.STATUSES_CONTROL_NAME]: [],
    [CONFIG_CONSTANTS.RECURRENCE_CONTROL_NAME]: {
      None: false,
      Daily: false,
      Monthly: false,
    },
  },
  jobLogFilters: {
    [LOG_CONSTANTS.START_DATE]: null,
    [LOG_CONSTANTS.START_TIME]: null,
    [LOG_CONSTANTS.END_DATE]: null,
    [LOG_CONSTANTS.END_TIME]: null,
    [LOG_CONSTANTS.JOB_ID]: null,
    [LOG_CONSTANTS.CONFIG_ID]: null,
    [LOG_CONSTANTS.CONFIG_IDS]: null,
    [LOG_CONSTANTS.CONFIG_NAME]: null,
    [LOG_CONSTANTS.STATUSES]: [],
  },
};
