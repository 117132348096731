import {
  DetailRateSchedule,
  MasterRateSchedule,
  RateScheduleQuantityType,
  TspSeasonCollection,
  UiConfigItem,
} from '@gms/rateschedulev2-api';
import { Action } from '@ngrx/store';
import { RetryableAction } from 'app/store/retry/retry.actions';

export enum ERateSchedulesActions {
  FetchMasterRateSchedules = '[RATE_SCHEDULES] Fetch Rate Schedules',
  FetchMasterRateSchedulesFailure = '[RATE_SCHEDULES] Fetch Rate Schedules Failure',
  FetchMasterRateSchedulesSuccess = '[RATE_SCHEDULES] Fetch Rate Schedules Success',
  FetchDetailRateSchedules = '[RATE_SCHEDULES] Fetch Detail Rate Schedules',
  FetchDetailRateSchedulesFailure = '[RATE_SCHEDULES] Fetch Detail Rate Schedules Failure',
  FetchDetailRateSchedulesSuccess = '[RATE_SCHEDULES] Fetch Detail Rate Schedules Success',
  FetchAllDetailRateSchedules = '[RATE_SCHEDULES] Fetch All Detail Rate Schedules',
  FetchAllDetailRateSchedulesFailure = '[RATE_SCHEDULES] Fetch All Detail Rate Schedules Failure',
  FetchAllDetailRateSchedulesSuccess = '[RATE_SCHEDULES] Fetch All Detail Rate Schedules Success',
  ClearDetailRateSchedulesError = '[RATE_SCHEDULES] Clear Detail Rate Schedules Error',
  FetchDetailRateScheduleUiConfiguration = '[RATE_SCHEDULES] Fetch Detail Rate Schedule Ui Configuration',
  FetchDetailRateScheduleUiConfigurationFailure = '[RATE_SCHEDULES] Fetch Detail Rate Schedule Ui Configuration Failure',
  FetchDetailRateScheduleUiConfigurationSuccess = '[RATE_SCHEDULES] Fetch Detail Rate Schedule Ui Configuration Success',
  FetchRateSchedule = '[RATE_SCHEDULES] Fetch RateSchedule',
  FetchRateScheduleSuccess = '[RATE_SCHEDULES] Fetch RateSchedule Success',
  FetchRateScheduleFailure = '[RATE_SCHEDULES] Fetch RateSchedule Failure',
  Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID = '[RATE_SCHEDULES] Fetch FetchMultipleNAESBLocationIndicatorsByTspID',
  Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID_Success = '[RATE_SCHEDULES] Fetch FetchMultipleNAESBLocationIndicatorsByTspID Success',
  Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID_Failure = '[RATE_SCHEDULES] Fetch FetchMultipleNAESBLocationIndicatorsByTspID Failure',
  FETCH_QUANTITY_TYPE = '[RATE_SCHEDULES] Fetch Quantity Type',
  FETCH_QUANTITY_TYPE_SUCCESS = '[RATE_SCHEDULES] Fetch Quantity Type Success',
  FETCH_QUANTITY_TYPE_FAILURE = '[RATE_SCHEDULES] Fetch Quantity Type Failure',
  FETCH_TSP_SEASONS = '[RATE_SCHEDULES] Fetch TSP Seasons',
  FETCH_TSP_SEASONS_FAILURE = '[RATE_SCHEDULES] Fetch TSP Seasons Failure',
  FETCH_TSP_SEASONS_SUCCESS = '[RATE_SCHEDULES] Fetch TSP Seasons Success',
  CLEAR_QUANTITY_TYPE = '[RATE_SCHEDULES] Clear Quantity Type',
  CLEAR_DETAIL_RATE_SCHEDULE = '[RATE_SCHEDULES] Clear Detail Rate Schedule',
  SET_ALLOW_SAME_DAY_DEAL = '[RATE_SCHEDULES] Set Allow Same Day Deal',
}

export class FetchTspSeasonsSuccess implements Action {
  public readonly type = ERateSchedulesActions.FETCH_TSP_SEASONS_SUCCESS;

  constructor(public payload: { tspSeasons: TspSeasonCollection }) {}
}

export class FetchTspSeasonsFailure implements Action {
  public readonly type = ERateSchedulesActions.FETCH_TSP_SEASONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchTspSeasons extends RetryableAction implements Action {
  public readonly type = ERateSchedulesActions.FETCH_TSP_SEASONS;

  constructor(public payload: { tspId: number }) {
    super(payload);
  }
}

export class FetchMasterRateSchedules extends RetryableAction implements Action {
  public readonly type = ERateSchedulesActions.FetchMasterRateSchedules;

  constructor(public payload: { tspId: number }) {
    super(payload);
  }
}

export class FetchMasterRateSchedulesSuccess implements Action {
  public readonly type = ERateSchedulesActions.FetchMasterRateSchedulesSuccess;

  constructor(public payload: { masterRateSchedules: Array<MasterRateSchedule> }) {}
}

export class FetchMasterRateSchedulesFailure implements Action {
  public readonly type = ERateSchedulesActions.FetchMasterRateSchedulesFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchDetailRateSchedules extends RetryableAction implements Action {
  public readonly type = ERateSchedulesActions.FetchDetailRateSchedules;

  constructor(public payload: { tspId: number; isTariff?: boolean }) {
    super(payload);
  }
}

export class FetchDetailRateSchedulesSuccess implements Action {
  public readonly type = ERateSchedulesActions.FetchDetailRateSchedulesSuccess;

  constructor(public payload: { detailRateSchedules: Array<DetailRateSchedule> }) {}
}

export class FetchDetailRateSchedulesFailure implements Action {
  public readonly type = ERateSchedulesActions.FetchDetailRateSchedulesFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchAllDetailRateSchedules extends RetryableAction implements Action {
  public readonly type = ERateSchedulesActions.FetchAllDetailRateSchedules;

  constructor(public payload: { isTariff?: boolean; serviceTypeId?: number }) {
    super(payload);
  }
}

export class FetchAllDetailRateSchedulesSuccess implements Action {
  public readonly type = ERateSchedulesActions.FetchAllDetailRateSchedulesSuccess;

  constructor(public payload: { allDetailRateSchedules: Array<DetailRateSchedule> }) {}
}

export class FetchAllDetailRateSchedulesFailure implements Action {
  public readonly type = ERateSchedulesActions.FetchAllDetailRateSchedulesFailure;

  constructor(public payload: { error: Error }) {}
}

export class ClearDetailRateSchedulesError implements Action {
  public readonly type = ERateSchedulesActions.ClearDetailRateSchedulesError;
}

export class FetchDetailRateScheduleUiConfiguration extends RetryableAction implements Action {
  public readonly type = ERateSchedulesActions.FetchDetailRateScheduleUiConfiguration;

  constructor(public payload: { tspId: number; detailRateScheduleId: number }) {
    super(payload);
  }
}

export class FetchDetailRateScheduleUiConfigurationSuccess implements Action {
  public readonly type = ERateSchedulesActions.FetchDetailRateScheduleUiConfigurationSuccess;

  constructor(
    public payload: { detailRateScheduleId: number; uiConfigurations: Array<UiConfigItem> }
  ) {}
}

export class FetchDetailRateScheduleUiConfigurationFailure implements Action {
  public readonly type = ERateSchedulesActions.FetchDetailRateScheduleUiConfigurationFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchRateSchedule extends RetryableAction implements Action {
  public readonly type = ERateSchedulesActions.FetchRateSchedule;

  constructor(public payload: { detailRateScheduleId: number; tspId: number }) {
    super(payload);
  }
}

export class FetchRateScheduleSuccess implements Action {
  public readonly type = ERateSchedulesActions.FetchRateScheduleSuccess;

  constructor(public payload: { detailRateSchedule: DetailRateSchedule }) {}
}

export class FetchRateScheduleFailure implements Action {
  public readonly type = ERateSchedulesActions.FetchRateScheduleFailure;

  constructor(public payload: { error: Error }) {}
}

export class FetchMultipleNAESBLocationIndicatorsByTspID implements Action {
  public readonly type = ERateSchedulesActions.Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID;

  constructor(public tspIds: number[]) {}
}

export class FetchMultipleNAESBLocationIndicatorsByTspIDSuccess implements Action {
  public readonly type =
    ERateSchedulesActions.Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID_Success;

  constructor(public payload) {}
}

export class FetchMultipleNAESBLocationIndicatorsByTspIDFailure implements Action {
  public readonly type =
    ERateSchedulesActions.Fetch_Multiple_NAESB_Location_Indicators_By_Tsp_ID_Failure;

  constructor(public error: Error) {}
}

export class FetchQuantityType extends RetryableAction implements Action {
  public readonly type = ERateSchedulesActions.FETCH_QUANTITY_TYPE;

  constructor(public payload: { tspId: number; detailRateScheduleId: number }) {
    super(payload);
  }
}

export class FetchQuantityTypeSuccess implements Action {
  public readonly type = ERateSchedulesActions.FETCH_QUANTITY_TYPE_SUCCESS;

  constructor(public payload: { quantityType: RateScheduleQuantityType }) {}
}

export class FetchQuantityTypeFailure implements Action {
  public readonly type = ERateSchedulesActions.FETCH_QUANTITY_TYPE_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearQuantityType implements Action {
  public readonly type = ERateSchedulesActions.CLEAR_QUANTITY_TYPE;
}

export class ClearDetailRateSchedule implements Action {
  public readonly type = ERateSchedulesActions.CLEAR_DETAIL_RATE_SCHEDULE;
}

export class SetAllowSameDayDeal implements Action {
  public readonly type = ERateSchedulesActions.SET_ALLOW_SAME_DAY_DEAL;

  constructor(public payload: { allow: Boolean }) {}
}

export type RateScheduleActions =
  | FetchMasterRateSchedules
  | FetchMasterRateSchedulesSuccess
  | FetchMasterRateSchedulesFailure
  | FetchDetailRateSchedules
  | FetchDetailRateSchedulesSuccess
  | FetchDetailRateSchedulesFailure
  | FetchAllDetailRateSchedules
  | FetchAllDetailRateSchedulesSuccess
  | FetchAllDetailRateSchedulesFailure
  | ClearDetailRateSchedulesError
  | FetchDetailRateScheduleUiConfiguration
  | FetchDetailRateScheduleUiConfigurationFailure
  | FetchDetailRateScheduleUiConfigurationSuccess
  | FetchRateSchedule
  | FetchRateScheduleSuccess
  | FetchRateScheduleFailure
  | FetchMultipleNAESBLocationIndicatorsByTspID
  | FetchMultipleNAESBLocationIndicatorsByTspIDSuccess
  | FetchMultipleNAESBLocationIndicatorsByTspIDFailure
  | FetchQuantityType
  | FetchQuantityTypeFailure
  | FetchQuantityTypeSuccess
  | ClearQuantityType
  | FetchTspSeasons
  | FetchTspSeasonsSuccess
  | FetchTspSeasonsFailure
  | ClearDetailRateSchedule
  | SetAllowSameDayDeal;
