import { Note } from '@gms/servicerequest-api';
import { Action } from '@ngrx/store';

export enum ENotesActions {
  FETCH_NOTES = '[NOTES] fetch notes',
  FETCH_NOTES_SUCCESS = '[NOTES] fetch notes success',
  FETCH_NOTES_ERROR = '[NOTES] fetch notes error',
  ADD_NOTE = '[NOTES] add note',
  ADD_NOTE_SUCCESS = '[NOTES] add note success',
  ADD_NOTE_ERROR = '[NOTES] add note error',
  DELETE_NOTE = '[NOTES] delete note',
  DELETE_NOTE_SUCCESS = '[NOTES] delete note success',
  DELETE_NOTE_ERROR = '[NOTES] delete note error',
}

export class FetchNotes implements Action {
  public readonly type = ENotesActions.FETCH_NOTES;

  constructor(
    public payload: {
      referenceId: number;
      referenceTypeId: number;
    }
  ) {}
}

export class FetchNotesSuccess implements Action {
  public readonly type = ENotesActions.FETCH_NOTES_SUCCESS;

  constructor(public notes: Note[]) {}
}

export class FetchNotesError implements Action {
  public readonly type = ENotesActions.FETCH_NOTES_ERROR;

  constructor(public payload: Note[]) {}
}

export class SaveNote implements Action {
  public readonly type = ENotesActions.ADD_NOTE;

  constructor(public payload: Note) {}
}

export class SaveNoteSuccess implements Action {
  public readonly type = ENotesActions.ADD_NOTE_SUCCESS;

  constructor(public payload: Note) {}
}

export class SaveNoteError implements Action {
  public readonly type = ENotesActions.ADD_NOTE_ERROR;

  constructor(public payload: Note) {}
}

export class DeleteNote implements Action {
  public readonly type = ENotesActions.DELETE_NOTE;

  constructor(public payload: { id: number }) {}
}

export class DeleteNoteSuccess implements Action {
  public readonly type = ENotesActions.DELETE_NOTE_SUCCESS;

  constructor() {}
}

export class DeleteNoteError implements Action {
  public readonly type = ENotesActions.DELETE_NOTE_ERROR;

  constructor() {}
}

export type NotesActions =
  | FetchNotes
  | FetchNotesSuccess
  | FetchNotesError
  | SaveNote
  | SaveNoteSuccess
  | SaveNoteError
  | DeleteNote
  | DeleteNoteSuccess
  | DeleteNoteError;
