import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AgencyRelationship, AgencyService } from '@gms/entity-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { asyncScheduler, of } from 'rxjs';
import {
  auditTime,
  catchError,
  debounceTime,
  map,
  switchMap,
  throttle,
  throttleTime,
} from 'rxjs/operators';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { DEBOUNCE_500 } from '../../../shared/consts/debounce.const';
import { filterIfCached } from '../app/app.models';
import { IAppState } from '../app/app.state';
import { EAgencyStatus } from '../entities/entities.models';
import {
  CreateAgency,
  CreateAgencyFailure,
  CreateAgencySuccess,
  EAgencyActions,
  FetchAgencyById,
  FetchAgencyByIdFailure,
  FetchAgencyByIdSuccess,
  FetchAgencyHeaders,
  FetchAgencyHeadersFailure,
  FetchAgencyHeadersSuccess,
  FetchAgencyRoles,
  FetchAgencyRolesFailure,
  FetchAgencyRolesSuccess,
  SearchAgencies,
  SearchAgenciesFailure,
  SearchAgenciesSuccess,
  UpdateAgency,
  UpdateAgencyFailure,
  UpdateAgencySuccess,
} from './agency.actions';
import { selectAgencyRolesState } from './agency.selectors';
import { sortDefinitions } from '../default-sort.definitions';

class getAllAgenciesParameters {
  get pageSize() {
    return this._actionPayload.pageSize;
  }
  get pageNumber() {
    return this._actionPayload.pageNumber;
  }
  get sortBy() {
    if (isNullOrUndefined(this._actionPayload.sortBy)) return sortDefinitions.getAgencyHeaders;

    return !!this._actionPayload.sortBy ? this._actionPayload.sortBy
      .map(sortDesc => `${sortDesc.field}+${sortDesc.dir}`)
      .join('|') : sortDefinitions.getAgencyHeaders;
  }
  get agencyIds() {
    return this._actionPayload.agencyIds;
  }
  get searchPhrase() {
    return this._actionPayload.searchPhrase;
  }
  get agentIds() {
    return this._actionPayload.agentIds;
  }
  get principalIds() {
    return this._actionPayload.principalIds;
  }
  get status() {
    return this._actionPayload.status ? this._actionPayload.status : null;
  }

  constructor(
    private _actionPayload: {
      pageSize?: number;
      pageNumber?: number;
      sortBy?: SortDescriptor[];
      agencyIds?: number[];
      searchPhrase?: string;
      agentIds?: number[];
      principalIds?: number[];
      status?: EAgencyStatus;
    }
  ) {}
}

@Injectable()
export class AgencyEffects {
  constructor(
    private _actions$: Actions,
    private _agencyService: AgencyService,
    private _store: Store<IAppState>
  ) {}

  CreateAgency$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateAgency>(EAgencyActions.CREATE_AGENCY),
      switchMap(action => {
        const newAgency: AgencyRelationship = action.payload;
        return this._agencyService.postAgency(newAgency).pipe(
          map(() => new CreateAgencySuccess()),
          catchError(error => of(new CreateAgencyFailure(error)))
        );
      })
    )
  );

  SearchAgencies$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<SearchAgencies>(EAgencyActions.SEARCH_AGENCIES),
      auditTime(debounce, scheduler),
      switchMap(action => {
        const searchParams = new getAllAgenciesParameters(action.payload);
        const sortBy = isNullOrUndefined(searchParams.sortBy) ? searchParams.sortBy : sortDefinitions.getAgencyHeaders;
        return this._agencyService
          .getAllAgencies(
            searchParams.pageSize,
            searchParams.pageNumber,
            sortBy,
            searchParams.agencyIds,
            searchParams.searchPhrase,
            searchParams.agentIds,
            searchParams.principalIds,
            searchParams.status
          )
          .pipe(
            map(agencyCollection => new SearchAgenciesSuccess(agencyCollection)),
            catchError(error => of(new SearchAgenciesFailure(error)))
          );
      })
    )
  );

  UpdateAgency$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateAgency>(EAgencyActions.UPDATE_AGENCY),
      switchMap(action => {
        const updatedAgency = action.payload;
        return this._agencyService
          .updateAgency(updatedAgency, updatedAgency.agencyRelationshipId)
          .pipe(
            map(() => new UpdateAgencySuccess()),
            catchError(error => of(new UpdateAgencyFailure(error)))
          );
      })
    )
  );

  FetchAgencyById$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAgencyById>(EAgencyActions.FETCH_AGENCY_BY_ID),
      map((action: FetchAgencyById) => action.payload),
      switchMap(payload => {
        return this._agencyService.getAgencyById(payload).pipe(
          map(agency => new FetchAgencyByIdSuccess(agency)),
          catchError(error => of(new FetchAgencyByIdFailure(error)))
        );
      })
    )
  );

  FetchAgencyRoles$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAgencyRoles>(EAgencyActions.FETCH_AGENCY_ROLES),
      filterIfCached(this._store.pipe(select(selectAgencyRolesState))),
      switchMap(payload => {
        return this._agencyService.getAgencyRoles().pipe(
          map(agencyRoles => new FetchAgencyRolesSuccess(agencyRoles)),
          catchError(error => of(new FetchAgencyRolesFailure(error)))
        );
      })
    )
  );

  FetchAgencyHeaders$ = createEffect(() => ({ // assign default values
    debounce = 500, scheduler = asyncScheduler } = {}) =>
    this._actions$.pipe(
      ofType<FetchAgencyHeaders>(EAgencyActions.FETCH_AGENCY_HEADERS),
      auditTime(debounce, scheduler),
      switchMap(action => {
        const searchParams = new getAllAgenciesParameters(action.payload);
        const sortBy = isNullOrUndefined(searchParams.sortBy) ? searchParams.sortBy : sortDefinitions.getAgencyHeaders;

        return this._agencyService
          .getAgencyHeaders(
            searchParams.pageSize,
            searchParams.pageNumber,
            sortBy,
            searchParams.searchPhrase,
            searchParams.agentIds,
            searchParams.principalIds,
            searchParams.status
          )
          .pipe(
            map(
              agencyHeaderCollection => new FetchAgencyHeadersSuccess({ agencyHeaderCollection })
            ),
            catchError(error => of(new FetchAgencyHeadersFailure({ error })))
          );
      })
    )
  );
}
