import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { DynamicComponentDefinition } from 'shared/components/dynamic-component-wrapper/dynamic-component.interface';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { InputOptions } from './input-cell.interface';

@Component({
  selector: 'gms-dynamic-input-cell',
  templateUrl: './input-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicInputComponent extends HasSubscriptions implements OnInit {
  @Input() data: InputOptions;

  static componentDefinition(data: InputOptions): DynamicComponentDefinition {
    return {
      dynamicComponentType: DynamicInputComponent,
      data: data,
    };
  }

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(
      this.data.control.statusChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => this.cd.markForCheck())
    );

    if (this.data.dynamicFormattingOptions) {
      this.addSubscription(
        this.data.dynamicFormattingOptions.pipe(take(1)).subscribe(dynamicFormattingOptions => {
          this.data.min = dynamicFormattingOptions.min;
          this.data.max = dynamicFormattingOptions.max;
          this.data.numberFormat = dynamicFormattingOptions.numberFormat;
          this.data.decimals = dynamicFormattingOptions.decimals;
          this.data.placeholder = dynamicFormattingOptions.placeholder;
        })
      );
    }
  }

  public inputBlurred() {
    if (this.data.inputBlurred) {
      this.data.inputBlurred();
    }
  }

  public inputFocused() {
    if (this.data.inputFocused) {
      this.data.inputFocused();
    }
  }

  public inputChanges() {
    if (this.data.inputChanges) {
      this.data.inputChanges();
    }
  }
}
