import {
  DictionaryCollections,
  Entity,
  EntityAddress,
  EntityCreditExposure,
  EntityCreditExposureSummary,
  EntityCreditLimit,
  EntityCreditLimitSummary,
  EntityHeader,
  EntityHeaderCollection,
  ParentEntityCollection,
} from '@gms/entity-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IEntityCreditExposureGridSort } from 'app/store/entities/entities.models';
import { getPagination, IPagination } from 'shared/models/pagination.model';
import {
  createInitialDataState,
  createSuccessState,
  emptyRequestStatus,
  IDataState,
} from '../app/app.models';

export interface IEntitiesState {
  entity: Entity;
  entityLoading: boolean;
  entityError: Error | null;
  entities: Array<Entity>;
  filteredEntities: Array<Entity>;
  filteredEntitiesLoading: boolean;
  sortDescriptors: SortDescriptor[];
  pagination: IPagination;
  totalEntityCount: number;
  loading: boolean;
  error: Error | null;
  entitiesSearch: Entity[];
  entitiesSearchLoading: boolean;
  entitiesSearchError: Error | null;
  updateEntityLoading: boolean;
  updateEntityError: Error | null;
  updateEntitySuccess: boolean;
  createEntityLoading: boolean;
  createEntityError: Error | null;
  createEntitySuccess: boolean;
  dictionary: DictionaryCollections;
  dictionaryLoading: boolean;
  dictionaryError: Error | null;
  updateEntityClassOverrideLoading: boolean;
  updateEntityClassOverrideError: Error | null;
  updateEntityClassOverrideSuccess: boolean;
  parentEntityCollection: IDataState<ParentEntityCollection>;
  entityCreditLimits: IDataState<EntityCreditLimitSummary>;
  updateEntityCreditLimits: IDataState<EntityCreditLimit>;
  addEntityCreditLimit: IDataState<EntityCreditLimit>;
  updateParentEntities: IDataState;
  entitiesByIds: IDataState<{ entities: Array<EntityHeader> }>;
  entityHeader: IDataState<EntityHeader>;
  entityHeaders: IDataState<EntityHeaderCollection>;
  searchEntityHeader: IDataState<EntityHeaderCollection>;
  entityCreditExposures: IDataState<EntityCreditExposureSummary>;
  entityCreditExposure: IDataState<EntityCreditExposure>;
  entityCreditExposureSort: IEntityCreditExposureGridSort;
  entityAddresses: IDataState<Array<EntityAddress>>;
  entityPermissionAccess: IDataState<boolean>;
}

export const initialEntitiesState: IEntitiesState = {
  entity: null,
  entityLoading: false,
  entityError: null,
  entities: [],
  filteredEntities: [],
  filteredEntitiesLoading: false,
  sortDescriptors: [
    {
      field: 'entityId',
      dir: 'asc',
    },
  ],
  pagination: getPagination(),
  totalEntityCount: 0,
  loading: false,
  error: null,
  entitiesSearch: [],
  searchEntityHeader: null,
  entitiesSearchLoading: false,
  entitiesSearchError: null,
  updateEntityLoading: false,
  updateEntityError: null,
  updateEntitySuccess: false,
  createEntityLoading: false,
  createEntityError: null,
  createEntitySuccess: false,
  dictionary: null,
  dictionaryLoading: false,
  dictionaryError: null,
  updateEntityClassOverrideLoading: false,
  updateEntityClassOverrideError: null,
  updateEntityClassOverrideSuccess: false,
  updateParentEntities: {
    data: { success: false },
    requestStatus: { ...emptyRequestStatus },
  },
  parentEntityCollection: {
    data: { parentEntities: [], total: 0 },
    requestStatus: { ...emptyRequestStatus },
  },
  entityCreditLimits: createInitialDataState(),
  updateEntityCreditLimits: createInitialDataState(),
  addEntityCreditLimit: createInitialDataState(),
  entitiesByIds: {
    data: { entities: [] },
    requestStatus: { ...emptyRequestStatus },
  },
  entityHeader: createInitialDataState(),
  entityHeaders: createSuccessState({ entityHeaders: [] }),
  entityCreditExposures: createInitialDataState(),
  entityCreditExposure: createInitialDataState(),
  entityCreditExposureSort: null,
  entityAddresses: createInitialDataState(),
  entityPermissionAccess: createSuccessState(false),
};
