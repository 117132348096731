import { LocationModel, LocationOptionsResponse, ValidationError } from '@gms/capacityrelease-api';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export enum OfferIcons {
  AWARD = 'icon-award',
  WITHDRAWN = 'icon-withdraw',
  CLOSED = 'icon-file-close',
  REJECTED = 'icon-block',
}

export enum OfferStatuses {
  ORIGINAL = 'Original',
  CHANGE = 'Change',
  DRAFT = 'Draft',
  POSTED = 'Posted',
  REJECTED = 'Rejected',
  AWARDED = 'Awarded',
  CLOSED = 'Closed',
  WITHDRAWN = 'Withdrawn',
}

export enum EOfferStatusIds {
  ORIGINAL = 1,
  CHANGE,
  DRAFT,
  POSTED,
  VALIDATING,
  AWARDED,
  CLOSED,
  WITHDRAWN,
  REJECTED,
  DELETED,
}

export enum NaesbCycleNames {
  EE = 'Early Evening (EE)',
  EVE = 'Evening (EVE)',
  ID1 = 'Intraday 1 (ID1)',
  ID2 = 'Intraday 2 (ID2)',
  ID3 = 'Intraday 3 (ID3)',
  TIM = 'Timely (TIM)',
}

export enum NaesbCycleCodes {
  EE = 'EE',
  EVE = 'EVE',
  ID1 = 'ID1',
  ID2 = 'ID2',
  ID3 = 'ID3',
  TIM = 'TIM',
}
export enum NaesbCycleIds {
  EE = 6,
  EVE = 2,
  ID1 = 3,
  ID2 = 4,
  ID3 = 5,
  TIM = 1,
}

export enum EViewOfferTabs {
  OFFER_TERMS = 'Offer Terms',
  ADDITIONAL_TERMS = 'Additional Terms',
  LOCATIONS = 'Locations',
  RATE_INFO = 'Rate Information',
}

export enum ESpecialTermIds {
  NOT_STAND_ALONE = 1,
  AMEND_PRIMARY_POINTS_TERMS = 2,
  STORAGE_INV_TERMS = 3,
  AMA = 4,
  RECALL_REPUT_TERMS = 5,
  CONTINGENCY_TERMS = 6,
  EVALUATION_METHOD_TERMS = 7,
  TIE_BREAKING_METHOD_TERMS = 8,
  MISC_NOTES = 9,
}

export enum EShipperRoles {
  AMA = 1,
  OTHER = 2,
  RETAIL_CHOICE = 3,
}

export enum EPrimaryPoints {
  CONDITIONAL = 'C',
  UNCONDITIONAL = 'U',
  NO = 'N',
}

export enum EReleaseTypes {
  POINT_TO_POINT = 1,
  NO_NOTICE_TRANSPORT_ONLY = 2,
  NO_NOTICE_BUNDLED = 3,
  NO_NOTICE_MSQ_MDWQ_MDIQ_ONLY = 4,
  MSQ_W_MDWQ_MDIQ = 5,
}

export enum EBidEvaluationMethods {
  HIGHEST_RATE = 1,
  NET_REVENUE = 2,
  OTHER_METHOD = 3,
  PRESENT_VALUE = 4,
}

export enum EBidTieBreakingMethods {
  PIPELINES_TARIFF_REFERENCE = 1,
  RELEASERS_METHOD_DESCRIBED = 2,
}

export enum EServiceTypes {
  TRANSPORTATION = 'Transportation',
  STORAGE = 'Storage',
  NO_NOTICE = 'No-Notice',
  SNNS = 'SNNS',
}

export enum ETextTypes {
  MINIMUM_RATE_DISCLOSURE = 1,
  SHORTER_TERM = 2,
  RELEASING_SHIPPERS_CONTINGENCY = 3,
  RELEASING_SHIPPER_LESSER_QUANTITY = 4,
}

export enum EShorterTerms {
  UNDISCLOSED = 5,
  YES = 6,
  NO = 7,
}

export enum EReleasingShippersContingencies {
  YES = 3,
  NO = 4,
}

export enum ERecallReputTypes {
  NOT_RECALLABLE = 1,
  RECALLABLE_REPLACEMENT_SHIPPER = 2,
  NOT_REPUTTABLE = 3,
  REPUTTABLE = 4,
}

export enum ERateTypeCategories {
  RESERVATION = 1,
  CAPACITY = 2,
}

export enum EAcceptableBiddingBases {
  NON_IBR_ABSOLUTE = 1,
  NON_IBR_PERCENT = 2,
  IBR_FORM_DIFF = 3,
  IBR_FORM_PERCENT = 4,
  IBR_RATE_FLR_DIFF = 5,
}

export enum EIbrFormulas {
  BASIC = 1,
  UNIQUE = 2,
}

export enum EIbrMathOperatorIndicators {
  PLUS = 1,
  MINUS = 2,
}

export enum EAffiliateIndicators {
  BOTH = 1,
  NONE = 2,
  RELEASING_SHIPPER = 3,
  TRANSPORTATION_SERVICE_PROVIDER = 4,
}

export enum EReleaserAcceptableBiddingBases {
  NON_IBR_ABSOLUTE = 1,
  NON_IBR_PCT = 2,
  IBR_FORM_DIFF = 3,
  IBR_FORM_PCT = 4,
  IBR_RATE_FLOOR_DIFF = 5,
}

export enum ERateScheduleCodes {
  NNL = 'NNL',
  NNS = 'NNS',
  FSS = 'FSS',
  FSS_M = 'FSS-M',
  FSS_N = 'FSS-N',
  FSS_P = 'FSS-P',

  FSS_B = 'FSS-B',
  FTS = 'FTS',
  EFT = 'EFT',
  FTS_P = 'FTS-P',
}

export enum EBiddableDealTexts {
  MIN_RATE_DISCLOSED = 1,
  MIN_RATE_UNDISCLOSED = 2,
  SHORT_TERM_NO = 7,
  REL_SHIP_LESSER_QTY_UNDISCLOSED = 8,
  REL_SHIP_LESSER_QTY_YES = 9,
  REL_SHIP_LESSER_QTY_NO = 10,
}

export enum OfferViewModesEnum {
  RELEASER = 'releaser',
  DOWNLOAD = 'download',
}

export enum ELocationQTI {
  REC_QTY = 'Rec Qty',
  DEL_QTY = 'Del Qty',
  SEG_QTY = 'Seg Qty',
  REC_TO_DEL_QTY = 'Rec to Del Qty',
  BNDLD_QTY_DAILY = 'Bndld K Qty - Daily',
  BNDLD_QTY_SEASNL = 'Bndld K Qty - Seasn',
  STOR_QTY = 'Stor Qty',
  STOR_WD_QTY = 'Stor W/D Qty',
  STOR_INJ_QTY = 'Stor Inj Qty',
  K_MAX_DAY_QTY = 'K Max Day Qty',
}

export enum ELocationQTIIds {
  'Rec Qty' = 1,
  'Del Qty',
  'Seg Qty',
  'Rec to Del Qty',
  'Bndld K Qty - Daily',
  'Bndld K Qty - Seasn',
  'Stor Qty',
  'Stor W/D Qty',
  'Stor Inj Qty',
  'K Max Day Qty',
}

export enum ELocationClassification {
  MKT_STO_WITHDRAW = 'Market Storage Withdraw',
}

export const AmendPrimaryPointsRightsMap = {
  C: 'Yes, conditionally',
  U: 'Yes, unconditionally',
  N: 'No',
};

export enum ECapTypeLoc {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  IN_PATH = 'In Path',
  OUT_PATH = 'Out Path',
  COMBO_PATH = 'Combo In/Out Path',
  MAX_DAILY_WD_QTY = 'Max Daily W/D Qty',
}

export enum ECapTypeLocIds {
  'Primary' = 1,
  'In Path',
  'Max Stor Qty',
  'Max Daily W/D Qty',
  'Max Daily Inj Qty',
  'Out Path',
  'Combo In/Out Path',
}

export enum EContractAttributes {
  RELEASABLE = 2,
  RESTRICTED_PATH = 3,
}

export enum EQuantityTypeId {
  FIRM_QTY = 4,
}

export enum EQuantityPathNames {
  FRM = 'FRM',
}

export enum EFlowDirections {
  RECEIPT = 'R',
  DELIVERY = 'D',
}

export enum EShipperRoleIndicators {
  RETAIL_CHOICE = 'Retail Choice',
  ASSET_MANAGEMENT_ARRANGEMENT = 'Asset Management Arrangement',
}

export enum EOfferCreationProcessSteps {
  OFFER_OPTIONS = 1,
  BID_AND_CONTACT_INFO = 2,
  OFFER_LOCATIONS = 3,
  RATE_INFORMATION = 4,
  OFFER_SUMMARY = 5,
}

export interface OfferLocationOptionsPayload {
  offerId: number;
  locqtiId: string;
  seasnlStartDt: string;
  seasnlEndDt: string;
  capBlockId: number;
  capBlockTwoId?: number;
  locationOneId?: number;
  locationTwoId?: number;
  getDelLocs?: boolean;
}

export interface IOfferLocationOptionDropdown {
  value: LocationModel;
  text: string;
  subText: string;
}

export interface IOfferLocationDropdownBehaviorSubjectDictionary {
  [key: string]: BehaviorSubject<Array<IOfferLocationOptionDropdown>>;
}

export interface IOfferLocationOptionResponses {
  [key: string]: LocationOptionsResponse;
}

export interface ICapBlockMap {
  [locationId: number]: number;
}

export interface IValidationErrorLookup {
  [key: string]: Array<ValidationError>;
}

export interface IErrors {
  [key: string]: boolean;
}

export interface IOfferDisplayDates {
  [key: string]: string;
}

export interface IShorterTerms {
  label: Array<string>;
  days: number;
  shorterTermDescription: string;
}

export enum ERateIdCodes {
  MIN_RATE = 'minAcceptableRate',
  MIN_PERCT = 'minAcceptablePercentage',
  RATE_BID = 'rateBid',
  MAX_PERC_TRF_RATE_BID = 'maxPercentTariffRateBid',
}

export enum EFlowDirection {
  RECEIPT_LOCATION = 'Receipt Location',
  DELIVERY_LOCATION = 'Delivery Location',
  WITHDRAWAL_POINT = 'Withdrawal Point',
}
