import { EPasswordResetActions, ResetPasswordActions } from './password-reset.actions';
import { initialPasswordResetState, IPasswordResetState } from './password-reset.state';

export function passwordResetReducers(
  state = initialPasswordResetState,
  action: ResetPasswordActions
): IPasswordResetState {
  switch (action.type) {
    case EPasswordResetActions.ResetPassword:
      return {
        ...state,
        error: null,
        loading: true,
        passwordReset: false,
      };

    case EPasswordResetActions.ResetPasswordSuccess:
      return {
        ...state,
        error: null,
        loading: false,
        passwordReset: true,
      };

    case EPasswordResetActions.ResetPasswordFailure:
      return {
        ...state,
        loading: false,
        passwordReset: false,
        error: action.payload.error,
      };

    case EPasswordResetActions.ClearPasswordResetState:
      return initialPasswordResetState;

    default:
      return state;
  }
}
