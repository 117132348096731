import { Amendment, DealSheet, ServiceRequest } from '@gms/servicerequest-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { EServiceRequestActions } from '../service-requests/service-requests.actions';

export enum EAmendmentActions {
  FETCH_AMENDMENTS = '[AMENDMENTS] fetch amendments',
  FETCH_AMENDMENTS_SUCCESS = '[AMENDMENTS] fetch amendments success',
  FETCH_AMENDMENTS_FAILURE = '[AMENDMENTS] fetch amendments error',

  FETCH_AMENDMENT_BY_ID = '[AMENDMENTS] fetch amendment by id',
  FETCH_AMENDMENT_BY_ID_SUCCESS = '[AMENDMENTS] fetch amendment by id success',
  FETCH_AMENDMENT_BY_ID_FAILURE = '[AMENDMENTS] fetch amendment by id error',

  FETCH_DEALSHEET_AMENDMENT_BY_ID = '[AMENDMENTS] fetch amendment deal sheet by id',
  FETCH_DEALSHEET_AMENDMENT_BY_ID_SUCCESS = '[AMENDMENTS] fetch amendment deal sheet by id success',
  FETCH_DEALSHEET_AMENDMENT_BY_ID_FAILURE = '[AMENDMENTS] fetch amendment deal sheet by id error',

  CREATE_DEAL_SHEET_AMENDMENT = '[AMENDMENTS] create deal sheet amendment',
  CREATE_DEAL_SHEET_AMENDMENT_SUCCESS = '[AMENDMENTS] create deal sheet amendment success',
  CREATE_DEAL_SHEET_AMENDMENT_FAILURE = '[AMENDMENTS] create deal sheet amendment error',

  UPDATE_DEAL_SHEET_AMENDMENT = '[AMENDMENTS] update deal sheet amendment',
  UPDATE_DEAL_SHEET_AMENDMENT_SUCCESS = '[AMENDMENTS] update deal sheet amendment success',
  UPDATE_DEAL_SHEET_AMENDMENT_FAILURE = '[AMENDMENTS] update deal sheet amendment error',

  CREATE_AMENDMENT = '[AMENDMENTS] create amendment',
  CREATE_AMENDMENT_SUCCESS = '[AMENDMENTS] create amendment success',
  CREATE_AMENDMENT_FAILURE = '[AMENDMENTS] create amendment error',

  UPDATE_AMENDMENT = '[AMENDMENTS] update amendment',
  UPDATE_AMENDMENT_SUCCESS = '[AMENDMENTS] update amendment success',
  UPDATE_AMENDMENT_FAILURE = '[AMENDMENTS] update amendment error',

  UPDATE_EDITING_AMENDMENT = '[AMENDMENTS] update editing amendment',

  UPDATE_CLIENT_SIDE_SERVICE_REQUEST = '[AMENDMENTS] update client side service request',
  ACKNOWLEDGE_NETWORK_REQUEST = '[AMENDMENTS] acknowledge network result',
}

export interface IAmendmentsPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  contractId?: number;
  entityId?: number;
  tspId?: number;
  requestId?: number;
  requestStatus?: string;
  requestStatusIds?: number[];
  lastUpdateBegin?: Date;
  lastUpdateEnd?: Date;
  rateScheduleId?: number;
  assignedToId?: number;
  primaryTermEndDate?: Date;
}

export class FetchAmendments implements Action {
  public readonly type = EAmendmentActions.FETCH_AMENDMENTS;

  constructor(public payload: IAmendmentsPayload) {}
}

export class FetchAmendmentsSuccess implements Action {
  public readonly type = EAmendmentActions.FETCH_AMENDMENTS_SUCCESS;

  constructor(
    public payload: {
      amendments: Array<ServiceRequest>;
      totalAmendmentsCount: number;
    }
  ) {}
}

export class FetchAmendmentsFailure implements Action {
  public readonly type = EAmendmentActions.FETCH_AMENDMENTS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchAmendmentById implements Action {
  public readonly type = EAmendmentActions.FETCH_AMENDMENT_BY_ID;

  constructor(public payload: number) {}
}

export class FetchAmendmentByIdSuccess implements Action {
  public readonly type = EAmendmentActions.FETCH_AMENDMENT_BY_ID_SUCCESS;

  constructor(public payload: { amendment: Amendment }) {}
}

export class FetchAmendmentByIdFailure implements Action {
  public readonly type = EAmendmentActions.FETCH_AMENDMENT_BY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchDealSheetAmendmentById implements Action {
  public readonly type = EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID;

  constructor(public payload: number) {}
}

export class FetchDealSheetAmendmentByIdSuccess implements Action {
  public readonly type = EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID_SUCCESS;

  constructor(public payload: { dealSheetAmendment: DealSheet }) {}
}

export class FetchDealSheetAmendmentByIdFailure implements Action {
  public readonly type = EAmendmentActions.FETCH_DEALSHEET_AMENDMENT_BY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class CreateDealSheetAmendment implements Action {
  public readonly type = EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT;

  constructor(public payload: DealSheet, public prefer?: string) {}
}

export class CreateDealSheetAmendmentSuccess implements Action {
  public readonly type = EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT_SUCCESS;

  constructor(public payload: { amendment: DealSheet }) {}
}

export class CreateDealSheetAmendmentFailure implements Action {
  public readonly type = EAmendmentActions.CREATE_DEAL_SHEET_AMENDMENT_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class CreateAmendment implements Action {
  public readonly type = EAmendmentActions.CREATE_AMENDMENT;

  constructor(public payload: number) {}
}

export class CreateAmendmentSuccess implements Action {
  public readonly type = EAmendmentActions.CREATE_AMENDMENT_SUCCESS;

  constructor(public payload: { amendment: Amendment }) {}
}

export class CreateAmendmentFailure implements Action {
  public readonly type = EAmendmentActions.CREATE_AMENDMENT_FAILURE;

  constructor(public amendment: Amendment) {}
}

export class UpdateDealSheetAmendment implements Action {
  public readonly type = EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT;

  constructor(public payload: DealSheet, public id: number, public prefer?: string) {}
}

export class UpdateDealSheetAmendmentSuccess implements Action {
  public readonly type = EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT_SUCCESS;

  constructor(public payload: { amendment: DealSheet }) {}
}

export class UpdateDealSheetAmendmentFailure implements Action {
  public readonly type = EAmendmentActions.UPDATE_DEAL_SHEET_AMENDMENT_FAILURE;

  constructor(public amendment: DealSheet) {}
}

export class UpdateAmendment implements Action {
  public readonly type = EAmendmentActions.UPDATE_AMENDMENT;

  constructor(public payload: ServiceRequest, public id: number, public prefer?: string) {}
}

export class UpdateAmendmentSuccess implements Action {
  public readonly type = EAmendmentActions.UPDATE_AMENDMENT_SUCCESS;

  constructor(public payload: { amendment: Amendment }) {}
}

export class UpdateAmendmentFailure implements Action {
  public readonly type = EAmendmentActions.UPDATE_AMENDMENT_FAILURE;

  constructor(public amendment: Amendment) {}
}

export class UpdateClientSideAmendedServiceRequest implements Action {
  public readonly type = EAmendmentActions.UPDATE_CLIENT_SIDE_SERVICE_REQUEST;

  constructor(public payload: ServiceRequest) {}
}

export class UpdateEditingAmendment implements Action {
  public readonly type = EAmendmentActions.UPDATE_EDITING_AMENDMENT;

  constructor(public payload: Amendment) {}
}

export class AcknowledgeNetworkResult implements Action {
  public readonly type = EAmendmentActions.ACKNOWLEDGE_NETWORK_REQUEST;
}

export type AmendmentActions =
  | FetchAmendments
  | FetchAmendmentsSuccess
  | FetchAmendmentsFailure
  | FetchAmendmentById
  | FetchAmendmentByIdSuccess
  | FetchAmendmentByIdFailure
  | CreateAmendment
  | CreateAmendmentSuccess
  | CreateAmendmentFailure
  | UpdateAmendment
  | UpdateAmendmentSuccess
  | UpdateAmendmentFailure
  | UpdateClientSideAmendedServiceRequest
  | UpdateEditingAmendment
  | AcknowledgeNetworkResult
  | CreateDealSheetAmendment
  | CreateDealSheetAmendmentSuccess
  | CreateDealSheetAmendmentFailure
  | UpdateDealSheetAmendment
  | UpdateDealSheetAmendmentSuccess
  | UpdateDealSheetAmendmentFailure
  | FetchDealSheetAmendmentById
  | FetchDealSheetAmendmentByIdSuccess
  | FetchDealSheetAmendmentByIdFailure;
