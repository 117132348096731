import get from 'lodash/get';
import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { DynamicStatusTagCellComponent } from 'shared/components/table/dynamic-cell-components/status-tag-cell/status-tag-cell.component';

export class DynamicStatusTagCell {
  dynamicComponentType: typeof DynamicStatusTagCellComponent;
  data: StatusTagCell;
}

export interface StatusTagCell {
  id?: string;
  color: TagColor;
  text: string;
  icon?: StatusTagCellIcon;
  updateOnModelChange?: boolean;
}

interface StatusTagCellIcon {
  iconClass: string;
  onClick?: Function;
}

export const createStatusTagCell = (statusTagCell: StatusTagCell): DynamicStatusTagCell => {
  let id = 'StatusTag';

  if (get(statusTagCell, 'id')) {
    id = get(statusTagCell, 'id') + '_' + id;
  }

  return {
    dynamicComponentType: DynamicStatusTagCellComponent,
    data: {
      ...statusTagCell,
      id: id,
    },
  };
};
