import {
  AccountDetail,
  AccountingPeriod,
  AccountingPeriodCollection,
  CalculatedPriceTypeCollection,
  ChargeTypeCollection,
  ChargeTypeDetailCollection,
  ChargeTypeDropdownDictionary,
  ChargeTypeSummary,
  IndexRate,
  IndexRateDetail,
  IndexRateSummary,
  Invoice,
  InvoiceAttachment,
  InvoiceDetailCollection,
  InvoiceDetailTotalCollection,
  InvoiceError,
  InvoiceFilterCollection,
  InvoiceGroup,
  InvoiceGroupCollection,
  InvoiceSummary,
  MasterChargeType,
  MasterChargeTypeDetail,
  TariffRate,
  TariffRateDetailCollection,
  TariffRateDropdownDictionary,
  TariffRateSummary,
  TariffRateVersionWorkflowAction,
  TspChargeTypeCollection,
  TspChargeTypeDetail,
} from '@gms/billing-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  createInitialDataState,
  createSuccessState,
  emptyRequestStatus,
  IDataState,
  IRequestStatus,
} from 'app/store/app/app.models';
import { ChargeTypesListNavigationState } from 'app/store/billing/billing.utils';

import { getPagination, IPagination } from 'shared/models/pagination.model';
import { IAccountPeriod } from '../../modules/accounting/utils/journal-entries-utils';

export interface IBillingState {
  loading: {
    chargeType: boolean;
    chargeTypeSubmit: boolean;
    chargeTypeDictionary: boolean;
    chargeTypesDetails: boolean;
    tariffRate: boolean;
    tariffRateDictionary: boolean;
    invoice: boolean;
    invoices: boolean;
    invoiceExceptions: boolean;
    invoiceDetail: boolean;
    invoiceDetailsTotal: boolean;
    invoiceFilters: boolean;
    earliestOpenAccountingPeriod: boolean;
    tariffRateDetails: boolean;
    contractDetail: boolean;
    contractDetailsSearch: boolean;
    exportInvoiceDetails: boolean;
    exportInvoiceExceptions: boolean;
    exportTariffRateDetails: boolean;
    tspChargeType: boolean;
    multipleTspChargeTypes: boolean;
    tspReservationChargeType: boolean;
    invoiceGroup: boolean;
    invoiceGroupCollection: boolean;
    allOpenAccountingPeriods: boolean;
    tariffRateVersionWorkflowAction: boolean;
    indexRate: boolean;
    indexRateDetail: boolean;
    calculatedPriceTypes: boolean;
    searchChargeTypes: boolean;
    addOrUpdateTariffRate: boolean;
  };
  error: Error | MasterChargeType | ChargeTypeCollection;
  invoiceExceptionsError: Error | null;
  tariffRateDictionaryError: Error;
  chargeTypesDictionaryError: Error;
  invoiceFilterError: Error;
  contractDetailError: Error;
  chargeTypeByIdError: Error;
  chargeTypesByIdsError: Error;
  accountingPeriodError: Error;
  tspReservationChargeTypesError: Error;
  invoiceGroupError: Error;
  invoiceGroupCollectionError: Error;
  tariffRateVersionWorkflowActionError: Error;
  indexRateError: Error;
  calculatedPriceTypesError: Error;
  tariffRateByIdError: Error;
  addOrUpdateTariffRateError: Error;
  invoiceDetailsError: Error;
  chargeTypes: ChargeTypeCollection;
  chargeTypesDetails: ChargeTypeDetailCollection;
  exportChargeTypesDetails: ChargeTypeDetailCollection;
  tariffRates: TariffRateSummary[];
  masterChargeType: MasterChargeType;
  masterChargeTypeView: MasterChargeTypeDetail;
  exportChargeTypesDetailsLoading: boolean;
  pagination: IPagination;
  chargeTypesDictionary: ChargeTypeDropdownDictionary;
  tariffRate: TariffRate;
  tariffRateDictionary: TariffRateDropdownDictionary;
  totalChargeTypeCount: number;
  totalTariffRateCount: number;
  sortDescriptors: SortDescriptor[];
  typeId: number;
  invoiceFilterCollection: InvoiceFilterCollection;
  invoices: InvoiceSummary[];
  invoice: Invoice;
  invoiceExceptions: InvoiceError[] | null;
  totalInvoiceExceptions: number;
  totalInvoiceCount: number;
  chargeTypesSearch: ChargeTypeSummary[];
  flowLocationsChargeTypesSearch: MasterChargeType[];
  segmentationTotalsChargeTypesSearch: MasterChargeType[];
  invoiceSortDescriptors: SortDescriptor[];
  invoicePagination: IPagination;
  chargeTypeDetailsSortDescriptors: SortDescriptor[];
  chargeTypeSortDescriptors: SortDescriptor[];
  chargeTypePagination: IPagination;
  tariffRatesSortDescriptors: SortDescriptor[];
  tariffRatesPagination: IPagination;
  invoiceDetails: InvoiceDetailCollection;
  invoiceDetailsTotal: InvoiceDetailTotalCollection;
  invoiceDetailsSortDescriptors: SortDescriptor[];
  earliestOpenAccountingPeriod: AccountingPeriod;
  tariffRateDetails: TariffRateDetailCollection;
  exportTariffRateDetails: TariffRateDetailCollection;
  selectedChargeTypes: Array<any>;
  exportInvoiceDetails: InvoiceDetailCollection;
  exportInvoiceExceptions: InvoiceError[] | null;
  tspChargeType: TspChargeTypeDetail;
  selectedTspChargeTypes: Array<TspChargeTypeDetail>;
  tspReservationChargeTypes: TspChargeTypeCollection;
  invoiceGroup: InvoiceGroup;
  chargeTypesNavigationState: ChargeTypesListNavigationState;
  invoiceGroupCollection: InvoiceGroupCollection;
  allOpenAccountingPeriods: AccountingPeriodCollection;
  tariffRateVersionWorkflowAction: TariffRateVersionWorkflowAction;
  tariffRateVersionWorkflowActionSuccess: boolean;
  indexRate: IndexRate;
  indexRateDetail: IndexRateDetail;
  calculatedPriceTypes: CalculatedPriceTypeCollection;
  indexRates: IDataState<IndexRateSummary[]>;
  accounts: IDataState<Array<AccountDetail>>;
  invoiceAttachments: IDataState<Array<InvoiceAttachment>>;
  deleteInvoiceAttachment: IRequestStatus;
  uploadInvoiceAttachment: IRequestStatus;
  downloadInvoicePackage: { loading: boolean; httpError: Error; invoiceId: number };
  palDailyRates: {
    loading: boolean;
    httpError: Error;
    dailyRates: { [contractId: string]: number };
  };
  accountingPeriodCollection: {
    accountingPeriods: AccountingPeriod[];
    defaultAccountingPeriod: IAccountPeriod;
    formattedAccountingPeriods: IAccountPeriod[];
    httpError: Error;
    loading: boolean;
  };
  capRelIndexRates: IDataState<IndexRateSummary[]>;
  effectiveRates: IDataState<Array<any>>;
}
export const initialBillingState: IBillingState = {
  chargeTypes: null,
  chargeTypesDetails: null,
  exportChargeTypesDetails: null,
  tariffRates: [],
  masterChargeType: null,
  masterChargeTypeView: null,
  sortDescriptors: [
    {
      field: 'rateDescription',
      dir: 'asc',
    },
  ],
  loading: {
    chargeType: false,
    chargeTypeSubmit: false,
    chargeTypeDictionary: false,
    chargeTypesDetails: false,
    tariffRate: false,
    tariffRateDictionary: false,
    invoice: false,
    invoices: false,
    invoiceDetail: false,
    invoiceDetailsTotal: false,
    invoiceFilters: false,
    invoiceExceptions: false,
    earliestOpenAccountingPeriod: false,
    tariffRateDetails: false,
    contractDetail: false,
    contractDetailsSearch: false,
    exportInvoiceDetails: false,
    exportInvoiceExceptions: false,
    exportTariffRateDetails: false,
    tspChargeType: false,
    multipleTspChargeTypes: false,
    tspReservationChargeType: false,
    invoiceGroup: false,
    invoiceGroupCollection: false,
    allOpenAccountingPeriods: false,
    tariffRateVersionWorkflowAction: false,
    indexRate: false,
    indexRateDetail: false,
    calculatedPriceTypes: false,
    searchChargeTypes: false,
    addOrUpdateTariffRate: false,
  },
  error: null,
  pagination: getPagination(),
  exportChargeTypesDetailsLoading: false,
  tariffRate: null,
  tariffRateDictionary: null,
  chargeTypesDictionary: null,
  totalChargeTypeCount: 0,
  totalTariffRateCount: 0,
  totalInvoiceCount: 0,
  typeId: null,
  invoiceFilterCollection: null,
  invoices: [],
  invoice: null,
  chargeTypesSearch: [],
  flowLocationsChargeTypesSearch: [],
  segmentationTotalsChargeTypesSearch: [],
  invoiceSortDescriptors: [
    {
      field: 'serviceRequestorName',
      dir: 'asc',
    },
  ],
  invoicePagination: getPagination(),
  chargeTypeDetailsSortDescriptors: [
    {
      field: 'intChargeTypeAbbr',
      dir: 'asc',
    },
  ],
  chargeTypeSortDescriptors: [
    {
      field: 'internalAbbreviation',
      dir: 'asc',
    },
  ],
  chargeTypePagination: getPagination(),
  tariffRatesSortDescriptors: [
    {
      field: 'chargeTypeDescription',
      dir: 'asc',
    },
  ],
  tariffRatesPagination: getPagination(),
  invoiceDetails: null,
  invoiceDetailsTotal: null,
  invoiceDetailsSortDescriptors: [
    {
      field: 'serviceRequestorName',
      dir: 'asc',
    },
  ],
  tariffRateDictionaryError: null,
  chargeTypesDictionaryError: null,
  invoiceFilterError: null,
  earliestOpenAccountingPeriod: null,
  accountingPeriodError: null,
  tariffRateDetails: null,
  contractDetailError: null,
  chargeTypeByIdError: null,
  chargeTypesByIdsError: null,
  selectedChargeTypes: [],
  exportInvoiceDetails: null,
  exportInvoiceExceptions: [],
  exportTariffRateDetails: null,
  tspChargeType: null,
  selectedTspChargeTypes: [],
  tspReservationChargeTypes: null,
  tspReservationChargeTypesError: null,
  invoiceGroup: null,
  invoiceGroupError: null,
  chargeTypesNavigationState: {
    pagination: null,
    sortDescriptors: [],
    tsp: null,
    headerFilters: null,
    quickSearchFilters: null,
    hiddenColumns: [],
    listView: 'Summary',
  },
  invoiceExceptions: [],
  invoiceExceptionsError: null,
  totalInvoiceExceptions: null,
  invoiceGroupCollection: null,
  invoiceGroupCollectionError: null,
  allOpenAccountingPeriods: null,
  tariffRateVersionWorkflowAction: null,
  tariffRateVersionWorkflowActionSuccess: false,
  tariffRateVersionWorkflowActionError: null,
  indexRate: null,
  indexRateDetail: null,
  indexRateError: null,
  calculatedPriceTypes: null,
  calculatedPriceTypesError: null,
  indexRates: createSuccessState([]),
  accounts: createSuccessState([]),
  invoiceAttachments: createSuccessState(null),
  deleteInvoiceAttachment: { ...emptyRequestStatus },
  uploadInvoiceAttachment: { ...emptyRequestStatus },
  downloadInvoicePackage: { ...emptyRequestStatus, invoiceId: null },
  palDailyRates: { ...emptyRequestStatus, dailyRates: {} },
  tariffRateByIdError: null,
  addOrUpdateTariffRateError: null,
  invoiceDetailsError: null,
  accountingPeriodCollection: {
    accountingPeriods: [],
    defaultAccountingPeriod: null,
    formattedAccountingPeriods: [],
    httpError: null,
    loading: false,
  },
  capRelIndexRates: createInitialDataState(),
  effectiveRates: createInitialDataState([]),
};
