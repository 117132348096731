import { Routes } from '@angular/router';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption, EUserType } from 'app/store/auth/model/models';

export const Merger_Assignments_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/merger-assignments/merger-assignments.module').then(
        m => m.MergerAssignmentsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/merger-assignments',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('./pages/create-merger-assignment/create-merger-assignment.module').then(
        m => m.CreateMergerAssignmentModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/merger-assignments/new',
      aclAccessLevel: EPermissionOption.Edit,
      aclUserType: EUserType.Internal,
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./pages/merger-assignment-detail/merger-assignment-detail.module').then(
        m => m.MergerAssignmentDetailModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/merger-assignments/{id}',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
  },
];
