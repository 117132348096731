import { NotificationType } from '@gms/communication-api';

export interface ICommunicationState {
  notificationTypes: NotificationType[];
  fetchNotificationTypesLoading: boolean;
  fetchNotificationTypesError: Error;
}

export const initialCommunicationState: ICommunicationState = {
  notificationTypes: [],
  fetchNotificationTypesError: null,
  fetchNotificationTypesLoading: false,
};
