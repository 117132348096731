import { HttpErrorResponse } from '@angular/common/http';

import { ModelLineSequence, ModelSequenceLocation, ModelStatuses } from '@gms/pipeline-api';
import { createErrorState, createLoadingState, createSuccessState } from '../app/app.models';
import { EHeaderActions, HeaderActions } from '../header/header.actions';
import { EPipelineModelsActions, PipelineModelsActions } from './pipeline-models.actions';
import {
  EPipelineError,
  ESequenceValidationError,
  initialPipelineModelsState,
  IPipelineModelsState,
} from './pipeline-models.state';

const SEQUENCE_VALIDATION_ERROR_MAPPINGS: { [key: string]: ESequenceValidationError } = {
  SequenceNumber: ESequenceValidationError.DUPLICATE_SEQUENCE_NUMBER_ERROR,
  SequenceName: ESequenceValidationError.DUPLICATE_SEQUENCE_NAME_ERROR,
};

//need to use the function format for AOT
export function pipelineModelsReducers(
  state = initialPipelineModelsState,
  action: PipelineModelsActions | HeaderActions
): IPipelineModelsState {
  switch (action.type) {
    case EPipelineModelsActions.FETCH_PIPELINE_MODELS:
      return {
        ...state,
        error: null,
        loading: true,
        modelLines: [],
        modelSequences: new Map<number, Array<ModelLineSequence>>(),
        modelSequencesErrorForLine: new Map<number, Error>(),
        modelSequencesLoadingForLine: new Map<number, boolean>(),
        modelSequenceLocations: new Map<number, Array<ModelSequenceLocation>>(),
        modelSequenceLocationsErrorForSequence: new Map<number, Error>(),
        modelSequenceLocationsLoadingForSequence: new Map<number, boolean>(),
        pipelineModels: [],
        status: {
          ...state.status,
          deletePipelineModel: { ...state.status.deletePipelineModel, httpError: null },
        },
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
      };

    case EPipelineModelsActions.FETCH_PIPELINE_MODELS_SUCCESS:
      return {
        ...state,
        loading: false,
        pipelineModels: action.payload.pipelineModelCollection.pipelineModels,
        totalPipelineCount: action.payload.pipelineModelCollection.total,
      };

    case EPipelineModelsActions.FETCH_PIPELINE_MODELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case EPipelineModelsActions.CLEAR_HISTORICAL_PIPELINE_MODEL:
      return {
        ...state,
        historicalPipelineModel: null,
        historicalModelLines: [],
      };

    case EPipelineModelsActions.SET_HISTORICAL_PIPELINE_MODEL:
      return {
        ...state,
        historicalPipelineModel: action.payload.pipelineModel,
      };

    case EPipelineModelsActions.FETCH_MODEL_LINES:
      return {
        ...state,
        modelLinesLoading: true,
        modelLinesError: null,
        modelLines: [],
        modelSequences: new Map<number, Array<ModelLineSequence>>(),
        modelSequencesErrorForLine: new Map<number, Error>(),
        modelSequencesLoadingForLine: new Map<number, boolean>(),
        modelSequenceLocations: new Map<number, Array<ModelSequenceLocation>>(),
        modelSequenceLocationsErrorForSequence: new Map<number, Error>(),
        modelSequenceLocationsLoadingForSequence: new Map<number, boolean>(),
      };

    case EPipelineModelsActions.FETCH_MODEL_LINES_SUCCESS:
      const newState = {
        ...state,
        modelLinesLoading: false,
      };
      if (action.payload.areHistoricalModelLines) {
        newState.historicalModelLines = action.payload.modelLines;
      } else {
        newState.modelLines = action.payload.modelLines;
      }
      return newState;

    case EPipelineModelsActions.FETCH_MODEL_LINES_FAILURE:
      return {
        ...state,
        modelLinesLoading: false,
        modelLinesError: action.payload.error,
      };

    case EPipelineModelsActions.FETCH_UNASSOCIATED_MODEL_LINES:
      return {
        ...state,
        status: {
          ...state.status,
          unassociatedModelLinesStatus: {
            loading: true,
            httpError: null,
          },
        },
      };

    case EPipelineModelsActions.FETCH_UNASSOCIATED_MODEL_LINES_SUCCESS:
      return {
        ...state,
        unassociatedModelLines: action.payload.unassociatedModelLines,
        status: {
          ...state.status,
          unassociatedModelLinesStatus: {
            loading: false,
            httpError: null,
          },
        },
      };

    case EPipelineModelsActions.FETCH_UNASSOCIATED_MODEL_LINES_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          unassociatedModelLinesStatus: {
            loading: false,
            httpError: action.payload.error,
          },
        },
      };

    case EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES:
      return {
        ...state,
        modelSequences: copyAndUpdateMap(state.modelSequences, action.payload.lineId, null),
        modelSequencesErrorForLine: copyAndUpdateMap(
          state.modelSequencesErrorForLine,
          action.payload.lineId,
          null
        ),
        modelSequencesLoadingForLine: copyAndUpdateMap(
          state.modelSequencesLoadingForLine,
          action.payload.lineId,
          true
        ),
      };

    case EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_SUCCESS:
      return {
        ...state,
        modelSequences: copyAndUpdateMap(
          state.modelSequences,
          action.payload.lineId,
          action.payload.modelSequences
        ),
        modelSequencesLoadingForLine: copyAndUpdateMap(
          state.modelSequencesLoadingForLine,
          action.payload.lineId,
          false
        ),
      };

    case EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_FAILURE:
      return {
        ...state,
        modelSequencesErrorForLine: copyAndUpdateMap(
          state.modelSequencesErrorForLine,
          action.payload.lineId,
          action.payload.error
        ),
        modelSequencesLoadingForLine: copyAndUpdateMap(
          state.modelSequencesLoadingForLine,
          action.payload.lineId,
          false
        ),
      };

    case EPipelineModelsActions.FETCH_MODEL_SEQUENCE_LOCATIONS:
      return {
        ...state,
        modelSequenceLocations: copyAndUpdateMap(
          state.modelSequenceLocations,
          action.payload.sequenceId,
          null
        ),
        modelSequenceLocationsErrorForSequence: copyAndUpdateMap(
          state.modelSequenceLocationsErrorForSequence,
          action.payload.sequenceId,
          null
        ),
        modelSequenceLocationsLoadingForSequence: copyAndUpdateMap(
          state.modelSequenceLocationsLoadingForSequence,
          action.payload.sequenceId,
          true
        ),
      };

    case EPipelineModelsActions.FETCH_MODEL_SEQUENCE_LOCATIONS_SUCCESS:
      return {
        ...state,
        modelSequenceLocations: copyAndUpdateMap(
          state.modelSequenceLocations,
          action.payload.sequenceId,
          action.payload.modelSequenceLocations
        ),
        modelSequenceLocationsLoadingForSequence: copyAndUpdateMap(
          state.modelSequenceLocationsLoadingForSequence,
          action.payload.sequenceId,
          false
        ),
      };

    case EPipelineModelsActions.FETCH_MODEL_SEQUENCE_LOCATIONS_FAILURE:
      return {
        ...state,
        modelSequenceLocationsErrorForSequence: copyAndUpdateMap(
          state.modelSequenceLocationsErrorForSequence,
          action.payload.sequenceId,
          action.payload.error
        ),
        modelSequenceLocationsLoadingForSequence: copyAndUpdateMap(
          state.modelSequenceLocationsLoadingForSequence,
          action.payload.sequenceId,
          false
        ),
      };

    case EPipelineModelsActions.FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS:
      return {
        ...state,
        availableModelSequenceLocations: createLoadingState(state.availableModelSequenceLocations),
      };

    case EPipelineModelsActions.FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS_SUCCESS:
      return {
        ...state,
        availableModelSequenceLocations: createSuccessState(action.payload.locations),
      };

    case EPipelineModelsActions.FETCH_AVAILABLE_MODEL_SEQUENCE_LOCATIONS_FAILURE:
      return {
        ...state,
        availableModelSequenceLocations: createErrorState(action.payload.error),
      };

    case EPipelineModelsActions.CREATE_LINE:
      return {
        ...state,
        status: {
          ...state.status,
          createLine: {
            httpError: null,
            loading: true,
            validationError: EPipelineError.PENDING,
          },
        },
      };

    case EPipelineModelsActions.CREATE_LINE_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          createLine: {
            httpError: null,
            loading: false,
            validationError: EPipelineError.NONE,
          },
        },
      };

    case EPipelineModelsActions.CREATE_LINE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          createLine: {
            httpError: action.payload.error,
            loading: false,
            validationError: action.payload.validationError,
          },
        },
      };

    case EPipelineModelsActions.UPDATE_LINE:
      return {
        ...state,
        status: {
          ...state.status,
          updateLine: {
            httpError: null,
            loading: true,
            validationError: EPipelineError.PENDING,
          },
        },
      };

    case EPipelineModelsActions.UPDATE_LINE_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          updateLine: {
            httpError: null,
            loading: false,
            validationError: EPipelineError.NONE,
          },
        },
      };

    case EPipelineModelsActions.UPDATE_LINE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          updateLine: {
            httpError: action.payload.error,
            loading: false,
            validationError: action.payload.validationError,
          },
        },
      };

    case EPipelineModelsActions.CREATE_SEQUENCE:
      return {
        ...state,
        status: {
          ...state.status,
          createSequence: {
            httpError: null,
            loading: true,
            validationError: null,
          },
        },
      };

    case EPipelineModelsActions.CREATE_SEQUENCE_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          createSequence: {
            httpError: null,
            loading: false,
            validationError: null,
          },
        },
      };

    case EPipelineModelsActions.CREATE_SEQUENCE_FAILURE: {
      const validationError =
        SEQUENCE_VALIDATION_ERROR_MAPPINGS[(action.payload.error as HttpErrorResponse).error] ||
        ESequenceValidationError.API_ERROR;
      return {
        ...state,
        status: {
          ...state.status,
          createSequence: {
            httpError: action.payload.error,
            loading: false,
            validationError,
          },
        },
      };
    }

    case EPipelineModelsActions.UPDATE_SEQUENCE:
      return {
        ...state,
        status: {
          ...state.status,
          updateSequence: {
            httpError: null,
            loading: true,
            validationError: null,
          },
        },
      };

    case EPipelineModelsActions.UPDATE_SEQUENCE_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          updateSequence: {
            httpError: null,
            loading: false,
            validationError: null,
          },
        },
      };

    case EPipelineModelsActions.UPDATE_SEQUENCE_FAILURE: {
      const validationError =
        SEQUENCE_VALIDATION_ERROR_MAPPINGS[(action.payload.error as HttpErrorResponse).error] ||
        ESequenceValidationError.API_ERROR;
      return {
        ...state,
        status: {
          ...state.status,
          updateSequence: {
            httpError: action.payload.error,
            loading: false,
            validationError,
          },
        },
      };
    }

    case EPipelineModelsActions.CLEAR_SEQUENCE_VALIDATION_ERROR: {
      return {
        ...state,
        status: {
          ...state.status,
          createSequence: {
            ...state.status.createSequence,
            validationError: null,
          },
        },
      };
    }

    case EPipelineModelsActions.RESET_SELECTED_LINE_AND_SEQUENCE:
      return { ...state, selectedElements: null };

    case EPipelineModelsActions.SET_SELECTED_LINE_AND_SEQUENCE:
      return {
        ...state,
        selectedElements: {
          modelLine: action.payload.selectedModelLine,
          modelLineSequence: action.payload.selectedModelLineSequence,
        },
      };

    case EPipelineModelsActions.SET_PIPELINE_MODEL_PRIORITY:
      return {
        ...state,
        pipelineModelPriority: action.payload.pipelineModelPriority,
        error: null,
        loading: true,
        modelLines: [],
        modelSequences: new Map<number, Array<ModelLineSequence>>(),
        modelSequencesErrorForLine: new Map<number, Error>(),
        modelSequencesLoadingForLine: new Map<number, boolean>(),
        modelSequenceLocations: new Map<number, Array<ModelSequenceLocation>>(),
        modelSequenceLocationsErrorForSequence: new Map<number, Error>(),
        modelSequenceLocationsLoadingForSequence: new Map<number, boolean>(),
        pipelineModels: [],
        status: {
          ...state.status,
          deletePipelineModel: { ...state.status.deletePipelineModel, httpError: null },
        },
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
      };

    case EPipelineModelsActions.ACTIVATE_DRAFT:
      return {
        ...state,
        status: { ...state.status, activateDraft: { httpError: null, loading: true } },
      };

    case EPipelineModelsActions.ACTIVATE_DRAFT_SUCCESS:
      return {
        ...state,
        pipelineModelDelta: null,
        status: {
          ...state.status,
          activateDraft: { ...state.status.activateDraft, loading: false },
        },
      };

    case EPipelineModelsActions.ACTIVATE_DRAFT_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          activateDraft: { httpError: action.payload.error, loading: false },
        },
      };

    case EPipelineModelsActions.SAVE_DRAFT:
      return {
        ...state,
        status: { ...state.status, saveDraft: { httpError: null, loading: true } },
      };

    case EPipelineModelsActions.SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          saveDraft: { ...state.status.saveDraft, loading: false },
        },
      };

    case EPipelineModelsActions.SAVE_DRAFT_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          saveDraft: { httpError: action.payload.error, loading: false },
        },
      };

    case EPipelineModelsActions.DELETE_PIPELINE_MODEL:
      return {
        ...state,
        status: { ...state.status, deletePipelineModel: { httpError: null, loading: true } },
      };

    case EPipelineModelsActions.DELETE_PIPELINE_MODEL_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          deletePipelineModel: { ...state.status.deletePipelineModel, loading: false },
        },
      };

    case EPipelineModelsActions.DELETE_PIPELINE_MODEL_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          deletePipelineModel: { httpError: action.payload.error, loading: false },
        },
      };

    case EPipelineModelsActions.CLEAR_DELETE_PIPELINE_MODEL_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          deletePipelineModel: { ...state.status.deletePipelineModel, httpError: null },
        },
      };

    // Should we really handle this action in here?
    case EHeaderActions.SET_TSP:
      return {
        ...state,
        error: null,
        loading: false,
        modelLines: [],
        modelSequences: new Map<number, Array<ModelLineSequence>>(),
        modelSequencesErrorForLine: new Map<number, Error>(),
        modelSequencesLoadingForLine: new Map<number, boolean>(),
        modelSequenceLocations: new Map<number, Array<ModelSequenceLocation>>(),
        modelSequenceLocationsErrorForSequence: new Map<number, Error>(),
        modelSequenceLocationsLoadingForSequence: new Map<number, boolean>(),
        pipelineModelPriority: [ModelStatuses.Active, ModelStatuses.Pending, ModelStatuses.Draft],
        pipelineModels: [],
      };

    case EPipelineModelsActions.CREATE_DRAFT:
      return {
        ...state,
        modelLines: [],
        modelSequences: new Map<number, Array<ModelLineSequence>>(),
        modelSequencesErrorForLine: new Map<number, Error>(),
        modelSequencesLoadingForLine: new Map<number, boolean>(),
        modelSequenceLocations: new Map<number, Array<ModelSequenceLocation>>(),
        modelSequenceLocationsErrorForSequence: new Map<number, Error>(),
        modelSequenceLocationsLoadingForSequence: new Map<number, boolean>(),
        pipelineModelPriority: [ModelStatuses.Draft, ModelStatuses.Active],
        status: { ...state.status, createDraft: { httpError: null, loading: true } },
      };

    case EPipelineModelsActions.CREATE_DRAFT_SUCCESS:
      return {
        ...state,
        pipelineModels: [...state.pipelineModels, action.payload.pipelineModel],
        status: {
          ...state.status,
          createDraft: { httpError: null, loading: false },
        },
      };

    case EPipelineModelsActions.CREATE_DRAFT_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          createDraft: { httpError: action.payload.error, loading: false },
        },
      };

    case EPipelineModelsActions.FETCH_PIPELINE_MODEL_DELTA:
      return {
        ...state,
        pipelineModelDelta: null,
        status: {
          ...state.status,
          pipelineModelDelta: { httpError: null, loading: true },
        },
      };

    case EPipelineModelsActions.FETCH_PIPELINE_MODEL_DELTA_SUCCESS:
      return {
        ...state,
        pipelineModelDelta: action.payload.pipelineModelDelta,
        status: {
          ...state.status,
          pipelineModelDelta: { httpError: null, loading: false },
        },
      };

    case EPipelineModelsActions.FETCH_PIPELINE_MODEL_DELTA_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          pipelineModelDelta: { httpError: action.payload.error, loading: false },
        },
      };

    case EPipelineModelsActions.FETCH_LOOKUP_DATA:
      return {
        ...state,
        status: {
          ...state.status,
          lookupData: { httpError: null, loading: true },
        },
      };

    case EPipelineModelsActions.FETCH_LOOKUP_DATA_SUCCESS:
      return {
        ...state,
        lookupData: action.payload.lookupData,
        status: {
          ...state.status,
          lookupData: { httpError: null, loading: false },
        },
      };

    case EPipelineModelsActions.FETCH_LOOKUP_DATA_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          lookupData: { httpError: action.payload.error, loading: false },
        },
      };

    case EPipelineModelsActions.RESET_CREATE_AND_UPDATE_STATUSES:
      return {
        ...state,
        status: {
          ...state.status,
          activateDraft: {
            httpError: null,
            loading: false,
          },
          createDraft: {
            httpError: null,
            loading: false,
          },
          createLine: {
            httpError: null,
            loading: false,
            validationError: null,
          },
          createSequence: {
            httpError: null,
            loading: false,
            validationError: null,
          },
          updateSequence: {
            httpError: null,
            loading: false,
            validationError: null,
          },
          deletePipelineModel: {
            httpError: null,
            loading: false,
          },
          saveDraft: {
            httpError: null,
            loading: false,
          },
          updateLine: {
            httpError: null,
            loading: false,
            validationError: null,
          },
        },
      };

    case EPipelineModelsActions.FETCH_LINE_SEQUENCES_BY_IDS:
      return {
        ...state,
        lineSequenceDetails: [],
        status: { ...state.status, lineSequenceDetails: { httpError: null, loading: true } },
      };

    case EPipelineModelsActions.FETCH_LINE_SEQUENCES_BY_IDS_SUCCESS:
      return {
        ...state,
        lineSequenceDetails: action.payload.lineSequenceDetails,
        status: {
          ...state.status,
          lineSequenceDetails: { httpError: null, loading: false },
        },
      };

    case EPipelineModelsActions.FETCH_LINE_SEQUENCES_BY_IDS_FAILURE:
      return {
        ...state,
        lineSequenceDetails: [],
        status: {
          ...state.status,
          lineSequenceDetails: { httpError: action.payload.error, loading: false },
        },
      };

    case EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS:
      return {
        ...state,
        status: {
          ...state.status,
          modelLineSequencesByLineIds: {
            httpError: null,
            loading: true,
          },
        },
      };

    case EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          modelLineSequencesByLineIds: {
            httpError: action.error,
            loading: false,
          },
        },
      };

    case EPipelineModelsActions.FETCH_MODEL_LINE_SEQUENCES_BY_MULTIPLE_IDS_SUCCESS:
      return {
        ...state,
        modelLineSequencesByLineIds: {
          ...state.modelLineSequencesByLineIds,
          ...action.payload.lineId.reduce(
            (acc, lineId, i) => ({
              ...acc,
              [lineId]: action.payload.modelLineSequencesByIds[i],
            }),
            {}
          ),
        },
        status: {
          ...state.status,
          modelLineSequencesByLineIds: {
            httpError: null,
            loading: false,
          },
        },
      };

    default:
      return state;
  }
}

function copyAndUpdateMap<K, V>(map: Map<K, V>, keyToUpdate: K, newValue: V): Map<K, V> {
  const newMap = new Map(map);
  newMap.set(keyToUpdate, newValue);

  return newMap;
}
