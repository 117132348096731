import { Injectable } from '@angular/core';
import { RateScheduleService } from '@gms/rateschedulev2-api';
import { RatchetLevelTemplatesService } from '@gms/servicerequest-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import {
  ERatchetLevelsActions,
  FetchRatchetLevelCollection,
  FetchRatchetLevelCollectionError,
  FetchRatchetLevelCollectionSuccess,
  FetchRatchetLevels,
  FetchRatchetLevelsError,
  FetchRatchetLevelsSuccess,
  FetchTariffRatchetCollection,
  FetchTariffRatchetCollectionError,
  FetchTariffRatchetCollectionSuccess,
} from './ratchet-levels.actions';

@Injectable({ providedIn: 'root' })
export class RatchetLevelsEffect {
  constructor(
    private actions$: Actions,
    private ratchetLevelService: RatchetLevelTemplatesService,
    private rateScheduleService: RateScheduleService
  ) {}

  FetchRatchetLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchRatchetLevels>(ERatchetLevelsActions.FETCH_RATCHET_LEVELS),
      switchMap(body => {
        return this.ratchetLevelService.getRatchetLevelTemplates().pipe(
          mergeMap(ratchetLevels => {
            return of(new FetchRatchetLevelsSuccess(ratchetLevels));
          }),
          catchError(error => {
            return of(new FetchRatchetLevelsError(error));
          })
        );
      })
    )
  );

  FetchRatchetLevelCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchRatchetLevelCollection>(ERatchetLevelsActions.FETCH_RATCHET_LEVEL_COLLECTION),
      switchMap(body => {
        return this.rateScheduleService.getRatchetLevels(body.payload.detailRateScheduleId).pipe(
          mergeMap(ratchetLevelCollection => {
            return of(new FetchRatchetLevelCollectionSuccess(ratchetLevelCollection));
          }),
          catchError(error => {
            return of(new FetchRatchetLevelCollectionError(error));
          })
        );
      })
    )
  );

  FetchTariffRatchetCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchTariffRatchetCollection>(ERatchetLevelsActions.FETCH_TARIFF_RATCHET_COLLECTION),
      switchMap(body => {
        return this.rateScheduleService.getTariffRatchets(body.payload.detailRateScheduleId).pipe(
          mergeMap(tariffRatchetCollection => {
            return of(new FetchTariffRatchetCollectionSuccess(tariffRatchetCollection));
          }),
          catchError(error => {
            return of(new FetchTariffRatchetCollectionError(error));
          })
        );
      })
    )
  );
}
