import { RatchetLevelCollection, TariffRatchetCollection } from '@gms/rateschedulev2-api';
import { RatchetLevelTemplateCollection } from '@gms/servicerequest-api';

export interface IRatchetLevelsState {
  ratchetLevelTemplates: RatchetLevelTemplateCollection;
  ratchetLevelCollection: RatchetLevelCollection;
  tariffRatchetCollection: TariffRatchetCollection;
  loading: boolean;
  error: boolean;
}

export const initialRatchetLevelsState: IRatchetLevelsState = {
  ratchetLevelTemplates: {},
  ratchetLevelCollection: {},
  tariffRatchetCollection: {},
  error: false,
  loading: false,
};
