import { HttpErrorResponse } from '@angular/common/http';
import {
  Amendment,
  AmendmentsCollection,
  Contract,
  ContractDocument,
  ContractDocumentCollection,
  ContractEdiTransactionSet,
  ContractEdiTransactionSetCollection,
  ContractEntityCollection,
  ContractGeneralCollection,
  ContractHeader,
  ContractLocationHeaders,
  ContractMergerAssignmentCollection,
  ContractQuantity,
  ContractQuantityCollection,
  LookupCollections,
  QuantityChange,
  QuantityChangesCollection,
  QuantityLocation,
  QuantityLocationChange,
  QuantityLocationChangePointPair,
  QuantityLocationChangesCollection,
  QuantityLocationChangesPointPairCollection,
  QuantityLocationCollection,
  QuantityLocationPointPair,
  QuantityLocationPointPairCollection,
  QuantityPath,
  QuantityPathCollection,
  RateSchedule,
  SupplementalRestrictionCollection,
  WorkflowKgenInitiationRequest,
} from '@gms/contract-api';
import { Entity } from '@gms/entity-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { AmendmentDropdownOption } from 'app/modules/contracts/enums';
import { RetryableAction } from '../retry/retry.actions';
import {
  DetailRateScheduleContract,
  IContractHeader,
  IContractRequest,
  IContractsHeader,
  IGetContractsPayload,
  ISearchContracts,
} from './contracts.model';

export enum EContractsActions {
  FETCH_AMENDMENTS_BY_CONTRACT_ID = '[CONTRACTS] fetch Amendments by Contract id',
  FETCH_AMENDMENTS_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] fetch Amendments by Contract id Success',
  FETCH_AMENDMENTS_BY_CONTRACT_ID_ERROR = '[CONTRACTS] fetch Amendments by Contract id Error',

  FETCH_CONTRACTS = '[CONTRACTS] fetch Contracts',
  FETCH_CONTRACTS_SUCCESS = '[CONTRACTS] fetch Contracts Success',
  FETCH_CONTRACTS_ERROR = '[CONTRACTS] fetch Contracts Error',
  CLEAR_CONTRACTS = '[CONTRACTS] clear Contracts',

  FETCH_CONTRACTS_HEADERS = '[CONTRACTS] Fetch All Contract Headers',
  FETCH_CONTRACTS_HEADERS_SUCCESS = '[CONTRACTS] Fetch All Contract Headers Success',
  FETCH_CONTRACTS_HEADERS_ERROR = '[CONTRACTS] Fetch All Contract Headers Error',

  FETCH_CONTRACTS_HEADERS_STORAGE = '[CONTRACTS] Fetch All Contract Headers for Storage',
  FETCH_CONTRACTS_HEADERS_STORAGE_SUCCESS = '[CONTRACTS] Fetch All Contract Headers for Storage Success',
  FETCH_CONTRACTS_HEADERS_STORAGE_ERROR = '[CONTRACTS] Fetch All Contract Headers for Storage Error',

  FETCH_CONTRACT_HEADER = '[CONTRACTS] Fetch Contract Header',
  FETCH_CONTRACT_HEADER_SUCCESS = '[CONTRACTS] Fetch Contract Header Success',
  FETCH_CONTRACT_HEADER_ERROR = '[CONTRACTS] Fetch Contract Header Error',
  CLEAR_CONTRACT_HEADER = '[CONTRACTS] Clear Contract Header',

  FETCH_CONTRACT_BY_ID = '[CONTRACTS] fetch Contract by id',
  FETCH_CONTRACT_BY_ID_SUCCESS = '[CONTRACTS] fetch Contract by Id Success',
  FETCH_CONTRACT_BY_ID_ERROR = '[CONTRACTS] fetch Contract by Id Error',

  FETCH_CONTRACTS_LIST = '[CONTRACTS] Fetch Contracts List',
  FETCH_CONTRACTS_LIST_SUCCESS = '[CONTRACTS] Fetch Contracts List Success',
  FETCH_CONTRACTS_LIST_FAILURE = '[CONTRACTS] Fetch Contracts List Failure',

  SEARCH_CONTRACTS = '[CONTRACTS] search contracts',
  SEARCH_CONTRACTS_SUCCESS = '[CONTRACTS] search contracts Success',
  SEARCH_CONTRACTS_FAILURE = '[CONTRACTS] search contracts failure',
  CLEAR_SEARCH_CONTRACTS = '[CONTRACTS] clear searched contracts',

  SET_CONTRACT = '[CONTRACTS] Set Contract',

  FETCH_CONTRACT_LOCATION_HEADERS = '[CONTRACTS] Fetch Contract Location Headers Collection',
  FETCH_CONTRACT_LOCATION_HEADERS_SUCCESS = '[CONTRACTS] Fetch Contract Location Headers Collection Success',
  FETCH_CONTRACT_LOCATION_HEADERS_FAILURE = '[CONTRACTS] Fetch Contract Location Headers Collection Failure',

  FETCH_LOOKUP_COLLECTIONS = '[CONTRACTS] Fetch Lookup Collections',
  FETCH_LOOKUP_COLLECTIONS_SUCCESS = '[CONTRACTS] Fetch Lookup Collections Success',
  FETCH_LOOKUP_COLLECTIONS_FAILURE = '[CONTRACTS] Fetch Lookup Collections Failure',
  CLEAR_LOOKUP_COLLECTIONS_ERROR = '[CONTRACTS] Clear Lookup Collections Error',

  FETCH_CONTRACT_RATE_SCHEDULES = '[CONTRACTS] Fetch Contract Rate Schedules',
  FETCH_CONTRACT_RATE_SCHEDULES_SUCCESS = '[CONTRACTS] Fetch Contract Rate Schedules Success',
  FETCH_CONTRACT_RATE_SCHEDULES_FAILURE = '[CONTRACTS] Fetch Contract Rate Schedules Failure',

  FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Quantities By Contract Id',
  FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Quantities By Contract Id Success',
  FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Quantities By Contract Id Failure',

  FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Locations Quantities By Contract Id',
  FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Locations Quantities By Contract Id Success',
  FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Locations Quantities By Contract Id Failure',

  FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Location Quantities Changes By Contract Id',
  FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Location Quantities Changes By Contract Id Success',
  FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Location Quantities Changes By Contract Id Failure',

  FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Quantities Changes by Contract Id',
  FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Quantities Changes by Contract Id Success',
  FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Quantities Changes by Contract Id Failure',

  FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Locations Point Pair Quantities By Contract Id',
  FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Locations Point Pair Quantities By Contract Id Success',
  FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Locations Point Pair Quantities By Contract Id Failure',

  FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Location Point Pair Quantities Changes By Contract Id',
  FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Location Point Pair Quantities Changes By Contract Id Success',
  FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Location Point Pair Quantities Changes By Contract Id Failure',

  FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Path Quantities By Contract Id',
  FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Path Quantities By Contract Id Success',
  FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Path Quantities By Contract Id Failure',

  FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract EDI By Contract Id',
  FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract EDI By Contract Id Success',
  FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract EDI By Contract Id Failure',

  FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS = '[CONTRACTS] Fetch Contract Entities By Rate Schedule Ids',
  FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS_SUCCESS = '[CONTRACTS] Fetch Contract Entities By Rate Schedule Ids Success',
  FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS_FAILURE = '[CONTRACTS] Fetch Contract Entities By Rate Schedule Ids Failure',

  UPDATE_CONTRACT_QUANTITIES = '[CONTRACTS] Update Contract Quantities',
  UPDATE_CONTRACT_QUANTITIES_SUCCESS = '[CONTRACTS] Update Contract Quantities Success',
  UPDATE_CONTRACT_QUANTITIES_FAILURE = '[CONTRACTS] Update Contract Quantities Failure',

  UPDATE_CONTRACT_PATH_QUANTITIES = '[CONTRACTS] Update Contract Path Quantities',
  UPDATE_CONTRACT_PATH_QUANTITIES_SUCCESS = '[CONTRACTS] Update Contract Path Quantities Success',
  UPDATE_CONTRACT_PATH_QUANTITIES_FAILURE = '[CONTRACTS] Update Contract Quantities Path Failure',

  CLEAR_CONTRACT_PATH_QUANTITIES_STATUS = '[CONTRACTS] Clear Contract Path Quantities Status',

  UPDATE_CONTRACT_EDI_TRANSACTION_SET = '[CONTRACTS] Update EDI Transaction Set',
  UPDATE_CONTRACT_EDI_TRANSACTION_SET_SUCCESS = '[CONTRACTS] Update Contract EDI Transaction Set Success',
  UPDATE_CONTRACT_EDI_TRANSACTION_SET_FAILURE = '[CONTRACTS] Update Contract EDI Transaction Set Failure',

  UPDATE_CONTRACT_LOCATION_QUANTITIES = '[CONTRACTS] Update Contract Location Quantities',
  UPDATE_CONTRACT_LOCATION_QUANTITIES_SUCCESS = '[CONTRACTS] Update Contract Location Quantities Success',
  UPDATE_CONTRACT_LOCATION_QUANTITIES_FAILURE = '[CONTRACTS] Update Contract Location Quantities Failure',
  UPDATE_CONTRACT_LOCATION_QUANTITIES_RESET = '[CONTRACTS] Update Contract Location Quantities Reset',

  UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES = '[CONTRACTS] Update Contract Location Point Pair Quantities',
  UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_SUCCESS = '[CONTRACTS] Update Contract Location Point Pair Quantities Success',
  UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_FAILURE = '[CONTRACTS] Update Contract Location Point Pair Quantities Failure',

  CLEAR_ALL_CONTRACT_QUANTITIES = '[CONTRACTS] Clear All Contract Quantities',
  CLEAR_CONTRACT = '[CONTRACTS] Clear Contract',
  CLEAR_CONTRACTS_LIST = '[CONTRACTS] Clear Contracts List',
  CLEAR_CONTRACTS_LIST_ERROR = '[CONTRACTS] Clear Contracts List Error',
  CLEAR_CONTRACTS_HEADERS = '[CONTRACTS] Clear Contracts Headers',

  UPDATE_CONTRACT_OVERVIEW = '[CONTRACTS] Update Contract Overview',
  UPDATE_CONTRACT_OVERVIEW_SUCCESS = '[CONTRACTS] Update Contract Overview Success',
  UPDATE_CONTRACT_OVERVIEW_FAILURE = '[CONTRACTS] Update Contract Overview Failure',

  ADD_CONTRACT_OVERVIEW = '[CONTRACTS] Add Contract Overview',
  ADD_CONTRACT_OVERVIEW_SUCCESS = '[CONTRACTS] Add Contract Overview Success',
  ADD_CONTRACT_OVERVIEW_FAILURE = '[CONTRACTS] Add Contract Overview Failure',

  FETCH_CONTRACT_GENERAL_INFO = '[CONTRACTS] Fetch Contract General Info',
  FETCH_CONTRACT_GENERAL_INFO_SUCCESS = '[CONTRACTS] Fetch Contract General Info Success',
  FETCH_CONTRACT_GENERAL_INFO_FAILURE = '[CONTRACTS] Fetch Contract General Info Failure',

  RESET_CONTRACT_OVERVIEW = '[CONTRACTS] Reset Contract Overview',
  RESET_CONTRACT_EDI = '[CONTRACTS] Reset Contract EDI',
  RESET_CONTRACT_QUANTITIES = '[CONTRACTS] Reset Contract Quantities',
  RESET_CONTRACT_LOCATIONS = '[CONTRACTS] Reset Contract Locations',
  RESET_CONTRACT_QUANTITY_CHANGES = '[CONTRACTS] Reset Contract Quantity Changes Updated State',
  RESET_CONTRACT_PATHS = '[CONTRACTS] Reset Contract Paths',
  RESET_CONTRACT_QUANTITIES_CHANGES = '[CONTRACTS] Reset Contract Quantities Changes',

  UNLOCK_CONTRACT = '[CONTRACTS] Unlock contract',
  UNLOCK_CONTRACT_FAILURE = '[CONTRACTS] Unlock contract Failure',
  UNLOCK_CONTRACT_SUCCESS = '[CONTRACTS] Unlock contract Success',

  FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID = '[CONTRACTS] Fetch Supplemental Restrictions By Id',
  FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID_SUCCESS = '[CONTRACTS] Fetch Supplemental Restrictions By Id Success',
  FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID_FAILURE = '[CONTRACTS] Fetch Supplemental Restrictions By Id Failure',

  FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT = '[CONTRACTS] Fetch Contracts by Merger Assignment',
  FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT_SUCCESS = '[CONTRACTS] Fetch Contracts by Merger Assignment Success',
  FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT_FAILURE = '[CONTRACTS] Fetch Contracts by Merger Assignment Failure',

  EXPORT_CONTRACT_LIST = '[CONTRACTS] Export Contract List',
  EXPORT_CONTRACT_LIST_SUCCESS = '[CONTRACTS] Export Contract List Success',
  EXPORT_CONTRACT_LIST_FAILURE = '[CONTRACTS] Export Contract List Failure',

  CREATE_AMENDMENT = '[CONTRACTS] Create Amendment',
  CREATE_AMENDMENT_SUCCESS = '[CONTRACTS] Create Amendment Success',
  CREATE_AMENDMENT_FAILURE = '[CONTRACTS] Create Amendment Failure',
  RESET_AMENDMENT_CREATED_VALUE = '[CONTRACTS] Reset Amendment Value',

  WORKFLOW_INITIATE_KGEN = '[CONTRACTS] Workflow Initiate Kgen',
  WORKFLOW_INITIATE_KGEN_FAILURE = '[CONTRACTS] Workflow Initiate Kgen Failure',
  WORKFLOW_INITIATE_KGEN_SUCCESS = '[CONTRACTS] Workflow Initiate Kgen Success',
  RESET_WORKFLOW_INITIATE_KGEN = '[CONTRACTS] Reset Workflow Initiate Kgen',

  FETCH_CONTRACT_DOCUMENTS = '[CONTRACTS] Fetch Contract Documents',
  FETCH_CONTRACT_DOCUMENTS_FAILURE = '[CONTRACTS] Fetch Contract Documents Failure',
  FETCH_CONTRACT_DOCUMENTS_SUCCESS = '[CONTRACTS] Fetch Contract Documents Success',

  FETCH_CONTRACT_DOCUMENT_BY_ID = '[CONTRACTS] Fetch Contract Document By Id',
  FETCH_CONTRACT_DOCUMENT_BY_ID_SUCCESS = '[CONTRACTS] Fetch Contract Document By Id Success',
  FETCH_CONTRACT_DOCUMENT_BY_ID_FAILURE = '[CONTRACTS] Fetch Contract Document By Id Failure',

  UPDATE_CONTRACT_QUANTITY_CHANGES = '[CONTRACTS] Update Contract Quantity Changes',
  UPDATE_CONTRACT_QUANTITY_CHANGES_SUCCESS = '[CONTRACTS] Update Contract Quantity Changes Success',
  UPDATE_CONTRACT_QUANTITY_CHANGES_FAILURE = '[CONTRACTS] Update Contract Quantity Changes Failure',

  UPDATE_SELECTED_AMENDMENT = '[CONTRACTS] Update Selected Amendment',
  CLEAR_SELECTED_AMENDMENT = '[CONTRACTS] Clear Selected Amendment',

  UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES = '[CONTRACTS] Update Contract Location Quantity Changes',
  UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES_SUCCESS = '[CONTRACTS] Update Contract Location Quantity Changes Success',
  UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES_FAILURE = '[CONTRACTS] Update Contract Location Quantity Changes Failure',
  RESET_CONTRACT_LOCATION_QUANTITY_CHANGES = '[CONTRACTS] Rest Contract Location Updated State',

  UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES = '[CONTRACTS] Update Contract Location Quantity Point Pair Changes',
  UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES_SUCCESS = '[CONTRACTS] Update Contract Location Quantity Point Pair Changes Success',
  UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES_FAILURE = '[CONTRACTS] Update Contract Location Quantity Point Pair Changes Failure',
  RESET_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES = '[CONTRACTS] Rest Contract Location Qty Point Pair Change Updated State',

  EXPORT_GRANDFATHERED_SUPPLY_LATERAL = '[CONTRACTS] Export Grandfathered Supply Lateral',
  ACKNOWLEDGE_NETWORK_RESULT = '[CONTRACTS] Acknowledge Network Result',
  EXPORT_GRANDFATHERED_SUPPLY_LATERAL_SUCCESS = '[CONTRACTS] Export Grandfathered Supply Lateral Success',
  EXPORT_GRANDFATHERED_SUPPLY_LATERAL_FAILURE = '[CONTRACTS] Export Grandfathered Supply Lateral Failure',

  FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID = '[CONTRACTS] Fetch Contract Entities By Contract Id',
  FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID_SUCCESS = '[CONTRACTS] Fetch Contract Entities By Contract Id Success',
  FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID_FAILURE = '[CONTRACTS] Fetch Contract Entities By Contract Id Failure',

  FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS = 'FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS',
  FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_SUCCESS = 'FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_SUCCESS',
  FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_FAILURE = 'FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_FAILURE',
}

export class GetContractLocationHeadersByIds implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_LOCATION_HEADERS;

  constructor(public locationIds: number[]) {}
}

export class GetContractLocationHeadersByIdsSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_LOCATION_HEADERS_SUCCESS;

  constructor(public payload: { contractLocationHeadersList: ContractLocationHeaders[] }) {}
}

export class GetContractLocationHeadersByIdsError implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_LOCATION_HEADERS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchAmendmentsByContractId implements Action {
  public readonly type = EContractsActions.FETCH_AMENDMENTS_BY_CONTRACT_ID;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      searchAmendmentSequence?: string;
      contractId: number;
    }
  ) {}
}

export class FetchAmendmentsByContractIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_AMENDMENTS_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: { amendmentCollection: AmendmentsCollection }) {}
}

export class FetchAmendmentsByContractIdError implements Action {
  public readonly type = EContractsActions.FETCH_AMENDMENTS_BY_CONTRACT_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchContracts implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS;

  constructor(
    public payload: {
      pageSize: number;
      pageNumber: number;
      sortDescriptors: SortDescriptor[];
      contractStatus?: Array<string>;
      searchPhrase?: string;
      tspId?: number;
      contractIds?: Array<number>;
      contractNumber?: string;
      rateScheduleIds?: Array<number>;
      entityId?: number;
      searchPhraseReport?: string;
      contractNumberExactMatch?: boolean;
    }
  ) {}
}

export class FetchContractsSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_SUCCESS;

  constructor(public payload: { contracts: Array<Contract>; totalContractCount: number }) {}
}

export class FetchContractsError implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearContracts implements Action {
  public readonly type = EContractsActions.CLEAR_CONTRACTS;
}

export class FetchContractsHeaders implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_HEADERS;

  constructor(public payload: IContractsHeader) {}
}

export class FetchContractsHeadersSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_HEADERS_SUCCESS;

  constructor(public payload: { contractHeaders: Array<ContractHeader> }) {}
}

export class FetchContractsHeadersError implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_HEADERS_ERROR;

  constructor(public payload: Error) {}
}

export class ClearContractsHeaders implements Action {
  public readonly type = EContractsActions.CLEAR_CONTRACTS_HEADERS;
}

export class FetchContractHeader implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_HEADER;

  constructor(public payload: IContractHeader) {}
}

export class FetchContractHeaderSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_HEADER_SUCCESS;

  constructor(public payload: { contractHeader: ContractHeader }) {}
}

export class FetchContractHeaderError implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_HEADER_ERROR;

  constructor(public payload: Error) {}
}

export class ClearContractHeader implements Action {
  public readonly type = EContractsActions.CLEAR_CONTRACT_HEADER;

  constructor() {}
}
export class FetchContractById implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_BY_ID;

  constructor(public payload: { id: number; getRateSchedule?: boolean, gasDay?: string}) {}
}

export class FetchContractByIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_BY_ID_SUCCESS;

  constructor(public payload: { contract: DetailRateScheduleContract }) {}
}

export class FetchContractByIdError implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_BY_ID_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchContractsList extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_LIST;

  constructor(public payload: IGetContractsPayload) {
    super(payload);
  }
}

export class FetchContractsListSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_LIST_SUCCESS;

  constructor(public payload: { contractsList: Array<Contract>; total: number }) {}
}

export class FetchContractsListFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_LIST_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class SearchContracts implements Action {
  public readonly type = EContractsActions.SEARCH_CONTRACTS;

  constructor(public payload: ISearchContracts) {}
}

export class SearchContractsSuccess implements Action {
  public readonly type = EContractsActions.SEARCH_CONTRACTS_SUCCESS;

  constructor(public payload: { contracts: Array<Contract> }) {}
}

export class SearchContractsFailure implements Action {
  public readonly type = EContractsActions.SEARCH_CONTRACTS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearSearchContracts implements Action {
  public readonly type = EContractsActions.CLEAR_SEARCH_CONTRACTS;
}

export class SetContract implements Action {
  public readonly type = EContractsActions.SET_CONTRACT;

  constructor(readonly payload: { contract: Contract }) {}
}

export class FetchLookupCollections extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_LOOKUP_COLLECTIONS;
}

export class FetchLookupCollectionsSuccess implements Action {
  public readonly type = EContractsActions.FETCH_LOOKUP_COLLECTIONS_SUCCESS;

  constructor(
    readonly payload: { lookupCollections?: LookupCollections; useCache?: boolean } = {
      useCache: false,
    }
  ) {}
}

export class FetchLookupCollectionsFailure implements Action {
  public readonly type = EContractsActions.FETCH_LOOKUP_COLLECTIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearLookupCollectionsError implements Action {
  public readonly type = EContractsActions.CLEAR_LOOKUP_COLLECTIONS_ERROR;
}

export class FetchContractRateSchedules extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_RATE_SCHEDULES;

  constructor(readonly payload: { contractId: number }) {
    super();
  }
}

export class FetchContractRateSchedulesSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_RATE_SCHEDULES_SUCCESS;

  constructor(readonly payload: { contractRateSchedules: Array<RateSchedule> }) {}
}

export class FetchContractRateSchedulesFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_RATE_SCHEDULES_FAILURE;

  constructor(readonly payload: { error: Error }) {}
}

export class FetchContractQuantitiesByContractId extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractQuantitiesByContractIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: ContractQuantityCollection) {}
}

export class FetchContractQuantitiesByContractIdFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_QUANTITIES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchContractPathQuantitiesByContractId extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractPathQuantitiesByContractIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: QuantityPathCollection) {}
}

export class FetchContractPathQuantitiesByContractIdFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_PATH_QUANTITIES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// CONTRACT EDI
export class FetchContractEdiTransactionSetByContractId extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractEdiTransactionSetByContractIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: ContractEdiTransactionSetCollection) {}
}

export class FetchContractEdiTransactionSetByContractIdFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_EDI_TRANS_SET_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// CONTRACT LOCATION QUANTITIES
export class FetchContractLocationQuantitiesByContractId extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractLocationQuantitiesByContractIdSuccess implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: QuantityLocationCollection) {}
}

export class FetchContractLocationQuantitiesByContractIdFailure implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// CONTRACT LOCATION QUANTITIES CHANGES
export class FetchContractLocationQuantitiesChangesByContractId extends RetryableAction
  implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractLocationQuantitiesChangesByContractIdSuccess implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: QuantityLocationChangesCollection) {}
}

export class FetchContractLocationQuantitiesChangesByContractIdFailure implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// CONTRACT LOCATION POINT PAIR QUANTITIES
export class FetchContractLocationPointPairQuantitiesByContractId extends RetryableAction
  implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractLocationPointPairQuantitiesByContractIdSuccess implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: QuantityLocationPointPairCollection) {}
}

export class FetchContractLocationPointPairQuantitiesByContractIdFailure implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// CONTRACT LOCATION POINT PAIR QUANTITIES CHANGES
export class FetchContractLocationPointPairQuantitiesChangesByContractId extends RetryableAction
  implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractLocationPointPairQuantitiesChangesByContractIdSuccess implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: QuantityLocationChangesPointPairCollection) {}
}

export class FetchContractLocationPointPairQuantitiesChangesByContractIdFailure implements Action {
  public readonly type =
    EContractsActions.FETCH_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchContractEntitiesByRateScheduleIds implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS;

  constructor(
    public payload: {
      contractIds?: number[];
      contractStatus?: string[];
      entityId?: number;
      pageNumber: number;
      pageSize: number;
      rateScheduleIds?: number[];
      searchPhrase?: string;
      sortDescriptors: SortDescriptor[];
      tspId?: number;
    }
  ) {}
}

export class FetchContractEntitiesByRateScheduleIdsSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS_SUCCESS;

  constructor(public payload: { entities: Entity[] }) {}
}

export class FetchContractEntitiesByRateScheduleIdsFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_ENTITIES_BY_RATE_SCHEDULE_IDS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateContractQuantities extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_QUANTITIES;

  constructor(
    readonly payload: {
      contractId: number;
      groupingType: 'Original' | 'Net' | 'CapacityRelease';
      contractQuantities: Array<ContractQuantity>;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractQuantitiesSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_QUANTITIES_SUCCESS;
}

export class UpdateContractQuantitiesFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateContractPathQuantities extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_PATH_QUANTITIES;

  constructor(
    readonly payload: {
      contractId: number;
      groupingType: 'Original' | 'Net' | 'CapacityRelease';
      contractPathQuantities: Array<QuantityPath>;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractPathQuantitiesSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_PATH_QUANTITIES_SUCCESS;
}

export class UpdateContractPathQuantitiesFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_PATH_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearContractPathQuantitiesStatus implements Action {
  public readonly type = EContractsActions.CLEAR_CONTRACT_PATH_QUANTITIES_STATUS;
}

export class UpdateContractEdiTransactionSet extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_EDI_TRANSACTION_SET;

  constructor(
    readonly payload: {
      contractId: number;
      contractEdiTransactionSets: Array<ContractEdiTransactionSet>;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractEdiTransactionSetSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_EDI_TRANSACTION_SET_SUCCESS;
}

export class UpdateContractEdiTransactionSetFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_EDI_TRANSACTION_SET_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateContractLocationQuantities extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES;

  constructor(
    readonly payload: {
      contractId: number;
      groupingType: 'Original' | 'Net' | 'CapacityRelease';
      contractLocationQuantities: Array<QuantityLocation>;
      isMaxMdq?: boolean;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractLocationQuantitiesSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES_SUCCESS;
}

export class UpdateContractLocationQuantitiesFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateContractLocationQuantitiesReset implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITIES_RESET;

  constructor() {}
}

export class UpdateContractOverview extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_OVERVIEW;

  constructor(
    readonly payload: {
      contractId: number;
      body: Contract;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractLocationPointPairQuantities extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES;

  constructor(
    readonly payload: {
      contractId: number;
      groupingType: 'Original' | 'Net' | 'CapacityRelease';
      contractLocationPointPairQuantities: Array<QuantityLocationPointPair>;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractOverviewSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_OVERVIEW_SUCCESS;
}

export class UpdateContractOverviewFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_OVERVIEW_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateContractLocationPointPairQuantitiesSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_SUCCESS;
}

export class UpdateContractLocationPointPairQuantitiesFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_POINT_PAIR_QUANTITIES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearAllContractQuantities implements Action {
  public readonly type = EContractsActions.CLEAR_ALL_CONTRACT_QUANTITIES;
}

export class ClearContract implements Action {
  public readonly type = EContractsActions.CLEAR_CONTRACT;

  constructor() {}
}

export class FetchContractGeneralInfo implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_GENERAL_INFO;

  constructor(
    public payload: {
      pageSize?: number;
      pageNumber?: number;
      sortBy?: string;
      contractStatus?: Array<string>;
      entityId?: number;
      searchPhrase?: string;
      tspId?: number;
      contractNumber?: string;
      originId?: number;
      contractBeginDate?: Date;
      rateScheduleId?: number;
      locationIds?: Array<number>;
      attributeIds?: Array<number>;
      contractIds?: Array<number>;
      entityIds?: Array<number>;
    }
  ) {}
}

export class FetchContractGeneralInfoSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_GENERAL_INFO_SUCCESS;

  constructor(public payload: ContractGeneralCollection) {}
}

export class FetchContractGeneralInfoFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_GENERAL_INFO_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearContractsList implements Action {
  public readonly type = EContractsActions.CLEAR_CONTRACTS_LIST;
}

export class ClearContractsListError implements Action {
  public readonly type = EContractsActions.CLEAR_CONTRACTS_LIST_ERROR;
}

export class AddContractOverview extends RetryableAction implements Action {
  public readonly type = EContractsActions.ADD_CONTRACT_OVERVIEW;

  constructor(readonly payload: Contract) {
    super(payload);
  }
}

export class AddContractOverviewSuccess implements Action {
  public readonly type = EContractsActions.ADD_CONTRACT_OVERVIEW_SUCCESS;

  constructor(readonly payload: Contract) {}
}

export class AddContractOverviewFailure implements Action {
  public readonly type = EContractsActions.ADD_CONTRACT_OVERVIEW_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ResetContractOverview implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_OVERVIEW;
}

export class ResetContractQuantities implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_QUANTITIES;
}

export class ResetContractLocations implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_LOCATIONS;
}

export class ResetContractPaths implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_PATHS;
}

export class UnlockContract extends RetryableAction implements Action {
  public readonly type = EContractsActions.UNLOCK_CONTRACT;

  constructor(readonly contractId: number) {
    super(contractId);
  }
}

export class UnlockContractSuccess implements Action {
  public readonly type = EContractsActions.UNLOCK_CONTRACT_SUCCESS;

  constructor() {}
}

export class UnlockContractFailure implements Action {
  public readonly type = EContractsActions.UNLOCK_CONTRACT_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchSupplementalRestrictionsById extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchSupplementalRestrictionsByIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID_SUCCESS;

  constructor(public payload: SupplementalRestrictionCollection) {}
}

export class FetchSupplementalRestrictionsByIdFailure implements Action {
  public readonly type = EContractsActions.FETCH_SUPPLEMENTAL_RESTRICTIONS_BY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ResetContractEdi implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_EDI;
}

export class FetchContractsByMergerAssignment extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT;

  constructor(readonly payload: number) {
    super(payload);
  }
}

export class FetchContractQuantitiesChangesByContractId extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID;

  constructor(public payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractQuantitiesChangesByContractIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: QuantityChangesCollection) {}
}

export class FetchContractQuantitiesChangesByContractIdFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_QUANTITIES_CHANGES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetContractQuantitiesChanges implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_QUANTITIES_CHANGES;
}

export class FetchContractsByMergerAssignmentSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT_SUCCESS;

  constructor(public payload: ContractMergerAssignmentCollection) {}
}

export class FetchContractsByMergerAssignmentFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACTS_BY_MERGER_ASSIGNMENT_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportContractsList implements Action {
  public readonly type = EContractsActions.EXPORT_CONTRACT_LIST;

  constructor(
    public payload: {
      entityId?: number;
      contractStatus?: Array<string>;
      tspId?: number;
      contractNumber?: string;
      originId?: number;
      contractBeginDate?: Date;
      rateScheduleIds?: Array<number>;
      todaysDate: Date;
    }
  ) {}
}

export class ExportContractsListSuccess implements Action {
  public readonly type = EContractsActions.EXPORT_CONTRACT_LIST_SUCCESS;
}

export class ExportContractsListFailure implements Action {
  public readonly type = EContractsActions.EXPORT_CONTRACT_LIST_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateAmendment implements Action {
  public readonly type = EContractsActions.CREATE_AMENDMENT;

  constructor(
    public payload: {
      amendment: Amendment;
      contractId: number;
    }
  ) {}
}

export class CreateAmendmentSuccess implements Action {
  public readonly type = EContractsActions.CREATE_AMENDMENT_SUCCESS;

  constructor(public payload: Amendment[]) {}
}

export class CreateAmendmentFailure implements Action {
  public readonly type = EContractsActions.CREATE_AMENDMENT_FAILURE;

  constructor(public payload: Error) {}
}

export class ResetAmendment implements Action {
  public readonly type = EContractsActions.RESET_AMENDMENT_CREATED_VALUE;
}

export class WorkflowInitiateKgen implements Action {
  public readonly type = EContractsActions.WORKFLOW_INITIATE_KGEN;

  constructor(public payload: WorkflowKgenInitiationRequest) {}
}

export class WorkflowInitiateKgenSuccess implements Action {
  public readonly type = EContractsActions.WORKFLOW_INITIATE_KGEN_SUCCESS;
}

export class WorkflowInitiateKgenFailure implements Action {
  public readonly type = EContractsActions.WORKFLOW_INITIATE_KGEN_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ResetWorkflowInitiateKgen implements Action {
  public readonly type = EContractsActions.RESET_WORKFLOW_INITIATE_KGEN;
}

// CONTRACT DOCUMENTS
export class FetchContractDocuments extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_DOCUMENTS;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractDocumentsSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_DOCUMENTS_SUCCESS;

  constructor(public payload: ContractDocumentCollection) {}
}

export class FetchContractDocumentsFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_DOCUMENTS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchContractDocumentById extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_DOCUMENT_BY_ID;

  constructor(
    readonly payload: {
      contractId: number;
      documentId: number;
    }
  ) {
    super(payload);
  }
}

export class FetchContractDocumentByIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_DOCUMENT_BY_ID_SUCCESS;

  constructor(public payload: ContractDocument) {}
}

export class FetchContractDocumentByIdFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_DOCUMENT_BY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateContractQuantityChanges extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_QUANTITY_CHANGES;

  constructor(
    readonly payload: {
      contractId: number;
      quantityChanges: Array<QuantityChange>;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractQuantityChangesSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_QUANTITY_CHANGES_SUCCESS;

  constructor(public payload: Array<QuantityChange>) {}
}

export class UpdateContractQuantityChangesFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_QUANTITY_CHANGES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ResetContractQuantityChanges implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_QUANTITY_CHANGES;
}

// non-point pair
export class UpdateContractLocationQuantityChanges extends RetryableAction implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES;

  constructor(
    readonly payload: {
      contractId: number;
      contractLocationQuantityChanges: Array<QuantityLocationChange>;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractLocationQuantityChangesSuccess implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES_SUCCESS;

  constructor(public payload: Array<QuantityLocationChange>) {}
}

export class UpdateContractLocationQuantityChangesFailure implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_CHANGES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ResetContractLocationQuantityChanges implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_LOCATION_QUANTITY_CHANGES;
}

// point-pair
export class UpdateContractLocationQuantityPointPairChanges extends RetryableAction
  implements Action {
  public readonly type = EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES;

  constructor(
    readonly payload: {
      contractId: number;
      contractLocationQuantityPointPairChanges: Array<QuantityLocationChangePointPair>;
    }
  ) {
    super(payload);
  }
}

export class UpdateContractLocationQuantityPointPairChangesSuccess implements Action {
  public readonly type =
    EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES_SUCCESS;

  constructor(public payload: Array<QuantityLocationChangePointPair>) {}
}

export class UpdateContractLocationQuantityPointPairChangesFailure implements Action {
  public readonly type =
    EContractsActions.UPDATE_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ResetContractLocationQuantityPointPairChanges implements Action {
  public readonly type = EContractsActions.RESET_CONTRACT_LOCATION_QUANTITY_POINT_PAIR_CHANGES;
}

export class ExportGrandfatheredSupplyLateral implements Action {
  public readonly type = EContractsActions.EXPORT_GRANDFATHERED_SUPPLY_LATERAL;

  constructor(
    public payload: {
      supplyLateralLineName?: string;
    }
  ) {}
}

export class AcknowledgeNetworkResult implements Action {
  public readonly type = EContractsActions.ACKNOWLEDGE_NETWORK_RESULT;
}

export class ExportGrandfatheredSupplyLateralSuccess implements Action {
  public readonly type = EContractsActions.EXPORT_GRANDFATHERED_SUPPLY_LATERAL_SUCCESS;
}

export class ExportGrandfatheredSupplyLateralFailure implements Action {
  public readonly type = EContractsActions.EXPORT_GRANDFATHERED_SUPPLY_LATERAL_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateSelectedAmendment implements Action {
  public readonly type = EContractsActions.UPDATE_SELECTED_AMENDMENT;

  constructor(public payload: AmendmentDropdownOption) {}
}

export class ClearSelectedAmendment implements Action {
  public readonly type = EContractsActions.CLEAR_SELECTED_AMENDMENT;
}

// CONTRACT ENTITIES
export class FetchContractEntitiesByContractId extends RetryableAction implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID;

  constructor(readonly payload: IContractRequest) {
    super(payload);
  }
}

export class FetchContractEntitiesByContractIdSuccess implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID_SUCCESS;

  constructor(public payload: ContractEntityCollection) {}
}

export class FetchContractEntitiesByContractIdFailure implements Action {
  public readonly type = EContractsActions.FETCH_CONTRACT_ENTITIES_BY_CONTRACT_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchStorageTransferContractsHeaders implements Action {
  public readonly type = EContractsActions.FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS;

  constructor(public payload: { accountingPeriod: string; tspId: number; entityId: number, includeAgentEntities: boolean }) {}
}

export class FetchStorageTransferContractsHeadersSuccess implements Action {
  public readonly type = EContractsActions.FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_SUCCESS;

  constructor(public payload: { contractHeaders: Array<ContractHeader> }) {}
}

export class FetchStorageTransferContractsHeadersFailure implements Action {
  public readonly type = EContractsActions.FETCH_STORAGE_TRANSFER_CONTRACTS_HEADERS_FAILURE;

  constructor(public payload: Error) {}
}

export type ContractsActions =
  | GetContractLocationHeadersByIdsError
  | GetContractLocationHeadersByIdsSuccess
  | GetContractLocationHeadersByIds
  | FetchAmendmentsByContractId
  | FetchAmendmentsByContractIdSuccess
  | FetchAmendmentsByContractIdError
  | FetchContractsHeaders
  | FetchContractsHeadersSuccess
  | FetchContractsHeadersError
  | FetchContractHeader
  | FetchContractHeaderSuccess
  | FetchContractHeaderError
  | ClearContractHeader
  | FetchContracts
  | FetchContractsSuccess
  | FetchContractsError
  | ClearContracts
  | FetchContractById
  | FetchContractByIdSuccess
  | FetchContractByIdError
  | FetchContractsList
  | FetchContractsListSuccess
  | FetchContractsListFailure
  | SearchContracts
  | SearchContractsSuccess
  | SearchContractsFailure
  | ClearSearchContracts
  | SetContract
  | FetchLookupCollections
  | FetchLookupCollectionsSuccess
  | FetchLookupCollectionsFailure
  | ClearLookupCollectionsError
  | FetchContractRateSchedules
  | FetchContractRateSchedulesSuccess
  | FetchContractRateSchedulesFailure
  | FetchContractQuantitiesByContractId
  | FetchContractQuantitiesByContractIdSuccess
  | FetchContractQuantitiesByContractIdFailure
  | FetchContractLocationQuantitiesByContractId
  | FetchContractLocationQuantitiesByContractIdSuccess
  | FetchContractLocationQuantitiesByContractIdFailure
  | FetchContractLocationQuantitiesChangesByContractId
  | FetchContractLocationQuantitiesChangesByContractIdSuccess
  | FetchContractLocationQuantitiesChangesByContractIdFailure
  | FetchContractQuantitiesChangesByContractId
  | FetchContractQuantitiesChangesByContractIdSuccess
  | FetchContractQuantitiesChangesByContractIdFailure
  | ResetContractQuantitiesChanges
  | FetchContractPathQuantitiesByContractId
  | FetchContractPathQuantitiesByContractIdSuccess
  | FetchContractPathQuantitiesByContractIdFailure
  | FetchContractLocationPointPairQuantitiesByContractId
  | FetchContractLocationPointPairQuantitiesByContractIdSuccess
  | FetchContractLocationPointPairQuantitiesByContractIdFailure
  | FetchContractLocationPointPairQuantitiesChangesByContractId
  | FetchContractLocationPointPairQuantitiesChangesByContractIdSuccess
  | FetchContractLocationPointPairQuantitiesChangesByContractIdFailure
  | FetchContractEdiTransactionSetByContractId
  | FetchContractEdiTransactionSetByContractIdSuccess
  | FetchContractEdiTransactionSetByContractIdFailure
  | FetchContractEntitiesByRateScheduleIds
  | FetchContractEntitiesByRateScheduleIdsSuccess
  | FetchContractEntitiesByRateScheduleIdsFailure
  | UpdateContractQuantities
  | UpdateContractQuantitiesSuccess
  | UpdateContractQuantitiesFailure
  | UpdateContractPathQuantities
  | UpdateContractPathQuantitiesSuccess
  | UpdateContractPathQuantitiesFailure
  | UpdateContractEdiTransactionSet
  | UpdateContractEdiTransactionSetSuccess
  | UpdateContractEdiTransactionSetFailure
  | UpdateContractLocationQuantities
  | UpdateContractLocationQuantitiesSuccess
  | UpdateContractLocationQuantitiesFailure
  | UpdateContractLocationQuantitiesReset
  | UpdateContractOverview
  | UpdateContractOverviewSuccess
  | UpdateContractOverviewFailure
  | UpdateContractLocationPointPairQuantities
  | UpdateContractLocationPointPairQuantitiesSuccess
  | UpdateContractLocationPointPairQuantitiesFailure
  | ClearAllContractQuantities
  | ClearContract
  | FetchContractGeneralInfo
  | FetchContractGeneralInfoSuccess
  | FetchContractGeneralInfoFailure
  | ClearContractsList
  | ClearContractsListError
  | AddContractOverview
  | AddContractOverviewFailure
  | AddContractOverviewSuccess
  | ResetContractOverview
  | ResetContractEdi
  | ResetContractQuantities
  | UnlockContract
  | UnlockContractFailure
  | UnlockContractSuccess
  | FetchSupplementalRestrictionsById
  | FetchSupplementalRestrictionsByIdSuccess
  | FetchSupplementalRestrictionsByIdFailure
  | FetchContractsByMergerAssignment
  | FetchContractsByMergerAssignmentSuccess
  | FetchContractsByMergerAssignmentFailure
  | ExportContractsList
  | ExportContractsListSuccess
  | ExportContractsListFailure
  | CreateAmendment
  | CreateAmendmentSuccess
  | CreateAmendmentFailure
  | ResetAmendment
  | ResetContractLocations
  | WorkflowInitiateKgen
  | WorkflowInitiateKgenSuccess
  | WorkflowInitiateKgenFailure
  | ResetWorkflowInitiateKgen
  | FetchContractDocuments
  | FetchContractDocumentsSuccess
  | FetchContractDocumentsFailure
  | FetchContractDocumentById
  | FetchContractDocumentByIdSuccess
  | FetchContractDocumentByIdFailure
  | UpdateContractQuantityChanges
  | UpdateContractQuantityChangesSuccess
  | UpdateContractQuantityChangesFailure
  | ResetContractQuantityChanges
  | UpdateContractLocationQuantityChanges
  | UpdateContractLocationQuantityChangesSuccess
  | UpdateContractLocationQuantityChangesFailure
  | ResetContractLocationQuantityChanges
  | UpdateContractLocationQuantityPointPairChanges
  | UpdateContractLocationQuantityPointPairChangesSuccess
  | UpdateContractLocationQuantityPointPairChangesFailure
  | ResetContractLocationQuantityPointPairChanges
  | ExportGrandfatheredSupplyLateral
  | ExportGrandfatheredSupplyLateralSuccess
  | ExportGrandfatheredSupplyLateralFailure
  | AcknowledgeNetworkResult
  | UpdateSelectedAmendment
  | ClearSelectedAmendment
  | ClearContractPathQuantitiesStatus
  | ResetContractPaths
  | ClearContractsHeaders
  | FetchContractEntitiesByContractId
  | FetchContractEntitiesByContractIdSuccess
  | FetchContractEntitiesByContractIdFailure
  | FetchStorageTransferContractsHeaders
  | FetchStorageTransferContractsHeadersSuccess
  | FetchStorageTransferContractsHeadersFailure;
