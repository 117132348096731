import { ClosingGroup, ClosingGroupCollection, ClosingGroupProcess } from '@gms/closing-api';
import { emptyRequestStatus, IRequestStatus } from 'app/store/app/app.models';

export enum EProcessStatuses {
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  COMPLETE = 'Complete',
}

export interface IConsumeClosingGroupProcess {
  total?: number;
  completed?: number;
  groupProcessesStatus?: EProcessStatuses;
  processes?: Array<ClosingGroupProcess>;
  loadingStatus?: IRequestStatus<any>;
}

export interface IConsumeClosingGroupProcessCollection {
  [key: number]: IConsumeClosingGroupProcess; // key is ClosingGroupId
}

const determineProcessStatus = (processes: ClosingGroupProcess[]): EProcessStatuses => {
  return processes.every(process => process.status === EProcessStatuses.PENDING)
    ? EProcessStatuses.PENDING // if everything is PENDING return pending
    : processes.every(process => process.status === EProcessStatuses.COMPLETE)
    ? EProcessStatuses.COMPLETE // if everything is COMPLETE return complete
    : EProcessStatuses.PROCESSING; //else return PROCESSING
};

const mapApiProcesses = (processes: ClosingGroupProcess[]): IConsumeClosingGroupProcess => {
  let completedProcesses = 0;
  processes.forEach(process => {
    completedProcesses += process.status === EProcessStatuses.COMPLETE ? 1 : 0;
  });

  return {
    total: processes.length,
    completed: completedProcesses,
    groupProcessesStatus: determineProcessStatus(processes),
    processes,
    loadingStatus: emptyRequestStatus,
  };
};

export const getProcesses = (
  data: ClosingGroupCollection
): IConsumeClosingGroupProcessCollection => {
  const processes: IConsumeClosingGroupProcessCollection = {};

  if (data.closingGroups) {
    data.closingGroups.forEach((group: ClosingGroup) => {
      processes[group.id] = mapApiProcesses(group.closingGroupProcesses);
    });
  }
  return processes;
};

export const updateGroupProcess = (
  state: IConsumeClosingGroupProcessCollection,
  closingGroupId: number,
  processes: ClosingGroupProcess[],
  loading?: boolean,
  error?: Error
): IConsumeClosingGroupProcessCollection => {
  if (error) {
    state[closingGroupId] = {
      ...state[closingGroupId],
      loadingStatus: { loading: false, httpError: error },
    };
  } else if (loading) {
    state[closingGroupId] = {
      ...state[closingGroupId],
      loadingStatus: { loading: true, httpError: null },
    };
  } else if (processes) {
    state[closingGroupId] = {
      ...state[closingGroupId],
      ...mapApiProcesses(processes),
    };
  }
  return state;
};
