<div *ngIf="group" class="multiselect-container" [formGroup]="group"
     [class.is-in-action-bar]="isInActionBar"
     [class.use-overflow-ellipsis]="useOverflowEllipsis"
>
  <gms-inline-error-message [control]="group.controls[controlName]" [isInATable]="isInATable">
    <div class="multiselect-wrapper" [formGroup]="group">
      <label *ngIf="label" class="form-field-label">{{ label }}</label>
      <div>
        <kendo-multiselect
          #multiselect
          [data]="data"
          [value]="value"
          [textField]="textField"
          [valueField]="valueField"
          [filterable]="filterable"
          id="{{ id }}-input"
          (valueChange)="valueChanged.emit($event)"
          (open)="opened.emit()"
          class="masked-form-input form-input"
          [valuePrimitive]="valuePrimitive"
          [style.width]="'100%'"
          [clearButton]="false"
          [placeholder]="placeholder || ''"
          [popupSettings]="popupSettings || null"
          [formControlName]="controlName"
          [tagMapper]="tagMapper"
          [loading] = "loading"
          [virtual]="useVirtualScrolling ? virtualScrollOptions : null"
          (blur)="onSelectBlurred()"
          [autoClose]="false"
          >
          <!-- [disabled]="disabled" -->
          <div *ngIf="noDataText">
            <ng-template kendoDropDownListNoDataTemplate>
              {{ noDataText }}
            </ng-template>
          </div>
          <ng-template *ngIf="itemTemplate" kendoMultiSelectItemTemplate let-dataItem>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { dataItem: dataItem }">
            </ng-container>
          </ng-template>
          <ng-template *ngIf="!itemTemplate" kendoMultiSelectNoDataTemplate>
            <ng-container *ngTemplateOutlet="noDataTemplate"></ng-container>
          </ng-template>
          <ng-template *ngIf="tagTemplate" kendoMultiSelectTagTemplate let-dataItem>
            <ng-container *ngTemplateOutlet="tagTemplate; context: { dataItem: dataItem }">
            </ng-container>
          </ng-template>
          <ng-template *ngIf="groupTagTemplate" kendoMultiSelectGroupTagTemplate let-dataItems>
            <ng-container *ngTemplateOutlet="groupTagTemplate; context: { dataItems: dataItems }">
            </ng-container>
          </ng-template>
        </kendo-multiselect>
      </div>
    </div>
  </gms-inline-error-message>
  <div
    *ngIf="
      !group?.get(controlName)?.valid &&
      group?.get(controlName)?.touched &&
      !group?.get(controlName)?.errors?.required
    "
    class="global-error-text"
  >
    {{ errorMessage }}
  </div>
  <div
    *ngIf="
      !group?.get(controlName)?.valid &&
      group?.get(controlName)?.touched &&
      group?.get(controlName)?.errors?.required
    "
    class="global-error-text required-field-error-text"
  >
    {{ 'required field' | uppercase }}
  </div>
</div>
