// Form control names
export const START_DATE = 'startDate';
export const START_TIME = 'startTime';
export const END_DATE = 'endDate';
export const END_TIME = 'endTime';
export const JOB_ID = 'jobId';
export const JOB_NAME = 'jobName';
export const CONFIG_ID = 'configId';
export const STATUSES = 'statuses';
export const CONFIG_NAME = 'configName';
export const CONFIG_IDS = 'configIds';
