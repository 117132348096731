import { DealSheetActions, EDealSheetActions } from './deal-sheets.actions';
import { IDealSheetState, initialDealSheetsState } from './deal-sheets.state';

export function dealSheetsReducers(
  state = initialDealSheetsState,
  action: DealSheetActions
): IDealSheetState {
  switch (action.type) {
    case EDealSheetActions.FETCH_DEAL_SHEETS:
      return {
        ...state,
        sort: action.payload.sortDescriptors,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        loading: true,
      };
    case EDealSheetActions.FETCH_DEAL_SHEETS_SUCCESS:
      return {
        ...state,
        dealSheets: action.payload.dealSheets,
        totalDealSheetsCount: action.payload.totalDealSheetsCount,
        loading: false,
        error: false,
      };
    case EDealSheetActions.FETCH_DEAL_SHEETS_ERROR:
      return {
        ...state,
        editingDealSheet: action.error,
        error: true,
        loading: false,
      };
    case EDealSheetActions.FETCH_DEAL_SHEET_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case EDealSheetActions.SAVE_DEAL_SHEET:
      return {
        ...state,
        loading: true,
      };
    case EDealSheetActions.SAVE_DEAL_SHEET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: EDealSheetActions.SAVE_DEAL_SHEET_SUCCESS,
        editingDealSheet: action.payload,
      };
    }
    case EDealSheetActions.SAVE_DEAL_SHEET_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        networkResultStatus: EDealSheetActions.SAVE_DEAL_SHEET_ERROR,
        editingDealSheet: action.error,
      };
    }
    case EDealSheetActions.UPDATE_EDITING_DEAL_SHEET:
      return {
        ...state,
        editingDealSheet: action.payload,
        error: false,
        loading: false,
      };
    case EDealSheetActions.UPDATE_DEAL_SHEET: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDealSheetActions.UPDATE_DEAL_SHEET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: EDealSheetActions.UPDATE_DEAL_SHEET_SUCCESS,
        editingDealSheet: action.payload,
        updatedDealSheet: action.payload,
      };
    }
    case EDealSheetActions.UPDATE_DEAL_SHEET_ERROR: {
      return {
        ...state,
        editingDealSheet: action.error,
        loading: false,
        error: true,
        networkResultStatus: EDealSheetActions.UPDATE_DEAL_SHEET_ERROR,
      };
    }
    case EDealSheetActions.ACKNOWLEDGE_NETWORK_RESULT: {
      return {
        ...state,
        networkResultStatus: null,
      };
    }
    case EDealSheetActions.SET_PAGE_SIZE: {
      const pagination = { ...state.pagination };

      pagination.pageSize = action.pageSize;

      return {
        ...state,
        pagination,
      };
    }
    case EDealSheetActions.DELETE_DEAL_SHEET: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDealSheetActions.DELETE_DEAL_SHEET_SUCCESS: {
      return {
        ...state,
        loading: false,
        networkResultStatus: EDealSheetActions.DELETE_DEAL_SHEET_SUCCESS,
      };
    }
    case EDealSheetActions.DELETE_DEAL_SHEET_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        dealSheetError: action.payload.error,
        networkResultStatus: EDealSheetActions.DELETE_DEAL_SHEET_ERROR,
      };
    }
    case EDealSheetActions.ADD_INTERNAL_COMMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDealSheetActions.ADD_INTERNAL_COMMENT_SUCCESS: {
      const editingDealSheet = { ...state.editingDealSheet };

      editingDealSheet.internalComments.push(action.payload);

      return {
        ...state,
        loading: false,
        error: false,
        networkResultStatus: EDealSheetActions.ADD_INTERNAL_COMMENT_SUCCESS,
        editingDealSheet: editingDealSheet,
        updatedDealSheet: editingDealSheet,
      };
    }
    case EDealSheetActions.ADD_INTERNAL_COMMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        networkResultStatus: EDealSheetActions.ADD_INTERNAL_COMMENT_ERROR,
      };
    }
    default:
      return state;
  }
}
