import { QeData } from 'shared/models/test.model';

export enum EMessageType {
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface Message {
  code?: string;
  message: string;
  qeData?: QeData;
}
