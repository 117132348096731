import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { PopoverHorizontalAlignment } from 'shared/components/popover/popover.component';

/**
 * Usage: see CustomValidators.ts
 * See IControlError
 *
 *
 *
 * control.setErrors({ controlError:
        {
          type: EControlErrorType,
          message: '',
        } as IControlError
      });
    })
 */

@Component({
  selector: 'gms-inline-error-message',
  templateUrl: './form-inline-error-message.component.html',
  styleUrls: ['./form-inline-error-message.component.scss'],
})
export class FormInlineErrorMessageComponent extends HasSubscriptions implements OnInit {
  _initialControlValue: any;
  _hasBeenChanged = false;

  popoverHorizontalAlignment = PopoverHorizontalAlignment;
  objectKeys = Object.keys;
  hasKey = has;

  @Input()
  control: UntypedFormControl;

  @Input()
  isInATable = false;
  @Input()
  tableOverflow = false;

  ngOnInit(): void {
    if (this.control) {
      this._initialControlValue = this.control.value;
      this.addSubscriptions([
        // in addition to checking if the value changes
        // this subscription fires the renderer by virtue of being subscribed
        this.control.valueChanges.subscribe((value: any) => {
          this.checkAgainstInitialValue(value);
        }),
        this.control.statusChanges.subscribe(() => {
          //forces a render!
        }),
      ]);
    }
  }

  private checkAgainstInitialValue(value: any) {
    this._hasBeenChanged = !isEqual(this._initialControlValue, value);
  }
}
