import { RequestStatusCollection, ServiceRequest } from '@gms/servicerequest-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { createInitialDataState, IDataState } from 'app/store/app/app.models';

export interface IServiceRequestState {
  serviceRequest: ServiceRequest;
  editingServiceRequest: ServiceRequest;
  serviceRequests: ServiceRequest[];
  sort: SortDescriptor[];
  loading: boolean;
  error: boolean;
  savedServiceRequestId: number;
  networkResultStatus: string;
  totalServiceRequestsCount: number;
  requestStatuses: IDataState<RequestStatusCollection>;
}
export const initialServiceRequestsState: IServiceRequestState = {
  serviceRequest: {},
  editingServiceRequest: {
    requestContacts: [],
    routes: [],
  },
  serviceRequests: [],
  savedServiceRequestId: undefined,
  sort: [
    {
      field: 'lastUpdatedDate',
      dir: 'asc',
    },
  ],
  loading: false,
  error: null,
  networkResultStatus: null,
  totalServiceRequestsCount: 0,
  requestStatuses: createInitialDataState(),
};
