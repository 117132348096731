import {
  GlAccount,
  JournalEntryRule,
  JournalEntryRuleAccount,
  JournalEntryRuleCollection,
} from '@gms/accounting-api';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IRequestStatus } from '../app/app.models';

export interface IJournalEntryRuleState {
  pageSize: number;
  pageNumber: number;
  sortDescriptors: SortDescriptor[];
  journalEntryRule: JournalEntryRule;
  journalEntryRuleCollection: JournalEntryRuleCollection;
  generalLedgerAccountDictionary: GlAccount[];
  getGeneralLedgerAccounts: IRequestStatus<any>;
  getJournalEntryRuleCollection: IRequestStatus<any>;
  updateJournalEntryRule: IRequestStatus<any>;
  getJournalEntryRule: IRequestStatus<any>;
  createJournalEntryRule: {
    journalEntryRule: JournalEntryRule;
    loading: {
      tsps: boolean;
      rateScheduleDictionary: boolean;
      chargeTypeDictionary: boolean;
      rateTypeDictionary: boolean;
      journalEntryRule: boolean;
      journalEntryRuleCollection: boolean;
      glAccount: boolean;
    };
    error: Error | JournalEntryRule | JournalEntryRuleAccount;
  };
}

export const initialJournalEntryRuleState = {
  pageSize: 15,
  pageNumber: 1,
  sortDescriptors: [],
  journalEntryRule: null,
  journalEntryRuleCollection: {
    journalEntryRules: [],
    total: 0,
  },
  generalLedgerAccountDictionary: [],
  getGeneralLedgerAccounts: { httpError: null, loading: false },
  getJournalEntryRuleCollection: { httpError: null, loading: false },
  getJournalEntryRule: { httpError: null, loading: false },
  createJournalEntryRule: {
    journalEntryRule: null,
    loading: {
      tsps: false,
      rateScheduleDictionary: false,
      chargeTypeDictionary: false,
      rateTypeDictionary: false,
      journalEntryRule: false,
      journalEntryRuleCollection: false,
      glAccount: false,
    },
    error: null,
  },
  updateJournalEntryRule: { httpError: null, loading: false },
};
