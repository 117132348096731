import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DynamicComponentDefinition } from 'shared/components/dynamic-component-wrapper/dynamic-component.interface';
import { StatusTagCell } from './status-tag-cell.interface';

@Component({
  selector: 'gms-dynamic-status-tag-cell',
  templateUrl: './status-tag-cell.component.html',
  styleUrls: ['./status-tag-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicStatusTagCellComponent {
  @Input() data: StatusTagCell;

  static componentDefinition(data: StatusTagCell): DynamicComponentDefinition {
    return {
      dynamicComponentType: DynamicStatusTagCellComponent,
      data: data,
    };
  }

  public onClick() {
    if (this.data.icon) {
      this.data.icon.onClick(this.data);
    }
  }
}
