import {
  NonTariffRate,
  NonTariffRateCollection,
  NonTariffRateDetailCollection,
  NonTariffRateDetailGridCollection,
  RatesDropdownDictionary,
  RevenueRecognitionCollection,
} from '@gms/rate-api';
import {
  NonTariffRateForDelete,
  NonTariffRateForEdit,
} from 'app/store/rate-contract/rate-contract.models';

export interface IRateContractState {
  nonTariffRate: NonTariffRateForEdit;
  loading: {
    nonTariffRate: boolean;
    rateDictionary: boolean;
    nonTariffRates: boolean;
    nonTariffRateDelete: boolean;
    nonTariffRatesDetails: boolean;
    nonTariffDetailsGrid: boolean;
    exportContractualRates: boolean;
    exportContractualRatesSummary: boolean;
    exportContractualRatesDetails: boolean;
    revenuerecognitionDictionary: boolean;
  };
  error: {
    exportContractualRates: Error;
    exportContractualRatesSummary: Error;
    exportContractualRatesDetails: Error;
    nonTariffRateDelete: Error;
  };
  nonTariffRateDelete: NonTariffRateForDelete;
  nonTariffRateError: Error;
  rateDictionary: RatesDropdownDictionary;
  rateDictionaryError: Error;
  nonTariffRates: NonTariffRateCollection;
  nonTariffRatesError: Error;
  nonTariffRatesDetails: NonTariffRateDetailCollection;
  nonTariffRatesDetailsError: Error;
  nonTariffDetailsGrid: NonTariffRateDetailGridCollection;
  nonTariffDetailsGridError: Error;
  exportContractualRates: NonTariffRateCollection;
  exportContractualRatesSummary: NonTariffRateDetailCollection;
  exportContractualRatesDetails: NonTariffRateDetailGridCollection;
  revenueRecognitionDictionary: RevenueRecognitionCollection;
  revenueRecognitionError: Error;
}

export const initialRateContractState: IRateContractState = {
  nonTariffRate: null,
  loading: {
    nonTariffRate: false,
    nonTariffRateDelete: false,
    nonTariffRatesDetails: false,
    nonTariffDetailsGrid: false,
    rateDictionary: false,
    nonTariffRates: false,
    exportContractualRates: false,
    exportContractualRatesSummary: false,
    exportContractualRatesDetails: false,
    revenuerecognitionDictionary: false,
  },
  error: {
    exportContractualRates: null,
    exportContractualRatesSummary: null,
    exportContractualRatesDetails: null,
    nonTariffRateDelete: null,
  },
  nonTariffRateDelete: null,
  nonTariffRateError: null,
  rateDictionary: null,
  rateDictionaryError: null,
  nonTariffRates: null,
  nonTariffRatesError: null,
  nonTariffRatesDetails: null,
  nonTariffRatesDetailsError: null,
  nonTariffDetailsGrid: null,
  nonTariffDetailsGridError: null,
  exportContractualRates: null,
  exportContractualRatesSummary: null,
  exportContractualRatesDetails: null,
  revenueRecognitionDictionary: null,
  revenueRecognitionError: null,
};
