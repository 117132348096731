import { ServiceProvider } from '@gms/tsp-api';
import { Action } from '@ngrx/store';
import { ServiceRequester } from './header.state';

export enum EHeaderActions {
  SET_TSP = '[HEADER] set TSP',
  SET_TSP_FROM_TSP_ID = '[HEADER] set TSP from TSP ID',
  SET_SERVICE_REQUESTER = '[HEADER] set ServiceRequester',
  CLEAR_SERVICE_REQUESTER = '[HEADER] clear ServiceRequester',
}

export class SetTsp implements Action {
  public readonly type = EHeaderActions.SET_TSP;

  constructor(public payload: { tsp: ServiceProvider; overrideIfAlreadySet?: boolean }) {}
}

export class SetTspFromTspId implements Action {
  public readonly type = EHeaderActions.SET_TSP_FROM_TSP_ID;

  constructor(public payload: { tspId: number }) {}
}

export class SetServiceRequester implements Action {
  public readonly type = EHeaderActions.SET_SERVICE_REQUESTER;

  constructor(public payload: { serviceRequester: ServiceRequester }) {}
}

export class ClearServiceRequester implements Action {
  public readonly type = EHeaderActions.CLEAR_SERVICE_REQUESTER;
}

export type HeaderActions = SetTsp | SetTspFromTspId | SetServiceRequester | ClearServiceRequester;
