export enum BidListTabValues {
  BIDS = 'bids',
  OFFERS = 'biddableOffers',
}

export enum EViewBidTabs {
  BID_TERMS = 'Bid Terms',
  LOCATIONS = 'Locations',
}

export enum BidderViewModesEnum {
  BIDDER = 'bidder',
  DOWNLOAD = 'download',
}

export const BidderStatuses = [
  'Draft',
  'Posted',
  'Confirmed',
  'Rejected',
  'Withdrawn',
  'Closed',
  'Deleted',
  'Pending',
  'Awarded',
];

export enum BidStatuses {
  DRAFT = 'Draft',
  POSTED = 'Posted',
  REJECTED = 'Rejected',
  AWARDED = 'Awarded',
  CLOSED = 'Closed',
  WITHDRAWN = 'Withdrawn',
  CONFIRMED = 'Confirmed',
  PENDING = 'Pending',
}

export const BidderBidStatusIds = [
  1, // Draft
  2, // Posted
  3, // Confirmed
  4, // Rejected
  5, // Withdrawn
  6, // Closed
  // 7, // Deleted - For MY Bids, PMs do not want to see deleted bids
  8, // Pending
  9, // Awarded
];

export enum EBidStatuses {
  DRAFT = 1,
  POSTED,
  CONFIRMED,
  REJECTED,
  WITHDRAWN,
  CLOSED,
  DELETED,
  PENDING,
  AWARDED,
}

export const NonBidderStatuses = ['Posted', 'Confirmed', 'Awarded'];
export const NonBidderBidStatusIds = [
  2, // Posted
  3, // Confirmed
  9, // Awarded
];

export enum EContractQuantitiesModes {
  BID = 'BID',
  OFFER = 'OFFER',
  AWARD = 'AWARD',
}

export enum EBidLesserQuantity {
  NO = 'No',
  YES = 'Yes',
  UNDISCLOSED = 'Undisclosed',
}

export enum EBidLesserQtyDesc {
  NO = 'Bidder will not accept an award for less than the full quantity.',
  YES = 'Bidder will accept an award for less than the full quantity.',
  UNDISCLOSED = 'Bidder will accept an award for less than the full quantity, but does not want that minimum disclosed',
}

export enum EReleaseShipperLesserQuantityIds {
  UNDISCLOSED = 8,
  YES = 9,
  NO = 10,
}

export enum ESeasonIds {
  WINTER = 1,
  SUMMER = 2,
  SHOULDER = 3,
  APRIL = 5,
  OCTOBER = 6,
}

export interface IBidSummaryFields {
  [key: string]: string;
}
