import {
  AverageDay,
  LineSequenceCollection,
  LookupCollections,
  MeterCapacityCheckModel,
  MeterCapacityCheckModelCollection,
  ObligationCollection,
  ObligationSelection,
  ReservationList,
} from '@gms/systemplanning-api';
import {
  AverageDayExport,
  ReservationEntry,
  StorageObligationList,
} from '@gms/systemplanning-api/model/models';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';

export enum ESystemPlanningActions {
  FETCH_OBLIGATIONS_COLLECTION = '[SYSTEM PLANNING] Fetch Obligations Collection',
  FETCH_OBLIGATIONS_COLLECTION_SUCCESS = '[SYSTEM PLANNING] Fetch Obligations Collection Success',
  FETCH_OBLIGATIONS_COLLECTION_FAILURE = '[SYSTEM PLANNING] Fetch Obligations Collection Failure',
  FETCH_AVERAGE_DAY_COLLECTION = '[SYSTEM PLANNING] Fetch Average Day Collection',
  FETCH_AVERAGE_DAY_COLLECTION_SUCCESS = '[SYSTEM PLANNING] Fetch Average Day Collection Success',
  FETCH_AVERAGE_DAY_COLLECTION_FAILURE = '[SYSTEM PLANNING] Fetch Average Day Collection Failure',
  CLEAR_OBLIGATIONS_COLLECTION = '[SYSTEM PLANNING] Clear Obligations Collection',
  CLEAR_AVERAGE_DAY_COLLECTION = '[SYSTEM PLANNING] Clear Average Day Collection',
  SAVE_AVERAGE_DAY = '[SYSTEM PLANNING] Save Average Day',
  SAVE_AVERAGE_DAY_SUCCESS = '[SYSTEM PLANNING] Save Average Day Success',
  SAVE_AVERAGE_DAY_FAILURE = '[SYSTEM PLANNING] Save Average Day Failure',
  ACKNOWLEDGE_NETWORK_RESULT = '[SYSTEM PLANNING] Acknowledge Network Result',
  FETCH_AVERAGE_DAY_LOOKUP = '[SYSTEM PLANNING] Fetch Average Day Lookup',
  FETCH_AVERAGE_DAY_LOOKUP_SUCCESS = '[SYSTEM PLANNING] Fetch Average Day Lookup Success',
  FETCH_AVERAGE_DAY_LOOKUP_FAILURE = '[SYSTEM PLANNING] Fetch Average Day Lookup Failure',
  FETCH_AVERAGE_DAY_BY_ID = '[SYSTEM PLANNING] Fetch Average Day By Id',
  FETCH_AVERAGE_DAY_BY_ID_SUCCESS = '[SYSTEM PLANNING] Fetch Average Day By Id Success',
  FETCH_AVERAGE_DAY_BY_ID_FAILURE = '[SYSTEM PLANNING] Fetch Average Day By Id Failure',
  UPDATE_AVERAGE_DAY_DEFAULT = '[SYSTEM PLANNING] Update Average Day By Id',
  UPDATE_AVERAGE_DAY_DEFAULT_SUCCESS = '[SYSTEM PLANNING] Update Average Day By Id Success',
  UPDATE_AVERAGE_DAY_DEFAULT_FAILURE = '[SYSTEM PLANNING] Update Average Day By Id Failure',
  EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION = '[SYSTEM PLANNING] Export AVERAGE DAY MEASUREMENT Selection',
  EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION_SUCCESS = '[SYSTEM PLANNING] Export AVERAGE DAY MEASUREMENT Selection Success',
  EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION_FAILURE = '[SYSTEM PLANNING] Export AVERAGE DAY MEASUREMENT Selection Failure',
  EXPORT_AVERAGE_DAY_MEASUREMENT_DOWNLOAD_RESULT_SUCCESS = '[SYSTEM PLANNING] Export AVERAGE DAY MEASUREMENT Download Result Success',
  EXPORT_FIRM_OBLIGATION_SELECTION = '[SYSTEM PLANNING] Export Firm Obligation Selection',
  EXPORT_FIRM_OBLIGATION_SELECTION_SUCCESS = '[SYSTEM PLANNING] Export Firm Obligation Selection Success',
  EXPORT_FIRM_OBLIGATION_SELECTION_FAILURE = '[SYSTEM PLANNING] Export Firm Obligation Selection Failure',
  EXPORT_FIRM_OBLIGATION_DOWNLOAD_RESULT_SUCCESS = '[SYSTEM PLANNING] Export Firm Obligation Download Result Success',
  FETCH_METER_CHECK_GUID = '[SYSTEM PLANNING] Fetch Meter Check GUID',
  FETCH_METER_CHECK_GUID_SUCCESS = '[SYSTEM PLANNING] Fetch Meter Check GUID Sucess',
  FETCH_METER_CHECK_GUID_FAILURE = '[SYSTEM PLANNING] Fetch Meter Check GUID Failure',
  CLEAR_METER_CHECK_GUID = '[SYSTEM PLANNING] Clear Meter Check GUID',
  FETCH_METER_CAPACITY_CHECK_COLLECTION = '[SYSTEM PLANNING] Fetch Meter Capacity Check Collection',
  FETCH_METER_CAPACITY_CHECK_COLLECTION_SUCCESS = '[SYSTEM PLANNING] Fetch Meter Capacity Check Collection Success',
  FETCH_METER_CAPACITY_CHECK_COLLECTION_FAILURE = '[SYSTEM PLANNING] Fetch Meter Capacity Check Collection Failure',
  CLEAR_METER_CAPACITY_CHECK_COLLECTION = '[SYSTEM PLANNING] Clear Meter Capacity Check Collection',
  FETCH_METER_CAPACITY_CHECK_BY_ID = '[SYSTEM PLANNING] Fetch Meter Capacity Check By Id',
  FETCH_METER_CAPACITY_CHECK_BY_ID_SUCCESS = '[SYSTEM PLANNING] Fetch Meter Capacity Check By Id Success',
  FETCH_METER_CAPACITY_CHECK_BY_ID_FAILURE = '[SYSTEM PLANNING] Fetch Meter Capacity Check By Id Failure',
  FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION = '[SYSTEM PLANNING] Fetch Operational Capacity Reservation Collection',
  FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION_SUCCESS = '[SYSTEM PLANNING] Fetch Operational Capacity Reservation Collection Success',
  FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION_FAILURE = '[SYSTEM PLANNING] Fetch Operational Capacity Reservation Collection Failure',
  CLEAR_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION = '[SYSTEM PLANNING] Clear Operational Capacity Reservation Collection',
  FETCH_LINE_SEQUENCE_COLLECTION = '[SYSTEM PLANNING] Fetch Line Sequence Collection',
  FETCH_LINE_SEQUENCE_COLLECTION_SUCCESS = '[SYSTEM PLANNING] Fetch Line Sequence Collection Success',
  FETCH_LINE_SEQUENCE_COLLECTION_FAILURE = '[SYSTEM PLANNING] Fetch Line Sequence Collection Failure',
  FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY = '[SYSTEM PLANNING] Fetch Operational Capacity Reservation Entry',
  FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS = '[SYSTEM PLANNING] Fetch Operational Capacity Reservation Entry Success',
  FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE = '[SYSTEM PLANNING] Fetch Operational Capacity Reservation Entry Failure',
  CLEAR_OPERATIONAL_CAPACITY_RESERVATION_ENTRY = '[SYSTEM PLANNING] Clear Operational Capacity Reservation Entry',
  SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY = '[SYSTEM PLANNING] Save Operational Capacity Reservation Entry',
  SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS = '[SYSTEM PLANNING] Save Operational Capacity Reservation Entry Success',
  SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE = '[SYSTEM PLANNING] Save Operational Capacity Reservation Entry Failure',
  UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY = '[SYSTEM PLANNING] Update Operational Capacity Reservation Entry',
  UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS = '[SYSTEM PLANNING] Update Operational Capacity Reservation Entry Success',
  UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE = '[SYSTEM PLANNING] Update Operational Capacity Reservation Entry Failure',
  FETCH_STORAGE_OBLIGATION_COLLECTION = '[SYSTEM PLANNING] Fetch Storage Obligation Collection',
  FETCH_STORAGE_OBLIGATION_COLLECTION_SUCCESS = '[SYSTEM PLANNING] Fetch Storage Obligation Collection Success',
  FETCH_STORAGE_OBLIGATION_COLLECTION_FAILURE = '[SYSTEM PLANNING] Fetch Storage Obligation Collection Failure',
  CLEAR_STORAGE_OBLIGATION_COLLECTION = '[SYSTEM PLANNING] Clear Storage Obligation Collection',
  EXPORT_STORAGE_OBLIGATION_COLLECTION = '[SYSTEM PLANNING] Export Storage Obligation Collection',
  EXPORT_STORAGE_OBLIGATION_COLLECTION_SUCCESS = '[SYSTEM PLANNING] Export Storage Obligation Collection Success',
  EXPORT_STORAGE_OBLIGATION_DOWNLOAD_SUCCESS = '[SYSTEM PLANNING] Export Storage Obligation Download Success',
  EXPORT_STORAGE_OBLIGATION_COLLECTION_FAILURE = '[SYSTEM PLANNING] Export Storage Obligation Collection Failure',
  CLEAR_EXPORT_STORAGE_OBLIGATION_COLLECTION = '[SYSTEM PLANNING] Clear Export Storage Obligation Collection',
}

export interface IFetchObligationsPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  entityId?: number[];
  tspId?: number;
  requestId?: number;
  requestStatus?: string;
  lastUpdateBegin?: Date;
  lastUpdateEnd?: Date;
  rateScheduleId?: number[];
  assignedToId?: number;
  studyBeginDate?: Date;
  studyEndDate?: Date;
  segmentable?: boolean[];
  combination?: boolean[];
  contractualRofr?: boolean[];
  flowDirection?: string[];
  contractId?: number[];
  deliveryZone?: string[];
  receiptZone?: string[];
  locationId?: number[];
  lineId?: number[];
  averageDayBaseMonthId?: number;
  userId?: number;
  studyName?: string;
  obligationSelection?: ObligationSelection;
}

export interface IFetchAverageDayPayload {
  tspId?: number;
  baseMonth?: Date;
}

export interface IUpdateAverageDayDefaultPayload {
  mongoDocumentId?: string;
  id?: number;
  tspId?: number;
  baseMonth?: Date;
  expiredBy?: Date;
  dateTimeExpired?: Date;
  addedBy?: number;
  dateTimeAdded?: Date;
  modifiedBy?: number;
  dateTimeModified?: Date;
  isDefault: boolean;
}

export interface IFetchMeterCheckCapacityPayload {
  guid?: string;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  lineIds?: number[];
  locationIds?: number[];
  sequenceIds?: number[];
}

export interface IFetchOperationalCapacityReservationListPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
}

export interface IFetchStorageObligationCollectionPayload {
  tspId?: number;
  studyBeginDate?: Date;
  studyEndDate?: Date;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  userId?: number;
}

export class GetFirmObligationsCollection implements Action {
  public readonly type = ESystemPlanningActions.FETCH_OBLIGATIONS_COLLECTION;

  constructor(public payload: IFetchObligationsPayload) {}
}

export class GetFirmObligationsCollectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_OBLIGATIONS_COLLECTION_SUCCESS;

  constructor(public payload: ObligationCollection) {}
}

export class GetFirmObligationsCollectionFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_OBLIGATIONS_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAverageDayCollection implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_COLLECTION;

  constructor(public payload: IFetchAverageDayPayload) {}
}

export class GetAverageDayCollectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_COLLECTION_SUCCESS;

  constructor(public payload: AverageDay) {}
}

export class GetAverageDayCollectionFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAverageDayById implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_BY_ID;

  constructor(public averageDayId: number) {}
}

export class GetAverageDayByIdSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_BY_ID_SUCCESS;

  constructor(public payload: AverageDay) {}
}

export class GetAverageDayByIdFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearFirmObligationsCollection implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_OBLIGATIONS_COLLECTION;

  constructor() {}
}

export class ClearAverageDayCollection implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_AVERAGE_DAY_COLLECTION;
}

export class SaveAverageDay implements Action {
  public readonly type = ESystemPlanningActions.SAVE_AVERAGE_DAY;

  constructor(public payload: AverageDay) {}
}

export class SaveAverageDaySucess implements Action {
  public readonly type = ESystemPlanningActions.SAVE_AVERAGE_DAY_SUCCESS;

  constructor(public payload: AverageDay) {}
}

export class SaveAverageDayFailure implements Action {
  public readonly type = ESystemPlanningActions.SAVE_AVERAGE_DAY_FAILURE;

  constructor(public payload: AverageDay) {}
}

export class UpdateAverageDayDefault implements Action {
  public readonly type = ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT;

  constructor(public payload: AverageDay) {}
}

export class UpdateAverageDayDefaultSuccess implements Action {
  public readonly type = ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT_SUCCESS;

  constructor(public payload: AverageDay) {}
}

export class UpdateAverageDayDefaultFailure implements Action {
  public readonly type = ESystemPlanningActions.UPDATE_AVERAGE_DAY_DEFAULT_FAILURE;

  constructor(public payload: AverageDay) {}
}

export class ExportAverageDayMeasurementSelection implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION;

  constructor(public payload: AverageDayExport) {}
}

export class ExportAverageDayMeasurementSelectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION_SUCCESS;

  constructor(public payload: AverageDayExport) {}
}

export class ExportAverageDayMeasurementSelectionFailure implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_SELECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportAverageDayMeasurementDownloadResultSuccess implements Action {
  public readonly type =
    ESystemPlanningActions.EXPORT_AVERAGE_DAY_MEASUREMENT_DOWNLOAD_RESULT_SUCCESS;
}

export class AcknowledgeNetworkResult implements Action {
  public readonly type = ESystemPlanningActions.ACKNOWLEDGE_NETWORK_RESULT;
}

export class GetAverageDayLookup implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_LOOKUP;

  constructor(public tspId: number) {}
}

export class GetAverageDayLookupSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_LOOKUP_SUCCESS;

  constructor(public payload: LookupCollections) {}
}

export class GetAverageDayLookupFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_AVERAGE_DAY_LOOKUP_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportFirmObligationSelection implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_SELECTION;

  constructor(public payload: IFetchObligationsPayload) {}
}

export class ExportFirmObligationSelectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_SELECTION_SUCCESS;

  constructor(public payload: ObligationCollection) {}
}

export class ExportFirmObligationSelectionFailure implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_SELECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ExportFirmObligationDownloadResultSuccess implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_FIRM_OBLIGATION_DOWNLOAD_RESULT_SUCCESS;
}

export class GetMeterCheckGUID implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CHECK_GUID;

  constructor(public payload: IFetchObligationsPayload) {}
}

export class GetMeterCheckGUIDSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CHECK_GUID_SUCCESS;

  constructor(public payload: string) {}
}

export class GetMeterCheckGUIDFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CHECK_GUID_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearMeterCheckGUID implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_METER_CHECK_GUID;

  constructor() {}
}

export class GetMeterCapacityCheckCollection implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_COLLECTION;

  constructor(public payload: IFetchMeterCheckCapacityPayload) {}
}

export class GetMeterCapacityCheckCollectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_COLLECTION_SUCCESS;

  constructor(public payload: MeterCapacityCheckModelCollection) {}
}

export class GetMeterCapacityCheckCollectionFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearMeterCapacityCheckCollection implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_METER_CAPACITY_CHECK_COLLECTION;

  constructor() {}
}

export class GetMeterCapacityCheckById implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_BY_ID;

  constructor(public payload: number) {}
}

export class GetMeterCapacityCheckByIdSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_BY_ID_SUCCESS;

  constructor(public payload: MeterCapacityCheckModel) {}
}

export class GetMeterCapacityCheckByIdFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_METER_CAPACITY_CHECK_BY_ID_FAILURE;

  constructor(public payload: Error) {}
}
export class GetOperationalCapacityReservationCollection implements Action {
  public readonly type = ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION;

  constructor(public payload: IFetchOperationalCapacityReservationListPayload) {}
}

export class GetOperationalCapacityReservationCollectionSuccess implements Action {
  public readonly type =
    ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION_SUCCESS;

  constructor(public payload: ReservationList) {}
}

export class GetOperationalCapacityReservationCollectionFailure implements Action {
  public readonly type =
    ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearOperationalCapacityReservationCollection implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_OPERATIONAL_CAPACITY_RESERVATION_COLLECTION;
}

export class GetOperationalCapacityReservationEntry implements Action {
  public readonly type = ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY;

  constructor(public payload: { reservationId: number }) {}
}

export class GetOperationalCapacityReservationEntrySuccess implements Action {
  public readonly type =
    ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS;

  constructor(public payload: ReservationEntry) {}
}

export class GetOperationalCapacityReservationEntryFailure implements Action {
  public readonly type =
    ESystemPlanningActions.FETCH_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE;

  constructor(public payload: Error) {}
}

export class SaveOperationalCapacityReservationEntry implements Action {
  public readonly type = ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY;

  constructor(public payload: ReservationEntry) {}
}

export class SaveOperationalCapacityReservationEntrySuccess implements Action {
  public readonly type = ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS;

  constructor(public payload: ReservationEntry) {}
}

export class SaveOperationalCapacityReservationEntryFailure implements Action {
  public readonly type = ESystemPlanningActions.SAVE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateOperationalCapacityReservationEntry implements Action {
  public readonly type = ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY;

  constructor(public payload: ReservationEntry, public id: number) {}
}

export class UpdateOperationalCapacityReservationEntrySuccess implements Action {
  public readonly type =
    ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_SUCCESS;

  constructor(public payload: ReservationEntry) {}
}

export class UpdateOperationalCapacityReservationEntryFailure implements Action {
  public readonly type =
    ESystemPlanningActions.UPDATE_OPERATIONAL_CAPACITY_RESERVATION_ENTRY_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearOperationalCapacityReservationEntry implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_OPERATIONAL_CAPACITY_RESERVATION_ENTRY;
}

export class GetLineSequenceCollection implements Action {
  public readonly type = ESystemPlanningActions.FETCH_LINE_SEQUENCE_COLLECTION;

  constructor() {}
}

export class GetLineSequenceCollectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_LINE_SEQUENCE_COLLECTION_SUCCESS;

  constructor(public payload: LineSequenceCollection) {}
}

export class GetLineSequenceCollectionFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_LINE_SEQUENCE_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class GetStorageObligationCollection implements Action {
  public readonly type = ESystemPlanningActions.FETCH_STORAGE_OBLIGATION_COLLECTION;

  constructor(public payload: IFetchStorageObligationCollectionPayload) {}
}

export class GetStorageObligationCollectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.FETCH_STORAGE_OBLIGATION_COLLECTION_SUCCESS;

  constructor(public payload: StorageObligationList) {}
}

export class GetStorageObligationCollectionFailure implements Action {
  public readonly type = ESystemPlanningActions.FETCH_STORAGE_OBLIGATION_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearStorageObligationCollection implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_STORAGE_OBLIGATION_COLLECTION;
}

export class ExportStorageObligationCollection implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_COLLECTION;

  constructor(public payload: IFetchStorageObligationCollectionPayload) {}
}

export class ExportStorageObligationCollectionSuccess implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_COLLECTION_SUCCESS;

  constructor(public payload: StorageObligationList) {}
}

export class ExportStorageObligationDownloadSuccess implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_DOWNLOAD_SUCCESS;

  constructor(public payload: StorageObligationList) {}
}

export class ExportStorageObligationCollectionFailure implements Action {
  public readonly type = ESystemPlanningActions.EXPORT_STORAGE_OBLIGATION_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class ClearExportStorageObligationCollection implements Action {
  public readonly type = ESystemPlanningActions.CLEAR_EXPORT_STORAGE_OBLIGATION_COLLECTION;
}

export type SystemPlanningActions =
  | GetFirmObligationsCollection
  | GetFirmObligationsCollectionSuccess
  | GetFirmObligationsCollectionFailure
  | GetAverageDayCollection
  | GetAverageDayCollectionSuccess
  | GetAverageDayCollectionFailure
  | ClearFirmObligationsCollection
  | ClearAverageDayCollection
  | SaveAverageDay
  | SaveAverageDaySucess
  | SaveAverageDayFailure
  | AcknowledgeNetworkResult
  | GetAverageDayLookup
  | GetAverageDayLookupSuccess
  | GetAverageDayLookupFailure
  | GetAverageDayById
  | UpdateAverageDayDefault
  | UpdateAverageDayDefaultSuccess
  | UpdateAverageDayDefaultFailure
  | ExportAverageDayMeasurementSelection
  | ExportAverageDayMeasurementSelectionSuccess
  | ExportAverageDayMeasurementSelectionFailure
  | ExportAverageDayMeasurementDownloadResultSuccess
  | GetAverageDayByIdFailure
  | GetAverageDayByIdSuccess
  | ExportFirmObligationSelection
  | ExportFirmObligationSelectionSuccess
  | ExportFirmObligationSelectionFailure
  | ExportFirmObligationDownloadResultSuccess
  | GetMeterCheckGUID
  | GetMeterCheckGUIDSuccess
  | GetMeterCheckGUIDFailure
  | ClearMeterCheckGUID
  | GetMeterCapacityCheckCollection
  | GetMeterCapacityCheckCollectionSuccess
  | GetMeterCapacityCheckCollectionFailure
  | ClearMeterCapacityCheckCollection
  | GetMeterCapacityCheckById
  | GetMeterCapacityCheckByIdSuccess
  | GetMeterCapacityCheckByIdFailure
  | GetOperationalCapacityReservationCollection
  | GetOperationalCapacityReservationCollectionSuccess
  | GetOperationalCapacityReservationCollectionFailure
  | ClearOperationalCapacityReservationCollection
  | GetLineSequenceCollection
  | GetLineSequenceCollectionSuccess
  | GetLineSequenceCollectionFailure
  | GetOperationalCapacityReservationEntry
  | GetOperationalCapacityReservationEntrySuccess
  | GetOperationalCapacityReservationEntryFailure
  | ClearOperationalCapacityReservationEntry
  | SaveOperationalCapacityReservationEntry
  | SaveOperationalCapacityReservationEntrySuccess
  | SaveOperationalCapacityReservationEntryFailure
  | UpdateOperationalCapacityReservationEntry
  | UpdateOperationalCapacityReservationEntrySuccess
  | UpdateOperationalCapacityReservationEntryFailure
  | GetStorageObligationCollection
  | GetStorageObligationCollectionSuccess
  | GetStorageObligationCollectionFailure
  | ClearStorageObligationCollection
  | ExportStorageObligationCollection
  | ExportStorageObligationDownloadSuccess
  | ExportStorageObligationCollectionFailure
  | ClearExportStorageObligationCollection;
