import { dateUtils } from 'shared/utils/date.utils';
import { isNullOrUndefined } from 'shared/utils/type.utils';

function transformDatesArray(dto: any) {
  if (isNullOrUndefined(dto)) {
    return null;
  }
  switch (typeof dto) {
    case 'string':
      if (Number(dto) === parseInt(dto, 10) || Number(dto) === parseFloat(dto)) {
        return dto;
      } else {
        const date = new Date(dto);
        return isNaN(date.getTime()) ? dto : dateUtils.getDateTimeFromString(dto);
      }
    case 'object':
      return transformDates(dto);
    default:
      return dto;
  }
}

export function transformDates(dto: any) {
  if (isNullOrUndefined(dto)) {
    return {};
  }

  return Object.keys(dto).reduce((domainObject: any, key) => {
    switch (typeof dto[key]) {
      case 'string':
        if (
          Number(dto[key]) === parseInt(dto[key], 10) ||
          Number(dto[key]) === parseFloat(dto[key])
        ) {
          domainObject[key] = dto[key];
        } else if (key === 'comment' || key === 'specialTerms') {
          //This is a temporary fix, to be removed for tech debt
          domainObject[key] = dto[key];
        } else {
          const date = new Date(dto[key]);
          domainObject[key] = isNaN(date.getTime())
            ? dto[key]
            : dateUtils.getDateTimeFromString(dto[key]);
        }
        break;
      case 'object':
        if (dto[key] instanceof Date) {
          domainObject[key] = dto[key];
          break;
        }
        domainObject[key] = Array.isArray(dto[key])
          ? dto[key].map(transformDatesArray) // an array needs a special case since it can have strings, numbers that can't be treated as an object with keys
          : transformDates(dto[key]);
        break;
      default:
        domainObject[key] = dto[key];
        break;
    }

    return domainObject;
  }, {});
}
