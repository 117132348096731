import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Auction,
  AuctionAward,
  AuctionAwardCollection,
  AuctionBanner,
  AuctionCollection,
  AuctionDraft,
  AuctionEvent,
  AuctionEventComposite,
  AuctionNotice,
  AuctionNoticeCollection,
  AuctionNoticeDocumentContent,
  AuctionNoticeGeneration,
  AuctionNoticeTemplateContentRequest,
  AuctionParticipant,
  AuctionParticipantCollection,
  AuctionRateSchedule,
  AuctionSegment,
  AuctionSegmentCollection,
  AuctionUserCollection,
  ITOnlineAdminCellsCollection,
  ITOnlineCellsCollection,
  ITOnlineDeliveryLocationCollection,
  ITOnlineICELocationCollection,
  ITOnlineLocation,
  ITOnlineLocationCollection,
  ITOnlineParameterSet,
  ITOnlineParameterSetCollection,
  ITOnlineReceiptLocationCollection,
  PostAndBidBiddingWindowMappingCollection,
  ReferenceTypeCollection,
  Trade,
  TradeCollection,
  WorkflowCaseCreationRequest,
} from '@gms/auction-api';
import { DetailRateSchedule, MasterRateSchedule } from '@gms/rateschedulev2-api';
import { ServiceProvider } from '@gms/tsp-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ELocationsGridTypes } from 'app/modules/it-online/components/locations-grid/locations-grid.utils';
import {
  IAuction,
  IAuctionAwardsPayload,
  IAuctionBidGridPayload,
  IAuctionNoticeDocumentContentsMap,
  IAuctionParticipantEventPayload,
  IAuctionParticipantPayload,
  IAuctionSegmentsPayload,
  IITOnlineCellsPayload,
  IITOnlineLocationsPayload,
} from './auctions.model';

export enum EAuctionsActions {
  GET_AUCTION_COLLECTION = '[AUCTIONS] Get Auction Collection',
  GET_AUCTION_COLLECTION_SUCCESS = '[AUCTIONS] Get Auction Collection Success',
  GET_AUCTION_COLLECTION_FAILURE = '[AUCTIONS] Get Auction Collection Failure',
  GET_TRADE_COLLECTION = '[AUCTIONS] Get Trade Collection',
  GET_TRADE_COLLECTION_SUCCESS = '[AUCTIONS] Get Trade Collection Success',
  GET_TRADE_COLLECTION_FAILURE = '[AUCTIONS] Get Trade Collection Failure',
  POST_TRADE = '[AUCTIONS] Post Trade',
  POST_TRADE_SUCCESS = '[AUCTIONS] Post Trade Success',
  POST_TRADE_FAILURE = '[AUCTIONS] Post Trade Failure',
  GET_BIDDING_PERIOD_MAPPINGS = '[AUCTIONS] Get BiddingPeriodMappings',
  GET_BIDDING_PERIOD_MAPPINGS_SUCCESS = '[AUCTIONS] Get BiddingPeriodMappings Success',
  GET_BIDDING_PERIOD_MAPPINGS_FAILURE = '[AUCTIONS] Get BiddingPeriodMappings Failure',
  GET_AUCTION_SERIES_COLLECTION = '[AUCTIONS] Get Auction Series Collection',
  GET_AUCTION_SERIES_COLLECTION_SUCCESS = '[AUCTIONS] Get Auction Series Collection Success',
  GET_AUCTION_SERIES_COLLECTION_FAILURE = '[AUCTIONS] Get Auction Series Collection Failure',
  CANCEL_AUCTION_BY_ID = '[AUCTIONS] Cancel Auction',
  CANCEL_AUCTION_BY_ID_SUCCESS = '[AUCTIONS] Cancel Auction Success',
  CANCEL_AUCTION_BY_ID_FAILURE = '[AUCTIONS] Cancel Auction Failure',
  SAVE_AUCTION_DRAFT = '[AUCTIONS] Save Auction Draft',
  SAVE_AUCTION_DRAFT_SUCCESS = '[AUCTIONS] Save Auction Draft Success',
  SAVE_AUCTION_DRAFT_FAILURE = '[AUCTIONS] Save Auction Draft Failure',
  GET_AUCTION_RATE_SCHEDULES = '[AUCTIONS] Get Auctions Rate Schedules',
  GET_AUCTION_RATE_SCHEDULES_SUCCESS = '[AUCTIONS] Get Auction Rate Schedules Success',
  GET_AUCTION_RATE_SCHEDULES_FAILURE = '[AUCTIONS] Get Auction Rate Schedules Failure',
  GET_AUCTION_REFERENCE_TYPES = '[AUCTIONS] Get Auction Reference Types',
  GET_AUCTION_REFERENCE_TYPES_SUCCESS = '[AUCTIONS] Get Auction Reference Types Success',
  GET_AUCTION_REFERENCE_TYPES_FAILURE = '[AUCTIONS] Get Auction Reference Types Failure',
  GET_AUCTION_RATE_SCHEDULE_DETAILS = '[AUCTIONS] Get Auction Rate Schedule Details',
  GET_AUCTION_RATE_SCHEDULE_DETAILS_SUCCESS = '[AUCTIONS] Get Auction Rate Schedule Details Success',
  GET_AUCTION_RATE_SCHEDULE_DETAILS_FAILURE = '[AUCTIONS] Get Auction Rate Schedule Details Failure',
  DELETE_AUCTION_DRAFT = '[AUCTIONS] Delete Auction Draft',
  DELETE_AUCTION_DRAFT_SUCCESS = '[AUCTIONS] Delete Auction Draft Success',
  DELETE_AUCTION_DRAFT_FAILURE = '[AUCTIONS] Delete Auction Draft Failure',
  GET_AUCTION_DRAFT_BY_ID = '[AUCTIONS] Get Auction Draft By Id',
  GET_AUCTION_DRAFT_BY_ID_SUCCESS = '[AUCTIONS] Get Auction Draft By Id Success',
  GET_AUCTION_DRAFT_BY_ID_FAILURE = '[AUCTIONS] Get Auction Draft By Id Failure',
  GET_AUCTION_BY_ID = '[AUCTIONS] Get Auction By Id',
  GET_AUCTION_BY_ID_SUCCESS = '[AUCTIONS] Get Auction By Id Success',
  GET_AUCTION_BY_ID_FAILURE = '[AUCTIONS] Get Auction By Id Failure',
  SET_DETAIL_RATE_SCHEDULE = '[AUCTIONS] Set Auction Rate Schedule',
  SET_MASTER_RATE_SCHEDULE = '[AUCTIONS] Set Auction Master Rate Schedule',
  SET_AUCTION_TSP = '[AUCTIONS] Set Auction Tsp',
  UPDATE_SERVICE_REQUEST_FORM_VALIDITY = '[AUCTIONS] Update Service Request Form Validity',
  RESET_SERVICE_REQUEST_FORM_VALIDITY = '[AUCTIONS] Reset Service Request Form Validity',
  SET_SERVICE_REQUEST_FORM_AS_TOUCHED = '[AUCTIONS] Mark Request Form As Touched',
  CLEAR_AUCTION_DRAFT = '[AUCTIONS] Clear Current Auction Draft',
  CLEAR_AUCTION = '[AUCTIONS] Clear Current Auction',
  UPDATE_AUCTION_DRAFT = '[AUCTIONS] Update Auction Draft',
  UPDATE_AUCTION_DRAFT_SUCCESS = '[AUCTIONS] Update Auction Draft Sucess',
  UPDATE_AUCTION_DRAFT_FAILURE = '[AUCTIONS] Update Auction Draft Failure',
  POST_AUCTION = '[AUCTIONS] Post Auction',
  POST_AUCTION_SUCCESS = '[AUCTIONS] Post Auction Success',
  POST_AUCTION_FAILURE = '[AUCTIONS] Post Auction Failure',
  CLEAR_POST_AUCTION_STATUS = '[AUCTIONS] Clear Post Auction Status',
  PUT_AUCTION = '[AUCTIONS] Put Auction',
  PUT_AUCTION_SUCCESS = '[AUCTIONS] Put Auction Success',
  PUT_AUCTION_FAILURE = '[AUCTIONS] Put Auction Failure',
  PUT_AUCTION_NOTICE = '[AUCTIONS] Put Auction Notice',
  PUT_AUCTION_NOTICE_SUCCESS = '[AUCTIONS] Put Auction Notice Success',
  PUT_AUCTION_NOTICE_FAILURE = '[AUCTIONS] Put Auction Notice Failure',
  POST_AUCTION_NOTICE = '[AUCTIONS] Post Auction Notice',
  POST_AUCTION_NOTICE_SUCCESS = '[AUCTIONS] Post Auction Notice Success',
  POST_AUCTION_NOTICE_FAILURE = '[AUCTIONS] Post Auction Notice Failure',
  CANCEL_AUCTION = '[AUCTIONS] Cancel Auction',
  CANCEL_AUCTION_SUCCESS = '[AUCTIONS] Cancel Auction Success',
  CANCEL_AUCTION_FAILURE = '[AUCTIONS] Cancel Auction Failure',
  UPDATE_CLIENT_SIDE_AUCTION = '[AUCTIONS] Update Client Side Auction',
  COPY_AUCTION_BY_ID = '[AUCTIONS] COPY Auction By Id',
  COPY_AUCTION_BY_ID_SUCCESS = '[AUCTIONS] COPY Auction By Id Success',
  COPY_AUCTION_BY_ID_FAILURE = '[AUCTIONS] COPY Auction By Id Failure',
  COPY_AUCTION_DRAFT_BY_ID = '[AUCTIONS] COPY Auction DRAFT By Id',
  COPY_AUCTION_DRAFT_BY_ID_SUCCESS = '[AUCTIONS] COPY Auction DRAFT By Id Success',
  COPY_AUCTION_DRAFT_BY_ID_FAILURE = '[AUCTIONS] COPY Auction DRAFT By Id Failure',
  CLEAR_COPY_AUCTION_STATUS = '[AUCTIONS] Clear COPY Auction Status',
  CLEAR_CANCEL_AUCTION_STATUS = '[AUCTIONS] Clear Cancel Auction Status',
  GET_SERIES_AUCTION_COLLECTION = 'GET_SERIES_AUCTION_COLLECTION',
  CANCEL_AUCTION_SERIES_FAILURE = 'CANCEL_AUCTION_SERIES_FAILURE',
  SET_AUCTION_OVERVIEW_FORM_LOADING = '[Auctions] Set Auction Overview Form Loading',
  POST_AUCTION_EVENT = '[Auctions] Post Auction Event',
  POST_AUCTION_EVENT_SUCCESS = '[Auctions] Post Auction Event Success',
  POST_AUCTION_EVENT_FAILURE = '[Auctions] Post Auction Event Failure',
  GET_AUCTION_EVENT_POPOUT_PANEL = '[Auctions] Get Auction Event Popup Panel',
  GET_AUCTION_EVENT_POPOUT_PANEL_SUCCESS = '[Auctions] Get Auction Event Popup Panel Success',
  GET_AUCTION_EVENT_POPOUT_PANEL_FAILURE = '[Auctions] Get Auction Event Popup Panel Failure',
  POST_AUCTION_BID = '[Auctions] Post Auction Bid',
  POST_AUCTION_BID_SUCCESS = '[Auctions] Post Auction Bid Success',
  POST_AUCTION_BID_FAILURE = '[Auctions] Post Auction Bid Failure',
  WITHDRAW_AUCTION_BID = '[Auctions] Withdraw Auction Bid',
  WITHDRAW_AUCTION_BID_SUCCESS = '[Auctions] Withdraw Auction Bid Success',
  WITHDRAW_AUCTION_BID_FAILURE = '[Auctions] Withdraw Auction Bid Failure',
  GET_AUCTION_BID_GRID = '[Auctions] Get Auction Bid Grid',
  GET_AUCTION_BID_GRID_SUCCESS = '[Auctions] Get Auction Bid Grid Success',
  GET_AUCTION_BID_GRID_FAILURE = '[Auctions] Get Auction Bid Grid Failure',
  POST_AUCTION_SEGMENT = '[Auctions] Post Auction Segment',
  POST_AUCTION_SEGMENT_SUCCESS = '[Auctions] Post Auction Segment Success',
  POST_AUCTION_SEGMENT_FAILURE = '[Auctions] Post Auction Segment Failure',
  GET_AUCTION_AWARDS = '[Auctions] Get Auction Awards',
  GET_AUCTION_AWARDS_SUCCESS = '[Auctions] Get Auction Awards Success',
  GET_AUCTION_AWARDS_FAILURE = '[Auctions] Get Auction Awards Failure',
  SET_AUCTION = '[Auctions] Set Auction',
  CREATE_AUCTION_PARTICIPANT = '[Auctions] Create Auction Participant',
  CREATE_AUCTION_PARTICIPANT_SUCCESS = '[Auctions] Create Auction Participant success',
  CREATE_AUCTION_PARTICIPANT_FAILURE = '[Auctions] Create Auction Participant failure',
  GET_AUCTION_NOTICES = '[Auctions] Get Auction Notice',
  GET_AUCTION_NOTICES_SUCCESS = '[Auctions] Get Auction Notice success',
  GET_AUCTION_NOTICES_FAILURE = '[Auctions] Get Auction Notice failure',
  CLEAR_AUCTION_NOTICE = '[Auctions] Clear Auction Notice',
  GET_AUCTION_PARTICIPANT = '[Auctions] Get Auction Participant',
  GET_AUCTION_PARTICIPANT_SUCCESS = '[Auctions] Get Auction Participant success',
  GET_AUCTION_PARTICIPANT_FAILURE = '[Auctions] Get Auction Participant failure',
  GET_AUCTION_PARTICIPANTS = '[Auctions] Get Auction Participants',
  GET_AUCTION_PARTICIPANTS_SUCCESS = '[Auctions] Get Auction Participants Success',
  GET_AUCTION_PARTICIPANTS_FAILURE = '[Auctions] Get Auction Participants Failure',
  GET_AUCTION_SEGMENTS = '[Auctions] Get Auction Segments',
  GET_AUCTION_SEGMENTS_SUCCESS = '[Auctions] Get Auction Segments Success',
  GET_AUCTION_SEGMENTS_FAILURE = '[Auctions] Get Auction Segments Failure',
  POST_PARTICIPANT_ENTER = '[Auctions] Post Participant Enter Event',
  POST_PARTICIPANT_ENTER_SUCCESS = '[Auctions] Post Participant Enter Event success',
  POST_PARTICIPANT_ENTER_FAILURE = '[Auctions] Post Participant Enter Event failure',
  GET_AUCTION_MESSAGE_PANEL = '[Auctions] Get Auction Message Panel',
  GET_AUCTION_MESSAGE_PANEL_SUCCESS = '[Auctions] Get Auction Message Panel success',
  GET_AUCTION_MESSAGE_PANEL_FAILURE = '[Auctions] Get Auction Message Panel failure',
  GET_AUCTION_BANNER = '[Auctions] Get Auction Banner',
  GET_AUCTION_BANNER_SUCCESS = '[Auctions] Get Auction Banner success',
  GET_AUCTION_BANNER_FAILURE = '[Auctions] Get Auction Banner failure',
  EVENT_UPDATE_AUCTION_BANNER = '[Auctions] Update Banner with event data',
  TOGGLE_AWARD_PERIOD_TIMER = '[Auctions] Toggle Award Period Timer',
  END_AWARD_PERIOD = '[Auctions] End Awarding Period',
  PUT_AUCTION_AWARD = '[AUCTIONS] Put Auction Award',
  PUT_AUCTION_AWARD_SUCCESS = '[AUCTIONS] Put Auction Award Success',
  PUT_AUCTION_AWARD_FAILURE = '[AUCTIONS] Put Auction Award Failure',
  GET_CALCULATED_AWARD = '[AUCTIONS] Get Calculated Auction Award',
  GET_CALCULATED_AWARD_SUCCESS = '[AUCTIONS] Get Calculated Auction Success',
  GET_CALCULATED_AWARD_FAILURE = '[AUCTIONS] Get Calculated Auction Failure',
  POST_AUCTION_AWARDS = '[AUCTIONS] Post Auction Awards',
  POST_AUCTION_AWARDS_SUCCESS = '[AUCTIONS] Post Auction Awards Success',
  POST_AUCTION_AWARDS_FAILURE = '[AUCTIONS] Post Auction Awards Failure',
  VALIDATE_AUCTION_SETTINGS = '[Auctions] Validate Auction Settings',
  SET_AUCTION_SETTINGS_VALID = '[Auctions] Set Auction Settings Valid',
  POST_AUCTION_NOTICE_TEMPLATE = '[Auctions] Post Auction Notice Template Content',
  POST_AUCTION_NOTICE_TEMPLATE_SUCCESS = '[Auctions] Post Auction Notice Template Content Success',
  POST_AUCTION_NOTICE_TEMPLATE_FAILURE = '[Auctions] Post Auction Notice Template Content Failure',
  CLEAR_AUCTION_NOTICE_TEMPLATE = '[Auctions] Clear Auction Notice Template Content',
  GET_AUCTION_NOTICE_DOCUMENT_CONTENT = '[Auctions] Get Auction Notice Document Content',
  GET_AUCTION_NOTICE_DOCUMENT_CONTENT_SUCCESS = '[Auctions] Get Auction Notice Document Content Success',
  GET_AUCTION_NOTICE_DOCUMENT_CONTENT_FAILURE = '[Auctions] Get Auction Notice Document Content Failure',
  EXPORT_TRADES = '[Auctions] Export Tradeblotter Trades to file.',
  EXPORT_TRADES_SUCCESS = '[Auctions] Export Tradeblotter Trades to file Success',
  EXPORT_TRADES_FAILURE = '[Auctions] Export Tradeblotter Trades to file Failure',
  UPDATE_TRADE_BY_ID = '[Auctions] Update Trade by Id.',
  UPDATE_TRADE_BY_ID_SUCCESS = '[Auctions] Update Trade by Id Success',
  UPDATE_TRADE_BY_ID_FAILURE = '[Auctions] Update Trade by Id Failure',
  SUBMIT_TRADES = '[Auctions] Submit Trades.',
  SUBMIT_TRADES_SUCCESS = '[Auctions] Submit Trades Success',
  SUBMIT_TRADES_FAILURE = '[Auctions] Submit Trades Failure',
  SET_DISABLE_CLOSE_BUTTON = '[Auctions] Set Disable Close Button',
  SET_BID_ALTERNATE_RATE_SCHEDULE = '[Auctions] Set Bid Alternate Rate Schedule',
  GET_IT_ONLINE_RECEIPT_LOCATIONS = '[Auctions] Get It Online Receipt Locations',
  GET_IT_ONLINE_RECEIPT_LOCATIONS_SUCCESS = '[Auctions] Get It Online Receipt Locations Success',
  GET_IT_ONLINE_RECEIPT_LOCATIONS_FAILURE = '[Auctions] Get It Online Receipt Locations Failure',
  GET_IT_ONLINE_DELIVERY_LOCATIONS = '[Auctions] Get It Online Delivery Locations',
  GET_IT_ONLINE_DELIVERY_LOCATIONS_SUCCESS = '[Auctions] Get It Online Delivery Locations Success',
  GET_IT_ONLINE_DELIVERY_LOCATIONS_FAILURE = '[Auctions] Get It Online Delivery Locations Failure',
  GET_IT_ONLINE_ICE_LOCATIONS = '[Auctions] Get It Online Ice Locations',
  GET_IT_ONLINE_ICE_LOCATIONS_SUCCESS = '[Auctions] Get It Online ICE Locations Success',
  GET_IT_ONLINE_ICE_LOCATIONS_FAILURE = '[Auctions] Get It Online ICE Locations Failure',
  GET_IT_ONLINE_ADMIN_CELLS = '[Auctions] Get It Online Admin Cells',
  GET_IT_ONLINE_ADMIN_CELLS_SUCCESS = '[Auctions] Get It Online Admin Cells Success',
  GET_IT_ONLINE_ADMIN_CELLS_FAILURE = '[Auctions] Get It Online Admin Cells Failure',
  GET_IT_ONLINE_CELLS = '[Auctions] Get It Online Cells',
  GET_IT_ONLINE_CELLS_SUCCESS = '[Auctions] Get It Online Cells Success',
  GET_IT_ONLINE_CELLS_FAILURE = '[Auctions] Get It Online Cells Failure',
  GET_IT_ONLINE_PARAMETER_SET = '[Auctions] Get It Online Parameter Set',
  GET_IT_ONLINE_PARAMETER_SET_SUCCESS = '[Auctions] Get It Online Parameter Set Success',
  GET_IT_ONLINE_PARAMETER_SET_FAILURE = '[Auctions] Get It Online Parameter Set Failure',
  PUT_IT_ONLINE_PARAMETER_SET = '[Auctions] Put It Online Parameter Set',
  PUT_IT_ONLINE_PARAMETER_SET_SUCCESS = '[Auctions] Put It Online Parameter Set Success',
  PUT_IT_ONLINE_PARAMETER_SET_FAILURE = '[Auctions] Put It Online Parameter Set Failure',
  SET_AUCTION_PARTICIPANTS = '[Auctions] Set Auction Participants',
  DELETE_IT_ONLINE_LOCATION = '[Auctions] Delete ITOnline Location',
  DELETE_IT_ONLINE_LOCATION_SUCCESS = '[Auctions] Delete ITOnline Location Success',
  DELETE_IT_ONLINE_LOCATION_FAILURE = '[Auctions] Delete ITOnline Location Failure',
  POST_IT_ONLINE_LOCATIONS = '[AUCTIONS] Post ITOnline Location',
  POST_IT_ONLINE_LOCATIONS_SUCCESS = '[AUCTIONS] Post ITOnline Location Success',
  POST_IT_ONLINE_LOCATIONS_FAILURE = '[AUCTIONS] Post ITOnline Location Failure',
  CLEAR_SUBMITTED_IT_ONLINE_TRADE = '[Auctions] Clear Submitted ITOnline Trade',
  GET_AUCTION_USERS = '[Auctions] Get Auction Users',
  GET_AUCTION_USERS_SUCCESS = '[Auctions] Get Auction Users Success',
  GET_AUCTION_USERS_FAILURE = '[Auctions] Get Auction Users Failure',
  SEARCH_AUCTION_USERS = '[Auctions] Search Auction Users',
  SEARCH_AUCTION_USERS_SUCCESS = '[Auctions] Search Auction Users Success',
  SEARCH_AUCTION_USERS_FAILURE = '[Auctions] Search Auction Users Failure',
  GENERATE_AUCTION_NOTICES = '[Auctions] Generate Auction Notices',
  GENERATE_AUCTION_NOTICES_SUCCESS = '[Auctions] Generate Auction Notices Success',
  GENERATE_AUCTION_NOTICES_FAILURE = '[Auctions] Generate Auction Notices Failure',
  CLEAR_AUCTION_NOTICE_STATE = '[Auctions] Clear Auction Notice State',
  START_AUCTION = '[Auctions] Start Auction',
  START_AUCTION_SUCCESS = '[Auctions] Start Auction Success',
  START_AUCTION_FAILURE = '[Auctions] Start Auction Failure',
  UPDATE_AUCTION_NOTICE_DOCUMENT_CONTENT_MAP = '[Auctions] Update Auction Notice Document Content Map',
  CLEAR_AUCTION_NOTICE_DOCUMENT_CONTENT_MAP = '[Auctions] Clear Auction Notice Document Content Map',
}

export interface IAuctionsPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  detailRateScheduleId?: number;
  status?: string[];
  tspId?: number;
  series?: boolean;
  seriesAuctionId?: number;
  contractualRofrFlag?: boolean;
  hideZeroQuantityClosed?: boolean;
  dateTimeAuctionStart?: string;
  dateTimeAuctionEnd?: string;
  quantityOffered?: number;
  quantityAwarded?: number;
  dateContractBegin?: string;
  datePrimaryTermEnd?: string;
  auctionTypeId?: number;
  auctioneerId?: number;
  auctionId?: number;
  serviceRequestId?: number;
  dateTimeModified?: string;
}

export interface ITradesPayload {
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  traderId?: number;
  externalTraderId?: number;
  tspId?: number;
  offerDate?: string;
  beginningOfferDate?: string;
  itOnlineFlag?: boolean;
  serviceTypeId?: number;
  entityId?: number;
}

export interface IBiddingPeriodPayload {
  tspId?: number;
  masterRateScheduleId?: number;
  auctionTypeId?: number;
  multiYearTermFlag?: boolean;
}

export interface IGetAuctionUsersPayload {
  userSearch?: string;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
  entityId?: number;
  roleIds: number[];
}

export class GetAuctionCollection implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_COLLECTION;

  constructor(public payload: IAuctionsPayload) {}
}

export class GetAuctionCollectionSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_COLLECTION_SUCCESS;

  constructor(public payload: AuctionCollection) {}
}

export class GetAuctionCollectionFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class GetTradeCollection implements Action {
  public readonly type = EAuctionsActions.GET_TRADE_COLLECTION;

  constructor(public payload: ITradesPayload) {}
}

export class GetTradeCollectionSuccess implements Action {
  public readonly type = EAuctionsActions.GET_TRADE_COLLECTION_SUCCESS;

  constructor(public payload: TradeCollection) {}
}

export class GetTradeCollectionFailure implements Action {
  public readonly type = EAuctionsActions.GET_TRADE_COLLECTION_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostTrade implements Action {
  public readonly type = EAuctionsActions.POST_TRADE;

  constructor(public payload: Trade) {}
}

export class PostTradeSuccess implements Action {
  public readonly type = EAuctionsActions.POST_TRADE_SUCCESS;

  constructor(public payload: Trade) {}
}

export class PostTradeFailure implements Action {
  public readonly type = EAuctionsActions.POST_TRADE_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}
export class UpdateTradeById implements Action {
  public readonly type = EAuctionsActions.UPDATE_TRADE_BY_ID;

  constructor(public payload: Trade) {}
}

export class UpdateTradeByIdSuccess implements Action {
  public readonly type = EAuctionsActions.UPDATE_TRADE_BY_ID_SUCCESS;

  constructor(public payload: Trade) {}
}

export class UpdateTradeByIdFailure implements Action {
  public readonly type = EAuctionsActions.UPDATE_TRADE_BY_ID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}
export class SubmitTrades implements Action {
  public readonly type = EAuctionsActions.SUBMIT_TRADES;

  constructor(public payload: Array<Trade>) {}
}

export class SubmitTradesSuccess implements Action {
  public readonly type = EAuctionsActions.SUBMIT_TRADES_SUCCESS;

  constructor(public payload: Array<any>) {}
}

export class SubmitTradesFailure implements Action {
  public readonly type = EAuctionsActions.SUBMIT_TRADES_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionSeriesCollection implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_SERIES_COLLECTION;

  constructor(public payload: IAuctionsPayload) {}
}

export class GetAuctionSeriesCollectionSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_SERIES_COLLECTION_SUCCESS;

  constructor(public payload: AuctionCollection) {}
}

export class GetAuctionSeriesCollectionFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_SERIES_COLLECTION_FAILURE;

  constructor(public payload: Error) {}
}

export class GetBiddingPeriodMappings implements Action {
  public readonly type = EAuctionsActions.GET_BIDDING_PERIOD_MAPPINGS;

  constructor(public payload: IBiddingPeriodPayload) {}
}

export class GetBiddingPeriodMappingsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_BIDDING_PERIOD_MAPPINGS_SUCCESS;

  constructor(public payload: PostAndBidBiddingWindowMappingCollection) {}
}

export class GetBiddingPeriodMappingsFailure implements Action {
  public readonly type = EAuctionsActions.GET_BIDDING_PERIOD_MAPPINGS_FAILURE;

  constructor(public payload: Error) {}
}

export class CancelAuctionById implements Action {
  public readonly type = EAuctionsActions.CANCEL_AUCTION_BY_ID;

  constructor(public payload: number) {}
}

export class CancelAuctionByIdSuccess implements Action {
  public readonly type = EAuctionsActions.CANCEL_AUCTION_BY_ID_SUCCESS;

  constructor(public payload: Auction) {}
}

export class ClearCancelAuctionStatus implements Action {
  public readonly type = EAuctionsActions.CLEAR_CANCEL_AUCTION_STATUS;
}

export class CancelAuctionByIdFailure implements Action {
  public readonly type = EAuctionsActions.CANCEL_AUCTION_BY_ID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class SaveAuctionDraft implements Action {
  public readonly type = EAuctionsActions.SAVE_AUCTION_DRAFT;

  constructor(public payload: AuctionDraft) {}
}

export class SaveAuctionDraftSuccess implements Action {
  public readonly type = EAuctionsActions.SAVE_AUCTION_DRAFT_SUCCESS;

  constructor(public payload: AuctionDraft) {}
}

export class SaveAuctionDraftFailure implements Action {
  public readonly type = EAuctionsActions.SAVE_AUCTION_DRAFT_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionReferenceTypes implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_REFERENCE_TYPES;
}

export class GetAuctionReferenceTypesSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_REFERENCE_TYPES_SUCCESS;

  constructor(public payload: ReferenceTypeCollection) {}
}

export class GetAuctionReferenceTypesFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_REFERENCE_TYPES_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAuctionRateSchedules implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_RATE_SCHEDULES;
}

export class GetAuctionRateSchedulesSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_RATE_SCHEDULES_SUCCESS;

  constructor(public payload: AuctionRateSchedule[]) {}
}

export class GetAuctionRateSchedulesFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_RATE_SCHEDULES_FAILURE;

  constructor(public payload: Error) {}
}
export class GetAuctionRateScheduleDetails implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_RATE_SCHEDULE_DETAILS;

  constructor(public payload: AuctionRateSchedule[]) {}
}

export class GetAuctionRateScheduleDetailsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_RATE_SCHEDULE_DETAILS_SUCCESS;

  constructor(public payload: DetailRateSchedule[]) {}
}

export class GetAuctionRateScheduleDetailsFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_RATE_SCHEDULE_DETAILS_FAILURE;

  constructor(public payload: Error) {}
}

export class DeleteAuctionDraft implements Action {
  public readonly type = EAuctionsActions.DELETE_AUCTION_DRAFT;

  constructor(public auctionDraftId: number) {}
}

export class DeleteAuctionDraftSuccess implements Action {
  public readonly type = EAuctionsActions.DELETE_AUCTION_DRAFT_SUCCESS;
}

export class DeleteAuctionDraftFailure implements Action {
  public readonly type = EAuctionsActions.DELETE_AUCTION_DRAFT_FAILURE;

  constructor(public payload: Error) {}
}

export class GetAuctionDraftById implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_DRAFT_BY_ID;

  constructor(public payload: number) {}
}

export class GetAuctionDraftByIdSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_DRAFT_BY_ID_SUCCESS;

  constructor(public payload: IAuction) {}
}

export class GetAuctionDraftByIdFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_DRAFT_BY_ID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionById implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BY_ID;

  constructor(public payload: number) {}
}

export class GetAuctionByIdSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BY_ID_SUCCESS;

  constructor(public payload: IAuction) {}
}

export class GetAuctionByIdFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BY_ID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearAuctionDraft implements Action {
  public readonly type = EAuctionsActions.CLEAR_AUCTION_DRAFT;
}

export class ClearAuction implements Action {
  public readonly type = EAuctionsActions.CLEAR_AUCTION;
}

export class UpdateAuctionDraftById implements Action {
  public readonly type = EAuctionsActions.UPDATE_AUCTION_DRAFT;

  constructor(public payload: AuctionDraft) {}
}

export class UpdateAuctionDraftByIdSuccess implements Action {
  public readonly type = EAuctionsActions.UPDATE_AUCTION_DRAFT_SUCCESS;

  constructor(public payload: AuctionDraft) {}
}

export class UpdateAuctionDraftByIdFailure implements Action {
  public readonly type = EAuctionsActions.UPDATE_AUCTION_DRAFT_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostAuction implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION;

  constructor(public payload: AuctionDraft) {}
}

export class PostAuctionSuccess implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_SUCCESS;

  constructor(public payload: AuctionDraft) {}
}

export class PostAuctionFailure implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearPostAuctionStatus implements Action {
  public readonly type = EAuctionsActions.CLEAR_POST_AUCTION_STATUS;
}

export class GetAuctionNotices implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_NOTICES;

  constructor(public payload: AuctionNotice) {}
}

export class GetAuctionNoticesSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_NOTICES_SUCCESS;

  constructor(public payload: AuctionNoticeCollection) {}
}

export class GetAuctionNoticesFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_NOTICES_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionNoticeDocumentContent implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_NOTICE_DOCUMENT_CONTENT;

  constructor(public payload: number) {}
}

export class GetAuctionNoticeDocumentContentSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_NOTICE_DOCUMENT_CONTENT_SUCCESS;

  constructor(public payload: AuctionNoticeDocumentContent) {}
}

export class GetAuctionNoticeDocumentContentFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_NOTICE_DOCUMENT_CONTENT_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearAuctionNotice implements Action {
  public readonly type = EAuctionsActions.CLEAR_AUCTION_NOTICE;
}

export class PutAuctionById implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION;

  constructor(public payload: Auction, public validateSettings?: boolean) {}
}

export class PutAuctionByIdSuccess implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_SUCCESS;

  constructor(public payload: Auction) {}
}

export class PutAuctionByIdFailure implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostAuctionNotice implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_NOTICE;

  constructor(public payload: AuctionNotice) {}
}

export class PostAuctionNoticeSuccess implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_NOTICE_SUCCESS;

  constructor(public payload: AuctionNotice) {}
}

export class PostAuctionNoticeFailure implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_NOTICE_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PutAuctionNoticeById implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_NOTICE;

  constructor(public payload: AuctionNotice, public postNotice = false) {}
}

export class PutAuctionNoticeByIdSuccess implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_NOTICE_SUCCESS;

  constructor(public payload: AuctionNotice) {}
}

export class PutAuctionNoticeByIdFailure implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_NOTICE_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class SetAuctionDetailRateSchedule implements Action {
  public readonly type = EAuctionsActions.SET_DETAIL_RATE_SCHEDULE;

  constructor(public payload: DetailRateSchedule) {}
}

export class SetAuctionMasterRateSchedule implements Action {
  public readonly type = EAuctionsActions.SET_MASTER_RATE_SCHEDULE;

  constructor(public payload: MasterRateSchedule) {}
}

export class SetAuctionTsp implements Action {
  public readonly type = EAuctionsActions.SET_AUCTION_TSP;

  constructor(public payload: ServiceProvider) {}
}

export class UpdateServiceRequestFormValidity implements Action {
  public readonly type = EAuctionsActions.UPDATE_SERVICE_REQUEST_FORM_VALIDITY;

  constructor(public payload: {}) {}
}

export class ResetServiceRequestFormValidity implements Action {
  public readonly type = EAuctionsActions.RESET_SERVICE_REQUEST_FORM_VALIDITY;
}

export class SetServiceRequestFormAsTouched implements Action {
  public readonly type = EAuctionsActions.SET_SERVICE_REQUEST_FORM_AS_TOUCHED;

  constructor(public payload: boolean) {}
}

export class UpdateClientSideAuction implements Action {
  public readonly type = EAuctionsActions.UPDATE_CLIENT_SIDE_AUCTION;

  constructor(public payload: IAuction) {}
}

export class CopyAuctionDraftById implements Action {
  public readonly type = EAuctionsActions.COPY_AUCTION_DRAFT_BY_ID;

  constructor(public payload: number) {}
}

export class CopyAuctionDraftByIdSuccess implements Action {
  public readonly type = EAuctionsActions.COPY_AUCTION_DRAFT_BY_ID_SUCCESS;

  constructor(public payload: Auction) {}
}

export class CopyAuctionDraftByIdFailure implements Action {
  public readonly type = EAuctionsActions.COPY_AUCTION_DRAFT_BY_ID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}
export class CopyAuctionById implements Action {
  public readonly type = EAuctionsActions.COPY_AUCTION_BY_ID;

  constructor(public payload: number) {}
}
export class CopyAuctionByIdSuccess implements Action {
  public readonly type = EAuctionsActions.COPY_AUCTION_BY_ID_SUCCESS;

  constructor(public payload: Auction) {}
}

export class CopyAuctionByIdFailure implements Action {
  public readonly type = EAuctionsActions.COPY_AUCTION_BY_ID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearCopyAuctionStatus implements Action {
  public readonly type = EAuctionsActions.CLEAR_COPY_AUCTION_STATUS;
}

export class SetAuctionOverviewFormLoading implements Action {
  public readonly type = EAuctionsActions.SET_AUCTION_OVERVIEW_FORM_LOADING;

  constructor(public payload: boolean) {}
}

export class PostAuctionEvent implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_EVENT;

  constructor(public payload: AuctionEvent) {}
}

export class PostAuctionEventSuccess implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_EVENT_SUCCESS;

  constructor(public payload: AuctionEvent) {}
}

export class PostAuctionEventFailure implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_EVENT_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostAuctionSegment implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_SEGMENT;

  constructor(public payload: AuctionSegment) {}
}

export class PostAuctionSegmentSuccess implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_SEGMENT_SUCCESS;

  constructor(public payload: HttpResponse<AuctionSegment>) {}
}

export class PostAuctionSegmentFailure implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_SEGMENT_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionEventPopoutPanel implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_EVENT_POPOUT_PANEL;

  constructor(
    public payload: {
      auctionId: number;
      auctionParticipantId?: number;
    }
  ) {}
}

export class GetAuctionEventPopoutPanelSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_EVENT_POPOUT_PANEL_SUCCESS;

  constructor(public payload: AuctionEventComposite) {}
}

export class GetAuctionEventPopoutPanelFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_EVENT_POPOUT_PANEL_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostAuctionBid implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_BID;

  constructor(public payload: AuctionEvent) {}
}

export class PostAuctionBidSuccess implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_BID_SUCCESS;

  constructor(public payload: AuctionEvent) {}
}

export class PostAuctionBidFailure implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_BID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class WithdrawAuctionBid implements Action {
  public readonly type = EAuctionsActions.WITHDRAW_AUCTION_BID;

  constructor(public payload: AuctionEvent) {}
}

export class WithdrawAuctionBidSuccess implements Action {
  public readonly type = EAuctionsActions.WITHDRAW_AUCTION_BID_SUCCESS;

  constructor(public payload: AuctionEvent) {}
}

export class WithdrawAuctionBidFailure implements Action {
  public readonly type = EAuctionsActions.WITHDRAW_AUCTION_BID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionBidGrid implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BID_GRID;

  constructor(public payload: IAuctionBidGridPayload) {}
}

export class GetAuctionBidGridSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BID_GRID_SUCCESS;

  constructor(public payload: AuctionEventComposite) {}
}

export class GetAuctionBidGridFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BID_GRID_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionAwards implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_AWARDS;

  constructor(public payload: IAuctionAwardsPayload) {}
}

export class GetAuctionAwardsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_AWARDS_SUCCESS;

  constructor(public payload: AuctionAwardCollection) {}
}

export class GetAuctionAwardsFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_AWARDS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class SetAuction implements Action {
  public readonly type = EAuctionsActions.SET_AUCTION;

  constructor(public payload: Auction) {}
}

export class GetAuctionBanner implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BANNER;

  constructor(
    public payload: {
      auctionId: number;
      auctionParticipantId?: number;
    }
  ) {}
}

export class GetAuctionBannerSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BANNER_SUCCESS;

  constructor(public payload: AuctionEventComposite) {}
}

export class GetAuctionBannerFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_BANNER_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class EventUpdateAuctionBanner implements Action {
  public readonly type = EAuctionsActions.EVENT_UPDATE_AUCTION_BANNER;

  constructor(public payload: AuctionBanner) {}
}

export class CreateAuctionParticipant implements Action {
  public readonly type = EAuctionsActions.CREATE_AUCTION_PARTICIPANT;

  constructor(public payload: AuctionParticipant) {}
}

export class CreateAuctionParticipantSuccess implements Action {
  public readonly type = EAuctionsActions.CREATE_AUCTION_PARTICIPANT_SUCCESS;

  constructor(public payload: AuctionParticipant) {}
}

export class CreateAuctionParticipantFailure implements Action {
  public readonly type = EAuctionsActions.CREATE_AUCTION_PARTICIPANT_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionParticipant implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_PARTICIPANT;

  constructor(public payload: IAuctionParticipantPayload) {}
}

export class GetAuctionParticipantSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_PARTICIPANT_SUCCESS;

  constructor(public payload: AuctionParticipant) {}
}

export class GetAuctionParticipantFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_PARTICIPANT_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionParticipants implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_PARTICIPANTS;

  constructor(public auctionId: number) {}
}

export class GetAuctionParticipantsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_PARTICIPANTS_SUCCESS;

  constructor(public payload: AuctionParticipantCollection) {}
}

export class GetAuctionParticipantsFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_PARTICIPANTS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionSegments implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_SEGMENTS;

  constructor(public payload: IAuctionSegmentsPayload) {}
}

export class GetAuctionSegmentsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_SEGMENTS_SUCCESS;

  constructor(public payload: AuctionSegmentCollection) {}
}

export class GetAuctionSegmentsFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_SEGMENTS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostAuctionParticipantEnter implements Action {
  public readonly type = EAuctionsActions.POST_PARTICIPANT_ENTER;

  constructor(public payload: IAuctionParticipantEventPayload) {}
}

export class PostAuctionParticipantEnterSuccess implements Action {
  public readonly type = EAuctionsActions.POST_PARTICIPANT_ENTER_SUCCESS;

  constructor(public payload: AuctionEvent) {}
}

export class PostAuctionParticipantEnterFailure implements Action {
  public readonly type = EAuctionsActions.POST_PARTICIPANT_ENTER_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetAuctionMessagePanel implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_MESSAGE_PANEL;

  constructor(public payload: AuctionEvent) {}
}

export class GetAuctionMessagePanelSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_MESSAGE_PANEL_SUCCESS;

  constructor(public payload: AuctionEventComposite) {}
}

export class GetAuctionMessagePanelFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_MESSAGE_PANEL_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ToggleAwardPeriodTimer implements Action {
  public readonly type = EAuctionsActions.TOGGLE_AWARD_PERIOD_TIMER;

  constructor(public payload: boolean) {}
}

export class EndAwardPeriod implements Action {
  public readonly type = EAuctionsActions.END_AWARD_PERIOD;
}

export class PutAuctionAwardById implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_AWARD;

  constructor(public payload: AuctionAward) {}
}

export class PutAuctionAwardSuccess implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_AWARD_SUCCESS;

  constructor(public payload: AuctionAward) {}
}

export class PutAuctionAwardFailure implements Action {
  public readonly type = EAuctionsActions.PUT_AUCTION_AWARD_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetCalculatedAwardByAuctionId implements Action {
  public readonly type = EAuctionsActions.GET_CALCULATED_AWARD;

  constructor(public payload: number) {}
}

export class GetCalculatedAwardSuccess implements Action {
  public readonly type = EAuctionsActions.GET_CALCULATED_AWARD_SUCCESS;

  constructor(public payload: AuctionAwardCollection) {}
}

export class GetCalculatedAwardFailure implements Action {
  public readonly type = EAuctionsActions.GET_CALCULATED_AWARD_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostAuctionAwardByAuctionId implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_AWARDS;

  constructor(public payload: { awardCollection: AuctionAwardCollection; auctionId: number }) {}
}

export class PostAuctionAwardByAuctionIdSuccess implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_AWARDS_SUCCESS;

  constructor(public payload: AuctionAwardCollection) {}
}

export class PostAuctionAwardByAuctionIdFailure implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_AWARDS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PostItOnlineLocations implements Action {
  public readonly type = EAuctionsActions.POST_IT_ONLINE_LOCATIONS;

  constructor(public payload: { iTOnlineLocationCollection: ITOnlineLocationCollection }) {}
}

export class PostItOnlineLocationsSuccess implements Action {
  public readonly type = EAuctionsActions.POST_IT_ONLINE_LOCATIONS_SUCCESS;
}

export class PostItOnlineLocationsFailure implements Action {
  public readonly type = EAuctionsActions.POST_IT_ONLINE_LOCATIONS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ValidateAuctionSettings implements Action {
  public readonly type = EAuctionsActions.VALIDATE_AUCTION_SETTINGS;
}

export class SetAuctionSettingsValid implements Action {
  public readonly type = EAuctionsActions.SET_AUCTION_SETTINGS_VALID;

  constructor(public payload: boolean) {}
}

export class PostAuctionNoticeTemplate implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_NOTICE_TEMPLATE;

  constructor(public payload: AuctionNoticeTemplateContentRequest) {}
}

export class PostAuctionNoticeTemplateSuccess implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_NOTICE_TEMPLATE_SUCCESS;

  constructor(public payload: WorkflowCaseCreationRequest) {}
}

export class PostAuctionNoticeTemplateFailure implements Action {
  public readonly type = EAuctionsActions.POST_AUCTION_NOTICE_TEMPLATE_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearAuctionNoticeTemplate implements Action {
  public readonly type = EAuctionsActions.CLEAR_AUCTION_NOTICE_TEMPLATE;
}
export class ExportTrades implements Action {
  public readonly type = EAuctionsActions.EXPORT_TRADES;

  constructor(public payload: { serviceTypeId: number }) {}
}

export class ExportTradesSuccess implements Action {
  public readonly type = EAuctionsActions.EXPORT_TRADES_SUCCESS;
}

export class ExportTradesFailure implements Action {
  public readonly type = EAuctionsActions.EXPORT_TRADES_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class SetDisableCloseButton implements Action {
  public readonly type = EAuctionsActions.SET_DISABLE_CLOSE_BUTTON;

  constructor(public payload: boolean) {}
}

export class SetBidAlternateRateSchedule implements Action {
  public readonly type = EAuctionsActions.SET_BID_ALTERNATE_RATE_SCHEDULE;

  constructor(public payload: DetailRateSchedule) {}
}

export class GetItOnlineReceiptLocations implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_RECEIPT_LOCATIONS;

  constructor(public payload: IITOnlineLocationsPayload) {}
}

export class GetItOnlineReceiptLocationsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_RECEIPT_LOCATIONS_SUCCESS;

  constructor(public payload: ITOnlineReceiptLocationCollection) {}
}

export class GetItOnlineReceiptLocationsFailure implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_RECEIPT_LOCATIONS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetItOnlineDeliveryLocations implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_DELIVERY_LOCATIONS;

  constructor(public payload: IITOnlineLocationsPayload) {}
}

export class GetItOnlineDeliveryLocationsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_DELIVERY_LOCATIONS_SUCCESS;

  constructor(public payload: ITOnlineDeliveryLocationCollection) {}
}

export class GetItOnlineDeliveryLocationsFailure implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_DELIVERY_LOCATIONS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetItOnlineIceLocations implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_ICE_LOCATIONS;
}

export class GetItOnlineIceLocationsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_ICE_LOCATIONS_SUCCESS;

  constructor(public payload: ITOnlineICELocationCollection) {}
}

export class GetItOnlineIceLocationsFailure implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_ICE_LOCATIONS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetItOnlineAdminCells implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_ADMIN_CELLS;

  constructor(public payload: IITOnlineCellsPayload) {}
}

export class GetItOnlineAdminCellsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_ADMIN_CELLS_SUCCESS;

  constructor(public payload: ITOnlineAdminCellsCollection) {}
}

export class GetItOnlineAdminCellsFailure implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_ADMIN_CELLS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetItOnlineCells implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_CELLS;

  constructor(public payload: IITOnlineCellsPayload) {}
}

export class GetItOnlineCellsSuccess implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_CELLS_SUCCESS;

  constructor(public payload: ITOnlineCellsCollection) {}
}

export class GetItOnlineCellsFailure implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_CELLS_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class GetItOnlineParameterSet implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_PARAMETER_SET;
}

export class GetItOnlineParameterSetSuccess implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_PARAMETER_SET_SUCCESS;

  constructor(public payload: ITOnlineParameterSetCollection) {}
}

export class GetItOnlineParameterSetFailure implements Action {
  public readonly type = EAuctionsActions.GET_IT_ONLINE_PARAMETER_SET_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class PutItOnlineParameterSet implements Action {
  public readonly type = EAuctionsActions.PUT_IT_ONLINE_PARAMETER_SET;

  constructor(public payload: ITOnlineParameterSet) {}
}

export class PutItOnlineParameterSetSuccess implements Action {
  public readonly type = EAuctionsActions.PUT_IT_ONLINE_PARAMETER_SET_SUCCESS;

  constructor(public payload: ITOnlineParameterSet) {}
}

export class PutItOnlineParameterSetFailure implements Action {
  public readonly type = EAuctionsActions.PUT_IT_ONLINE_PARAMETER_SET_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class SetAuctionParticipants implements Action {
  public readonly type = EAuctionsActions.SET_AUCTION_PARTICIPANTS;

  constructor(public payload: AuctionParticipant[]) {}
}

export class DeleteITOnlineLocation implements Action {
  public readonly type = EAuctionsActions.DELETE_IT_ONLINE_LOCATION;

  constructor(public payload: { location: ITOnlineLocation; gridType: ELocationsGridTypes }) {}
}

export class DeleteITOnlineLocationSuccess implements Action {
  public readonly type = EAuctionsActions.DELETE_IT_ONLINE_LOCATION_SUCCESS;
}

export class DeleteITOnlineLocationFailure implements Action {
  public readonly type = EAuctionsActions.DELETE_IT_ONLINE_LOCATION_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ClearSubmittedITOnlineTrade implements Action {
  public readonly type = EAuctionsActions.CLEAR_SUBMITTED_IT_ONLINE_TRADE;
}

export class GetAuctionUsers implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_USERS;

  constructor(
    public payload: IGetAuctionUsersPayload
  ) {}
}

export class GetAuctionUsersSuccess implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_USERS_SUCCESS;

  constructor(public response: AuctionUserCollection) {}
}

export class GetAuctionUsersFailure implements Action {
  public readonly type = EAuctionsActions.GET_AUCTION_USERS_FAILURE;

  constructor(public error: HttpErrorResponse) {}
}

export class SearchAuctionUsers implements Action {
  public readonly type = EAuctionsActions.SEARCH_AUCTION_USERS;

  constructor(
    public payload: IGetAuctionUsersPayload
  ) {}
}

export class SearchAuctionUsersSuccess implements Action {
  public readonly type = EAuctionsActions.SEARCH_AUCTION_USERS_SUCCESS;

  constructor(public response: AuctionUserCollection) {}
}

export class SearchAuctionUsersFailure implements Action {
  public readonly type = EAuctionsActions.SEARCH_AUCTION_USERS_FAILURE;

  constructor(public error: HttpErrorResponse) {}
}

export class GenerateAuctionNotices implements Action {
  public readonly type = EAuctionsActions.GENERATE_AUCTION_NOTICES;

  constructor(public payload: AuctionNoticeGeneration) {}
}

export class GenerateAuctionNoticesSuccess implements Action {
  public readonly type = EAuctionsActions.GENERATE_AUCTION_NOTICES_SUCCESS;

  constructor(public response: AuctionNoticeCollection) {}
}

export class GenerateAuctionNoticesFailure implements Action {
  public readonly type = EAuctionsActions.GENERATE_AUCTION_NOTICES_FAILURE;

  constructor(public error: HttpErrorResponse) {}
}

export class ClearAuctionNoticeState implements Action {
  public readonly type = EAuctionsActions.CLEAR_AUCTION_NOTICE_STATE;
}

export class StartAuction implements Action {
  public readonly type = EAuctionsActions.START_AUCTION;

  constructor(public payload: Auction) {}
}

export class StartAuctionSuccess implements Action {
  public readonly type = EAuctionsActions.START_AUCTION_SUCCESS;

  constructor(public response: AuctionSegment) {}
}

export class StartAuctionFailure implements Action {
  public readonly type = EAuctionsActions.START_AUCTION_FAILURE;

  constructor(public error: HttpErrorResponse) {}
}

export class UpdateNoticeDocumentContentMap implements Action {
  public readonly type = EAuctionsActions.UPDATE_AUCTION_NOTICE_DOCUMENT_CONTENT_MAP;

  constructor(public documentContents: IAuctionNoticeDocumentContentsMap) {}
}

export class ClearNoticeDocumentContentMap implements Action {
  public readonly type = EAuctionsActions.CLEAR_AUCTION_NOTICE_DOCUMENT_CONTENT_MAP;
}

export type AuctionsActions =
  | GetAuctionCollection
  | GetAuctionCollectionSuccess
  | GetAuctionCollectionFailure
  | GetTradeCollection
  | GetTradeCollectionSuccess
  | GetTradeCollectionFailure
  | UpdateTradeById
  | UpdateTradeByIdSuccess
  | UpdateTradeByIdFailure
  | SubmitTrades
  | SubmitTradesSuccess
  | SubmitTradesFailure
  | PostTrade
  | PostTradeSuccess
  | PostTradeFailure
  | GetBiddingPeriodMappings
  | GetBiddingPeriodMappingsSuccess
  | GetBiddingPeriodMappingsFailure
  | GetAuctionSeriesCollection
  | GetAuctionSeriesCollectionSuccess
  | GetAuctionSeriesCollectionFailure
  | SaveAuctionDraft
  | SaveAuctionDraftSuccess
  | SaveAuctionDraftFailure
  | GetAuctionReferenceTypes
  | GetAuctionReferenceTypesSuccess
  | GetAuctionReferenceTypesFailure
  | GetAuctionRateSchedules
  | GetAuctionRateSchedulesSuccess
  | GetAuctionRateSchedulesFailure
  | GetAuctionRateScheduleDetails
  | GetAuctionRateScheduleDetailsSuccess
  | GetAuctionRateScheduleDetailsFailure
  | DeleteAuctionDraft
  | DeleteAuctionDraftSuccess
  | DeleteAuctionDraftFailure
  | GetAuctionById
  | GetAuctionByIdSuccess
  | GetAuctionByIdFailure
  | SetAuctionDetailRateSchedule
  | SetAuctionMasterRateSchedule
  | SetAuctionTsp
  | UpdateServiceRequestFormValidity
  | ResetServiceRequestFormValidity
  | SetServiceRequestFormAsTouched
  | GetAuctionDraftById
  | GetAuctionDraftByIdSuccess
  | GetAuctionDraftByIdFailure
  | ClearAuction
  | ClearAuctionDraft
  | UpdateAuctionDraftById
  | UpdateAuctionDraftByIdFailure
  | UpdateAuctionDraftByIdSuccess
  | PostAuction
  | PostAuctionSuccess
  | PostAuctionFailure
  | ClearPostAuctionStatus
  | PutAuctionById
  | PutAuctionByIdSuccess
  | PutAuctionByIdFailure
  | PostAuctionNotice
  | PostAuctionNoticeSuccess
  | PostAuctionNoticeFailure
  | PutAuctionNoticeById
  | PutAuctionNoticeByIdSuccess
  | PutAuctionNoticeByIdFailure
  | CancelAuctionById
  | CancelAuctionByIdSuccess
  | CancelAuctionByIdFailure
  | UpdateClientSideAuction
  | PutAuctionByIdFailure
  | CopyAuctionDraftById
  | CopyAuctionDraftByIdSuccess
  | CopyAuctionDraftByIdFailure
  | CopyAuctionById
  | CopyAuctionByIdSuccess
  | CopyAuctionByIdFailure
  | ClearCopyAuctionStatus
  | ClearCancelAuctionStatus
  | SetAuctionOverviewFormLoading
  | PostAuctionEvent
  | PostAuctionEventSuccess
  | PostAuctionEventFailure
  | GetAuctionEventPopoutPanel
  | GetAuctionEventPopoutPanelSuccess
  | GetAuctionEventPopoutPanelFailure
  | PostAuctionBid
  | PostAuctionBidSuccess
  | PostAuctionBidFailure
  | GetAuctionNotices
  | GetAuctionNoticesSuccess
  | GetAuctionNoticesFailure
  | ClearAuctionNotice
  | WithdrawAuctionBid
  | WithdrawAuctionBidSuccess
  | WithdrawAuctionBidFailure
  | GetAuctionBidGrid
  | GetAuctionBidGridSuccess
  | GetAuctionBidGridFailure
  | PostAuctionSegment
  | PostAuctionSegmentSuccess
  | PostAuctionSegmentFailure
  | CreateAuctionParticipant
  | CreateAuctionParticipantSuccess
  | CreateAuctionParticipantFailure
  | GetAuctionParticipant
  | GetAuctionParticipantSuccess
  | GetAuctionParticipantFailure
  | GetAuctionParticipants
  | GetAuctionParticipantsSuccess
  | GetAuctionParticipantsFailure
  | GetAuctionSegments
  | GetAuctionSegmentsSuccess
  | GetAuctionSegmentsFailure
  | GetAuctionMessagePanel
  | GetAuctionMessagePanelSuccess
  | GetAuctionMessagePanelFailure
  | PostAuctionParticipantEnter
  | PostAuctionParticipantEnterFailure
  | PostAuctionParticipantEnterSuccess
  | GetAuctionAwards
  | GetAuctionAwardsSuccess
  | GetAuctionAwardsFailure
  | SetAuction
  | GetAuctionBanner
  | GetAuctionBannerSuccess
  | GetAuctionBannerFailure
  | EventUpdateAuctionBanner
  | ToggleAwardPeriodTimer
  | EndAwardPeriod
  | PutAuctionAwardById
  | PutAuctionAwardFailure
  | PutAuctionAwardSuccess
  | GetCalculatedAwardByAuctionId
  | GetCalculatedAwardSuccess
  | GetCalculatedAwardFailure
  | PostAuctionAwardByAuctionId
  | PostAuctionAwardByAuctionIdSuccess
  | PostAuctionAwardByAuctionIdFailure
  | ValidateAuctionSettings
  | SetAuctionSettingsValid
  | PostAuctionNoticeTemplate
  | PostAuctionNoticeTemplateSuccess
  | PostAuctionNoticeTemplateFailure
  | GetAuctionNoticeDocumentContent
  | GetAuctionNoticeDocumentContentSuccess
  | GetAuctionNoticeDocumentContentFailure
  | PostAuctionNoticeSuccess
  | ClearAuctionNoticeTemplate
  | ExportTrades
  | ExportTradesSuccess
  | ExportTradesFailure
  | SetDisableCloseButton
  | SetBidAlternateRateSchedule
  | GetItOnlineReceiptLocations
  | GetItOnlineReceiptLocationsSuccess
  | GetItOnlineReceiptLocationsFailure
  | GetItOnlineDeliveryLocations
  | GetItOnlineDeliveryLocationsSuccess
  | GetItOnlineDeliveryLocationsFailure
  | GetItOnlineIceLocations
  | GetItOnlineIceLocationsSuccess
  | GetItOnlineIceLocationsFailure
  | GetItOnlineAdminCells
  | GetItOnlineAdminCellsSuccess
  | GetItOnlineAdminCellsFailure
  | GetItOnlineCells
  | GetItOnlineCellsSuccess
  | GetItOnlineCellsFailure
  | GetItOnlineParameterSet
  | GetItOnlineParameterSetSuccess
  | GetItOnlineParameterSetFailure
  | PutItOnlineParameterSet
  | PutItOnlineParameterSetSuccess
  | PutItOnlineParameterSetFailure
  | SetAuctionParticipants
  | DeleteITOnlineLocation
  | DeleteITOnlineLocationSuccess
  | DeleteITOnlineLocationFailure
  | PostItOnlineLocations
  | PostItOnlineLocationsSuccess
  | PostItOnlineLocationsFailure
  | ClearSubmittedITOnlineTrade
  | GetAuctionUsers
  | GetAuctionUsersFailure
  | GetAuctionUsersSuccess
  | SearchAuctionUsers
  | SearchAuctionUsersFailure
  | SearchAuctionUsersSuccess
  | GenerateAuctionNotices
  | GenerateAuctionNoticesSuccess
  | GenerateAuctionNoticesFailure
  | ClearAuctionNoticeState
  | StartAuction
  | StartAuctionSuccess
  | StartAuctionFailure
  | UpdateNoticeDocumentContentMap
  | ClearNoticeDocumentContentMap;
