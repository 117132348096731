import { ClosingGroupCollection } from '@gms/closing-api';
import { IConsumeClosingGroupProcessCollection } from 'app/store/closing-dashboard/closing-dashboard.utils';
import { emptyRequestStatus, IDataState, IRequestStatus } from '../app/app.models';

export interface IClosingDashboardState {
  closingDashboardData: IDataState<ClosingGroupCollection>;
  closingDashboardProcesses: IConsumeClosingGroupProcessCollection;
  updateGroupTask: IRequestStatus;
  closeClosingGroup: IRequestStatus;
  runTieOutQuery: IRequestStatus;
}

export const initialClosingDashboardState: IClosingDashboardState = {
  closingDashboardData: {
    data: {
      closingGroups: null,
    },
    requestStatus: emptyRequestStatus,
  },
  closingDashboardProcesses: null,
  updateGroupTask: emptyRequestStatus,
  closeClosingGroup: emptyRequestStatus,
  runTieOutQuery: emptyRequestStatus,
};
