import {
  EPredefinedFootnotesActions,
  PredefinedFootnotesActions,
} from './predefined-footnotes.actions';
import {
  initialPredefinedFootnotesState,
  IPredefinedFootnotesState,
} from './predefined-footnotes.state';

export function predefinedFootnotesReducers(
  state = initialPredefinedFootnotesState,
  action: PredefinedFootnotesActions
): IPredefinedFootnotesState {
  switch (action.type) {
    case EPredefinedFootnotesActions.FETCH_PREDEFINED_FOOTNOTES:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case EPredefinedFootnotesActions.FETCH_PREDEFINED_FOOTNOTES_SUCCESS:
      return {
        ...state,
        predefinedFootnotes: action.predefinedFootnotes,
        loading: false,
        error: false,
      };
    case EPredefinedFootnotesActions.FETCH_PREDEFINED_FOOTNOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
