import { SortDescriptor } from '@progress/kendo-data-query';

import {
  Amendment,
  Contract,
  ContractDocument,
  ContractDocumentCollection,
  ContractEdiTransactionSetCollection,
  ContractGeneralCollection,
  ContractHeader,
  ContractHeaderCollection,
  ContractLocationHeaders,
  ContractMergerAssignmentCollection,
  ContractQuantityCollection,
  Entity,
  LookupCollections,
  QuantityChangesCollection,
  QuantityLocationChangesCollection,
  QuantityLocationChangesPointPairCollection,
  QuantityLocationCollection,
  QuantityLocationPointPairCollection,
  QuantityPathCollection,
  RateSchedule,
  SupplementalRestrictionCollection,
} from '@gms/contract-api';
import { ContractEntityCollection } from '@gms/contract-api/model/contractEntityCollection';
import { MergerAssignment } from '@gms/entity-api';
import { AmendmentDropdownOption } from 'app/modules/contracts/enums';
import {
  createSuccessState,
  emptyRequestStatus,
  IDataState,
  IRequestStatus,
} from 'app/store/app/app.models';
import { DetailRateScheduleContract } from 'app/store/contracts/contracts.model';
import { getPagination, IPagination } from 'shared/models/pagination.model';

export interface IContractState {
  amendments: Amendment[];
  contracts: Contract[];
  contractLocationHeadersList: ContractLocationHeaders[];
  contractsLoading: boolean;
  contractHeader: IDataState<ContractHeader>;
  contractHeaders: IDataState<ContractHeaderCollection>;
  contract: DetailRateScheduleContract;
  sortDescriptors: SortDescriptor[];
  loading: boolean;
  error: Error | null;
  pagination: IPagination;
  totalContractCount: number;
  contractsSearch: Contract[];
  contractsSearchLoading: boolean;
  contractsSearchError: Error | null;
  contractsList: Array<Contract>;
  contractGeneralCollection: ContractGeneralCollection;
  lookupCollections: LookupCollections;
  contractRateSchedules: Array<RateSchedule>;
  contractQuantityCollection: ContractQuantityCollection;
  quantityChangesCollection: IDataState<QuantityChangesCollection>;
  contractQuantityChangesUpdated: IDataState<boolean>;
  quantityLocationCollection: QuantityLocationCollection;
  isContractQuantitiesReset: boolean;
  quantityLocationChangesCollection: IDataState<QuantityLocationChangesCollection>;
  contractLocationQuantityChangesUpdated: IDataState<boolean>;
  quantityLocationPointPairCollection: QuantityLocationPointPairCollection;
  quantityLocationChangesPointPairCollection: IDataState<
    QuantityLocationChangesPointPairCollection
  >;
  quantityPathCollection: QuantityPathCollection;
  contractLockStatusUpdated: boolean;
  contractEdiTransactionSetCollection: ContractEdiTransactionSetCollection;
  supplementalRestrictionCollection: SupplementalRestrictionCollection;
  contractsByMergerAssignment: ContractMergerAssignmentCollection;
  mergerAssignmentHistory: MergerAssignment[];
  contractDocuments: IDataState<ContractDocumentCollection>;
  contractDocument: IDataState<ContractDocument>;
  contractRateScheduleEntities: {
    entities: Entity[];
    loading: boolean;
    error: Error;
  };
  contractLocationQuantitiesUpdated: boolean;
  contractPathQuantitiesUpdated: boolean;
  contractEdiUpdated: IDataState<boolean>;
  contractLocationQuantityPointPairChangesUpdated: IDataState<boolean>;
  exportGrandfatheredSupplyLateral: IDataState<boolean>;
  amendmentCreated: IDataState<boolean>;
  workflowInitiateKgen: IDataState<boolean>;
  contractOverviewUpdated: IDataState<boolean>;
  selectedAmendment: AmendmentDropdownOption;
  contractEntities: IDataState<ContractEntityCollection>;
  status: {
    addContractOverview: IRequestStatus;
    amendments: IRequestStatus;
    contractsList: IRequestStatus;
    contractLockStatusUpdated: IRequestStatus;
    lookupCollections: IRequestStatus;
    contractEntityHistory: IRequestStatus;
    contractRateSchedules: IRequestStatus;
    contractLocationHeaders: IRequestStatus;
    contractQuantities: IRequestStatus;
    contractGeneral: IRequestStatus;
    locationQuantities: IRequestStatus;
    locationPointPairQuantities: IRequestStatus;
    pathQuantities: IRequestStatus;
    updateContractQuantities: IRequestStatus;
    updateContractPathQuantities: IRequestStatus;
    updateContractEdiTransactionSet: IRequestStatus;
    updateContractLocationQuantities: IRequestStatus;
    updateContractLocationPointPairQuantities: IRequestStatus;
    contractEdiTransactionSet: IRequestStatus;
    supplementalRestrictions: IRequestStatus;
    contractsByMergerAssignment: IRequestStatus;
    exportContractsList: IRequestStatus;
    contractEntities: IRequestStatus;
  };
}

export const initialContractsState: IContractState = {
  amendments: [],
  contracts: [],
  contractsLoading: false,
  contractLocationHeadersList: [],
  contractHeader: createSuccessState({}),
  contractHeaders: createSuccessState({ contractHeaders: [] }),
  contract: null,
  sortDescriptors: [
    {
      field: 'status',
      dir: 'asc',
    },
  ],
  loading: false,
  error: null,
  pagination: getPagination(),
  totalContractCount: 0,
  contractsSearch: [],
  contractsSearchLoading: false,
  contractsSearchError: null,
  contractsList: [],
  lookupCollections: null,
  contractRateSchedules: [],
  contractGeneralCollection: null,
  contractQuantityCollection: null,
  isContractQuantitiesReset: null,
  quantityChangesCollection: {
    data: null,
    requestStatus: { loading: false, httpError: null },
  },
  quantityLocationCollection: null,
  quantityLocationChangesCollection: {
    data: null,
    requestStatus: { loading: false, httpError: null },
  },
  quantityLocationPointPairCollection: null,
  quantityLocationChangesPointPairCollection: {
    data: null,
    requestStatus: { loading: false, httpError: null },
  },
  quantityPathCollection: null,
  contractLockStatusUpdated: false,
  contractEdiTransactionSetCollection: null,
  supplementalRestrictionCollection: null,
  contractsByMergerAssignment: null,
  mergerAssignmentHistory: [],
  contractDocuments: {
    data: null,
    requestStatus: { loading: false, httpError: null },
  },
  contractDocument: {
    data: null,
    requestStatus: { loading: false, httpError: null },
  },
  contractRateScheduleEntities: {
    loading: false,
    entities: [],
    error: null,
  },
  contractLocationQuantitiesUpdated: false,
  contractPathQuantitiesUpdated: false,
  contractQuantityChangesUpdated: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  contractEdiUpdated: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  contractLocationQuantityChangesUpdated: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  contractLocationQuantityPointPairChangesUpdated: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  exportGrandfatheredSupplyLateral: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  amendmentCreated: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  workflowInitiateKgen: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  contractOverviewUpdated: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  selectedAmendment: null,
  contractEntities: {
    data: null,
    requestStatus: emptyRequestStatus,
  },
  status: {
    addContractOverview: {
      httpError: null,
      loading: false,
    },
    amendments: {
      httpError: null,
      loading: false,
    },
    contractsList: {
      httpError: null,
      loading: false,
    },
    contractLockStatusUpdated: {
      httpError: null,
      loading: false,
    },
    lookupCollections: {
      httpError: null,
      loading: false,
    },
    contractEntityHistory: {
      httpError: null,
      loading: false,
    },
    contractRateSchedules: {
      httpError: null,
      loading: false,
    },
    contractQuantities: {
      httpError: null,
      loading: false,
    },
    contractGeneral: {
      httpError: null,
      loading: false,
    },
    locationQuantities: {
      httpError: null,
      loading: false,
    },
    contractLocationHeaders: {
      httpError: null,
      loading: false,
    },
    locationPointPairQuantities: {
      httpError: null,
      loading: false,
    },
    pathQuantities: {
      httpError: null,
      loading: false,
    },
    contractEdiTransactionSet: {
      httpError: null,
      loading: false,
    },
    updateContractQuantities: {
      httpError: null,
      loading: false,
    },
    updateContractPathQuantities: {
      httpError: null,
      loading: false,
    },
    updateContractEdiTransactionSet: {
      httpError: null,
      loading: false,
    },
    updateContractLocationQuantities: {
      httpError: null,
      loading: false,
    },
    updateContractLocationPointPairQuantities: {
      httpError: null,
      loading: false,
    },
    supplementalRestrictions: {
      httpError: null,
      loading: false,
    },
    contractsByMergerAssignment: {
      httpError: null,
      loading: false,
    },
    exportContractsList: {
      httpError: null,
      loading: false,
    },
    contractEntities: {
      httpError: null,
      loading: false,
    },
  },
};
