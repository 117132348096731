export enum ERateScheduleCodes {
  FT = 'FT',
  STF = 'STF',
  FTS_P = 'FTS-P',
  FTS_A = 'FTS-A',
  FTS = 'FTS',
  EFT = 'EFT',
  PAL = 'PAL',
  IWS = 'IWS',
  IT = 'IT',
  HOT = 'HOT',
  TAPS = 'TAPS',
  PXS = 'PXS',
  AVS = 'AVS',
  PKS = 'PKS',
  PS = 'PS',
  ITS = 'ITS',
  ITS_P = 'ITS-P',
  IPS = 'IPS',
  ILS = 'ILS',
  FSS_M = 'FSS-M',
  FSS = 'FSS',
  FSS_P = 'FSS-P',
  FSS_B = 'FSS-B',
  ISS_M = 'ISS-M',
  ISS_P = 'ISS-P',
  ISS = 'ISS',
  NNS = 'NNS',
  NNL = 'NNL',
  WNS = 'WNS',
  SGL = 'SGL',
  SNS = 'SNS',
  ATS = 'ATS',
  EPS = 'EPS',
  IBS = 'IBS',
  IBTS = 'IBTS',
  NNS_A = 'NNS-A',
  SGT = 'SGT',
  TPA = 'TPA',
  UMB = 'UMB',
  OBA = 'OBA',
  ADM = 'ADM',
  EX = 'EX',
  FSS_PAD = 'FSS-PAD',
  LEASE = 'LEASE',
  OWNER = 'OWNER',
  PAL_BO = 'PAL-BO',
  PAS = 'PAS',
  PLANT = 'PLANT',
  SBO = 'SBO',
  TBO = 'TBO',
  TBO_FT = 'TBO-FT',
  TBO_IT = 'TBO-IT',
  GP = 'GP',
  GS = 'GS',
  ECI_IWA = 'ECI / IWA',
}

export enum EMasterRateScheduleIds {
  AVS = 22,
  PKS = 23,
  FSS_P = 36,
  FSS = 37,
  ISS_P = 41,
  OBA = 58,
  GP = 69,
  GS = 70,
  ISS = 73,
}
