import { ServiceProvider } from '@gms/tsp-api';

export interface IHeaderState {
  tsp: ServiceProvider;
  serviceRequester: ServiceRequester;
  serviceRequesters: Array<ServiceRequester>;
}

export interface ServiceRequester {
  entityId: number;
  shortName: string;
  legalName: string;
  dunsNumber?: string;
}

export const initialHeaderState: IHeaderState = {
  tsp: null,
  serviceRequester: null,
  serviceRequesters: [
    {
      entityId: 10000,
      shortName: 'Centerpoint Energy',
      legalName: 'Centerpoint Energy',
    },
    {
      entityId: 100001,
      shortName: 'Alcan Aluminum',
      legalName: 'Alcan Aluminum',
    },
    {
      entityId: null,
      shortName: 'View All Service Requesters',
      legalName: 'View All Service Requesters',
    },
  ],
};
