import { Routes } from '@angular/router';

export const AccountLocked_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/account-locked/pages/account-locked/account-locked.module').then(
        m => m.AccountLockedModule
      ),
  },
];
