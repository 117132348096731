import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import get from 'lodash/get';
import { BehaviorSubject } from 'rxjs';
import { EFormInputUnits } from 'shared/components/form/form-input/form-input.utils';
import { getControlName } from 'shared/components/table/dynamic-cell-components/dynamic-cell.interface';
import { DynamicInputComponent } from 'shared/components/table/dynamic-cell-components/input-cell/input-cell.component';
import { Alignment } from '../../table.interfaces';

export class DynamicInputCell {
  dynamicComponentType: typeof DynamicInputComponent;
  data: InputOptions;
}

export interface InputFormattingOptions {
  min?: number;
  max?: number;
  numberFormat?: string | NumberFormatOptions | null | undefined;
  decimals?: number;
  placeholder?: string;
}

export interface InputOptions {
  id?: string;
  control: UntypedFormControl | AbstractControl;
  maxLength?: number;

  // only use the following if you want to use a Kendo Masked Textbox
  // NOTE MASKED TEXT BOXES ONLY WORK WITH STRINGS.
  inputRegex?: RegExp;
  mask?: string;
  prompt?: string;
  rules?: { [key: string]: RegExp };

  autoCorrect?: boolean;

  min?: number;
  max?: number;
  alignment?: Alignment;
  includeLiterals?: boolean;
  tableOverflow?: boolean;

  // Numeric format, for min and max add validators.
  numberFormat?: string | NumberFormatOptions | null | undefined;
  decimals?: number;
  placeholder?: string;

  inputBlurred?: Function;
  inputFocused?: Function;
  inputChanges?: Function;

  dynamicFormattingOptions?: BehaviorSubject<InputFormattingOptions>;
  updateOnModelChange?: boolean;

  units?: EFormInputUnits;

  resizable?: string;
}

export const createInputCell = (inputOptions: InputOptions): DynamicInputCell => {
  // const controlName = getControlName(inputOptions.control as FormControl);

  const prependId =
    get(inputOptions, 'id') || getControlName(inputOptions.control as UntypedFormControl);
  const id = prependId + '_Input';

  return {
    dynamicComponentType: DynamicInputComponent,
    data: {
      ...inputOptions,
      control: inputOptions.control as UntypedFormControl,
      id: id,
    },
  };
};
