import { RatchetLevelCollection, TariffRatchetCollection } from '@gms/rateschedulev2-api';
import { RatchetLevelTemplateCollection } from '@gms/servicerequest-api';
import { Action } from '@ngrx/store';

export enum ERatchetLevelsActions {
  FETCH_RATCHET_LEVELS = '[RATCHET_LEVELS] fetch ratchet_levels',
  FETCH_RATCHET_LEVELS_SUCCESS = '[RATCHET_LEVELS] fetch ratchet_levels success',
  FETCH_RATCHET_LEVELS_ERROR = '[RATCHET_LEVELS] fetch ratchet_levels error',

  FETCH_RATCHET_LEVEL_COLLECTION = '[RATCHET_LEVELS] fetch ratchet_level_collection',
  FETCH_RATCHET_LEVEL_COLLECTION_SUCCESS = '[RATCHET_LEVELS] fetch ratchet_level_collection success',
  FETCH_RATCHET_LEVEL_COLLECTION_ERROR = '[RATCHET_LEVELS] fetch ratchet_level_collection error',

  FETCH_TARIFF_RATCHET_COLLECTION = '[RATCHET_LEVELS] fetch tariff_ratchet collection',
  FETCH_TARIFF_RATCHET_COLLECTION_SUCCESS = '[RATCHET_LEVELS] fetch tariff_ratchet collection success',
  FETCH_TARIFF_RATCHET_COLLECTION_ERROR = '[RATCHET_LEVELS] fetch tariff_ratchet collection error',
}

export class FetchRatchetLevels implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_RATCHET_LEVELS;

  constructor() {}
}

export class FetchRatchetLevelsSuccess implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_RATCHET_LEVELS_SUCCESS;

  constructor(public ratchetLevels: RatchetLevelTemplateCollection) {}
}

export class FetchRatchetLevelsError implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_RATCHET_LEVELS_ERROR;

  constructor(public payload: RatchetLevelTemplateCollection) {}
}

export class FetchRatchetLevelCollection implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_RATCHET_LEVEL_COLLECTION;

  constructor(public payload: { detailRateScheduleId: number }) {}
}

export class FetchRatchetLevelCollectionSuccess implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_RATCHET_LEVEL_COLLECTION_SUCCESS;

  constructor(public ratchetLevelCollection: RatchetLevelCollection) {}
}

export class FetchRatchetLevelCollectionError implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_RATCHET_LEVEL_COLLECTION_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchTariffRatchetCollection implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_TARIFF_RATCHET_COLLECTION;

  constructor(public payload: { detailRateScheduleId: number }) {}
}

export class FetchTariffRatchetCollectionSuccess implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_TARIFF_RATCHET_COLLECTION_SUCCESS;

  constructor(public tariffRatchetCollection: TariffRatchetCollection) {}
}

export class FetchTariffRatchetCollectionError implements Action {
  public readonly type = ERatchetLevelsActions.FETCH_TARIFF_RATCHET_COLLECTION_ERROR;

  constructor(public payload: { error: Error }) {}
}

export type RatchetLevelsActions =
  | FetchRatchetLevels
  | FetchRatchetLevelsSuccess
  | FetchRatchetLevelsError
  | FetchRatchetLevelCollection
  | FetchRatchetLevelCollectionSuccess
  | FetchRatchetLevelCollectionError
  | FetchTariffRatchetCollection
  | FetchTariffRatchetCollectionSuccess
  | FetchTariffRatchetCollectionError;
