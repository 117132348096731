import {
  DetailRateSchedule,
  MasterRateSchedule,
  RateScheduleQuantityType,
  TspSeasonCollection,
  UiConfigItem,
} from '@gms/rateschedulev2-api';
import { IRequestStatus } from 'app/store/app/app.models';

/*
 * The redux pattern in this file does not match the agreed upon standards -> https://boardwalkpp.atlassian.net/wiki/spaces/GMS/pages/766967893/Redux+Standards
 */
export const initialRateSchedulesState: IRateScheduleState = {
  status: {
    detailRateScheduleUiConfiguration: {
      loading: false,
      httpError: null,
    },
    masterRateSchedules: {
      loading: false,
      httpError: null,
    },
    detailRateSchedules: {
      loading: false,
      httpError: null,
    },
    allDetailRateSchedules: {
      loading: false,
      httpError: null,
    },
    detailRateSchedule: {
      loading: false,
      httpError: null,
    },
    naesbLocationIndicators: {
      loading: false,
      httpError: null,
    },
    tspSeasons: {
      loading: false,
      httpError: null,
    },
  },
  masterRateSchedules: [],
  detailRateSchedules: [],
  allDetailRateSchedules: [],
  uiConfiguration: [],
  detailRateSchedule: null,
  naesbLocationIndicators: {},
  quantityTypeState: {
    data: null,
    requestStatus: {
      loading: false,
      httpError: null,
    },
  },
  tspSeasons: null,
  allowSameDay: false,
};

export interface IRateScheduleState {
  status: {
    detailRateScheduleUiConfiguration: IRequestStatus;
    detailRateSchedules: IRequestStatus;
    allDetailRateSchedules: IRequestStatus;
    masterRateSchedules: IRequestStatus;
    detailRateSchedule: IRequestStatus;
    naesbLocationIndicators: IRequestStatus;
    tspSeasons: IRequestStatus;
  };
  masterRateSchedules: Array<MasterRateSchedule>;
  detailRateSchedules: Array<DetailRateSchedule>;
  allDetailRateSchedules: Array<DetailRateSchedule>;
  uiConfiguration: Array<IDetailRateScheduleUiConfiguration>;
  detailRateSchedule: DetailRateSchedule;
  naesbLocationIndicators;
  quantityTypeState: {
    data: RateScheduleQuantityType;
    requestStatus: IRequestStatus;
  };
  tspSeasons: TspSeasonCollection;
  allowSameDay: Boolean;
}

export interface IDetailRateScheduleUiConfiguration {
  detailRateScheduleId: number;
  configuration: Array<UiConfigItem>;
}
