import { Injectable } from '@angular/core';
import { LookupData, PipelineSystem, Tsp } from '@gms/pipeline-api';
import { ServiceProvider } from '@gms/tsp-api';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/app/app.state';
import { FetchLookupData } from 'app/store/pipeline-models/pipeline-models.actions';
import { IPipelineModelsState } from 'app/store/pipeline-models/pipeline-models.state';
import { combineLatest, concat, Observable } from 'rxjs';
import { filter, first, map, skip, take, tap } from 'rxjs/operators';

interface IPipelineSystem extends PipelineSystem {
  tspId: number;
}

@Injectable()
export class PipelineSystemService {
  constructor(private _store: Store<IAppState>) {}

  private loadIfRequired(): Observable<any> {
    return this._store.pipe(select(state => state.pipelineModelsState)).pipe(
      map(state => state),
      take(1),
      tap((data: IPipelineModelsState) => {
        if (!data.lookupData && !data.status.lookupData.loading) {
          this._store.dispatch(new FetchLookupData());
        }
      })
    );
  }

  private hasData(): Observable<LookupData> {
    return this._store.pipe(select(state => state.pipelineModelsState)).pipe(
      first(data => data != null && data.lookupData != null),
      map(data => data.lookupData)
    );
  }

  private loadPipelineSystemMap(pipelineSystemMap: Map<number, IPipelineSystem>, tsp: Tsp) {
    tsp.pipelineSystems.forEach(pipeline =>
      pipelineSystemMap.set(pipeline.pipelineSystemId, ({
        tspId: tsp.tspId,
        ...pipeline,
      } as any) as IPipelineSystem)
    );
  }

  /**
   * Return all Pipelinesystem in Map
   */
  get pipelineSystemMap$(): Observable<Map<number, IPipelineSystem>> {
    return concat(this.loadIfRequired(), this.hasData()).pipe(
      skip(1),
      map(lookupData => {
        const pipelineSystemMap = new Map<number, IPipelineSystem>();
        lookupData.tsps.forEach((tsp: Tsp) => this.loadPipelineSystemMap(pipelineSystemMap, tsp));
        return pipelineSystemMap;
      })
    );
  }

  /**
   * return Tsp grouped pipelineSystem Map
   */
  get tspPipelineSystemMap$(): Observable<Map<number, Map<number, IPipelineSystem>>> {
    return concat(this.loadIfRequired(), this.hasData()).pipe(
      skip(1),
      map((lookupData: LookupData) => {
        const tspPipelineSystemMap = new Map<number, Map<number, IPipelineSystem>>();
        lookupData.tsps.forEach((tsp: Tsp) => {
          const pipelineSystemMap = new Map<number, IPipelineSystem>();
          this.loadPipelineSystemMap(pipelineSystemMap, tsp);
          tspPipelineSystemMap.set(tsp.tspId, pipelineSystemMap);
        });
        return tspPipelineSystemMap;
      })
    );
  }

  /**
   * return pipeline system arrays grouped by tsp
   */
  get tspPipelineSystems$(): Observable<Map<number, PipelineSystem[]>> {
    return concat(this.loadIfRequired(), this.hasData()).pipe(
      skip(1),
      map((lookupData: LookupData) => {
        const tspPipelineSystemMap = new Map<number, PipelineSystem[]>();
        lookupData.tsps.forEach((tsp: Tsp) => {
          tspPipelineSystemMap.set(tsp.tspId, tsp.pipelineSystems);
        });
        return tspPipelineSystemMap;
      })
    );
  }

  /**
   * returns an observable of the service provider's pipeline map
   */
  public getPipelineSystemMap$(
    serviceProvider$: Observable<ServiceProvider>
  ): Observable<Map<number, PipelineSystem>> {
    return combineLatest([
      this.tspPipelineSystemMap$.pipe(filter(Boolean)),
      serviceProvider$.pipe(
        filter((serviceProvider: ServiceProvider) =>
          Boolean(serviceProvider && serviceProvider.providerId)
        )
      ),
    ]).pipe(
      map(
        ([tspPipelineSystemMap, serviceProvider]: [
          Map<number, Map<number, PipelineSystem>>,
          ServiceProvider
        ]) => tspPipelineSystemMap.get(serviceProvider.providerId)
      )
    );
  }
}
