import { ConfirmationLevel, ConfirmationSummary } from '@gms/nomination-api';
import { UpdateConfirmationErrorResponse } from './confirmations.actions';

export interface ConfirmationEditLocationDateAccess {
  editAccess: boolean;
  loading: boolean;
  error: Error | null;
}

export interface ConfirmationEditLocationAccess {
  [gasFlowDate: string]: ConfirmationEditLocationDateAccess;
}

export interface ConfirmationEditAccess {
  [locationNumber: string]: ConfirmationEditLocationAccess;
}

export interface IConfirmationsState {
  confirmationSummaries: Array<ConfirmationSummary>;
  confirmationSummariesLoading: { [locationNumber: string]: boolean };
  confirmationSummariesError: { [locationNumber: string]: Error | null };
  totalConfirmationSummaries: { [locationNumber: string]: number };
  confirmationLastUpdated: number;

  loading: boolean;
  error: Error | null;
  totalConfirmationsCount: number;
  gasFlowDate: Date;
  cycleCode: string;
  excludeZeroQty: boolean;
  updateConfirmationsLoading: boolean;
  updateConfirmationsError: UpdateConfirmationErrorResponse | null;

  updateConfirmationsSuccess: ConfirmationSummary | null;
  confirmationsToUpdate: ConfirmationSummary;

  confirmationLevels: Array<ConfirmationLevel>;

  confirmationsAccessForLocation: ConfirmationEditAccess;
}

export const initialConfirmationsState: IConfirmationsState = {
  confirmationSummaries: null,
  confirmationSummariesLoading: {},
  confirmationSummariesError: {},
  totalConfirmationSummaries: {},
  confirmationLastUpdated: null,

  loading: true,
  error: null,
  totalConfirmationsCount: null,
  gasFlowDate: null,
  cycleCode: null,
  excludeZeroQty: null,
  updateConfirmationsLoading: null,
  updateConfirmationsError: null,
  confirmationsToUpdate: null,
  updateConfirmationsSuccess: null,
  confirmationLevels: [],

  confirmationsAccessForLocation: {},
};
