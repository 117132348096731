import { PredefinedFootnoteCollection } from '@gms/servicerequest-api';

export interface IPredefinedFootnotesState {
  predefinedFootnotes: PredefinedFootnoteCollection;
  loading: boolean;
  error: boolean;
}

export const initialPredefinedFootnotesState: IPredefinedFootnotesState = {
  predefinedFootnotes: {},
  error: false,
  loading: false,
};
