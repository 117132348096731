
export const sortDefinitions = {
  getEntityHeaders: 'shortName+asc',
  getAllAgencies: 'shortName+asc',
  getAgencyHeaders: 'agent_name+asc',
  getTrades: 'offerDate+asc',
  getContracts: 'contractNumber+asc',
  getContractHeaders: 'contractNumber+asc',
  getContractLocationQnty: 'locationNumber+asc',
  getBids : 'bidId+asc',
  getLocations: 'locationNumber+asc',
  getLocationHeaders: 'locationNumber+asc',
  getDetailRateSchedules: 'code+asc'
}







