import { ELookupActions, LookupActions } from './lookup.actions';
import { ILookupState, initialLookupState } from './lookup.state';

export function lookupReducers(state = initialLookupState, action: LookupActions): ILookupState {
  switch (action.type) {
    case ELookupActions.FETCH_LOOKUP_COLLECTIONS:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case ELookupActions.FETCH_LOOKUP_COLLECTIONS_SUCCESS:
      return {
        ...state,
        lookupCollections: action.payload,
        error: null,
        loading: false,
      };

    case ELookupActions.FETCH_LOOKUP_COLLECTIONS_FAILURE:
      return {
        ...state,
        lookupCollections: null,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
}
