import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from '@progress/kendo-file-saver';

import {
  AccountingAdjustmentsService,
  AccountingAdjustmentTypesService,
  AccountingFacilityBalanceExportService,
  AccountingFacilityBalanceService,
  AccountingJeAccountingPeriodStatusService,
  AccountingJournalEntriesService,
  AccountingJournalEntryExportService,
  AccountingJournalEntryRulesService,
  AccountingPeriodService,
  AccountingPipelineBalanceExportService,
  AccountingPipelineBalanceService,
  AccountingProjectContractsService,
  AccountingProjectsService,
  AccountingRevenueRecognitionReviewsService,
  AccountingStatementOfAccountsService,
  AccountingUnassignedGrowthContractsService,
  StatementOfAccountExportService,
} from '@gms/accounting-api';
import {
  AccountingEngineInventoryaccumulationsManualService,
  AccountingEngineService,
  AccountingEngineStatusService,
} from '@gms/accountingengine-api';
import { EInventoryViewByOptions } from 'app/modules/accounting/utils/inventory.utils';
import { convertAccountPeriodToDate } from 'app/modules/accounting/utils/journal-entries-utils';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  AssignContractToProject,
  AssignContractToProjectFailure,
  AssignContractToProjectSuccess,
  EAdjustmentsActions,
  EInventoryActions,
  EJournalEntryActions,
  EmptyAction,
  EProjectsActions,
  ERevenueRecognitionActions,
  EStatementOfAccountActions,
  ExportInventory,
  ExportInventoryFailure,
  ExportInventorySuccess,
  ExportJournalEntries,
  ExportJournalEntriesFailure,
  ExportJournalEntriesSuccess,
  ExportStatementOfAccount,
  ExportStatementOfAccountFailure,
  ExportStatementOfAccountSuccess,
  FetchMissingJournalEntryRulesCollection,
  FetchMissingJournalEntryRulesCollectionFailure,
  FetchMissingJournalEntryRulesCollectionSuccess,
  FetchRevenueRecognitionCollection,
  FetchRevenueRecognitionCollectionFailure,
  FetchRevenueRecognitionCollectionSuccess,
  FetchRunJournalEntry,
  FetchRunJournalEntryFailure,
  FetchRunJournalEntryStatus,
  FetchRunJournalEntryStatusFailure,
  FetchRunJournalEntryStatusSuccess,
  FetchRunJournalEntrySuccess,
  FetchStatementOfAccountCollection,
  FetchStatementOfAccountCollectionFailure,
  FetchStatementOfAccountCollectionSuccess,
  GetAdjustmentById,
  GetAdjustmentByIdFailure,
  GetAdjustmentByIdSuccess,
  GetAdjustmentPeriodStatusCollection,
  GetAdjustmentPeriodStatusCollectionFailure,
  GetAdjustmentPeriodStatusCollectionSuccess,
  GetAdjustmentsCollection,
  GetAdjustmentsCollectionFailure,
  GetAdjustmentsCollectionSuccess,
  GetAdjustmentTypesCollection,
  GetAdjustmentTypesCollectionFailure,
  GetAdjustmentTypesCollectionSuccess,
  GetFacilityStorage,
  GetFacilityStorageFailure,
  GetFacilityStorageSuccess,
  GetInventory,
  GetInventoryDefaultAccountPeriod,
  GetInventoryDefaultAccountPeriodFailure,
  GetInventoryDefaultAccountPeriodSuccess,
  GetInventoryFailure,
  GetInventorySuccess,
  GetJournalEntryCollection,
  GetJournalEntryCollectionFailure,
  GetJournalEntryCollectionSuccess,
  GetJournalEntryDetailCollection,
  GetJournalEntryDetailCollectionFailure,
  GetJournalEntryDetailCollectionSuccess,
  GetJournalEntryEbsUploadCollection,
  GetJournalEntryEbsUploadCollectionFailure,
  GetJournalEntryEbsUploadCollectionSuccess,
  GetJournalEntryRevenueRecognitionCollection,
  GetJournalEntryRevenueRecognitionCollectionFailure,
  GetJournalEntryRevenueRecognitionCollectionSuccess,
  GetProjectById,
  GetProjectByIdFailure,
  GetProjectByIdSuccess,
  GetProjectsCollection,
  GetProjectsCollectionFailure,
  GetProjectsCollectionSuccess,
  GetUnassignedContracts,
  GetUnassignedContractsFailure,
  GetUnassignedContractsSuccess,
  RunInventoryAccumulationsWebsocket,
  RunInventoryAccumulationsWebsocketConnectionFailure,
  SaveAdjustment,
  SaveAdjustmentFailure,
  SaveAdjustmentSuccess,
  SaveProject,
  SaveProjectFailure,
  SaveProjectSuccess,
  UpdateProject,
  UpdateProjectFailure,
  UpdateProjectSuccess,
  UpdateRevenueRecognition,
  UpdateRevenueRecognitionFailure,
  UpdateRevenueRecognitionSuccess,
} from './accounting.actions';

import { mockStatementOfAccount } from './accounting.models.mock';

@Injectable({ providedIn: 'root' })
export class AccountingEffects {
  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _accountingService: AccountingJournalEntriesService,
    private _accountingExportService: AccountingJournalEntryExportService,
    private _accountingEngineStatusService: AccountingEngineStatusService,
    private _accountingEngineInventoryAccumulation: AccountingEngineInventoryaccumulationsManualService,
    private _accountingEngineService: AccountingEngineService,
    private _adjustmentsService: AccountingAdjustmentsService,
    private _adjustmentPeriodService: AccountingJeAccountingPeriodStatusService,
    private _adjustmentTypesService: AccountingAdjustmentTypesService,
    private _projectsService: AccountingProjectsService,
    private _journalEntryRulesService: AccountingJournalEntryRulesService,
    private _accountingPipelineBalanceService: AccountingPipelineBalanceService,
    private _accountingUnassignedContractsService: AccountingUnassignedGrowthContractsService,
    private _accountingProjectContractsService: AccountingProjectContractsService,
    private _accountingDefaultPeriodService: AccountingPeriodService,
    private _accountingFacilityBalanceService: AccountingFacilityBalanceService,
    private _accountingPipelineBalanceExport: AccountingPipelineBalanceExportService,
    private _accountingFacilityBalanceExport: AccountingFacilityBalanceExportService,
    private _accountingRevRecService: AccountingRevenueRecognitionReviewsService,
    private _accountingStatementOfAccountService: AccountingStatementOfAccountsService,
    private _accountingStatementOfAccountExportService: StatementOfAccountExportService
  ) {}

  getJournalEntryCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJournalEntryCollection>(EJournalEntryActions.GetJournalEntryCollection),
      map((action: GetJournalEntryCollection) => action),
      switchMap(action => {
        const { accountPeriod, pageSize, pageNumber, sortDescriptors } = action.payload;

        let tspIds = null;

        if (action.payload.tspIds !== null && action.payload.tspIds.length > 0) {
          tspIds = action.payload.tspIds.join('|');
        }

        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;
        return this._accountingService
          .getJournalEntries(tspIds, accountPeriodInDateType, pageSize, pageNumber, sortBy)
          .pipe(
            map(response => new GetJournalEntryCollectionSuccess(response)),
            catchError(error => of(new GetJournalEntryCollectionFailure(error)))
          );
      })
    )
  );

  getJournalEntryDetailCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJournalEntryDetailCollection>(EJournalEntryActions.GetJournalEntryDetailCollection),
      map((action: GetJournalEntryDetailCollection) => action),
      switchMap(action => {
        const { accountPeriod, pageSize, pageNumber, sortDescriptors } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;

        let tspIds = null;

        if (action.payload.tspIds !== null && action.payload.tspIds.length > 0) {
          tspIds = action.payload.tspIds.join('|');
        }

        return this._accountingService
          .getJournalEntryDetails(tspIds, accountPeriodInDateType, pageSize, pageNumber, sortBy)
          .pipe(
            map(response => new GetJournalEntryDetailCollectionSuccess(response)),
            catchError(error => of(new GetJournalEntryDetailCollectionFailure(error)))
          );
      })
    )
  );

  getJournalEntryEbsUploadCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJournalEntryEbsUploadCollection>(
        EJournalEntryActions.GetJournalEntryEbsUploadCollection
      ),
      map((action: GetJournalEntryEbsUploadCollection) => action),
      switchMap(action => {
        const { accountPeriod, pageSize, pageNumber, sortDescriptors } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;

        let tspIds = null;

        if (action.payload.tspIds !== null && action.payload.tspIds.length > 0) {
          tspIds = action.payload.tspIds.join('|');
        }

        return this._accountingService
          .getJournalEntryEbsUpload(tspIds, accountPeriodInDateType, pageSize, pageNumber, sortBy)
          .pipe(
            map(response => new GetJournalEntryEbsUploadCollectionSuccess(response)),
            catchError(error => of(new GetJournalEntryEbsUploadCollectionFailure(error)))
          );
      })
    )
  );

  getJournalEntryRevenueRecognitionCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetJournalEntryRevenueRecognitionCollection>(
        EJournalEntryActions.GetJournalEntryRevenueRecognitionCollection
      ),
      map((action: GetJournalEntryRevenueRecognitionCollection) => action),
      switchMap(action => {
        const { accountPeriod, pageSize, pageNumber, sortDescriptors } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;

        let tspIds = null;

        if (action.payload.tspIds !== null && action.payload.tspIds.length > 0) {
          tspIds = action.payload.tspIds.join('|');
        }

        return this._accountingService
          .getJournalEntriesRevRec(tspIds, accountPeriodInDateType, pageSize, pageNumber, sortBy)
          .pipe(
            map(response => new GetJournalEntryRevenueRecognitionCollectionSuccess(response)),
            catchError(error => of(new GetJournalEntryRevenueRecognitionCollectionFailure(error)))
          );
      })
    )
  );

  exportJournalEntries$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportJournalEntries>(EJournalEntryActions.ExportJournalEntries),
      map((action: ExportJournalEntries) => action),
      switchMap(action => {
        const { tspName, accountPeriod, exportView, sortDescriptors } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;

        let tspIds = null;

        if (action.payload.tspIds !== null && action.payload.tspIds.length > 0) {
          tspIds = action.payload.tspIds.join('|');
        }

        return this._accountingExportService
          .getJournalEntryExport(exportView, tspIds, accountPeriodInDateType, null, null, sortBy)
          .pipe(
            map(response => {
              const mapResponse: string[] = atob(response).split('\n');
              const responseHeaders: string[] = mapResponse[0].split(',');
              const debitAmountIndex = responseHeaders.findIndex(
                header => header.trim() === 'DebitAmount'
              );
              const creditAmountIndex = responseHeaders.findIndex(
                header => header.trim() === 'CreditAmount'
              );
              const commaFollowingByNoSpaceRegex = /\s*(?:,(?=\S)|$)\s*/;
              for (let i = 1; i < mapResponse.length - 1; i++) {
                const splittedLine = mapResponse[i].split(
                  commaFollowingByNoSpaceRegex,
                  responseHeaders.length
                );
                splittedLine[debitAmountIndex] = Math.abs(
                  Number(splittedLine[debitAmountIndex])
                ).toString();
                splittedLine[creditAmountIndex] = Math.abs(
                  Number(splittedLine[creditAmountIndex])
                ).toString();
                mapResponse[i] = splittedLine.join(',');
              }

              const blob = new Blob([mapResponse.join('\n')], { type: 'text/csv' });
              saveAs(
                blob,
                `Journal Entries - ${accountPeriod.mon}_${
                  accountPeriod.year
                }_${exportView}_${tspName}.csv`
              );
              return new ExportJournalEntriesSuccess();
            }),
            catchError(error => of(new ExportJournalEntriesFailure(error)))
          );
      })
    )
  );

  getRunJournalEntryStatus$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRunJournalEntryStatus>(EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY_STATUS),
      map((action: FetchRunJournalEntryStatus) => action),
      switchMap(action => {
        const { tspId, accountingPeriod, jobTypeIds } = action.payload;
        const accountPeriodParam = !!accountingPeriod
          ? `${+accountingPeriod.mon}+${accountingPeriod.year}`
          : null;
        return this._accountingEngineStatusService
          .getEngineStatus(tspId, accountPeriodParam, jobTypeIds)
          .pipe(
            map(response => new FetchRunJournalEntryStatusSuccess(response)),
            catchError(error => of(new FetchRunJournalEntryStatusFailure(error)))
          );
      })
    )
  );

  getRunJournalEntry$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRunJournalEntry>(EJournalEntryActions.FETCH_RUN_JOURNAL_ENTRY),
      map((action: FetchRunJournalEntry) => action),
      switchMap(action => {
        const { tspId, accountingPeriod } = action.payload;
        const accountPeriodParam = !!accountingPeriod
          ? `${+accountingPeriod.mon}+${accountingPeriod.year}`
          : null;
        return this._accountingEngineService
          .triggerAccountingEngine(tspId, accountPeriodParam)
          .pipe(
            map(response => new FetchRunJournalEntrySuccess(response)),
            catchError(error => of(new FetchRunJournalEntryFailure(error)))
          );
      })
    )
  );

  getAdjustmentsCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAdjustmentsCollection>(EAdjustmentsActions.GET_ADJUSTMENTS_COLLECTION),
      map((action: GetAdjustmentsCollection) => action),
      switchMap(action => {
        const { tspId, accountPeriod, pageSize, pageNumber, sortDescriptors } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const sortBy = `${sortDescriptors[0].field}+${sortDescriptors[0].dir}`;
        return this._adjustmentsService
          .getAccountingAdjustments(tspId, accountPeriodInDateType, pageSize, pageNumber, sortBy)
          .pipe(
            map(response => new GetAdjustmentsCollectionSuccess(response)),
            catchError(error => of(new GetAdjustmentsCollectionFailure(error)))
          );
      })
    )
  );

  getAdjustmentTypesCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAdjustmentTypesCollection>(EAdjustmentsActions.GET_ADJUSTMENTS_TYPES_COLLECTION),
      map((action: GetAdjustmentTypesCollection) => action),
      switchMap(action => {
        return this._adjustmentTypesService.getAccountingAdjustmentTypes().pipe(
          map(response => new GetAdjustmentTypesCollectionSuccess(response)),
          catchError(error => of(new GetAdjustmentTypesCollectionFailure(error)))
        );
      })
    )
  );

  saveSelectedAdjustment$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveAdjustment>(EAdjustmentsActions.SAVE_ADJUSTMENT),
      map((action: SaveAdjustment) => action),
      switchMap(action => {
        return this._adjustmentsService.addAccountingAdjustments(action.payload).pipe(
          map(response => new SaveAdjustmentSuccess(response)),
          catchError(error => of(new SaveAdjustmentFailure(error)))
        );
      })
    )
  );

  navigateToSavedAdjustment$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SaveAdjustmentSuccess>(EAdjustmentsActions.SAVE_ADJUSTMENT_SUCCESS),
        tap(a => this._router.navigateByUrl(`/adjustments/${a.payload.id}`))
      ),
    { dispatch: false }
  );

  getadjustmentPeriodStatus$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAdjustmentPeriodStatusCollection>(
        EAdjustmentsActions.GET_ADJUSTMENT_PERIOD_STATUS_COLLECTION
      ),
      map((action: GetAdjustmentPeriodStatusCollection) => action),
      switchMap(action => {
        return this._adjustmentPeriodService.getjeAccountingPeriodStatuses(action.tspId).pipe(
          map(response => new GetAdjustmentPeriodStatusCollectionSuccess(response)),
          catchError(error => of(new GetAdjustmentPeriodStatusCollectionFailure(error)))
        );
      })
    )
  );

  getAdjustmentById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAdjustmentById>(EAdjustmentsActions.FETCH_ADJUSTMENT_BY_ID),
      map((action: GetAdjustmentById) => action),
      switchMap(action => {
        return this._adjustmentsService.getAccountingAdjustmentById(action.payload).pipe(
          map(response => new GetAdjustmentByIdSuccess(response)),
          catchError(error => of(new GetAdjustmentByIdFailure(error)))
        );
      })
    )
  );

  saveSelectedProject$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveProject>(EProjectsActions.SAVE_PROJECT),
      map((action: SaveProject) => action),
      switchMap(action => {
        return this._projectsService.createProject(action.payload).pipe(
          map(response => new SaveProjectSuccess(response)),
          catchError(error => of(new SaveProjectFailure(error)))
        );
      })
    )
  );

  navigateToProjects$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveProjectSuccess>(EProjectsActions.SAVE_PROJECT_SUCCESS),
      tap(a => this._router.navigateByUrl(`/projects`))
    )
  );

  navigateToUpdatedProjects$: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateProjectSuccess>(EProjectsActions.UPDATE_PROJECT_SUCCESS),
        tap(a => this._router.navigateByUrl(`/projects`))
      ),
    { dispatch: false }
  );

  updateSelectedProject$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateProject>(EProjectsActions.UPDATE_PROJECT),
      map((action: UpdateProject) => action),
      switchMap(action => {
        return this._projectsService.updateProject(action.payload, action.projectId).pipe(
          map(response => new UpdateProjectSuccess(response)),
          catchError(error => of(new UpdateProjectFailure(error)))
        );
      })
    )
  );

  getProjectById$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetProjectById>(EProjectsActions.FETCH_PROJECT_BY_ID),
      map((action: GetProjectById) => action),
      switchMap(action => {
        return this._projectsService.getProjectById(action.payload).pipe(
          map(response => new GetProjectByIdSuccess(response)),
          catchError(error => of(new GetProjectByIdFailure(error)))
        );
      })
    )
  );

  getProjectsCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetProjectsCollection>(EProjectsActions.FETCH_PROJECTS_COLLECTION),
      map((action: GetProjectsCollection) => action),
      switchMap(action => {
        const { viewById } = action.payload;

        let tspIds = null;

        if (action.payload.tspIds && action.payload.tspIds.length > 0) {
          tspIds = action.payload.tspIds.join('|');
        }

        return this._projectsService.getProjects(tspIds, viewById).pipe(
          map(response => new GetProjectsCollectionSuccess(response)),
          catchError(error => of(new GetProjectsCollectionFailure(error)))
        );
      })
    )
  );

  getUnassignedContracts$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetUnassignedContracts>(EProjectsActions.FETCH_UNASSIGNED_CONTRACTS),
      map((action: GetUnassignedContracts) => action),
      switchMap(action => {
        return this._accountingUnassignedContractsService
          .getUnassignedGrowthContracts(action.payload.tspId)
          .pipe(
            map(response => new GetUnassignedContractsSuccess(response)),
            catchError(error => of(new GetUnassignedContractsFailure(error)))
          );
      })
    )
  );

  AssignContractToProject$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<AssignContractToProject>(EProjectsActions.ASSIGN_CONTRACT_TO_PROJECT),
      map((action: AssignContractToProject) => action),
      switchMap(action => {
        const { contractId, projectId, tspId, viewById } = action.payload;
        const payloadForCreatContract = { contractId, projectId };
        return this._accountingProjectContractsService
          .createProjectContract(payloadForCreatContract)
          .pipe(
            switchMap(() => [
              new AssignContractToProjectSuccess(),
              new GetUnassignedContracts({ tspId }),
              new GetProjectsCollection({
                tspIds: new Array(1).fill(tspId) as Array<Number>,
                viewById,
              }),
            ]),
            catchError(error => of(new AssignContractToProjectFailure(error)))
          );
      })
    )
  );

  fetchMissingJournalEntryRulesCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchMissingJournalEntryRulesCollection>(
        EJournalEntryActions.FETCH_MISSING_JOURNAL_ENTRY_RULES_COLLECTION
      ),
      map((action: FetchMissingJournalEntryRulesCollection) => action),
      switchMap(action => {
        const { accountPeriod } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        let tspIds = null;

        if (action.payload.tspIds && action.payload.tspIds.length > 0) {
          tspIds = action.payload.tspIds.join('|');
        }

        return this._journalEntryRulesService
          .getMissingJournalEntryRules(tspIds, accountPeriodInDateType)
          .pipe(
            map(response => new FetchMissingJournalEntryRulesCollectionSuccess(response)),
            catchError(error => of(new FetchMissingJournalEntryRulesCollectionFailure(error)))
          );
      })
    )
  );

  getInventory$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetInventory>(EInventoryActions.FETCH_INVENTORY),
      map((action: GetInventory) => action),
      switchMap(action => {
        const { tspId, viewBy, accountPeriod } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        return this._accountingPipelineBalanceService
          .getPipelineBalance(tspId, accountPeriodInDateType)
          .pipe(
            map(response => new GetInventorySuccess(response)),
            catchError(error => of(new GetInventoryFailure(error)))
          );
      })
    )
  );

  getRunInventoryAccumulationsWebsocket$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RunInventoryAccumulationsWebsocket>(
        EInventoryActions.RUN_INVENTORY_ACCUMULATIONS_WEBSOCKET
      ),
      map((action: RunInventoryAccumulationsWebsocket) => action),
      switchMap(action => {
        const { tspId, accountingPeriod } = action.payload;
        const accountPeriodParam = !!accountingPeriod
          ? `${+accountingPeriod.mon}+${accountingPeriod.year}`
          : null;
        return this._accountingEngineInventoryAccumulation
          .triggerManualAccumulations(tspId, accountPeriodParam)
          .pipe(
            map(response => new EmptyAction()),
            catchError(error => of(new RunInventoryAccumulationsWebsocketConnectionFailure(error)))
          );
      })
    )
  );

  fetchDefaultInventoryAccountPeriod$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetInventoryDefaultAccountPeriod>(
        EInventoryActions.FETCH_INVENTORY_DEFAULT_ACCOUNT_PERIOD
      ),
      map((action: GetInventoryDefaultAccountPeriod) => action),
      switchMap(action => {
        const { tspId } = action.payload;
        return this._accountingDefaultPeriodService.getDefaultAcctPeriod(tspId).pipe(
          map(response => new GetInventoryDefaultAccountPeriodSuccess(response)),
          catchError(error => of(new GetInventoryDefaultAccountPeriodFailure(error)))
        );
      })
    )
  );

  getFacilityBalance$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetFacilityStorage>(EInventoryActions.FETCH_FACILITY_STORAGE),
      map((action: GetFacilityStorage) => action),
      switchMap(action => {
        const { tspId, accountPeriod } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        return this._accountingFacilityBalanceService
          .getFacilityBalance(tspId, accountPeriodInDateType)
          .pipe(
            map(response => new GetFacilityStorageSuccess(response)),
            catchError(error => of(new GetFacilityStorageFailure(error)))
          );
      })
    )
  );

  exportInventory$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportInventory>(EInventoryActions.EXPORT_INVENTORY),
      map((action: ExportInventory) => action),
      switchMap(action => {
        const { tspId, tspName, accountPeriod, exportView } = action.payload;
        const accountPeriodInDateType = convertAccountPeriodToDate(accountPeriod);
        const saveData = (data: string) => {
          const trimData = data.replace(/"/g, '');
          const blob = new Blob([atob(trimData)], { type: 'text/csv' });
          saveAs(blob, `${exportView} - ${accountPeriod.mon}_${accountPeriod.year} ${tspName}.csv`);
        };
        if (exportView === EInventoryViewByOptions.SandD) {
          return this._accountingPipelineBalanceExport
            .getPipelineBalanceExport(tspId, accountPeriodInDateType)
            .pipe(
              map(response => {
                saveData(response);
                return new ExportInventorySuccess();
              }),
              catchError(error => of(new ExportInventoryFailure(error)))
            );
        } else if (exportView === EInventoryViewByOptions.FacilityStorage) {
          return this._accountingFacilityBalanceExport
            .getFacilityBalanceExport(tspId, accountPeriodInDateType)
            .pipe(
              map(response => {
                saveData(response);
                return new ExportInventorySuccess();
              }),
              catchError(error => of(new ExportInventoryFailure(error)))
            );
        } else {
          return of(new ExportInventoryFailure({ name: '404', message: 'unsupported view' }));
        }
      })
    )
  );

  getRevenueRecognitionCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchRevenueRecognitionCollection>(
        ERevenueRecognitionActions.FETCH_REVENUE_RECOGNITION
      ),
      map((action: FetchRevenueRecognitionCollection) => action),
      switchMap(action => {
        const { tspId, pageSize, pageNumber } = action.payload;
        return this._accountingRevRecService
          .getRevRecReviewedChTypes(tspId, pageNumber, pageSize)
          .pipe(
            map(response => new FetchRevenueRecognitionCollectionSuccess(response)),
            catchError(error => of(new FetchRevenueRecognitionCollectionFailure(error)))
          );
      })
    )
  );

  updateRevenueRecognition$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateRevenueRecognition>(ERevenueRecognitionActions.UPDATE_REVENUE_RECOGNITION),
      map((action: UpdateRevenueRecognition) => action),
      switchMap(action => {
        const { revRec } = action.payload;
        return this._accountingRevRecService.putRevRecReviewedChType(revRec, revRec.id).pipe(
          map(response => new UpdateRevenueRecognitionSuccess()),
          catchError(error => of(new UpdateRevenueRecognitionFailure(error)))
        );
      })
    )
  );

  fetchStatementOfAccountCollection$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchStatementOfAccountCollection>(
        EStatementOfAccountActions.FETCH_STATEMENT_OF_ACCOUNT_COLLECTION
      ),
      map((action: FetchStatementOfAccountCollection) => action),
      switchMap(action => {
        const { tspId, accountingPeriod, svcReqNameId, agentNameId } = action.payload;

        const acctPeriodDate =
          !!accountingPeriod && !!accountingPeriod.id
            ? convertAccountPeriodToDate(accountingPeriod)
            : null;

        return this._accountingStatementOfAccountService
          .getStatementOfAccounts(acctPeriodDate, svcReqNameId, tspId)
          .pipe(
            map(response => new FetchStatementOfAccountCollectionSuccess(response)),
            catchError(error => of(new FetchStatementOfAccountCollectionFailure(error)))
          );
      })
    )
  );

  exportStatementOfAccount$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportStatementOfAccount>(EStatementOfAccountActions.EXPORT_STATEMENT_OF_ACCOUNT),
      map((action: ExportStatementOfAccount) => action),
      switchMap(action => {
        const { tspId, tspName, svcReqNameId, accountingPeriod } = action.payload;
        const saveData = (data: string) => {
          const trimData = data.replace(/"/g, '');
          const blob = new Blob([atob(trimData)], { type: 'text/csv' });
          saveAs(
            blob,
            `StatementOfAccount_${tspName}_${svcReqNameId}_${accountingPeriod.mon +
              accountingPeriod.year}.csv`
          );
        };
        return this._accountingStatementOfAccountExportService
          .getStatementOfAccountsExport(
            convertAccountPeriodToDate(accountingPeriod),
            svcReqNameId,
            tspId
          )
          .pipe(
            map((response: string) => {
              saveData(response);
              return new ExportStatementOfAccountSuccess();
            }),
            catchError(error => of(new ExportStatementOfAccountFailure(error)))
          );
      })
    )
  );
}
