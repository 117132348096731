import {
  clearState,
  createEmptyLoadingState,
  createErrorState,
  createInitialDataState,
  createLoadingState,
  createSuccessState,
  emptyRequestStatus,
} from 'app/store/app/app.models';
import { EEntitiesActions, EntitiesActions } from './entities.actions';
import { IEntitiesState, initialEntitiesState } from './entities.state';

export function entitiesReducers(
  state = initialEntitiesState,
  action: EntitiesActions
): IEntitiesState {
  switch (action.type) {
    case EEntitiesActions.FetchEntitiyById:
      return {
        ...state,
        entity: null,
        entityLoading: true,
        entityError: null,
      };

    case EEntitiesActions.ClearEntity:
      return {
        ...state,
        entity: null,
        entityLoading: false,
        entityError: null,
      };

    case EEntitiesActions.FetchEntitiyByIdSuccess:
      return {
        ...state,
        entity: action.payload.entity,
        entityLoading: false,
        entityError: null,
        updateEntitySuccess: false,
        createEntitySuccess: false,
        updateEntityClassOverrideSuccess: false,
        updateParentEntities: initialEntitiesState.updateParentEntities,
      };

    case EEntitiesActions.FetchEntitiyByIdFailure:
      return {
        ...state,
        entity: null,
        entityLoading: false,
        entityError: action.payload.error,
      };

    case EEntitiesActions.FetchEntities:
      return {
        ...state,
        error: null,
        filteredEntitiesLoading: action.payload.isEntitiesFilter
          ? true
          : state.filteredEntitiesLoading,
        loading: true,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
        entitiesSearchLoading: false,
      };

    case EEntitiesActions.FetchEntitiesSuccess: {
      return {
        ...state,
        entities: action.payload.entities,
        error: null,
        filteredEntities: action.payload.isEntitiesFilter
          ? action.payload.entities
          : state.filteredEntities,
        filteredEntitiesLoading: action.payload.isEntitiesFilter
          ? false
          : state.filteredEntitiesLoading,
        loading: false,
        totalEntityCount: action.payload.totalEntityCount,
      };
    }

    case EEntitiesActions.FetchEntitiesFailure:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        totalEntityCount: null,
        entities: [],
        filteredEntities: action.payload.isEntitiesFilter ? [] : state.filteredEntities,
        filteredEntitiesLoading: action.payload.isEntitiesFilter
          ? false
          : state.filteredEntitiesLoading,
      };

    case EEntitiesActions.CLEAR_FILTERED_ENTITIES:
      return {
        ...state,
        error: null,
        loading: false,
        totalEntityCount: null,
        entities: [],
        filteredEntities: [],
        filteredEntitiesLoading: false,
      };

    case EEntitiesActions.FetchAllEntities:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case EEntitiesActions.GetEntityDictionary:
      return {
        ...state,
        dictionary: null,
        dictionaryLoading: true,
        dictionaryError: null,
      };

    case EEntitiesActions.GetEntityDictionarySuccess:
      return {
        ...state,
        dictionary: action.payload,
        dictionaryLoading: false,
        dictionaryError: null,
      };

    case EEntitiesActions.GetEntityDictionaryError:
      return {
        ...state,
        dictionary: null,
        dictionaryLoading: false,
        dictionaryError: action.payload.error,
      };

    case EEntitiesActions.ResetEntitiesSuccess:
      return {
        ...state,
        entities: [],
        loading: false,
        totalEntityCount: null,
      };
    case EEntitiesActions.SearchEntities:
      return {
        ...state,
        entitiesSearchLoading: true,
      };
    case EEntitiesActions.SearchEntitiesSuccess:
      return {
        ...state,
        entitiesSearch: action.payload.entities,
        entitiesSearchLoading: false,
        entitiesSearchError: null,
      };
    case EEntitiesActions.SearchEntitiesFailure:
      return {
        ...state,
        entitiesSearchLoading: false,
        entitiesSearchError: action.payload.error,
      };
    case EEntitiesActions.ClearEntitiesSearch:
      return {
        ...state,
        entitiesSearch: [],
      };

    case EEntitiesActions.ResetFilteredEntities:
      return {
        ...state,
        filteredEntities: [],
      };
    case EEntitiesActions.ResetEntity:
      return {
        ...state,
        entity: null,
        updateEntityError: null,
      };

    case EEntitiesActions.UpdateEntity:
      return {
        ...state,
        updateEntityLoading: true,
        updateEntityError: null,
        updateEntitySuccess: false,
      };

    case EEntitiesActions.UpdateEntitySuccess:
      return {
        ...state,
        updateEntityLoading: false,
        updateEntityError: null,
        updateEntitySuccess: true,
      };

    case EEntitiesActions.UpdateEntityFailure:
      return {
        ...state,
        updateEntityLoading: false,
        updateEntityError: action.payload.error,
        updateEntitySuccess: false,
      };

    case EEntitiesActions.UpdateEntityClassOverrides:
      return {
        ...state,
        updateEntityClassOverrideLoading: true,
        updateEntityClassOverrideError: null,
        updateEntityClassOverrideSuccess: false,
      };

    case EEntitiesActions.UpdateEntityClassOverridesSuccess:
      return {
        ...state,
        updateEntityClassOverrideLoading: false,
        updateEntityClassOverrideError: null,
        updateEntityClassOverrideSuccess: true,
      };

    case EEntitiesActions.UpdateEntityClassOverridesFailure:
      return {
        ...state,
        updateEntityClassOverrideLoading: false,
        updateEntityClassOverrideError: action.payload.error,
        updateEntityClassOverrideSuccess: false,
      };

    case EEntitiesActions.CreateEntity:
      return {
        ...state,
        createEntityLoading: true,
        createEntityError: null,
        createEntitySuccess: false,
      };

    case EEntitiesActions.CreateEntitySuccess:
      return {
        ...state,
        createEntityLoading: false,
        createEntityError: null,
        createEntitySuccess: true,
        entity: action.payload.entity,
      };

    case EEntitiesActions.CreateEntityFailure:
      return {
        ...state,
        createEntityLoading: false,
        createEntityError: action.payload.error,
        createEntitySuccess: false,
      };
    case EEntitiesActions.FETCH_PARENT_ENTITIES_BY_ENTITY_ID:
      return {
        ...state,
        parentEntityCollection: createLoadingState(state.parentEntityCollection),
        updateParentEntities: initialEntitiesState.updateParentEntities,
      };

    case EEntitiesActions.FETCH_PARENT_ENTITIES_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        parentEntityCollection: createSuccessState(action.payload.parentEntityCollection),
      };

    case EEntitiesActions.FETCH_PARENT_ENTITIES_BY_ENTITY_ID_FAILURE:
      return {
        ...state,
        parentEntityCollection: createErrorState(action.payload.error),
      };

    case EEntitiesActions.ADD_CREDIT_LIMIT_BY_ENTITY_ID:
      return {
        ...state,
        addEntityCreditLimit: createLoadingState(state.addEntityCreditLimit),
      };

    case EEntitiesActions.CLEAR_ADD_CREDIT_LIMIT_BY_ENTITY_ID:
      return {
        ...state,
        addEntityCreditLimit: clearState(state.addEntityCreditLimit),
      };

    case EEntitiesActions.ADD_CREDIT_LIMIT_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        addEntityCreditLimit: createSuccessState(action.payload.creditLimit),
      };

    case EEntitiesActions.ADD_CREDIT_LIMIT_BY_ENTITY_ID_FAILURE:
      return {
        ...state,
        addEntityCreditLimit: createErrorState(action.payload.error),
      };

    case EEntitiesActions.UPDATE_CREDIT_LIMITS_BY_ENTITY_ID:
      return {
        ...state,
        updateEntityCreditLimits: createLoadingState(state.updateEntityCreditLimits),
      };

    case EEntitiesActions.CLEAR_UPDATE_CREDIT_LIMITS_BY_ENTITY_ID:
      return {
        ...state,
        updateEntityCreditLimits: clearState(state.updateEntityCreditLimits),
      };

    case EEntitiesActions.UPDATE_CREDIT_LIMITS_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        updateEntityCreditLimits: createSuccessState(action.payload.creditLimits),
      };

    case EEntitiesActions.UPDATE_CREDIT_LIMITS_BY_ENTITY_ID_FAILURE:
      return {
        ...state,
        updateEntityCreditLimits: createErrorState(action.payload.error),
      };

    case EEntitiesActions.GET_CREDIT_LIMITS_BY_ENTITY_ID:
      return {
        ...state,
        entityCreditLimits: createLoadingState(state.entityCreditLimits),
      };

    case EEntitiesActions.GET_CREDIT_LIMITS_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        entityCreditLimits: createSuccessState(action.payload.creditLimits),
      };

    case EEntitiesActions.GET_CREDIT_LIMITS_BY_ENTITY_ID_FAILURE:
      return {
        ...state,
        entityCreditLimits: createErrorState(action.payload.error),
      };

    case EEntitiesActions.UPDATE_PARENT_ENTITIES_BY_ENTITY_ID:
      return {
        ...state,
        updateParentEntities: createLoadingState({
          ...state.updateParentEntities,
          data: { success: false },
        }),
      };

    case EEntitiesActions.UPDATE_PARENT_ENTITIES_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        updateParentEntities: createSuccessState({ success: true }),
      };

    case EEntitiesActions.UPDATE_PARENT_ENTITIES_BY_ENTITY_ID_FAILURE:
      return {
        ...state,
        updateParentEntities: {
          ...createErrorState(action.payload.error),
          data: { success: false },
        },
      };
    case EEntitiesActions.FETCH_ALL_ENTITIES_BY_IDS:
      return {
        ...state,
        entitiesByIds: createLoadingState(state.entitiesByIds),
      };

    case EEntitiesActions.FETCH_ALL_ENTITIES_BY_IDS_SUCCESS:
      return {
        ...state,
        entitiesByIds: createSuccessState({ entities: action.payload.entities }),
      };

    case EEntitiesActions.FETCH_ALL_ENTITIES_BY_IDS_FAILURE:
      return {
        ...state,
        entitiesByIds: createErrorState(action.payload.error),
      };

    case EEntitiesActions.FETCH_ENTITY_HEADER_BY_ID:
      return {
        ...state,
        entityHeader: createLoadingState(state.entityHeader),
      };
    case EEntitiesActions.FETCH_ENTITY_HEADER_BY_ID_SUCCESS:
      return {
        ...state,
        entityHeader: createSuccessState(action.payload),
      };
    case EEntitiesActions.FETCH_ENTITY_HEADER_BY_ID_FAILURE:
      return {
        ...state,
        entityHeader: createErrorState(action.payload),
      };
    case EEntitiesActions.CLEAR_ENTITY_HEADER_BY_ID:
      return {
        ...state,
        entityHeader: createInitialDataState(),
      };
    case EEntitiesActions.SEARCH_ENTITY_HEADERS:
      return {
        ...state,
        searchEntityHeader: createLoadingState(state.entityHeader),
      };
    case EEntitiesActions.SEARCH_ENTITY_HEADERS_SUCCESS:
      return {
        ...state,
        searchEntityHeader: createSuccessState(action.payload),
      };
    case EEntitiesActions.SEARCH_ENTITY_HEADERS_FAILURE:
      return {
        ...state,
        searchEntityHeader: createErrorState(action.payload),
      };

    case EEntitiesActions.FETCH_ALL_ENTITY_HEADERS:
      return {
        ...state,
        entityHeaders: createLoadingState(state.entityHeaders),
      };

    case EEntitiesActions.FETCH_ALL_ENTITY_HEADERS_SUCCESS:
      return {
        ...state,
        entityHeaders: createSuccessState(action.payload),
      };

    case EEntitiesActions.FETCH_ALL_ENTITY_HEADERS_FAILURE:
      return {
        ...state,
        entityHeaders: createErrorState(action.payload),
      };

    case EEntitiesActions.CLEAR_ENTITY_HEADERS:
      return {
        ...state,
        entityHeaders: createSuccessState({ entityHeaders: [] }),
      };

    case EEntitiesActions.CLEAR_ENTITY_HEADERS_LOOKUP_ERROR:
      return {
        ...state,
        entityHeaders: { ...state.entityHeaders, requestStatus: emptyRequestStatus },
      };

    case EEntitiesActions.FETCH_ENTITY_CREDIT_EXPOSURES:
      return {
        ...state,
        entityCreditExposures: createEmptyLoadingState(),
      };

    case EEntitiesActions.FETCH_ENTITY_CREDIT_EXPOSURES_FAILURE:
      return {
        ...state,
        entityCreditExposures: createErrorState(action.payload),
      };

    case EEntitiesActions.FETCH_ENTITY_CREDIT_EXPOSURES_SUCCESS:
      return {
        ...state,
        entityCreditExposures: createSuccessState(action.payload),
      };

    case EEntitiesActions.SORT_ENITITY_CREDIT_EXPOSURE_TABLE:
      return {
        ...state,
        entityCreditExposureSort: action.payload,
      };

    case EEntitiesActions.CREATE_ENTITY_CREDIT_EXPOSURE:
      return {
        ...state,
        entityCreditExposure: createEmptyLoadingState(),
      };

    case EEntitiesActions.CREATE_ENTITY_CREDIT_EXPOSURE_FAILURE:
      return {
        ...state,
        entityCreditExposure: createErrorState(action.payload),
      };

    case EEntitiesActions.CREATE_ENTITY_CREDIT_EXPOSURE_SUCCESS:
      return {
        ...state,
        entityCreditExposure: createSuccessState(action.payload),
      };

    case EEntitiesActions.UPDATE_ENTITY_CREDIT_EXPOSURE:
      return {
        ...state,
        entityCreditExposure: createEmptyLoadingState(),
      };

    case EEntitiesActions.UPDATE_ENTITY_CREDIT_EXPOSURE_FAILURE:
      return {
        ...state,
        entityCreditExposure: createErrorState(action.payload),
      };

    case EEntitiesActions.UPDATE_ENTITY_CREDIT_EXPOSURE_SUCCESS:
      return {
        ...state,
        entityCreditExposure: createSuccessState(action.payload),
      };

    case EEntitiesActions.CLEAR_CREDIT_EXPOSURE_STATE:
      return {
        ...state,
        entityCreditExposure: createInitialDataState(),
        entityCreditExposures: createInitialDataState(),
        entityCreditExposureSort: null,
      };

    case EEntitiesActions.CLEAR_CREDIT_LIMIT_STATE:
      return {
        ...state,
        entityCreditLimits: createInitialDataState(),
      };

    case EEntitiesActions.FETCH_ENTITY_ADDRESSES:
      return {
        ...state,
        entityAddresses: createEmptyLoadingState(),
      };

    case EEntitiesActions.FETCH_ENTITY_ADDRESSES_FAILURE:
      return {
        ...state,
        entityAddresses: createErrorState(action.payload),
      };

    case EEntitiesActions.FETCH_ENTITY_ADDRESSES_SUCCESS:
      return {
        ...state,
        entityAddresses: createSuccessState(action.payload),
      };

    case EEntitiesActions.CLEAR_ENTITY_ADDRESSES:
      return {
        ...state,
        entityAddresses: createSuccessState([]),
      };

    case EEntitiesActions.FETCH_ENTITY_PERMISSION_ACCESS:
      return {
        ...state,
        entityPermissionAccess: createLoadingState(state.entityPermissionAccess),
      };

    case EEntitiesActions.FETCH_ENTITY_PERMISSION_ACCESS_SUCCESS:
      return {
        ...state,
        entityPermissionAccess: createSuccessState(action.payload.hasAccess),
      };

    case EEntitiesActions.FETCH_ENTITY_PERMISSION_ACCESS_FAILURE:
      return {
        ...state,
        entityPermissionAccess: createErrorState(action.payload.error),
      };

    default:
      return state;
  }
}
