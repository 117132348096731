import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { appConfig } from 'config/app-config';
import { AwsRumConfig, AwsRum, Telemetry } from 'aws-rum-web';
import packageInfo from '../../package.json'
import { HasSubscriptions } from 'shared/components/higher-order/has-subscriptions';
import { EAuthActions } from 'app/store/auth/auth.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

/**
 * Acceptable Formats
 * gms.module.event
 * gms.module.feature.sub_feature
 * gms.module.page.sub_feature
 */
export enum ERumEvent {
  Update_Refresh_Token = "gms.auth.refresh_token",
}

type UserItems = "gms:user:id" | "gms:user:isInternal";
type AuthItems = "gms:auth:now" | "gms:auth:logoutTime" | "gms:auth:warningLogoutTime";

type gmsUser = {
  [K in UserItems]: string;
}
type gmsAuth = {
  [K in AuthItems]: string;
}

@Injectable({providedIn: 'root'})
export class Rum extends HasSubscriptions {
  public awsRum: AwsRum;

  constructor(private _ngZone: NgZone, private _router: Router, private _actions$: Actions ) {
    super();
    try {
      _ngZone.runOutsideAngular(() => {
        const APP_ID: string = appConfig.rum.appmonitorid;
        const APP_VERSION: string = packageInfo.version;
        const APP_REGION: string = 'us-east-1';
        const config: AwsRumConfig = this.createAwsRumConfig();

        console.log("Initializing AWS RUM");

        this.awsRum = new AwsRum(APP_ID, APP_VERSION, APP_REGION, config);

        this.addLoginEventListener();
        this.subscribeToRouterNavigation();

        console.log("Successfully initialized AWS RUM");
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Record Event
   * @param {ERumEvent} _eventType
   * @param {object } eventData
   *
    * If we are going to add more than a few event types this
    * should be re-written to a type of 1 argument base class,
    * similar how throw should always have a new Error() thrown
    * we could write specifics about each Even type including if it were
    * enabled/disabled. and any additional features we want to run on record.
    *@returns {void}
  */
  public recordEvent(_eventType: ERumEvent, _eventData: object){
    // Enable or disable  certain event types with this switch
    switch(_eventType) {
      default:
        this.awsRum?.recordEvent(_eventType, _eventData);
        break;
    }
  }

  /**
   * Defaults were set using
   * https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
   * @returns AwsRumConfig
   */
  private createAwsRumConfig = (): AwsRumConfig => {
    return {
      allowCookies: true,
      batchLimit: 100,
      // client: undefined,
      // clientBuilder: undefined,
      cookieAttributes: { domain: window.location.hostname, path: '/', sameSite: 'Strict', secure: true, unique: false },
      disableAutoPageView: true,
      dispatchInterval: 5000,
      enableRumClient: true,
      enableXRay: true,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      eventCacheSize: 200,
      eventPluginsToLoad: [],
      guestRoleArn: appConfig.rum.rumguestrole,
      identityPoolId: appConfig.rum.rumidentitypoolid,
      pageIdFormat: 'PATH',
      pagesToExclude: [] as RegExp[],
      pagesToInclude: [/.*/] as RegExp[],
      signing: true,
      recordResourceUrl: true,
      routeChangeComplete: 100,
      routeChangeTimeout: 10000,
      sessionAttributes: this.getSessionAttributes(),
      sessionEventLimit: 200,
      sessionLengthSeconds: 1800,
      sessionSampleRate: 1,
      telemetries: this.getTelemetries(),
      // useBeacon: undefined,
      // userIdRetentionDays: undefined,
    };
  }

   /**
   * Application owners think about data collection in terms of the categories
   * of data being collected. For example, JavaScript errors, page load
   * performance, user journeys and user interactions are data collection
   * categories. However, there is not a 1-1 mapping between data collection
   * categories and plugins.
   *
   * This configuration option allows application owners to define the data
   * categories they want to collect without needing to understand and
   * instantiate each plugin themselves. The toolkit will instantiate the
   * plugins which map to the selected categories.
   * @returns {Telemetry[]}
   */
  private getTelemetries = (): Telemetry[] => {
   return [
      "performance",
      "errors",
      ["http", {
        // Leaving this at default (false) to keep ingested event count low
        // recordAllRequests: true,
        // TODO: requires deploy script update & rollout to allow header in CORS
        // https://boardwalkpp.atlassian.net/browse/GMS-54482
        // addXRayTraceIdHeader: true
      }]
    ] as Telemetry[];
  }

  /**
   *
   * @returns {[k: string]: string | number | boolean}
   */
  private getSessionAttributes = (): gmsUser & gmsAuth => {
    // Set auth state at first launch (for scenarios where user is already logged-in)
    const authState = JSON.parse(localStorage.getItem('authState'));
    return {
      ["gms:user:id"]: authState?.user?.data?.userId || '',
      ["gms:user:isInternal"]: authState?.isInternal?.toString() || '',
      ["gms:auth:now"]: Date.now().toString(),
      ["gms:auth:logoutTime"]: authState?.logoutTime?.toString() || '',
      ["gms:auth:warningLogoutTime"]: authState?.warningLogoutTime?.toString() || '',
    }
  }

  /*
  * Also subscribe to login success (for scenarios where user isn't already logged-in)
  */
  private addLoginEventListener = (): void => {
    this.addSubscription(this._actions$.pipe(
      ofType(EAuthActions.FETCH_USER_SUCCESS),
      tap(() => {
        this.awsRum?.addSessionAttributes(this.getSessionAttributes());
      })
    ).subscribe())
  }

  private subscribeToRouterNavigation = (): void => {
    this.addSubscription(
      this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.awsRum) {
        this.awsRum.recordPageView(this._router.url);
      }
    }));
  }
}
