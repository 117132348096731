<!-- Usage: see CustomValidators.ts -->
<!-- Note: This component needs to be added to any form component that displays errors if it is not already in the template -->

  <!-- Style for in a table -->
  <app-popover *ngIf="isInATable"
  [horizontalAlignment]="popoverHorizontalAlignment.Center"
  [overrideFitContent]="true"
  [displayOnHover]="true"
  [isActivatedByFocus]="true"
  [tableOverflow]="tableOverflow"
>
  <popover-content class="tooltip" *ngIf="control && control.errors && !!control.touched">
    <ng-container *ngTemplateOutlet="messages; context: {control: control, defaultErrorStyle: false } "></ng-container>
    <!-- TODO: fix this hack -->
    <div class="arrow-down"></div>
  </popover-content>

  <ng-container *ngTemplateOutlet="inputContent"></ng-container>
</app-popover>

<!-- Style for not in a table -->
<ng-container *ngIf="!isInATable">
    <ng-container *ngTemplateOutlet="inputContent"></ng-container>
    <ng-container *ngTemplateOutlet="messages; context: {control: control, defaultErrorStyle: true } "></ng-container>
</ng-container>


<!-- Hack to get Ng content to work nicely, otherwise it will only appear with the last usage of NG-content :) -->
<ng-template #inputContent>
  <ng-content></ng-content>
</ng-template>

<!-- message template -->
<ng-template #messages let-control = "control" let-defaultErrorStyle = "defaultErrorStyle">
  <ng-container *ngIf="control && control.errors">
    <ng-container *ngFor = "let errorName of objectKeys(control.errors)" >
      <div *ngIf="
      !!control.invalid &&
        !!control.touched &&
        hasKey(control.errors[errorName],'type') &&
        hasKey(control.errors[errorName],'message')"
        [class.global-error-text]="defaultErrorStyle">
        {{control.errors[errorName].message}}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
