import { Routes } from '@angular/router';
import { AuthGuard } from 'app/core/routeGuards/auth.guard';
import { ResourceGuard } from 'app/core/routeGuards/resource.guard';
import { EPermissionOption } from 'app/store/auth/model/models';

export const Dealsheets_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/service-request/pages/deal-sheet/deal-sheet.module').then(
        m => m.DealSheetModule
      ),
    canActivate: [AuthGuard, ResourceGuard],
    data: {
      aclResource: '/deal-sheet',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },

  {
    path: 'view-all',
    loadChildren: () =>
      import('app/modules/service-request/pages/view-all-deal-sheets/view-all-deal-sheets.module').then(
        m => m.ViewAllDealSheetsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/deal-sheet/view-all',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },

  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/service-request/pages/create-deal-sheet/create-deal-sheet.module').then(
        m => m.CreateDealSheetModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/deal-sheet/new',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },

  {
    path: ':id/edit',
    loadChildren: () =>
      import('app/modules/service-request/pages/create-deal-sheet/create-deal-sheet.module').then(
        m => m.CreateDealSheetModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/deal-sheet/{id}/edit',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },

  {
    path: ':id/overview',
    loadChildren: () =>
      import('app/modules/service-request/pages/deal-sheet-overview/deal-sheet-overview.module').then(
        m => m.DealSheetOverviewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/deal-sheet',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
];

export const Service_Requests_Routes: Routes = [
  {
    path: 'overview',
    loadChildren: () =>
      import('app/modules/service-request/pages/service-requests-overview/service-requests-overview.module').then(
        m => m.ServiceRequestsOverviewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/service-request',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/service-request/pages/service-requests/service-requests.module').then(
        m => m.ServiceRequestsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/service-requests/new',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: 'admin',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/modules/service-request/pages/service-requests-admin/service-requests-admin.module').then(
            m => m.ServiceRequestsAdminModule
          ),
        canActivate: [ResourceGuard],
        data: {
          aclResource: '/service-requests/admin',
          aclAccessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        path: 'view-all',
        loadChildren: () =>
          import('app/modules/service-request/pages/admin-rfs-view-all/admin-rfs-view-all.module').then(
            m => m.AdminRfsViewAllModule
          ),
        canActivate: [ResourceGuard],
        data: {
          aclResource: '/service-requests/admin/view-all',
          aclAccessLevel: EPermissionOption.ReadOnly,
        },
      },
    ],
  },
  {
    path: ':id/overview',
    loadChildren: () =>
      import('app/modules/service-request/pages/service-requests-overview/service-requests-overview.module').then(
        m => m.ServiceRequestsOverviewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/service-requests/{id}/overview',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/quantity',
    loadChildren: () =>
      import('app/modules/service-request/pages/service-requests-quantity/service-requests-quantity.module').then(
        m => m.ServiceRequestsQuantityModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/service-requests/{id}/quantity',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('app/modules/service-request/pages/service-requests/service-requests.module').then(
        m => m.ServiceRequestsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/service-requests/{id}/edit',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
];

export const Amendments_Routes: Routes = [
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/service-request/pages/create-amendments/create-amendments.module').then(
        m => m.CreateAmendmentsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/amendments/new',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('app/modules/service-request/pages/create-amendments/create-amendments.module').then(
        m => m.CreateAmendmentsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/amendments/{id}/edit',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },
  {
    path: ':id/interim',
    loadChildren: () =>
      import('app/modules/service-request/pages/interim-amendments/interim-amendments.module').then(
        m => m.InterimAmendmentsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/amendments/{id}/interim',
      aclAccessLevel: EPermissionOption.Edit,
    },
  },

  {
    path: ':id/overview',
    loadChildren: () =>
      import('app/modules/service-request/pages/overview-amendments/overview-amendments.module').then(
        m => m.OverviewAmendmentsModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/amendments',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },

  {
    path: ':id/test',
    loadChildren: () =>
      import('app/modules/service-request/pages/automation-amendments/automation-amendments.module').then(
        m => m.AutomationAmendmentsModule
      ),
    data: { aclKey: '/amendments/{id}/test' },
  },
];

export const ContractConsolidation_Routes: Routes = [
  {
    path: 'new',
    loadChildren: () =>
      import('app/modules/service-request/pages/create-contract-consolidation/create-contract-consolidation.module').then(
        m => m.CreateContractConsolidationModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contract-consolidation/new',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    path: ':id/overview',
    loadChildren: () =>
      import('app/modules/service-request/pages/service-requests-overview/service-requests-overview.module').then(
        m => m.ServiceRequestsOverviewModule
      ),
    canActivate: [ResourceGuard],
    data: {
      aclResource: '/contract-consolidation/{id}/overview',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];
