import get from 'lodash/get';
import { BehaviorSubject } from 'rxjs';
import { DynamicClickableIconComponent } from 'shared/components/table/dynamic-cell-components/clickable-icon-cell/clickable-icon-cell.component';
import { EIcon } from 'shared/icons/icon.enum';

export class DynamicClickableIconCell {
  dynamicComponentType: typeof DynamicClickableIconComponent;
  data: ClickableIconOptions;
}

export interface TooltipIconOptions {
  displayIcon?: boolean;
  displayOnHover?: boolean;
  cssClass?: string;
  iconClass?: EIcon;
  tooltipMessage?: string;
}

export interface ClickableIconOptions {
  id?: string;
  data: any;
  iconClass: string;
  displayText?: string;
  dynamicClass: BehaviorSubject<string>;
  onClick: Function | ((data: any, event: any) => {});
  updateOnModelChange?: boolean;
  tooltipIconOptions?: TooltipIconOptions;
}

export const createClickableIconCell = (
  clickableIconOptions: ClickableIconOptions
): DynamicClickableIconCell => {
  let id = 'ClickableIcon';
  if (get(clickableIconOptions, 'id')) {
    id = get(clickableIconOptions, 'id') + '_' + id;
  }

  return {
    dynamicComponentType: DynamicClickableIconComponent,
    data: {
      ...clickableIconOptions,
      id: id,
    },
  };
};
