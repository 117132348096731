import { Note } from '@gms/servicerequest-api';
import { ENotesActions } from './notes.actions';

export interface INotesState {
  notes: Note[];
  loading: boolean;
  error: boolean;
  networkResultStatus: ENotesActions;
}

export const initialNotesState: INotesState = {
  notes: [],
  error: false,
  loading: false,
  networkResultStatus: null,
};
