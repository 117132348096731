export enum EDetailRateScheduleCodes {
  FT = 'FT',
  FT_EFT = 'FT+EFT',
  FT_ENS = 'FT+ENS',
  FT_EFT_ENS = 'FT+EFT+ENS',
  STF = 'STF',
  STF_EFT = 'STF+EFT',
  STF_ENS = 'STF+ENS',
  STF_EFT_ENS = 'STF+EFT+ENS',
  FT_FLS = 'FT-FLS',
  FT_FLS_ENS = 'FT-FLS+ENS',
  FT_FLS_EFT = 'FT-FLS+EFT',
  FT_FLS_EFT_ENS = 'FT-FLS+EFT+ENS',
  FTS_P = 'FTS-P',
  FTS_A = 'FTS-A',
  FTS_SSO = 'FTS-SSO',
  FTS_FLS = 'FTS-FLS',
  FTS = 'FTS',
  EFT = 'EFT',
  EFT_SSO = 'EFT-SSO',
  PAL = 'PAL',
  IWS = 'IWS',
  IT = 'IT',
  HOT = 'HOT',
  TAPS = 'TAPS',
  PXS = 'PXS',
  AVS = 'AVS',
  PKS = 'PKS',
  PS = 'PS',
  ITS = 'ITS',
  ITS_P = 'ITS-P',
  ILS = 'ILS',
  IPS = 'IPS',
  FSS_M = 'FSS-M',
  FSS = 'FSS',
  FSS_P = 'FSS-P',
  FSS_B = 'FSS-B',
  ISS_M = 'ISS-M',
  ISS_P = 'ISS-P',
  ISS = 'ISS',
  PAL_Loan = 'PAL-Loan',
  PAL_Park = 'PAL-Park',
  NNS = 'NNS',
  NNL = 'NNL',
  OBA = 'OBA',
  GS_ESI = 'GS-ESI',
  SGT = 'SGT',
}

export const RATESCHEDULE_SEASONAL_PRIMARY_POINT = 'SeasonalPrimaryPointsFlag';
export const RATESCHEDULE_EDI_APPLICATION = 'EDIApplicableFlag';
