import {
  createEmptyLoadingState,
  createErrorState,
  createLoadingState,
  createSuccessState,
} from 'app/store/app/app.models';
import groupBy from 'lodash/groupBy';
import { CapacityReleaseActions, ECapacityReleaseActions } from './capacity-release.actions';
import { ICapacityReleaseState, initialCapacityReleaseState } from './capacity-release.state';

export function capacityReleaseReducers(
  state = initialCapacityReleaseState,
  action: CapacityReleaseActions
): ICapacityReleaseState {
  switch (action.type) {
    case ECapacityReleaseActions.FetchOffers:
      if (action.payload.searchOffers) {
        return {
          ...state,
          searchedOffers: createLoadingState(state.searchedOffers),
        };
      } else {
        return {
          ...state,
          offersCollectionStatus: {
            error: null,
            loading: true,
          },
        };
      }
    case ECapacityReleaseActions.FetchOffersSuccess:
      if (action.payload.searchOffers) {
        return {
          ...state,
          searchedOffers: createSuccessState(action.payload),
        };
      } else {
        return {
          ...state,
          offers: action.payload.offers,
          offersCollectionStatus: {
            loading: false,
            error: null,
          },
        };
      }
    case ECapacityReleaseActions.FetchOffersFailure:
      return {
        ...state,
        offersCollectionStatus: {
          error: action.payload.error,
          loading: false,
        },
        searchedOffers: createEmptyLoadingState(),
      };

    case ECapacityReleaseActions.FetchBiddableOffers:
      if (action.payload.searchOffers) {
        return {
          ...state,
          searchedOffers: createLoadingState(state.searchedOffers),
        };
      } else {
        return {
          ...state,
          offersCollectionStatus: {
            error: null,
            loading: true,
          },
        };
      }
    case ECapacityReleaseActions.FetchBiddableOffersSuccess:
      if (action.payload.searchOffers) {
        return {
          ...state,
          searchedOffers: createSuccessState(action.payload),
        };
      } else {
        return {
          ...state,
          offers: action.payload.offers,
          offersCollectionStatus: {
            loading: false,
            error: null,
          },
        };
      }
    case ECapacityReleaseActions.FetchBiddableOffersFailure:
      return {
        ...state,
        offersCollectionStatus: {
          error: action.payload.error,
          loading: false,
        },
        searchedOffers: createEmptyLoadingState(),
      };

    case ECapacityReleaseActions.FetchOfferById:
      return {
        ...state,
        offerStatus: {
          error: null,
          loading: true,
        },
      };
    case ECapacityReleaseActions.FetchOfferByIdSuccess:
      return {
        ...state,
        offer: action.payload.offer,
        offerStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchOfferByIdFailure:
      return {
        ...state,
        offerStatus: {
          error: action.payload.error,
          loading: false,
        },
      };

    case ECapacityReleaseActions.FetchBiddableOfferById:
      return {
        ...state,
        offerStatus: {
          error: null,
          loading: true,
        },
      };
    case ECapacityReleaseActions.FetchBiddableOfferByIdSuccess:
      return {
        ...state,
        offer: action.payload.offer,
        offerStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchBiddableOfferByIdFailure:
      return {
        ...state,
        offerStatus: {
          error: action.payload.error,
          loading: false,
        },
      };
    case ECapacityReleaseActions.FetchWithdrawals:
      return {
        ...state,
        withdrawalsCollectionStatus: {
          error: null,
          loading: true,
        },
      };
    case ECapacityReleaseActions.FetchWithdrawalsSuccess:
      return {
        ...state,
        withdrawals: action.payload.withdrawals,
        withdrawalsCollectionStatus: {
          error: null,
          loading: false,
        },
      };
    case ECapacityReleaseActions.FetchWithdrawalsFailure:
      return {
        ...state,
        withdrawalsCollectionStatus: {
          error: action.payload.error,
          loading: false,
        },
      };
    case ECapacityReleaseActions.FetchBidById:
      return {
        ...state,
        bidStatus: {
          error: null,
          loading: true,
        },
      };
    case ECapacityReleaseActions.FetchBidByIdSuccess:
      return {
        ...state,
        bid: action.payload.bid,
        bidStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchBidByIdFailure:
      return {
        ...state,
        bidStatus: {
          error: action.payload.error,
          loading: false,
        },
      };
    case ECapacityReleaseActions.PostAward:
    case ECapacityReleaseActions.PutAward:
    case ECapacityReleaseActions.FetchAwardById:
    case ECapacityReleaseActions.FetchAwardNetQuantitiesById:
    case ECapacityReleaseActions.FetchAwardSeededLocationsById:
      return {
        ...state,
        awardStatus: {
          error: null,
          loading: true,
        },
      };
    case ECapacityReleaseActions.PostAwardSuccess:
    case ECapacityReleaseActions.PutAwardSuccess:
    case ECapacityReleaseActions.FetchAwardByIdSuccess:
    case ECapacityReleaseActions.FetchAwardNetQuantitiesByIdSuccess:
    case ECapacityReleaseActions.FetchAwardSeededLocationsByIdSuccess:
      return {
        ...state,
        award: action.payload.award,
        awardStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.PostAwardFailure:
    case ECapacityReleaseActions.PutAwardFailure:
    case ECapacityReleaseActions.FetchAwardByIdFailure:
    case ECapacityReleaseActions.FetchAwardNetQuantitiesByIdFailure:
    case ECapacityReleaseActions.FetchAwardSeededLocationsByIdFailure:
      return {
        ...state,
        awardStatus: {
          error: action.payload.error,
          loading: false,
        },
      };
    case ECapacityReleaseActions.ClearAward:
      return {
        ...state,
        award: null,
      };

    case ECapacityReleaseActions.FetchLinkedAwardById:
      return {
        ...state,
        linkedAward: null,
        linkedAwardStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchLinkedAwardByIdSuccess:
      return {
        ...state,
        linkedAward: action.payload.award,
        linkedAwardStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchLinkedAwardByIdFailure:
      return {
        ...state,
        linkedAward: null,
        linkedAwardStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchOriginalAwardById:
      return {
        ...state,
        originalAwardStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchOriginalAwardByIdSuccess:
      return {
        ...state,
        originalAward: action.payload.award,
        originalAwardStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchOriginalAwardByIdFailure:
      return {
        ...state,
        originalAwardStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchDictionary:
      return {
        ...state,
        dictionaryStatus: {
          error: null,
          loading: true,
        },
      };
    case ECapacityReleaseActions.FetchDictionarySuccess:
      return {
        ...state,
        dictionary: action.payload.dictionary,
        dictionaryStatus: {
          error: null,
          loading: false,
        },
      };
    case ECapacityReleaseActions.FetchDictionaryFailure:
      return {
        ...state,
        dictionary: null,
        dictionaryStatus: {
          error: action.payload.error,
          loading: false,
        },
      };

    case ECapacityReleaseActions.PostOffer:
      return {
        ...state,
        offerStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.PostOfferSuccess:
      return {
        ...state,
        offer: action.payload.offer,
        offerStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.PostOfferFailure:
      return {
        ...state,
        offerStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.PutOffer:
      return {
        ...state,
        offerStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.PutOfferSuccess:
      return {
        ...state,
        offer: action.payload.offer,
        offerStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.PutOfferFailure:
      return {
        ...state,
        offerStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.GetOffersLookup:
      return {
        ...state,
        offersSearchLookup: null,
        offersSearchLookupStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.GetOffersLookupSuccess:
      return {
        ...state,
        offersSearchLookup: action.payload.offersSearchLookup,
        offersSearchLookupStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.GetOffersLookupFailure:
      return {
        ...state,
        offersSearchLookup: null,
        offersSearchLookupStatus: {
          loading: true,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.ClearOffer:
      return {
        ...state,
        offer: null,
      };
    case ECapacityReleaseActions.ClearOffersCollection:
      return {
        ...state,
        offers: {
          offers: [],
          total: 0,
        },
      };

    case ECapacityReleaseActions.DeleteOffer:
      return {
        ...state,
        deleteOfferStatus: {
          success: false,
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.DeleteOfferSuccess:
      return {
        ...state,
        deleteOfferStatus: {
          success: true,
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.DeleteOfferFailure:
      return {
        ...state,
        deleteOfferStatus: {
          success: false,
          loading: false,
          error: action.payload.error,
        },
      };
    case ECapacityReleaseActions.ResetDeleteOfferStatus:
      return {
        ...state,
        deleteOfferStatus: {
          success: false,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.WithdrawOffer:
      return {
        ...state,
        withdrawOfferStatus: {
          success: false,
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.WithdrawOfferSuccess:
      return {
        ...state,
        withdrawOfferStatus: {
          success: true,
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.WithdrawOfferFailure:
      return {
        ...state,
        withdrawOfferStatus: {
          success: false,
          loading: false,
          error: action.payload.error,
        },
      };
    case ECapacityReleaseActions.ResetWithdrawOfferStatus:
      return {
        ...state,
        withdrawOfferStatus: {
          success: false,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.CopyOffer:
      return {
        ...state,
        copyOfferStatus: {
          success: false,
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.CopyOfferSuccess:
      return {
        ...state,
        offer: action.payload.offer,
        copyOfferStatus: {
          success: true,
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.CopyOfferFailure:
      return {
        ...state,
        copyOfferStatus: {
          success: false,
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchBids:
      return {
        ...state,
        bidsCollectionStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchBidsSuccess:
      return {
        ...state,
        bidsCollection: action.payload.bidsCollection,
        bidsCollectionStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchBidsFailure:
      return {
        ...state,
        bidsCollectionStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchBidsForFilters:
      return {
        ...state,
        bidsForFiltersStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchBidsForFiltersSuccess:
      return {
        ...state,
        bidsForFilters: action.payload.bids,
        bidsForFiltersStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.RESET_BIDS_FOR_FILTERS:
      return {
        ...state,
        bidsForFilters: action.payload.bids,
      };
    case ECapacityReleaseActions.FetchBidsFailure:
      return {
        ...state,
        bidsForFiltersStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.ClearBids:
      return {
        ...state,
        bidsCollection: {},
        bidsCollectionStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.ClearBid:
      return {
        ...state,
        bid: null,
      };

    case ECapacityReleaseActions.SetBidderViewMode:
      return {
        ...state,
        bidderViewMode: action.payload.bidderViewMode,
      };

    case ECapacityReleaseActions.SET_OFFER_VIEW_MODE:
      return {
        ...state,
        offerViewMode: action.payload.offerViewMode,
      };

    case ECapacityReleaseActions.CLEAR_OFFER_VIEW_MODE:
      return {
        ...state,
        offerViewMode: null,
      };

    case ECapacityReleaseActions.ConfirmBid:
      return {
        ...state,
        confirmBidStatus: {
          success: false,
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.ConfirmBidSuccess:
      return {
        ...state,
        bid: action.payload.bid,
        confirmBidStatus: {
          success: true,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.ConfirmBidFailure:
      return {
        ...state,
        confirmBidStatus: {
          success: false,
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.RejectBid:
      return {
        ...state,
        rejectBidStatus: {
          success: false,
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.RejectBidSuccess:
      return {
        ...state,
        bid: action.payload.bid,
        rejectBidStatus: {
          success: true,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.RejectBidFailure:
      return {
        ...state,
        rejectBidStatus: {
          success: false,
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchOfferBids:
      return {
        ...state,
        offerBids: {},
        offerBidsStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchOfferBidsSuccess:
      return {
        ...state,
        offerBids: action.payload.bidsCollection,
        offerBidsStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchOfferBidsFailure:
      return {
        ...state,
        offerBids: {},
        offerBidsStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.ClearOfferBids:
      return {
        ...state,
        offerBids: {},
        offerBidsStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.DeleteBid:
      return {
        ...state,
        deleteBidStatus: {
          success: false,
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.DeleteBidSuccess:
      return {
        ...state,
        deleteBidStatus: {
          success: true,
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.DeleteBidFailure:
      return {
        ...state,
        deleteBidStatus: {
          success: false,
          loading: false,
          error: action.payload.error,
        },
      };
    case ECapacityReleaseActions.ResetDeleteBidStatus:
      return {
        ...state,
        bid: null,
        deleteBidStatus: {
          success: false,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.WithdrawBid:
      return {
        ...state,
        withdrawBidStatus: {
          success: false,
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.WithdrawBidSuccess:
      return {
        ...state,
        bid: action.payload.bid,
        withdrawBidStatus: {
          success: true,
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.WithdrawBidFailure:
      return {
        ...state,
        withdrawBidStatus: {
          success: false,
          loading: false,
          error: action.payload.error,
        },
      };
    case ECapacityReleaseActions.ResetWithdrawBidStatus:
      return {
        ...state,
        bid: null,
        withdrawBidStatus: {
          success: false,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.ResetConfirmBidStatus:
      return {
        ...state,
        bid: null,
        confirmBidStatus: {
          success: false,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.ResetRejectBidStatus:
      return {
        ...state,
        bid: null,
        rejectBidStatus: {
          success: false,
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.PostBid:
      return {
        ...state,
        bid: null,
        bidStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.PostBidSuccess:
      return {
        ...state,
        bid: action.payload.bid,
        bidStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.PostBidFailure:
      return {
        ...state,
        bid: null,
        bidStatus: {
          loading: false,
          error: action.payload.error,
        },
      };
    case ECapacityReleaseActions.PutBid:
      return {
        ...state,
        bid: null,
        bidStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.PutBidSuccess:
      return {
        ...state,
        bid: action.payload.bid,
        bidStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.PutBidFailure:
      return {
        ...state,
        bid: null,
        bidStatus: {
          loading: false,
          error: action.payload.error,
        },
      };
    case ECapacityReleaseActions.FetchWithdrawnBids:
      return {
        ...state,
        withdrawnBids: null,
        withdrawnBidsStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchWithdrawnBidsSuccess:
      return {
        ...state,
        withdrawnBids: action.payload.bidCollection.bids,
        withdrawnBidsStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchWithdrawnBidsFailure:
      return {
        ...state,
        withdrawnBids: null,
        withdrawnBidsStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.ClearWithdrawnBids:
      return {
        ...state,
        withdrawnBids: null,
        withdrawnBidsStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchAwards:
      return {
        ...state,
        awardsCollectionStatus: {
          loading: true,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchAwardsSuccess:
      return {
        ...state,
        awardsCollection: action.payload.awardsCollection,
        awardsCollectionStatus: {
          loading: false,
          error: null,
        },
      };
    case ECapacityReleaseActions.FetchAwardsFailure:
      return {
        ...state,
        awardsCollectionStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchAwardsForFilters:
      return {
        ...state,
        awardsForFiltersStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchAwardsForFiltersSuccess:
      return {
        ...state,
        awardsForFilters: action.payload.awards,
        awardsForFiltersStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchAwardsForFiltersFailure:
      return {
        ...state,
        awardsForFiltersStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.SetReleaser:
      return {
        ...state,
        releaser: action.payload.releaser,
      };

    case ECapacityReleaseActions.SetReleaserOptions:
      return {
        ...state,
        releaserOptions: action.payload.releasers,
      };

    case ECapacityReleaseActions.FetchAwards:
      return {
        ...state,
        awardsCollectionStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchAwardOffer:
      return {
        ...state,
        awardOfferStatus: {
          ...state.awardOfferStatus,
          loading: true,
          loadingCount: state.awardOfferStatus.loadingCount + 1,
        },
      };

    case ECapacityReleaseActions.FetchAwardOfferSuccess:
      return {
        ...state,
        awardOffer:
          action.payload.offers && action.payload.offers.length
            ? { ...state.awardOffer, [action.payload.offers[0].offerId]: action.payload.offers }
            : state.awardOffer,
        awardOfferStatus: {
          ...state.awardOfferStatus,
          loading: state.awardOfferStatus.loadingCount - 1 === 0 ? false : true,
          loadingCount: state.awardOfferStatus.loadingCount - 1,
        },
      };

    case ECapacityReleaseActions.FetchAwardOfferFailure:
      return {
        ...state,
        awardOfferStatus: {
          ...state.awardOfferStatus,
          loading: state.awardOfferStatus.loadingCount - 1 === 0 ? false : true,
          loadingCount: state.awardOfferStatus.loadingCount - 1,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchBidEvaluationOffers:
      return {
        ...state,
        bidEvaluationOffers: [],
        bidEvaluationOffersStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchBidEvaluationOffersSuccess:
      return {
        ...state,
        bidEvaluationOffers: action.payload.offerCollection.offers,
        bidEvaluationOffersStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchBidEvaluationOffersFailure:
      return {
        ...state,
        bidEvaluationOffers: [],
        bidEvaluationOffersStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.ClearBidEvaluationOffers:
      return {
        ...state,
        bidEvaluationOffers: [],
        bidEvaluationOffersStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchBidEvaluationBids:
      return {
        ...state,
        bidEvaluationBidsStatus: {
          error: null,
          loading: true,
          loadingByItem: {
            ...state.bidEvaluationBidsStatus.loadingByItem,
            [action.payload.offerIds[0]]: true,
          },
        },
      };

    case ECapacityReleaseActions.FetchBidEvaluationBidsSuccess:
      if (action.payload.bids.length === 0) {
        return {
          ...state,
          bidEvaluationBidsStatus: {
            error: null,
            loading: false,
            loadingByItem: {
              ...state.bidEvaluationBidsStatus.loadingByItem,
              [action.payload.offerIds[0]]: false,
            },
          },
        };
      }
      const bidsOfferId = action.payload.bids[0].offerId;
      return {
        ...state,
        bidEvaluationBids: { ...state.bidEvaluationBids, [bidsOfferId]: action.payload.bids },
        bidEvaluationBidsStatus: {
          error: null,
          loading: false,
          loadingByItem: {
            ...state.bidEvaluationBidsStatus.loadingByItem,
            [bidsOfferId.toString()]: false,
          },
        },
      };

    case ECapacityReleaseActions.FetchBidEvaluationBidsFailure:
      return {
        ...state,
        bidEvaluationBidsStatus: {
          loading: false,
          error: action.payload.error,
          loadingByItem: {
            ...state.bidEvaluationBidsStatus.loadingByItem,
            [action.payload.offerIds[0]]: true,
          },
        },
      };

    case ECapacityReleaseActions.ClearBidEvaluationBids:
      return {
        ...state,
        bidEvaluationBids: {},
        bidEvaluationBidsStatus: {
          loading: false,
          error: null,
          loadingByItem: {},
        },
      };

    case ECapacityReleaseActions.FetchBidEvaluationAwards:
      return {
        ...state,
        bidEvaluationAwardsStatus: {
          loading: true,
          error: null,
          loadingByItem: {
            ...state.bidEvaluationBidsStatus.loadingByItem,
            [action.payload.offerIds[0]]: true,
          },
        },
      };
    case ECapacityReleaseActions.FetchBidEvaluationAwardsSuccess:
      if (action.payload.awards.length === 0) {
        return {
          ...state,
          bidEvaluationAwardsStatus: {
            loading: false,
            error: null,
            loadingByItem: {
              ...state.bidEvaluationBidsStatus.loadingByItem,
              [action.payload.offerIds[0]]: false,
            },
          },
        };
      }
      const awardsOfferId = action.payload.awards[0].offerId;
      return {
        ...state,
        bidEvaluationAwards: {
          ...state.bidEvaluationAwards,
          [action.payload.awards[0].offerId]: action.payload.awards,
        },
        bidEvaluationAwardsStatus: {
          loading: false,
          error: null,
          loadingByItem: {
            ...state.bidEvaluationBidsStatus.loadingByItem,
            [action.payload.offerIds[0].toString()]: false,
          },
        },
      };
    case ECapacityReleaseActions.FetchBidEvaluationAwardsFailure:
      return {
        ...state,
        bidEvaluationAwardsStatus: {
          loading: false,
          error: action.payload.error,
          loadingByItem: {
            ...state.bidEvaluationBidsStatus.loadingByItem,
            [action.payload.offerIds[0]]: false,
          },
        },
      };
    case ECapacityReleaseActions.ClearBidEvaluationAwards:
      return {
        ...state,
        bidEvaluationAwards: {},
        bidEvaluationAwardsStatus: {
          loading: false,
          error: null,
          loadingByItem: {},
        },
      };

    case ECapacityReleaseActions.CreateMatchingBid:
      return {
        ...state,
        matchingBid: null,
        matchingBidStatus: {
          loading: true,
          error: null,
          success: false,
        },
      };

    case ECapacityReleaseActions.CreateMatchingBidFailure:
      return {
        ...state,
        matchingBid: null,
        matchingBidStatus: {
          loading: false,
          error: action.payload.error,
          success: false,
        },
      };

    case ECapacityReleaseActions.CreateMatchingBidSuccess:
      return {
        ...state,
        matchingBid: action.payload.matchingBid,
        matchingBidStatus: {
          loading: false,
          error: null,
          success: true,
        },
      };

    case ECapacityReleaseActions.FetchContractOverview:
      return {
        ...state,
        contractOverview: null,
        contractOverviewStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchContractOverviewSuccess:
      return {
        ...state,
        contractOverview: action.payload.contractOverview,
        contractOverviewStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchContractOverviewFailure:
      return {
        ...state,
        contractOverview: null,
        contractOverviewStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.FetchOfferLocationOptions:
      return {
        ...state,
        offerLocationOptions: null,
        offerLocationOptionsStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchOfferLocationOptionsSuccess:
      return {
        ...state,
        offerLocationOptions: action.payload.offerLocationOptions,
        offerLocationOptionsStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchOfferLocationOptionsFailure:
      return {
        ...state,
        offerLocationOptions: null,
        offerLocationOptionsStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.ClearOfferLocationOptions:
      return {
        ...state,
        offerLocationOptions: null,
      };

    case ECapacityReleaseActions.FetchPreapprovedBidders:
      return {
        ...state,
        preApprovedBidders: [],
        preApprovedBiddersStatus: {
          loading: true,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchPreapprovedBiddersSuccess:
      return {
        ...state,
        preApprovedBidders: [...state.preApprovedBidders, ...action.payload.preapprovedBidders],
        preApprovedBiddersStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FetchPreapprovedBiddersFailure:
      return {
        ...state,
        preApprovedBidders: [...state.preApprovedBidders],
        preApprovedBiddersStatus: {
          loading: false,
          error: action.payload.error,
        },
      };

    case ECapacityReleaseActions.ClearPreapprovedBidders:
      return {
        ...state,
        preApprovedBidders: [],
        preApprovedBiddersStatus: {
          loading: false,
          error: null,
        },
      };

    case ECapacityReleaseActions.FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS:
      return {
        ...state,
        multipleOfferLocationsOptions: createLoadingState(state.multipleOfferLocationsOptions),
      };

    case ECapacityReleaseActions.FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS_SUCCESS:
      return {
        ...state,
        multipleOfferLocationsOptions: createSuccessState(action.payload.locationsOptions),
      };

    case ECapacityReleaseActions.FETCH_MULTIPLE_OFFER_LOCATIONS_OPTIONS_FAILURE:
      return {
        ...state,
        offerLocationOptionsStatus: {
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case ECapacityReleaseActions.SET_PARENT_OFFER:
      return {
        ...state,
        parentOffer: action.payload.parentOffer,
      };

    case ECapacityReleaseActions.CLEAR_RELEASER:
      return {
        ...state,
        releaser: null,
      };

    default:
      return state;
  }
}
