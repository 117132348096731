<app-form-input
  isInATable = 'true'
  [tableOverflow] = "data?.tableOverflow"
  [id] = "data?.id"
  [control] = "data?.control"
  [inputRegex] = "data?.inputRegex"
  [mask] = "data?.mask"
  [includeLiterals] = "data?.includeLiterals"
  [prompt] = "data?.prompt"
  [min]="(data?.dynamicFormattingOptions | async)?.min || data?.min"
  [max]="(data?.dynamicFormattingOptions | async)?.max || data?.max"
  [maxLength]="data?.maxLength"
  [rules] = "data?.rules"
  (inputBlurred) = "inputBlurred()"
  (inputFocused) = "inputFocused()"
  (inputChanges) = "inputChanges()"
  [units]="data?.units"

  [autoCorrect]= "data?.autoCorrect"

  [resizable] = "data?.resizable"

  [numberFormat] = "(data?.dynamicFormattingOptions | async)?.numberFormat || data?.numberFormat"
  [decimals] = "(data?.dynamicFormattingOptions | async)?.decimals || data?.decimals"

  [alignment] = "data?.alignment"
  [placeholder] = "(data?.dynamicFormattingOptions | async)?.placeholder || data?.placeholder"
>
</app-form-input>
