/**
 * Returns the specified number of minutes in milliseconds
 * @param minutes - number of minutes to convert to ms
 */
export const getMinutesInMS = (minutes: number) => {
  if (!minutes) {
    return 0;
  }

  return minutes * 60 * 1000;
};

/**
 * Returns the specified number of hours in milliseconds
 * @param hours - number of hours to convert to ms
 * @returns
 */
export const getHoursInMS = (hours: number) => {
  if (!hours) {
    return 0;
  }

  return getMinutesInMS(hours * 60);
};
