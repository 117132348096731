import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { saveAs } from '@progress/kendo-file-saver';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  AccumulatedFlowCollection,
  AccumulatedFlowSummary,
  GasQuality,
  LookupCollections,
  LookupService,
  MeasurementPeriodCollection,
  MeasurementsService,
} from '@gms/measurement-api';

import { HourlyOverrun } from '@gms/measurement-api/model/hourlyOverrun';
import { IAppState } from 'app/store/app/app.state';
import {
  AccumulatedFlowsPayload,
  AccumulatedFlowsSuccessPayload,
  AccumulatedFlowSummaryPayload,
  AccumulatedFlowSummarySuccessPayload,
  CreateLocationMeasurement,
  CreateLocationMeasurementFailure,
  CreateLocationMeasurementSuccess,
  EMeasurementsActions,
  ExportGasQuality,
  ExportGasQualityFailure,
  ExportGasQualitySuccess,
  ExportHourlyOverrun,
  ExportHourlyOverrunFailure,
  ExportHourlyOverrunSuccess,
  ExportMeasurementInfo,
  ExportMeasurementInfoFailure,
  ExportMeasurementInfoSuccess,
  FetchAccumulatedFlows,
  FetchAccumulatedFlowsFailure,
  FetchAccumulatedFlowsSuccess,
  FetchAccumulatedFlowSummary,
  FetchAccumulatedFlowSummaryFailure,
  FetchAccumulatedFlowSummarySuccess,
  FetchGasQuality,
  FetchGasQualityFailure,
  FetchGasQualitySuccess,
  FetchHourlyOverrun,
  FetchHourlyOverrunFailure,
  FetchHourlyOverrunSuccess,
  FetchLocationMeasurements,
  FetchLocationMeasurementsFailure,
  FetchLocationMeasurementsSuccess,
  FetchLookupCollections,
  FetchLookupCollectionsFailure,
  FetchLookupCollectionsSuccess,
  SetLocationMeasurements,
  UpdateLocationMeasurement,
  UpdateLocationMeasurementFailure,
  UpdateLocationMeasurementSuccess,
} from 'app/store/measurements/measurements.actions';
import { selectLookupCollections } from 'app/store/measurements/measurements.selectors';
import { dateUtils } from 'shared/utils/date.utils';
import { ToastService } from 'shared/services/toast.service';

@Injectable()
export class MeasurementsEffects {
  constructor(
    private _actions$: Actions,
    private _lookupService: LookupService,
    private _measurementsService: MeasurementsService,
    private _store: Store<IAppState>,
    private _toastService: ToastService
  ) {}

  fetchLocationMeasurements$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchLocationMeasurements>(EMeasurementsActions.FETCH_LOCATION_MEASUREMENTS),
      map((action: FetchLocationMeasurements) => action.payload),
      switchMap(payload => {
        payload = payload || {};
        return this._measurementsService
          .getLocationMeasurements(
            payload.beginFlowDate,
            payload.endFlowDate,
            payload.locationNumbers,
            payload.tspId,
            payload.locationId
          )
          .pipe(
            switchMap((locationMeasurements: MeasurementPeriodCollection) =>
              of(new FetchLocationMeasurementsSuccess({ locationMeasurements }))
            ),
            catchError(error => {
              this._toastService.showError('Error Fetching Measurements');
              return of(new FetchLocationMeasurementsFailure({ error }));
            })
          );
      })
    )
  );

  fetchLookupCollections$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchLookupCollections>(EMeasurementsActions.FETCH_LOOKUP_COLLECTIONS),
      withLatestFrom(this._store.pipe(select(selectLookupCollections))),
      switchMap(([_, lookupCollectionsState]: [FetchLookupCollections, LookupCollections]) => {
        if (lookupCollectionsState) {
          return of(
            new FetchLookupCollectionsSuccess({
              useCache: true,
            })
          );
        }

        return this._lookupService.getLookup().pipe(
          switchMap((lookupCollections: LookupCollections) =>
            of(new FetchLookupCollectionsSuccess({ lookupCollections }))
          ),
          catchError(error => of(new FetchLookupCollectionsFailure({ error })))
        );
      })
    )
  );

  updateLocationMeasurement$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateLocationMeasurement>(EMeasurementsActions.UPDATE_LOCATION_MEASUREMENT),
      switchMap((action: UpdateLocationMeasurement) => {
        return this._measurementsService
          .putLocationMeasurements(
            action.payload.locationMeasurements,
            action.payload.beginFlowDate,
            action.payload.endFlowDate,
            [action.payload.locationNumber],
            action.payload.tspId
          )
          .pipe(
            switchMap(() => [
              new SetLocationMeasurements({
                locationMeasurements: action.payload.locationMeasurements,
              }),
              new UpdateLocationMeasurementSuccess(),
            ]),
            catchError(error => of(new UpdateLocationMeasurementFailure({ error })))
          );
      })
    )
  );

  createLocationMeasurement$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateLocationMeasurement>(EMeasurementsActions.CREATE_LOCATION_MEASUREMENT),
      switchMap((action: CreateLocationMeasurement) => {
        return this._measurementsService
          .postLocationMeasurement(
            action.payload.measurement,
            [action.payload.locationNumber],
            action.payload.tspId
          )
          .pipe(
            map(() => new CreateLocationMeasurementSuccess()),
            catchError(error => of(new CreateLocationMeasurementFailure({ error })))
          );
      })
    )
  );

  fetchHourlyOverrun$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchHourlyOverrun>(EMeasurementsActions.FETCH_HOURLY_OVERRUN),
      switchMap((action: FetchHourlyOverrun) => {
        return this._measurementsService
          .getHourlyOverrun(action.payload.locationId, action.payload.gasDay)
          .pipe(
            switchMap((hourlyOverrun: HourlyOverrun) =>
              of(new FetchHourlyOverrunSuccess({ hourlyOverrun }))
            ),
            catchError(error => of(new FetchHourlyOverrunFailure({ error })))
          );
      })
    )
  );

  exportHourlyOverrun$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportHourlyOverrun>(EMeasurementsActions.EXPORT_HOURLY_OVERRUN),
      map((action: ExportHourlyOverrun) => action),
      switchMap(action => {
        const { locationId, locationNumber, gasDay, tspId } = action.payload;
        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });
          saveAs(blob, `${locationNumber}-${gasDay}-${tspId}.csv`);
        };
        return this._measurementsService.exportHourlyOverrun(locationId, gasDay, tspId).pipe(
          map((response: string) => {
            saveData(response);
            return new ExportHourlyOverrunSuccess();
          }),
          catchError(error => of(new ExportHourlyOverrunFailure(error)))
        );
      })
    )
  );

  fetchGasQuality$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchGasQuality>(EMeasurementsActions.FETCH_GAS_QUALITY),
      map((action: FetchGasQuality) => action.payload),
      switchMap(payload => {
        return this._measurementsService
          .getGasQuality(payload.locationId, payload.accountingPeriod)
          .pipe(
            switchMap((gasQuality: GasQuality) => {
              return of(new FetchGasQualitySuccess({ gasQuality }));
            }),
            catchError(error => of(new FetchGasQualityFailure({ error })))
          );
      })
    )
  );

  exportGasQuality$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportGasQuality>(EMeasurementsActions.EXPORT_GAS_QUALITY),
      map((action: ExportGasQuality) => action),
      switchMap(action => {
        const { locationId, locationNumber, accountingPeriod } = action.payload;

        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });
          saveAs(blob, `${locationNumber}-${accountingPeriod}.csv`);
        };

        return this._measurementsService.exportGasQuality(locationId, accountingPeriod).pipe(
          map((response: string) => {
            saveData(response);
            return new ExportGasQualitySuccess();
          }),
          catchError(error => of(new ExportGasQualityFailure(error)))
        );
      })
    )
  );

  getAccumulatedFlowSummary$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAccumulatedFlowSummary>(EMeasurementsActions.FETCH_ACCUMULATED_FLOW_SUMMARY),
      map((action: FetchAccumulatedFlowSummary) => action.payload),
      switchMap((payload: AccumulatedFlowSummaryPayload) => {
        const gasDate = payload.gasDay.toDateString();

        return this._measurementsService
          .getAccumulatedFlowSummary(payload.locationId, gasDate)
          .pipe(
            switchMap((response: AccumulatedFlowSummary) => {
              return of(new FetchAccumulatedFlowSummarySuccess({ ...response }));
            }),
            catchError(error => of(new FetchAccumulatedFlowSummaryFailure({ error })))
          );
      })
    )
  );

  getAccumulatedFlows$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchAccumulatedFlows>(EMeasurementsActions.FETCH_ACCUMULATED_FLOWS),
      map((action: FetchAccumulatedFlows) => action.payload),
      switchMap((payload: AccumulatedFlowsPayload) => {
        const { locationId, pageSize, pageNumber, gasDay } = payload;
        const gasDate = gasDay.toDateString();

        return this._measurementsService
          .getAccumulatedFlows(locationId, gasDate, pageSize, pageNumber)
          .pipe(
            switchMap((response: AccumulatedFlowCollection) => {
              return of(
                new FetchAccumulatedFlowsSuccess({
                  accumulatedFlows: response.accumullatedFlows,
                  total: response.total,
                })
              );
            }),
            catchError(error => of(new FetchAccumulatedFlowsFailure({ error })))
          );
      })
    )
  );

  exportMeasurementInfo$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<ExportMeasurementInfo>(EMeasurementsActions.EXPORT_MEASUREMENT_INFO),
      map((action: ExportMeasurementInfo) => action),
      switchMap(action => {
        const { locationId, beginFlowDate, endFlowDate, tspId, locationNumber } = action.payload;
        const saveData = (data: string) => {
          const blob = new Blob([atob(data)], { type: 'text/csv' });

          const beginGasFlowDate = dateUtils.getDateAsYYYY_MM_DD(beginFlowDate);
          const endGasFlowDate = dateUtils.getDateAsYYYY_MM_DD(endFlowDate);

          let fileName = `${locationId}_`;
          if (locationNumber != null) {
            fileName = fileName + `${locationNumber}_`;
          }
          if (tspId != null) {
            fileName = fileName + `${tspId}_`;
          }
          fileName = fileName + `${beginGasFlowDate}_${endGasFlowDate}.csv`;

          if (!action.payload.exportChildrenWithEmail) {
            saveAs(blob, fileName);
          }
        };
        if (action.payload.exportChildrenWithEmail) {
          return this._measurementsService
            .exportMeasurementInfo(
              tspId,
              beginFlowDate,
              endFlowDate,
              action.payload.exportChildrenWithEmail,
              locationId
            )
            .pipe(
              map((response: string) => {
                this._toastService.success('You will get an email when export is finished.');
                return new ExportMeasurementInfoSuccess();
              }),
              catchError(error => of(new ExportMeasurementInfoFailure(error)))
            );
        } else {
          return this._measurementsService
            .exportMeasurementInfo(
              tspId,
              beginFlowDate,
              endFlowDate,
              action.payload.exportChildrenWithEmail,
              locationId
            )
            .pipe(
              map((response: string) => {
                saveData(response);
                return new ExportMeasurementInfoSuccess();
              }),
              catchError(error => of(new ExportMeasurementInfoFailure(error)))
            );
        }
      })
    )
  );
}
