import { ConfirmationLevel, ConfirmationSummary } from '@gms/nomination-api';
import { Action } from '@ngrx/store';

export enum EConfirmationsActions {
  CLEAR_CONFIRMATION_SUMMARIES = '[CONFIRMATIONS] Clear Confirmation Summaries',
  CLEAR_CONFIRMATION_TOASTS = '[CONFIRMATIONS] Clear Confirmation Toasts',
  FETCH_CONFIRMATIONS = '[CONFIRMATIONS] Fetch Confirmations',
  FETCH_CONFIRMATIONS_SUCCESS = '[CONFIRMATIONS] Fetch Confirmations Success',
  FETCH_CONFIRMATIONS_ERROR = '[CONFIRMATIONS] Fetch Confirmations Error',
  FETCH_CONFIRMATIONS_FOR_LOCATION = '[CONFIRMATIONS] Fetch Confirmations for a Location',
  FETCH_CONFIRMATIONS_FOR_LOCATION_SUCCESS = '[CONFIRMATIONS] Fetch Confirmations for a Location Success',
  FETCH_CONFIRMATIONS_FOR_LOCATION_ERROR = '[CONFIRMATIONS] Fetch Confirmations for a Location Error',
  FETCH_CONFIRMATION_LEVEL_CONFIG = '[CONFIRMATIONS] Fetch Confirmation Level Config',
  FETCH_CONFIRMATION_LEVEL_CONFIG_SUCCESS = '[CONFIRMATIONS] Fetch Confirmation Level Config Success',
  FETCH_CONFIRMATION_LEVEL_CONFIG_FAILURE = '[CONFIRMATIONS] Fetch Confirmation Level Config Failure',
  UPDATE_CONFIRMATIONS = '[CONFIRMATIONS] Update Confirmations',
  UPDATE_CONFIRMATIONS_SUCCESS = '[CONFIRMATIONS] Update Confirmations Success',
  UPDATE_CONFIRMATIONS_ERROR = '[CONFIRMATIONS] Update Confirmations Error',
  FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION = '[CONFIRMATIONS] Fetch Confirmations Edit Access for a Location',
  FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION_SUCCESS = '[CONFIRMATIONS] Fetch Confirmations Edit Access for a Location Success',
  FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION_FAILURE = '[CONFIRMATIONS] Fetch Confirmations Edit Access for a Location Failure',
}

export interface IConfirmationsFilterPayload {
  tspId: number;
  confirmingPartyId: number;
  gasFlowDate: Date;
  cycleCode: string;
  excludeZeroQty: boolean;
  locationNumber?: Array<number>;
  serviceRequestContractId?: Array<number>;
  confirmationGroupId?: number;
  packageId?: string;
}

export interface IConfirmationsByLocationFilterPayload {
  locationId: number;
  gasFlowDate: Date;
  cycleCode: string;
  confirmationLevel?: string;
  excludeZeroQty?: boolean;
  serviceRequestContractId?: Array<number>;
  packageId?: string;
}

export class ClearConfirmationSummaries implements Action {
  public readonly type = EConfirmationsActions.CLEAR_CONFIRMATION_SUMMARIES;

  constructor() {}
}

export class ClearConfirmationToasts implements Action {
  public readonly type = EConfirmationsActions.CLEAR_CONFIRMATION_TOASTS;

  constructor() {}
}

export class FetchConfirmations implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS;

  constructor(public payload: IConfirmationsFilterPayload) {}
}

export class FetchConfirmationsSuccess implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_SUCCESS;

  constructor(
    public payload: {
      confirmationSummaries: Array<ConfirmationSummary>;
      totalConfirmationsCount: number;
    }
  ) {}
}

export class FetchConfirmationsFailure implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchConfirmationsForLocation implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_FOR_LOCATION;

  constructor(public payload: IConfirmationsByLocationFilterPayload) {}
}

export class FetchConfirmationsForLocationSuccess implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_FOR_LOCATION_SUCCESS;

  constructor(public payload: { confirmationSummary: ConfirmationSummary; locationId: number }) {}
}

export class FetchConfirmationsForLocationFailure implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_FOR_LOCATION_ERROR;

  constructor(public payload: { error: Error; locationId: number }) {}
}
export class FetchConfirmationLevelConfig implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATION_LEVEL_CONFIG;

  constructor() {}
}

export class FetchConfirmationLevelConfigSuccess implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATION_LEVEL_CONFIG_SUCCESS;

  constructor(public payload: { confirmationLevels: Array<ConfirmationLevel> }) {}
}

export class FetchConfirmationLevelConfigFailure implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATION_LEVEL_CONFIG_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class UpdateConfirmations implements Action {
  public readonly type = EConfirmationsActions.UPDATE_CONFIRMATIONS;

  constructor(
    public payload: {
      confirmationSummary: ConfirmationSummary;
      locationId: number;
      gasFlowDate: Date;
      cycleCode: string;
      confirmationLevel?: string;
      excludeZeroQty?: boolean;
      serviceRequestContractId?: Array<number>;
    }
  ) {}
}

export class UpdateConfirmationsSuccess implements Action {
  public readonly type = EConfirmationsActions.UPDATE_CONFIRMATIONS_SUCCESS;

  constructor(
    public payload: { updateConfirmationsSuccess: ConfirmationSummary; locationId: number }
  ) {}
}

export interface UpdateConfirmationErrorResponse {
  confirmationSummary: ConfirmationSummary;
  status: number;
  locationId: number;
}

export class UpdateConfirmationsError implements Action {
  public readonly type = EConfirmationsActions.UPDATE_CONFIRMATIONS_ERROR;

  constructor(public payload: UpdateConfirmationErrorResponse) {}
}

export class FetchConfirmationAccessForLocation implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION;

  constructor(
    public payload: {
      locationId: number;
      gasFlowDate: string;
    }
  ) {}
}

export class FetchConfirmationAccessForLocationSuccess implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION_SUCCESS;

  constructor(
    public payload: {
      locationId: number;
      gasFlowDate: string;
      editAccess: boolean;
    }
  ) {}
}

export class FetchConfirmationAccessForLocationFailure implements Action {
  public readonly type = EConfirmationsActions.FETCH_CONFIRMATIONS_ACCESS_FOR_LOCATION_FAILURE;

  constructor(
    public payload: {
      error: Error;
      locationId: number;
      gasFlowDate: string;
    }
  ) {}
}

export type ConfirmationsActions =
  | ClearConfirmationSummaries
  | ClearConfirmationToasts
  | FetchConfirmations
  | FetchConfirmationsSuccess
  | FetchConfirmationsFailure
  | FetchConfirmationsForLocation
  | FetchConfirmationsForLocationSuccess
  | FetchConfirmationsForLocationFailure
  | UpdateConfirmations
  | UpdateConfirmationsSuccess
  | UpdateConfirmationsError
  | FetchConfirmationLevelConfig
  | FetchConfirmationLevelConfigSuccess
  | FetchConfirmationLevelConfigFailure
  | FetchConfirmationAccessForLocation
  | FetchConfirmationAccessForLocationSuccess
  | FetchConfirmationAccessForLocationFailure;
