import { Cycle, Nomination } from '@gms/nomination-api';
import { User } from '@gms/user-api';
import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { gridStatusColorMapperFactory } from 'shared/utils/grid.utils';

const nominationColorMap = {
  [Nomination.StatusEnum.Submitted]: TagColor.DARK_BLUE,
  [Nomination.StatusEnum.Rejected]: TagColor.RED,
};

const cycleDisplayNames = {
  TIMELY: 'Timely',
  EVENING: 'Evening',
  ID1: 'Intraday 1',
  ID2: 'Intraday 2',
  ID3: 'Intraday 3',
};

export enum ENominationCreateCodes {
  BOTH = 'B',
  DELIVERY = 'D',
  RECEIPT = 'R',
  NEITHER = 'N',
}

export interface INomination extends Nomination {
  user?: User;
}

export const nominationUtils = {
  mapNominationToGridNomination: gridStatusColorMapperFactory('status', nominationColorMap),
};

export const sortNominationCycles = (nominationCycles: Array<Cycle>) => {
  if (nominationCycles) {
    return nominationCycles.sort((cycle1, cycle2) => {
      return cycle1.displayOrder > cycle2.displayOrder ? 1 : -1;
    });
  }
};
