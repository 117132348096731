import { TagColor } from 'shared/components/static-tag/tag-color.enum';
import { gridStatusColorMapperFactory } from 'shared/utils/grid.utils';

type LocationStatusEnum = 'ACTIVE' | 'INACTIVE' | 'RETIRED' | 'PROPOSED';

export const LocationStatusEnum = {
  ACTIVE: 'Active' as LocationStatusEnum,
  INACTIVE: 'Inactive' as LocationStatusEnum,
  RETIRED: 'Retired' as LocationStatusEnum,
  PROPOSED: 'Proposed' as LocationStatusEnum,
};

export const locationStatusNameMap = {
  A: 'Active',
  I: 'Inactive',
  R: 'Retired',
  P: 'Proposed',
};

export const locationStatusCodeMap = {
  ACTIVE: 'A',
  INACTIVE: 'I',
  RETIRED: 'R',
  PROPOSED: 'P',
};

export let locationsColorMap = {
  [LocationStatusEnum.ACTIVE]: TagColor.GREEN,
  [LocationStatusEnum.INACTIVE]: TagColor.WHITE,
  [LocationStatusEnum.RETIRED]: TagColor.GRAY,
  [LocationStatusEnum.PROPOSED]: TagColor.YELLOW,
};

export const locationTypes = {
  P: { typeName: 'Physical', typeId: 'P' },
  L: { typeName: 'Logical', typeId: 'L' },
};

export const mapLocationsToGridLocation = gridStatusColorMapperFactory('status', locationsColorMap);

export const mapLocationTypes = locationCollection => {
  return locationCollection.locations.map(location => {
    const temp = JSON.parse(JSON.stringify(location));
    if (temp.type === 'P') {
      temp.type = 'Physical';
    } else temp.type = 'Logical';
    return temp;
  });
};

export abstract class LocationGroupErrorMessages {
  static readonly LocationIsParentOfActiveGroupErrorMessage =
    'This Location is the parent of another active Group.';
}
