export type RequestStatusEnum =
  | 'Approved'
  | 'ContractExecuted'
  | 'RejectedKAdmin'
  | 'RejectedCommercial'
  | 'RejectedCredit'
  | 'RejectedSysPlaned'
  | 'Draft'
  | 'Invalid'
  | 'InvalidCredit'
  | 'PendingExecution'
  | 'PendingExecutionInvalidEmail'
  | 'SenttoAuction'
  | 'ReviewCommercial'
  | 'ReviewCredit'
  | 'ReviewKAdmin'
  | 'ReviewSysPlan'
  | 'Submitted'
  | 'ContractinProgress'
  | 'ContractReview'
  | 'FailuretoExecute'
  | 'Withdrawn';
export const RequestStatusEnum = {
  Approved: '1' as RequestStatusEnum,
  ContractExecuted: '5' as RequestStatusEnum,
  RejectedKAdmin: '8' as RequestStatusEnum,
  RejectedCommercial: '9' as RequestStatusEnum,
  RejectedCredit: '10' as RequestStatusEnum,
  RejectedSysPlaned: '11' as RequestStatusEnum,
  Draft: '13' as RequestStatusEnum,
  Invalid: '14' as RequestStatusEnum,
  InvalidCredit: '15' as RequestStatusEnum,
  PendingExecution: '17' as RequestStatusEnum,
  PendingExecutionInv: '18' as RequestStatusEnum,
  SenttoAuction: '21' as RequestStatusEnum,
  ReviewCommercial: '22' as RequestStatusEnum,
  ReviewCredit: '23' as RequestStatusEnum,
  ReviewKAdmin: '24' as RequestStatusEnum,
  ReviewSysPlan: '25' as RequestStatusEnum,
  Submitted: '26' as RequestStatusEnum,
  ContractinProgress: '27' as RequestStatusEnum,
  ContractReview: '28' as RequestStatusEnum,
  FailuretoExecute: '29' as RequestStatusEnum,
  Withdrawn: '30' as RequestStatusEnum,
};
