import {
  createCacheableSuccessState,
  createErrorState,
  createLoadingState,
  createSuccessState,
  useCache,
} from 'app/store/app/app.models';
import { ENominationsActions, NominationsActions } from './nominations.actions';
import { initialNominationsState, INominationsState } from './nominations.state';

export function nominationsReducers(
  state = initialNominationsState,
  action: NominationsActions
): INominationsState {
  switch (action.type) {
    case ENominationsActions.UPLOAD_NOMINATIONS:
      return {
        ...state,
        nominationUploadValidation: createLoadingState(state.nominationUploadValidation),
      };
    case ENominationsActions.UPLOAD_NOMINATIONS_SUCCESS:
      return {
        ...state,
        nominationUploadValidation: createSuccessState(action.payload),
      };
    case ENominationsActions.UPLOAD_NOMINATIONS_FAILURE:
      return {
        ...state,
        nominationUploadValidation: createErrorState(action.payload.error),
      };
    case ENominationsActions.SET_UPLOAD_NOMINATIONS_LOADING:
      return {
        ...state,
        nominationUploadValidation: {
          data: null,
          requestStatus: {
            loading: action.payload.loading,
            httpError: null,
          },
        },
      };

    case ENominationsActions.FETCH_NOMINATIONS:
      return {
        ...state,
        loading: true,
        loadingNominations: true,
        error: null,
        pagination: {
          pageSize: action.payload.pageSize,
          pageNumber: action.payload.pageNumber,
        },
        sortDescriptors: action.payload.sortDescriptors,
        nominations: [],
        totalNominationsCount: null,
        agencyAccessBySvcReq: false,
      };

    case ENominationsActions.FETCH_NOMINATIONS_SUCCESS:
      return {
        ...state,
        nominations: action.payload.nominations,
        loading: false,
        loadingNominations: false,
        error: null,
        totalNominationsCount: action.payload.totalNominationsCount,
        agencyAccessBySvcReq: action.payload.agencyAccessBySvcReq,
      };

    case ENominationsActions.FETCH_NOMINATIONS_ERROR:
      return {
        ...state,
        loading: false,
        loadingNominations: false,
        error: action.payload.error,
        nominations: null,
        totalNominationsCount: null,
      };

    case ENominationsActions.FETCH_NOMINATION_BY_ID:
      return {
        ...state,
        nomination: null,
        loading: true,
        error: null,
      };

    case ENominationsActions.FETCH_NOMINATION_BY_ID_SUCCESS:
      return {
        ...state,
        nomination: action.payload.nomination,
        loading: false,
        error: null,
      };

    case ENominationsActions.FETCH_NOMINATION_BY_ID_ERROR:
      return {
        ...state,
        nomination: null,
        error: action.payload.error,
        loading: false,
      };

    case ENominationsActions.VALIDATE_NOMINATIONS:
      return {
        ...state,
        validateNominationsLoading: true,
        validateNominationsError: null,
      };

    case ENominationsActions.VALIDATE_NOMINATIONS_SUCCESS:
      return {
        ...state,
        validateNominationsSuccess: { nominations: action.payload.nominations },
        validateNominationsError: null,
        validateNominationsLoading: false,
      };

    case ENominationsActions.VALIDATE_NOMINATIONS_FAILURE:
      return {
        ...state,
        validateNominationsError: action.payload.error,
        validateNominationsLoading: false,
      };

    case ENominationsActions.CLEAR_VALIDATE_NOMINATIONS:
      return {
        ...state,
        validateNominationsLoading: false,
        validateNominationsError: null,
      };

    case ENominationsActions.CREATE_NOMINATION:
      return {
        ...state,
        createNominationsSuccess: null,
        createNominationLoading: true,
        createNominationValidationError: null,
        createNominationError: null,
      };

    case ENominationsActions.CREATE_NOMINATION_SUCCESS:
      return {
        ...state,
        createNominationsSuccess: { nominations: action.payload.nominations },
        createNominationLoading: false,
        createNominationValidationError: null,
        createNominationError: null,
      };
    case ENominationsActions.CREATE_NOMINATION_VALIDATION_FAILURE:
      return {
        ...state,
        createNominationValidationError: action.payload.error,
        createNominationError: null,
        createNominationLoading: false,
      };
    case ENominationsActions.CREATE_NOMINATION_FAILURE:
      return {
        ...state,
        createNominationValidationError: null,
        createNominationError: action.payload.error,
        createNominationLoading: false,
      };
    case ENominationsActions.ADD_NOMINATION_WARNINGS:
      return {
        ...state,
        nominationWarnings: action.payload,
      };
    case ENominationsActions.CLEAR_NOMINATION_WARNINGS:
      return {
        ...state,
        nominationWarnings: [],
      };
    case ENominationsActions.CLEAR_NOMINATIONS:
      return {
        ...state,
        nominations: [],
        totalNominationsCount: null,
        validateNominationsSuccess: null,
        createNominationsSuccess: null,
        createNominationValidationError: null,
        createNominationError: null,
        createNominationLoading: false,
      };
    case ENominationsActions.FETCH_SCHEDULED_QUANTITIES:
      return {
        ...state,
        scheduledQuantitiesLoading: true,
        scheduledQuantitiesError: null,
        scheduledQuantities: [],
        totalScheduledQuantities: null,
      };

    case ENominationsActions.FETCH_SCHEDULED_QUANTITIES_SUCCESS:
      const scheduledQuantityLoading = {};
      const scheduledQuantityError = {};
      const scheduledQuantity = {};
      const totalScheduledQuantity = {};

      action.payload.scheduledQuantities.map(ScheduledQuantitySummary => {
        scheduledQuantityLoading[ScheduledQuantitySummary.contractId] = true;
        scheduledQuantityError[ScheduledQuantitySummary.contractId] = null;
        scheduledQuantity[ScheduledQuantitySummary.contractId] = [];
        totalScheduledQuantity[ScheduledQuantitySummary.contractId] = null;
      });

      return {
        ...state,
        scheduledQuantities: action.payload.scheduledQuantities,
        scheduledQuantitiesLoading: false,
        scheduledQuantitiesError: null,
        totalScheduledQuantities: action.payload.totalScheduledQuantitiesCount,

        scheduledQuantityLoading: scheduledQuantityLoading,
        scheduledQuantityError: scheduledQuantityError,
        scheduledQuantity: scheduledQuantity,
        totalScheduledQuantity: totalScheduledQuantity,
      };

    case ENominationsActions.FETCH_SCHEDULED_QUANTITIES_FAILURE:
      return {
        ...state,
        scheduledQuantitiesLoading: false,
        scheduledQuantitiesError: action.payload.error,
        scheduledQuantities: null,
        totalScheduledQuantities: null,
      };

    case ENominationsActions.CLEAR_SCHEDULED_QUANTITIES:
      return {
        ...state,
        scheduledQuantitiesLoading: false,
        scheduledQuantitiesError: null,
        scheduledQuantities: [],
        totalScheduledQuantities: null,

        scheduledQuantityLoading: {},
        scheduledQuantityError: {},
        scheduledQuantity: {},
        totalScheduledQuantity: {},
      };

    case ENominationsActions.EXPORT_SCHEDULED_QUANTITIES:
      return {
        ...state,
        exportScheduledQuantitiesLoading: true,
        exportScheduledQuantitiesError: null,
      };

    case ENominationsActions.EXPORT_SCHEDULED_QUANTITIES_SUCCESS:
      return {
        ...state,
        exportScheduledQuantitiesLoading: false,
        exportScheduledQuantitiesError: null,
      };

    case ENominationsActions.EXPORT_SCHEDULED_QUANTITIES_FAILURE:
      return {
        ...state,
        exportScheduledQuantitiesLoading: false,
        exportScheduledQuantitiesError: action.payload.error,
      };

    case ENominationsActions.EXPORT_OPERATOR_SCHEDULED_QUANTITIES:
      return {
        ...state,
        exportOperatorScheduledQuantitiesLoading: true,
        exportOperatorScheduledQuantitiesError: null,
      };

    case ENominationsActions.EXPORT_OPERATOR_SCHEDULED_QUANTITIES_SUCCESS:
      return {
        ...state,
        exportOperatorScheduledQuantitiesLoading: false,
        exportOperatorScheduledQuantitiesError: null,
      };

    case ENominationsActions.EXPORT_OPERATOR_SCHEDULED_QUANTITIES_FAILURE:
      return {
        ...state,
        exportOperatorScheduledQuantitiesLoading: false,
        exportOperatorScheduledQuantitiesError: action.payload.error,
      };

    case ENominationsActions.FETCH_SCHEDULED_QUANTITY: {
      return {
        ...state,
        scheduledQuantityLoading: {
          ...state.scheduledQuantityLoading,
          [action.payload.serviceRequestContractId]: true,
        },
        scheduledQuantityError: {
          ...state.scheduledQuantityError,
          [action.payload.serviceRequestContractId]: null,
        },
        scheduledQuantity: {
          ...state.scheduledQuantity,
          [action.payload.serviceRequestContractId]: [],
        },
        totalScheduledQuantity: {
          ...state.totalScheduledQuantity,
          [action.payload.serviceRequestContractId]: null,
        },
      };
    }
    case ENominationsActions.FETCH_SCHEDULED_QUANTITY_SUCCESS: {
      return {
        ...state,
        scheduledQuantityLoading: {
          ...state.scheduledQuantityLoading,
          [action.payload.contractId]: false,
        },
        scheduledQuantityError: {
          ...state.scheduledQuantityError,
          [action.payload.contractId]: null,
        },
        scheduledQuantity: {
          ...state.scheduledQuantity,
          [action.payload.contractId]: action.payload.scheduledQuantity,
        },
        totalScheduledQuantity: {
          ...state.totalScheduledQuantity,
          [action.payload.contractId]: action.payload.totalScheduledQuantityCount,
        },
      };
    }
    case ENominationsActions.FETCH_SCHEDULED_QUANTITY_FAILURE:
      return {
        ...state,
        scheduledQuantityLoading: {
          ...state.scheduledQuantityLoading,
          [action.payload.contractId]: false,
        },
        scheduledQuantityError: {
          ...state.scheduledQuantityError,
          [action.payload.contractId]: true,
        },
        scheduledQuantity: { ...state.scheduledQuantity, [action.payload.contractId]: null },
        totalScheduledQuantity: {
          ...state.totalScheduledQuantity,
          [action.payload.contractId]: null,
        },
      };
    case ENominationsActions.FETCH_FUEL_RATE:
      return {
        ...state,
        fuelRateCollection: createLoadingState(state.fuelRateCollection),
      };
    case ENominationsActions.FETCH_FUEL_RATE_SUCCESS:
      return {
        ...state,
        fuelRateCollection: createSuccessState(action.payload),
      };
    case ENominationsActions.FETCH_FUEL_RATE_FAILURE:
      return {
        ...state,
        fuelRateCollection: createErrorState(action.payload.error),
      };
    case ENominationsActions.CLEAR_FUEL_RATE:
      return {
        ...state,
        fuelRateCollection: createSuccessState(null),
      };

    case ENominationsActions.FETCH_ORDER_MANAGEMENT_DICTIONARY:
      return {
        ...state,
        dictionary: null,
        error: null,
        loading: true,
      };

    case ENominationsActions.FETCH_ORDER_MANAGEMENT_DICTIONARY_SUCCESS:
      return {
        ...state,
        dictionary: action.payload.dictionary,
        error: null,
        loading: false,
      };

    case ENominationsActions.FETCH_ORDER_MANAGEMENT_DICTIONARY_FAILURE:
      return {
        ...state,
        dictionary: null,
        error: action.payload.error,
        loading: false,
      };

    case ENominationsActions.FETCH_NOMINATION_CYCLES:
      return {
        ...state,
        error: null,
        loading: true,
        loadingCycles: true,
        nominationCycles: [],
      };

    case ENominationsActions.FETCH_NOMINATION_CYCLES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loadingCycles: false,
        nominationCycles: action.payload.cycles,
      };

    case ENominationsActions.FETCH_NOMINATION_CYCLES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loadingCycles: false,
        nominationCycles: [],
      };

    case ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITIES:
      return {
        ...state,
        operatorScheduledQuantities: [],
        operatorScheduledQuantitiesLoading: true,
        operatorScheduledQuantitiesError: null,
        operatorTotalScheduledQuantities: 0,
      };

    case ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITIES_SUCCESS:
      return {
        ...state,
        operatorScheduledQuantities: action.payload.operatorScheduledQuantities,
        operatorScheduledQuantitiesLoading: false,
        operatorScheduledQuantitiesError: null,
        operatorTotalScheduledQuantities: action.payload.total,
      };

    case ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITIES_FAILURE:
      return {
        ...state,
        operatorScheduledQuantities: null,
        operatorScheduledQuantitiesLoading: false,
        operatorScheduledQuantitiesError: action.payload.error,
        operatorTotalScheduledQuantities: 0,
      };

    case ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITY:
      return {
        ...state,
        operatorScheduledQuantity: null,
        operatorScheduledQuantityLoading: true,
        operatorScheduledQuantityError: null,
      };

    case ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITY_SUCCESS:
      return {
        ...state,
        operatorScheduledQuantity: action.payload.operatorScheduledQuantities[0],
        operatorScheduledQuantityLoading: false,
        operatorScheduledQuantityError: null,
      };

    case ENominationsActions.FETCH_OPERATOR_SCHEDULED_QUANTITY_FAILURE:
      return {
        ...state,
        operatorScheduledQuantity: null,
        operatorScheduledQuantityLoading: false,
        operatorScheduledQuantityError: action.payload.error,
      };

    case ENominationsActions.CLEAR_OPERATOR_SCHEDULED_QUANTITY:
      return {
        ...state,
        operatorScheduledQuantity: null,
        operatorScheduledQuantityLoading: false,
        operatorScheduledQuantityError: null,
      };

    case ENominationsActions.SET_OPERATOR_SCHEDULED_QUANTITY_FILTER:
      return {
        ...state,
        operatorScheduledQuantityFilterValues: {
          recipient: action.payload.recipient,
          beginDate: action.payload.beginDate,
          endDate: action.payload.endDate,
          cycle: action.payload.cycle,
          locationId: action.payload.locationId,
        },
      };

    case ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE:
      return {
        ...state,
        createCycleOverrideLoading: true,
        createCycleOverrideSuccess: false,
      };

    case ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE_SUCCESS:
      // Return an updated cycle if we have one, otherwise keep existing cycle
      const updatedCycles = state.nominationCycles.map(
        cycle => action.payload.cycles.find(updatedCycle => updatedCycle.id === cycle.id) || cycle
      );

      return {
        ...state,
        nominationCycles: updatedCycles,
        createCycleOverrideLoading: false,
        createCycleOverrideSuccess: true,
      };

    case ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE_FAILURE:
      return {
        ...state,
        createCycleOverrideLoading: false,
        createCycleOverrideSuccess: false,
        error: action.payload,
      };

    case ENominationsActions.CREATE_NOMINATION_CYCLES_OVERRIDE_RESET:
      return {
        ...state,
        createCycleOverrideLoading: false,
        createCycleOverrideSuccess: false,
        error: null,
      };

    case ENominationsActions.FETCH_CURRENT_GAS_DAY:
      return {
        ...state,
        gasDay: null,
        gasDayError: null,
      };

    case ENominationsActions.FETCH_CURRENT_GAS_DAY_SUCCESS:
      return {
        ...state,
        gasDay: action.payload.gasDay,
        gasDayError: null,
      };

    case ENominationsActions.FETCH_CURRENT_GAS_DAY_FAILURE:
      return {
        ...state,
        gasDay: null,
        gasDayError: action.payload.error,
      };

    case ENominationsActions.FETCH_TRANSACTION_TYPES:
      return {
        ...state,
        transactionTypes: createLoadingState(state.transactionTypes),
      };

    case ENominationsActions.FETCH_TRANSACTION_TYPES_SUCCESS:
      return {
        ...state,
        transactionTypes: createSuccessState(action.payload),
      };

    case ENominationsActions.FETCH_TRANSACTION_TYPES_FAILURE:
      return {
        ...state,
        transactionTypes: createErrorState(action.payload.error),
      };

    case ENominationsActions.FETCH_NOMINATION_SUMMARY:
      return {
        ...state,
        nominationSummary: createLoadingState(state.nominationSummary),
      };

    case ENominationsActions.FETCH_NOMINATION_SUMMARY_SUCCESS:
      return {
        ...state,
        nominationSummary: createSuccessState(action.payload),
      };

    case ENominationsActions.FETCH_NOMINATION_SUMMARY_FAILURE:
      return {
        ...state,
        nominationSummary: createErrorState(action.payload.error),
      };

    case ENominationsActions.CLEAR_NOMINATION_SUMMARY:
      return {
        ...state,
        nominationSummary: createSuccessState(null),
      };

    case ENominationsActions.FETCH_REDUCTION_REASONS:
      return {
        ...state,
        reductionReasons: createLoadingState(state.reductionReasons),
      };

    case ENominationsActions.FETCH_REDUCTION_REASONS_SUCCESS:
      return {
        ...state,
        reductionReasons: createSuccessState(action.payload),
      };

    case ENominationsActions.FETCH_REDUCTION_REASONS_FAILURE:
      return {
        ...state,
        reductionReasons: createErrorState(action.payload.error),
      };

    case ENominationsActions.FETCH_TSP_DETAILS:
      if (useCache(state.tspDetails, action)) return state;

      return {
        ...state,
        tspDetails: createLoadingState(state.tspDetails),
      };

    case ENominationsActions.FETCH_TSP_DETAILS_SUCCESS:
      return {
        ...state,
        tspDetails: createCacheableSuccessState(action.payload),
      };

    case ENominationsActions.FETCH_TSP_DETAILS_FAILURE:
      return {
        ...state,
        tspDetails: createErrorState(action.payload.error),
      };

    default:
      return state;
  }
}
