import { UntypedFormGroup } from '@angular/forms';
import {
  ChainMaxAvailableResultsCollection,
  ChainPathLimitsResultsCollection,
  KPathLimitsResultsCollection,
  NominationDetailResultsCollection,
  PLComponentResultsCollection,
  PriorityOfServiceLookup,
  ResultsOverview,
  RunRequest,
  RunResults,
  RunResultsCollection,
  SchedulingEngineOverrideCollection,
  SchedulingOrderSet,
  Template,
} from '@gms/scheduling-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { RetryableAction } from '../retry/retry.actions';
import { SchedulingConstrainedAreaPostingDetails } from '@gms/reporting-api';

export enum ESchedulingActions {
  FETCH_SCHEDULING_CONFIGURATIONS = '[SCHEDULING] Fetch Scheduling Configurations',
  FETCH_SCHEDULING_CONFIGURATIONS_SUCCESS = '[SCHEDULING] Fetch Scheduling Configurations Success',
  FETCH_SCHEDULING_CONFIGURATIONS_ERROR = '[SCHEDULING] Fetch Scheduling Configurations Error',

  FETCH_RUN_RESULTS = '[SCHEDULING] Fetch Runs',
  FETCH_RUN_RESULTS_SUCCESS = '[SCHEDULING] Fetch Runs Success',
  FETCH_RUN_RESULTS_ERROR = '[SCHEDULING] Fetch Runs Error',
  FETCH_RUN_RESULTS_RESET = '[SCHEDULING] Fetch Runs reset',

  FETCH_RUN_RESULTS_VALIDATION_LOOKUP = '[SCHEDULING] Fetch Runs for Validation Lookup',
  FETCH_RUN_RESULTS_VALIDATION_LOOKUP_SUCCESS = '[SCHEDULING] Fetch Runs for Validation Lookup Success',
  FETCH_RUN_RESULTS_VALIDATION_LOOKUP_ERROR = '[SCHEDULING] Fetch Runs for Validation Lookup Error',
  CLEAR_RUN_RESULTS_VALIDATION_LOOKUP = '[SCHEDULING] Clear Runs for Validation Lookup',

  FETCH_RUN_RESULTS_NAESB_CYCLE = '[SCHEDULING] Fetch Runs for NAESB Cycle',
  FETCH_RUN_RESULTS_NAESB_CYCLE_SUCCESS = '[SCHEDULING] Fetch Runs for NAESB Cycle Success',
  FETCH_RUN_RESULTS_NAESB_CYCLE_ERROR = '[SCHEDULING] Fetch Runs for NAESB Cycle Error',
  CLEAR_RUN_RESULTS_NAESB_CYCLE = '[SCHEDULING] Clear Runs for NAESB Cycle',

  RUN_SCHEDULING_ENGINE = '[SCHEDULING] Run the Scheduling Engine',
  RUN_SCHEDULING_ENGINE_SUCCESS = '[SCHEDULING] Run the Scheduling Engine Success',
  RUN_SCHEDULING_ENGINE_ERROR = '[SCHEDULING] Run the Scheduling Engine Error',
  RUN_SCHEDULING_ENGINE_RESET = '[SCHEDULING] Run the Scheduling Engine Reset',

  GET_SCHEDULING_ENGINE_TEMPLATE = '[SCHEDULING] Get the Scheduling Template Engine',
  GET_SCHEDULING_ENGINE_TEMPLATE_SUCCESS = '[SCHEDULING] Get the Scheduling Engine Template Success',
  GET_SCHEDULING_ENGINE_TEMPLATE_ERROR = '[SCHEDULING] Get the Scheduling Engine Template Error',
  GET_SCHEDULING_ENGINE_TEMPLATE_RESET = '[SCHEDULING] Get the Scheduling Engine Template Reset',

  GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW = '[SCHEDULING] Get the Scheduling Engine run results for overview',
  GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_SUCCESS = '[SCHEDULING] Get the Scheduling Engine run results for overview Success',
  GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_ERROR = '[SCHEDULING] Get the Scheduling Engine run results for overview Error',
  GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_RESET = '[SCHEDULING] Get the Scheduling Engine run results for overview Reset',

  GET_RUN_RESULTS_NOM_DETAIL = '[Scheduling] get run results nom detail',
  GET_RUN_RESULTS_NOM_DETAIL_SUCCESS = '[SCHEDULING] get run results nom detail success',
  GET_RUN_RESULTS_NOM_DETAIL_ERROR = '[SCHEDULING] get run results nom detail error',

  GET_RUN_RESULTS_KPATH_LIMITS = '[Scheduling] get run results k path limits',
  GET_RUN_RESULTS_KPATH_LIMITS_SUCCESS = '[Scheduling] get run results k path limits success',
  GET_RUN_RESULTS_KPATH_LIMITS_ERROR = '[Scheduling] get run results k path limits error',

  GET_RUN_RESULTS_CHAIN_PATH_LIMITS = '[SCHEDULING] get contract path limits',
  GET_RUN_RESULTS_CHAIN_PATH_LIMITS_SUCCESS = '[SCHEDULING] get contract path limits Success',
  GET_RUN_RESULTS_CHAIN_PATH_LIMITS_ERROR = '[SCHEDULING] get contract path limits Error',

  GET_RUN_RESULTS_PL_COMPONENT = '[Scheduling] get run results p/l component',
  GET_RUN_RESULTS_PL_COMPONENT_SUCCESS = '[SCHEDULING] get run results p/l component success',
  GET_RUN_RESULTS_PL_COMPONENT_ERROR = '[SCHEDULING] get run results p/l component error',

  EXPORT_PL_COMPONENT = '[Scheduling] Export run results p/l component',
  EXPORT_PL_COMPONENT_SUCCESS = '[SCHEDULING] Export run results p/l component success',
  EXPORT_PL_COMPONENT_ERROR = '[SCHEDULING] Export run results p/l component error',

  GET_RUN_RESULTS_MAX_PATH_LIMITS = '[SCHEDULING] get contract path Max',
  GET_RUN_RESULTS_MAX_PATH_LIMITS_SUCCESS = '[SCHEDULING] get contract path Max Success',
  GET_RUN_RESULTS_MAX_PATH_LIMITS_ERROR = '[SCHEDULING] get contract path Max Error',

  UPDATE_SCHEDULING_PAGE_INFORMATION = '[SCHEDULING] Update Scheduling page information',

  GET_RUN_RESULTS_COMMIT = '[SCHEDULING] Commit run results',
  GET_RUN_RESULTS_COMMIT_SUCCESS = '[SCHEDULING] Commit run results Success',
  GET_RUN_RESULTS_COMMIT_ERROR = '[SCHEDULING] Commit run results Error',
  GET_RUN_RESULTS_COMMIT_RESET = '[SCHEDULING] Commit run results reset',
  CREATE_SE_OVERRIDE = '[SCHEDULING] Create Scheduling Engine Override',
  CREATE_SE_OVERRIDE_SUCCESS = '[SCHEDULING] Create Scheduling Engine Override Success',
  CREATE_SE_OVERRIDE_ERROR = '[SCHEDULING] Create Scheduling Engine Override Error',
  CLEAR_SE_OVERRIDE = '[SCHEDULING] Clear Scheduling Engine Override',

  FETCH_BLOCK_MATCHING = '[SCHEDULING] Fetch Block Matching',
  FETCH_BLOCK_MATCHING_SUCCESS = '[SCHEDULING] Fetch Block Matching Success',
  FETCH_BLOCK_MATCHING_FAILURE = '[SCHEDULING] Fetch Block Matching Failure',

  EXPORT_BLOCK_MATCHING = 'SCHEDULING] Export Block Matching',
  EXPORT_BLOCK_MATCHING_SUCCESS = 'SCHEDULING] Export Block Matching Success',
  EXPORT_BLOCK_MATCHING_ERROR = 'SCHEDULING] Export Block Matching Failure',

  FETCH_PRIORITY_OF_SERVICE_RESULTS = '[SCHEDULING] Fetch Priority of Service Results',
  FETCH_PRIORITY_OF_SERVICE_RESULTS_SUCCESS = '[SCHEDULING] Fetch Priority of Service Results Success',
  FETCH_PRIORITY_OF_SERVICE_RESULTS_FAILURE = '[SCHEDULING] Fetch Priority of Service Results Failure',

  GET_SCHEDULING_ORDER_SETS = '[SCHEDULING] Get Scheduling Order Sets',
  GET_SCHEDULING_ORDER_SETS_SUCCESS = '[SCHEDULING] Get Scheduling Order Sets Success',
  GET_SCHEDULING_ORDER_SETS_ERROR = '[SCHEDULING] Get Scheduling Order Sets Failure',
  CLEAR_SCHEDULING_ORDER_SETS = '[SCHEDULING] Clear Scheduling Order Sets',

  GET_SCHEDULING_ORDER_SET = '[SCHEDULING] Get Scheduling Order Set',
  GET_SCHEDULING_ORDER_SET_SUCCESS = '[SCHEDULING] Get Scheduling Order Set Success',
  GET_SCHEDULING_ORDER_SET_ERROR = '[SCHEDULING] Get Scheduling Order Set Failure',

  GET_SCHEDULING_ORDER_SET_LIVE = '[SCHEDULING] Get Scheduling Order Set Live',
  GET_SCHEDULING_ORDER_SET_LIVE_SUCCESS = '[SCHEDULING] Get Scheduling Order Set Live Success',
  GET_SCHEDULING_ORDER_SET_LIVE_ERROR = '[SCHEDULING] Get Scheduling Order Set Live Failure',

  CREATE_SCHEDULING_ORDER_SET = '[SCHEDULING] Create Scheduling Order Set',
  CREATE_SCHEDULING_ORDER_SET_SUCCESS = '[SCHEDULING] Create Scheduling Order Set Success',
  CREATE_SCHEDULING_ORDER_SET_ERROR = '[SCHEDULING] Create Scheduling Order Set Failure',
  CREATE_SCHEDULING_ORDER_SET_CLEAR_ERRORS = '[SCHEDULING] Clear Create Scheduling Order Set Errors',

  UPDATE_SCHEDULING_ORDER_SET = '[SCHEDULING] Update Scheduling Order Set',
  UPDATE_SCHEDULING_ORDER_SET_SUCCESS = '[SCHEDULING] Update Scheduling Order Set Success',
  UPDATE_SCHEDULING_ORDER_SET_ERROR = '[SCHEDULING] Update Scheduling Order Set Failure',
  UPDATE_SCHEDULING_ORDER_SET_CLEAR_ERRORS = '[SCHEDULING] Clear Update Scheduling Order Set Errors',

  DELETE_SCHEDULING_ORDER_SET = '[SCHEDULING] Delete Scheduling Order Set',
  DELETE_SCHEDULING_ORDER_SET_SUCCESS = '[SCHEDULING] Delete Scheduling Order Set Success',
  DELETE_SCHEDULING_ORDER_SET_ERROR = '[SCHEDULING] Delete Scheduling Order Set Failure',
  DELETE_SCHEDULING_ORDER_SET_CLEAR_ERRORS = '[SCHEDULING] Clear Delete Scheduling Order Set Errors',

  FETCH_SCHEDULING_GROUP_ITEM_LIST = '[SCHEDULING] Fetch Scheduling Group Item List',
  FETCH_SCHEDULING_GROUP_ITEM_SUCCESS = '[SCHEDULING] Fetch Scheduling Group Item List success',
  FETCH_SCHEDULING_GROUP_ITEM_FAILURE = '[SCHEDULING] Fetch Scheduling Group Item List failure',

  CREATE_SCHEDULING_GROUP_RECORD = '[SCHEDULING] Create Scheduling Group Record',
  CREATE_SCHEDULING_GROUP_RECORD_SUCCESS = '[SCHEDULING] Create Scheduling Group Record success',
  CREATE_SCHEDULING_GROUP_RECORD_FAILURE = '[SCHEDULING] Create Scheduling Group Record failure',
}

// --------------------------------------------------
// INTERFACES
// --------------------------------------------------
export interface ISchedulingConfigurationsPayload {
  tspId: number;
  runType: 'P' | 'I' | 'F';
}

export interface ISchedulingOrderSetsPayload {
  tspId: number;
}

export interface ISchedulingOrderSetPayload {
  schedulingOrderSetId: number;
}

export interface ISchedulingOrderSetLivePayload {
  tspId: number;
}

export interface ICreateSchedulingOrderSetPayload {
  schedulingOrderSet: SchedulingOrderSet;
}

export interface IRunResultsPayload {
  cycleCode: string;
  runType: 'All' | 'Preliminary' | 'Intermediate' | 'Final';
  date?: any;
  dateType?: 'RunDate' | 'GasDate';
  tspId: number;
}

export interface IPriorityOfServiceResultsPayload {
  tspId: number;
  smpFlag: boolean;
}

export interface IKPathLimitsResultsPayload {
  seRunId: number;
  locationNumbers?: Array<number>;
  schedulingGroups?: Array<string>;
  flowDirection?: 'R' | 'D' | 'B' | 'N';
  locationClassification?: string;
  entityId?: Array<number>;
  serviceRequestContractIds?: Array<number>;
  onlyShowReductions?: boolean;
  rateSchedule?: string;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: Array<SortDescriptor>;
}

export interface IMaxLimitsResultsPayload {
  seRunId: number;
  locationNumbers?: Array<number>;
  schedulingGroups?: Array<string>;
  flowDirection?: 'R' | 'D' | 'B' | 'N';
  locationClassification?: string;
  entityId?: Array<number>;
  serviceRequestContractIds?: Array<number>;
  onlyShowReductions?: boolean;
  rateSchedule?: string;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: Array<SortDescriptor>;
}

export interface IChainPathLimitsResultsPayload {
  seRunId: number;
  locationNumbers?: Array<number>;
  schedulingGroups?: Array<string>;
  flowDirection?: 'R' | 'D' | 'B' | 'N';
  locationClassification?: string;
  entityId?: Array<number>;
  serviceRequestContractIds?: Array<number>;
  onlyShowReductions?: boolean;
  rateSchedule?: string;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: Array<SortDescriptor>;
}

export interface IRunResultsNomDetailPayload {
  seRunId: number;
  locationNumbers?: Array<number>;
  schedulingGroups?: Array<string>;
  flowDirection?: 'R' | 'D' | 'B' | 'N';
  locationClassification?: string;
  entityId?: Array<number>;
  serviceRequestContractIds?: Array<number>;
  onlyShowReductions?: boolean;
  transactionTypeCode?: string;
  tspStepDisplayOrderNumbers?: Array<number>;
  pageSize?: number;
  pageNumber?: number;
}

export interface SchedulingTemplate {
  cycleType: 'ENS' | 'NAESB';
  runTypeId: number;
}

export interface IRunResultsPLComponentPayload {
  seRunId: number;
  locationNumbers?: Array<number>;
  schedulingGroups?: Array<string>;
  flowDirection?: 'R' | 'D' | 'B' | 'N';
  locationClassification?: string;
  entityId?: Array<number>;
  serviceRequestContractIds?: Array<number>;
  onlyShowReductions?: boolean;
  componentType?: 'Loc' | 'Seg';
  viewBy?: 'CapacityIssues' | 'NotConfirmed' | 'AvailableCapacity';
  pageSize?: number;
  pageNumber?: number;

  GasDayOnDatSet?: Date;
  cycle?: any;
}

export interface SchedulingGroupPayload{
  tspId: number,
  gasFlowDate: Date
}

// --------------------------------------------------
// FETCH RUN RESULTS
// --------------------------------------------------
export class FetchRunResults implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS;

  constructor(public payload: IRunResultsPayload, public userInitiated: boolean) {}
}

export class FetchRunResultsSuccess implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_SUCCESS;

  constructor(
    public payload: {
      runResults: Array<RunResults>;
      total: number;
    }
  ) {}
}

export class FetchRunResultsError implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class FetchRunResultsReset implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_RESET;

  constructor() {}
}

// --------------------------------------------------
// FETCH PRIORITY OF SERVICE RESULTS
// --------------------------------------------------
export class FetchPriorityOfServiceResults extends RetryableAction implements Action {
  public readonly type = ESchedulingActions.FETCH_PRIORITY_OF_SERVICE_RESULTS;

  constructor(public payload: IPriorityOfServiceResultsPayload) {
    super(payload);
  }
}

export class FetchPriorityOfServiceResultsSuccess implements Action {
  public readonly type = ESchedulingActions.FETCH_PRIORITY_OF_SERVICE_RESULTS_SUCCESS;

  constructor(public payload: PriorityOfServiceLookup[]) {}
}

export class FetchPriorityOfServiceResultsFailure implements Action {
  public readonly type = ESchedulingActions.FETCH_PRIORITY_OF_SERVICE_RESULTS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// FETCH RUN RESULTS VALIDATION LOOKUP
// --------------------------------------------------
export class FetchRunResultsValidationLookup implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_VALIDATION_LOOKUP;

  constructor(public payload: IRunResultsPayload) {}
}

export class FetchRunResultsValidationLookupSuccess implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_VALIDATION_LOOKUP_SUCCESS;

  constructor(public payload: RunResultsCollection) {}
}

export class FetchRunResultsValidationLookupError implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_VALIDATION_LOOKUP_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearRunResultsValidationLookup implements Action {
  public readonly type = ESchedulingActions.CLEAR_RUN_RESULTS_VALIDATION_LOOKUP;

  constructor() {}
}

export class FetchRunResultsNAESBCycle implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_NAESB_CYCLE;

  constructor(public payload: IRunResultsPayload) {}
}

export class FetchRunResultsNAESBCycleSuccess implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_NAESB_CYCLE_SUCCESS;

  constructor(public payload: RunResultsCollection) {}
}

export class FetchRunResultsNAESBCycleError implements Action {
  public readonly type = ESchedulingActions.FETCH_RUN_RESULTS_NAESB_CYCLE_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearRunResultsNAESBCycle implements Action {
  public readonly type = ESchedulingActions.CLEAR_RUN_RESULTS_NAESB_CYCLE;

  constructor() {}
}

// --------------------------------------------------
// RUN SCHEDULING ENGINE
// --------------------------------------------------
export class RunSchedulingEngine implements Action {
  public readonly type = ESchedulingActions.RUN_SCHEDULING_ENGINE;

  constructor(public payload: RunRequest) {}
}

export class RunSchedulingEngineSuccess implements Action {
  public readonly type = ESchedulingActions.RUN_SCHEDULING_ENGINE_SUCCESS;

  constructor() {}
}

export class RunSchedulingEngineError implements Action {
  public readonly type = ESchedulingActions.RUN_SCHEDULING_ENGINE_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class RunSchedulingEngineReset implements Action {
  public readonly type = ESchedulingActions.RUN_SCHEDULING_ENGINE_RESET;

  constructor() {}
}

// --------------------------------------------------
// SCHEDULING ENGINE TEMPLATE
// --------------------------------------------------
export class GetSchedulingEngineTemplate implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE;

  constructor(public payload: SchedulingTemplate) {}
}

export class GetSchedulingEngineTemplateSuccess implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE_SUCCESS;

  constructor(public payload: Template) {}
}

export class GetSchedulingEngineTemplateError implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class GetSchedulingEngineTemplateReset implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_TEMPLATE_RESET;

  constructor() {}
}

// --------------------------------------------------
// RUN RESULTS NOM DETAIL
// --------------------------------------------------
export class GetRunResultsNomDetail implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_NOM_DETAIL;

  constructor(public payload: IRunResultsNomDetailPayload) {}
}

export class GetRunResultsNomDetailSuccess implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_NOM_DETAIL_SUCCESS;

  constructor(public payload: NominationDetailResultsCollection) {}
}

export class GetRunResultsNomDetailError implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_NOM_DETAIL_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// RUN RESULTS OVERVIEW
// --------------------------------------------------
export class GetSchedulingEngineRunResultsOverview implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW;

  constructor(public payload: number) {}
}

export class GetSchedulingEngineRunResultsOverviewSuccess implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_SUCCESS;

  constructor(public payload: ResultsOverview) {}
}

export class GetSchedulingEngineRunResultsOverviewError implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class GetSchedulingEngineRunResultsOverviewReset implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ENGINE_RUN_RESULTS_OVERVIEW_RESET;

  constructor() {}
}

// --------------------------------------------------
// RUN RESULTS K-PATH LIMITS
// --------------------------------------------------
export class GetSchedulingEngineRunResultsKPathLimits implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_KPATH_LIMITS;

  constructor(public payload: IKPathLimitsResultsPayload) {}
}

export class GetSchedulingEngineRunResultsKPathLimitsSuccess implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_KPATH_LIMITS_SUCCESS;

  constructor(public payload: KPathLimitsResultsCollection) {}
}

export class GetSchedulingEngineRunResultsKPathLimitsError implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_KPATH_LIMITS_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// RUN RESULTS CHAIN PATH LIMITS
// --------------------------------------------------
export class GetSchedulingEngineRunResultsChainPathLimits implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_CHAIN_PATH_LIMITS;

  constructor(public payload: IChainPathLimitsResultsPayload) {}
}

export class GetSchedulingEngineRunResultsChainPathLimitsSuccess implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_CHAIN_PATH_LIMITS_SUCCESS;

  constructor(public payload: ChainPathLimitsResultsCollection) {}
}

export class GetSchedulingEngineRunResultsChainPathLimitsError implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_CHAIN_PATH_LIMITS_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// RUN RESULTS P/L COMPONENT
// --------------------------------------------------
export class GetSchedulingEngineRunResultsPLComponent implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_PL_COMPONENT;

  constructor(public payload: IRunResultsPLComponentPayload) {}
}

export class GetSchedulingEngineRunResultsPLComponentSuccess implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_PL_COMPONENT_SUCCESS;

  constructor(public payload: PLComponentResultsCollection) {}
}

export class GetSchedulingEngineRunResultsPLComponentError implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_PL_COMPONENT_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// EXPORT PL/COMPONENT
// --------------------------------------------------
export class ExportPLComponent implements Action {
  public readonly type = ESchedulingActions.EXPORT_PL_COMPONENT;

  constructor(public payload: IRunResultsPLComponentPayload) {}
}

export class ExportPLComponentSuccess implements Action {
  public readonly type = ESchedulingActions.EXPORT_PL_COMPONENT_SUCCESS;

  constructor() {}
}

export class ExportPLComponentError implements Action {
  public readonly type = ESchedulingActions.EXPORT_PL_COMPONENT_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// RUN RESULTS MAX PATH LIMITS
// --------------------------------------------------
export class GetSchedulingEngineRunResultsMaxPathLimits implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_MAX_PATH_LIMITS;

  constructor(public payload: IMaxLimitsResultsPayload) {}
}

export class GetSchedulingEngineRunResultsMaxPathLimitsSuccess implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_MAX_PATH_LIMITS_SUCCESS;

  constructor(public payload: ChainMaxAvailableResultsCollection) {}
}

export class GetSchedulingEngineRunResultsMaxPathLimitsError implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_MAX_PATH_LIMITS_ERROR;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// RUN RESULTS AND FORM
// --------------------------------------------------
export class UpdateRunResultsAndForm implements Action {
  public readonly type = ESchedulingActions.UPDATE_SCHEDULING_PAGE_INFORMATION;

  constructor(public payload: { runResults: RunResults; actionBar: UntypedFormGroup }) {}
}

// --------------------------------------------------
// RUN RESULTS Commit
// --------------------------------------------------
export class GetRunResultsCommit implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_COMMIT;

  constructor(public payload: { seRunId: number; validationOnly?: boolean }) {}
}

export class GetRunResultsCommitSuccess implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_COMMIT_SUCCESS;

  constructor(public payload: boolean) {}
}

export class GetRunResultsCommitError implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_COMMIT_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class GetRunResultsReset implements Action {
  public readonly type = ESchedulingActions.GET_RUN_RESULTS_COMMIT_RESET;

  constructor() {}
}

export class CreateSEOverride implements Action {
  public readonly type = ESchedulingActions.CREATE_SE_OVERRIDE;

  constructor(public payload: SchedulingEngineOverrideCollection) {}
}

export class CreateSEOverrideSuccess implements Action {
  public readonly type = ESchedulingActions.CREATE_SE_OVERRIDE_SUCCESS;

  constructor(public payload: SchedulingEngineOverrideCollection) {}
}

export class CreateSEOverrideError implements Action {
  public readonly type = ESchedulingActions.CREATE_SE_OVERRIDE_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearSEOverride implements Action {
  public readonly type = ESchedulingActions.CLEAR_SE_OVERRIDE;
}

// --------------------------------------------------
// BlockMatching
// --------------------------------------------------

export interface IExportBlockMatching {
  seRunId: number;
  serviceRequesterId?: number;
  gasDay: string; // for filename.
}
export class ExportBlockMatching implements Action {
  public readonly type = ESchedulingActions.EXPORT_BLOCK_MATCHING;

  constructor(public payload: IExportBlockMatching) {}
}

export class ExportBlockMatchingSuccess implements Action {
  public readonly type = ESchedulingActions.EXPORT_BLOCK_MATCHING_SUCCESS;

  // constructor(public payload: PLComponentResultsCollection) {}
}

export class ExportBlockMatchingError implements Action {
  public readonly type = ESchedulingActions.EXPORT_BLOCK_MATCHING_ERROR;

  constructor(public payload: { error: Error }) {}
}

export interface IFetchBlockMatching {
  seRunId: number;
  serviceRequesterId?: number;
  pageSize?: number;
  pageNumber?: number;
}
export class FetchBlockMatching implements Action {
  public readonly type = ESchedulingActions.FETCH_BLOCK_MATCHING;

  constructor(public payload: IFetchBlockMatching) {}
}

export class FetchBlockMatchingSuccess implements Action {
  public readonly type = ESchedulingActions.FETCH_BLOCK_MATCHING_SUCCESS;

  // TODO: update the payload interface on integration.
  constructor(public payload: any) {}
}

export class FetchBlockMatchingFailure implements Action {
  public readonly type = ESchedulingActions.FETCH_BLOCK_MATCHING_FAILURE;

  constructor(public payload: { error: Error }) {}
}

// --------------------------------------------------
// SCHEDULING ORDER SETS
// --------------------------------------------------
export class GetSchedulingOrderSets extends RetryableAction implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SETS;

  constructor(public payload: ISchedulingOrderSetsPayload) {
    super(payload);
  }
}

export class GetSchedulingOrderSetsSuccess implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SETS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetSchedulingOrderSetsError implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SETS_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class ClearSchedulingOrderSets implements Action {
  public readonly type = ESchedulingActions.CLEAR_SCHEDULING_ORDER_SETS;
}

// --------------------------------------------------
// SCHEDULING ORDER SET
// --------------------------------------------------
export class GetSchedulingOrderSet extends RetryableAction implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SET;

  constructor(public payload: ISchedulingOrderSetPayload) {
    super(payload);
  }
}

export class GetSchedulingOrderSetSuccess implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SET_SUCCESS;

  constructor(public payload: any) {}
}

export class GetSchedulingOrderSetError implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SET_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class GetSchedulingOrderSetLive extends RetryableAction implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SET_LIVE;

  constructor(public payload: ISchedulingOrderSetLivePayload) {
    super(payload);
  }
}

export class GetSchedulingOrderSetLiveSuccess implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SET_LIVE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetSchedulingOrderSetLiveError implements Action {
  public readonly type = ESchedulingActions.GET_SCHEDULING_ORDER_SET_LIVE_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class CreateSchedulingOrderSet extends RetryableAction implements Action {
  public readonly type = ESchedulingActions.CREATE_SCHEDULING_ORDER_SET;

  constructor(public payload: ICreateSchedulingOrderSetPayload) {
    super(payload);
  }
}

export class CreateSchedulingOrderSetSuccess implements Action {
  public readonly type = ESchedulingActions.CREATE_SCHEDULING_ORDER_SET_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateSchedulingOrderSetError implements Action {
  public readonly type = ESchedulingActions.CREATE_SCHEDULING_ORDER_SET_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class CreateSchedulingOrderSetClearErrors implements Action {
  public readonly type = ESchedulingActions.CREATE_SCHEDULING_ORDER_SET_CLEAR_ERRORS;

  constructor(public payload: any) {}
}

export class UpdateSchedulingOrderSet extends RetryableAction implements Action {
  public readonly type = ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET;

  constructor(public payload: ICreateSchedulingOrderSetPayload) {
    super(payload);
  }
}

export class UpdateSchedulingOrderSetSuccess implements Action {
  public readonly type = ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateSchedulingOrderSetError implements Action {
  public readonly type = ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class UpdateSchedulingOrderSetClearErrors implements Action {
  public readonly type = ESchedulingActions.UPDATE_SCHEDULING_ORDER_SET_CLEAR_ERRORS;

  constructor(public payload: any) {}
}

export class DeleteSchedulingOrderSet extends RetryableAction implements Action {
  public readonly type = ESchedulingActions.DELETE_SCHEDULING_ORDER_SET;

  constructor(public payload: ISchedulingOrderSetPayload) {
    super(payload);
  }
}

export class DeleteSchedulingOrderSetSuccess implements Action {
  public readonly type = ESchedulingActions.DELETE_SCHEDULING_ORDER_SET_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteSchedulingOrderSetError implements Action {
  public readonly type = ESchedulingActions.DELETE_SCHEDULING_ORDER_SET_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class DeleteSchedulingOrderSetClearErrors implements Action {
  public readonly type = ESchedulingActions.DELETE_SCHEDULING_ORDER_SET_CLEAR_ERRORS;

  constructor(public payload: any) {}
}

export class FetchSchedulingGroupItemsAction implements Action {
  public readonly type = ESchedulingActions.FETCH_SCHEDULING_GROUP_ITEM_LIST;

  constructor(public payload: SchedulingGroupPayload) {}
}
export class FetchSchedulingGroupItemsSuccess implements Action {
  public readonly type = ESchedulingActions.FETCH_SCHEDULING_GROUP_ITEM_SUCCESS;

  constructor(public payload) {}
}
export class FetchSchedulingGroupItemsFailure implements Action {
  public readonly type = ESchedulingActions.FETCH_SCHEDULING_GROUP_ITEM_FAILURE;

  constructor(public payload: { error: Error }) {}
}
export class CreateSchedulingGroupRecordAction implements Action {
  public readonly type = ESchedulingActions.CREATE_SCHEDULING_GROUP_RECORD;

  constructor(public payload: SchedulingConstrainedAreaPostingDetails) {}
}
export class CreateSchedulingGroupRecordSuccess implements Action {
  public readonly type = ESchedulingActions.CREATE_SCHEDULING_GROUP_RECORD_SUCCESS;
  constructor(public payload: boolean) {}
}
export class CreateSchedulingGroupRecordFailure implements Action {
  public readonly type = ESchedulingActions.CREATE_SCHEDULING_GROUP_RECORD_FAILURE;

  constructor(public payload: { error: Error }) {}
}


export type SchedulingActions =
  | FetchRunResults
  | FetchRunResultsSuccess
  | FetchRunResultsError
  | FetchRunResultsReset
  | FetchPriorityOfServiceResults
  | FetchPriorityOfServiceResultsSuccess
  | FetchPriorityOfServiceResultsFailure
  | FetchRunResultsValidationLookup
  | FetchRunResultsValidationLookupSuccess
  | FetchRunResultsValidationLookupError
  | ClearRunResultsValidationLookup
  | FetchRunResultsNAESBCycle
  | FetchRunResultsNAESBCycleSuccess
  | FetchRunResultsNAESBCycleError
  | ClearRunResultsNAESBCycle
  | RunSchedulingEngine
  | RunSchedulingEngineSuccess
  | RunSchedulingEngineError
  | RunSchedulingEngineReset
  | GetSchedulingEngineTemplate
  | GetSchedulingEngineTemplateSuccess
  | GetSchedulingEngineTemplateError
  | GetSchedulingEngineTemplateReset
  | UpdateRunResultsAndForm
  | GetRunResultsNomDetail
  | GetRunResultsNomDetailSuccess
  | GetRunResultsNomDetailError
  | GetSchedulingEngineRunResultsOverview
  | GetSchedulingEngineRunResultsOverviewSuccess
  | GetSchedulingEngineRunResultsOverviewError
  | GetSchedulingEngineRunResultsOverviewReset
  | GetSchedulingEngineRunResultsKPathLimits
  | GetSchedulingEngineRunResultsKPathLimitsSuccess
  | GetSchedulingEngineRunResultsKPathLimitsError
  | GetSchedulingEngineRunResultsChainPathLimits
  | GetSchedulingEngineRunResultsChainPathLimitsSuccess
  | GetSchedulingEngineRunResultsChainPathLimitsError
  | GetSchedulingEngineRunResultsPLComponent
  | GetSchedulingEngineRunResultsPLComponentSuccess
  | GetSchedulingEngineRunResultsPLComponentError
  | GetSchedulingEngineRunResultsMaxPathLimits
  | GetSchedulingEngineRunResultsMaxPathLimitsSuccess
  | GetSchedulingEngineRunResultsMaxPathLimitsError
  | GetSchedulingEngineRunResultsChainPathLimitsError
  | GetSchedulingEngineRunResultsPLComponent
  | GetSchedulingEngineRunResultsPLComponentSuccess
  | GetSchedulingEngineRunResultsPLComponentError
  | ExportPLComponent
  | ExportPLComponentSuccess
  | ExportPLComponentError
  | GetRunResultsCommit
  | GetRunResultsCommitSuccess
  | GetRunResultsCommitError
  | GetRunResultsReset
  | GetSchedulingEngineRunResultsChainPathLimitsError
  | CreateSEOverride
  | CreateSEOverrideSuccess
  | CreateSEOverrideError
  | ClearSEOverride
  | FetchBlockMatching
  | FetchBlockMatchingSuccess
  | FetchBlockMatchingFailure
  | ExportBlockMatching
  | ExportBlockMatchingSuccess
  | ExportBlockMatchingError
  | GetSchedulingOrderSets
  | GetSchedulingOrderSetsSuccess
  | GetSchedulingOrderSetsError
  | ClearSchedulingOrderSets
  | GetSchedulingOrderSet
  | GetSchedulingOrderSetSuccess
  | GetSchedulingOrderSetError
  | GetSchedulingOrderSetLive
  | GetSchedulingOrderSetLiveSuccess
  | GetSchedulingOrderSetLiveError
  | CreateSchedulingOrderSet
  | CreateSchedulingOrderSetSuccess
  | CreateSchedulingOrderSetError
  | CreateSchedulingOrderSetClearErrors
  | UpdateSchedulingOrderSet
  | UpdateSchedulingOrderSetSuccess
  | UpdateSchedulingOrderSetError
  | UpdateSchedulingOrderSetClearErrors
  | DeleteSchedulingOrderSet
  | DeleteSchedulingOrderSetSuccess
  | DeleteSchedulingOrderSetError
  | DeleteSchedulingOrderSetClearErrors
  | FetchSchedulingGroupItemsAction
  | FetchSchedulingGroupItemsSuccess
  | FetchSchedulingGroupItemsFailure
  | CreateSchedulingGroupRecordAction
  | CreateSchedulingGroupRecordSuccess
  | CreateSchedulingGroupRecordFailure
