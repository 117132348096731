import {
  Allocation,
  AllocationDetail,
  AllocationMethod,
  LookupCollections,
  PredeterminedAllocation,
  PredeterminedAllocationDetailCollection,
  PredeterminedAllocationStatusCollection,
} from '@gms/allocation-api';
import { Action } from '@ngrx/store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { IPagination } from 'shared/models/pagination.model';

export enum EAllocationsActions {
  EXPORT_ALLOCATION_DETAILS = '[ALLOCATIONS] Export Details',
  EXPORT_ALLOCATION_DETAILS_SUCCESS = '[ALLOCATIONS] Export Details Success',
  EXPORT_ALLOCATION_DETAILS_FAILURE = '[ALLOCATIONS] Export Details Failure',
  FETCH_ALLOCATIONS = '[ALLOCATIONS] Fetch allocations',
  FETCH_ALLOCATIONS_SUCCESS = '[ALLOCATIONS] Fetch allocations Success',
  FETCH_ALLOCATIONS_FAILURE = '[ALLOCATIONS] Fetch allocations Failure',
  CLEAR_ALLOCATIONS = '[ALLOCATIONS] clear allocations',
  FETCH_ALLOCATION_DETAILS = '[ALLOCATIONS DETAILS] Fetch allocation Details',
  FETCH_ALLOCATION_DETAILS_SUCCESS = '[ALLOCATIONS DETAILS] Fetch allocation Details Success',
  FETCH_ALLOCATION_DETAILS_FAILURE = '[ALLOCATIONS DETAILS] Fetch allocation Details Failure',
  FETCH_ALLOCATIONS_METHOD = '[ALLOCATIONS] Fetch allocations method',
  FETCH_ALLOCATIONS_METHOD_SUCCESS = '[ALLOCATIONS] Fetch allocations method Success',
  FETCH_ALLOCATIONS_METHOD_FAILURE = '[ALLOCATIONS] Fetch allocations method Failure',
  CREATE_PDA = '[ALLOCATIONS] Create Pda',
  CREATE_PDA_SUCCESS = '[ALLOCATIONS] Create Pda Success',
  CREATE_PDA_FAILURE = '[ALLOCATIONS] Create Pda Failure',
  FETCH_PREDETERMINED_ALLOCATIONS = '[ALLOCATIONS] Fetch pdas',
  FETCH_PREDETERMINED_ALLOCATIONS_SUCCESS = '[ALLOCATIONS] Fetch pdas success',
  FETCH_PREDETERMINED_ALLOCATIONS_FAILURE = '[ALLOCATIONS] Fetch pdas failure',
  FETCH_PREDETERMINED_ALLOCATION_DETAILS = '[ALLOCATIONS] Fetch pda details',
  FETCH_PREDETERMINED_ALLOCATION_DETAILS_SUCCESS = '[ALLOCATIONS] Fetch pda details success',
  FETCH_PREDETERMINED_ALLOCATION_DETAILS_FAILURE = '[ALLOCATIONS] Fetch pda details failure',
  FETCH_PREDETERMINED_ALLOCATION_BY_ID = '[ALLOCATIONS] Fetch pda by ID',
  FETCH_PREDETERMINED_ALLOCATION_BY_ID_SUCCESS = '[ALLOCATIONS] Fetch pda by ID success',
  FETCH_PREDETERMINED_ALLOCATION_BY_ID_FAILURE = '[ALLOCATIONS] Fetch pda by ID failure',
  CLEAR_PDA_DETAILS = '[ALLOCATIONS] Clear pda details',
  FETCH_INCOMPLETE_PDAS = '[ALLOCATIONS] Fetch Incomplete Pdas',
  FETCH_INCOMPLETE_PDAS_SUCCESS = '[ALLOCATIONS] Fetch Incomplete Pdas Success',
  FETCH_INCOMPLETE_PDAS_FAILURE = '[ALLOCATIONS] Fetch Incomplete Pdas Failure',
  FETCH_MISSING_PDAS = '[ALLOCATIONS] Fetch Missing Pdas',
  FETCH_MISSING_PDAS_SUCCESS = '[ALLOCATIONS] Fetch Missing Pdas Success',
  FETCH_MISSING_PDAS_FAILURE = '[ALLOCATIONS] Fetch Missing Pdas Failure',
  FETCH_EXPIRED_PDAS = '[ALLOCATIONS] Fetch Expired Pdas',
  FETCH_EXPIRED_PDAS_SUCCESS = '[ALLOCATIONS] Fetch Expired Pdas Success',
  FETCH_EXPIRED_PDAS_FAILURE = '[ALLOCATIONS] Fetch Expired Pdas Failure',
  SET_CREATE_PDA_LOCATION_PARAM = '[ALLOCATIONS] Set Location Param for Create PDA',
  CLEAR_CREATE_PDA_LOCATION_PARAM = '[ALLOCATIONS] Clear Location Param for Create PDA',
  FETCH_ALLOCATION_LOOKUP = '[ALLOCATIONS] Fetch allocation lookup',
  FETCH_ALLOCATION_LOOKUP_SUCCESS = '[ALLOCATIONS] Fetch allocation lookup success',
  FETCH_ALLOCATION_LOOKUP_FAILURE = '[ALLOCATIONS] Fetch allocation lookup failure',
  CLEAR_PDAS = '[ALLOCATIONS] Clear pdas',
}

export interface IAllocationsPayload {
  operatorId: number;
  dateBegin: Date;
  dateEnd?: Date;
  tspId?: number;
  confirmingEntityId?: number;
  accountingPeriod?: string;
  locationId?: number;
  pageSize?: number;
  pageNumber?: number;
  sortDescriptors?: SortDescriptor[];
}

export interface IAllocationDetailsPayload {
  locationId: number;
  confirmingEntityId: number;
  dateBegin: string;
  dateEnd: string;
  accountingPeriod?: string;
  sortDescriptors?: SortDescriptor[];
  pageSize?: number;
  pageNumber?: number;
  viewBy?: 'ContractSummary' | 'ContractDetail' | 'LocationDetail'; //TODO: should this be an enum?
}

export class ExportAllocationDetails implements Action {
  public readonly type = EAllocationsActions.EXPORT_ALLOCATION_DETAILS;

  constructor(public payload: IAllocationDetailsPayload) {}
}

export class ExportAllocationDetailsSuccess implements Action {
  public readonly type = EAllocationsActions.EXPORT_ALLOCATION_DETAILS_SUCCESS;
}

export class ExportAllocationDetailsFailure implements Action {
  public readonly type = EAllocationsActions.EXPORT_ALLOCATION_DETAILS_FAILURE;

  constructor(public payload: Error) {}
}

export class FetchAllocations implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATIONS;

  constructor(public payload: IAllocationsPayload) {}
}

export class FetchAllocationsSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATIONS_SUCCESS;

  constructor(public payload: { allocations: Array<Allocation>; totalAllocationsCount: number }) {}
}

export class FetchAllocationsError implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearAllocations implements Action {
  public readonly type = EAllocationsActions.CLEAR_ALLOCATIONS;

  constructor() {}
}

/**
 * Allocation Detail
 */

export class FetchAllocationDetails implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATION_DETAILS;

  constructor(public payload: IAllocationDetailsPayload) {}
}

export class FetchAllocationDetailsSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATION_DETAILS_SUCCESS;

  constructor(
    public payload: { allocationDetails: Array<AllocationDetail>; totalAllocationsCount: number }
  ) {}
}

export class FetchAllocationDetailsError implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATION_DETAILS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Allocation Method
 */

export class FetchAllocationMethod implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATIONS_METHOD;

  constructor() {}
}

export class FetchAllocationMethodSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATIONS_METHOD_SUCCESS;

  constructor(public payload: Array<AllocationMethod>) {}
}

export class FetchAllocationMethodError implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATIONS_METHOD_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Create a new pda
 */

export class CreatePda implements Action {
  public readonly type = EAllocationsActions.CREATE_PDA;

  constructor(public payload: PredeterminedAllocation) {}
}

export class CreatePdaSuccess implements Action {
  public readonly type = EAllocationsActions.CREATE_PDA_SUCCESS;

  constructor() {}
}

export class CreatePdaError implements Action {
  public readonly type = EAllocationsActions.CREATE_PDA_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Fetch Pdas
 */

export class FetchPdas implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATIONS;

  constructor(
    public payload: {
      locationId?: number;
      dateBegin: Date;
      dateEnd: Date;
      pagination: IPagination;
      sortDescriptors: SortDescriptor[];
      tspId?: number;
    }
  ) {}
}

export class ClearPdas implements Action {
  public readonly type = EAllocationsActions.CLEAR_PDAS;

  constructor() {}
}

export class FetchPdasSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATIONS_SUCCESS;

  constructor(
    public payload: {
      collection: Array<PredeterminedAllocation>;
      totalPredeterminedAllocations: number;
    }
  ) {}
}

export class FetchPdasFailure implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATIONS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Fetch Pda by ID
 */

export class FetchPdaById implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_BY_ID;

  constructor(
    public payload: {
      pdaId: number;
      dateBegin?: Date;
      dateEnd?: Date;
    }
  ) {}
}

export class FetchPdaByIdSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_BY_ID_SUCCESS;

  constructor(public payload: { predeterminedAllocation: PredeterminedAllocation }) {}
}

export class FetchPdaByIdFailure implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_BY_ID_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Fetch Incomplete Pdas
 */

export class FetchIncompletePdas implements Action {
  public readonly type = EAllocationsActions.FETCH_INCOMPLETE_PDAS;

  constructor(public payload: { gasDays?: number; tspId?: number }) {}
}

export class FetchIncompletePdasSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_INCOMPLETE_PDAS_SUCCESS;

  constructor(
    public payload: {
      predeterminedAllocationStatusCollection: PredeterminedAllocationStatusCollection;
    }
  ) {}
}

export class FetchIncompletePdasFailure implements Action {
  public readonly type = EAllocationsActions.FETCH_INCOMPLETE_PDAS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Fetch Missing Pdas
 */

export class FetchMissingPdas implements Action {
  public readonly type = EAllocationsActions.FETCH_MISSING_PDAS;

  constructor(public payload: { gasDays?: number; tspId?: number }) {}
}

export class FetchMissingPdasSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_MISSING_PDAS_SUCCESS;

  constructor(
    public payload: {
      predeterminedAllocationStatusCollection: PredeterminedAllocationStatusCollection;
    }
  ) {}
}

export class FetchMissingPdasFailure implements Action {
  public readonly type = EAllocationsActions.FETCH_MISSING_PDAS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Fetch Expired Pdas
 */

export class FetchExpiredPdas implements Action {
  public readonly type = EAllocationsActions.FETCH_EXPIRED_PDAS;

  constructor(public payload: { gasDays?: number }) {}
}

export class FetchExpiredPdasSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_EXPIRED_PDAS_SUCCESS;

  constructor(
    public payload: {
      predeterminedAllocationStatusCollection: PredeterminedAllocationStatusCollection;
    }
  ) {}
}

export class FetchExpiredPdasFailure implements Action {
  public readonly type = EAllocationsActions.FETCH_EXPIRED_PDAS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

/**
 * Fetch pda details
 */
export interface FetchPdaDetailsPayload {
  locationId: number;
  locationNumber: number;
  dateBegin: Date;
  dateEnd: Date;
  locationNormalFlowDirection: string;
  pdaId?: number;
  accountIds?: Array<number>;
  allocationMethodCode?: string;
}

export class FetchPdaDetails implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_DETAILS;

  constructor(public payload: FetchPdaDetailsPayload) {}
}

export class FetchPdaDetailsSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_DETAILS_SUCCESS;

  constructor(public payload: PredeterminedAllocationDetailCollection) {}
}

export class FetchPdaDetailsFailure implements Action {
  public readonly type = EAllocationsActions.FETCH_PREDETERMINED_ALLOCATION_DETAILS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class ClearPdaDetails implements Action {
  public readonly type = EAllocationsActions.CLEAR_PDA_DETAILS;

  constructor() {}
}

export class SetCreatePDALocationParam implements Action {
  public readonly type = EAllocationsActions.SET_CREATE_PDA_LOCATION_PARAM;

  constructor(
    public payload: {
      locationName: string;
      locationId: number;
      locationNumber: number;
      beginDate: Date;
      endDate: Date;
    }
  ) {}
}

export class ClearCreatePDALocationParam implements Action {
  public readonly type = EAllocationsActions.CLEAR_CREATE_PDA_LOCATION_PARAM;

  constructor() {}
}

export class FetchAllocationLookup implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATION_LOOKUP;

  constructor() {}
}

export class FetchAllocationLookupSuccess implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATION_LOOKUP_SUCCESS;

  constructor(public payload: LookupCollections) {}
}

export class FetchAllocationLookupFailure implements Action {
  public readonly type = EAllocationsActions.FETCH_ALLOCATION_LOOKUP_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export type AllocationsActions =
  | FetchAllocations
  | FetchAllocationsSuccess
  | FetchAllocationsError
  | ClearAllocations
  | FetchAllocationDetails
  | FetchAllocationDetailsSuccess
  | FetchAllocationDetailsError
  | FetchAllocationMethod
  | FetchAllocationMethodSuccess
  | FetchAllocationMethodError
  | CreatePda
  | CreatePdaSuccess
  | CreatePdaError
  | FetchPdas
  | FetchPdasSuccess
  | FetchPdasFailure
  | FetchPdaDetails
  | FetchPdaDetailsSuccess
  | FetchPdaDetailsFailure
  | ClearPdaDetails
  | FetchIncompletePdas
  | FetchIncompletePdasSuccess
  | FetchIncompletePdasFailure
  | FetchMissingPdas
  | FetchMissingPdasSuccess
  | FetchMissingPdasFailure
  | FetchExpiredPdas
  | FetchExpiredPdasSuccess
  | FetchExpiredPdasFailure
  | FetchPdaById
  | FetchPdaByIdSuccess
  | FetchPdaByIdFailure
  | SetCreatePDALocationParam
  | ClearCreatePDALocationParam
  | FetchAllocationLookup
  | FetchAllocationLookupSuccess
  | FetchAllocationLookupFailure
  | ExportAllocationDetails
  | ExportAllocationDetailsSuccess
  | ExportAllocationDetailsFailure
  | ClearPdas;
