export enum EControlErrorType {
  ERROR,
  WARNING,
  INFO,
}

export interface IControlError {
  type: EControlErrorType;
  message: string;
  code?: string;
  priority?: number;
}
